import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  DrawerProps,
} from '@chakra-ui/react';
import Actions from '../Layouts/Actions';

export default function ActionDrawer(props: Omit<DrawerProps, 'children'>) {
  return (
    <Drawer {...props}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <Actions />
      </DrawerContent>
    </Drawer>
  );
}
