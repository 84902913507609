import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { Fragment } from "react";
import { useBusinessContext } from "../../../../../Hooks/BusinessContext";
import AcceptRequestModal from "../Modals/AcceptShiftModal";
import RejectRequestModal from "../Modals/RejectRequestModal";
interface RequestTagProps {
  shift: any;
  setLastUpdated: (time: number) => void;
}
export default function RequestTag({ shift, setLastUpdated }: RequestTagProps) {
  const { allEmployeesList, locationList, businessesList, currentBusinessId } =
    useBusinessContext();
  const calculateDuration = (start: any, end: any) => {
    const startTime = moment(start, "YYYY-MM-DDTHH:mm:ss");
    const endTime = moment(end, "YYYY-MM-DDTHH:mm:ss");
    const duration = moment.duration(endTime.diff(startTime));
    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${hours}h ${minutes}m`;
  };
  const acceptRequest = useDisclosure();
  const rejectRequest = useDisclosure();
  return (
    <Fragment>
      <AcceptRequestModal
        isOpen={acceptRequest.isOpen}
        onClose={acceptRequest.onClose}
        setLastUpdated={setLastUpdated}
        shiftId={shift?.id}
      />
      <RejectRequestModal
        isOpen={rejectRequest.isOpen}
        onClose={rejectRequest.onClose}
        setLastUpdated={setLastUpdated}
        shiftId={shift?.id}
      />
      <Box
        h="auto"
        w={"100%"}
        border="1px"
        borderColor="gray.200"
        borderRadius="md"
        p="10px"
        alignItems="center"
        justifyContent="space-between"
        mb="10px"
      >
        <Flex>
          <Text
            fontWeight={"600"}
            flex={1}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"15px"}
          >
            {moment(shift?.shift?.shiftStartDate).format("DD MMM YYYY")} -{" "}
            {
              locationList?.find(
                (location: any) => location.id === shift?.shift?.locationId
              )?.name
            }
          </Text>
          <Text
            fontWeight={"600"}
            color={
              shift?.shift?.status === "pending"
                ? "#FFA500"
                : shift?.shift?.status === "started"
                ? "#000000"
                : "#FF0000"
            }
            fontSize={"12px"}
          >
            {shift?.shift?.status === "pending"
              ? "Pending"
              : shift?.shift?.status === "started"
              ? "Started"
              : "Ended"}
          </Text>
        </Flex>
        <Flex>
          <Box flex={1}>
            <Text
              fontWeight={"400"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontSize={"15px"}
            >
              {`${moment(
                `${shift?.shift?.shiftStartDate}T${shift?.shift?.shiftStartTime}`
              ).format("hh:mm A")} - ${moment(
                `${shift?.shift?.shiftEndDate}T${shift?.shift?.shiftEndTime}`
              ).format("hh:mm A")}`}
            </Text>
            <Text
              fontWeight={"400"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontSize={"11px"}
            >
              Employee name:{" "}
              {
                allEmployeesList?.find(
                  (emp: any) => emp.id === shift?.shift?.employeeId
                )?.firstName
              }{" "}
              {
                allEmployeesList?.find(
                  (emp: any) => emp.id === shift?.shift?.employeeId
                )?.lastName
              }
            </Text>
          </Box>
          <Box>
            <Text
              fontWeight={"400"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontSize={"11px"}
            >
              Total Shift time:{" "}
              {calculateDuration(
                `${shift?.shift?.shiftStartDate}T${shift?.shift?.shiftStartTime}`,
                `${shift?.shift?.shiftEndDate}T${shift?.shift?.shiftEndTime}`
              )}
            </Text>
            <Text
              fontWeight={"400"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontSize={"11px"}
              textAlign="right"
            >
              Business:{" "}
              {
                businessesList?.find(
                  (business: any) => business.id === currentBusinessId
                )?.name
              }
            </Text>
          </Box>
        </Flex>
        <Flex justifyContent="flex-end">
          <ButtonGroup>
            <Button
              borderRadius={"5px"}
              colorScheme="green"
              size="sm"
              onClick={() => {
                acceptRequest.onOpen();
              }}
            >
              Accept
            </Button>
            <Button
              borderRadius={"5px"}
              colorScheme="red"
              size="sm"
              onClick={() => {
                rejectRequest.onOpen();
              }}
            >
              Reject
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </Fragment>
  );
}
