import {
  Box,
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
// import {addDays} from 'date-fns';
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { CustomInput } from "../../../../../Common/CustomInput";
import { ReactSelect } from "../../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../../Hooks/BusinessContext";
import { TemplateMeta } from "../../../../modules/Audit";
import { ScheduleInspectionModule } from "../../../../modules/Audit/ScheduleInspectionModule";
import { ComponentLabel } from "../../../Notification/Components/ComponentLabel";

interface ScheduleInspectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedId?: number;
  templatesList: TemplateMeta.TemplateMeta[];
  setLastUpdate: (timestamp: number) => void;
}
export default function ScheduleInspectionModal({
  isOpen,
  onClose,
  selectedId,
  templatesList,
  setLastUpdate,
}: ScheduleInspectionModalProps) {
  const form = useFormContext<any>();
  const { allUsers, locationList } = useBusinessContext();
  const frequencyOptions = [
    {
      label: "One off",
      value: "ONCE",
    },
    {
      label: "Every day",
      value: "EVERY_DAY",
    },
    {
      label: "Every weekday",
      value: "EVERY_WEEKDAY",
    },
    {
      label: "Every week",
      value: "EVERY_WEEK",
    },
    {
      label: "Every month",
      value: "EVERY_MONTH",
    },
    {
      label: "Every year",
      value: "EVERY_YEAR",
    },
    {
      label: "Custom",
      value: "CUSTOM",
    },
  ];
  const watchStartDate = useWatch({
    control: form.control,
    name: "timeRange.after",
  });
  const customFrequency = ["Month", "Week"].map((customFrequency) => ({
    label: customFrequency,
    value: customFrequency,
  }));
  const toast = useToast();
  const page = 0;
  const limit = 0;

  const daysOfWeek = [
    { value: "0", name: "S" },
    { value: "1", name: "M" },
    { value: "2", name: "T" },
    { value: "3", name: "W" },
    { value: "4", name: "T" },
    { value: "5", name: "F" },
    { value: "6", name: "S" },
  ];

  const [maxDate, setMaxDate] = useState(0);
  const [totalDays, setTotalDays] = useState<number>(0);

  const extractDate = (dateTimeString: any) => {
    const dateObj = new Date(dateTimeString);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const formattedDate = `${day.toString().padStart(2, "0")}-${month
      .toString()
      .padStart(2, "0")}-${year}`;
    return formattedDate;
  };
  const allDays = useFieldArray({
    control: form.control,
    name: "days",
  });
  const [selectedFrequency, setSelectedFrequency] = useState("");

  const watchedFrequency = useWatch({
    control: form.control,
    name: "frequency",
  });
  const watchedLocation = useWatch({
    control: form.control,
    name: "locationId",
  });
  const watchedTemplate = useWatch({
    control: form.control,
    name: "templateId",
  });

  useEffect(() => {
    let title = "";
    if (watchedFrequency) {
      const frquencyname = frequencyOptions.find(
        (option) => option.value === watchedFrequency
      )?.label;
      title += frquencyname + " - ";
    }
    if (watchedLocation) {
      const locaionName = locationList?.find(
        (option) => option.id === watchedLocation
      )?.name;
      title += locaionName + " - ";
    }
    if (watchedTemplate) {
      const templatename = templatesList.find(
        (option) => option.id === watchedTemplate
      )?.title;
      title += templatename;
    }
    form.setValue("title", title);
  }, [watchedFrequency, watchedLocation, watchedTemplate]);

  const calculateMaxDate = () => {
    let daysToAdd = 0;
    switch (watchedFrequency) {
      case "EVERY_DAY":
        daysToAdd = 1;
        break;
      case "EVERY_WEEKDAY":
        daysToAdd = 1;
        break;
      case "EVERY_WEEK":
        daysToAdd = 7;
        break;
      case "EVERY_MONTH":
        daysToAdd = 30;
        break;
      case "EVERY_YEAR":
        daysToAdd = 365;
        break;
      case "CUSTOM":
        if (form.watch("customFrequency.entity") === "Week") {
          daysToAdd = form.watch("customFrequency.number") * 7;
        } else if (form.watch("customFrequency.entity") === "Month") {
          daysToAdd = form.watch("customFrequency.number") * 30;
        }
        break;
      default:
        daysToAdd = 365;
        break;
    }
    setMaxDate(daysToAdd);
  };

  useEffect(() => {
    calculateMaxDate();

    const startDate = moment(watchStartDate);
    let newEndDate = startDate.clone();

    switch (watchedFrequency) {
      case "EVERY_DAY":
      case "EVERY_WEEKDAY":
        newEndDate = startDate.add(1, "days");
        break;
      case "EVERY_WEEK":
        newEndDate = startDate.add(1, "weeks");
        break;
      case "EVERY_MONTH":
        newEndDate = startDate.add(1, "months");
        break;
      case "EVERY_YEAR":
        newEndDate = startDate.add(1, "years");
        break;
      case "CUSTOM":
        if (form.watch("customFrequency.entity") === "Week") {
          newEndDate = startDate.add(
            form.watch("customFrequency.number"),
            "weeks"
          );
        } else if (form.watch("customFrequency.entity") === "Month") {
          newEndDate = startDate.add(
            form.watch("customFrequency.number"),
            "months"
          );
        }
        break;
      default:
        newEndDate = startDate.add(1, "days");
        break;
    }

    form.setValue("timeRange.before", newEndDate.format("YYYY-MM-DDTHH:mm"));
  }, [watchStartDate, watchedFrequency, form.watch]);
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Schedule Inspection</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Stack
              pb="16px"
              pt="32px"
              px="32px"
              h="100%"
              border="1px"
              borderColor="borders.accent"
              borderTopRadius="16px"
              spacing="40px"
            >
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Template is required",
                  },
                }}
                name="templateId"
                control={form.control}
                render={({ field, fieldState }) => (
                  <ReactSelect
                    key={field.value}
                    {...field}
                    label="Template"
                    withValidation
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Please select template"
                    placeholder="Select template"
                    options={templatesList}
                    value={
                      field.value
                        ? {
                            id: field.value,
                            title:
                              templatesList.find(
                                (temp) => temp.id === field.value
                              )?.title ?? "",
                          }
                        : undefined
                    }
                    onChange={(val: any) => {
                      field.onChange(val?.id);
                    }}
                    getOptionLabel={(option: any) => option.title}
                    getOptionValue={(option: any) => option.id}
                  />
                )}
              />

              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Location is required",
                  },
                }}
                name="locationId"
                control={form.control}
                render={({ field, fieldState }) => (
                  <ReactSelect
                    key={field.value}
                    {...field}
                    label="Location"
                    withValidation
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Please select location"
                    placeholder="Select Location"
                    options={locationList}
                    value={
                      field.value
                        ? {
                            id: field.value,
                            name:
                              locationList?.find(
                                (loc) => loc.id === field.value
                              )?.name ?? "",
                          }
                        : undefined
                    }
                    onChange={(val: any) => {
                      field.onChange(val?.id);
                    }}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.id}
                  />
                )}
              />

              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "User is required",
                  },
                }}
                name="employeeId"
                control={form.control}
                render={({ field, fieldState }) => (
                  <ReactSelect
                    key={field.value}
                    {...field}
                    label="Employees"
                    withValidation
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Please select User"
                    placeholder="Select User"
                    options={allUsers.map((user) => {
                      return {
                        id: user.user.id,
                        firstName: user.user.firstName,
                        lastName: user.user.lastName,
                        access: user.accessLevel,
                      };
                    })}
                    getOptionLabel={(option: any) => {
                      return `${option?.firstName} ${option?.lastName} (${option?.access})`;
                    }}
                    getOptionValue={(option: any) => option.id}
                    value={
                      field.value
                        ? {
                            id: field.value,
                            firstName:
                              allUsers.find(
                                (user) => user?.user?.id === field.value
                              )?.user?.firstName ?? "",
                            lastName:
                              allUsers.find(
                                (user) => user?.user?.id === field.value
                              )?.user?.lastName ?? "",
                            access:
                              allUsers.find(
                                (user) => user?.user?.id === field.value
                              )?.accessLevel ?? "",
                          }
                        : undefined
                    }
                    onChange={(val: any) => {
                      field.onChange(val?.id);
                    }}
                  />
                )}
              />
              <Stack>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Frequency is required",
                    },
                  }}
                  name="frequency"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      key={field.value}
                      {...field}
                      label="Frequency"
                      withValidation
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Please Select frequency"
                      placeholder="Select Frequency"
                      options={frequencyOptions}
                      value={
                        field.value
                          ? frequencyOptions.find(
                              (option) => option.value === field.value
                            )
                          : undefined
                      }
                      onChange={(selectedOption: any) => {
                        field.onChange(selectedOption?.value);
                        setSelectedFrequency(selectedOption?.value);

                        // if (selectedOption?.value === 'EVERY_DAY') {
                        //   setMaxDate(1);
                        // } else if (selectedOption?.value === 'EVERY_WEEKDAY') {
                        //   setMaxDate(1);
                        // } else if (selectedOption?.value === 'EVERY_WEEK') {
                        //   setMaxDate(7);
                        // } else if (selectedOption?.value === 'EVERY_MONTH') {
                        //   setMaxDate(30);
                        // } else if (selectedOption?.value === 'EVERY_YEAR') {
                        //   setMaxDate(365);
                        // } else if (selectedOption?.value === 'CUSTOM') {
                        //   if (form.watch('customFrequency.entity') === 'Week') {
                        //     setMaxDate(
                        //       form.watch('customFrequency.number') * 7,
                        //     );
                        //   } else if (
                        //     form.watch('customFrequency.entity') === 'Month'
                        //   ) {
                        //     setMaxDate(
                        //       form.watch('customFrequency.number') * 30,
                        //     );
                        //   }
                        // } else {
                        //   setMaxDate(365);
                        // }
                      }}
                    />
                  )}
                />

                {form.watch("frequency") === "CUSTOM" && (
                  <HStack w="50%" spacing="4px">
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Range is required",
                        },
                      }}
                      name="customFrequency.number"
                      control={form.control}
                      render={({ field, fieldState }) => (
                        <CustomInput
                          input={{
                            ...field,
                            marginTop: "8px",
                            variant: "outline",
                            isInvalid:
                              (fieldState.error?.message?.length ?? 0) > 0,
                            value: field.value,
                            type: "number",
                          }}
                          placeholder="Enter Range"
                          withValidation
                          validationMessage={fieldState.error?.message}
                        />
                      )}
                    />

                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Select Custom Frequency",
                        },
                      }}
                      control={form.control}
                      name="customFrequency.entity"
                      render={({ field, fieldState }) => (
                        <ReactSelect
                          key={field.value}
                          {...field}
                          options={customFrequency}
                          withValidation
                          isInvalid={fieldState.invalid}
                          ErrorMessage="Please Select Cutom Frequency"
                          placeholder="Select..."
                          value={customFrequency.find(
                            (op) => op.value === field.value
                          )}
                          onChange={(newValue: any) => {
                            field.onChange(newValue?.value);
                          }}
                        />
                      )}
                    />
                  </HStack>
                )}

                {form.watch("customFrequency.entity") === "Week" &&
                  form.watch("frequency") === "CUSTOM" && (
                    <Controller
                      control={form.control}
                      name="days"
                      render={({ field, fieldState }) => (
                        <Stack>
                          <HStack align="start" spacing="16px">
                            {daysOfWeek.map((day, index) => {
                              const isCheckedDefault = (
                                (field.value ?? []) as Array<string>
                              ).findIndex((value) => value === day.value);
                              return (
                                <Checkbox
                                  key={index}
                                  isChecked={isCheckedDefault > -1}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      allDays.append(day.value);
                                    } else {
                                      allDays.remove(isCheckedDefault);
                                    }
                                  }}
                                >
                                  {day.name}
                                </Checkbox>
                              );
                            })}
                          </HStack>
                          {fieldState.error && (
                            <Text color="red.500" fontSize="xs">
                              {fieldState.error.message}
                            </Text>
                          )}
                        </Stack>
                      )}
                    />
                  )}
                <HStack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Date & Time is required",
                      },
                    }}
                    name="timeRange.after"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <CustomInput
                        input={{
                          ...field,
                          variant: "outline",
                          isInvalid:
                            (fieldState.error?.message?.length ?? 0) > 0,
                          value: moment(field.value).format("YYYY-MM-DDTHH:mm"),

                          type: "datetime-local",
                        }}
                        withValidation
                        validationMessage={fieldState.error?.message}
                      />
                    )}
                  />

                  <Text>~</Text>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Date & Time is required",
                      },

                      validate: {
                        isAfterStartDate: (value) =>
                          moment(value).isSameOrAfter(watchStartDate) ||
                          "End date cannot be before start date",
                      },
                    }}
                    name="timeRange.before"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <CustomInput
                        input={{
                          ...field,
                          variant: "outline",
                          isInvalid:
                            (fieldState.error?.message?.length ?? 0) > 0,
                          value: moment(field.value).format("YYYY-MM-DDTHH:mm"),

                          type: "datetime-local",
                          min: watchStartDate,
                          max:
                            watchStartDate &&
                            moment(watchStartDate)
                              .add(maxDate, "days")
                              .format("YYYY-MM-DDTHH:mm"),
                        }}
                        withValidation
                        validationMessage={fieldState.error?.message}
                      />
                    )}
                  />
                </HStack>
                <Controller
                  control={form.control}
                  name="allowedAfterTime"
                  render={({ field }) => (
                    <Checkbox
                      isChecked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    >
                      <HStack align="center" spacing="4px">
                        <ComponentLabel p={0}>
                          Allow inspections to be submitted after the due date
                        </ComponentLabel>
                        <AiOutlineInfoCircle size={13} color="#545f70" />
                      </HStack>
                    </Checkbox>
                  )}
                />
              </Stack>
              <Stack>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Title is required",
                    },
                  }}
                  name="title"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <CustomInput
                      input={{
                        ...field,
                        variant: "outline",
                        isInvalid: (fieldState.error?.message?.length ?? 0) > 0,
                        value: field.value,
                        type: "text",
                      }}
                      label="Title"
                      withValidation
                      validationMessage={fieldState.error?.message}
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              variant="solid"
              borderRadius="8px"
              h="43px"
              px="20px"
              py="12px"
              onClick={form.handleSubmit(
                (data) => {
                  if (data.frequency === "CUSTOM") {
                    if (data.customFrequency.entity === "Week") {
                      if (allDays.fields.length == 0) {
                        // form.setError('days', {
                        //   type: 'manual',
                        //   message: 'Select at least one day',
                        // });
                        toast({
                          title: "Error",
                          description: "Select at least one day",
                          status: "error",
                        });
                        return;
                      } else {
                        data["numberOfDays"] =
                          data.customFrequency.number * allDays.fields.length;
                      }
                    } else if (data.customFrequency.entity === "Month") {
                      data["numberOfDays"] = data.customFrequency.number * 30;
                      delete data["days"];
                    }
                  } else {
                    delete data["numberOfDays"];
                    delete data["days"];
                  }
                  data["timeRange"] = {
                    after: moment(data.timeRange.after).utc(),
                    before: moment(data.timeRange.before).utc(),
                  };

                  if (selectedId) {
                    ScheduleInspectionModule.UpdateScheduleInspection(
                      selectedId,
                      data,
                      () => {
                        toast({
                          title: "Inspection Schedule Updated",
                          status: "success",
                        });
                        setLastUpdate(Date.now());
                        onClose();
                      },
                      (error) => {
                        toast({
                          title: "Error",
                          description: error,
                          status: "error",
                        });
                      }
                    );
                  } else {
                    ScheduleInspectionModule.PostScheduleInspection(
                      data,
                      () => {
                        toast({
                          title: "Inspection Scheduled",
                          status: "success",
                        });
                        setLastUpdate(Date.now());
                        onClose();
                        console.log(data.timeRange.after);
                      },
                      (error) => {
                        toast({
                          title: "Error",
                          description: error,
                          status: "error",
                        });
                      }
                    );
                  }
                },
                (error) => {
                  toast({
                    title: "Error",

                    status: "error",
                  });
                }
              )}
            >
              {selectedId ? "Update" : "Create"}
            </Button>
            <Button
              variant="outline"
              borderRadius="8px"
              h="43px"
              px="20px"
              py="12px"
              onClick={() => {
                form.reset({
                  title: "",
                  allowedAfterTime: false,
                  days: [],
                  employeeId: undefined,
                  frequency: undefined,
                  locationId: undefined,
                  numberOfDays: 0,
                  timeRange: {
                    after: "",
                    before: "",
                  },
                  templateId: 0,
                });
                onClose();
              }}
            >
              Cancel
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
