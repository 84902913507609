import {
  Box,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import {Controller, useFormContext} from 'react-hook-form';
import {PeriodicalSchedule} from '../../../../../modules/Audit/PeriodicalSchedule';

export default function DailySchedule() {
  const form = useFormContext<PeriodicalSchedule.AddPeriodicSchedule>();

  return (
    <Box>
      <HStack h="100%">
        <Controller
          control={form.control}
          name="properties.selection"
          rules={{
            onChange(event) {
              event.target.value === 'WEEKDAY'
                ? form.setValue('properties.daily', {
                    onlyWeekdays: true,
                  })
                : form.setValue('properties.daily', {
                    every: undefined,
                  });
            },
          }}
          render={selection => (
            <RadioGroup {...selection.field} h="100%">
              <Stack>
                <HStack>
                  <Radio value="DAY" mb="8px">
                    Every
                  </Radio>
                  <Controller
                    control={form.control}
                    name="properties.daily.every"
                    render={everyValue => (
                      <NumberInput
                        key={everyValue.field.value}
                        w="75px"
                        max={31}
                        mb="8px"
                        isDisabled={selection.field.value !== 'DAY'}
                        {...everyValue.field}>
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    )}
                  />
                  <span>day(s)</span>
                </HStack>
                <Radio value="WEEKDAY" mt="16px">
                  Every Week Day
                </Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </HStack>
    </Box>
  );
}
