import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Flex,
  Heading,
  Icon,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  useAuthHeader,
  useAuthUser,
  useSignIn,
  useSignOut,
} from "react-auth-kit";
import { FormProvider, useForm } from "react-hook-form";
import { FaPlus, FaUserSecret } from "react-icons/fa";
import { IoLogOutOutline, IoPeopleSharp, IoSettings } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { Business } from "../../Api/Business";
import AddBusiness from "../Modal/addBusiness";
import { capitalizeFirstLetter } from "../TimeSheet/utils";

export function SwitchBusiness() {
  const [userName, setUserName] = useState("");
  const user = useAuthUser();
  const addBusinessModal = useDisclosure();
  const signIn = useSignIn();
  const navigate = useNavigate();
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  const { currentEmployeeId } = useBusinessContext();
  const toast = useToast();

  const signOut = useSignOut();
  const form = useForm<Business.AddNewBusiness>({
    defaultValues: {
      name: "",
      industry: "",
      country: "",
      timezone: "",
      currency: "",
      last_date_financial_year: "",
      last_month_financial_year: "",
      gst_registered: false,
    },
  });
  useEffect(() => {
    const UserDetail = user();

    if (UserDetail) {
      const fullName = UserDetail.firstName + " " + UserDetail.lastName;
      setUserName(fullName); // Update the state variable
    } else {
      toast({
        title: "Name not found in the user object",
        status: "error",
      });
    }
  }, []);
  const { businessesList, setCurrentBusinessId, currentBusinessId } =
    useBusinessContext();
  const [EmployeeBusinesses, setEmployeeBusinesses] = useState<any[]>([]);
  const [ManagementBusinesses, setManagementBusinesses] = useState<any[]>([]);
  useEffect(() => {
    if (businessesList.length > 0) {
      const employeeBusinesses = businessesList.filter(
        (business) => business.accessLevel === "EMPLOYEE"
      );
      const managementBusinesses = businessesList.filter(
        (business) => business.accessLevel !== "EMPLOYEE"
      );
      setEmployeeBusinesses(employeeBusinesses);
      setManagementBusinesses(managementBusinesses);
    }
  }, [businessesList]);
  function switchBusiness(
    id: string,
    accessLevel: string,
    callback?: Function
  ) {
    Business.SwitchBusiness(
      id,
      accessLevel,
      (data) => {
        const decodedToken = jwtDecode<any>(data.token);
        const expirytime = decodedToken.exp;
        const timeDiff = moment.duration(
          expirytime - moment().unix(),
          "seconds"
        );
        setCurrentBusinessId(id);
        if (
          signIn({
            token: data.token,
            expiresIn: timeDiff.asMinutes(),
            tokenType: "Bearer",
            authState: user()!,
          })
        ) {
          toast({
            title: "Business Switched",
            status: "success",
          });

          callback?.();
        }
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      }
    );
  }
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const handleButtonClick = (e: any, id: any, accessLevel: any, path: any) => {
    e.stopPropagation();
    if (!isButtonClicked) {
      setIsButtonClicked(true);
      switchBusiness(id, accessLevel, () => {
        navigate(path);
      });
    }
  };
  const [EmployeeSelected, setEmployeeSelected] = useState(true);
  const [ManagementSelected, setManagementSelected] = useState(false);
  return (
    <FormProvider {...form}>
      <Stack overflowX={"auto"} h="calc(100vh)">
        <AddBusiness
          isOpen={addBusinessModal.isOpen}
          onClose={addBusinessModal.onClose}
        />
        <Box
          w="100%"
          display="flex"
          justifyContent="flex-end"
          marginTop="24px"
          paddingRight={"16px"}
        >
          <Button
            leftIcon={<IoLogOutOutline size={20} />}
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"customColor.black7"}
            padding={"12px 16px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
              // color: "#3454D1",
              color: "red",
            }}
            onClick={() => {
              signOut();
              toast({
                title: "Logout Successful",
                status: "success",
              });
              navigate("/");
            }}
          >
            Logout
          </Button>
        </Box>
        <Container
          justifyContent="center"
          alignItems="center"
          p="50px 16px"
          maxW={"1024px"}
          borderRadius="md"
        >
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems={"baseline"}
            >
              <Heading
                size="lg"
                fontSize={"36px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Welcome
              </Heading>
              <Stack direction="row" alignItems={"baseline"}>
                <Text
                  fontSize={"36px"}
                  color={"primary.950"}
                  fontWeight={"700"}
                >
                  {userName}
                </Text>
                <Text
                  fontSize={"36px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  fontWeight={"700"}
                >
                  !
                </Text>
              </Stack>
            </Stack>

            <ButtonGroup spacing={0}>
              <Button
                onClick={() => {
                  setManagementSelected(true);
                  setEmployeeSelected(false);
                }}
                bgColor={ManagementSelected ? "#3454D1" : "#fff"}
                color={ManagementSelected ? "#fff" : "#3454D1"}
                padding={"12px 16px"}
                borderRadius={"2px"}
                fontWeight={700}
                variant={ManagementSelected ? "solid" : "outline"}
                border={
                  ManagementSelected ? "1px solid #3454D1" : "1px solid #E2E8F0"
                }
                _hover={{
                  bgColor: ManagementSelected ? "#2c48b5" : "#e9eefc",
                }}
                marginRight={"-1px"}
              >
                Management
              </Button>
              <Button
                onClick={() => {
                  setEmployeeSelected(true);
                  setManagementSelected(false);
                }}
                bgColor={EmployeeSelected ? "#3454D1" : "#fff"}
                color={EmployeeSelected ? "#fff" : "#3454D1"}
                padding={"12px 16px"}
                borderRadius={"2px"}
                variant={EmployeeSelected ? "solid" : "outline"}
                fontWeight={700}
                marginLeft={"-2px"}
                border={
                  EmployeeSelected ? "1px solid #3454D1" : "1px solid #3454D1"
                }
                _hover={{
                  bgColor: EmployeeSelected ? "#2c48b5" : "#e9eefc",
                }}
              >
                Employee
              </Button>
            </ButtonGroup>
          </Stack>
          <Text
            fontSize={"16px"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            marginBottom={"50px"}
          >
            Select one of the your organizations to start managing your Payroll
            and Timesheets.
          </Text>
          <Flex w="100%" justifyContent="flex-end" mb="10px">
            {ManagementSelected && (
              <Stack>
                <Button
                  w={"fit-content"}
                  leftIcon={<FaPlus fontSize={"14px"} />}
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  onClick={() => {
                    addBusinessModal.onOpen();
                    form.reset({
                      name: "",
                      industry: "",
                      country: "",
                      timezone: "",
                      currency: "",
                      last_date_financial_year: "",
                      last_month_financial_year: "",
                      gst_registered: false,
                    });
                  }}
                >
                  Add Organization
                </Button>
              </Stack>
            )}
          </Flex>
          {/* Management Businesses */}
          {ManagementSelected && ManagementBusinesses.length > 0
            ? ManagementBusinesses.map((business, i) => {
                const businessAccessLevel = business?.accessLevel;
                return (
                  <Stack
                    padding={"20px"}
                    borderColor={"#E2E8F0"}
                    borderWidth={"0px"}
                    borderStyle={"solid"}
                    _dark={{
                      bgColor: "customColor.dark.50",
                    }}
                    boxShadow={
                      "0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)"
                    }
                    overflowWrap={"break-word"}
                    borderRadius={"10px"}
                    boxSizing="border-box"
                    _hover={{
                      boxShadow:
                        "0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                      cursor: "pointer",
                    }}
                    key={i}
                    onClick={(e) => {
                      e.stopPropagation();
                      // switchBusiness(business.id);
                    }}
                    marginBottom={"15px"}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        onClick={(e) => {
                          handleButtonClick(
                            e,
                            business.id,
                            businessAccessLevel,
                            "/app/rediretingtobusiness"
                          );
                        }}
                      >
                        <Avatar
                          w={"48px"}
                          h={"48px"}
                          name={business.name}
                          fontWeight={"700"}
                          objectFit={"cover"}
                          src={`${business?.logo}?updated=${Date.now()}`}
                        />
                        <Text
                          fontSize={"24px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          fontWeight={"700"}
                        >
                          {business.name} (
                          {capitalizeFirstLetter(
                            businessAccessLevel?.toString() || ""
                          )}
                          )
                        </Text>
                      </Stack>
                      {businessAccessLevel !== "EMPLOYEE" && (
                        <Stack>
                          <Icon
                            h={"25px"}
                            w={"32px"}
                            as={IoSettings}
                            display={
                              business?.allowedFeatures?.businessSettings
                                ?.editBusinessPermission
                                ? "block"
                                : "none"
                            }
                            color={"primary.950"}
                            onClick={(e) => {
                              e.stopPropagation();
                              form.reset({
                                logo: business.logo,
                                name: business.name,
                                industry: business.industry,
                                country: business.country,
                                timezone: business.timezone,
                                currency: business.currency,
                                last_date_financial_year:
                                  business.last_date_financial_year.toString(),
                                last_month_financial_year:
                                  business.last_month_financial_year.toString(),
                                gst_registered: business.gst_registered,
                                id: business.id,
                              });
                              addBusinessModal.onOpen();
                            }}
                          />
                        </Stack>
                      )}
                    </Stack>
                    <Stack
                      direction={"row"}
                      marginTop={"8px"}
                      display={
                        businessAccessLevel === "EMPLOYEE" ? "none" : "flex"
                      }
                    >
                      <Tag colorScheme="#fff" border={"1.5px solid #11928A "}>
                        <TagLeftIcon as={FaUserSecret} color={"#11928A"} />
                        <TagLabel color={"#11928A"} fontWeight={"700"}>
                          {business?.employeeCount ?? 0} Employees
                        </TagLabel>
                      </Tag>
                      <Tag colorScheme="#fff" border={"1.5px solid #FF0000 "}>
                        <TagLeftIcon as={IoPeopleSharp} color={"#FF0000"} />
                        <TagLabel color={"#FF0000"} fontWeight={"700"}>
                          {business?.userCount ?? 0} Users
                        </TagLabel>
                      </Tag>
                    </Stack>
                    <Divider margin={"0px 0px 8px 0px"} />
                    <Stack direction={"row"}>
                      <Button
                        variant={"outline"}
                        color={"#3454D1"}
                        padding={"12px 15px"}
                        borderRadius={"3px"}
                        onClick={(e) => {
                          handleButtonClick(
                            e,
                            business.id,
                            businessAccessLevel,
                            "/app/rediretingtobusiness"
                          );
                        }}
                        isDisabled={isButtonClicked}
                      >
                        Dashboard
                      </Button>
                      {businessAccessLevel !== "EMPLOYEE" && (
                        <Button
                          variant={"outline"}
                          color={"#3454D1"}
                          padding={"12px 15px"}
                          borderRadius={"3px"}
                          onClick={(e) =>
                            handleButtonClick(
                              e,
                              business.id,
                              businessAccessLevel,
                              "/app/payroll/payrollsettings"
                            )
                          }
                          isDisabled={isButtonClicked}
                        >
                          Payroll
                        </Button>
                      )}
                      {businessAccessLevel !== "EMPLOYEE" && (
                        <Button
                          variant={"outline"}
                          color={"#3454D1"}
                          padding={"12px 15px"}
                          borderRadius={"3px"}
                          onClick={(e) =>
                            handleButtonClick(
                              e,
                              business.id,
                              businessAccessLevel,
                              "/app/TimeAttendance/Schedule"
                            )
                          }
                          isDisabled={isButtonClicked}
                        >
                          Timesheet
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                );
              })
            : ManagementSelected && (
                <Box>
                  <Text>
                    Ooops! you are in the <b>Management Section</b>, please go
                    to above <b>Employee</b> tab.
                  </Text>
                  <Text>
                    If you also have HRIS management portal access, please
                    contact your focal point of contact in HRIS to assist you.
                  </Text>
                </Box>
              )}
          {/* Employee Businesses */}
          {EmployeeSelected && EmployeeBusinesses.length > 0
            ? EmployeeBusinesses.map((business, i) => {
                const businessAccessLevel = business?.accessLevel;
                return (
                  <Stack
                    padding={"20px"}
                    borderColor={"#E2E8F0"}
                    borderWidth={"0px"}
                    borderStyle={"solid"}
                    _dark={{
                      bgColor: "customColor.dark.50",
                    }}
                    boxShadow={
                      "0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)"
                    }
                    overflowWrap={"break-word"}
                    borderRadius={"10px"}
                    boxSizing="border-box"
                    _hover={{
                      boxShadow:
                        "0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                      cursor: "pointer",
                    }}
                    key={i}
                    onClick={(e) => {
                      e.stopPropagation();
                      // switchBusiness(business.id);
                    }}
                    marginBottom={"15px"}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        onClick={(e) => {
                          handleButtonClick(
                            e,
                            business.id,
                            businessAccessLevel,
                            "/app/rediretingtobusiness"
                          );
                        }}
                      >
                        <Avatar
                          w={"48px"}
                          h={"48px"}
                          name={business.name}
                          fontWeight={"700"}
                          objectFit={"cover"}
                          src={`${business?.logo}?updated=${Date.now()}`}
                        />
                        <Text
                          fontSize={"24px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          fontWeight={"700"}
                        >
                          {business.name} (
                          {capitalizeFirstLetter(
                            businessAccessLevel?.toString() || ""
                          )}
                          )
                        </Text>
                      </Stack>
                      {businessAccessLevel !== "EMPLOYEE" && (
                        <Stack>
                          <Icon
                            h={"25px"}
                            w={"32px"}
                            as={IoSettings}
                            color={"primary.950"}
                            display={
                              business?.allowedFeatures?.businessSettings
                                ?.editBusinessPermission
                                ? "block"
                                : "none"
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              form.reset({
                                logo: business.logo,
                                name: business.name,
                                industry: business.industry,
                                country: business.country,
                                timezone: business.timezone,
                                currency: business.currency,
                                last_date_financial_year:
                                  business.last_date_financial_year.toString(),
                                last_month_financial_year:
                                  business.last_month_financial_year.toString(),
                                gst_registered: business.gst_registered,
                                id: business.id,
                              });
                              addBusinessModal.onOpen();
                            }}
                          />
                        </Stack>
                      )}
                    </Stack>
                    <Stack
                      direction={"row"}
                      marginTop={"8px"}
                      display={
                        businessAccessLevel === "EMPLOYEE" ? "none" : "flex"
                      }
                    >
                      <Tag colorScheme="#fff" border={"1.5px solid #11928A "}>
                        <TagLeftIcon as={FaUserSecret} color={"#11928A"} />
                        <TagLabel color={"#11928A"} fontWeight={"700"}>
                          {business?.employeeCount ?? 0} Employees
                        </TagLabel>
                      </Tag>
                      <Tag colorScheme="#fff" border={"1.5px solid #FF0000 "}>
                        <TagLeftIcon as={IoPeopleSharp} color={"#FF0000"} />
                        <TagLabel color={"#FF0000"} fontWeight={"700"}>
                          {business?.userCount ?? 0} Users
                        </TagLabel>
                      </Tag>
                    </Stack>
                    <Divider margin={"0px 0px 8px 0px"} />
                    <Stack direction={"row"}>
                      <Button
                        variant={"outline"}
                        color={"#3454D1"}
                        padding={"12px 15px"}
                        borderRadius={"3px"}
                        onClick={(e) => {
                          handleButtonClick(
                            e,
                            business.id,
                            businessAccessLevel,
                            "/app/rediretingtobusiness"
                          );
                        }}
                        isDisabled={isButtonClicked}
                      >
                        Dashboard
                      </Button>
                      {businessAccessLevel !== "EMPLOYEE" && (
                        <Button
                          variant={"outline"}
                          color={"#3454D1"}
                          padding={"12px 15px"}
                          borderRadius={"3px"}
                          onClick={(e) =>
                            handleButtonClick(
                              e,
                              business.id,
                              businessAccessLevel,
                              "/app/payroll/payrollsettings"
                            )
                          }
                          isDisabled={isButtonClicked}
                        >
                          Payroll
                        </Button>
                      )}
                      {businessAccessLevel !== "EMPLOYEE" && (
                        <Button
                          variant={"outline"}
                          color={"#3454D1"}
                          padding={"12px 15px"}
                          borderRadius={"3px"}
                          onClick={(e) =>
                            handleButtonClick(
                              e,
                              business.id,
                              businessAccessLevel,
                              "/app/TimeAttendance/Schedule"
                            )
                          }
                          isDisabled={isButtonClicked}
                        >
                          Timesheet
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                );
              })
            : EmployeeSelected && (
                <Box>
                  <Text>
                    Ooops! you are in the <b>Employee Section</b>, please select
                    the above <b>Management</b> tab.
                  </Text>
                  <Text>
                    If you also have your own employee dashboard, please contact
                    HRIS support for further assistance.
                  </Text>
                </Box>
              )}
        </Container>
      </Stack>
    </FormProvider>
  );
}
