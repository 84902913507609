import { useToast } from "@chakra-ui/react";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import { useEffect } from "react";
import { useSignIn } from "react-auth-kit";
import { useSearchParams } from "react-router-dom";
import { PayslipsModule } from "../../Api/PaySlips";
import PayslipDocument from "../PDFGenerator/PDFGenerator";

export default function PayslipDownloadMobile() {
  const [queryParams, setQueryParams] = useSearchParams();
  let payslipId = queryParams.get("paySlipId");
  let token = queryParams.get("token");
  const signIn = useSignIn();
  const toast = useToast();
  const downloadPdf = async (data: any) => {
    const fileName = "Payslip.pdf";
    const blob = await pdf(<PayslipDocument data={data} />).toBlob();
    saveAs(blob, fileName);
    window.close();
  };
  useEffect(() => {
    if (!!token && !!payslipId) {
      PayslipsModule.MobileDownloadPayslip(
        token!,
        payslipId!,
        (response) => {
          downloadPdf(response);
        },
        (message) => {
          toast({
            title: "Error",
            status: "error",
            description: message,
          });
        }
      );
    }
  }, [token]);
  return <></>;
}
