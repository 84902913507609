import React from 'react';
import {UseFieldArrayReturn, useFormContext} from 'react-hook-form';
import {Template, Template as TemplateAPI} from '../../../modules/Audit';
import {getFieldKey} from '../../../modules/form-apis';
import useDrawer from './drawersContext';
interface ItemContext {
  item: {
    pageIndex: number;
    sectionIndex?: number;
    questionIndex: number;
  };
  setItem: React.Dispatch<React.SetStateAction<ItemContext['item']>>;
  appendQuestion: Function;
  updateQuestionType: (type: Template.QuestionType, widgetId: number) => void;
  questionsArr: TemplateAPI.Question[];
  appendSection: Function;
}
export const ItemContext = React.createContext<ItemContext>({
  item: {pageIndex: 0, questionIndex: 0},
  setItem: () => {},
  appendQuestion: () => {},
  updateQuestionType: () => {},
  questionsArr: [],
  appendSection: () => {},
});

interface ItemProvider {
  children: React.ReactNode;
  pageIndex: number;
  sectionIndex?: number;
  questionIndex: number;
  pageFieldArray:
    | UseFieldArrayReturn<Template.Template, 'templateItems', 'id'>
    | UseFieldArrayReturn<Template.Template, 'templateItems', 'uniqueId'>;
}
export function ItemProvider({
  children,
  pageIndex,
  questionIndex,
  sectionIndex,
  pageFieldArray,
}: ItemProvider) {
  const [item, setItem] = React.useState<ItemContext['item']>({
    pageIndex,
    questionIndex,
    sectionIndex,
  });
  const {DocNumberDrawer, setQuestionItemDetails, SliderDrawer} = useDrawer();
  const template = useFormContext();
  const key =
    sectionIndex !== undefined
      ? `templateItems.${pageIndex}.children.${sectionIndex}.children`
      : `templateItems.${pageIndex}.children`;

  const questionsArr: Array<any> = template.getValues(key) ?? [];
  function appendQuestion() {
    pageFieldArray.insert(questionIndex + 1, TemplateAPI.Question_INIT);
  }
  function appendSection() {
    pageFieldArray.insert(questionIndex + 1, TemplateAPI.Section_INIT);
  }
  // function updateQuestionType(type: Template.QuestionType) {
  //   const existingValues = template.getValues(getFieldKey(item, 'question'));
  //   const widgetDetails = widgets.find(widget => widget.type === type);

  //   const format = Template.QUESTION_TYPES[type].format;
  //   template.setValue(getFieldKey(item, 'question'), {
  //     ...existingValues,
  //     type,
  //     widgetId: widgetDetails?.id,
  //     format,
  //   });
  // }

  function updateQuestionType(type: Template.QuestionType, widgetId: number) {
    const existingValues = template.getValues(
      getFieldKey({pageIndex, questionIndex, sectionIndex}, 'question'),
    );

    const format = Template.QUESTION_TYPES[type]?.format ?? '';

    if (type !== 'MCQs') {
      delete existingValues.mcqsData;
    }

    const question = {
      ...existingValues,
      widgetId,
      type,
      format,
      properties: {
        ...(type === 'SLIDER'
          ? {
              min: existingValues?.properties?.min ?? 1,
              max: existingValues?.properties?.max ?? 10,
              increment: existingValues?.properties?.increment ?? 1,
            }
          : {}),
        ...(type === 'DOCUMENT_NUMBER'
          ? {
              documentNumberFormat:
                existingValues?.properties?.documentNumberFormat ??
                'Ref-[number]',
            }
          : {}),

        ...(type === 'DATE_TIME' || type === 'INSPECTION_DATE'
          ? {
              date: existingValues?.properties?.date ?? true,
              time: existingValues?.properties?.time ?? true,
            }
          : {}),
        ...(type === 'MEDIA'
          ? {
              banner: existingValues?.properties?.banner ?? false,
            }
          : {}),
        // ...(type === 'DOCUMENTS'
        //   ? {
        //       documents: existingValues?.properties?.documents ?? [],
        //     }
        //   : {}),
        ...(type === 'TABLE'
          ? {
              tableData: existingValues?.properties?.tableData ?? [],
            }
          : {}),
      },
      ...(type === 'CHECKBOX'
        ? {
            score: +existingValues?.score ?? 1,
          }
        : type === 'SLIDER'
        ? {
            score:
              existingValues.score ?? existingValues?.properties?.max ?? 10,
          }
        : type === 'MCQs'
        ? {
            score:
              +existingValues.score ??
              +existingValues?.mcqsData?.options?.sort(
                (a: any, b: any) => b?.score - a?.score,
              )?.[0]?.score ??
              0,
          }
        : {
            score: undefined,
          }),
    };

    template.setValue(
      getFieldKey({pageIndex, questionIndex, sectionIndex}, 'question'),
      question,
    );

    setQuestionItemDetails({
      pageIndex,
      questionIndex,
      sectionIndex,
    });

    if (type === 'DOCUMENT_NUMBER') {
      DocNumberDrawer?.onOpen();
    }
    if (type === 'SLIDER') {
      SliderDrawer?.onOpen();
    }
  }
  return (
    <ItemContext.Provider
      value={{
        item,
        setItem,
        appendQuestion,
        updateQuestionType,
        questionsArr,
        appendSection,
      }}>
      {children}
    </ItemContext.Provider>
  );
}
export function useItemContext() {
  return React.useContext<ItemContext>(ItemContext);
}
