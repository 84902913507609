import {
  Box,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { MCQ } from "../../../../modules/Audit/MCQs";
import useDrawer from "../../hooks/drawersContext";
import MultipleChoiceResponseBody from "../MultipleChoiceResponseBody";
interface MultipleChoiceResponseProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function MultipleChoiceResponse({
  isOpen,
  onClose,
}: MultipleChoiceResponseProps) {
  const { multiChoiceOptions } = useDrawer();

  const multiResponseForm = useForm<MCQ.IMCQ>({
    defaultValues: multiChoiceOptions,
  });
  useEffect(() => {
    if (multiChoiceOptions) {
      multiResponseForm.reset(multiChoiceOptions);
    }
  }, [multiChoiceOptions]);

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      size={{ xs: "full", md: "md" }}
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent
        backgroundColor={"backgrounds.header"}
        overflowY="auto"
        overflowX="hidden"
      >
        <Stack direction="column" spacing={0}>
          <DrawerHeader
            padding={"24px"}
            borderBottom={"2px solid"}
            borderColor={"borders.accent"}
          >
            <Box>
              <Text fontSize="21px" fontWeight={500} color="textcolors.primary">
                Multiple choice responses
              </Text>
            </Box>
            <Stack
              margin="8px 0px 32px 0px"
              direction="row"
              justifyContent="space-between"
              fontSize="14px"
              fontWeight={400}
            >
              <Text color={"icons.primary"}>e.g. Yes, No, N/A</Text>
              <Controller
                control={multiResponseForm.control}
                name={"isScoringEnabled"}
                render={({ field }) => (
                  <Checkbox
                    textColor="textcolors.primary"
                    borderColor="icons.primary"
                    {...field}
                    value={undefined}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                    }}
                    isChecked={field.value}
                  >
                    Scoring
                  </Checkbox>
                )}
              />
            </Stack>
            <Box position="absolute" w="100%">
              <Text fontSize="13px" fontWeight={400} color="icons.primary">
                Response
              </Text>
            </Box>
          </DrawerHeader>

          <DrawerBody padding="0px" overflowX="hidden">
            <MultipleChoiceResponseBody
              multiResponseForm={multiResponseForm}
              onCancel={() => {
                multiResponseForm.reset(multiChoiceOptions);
                onClose();
              }}
            />
          </DrawerBody>

          {/* <DrawerFooter
              maxH=""
              padding="0px"
              backgroundColor={'backgrounds.header'}
              flexDirection="column">
              <Stack spacing={0} w="100%" direction="column"></Stack>
            </DrawerFooter> */}
        </Stack>
      </DrawerContent>
    </Drawer>
  );
}
