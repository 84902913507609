import {
  Box,
  Button,
  ButtonGroup,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Fragment, useCallback, useEffect, useState } from "react";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { MdAdd, MdDelete } from "react-icons/md";
import { useDebouncedCallback } from "use-debounce";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { Inspection } from "../../../modules/Audit";
import { InventoryModule } from "../../../modules/Audit/Inventory";
import { InventoryCategoryModule } from "../../../modules/Audit/InventoryCategories";
import UploadInventoryDrawer from "../../template/pages/MainPage-components/components/Inventory/UploadInventoryDrawer";
import ViewImageModal from "../../template/pages/report-components/Previews/WebPreview/Modals/ViewImageModal";
import { useInspectionContext } from "../hooks/InspectionContext";
import { useAuditQuestionContainer } from "./AuditQuestionContainer";
const CustomDropdown = ({
  children,
  innerRef,
  innerProps,
  inventoryDrawer,
}: any) => {
  return (
    <Box ref={innerRef} {...innerProps} bgColor="white" borderWidth="1px">
      <Button
        // size="md"
        fontSize="16px"
        w="100%"
        mt="2px"
        leftIcon={<MdAdd size="15px" />}
        iconSpacing="1px"
        variant={"outline"}
        border="0px"
        h={"25px"}
        textDecoration="underline"
        _hover={{
          bg: "transparent",
        }}
        px="12px"
        justifyContent={"flex-start"}
        onClick={() => inventoryDrawer.onOpen()}
      >
        Add Inventory
      </Button>

      {children}
    </Box>
  );
};
export default function InspectionTable() {
  const template = useFormContext();
  const [recalculateTotal, setRecalculateTotal] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(false);
  const { setIsSaving } = useInspectionContext();
  const { getItemField } = useAuditQuestionContainer();
  const getInputType = (type: any) => {
    switch (type) {
      case "text":
        return "text";
      case "text_area":
        return "textarea";
      case "date":
        return "date";
      case "number":
        return "number";
      case "INVENTORY":
        return "inventory";
      case "INVENTORY_ITEM_CODE":
        return "inventory_item_code";
      case "yes_no":
        return "yes_no";
      case "total":
        return "total";
      default:
        return "text";
    }
  };
  const properties = useWatch({
    control: template.control,
    name: getItemField("question.properties"),
  });
  const [inventoryData, setInventoryData] = useState<
    InventoryModule.FetchInventoryTypeResponse[]
  >([]);
  const [itemCategory, setItemCategory] = useState<
    Array<InventoryCategoryModule.FetchInventoryCategoryResponse>
  >([]);
  const toast = useToast();
  const YES_NO_OPTIONS: Array<{ label: string; value: string }> = [
    {
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
  ];
  const AnswersArray = useFieldArray({
    control: template.control,
    name: getItemField("question.questionValue"),
  });
  const totalColumns =
    properties?.tableData?.filter((item: any) => item.type === "total") || [];

  // Memoized function to calculate totals for performance optimization
  const calculateTotal = useCallback(
    (index: any, columnsToInclude: any) => {
      return columnsToInclude.reduce((sum: any, columnName: any) => {
        const columnValue = parseFloat(
          template.getValues(
            `${getItemField("question.questionValue")}.${index}.${columnName}`
          ) || 0
        );
        return sum + columnValue;
      }, 0);
    },
    [template, getItemField]
  );

  // Effect to update total fields based on watched values
  useEffect(() => {
    if (!Array.isArray(totalColumns) || totalColumns.length === 0) return;

    AnswersArray.fields.forEach((field, index) => {
      totalColumns.forEach((totalColumn) => {
        const total: any = calculateTotal(index, totalColumn.columnsToInclude);
        template.setValue(
          `${getItemField("question.questionValue")}.${index}.${
            totalColumn.name
          }`,
          total.toFixed(2)
        );
      });
    });
  }, [recalculateTotal]);
  useEffect(() => {
    const tableAnswersData = template.getValues(
      getItemField("question.questionValue")
    );
    if (tableAnswersData?.length > 0) {
      const tableData = JSON.parse(tableAnswersData);
      template.setValue(getItemField("question.questionValue"), tableData);
    }
    if (tableAnswersData.length === 0) {
      if (Array.isArray(properties?.tableData)) {
        AnswersArray?.append(
          properties?.tableData?.reduce((acc: any, item: any) => {
            acc[item.name] = "";
            return acc;
          }, {})
        );
      } else {
        toast({
          title: "Error",
          description: "Table Columns are not available",
          status: "error",
        });
      }
    }
  }, []);
  const findFieldNameByType = (type: any) => {
    return properties.tableData.find((item: any) => item.type === type)?.name;
  };
  const hasInventoryType = properties?.tableData?.some(
    (item: any) =>
      item.type === "INVENTORY" || item.type === "INVENTORY_ITEM_CODE"
  );
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  useEffect(() => {
    if (hasInventoryType) {
      InventoryModule.FetchInventory(
        {
          page: 1,
          limit: 2000,
        },
        (items) => {
          setInventoryData(items);
        },
        () => {
          toast({
            title: "Error",
            description: "Failed to fetch inventory items",
            status: "error",
          });
        }
      );
    }
  }, [lastUpdate]);
  useEffect(() => {
    if (hasInventoryType) {
      InventoryCategoryModule.FetchInventoryCategory(
        (data) => {
          setItemCategory(data);
        },
        (error) => {
          toast({
            title: "Error",
            description: "Failed to fetch inventory categories",
            status: "error",
          });
        }
      );
    }
  }, []);
  const inventoryDrawer = useDisclosure();
  const debouncedApiCall = useDebouncedCallback((value: any) => {
    setIsLoading(true);
    const StringifyTableData = JSON.stringify(
      template.getValues(getItemField("question.questionValue"))
    );
    template.setValue(
      getItemField("question.questionValue"),
      StringifyTableData
    );
    const answerId = template.getValues(getItemField("question.answerId"));
    const type = template.getValues(getItemField("question.type"));
    const inspectionMetaID = template.getValues("inspection.id");
    const notes = template.getValues(getItemField("question.notes"));
    Inspection.CachingInspection(
      answerId,
      {
        question_value: StringifyTableData,
        notes: notes,
        inspection_meta_id: inspectionMetaID,
        type: type,
      },
      () => {
        // toast({
        //   title: 'Success',
        //   description: 'Table data saved successfully',
        //   status: 'success',
        // });
        setIsSaving(false);
        setIsLoading(false);
        template.setValue(
          getItemField("question.questionValue"),
          JSON.parse(StringifyTableData)
        );
      },
      () => {
        setIsSaving(false);
        setIsLoading(false);
      }
    );
  }, 1000);
  const ImageModal = useDisclosure();
  const [selectedImage, setSelectedImage] = useState("");
  const handleImageClick = (imageUrl: any) => {
    setSelectedImage(imageUrl);
    ImageModal.onOpen();
  };
  return (
    <Fragment>
      <UploadInventoryDrawer
        isOpen={inventoryDrawer.isOpen}
        onClose={inventoryDrawer.onClose}
        setLastUpdate={setLastUpdate}
        inventoryTypes={itemCategory}
      />
      <ViewImageModal
        isOpen={ImageModal.isOpen}
        onClose={ImageModal.onClose}
        selectedImage={selectedImage}
      />
      {AnswersArray.fields.map((field, index) => (
        <Box
          px="12px"
          py="4px"
          borderWidth="1px"
          borderRadius="5px"
          borderColor="primary.300"
          mb="8px"
        >
          <HStack justifyContent="space-between" alignItems="center">
            {hasInventoryType && (
              <Box
                display={"flex"}
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                <Box w="70px" h="50px" display={"flex"} justifyContent="center">
                  <Box w="100%" h="100%" borderRadius="20%">
                    {template.getValues(
                      `${getItemField(
                        "question.questionValue"
                      )}.${index}.mediaUrl`
                    ) ? (
                      <Image
                        w={"100%"}
                        h={"100%"}
                        borderRadius="20%"
                        onClick={() => {
                          handleImageClick(
                            template.getValues(
                              `${getItemField(
                                "question.questionValue"
                              )}.${index}.mediaUrl`
                            )
                          );
                        }}
                        src={template.getValues(
                          `${getItemField(
                            "question.questionValue"
                          )}.${index}.mediaUrl`
                        )}
                      />
                    ) : (
                      <Box
                        w={"100%"}
                        h={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Text
                          fontSize="12px"
                          color={"rgb(55, 56, 87)"}
                          fontWeight={500}
                        >
                          No Image
                        </Text>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
            <Box flex={1}>
              <Wrap key={field.id} spacing="10px">
                {Array.isArray(properties?.tableData) &&
                  properties.tableData.map((item: any, itemIndex: number) => (
                    <WrapItem>
                      <Controller
                        key={itemIndex}
                        control={template.control}
                        name={`${getItemField(
                          "question.questionValue"
                        )}.${index}.${item.name}`}
                        render={({ field, fieldState }) => {
                          const inputType = getInputType(item.type);
                          return inputType === "textarea" ? (
                            <Box>
                              <FormLabel
                                fontSize="13px"
                                fontWeight={500}
                                color={"rgb(55, 56, 87)"}
                              >
                                {item.name}
                              </FormLabel>
                              <Textarea
                                {...field}
                                borderRadius="0px"
                                isInvalid={fieldState.invalid}
                                placeholder={item.name}
                                minH={"38px"}
                                onChange={(e: any) => {
                                  field.onChange(e.target.value);
                                  debouncedApiCall(e.target.value);
                                }}
                              />
                            </Box>
                          ) : inputType === "inventory" ? (
                            <Box minW="200px">
                              <ReactSelect
                                {...field}
                                placeholder="Search Item"
                                options={inventoryData}
                                value={inventoryData.find(
                                  (opt: any) => opt?.name === field.value
                                )}
                                onChange={(val: any) => {
                                  field.onChange(val?.name || "");
                                  debouncedApiCall(val?.name);
                                  const itemCodeFieldName = findFieldNameByType(
                                    "INVENTORY_ITEM_CODE"
                                  );
                                  template.setValue(
                                    `${getItemField(
                                      "question.questionValue"
                                    )}.${index}.mediaUrl`,
                                    val?.mediaUrl || ""
                                  );
                                  if (itemCodeFieldName) {
                                    template.setValue(
                                      `${getItemField(
                                        "question.questionValue"
                                      )}.${index}.${itemCodeFieldName}`,
                                      val ? val.id : ""
                                    );
                                  }
                                }}
                                components={{
                                  Menu: (props: any) => (
                                    <CustomDropdown
                                      {...props}
                                      inventoryDrawer={inventoryDrawer}
                                    />
                                  ),
                                }}
                                getOptionLabel={(option: any) => option.name}
                                getOptionValue={(option: any) => option.name}
                                label={item.name}
                              />
                            </Box>
                          ) : inputType === "inventory_item_code" ? (
                            <Box minW="200px">
                              <ReactSelect
                                {...field}
                                placeholder="Search Item Code"
                                options={inventoryData}
                                // value={inventoryData.find(
                                //   (opt: any) => opt?.id === field.value,
                                // )}
                                value={
                                  field.value
                                    ? {
                                        id: field.value,
                                        name: inventoryData.find(
                                          (opt: any) => opt?.id === field.value
                                        )?.name,
                                      }
                                    : null
                                }
                                onChange={(val: any) => {
                                  field.onChange(val?.id || "");
                                  debouncedApiCall(val?.id);
                                  template.setValue(
                                    `${getItemField(
                                      "question.questionValue"
                                    )}.${index}.mediaUrl`,
                                    val?.mediaUrl || ""
                                  );
                                  const itemNameFieldName =
                                    findFieldNameByType("INVENTORY");
                                  if (itemNameFieldName) {
                                    template.setValue(
                                      `${getItemField(
                                        "question.questionValue"
                                      )}.${index}.${itemNameFieldName}`,
                                      val ? val.name : ""
                                    );
                                  }
                                }}
                                getOptionLabel={(option: any) =>
                                  `${option.id} - ${option.name}`
                                }
                                getOptionValue={(option: any) => option.id}
                                components={{
                                  Menu: (props: any) => (
                                    <CustomDropdown
                                      {...props}
                                      inventoryDrawer={inventoryDrawer}
                                    />
                                  ),
                                }}
                                label={item.name}
                              />
                            </Box>
                          ) : inputType === "yes_no" ? (
                            <Box minW="100px">
                              <ReactSelect
                                {...field}
                                placeholder="Select..."
                                options={YES_NO_OPTIONS}
                                value={YES_NO_OPTIONS.find(
                                  (option) => option.value === field.value
                                )}
                                onChange={(value: any) => {
                                  field.onChange(value.value);
                                  debouncedApiCall(value.value);
                                }}
                                label={item.name}
                                getOptionLabel={(option: any) => option.label}
                                getOptionValue={(option: any) => option.value}
                              />
                            </Box>
                          ) : inputType === "total" ? (
                            <Box minW="80px">
                              <CustomInput
                                input={{
                                  ...field,
                                  variant: "outline",
                                  isInvalid: fieldState.invalid,
                                  value: field.value,
                                  isDisabled: true,
                                  type: "number",
                                  minW: "120px",
                                  onChange: (e: any) => {
                                    field.onChange(e.target.value);
                                    debouncedApiCall(e.target.value);
                                  },
                                }}
                                placeholder={item.name}
                                label={item.name}
                              />
                            </Box>
                          ) : inputType === "number" ? (
                            <CustomInput
                              input={{
                                ...field,
                                variant: "outline",
                                isInvalid: fieldState.invalid,
                                value: field.value,
                                type: "number",
                                minW: "80px",
                                onChange: (e: any) => {
                                  field.onChange(e.target.value);
                                  setRecalculateTotal(Date.now());
                                  debouncedApiCall(e.target.value);
                                },
                              }}
                              placeholder={item.name}
                              label={item.name}
                            />
                          ) : (
                            <CustomInput
                              input={{
                                ...field,
                                variant: "outline",
                                isInvalid: fieldState.invalid,
                                value: field.value,
                                type: inputType,
                                minW: "80px",
                                onChange: (e: any) => {
                                  field.onChange(e.target.value);
                                  debouncedApiCall(e.target.value);
                                },
                              }}
                              placeholder={item.name}
                              label={item.name}
                            />
                          );
                        }}
                      />
                    </WrapItem>
                  ))}
              </Wrap>
            </Box>
            <IconButton
              icon={<MdDelete size="20px" />}
              aria-label={"delete"}
              color={"#000000"}
              _hover={{
                color: "#c00",
                bg: "transparent",
              }}
              backgroundColor="transparent"
              padding={"0px"}
              onClick={() => {
                AnswersArray.remove(index);
                debouncedApiCall(
                  template.getValues(getItemField("question.questionValue"))
                );
              }}
            />
          </HStack>
        </Box>
      ))}
      <HStack justifyContent="space-between" mt="20px">
        <Button
          h={"21px"}
          padding={"3px 7px 2px 5px"}
          cursor={"pointer"}
          // marginRight={"5px"}
          color={"#048fc2"}
          size={"sm"}
          fontSize={"11px"}
          fontWeight={"700"}
          textAlign={"center"}
          // whiteSpace={"nowrap"}
          border={"1px solid #e6e6e6"}
          borderRadius={"4px"}
          bg={"#fff"}
          _hover={{
            color: "#005e7b",
            bg: "#fff",
          }}
          onClick={() => {
            if (Array.isArray(properties.tableData)) {
              AnswersArray.append(
                properties.tableData.reduce((acc: any, item: any) => {
                  acc[item.name] = "";
                  return acc;
                }, {})
              );
            } else {
              toast({
                title: "Error",
                description: "Table Columns are not available",
                status: "error",
              });
            }
          }}
        >
          + Add a Row
        </Button>
        <ButtonGroup spacing={"4px"}>
          {/* {hasInventoryType && (
            <Button
              isLoading={isLoading}
              h="40px"
              w={'auto'}
              borderRadius="5px"
              fontSize="14px"
              fontWeight={500}
              padding={'12px 20px'}
              onClick={() => {
                inventoryDrawer.onOpen();
              }}>
              Add Inventory
            </Button>
          )} */}

          {/* <Button
            isLoading={isLoading}
            h="40px"
            w={'auto'}
            borderRadius="5px"
            fontSize="14px"
            fontWeight={500}
            marginRight="16px"
            padding={'12px 20px'}
            // onClick={() => {
            //   console.log(
            //     template.getValues(getItemField('question.questionValue')),
            //   );
            // }}
            onClick={event => {}}>
            Save Changes
          </Button> */}
        </ButtonGroup>
      </HStack>
    </Fragment>
  );
}
