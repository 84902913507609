import axiosInstance from "./axiosInstance-aud";
import { Common } from "./Common";

declare module Template {
  type QuestionType =
    | "TEXT_ANSWER"
    | "NUMBER"
    | "CHECKBOX"
    | "DATE_TIME"
    | "MEDIA"
    | "SLIDER"
    | "ANNOTATION"
    | "SIGNATURE"
    | "LOCATION"
    | "MULTICHOICE_RADIO"
    | "MULTICHOICE_CHECKBOX"
    | "DOCUMENT_NUMBER"
    | "INSPECTION_DATE"
    | "INSPECTION_LOCATION"
    | "INSTRUCTIONS"
    | "SITE"
    | "PERSON"
    | "MCQs"
    | "TABLE";
  // | 'DOCUMENTS';

  interface Template {
    template: TemplateMeta;
    templateItems: TemplateItem[];
    deletedItems: DeletedItemObject[];
    archive?: boolean;
    templateMedia: templateDocs[];
  }

  interface TemplateMeta {
    title: string;
    description: string;
    image: string;
    businessId: number;
    createdBy: number;
    totalScore?: number;
    published: boolean;
    id?: number;
    archive?: boolean;
    inductionTemplate?: boolean;
    warehouseTemplate?: boolean;
  }

  type TemplateItemType = "PAGE" | "SECTION" | "QUESTION";

  interface TemplateItem {
    title: string;
    officeUse?: boolean;
    type: TemplateItemType; // ['PAGE', 'SECTION', 'QUESTION']
    orderIndex: number;
    status: boolean;
    children: TemplateItem[]; // Children of each item
    question?: Question;
    id?: number;
  }

  type QuestionFormat = "SHORT" | "PARAGRAPH" | "NUMBER" | "TEMP";
  interface Question {
    widgetId: number; // will be fetched from DB
    format: QuestionFormat; // TEXT_ANSWER -> SHORT, PARAGRAPH, NUMBER -> NUMBER, TEMPERATURE
    required: boolean;
    includeTimestamp: boolean;
    type: QuestionType;
    properties?: QuestionProperties;
    mcqsData?: {
      id: number;
      name: string;
      isGlobal: boolean;
      multiSelect: boolean;
      options: Array<MultiChoiceOption>;
    };
    id?: number;
    score?: string;
    notes?: string;
    media?: Array<MediaObject>;
    deletedMedia?: Array<number>;
  }
  type duplicateTemplate = {
    templateId: number;
  };

  type MultiChoiceOption = {
    id: number;
    value: string;
    color: string;
    multiChoiceResponseId: number;
    score?: number;
    orderIndex: number;
    flagged?: boolean;
  };

  type MediaObject = {
    mediaUrl?: string;
    mediaName: string;
    generic: boolean;
    mediaType: string;
    base64?: string;
    id?: number;
  };

  type QuestionConditions = "BETWEEN" | "GREATER_AND_EQUAL" | "LESS_AND_EQUAL";

  type QuestionProperties = {
    condition?: QuestionConditions;
    banner?: string;
    greater?: number;
    smaller?: number;
    dUnit?: string;
    min?: number;
    max?: number;
    increment?: number;
    date?: "true" | "false";
    time?: "true" | "false";
    tableData?: Array<{
      name: string;
      type: string;
      computeTotal?: boolean;
      columnsToInclude?: Array<string>;
    }>;
  };

  type DeletedItemObject = {
    id: number;
    type: TemplateItemType;
  };
  type templateDocs = {
    tempId: number;
    docUrl: string;
    fileName: string;
    id?: number;
  };
}
module Template {
  const API_BASE_URL = "/global-template";
  export function Fetch(
    success: (template: Array<Template>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(API_BASE_URL)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function Create(
    data: Template,
    success: (template: Template) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(API_BASE_URL, data)
      .then(({ data }) => {
        const response = data as Common.Success<Template>;
        success(response.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function CreateDuplicate(
    data: duplicateTemplate,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post("/global-template/duplicate", data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function GetById(
    id: number,
    success: (template: Template) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_BASE_URL}/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function GetByIdToEdit(
    id: number,
    success: (template: Template) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_BASE_URL}/edit/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function Update(
    id: number,
    data: Partial<Template>,
    success: (template: Template) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_BASE_URL}/${id}`, data)
      .then(({ data }) => {
        const response = data as Common.Success<Template>;
        success(response.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function Delete(
    id: number,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_BASE_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export const Question_INIT: Template.TemplateItem = {
    type: "QUESTION",
    children: [],
    orderIndex: 0,
    status: false,
    title: "",
    question: {
      type: "TEXT_ANSWER",
      widgetId: 3,
      format: "SHORT",
      required: false,
      includeTimestamp: false,
    },
  };
  export const Section_INIT: Template.TemplateItem = {
    type: "SECTION",
    children: [Question_INIT],
    orderIndex: 0,
    status: false,
    title: "New Section",
  };
  export const PAGE_INIT: Template.TemplateItem = {
    title: "Title Page",
    orderIndex: 0,
    status: false,
    type: "PAGE",
    children: [],
    officeUse: false,
  };

  export const QUESTION_TYPES: {
    [key in Template.QuestionType]: {
      title: string;
      format?: Template.QuestionFormat;
      type: Template.QuestionType;
      otherFormats?: { [key: string]: { label: string; value: string } };
    };
  } = {
    TEXT_ANSWER: {
      title: "Text Answer",
      format: "SHORT",
      type: "TEXT_ANSWER",
      otherFormats: {
        PARAGRAPH: {
          label: "Paragraph",
          value: "PARAGRAPH",
        },

        SHORT: {
          label: "Short",
          value: "SHORT",
        },
      },
    },
    NUMBER: {
      title: "Number",
      format: "NUMBER",
      type: "NUMBER",
      otherFormats: {
        NUMBER: {
          label: "Number",
          value: "NUMBER",
        },
        TEMP: {
          label: "Temperature",
          value: "TEMP",
        },
      },
    },
    CHECKBOX: {
      title: "Checkbox",
      type: "CHECKBOX",
    },
    DATE_TIME: {
      title: "Date & Time",
      type: "DATE_TIME",
    },
    MEDIA: {
      title: "Media",
      type: "MEDIA",
    },
    // DOCUMENTS: {
    //   title: 'Documents',
    //   type: 'DOCUMENTS',
    // },
    SLIDER: {
      title: "Slider",
      type: "SLIDER",
    },
    ANNOTATION: {
      title: "Annotation",
      type: "ANNOTATION",
    },
    SIGNATURE: {
      title: "Signature",
      type: "SIGNATURE",
    },
    LOCATION: {
      title: "Location",
      type: "LOCATION",
    },
    MULTICHOICE_RADIO: {
      title: "Multiple Choice (Radio)",
      type: "MULTICHOICE_RADIO",
    },
    MULTICHOICE_CHECKBOX: {
      title: "Multiple Choice (Checkbox)",
      type: "MULTICHOICE_CHECKBOX",
    },
    DOCUMENT_NUMBER: {
      title: "Document Number",
      type: "DOCUMENT_NUMBER",
    },

    MCQs: {
      title: "Global Response",
      type: "MCQs",
    },
    INSPECTION_DATE: {
      title: "Inspection Date",
      type: "INSPECTION_DATE",
    },
    INSPECTION_LOCATION: {
      title: "Inspection Location",
      type: "INSPECTION_LOCATION",
    },
    INSTRUCTIONS: {
      title: "Instructions",
      type: "INSTRUCTIONS",
    },
    PERSON: {
      title: "Person",
      type: "PERSON",
    },
    SITE: {
      title: "Site",
      type: "SITE",
    },
    TABLE: {
      title: "Table",
      type: "TABLE",
    },
  };

  export const QUESTION_CONDITIONS: {
    [key in Template.QuestionConditions]: {
      label: string;
      value: Template.QuestionConditions;
    };
  } = {
    BETWEEN: {
      label: "Between",
      value: "BETWEEN",
    },
    GREATER_AND_EQUAL: {
      label: "Greater than or equal to",
      value: "GREATER_AND_EQUAL",
    },
    LESS_AND_EQUAL: {
      label: "Less than or equal to",
      value: "LESS_AND_EQUAL",
    },
  };
}

export { Template };
