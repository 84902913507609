import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
  useDisclosure,
  useToast,
  Wrap,
} from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import {
  FaFileAlt,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
} from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { BusinessDocumentsApi } from "../../../Api/BusinessDocumentsApi";
import UploadModal from "./Components/UploadModal";
interface BusinessDocumentsProps {
  selectedTab: number;
  setSelectedTab: any;
}
const fileIconMapping: Record<string, any> = {
  pdf: FaFilePdf,
  doc: FaFileWord,
  docx: FaFileWord,
  xls: FaFileExcel,
  xlsx: FaFileExcel,
  jpg: FaFileImage,
  jpeg: FaFileImage, // Added support for JPEG
  png: FaFileImage,
  pptx: FaFileAlt, // Example: You can replace this with a specific PPT icon if available
  default: FaFileAlt, // Fallback icon
};
export default function BusinessDocuments({
  selectedTab,
  setSelectedTab,
}: BusinessDocumentsProps) {
  const documentUpload = useDisclosure();
  const [allDocuments, setAllDocuments] = useState<any>();
  const [lastUpdate, setLastUpdate] = useState<any>();
  const toast = useToast();
  useEffect(() => {
    BusinessDocumentsApi.GetBusinessDocuments(
      (response) => {
        setAllDocuments(response);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, [lastUpdate]);
  const getIconForFileType = (fileUrl: string) => {
    // Extract file extension from URL
    const extensionMatch = fileUrl.match(/\.([a-zA-Z0-9]+)(\?|$)/);
    const extension = extensionMatch?.[1]?.toLowerCase() || "default";
    return fileIconMapping[extension] || fileIconMapping.default;
  };
  return (
    <Fragment>
      <UploadModal
        isOpen={documentUpload.isOpen}
        onClose={documentUpload.onClose}
        setLastUpdate={setLastUpdate}
      />
      <Stack
        marginTop={"15px"}
        p="0px"
        // maxW="940px"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        // border="1px solid #d6dade"
        // borderRadius="md"
        marginBottom={"1.25rem"}
        border={"1px solid #f6f6f6"}
        borderRadius="4px"
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          p="0px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          // border="1px solid #d6dade"
          // borderRadius="md"
        >
          <HStack gap={0} padding="20px">
            <Heading
              textAlign="left"
              fontSize=".9975rem"
              fontWeight="bold"
              _light={{
                color: "customColor.black7",
              }}
              as="h2"
              flex={1}
              size="md"
            >
              Business Documents
            </Heading>
            <Button
              onClick={() => {
                documentUpload.onOpen();
              }}
            >
              Upload Document
            </Button>
          </HStack>

          <Divider orientation="horizontal" />
          <Box padding="20px">
            <Wrap spacing="30px">
              {/* <Link
                href="#" // Set your target URL here
                display="-webkit-box"
                maxW="100px" // Adjust width as needed
                height="50px" // Adjust height as needed
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="normal"
                sx={{
                  WebkitLineClamp: 2, // Controls the number of lines after which text will be truncated
                  WebkitBoxOrient: "vertical",
                }}
                title="Document 1 shj sjshjs shksk"
              >
                Document 1 shj sjshjs shksk
              </Link> */}
              {allDocuments?.map((document: any, i: any) => {
                const IconComponent = getIconForFileType(document.fileUrl);
                return (
                  <Flex key={i}>
                    <Flex
                      flexDirection={"column"}
                      alignItems="center"
                      w="100px"
                      onClick={() => {
                        window.open(document.fileUrl, "_blank");
                      }}
                      cursor="pointer"
                    >
                      <Icon
                        as={IconComponent}
                        color="gray.500"
                        boxSize="24px"
                      />
                      <Text
                        w="100px"
                        height="50px"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="normal"
                        textAlign={"center"}
                        title={document.fileName}
                        sx={{
                          display: "-webkit-box",
                          WebkitLineClamp: 2, // Assuming each line approximately takes up 25px
                          WebkitBoxOrient: "vertical",
                          lineHeight: "tight", // This adjusts the line height to fit better within the container
                        }}
                      >
                        {document.fileName}
                      </Text>
                    </Flex>
                    <Icon
                      color="red"
                      cursor="pointer"
                      onClick={() => {
                        BusinessDocumentsApi.DeleteDocument(
                          document.id,
                          (message) => {
                            toast({
                              title: "Document deleted",
                              status: "success",
                              description: "Document deleted successfully",
                            });
                            setLastUpdate(Date.now());
                          },
                          (error) => {
                            toast({
                              title: "Error",
                              description: error,
                              status: "error",
                            });
                          }
                        );
                      }}
                      as={MdDelete}
                    />
                  </Flex>
                );
              })}
            </Wrap>
          </Box>
        </Box>
        <Stack alignItems={"flex-end"} margin={"20px 20px 20px 0px"}>
          <Button
            colorScheme={"green"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"customColor.white"}
            padding={"12px 16px"}
            marginRight={"5px"}
            fontWeight={600}
            onClick={() => setSelectedTab(selectedTab + 1)}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </Fragment>
  );
}
