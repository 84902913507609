import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useBoolean,
  useBreakpointValue,
  useDisclosure,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { AiOutlineSearch } from "react-icons/ai";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { ReactSelect } from "../../../../../../../Common/ReactSelect";
import { Template, TemplateMeta } from "../../../../../../modules/Audit";
import { ReportLayout } from "../../../../../../modules/Audit/ReportLayout";
import Group from "../../../access-components/Popovers/Group";
import Filter from "../Filter";
import LgTable from "./lgTable";
import SmTable from "./smTable";
import TemplateDrawer from "./TemplateDrawer";

export default function MainTemplate() {
  const GroupDisclosure = useDisclosure();
  const navigate = useNavigate();
  const [isCreatingTemplate, setIsCreatingTemplate] = useState(false);
  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const [templatesList, setTemplatesList] = useState<
    Array<TemplateMeta.TemplateMeta>
  >([]);
  const [totalTemplates, setTotalTemplate] = useState<number>(0);
  const [isLoading, setIsLoading] = useBoolean(true);
  const [auditTemplate, setAuditTemplate] = useState<
    TemplateMeta.TemplateMeta | undefined
  >();
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState<number>(25);
  const searchedTemplates = useCallback(() => {
    return templatesList?.filter((temp) =>
      temp.title.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, templatesList]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const futureImplemetation = true;
  const TemplateType: Array<{ label: string; value?: string }> = [
    {
      label: "All",
      value: undefined,
    },
    {
      label: "Audit",
      value: "audit",
    },
    {
      label: "Induction",
      value: "induction",
    },
    {
      label: "Warehouse",
      value: "warehouse",
    },
  ];
  const [templateType, setTemplateType] = useState<string | undefined>(
    undefined
  );
  const TableSize = useBreakpointValue({
    xs: (
      <SmTable
        onRowClick={(temp) => {
          templatedrawer.onOpen();
          setAuditTemplate(temp);
        }}
        data={searchedTemplates()}
        isLoading={isLoading}
      />
    ),
    md: (
      <LgTable
        onRowClick={(temp) => {
          templatedrawer.onOpen();
          setAuditTemplate(temp);
        }}
        data={searchedTemplates()}
        isLoading={isLoading}
        futureImplemetation={futureImplemetation}
        setLastUpdate={setLastUpdate}
      />
    ),
  });
  const CreateButton = useBreakpointValue({
    xs: "Create Template",
    md: "Create",
  });
  const templatedrawer = useDisclosure();

  const toast = useToast();
  const pageCount = Math.ceil(totalTemplates / limit);
  const user = useAuthUser();
  const UserDetail = user();
  const debounced = useDebouncedCallback((value) => {
    setSearchText(value);
  }, 1000);
  useEffect(() => {
    TemplateMeta.Fetch(
      {
        page: pageNumber,
        limit: limit,
        archive: false,
        search: searchText,
        templateType: templateType,
      },
      (data, total) => {
        setTemplatesList(data);
        setTotalTemplate(total);
        setIsLoading.off();

        // Employees.FetchEmployees(
        //   emps => {
        //     const decodedToken = jwtDecode<any>(user?.token!);

        //     setEmployees([
        //       ...(emps ?? []),
        //       {
        //         access: 'Admin',
        //         contractor: false,
        //         email: user?.email!,
        //         fullyTrained: true,
        //         id: decodedToken.employeeId,
        //         isActive: true,
        //         phone: '',
        //         position: 'full-time',
        //         preferredTitle: user?.title!,
        //         registered: true,
        //       },
        //     ]);
        //     setIsLoading.off();
        //   },
        //   error => {
        //     toast({
        //       title: 'Error',
        //       description: error,
        //       status: 'error',
        //     });
        //     setIsLoading.off();
        //   },
        // );
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
        setIsLoading.off();
      }
    );
  }, [pageNumber, lastUpdate, searchText, templateType]);

  return (
    <Fragment>
      <TemplateDrawer
        isOpen={templatedrawer.isOpen}
        onClose={templatedrawer.onClose}
        // temp={auditTemplate}
        temp={auditTemplate}
        futureImplemetation={futureImplemetation}
        setLastUpdate={setLastUpdate}
      />
      <Stack px={{ sm: "0px", md: "32px", xl: "50px" }} py="32px">
        <HStack px={{ xs: "16px", md: "0px" }}>
          <Text fontSize="16px" color="icons.primary">
            Templates
          </Text>
          <Text fontSize="13px" color="icons.primary">
            {`(${limit * (pageNumber - 1) + 1}-${
              templatesList.length + limit * (pageNumber - 1)
            } of ${totalTemplates})`}
          </Text>
        </HStack>
        <Stack
          px={{ xs: "16px", md: "0px" }}
          // justifyContent="space-between"
          direction={{ xs: "column", md: "row" }}
        >
          <Stack direction={{ xs: "column", md: "row" }} flex={1}>
            <Stack>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<AiOutlineSearch color="#545f70" size="18px" />}
                />
                <Input
                  minW={{ xs: "100%", md: "400px" }}
                  placeholder="Search template name"
                  border="1px solid"
                  borderColor="borders.accent"
                  fontSize="16px"
                  lineHeight="1.5rem"
                  fontWeight={400}
                  color="textcolors.primary"
                  py="8px"
                  pl="38px"
                  _focusVisible={{
                    boxShadow: "none",
                    border: "1px sloid",
                    borderColor: "primary.500",
                  }}
                  onChange={(e) => {
                    debounced(e.target.value);
                  }}
                />
              </InputGroup>
            </Stack>
            <Stack marginTop={{ xs: "0px", md: "-27px" }} w={"250px"}>
              <ReactSelect
                placeholder="Template Type"
                options={TemplateType}
                value={
                  TemplateType.find((op) => op.value === templateType) ||
                  TemplateType[0]
                }
                onChange={(newValue: any) => {
                  setTemplateType(newValue?.value);
                }}
                label="Template Type"
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            </Stack>
            {futureImplemetation ?? (
              <HStack flex={1}>
                <Wrap spacing="8px">
                  <WrapItem>
                    <Filter
                      Label="Available to:"
                      Body={<Group />}
                      isOpen={GroupDisclosure.isOpen}
                      onClose={GroupDisclosure.onClose}
                      onClick={GroupDisclosure.onToggle}
                    />
                  </WrapItem>
                </Wrap>
                <Menu>
                  <MenuButton
                    as={Button}
                    h="41px"
                    minW="110px"
                    variant="ghost"
                    px="20px"
                    py="12px"
                    fontWeight={500}
                    iconSpacing={0.5}
                    color="primary.500"
                    borderRadius="8px"
                  >
                    + Add filter
                  </MenuButton>
                  <MenuList minW="160px">
                    {["Owned by", "Available to"].map((menu, i) => {
                      return (
                        <Fragment>
                          <MenuItem
                            px="14px"
                            py="9.6px"
                            fontSize="14px"
                            fontWeight={400}
                            color="textcolors.primary"
                          >
                            {menu}
                          </MenuItem>
                        </Fragment>
                      );
                    })}
                  </MenuList>
                </Menu>
              </HStack>
            )}
          </Stack>
          {/* <Button
            h="41px"
            iconSpacing={1}
            leftIcon={<AiOutlinePlus />}
            px="16px"
            py="8px"
            fontSize="14px"
            fontWeight={400}
            lineHeight="22px"
            borderRadius="8px"
            isLoading={isCreatingTemplate}
            loadingText="Generating Template"
            onClick={() => {
              setIsCreatingTemplate(true);
              Template.Create(
                {
                  template: {
                    businessId: 0,
                    createdBy: 0,
                    description: '',
                    title: 'New Template',
                    published: false,
                    image: '',
                    totalScore: 0,
                  },
                  templateItems: [
                    {
                      title: 'Introduction Page',
                      orderIndex: 0,
                      status: false,
                      type: 'PAGE',
                      children: [
                        {
                          orderIndex: 0,
                          children: [],
                          status: false,

                          title: '',
                          type: 'QUESTION',
                          question: {
                            widgetId: 3,
                            type: 'TEXT_ANSWER',
                            format: 'SHORT',
                            required: false,
                          },
                        },
                      ],
                    },
                  ],
                  deletedItems: [],
                  templateMedia: [],
                },
                template => {
                  setIsCreatingTemplate(false);
                  navigate(
                    `/app/auditor/template-edit/${template.template.id}/?tab=Build`,
                  );
                  ReportLayout.postReportLayout(
                    {
                      active: true,
                      name: 'Untitled Layout',
                      templateId: Number(template.template.id),
                      layout: {
                        coverPage: '',
                        logo: '',
                        action: true,
                        checkboxes: true,
                        disclaimer: true,
                        questionDivider: true,
                        flaggedItems: true,
                        footer: {
                          addFooter: true,
                          additionalInfo: true,
                          image: '',
                          inspectionTitle: true,
                          notes: '',
                          showPicture: true,
                        },
                        header: {
                          addHeader: true,
                          image: '',
                          showPicture: true,
                        },
                        inspectionResults: true,
                        instructions: true,
                        mediaSummary: true,
                        scores: true,
                        tableOfContents: false,
                        unansweredQuestions: true,
                      },
                      // reportStructure: restructureTemplateItems(...... templateitem....) ,
                    },
                    () => {},
                    err => {
                      toast({
                        description: err,
                        title: 'Error',
                        status: 'error',
                      });
                    },
                  );
                },
                error => {
                  setIsCreatingTemplate(false);
                  toast({
                    title: 'Error',
                    description: error,
                    position: 'top',
                    status: 'error',
                  });
                },
              );
            }}>
            {CreateButton}
          </Button> */}
          <Menu>
            <MenuButton
              as={Button}
              h="41px"
              iconSpacing={1}
              px="16px"
              py="8px"
              fontSize="14px"
              fontWeight={400}
              lineHeight="22px"
              borderRadius="8px"
              isLoading={isCreatingTemplate}
              loadingText="Generating Template"
              rightIcon={<ChevronDownIcon />}
            >
              {CreateButton}
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  setIsCreatingTemplate(true);
                  Template.Create(
                    {
                      template: {
                        businessId: 0,
                        createdBy: 0,
                        inductionTemplate: false,
                        description: "",
                        title: "New Template",
                        published: false,
                        image: "",
                        totalScore: 0,
                      },
                      templateItems: [
                        {
                          title: "Introduction Page",
                          orderIndex: 0,
                          status: false,
                          type: "PAGE",
                          officeUse: false,
                          children: [
                            {
                              orderIndex: 0,
                              children: [],
                              status: false,

                              title: "",
                              type: "QUESTION",
                              question: {
                                widgetId: 3,
                                type: "TEXT_ANSWER",
                                format: "SHORT",
                                required: false,
                                includeTimestamp: false,
                              },
                            },
                          ],
                        },
                      ],
                      deletedItems: [],
                      templateMedia: [],
                    },
                    (template) => {
                      setIsCreatingTemplate(false);
                      navigate(
                        `/app/auditor/template-edit/${template.template.id}/?tab=Build`
                      );
                      ReportLayout.postReportLayout(
                        {
                          active: true,
                          name: "Untitled Layout",
                          templateId: Number(template.template.id),
                          layout: {
                            coverPage: "",
                            logo: "",
                            action: true,
                            checkboxes: true,
                            disclaimer: true,
                            questionDivider: true,
                            flaggedItems: true,
                            footer: {
                              addFooter: true,
                              additionalInfo: true,
                              image: "",
                              inspectionTitle: true,
                              notes: "",
                              showPicture: true,
                            },
                            header: {
                              addHeader: true,
                              image: "",
                              showPicture: true,
                            },
                            inspectionResults: true,
                            instructions: true,
                            mediaSummary: true,
                            scores: true,
                            tableOfContents: false,
                            unansweredQuestions: true,
                          },
                          // reportStructure: restructureTemplateItems(...... templateitem....) ,
                        },
                        () => {},
                        (err) => {
                          toast({
                            description: err,
                            title: "Error",
                            status: "error",
                          });
                        }
                      );
                    },
                    (error) => {
                      setIsCreatingTemplate(false);
                      toast({
                        title: "Error",
                        description: error,
                        position: "top",
                        status: "error",
                      });
                    }
                  );
                }}
              >
                Inspection Template
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setIsCreatingTemplate(true);
                  Template.Create(
                    {
                      template: {
                        businessId: 0,
                        createdBy: 0,
                        inductionTemplate: true,
                        description: "",
                        title: "New Template",
                        published: false,
                        image: "",
                        totalScore: 0,
                      },
                      templateItems: [
                        {
                          title: "Introduction Page",
                          orderIndex: 0,
                          status: false,
                          type: "PAGE",
                          officeUse: false,
                          children: [
                            {
                              orderIndex: 0,
                              children: [],
                              status: false,

                              title: "",
                              type: "QUESTION",
                              question: {
                                widgetId: 3,
                                type: "TEXT_ANSWER",
                                format: "SHORT",
                                required: false,
                                includeTimestamp: false,
                              },
                            },
                          ],
                        },
                      ],
                      deletedItems: [],
                      templateMedia: [],
                    },
                    (template) => {
                      setIsCreatingTemplate(false);
                      navigate(
                        `/app/auditor/template-edit/${template.template.id}/?tab=Build`
                      );
                      ReportLayout.postReportLayout(
                        {
                          active: true,
                          name: "Untitled Layout",
                          templateId: Number(template.template.id),
                          layout: {
                            coverPage: "",
                            logo: "",
                            action: true,
                            checkboxes: true,
                            disclaimer: true,
                            questionDivider: true,
                            flaggedItems: true,
                            footer: {
                              addFooter: true,
                              additionalInfo: true,
                              image: "",
                              inspectionTitle: true,
                              notes: "",
                              showPicture: true,
                            },
                            header: {
                              addHeader: true,
                              image: "",
                              showPicture: true,
                            },
                            inspectionResults: true,
                            instructions: true,
                            mediaSummary: true,
                            scores: true,
                            tableOfContents: false,
                            unansweredQuestions: true,
                          },
                          // reportStructure: restructureTemplateItems(...... templateitem....) ,
                        },
                        () => {},
                        (err) => {
                          toast({
                            description: err,
                            title: "Error",
                            status: "error",
                          });
                        }
                      );
                    },
                    (error) => {
                      setIsCreatingTemplate(false);
                      toast({
                        title: "Error",
                        description: error,
                        position: "top",
                        status: "error",
                      });
                    }
                  );
                }}
              >
                Induction Template
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setIsCreatingTemplate(true);
                  Template.Create(
                    {
                      template: {
                        businessId: 0,
                        createdBy: 0,
                        inductionTemplate: false,
                        warehouseTemplate: true,
                        description: "",
                        title: "New Template",
                        published: false,
                        image: "",
                        totalScore: 0,
                      },
                      templateItems: [
                        {
                          title: "Introduction Page",
                          orderIndex: 0,
                          status: false,
                          type: "PAGE",
                          officeUse: false,
                          children: [
                            {
                              orderIndex: 0,
                              children: [],
                              status: false,

                              title: "",
                              type: "QUESTION",
                              question: {
                                widgetId: 3,
                                type: "TEXT_ANSWER",
                                format: "SHORT",
                                required: false,
                                includeTimestamp: false,
                              },
                            },
                          ],
                        },
                      ],
                      deletedItems: [],
                      templateMedia: [],
                    },
                    (template) => {
                      setIsCreatingTemplate(false);
                      navigate(
                        `/app/auditor/template-edit/${template.template.id}/?tab=Build`
                      );
                      ReportLayout.postReportLayout(
                        {
                          active: true,
                          name: "Untitled Layout",
                          templateId: Number(template.template.id),
                          layout: {
                            coverPage: "",
                            logo: "",
                            action: true,
                            checkboxes: true,
                            disclaimer: true,
                            questionDivider: true,
                            flaggedItems: true,
                            footer: {
                              addFooter: true,
                              additionalInfo: true,
                              image: "",
                              inspectionTitle: true,
                              notes: "",
                              showPicture: true,
                            },
                            header: {
                              addHeader: true,
                              image: "",
                              showPicture: true,
                            },
                            inspectionResults: true,
                            instructions: true,
                            mediaSummary: true,
                            scores: true,
                            tableOfContents: false,
                            unansweredQuestions: true,
                          },
                          // reportStructure: restructureTemplateItems(...... templateitem....) ,
                        },
                        () => {},
                        (err) => {
                          toast({
                            description: err,
                            title: "Error",
                            status: "error",
                          });
                        }
                      );
                    },
                    (error) => {
                      setIsCreatingTemplate(false);
                      toast({
                        title: "Error",
                        description: error,
                        position: "top",
                        status: "error",
                      });
                    }
                  );
                }}
              >
                Warehouse Template
              </MenuItem>
            </MenuList>
          </Menu>
        </Stack>
        {TableSize}
        <HStack spacing="13px" justify={{ xs: "center", md: "end" }}>
          <IconButton
            aria-label="back"
            backgroundColor="transparent"
            _hover={{ backgroundColor: "transparent" }}
            icon={
              <BsChevronLeft
                size={19}
                color={
                  pageNumber <= 1 || pageNumber > pageCount
                    ? "#828ea0"
                    : "#000000"
                }
              />
            }
            isDisabled={
              pageNumber <= 1 || pageNumber > pageCount ? true : false
            }
            onClick={() => {
              if (pageNumber > 1 && pageNumber <= pageCount)
                pageNumberInputRef.current?.value &&
                  (pageNumberInputRef.current.value = String(pageNumber - 1));
              setPageNumber(pageNumber - 1);
            }}
          />
          <Input
            ref={pageNumberInputRef}
            h="37px"
            w="56px"
            backgroundColor="backgrounds.primary"
            defaultValue={pageNumber.toString()}
            textAlign="center"
            borderColor="borders.accent"
            onBlur={(e) => {
              const pageValue = e.target.value;
              setPageNumber(parseInt(pageValue));
            }}
          />

          <Text color="textcolors.secondary">/</Text>
          <Text w="40px" textAlign="center">
            {pageCount}
          </Text>
          <IconButton
            aria-label="right"
            backgroundColor="transparent"
            _hover={{ backgroundColor: "transparent" }}
            icon={
              <BsChevronRight
                size={19}
                color={
                  pageNumber >= pageCount || pageNumber < 1
                    ? "#828ea0"
                    : "#000000"
                }
              />
            }
            isDisabled={
              pageNumber >= pageCount || pageNumber < 1 ? true : false
            }
            onClick={() => {
              if (pageNumber < pageCount && pageNumber >= 1) {
                pageNumberInputRef.current?.value &&
                  (pageNumberInputRef.current.value = String(pageNumber + 1));
                setPageNumber(pageNumber + 1);
              }
            }}
          />
        </HStack>
      </Stack>
    </Fragment>
  );
}
