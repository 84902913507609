import {
  Button,
  ButtonGroup,
  Drawer,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {useEffect} from 'react';
import {AiFillCloseCircle, AiOutlineCheckCircle} from 'react-icons/ai';
import {IoCheckmarkCircle} from 'react-icons/io5';
import {MdDelete} from 'react-icons/md';
import {ScheduleInspectionModule} from '../../../../modules/Audit/ScheduleInspectionModule';

interface ManageScheduleBottomBarProps {
  isOpen: boolean;
  onClose: () => void;
  scheduleIds: Array<number>;
  setLastUpdate: (timestamp: number) => void;
}
export default function ManageScheduleBottomBar({
  isOpen,
  onClose,
  scheduleIds,
  setLastUpdate,
}: ManageScheduleBottomBarProps) {
  const toast = useToast();
  const deleteModal = useDisclosure();
  const btns: Array<{
    label: String;
    icon: any;
    onClick: any;
  }> = [
    {
      label: 'Change Status',
      icon: <AiOutlineCheckCircle size={16} fill="#1da5d4" />,
      onClick: () => {},
    },
    // {
    //   label: 'Duplicate',
    //   icon: <RiFileCopy2Fill size={16} fill="#1da5d4" />,
    //   onClick: () => {},
    // },
    // {
    //   label: 'Delete',
    //   icon: <MdDelete size={16} fill="#1da5d4" />,
    //   onClick: () => {},
    // },
  ];
  useEffect(() => {
    scheduleIds.length == 0 && onClose();
  }, [scheduleIds]);
  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <Stack
        position="fixed"
        bottom={0}
        left={0}
        right={0}
        bgColor="White"
        w="100%"
        zIndex={21}
        padding="0px"
        backgroundColor={'primary.500'}>
        <HStack h="65px" justifyContent="space-between" px="96px" py="16px">
          <HStack>
            <IoCheckmarkCircle fill="white" size={23} />
            <Text
              fontSize="16px"
              fontWeight={400}
              color="white"
              lineHeight="24px">
              {`${scheduleIds.length} items selected`}
            </Text>
          </HStack>

          <HStack spacing={4}>
            <Menu>
              <MenuButton
                as={Button}
                lineHeight="16px"
                px="12.8px"
                py="8px"
                borderRadius="8px"
                fontSize="13px"
                fontWeight={500}
                backgroundColor="white"
                color="primary.500"
                leftIcon={<AiOutlineCheckCircle size={16} fill="#1da5d4" />}
                _hover={{backgroundColor: 'none'}}
                _active={{backgroundColor: 'none'}}>
                Change Status
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    ScheduleInspectionModule.bulkScheduleUpdate(
                      {ids: scheduleIds, status: 'Paused'},
                      () => {
                        toast({
                          title: 'Status Updated',
                          status: 'success',
                        });
                        setLastUpdate(Date.now());
                        onClose();
                      },
                      error => {
                        toast({
                          title: 'Error',
                          description: error,
                          status: 'error',
                        });
                      },
                    );
                  }}>
                  Pause
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    ScheduleInspectionModule.bulkScheduleUpdate(
                      {ids: scheduleIds, status: 'Active'},
                      () => {
                        toast({
                          title: 'Status Updated',
                          status: 'success',
                        });
                        setLastUpdate(Date.now());
                        onClose();
                      },
                      error => {
                        toast({
                          title: 'Error',
                          description: error,
                          status: 'error',
                        });
                      },
                    );
                  }}>
                  Resume
                </MenuItem>
              </MenuList>
            </Menu>

            <Button
              lineHeight="16px"
              px="12.8px"
              py="8px"
              borderRadius="8px"
              fontSize="13px"
              fontWeight={500}
              backgroundColor="white"
              color="primary.500"
              leftIcon={<MdDelete size={16} fill="#1da5d4" />}
              _hover={{backgroundColor: 'none'}}
              _active={{backgroundColor: 'none'}}
              onClick={() => {
                deleteModal.onOpen();
              }}>
              Delete
            </Button>
            <Modal isOpen={deleteModal.isOpen} onClose={deleteModal.onClose}>
              <ModalOverlay />
              <ModalContent mx="10px">
                <ModalHeader fontWeight="semibold" py="4px">
                  Confirmation
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>Are you sure you want to Delete this?</ModalBody>

                <ModalFooter
                  display="flex"
                  justifyContent="flex-end"
                  pt="16px"
                  border="0px">
                  <ButtonGroup size="xs" variant="solid" borderRadius="5px">
                    <Button onClick={deleteModal.onClose} borderRadius="5px">
                      No
                    </Button>
                    <Button
                      colorScheme="red"
                      borderRadius="5px"
                      onClick={() => {
                        ScheduleInspectionModule.bulkScheduleDelete(
                          {ids: scheduleIds},
                          () => {
                            toast({
                              title: 'Schedule Deleted',
                              status: 'success',
                            });
                            setLastUpdate(Date.now());
                            deleteModal.onClose();
                            onClose();
                          },
                          error => {
                            toast({
                              title: 'Error',
                              description: error,
                              status: 'error',
                            });
                            deleteModal.onClose();
                          },
                        );
                      }}>
                      Yes
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </ModalContent>
            </Modal>
            <AiFillCloseCircle fill="white" size={19} onClick={onClose} />
          </HStack>
        </HStack>
      </Stack>
    </Drawer>
  );
}
