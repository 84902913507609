import { axiosInstance } from "./axiosInstance-hris";

declare module ExpenseClaimApi {
  type AddExpenseClaim = {
    id?: string;
    claimType: ClaimType;
    expenseClaimType?: ExpenseClaimType;
    amount: number;
    description: string;
    bankAccountType?: BankAccountType;
    spentAt: string;
    date: string;
    chartAccountId: string;
    payItemReimbursementId: string;
    region: string;
    assignTo: string;
    label: string;
    attachments: Array<string>;
    empId: string;
    taxType?: ExpenseTaxType;
    status?: ExpenseClaimStatus;
    taxRateId: string;
    taxableAmount: number;
    milageToClaim: number;
    milageRate: number;
    totalAmount: number;
  };
  type FetchExpenseClaim = {
    id: string;
    createdAt: string;
    updatedAt: string;
    claimType: ClaimType;
    expenseClaimType: ExpenseClaimType;
    amount: number;
    description: string;
    status: ExpenseClaimStatus;
    bankAccountType: null;
    spentAt: string;
    date: string;
    chartAccountId: null | string;
    payItemReimbursementId: string;
    region: string;
    assignTo: null | string;
    label: null;
    attachments: Array<string>;
    empId: string;
    taxType: ExpenseTaxType;
    taxRateId: string;
    taxableAmount: number;
    milageToClaim: null | number;
    milageRate: null | number;
    totalAmount: number;
    businessId: string;
  };
  type ChangeStatus = {
    status: ExpenseClaimStatus;
  };
}
module ExpenseClaimApi {
  export enum ExpenseClaimType {
    OPD = "OPD",
    OTHER = "OTHER",
    REIMBURSABLE = "REIMBURSABLE",
    NON_REIMBURSABLE = "NON_REIMBURSABLE",
  }
  export enum ClaimType {
    EXPENSE_CLAIM = "EXPENSE_CLAIM",
    MILAGE_CLAIM = "MILAGE_CLAIM",
  }
  export enum BankAccountType {
    BUSINESS_BANK_ACCOUNT = "BUSINESS_BANK_ACCOUNT",
    BUSINESS_SAVING_ACCOUNT = "BUSINESS_SAVING_ACCOUNT",
  }
  export enum ExpenseTaxType {
    INCLUDED = "INCLUDED",
    EXCLUDED = "EXCLUDED",
  }
  export enum ExpenseClaimStatus {
    DRAFT = "DRAFT",
    SUBMITTED = "SUBMITTED",
    APPROVED = "APPROVED",
  }
  const API_URL = "/expense-claim";
  export function PostClaim(
    data: AddExpenseClaim,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function PostBulkClaim(
    data: AddExpenseClaim,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/bulkUpload`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function GetAllExpenseClaim(
    params: {
      expenseClaimPersonalMoney: boolean;
      expenseClaimCompanyMoney: boolean;
      milageClaim: boolean;
      status: "APPROVED" | "DRAFT" | "SUBMITTED";
      search?: string;
      page?: number;
      limit?: number;
    },
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`, { params })
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function GetExpenseClaim(
    employeeId: string,
    success: (response: Array<FetchExpenseClaim>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/employee/${employeeId}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function UpdateExpenseClaim(
    id: string,
    data: Partial<AddExpenseClaim>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function DeleteExpenseClaim(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function changeClaimStatus(
    id: string,
    data: ChangeStatus,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}/status`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { ExpenseClaimApi };
