import { CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  Container,
  Icon,
  IconButton,
  Image,
  Link,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { IoIosWarning } from "react-icons/io";

interface UploadContactPros {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}
export function UploadContact({
  setActiveStep,
  activeStep,
}: UploadContactPros) {
  const [file, setFile] = useState<File | null>(null);

  // Explicitly typing the ref to refer to an HTMLInputElement
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type.startsWith("image/")) {
      setFile(droppedFile);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      setFile(selectedFile);
    }
  };

  const handleDelete = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input value with an empty string
    }
  };
  return (
    <Container maxW={"560px"} padding={"0px 12px"}>
      <Text
        margin={"21px 0px"}
        _light={{
          color: "customColor.black7",
        }}
        fontSize={"21px"}
        fontWeight={"700"}
      >
        Prepare file to import
      </Text>
      <Text
        margin={"15px 0px"}
        _light={{
          color: "customColor.black7",
        }}
        fontSize={"15px"}
        fontWeight={"400"}
      >
        Download the template and add your contacts. Don't delete the column
        headings as they are needed for the import to succeed.
      </Text>
      <Text
        _light={{
          color: "customColor.black7",
        }}
        fontSize={"15px"}
        fontWeight={"400"}
      >
        To update contacts already in Xero, export your contacts to CSV and edit
        them in the spreadsheet. Existing contacts will be updated if the value
        in <b>ContactName</b> matches the name of a contact that already exists.
      </Text>

      <Stack
        marginTop={"15px"}
        border={"1px solid #e5e7eb"}
        borderRadius={"2px"}
        padding={"12px"}
        gap={0}
        direction={"row"}
        alignItems={"flex-start"}
        bg={"customColor.gray.400"}
      >
        <Icon marginTop={"3px"} as={IoIosWarning} />
        <Stack marginLeft={"8px"}>
          <Text
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"15px"}
            fontWeight={"400"}
          >
            Updating a contact will delete its additional people – if columns
            are left empty
          </Text>
          <Text
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"15px"}
            fontWeight={"400"}
          >
            To keep additional people details, export a file with the
            information already populated or remove columns from
            <b>Person1FirstName</b> to <b>Person5IncludeInEmail</b>
            from the import
          </Text>
        </Stack>
      </Stack>
      <Text
        margin={"15px 0px"}
        _light={{
          color: "customColor.black7",
        }}
        fontSize={"15px"}
        fontWeight={"400"}
      >
        Up to 6,000 items can be imported each time.
      </Text>
      <Stack direction={"row"}>
        <Button
          backgroundColor={"#fff"}
          borderRadius={"3px"}
          border={"1px solid #dcdee4"}
          fontSize={"15px"}
          h={"40px"}
          variant={"solid"}
          textColor={"customColor.black7"}
          padding={"12px 16px"}
          fontWeight={600}
          _hover={{
            backgroundColor: "#fff",
          }}
        >
          Download template
        </Button>
        <Button
          variant={"ghost"}
          h={"40px"}
          color={"#3454d1"}
          fontSize={"15px"}
          _hover={{
            bg: "rgba(0,10,30,.05)",
          }}
        >
          Export items to CSV
        </Button>
      </Stack>
      <Stack
        marginBottom={"20px"}
        marginTop={"20px"}
        borderRadius={"3px"}
        boxShadow={"0 0 0 1px rgba(0,10,30,.2)"}
        paddingBottom={"12px"}
        paddingLeft={"20px"}
        paddingRight={"20px"}
      >
        <Text
          _light={{
            color: "customColor.black7",
          }}
          fontSize={"17px"}
          fontWeight={"700"}
        >
          Tips for importing
        </Text>
        <UnorderedList>
          <ListItem
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"15px"}
            fontWeight={"400"}
          >
            Don't change or delete the column headings
          </ListItem>
          <ListItem
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"15px"}
            fontWeight={"400"}
          >
            Enter the account code only (for example: 200)
          </ListItem>
          <ListItem
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"15px"}
            fontWeight={"400"}
          >
            Enter the tax rate exactly as it appears in Hris360 (for example:
            15% GST on Income)
          </ListItem>
          <ListItem
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"15px"}
            fontWeight={"400"}
          >
            If you're adding tracked inventory items, enter both the
            <b> InventoryAssetAccount</b> and
            <b> CostOfGoodsSoldAccount </b>
            codes.
          </ListItem>
        </UnorderedList>
        <Stack alignItems={"center"} direction={"row"} margin={"8px 0px 16px"}>
          <Link fontSize={"15px"} color={"#3454d1"}>
            Read our guide on importing items.
          </Link>
          <BsBoxArrowUpRight color={"#3454d1"} />
        </Stack>
      </Stack>
      <Text
        margin={"21px 0px"}
        _light={{
          color: "customColor.black7",
        }}
        fontSize={"21px"}
        fontWeight={"700"}
      >
        Upload items
      </Text>
      <Text
        fontSize={"13px"}
        _light={{
          color: "customColor.black7",
        }}
        paddingBottom={"4px"}
      >
        <b>File to upload</b> (required)
      </Text>
      <Stack
        border={"1px dashed #a6a9b0"}
        alignItems={"center"}
        justifyContent={"center"}
        padding={"20px"}
        textAlign={"center"}
        borderRadius={"3px"}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        position="relative"
      >
        {file ? (
          <>
            <Image
              src={URL.createObjectURL(file)}
              alt="Dropped file"
              maxWidth="100%"
              maxHeight="300px"
              borderRadius="3px"
            />
            <IconButton
              icon={<CloseIcon />}
              size="sm"
              colorScheme="red"
              position="absolute"
              top="10px"
              right="10px"
              onClick={handleDelete}
              aria-label="Delete Image"
            />
          </>
        ) : (
          <>
            <Text
              fontSize={"15px"}
              _light={{
                color: "customColor.black7",
              }}
              marginBottom={"12px"}
            >
              Drag and drop file or select manually
            </Text>
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
                color: "#3454D1",
              }}
              onClick={() => {
                if (fileInputRef.current) {
                  fileInputRef.current.click();
                }
              }}
            >
              Select File
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileSelect}
            />
          </>
        )}
      </Stack>
      <Text
        fontSize={"11px"}
        _light={{
          color: "customColor.black7",
        }}
        marginBottom={"12px"}
        paddingTop={"8px"}
      >
        2 MB max size. File should end in .csv or .txt.
      </Text>
      <Text
        fontSize={"15px"}
        _light={{
          color: "customColor.black7",
        }}
        fontWeight={"bold"}
        marginBottom={"15px"}
        paddingTop={"8px"}
      >
        On importing an existing contacts , empty fields will
      </Text>

      <RadioGroup defaultValue="2">
        <Stack border={"2px solid #e5e7eb"} gap={0} borderRadius={"3px"}>
          <Stack borderBottom={"2px solid #e5e7eb"}>
            <Radio colorScheme="red" value="1" h={"40px"} padding={"7px"}>
              Be ignored
            </Radio>
          </Stack>
          <Radio colorScheme="green" value="2" h={"40px"} padding={"7px"}>
            Delete existing information
          </Radio>
        </Stack>
      </RadioGroup>

      <Stack paddingTop={"20px"} paddingBottom={"10px"} alignItems={"flex-end"}>
        <Button
          colorScheme="green"
          borderRadius={"3px"}
          border={"1px solid green"}
          fontSize={"13px"}
          h={"40px"}
          marginLeft={"12px"}
          variant={"solid"}
          textColor={"customColor.white"}
          padding={"12px 16px"}
          marginRight={"5px"}
          fontWeight={600}
          onClick={() => setActiveStep(activeStep + 1)}
        >
          Next
        </Button>
      </Stack>
    </Container>
  );
}
