import {
  Box,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { DevTool } from "@hookform/devtools";
import { Controller, useFormContext } from "react-hook-form";
import { ReactSelect } from "../../../../../../Common/ReactSelect";
import { PeriodicalSchedule } from "../../../../../modules/Audit/PeriodicalSchedule";

export default function MonthlySchedule() {
  const form = useFormContext<PeriodicalSchedule.AddPeriodicSchedule>();
  const monthlyFrequencyDay = [
    { value: "FIRST", label: "First" },
    { value: "SECOND", label: "Second" },

    { value: "THIRD", label: "Third" },
    { value: "FOURTH", label: "Fourth" },
    { value: "LAST", label: "Last" },
  ];
  const daysOfWeek = [
    { value: "SUNDAY", label: "Sunday" },
    { value: "MONDAY", label: "Monday" },
    { value: "TUESDAY", label: "Tuesday" },
    { value: "WEDNESDAY", label: "Wednesday" },
    { value: "THURSDAY", label: "Thursday" },
    { value: "FRIDAY", label: "Friday" },
    { value: "SATURDAY", label: "Saturday" },
  ];
  return (
    <Box>
      <DevTool control={form.control} />
      <HStack h="100%">
        <Controller
          control={form.control}
          name="properties.selection"
          rules={{
            onChange(event) {
              event.target.value === "CUSTOM_MONTH"
                ? form.setValue("properties.monthly", {
                    every: 1,
                  })
                : form.setValue("properties.monthly", {
                    every: 1,
                  });
            },
          }}
          render={(selection) => (
            <RadioGroup {...selection.field} h="100%">
              <Stack>
                <HStack>
                  <Radio value="NO_OF_MONTH" mb="8px">
                    Every
                  </Radio>
                  <HStack mb="4px">
                    <Controller
                      control={form.control}
                      name="properties.monthly.dayOfMonth"
                      render={(dayofmonth) => (
                        <NumberInput
                          w="75px"
                          min={1}
                          isDisabled={selection.field.value !== "NO_OF_MONTH"}
                          {...dayofmonth.field}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      )}
                    />

                    <span>of every</span>
                    <Controller
                      control={form.control}
                      name="properties.monthly.every"
                      render={(every) => (
                        <NumberInput
                          w="75px"
                          min={1}
                          isDisabled={selection.field.value !== "NO_OF_MONTH"}
                          {...every.field}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      )}
                    />

                    <span>month(s)</span>
                  </HStack>
                </HStack>
                <HStack>
                  <Radio value="CUSTOM_MONTH">The</Radio>
                  <HStack>
                    <Box minW="120px">
                      <Controller
                        key={`properties.monthly.dayPosition-${selection.field.value}`}
                        control={form.control}
                        name="properties.monthly.dayPosition"
                        render={(position) => {
                          console.log(position.field.value);
                          return (
                            <ReactSelect
                              isDisabled={
                                selection.field.value !== "CUSTOM_MONTH"
                              }
                              withValidation
                              options={monthlyFrequencyDay}
                              value={
                                position.field.value
                                  ? monthlyFrequencyDay.find(
                                      (option) =>
                                        option.value === position.field.value
                                    )
                                  : undefined
                              }
                              onChange={(val: any) => {
                                position.field.onChange(val?.value);
                              }}
                              getOptionLabel={(option: any) => option.label}
                              getOptionValue={(option: any) => option.value}
                            />
                          );
                        }}
                      />
                    </Box>
                    <Box minW="150px">
                      <Controller
                        control={form.control}
                        name="properties.monthly.day"
                        render={(day) => (
                          <ReactSelect
                            isDisabled={
                              selection.field.value !== "CUSTOM_MONTH"
                            }
                            withValidation
                            options={daysOfWeek}
                            value={
                              day.field.value
                                ? daysOfWeek.find(
                                    (option) => option.value === day.field.value
                                  )
                                : undefined
                            }
                            onChange={(val: any) => {
                              day.field.onChange(val?.value);
                            }}
                            getOptionLabel={(option: any) => option.label}
                            getOptionValue={(option: any) => option.value}
                          />
                        )}
                      />
                    </Box>

                    <span>of every</span>
                    <Controller
                      control={form.control}
                      name="properties.monthly.every"
                      render={(every) => (
                        <NumberInput
                          w="75px"
                          defaultValue={1}
                          min={1}
                          isDisabled={selection.field.value !== "CUSTOM_MONTH"}
                          {...every.field}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      )}
                    />
                    <span>month(s)</span>
                  </HStack>
                </HStack>
              </Stack>
            </RadioGroup>
          )}
        />
      </HStack>
    </Box>
  );
}
