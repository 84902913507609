import { CheckIcon } from "@chakra-ui/icons";
import {
  Button,
  Card,
  Center,
  Container,
  HStack,
  Heading,
  Icon,
  SimpleGrid,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaBox, FaEnvelope, FaRocket, FaUser } from "react-icons/fa";

export default function Pricing() {
  const [showTag, setShowTag] = useState(true); // State to control the visibility of the tag

  useEffect(() => {
    // Set a timeout to toggle the visibility of the tag after 3 seconds
    const timeout = setTimeout(() => {
      setShowTag(false);
    }, 3000);

    // Clear the timeout when component unmounts to avoid memory leaks
    return () => clearTimeout(timeout);
  }, []);
  const data2 = [
    {
      iconName: FaUser,
      subscriptionDuration: "/month",
      title: "Basic Plan",
      amount: "$39.00",
      list: [
        "30GB Disk Space",
        "30 Email Accounts",
        "30GB Monthly Bandwidth",
        "06 Subdomains",
        "10 Domains",
      ],
    },
    {
      Tag: "POPULAR",
      iconName: FaRocket,
      subscriptionDuration: "/month",
      title: "Premium Plan",
      amount: "$49.00",
      list: [
        "50GB Disk Space",
        "50 Email Accounts",
        "50GB Monthly Bandwidth",
        "10 Subdomains",
        "15 Domains",
      ],
    },
    {
      iconName: FaEnvelope,
      subscriptionDuration: "/month",
      title: "Plus Plan",
      amount: "$69.00",
      list: [
        "80GB  Disk Space",
        "80  Email Accounts",
        "80GB  Monthly Bandwidth",
        "15 Subdomains",
        "20 Domains",
      ],
    },
    {
      iconName: FaBox,
      subscriptionDuration: "/year",
      title: "Master Plan",
      amount: "$199.00",
      list: [
        "80GB  Disk Space",
        "80  Email Accounts",
        "80GB  Monthly Bandwidth",
        "15 Subdomains",
        "20 Domains",
      ],
    },
  ];
  const data1 = [
    {
      title: "Master Plan",
      desc: "It is a long established fact that a reader will be distracted by the readable.",
      amount: "$39.00",
      list: [
        "180GB  Disk Space",
        "180 Email Accounts",
        "180GB  Monthly Bandwidth",
        "50 Subdomains",
        "40 Domains",
      ],
      ButtonColor: "BlueMartina",
    },
    {
      Tag: "POPULAR",
      title: "Premium Plan",
      desc: "It is a long established fact that a reader will be distracted by the readable.",
      amount: "$49.00",
      list: [
        "30GB Disk Space",
        "30 Email Accounts",
        "30GB Monthly Bandwidth",
        "06 Subdomains",
        "10 Domains",
      ],
      ButtonColor: "HotSun",
    },
    {
      title: "Plus Plan",
      desc: "It is a long established fact that a reader will be distracted by the readable.",
      amount: "$69.00",
      list: [
        "30GB Disk Space",
        "30 Email Accounts",
        "30GB Monthly Bandwidth",
        "06 Subdomains",
        "10 Domains",
      ],
      ButtonColor: "BlueMartina",
    },
    {
      title: "Master Plan",
      desc: "It is a long established fact that a reader will be distracted by the readable.",
      amount: "$199.00",
      list: [
        "30GB Disk Space",
        "30 Email Accounts",
        "30GB Monthly Bandwidth",
        "06 Subdomains",
        "10 Domains",
      ],
      ButtonColor: "BlueMartina",
    },
  ];

  return (
    <Stack
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      height="calc(100vh - 70px)"
      overflowY={"auto"}
    >
      <Stack
        boxShadow={"0 1px 0 0 rgba(0,10,30,.2)"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        h={{ sm: "100px", md: "60px" }}
      >
        <Container maxW={"1200px"} padding={0}>
          <Text
            fontWeight={"bold"}
            _dark={{
              color: "customColor.dark.150",
            }}
            _light={{
              color: "customColor.black",
            }}
            padding={"16px 12px 16px 0px "}
            margin={"0px 4px 0px 16px"}
            fontSize={"24px"}
          >
            Pricing
          </Text>
        </Container>
      </Stack>

      <Container maxW={"1200px"} padding={0}>
        <Stack padding={"16px 12px 16px 0px "} margin={"0px 4px 0px 16px"}>
          <SimpleGrid columns={{ sm: 1, md: 2, lg: 3, xl: 4 }} spacing={"20px"}>
            {data1.map((item, i) => (
              <Stack
                key={i}
                border={"none"}
                flex={1}
                boxShadow={
                  "0 0 24px 0 rgba(0,0,0,.06), 0 1px 0 0 rgba(0,0,0,.02)"
                }
                borderRadius={"3px"}
                height="100%" // Ensuring each stack takes up full height
              >
                <Card padding={"20px"} border={"none"} height="100%">
                  {item.Tag && (
                    <Tag
                      color={"customColor.white"}
                      fontSize={"10.5px"}
                      bg={"#EC408F"}
                      borderRadius={"4px"}
                      padding={"3.67px 5.25px"}
                      maxW={"58px"}
                      maxHeight={"19px"}
                    >
                      {item.Tag}
                    </Tag>
                  )}
                  <Center>
                    <Heading
                      padding={"16px"}
                      fontSize={"20px"}
                      _light={{
                        color: "customColor.black",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      fontWeight={"700"}
                    >
                      {item.title}
                    </Heading>
                  </Center>

                  <Heading
                    padding={"16px"}
                    textAlign={"center"}
                    fontSize={"13px"}
                    _light={{
                      color: "customColor.gray.50",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontWeight="400"
                  >
                    {item.desc}
                  </Heading>
                  <Center>
                    <HStack padding={"24px 0px"} margin={"10px 0px"} gap={0}>
                      <Heading
                        fontSize={"36px"}
                        _light={{
                          color: "customColor.black",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        fontWeight="700"
                      >
                        {item.amount}
                      </Heading>
                      <Heading
                        fontSize={"14px"}
                        _light={{
                          color: "customColor.gray.50",
                        }}
                        _dark={{
                          color: "customColor.pearlWhite",
                        }}
                        fontWeight="400"
                      >
                        /month
                      </Heading>
                    </HStack>
                  </Center>
                  <Stack padding={"16px 0px"} margin={"0px 0px 30px"}>
                    {item.list.map((listItem, index) => (
                      <Center key={index}>
                        <HStack>
                          <Icon
                            borderRadius="full"
                            bg="rgba(30,202,184,.1)"
                            w={"26px"}
                            h={"26px"}
                          >
                            <CheckIcon
                              color="#1ecab8!important"
                              fontSize={"12px"}
                            />
                          </Icon>

                          <Text fontSize={"15px"}>{listItem}</Text>
                        </HStack>
                      </Center>
                    ))}
                  </Stack>

                  {/* Skew the button to make it a parallelogram */}
                  <Button
                    h={"57.6px"}
                    transform="skewX(-20deg)"
                    colorScheme={item.ButtonColor}
                  >
                    <Text
                      transform="skewX(20deg)"
                      fontSize={"18px"}
                      fontStyle={"normal"}
                    >
                      Sign up
                    </Text>
                  </Button>
                </Card>
              </Stack>
            ))}
          </SimpleGrid>
        </Stack>
        <Stack padding={"16px 12px 16px 0px "} margin={"0px 4px 0px 16px"}>
          <SimpleGrid columns={{ sm: 1, md: 2, lg: 3, xl: 4 }} spacing={"20px"}>
            {data2.map((item, i) => (
              <Stack
                key={i}
                border={"none"}
                flex={1}
                boxShadow={
                  "0 0 24px 0 rgba(0,0,0,.06), 0 1px 0 0 rgba(0,0,0,.02)"
                }
                borderRadius={"3px"}
                height="100%" // Ensuring each stack takes up full height
              >
                <Card padding={"20px"} border={"none"} height="100%">
                  <HStack justifyContent={"space-between"} alignItems={"start"}>
                    <Stack border={"3px solid #fff"}>
                      <Stack
                        bg={"rgba(87,102,218,.18)"}
                        w={"64px"}
                        h={"64px"}
                        borderRadius={"5px"}
                        justifyContent="center" // Align items horizontally center
                        alignItems="center" // Align items vertically center
                      >
                        <Icon
                          as={item.iconName}
                          height={"24px"}
                          width={"24.8px"}
                          color="#5766da"
                        />
                      </Stack>
                    </Stack>
                    {item.Tag && (
                      <Tag
                        color={"customColor.white"}
                        fontSize={"10.5px"}
                        bg={"#FBB624"}
                        borderRadius={"4px"}
                        padding={"3.67px 5.25px"}
                        maxW={"58px"}
                        maxHeight={"19px"}
                      >
                        {item.Tag}
                      </Tag>
                    )}
                  </HStack>
                  <HStack
                    alignItems={"center"}
                    padding={"16px 0px"}
                    margin={"8px 0px 0px"}
                  >
                    <Heading
                      fontSize={"20px"}
                      _light={{
                        color: "customColor.black",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      fontWeight={"700"}
                    >
                      {item.title}
                    </Heading>
                    <Heading
                      fontSize={"14px"}
                      _light={{
                        color: "customColor.gray.50",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontWeight="400"
                    >
                      {item.subscriptionDuration}
                    </Heading>
                  </HStack>

                  <Stack
                    padding={"16px 0px"}
                    margin={"0px 0px 30px"}
                    borderBottom={"1px solid #e9ecef"}
                  >
                    {item.list.map((listItem, index) => (
                      <HStack key={index}>
                        <Icon
                          borderRadius="full"
                          bg="rgba(30,202,184,.1)"
                          w={"26px"}
                          h={"26px"}
                        >
                          <CheckIcon
                            color="#1ecab8!important"
                            fontSize={"12px"}
                          />
                        </Icon>

                        <Text fontSize={"15px"}>{listItem}</Text>
                      </HStack>
                    ))}
                  </Stack>
                  <Center>
                    <HStack margin={"10px 0px"} gap={0}>
                      <Heading
                        fontSize={"36px"}
                        _light={{
                          color: "customColor.black",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        fontWeight="700"
                      >
                        {item.amount}
                      </Heading>
                      <Heading
                        fontSize={"14px"}
                        _light={{
                          color: "customColor.gray.50",
                        }}
                        _dark={{
                          color: "customColor.pearlWhite",
                        }}
                        fontWeight="400"
                      >
                        {item.subscriptionDuration}
                      </Heading>
                    </HStack>
                  </Center>
                  {/* Skew the button to make it a parallelogram */}
                  <Button
                    h={"49.6px"}
                    colorScheme="customColor.Blueberry"
                    padding={"14px 26px"}
                    margin={"16px 0px 0px"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    borderRadius={"50px"}
                  >
                    SIGN UP
                  </Button>
                </Card>
              </Stack>
            ))}
          </SimpleGrid>
        </Stack>
      </Container>
    </Stack>
  );
}
