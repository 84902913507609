import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Stack,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ExpenseClaimApi } from "../../../../../Api/ExpenseClaimApi";
import { AddExpenseLeftSection } from "./AddExpenseClaim/AddExpenseLeftSection";
import { AddExpenseRightSection } from "./AddExpenseClaim/AddExpenseRightSection";

interface ClaimProps {
  isOpen: boolean;
  onClose: () => void;
  allEmployees?: string[];
  setLastUpdated: (time: number) => void;
}

export function AddExpenseClaim({
  setLastUpdated,
  allEmployees,
  isOpen,
  onClose,
}: ClaimProps) {
  const form = useFormContext<ExpenseClaimApi.AddExpenseClaim>();
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleFormSubmit = (status: ExpenseClaimApi.ExpenseClaimStatus) => {
    form.setValue("status", status); // Set the status here
    form.handleSubmit((data) => {
      setIsLoading(true);

      // Check if it's an update or a new post
      if (form.getValues("id")) {
        // Update existing expense claim
        ExpenseClaimApi.UpdateExpenseClaim(
          form.getValues("id")!,
          data,
          (success) => {
            toast({
              title: "Expense Claim Updated",
              status: "success",
            });
            setIsLoading(false);
            setLastUpdated(Date.now());
            onClose();
          },
          (err) => {
            toast({
              title: err,
              status: "error",
            });
            setIsLoading(false);
          }
        );
      } else if (allEmployees && allEmployees?.length > 0) {
        ExpenseClaimApi.PostBulkClaim(
          data,
          (success) => {
            toast({
              title: "Expense Bulk Claim Added",
              status: "success",
            });
            setIsLoading(false);
            setLastUpdated(Date.now());
            onClose();
          },
          (err) => {
            toast({
              title: err,
              status: "error",
            });
            setIsLoading(false);
          }
        );
      } else {
        ExpenseClaimApi.PostClaim(
          data,
          (success) => {
            toast({
              title: "Expense Claim Added",
              status: "success",
            });
            setIsLoading(false);
            setLastUpdated(Date.now());
            onClose();
          },
          (err) => {
            toast({
              title: err,
              status: "error",
            });
            setIsLoading(false);
          }
        );
      }
    })();
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="xl" placement="top">
      <DrawerOverlay />
      <DrawerContent h={"100%"}>
        <DrawerHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          {`New Expense `}
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody
          borderTop={"1px solid #ddd"}
          borderBottom={"1px solid #ddd"}
        >
          <HStack gap={0} alignItems={"flex-start"}>
            <Stack flex={1}>
              <AddExpenseLeftSection />
            </Stack>
            <Stack flex={1} padding={"8px 20px"} position="sticky" top="0">
              <AddExpenseRightSection form={form} />
            </Stack>
          </HStack>
        </DrawerBody>

        <DrawerFooter
          boxShadow={"0 -1px 0 0 rgba(0,10,30,.2),0 -3px 0 0 rgba(0,10,30,.05)"}
          border="1px solid #e5e7eb"
          padding={"16px"}
        >
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"8px"}
            fontWeight={600}
            _hover={{
              color: "#cb4242",
              backgroundColor: "#fff",
              textDecoration: "underline",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#3454d1"}
            padding={"12px 16px"}
            marginRight={"8px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
              color: "#2c48b5",
              textDecoration: "underline",
            }}
            isLoading={isLoading}
            onClick={() =>
              handleFormSubmit(ExpenseClaimApi.ExpenseClaimStatus.DRAFT)
            } // Set status to 'DRAFT'
          >
            Draft
          </Button>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#2E7D32"}
            padding={"12px 16px"}
            marginRight={"8px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
              color: "#276c2a",
              textDecoration: "underline",
            }}
            isLoading={isLoading}
            onClick={() =>
              handleFormSubmit(ExpenseClaimApi.ExpenseClaimStatus.SUBMITTED)
            } // Set status to 'SUBMITTED'
          >
            Submit
          </Button>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            isLoading={isLoading}
            onClick={() =>
              handleFormSubmit(ExpenseClaimApi.ExpenseClaimStatus.APPROVED)
            } // Set status to 'APPROVED'
          >
            Approve
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
