import {
  Box,
  HStack,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import {Fragment, useState} from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import {IoMdCloudUpload} from 'react-icons/io';
import Content from '../../pages/Content';
import SubHeading from '../../pages/Sub-Heading';
import CSVDataModal from './CSVDataModal';

interface uploadcsvprops {
  isOpen: boolean;
  onClose: () => void;
}
export default function UploadCsvModal({isOpen, onClose}: uploadcsvprops) {
  const csvFileUpload = useDisclosure();
  const csvdataModal = useDisclosure();
  const [isValue, Setisvalue] = useState(true);
  const reviewdata = useDisclosure();

  const [modalComponent, setModalComponent] = useState<React.ReactNode>(
    <UploadFileContent
      onBrowseClick={() => {
        setModalComponent(<CSVDataModal updateComponent={setModalComponent} />);
      }}
    />,
  );
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px="24px" pt="24px" pb="0px" mb="8px">
          <HStack>
            <SubHeading fontSize="21px" flex={1}>
              Upload responses as CSV
            </SubHeading>
            <Icon
              as={AiOutlineClose}
              boxSize="24px"
              color="#545f70"
              onClick={onClose}
            />
          </HStack>
        </ModalHeader>
        <ModalBody px="24px" pt="0px" pb="24px" mt="24px">
          {modalComponent}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function UploadFileContent({onBrowseClick}: {onBrowseClick: () => void}) {
  return (
    <Fragment>
      <Content fontSize="14px" mb="16px">
        Use this <Link textDecoration="underline">sample CSV</Link> to get
        started. By uploading a CSV, all existing responses will be overwritten.
      </Content>
      <Box
        minH="248px"
        bgColor="backgrounds.header"
        borderRadius="5px"
        border="1px dashed"
        borderColor="backgrounds.tag"
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={onBrowseClick}>
        <Stack spacing="0px" align="center">
          <Icon as={IoMdCloudUpload} color="icons.primary" boxSize="40px" />
          <Content>Drag your CSV file here</Content>
          <Content>
            or <Link textDecoration="underline">browse</Link>
          </Content>
        </Stack>
      </Box>
    </Fragment>
  );
}
