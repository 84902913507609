import { HStack, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import Select from "react-select";

const options = [
  { value: "10", label: "10" },
  { value: "25", label: "25" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
];
interface FEListLimitProps {
  setItemsPerPage: (value: string) => void;
}
export default function FEListLimit({ setItemsPerPage }: FEListLimitProps) {
  const form = useFormContext();
  const [selectedValue, setSelectedValue] = useState(options[0].value);
  return (
    <HStack spacing={0} justify="end">
      <Stack direction={"row"} alignItems={"center"} marginBottom={"8px"}>
        <Text
          _light={{
            color: "customColor.black7",
          }}
          fontSize={"14.4px"}
        >
          Show
        </Text>

        <Select
          options={options.map((option) => ({
            label: option.label,
            value: option.value,
          }))}
          value={options.find((option) => option.value === selectedValue)}
          onChange={(selectedOption) => {
            setSelectedValue(selectedOption?.value!);
            form.setValue("pagination.page", 1);
            setItemsPerPage(selectedOption?.value!);
          }}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          styles={{
            control: (provided) => ({
              ...provided,
              minHeight: "30px",
              fontSize: "14.4px",
              height: "30px",
              borderRadius: "4px",
              border: "1px solid #E2E8F0",
              padding: "0 4px",
              _hover: { borderColor: "#CBD5E0" },
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: "0 6px",
            }),
            input: (provided) => ({
              ...provided,
              margin: "0",
              padding: "0",
            }),
            indicatorSeparator: () => ({
              display: "none",
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              padding: "0",
            }),
            menu: (provided) => ({
              ...provided,
              minWidth: "150px",
            }),
            singleValue: (provided) => ({
              ...provided,
              display: "flex",
              alignItems: "center",
            }),
          }}
        />

        <Text
          _light={{
            color: "customColor.black7",
          }}
          fontSize={"14.4px"}
        >
          entries
        </Text>
      </Stack>
    </HStack>
  );
}
