import {
  Box,
  HStack,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tag,
  Text,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import {useEffect, useRef, useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {IoMdInformationCircleOutline} from 'react-icons/io';
import {Template} from '../../../modules/Audit';
import {MCQ} from '../../../modules/Audit/MCQs';
import {getFieldKey} from '../../../modules/form-apis';
import {useItemContext} from '../hooks/auditItemContext';
import useDrawer from '../hooks/drawersContext';

export default function ScorePopover() {
  const {isOpen, onOpen, onClose} = useDisclosure({
    onClose: () => {
      // console.log(generateNewScoring());
    },
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<any>();
  useOutsideClick({
    ref: containerRef,
    handler: onClose,
  });
  const {MultipleChoiceResponseModal} = useDrawer();
  const template = useFormContext();
  const {item} = useItemContext();
  const watchedMCQSData: MCQ.IMCQ = useWatch({
    control: template.control,
    name: getFieldKey(item, 'question.mcqsData'),
  });

  const watchedProperties: Template.QuestionProperties = useWatch({
    control: template.control,
    name: getFieldKey(item, 'question.properties'),
  });

  const watchedQuestionType: Template.QuestionType = useWatch({
    control: template.control,
    name: getFieldKey(item, 'question.type'),
  });

  const [maxScore, setMaxScore] = useState<number>();

  const [enteredMaxScore, setEnteredMaxScore] = useState<number>(0);

  const maxNumber = watchedMCQSData?.options?.reduce((prev, curr) => {
    if (curr?.score && (curr?.score ?? 0) > prev) {
      return curr.score;
    }
    return prev;
  }, 0);

  useEffect(() => {
    if (watchedMCQSData) {
      let calcScore = watchedMCQSData.options?.reduce((prev, curr) => {
        if (curr?.score && curr.score > prev) {
          return curr.score;
        }

        return prev;
      }, 0);

      const maxScore =
        template.getValues(getFieldKey(item, 'question.score')) ?? calcScore;
      setMaxScore(calcScore);
      setEnteredMaxScore(calcScore * maxScore);
    }

    if (watchedQuestionType === 'MCQs' && watchedMCQSData.isGlobal) {
      setEnteredMaxScore(
        template.getValues(getFieldKey(item, 'question.score')),
      );
    }

    if (watchedProperties?.max) {
      setEnteredMaxScore(watchedProperties?.max);
    }
    if (watchedQuestionType === 'CHECKBOX') {
      setEnteredMaxScore(
        template.getValues(getFieldKey(item, 'question.score')),
      );
    }
  }, [watchedMCQSData, watchedProperties, watchedQuestionType]);

  function calcScore(score: number) {
    return (score * ((enteredMaxScore ?? 0) / maxNumber)).toFixed(2);
  }

  function calcMultiplier(enteredMaxScore: number) {
    return Number(((enteredMaxScore ?? 0) / maxNumber).toFixed(2));
  }

  return (
    <Box onFocus={onOpen} ref={containerRef} w="100%" h="100%">
      <Popover
        placement="bottom-end"
        isOpen={isOpen}
        initialFocusRef={inputRef}>
        <PopoverTrigger>
          <Input
            w="100%"
            h="100%"
            border="transparent"
            _focus={{borderColor: 'primary.500'}}
            type="number"
            min={0}
            placeholder="/"
            // defaultValue={maxScore}

            ref={inputRef}
            value={enteredMaxScore}
            onChange={e => {
              const value = Number(e.target.value);
              setEnteredMaxScore(value);

              template.setValue(
                getFieldKey(item, 'question.score'),
                watchedQuestionType === 'MCQs' && !watchedMCQSData.isGlobal
                  ? calcMultiplier(value)
                  : value,
              );
              if (
                watchedQuestionType === 'SLIDER' &&
                (watchedProperties?.max ?? -1) > -1
              ) {
                template.setValue(
                  getFieldKey(item, 'question.properties.max'),
                  value,
                );
              }
            }}
          />
        </PopoverTrigger>
        <PopoverContent borderRadius="5px">
          <PopoverBody p="16px">
            <HStack justifyContent="space-between" py="8px">
              <Text
                fontSize="13px"
                fontWeight={400}
                color="icons.primary"
                lineHeight="16px">
                Response
              </Text>
              <Text
                fontSize="13px"
                fontWeight={400}
                color="icons.primary"
                lineHeight="16px">
                Actual score
              </Text>
            </HStack>
            {watchedMCQSData?.options?.map(({value, color, score}, i) => (
              <HStack key={i} justifyContent="space-between" py="8px">
                <Tag
                  fontSize="13px"
                  fontWeight={400}
                  borderRadius="12px"
                  backgroundColor={`${color}30`}
                  color={`${color}`}>
                  {value}
                </Tag>

                {/* <Text
                  fontSize="16px"
                  fontWeight={400}
                  color="textcolors.primary"
                  lineHeight="24px">
                  {(enteredMaxScore ?? -1) > -1
                    ? `${score}*${(
                        (enteredMaxScore ?? 0) / totalNumberOfOps
                      ).toFixed(2)}=${score && calcScore(score)}`
                    : score}
                </Text> */}
                {enteredMaxScore === maxScore ? (
                  <Text
                    fontSize="16px"
                    fontWeight={700}
                    color="textcolors.primary"
                    lineHeight="24px">
                    {score}
                  </Text>
                ) : (
                  <HStack spacing={1}>
                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      color="textcolors.primary"
                      lineHeight="24px">
                      {score}
                    </Text>
                    <HStack spacing={0}>
                      <Text
                        fontSize="11px"
                        fontWeight={400}
                        color="textcolors.tertiary"
                        lineHeight="16px">
                        *
                      </Text>
                      <Text
                        fontSize="11px"
                        fontWeight={400}
                        color="textcolors.tertiary"
                        lineHeight="16px">
                        {calcMultiplier(enteredMaxScore)}
                      </Text>
                    </HStack>
                    <Text
                      fontSize="11px"
                      fontWeight={400}
                      color="textcolors.tertiary"
                      lineHeight="16px">
                      =
                    </Text>
                    <Text
                      fontSize="16px"
                      fontWeight={700}
                      color="textcolors.primary"
                      lineHeight="24px">
                      {score && calcScore(score)}
                    </Text>
                  </HStack>
                )}
              </HStack>
            ))}

            <HStack
              justifyContent="space-between"
              mt="16px"
              onClick={e => {
                onClose();
                // MultipleChoiceResponseModal?.onOpen();
              }}>
              <IoMdInformationCircleOutline size={18} color="#828ea0" />
              <Text
                fontSize="14px"
                fontWeight={400}
                color="primary.500"
                cursor="pointer"
                _hover={{textDecoration: 'underline'}}>
                Edit response set score
              </Text>
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}
