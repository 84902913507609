import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  logo: {
    width: 90,
    height: 90,
    alignSelf: "center",
  },
  payslip: {
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 11.25,
    marginBottom: 11.25,
  },
  container: {
    flexDirection: "row",
    // flexWrap: "wrap",
    marginLeft: 3.75,
    marginRight: 3.75,
    height: 30,
    justifyContent: "space-between",
  },
  gridItem: {
    fontFamily: "Helvetica",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 9.75,
  },

  textHeading: {
    fontFamily: "Helvetica",
    margin: 0,
    fontSize: 6.75,
  },
  textValue1: {
    fontFamily: "Helvetica-Bold",
    marginVertical: 0,
    marginLeft: 3.75,
    fontSize: 9,
    fontWeight: "bold",
  },
  textValue2: {
    fontFamily: "Helvetica-Bold",
    marginVertical: 0,
    marginLeft: 3.75,
    fontSize: 7.5,
    fontWeight: "bold",
  },

  PayPeriodtableHeader: {
    backgroundColor: "#eaeaea",
    width: "100%",
    borderTopWidth: 1.5,
    borderTopColor: "#bebebe",
    borderBottomWidth: 1.5,
    borderBottomColor: "#bebebe",
    marginTop: 11.25,
  },

  th: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    textAlign: "right",
    width: "12.5%",
    fontSize: 7.5,
  },

  topView: {
    flexDirection: "column",
    margin: 0,
    padding: 0,
  },
  LogoandCompanyInfo: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 0,
    padding: 0,
  },
  EmployeeInfo: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    // alignItems: "center",
    marginTop: 0,
    padding: 0,
  },
  EmployeePersonalDetails: {
    marginLeft: 56.25,
    marginTop: 10,
  },
  EmployeeName: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    fontSize: 9,
    margin: 0,
    padding: 0,
  },
  emptyLogo: {
    width: 90,
    height: 90,
    margin: 0,
    padding: 0,
  },
  companyInfoView: {
    width: "35%",
    margin: 0,
    padding: 0,
  },
  comapnyAddressHeading: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    fontSize: 7.5,
    marginBottom: 3.75,
    marginTop: 0,
  },
  companyAddress: {
    fontFamily: "Helvetica",
    textAlign: "justify",
    letterSpacing: 0.5,
    lineHeight: 1.5, // Adjust line height for less spacing
    fontSize: 7.5,
    margin: 0,
    padding: 0,
  },
  normalText: {
    fontFamily: "Helvetica",
    fontSize: 7.5,

    lineHeight: 1.5,
  },
  nameText: {
    fontFamily: "Helvetica",
    fontSize: 9,
    margin: 0,
    padding: 0,
  },
  EmploymentDetailBoxes: {
    backgroundColor: "#f2f2f2",
    padding: 7.5,
    minHeight: "auto",
    width: "35%",
  },
  EmployementBoxHeading: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    fontSize: 7.5,
    marginBottom: 3.75,
    marginTop: 0,
    width: "100%",
  },
  EntitlementHeading: {
    borderBottomWidth: 0.75,
    borderBottomColor: "#bebebe",
    marginTop: 15,
    width: "100%",
  },
  header1Entitlement: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    width: "50%",
    textAlign: "left",
    fontSize: 7.5,
  },

  // Earnings Design
  table: {
    width: "100%",
  },
  tableHeader: {
    backgroundColor: "#eaeaea",
    width: "100%",
    borderTopWidth: 0.75,
    borderTopColor: "#bebebe",
    borderBottomWidth: 0.75,
    borderBottomColor: "#bebebe",
  },
  td: {
    textAlign: "right",
    width: "12.5%",
    fontSize: 7.5,
  },
});
const formatDate = (dateString: any) => {
  const date = new Date(dateString);
  const day = ("0" + date.getDate()).slice(-2);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
const formatPaymentDate = (dateString: any) => {
  const date = new Date(dateString);
  const paymentDay = ("0" + date.getDate()).slice(-2);
  const paymentMonth = ("0" + (date.getMonth() + 1)).slice(-2);
  const paymentYear = date.getFullYear();
  return `${paymentDay}/${paymentMonth}/${paymentYear}`;
};
const formattedPayPeriod = (startDate: any, endDate: any) => {
  const formattedStartDate = new Date(startDate);
  const StartDay = ("0" + formattedStartDate.getDate()).slice(-2);
  const StartMonth = ("0" + (formattedStartDate.getMonth() + 1)).slice(-2);
  const StartYear = formattedStartDate.getFullYear();
  const formattedEndDate = new Date(endDate);
  const EndDay = ("0" + formattedEndDate.getDate()).slice(-2);
  const EndMonth = ("0" + (formattedEndDate.getMonth() + 1)).slice(-2);
  const EndYear = formattedEndDate.getFullYear();
  return `${StartDay}/${StartMonth}/${StartYear} - ${EndDay}/${EndMonth}/${EndYear}`;
};
const PayslipDocument = ({ data }: any) => {
  return (
    <Document>
      {data.map((item: any) => (
        <Page key={item.id} style={styles.payslip} size="A4">
          {/* Header */}
          <View style={styles.topView}>
            <View style={styles.LogoandCompanyInfo}>
              {item?.business?.companyLogo ? (
                <View>
                  <Image style={styles.logo} src={item.business?.companyLogo} />
                </View>
              ) : (
                <View style={styles.emptyLogo}></View>
              )}
              <View style={styles.companyInfoView}>
                {!!item?.business?.address && item?.business?.address !== "" ? (
                  <View>
                    <Text style={styles.comapnyAddressHeading}>
                      Main Office Address:
                    </Text>
                    <Text style={styles.companyAddress}>
                      {item.business.address}
                    </Text>
                    <Text
                      style={styles.companyAddress}
                    >{`${item.business?.city}, ${item.business?.province}, ${item.business?.country} ${item.business?.postalCode}`}</Text>
                  </View>
                ) : (
                  <Text style={styles.companyAddress}></Text>
                )}
                <Text style={styles.normalText}>
                  Contact number: {item.business.mobileNumber}
                </Text>
                <Text style={styles.normalText}>
                  Email: {item.business.email}
                </Text>
              </View>
            </View>
            <View style={styles.EmployeeInfo}>
              <View style={styles.EmployeePersonalDetails}>
                <Text style={styles.EmployeeName}>
                  {`${item.employee.firstName} ${item.employee.lastName}`}
                </Text>
                <Text style={styles.nameText}>{item.employee.address}</Text>
                <Text style={styles.nameText}>
                  {`${item.employee.city}, ${item.employee.province}, ${item.employee.country} ${item.employee.postalCode}`}
                </Text>
              </View>
              <View style={styles.EmploymentDetailBoxes}>
                <Text style={styles.EmployementBoxHeading}>
                  EMPLOYMENT DETAILS
                </Text>
                <Text style={styles.normalText}>
                  Employee ID: {item.employee?.uniqueId}
                </Text>
                <Text style={styles.normalText}>
                  Position:{" "}
                  {item.employee?.employeeEmployementDetails?.jobTitle}
                </Text>
                <Text style={styles.normalText}>
                  Department:{" "}
                  {item.employee?.employeeEmployementDetails?.department}
                </Text>
                <Text style={styles.normalText}>
                  Joining Date:{" "}
                  {formatDate(
                    item.employee?.employeeEmployementDetails?.startDate
                  )}
                </Text>
                <Text style={styles.normalText}>
                  Gross Salary:{" "}
                  {item.employee?.payTemplate?.grossSalary?.toLocaleString()}/-
                </Text>
              </View>
            </View>
          </View>

          {/* Pay Period */}
          <View style={styles.PayPeriodtableHeader}>
            <View style={styles.container}>
              <View style={styles.gridItem}>
                <Text style={styles.textHeading}>Pay Period</Text>
                <Text style={styles.textValue2}>
                  {formattedPayPeriod(
                    item?.payRun?.startDate,
                    item?.payRun?.endDate
                  )}
                </Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.textHeading}>Payment Date</Text>
                <Text style={styles.textValue2}>
                  {formatPaymentDate(item.paymentDate)}
                </Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.textHeading}>Total Earning</Text>
                <Text style={styles.textValue1}>
                  {item.totalEarnings?.toLocaleString()}
                </Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.textHeading}>Net Pay</Text>
                <Text style={styles.textValue1}>
                  {(
                    item.totalEarnings +
                    item.totalReimbursements -
                    item.totalDeductions -
                    item.totalTax
                  ).toLocaleString()}
                  /-
                </Text>
              </View>
            </View>
          </View>

          {/* Main Heading of the Entitlements */}
          <View style={styles.EntitlementHeading}>
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <Text style={styles.header1Entitlement}></Text>
              <Text style={{ width: "12.5%" }}></Text>
              <Text style={{ width: "12.5%" }}></Text>
              <Text style={styles.th}>THIS PAY</Text>
              <Text style={styles.th}>YTD</Text>
            </View>
          </View>

          {/* Earnings Table */}
          {item?.payRunItems?.earnings?.length > 0 && (
            <View>
              <View style={[styles.table, { marginTop: 11.25 }]}>
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={[
                      styles.th,
                      {
                        fontFamily: "Helvetica-Bold",
                        width: "50%",
                        fontWeight: "bold",
                        textAlign: "left",
                        paddingBottom: 1.125,
                        fontSize: 7.5,
                      },
                    ]}
                  >
                    SALARY & WAGES
                  </Text>
                  <Text style={[styles.th, { paddingBottom: 3.75 }]}></Text>
                  <Text
                    style={[
                      styles.th,
                      {
                        fontFamily: "Helvetica",
                        fontSize: 6.75,

                        paddingBottom: 1.125,
                      },
                    ]}
                  >
                    RATE
                  </Text>
                  <Text style={[styles.th, { paddingBottom: 3.75 }]}></Text>
                  <Text style={[styles.th, { paddingBottom: 3.75 }]}></Text>
                </View>
                {item.payRunItems.earnings.map((ear: any) => (
                  <View>
                    {ear?.payItemEarning?.rateType === "GROSS_SALARY" ? (
                      <View>
                        <View
                          style={{
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={[
                              styles.td,
                              { width: "50%", textAlign: "left" },
                            ]}
                          >
                            Base Salary
                          </Text>
                          <Text style={[styles.td]}>
                            {ear?.hours ? ear.hours.toLocaleString() : ""}
                          </Text>
                          <Text style={[styles.td]}>
                            {ear?.rate
                              ? (
                                  (ear.rate / 100) *
                                  ear?.payItemEarning?.baseSalaryPercentage
                                ).toLocaleString()
                              : ""}
                          </Text>
                          <Text style={[styles.td]}>
                            {ear?.amount
                              ? (
                                  (ear.amount / 100) *
                                  ear?.payItemEarning?.baseSalaryPercentage
                                ).toLocaleString()
                              : ""}
                          </Text>
                          <Text style={[styles.td]}>
                            {item.YTDByPayItems[
                              ear.payItemEarningId
                            ]?.toLocaleString()}
                          </Text>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={[
                              styles.td,
                              { width: "50%", textAlign: "left" },
                            ]}
                          >
                            Allowances
                          </Text>
                          <Text style={[styles.td]}>
                            {ear?.hours ? ear.hours.toLocaleString() : ""}
                          </Text>
                          <Text style={[styles.td]}>
                            {ear?.rate
                              ? (
                                  (ear.rate / 100) *
                                  ear?.payItemEarning?.allowancesPercentage
                                ).toLocaleString()
                              : ""}
                          </Text>
                          <Text style={[styles.td]}>
                            {ear?.amount
                              ? (
                                  (ear.amount / 100) *
                                  ear?.payItemEarning?.allowancesPercentage
                                ).toLocaleString()
                              : ""}
                          </Text>
                        </View>
                      </View>
                    ) : (
                      <View
                        style={{
                          flexDirection: "row",
                        }}
                      >
                        {!!ear?.payItemEarningId ? (
                          <Text
                            style={[
                              styles.td,
                              { width: "50%", textAlign: "left" },
                            ]}
                          >
                            {ear?.payItemEarning?.name}
                          </Text>
                        ) : !!ear?.payItemLeaveId ? (
                          <Text
                            style={[
                              styles.td,
                              { width: "50%", textAlign: "left" },
                            ]}
                          >
                            {ear?.payItemLeave?.name}
                          </Text>
                        ) : !!ear?.payItemRetirementId ? (
                          <Text
                            style={[
                              styles.td,
                              { width: "50%", textAlign: "left" },
                            ]}
                          >
                            {ear?.payItemRetirement?.name}
                          </Text>
                        ) : !!ear?.payItemDeductionId ? (
                          <Text
                            style={[
                              styles.td,
                              { width: "50%", textAlign: "left" },
                            ]}
                          >
                            {ear?.payItemDeduction?.name}
                          </Text>
                        ) : (
                          <Text
                            style={[
                              styles.td,
                              { width: "50%", textAlign: "left" },
                            ]}
                          >
                            N/A
                          </Text>
                        )}
                        <Text style={[styles.td]}>
                          {ear?.hours ? ear.hours.toLocaleString() : ""}
                        </Text>
                        <Text style={[styles.td]}>
                          {ear?.rate ? ear.rate.toLocaleString() : ""}
                        </Text>
                        <Text style={[styles.td]}>
                          {ear?.amount ? ear.amount.toLocaleString() : ""}
                        </Text>
                        <Text style={[styles.td]}>
                          {item.YTDByPayItems[
                            ear.payItemEarningId ||
                              ear.payItemDeductionId ||
                              ear?.payItemLeaveId ||
                              ear?.payItemRetirementId
                          ]?.toLocaleString()}
                        </Text>
                      </View>
                    )}
                  </View>
                ))}
              </View>
              <View
                style={[
                  styles.tableHeader,
                  {
                    paddingTop: 1.5,
                    paddingBottom: 1.5,
                    marginTop: 7.5,
                  },
                ]}
              >
                <View style={styles.table}>
                  <View style={{ flexDirection: "row" }}>
                    <Text
                      style={[styles.td, { width: "50%", textAlign: "left" }]}
                    ></Text>
                    <Text style={[styles.td]}></Text>
                    <Text
                      style={[
                        styles.td,
                        {
                          fontFamily: "Helvetica",
                          fontSize: 6.75,
                        },
                      ]}
                    >
                      TOTAL
                    </Text>
                    <Text
                      style={[
                        styles.td,
                        {
                          fontFamily: "Helvetica-Bold",
                          fontSize: 7.5,
                          fontWeight: "bold",
                        },
                      ]}
                    >
                      {item.totalEarnings?.toLocaleString()}
                    </Text>
                    <Text
                      style={[
                        styles.td,
                        {
                          fontFamily: "Helvetica-Bold",
                          fontSize: 7.5,
                          fontWeight: "bold",
                        },
                      ]}
                    >
                      {item.totalYTDs?.totalEarningYTD?.toLocaleString()}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {/* Deductions Table */}
          {item?.payRunItems?.deductions?.length > 0 && (
            <View style={[styles.table, { marginTop: 11.25 }]}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={[
                    styles.th,
                    { width: "50%", textAlign: "left", paddingBottom: 3.75 },
                  ]}
                >
                  DEDUCTIONS
                </Text>
                <Text style={[styles.th, { paddingBottom: 3.75 }]}></Text>
                <Text
                  style={[styles.th, { paddingBottom: 3.75, fontSize: 9 }]}
                ></Text>
                <Text style={[styles.th, { paddingBottom: 3.75 }]}></Text>
                <Text style={[styles.th, { paddingBottom: 3.75 }]}></Text>
              </View>
              {item.payRunItems.deductions.map((ded: any) => (
                <View>
                  <View style={{ flexDirection: "row" }}>
                    <Text
                      style={[styles.td, { width: "50%", textAlign: "left" }]}
                    >
                      {ded?.payItemDeduction?.name}{" "}
                      {ded?.description && `(${ded?.description})`}
                    </Text>

                    <Text style={[styles.td]}></Text>
                    <Text style={[styles.td]}></Text>
                    <Text style={[styles.td]}>
                      {ded?.amount ? ded.amount.toLocaleString() : ""}
                    </Text>
                    <Text style={[styles.td]}>
                      {item.YTDByPayItems[
                        ded.payItemDeductionId
                      ]?.toLocaleString()}
                    </Text>
                  </View>
                </View>
              ))}
              <View
                style={[
                  styles.tableHeader,
                  {
                    paddingTop: 1.5,
                    paddingBottom: 1.5,
                    marginTop: 7.5,
                  },
                ]}
              >
                <View style={styles.table}>
                  <View style={{ flexDirection: "row" }}>
                    <Text
                      style={[styles.td, { width: "50%", textAlign: "left" }]}
                    ></Text>
                    <Text style={[styles.td]}></Text>
                    <Text
                      style={[
                        styles.td,
                        {
                          fontFamily: "Helvetica",
                          fontSize: 6.75,
                        },
                      ]}
                    >
                      TOTAL
                    </Text>
                    <Text
                      style={[
                        styles.td,
                        {
                          fontFamily: "Helvetica-Bold",
                          fontSize: 7.5,
                          fontWeight: "bold",
                        },
                      ]}
                    >
                      {item.totalDeductions?.toLocaleString()}
                    </Text>
                    <Text
                      style={[
                        styles.td,
                        {
                          fontFamily: "Helvetica-Bold",
                          fontSize: 7.5,
                          fontWeight: "bold",
                        },
                      ]}
                    >
                      {item.totalYTDs?.totalDeductionYTD?.toLocaleString()}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {/* Tax Table */}
          {item?.payRunItems?.taxes?.length > 0 && (
            <View>
              <View style={[styles.table, { marginTop: 11.25 }]}>
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={[
                      styles.th,
                      { width: "50%", textAlign: "left", paddingBottom: 3.75 },
                    ]}
                  >
                    TAX
                  </Text>
                  <Text style={[styles.th, { paddingBottom: 3.75 }]}></Text>
                  <Text
                    style={[styles.th, { paddingBottom: 3.75, fontSize: 9 }]}
                  ></Text>
                  <Text style={[styles.th, { paddingBottom: 3.75 }]}></Text>
                  <Text style={[styles.th, { paddingBottom: 3.75 }]}></Text>
                </View>
                {item.payRunItems.taxes.map((tax: any) => (
                  <View
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={[styles.td, { width: "50%", textAlign: "left" }]}
                    >
                      Tax
                    </Text>
                    <Text style={[styles.td]}></Text>
                    <Text style={[styles.td]}></Text>
                    <Text style={[styles.td]}>
                      {tax?.amount ? tax.amount.toLocaleString() : ""}
                    </Text>
                    <Text style={[styles.td]}>
                      {item.YTDByPayItems[
                        tax.taxChartOfAccountId
                      ]?.toLocaleString()}
                    </Text>
                  </View>
                ))}
              </View>
              <View
                style={[
                  styles.tableHeader,
                  {
                    paddingTop: 1.5,
                    paddingBottom: 1.5,
                    marginTop: 7.5,
                  },
                ]}
              >
                <View style={styles.table}>
                  <View style={{ flexDirection: "row" }}>
                    <Text
                      style={[styles.td, { width: "50%", textAlign: "left" }]}
                    ></Text>
                    <Text style={[styles.td]}></Text>
                    <Text
                      style={[
                        styles.td,
                        {
                          fontFamily: "Helvetica",
                          fontSize: 6.75,
                        },
                      ]}
                    >
                      TOTAL
                    </Text>
                    <Text
                      style={[
                        styles.td,
                        {
                          fontFamily: "Helvetica-Bold",
                          fontSize: 7.5,
                          fontWeight: "bold",
                        },
                      ]}
                    >
                      {item.totalTax?.toLocaleString()}
                    </Text>
                    <Text
                      style={[
                        styles.td,
                        {
                          fontFamily: "Helvetica-Bold",
                          fontSize: 7.5,
                          fontWeight: "bold",
                        },
                      ]}
                    >
                      {item.totalYTDs?.totalTaxYTD?.toLocaleString()}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {/* Reimbursement Table */}
          {item?.payRunItems?.reimbursments?.length > 0 && (
            <View>
              <View style={[styles.table, { marginTop: 11.25 }]}>
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={[
                      styles.th,
                      { width: "50%", textAlign: "left", paddingBottom: 3.75 },
                    ]}
                  >
                    REIMBURSEMENTS
                  </Text>
                  <Text style={[styles.th, { paddingBottom: 3.75 }]}></Text>
                  <Text
                    style={[styles.th, { paddingBottom: 3.75, fontSize: 9 }]}
                  ></Text>
                  <Text style={[styles.th, { paddingBottom: 3.75 }]}></Text>
                  <Text style={[styles.th, { paddingBottom: 3.75 }]}></Text>
                </View>
                {item.payRunItems.reimbursments.map((reim: any) => (
                  <View
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={[styles.td, { width: "50%", textAlign: "left" }]}
                    >
                      {reim?.payItemReimbursement?.name}
                    </Text>
                    <Text style={[styles.td]}></Text>
                    <Text style={[styles.td]}></Text>
                    <Text style={[styles.td]}>
                      {reim?.amount ? reim.amount.toLocaleString() : ""}
                    </Text>
                    <Text style={[styles.td]}>
                      {item.YTDByPayItems[
                        reim.payItemReimbursementId
                      ]?.toLocaleString()}
                    </Text>
                  </View>
                ))}
              </View>
              <View
                style={[
                  styles.tableHeader,
                  {
                    paddingTop: 1.5,
                    paddingBottom: 1.5,
                    marginTop: 7.5,
                  },
                ]}
              >
                <View style={styles.table}>
                  <View style={{ flexDirection: "row" }}>
                    <Text
                      style={[styles.td, { width: "50%", textAlign: "left" }]}
                    ></Text>
                    <Text style={[styles.td]}></Text>
                    <Text
                      style={[
                        styles.td,
                        {
                          fontFamily: "Helvetica",
                          fontSize: 6.75,
                        },
                      ]}
                    >
                      TOTAL
                    </Text>
                    <Text
                      style={[
                        styles.td,
                        {
                          fontFamily: "Helvetica-Bold",
                          fontSize: 7.5,
                          fontWeight: "bold",
                        },
                      ]}
                    >
                      {item.totalReimbursements?.toLocaleString()}
                    </Text>
                    <Text
                      style={[
                        styles.td,
                        {
                          fontFamily: "Helvetica-Bold",
                          fontSize: 7.5,
                          fontWeight: "bold",
                        },
                      ]}
                    >
                      {item.totalYTDs?.totalReimbursementYTD?.toLocaleString()}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {/* Payment Details Table */}
          <View
            style={[
              styles.table,
              {
                borderBottomWidth: 0.75,
                borderBottomColor: "#bebebe",
                marginTop: 11.25,
              },
            ]}
          >
            <View style={{ flexDirection: "row" }}>
              <Text style={[styles.th, { width: "30%", textAlign: "left" }]}>
                Payment Details
              </Text>
              <Text
                style={[
                  styles.th,
                  {
                    fontFamily: "Helvetica-Bold",
                    fontSize: 9.75,
                    fontWeight: "bold",
                    width: "20%",
                  },
                ]}
              ></Text>
              <Text
                style={[
                  styles.th,
                  {
                    fontFamily: "Helvetica-Bold",
                    fontSize: 6.75,
                    fontWeight: "bold",
                    textAlign: "right",
                    width: "25%",
                  },
                ]}
              >
                REFERENCE
              </Text>
              <Text
                style={[
                  styles.th,
                  {
                    fontFamily: "Helvetica-Bold",
                    fontSize: 6.75,
                    fontWeight: "bold",
                    textAlign: "right",
                    width: "25%",
                  },
                ]}
              >
                AMOUNT
              </Text>
            </View>
          </View>
          <View style={[styles.table]}>
            <View style={{ flexDirection: "row" }}>
              <Text style={[styles.td, { width: "30%", textAlign: "left" }]}>
                {item?.employee?.employeeBankDetails?.iban}
              </Text>
              <Text style={[styles.td, { textAlign: "left", width: "20%" }]}>
                {item?.employee?.employeeBankDetails?.accountName}
              </Text>
              <Text
                style={[
                  styles.td,
                  {
                    textAlign: "right",
                    width: "25%",
                  },
                ]}
              >
                Salary
              </Text>
              <Text
                style={[
                  styles.td,
                  {
                    fontFamily: "Helvetica-Bold",
                    fontSize: 11.25,
                    fontWeight: "bold",
                    textAlign: "right",
                    width: "25%",
                  },
                ]}
              >
                {(
                  item.totalEarnings +
                  item.totalReimbursements -
                  item.totalDeductions -
                  item.totalTax
                ).toLocaleString()}
                /-
              </Text>
            </View>
          </View>

          {/* Leave Table */}
          {item?.payRunItems?.leaves?.length > 0 && (
            <View>
              <View
                style={[
                  styles.table,
                  {
                    marginTop: 22.5,
                    borderBottomWidth: 0.75,
                    borderBottomColor: "#bebebe",
                  },
                ]}
              >
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={[styles.th, { width: "50%", textAlign: "left" }]}
                  >
                    LEAVES
                  </Text>
                  <Text style={[styles.th]}></Text>
                  <Text
                    style={[
                      styles.th,
                      {
                        fontFamily: "Helvetica",
                        fontSize: 8.75,
                        fontWeight: "bold",
                      },
                    ]}
                  >
                    ACCRUED
                  </Text>
                  <Text
                    style={[
                      styles.th,
                      {
                        fontFamily: "Helvetica",
                        fontSize: 8.75,
                        fontWeight: "bold",
                      },
                    ]}
                  >
                    USED
                  </Text>
                  <Text
                    style={[
                      styles.th,
                      {
                        fontFamily: "Helvetica",
                        fontSize: 8.75,
                        fontWeight: "bold",
                      },
                    ]}
                  >
                    BALANCE
                  </Text>
                </View>
                <View style={[styles.table, { marginTop: 5 }]}>
                  {item.payRunItems.leaves.map((leave: any) => (
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={[styles.td, { width: "50%", textAlign: "left" }]}
                      >
                        {leave?.payItemLeave?.name}
                      </Text>
                      <Text style={[styles.td]}></Text>
                      <Text style={[styles.td]}>
                        {leave?.hours ? leave.hours.toLocaleString() : ""}
                      </Text>
                      <Text style={[styles.td]}>
                        {item?.usedLeaves?.[leave?.payItemLeaveId]
                          ? item?.usedLeaves[leave.payItemLeaveId]
                          : ""}
                      </Text>
                      <Text style={[styles.td]}>
                        {leave?.payItemLeave?.employeeLeaveEntitlements?.[0]
                          ?.balance
                          ? leave.payItemLeave.employeeLeaveEntitlements[0].balance.toLocaleString()
                          : ""}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          )}

          {/* Retirement Table */}
          {item?.payRunItems?.retirements?.length > 0 && (
            <View>
              <View
                style={[
                  styles.table,
                  {
                    marginTop: 11.25,
                  },
                ]}
              >
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={[
                      styles.th,
                      { width: "50%", textAlign: "left", paddingBottom: 3.75 },
                    ]}
                  >
                    Statutory Benefits Balance
                  </Text>
                  <Text style={[styles.th]}></Text>
                  <Text
                    style={[
                      styles.th,
                      {
                        fontFamily: "Helvetica",
                        fontSize: 9.75,
                        fontWeight: "bold",
                        paddingBottom: 3.75,
                      },
                    ]}
                  ></Text>
                  <Text
                    style={[
                      styles.th,
                      {
                        paddingBottom: 3.75,
                      },
                    ]}
                  ></Text>
                  <Text
                    style={[
                      styles.th,
                      {
                        paddingBottom: 3.75,
                      },
                    ]}
                  ></Text>
                </View>

                {item.payRunItems.retirements.map((retirement: any) => (
                  <View
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={[styles.td, { width: "50%", textAlign: "left" }]}
                    >
                      {retirement?.payItemRetirement?.name}
                    </Text>
                    <Text style={[styles.td]}></Text>
                    <Text style={[styles.td]}></Text>
                    <Text style={[styles.td]}>
                      {retirement?.amount
                        ? retirement.amount.toLocaleString()
                        : ""}
                    </Text>

                    <Text style={[styles.td]}>
                      {item.YTDByPayItems[
                        retirement.payItemRetirementId
                      ]?.toLocaleString()}
                    </Text>
                  </View>
                ))}
              </View>
              <View
                style={[
                  styles.tableHeader,
                  {
                    paddingTop: 1.5,
                    paddingBottom: 1.5,
                    marginTop: 7.5,
                  },
                ]}
              >
                <View style={styles.table}>
                  <View style={{ flexDirection: "row" }}>
                    <Text
                      style={[styles.td, { width: "50%", textAlign: "left" }]}
                    ></Text>
                    <Text style={[styles.td]}></Text>
                    <Text
                      style={[
                        styles.td,
                        {
                          fontFamily: "Helvetica",
                          fontSize: 6.75,
                        },
                      ]}
                    >
                      TOTAL
                    </Text>
                    <Text
                      style={[
                        styles.td,
                        {
                          fontFamily: "Helvetica-Bold",
                          fontSize: 7.5,
                          fontWeight: "bold",
                        },
                      ]}
                    >
                      {item.totalRetirements?.toLocaleString()}
                    </Text>
                    <Text
                      style={[
                        styles.td,
                        {
                          fontFamily: "Helvetica-Bold",
                          fontSize: 7.5,
                          fontWeight: "bold",
                        },
                      ]}
                    >
                      {item.totalYTDs?.totalRetirementYTD?.toLocaleString()}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}
          {(!!item?.note || !!item?.payRun?.note) && (
            <View>
              <View
                style={[
                  styles.table,
                  {
                    borderBottomWidth: 0.75,
                    borderBottomColor: "#bebebe",
                    marginTop: 11.25,
                  },
                ]}
              >
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={[styles.th, { width: "50%", textAlign: "left" }]}
                  >
                    Messages
                  </Text>
                  <Text style={[styles.th]}></Text>
                  <Text style={[styles.th]}></Text>
                  <Text style={[styles.th]}></Text>
                  <Text style={[styles.th]}></Text>
                </View>
              </View>
              {item?.payRun?.note && (
                <Text
                  style={{
                    marginTop: 7.5,
                    fontSize: 7.5,
                    fontFamily: "Helvetica",
                  }}
                >
                  {item.payRun?.note.replace(/\n/g, "\n")}
                </Text>
              )}
              {item?.note && (
                <Text
                  style={{
                    marginTop: 7.5,
                    fontSize: 7.5,
                    fontFamily: "Helvetica",
                  }}
                >
                  {item?.note.replace(/\n/g, "\n")}
                </Text>
              )}
            </View>
          )}
        </Page>
      ))}
    </Document>
  );
};

export default PayslipDocument;
