import {
  AbsoluteCenter,
  Avatar,
  Button,
  ButtonGroup,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { Fragment, useMemo, useState } from "react";
import { FiMoreVertical, FiRotateCcw } from "react-icons/fi";
import { IoIosSettings } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { RiBookReadFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Inspection } from "../../../../modules/Audit/Inspection";
import Content from "../../../template/pages/Content";
import BuildBottomInspectionArchive from "./BuildBottomInspectionArchive";

interface InspectionArchiveTableLgProps {
  onRowClick: (id: any) => void;
  data: Inspection.InspectionDateWise;
  isLoading: boolean;
  setLastUpdate: (timestamp: number) => void;
}
export default function InpectionArchiveTableLg({
  onRowClick,
  data,
  isLoading,
  setLastUpdate,
}: InspectionArchiveTableLgProps) {
  const navigate = useNavigate();
  const toast = useToast();
  const deleteModal = useDisclosure();
  const menuitems: Array<{
    label: string;
    icon: any;
    onClick?: (...args: any) => void;
  }> = [
    {
      label: "Restore",
      icon: FiRotateCcw,
      onClick: (inspectionId: number) => {
        const archiveTemplate: Partial<Inspection.InpsectionPayload> = {
          archive: false,
        };

        Inspection.Update(
          inspectionId,
          archiveTemplate,
          () => {
            toast({
              title: "Inspection Restored",
              status: "success",
            });
            setLastUpdate(Date.now());
          },
          (error) => {
            toast({
              title: "Error",
              description: error,
              status: "error",
            });
          }
        );
      },
    },
    {
      label: "View report",
      icon: RiBookReadFill,
      onClick: (inspectionId: number) => {
        navigate(`/app/auditor/report/${inspectionId}`);
      },
    },
    // {
    //   label: 'View history',
    //   icon: MdHistory,
    // },
    {
      label: "Delete",
      icon: MdDelete,
      onClick: (inspectionId: number) => {
        if (inspectionId) {
          deleteModal.onOpen();
        }
      },
    },
  ];
  const [selectedInspectionIds, setSelectedInspectionIds] = useState<number[]>(
    []
  );
  const buildbottomarchive = useDisclosure();
  const memorizedInspectionCombined = useMemo(() => {
    return Object.entries(data).reduce((prev, [date, inspections]) => {
      return [...prev, ...inspections.map((insp) => insp.id)];
    }, [] as Array<number>);
  }, [data]);
  type ShowColumns = {
    Actions: boolean;
    Doc_number: boolean;
    Score: boolean;
    Counducted: boolean;
    Completed: boolean;
  };
  const [showColumns, setShowColumns] = useState<ShowColumns>({
    Actions: true,
    Doc_number: true,
    Score: true,
    Counducted: true,
    Completed: true,
  });
  const handleColumnCheckbox = (column: keyof typeof showColumns) => {
    setShowColumns((prevState) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };
  return (
    <TableContainer
      border="1px solid"
      borderRadius="5px"
      borderColor="borders.accent"
    >
      <Table variant="simple">
        <Thead bg="backgrounds.primary">
          <Tr>
            <Th w="40px" p="8px" borderColor="borders.accent">
              <Checkbox
                w="21px"
                h="21px"
                borderColor="grey"
                isChecked={
                  memorizedInspectionCombined.length ===
                  selectedInspectionIds.length
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedInspectionIds(memorizedInspectionCombined);
                    buildbottomarchive.onOpen();
                  } else {
                    setSelectedInspectionIds([]);
                  }
                }}
              />
            </Th>
            <Th
              minW="200px"
              py="8px"
              pl="8px"
              pr="16px"
              fontSize="13px"
              fontWeight={400}
              lineHeight="1rem"
              color="textcolors.primary"
              textDecoration="underline"
              borderColor="borders.accent"
            >
              Inspection
            </Th>
            {showColumns.Actions && (
              <Th
                display={{ md: "none", lg: "table-cell" }}
                maxW="200px"
                py="8px"
                px="16px"
                fontSize="13px"
                fontWeight={400}
                lineHeight="1rem"
                color="textcolors.primary"
                borderColor="borders.accent"
              >
                Action
              </Th>
            )}
            {showColumns.Doc_number && (
              <Th
                w="200px"
                py="8px"
                px="16px"
                fontSize="13px"
                fontWeight={400}
                lineHeight="1rem"
                color="textcolors.primary"
                borderColor="borders.accent"
              >
                Doc Number
              </Th>
            )}
            {showColumns.Score && (
              <Th
                display={{ md: "none", lg: "table-cell" }}
                maxW="200px"
                py="8px"
                px="16px"
                fontSize="13px"
                fontWeight={400}
                lineHeight="1rem"
                color="textcolors.primary"
                textDecoration="underline"
                borderColor="borders.accent"
              >
                Score
              </Th>
            )}
            {showColumns.Counducted && (
              <Th
                maxW="150px"
                py="8px"
                px="16px"
                borderColor="borders.accent"
                display={{ md: "none", lg: "table-cell" }}
              >
                <HStack>
                  <Text
                    fontSize="13px"
                    fontWeight={400}
                    lineHeight="1rem"
                    color="textcolors.primary"
                    textDecoration="underline"
                  >
                    Conducted
                  </Text>
                  {/* <AiOutlineArrowDown size={18} color="#3f495a" /> */}
                </HStack>
              </Th>
            )}
            {showColumns.Completed && (
              <Th
                display={{ md: "none", lg: "table-cell" }}
                maxW="150px"
                py="8px"
                px="16px"
                fontSize="13px"
                fontWeight={400}
                lineHeight="1rem"
                color="textcolors.primary"
                textDecoration="underline"
                borderColor="borders.accent"
              >
                Completed
              </Th>
            )}
            <Th
              display={{ md: "none", lg: "table-cell" }}
              maxW="150px"
              py="8px"
              px="16px"
              borderColor="borders.accent"
            ></Th>
            <Th
              w="40px"
              py="8px"
              pr="0px"
              pl="8px"
              borderColor="borders.accent"
            >
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<IoIosSettings size={18} color="#545f70" />}
                  variant="outline"
                  borderRadius={"50%"}
                  border={"none"}
                  _hover={{ backgroundColor: "transparent" }}
                  _active={{
                    backgroundColor: "transparent",
                  }}
                />
                <MenuList minW="160px">
                  <MenuGroup
                    title="Show / hide columns"
                    fontWeight={400}
                    fontSize="13px"
                    textTransform="initial"
                  >
                    {(
                      [
                        { key: "Actions", label: "Actions" },
                        { key: "Doc_number", label: "Doc number" },
                        { key: "Score", label: "Score" },
                        { key: "Counducted", label: "Counducted" },
                        { key: "Completed", label: "Completed" },
                      ] as const
                    ).map((menu, i) => (
                      <MenuItem key={i} px="14px" py="9.5px">
                        <Checkbox
                          isChecked={showColumns[menu.key]}
                          onChange={(e) => {
                            handleColumnCheckbox(menu.key);
                            e.stopPropagation();
                          }}
                        >
                          {menu.label}
                        </Checkbox>
                      </MenuItem>
                    ))}
                  </MenuGroup>
                </MenuList>
              </Menu>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <AbsoluteCenter>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="primary.500"
                size="xl"
              />
            </AbsoluteCenter>
          ) : (
            Object.entries(data).map(([date, inspections]) => {
              const conductedDate = moment(date, "DD/MM/YYYY");
              return (
                <Fragment>
                  <Tr backgroundColor="backgrounds.header">
                    <Td maxW="40px" py="8px" px="8px" border="0px">
                      <Checkbox
                        w="21px"
                        h="21px"
                        borderColor="grey"
                        isChecked={inspections.every((insp) =>
                          selectedInspectionIds.includes(insp.id)
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            const allInspectionsIdsOfDate = inspections
                              .filter(
                                (insp) =>
                                  !selectedInspectionIds.includes(insp.id)
                              )
                              .map((insp) => insp.id);
                            setSelectedInspectionIds((prev) => [
                              ...prev,
                              ...allInspectionsIdsOfDate,
                            ]);
                            buildbottomarchive.onOpen();
                          } else {
                            setSelectedInspectionIds((prev) =>
                              prev.filter(
                                (id) =>
                                  !inspections.some((ins) => ins.id === id)
                              )
                            );
                          }
                        }}
                      />
                    </Td>
                    <Td
                      py="8px"
                      px="8px"
                      border="0px"
                      fontSize="14px"
                      color="textcolors.primary"
                    >
                      {conductedDate.isSame(moment(), "day")
                        ? "Today"
                        : conductedDate.fromNow()}
                    </Td>
                  </Tr>
                  {inspections.map((inspection, i) => (
                    <Tr onClick={onRowClick} backgroundColor="white">
                      <Td
                        maxW="40px"
                        py="16px"
                        px="8px"
                        borderTop="1px"
                        borderColor="borders.accent"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Checkbox
                          w="21px"
                          h="21px"
                          borderColor="grey"
                          isChecked={selectedInspectionIds.includes(
                            inspection.id
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedInspectionIds((prevIds) => [
                                ...prevIds,
                                inspection.id,
                              ]);
                              buildbottomarchive.onOpen();
                            } else {
                              setSelectedInspectionIds((prevIds) =>
                                prevIds.filter((id) => id !== inspection.id)
                              );
                            }
                          }}
                        />
                      </Td>
                      <Td
                        py="16px"
                        pr="8px"
                        pl="0px"
                        borderTop="1px"
                        borderColor="borders.accent"
                      >
                        <HStack
                          maxW="100%"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                        >
                          {inspection?.inspectionTemplate?.image ? (
                            <Image
                              h="40px"
                              w="40px"
                              ml="8px"
                              src={inspection?.inspectionTemplate.image}
                              borderRadius="8px"
                            />
                          ) : (
                            <Avatar
                              w="40px"
                              h="40px"
                              ml="8px"
                              name={inspection?.inspectionTemplate?.title}
                              backgroundColor="primary.500"
                            />
                          )}
                          <Stack spacing={0}>
                            <HStack
                              divider={
                                <Text
                                  fontWeight={500}
                                  color="textcolors.primary"
                                  mx="1"
                                >
                                  /
                                </Text>
                              }
                            >
                              <Text fontWeight={500} color="textcolors.primary">
                                {moment(inspection?.inspectedOn).format(
                                  "DD MMM YYYY"
                                )}
                              </Text>

                              {inspection?.templateMeta?.site && (
                                <Text
                                  fontWeight={500}
                                  color="textcolors.primary"
                                >
                                  {inspection?.templateMeta?.site}
                                </Text>
                              )}
                              {inspection?.templateMeta?.person && (
                                <Text
                                  fontWeight={500}
                                  color="textcolors.primary"
                                >
                                  {inspection?.templateMeta?.person}
                                </Text>
                              )}
                            </HStack>
                            <Content lineHeight="16px" fontSize="14px" mt="4px">
                              {inspection?.inspectionTemplate?.title}
                            </Content>
                          </Stack>
                        </HStack>
                      </Td>
                      {showColumns.Actions && (
                        <Td
                          display={{ md: "none", lg: "table-cell" }}
                          py="16px"
                          pl="16px"
                          borderTop="1px"
                          borderColor="borders.accent"
                          fontSize="14px"
                          color="textcolors.secondary"
                        >
                          NA
                        </Td>
                      )}
                      {showColumns.Doc_number && (
                        <Td
                          py="16px"
                          pl="16px"
                          borderTop="1px"
                          borderColor="borders.accent"
                          fontSize="14px"
                          color="textcolors.secondary"
                        >
                          {inspection.templateMeta?.documentNumber
                            ? inspection.templateMeta.documentNumber
                            : "NA"}
                        </Td>
                      )}
                      {showColumns.Score && (
                        <Td
                          display={{ md: "none", lg: "table-cell" }}
                          py="16px"
                          pl="16px"
                          borderTop="1px"
                          borderColor="borders.accent"
                          fontSize="14px"
                          color="textcolors.secondary"
                        >
                          {`${Number(inspection.inspectionScore).toFixed(2)} %`}
                        </Td>
                      )}
                      {showColumns.Counducted && (
                        <Td
                          display={{ md: "none", lg: "table-cell" }}
                          p="16px"
                          borderTop="1px"
                          borderColor="borders.accent"
                          fontSize="14px"
                          color="textcolors.primary"
                        >
                          {moment(inspection.inspectedOn).format(
                            "DD MMM YY hh:mm A"
                          )}
                        </Td>
                      )}
                      {showColumns.Completed && (
                        <Td
                          display={{ md: "none", lg: "table-cell" }}
                          p="16px"
                          borderTop="1px"
                          borderColor="borders.accent"
                          fontSize="14px"
                          color="textcolors.primary"
                        >
                          {inspection.inspectionCompleted
                            ? "Completed"
                            : "Draft"}
                        </Td>
                      )}
                      <Td
                        display={{ md: "none", lg: "table-cell" }}
                        py="16px"
                        px="0px"
                        textAlign="start"
                        borderTop="1px"
                        borderColor="borders.accent"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Button
                          isDisabled={
                            !inspection.EDIT_PERMISSION &&
                            !inspection.inspectionCompleted
                          }
                          variant="ghost"
                          h="41px"
                          px="8px"
                          py="12px"
                          fontWeight={500}
                          fontSize="14px"
                          borderRadius="8px"
                          onClick={() => {
                            if (inspection.inspectionCompleted) {
                              navigate(`/app/auditor/report/${inspection.id}`);
                            } else {
                              navigate(
                                `/app/auditor/inspection-conduct/CONTINUE_${inspection.id}`
                              );
                            }
                          }}
                        >
                          {inspection.inspectionCompleted
                            ? "View report"
                            : "Continue"}
                        </Button>
                      </Td>
                      <Td
                        py="16px"
                        px="0px"
                        borderTop="1px"
                        borderColor="borders.accent"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            pl="12px"
                            aria-label="Options"
                            icon={<FiMoreVertical size={24} color="#545f70" />}
                            variant="outline"
                            borderRadius={"50%"}
                            border={"none"}
                            _hover={{ backgroundColor: "transparent" }}
                            _active={{
                              backgroundColor: "transparent",
                            }}
                          />
                          <MenuList minW="160px">
                            {menuitems.map((menu, i) => {
                              let isDisabled = false;
                              if (menu.label == "View report") {
                                if (!inspection.inspectionCompleted) {
                                  isDisabled = true;
                                }
                              } else if (menu.label == "Delete") {
                                if (!inspection.DELETE_PERMISSION) {
                                  isDisabled = true;
                                }
                              }
                              return (
                                <Fragment key={i}>
                                  <MenuItem
                                    isDisabled={isDisabled}
                                    px="14px"
                                    py="9.6px"
                                    color="textcolors.primary"
                                    onClick={() => {
                                      menu.onClick?.(inspection.id);
                                    }}
                                  >
                                    <HStack>
                                      <Icon
                                        as={menu.icon}
                                        boxSize={18}
                                        color="#1f2533"
                                      />
                                      <Text fontSize="14px" fontWeight={400}>
                                        {menu.label}
                                      </Text>
                                    </HStack>
                                  </MenuItem>
                                  <Modal
                                    isOpen={deleteModal.isOpen}
                                    onClose={deleteModal.onClose}
                                  >
                                    <ModalOverlay />
                                    <ModalContent mx="10px">
                                      <ModalHeader
                                        fontWeight="semibold"
                                        py="4px"
                                      >
                                        Confirmation
                                      </ModalHeader>
                                      <ModalCloseButton />
                                      <ModalBody>
                                        Are you sure you want to Delete this?
                                      </ModalBody>

                                      <ModalFooter
                                        display="flex"
                                        justifyContent="flex-end"
                                        pt="16px"
                                        border="0px"
                                      >
                                        <ButtonGroup
                                          size="xs"
                                          variant="solid"
                                          borderRadius="5px"
                                        >
                                          <Button
                                            onClick={deleteModal.onClose}
                                            borderRadius="5px"
                                          >
                                            No
                                          </Button>
                                          <Button
                                            colorScheme="red"
                                            borderRadius="5px"
                                            onClick={() => {
                                              Inspection.DeleteInspection(
                                                inspection.id,
                                                () => {
                                                  toast({
                                                    title: "Success",
                                                    description:
                                                      "Inspection deleted sucessfully",
                                                    status: "success",
                                                  });
                                                  setLastUpdate(Date.now());
                                                  deleteModal.onClose();
                                                },
                                                (error) => {
                                                  toast({
                                                    title: "Error",
                                                    description: error,
                                                    status: "error",
                                                  });
                                                  deleteModal.onClose();
                                                }
                                              );
                                            }}
                                          >
                                            Yes
                                          </Button>
                                        </ButtonGroup>
                                      </ModalFooter>
                                    </ModalContent>
                                  </Modal>
                                </Fragment>
                              );
                            })}
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                </Fragment>
              );
            })
          )}
        </Tbody>
        <BuildBottomInspectionArchive
          isOpen={buildbottomarchive.isOpen}
          onClose={buildbottomarchive.onClose}
          inspectionIds={selectedInspectionIds}
          setLastUpdate={setLastUpdate}
        />
      </Table>
    </TableContainer>
  );
}
