import {
  Button,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { Fragment } from "react";
import { BiMessageRoundedError } from "react-icons/bi";
import { GlobalResponseDrawerProvider } from "../../hooks/globalResponsesDrawer";
import Archive from "./components/Archive/Archive";
import Inventory from "./components/Inventory/Inventory";
import PublicLibrary from "./components/PublicLibrary/PublicLibrary";
import Response from "./components/Responses/Response";
import MainTemplate from "./components/templates/templates";

export default function TemplatesPage() {
  const futureImplementation = true;
  return (
    <Stack h="calc(100vh - 70px)">
      <Tabs isLazy>
        <HStack
          px="16px"
          pt="8px"
          borderBottom="1px"
          borderColor="borders.accent"
          justifyContent="space-between"
        >
          <TabList color="textcolors.primary" border="0px">
            {[
              "Templates",
              "Responses",
              "Public Library",
              "Inventory",
              "Archive",
            ].map((menu, i) => {
              // if (futureImplementation && menu === 'Public Library') {
              //   return null;
              // }
              return (
                <Fragment key={i}>
                  <Tab
                    fontSize="14px"
                    px="16px"
                    py="12px"
                    sx={{ borderBottomWidth: "4px" }}
                    // _selected={{borderwidth: '5px'}}
                  >
                    {menu}
                  </Tab>
                </Fragment>
              );
            })}
          </TabList>
          <Button
            display={{ xs: "none", md: "flex" }}
            variant="ghost"
            leftIcon={<BiMessageRoundedError size={16} />}
            px="16px"
            py="8px"
          >
            Send feedback
          </Button>
        </HStack>

        <TabPanels>
          <TabPanel p={0}>
            <MainTemplate />
          </TabPanel>
          <TabPanel p={0}>
            <GlobalResponseDrawerProvider>
              <Response />
            </GlobalResponseDrawerProvider>
          </TabPanel>
          {/* {!futureImplementation && ( */}
          <TabPanel p={0}>
            <PublicLibrary />
          </TabPanel>
          {/* )} */}
          <TabPanel p={0}>
            <Inventory />
          </TabPanel>
          <TabPanel p={0}>
            <Archive />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
