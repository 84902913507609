import { Button, Flex, Stack, Text } from "@chakra-ui/react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

interface WeekInspectionTagProps {
  startDate: string;
  dueDate: string;
  title: string;
  displayDate: boolean;
  templateId: number;
}

export default function WeekInspectionTag({
  startDate,
  dueDate,
  title,
  displayDate,
  templateId,
}: WeekInspectionTagProps) {
  const navigate = useNavigate();
  const currentDate = moment();
  const todaysDate = moment(currentDate).format("D MMM YYYY h:mm A");
  const startTime = moment(startDate).format("D MMM YYYY h:mm A");
  const endTime = moment(dueDate).format("D MMM YYYY h:mm A");
  const isStartDateValid = moment(startDate).isSame(currentDate, "day");
  const isInspectionAvailable = moment(todaysDate).isBetween(
    startTime,
    endTime,
    "minutes"
  );
  return (
    <Flex w="100%" flexDir={{ xs: "column", md: "row" }}>
      <Stack
        p="0px"
        borderBottom="0px"
        // flex={1}
        w={{ xs: "100%", md: "15%" }}
        textAlign="center"
        marginEnd="8px"
      >
        {displayDate && (
          <Stack>
            <Text
              fontSize="11px"
              fontWeight={500}
              color="icons.primary"
              textTransform="uppercase"
            >
              {moment(startDate)?.format("dddd")}
            </Text>
            <Text
              fontSize="20px"
              fontWeight={500}
              color="textcolors.primary"
              letterSpacing="-1.5px"
            >
              {moment(startDate)?.format("ll")}
            </Text>
          </Stack>
        )}
      </Stack>

      <Stack
        // onClick={onClicked}

        w={{ xs: "100%", md: "85%" }}
        border="1px"
        borderLeftWidth="4px"
        borderColor="primary.500"
        marginLeft={{ xs: "0px", md: "16px" }}
        spacing={0}
        direction="row"
        px="15px"
        py="12px"
      >
        <Stack spacing="2px" flex={1}>
          <Text fontSize="18px" fontWeight={500} color="textcolors.primary">
            {title}
          </Text>
          <Text
            fontSize="12px"
            fontWeight={400}
            color="icons.primary"
            lineHeight="1.375"
            display="block"
          >
            {`Starts on ${moment(startDate).format(
              "D MMM YYYY h:mm A"
            )} - Due on ${moment(dueDate).format("D MMM YYYY h:mm A")}`}
          </Text>
        </Stack>
        <Button
          h="41px"
          px="20px"
          py="12px"
          fontWeight={500}
          fontSize="14px"
          isDisabled={!isInspectionAvailable}
          borderRadius="8px"
          onClick={(e) => {
            navigate(`/app/auditor/inspection-conduct/NEW_${templateId}`);
            e.stopPropagation();
            console.log(
              todaysDate,
              startTime,
              endTime,
              isInspectionAvailable,
              isStartDateValid
            );
          }}
        >
          Start inspection
        </Button>
      </Stack>
    </Flex>
  );
}
