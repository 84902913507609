import {
  Box,
  Container,
  Icon,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import {
  Controller,
  FormProvider,
  UseFieldArrayReturn,
  useForm,
  UseFormReturn,
} from "react-hook-form";

import { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { BusinessTaxRate } from "../../../Api/BusinessTaxRate";
import { ChartOfAccounts } from "../../../Api/ChartOfAccounts";
import { AddInvoices } from "../../../Api/Invoices";

import { BsTrash } from "react-icons/bs";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { ProductAndService } from "../../../Api/ProductAndServices";
import Accmodal from "../../Accounting/AddAccount/Accmodal";
import { AddNewTax } from "../../Accounting/TaxRates/Modal/AddNewTax";
import { AddItem } from "../../Prooduct&Services/AddNewItem";
interface Props {
  form: UseFormReturn<AddInvoices.NewInvoiceData, any, undefined>;
  taxRateData: BusinessTaxRate.FetchTaxRate[];
  chartOfAccounts: ChartOfAccounts.FetchChartOfAccounts[];
  invoiceComponent: UseFieldArrayReturn<
    AddInvoices.NewInvoiceData,
    "items",
    "id"
  >;
  setLastUpdated: React.Dispatch<React.SetStateAction<number>>;
  setChartOfAccountLastUpdated: React.Dispatch<React.SetStateAction<number>>;
  setTaxRateLastUpdated: React.Dispatch<React.SetStateAction<number>>;
  setItemData: React.Dispatch<
    React.SetStateAction<ProductAndService.FetchAllProductAndService[]>
  >;
  itemData: ProductAndService.FetchAllProductAndService[];
  fieldsState: any;
}
export function TableComponent({
  form,
  invoiceComponent,
  chartOfAccounts,
  taxRateData,
  setItemData,
  itemData,
  setChartOfAccountLastUpdated,
  setTaxRateLastUpdated,
  fieldsState,
  setLastUpdated,
}: Props) {
  const chartOfAccountModal = useDisclosure();
  const taxRateModal = useDisclosure();
  const itemModal = useDisclosure();
  const [itemLastUpdated, setItemLastUpdated] = useState<number>(Date.now());
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const ItemForm = useForm<ProductAndService.AddProductAndService>({
    defaultValues: {
      code: undefined,
      name: undefined,
      description: undefined,
      purchaseInfo: {
        costPrice: undefined,
        purchaseAccountId: undefined,
        taxRate: undefined,
        description: undefined,
      },
      sellInfo: {
        salePrice: undefined,
        saleAccountId: undefined,
        taxRate: undefined,
        description: undefined,
      },
    },
  });

  const chartForm = useForm({
    defaultValues: {
      accountCategory: "",
      accountType: "",
      code: 0,
      name: "",
      description: "",
      businesTaxRateId: "",
      showDashboardWatchlist: false,
      showExpenseClaims: false,
      enablePayments: false,
    },
  });
  const taxRateForm = useForm({
    defaultValues: {
      id: "",
      name: "",
      tax_type: "",
      tax_components: [
        {
          name: "",
          tax: 0,
        },
      ],
    },
  });

  // Assuming you have the AddInvoices and UpdateItemInInvoices functions imported
  const handlePostForItem = (
    form: any, // Form control
    index: number // Index of the item in the form
  ) => {
    // Collect data from the form for the item at the given index
    const item = form.getValues(`items[${index}]`);

    const invoiceData = {
      itemId: item.itemId,
      quantity: item.quantity,
      price: item.price,
      description: item.description,
      amount: item.amount,
      discount: item.discount,
      accountId: item.accountId,
      taxRateId: item.taxRateId,
      taxAmount: item.taxAmount,
    };

    const invoiceId = form.getValues("id");

    // Ensure invoiceId exists before proceeding
    if (invoiceId !== undefined && invoiceId !== "" && invoiceId !== null) {
      // Check if the item_id exists in the form (if it's an existing item)
      const itemId = form.getValues(`items.${index}.id`);

      if (itemId && itemId !== "" && itemId !== null) {
        AddInvoices.UpdateItemInInvoices(
          invoiceData,
          invoiceId,
          itemId,
          (success) => {
            setLastUpdated(Date.now());
            setIsLoading(false);
            AddInvoices.UpdateInvoice(
              invoiceId!,
              form.getValues(),
              (success) => {},
              (err) => {
                setIsLoading(false);
              }
            );
          },
          (err) => {
            toast({
              title: "Error",
              description: err,
              status: "error",
            });
            setIsLoading(false);
          }
        );
      } else {
        // If itemId doesn't exist, create a new item
        AddInvoices.PostItemInInvoices(
          invoiceData,
          invoiceId,
          (success) => {
            setLastUpdated(Date.now());
            setIsLoading(false);
            AddInvoices.UpdateInvoice(
              invoiceId!,
              form.getValues(),
              (success) => {},
              (err) => {
                setIsLoading(false);
              }
            );
          },
          (err) => {
            toast({
              title: "Error",
              description: err,
              status: "error",
            });
            setIsLoading(false);
          }
        );
      }
    }
  };

  useEffect(() => {
    ProductAndService.GetProductAndServiceItemDetails(
      {
        page: 1,
        limit: 100,
        isActive: true,
      },
      (data) => {
        setItemData(data.data);
        setIsLoading(false);
      },
      (err) => {
        toast({
          title: "Error",
          description: err,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, [itemLastUpdated]);

  return (
    <Container
      maxW={"100%"}
      overflowX={"auto"}
      padding={0}
      boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      borderColor="#f6f6f6"
      borderWidth="1px"
      borderStyle={"solid"}
    >
      <Skeleton isLoaded={!isLoading} height="100%">
        <Box overflowX="auto">
          <Table variant="simple" width="100%" minWidth="1500px">
            <Thead h={"36.8px"}>
              <Tr
                color={"customColor.black7"}
                _dark={{
                  color: "customColor.dark.150",
                }}
              >
                {[
                  {
                    name: "#",
                    value: "2%",
                    paddingValue: "8px 15px 8px 30px",
                  },
                  {
                    name: "Item",
                    value: "15%",
                    paddingValue: "8px 15px",
                    field: "Items",
                  },

                  {
                    name: "Description",
                    value: "20%",
                    paddingValue: "8px 15px",
                  },

                  {
                    name: " Qty.",
                    value: "6%",
                    paddingValue: "8px 15px",
                    field: "Quantity",
                  },
                  {
                    name: "Price.",
                    value: "6%",
                    paddingValue: "8px 15px",
                    field: "Price",
                  },
                  {
                    name: "Discount.",
                    value: "6%",
                    paddingValue: "8px 15px",
                    field: "Discount",
                  },
                  {
                    name: "Tax Amount.",
                    value: "6%",
                    paddingValue: "8px 15px",
                    field: "Tax Amount",
                  },
                  {
                    name: "Account.",
                    value: "15%",
                    paddingValue: "8px 15px",
                  },
                  {
                    name: "Tax rate.",
                    value: "15%",
                    paddingValue: "8px 15px",
                  },

                  {
                    name: "Amount.",
                    value: "9%",
                    paddingValue: "8px 15px",
                  },

                  ...(invoiceComponent.fields.length > 1 &&
                  form.getValues("id") === undefined
                    ? [
                        {
                          name: "",
                          value: "1%",
                          paddingValue: "1px",
                        },
                      ]
                    : []),
                ].map((item, i) => {
                  if (
                    (item.field === "Items" && fieldsState["Items"]) ||
                    (item.field === "Discount" && fieldsState["Discount"]) ||
                    (item.field === "Quantity" && fieldsState["Quantity"]) ||
                    (item.field === "Price" && fieldsState["Price"]) ||
                    (item.field === "Tax Amount" && fieldsState["Tax Amount"])
                  ) {
                    return (
                      <Th
                        key={i}
                        padding={item.paddingValue}
                        bg={"#fff"}
                        borderRight={"1px"}
                        borderRightColor={"borders.tableBorder"}
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                        width={item.value}
                      >
                        {item.name}
                      </Th>
                    );
                  }

                  // Always render other headers without condition
                  if (item.field === undefined) {
                    return (
                      <Th
                        key={i}
                        padding={item.paddingValue}
                        bg={"#fff"}
                        borderRight={item.name === "Amount" ? "0px" : "1px"}
                        borderRightColor={
                          item.name === "Amount"
                            ? "none"
                            : "borders.tableBorder"
                        }
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                        width={item.value}
                      >
                        {item.name}
                      </Th>
                    );
                  }

                  return null;
                })}
              </Tr>
            </Thead>
            <Tbody padding={0}>
              {invoiceComponent.fields.map((field, index) => (
                <Tr key={field.id}>
                  <Td
                    padding="15px 15px 15px 30px"
                    bg={"#fff"}
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    whiteSpace="nowrap"
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                    borderRight={"1px"}
                    borderRightColor={"borders.tableBorder"}
                  >
                    {index}
                  </Td>

                  {fieldsState["Items"] && (
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                      borderRight={"1px"}
                      borderRightColor={"borders.tableBorder"}
                    >
                      <Controller
                        name={`items.${index}.itemId`}
                        control={form.control}
                        render={({ field, fieldState: { error } }) => {
                          // Track if the itemId has been changed (dirty)
                          const isDirty =
                            form.formState.dirtyFields?.items?.[index]?.itemId;

                          const handleSelect = (selectedOption: any) => {
                            if (selectedOption?.value === "add_new_item") {
                              // Reset the Item form for new entry
                              ItemForm.reset({
                                code: undefined,
                                name: undefined,
                                description: undefined,
                                purchaseInfo: {
                                  costPrice: undefined,
                                  purchaseAccountId: undefined,
                                  taxRate: undefined,
                                  description: undefined,
                                },
                                sellInfo: {
                                  salePrice: undefined,
                                  saleAccountId: undefined,
                                  taxRate: undefined,
                                  description: undefined,
                                },
                              });

                              itemModal.onOpen(); // Open the modal to add a new item
                            } else {
                              field.onChange(selectedOption?.value);

                              const selectedItem = itemData?.find(
                                (acc) => acc.id === selectedOption?.value
                              );

                              if (selectedItem) {
                                const descriptionValue =
                                  selectedItem.description || undefined;

                                // Set additional values in the form based on the selected item
                                form.setValue(
                                  `items.${index}.description`,
                                  descriptionValue
                                );
                                form.setValue(
                                  `items.${index}.accountId`,
                                  selectedItem.sellInfo.saleAccountId
                                );
                                form.setValue(
                                  `items.${index}.taxRateId`,
                                  selectedItem.sellInfo.taxRate
                                );
                              }

                              // If the itemId is dirty (changed), trigger the post function
                            }
                          };

                          // Generate options from the itemData array
                          const itemOptions = itemData?.map((item: any) => ({
                            value: item.id,
                            label: `${item.code}:${item.name}`,
                          }));

                          // Add the "Add Item" option
                          const addItemOption = {
                            value: "add_new_item",
                            label: "Add Item",
                          };

                          return (
                            <Stack>
                              <ReactSelect
                                options={[addItemOption, ...itemOptions]} // Add the 'Add Item' option
                                value={
                                  itemOptions.find(
                                    (option) => option.value === field.value
                                  ) || null
                                }
                                onChange={handleSelect}
                                onBlur={() => {
                                  if (isDirty) {
                                    handlePostForItem(form, index);
                                  }
                                }}
                                isDisabled={form.getValues("status") === "Paid"}
                                placeholder="Select"
                              />
                            </Stack>
                          );
                        }}
                      />
                    </Td>
                  )}

                  <Td
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                    borderRight={"1px"}
                    borderRightColor={"borders.tableBorder"}
                  >
                    <Controller
                      name={`items.${index}.description`}
                      control={form.control}
                      render={({ field, fieldState: { error } }) => {
                        const isDirty =
                          form.formState.dirtyFields?.items?.[index]
                            ?.description;

                        return (
                          <CustomInput
                            ref={field.ref}
                            withValidation
                            input={{
                              isReadOnly: form.getValues("status") === "Paid",
                              ...field,
                              h: "38px",
                              placeholder: "",
                              onBlur: () => {
                                // Check if the value has changed
                                if (isDirty) {
                                  handlePostForItem(form, index);
                                }
                              },
                              padding: "12px 5px",
                              _light: {
                                color: "customColor.black7",
                              },
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "text",
                              borderRadius: "3px",
                              bgColor: "#fff",
                            }}
                          />
                        );
                      }}
                    />
                  </Td>
                  {fieldsState["Quantity"] && (
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                      borderRight={"1px"}
                      borderRightColor={"borders.tableBorder"}
                    >
                      <Controller
                        name={`items.${index}.quantity`}
                        control={form.control}
                        render={({ field, fieldState: { error } }) => {
                          // Track if the quantity field has been modified (dirty)
                          const isDirty =
                            form.formState.dirtyFields?.items?.[index]
                              ?.quantity;

                          return (
                            <CustomInput
                              ref={field.ref}
                              withValidation
                              input={{
                                h: "38px",
                                isReadOnly: form.getValues("status") === "Paid",
                                ...field,
                                placeholder: "",
                                onBlur: () => {
                                  // If the quantity field is dirty, trigger the post function
                                  if (isDirty) {
                                    handlePostForItem(form, index);
                                  }
                                },
                                onChange: (e) => {
                                  const qtyValue = Number(e.target.value);
                                  field.onChange(qtyValue);

                                  // Calculate the amount based on quantity and price
                                  const amount =
                                    (qtyValue ?? 0) *
                                      (form.watch(`items.${index}.price`) ??
                                        0) -
                                    (form.watch(`items.${index}.discount`) ??
                                      0);
                                  const roundedAmount = parseFloat(
                                    amount.toFixed(2)
                                  );
                                  form.setValue(
                                    `items.${index}.amount`,
                                    roundedAmount
                                  );
                                },
                                padding: "12px 5px",
                                _light: {
                                  color: "customColor.black7",
                                },
                                fontSize: ".845rem",
                                variant: "outline",
                                type: "number",
                                borderRadius: "3px",
                                bgColor: "#fff",
                                isDisabled: form.getValues("status") === "Paid",
                              }}
                            />
                          );
                        }}
                      />
                    </Td>
                  )}

                  {fieldsState["Price"] && (
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                      borderRight={"1px"}
                      borderRightColor={"borders.tableBorder"}
                    >
                      <Controller
                        name={`items.${index}.price`}
                        control={form.control}
                        render={({ field, fieldState: { error } }) => {
                          // Track if the price field has been modified (dirty)
                          const isDirty =
                            form.formState.dirtyFields?.items?.[index]?.price;

                          return (
                            <CustomInput
                              ref={field.ref}
                              withValidation
                              input={{
                                h: "38px",
                                isReadOnly: form.getValues("status") === "Paid",
                                // Display formatted value with commas
                                value: field.value
                                  ? Number(field.value).toLocaleString()
                                  : "",
                                placeholder: "",
                                onBlur: () => {
                                  // If the price field is dirty, trigger the post function
                                  if (isDirty) {
                                    handlePostForItem(form, index);
                                  }
                                },
                                onChange: (e) => {
                                  // Remove commas and convert to number
                                  const value = e.target.value.replace(
                                    /,/g,
                                    ""
                                  );

                                  // Set the value for the price field
                                  field.onChange(Number(value));

                                  // Calculate the amount based on price and quantity
                                  const priceValue = Number(value);
                                  const amount =
                                    (form.watch(`items.${index}.quantity`) ??
                                      0) *
                                      (priceValue ?? 0) -
                                    (form.watch(`items.${index}.discount`) ??
                                      0);

                                  const roundedAmount = parseFloat(
                                    amount.toFixed(2)
                                  );

                                  // Set the calculated amount for this item
                                  form.setValue(
                                    `items.${index}.amount`,
                                    roundedAmount
                                  );
                                },
                                padding: "12px 5px",
                                _light: {
                                  color: "customColor.black7",
                                },
                                fontSize: ".845rem",
                                variant: "outline",
                                type: "text",
                                borderRadius: "3px",
                                bgColor: "#fff",
                                isDisabled: form.getValues("status") === "Paid",
                              }}
                            />
                          );
                        }}
                      />
                    </Td>
                  )}

                  {fieldsState["Discount"] && (
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                      borderRight={"1px"}
                      borderRightColor={"borders.tableBorder"}
                    >
                      <Controller
                        name={`items.${index}.discount`}
                        control={form.control}
                        render={({ field, fieldState: { error } }) => {
                          // Track if the price field has been modified (dirty)
                          const isDirty =
                            form.formState.dirtyFields?.items?.[index]
                              ?.discount;

                          return (
                            <CustomInput
                              ref={field.ref}
                              withValidation
                              input={{
                                h: "38px",
                                isReadOnly: form.getValues("status") === "Paid",
                                ...field,
                                placeholder: "",
                                onBlur: () => {
                                  // If the price field is dirty, trigger the post function
                                  if (isDirty) {
                                    handlePostForItem(form, index);
                                  }
                                },
                                value: field.value
                                  ? Number(field.value).toLocaleString()
                                  : "",
                                onChange: (e) => {
                                  const value = e.target.value.replace(
                                    /,/g,
                                    ""
                                  );
                                  const discountValue = Number(value);

                                  // Calculate the total amount based on price * quantity
                                  const quantity =
                                    form.watch(`items.${index}.quantity`) ?? 0;
                                  const price =
                                    form.watch(`items.${index}.price`) ?? 0;
                                  const amount = quantity * price;

                                  // Ensure that the discount does not exceed the total amount (price * quantity)
                                  const validDiscount =
                                    discountValue > amount
                                      ? amount
                                      : discountValue;

                                  // Set the discount value in the form
                                  field.onChange(validDiscount);

                                  // Calculate the discounted amount
                                  const discountedAmount =
                                    amount - validDiscount;

                                  // Round to 2 decimal places and update the value
                                  const roundedAmount = !isNaN(discountedAmount)
                                    ? parseFloat(discountedAmount.toFixed(2))
                                    : 0;

                                  // Update the amount in the form state
                                  form.setValue(
                                    `items.${index}.amount`,
                                    roundedAmount
                                  );
                                },
                                padding: "12px 5px",
                                _light: {
                                  color: "customColor.black7",
                                },
                                fontSize: ".845rem",
                                variant: "outline",
                                type: "text",
                                borderRadius: "3px",
                                bgColor: "#fff",
                                isDisabled: form.getValues("status") === "Paid",
                              }}
                            />
                          );
                        }}
                      />
                    </Td>
                  )}
                  {fieldsState["Tax Amount"] && (
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                      borderRight={"1px"}
                      borderRightColor={"borders.tableBorder"}
                    >
                      <Controller
                        name={`items.${index}.taxAmount`}
                        control={form.control}
                        render={({ field, fieldState: { error } }) => {
                          // Track if the price field has been modified (dirty)
                          const isDirty =
                            form.formState.dirtyFields?.items?.[index]
                              ?.discount;

                          return (
                            <CustomInput
                              ref={field.ref}
                              withValidation
                              input={{
                                h: "38px",
                                isReadOnly: true,
                                ...field,
                                placeholder: "",
                                onBlur: () => {
                                  // If the price field is dirty, trigger the post function
                                  if (isDirty) {
                                    handlePostForItem(form, index);
                                  }
                                },
                                value: field.value
                                  ? Number(field.value).toLocaleString()
                                  : "",
                                onChange: (e) => {
                                  const value = e.target.value.replace(
                                    /,/g,
                                    ""
                                  );

                                  // Set the value for the price field
                                  field.onChange(Number(value));
                                },
                                padding: "12px 5px",
                                _light: {
                                  color: "customColor.black7",
                                },
                                fontSize: ".845rem",
                                variant: "outline",
                                type: "text",
                                borderRadius: "3px",
                                bgColor: "#fff",
                                isDisabled: form.getValues("status") === "Paid",
                              }}
                            />
                          );
                        }}
                      />
                    </Td>
                  )}
                  <Td
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                    borderRight={"1px"}
                    borderRightColor={"borders.tableBorder"}
                  >
                    <Controller
                      name={`items.${index}.accountId`}
                      control={form.control}
                      render={({ field, fieldState }) => {
                        // Track if the accountId field has been modified (dirty)
                        const isDirty =
                          form.formState.dirtyFields?.items?.[index]?.accountId;

                        const accountOptions = chartOfAccounts.map(
                          (acc: any) => ({
                            value: acc.id,
                            label: `${acc.code}: ${acc.name}`,
                          })
                        );

                        const handleChange = (selectedOption: any) => {
                          field.onChange(selectedOption?.value);

                          // Open modal if 'Add Chart Of Account' option is selected
                          if (selectedOption?.value === "add_new_account") {
                            chartForm.reset({
                              accountCategory: "",
                              accountType: undefined,
                              code: undefined,
                              name: undefined,
                              description: undefined,
                              businesTaxRateId: undefined,
                              showDashboardWatchlist: false,
                              showExpenseClaims: false,
                              enablePayments: false,
                            });
                            chartOfAccountModal.onOpen(); // Open the modal
                          }

                          // Trigger handlePostForItem only if the accountId field is dirty
                        };

                        const addAccountOption = {
                          value: "add_new_account",
                          label: "Add Chart Of Account",
                        };

                        return (
                          <Stack>
                            <ReactSelect
                              options={[addAccountOption, ...accountOptions]} // Add the 'Add Chart Of Account' option
                              value={
                                accountOptions.find(
                                  (option) => option.value === field.value
                                ) || null
                              }
                              onChange={handleChange}
                              onBlur={() => {
                                if (isDirty) {
                                  handlePostForItem(form, index);
                                }
                              }}
                              isDisabled={form.getValues("status") === "Paid"}
                              placeholder="Select"
                            />
                          </Stack>
                        );
                      }}
                    />
                  </Td>
                  <Td
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                    borderRight={"1px"}
                    borderRightColor={"borders.tableBorder"}
                  >
                    <Controller
                      name={`items.${index}.taxRateId`}
                      control={form.control}
                      render={({ field, fieldState }) => {
                        // Track if the taxRateId field has been modified (dirty)
                        const isDirty =
                          form.formState.dirtyFields?.items?.[index]?.taxRateId;

                        const handleSelect = (selectedOption: any) => {
                          if (selectedOption?.value === "add_new_tax_rate") {
                            // Reset the tax rate form for new entry
                            taxRateForm.reset({
                              id: "",
                              name: "",
                              tax_type: "",
                              tax_components: [
                                {
                                  name: "",
                                  tax: 0,
                                },
                              ],
                            });
                            taxRateModal.onOpen(); // Open the modal to add a new tax rate
                          } else {
                            field.onChange(selectedOption?.value);
                          }

                          // Trigger handlePostForItem only if the taxRateId field is dirty
                        };

                        const taxRateOptions = taxRateData?.map(
                          (taxRate: any) => ({
                            value: taxRate.id,
                            label: `${
                              taxRate.name
                            } (${taxRate.tax_components.reduce(
                              (sum: any, component: any) => sum + component.tax,
                              0
                            )}%)`,
                          })
                        );

                        const addTaxRateOption = {
                          value: "add_new_tax_rate",
                          label: "Add Tax Rate",
                        };

                        return (
                          <Stack>
                            <ReactSelect
                              options={[addTaxRateOption, ...taxRateOptions]} // Add the 'Add Tax Rate' option
                              value={
                                taxRateOptions.find(
                                  (option) => option.value === field.value
                                ) || null
                              }
                              onChange={handleSelect}
                              onBlur={() => {
                                if (isDirty) {
                                  handlePostForItem(form, index);
                                }
                              }}
                              isDisabled={form.getValues("status") === "Paid"}
                              placeholder="Select"
                            />
                          </Stack>
                        );
                      }}
                    />
                  </Td>

                  <Td
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                    borderRight={"1px"}
                    borderRightColor={"borders.tableBorder"}
                  >
                    <Controller
                      name={`items.${index}.amount`}
                      control={form.control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          withValidation
                          input={{
                            h: "38px",
                            ...field,
                            placeholder: "",
                            isReadOnly: true,
                            padding: "12px 5px",
                            _light: {
                              color: "customColor.black7",
                            },
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "text",
                            borderRadius: "3px",
                            bgColor: "#fff",
                            value: field.value
                              ? Number(field.value).toLocaleString()
                              : "",
                            onChange: (e) => {
                              const value = e.target.value.replace(/,/g, "");

                              // Set the value for the price field
                              field.onChange(Number(value));
                              const quantity = form.watch(
                                `items.${index}.quantity`
                              )!;
                              const price = form.watch(`items.${index}.price`)!;
                              const discount =
                                form.watch(`items.${index}.discount`) ?? 0; // Get the discount value from the form

                              let amount = quantity * price;

                              // Apply discount
                              const discountedAmount = amount - discount;

                              // Round to 2 decimal places and update the value
                              const roundedAmount = !isNaN(discountedAmount)
                                ? parseFloat(discountedAmount.toFixed(2))
                                : 0;

                              form.setValue(
                                `items.${index}.amount`,
                                roundedAmount
                              );
                            },
                          }}
                        />
                      )}
                    />
                  </Td>

                  {invoiceComponent.fields.length > 1 &&
                    (form.getValues("id") === undefined ||
                      form.getValues(`items.${index}.id`) === undefined) && (
                      <Td
                        bg={"#fff"}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                        borderRight={"1px"}
                        borderRightColor={"borders.tableBorder"}
                        padding={"8px 10px 8px 10px"}
                        onClick={() => {
                          invoiceComponent.remove(index);
                          console.log(index);
                        }}
                      >
                        <Icon
                          fontSize={"20px"}
                          as={IoIosCloseCircleOutline}
                          color={"grey"}
                          _hover={{
                            color: "red.500",
                          }}
                        />
                      </Td>
                    )}
                  {form.getValues("id") &&
                    form.getValues(`items.${index}.id`) !== undefined && (
                      <Td
                        bg={"#fff"}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                        borderRight={"1px"}
                        borderRightColor={"borders.tableBorder"}
                        padding={"8px 10px 8px 10px"}
                        onClick={() => {
                          setIsLoading(true);
                          AddInvoices.DeleteItemsInInvoices(
                            form.getValues("id")!,
                            form.getValues(`items.${index}.id`)!,
                            (success) => {
                              // window.location.reload();
                              setIsLoading(false);
                              setLastUpdated(Date.now());
                              AddInvoices.UpdateInvoice(
                                form.getValues("id")!,
                                form.getValues(),
                                (success) => {},
                                (err) => {
                                  toast({
                                    title: "Error",
                                    description: err,
                                    status: "error",
                                  });
                                  setIsLoading(false);
                                }
                              );
                            },
                            () => {
                              setIsLoading(false);
                            }
                          );
                        }}
                      >
                        <Icon
                          fontSize={"20px"}
                          as={BsTrash}
                          color={"grey"}
                          _hover={{
                            color: "red.500",
                          }}
                        />
                      </Td>
                    )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Skeleton>

      <FormProvider {...chartForm}>
        <Accmodal
          isOpen={chartOfAccountModal.isOpen}
          onClose={chartOfAccountModal.onClose}
          setLastUpdated={setChartOfAccountLastUpdated}
          taxRateData={taxRateData}
        />
      </FormProvider>
      <FormProvider {...taxRateForm}>
        <AddNewTax
          isOpen={taxRateModal.isOpen}
          onClose={taxRateModal.onClose}
          setLastUpdated={setTaxRateLastUpdated}
        />
        <FormProvider {...ItemForm}>
          <AddItem
            isOpen={itemModal.isOpen}
            onClose={itemModal.onClose}
            setLastUpdated={setItemLastUpdated}
          />
        </FormProvider>
      </FormProvider>
    </Container>
  );
}
