import {
  Button,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { FormProvider, useForm } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { useLocation, useSearchParams } from "react-router-dom";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { TemplateMeta } from "../../modules/Audit";
import { ScheduleInspectionModule } from "../../modules/Audit/ScheduleInspectionModule";
import SubHeading from "../template/pages/Sub-Heading";
import Schedule from "./Component/ManageSchedules/Schedule";
import WeeklyInspectionList from "./Component/ManageSchedules/WeeklyInspectionList/WeeklyInspectionList";
import ScheduleInspectionModal from "./Component/Modals/ScheduleInspectionModal";

export default function ScheduleInspection() {
  const { state } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: state?.triggerCreateModal,
  });
  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const [templatesList, setTemplatesList] = useState<
    Array<TemplateMeta.TemplateMeta>
  >([]);
  const { allUsers, locationList } = useBusinessContext();
  const user = useAuthUser();
  const UserDetails = user();
  const [searchParam] = useSearchParams();
  const form = useForm<ScheduleInspectionModule.ScheduleMeta>({
    defaultValues: {
      title: "",
      allowedAfterTime: false,
      days: [],
      employeeId: "",
      frequency: "",
      locationId: "",
      numberOfDays: 0,
      timeRange: {
        after: "",
        before: "",
      },
      templateId: +(searchParam.get("templateId") ?? 0),
    },
  });
  const toast = useToast();
  useEffect(() => {
    TemplateMeta.Fetch(
      { archive: false },
      (data) => {
        setTemplatesList(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, []);
  return (
    <FormProvider {...form}>
      <Stack h="calc(100vh - 70px)">
        <HStack px="32px" pt="16px" justifyContent="space-between">
          <SubHeading fontSize="24px" px={{ xs: "16px", md: "0px" }}>
            Schedule
          </SubHeading>

          <Button
            h="41px"
            iconSpacing={1}
            leftIcon={<AiOutlinePlus />}
            px="16px"
            py="8px"
            fontSize="14px"
            fontWeight={400}
            lineHeight="22px"
            borderRadius="8px"
            onClick={() => {
              form.reset({
                title: "",
                allowedAfterTime: false,
                days: [],
                employeeId: undefined,
                frequency: undefined,
                locationId: undefined,
                numberOfDays: 0,
                timeRange: {
                  after: "",
                  before: "",
                },
                templateId: 0,
              });

              onOpen();
            }}
          >
            Schedule Inspection
          </Button>
        </HStack>
        <ScheduleInspectionModal
          isOpen={isOpen}
          onClose={onClose}
          templatesList={templatesList}
          setLastUpdate={setLastUpdate}
        />
        <Tabs>
          <HStack
            px="16px"
            pt="8px"
            borderBottom="1px"
            borderColor="borders.accent"
            justifyContent="space-between"
          >
            <TabList color="textcolors.primary" border="0px">
              {["My Schedule", "Manage Schedules"].map((menu, i) => {
                return (
                  <Fragment key={i}>
                    <Tab
                      fontSize="14px"
                      px="16px"
                      py="12px"
                      sx={{ borderBottomWidth: "4px" }}
                    >
                      {menu}
                    </Tab>
                  </Fragment>
                );
              })}
            </TabList>
          </HStack>

          <TabPanels>
            <TabPanel p={{ xs: "4px", md: "16px" }}>
              <WeeklyInspectionList lastUpdate={lastUpdate} />
            </TabPanel>
            <TabPanel p={0}>
              <Schedule
                templatesList={templatesList}
                setLastUpdate={setLastUpdate}
                lastUpdate={lastUpdate}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </FormProvider>
  );
}
