import {
  AbsoluteCenter,
  Box,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { createContext, useContext, useEffect, useState } from "react";
import { AccessControl } from "../api/AccessControl";
import { flattenNestedObject } from "../utils/flattenNestedObject";
import { useBusinessContext } from "./BusinessContext";

const INIT_PERMISSIONS: AccessControlContextProps = {
  "onDemandNotifications.onDemandNotifications": false,
  "contacts.allContacts": false,
  "employee.financialDetails": false,
  "employee.personalDetails": false,
  "employee.employees": false,
  "employee.leave": false,
  "expenseClaim.expenseClaim": false,
  "timeAndAttendance.locations": false,
  "timeAndAttendance.notifications": false,
  "timeAndAttendance.schedules": false,
  "timeAndAttendance.checkinReport": false,
  "timeAndAttendance.statistics": false,
  "auditor.templates": false,
  "auditor.inspections": false,
  "auditor.schedule": false,
  "auditor.periodicalSchedule": false,
  "auditor.periodicalReports": false,
  "auditor.notificationSettings": false,
  "payroll.payrollSettings": false,
  "payroll.payRuns": false,
  "payroll.timesheets": false,
  "reports.employeeDetails": false,
  "reports.employeeContactDetails": false,
  "reports.employeeRemuneration": false,
  "reports.leaveRequests": false,
  "reports.leaveBalance": false,
  "reports.payrollActivityDetail": false,
  "reports.payrollActivitySummary": false,
  "reports.payrollEmployeeSummary": false,
  "reports.attendanceReport": false,
  "reports.transactionListingDetail": false,
  "reports.transactionListingSummary": false,
  "statuaryBenefits.statuaryBenefits": false,
  "business.billsToPay": false,
  "business.invoices": false,
  "business.productsAndServices": false,
  "accounting.chartOfAccounts": false,
  "accounting.taxRates": false,
  "businessSettings.businessSetup": false,
  "businessSettings.users": false,
  "businessSettings.mappingWidow": false,
  "businessSettings.payrollSettings": false,
  "businessSettings.invoiceSettings": false,
  "businessSettings.editBusinessPermission": false,
};

export interface AccessControlContextProps {
  "onDemandNotifications.onDemandNotifications": boolean;
  "contacts.allContacts": boolean;
  "employee.personalDetails": boolean;
  "employee.financialDetails": boolean;
  "employee.employees": boolean;
  "employee.leave": boolean;
  "expenseClaim.expenseClaim": boolean;
  "timeAndAttendance.locations": boolean;
  "timeAndAttendance.schedules": boolean;
  "timeAndAttendance.notifications": boolean;
  "timeAndAttendance.checkinReport": boolean;
  "timeAndAttendance.statistics": boolean;
  "auditor.templates": boolean;
  "auditor.inspections": boolean;
  "auditor.schedule": boolean;
  "auditor.periodicalSchedule": boolean;
  "auditor.periodicalReports": boolean;
  "auditor.notificationSettings": boolean;
  "payroll.payRuns": boolean;
  "payroll.timesheets": boolean;
  "payroll.payrollSettings": boolean;
  "reports.employeeDetails": boolean;
  "reports.employeeContactDetails": boolean;
  "reports.employeeRemuneration": boolean;
  "reports.leaveRequests": boolean;
  "reports.leaveBalance": boolean;
  "reports.payrollActivityDetail": boolean;
  "reports.payrollActivitySummary": boolean;
  "reports.payrollEmployeeSummary": boolean;
  "reports.attendanceReport": boolean;
  "reports.transactionListingDetail": boolean;
  "reports.transactionListingSummary": boolean;
  "statuaryBenefits.statuaryBenefits": boolean;
  "business.billsToPay": boolean;
  "business.invoices": boolean;
  "business.productsAndServices": boolean;
  "accounting.taxRates": boolean;
  "accounting.chartOfAccounts": boolean;
  "businessSettings.businessSetup": boolean;
  "businessSettings.users": boolean;
  "businessSettings.mappingWidow": boolean;
  "businessSettings.payrollSettings": boolean;
  "businessSettings.invoiceSettings": boolean;
  "businessSettings.editBusinessPermission": boolean;
}
export const AccessControlContext =
  createContext<AccessControlContextProps>(INIT_PERMISSIONS);

interface AccessControlProviderProps {
  children: React.ReactNode;
}
export function AccessControlProvider({
  children,
}: AccessControlProviderProps) {
  const toast = useToast({
    position: "top",
    status: "error",
    title: "Error",
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [flattenPermissions, setFlattenPermissions] =
    useState<AccessControlContextProps>(INIT_PERMISSIONS);
  const { currentBusinessId } = useBusinessContext();

  useEffect(() => {
    AccessControl.GetLoggedInUserPermissions(
      (permission) => {
        const plainPermissions = flattenNestedObject(
          permission.allowedFeatures
        );

        setFlattenPermissions(plainPermissions);
        setIsLoaded(true);
      },
      (error) => {
        toast({ description: error });
        setIsLoaded(true);
      }
    );
  }, [currentBusinessId]);
  return (
    <AccessControlContext.Provider value={flattenPermissions}>
      {isLoaded ? (
        children
      ) : (
        <Box w="100vw" h="100vh">
          <AbsoluteCenter>
            <VStack>
              <Spinner size="xl" />
              <Text>Checking Permissions</Text>
            </VStack>
          </AbsoluteCenter>
        </Box>
      )}
    </AccessControlContext.Provider>
  );
}

export function useAccessControl() {
  const context = useContext<AccessControlContextProps>(AccessControlContext);

  return context;
}
