import { Text, TextProps } from '@chakra-ui/react';
import React from 'react';

export function MainHeading({ children, ...props }: TextProps) {
  return (
    <Text
      color="textcolors.primary"
      fontSize="22px"
      fontWeight={500}
      lineHeight={1.2}
      {...props}
    >
      {children}
    </Text>
  );
}
export default MainHeading;
