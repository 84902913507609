import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useState } from "react";
import { AiOutlineClose, AiOutlineQrcode } from "react-icons/ai";
import { BsBookmarkFill } from "react-icons/bs";
import { FiMoreVertical } from "react-icons/fi";
import { HiDuplicate } from "react-icons/hi";
import { IoMdArchive } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useBusinessContext } from "../../../../../../../Hooks/BusinessContext";
import {
  Inspection,
  Template,
  TemplateMeta,
} from "../../../../../../modules/Audit";
import Content from "../../../Content";
import SubHeading from "../../../Sub-Heading";

interface templateprops {
  isOpen: boolean;
  onClose: () => void;
  temp: TemplateMeta.TemplateMeta | undefined;
  futureImplemetation: boolean;
  setLastUpdate: (timestamp: number) => void;
}
export default function TemplateDrawer({
  isOpen,
  onClose,
  temp,
  futureImplemetation,
  setLastUpdate,
}: templateprops) {
  const { allUsers } = useBusinessContext();
  const navigate = useNavigate();
  const toast = useToast();
  const [isCreatingInspection, setIsCreatingInspection] = useState(false);
  const { setLastUpdatedLocations } = useBusinessContext();
  const menuitems: Array<{
    label: string;
    icon: any;
    onClick?: (...args: any) => void;
  }> = [
    {
      label: "Duplicate",
      icon: HiDuplicate,
      onClick: (templateId: number) => {
        const templateData: Template.duplicateTemplate = {
          templateId: templateId,
        };
        Template.CreateDuplicate(
          templateData,
          () => {
            toast({
              title: "Duplicate Template Created",
              status: "success",
            });
            setLastUpdate(Date.now());
            onClose();
          },
          (error) => {
            toast({
              title: "Error",
              description: error,
              status: "error",
            });
          }
        );
      },
    },
    {
      label: "Archive",
      icon: IoMdArchive,
      onClick: (templateId: number) => {
        const archiveTemplate: Partial<TemplateMeta.TemplateMeta> = {
          archive: true,
        };

        TemplateMeta.Update(
          templateId,
          archiveTemplate,
          () => {
            toast({
              title: "Template Archived",
              status: "success",
            });
            setLastUpdate(Date.now());
            onClose();
          },
          (error) => {
            toast({
              title: "Error",
              description: error,
              status: "error",
            });
          }
        );
      },
    },
    {
      label: "Bookmark",
      icon: BsBookmarkFill,
    },
    {
      label: "Get QR code",
      icon: AiOutlineQrcode,
    },
  ];
  if (futureImplemetation) {
    menuitems.splice(-2);
  }
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size={{ xs: "full", sm: "md" }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody bgColor="backgrounds.header" px="0px">
          <Stack
            spacing="32px"
            pt="16px"
            pb="24px"
            px="24px"
            borderBottom="1px"
            borderColor="borders.accent"
            bgColor="white"
          >
            <HStack>
              <IconButton
                aria-label="close"
                icon={<AiOutlineClose size={24} color="#545F70" />}
                backgroundColor="transparent"
                _hover={{ backgroundColor: "transparent" }}
                _active={{
                  backgroundColor: "transparent",
                }}
                onClick={onClose}
              />
              <Text
                align="center"
                fontSize="16px"
                fontWeight={500}
                color="textcolors.primary"
                flex={1}
              >
                Inspection Template
              </Text>
              <Menu>
                <MenuButton
                  as={IconButton}
                  pl="12px"
                  aria-label="Options"
                  icon={<FiMoreVertical size={24} color="#545f70" />}
                  variant="outline"
                  borderRadius={"50%"}
                  border={"none"}
                  _hover={{ backgroundColor: "transparent" }}
                  _active={{
                    backgroundColor: "transparent",
                  }}
                />
                <MenuList minW="160px">
                  {menuitems.map((menu, i) => {
                    return (
                      <Fragment key={i}>
                        <MenuItem
                          px="14px"
                          py="9.6px"
                          color="textcolors.primary"
                          onClick={() => {
                            menu.onClick?.(temp?.id);
                          }}
                        >
                          <HStack>
                            <Icon as={menu.icon} boxSize={18} color="#1f2533" />
                            <Text fontSize="14px" fontWeight={400}>
                              {menu.label}
                            </Text>
                          </HStack>
                        </MenuItem>
                      </Fragment>
                    );
                  })}
                </MenuList>
              </Menu>
            </HStack>
            <Stack spacing="24px">
              <Text
                fontSize="24px"
                fontWeight={500}
                color="textcolors.primary"
                lineHeight="2rem"
              >
                {temp?.title}
              </Text>
              <HStack>
                <Button
                  px="30.5px"
                  py="16px"
                  flex={1}
                  minH="57px"
                  borderRadius="8px"
                  fontSize="16px"
                  fontWeight={500}
                  onClick={(e) => {
                    setIsCreatingInspection(true);
                    Template.GetById(
                      temp?.id!,
                      (temp) => {
                        Inspection.SubmitInspection(
                          {
                            inspectionCompleted: false,
                            inspectionInventory: [],
                            inspectionScore: 0,
                            mediaUniqueId: uuidv4(),
                            archive: false,
                            templateItems: temp.templateItems as any,
                            template: temp.template,
                            deletedInventoryItems: [],
                            templateMedia: temp?.templateMedia?.map((doc) => {
                              return {
                                templateId: doc.tempId,
                                attachmentUrl: doc.docUrl,
                                attachmentName: doc.fileName,
                                id: doc.id!,
                              };
                            }),
                          },
                          (inspection) => {
                            setLastUpdatedLocations(Date.now());
                            setIsCreatingInspection(false);
                            navigate(
                              `/app/auditor/inspection-conduct/CONTINUE_${inspection.inspection?.id}`
                            );
                            // console.log(inspection);
                          },
                          (error) => {
                            toast({
                              title: "Error",
                              description:
                                "Error Occured while Submitting Inspection",
                              status: "error",
                            });
                          }
                        );
                      },
                      (error) => {
                        console.log(error);
                        toast({
                          title: "Error",
                          description:
                            "You do not have permission to submit this resource",
                          status: "error",
                          duration: 5000,
                          isClosable: true,
                        });
                        navigate(-1);
                      }
                    );
                    // navigate(`/app/auditor/inspection-conduct/NEW_${temp.id}`);
                  }}
                >
                  Start Inspection
                </Button>
                {/* <Button
                  variant="outline"
                  px="30.5px"
                  minH="57px"
                  py="16px"
                  borderRadius="8px"
                  fontSize="16px"
                  fontWeight={500}
                  leftIcon={<BsFillEyeFill size={24} />}>
                  Preview
                </Button> */}
              </HStack>
            </Stack>
          </Stack>
          <Stack
            spacing="24px"
            p="16px"
            border="1px"
            borderColor="borders.accent"
            borderRadius="8px"
            bgColor="white"
            mx="16px"
            mt="16px"
          >
            <Text
              fontSize="21px"
              fontWeight={500}
              lineHeight="1.75rem"
              color="textcolors.primary"
            >
              Edit
            </Text>
            <HStack justifyContent="space-between">
              <Stack spacing="4px">
                <SubHeading>Template</SubHeading>
                {/* <Content>2 pages and 13 questions</Content> */}
              </Stack>
              <Button
                variant="link"
                fontSize="16px"
                isDisabled={!temp?.EDIT_PERMISSION}
                onClick={() => {
                  navigate(`/app/auditor/template-edit/${temp?.id}/?tab=Build`);
                }}
              >
                Edit template
              </Button>
            </HStack>
            {/* <HStack justifyContent="space-between">
              <Stack spacing="4px">
                <SubHeading>Report</SubHeading>
                <Content>Design how the inspection report will look</Content>
              </Stack>
              <Button variant="link" fontSize="16px">
                Edit report
              </Button>
            </HStack> */}
            <HStack justifyContent="space-between">
              <Stack spacing="4px">
                <SubHeading>Access</SubHeading>
                {/* <Content>All users (Saoud's Team)</Content> */}
              </Stack>
              <Button
                isDisabled={!temp?.EDIT_PERMISSION}
                variant="link"
                fontSize="16px"
                onClick={() => {
                  navigate(
                    `/app/auditor/template-edit/${temp?.id}/?tab=Access`
                  );
                }}
              >
                Manage access
              </Button>
            </HStack>
            <HStack justifyContent="space-between">
              <Stack spacing="4px">
                <SubHeading>Schedule</SubHeading>
                {/* <Content>Not scheduled</Content> */}
              </Stack>
              <Button
                variant="link"
                fontSize="16px"
                onClick={() => {
                  navigate(
                    `/app/auditor/schedule_inspection?templateId=${temp?.id}`,
                    {
                      state: {
                        triggerCreateModal: true,
                      },
                    }
                  );
                }}
              >
                Schedule inspection
              </Button>
            </HStack>
          </Stack>
          <Stack
            spacing="24px"
            p="16px"
            border="1px"
            borderColor="borders.accent"
            borderRadius="8px"
            bgColor="white"
            mt="16px"
            mx="16px"
          >
            <Text
              fontSize="21px"
              fontWeight={500}
              lineHeight="1.75rem"
              color="textcolors.primary"
            >
              Details
            </Text>
            <Stack spacing="4px">
              <SubHeading>Description</SubHeading>
              <Content>{temp?.description}</Content>
            </Stack>
            <Stack spacing="4px">
              <SubHeading>Last published</SubHeading>
              <Content>{temp?.lastPublished}</Content>
            </Stack>
            <Stack spacing="4px">
              <SubHeading>Last modified</SubHeading>
              <Content>{temp?.lastModified}</Content>
            </Stack>
            <Stack spacing="4px">
              <SubHeading>Created by</SubHeading>
              <Content>
                {allUsers.find((user) => user?.user?.id === temp?.createdBy)
                  ?.user?.firstName +
                  " " +
                  allUsers.find((user) => user?.user?.id === temp?.createdBy)
                    ?.user?.lastName}
              </Content>
            </Stack>
            {/* <Stack spacing="4px">
              <SubHeading>Organization</SubHeading>
              <Content>Saoud's Team</Content>
            </Stack>
            <Stack spacing="4px">
              <SubHeading>Template owner</SubHeading>
              <Content>{temp.}</Content>
            </Stack> */}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
