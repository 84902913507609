import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Fragment } from "react";
import {
  Controller,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { MdDelete } from "react-icons/md";
import { CustomInput } from "../../../../../Common/CustomInput";
import { ReactSelect } from "../../../../../Common/ReactSelect";
import { Template } from "../../../../modules/Audit";
import { getFieldKey } from "../../../../modules/form-apis";
import { useItemContext } from "../../hooks/auditItemContext";
import useDrawer from "../../hooks/drawersContext";

interface TableDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function TableDrawer({ isOpen, onClose }: TableDrawerProps) {
  const { item } = useItemContext();
  const template = useFormContext();
  const { questionItemDetails } = useDrawer();
  const { control, handleSubmit } = useForm<Template.QuestionProperties>({
    defaultValues: template.getValues(
      getFieldKey(
        questionItemDetails ?? { pageIndex: 0 },
        "question.properties"
      )
    ),
  });
  const ColumnHeader = useFieldArray({
    control: control,
    name: "tableData",
  });
  const DataTypes: Array<{ label: string; value: string }> = [
    {
      label: "Text Answer",
      value: "text",
    },
    {
      label: "Paragraph",
      value: "text_area",
    },
    {
      label: "Number",
      value: "number",
    },
    {
      label: "Date",
      value: "date",
    },
    {
      label: "Inventory",
      value: "INVENTORY",
    },
    {
      label: "Inventory Item Code",
      value: "INVENTORY_ITEM_CODE",
    },
    {
      label: "Yes/No",
      value: "yes_no",
    },
    {
      label: "Total",
      value: "total",
    },
  ];
  const watchTableColumns = useWatch({
    control,
    name: "tableData",
  });
  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add Table Columns</DrawerHeader>

          <DrawerBody>
            <Stack>
              {ColumnHeader.fields.map((field, index) => (
                <Fragment>
                  <Text fontSize="12px" fontWeight={500}>{`Column ${
                    index + 1
                  } Header`}</Text>
                  <HStack spacing={0}>
                    <Box flex={1}>
                      <HStack key={field.id} align="top">
                        <Box maxW="400px">
                          <Controller
                            rules={{
                              required: {
                                value: true,
                                message: "Column header is required",
                              },
                            }}
                            name={`tableData.${index}.name`}
                            control={control}
                            render={({ field, fieldState }) => (
                              <CustomInput
                                input={{
                                  ...field,
                                  variant: "outline",
                                  isInvalid: fieldState.invalid,
                                  value: field.value,
                                  type: "text",
                                }}
                                withValidation
                                validationMessage={fieldState.error?.message}
                                placeholder={"Column name"}
                              />
                            )}
                          />
                        </Box>
                        <Box flex={1}>
                          <Controller
                            name={`tableData.${index}.type`}
                            control={control}
                            rules={{
                              required: "Data Type is required",
                            }}
                            render={({ field, fieldState }) => (
                              <ReactSelect
                                {...field}
                                withValidation
                                isInvalid={fieldState.invalid}
                                placeholder={"Select Data Type"}
                                options={DataTypes}
                                value={DataTypes.find(
                                  (option) => option.value === field.value
                                )}
                                onChange={(value: any) => {
                                  field.onChange(value.value);
                                }}
                              />
                            )}
                          />
                        </Box>
                      </HStack>

                      <HStack>
                        {(watchTableColumns?.[index]?.type === "number" ||
                          watchTableColumns?.[index]?.type === "total") && (
                          <Box flex={1}>
                            <Controller
                              name={`tableData.${index}.computeTotal`}
                              control={control}
                              defaultValue={false}
                              render={({
                                field: { onChange, onBlur, name, ref, value },
                              }) => (
                                <Checkbox
                                  isChecked={value} // Controls the checkbox state
                                  onChange={(e) => onChange(e.target.checked)} // Updates checkbox state
                                  onBlur={onBlur}
                                  name={name}
                                  ref={ref}
                                >
                                  Compute Total
                                </Checkbox>
                              )}
                            />
                          </Box>
                        )}
                        {watchTableColumns?.[index]?.type === "total" && (
                          <Box flex={1}>
                            <Controller
                              name={`tableData.${index}.columnsToInclude`}
                              control={control}
                              defaultValue={[]}
                              render={({ field }) => (
                                <ReactSelect
                                  {...field}
                                  isMulti
                                  placeholder={"Select Columns to Include"}
                                  options={watchTableColumns
                                    ?.filter((item) => item.type === "number")
                                    .map((item, i) => ({
                                      label: item.name,
                                      value: item.name,
                                    }))}
                                  getOptionLabel={(option: any) =>
                                    option?.label
                                  }
                                  getOptionValue={(option: any) =>
                                    option?.value
                                  }
                                  onChange={(value: any) => {
                                    field.onChange(
                                      value.map((v: any) => v.value)
                                    );
                                  }}
                                  value={field.value?.map((v: any) => ({
                                    label: v,
                                    value: v,
                                  }))}
                                />
                              )}
                            />
                          </Box>
                        )}
                      </HStack>
                    </Box>
                    <Button
                      as={IconButton}
                      icon={<MdDelete size="20px" />}
                      variant={"ghost"}
                      color={"#000000"}
                      _hover={{
                        color: "#c00",
                        bg: "transparent",
                      }}
                      padding={"0px"}
                      onClick={() => {
                        ColumnHeader.remove(index);
                      }}
                    >
                      X
                    </Button>
                  </HStack>
                </Fragment>
              ))}
            </Stack>
            <Button
              h={"21px"}
              padding={"3px 7px 2px 5px"}
              cursor={"pointer"}
              // marginRight={"5px"}
              color={"#048fc2"}
              size={"sm"}
              fontSize={"11px"}
              fontWeight={"700"}
              textAlign={"center"}
              // whiteSpace={"nowrap"}
              border={"1px solid #e6e6e6"}
              borderRadius={"4px"}
              bg={"#fff"}
              _hover={{
                color: "#005e7b",
                bg: "#fff",
              }}
              mt="20px"
              onClick={() => {
                ColumnHeader.append({ name: "", type: "" });
              }}
            >
              + Add a Column
            </Button>
          </DrawerBody>

          <DrawerFooter
            borderTop="1px solid"
            borderColor={"borders.accent"}
            padding={"24px"}
            backgroundColor={"backgrounds.header"}
            flexDirection="column"
          >
            <Stack spacing={0} w="100%">
              <HStack direction={{ xs: "column", md: "row" }} w="100%">
                <Button
                  h="40px"
                  borderRadius="5px"
                  fontSize="14px"
                  fontWeight={500}
                  variant="outline"
                  padding={"12px 20px"}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  h="40px"
                  w={"auto"}
                  borderRadius="5px"
                  fontSize="14px"
                  fontWeight={500}
                  marginRight="16px"
                  padding={"12px 20px"}
                  onClick={handleSubmit(
                    (data) => {
                      if (questionItemDetails?.questionIndex !== undefined) {
                        template.setValue(
                          getFieldKey(
                            questionItemDetails,
                            "question.properties"
                          ),
                          data
                        );

                        onClose();
                      }
                    },
                    (error) => {
                      console.log(error);
                    }
                  )}
                >
                  Save
                </Button>
              </HStack>
            </Stack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
