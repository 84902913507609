import { axiosInstance } from "./axiosInstance-aud";
import { Template } from "./Template";

declare module Report {
  interface IReport {
    template: {
      createdAt: string;
      updatedAt: string;
      id: number;
      deletedAt?: string;
      title: string;
      description: string;
      image: string;
      businessId: string;
      createdBy: string;
      totalScore: number;
      published: boolean;
    };
    pages: Array<IReportPage>;
    inspectionMedia: Array<IReportMedia>;
    templateMedia: Array<IReportDocuments>;
    flaggedItems: Array<IReportPageData>;
    inspectionInventory: Array<InventoryData>;
    periodicScheduleEvents: Array<periodicActivities>;
    templateMeta: {
      site: string;
      person: string;
      inspectionDate: string;
      id: number;
      inspectionId: number;
      inspectionLocation: string;
      documentNumber: string;
      inspectionScore: string;
    };
  }

  interface IReportPage {
    page_id: number;
    page_title: string;
    data: Array<IReportPageData>;
  }
  interface IReportPageData {
    createdAt: string;
    updatedAt: string;
    id: number;
    deletedAt?: string;
    page_title?: string;
    inspection_meta_id: number;
    question_value: string;
    notes?: string;
    question_title: string;
    question_color?: string;
    question_order_index: number;
    section_id?: number;
    section_title?: string;
    parent_order?: number;
    properties: Template.QuestionProperties;
    question_id: number;
    question_score?: number;
    question_max_score?: number;
    media: Array<Template.MediaObject>;
    questions?: Array<IReportPageData>;
    type: Template.QuestionType;
    format: Template.QuestionFormat;
    alignment: "left" | "center" | "right";
    display: boolean;
  }
  interface InventoryData {
    createdAt: string;
    updatedAt: string;
    id: number;
    deletedAt: null;
    inventoryItem: string;
    inventoryCategory: string;
    inspectionId: number;
    itemQuantity: number;
  }
  interface periodicActivities {
    id: number;

    templateId: number;
    inspectionId: number;
    status: string;

    dateRange: {
      startDate: string;
      endDate: string;
    };
    timeRange: {
      endTime: string;
      startTime: string;
    };
    completedBy: string;
    color: string;
    scheduleId: number;
    employees: Array<string>;
    activityId: number;
    location: string;
    frequency: string;
    areas: Array<string>;
    periodicActivity: {
      id: number;

      name: string;
      description: string;
      code: string;
      color: string | null;
    };
  }
  interface IReportMedia {
    createdAt: string;
    updatedAt: string;
    id: number;
    deletedAt?: string;
    mediaUrl: string;
    mediaName: string;
    answerId: number;
    generic: boolean;
  }
  interface IReportDocuments {
    createdAt: string;
    updatedAt: string;
    id: number;
    attachmentUrl: string;
    attachmentName: string;
    templateId: number;
  }
}
module Report {
  const Report_URL = "/inspection-meta/generate-report";
  export function FetchReportbyId(
    id: number,
    success: (inspection: IReport) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${Report_URL}/${id}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((err) => {
        error(err?.response?.data?.message ?? err?.message);
      });
  }

  function arrayBufferToBlob(arrayBuffer: any) {
    return new Blob([arrayBuffer]);
  }
}
export { Report };
