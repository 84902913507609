import {
  Avatar,
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Country } from "country-state-city";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useAuthHeader, useAuthUser, useSignIn } from "react-auth-kit";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FiUser } from "react-icons/fi";
import { MdCancel, MdOutlineMailOutline, MdOutlinePhone } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ReactSelect } from "../../../Common/ReactSelect";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { UploadAttachments } from "../../Api/UploadAttachments";
import { UserProfileApi } from "../../Api/UserProfile";

export function UserProfile() {
  const [showPhoneNumber, setShowPhoneNumber] = useState(true);
  const { currentEmployeeId } = useBusinessContext();
  const auth = useAuthHeader();
  const user = useAuthUser();

  const signIn = useSignIn();
  const decodedToken = jwtDecode<any>(auth());

  const toast = useToast();
  let countryData = Country.getAllCountries();
  const form = useForm<UserProfileApi.UserDetail>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      photo: "",
      phone: "",
      country: "",
    },
  });
  const [uploadedPhoto, setUploadPhoto] = useState<number>(Date.now());
  const [photoLoading, setPhotoLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  useEffect(() => {
    UserProfileApi.GetUserProfile(
      (data) => {
        form.reset(data);
        setLoading(false);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
        setLoading(false);
      }
    );
  }, []);
  return (
    <FormProvider {...form}>
      <Stack
        gap={0}
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
      >
        <HStack
          _light={{
            bg: "#fff",
            boxShadow:
              "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)",
          }}
          _dark={{
            bg: "customColor.dark.50",
          }}
          justifyContent={"space-between"}
          minH={"65px"}
          padding={"0 30px"}
        >
          <Heading
            fontWeight={"600"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"18px"}
          >
            Profile Settings
          </Heading>
          <Stack direction={"row"}>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={form.handleSubmit(
                (data) => {
                  UserProfileApi.UpdateUserProfile(
                    data,
                    (success) => {
                      toast({
                        title: "User Profile Updated",
                        status: "success",
                      });
                      decodedToken.accessLevel !== "EMPLOYEE" &&
                        navigate("/app/payroll/Dashboard");
                      decodedToken.accessLevel === "EMPLOYEE" &&
                        navigate(
                          `/app/payroll/EmployeeDashboard/${currentEmployeeId}`
                        );

                      const userDetails = user();

                      const expirytime = decodedToken.exp;
                      const timeDiff = moment.duration(
                        expirytime - moment().unix(),
                        "seconds"
                      );
                      signIn({
                        token: auth().replace(/^Bearer\s+/i, ""), // Remove "Bearer " if it exists
                        expiresIn: timeDiff.asMinutes(),
                        tokenType: "Bearer",
                        authState: {
                          ...userDetails,
                          firstName: `${data?.firstName}`,
                          lastName: `${data?.lastName}`,
                          email: `${data?.email}`,
                          password: `${data?.password}`,
                          phone: `${data?.phone}`,
                          country: `${data?.country}`,
                        },
                      });
                    },

                    (error) => {
                      toast({
                        title: "User Profile Update Failed",
                        description: error,
                        status: "error",
                      });
                    }
                  );
                },
                (error) => {
                  toast({
                    title: "Error",
                    status: "error",
                  });
                }
              )}
            >
              Save
            </Button>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => {
                decodedToken.accessLevel !== "EMPLOYEE" &&
                  navigate("/app/payroll/Dashboard");
                decodedToken.accessLevel === "EMPLOYEE" &&
                  navigate(
                    `/app/payroll/EmployeeDashboard/${currentEmployeeId}`
                  );
              }}
            >
              Cancel
            </Button>
          </Stack>
        </HStack>

        <Container
          maxW={"container.xl"}
          padding={"30px 42px 5px 42px"}
          h="calc(100vh - 135px)"
          overflowY="auto"
        >
          <Stack
            marginBottom={"24px"}
            gap={0}
            transition={"all .3s ease"}
            boxShadow={
              "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
            }
            borderRadius={"10px"}
            _light={{
              bg: "#fff",
            }}
            padding={"25px"}
          >
            <Heading
              _light={{
                color: "customColor.black7",
              }}
              _dark={{ color: "customColor.white" }}
              fontSize={"16px"}
              marginBottom={"24px"}
            >
              Basic Information
            </Heading>

            <Stack
              marginBottom={"24px"}
              direction={"row"}
              alignItems={"center"}
            >
              <Stack flex={2}>
                <Text
                  fontSize={"13.44px"}
                  _light={{ color: "customColor.gray.64748B" }}
                  _dark={{ color: "customColor.white" }}
                  fontWeight={"600"}
                >
                  Profile Image:
                </Text>
              </Stack>
              <Stack flex={4} paddingLeft={"12px"}>
                {/* <Avatar w={"99px"} h={"99px"} borderRadius={"5px"} /> */}

                <Stack position="relative">
                  <Avatar
                    key={uploadedPhoto}
                    size={"xl"}
                    h={"95px"}
                    w={"95px"}
                    bg={
                      photoLoading || loading
                        ? "rgba(255, 255, 255, 0.8)"
                        : "#3454d1"
                    }
                    objectFit="fill"
                    name={
                      form.watch("firstName") + " " + form.watch("lastName")
                    }
                    src={`${form.watch("photo")}?updated=${uploadedPhoto}`}
                    // Other props
                    borderWidth={"2px"}
                    borderColor={"gray.200"}
                  />
                  {(photoLoading || loading) && (
                    <Stack
                      position="absolute"
                      top="7"
                      right="0"
                      bottom="0"
                      left="7"
                      // backgroundColor="rgba(255, 255, 255, 0.8)"
                      // Optional: semi-transparent overlay
                    >
                      <Spinner size="lg" />
                    </Stack>
                  )}
                  {/* Other components */}
                </Stack>

                <Box>
                  <Button
                    backgroundColor={"transparent"}
                    borderRadius={"3px"}
                    border={"1px solid #dcdee4"}
                    fontSize={"13px"}
                    variant={"solid"}
                    textColor={"customColor.black7"}
                    padding={"12px 16px"}
                    fontWeight={600}
                    _hover={{
                      backgroundColor: "#fff",
                      color: "#3454D1",
                    }}
                    onClick={() => {
                      if (inputRef.current) {
                        inputRef.current.click();
                      }
                    }}
                  >
                    Upload photo
                  </Button>
                  <input
                    type="file"
                    ref={inputRef}
                    onChange={(e) => {
                      if (e.target.files) {
                        const file = e.target.files[0];
                        const formData = new FormData();
                        formData.append("docType", "USER_PROFILE_PICTURE");
                        formData.append("file", file);
                        // formData.append(
                        //   "defaultBusinessId",
                        //   form.getValues("defaultBusinessId")!
                        // );
                        setPhotoLoading(true);
                        UploadAttachments.UploadAttachment(
                          formData,
                          (success) => {
                            form.setValue("photo", success.url);
                            setUploadPhoto(Date.now());
                            setPhotoLoading(false);
                            const userDetails = user();

                            const expirytime = decodedToken.exp;
                            const timeDiff = moment.duration(
                              expirytime - moment().unix(),
                              "seconds"
                            );
                            signIn({
                              token: auth().replace(/^Bearer\s+/i, ""), // Remove "Bearer " if it exists
                              expiresIn: timeDiff.asMinutes(),
                              tokenType: "Bearer",
                              authState: {
                                ...userDetails,
                                photo: `${
                                  success?.url
                                }?updated=${moment().unix()}`,
                              },
                            });
                          },
                          (err) => {
                            toast({
                              title: "Error uploading photo",
                              status: "error",
                            });
                            setPhotoLoading(false);
                          }
                        );
                      }
                      e.target.value = "";
                    }}
                    style={{ display: "none" }}
                  />
                </Box>
              </Stack>
            </Stack>
            <Stack
              marginBottom={"24px"}
              direction={"row"}
              alignItems={"center"}
            >
              <Stack flex={2}>
                <Text
                  fontSize={"13.44px"}
                  _light={{ color: "customColor.gray.64748B" }}
                  _dark={{ color: "customColor.white" }}
                  fontWeight={"600"}
                >
                  Name
                </Text>
              </Stack>
              <Stack flex={4} paddingLeft={"12px"}>
                <Stack direction={"row"} alignItems={"baseline"}>
                  <InputGroup>
                    <InputLeftAddon h={"38px"}>
                      <FiUser />
                    </InputLeftAddon>
                    <Controller
                      control={form.control}
                      name="firstName"
                      render={({ field, fieldState: { invalid } }) => (
                        // <CustomInput
                        //   ref={field.ref}
                        //   validationMessage={error?.message}
                        //   withValidation
                        //   input={{
                        //     ...field,
                        //     isInvalid: error !== undefined,
                        //     h: "45px",
                        //     w: "100%",
                        //     fontSize: ".845rem",
                        //     padding: "12px 15px",
                        //     _light: {
                        //       color: "customColor.black7",
                        //     },
                        //     variant: "outline",
                        //     type: "text",

                        //     borderRadius: "5px",
                        //   }}
                        //   placeholder=""
                        // />
                        <Input
                          placeholder="First Name"
                          borderRadius={"0px"}
                          borderRightRadius={"5px"}
                          h={"38px"}
                          fontSize=".845rem"
                          fontWeight={400}
                          _light={{
                            color: "customColor.black7",
                          }}
                          padding="12px 15px"
                          border="1px solid #e5e7eb"
                          _focus={{
                            borderWidth: "1px",

                            boxShadow: "0 0 0 1px #2684FF",
                            _hover: {
                              borderWidth: "1px",
                              borderColor: "#2684FF",
                              boxShadow: "0 0 0 1px #2684FF",
                            },
                          }}
                          _hover={{
                            border: "1px solid hsl(0, 0%, 70%)",
                          }}
                          _focusVisible={{
                            borderWidth: "1px",
                            boxShadow: "0 0 0 1px #2684FF",
                            _hover: {
                              borderWidth: "1px",
                              borderColor: "#2684FF",
                              boxShadow: "0 0 0 1px #2684FF",
                            },
                          }}
                          isInvalid={invalid}
                          {...field}
                        />
                      )}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputLeftAddon h={"38px"}>
                      <FiUser />
                    </InputLeftAddon>
                    <Controller
                      control={form.control}
                      name="lastName"
                      render={({ field, fieldState: { invalid } }) => (
                        // <CustomInput
                        //   ref={field.ref}
                        //   validationMessage={error?.message}
                        //   withValidation
                        //   input={{
                        //     ...field,
                        //     isInvalid: error !== undefined,
                        //     h: "30px",

                        //     fontSize: "12px",
                        //     paddingLeft: "5px",
                        //     variant: "outline",
                        //     type: "text",
                        //     borderWidth: "1px",
                        //     borderStyle: "solid",
                        //     borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc3",
                        //     borderTopRadius: "0",
                        //   }}
                        //   placeholder=""
                        // />
                        <Input
                          placeholder="Last Name"
                          borderRadius={"0px"}
                          borderRightRadius={"5px"}
                          h={"38px"}
                          fontSize=".845rem"
                          fontWeight={400}
                          _light={{
                            color: "customColor.black7",
                          }}
                          padding="12px 15px"
                          border="1px solid #e5e7eb"
                          _focus={{
                            borderWidth: "1px",

                            boxShadow: "0 0 0 1px #2684FF",
                            _hover: {
                              borderWidth: "1px",
                              borderColor: "#2684FF",
                              boxShadow: "0 0 0 1px #2684FF",
                            },
                          }}
                          _hover={{
                            border: "1px solid hsl(0, 0%, 70%)",
                          }}
                          _focusVisible={{
                            borderWidth: "1px",
                            boxShadow: "0 0 0 1px #2684FF",
                            _hover: {
                              borderWidth: "1px",
                              borderColor: "#2684FF",
                              boxShadow: "0 0 0 1px #2684FF",
                            },
                          }}
                          isInvalid={invalid}
                          {...field}
                        />
                      )}
                    />
                  </InputGroup>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              marginBottom={"24px"}
              direction={"row"}
              alignItems={"center"}
            >
              <Stack flex={2}>
                <Text
                  fontSize={"13.44px"}
                  _light={{ color: "customColor.gray.64748B" }}
                  _dark={{ color: "customColor.white" }}
                  fontWeight={"600"}
                >
                  Email
                </Text>
              </Stack>
              <Stack flex={4} paddingLeft={"12px"}>
                <InputGroup>
                  <InputLeftAddon h={"38px"}>
                    <MdOutlineMailOutline />
                  </InputLeftAddon>
                  <Controller
                    control={form.control}
                    name="email"
                    render={({ field, fieldState: { invalid } }) => (
                      // <CustomInput
                      //   ref={field.ref}
                      //   validationMessage={error?.message}
                      //   withValidation
                      //   input={{
                      //     ...field,
                      //     isInvalid: error !== undefined,
                      //     h: "30px",
                      //     w: "386px",

                      //     fontSize: "12px",
                      //     paddingLeft: "5px",
                      //     variant: "outline",
                      //     type: "text",
                      //     borderWidth: "1px",
                      //     borderStyle: "solid",
                      //     borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc3",
                      //     borderTopRadius: "0",
                      //   }}
                      //   placeholder=""
                      // />
                      <Input
                        placeholder="Email"
                        borderRadius={"0px"}
                        borderRightRadius={"5px"}
                        h={"38px"}
                        fontSize=".845rem"
                        fontWeight={400}
                        _light={{
                          color: "customColor.black7",
                        }}
                        border="1px solid #e5e7eb"
                        _focus={{
                          borderWidth: "1px",

                          boxShadow: "0 0 0 1px #2684FF",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#2684FF",
                            boxShadow: "0 0 0 1px #2684FF",
                          },
                        }}
                        _hover={{
                          border: "1px solid hsl(0, 0%, 70%)",
                        }}
                        _focusVisible={{
                          borderWidth: "1px",
                          boxShadow: "0 0 0 1px #2684FF",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#2684FF",
                            boxShadow: "0 0 0 1px #2684FF",
                          },
                        }}
                        isInvalid={invalid}
                        {...field}
                      />
                    )}
                  />
                </InputGroup>
              </Stack>
            </Stack>
            <Stack
              marginBottom={"24px"}
              direction={"row"}
              alignItems={"center"}
            >
              <Stack flex={2}>
                <Text
                  fontSize={"13.44px"}
                  _light={{ color: "customColor.gray.64748B" }}
                  _dark={{ color: "customColor.white" }}
                  fontWeight={"600"}
                >
                  Location
                </Text>
              </Stack>
              <Stack flex={4} paddingLeft={"12px"}>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Country is required",
                    },
                  }}
                  control={form.control}
                  name="country"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select Country"
                      // marginBottom="30px"
                      // width={"48em"}

                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Country"
                      withValidation
                      options={countryData}
                      value={countryData.find((op) => op.name === field.value)}
                      onChange={(newValue: any) =>
                        field.onChange(newValue?.name)
                      }
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.name}
                    />
                  )}
                />
              </Stack>
            </Stack>

            {showPhoneNumber && (
              <Stack
                marginBottom={"24px"}
                direction={"row"}
                alignItems={"center"}
              >
                <Stack flex={2}>
                  <Text
                    fontSize={"13.44px"}
                    _light={{ color: "customColor.gray.64748B" }}
                    _dark={{ color: "customColor.white" }}
                    fontWeight={"600"}
                  >
                    Phone Number
                  </Text>
                </Stack>
                <Stack
                  flex={4}
                  direction={"row"}
                  alignItems={"center"}
                  paddingLeft={"12px"}
                >
                  <InputGroup>
                    <InputLeftAddon h={"38px"}>
                      <MdOutlinePhone />
                    </InputLeftAddon>

                    <Controller
                      control={form.control}
                      name="phone"
                      render={({ field, fieldState: { invalid } }) => (
                        // <CustomInput
                        //   ref={field.ref}
                        //   validationMessage={error?.message}
                        //   withValidation
                        //   input={{
                        //     ...field,
                        //     isInvalid: error !== undefined,
                        //     h: "30px",

                        //     fontSize: "12px",
                        //     paddingLeft: "5px",
                        //     variant: "outline",
                        //     type: "text",
                        //     borderWidth: "1px",
                        //     borderStyle: "solid",
                        //     borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc3",
                        //     borderTopRadius: "0",
                        //   }}
                        //   placeholder=""
                        // />
                        <Input
                          placeholder="Phone Number"
                          borderRadius={"0px"}
                          h={"38px"}
                          fontSize=".845rem"
                          fontWeight={400}
                          _light={{
                            color: "customColor.black7",
                          }}
                          border="1px solid #e5e7eb"
                          _focus={{
                            borderWidth: "1px",

                            boxShadow: "0 0 0 1px #2684FF",
                            _hover: {
                              borderWidth: "1px",
                              borderColor: "#2684FF",
                              boxShadow: "0 0 0 1px #2684FF",
                            },
                          }}
                          _hover={{
                            border: "1px solid hsl(0, 0%, 70%)",
                          }}
                          _focusVisible={{
                            borderWidth: "1px",
                            boxShadow: "0 0 0 1px #2684FF",
                            _hover: {
                              borderWidth: "1px",
                              borderColor: "#2684FF",
                              boxShadow: "0 0 0 1px #2684FF",
                            },
                          }}
                          isInvalid={invalid}
                          {...field}
                        />
                      )}
                    />
                    <InputRightAddon h={"38px"}>
                      {" "}
                      <Icon
                        as={MdCancel}
                        color={"primary.950"}
                        _hover={{
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => setShowPhoneNumber(false)}
                      />
                    </InputRightAddon>
                  </InputGroup>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Container>
      </Stack>
    </FormProvider>
  );
}
