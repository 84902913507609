import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ReactSelect } from "../../../../../../../Common/ReactSelect";
import { InventoryModule } from "../../../../../../modules/Audit/Inventory";
import { InventoryCategoryModule } from "../../../../../../modules/Audit/InventoryCategories";
import UpdateInventoryImage from "./UpdateInvetoryImage";

interface UpdateInventoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedId: number | undefined;
  selectedItem: InventoryModule.InventoryData | undefined;
  inventoryTypes:
    | Array<InventoryCategoryModule.FetchInventoryCategoryResponse>
    | undefined;
  setLastUpdate: (timestamp: number) => void;
}
export default function UpdateInventoryModal({
  isOpen,
  onClose,
  inventoryTypes,
  selectedItem,
  selectedId,
  setLastUpdate,
}: UpdateInventoryModalProps) {
  const toast = useToast();
  const [iconVisible, setIconVisible] = useState(false);
  const form = useForm<InventoryModule.InventoryData>();
  useEffect(() => {
    form.reset({
      name: selectedItem?.name,
      inventoryCategoryId: selectedItem?.inventoryCategoryId,
      mediaUrl: selectedItem?.mediaUrl,
    });
  }, [selectedItem]);
  return (
    <FormProvider {...form}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text color="textcolors.primary">Update inventory item</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box w="100%" display={"flex"} justifyContent="center">
              <Box w="100px" h="70px">
                <UpdateInventoryImage />
              </Box>
            </Box>
            <Box marginTop="24px">
              <Controller
                control={form.control}
                name={"name"}
                rules={{
                  required: {
                    value: true,
                    message: "Item name is required",
                  },
                  onBlur: () => {
                    setIconVisible(false);
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl isInvalid={error !== undefined}>
                    <Input
                      isInvalid={error !== undefined}
                      h="38px"
                      padding="5px"
                      w="100%"
                      border="1px"
                      borderColor="primary.500"
                      onFocus={() => setIconVisible(true)}
                      placeholder="Enter item"
                      _focusVisible={{
                        boxShadow: "0px",
                        border: "1px solid",
                        borderColor: "primary.500",
                      }}
                      {...field}
                    />
                    <FormErrorMessage>{error?.message}</FormErrorMessage>
                  </FormControl>
                )}
              />

              <Box marginTop="16px" minW={"100%"}>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Category is required",
                    },
                  }}
                  name={"inventoryCategoryId"}
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      key={field.value}
                      {...field}
                      withValidation
                      isInvalid={fieldState.invalid}
                      placeholder="Select Category"
                      ErrorMessage="Category is required"
                      options={inventoryTypes}
                      value={
                        field.value
                          ? {
                              id: field.value,
                              name:
                                inventoryTypes?.find(
                                  (type) => type?.id === field.value
                                )?.name ?? "",
                            }
                          : undefined
                      }
                      onChange={(val: any) => {
                        field.onChange(val?.id);
                      }}
                      getOptionLabel={(option: any) => option?.name}
                      getOptionValue={(option: any) => option?.id}
                    />
                  )}
                />
              </Box>
            </Box>
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button
                variant="outline"
                borderRadius="8px"
                h="43px"
                px="20px"
                py="12px"
                onClick={onClose}
              >
                Close
              </Button>
              <Button
                //   isLoading={isLoading}
                variant="solid"
                borderRadius="8px"
                h="43px"
                px="20px"
                py="12px"
                onClick={form.handleSubmit((data) => {
                  if (selectedId) {
                    InventoryModule.UpdateInventoryItem(
                      selectedId,
                      data,
                      () => {
                        toast({
                          title: "Success",
                          description: "Item Updated successfully",
                          status: "success",
                        });
                        setLastUpdate(Date.now());
                        onClose();
                      },
                      (error) => {
                        toast({
                          title: "Error",
                          description: error,
                          status: "error",
                        });
                      }
                    );
                  }
                })}
              >
                Update
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
