import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  HStack,
  Stack,
  Text,
  useSteps,
} from "@chakra-ui/react";
import { CompleteContact } from "./Components/CompleteContact";
import { UploadContact } from "./Components/UploadContact";

export function ImportContact() {
  const steps = [{ title: "Upload Contact" }, { title: "Complete Contact" }];
  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  });
  return (
    <Stack
      gap={0}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      h={"calc(100vh - 70px)"}
      // h="calc(100vh - 130px)"
      overflowY="auto"
    >
      <Stack
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        border="1px solid #e5e7eb"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        padding={"16px"}
        gap={0}
        lineHeight={1.2}
      >
        <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
          <BreadcrumbItem>
            <BreadcrumbLink
              href="ProductAndServices"
              fontSize={"13px"}
              color={"#3454d1"}
            >
              Contact
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text
            _light={{ color: "customColor.black7" }}
            fontSize={"17px"}
            fontWeight={"700"}
          >
            Import Contact
          </Text>
        </Stack>
      </Stack>
      <Container maxWidth={"container.xl"} p={"16px"}>
        <Stack
          gap={0}
          _light={{
            bg: "#fff",
          }}
          marginBottom={"20px"}
          overflow={"hidden"}
          p={"8px"}
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          border="1px solid #e5e7eb"
        >
          <Container maxW={"450px"} p={0}>
            <HStack justifyContent={"space-between"}>
              {steps.map((step, index) => (
                <HStack
                  cursor={
                    (step.title === "Complete Contact" && activeStep === 1) ||
                    (step.title === "Upload Contact" && activeStep === 2)
                      ? "not-allowed"
                      : "pointer"
                  }
                  // _disabled={
                  //   step.title === "Complete import" && index === 1
                  //     ? { opacity: 0.4, cursor: "not-allowed" }
                  //     : undefined
                  // }
                  padding={"10px 5px"}
                  key={index}
                  _hover={{
                    bg:
                      (step.title === "Complete Contact" && activeStep === 1) ||
                      (step.title === "Upload Contact" && activeStep === 2)
                        ? "transparent"
                        : "#f3f4f5",
                  }}
                  onClick={() => {
                    if (step.title === "Upload Contact" && index === 1)
                      setActiveStep(index + 1);
                  }}
                >
                  <Button
                    cursor={
                      (step.title === "Complete Contact" && activeStep === 1) ||
                      (step.title === "Upload Contact" && activeStep === 2)
                        ? "not-allowed"
                        : "pointer"
                    }
                    borderRadius={"full"}
                    bg={
                      (step.title === "Complete Contact" && activeStep === 1) ||
                      (step.title === "Upload Contact" && activeStep === 2)
                        ? "#f3f4f5"
                        : "#3454d1"
                    }
                    color={
                      (step.title === "Complete Contact" && activeStep === 1) ||
                      (step.title === "Upload Contact" && activeStep === 2)
                        ? "customColor.black7"
                        : "#fff"
                    }
                    _hover={{
                      bg:
                        (step.title === "Complete Contact" &&
                          activeStep === 1) ||
                        (step.title === "Upload Contact" && activeStep === 2)
                          ? "#f3f4f5"
                          : "#2c48b5",
                    }}
                  >
                    {index + 1}
                  </Button>
                  <Text whiteSpace="nowrap">{step.title}</Text>
                </HStack>
              ))}
            </HStack>

            {/* <Stepper size="md" index={activeStep}>
              {steps.map((step, index) => (
                <Tooltip label={step.title} hasArrow>
                  <Step key={index} onClick={() => setActiveStep(index + 1)}>
                    <StepIndicator
                      cursor={"pointer"}
                      _hover={{
                        bg: "#ddd",
                        borderColor: "#ddd",
                      }}
                    >
                      <StepStatus
                        complete={<StepNumber />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box
                      flexShrink="0"
                      cursor={"pointer"}
                      _hover={{
                        bg: "#ddd",
                      }}
                    >
                      <StepTitle>{step.title} </StepTitle>
                    </Box>

                    <StepSeparator />
                  </Step>
                </Tooltip>
              ))}
            </Stepper> */}
          </Container>
          <Stack paddingTop={"11px"}>
            {activeStep === 1 && (
              <UploadContact
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
            )}
            {activeStep === 2 && (
              <CompleteContact
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
            )}
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
}
