import {
  HStack,
  Icon,
  IconButton,
  Link,
  StackProps,
  Text,
} from '@chakra-ui/react';
import {Fragment} from 'react';
import {useFormContext} from 'react-hook-form';
import {FaPen} from 'react-icons/fa';
import {MCQ} from '../../../modules/Audit/MCQs';
import {getFieldKey} from '../../../modules/form-apis';
import {getMaxScoreMultichoice} from '../../../modules/utils';
import {useItemContext} from '../hooks/auditItemContext';
import useDrawer from '../hooks/drawersContext';
import {useTemplateBuilder} from '../hooks/templateBuilderContext';
import {QuestionTypeButtons} from './Types';

interface MultiOptionsProps {
  options: Array<MCQ.IMCQ>;
  onPopoverClose: Function;
}
export default function MultiOptions({
  options,
  onPopoverClose,
}: MultiOptionsProps) {
  const {widgets} = useTemplateBuilder();
  const template = useFormContext();
  const {item, updateQuestionType} = useItemContext();

  const {
    MultipleChoiceResponseModal,
    setMultiChoiceOptions,
    setQuestionItemDetails,
  } = useDrawer();

  return (
    <Fragment>
      <HStack
        w={'100%'}
        justifyContent={'space-between'}
        padding={'9.6px 14px'}>
        <Text fontSize={'13px'} fontWeight={400} color={'icons.primary'}>
          Multiple choice responces
        </Text>
        <Link
          fontSize={'13px'}
          fontWeight={400}
          onClick={() => {
            onPopoverClose();
            setTimeout(() => {
              MultipleChoiceResponseModal?.onOpen();
            }, 300);
          }}>
          + Responses
        </Link>
      </HStack>
      {options?.map((outerOp, i) => (
        <HStack
          key={i}
          w={'100%'}
          justifyContent={'space-between'}
          padding={'9.6px 14px'}
          _hover={{backgroundColor: 'gray.200'}}
          onClick={() => {
            const widgetDetails = widgets.find(w => w.type === 'MCQs');
            if (widgetDetails) {
              template.setValue(
                getFieldKey(item, 'question.mcqsData'),
                outerOp,
              );
              template.setValue(
                getFieldKey(item, 'question.score'),
                getMaxScoreMultichoice(outerOp.options),
              );

              updateQuestionType('MCQs', widgetDetails.id);

              onPopoverClose();
            }
          }}>
          <MultiOptionsStack
            optionSet={outerOp.options}
            optionSetName={outerOp.name ?? ''}
          />
          <IconButton
            aria-label="edit"
            variant="ghost"
            _hover={{backgroundColor: 'gray.400'}}
            borderRadius="50%"
            icon={<FaPen color="#545f70" />}
            onClick={e => {
              onPopoverClose();
              setMultiChoiceOptions?.(outerOp, true);
              setQuestionItemDetails?.(item);

              setTimeout(() => {
                MultipleChoiceResponseModal?.onOpen();
              }, 400);
              e.stopPropagation();
            }}
          />
        </HStack>
      ))}
    </Fragment>
  );
}

interface MultiOptionsStackProps extends StackProps {
  optionSet: MCQ.IMCQ['options'];
  isGlobal?: boolean;
  optionSetName: string;
}

export function MultiOptionsStack({
  optionSet,
  isGlobal,
  optionSetName,
  ...stackProps
}: MultiOptionsStackProps) {
  return (
    <HStack
      overflow="scroll"
      className="no-scrollbar"
      w="100%"
      cursor="default"
      {...stackProps}>
      {isGlobal ? (
        <Fragment>
          <Icon as={() => QuestionTypeButtons.MCQs.Icon} color="#545f70" />
          <Text>{optionSetName}</Text>
        </Fragment>
      ) : (
        optionSet
          ?.sort((a, b) => a.orderIndex - b.orderIndex)
          .map((innerOp, innerOpIndex) => (
            <Text
              key={innerOpIndex}
              // noOfLines={1}
              isTruncated
              backgroundColor={innerOp.color ? `${innerOp.color}30` : 'gray'}
              borderRadius={'12px'}
              fontSize={'13px'}
              fontWeight={400}
              color={innerOp.color}
              minW="50px"
              padding={'3.6px 6.4px'}>
              {innerOp?.value}
            </Text>
          ))
      )}
    </HStack>
  );
}
