import {
  Button,
  HStack,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { Fragment, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { FiChevronDown, FiChevronRight, FiChevronUp } from "react-icons/fi";
import { RiGroupLine } from "react-icons/ri";
import { RxPerson } from "react-icons/rx";
import SiteGroupMembership from "../Modals/SiteGroupMembership";
import Group from "./Group";
import User from "./User";

export default function InspectionInput() {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const MembershipModal = useDisclosure();
  const Userpopover = useDisclosure();
  // const Items:Array<{
  //   label: string;
  //   onClick : ()=>{}
  // }={
  //   label="Group",
  //   onclick = Grouppopover.onToggle
  // }>
  const [popoverComponent, setPopoverComponent] = useState<React.ReactNode>();
  const Items: Array<{
    icon: any;
    label: string;
    onclick: any;
  }> = [
    {
      icon: RxPerson,
      label: "Groups",
      onclick: () => {
        setPopoverComponent(
          <Group
            onBack={() => {
              setPopoverComponent(<PopoverListItems />);
            }}
          />
        );
      },
    },
    {
      icon: RiGroupLine,
      label: "Users",
      onclick: () => {
        setPopoverComponent(
          <User
            options={[
              {
                label: "danish",
              },
              {
                label: "saoud",
              },
              {
                label: "asif",
              },
            ]}
            onBack={() => {
              setPopoverComponent(<PopoverListItems />);
            }}
          />
        );
      },
    },
  ];

  function PopoverListItems() {
    return (
      <Fragment>
        {Items.map((menu, i) => {
          return (
            <Fragment key={i}>
              <HStack
                _hover={{ backgroundColor: "primary.50" }}
                onClick={menu.onclick}
                p="16px"
                lineHeight="16px"
              >
                {/* <RiGroupLine size={21} color="#1da5d4" /> */}
                <Icon as={menu.icon} color="#1da5d4" boxSize={21} />
                <Text
                  flex={1}
                  fontSize="14px"
                  fontWeight={400}
                  color="textcolors.primary"
                >
                  {menu.label}
                </Text>
                <FiChevronRight size={18} color="#1da5d4" />
              </HStack>
            </Fragment>
          );
        })}
      </Fragment>
    );
  }
  return (
    <Popover isOpen={isOpen} matchWidth={true}>
      <PopoverTrigger>
        <HStack
          border="1px solid"
          borderColor="borders.accent"
          _hover={{ borderColor: "primary.500" }}
          borderRadius="4px"
          px="16px"
          py="8px"
          onClick={onToggle}
        >
          <Stack flex={1}>
            <Text fontSize="16px" fontWeight={400} lineHeight="24px">
              Danish
            </Text>
          </Stack>
          {isOpen ? <FiChevronUp /> : <FiChevronDown />}
        </HStack>
      </PopoverTrigger>
      <PopoverContent w="100%" pb="8px">
        <PopoverBody padding={0}>
          <Stack spacing={0}>{popoverComponent ?? <PopoverListItems />}</Stack>
          <HStack
            onClick={MembershipModal.onOpen}
            _hover={{ backgroundColor: "primary.50" }}
            p="16px"
            lineHeight="16px"
          >
            <AiOutlinePlus size={21} color="#1da5d4" />
            <Text
              flex={1}
              fontSize="14px"
              fontWeight={400}
              color="textcolors.primary"
            >
              Based on Site & Group membership
            </Text>
          </HStack>
          <SiteGroupMembership
            isOpen={MembershipModal.isOpen}
            onClose={MembershipModal.onClose}
          />
        </PopoverBody>
        <PopoverFooter textAlign="end">
          <Button
            h="40px"
            w={"auto"}
            borderRadius="5px"
            fontSize="14px"
            fontWeight={500}
            marginRight="16px"
            padding={"12px 20px"}
          >
            Done
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
