import {
  AbsoluteCenter,
  Avatar,
  Button,
  ButtonGroup,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useState } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { BsArrowCounterclockwise } from "react-icons/bs";
import { FiMoreVertical } from "react-icons/fi";
import { IoIosSettings } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useBusinessContext } from "../../../../../../../Hooks/BusinessContext";
import { TemplateMeta } from "../../../../../../modules/Audit";
import BuildBottomArchiveTemplates from "./BuildBottomTemplatesArchive";

interface ArchiveTableLgProps {
  data: Array<TemplateMeta.TemplateMeta>;
  isLoading: boolean;
  setLastUpdate: (timestamp: number) => void;
}
export default function ArchiveTableLg({
  data,
  isLoading,
  setLastUpdate,
}: ArchiveTableLgProps) {
  const { allUsers } = useBusinessContext();
  const toast = useToast();
  const [selectedTemplateIds, setSelectedTemplateIds] = useState<number[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const deleteModal = useDisclosure();
  const handleCheckboxClick = (templateId: number) => {
    if (selectedTemplateIds.includes(templateId)) {
      setSelectedTemplateIds((prevIds) =>
        prevIds.filter((id) => id !== templateId)
      );
    } else {
      setSelectedTemplateIds((prevIds) => [...prevIds, templateId]);
      onOpen();
    }
  };
  const menuitems: Array<{
    label: string;
    icon: any;
    onClick?: (...args: any) => void;
  }> = [
    {
      label: "Restore",
      icon: BsArrowCounterclockwise,
      onClick: (templateId: number) => {
        const archiveTemplate: Partial<TemplateMeta.TemplateMeta> = {
          archive: false,
        };

        TemplateMeta.Update(
          templateId,
          archiveTemplate,
          () => {
            toast({
              title: "Template Restored",
              status: "success",
            });
            setLastUpdate(Date.now());
          },
          (error) => {
            toast({
              title: "Error",
              description: error,
              status: "error",
            });
          }
        );
      },
    },
    {
      label: "Delete",
      icon: MdDelete,
      onClick: (templateId: number) => {
        if (templateId) {
          deleteModal.onOpen();
        }
      },
    },
  ];
  type ShowColumns = {
    lastPublished: boolean;
    lastModified: boolean;
  };
  const [showColumns, setShowColumns] = useState<ShowColumns>({
    lastPublished: true,
    lastModified: true,
  });
  const handleColumnCheckbox = (column: keyof typeof showColumns) => {
    setShowColumns((prevState) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };
  return (
    <TableContainer
      border="1px solid"
      borderRadius="5px"
      borderColor="borders.accent"
    >
      <Table variant="simple">
        <Thead bg="backgrounds.primary">
          <Tr>
            <Th maxW="40px" p="16px" borderColor="borders.accent">
              <Checkbox
                w="21px"
                h="21px"
                borderColor="grey"
                isChecked={data.length === selectedTemplateIds.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    const allTemplateIds = data.map((temp) => temp.id);
                    setSelectedTemplateIds(allTemplateIds);
                    onOpen();
                  } else {
                    setSelectedTemplateIds([]);
                  }
                }}
              />
            </Th>
            <Th
              w="60%"
              //   flex={1}
              py="16px"
              pl="8px"
              pr="16px"
              fontSize="13px"
              fontWeight={400}
              lineHeight="1rem"
              color="textcolors.primary"
              textDecoration="underline"
              borderColor="borders.accent"
            >
              Name
            </Th>
            {showColumns.lastPublished && (
              <Th maxW="150px" p="16px" borderColor="borders.accent">
                <HStack>
                  <Text
                    fontSize="13px"
                    fontWeight={400}
                    lineHeight="1rem"
                    color="textcolors.primary"
                    textDecoration="underline"
                  >
                    last Published
                  </Text>
                  <AiOutlineArrowDown size={18} color="#3f495a" />
                </HStack>
              </Th>
            )}
            {showColumns.lastModified && (
              <Th maxW="150px" p="16px" borderColor="borders.accent">
                <HStack>
                  <Text
                    fontSize="13px"
                    fontWeight={400}
                    lineHeight="1rem"
                    color="textcolors.primary"
                    textDecoration="underline"
                  >
                    last Modified
                  </Text>
                  <AiOutlineArrowDown size={18} color="#3f495a" />
                </HStack>
              </Th>
            )}
            <Th
              maxW="200px"
              p="16px"
              fontSize="13px"
              fontWeight={400}
              lineHeight="1rem"
              color="textcolors.primary"
              borderColor="borders.accent"
            >
              Access
            </Th>

            <Th
              maxW="40px"
              py="16px"
              pr="0px"
              pl="8px"
              borderColor="borders.accent"
            >
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<IoIosSettings size={18} color="#545f70" />}
                  variant="outline"
                  borderRadius={"50%"}
                  border={"none"}
                  _hover={{ backgroundColor: "transparent" }}
                  _active={{
                    backgroundColor: "transparent",
                  }}
                />
                <MenuList minW="160px">
                  <MenuItem px="14px" py="9.5px">
                    <Text fontSize="13px" fontWeight={400} lineHeight="1rem">
                      Show / hide columns
                    </Text>
                  </MenuItem>
                  {(
                    [
                      { key: "lastPublished", label: "Last published" },
                      { key: "lastModified", label: "Last modified" },
                    ] as const
                  ).map((menu, i) => (
                    <MenuItem key={i} px="14px" py="9.5px">
                      <Checkbox
                        isChecked={showColumns[menu.key]}
                        onChange={() => handleColumnCheckbox(menu.key)}
                      >
                        {menu.label}
                      </Checkbox>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <AbsoluteCenter>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="primary.500"
                size="xl"
              />
            </AbsoluteCenter>
          ) : (
            data.map((temp, i) => {
              return (
                <Tr key={i}>
                  <Td
                    maxW="40px"
                    py="16px"
                    px="16px"
                    borderColor="borders.accent"
                  >
                    <Checkbox
                      w="21px"
                      h="21px"
                      borderColor="grey"
                      isChecked={selectedTemplateIds.includes(temp.id)}
                      onChange={() => {
                        handleCheckboxClick(temp.id);
                      }}
                    />
                  </Td>
                  <Td py="16px" pr="8px" pl="0px" borderColor="borders.accent">
                    <HStack>
                      {temp.image ? (
                        <Image
                          h="40px"
                          w="40px"
                          ml="8px"
                          src={temp.image}
                          borderRadius="8px"
                        />
                      ) : (
                        <Avatar
                          w="40px"
                          h="40px"
                          ml="8px"
                          name={temp.title}
                          backgroundColor="primary.500"
                        />
                      )}
                      <Stack spacing={0}>
                        <Text fontWeight={500} color="textcolors.primary">
                          {temp.title}
                        </Text>
                        <Text
                          w="auto"
                          textColor="textcolors.primary"
                          fontWeight={400}
                        >
                          {allUsers.find(
                            (user) => user.user?.id === temp.createdBy
                          )?.user?.firstName +
                            " " +
                            allUsers.find(
                              (user) => user.user?.id === temp.createdBy
                            )?.user?.lastName}
                        </Text>
                      </Stack>
                    </HStack>
                  </Td>
                  {showColumns.lastPublished && (
                    <Td
                      py="16px"
                      pl="16px"
                      borderColor="borders.accent"
                      fontSize="14px"
                      color="textcolors.secondary"
                    >
                      {temp.lastPublished}
                    </Td>
                  )}
                  {showColumns.lastModified && (
                    <Td
                      py="16px"
                      pl="16px"
                      borderColor="borders.accent"
                      fontSize="14px"
                      color="textcolors.secondary"
                    >
                      {temp.lastModified}
                    </Td>
                  )}

                  <Td p="16px" borderColor="borders.accent">
                    <Text fontSize="14px" color="textcolors.secondary">
                      {temp.countOfPermissions}
                    </Text>
                  </Td>

                  <Td py="16px" px="0px" borderColor="borders.accent">
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        pl="12px"
                        aria-label="Options"
                        icon={<FiMoreVertical size={24} color="#545f70" />}
                        variant="outline"
                        borderRadius={"50%"}
                        border={"none"}
                        _hover={{ backgroundColor: "transparent" }}
                        _active={{
                          backgroundColor: "transparent",
                        }}
                      />
                      <MenuList minW="160px">
                        {menuitems.map((menu, i) => {
                          return (
                            <Fragment key={i}>
                              <MenuItem
                                px="14px"
                                py="9.6px"
                                color="textcolors.primary"
                                onClick={() => {
                                  menu.onClick?.(temp.id);
                                }}
                              >
                                <HStack>
                                  <Icon
                                    as={menu.icon}
                                    boxSize={18}
                                    color="#1f2533"
                                  />
                                  <Text fontSize="14px" fontWeight={400}>
                                    {menu.label}
                                  </Text>
                                </HStack>
                              </MenuItem>
                              <Modal
                                isOpen={deleteModal.isOpen}
                                onClose={deleteModal.onClose}
                              >
                                <ModalOverlay />
                                <ModalContent mx="10px">
                                  <ModalHeader fontWeight="semibold" py="4px">
                                    Confirmation
                                  </ModalHeader>
                                  <ModalCloseButton />
                                  <ModalBody>
                                    Are you sure you want to Delete this?
                                  </ModalBody>

                                  <ModalFooter
                                    display="flex"
                                    justifyContent="flex-end"
                                    pt="16px"
                                    border="0px"
                                  >
                                    <ButtonGroup
                                      size="xs"
                                      variant="solid"
                                      borderRadius="5px"
                                    >
                                      <Button
                                        onClick={deleteModal.onClose}
                                        borderRadius="5px"
                                      >
                                        No
                                      </Button>
                                      <Button
                                        colorScheme="red"
                                        borderRadius="5px"
                                        onClick={() => {
                                          TemplateMeta.Delete(
                                            temp.id,
                                            () => {
                                              toast({
                                                title: "Success",
                                                description:
                                                  "Template deleted sucessfully",
                                                status: "success",
                                              });
                                              setLastUpdate(Date.now());
                                              deleteModal.onClose();
                                            },
                                            (error) => {
                                              toast({
                                                title: "Error",
                                                description: error,
                                                status: "error",
                                              });
                                              deleteModal.onClose();
                                            }
                                          );
                                        }}
                                      >
                                        Yes
                                      </Button>
                                    </ButtonGroup>
                                  </ModalFooter>
                                </ModalContent>
                              </Modal>
                            </Fragment>
                          );
                        })}
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              );
            })
          )}
        </Tbody>
        <BuildBottomArchiveTemplates
          isOpen={isOpen}
          onClose={onClose}
          templateIds={selectedTemplateIds}
          setLastUpdate={setLastUpdate}
        />
      </Table>
    </TableContainer>
  );
}
