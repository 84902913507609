import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  HStack,
  IconButton,
  Stack,
  Text,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useBusinessContext } from "../../../../../../../Hooks/BusinessContext";
import { Inspection, Template } from "../../../../../../modules/Audit";
import { PublicLibraryModule } from "../../../../../../modules/Audit/PublicLibrary";
import InspectionForm from "../../../../../inspection/InspectionForm";
import Content from "../../../Content";
import SubHeading from "../../../Sub-Heading";
interface previewprops {
  isOpen: boolean;
  onClose: () => void;
  libraryTemplate: PublicLibraryModule.FetchLibraryTemplates | undefined;
}
export default function PreviewDrawer({
  isOpen,
  onClose,
  libraryTemplate,
}: previewprops) {
  const navigate = useNavigate();
  const template = useForm({
    defaultValues: {},
    resolver: yupResolver(Inspection.ValidationSchema),
  });
  const { setLastUpdatedLocations } = useBusinessContext();
  const toast = useToast();
  const [isLoading, setIsLoading] = useBoolean(true);
  const [isCreatingInspection, setIsCreatingInspection] = useState(false);
  const [templateData, setTemplateData] =
    useState<PublicLibraryModule.templateData>();
  useEffect(() => {
    if (libraryTemplate?.templateId)
      Template.GetById(
        libraryTemplate.templateId,
        (temp) => {
          template.reset({ ...temp, mediaUniqueId: uuidv4() });
          setIsLoading.off();
        },
        (error) => {
          console.log(error);
          toast({
            title: "Error",
            description: "You do not have permission to submit this resource",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          navigate(-1);
        }
      );
  }, [libraryTemplate?.templateId]);
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody p={0}>
          <Stack spacing="24px" p="24px" bgColor="white">
            <HStack>
              <IconButton
                aria-label="close"
                icon={<AiOutlineClose size={24} color="#545F70" />}
                backgroundColor="transparent"
                _hover={{ backgroundColor: "transparent" }}
                _active={{
                  backgroundColor: "transparent",
                }}
                onClick={onClose}
              />
              <Text
                align="center"
                fontSize="16px"
                fontWeight={500}
                color="textcolors.primary"
                flex={1}
              >
                Preview
              </Text>
            </HStack>
            <Stack spacing={0}>
              <SubHeading>{libraryTemplate?.name}</SubHeading>
              <Content fontSize="13px">{libraryTemplate?.description}</Content>
            </Stack>
            <HStack spacing="16px">
              <Button
                isLoading={isCreatingInspection}
                flex={2}
                px="12px"
                py="5.5px"
                h="42.5px"
                borderRadius="8px"
                fontSize="14px"
                onClick={() => {
                  setIsCreatingInspection(true);
                  PublicLibraryModule.DownloadLibraryTemplate(
                    { recordId: libraryTemplate?.id! },
                    (data) => {
                      // navigate(`/app/auditor/inspection-conduct/NEW_${data.id}`);
                      Template.GetById(
                        +data?.id,
                        (temp) => {
                          Inspection.SubmitInspection(
                            {
                              inspectionCompleted: false,
                              inspectionInventory: [],
                              inspectionScore: 0,
                              mediaUniqueId: uuidv4(),
                              archive: false,
                              templateItems: temp.templateItems as any,
                              template: temp.template,
                              deletedInventoryItems: [],
                              templateMedia: temp?.templateMedia?.map((doc) => {
                                return {
                                  templateId: doc.tempId,
                                  attachmentUrl: doc.docUrl,
                                  attachmentName: doc.fileName,
                                  id: doc.id!,
                                };
                              }),
                            },
                            (inspection) => {
                              // setIsCreatingInspection(false);
                              setLastUpdatedLocations(Date.now());
                              setIsCreatingInspection(false);
                              navigate(
                                `/app/auditor/inspection-conduct/CONTINUE_${inspection.inspection?.id}`
                              );
                              // console.log(inspection);
                            },
                            (error) => {
                              toast({
                                title: "Error",
                                description:
                                  "Error Occured while Submitting Inspection",
                                status: "error",
                              });
                              setIsCreatingInspection(false);
                            }
                          );
                        },
                        (error) => {
                          console.log(error);
                          toast({
                            title: "Error",
                            description:
                              "You do not have permission to submit this resource",
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                          });
                          setIsCreatingInspection(false);
                          navigate(-1);
                        }
                      );
                    },
                    () => {
                      console.log("Error");
                    }
                  );
                }}
              >
                Import & start inspection
              </Button>
              <Button
                flex={1}
                px="12px"
                py="5.5px"
                h="42.5px"
                variant="outline"
                borderRadius="8px"
                fontSize="14px"
                borderColor="borders.accent"
                onClick={() => {
                  console.log(libraryTemplate?.id);
                  PublicLibraryModule.DownloadLibraryTemplate(
                    { recordId: libraryTemplate?.id! },
                    (data) => {
                      navigate(
                        `/app/auditor/template-edit/${data.id}/?tab=Build`
                      );
                    },
                    () => {
                      toast({
                        title: "Error",
                        description: "Template import failed",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });
                    }
                  );
                }}
              >
                Edit questions
              </Button>
            </HStack>
          </Stack>
          {/* <Text backgroundColor="red">Preview</Text> */}
          {(libraryTemplate?.templateId ?? 0) > 0 && !isLoading && (
            <FormProvider {...template}>
              <InspectionForm mode="PREVIEW" />
            </FormProvider>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
