import {
  Checkbox,
  Container,
  HStack,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Country, State } from "country-state-city";
import { useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { BusinessTaxRate } from "../../../Api/BusinessTaxRate";
import { ChartOfAccounts } from "../../../Api/ChartOfAccounts";
import { ContactApi } from "../../../Api/Contact";

interface SalesProp {
  salesDefaultsRef: React.RefObject<HTMLDivElement>;

  taxRateData: BusinessTaxRate.FetchTaxRate[];
  chartOfAccounts: ChartOfAccounts.FetchChartOfAccounts[];
}
export function SalesDetails({
  salesDefaultsRef,
  taxRateData,
  chartOfAccounts,
}: SalesProp) {
  const form = useFormContext<ContactApi.AddContact>();
  const watchInvoiceDue = useWatch({
    control: form.control,
    name: "salesDefaults.invoiceDueDateOption",
  });
  const [provinces, setProvinces] = useState<
    Array<{
      name: string;
    }>
  >([]);
  const watchCreditLimit = useWatch({
    control: form.control,
    name: "salesDefaults.creditLimitAmount",
  });
  let countryData = Country.getAllCountries();
  const { businessesList, currentBusinessId } = useBusinessContext();
  useEffect(() => {
    const provinceOptions = State.getStatesOfCountry(
      countryData.find(
        (country) =>
          country.name ===
          businessesList.find((business) => business.id === currentBusinessId)
            ?.country
      )?.isoCode
    );
    const province = provinceOptions.map((province: any) => {
      return { name: province?.name };
    });
    setProvinces(province);
  }, []);
  const brandingOptions = [
    "Standard",
    "Special Projects",
    "Very orange invoices",
  ].map((brand) => ({
    label: brand,
    value: brand,
  }));
  const [branding, setBranding] = useState("");

  const toast = useToast();

  const billingOptions: Array<{ label: string; value: string }> = [
    {
      label: "of the following month",
      value: "of the following month",
    },
    {
      label: "days(s) after the invoice date",
      value: "days(s) after the invoice date",
    },
    {
      label: "days(s) after the invoice month",
      value: "days(s) after the invoice month",
    },
    {
      label: "off the current month",
      value: "off the current month",
    },
  ];

  const amountOptions: Array<{ label: string; value: string }> = [
    {
      label: "Use organisation settings",
      value: "Use organisation settings",
    },
    {
      label: "Tax inclusive",
      value: "Tax inclusive",
    },
    {
      label: "Tax exclusive",
      value: "Tax exclusive",
    },
    {
      label: "No Tax",
      value: "No Tax",
    },
  ];
  return (
    <Stack
      ref={salesDefaultsRef}
      _dark={{ bgColor: "customColor.dark.50" }}
      _light={{ bgColor: "customColor.white" }}
      paddingBottom={"30px"}
      borderRight={"1px solid #e5e7eb"}
      borderLeft={"1px solid #e5e7eb"}
      borderBottom={"2px solid #e5e7eb"}
    >
      <Container maxW={"450px"}>
        <Text
          padding={"16px 0px"}
          margin={"14px 0px"}
          fontSize={"17px"}
          color={"customColor.black7"}
          fontWeight={"700"}
        >
          Sales Defaults
        </Text>
        <Stack spacing={"25px"}>
          <Text fontSize={"15px"}>
            Defaults can be overridden on individual invoices, quotes, and
            receive money
          </Text>
          <Stack gap={0}>
            <Text
              fontSize={".845rem"}
              fontWeight={"600"}
              color={"customColor.gray.6b7885"}
              margin={"0px 12px 8px 0px"}
            >
              Sales account
            </Text>
            <Controller
              name={"salesDefaults.salesAccountId"}
              control={form.control}
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  placeholder=""
                  withValidation
                  options={chartOfAccounts?.map((acc: any) => ({
                    value: acc.id,
                    label: `${acc.code}: ${acc.name}`, // Including code before name
                  }))}
                  value={chartOfAccounts
                    ?.map((acc: any) => ({
                      value: acc.id,
                      label: `${acc.code}: ${acc.name}`, // Including code before name
                    }))
                    .find((option: any) => option.value === field.value)}
                  onChange={(newValue: any) => field.onChange(newValue?.value)}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              )}
            />
          </Stack>
          <Stack gap={0}>
            <Text
              fontSize={".845rem"}
              fontWeight={"600"}
              color={"customColor.gray.6b7885"}
              margin={"0px 12px 8px 0px"}
            >
              Invoice due date
            </Text>
            <HStack gap={"0.5"} alignItems={"baseline"}>
              <Stack flex={1}>
                <Controller
                  name="salesDefaults.invoiceDueDay"
                  control={form.control}
                  rules={{
                    validate: (value) => {
                      if (value < 0) {
                        return "Day Of Month cannot be negative";
                      } else if (
                        value > 31 &&
                        (watchInvoiceDue === "FOLLOWING_MONTH" ||
                          watchInvoiceDue === "CURRENT_MONTH")
                      ) {
                        return "Day Of Month cannot be more than 31";
                      }
                      return true;
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      withValidation
                      input={{
                        ...field,

                        fontSize: ".845rem",
                        h: "38px",
                        variant: "outline",
                        type: "text",
                        onChange: (e) => {
                          field.onChange(Number(e.target.value));
                        },

                        borderTopRadius: "3px",
                        borderRightRadius: "0px",
                        alignContent: "center",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Stack>
              <Stack flex={6}>
                <Controller
                  control={form.control}
                  name="salesDefaults.invoiceDueDateOption"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select"
                      // marginBottom="30px"
                      // width={"48em"}

                      withValidation
                      options={billingOptions}
                      value={billingOptions.find(
                        (billingOptions) => billingOptions.value === field.value
                      )}
                      onChange={(newValue: any) =>
                        field.onChange(newValue?.value)
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </Stack>
            </HStack>
          </Stack>
          <Stack gap={0}>
            <Text
              fontSize={".845rem"}
              fontWeight={"600"}
              color={"customColor.gray.6b7885"}
              margin={"0px 12px 8px 0px"}
            >
              Amount are
            </Text>
            <Controller
              name={"salesDefaults.amountType"}
              control={form.control}
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  key={field.value}
                  options={amountOptions}
                  withValidation
                  placeholder="Select..."
                  value={amountOptions.find((op) => op.value === field.value)}
                  onChange={(newValue: any) => {
                    field.onChange(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              )}
            />
          </Stack>
          <Stack gap={0}>
            <Text
              fontSize={".845rem"}
              fontWeight={"600"}
              color={"customColor.gray.6b7885"}
              margin={"0px 12px 8px 0px"}
            >
              Sales GST
            </Text>
            <Controller
              name={`salesDefaults.saleGST`}
              control={form.control}
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  placeholder=""
                  withValidation
                  options={taxRateData}
                  value={
                    field.value
                      ? {
                          id: field.value,
                          name:
                            taxRateData.find(
                              (taxRateData) => taxRateData.id === field.value
                            )?.name ?? "",
                        }
                      : undefined
                  }
                  onChange={(val: any) => {
                    field.onChange(val?.id);
                  }}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.id}
                />
              )}
            />
          </Stack>
          <InputGroup w="100%">
            <Controller
              name="salesDefaults.discount"
              control={form.control}
              rules={{
                validate: (value) => {
                  if (value === undefined || value === null) {
                    return true; // Allow empty or undefined values
                  }
                  return value <= 100 || "Discount cannot exceed 100"; // Apply validation for non-empty values
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  ref={field.ref}
                  withValidation
                  input={{
                    ...field,
                    w: "100%",
                    h: "38px",
                    _light: {
                      color: "customColor.black7",
                    },
                    fontSize: ".845rem",
                    variant: "outline",
                    type: "text",
                    onChange: (e) => {
                      const value = e.target.value;
                      if (value === "" || Number(value) <= 100) {
                        field.onChange(value === "" ? null : Number(value)); // Allow empty string and handle number conversion
                      }
                    },
                    padding: "12px 15px",
                    borderTopRadius: "3px",
                  }}
                  placeholder=""
                  label="Discount"
                  labelProps={{
                    fontSize: ".845rem",
                    fontWeight: "600",
                    _light: {
                      color: "customColor.gray.6b7885",
                    },
                  }}
                />
              )}
            />

            <InputRightElement h="100%" w="3rem">
              <Text marginTop={"30px"}>%</Text>
            </InputRightElement>
          </InputGroup>

          <Controller
            name="salesDefaults.creditLimitAmount"
            control={form.control}
            // rules={{
            //   validate: (value) => value <= 100 || "Discount cannot exceed 100",
            // }}
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                withValidation
                validationMessage={error?.message}
                input={{
                  ...field,
                  // isInvalid: !!error,
                  w: "100%",
                  h: "38px",
                  _light: {
                    color: "customColor.black7",
                  },
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "text",
                  onChange: (e) => {
                    field.onChange(Number(e.target.value));
                  },
                  padding: "12px 15px",
                  borderTopRadius: "3px",
                }}
                placeholder=""
                label="Credit Limit amount"
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.gray.6b7885",
                  },
                }}
              />
            )}
          />

          <Stack direction={"row"} padding={"7px"} border={"1px solid #e5e7eb"}>
            <Controller
              control={form.control}
              name="salesDefaults.blockNewInvoiceWhenLimitReached"
              render={({
                field: { value, ...field },
                fieldState: { error },
              }) => (
                <Checkbox
                  isDisabled={!watchCreditLimit ? true : false}
                  size={"lg"}
                  {...field}
                  isInvalid={error !== undefined}
                >
                  <Text fontSize={".845rem"} color={"customInput.black7"}>
                    Block new invoices when credit limit is reached
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack gap={0}>
            <Text
              fontSize={".845rem"}
              fontWeight={"600"}
              color={"customColor.gray.6b7885"}
              margin={"0px 12px 8px 0px"}
            >
              Branding theme
            </Text>
            <ReactSelect
              withValidation
              options={brandingOptions}
              value={brandingOptions.find((op) => op.value === branding)}
              onChange={(newValue: any) => {
                setBranding(newValue?.value);
              }}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
            />
          </Stack>
          <Stack gap={0}>
            <Text
              fontSize={".845rem"}
              fontWeight={"600"}
              color={"customColor.gray.6b7885"}
              margin={"0px 12px 8px 0px"}
            >
              Region
            </Text>
            <Controller
              control={form.control}
              name={`salesDefaults.region`}
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  key={field.value}
                  options={provinces}
                  ErrorMessage="Please Select Region"
                  placeholder="Select..."
                  value={provinces?.find((op: any) => op.name === field.value)}
                  onChange={(newValue: any) => {
                    field.onChange(newValue?.name);
                  }}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.name}
                />
              )}
            />
          </Stack>
          <Controller
            name="salesDefaults.hrisNetworkKey"
            control={form.control}
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                // validationMessage={error?.message}
                withValidation
                ref={field.ref}
                input={{
                  ...field,

                  // isInvalid: error !== undefined,
                  w: "100%",

                  h: "38px",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "number",

                  borderTopRadius: "3px",
                }}
                placeholder=""
                label="HRIS network key"
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.gray.6b7885",
                  },
                }}
              />
            )}
          />
        </Stack>
      </Container>
    </Stack>
  );
}
