import {
  Avatar,
  Box,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { AiOutlineSetting } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { IoMdLogOut } from "react-icons/io";
import { MdPassword } from "react-icons/md";
import { useNavigate } from "react-router-dom";

interface Props {
  decodedToken: any;
}
export function AvatarMenu({ decodedToken }: Props) {
  const navigate = useNavigate();
  const user = useAuthUser();
  const toast = useToast();
  const [userName, setUserName] = useState("");
  const signOut = useSignOut();
  const UserDetail = user();
  useEffect(() => {
    const UserDetail = user();

    if (UserDetail) {
      const fullName = UserDetail.firstName + " " + UserDetail.lastName;
      setUserName(fullName); // Update the state variable
    } else {
      toast({
        title: "Name not found in the user object",
        status: "error",
      });
    }
  }, []);
  return (
    <Popover trigger="hover" placement="bottom-start">
      <PopoverTrigger>
        <Box
          w="60px"
          h="70px"
          px="12px"
          marginRight={"10px"}
          py="7.520px"
          display="flex"
          alignItems="center"
          cursor="pointer"
        >
          <Avatar w="36px" h="36px" name={userName} />
        </Box>
      </PopoverTrigger>
      <PopoverContent
        w={"300px"}
        padding={"15px 0"}
        border={"1px solid #e5e7eb"}
        boxShadow={"0 10px 24px 0 rgba(62,57,107,.18)"}
      >
        <Stack
          gap={0}
          marginBottom={"10px"}
          direction={"row"}
          paddingBottom={"20px"}
          paddingLeft={"25px"}
          paddingRight={"25px"}
          borderBottom={"1px solid #e5e7eb"}
        >
          <Avatar
            w="36px"
            h="36px"
            marginRight={"15px"}
            name={userName}
            src={UserDetail!.photo}
          />
          <Stack>
            <Text
              fontSize={".9375rem"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black7",
              }}
            >
              {userName}
            </Text>
            <Text
              fontSize={"12px"}
              fontWeight={"600"}
              _light={{
                color: "customColor.gray.64748B",
              }}
            >
              {UserDetail!.email}
            </Text>
          </Stack>
        </Stack>

        {[
          {
            label: "View Profile",
            icon: CgProfile,
            onClick: () => navigate("/app/payroll/UserProfile"),
          },
          {
            label: "Change Password",
            icon: MdPassword,
            onClick: () => navigate("/app/payroll/ChangePasswordEmail"),
          },
          {
            label: "Switch Business",
            icon: HiOutlineSwitchHorizontal,
            onClick: () => navigate("/app/SwitchBusiness"),
          },
        ].map((item, index) => (
          <Stack
            key={index}
            padding={0}
            _hover={{ bg: "none" }}
            margin={"0px 10px"}
          >
            <Stack
              w={"100%"}
              borderRadius={"5px"}
              alignItems={"center"}
              cursor={"pointer"}
              padding={"10px 15px"}
              _hover={{
                _light: {
                  color: "customColor.black8",
                },
                bg: "#eaebef",
                transition: "all .3s ease",
              }}
              direction={"row"}
              onClick={item.onClick}
            >
              <Icon as={item.icon} size="16px" color={"#283C50"} />
              <Text
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
                whiteSpace={"nowrap"}
                fontSize="13px"
              >
                {item.label}
              </Text>
            </Stack>
          </Stack>
        ))}
        {decodedToken.accessLevel !== "EMPLOYEE" &&
          decodedToken.accessLevel !== "CLIENT" && (
            <Stack padding={0} _hover={{ bg: "none" }} margin={"0px 10px"}>
              <Stack
                w={"100%"}
                borderRadius={"5px"}
                alignItems={"center"}
                cursor={"pointer"}
                padding={"10px 15px"}
                _hover={{
                  _light: {
                    color: "customColor.black8",
                  },
                  bg: "#eaebef",
                  transition: "all .3s ease",
                }}
                direction={"row"}
                onClick={() => {
                  navigate("/app/payroll/organisationSettings");
                }}
              >
                <Icon as={AiOutlineSetting} size="16px" color={"#283C50"} />
                <Text
                  fontWeight="600"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.pearlWhite",
                  }}
                  whiteSpace={"nowrap"}
                  fontSize="13px"
                >
                  {"Settings"}
                </Text>
              </Stack>
            </Stack>
          )}

        <Stack
          borderTop={"1px solid #e5e7eb"}
          padding={"15px 0 0px 0px"}
          marginTop={"10px"}
        >
          <Stack padding={0} _hover={{ bg: "none" }} margin={"0px 10px"}>
            <Stack
              w={"100%"}
              borderRadius={"5px"}
              cursor={"pointer"}
              alignItems={"center"}
              padding={"10px 15px"}
              _hover={{
                _light: {
                  color: "customColor.black8",
                },
                bg: "#eaebef",
                transition: "all .3s ease",
              }}
              direction={"row"}
              onClick={() => {
                signOut();
                toast({
                  title: "Logout Successful",
                  status: "success",
                });
                navigate("/");
              }}
            >
              <Icon as={IoMdLogOut} size="16px" color={"#283C50"} />
              <Text
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
                whiteSpace={"nowrap"}
                fontSize="13px"
              >
                Sign out
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </PopoverContent>
    </Popover>
  );
}
