import { Button, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { IoClose } from "react-icons/io5";

export function InvoiceTemplatePopUp() {
  const { onOpen } = useDisclosure();
  const [state, setState] = useState(false);
  return (
    <Stack
      //   display={state ? "none" : "flex"}
      display={"none"}
      direction={{ xs: "column", md: "row" }}
      justifyContent={{ xs: "normal", md: "space-between" }}
      alignItems={{ xs: "normal", md: "center" }}
      _light={{
        bgColor: "customColor.white",
      }}
      padding={"16px"}
      boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      border="1px solid #e5e7eb"
    >
      <Text
        fontSize={"17px"}
        _light={{
          color: "customColor.black7",
        }}
        fontWeight={"700"}
      >
        Set up invoice template and payments
      </Text>
      <Stack direction={"row"}>
        <Button
          variant={"ghost"}
          h={"38px"}
          color={"#3454d1"}
          _hover={{
            bg: "rgba(0,10,30,.05)",
          }}
          onClick={() => {
            setState(!state);
          }}
        >
          Skip for now
        </Button>
        <Button
          fontSize={"13px"}
          bgColor="#3454D1"
          h={"38px"}
          borderRadius={"3px"}
          borderRightRadius={0}
          borderWidth={"1px"}
          borderColor={"#3454d1!important"}
          variant={"solid"}
          padding={"12px 16px"}
          fontWeight={700}
          _hover={{
            bgColor: "#3454D1",
          }}
          onClick={() => {
            onOpen();
          }}
        >
          Add Details
        </Button>
        <Button
          bg={"#fff"}
          h={"34px"}
          color={"customColor.black7"}
          padding={""}
          borderRadius={"full"}
          _hover={{
            bg: "rgba(0,10,30,.05)",
          }}
          onClick={() => {
            setState(!state);
          }}
        >
          <IoClose fontSize={"18px"} />
        </Button>
      </Stack>
    </Stack>
  );
}
