import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";

import { Country } from "country-state-city";
import { jwtDecode } from "jwt-decode";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { useAuthUser, useSignIn } from "react-auth-kit";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { Business } from "../../Api/Business";
import { UploadAttachments } from "../../Api/UploadAttachments";
import HRISLOGO from "./../../../assets/hris-logo.png";
import Delete from "./Delete";

// Generate an array of day options (1 to 31)

interface AddBusinessProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function AddBusiness({ isOpen, onClose }: AddBusinessProps) {
  const { currentBusinessId, setCurrentBusinessId, setLastUpdated } =
    useBusinessContext();
  const navigate = useNavigate();
  const form = useFormContext<Business.AddNewBusiness>();

  const timeOptions = moment.tz.names().map((timezone) => {
    const offset = moment.tz(timezone).format("Z");
    const formattedTimezone = `(GMT${offset}) ${timezone}`;
    return {
      value: timezone,
      label: formattedTimezone,
    };
  });
  const industriesOptions = [
    "Agriculture",
    "Automotive",
    "Banking",
    "Biotechnology",
    "Chemical",
    "Communications",
    "Construction",
    "Consulting",
    "Education",
    "Electronics",
    "Energy",
    "Engineering",
    "Entertainment",
    "Environmental",
    "Finance",
    "Food & Beverage",
    "Government",
    "Healthcare",
    "Hospitality",
    "Insurance",
    "Machinery",
    "Manufacturing",
    "Media",
    "Mining",
    "Oil & Gas",
    "Pharmaceutical",
    "Real Estate",
    "Retail",
    "Technology",
    "Telecommunications",
    "Transportation",
    "Utilities",
  ].map((industries) => ({ label: industries, value: industries }));
  const currencyOptions = [
    "AED: United Arab Emirates Dirham",
    "AFN: Afghan Afghani",
    "ALL: Albanian Lek",
    "AMD: Armenian Dram",
    "ANG: Netherlands Antillean Guilder",
    "AOA: Angolan Kwanza",
    "ARS: Argentine Peso",
    "AUD: Australian Dollar",
    "AWG: Aruban Florin",
    "AZN: Azerbaijani Manat",
    "BAM: Bosnia-Herzegovina Convertible Mark",
    "BBD: Barbadian Dollar",
    "BDT: Bangladeshi Taka",
    "BGN: Bulgarian Lev",
    "BHD: Bahraini Dinar",
    "BIF: Burundian Franc",
    "BMD: Bermudan Dollar",
    "BND: Brunei Dollar",
    "BOB: Bolivian Boliviano",
    "BRL: Brazilian Real",
    "BSD: Bahamian Dollar",
    "BTC: Bitcoin",
    "BTN: Bhutanese Ngultrum",
    "BWP: Botswanan Pula",
    "BYN: Belarusian Ruble",
    "BZD: Belize Dollar",
    "CAD: Canadian Dollar",
    "CDF: Congolese Franc",
    "CHF: Swiss Franc",
    "CLF: Chilean Unit of Account (UF)",
    "CLP: Chilean Peso",
    "CNH: Chinese Yuan (Offshore)",
    "CNY: Chinese Yuan",
    "COP: Colombian Peso",
    "CRC: Costa Rican Colón",
    "CUC: Cuban Convertible Peso",
    "CUP: Cuban Peso",
    "CVE: Cape Verdean Escudo",
    "CZK: Czech Republic Koruna",
    "DJF: Djiboutian Franc",
    "DKK: Danish Krone",
    "DOP: Dominican Peso",
    "DZD: Algerian Dinar",
    "EGP: Egyptian Pound",
    "ERN: Eritrean Nakfa",
    "ETB: Ethiopian Birr",
    "EUR: Euro",
    "FJD: Fijian Dollar",
    "FKP: Falkland Islands Pound",
    "GBP: British Pound Sterling",
    "GEL: Georgian Lari",
    "GGP: Guernsey Pound",
    "GHS: Ghanaian Cedi",
    "GIP: Gibraltar Pound",
    "GMD: Gambian Dalasi",
    "GNF: Guinean Franc",
    "GTQ: Guatemalan Quetzal",
    "GYD: Guyanaese Dollar",
    "HKD: Hong Kong Dollar",
    "HNL: Honduran Lempira",
    "HRK: Croatian Kuna",
    "HTG: Haitian Gourde",
    "HUF: Hungarian Forint",
    "IDR: Indonesian Rupiah",
    "ILS: Israeli New Sheqel",
    "IMP: Manx pound",
    "INR: Indian Rupee",
    "IQD: Iraqi Dinar",
    "IRR: Iranian Rial",
    "ISK: Icelandic Króna",
    "JEP: Jersey Pound",
    "JMD:Jamaican Dollar",
    "JOD: Jordanian Dinar",
    "JPY: Japanese Yen",
    "KES: Kenyan Shilling",
    "KGS:Kyrgystani Som",
    "KHR: Cambodian Riel",
    "KMF: Comorian Franc",
    "KPW: North Korean Won",
    "KRW: South Korean Won",
    "KWD: Kuwaiti Dinar",
    "KYD: Cayman Islands Dollar",
    "KZT: Kazakhstani Tenge",
    "LAK: Laotian Kip",
    "LBP: Lebanese Pound",
    "LKR: Sri Lankan Rupee",
    "LRD: Liberian Dollar",
    "LSL: Lesotho Loti",
    "LYD: Libyan Dinar",
    "MAD: Moroccan Dirham",
    "MDL: Moldovan Leu",
    "MGA: Malagasy Ariary",
    "MKD: Macedonian Denar",
    "MMK: Myanma Kyat",
    "MNT: Mongolian Tugrik",
    "MOP: Macanese Pataca",
    "MRU: Mauritanian Ouguiya",
    "MUR: Mauritian Rupee",
    "MVR: Maldivian Rufiyaa",
    "MWK: Malawian Kwacha",
    "MXN: Mexican Peso",
    "MYR: Malaysian Ringgit",
    "MZN: Mozambican Metical",
    "NAD: Namibian Dollar",
    "NGN: Nigerian Naira",
    "NIO: Nicaraguan Córdoba",
    "NOK: Norwegian Krone",
    "NPR: Nepalese Rupee",
    "NZD: New Zealand Dollar",
    "OMR: Omani Rial",
    "PAB: Panamanian Balboa",
    "PEN: Peruvian Nuevo Sol",
    "PGK: Papua New Guinean Kina",
    "PHP: Philippine Peso",
    "PKR: Pakistani Rupee",
    "PLN: Polish Zloty",
    "PYG: Paraguayan Guarani",
    "QAR: Qatari Rial",
    "RON: Romanian Leu",
    "RSD: Serbian Dinar",
    "RUB: Russian Ruble",
    "RWF: Rwandan Franc",
    "SAR: Saudi Riyal",
    "SBD: Solomon Islands Dollar",
    "SCR: Seychellois Rupee",
    "SDG: Sudanese Pound",
    "SEK: Swedish Krona",
    "SGD: Singapore Dollar",
    "SHP: Saint Helena Pound",
    "SLL: Sierra Leonean Leone",
    "SOS: Somali Shilling",
    "SRD: Surinamese Dollar",
    "SSP: South Sudanese Pound",
    "STD: São Tomé and Príncipe Dobra (pre-2018)",
    "STN: São Tomé and Príncipe Dobra",
    "SVC: Salvadoran Colón",
    "SYP: Syrian Pound",
    "SZL: Swazi Lilangeni",
    "THB: Thai Baht",
    "TJS: Tajikistani Somoni",
    "TMT: Turkmenistani Manat",
    "TND: Tunisian Dinar",
    "TOP: Tongan Pa'anga",
    "TRY: Turkish Lira",
    "TTD: Trinidad and Tobago Dollar",
    "TWD: New Taiwan Dollar",
    "TZS: Tanzanian Shilling",
    "UAH: Ukrainian Hryvnia",
    "UGX: Ugandan Shilling",
    "USD: United States Dollar",
    "UYU: Uruguayan Peso",
    "UZS: Uzbekistan Som",
    "VEF:Venezuelan Bolívar Fuerte (Old)",
    "VES:Venezuelan Bolívar Soberano",
    "VND:Vietnamese Dong",
    "VUV:Vanuatu Vatu",
    "WST: Samoan Tala",
    "XAF: CFA Franc BEAC",
    "XAG: Silver Ounce",
    "XAU: Gold Ounce",
    "XCD: East Caribbean Dollar",
    "XDR: Special Drawing Rights",
    "XOF: CFA Franc BCEAO",
    "XPD: Palladium Ounce",
    "XPF: CFP Franc",
    "XPT: Platinum Ounce",
    "YER: Yemeni Rial",
    "ZAR: South African Rand",
    "ZMW: Zambian Kwacha",
    "ZWL: Zimbabwean Dollar",
  ].map((currency) => ({ label: currency, value: currency }));
  const industries = [
    "Aged care industry",
    "Agricultural industry",
    "Airline operations",
    "Airport operations",
    "Aluminium industry",
    "Ambulance and patient transport",
    "Amusement, events and recreation industry",
    "Animal care and veterinary services",
    "Aquaculture",
    "Asphalt industry",
    "Australian Capital Territory",
    "Banking, finance and insurance industry",
    "Broadcasting and recorded entertainment industry",
    "Building services",
    "Building, metal and civil construction industries",
    "Business equipment industry",
    "Cement and concrete products",
    "Cemetery operations",
    "Children's services",
    "Christmas Island",
    "Cleaning services",
    "Clerical industry",
    "Clothing industry",
    "Coal export terminals",
    "Coal industry",
    "Cocos (Keeling) Islands",
    "Commercial sales",
    "Commonwealth employment",
    "Contract call centre industry",
    "Corrections and detentions",
    "Diving services",
    "Dredging industry",
    "Dry cleaning and laundry services",
    "Educational services",
    "Electrical contracting industry",
    "Electrical power industry",
    "Fast food industry",
    "Federal police operations",
    "Fire fighting services",
    "Food, beverages and tobacco manufacturing industry",
    "Funeral directing services",
    "Gardening services",
    "Grain handling industry",
    "Graphic arts",
    "Hair and beauty",
    "Health and welfare services",
    "Hospitality industry",
    "Indigenous organisations and services",
    "Journalism",
    "Licensed and registered clubs",
    "Live performance industry",
    "Local government administration",
    "Mannequins and modelling",
    "Manufacturing and associated industries",
    "Marine tourism and charter vessels",
    "Market and business consultancy services",
    "Meat industry",
    "Mining industry",
    "Miscellaneous",
    "Norfolk Island",
    "Northern Territory",
    "Nursery industry",
    "Passenger vehicle transport (non rail) industry",
    "Pet food manufacturing",
    "Pharmaceutical industry",
    "Pharmacy operations",
    "Plumbing industry",
    "Port authorities",
    "Postal services",
    "Poultry processing",
    "Publishing industry",
    "Quarrying industry",
    "Racing industry",
    "Rail industry",
    "Real estate industry",
    "Restaurants",
    "Retail industry",
    "Road transport industry",
    "Rubber, plastic and cable making industry",
    "Salt industry",
    "Scientific services",
    "Seafood processing",
    "Security services",
    "Social, community, home care and disability services",
    "Sporting organisations",
    "State and Territory government administration",
    "Stevedoring industry",
    "Storage services",
    "Sugar industry",
    "Tasmania",
    "Technical services",
    "Telecommunications services",
    "Textile industry",
    "Timber and paper products industry",
    "Tourism industry",
    "Uranium mining (incl. construction)",
    "Vehicle industry",
    "Waste management industry",
    "Water, sewerage and drainage services",

    "Wool storage, sampling and testing industry",
    "Aged Care Industry",
    "Agricultural Industry",
    "Airline Operations",
    "Automotive Industry",
    "Banking & Financial Services",
    "Biotechnology Industry",
    "Construction Industry",
    "Consumer Goods Industry",
    "Education Sector",
    "Energy Industry",
    "Entertainment & Media Industry",
    "Fashion & Apparel Industry",
    "Food & Beverage Industry",
    "Healthcare Industry",
    "Hospitality Industry",
    "Information Technology Industry",
    "Insurance Industry",
    "Manufacturing Industry",
    "Mining Industry",
    "Pharmaceutical Industry",
    "Professional Services",
    "Real Estate Industry",
    "Retail Industry",
    "Telecommunications Industry",
    "Transportation and Logistics",
    "Travel and Tourism Industry",
    "Utilities Industry",
    "Accounting Services",
    "Corporate Finance & Investment Management",
    "Financial Planning & Advisory Services",
    "Fintech Industry",
    "Insurance Services",
    "Investment Banking",
    "Private Equity & Venture Capital",
    "Taxation Services",
    "Other",
  ].map((industry) => ({ label: industry, value: industry }));
  let countryData = Country.getAllCountries();
  const toast = useToast();
  const dayOptions = Array.from({ length: 31 }, (_, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  }));
  // Array of month options
  const monthOptions = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  const signIn = useSignIn();
  const user = useAuthUser();
  const [uploadedLogo, setUploadLogo] = useState<number>(Date.now());
  const [logoLoading, setLogoLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isHovering, setIsHovering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const businessName = form.getValues("name");
  const deleteId = form.getValues("id")!;
  const deleteModal = useDisclosure();
  const [deleteBusiness, setDeleteBusiness] = useState<Boolean>(false);
  useEffect(() => {
    if (deleteBusiness) {
      onClose();
    }
  }, [deleteBusiness]);
  const removeLogo = () => {
    form.setValue("logo", "");
    setUploadLogo(Date.now());
  };
  const watchActivationcode = useWatch({
    control: form.control,
    name: "activationCode",
  });
  return (
    // <FormProvider {...form}>
    <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxH={"610px"}
        overflowX={"auto"}
        marginTop={"50px"}
        marginBottom={"50px"}
      >
        <ModalHeader>
          <VStack padding="3px">
            <Image w="200px" src={HRISLOGO} objectFit="fill" alt="Logo" />
            <Text
              _light={{
                color: "customColor.black7",
              }}
              fontSize=".9975rem"
            >
              Add your business
            </Text>
          </VStack>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Stack>
            <Center
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              <Stack position="relative">
                <Avatar
                  key={uploadedLogo}
                  size={"xl"}
                  h={"80px"}
                  w={"80px"}
                  objectFit="contain"
                  name={form.watch("name")}
                  src={`${form.watch("logo")}?updated=${uploadedLogo}`}
                  // Other props
                  borderWidth={"2px"}
                  borderColor={"gray.200"}
                />
                {isHovering && (
                  <IconButton
                    aria-label="Remove logo"
                    icon={<MdDelete color="gray" />}
                    position="absolute"
                    top="20px"
                    right="20px"
                    size="md"
                    onClick={removeLogo}
                    _hover={{
                      bg: "transparent",
                    }}
                    color="white"
                    bg={"transparent"}
                  />
                )}
                {logoLoading && (
                  <Center
                    position="absolute"
                    top="0"
                    right="0"
                    bottom="0"
                    left="0"
                    backgroundColor="rgba(255, 255, 255, 0.8)" // Optional: semi-transparent overlay
                  >
                    <Spinner size="lg" />
                  </Center>
                )}
                {/* Other components */}
              </Stack>
            </Center>
            <Center>
              <Button
                backgroundColor={"transparent"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                  color: "#3454D1",
                }}
                onClick={() => {
                  if (inputRef.current) {
                    inputRef.current.click();
                  }
                }}
              >
                Upload Logo
              </Button>
            </Center>
            <input
              type="file"
              ref={inputRef}
              onChange={(e) => {
                if (e.target.files) {
                  const file = e.target.files[0];
                  const formData = new FormData();
                  formData.append("docType", "BUSINESS_LOGO");
                  formData.append("file", file);
                  formData.append("businessId", form.getValues("id")!);

                  setLogoLoading(true);
                  UploadAttachments.UploadAttachment(
                    formData,
                    (success) => {
                      form.setValue("logo", `${success.url}`);
                      setUploadLogo(Date.now());
                      setLogoLoading(false);
                    },
                    (err) => {
                      toast({
                        title: "Error uploading photo",
                        status: "error",
                        description: err,
                      });
                      setLogoLoading(false);
                    }
                  );
                }
                e.target.value = "";
              }}
              style={{ display: "none" }}
            />
          </Stack>
          <FormControl paddingLeft="55px">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Business Name is required",
                },
              }}
              control={form.control}
              name="name"
              render={({ field, fieldState }) => (
                <CustomInput
                  ref={field.ref}
                  validationMessage={fieldState.error?.message}
                  withValidation
                  input={{
                    ...field,
                    isInvalid: fieldState?.error !== undefined,

                    padding: "12px 15px",
                    _light: {
                      color: "customColor.black7",
                    },
                    fontSize: ".845rem",
                    variant: "outline",
                    type: "text",

                    maxW: "400px",

                    borderTopRadius: "3px",
                    maxLength: 50,
                  }}
                  placeholder=""
                  label="Business name"
                  labelProps={{
                    fontSize: ".845rem",
                    fontWeight: "600",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                />
              )}
            />
            <br />
            <Stack maxW={"400px"} gap={0}>
              <FormLabel
                fontSize=".845rem"
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Industry
              </FormLabel>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Industry is required",
                  },
                }}
                // control={form.control}
                control={form.control}
                name="industry"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder=""
                    // marginBottom="30px"
                    // width={"48em"}
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Select industry "
                    withValidation
                    options={industriesOptions}
                    value={industriesOptions.find(
                      (industriesOptions) =>
                        industriesOptions.value === field.value
                    )}
                    onChange={(newValue: any) =>
                      field.onChange(newValue?.value)
                    }
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                )}
              />
            </Stack>
            {/* <Controller
              name="industry"
              // control={form.control}
              control={form.control}
              rules={{
                required: { value: true, message: "Industry is required" },
                maxLength: 50,
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                       ref={field.ref}
                  validationMessage={error?.message}
                  withValidation
                  left={
                    <InputLeftElement pointerEvents="none">
                      <SearchIcon color="teal" />
                    </InputLeftElement>
                  }
                  input={{
                    ...field,
                    isInvalid: error !== undefined,
                    w: "400px",
                    fontSize: "15px",
                    variant: "outline",
                    type: "text",
                    border: "1px solid",
                    borderColor: "gray.200",
                    borderTopRadius: "3px",
                    alignContent: "center",
                  }}
                  placeholder=" eg: construction, retail, services"
                />
              )}
            /> */}
            <FormHelperText
              fontSize=".6875rem"
              _light={{
                color: "customColor.black7",
              }}
            >
              If you cant find your industry,{" "}
              <Link color="primary.950" href="#">
                select it from this list
              </Link>
            </FormHelperText>
            <br />

            <FormLabel
              fontSize=".845rem"
              fontWeight="600"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Country
            </FormLabel>
            <Box
              textAlign="left"
              maxW="400px"
              marginTop="10px"
              borderTopRadius="3px"
              placeholder="Country"
            >
              {/* <Countryselector onChange={handleCountryChange} /> */}
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Country is required",
                  },
                }}
                // control={form.control}
                control={form.control}
                name="country"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder="Select Country"
                    // marginBottom="30px"
                    // width={"48em"}
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Select Country"
                    withValidation
                    options={countryData}
                    value={countryData.find((op) => op.name === field.value)}
                    onChange={(newValue: any) => field.onChange(newValue?.name)}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.name}
                  />
                )}
              />
            </Box>
          </FormControl>
          <br />
          <FormControl paddingLeft="55px">
            <VStack align="left">
              <HStack maxW="400px">
                <Text
                  fontSize=".845rem"
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Timezone:
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Timezone is required",
                    },
                  }}
                  // control={form.control}
                  control={form.control}
                  name="timezone"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder=""
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Timezone"
                      withValidation
                      options={timeOptions}
                      value={timeOptions.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(newValue: any) =>
                        field.onChange(newValue?.value)
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </HStack>
              <HStack maxW="400px">
                <Text
                  fontSize=".845rem"
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Currency:
                </Text>

                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Currency is required",
                    },
                  }}
                  // control={form.control}
                  control={form.control}
                  name="currency"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder=""
                      // marginBottom="30px"
                      // width={"48em"}
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Currency "
                      withValidation
                      options={currencyOptions}
                      value={currencyOptions.find(
                        (currencyOptions) =>
                          currencyOptions.value === field.value
                      )}
                      onChange={(newValue: any) =>
                        field.onChange(newValue?.value)
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </HStack>
            </VStack>
          </FormControl>
          <br />
          <FormControl paddingLeft="55px" alignItems={"baseline"}>
            <FormLabel
              fontSize=".845rem"
              fontWeight="600"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Last day of your financial year
            </FormLabel>
            <HStack alignItems={"baseline"} maxW="400px">
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Date is required",
                  },
                }}
                // control={form.control}
                control={form.control}
                name="last_date_financial_year"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder=""
                    // marginBottom="30px"
                    // width={"48em"}
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Select Date "
                    withValidation
                    options={dayOptions}
                    value={dayOptions.find(
                      (dayOptions) => dayOptions.value === field.value
                    )}
                    onChange={(newValue: any) =>
                      field.onChange(newValue?.value)
                    }
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                )}
              />

              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Month is required",
                  },
                }}
                // control={form.control}
                control={form.control}
                name="last_month_financial_year"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder=""
                    // marginBottom="30px"
                    // width={"48em"}
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Select Month "
                    withValidation
                    options={monthOptions}
                    value={monthOptions.find(
                      (monthOptions) => monthOptions.value === field.value
                    )}
                    onChange={(newValue: any) =>
                      field.onChange(newValue?.value)
                    }
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                )}
              />
            </HStack>
          </FormControl>

          <br />
          <FormControl paddingLeft="55px">
            <FormLabel
              fontSize=".845rem"
              fontWeight="600"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Do you have employees?
            </FormLabel>
            <Controller
              name="gst_registered"
              // control={form.control}

              rules={{
                required: {
                  value: true,
                  message: "Please choose an option",
                },
              }}
              control={form.control}
              render={({ field, fieldState }) => (
                <>
                  <RadioGroup>
                    <VStack
                      align="left"
                      maxW="400px"
                      border="1px solid #a6a9b0"
                    >
                      <Radio
                        {...field}
                        // isInvalid={fieldState.invalid}
                        paddingTop="5px"
                        paddingLeft="10px"
                        value="Yes"
                        colorScheme="green"
                      >
                        Yes
                      </Radio>
                      <Radio
                        {...field}
                        // isInvalid={fieldState.invalid}
                        paddingBottom="5px"
                        paddingLeft="10px"
                        value="No"
                        colorScheme="red"
                      >
                        No, Its just me
                      </Radio>
                    </VStack>
                  </RadioGroup>
                </>
              )}
            />
          </FormControl>
          <br />
          <FormControl paddingLeft="55px">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Activation code is required",
                },
              }}
              control={form.control}
              name="activationCode"
              render={({ field, fieldState }) => (
                <CustomInput
                  ref={field.ref}
                  validationMessage={fieldState.error?.message}
                  withValidation
                  input={{
                    ...field,
                    isInvalid: fieldState?.error !== undefined,

                    padding: "12px 15px",
                    _light: {
                      color: "customColor.black7",
                    },
                    fontSize: ".845rem",
                    variant: "outline",
                    type: "password",

                    maxW: "400px",

                    borderTopRadius: "3px",
                    maxLength: 50,
                  }}
                  placeholder=""
                  label="Activation Code"
                  labelProps={{
                    fontSize: ".845rem",
                    fontWeight: "600",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                />
              )}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          {form.getValues("id") && (
            <ButtonGroup
              minH={"40px"}
              spacing="1px"
              colorScheme={"red"}
              flex={1}
            >
              <Button
                variant="solid"
                fontSize={"13px"}
                h={"40px"}
                padding={"10px 15px"}
                borderRadius={"3px"}
                border={"1px solid #FDEDED"}
                bg={"#FDEDED"}
                color={"#EA4D4D"}
                minW={"75px"}
                _hover={{
                  border: "1px solid #EA4D4D",
                  bg: "#EA4D4D",
                  color: "#fff",
                }}
                isLoading={isLoading}
                onClick={(e) => {
                  deleteModal.onOpen();
                }}
              >
                {isLoading ? "Deleting" : "Delete"}
              </Button>
            </ButtonGroup>
          )}

          <Flex>
            <Button
              fontSize={"13px"}
              h={"40px"}
              bgColor="#3454D1"
              borderRadius={"3px"}
              marginRight={"8px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              display={
                watchActivationcode === "$HRIS360@1704$!!" ? "flex" : "none"
              }
              _hover={{
                bgColor: "#3454D1",
              }}
              isLoading={isLoading}
              onClick={(e) => {
                form.handleSubmit((data) => {
                  setIsLoading(true);
                  if (form.getValues("id")) {
                    Business.UpdateBusiness(
                      form.getValues("id")!,
                      data,
                      (success) => {
                        setLastUpdated(Date.now());
                        onClose();
                        toast({
                          title: "Business updated",
                          status: "success",
                        });
                        setIsLoading(false);
                      },
                      (error) => {
                        toast({
                          title: "Update business Failed",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  } else {
                    Business.BusinessAdd(
                      data,
                      (success) => {
                        setIsLoading(false);
                        setLastUpdated(Date.now());
                        onClose();
                        currentBusinessId
                          ? toast({
                              title: "Business created",
                              status: "success",
                            })
                          : Business.SwitchBusiness(
                              success?.id,
                              "ADMIN",
                              (data) => {
                                const decodedToken = jwtDecode<any>(data.token);
                                const expirytime = decodedToken.exp;
                                const timeDiff = moment.duration(
                                  expirytime - moment().unix(),
                                  "seconds"
                                );
                                setCurrentBusinessId(success?.id);
                                if (
                                  signIn({
                                    token: data.token,
                                    expiresIn: timeDiff.asMinutes(),
                                    tokenType: "Bearer",
                                    authState: user()!,
                                  })
                                ) {
                                  toast({
                                    title: "Business Switched",
                                    status: "success",
                                  });
                                  setIsLoading(false);

                                  navigate("/app/payroll/Dashboard");
                                  setIsLoading(false);
                                }
                              },
                              (err) => {
                                toast({
                                  title: err,
                                  status: "error",
                                });
                                setIsLoading(false);
                              }
                            );
                      },
                      (error) => {
                        toast({
                          title: "Create business Failed",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  }
                })(e);
              }}
            >
              {isLoading ? "Saving" : "Save"}
            </Button>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              h={"40px"}
              color={"#ea4d4d"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
      <Delete
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
        businessName={businessName}
        id={deleteId}
        setDeleteBusiness={setDeleteBusiness}
        setLastUpdated={setLastUpdated}
      />
    </Modal>
  );
}
