import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { AddInvoices } from "../../../Api/Invoices";

interface Props {
  id: any;
}
export default function InvoicesHistory({ id }: Props) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [historyData, setHistoryData] = useState<Array<AddInvoices.HistoryLog>>(
    []
  );
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  useEffect(() => {
    if (id) {
      AddInvoices.FetchHistoryLogs(
        id,
        (data) => {
          setHistoryData(data);

          setIsLoading(false);
        },
        (err) => {
          toast({
            title: err,
            status: "error",
          });
          setIsLoading(false);
        }
      );
    }
  }, []);
  return (
    <Accordion allowMultiple>
      <AccordionItem
        marginTop={"10px"}
        _light={{
          bgColor: "customColor.white",
        }}
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        borderColor="#f6f6f6"
        borderWidth="1px"
        borderStyle={"solid"}
        borderRadius="10px"
      >
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton
                h={"56px"}
                padding={0}
                paddingBottom={"10px"}
                _light={{
                  bgColor: "customColor.white",
                }}
                _dark={{
                  bgColor: "customColor.dark.100",
                }}
                _hover={{
                  _light: {
                    bgColor: "customColor.white",
                  },
                }}
              >
                {isExpanded ? (
                  <Button
                    variant={"ghost"}
                    color={"black"}
                    borderRadius={"full"}
                    _hover={{
                      backgroundColor: "#f5f6f7",
                      color: "#000a1e",
                    }}
                  >
                    <RiArrowUpSLine fontSize={"24px"} />
                  </Button>
                ) : (
                  <Button
                    variant={"ghost"}
                    color={"black"}
                    borderRadius={"full"}
                    _hover={{
                      backgroundColor: "#f5f6f7",
                      color: "#000a1e",
                    }}
                  >
                    <RiArrowDownSLine fontSize="24px" />
                  </Button>
                )}
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  padding={"12px 16px 12px 0px"}
                >
                  <Text
                    fontSize={"15px"}
                    _light={{ color: "customColor.customBlack" }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    fontWeight={"700"}
                    lineHeight={"1.5rem"}
                  >
                    History
                  </Text>
                </Box>
              </AccordionButton>
            </h2>
            <AccordionPanel padding={0}>
              <Skeleton isLoaded={!isLoading} height="100%">
                <Table variant="simple" w="100%">
                  <Thead borderTop={"1px solid grey"}>
                    <Tr>
                      {["Date", "User", "Item", "Detail"].map((item, i) => {
                        return <Th key={i}>{item}</Th>;
                      })}
                    </Tr>
                  </Thead>
                  <Tbody padding={0}>
                    {historyData.map((item, i) => {
                      return (
                        <Tr
                          key={i}
                          _hover={{
                            bgColor: "#f2f3f8",
                            cursor: "pointer",
                          }}
                        >
                          <Td
                            padding="15px 15px 15px 30px"
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            fontWeight={500}
                            whiteSpace="nowrap"
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            onClick={(e) => e.stopPropagation()}
                          >
                            {item.date
                              ? moment(item.date).format(
                                  "DD MMMM YYYY (hh:mm A)"
                                )
                              : "N.A"}
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            {item.userId === decodedToken.userId
                              ? `${decodedToken.firstName} ${decodedToken.lastName}`
                              : "N.A"}
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            {item.action ?? "N.A"}
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            {item.detail ?? "N.A"}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Skeleton>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
}
