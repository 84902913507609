import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { BiCurrentLocation } from "react-icons/bi";
import { AddressInput } from "../../../../Common/AddressInput";
import { useAuditQuestionContainer } from "./AuditQuestionContainer";
interface MapModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function MapModal({ isOpen, onClose }: MapModalProps) {
  const form = useFormContext();
  const { getItemField } = useAuditQuestionContainer();
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent minW={{ xs: "95%", md: "640px" }} padding="24px">
          <ModalBody w="100%" padding="0px">
            <VStack w="100%" h="100%" align="start" spacing={"16px"}>
              {/* <Box>
                <Text
                  fontSize="21px"
                  fontWeight={500}
                  color="textcolors.primary">
                  Select location
                </Text>
              </Box> */}
              <Stack
                w="100%"
                spacing={0}
                // justifyContent="space-between"
                direction={{ xs: "column", md: "row" }}
              >
                <Box>
                  {/* <Input
                    w={{xs: '100%', md: '350px'}}
                    border="1px solid"
                    borderColor="borders.accent"
                    fontSize="16px"
                    fontWeight={400}
                    color="textcolors.primary"
                    padding="8px 16px"
                    _focusVisible={{
                      boxShadow: 'none',
                      border: '1px sloid',
                      borderColor: 'primary.500',
                    }}></Input> */}
                  <Controller
                    name={getItemField("question.questionValue")}
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <AddressInput
                        onPlaceSelected={(place) => {
                          field.onChange(place.formatted_address);
                        }}
                        input={{
                          ...field,
                          variant: "outline",
                          isInvalid:
                            (fieldState.error?.message?.length ?? 0) > 0,
                          type: "text",
                        }}
                        label="Select Location"
                        withValidation
                        validationMessage={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Button
                    w={{ xs: "100%", md: "auto" }}
                    h="41.6px"
                    padding="12px 20px"
                    margin={{ xs: "16px 0px", md: "0px 16px" }}
                    border="none"
                    borderRadius="8px"
                    fontWeight={500}
                    fontSize="14px"
                  >
                    Search
                  </Button>
                </Box>
                <Box>
                  <Button
                    w={{ xs: "100%", md: "auto" }}
                    h="41.6px"
                    variant="outline"
                    padding="12px 20px"
                    iconSpacing={"0px"}
                    leftIcon={<BiCurrentLocation color="#1da5d4" size={"17"} />}
                    border="1px solid"
                    borderColor="primary.500"
                    borderRadius="8px"
                    fontWeight={500}
                    fontSize="14px"
                  >
                    Locate me
                  </Button>
                </Box>
              </Stack>
              <Box w="100%" h="320px" backgroundColor="primary.500"></Box>
            </VStack>
          </ModalBody>
          <ModalFooter padding={"0px"} marginTop="32px">
            <ButtonGroup justifyContent="center" size="sm" spacing={4}>
              <Button
                h="41px"
                variant="outline"
                borderRadius="8px"
                padding="12px 20px"
                fontSize="14px"
                fontWeight="500"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                h="41px"
                variant="solid"
                borderRadius="8px"
                padding="12px 20px"
                fontSize="14px"
                fontWeight="500"
              >
                Save and apply
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
