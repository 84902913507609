import { axiosInstance } from "./axiosInstance-hris";
declare module PayTemp {
  type AddNewPayTemp = {
    id: string;
    earnings: Array<EarningRate>;
    deductions: Array<DeductionRate>;
    reimbursments: Array<ReimbursementRate>;
    retirements: Array<RetirementRate>;
    grossSalary: number;
    deletedIds?: Array<string>;
    incomeType: string;
    isSocialSecurityRequired: boolean;
  };
  type EarningRate = {
    id?: string;
    payTemplateId: string;
    payItemEarningId: string;
    calculationType?: string;
    category: string;
    hours?: number | null;
    annualSalary?: number | null;
    earningFixedAmount?: number;
    rate?: number;
    rateType: string;
  };
  type DeductionRate = {
    id?: string;
    payTemplateId?: string;
    payItemDeductionId: string;
    calculationType?: string;
    category: string;
    amount?: number;
    percentage?: number;
    minimumWage?: number;
    rateType?: string;
  };
  type RetirementRate = {
    id?: string;
    payTemplateId?: string;
    payItemRetirementId: string;
    amount?: number;
    description?: string;
    rateType?: string;
  };
  type ReimbursementRate = {
    id?: string;
    payTemplateId?: string;
    payItemReimbursementId: string;
    amount?: number;
    description?: string;
    rateType?: string;
  };
}
module PayTemp {
  export enum PayTemplateCalcTypeEnum {
    "ANNUAL_SALARY" = "ANNUAL_SALARY",
    "USE_EARNINGS_RATE_MULTIPLY" = "USE_EARNINGS_RATE_MULTIPLY",
    "RATE" = "RATE",
    "FIXED_AMOUNT" = "FIXED_AMOUNT",
    "PRE-TAX" = "PRE-TAX",
    "POST-TAX" = "POST-TAX",
    "REIMBURSEMENT" = "REIMBURSEMENT",
    "EMPLOYEE_EOBI" = "EMPLOYEE_EOBI",
    "EMPLOYEE_PROVIDENT_FUND" = "EMPLOYEE_PROVIDENT_FUND",
    "EMPLOYER_EOBI" = "EMPLOYER_EOBI",
    "EMPLOYER_PROVIDENT_FUND" = "EMPLOYER_PROVIDENT_FUND",
    "SOCIAL_SECURITY" = "SOCIAL_SECURITY",
    "FIXED_AMOUNT_EACH_PERIOD" = "FIXED_AMOUNT_EACH_PERIOD",
    "MANUALLY_RECOREDED_RATE" = "MANUALLY_RECOREDED_RATE",
    "NO_CALCULATION_REQUIRED" = "NO_CALCULATION_REQUIRED",
    "EARNING_TAX" = "EARNING_TAX",
    "PROVIDENT_FUND" = "PROVIDENT_FUND",
    "GRATUITY" = "GRATUITY",
    "MANUAL_EARNING" = "MANUAL_EARNING",
  }
  export enum PayTemplateRateTypeEnum {
    "FIXED_AMOUNT" = "FIXED_AMOUNT",
    "MULTIPLE_OF_ORDINARY_EARNINGS" = "MULTIPLE_OF_ORDINARY_EARNINGS",
    "RATE_PER_UNIT" = "RATE_PER_UNIT",
    "GROSS_SALARY" = "GROSS_SALARY",
  }
  export enum PayTemplateItemTypeEnum {
    "EARNING" = "EARNING",
    "DEDUCTION" = "DEDUCTION",
    "REIMBURSEMENT" = "REIMBURSEMENT",
    "RETIREMENT" = "RETIREMENT",
    "TAX" = "TAX",
    "LEAVE" = "LEAVE",
  }
  const API_URL = "/pay-template";
  export function PayTempAdded(
    empId: string,
    data: AddNewPayTemp,
    success: (data: AddNewPayTemp) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/${empId}`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function GetPayTemp(
    empId: string,

    success: (data: AddNewPayTemp) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${empId}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { PayTemp };
