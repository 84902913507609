import {
  Button,
  ButtonGroup,
  Center,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FiSettings } from "react-icons/fi";
import { useBusinessContext } from "../../../../../../Hooks/BusinessContext";
import { TemplateMeta } from "../../../../../modules/Audit";
import { PeriodicalEvents } from "../../../../../modules/Audit/PeriodicalEvents";
import { PeriodicalSchedule } from "../../../../../modules/Audit/PeriodicalSchedule";
import PeriodicalScheduleModal from "../../Modals/PeriodicalScheduleModal";

interface ManagePeriodicalScheduleSmProps {
  data: PeriodicalSchedule.GetPeriodicalSchedule[];
  isLoading: boolean;
  templatesList: TemplateMeta.TemplateMeta[];
  setLastUpdate: (timestamp: number) => void;
  allEvents: PeriodicalEvents.GetEvents[];
}

export default function ManagePeriodicalScheduleSm({
  isLoading,
  data,
  templatesList,
  setLastUpdate,
  allEvents,
}: ManagePeriodicalScheduleSmProps) {
  const { allUsers, locationList } = useBusinessContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedId, setSelectedId] = useState<number | undefined>();
  const form = useFormContext();
  const handleFormReset = (index: number) => {
    const selectedScheduleInspection = data[index];
    form.reset(selectedScheduleInspection);
  };
  const deleteModal = useDisclosure();
  const toast = useToast({ position: "top" });
  // const sortitems: Array<{
  //   label: string;
  // }> = [
  //   {
  //     label: 'Inspection',
  //   },
  //   {
  //     label: 'Score',
  //   },
  //   {
  //     label: 'Conducted',
  //   },
  //   {
  //     label: 'Completed',
  //   },
  // ];
  return (
    <TableContainer>
      <Table>
        <Tbody>
          {isLoading ? (
            <Tr>
              <Td px="10px" py="8px" borderColor={"borders.accent"} colSpan={6}>
                <Center>
                  <Spinner size="lg" color="secondary.500" />
                </Center>
              </Td>
            </Tr>
          ) : data.length > 0 ? (
            data.map((schpr, i) => {
              return (
                <Fragment>
                  <Tr>
                    <Td
                      py="16px"
                      px="16px"
                      borderTop="1px"
                      borderColor="borders.accent"
                    >
                      <Stack>
                        <Stack spacing="3px">
                          <HStack>
                            <Text
                              flex={1}
                              fontSize="16px"
                              fontWeight={500}
                              lineHeight="24px"
                              textColor="textcolor.primary"
                            >
                              {
                                allEvents.find(
                                  (event) => event.id === schpr.activityId
                                )?.name
                              }
                            </Text>
                            <Menu placement="bottom-end">
                              <MenuButton
                                as={IconButton}
                                backgroundColor="transparent"
                                _hover={{ backgroundColor: "transparent" }}
                                _active={{ backgroundColor: "transparent" }}
                                aria-label="dropdown menu"
                                icon={<FiSettings color="#545f70" size={20} />}
                              />
                              <MenuList minW="100px">
                                <MenuItem
                                  fontSize="13px"
                                  onClick={() => {
                                    setSelectedId(schpr.id);
                                    form.reset(schpr);
                                    // handleFormReset(i);
                                    onOpen();
                                  }}
                                >
                                  Edit Schedule
                                </MenuItem>
                                <MenuItem
                                  fontSize="13px"
                                  onClick={() => {
                                    setSelectedId(schpr.id);
                                    deleteModal.onOpen();
                                  }}
                                >
                                  Delete
                                </MenuItem>
                                <Modal
                                  isOpen={deleteModal.isOpen}
                                  onClose={deleteModal.onClose}
                                >
                                  <ModalOverlay />
                                  <ModalContent mx="10px">
                                    <ModalHeader fontWeight="semibold" py="4px">
                                      Confirmation
                                    </ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                      Are you sure you want to Delete this?
                                    </ModalBody>

                                    <ModalFooter
                                      display="flex"
                                      justifyContent="flex-end"
                                      pt="16px"
                                      border="0px"
                                    >
                                      <ButtonGroup
                                        size="xs"
                                        variant="solid"
                                        borderRadius="5px"
                                      >
                                        <Button
                                          onClick={deleteModal.onClose}
                                          borderRadius="5px"
                                        >
                                          No
                                        </Button>
                                        <Button
                                          colorScheme="red"
                                          borderRadius="5px"
                                          onClick={() => {
                                            if (selectedId) {
                                              PeriodicalSchedule.DeletePeriodicalSchedule(
                                                selectedId,
                                                () => {
                                                  toast({
                                                    title: "Success",
                                                    description:
                                                      "Schedule deleted sucessfully",
                                                    status: "success",
                                                  });
                                                  setLastUpdate(Date.now());
                                                  deleteModal.onClose();
                                                },
                                                (error) => {
                                                  toast({
                                                    title: "Error",
                                                    description: error,
                                                    status: "error",
                                                  });
                                                }
                                              );
                                            }
                                          }}
                                        >
                                          Yes
                                        </Button>
                                      </ButtonGroup>
                                    </ModalFooter>
                                  </ModalContent>
                                </Modal>
                              </MenuList>
                            </Menu>
                          </HStack>

                          <HStack>
                            <Text
                              textColor="textcolor.primary"
                              fontWeight={400}
                              fontSize="14px"
                              lineHeight="20px"
                            >
                              Location
                            </Text>
                            <Text
                              textColor="gray.500"
                              fontWeight={400}
                              fontSize="14px"
                              lineHeight="20px"
                            >
                              {
                                locationList?.find(
                                  (loc) => loc.id === schpr.location
                                )?.name
                              }
                            </Text>
                          </HStack>
                          <HStack>
                            <Text
                              textColor="textcolor.primary"
                              fontWeight={400}
                              fontSize="14px"
                              lineHeight="20px"
                            >
                              Employees
                            </Text>
                            <Text
                              textColor="gray.500"
                              fontWeight={400}
                              fontSize="14px"
                              lineHeight="20px"
                            >
                              {schpr.employees
                                .map(
                                  (emp) =>
                                    allUsers?.find(
                                      (user) => user?.user?.id === emp
                                    )?.user?.firstName +
                                    " " +
                                    allUsers?.find(
                                      (user) => user?.user?.id === emp
                                    )?.user?.lastName
                                )
                                .join(", ")}
                            </Text>
                          </HStack>
                          <HStack>
                            <Text
                              textColor="textcolor.primary"
                              fontWeight={400}
                              fontSize="14px"
                              lineHeight="20px"
                            >
                              Frequency
                            </Text>
                            <Text
                              textColor="gray.500"
                              fontWeight={400}
                              fontSize="14px"
                              lineHeight="20px"
                            >
                              {schpr.frequency}
                            </Text>
                          </HStack>
                          <HStack>
                            <Text
                              textColor="textcolor.primary"
                              fontWeight={400}
                              fontSize="14px"
                              lineHeight="20px"
                            >
                              Date Range
                            </Text>
                            <Text
                              textColor="gray.500"
                              fontWeight={400}
                              fontSize="14px"
                              lineHeight="20px"
                            >
                              {`${schpr.dateRange.startDate} - ${schpr.dateRange.endDate}`}
                            </Text>
                          </HStack>
                        </Stack>
                      </Stack>
                    </Td>
                  </Tr>
                </Fragment>
              );
            })
          ) : (
            <Tr>
              <Td px="10px" py="8px" borderColor="borders.accent" colSpan={6}>
                <Center w="100%">
                  <Text>No Scheduled Inspections Found !</Text>
                </Center>
              </Td>
            </Tr>
          )}
          <PeriodicalScheduleModal
            isOpen={isOpen}
            onClose={onClose}
            selectedId={selectedId}
            templatesList={templatesList}
            setLastUpdate={setLastUpdate}
          />
        </Tbody>
      </Table>
    </TableContainer>
  );
}
