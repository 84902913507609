import { Input } from "@chakra-ui/react";
import moment from "moment";
import { Fragment } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Inspection, Template } from "../../../modules/Audit";
import { useInspectionContext } from "../hooks/InspectionContext";
import { useAuditQuestionContainer } from "./AuditQuestionContainer";

export default function DateTime() {
  const template = useFormContext();
  const { getItemField } = useAuditQuestionContainer();
  const properties: Template.QuestionProperties = useWatch({
    control: template.control,
    name: getItemField("question.properties"),
  });
  const format =
    properties.date && properties.time
      ? "YYYY-MM-DDTHH:mm"
      : properties?.date && !properties?.time
      ? "YYYY-MM-DD"
      : "HH:mm";
  const { setIsSaving } = useInspectionContext();
  return (
    <Fragment>
      <Controller
        name={getItemField("question.questionValue")}
        control={template.control}
        rules={{
          // onChange: e => {
          //   if (properties?.time && properties?.date) {
          //     const date = moment(e.target.value, format);
          //     const now = moment();
          //     date.set({
          //       year: now.year(),
          //       month: now.month(),
          //       date: now.date(),
          //     });
          //     template.setValue(
          //       getItemField('question.questionValue'),
          //       date.format('YYYY-MM-DDTHH:mm'),
          //     );
          //   }
          // },
          onBlur(event) {
            const answerId = template.getValues(
              getItemField("question.answerId")
            );

            const type = template.getValues(getItemField("question.type"));
            const inspectionMetaID = template.getValues("inspection.id");
            const notes = template.getValues(getItemField("question.notes"));
            setIsSaving(true);

            Inspection.CachingInspection(
              answerId,
              {
                question_value: event.target.value,
                notes: notes,
                inspection_meta_id: inspectionMetaID,
                type: type,
              },
              () => {
                setIsSaving(false);
              },
              () => {
                setIsSaving(false);
              }
            );
          },
        }}
        render={({ field }) => (
          <Input
            {...field}
            value={
              field.value ? moment(field.value, format).format(format) : ""
            }
            w={{ xs: "100%", md: "50%" }}
            placeholder={
              properties?.date && properties?.time
                ? "Enter Date and Time"
                : properties?.date && !properties?.time
                ? "Enter Date"
                : properties?.time && !properties?.date
                ? "Enter Time"
                : ""
            }
            // type={
            //   properties.date === 'true' && properties.time === 'true'
            //     ? 'datetime-local'
            //     : properties.date === 'true' && properties.time === 'false'
            //     ? 'date'
            //     : 'time'
            // }
            type={
              properties?.date && properties?.time
                ? "datetime-local"
                : properties?.date && !properties?.time
                ? "date"
                : properties?.time && !properties?.date
                ? "time"
                : ""
            }
            border="1px solid"
            borderColor="borders.accent"
            fontSize="16px"
            fontWeight={400}
            color="textcolors.primary"
            padding="8px 16px"
            _focusVisible={{
              boxShadow: "none",
              border: "1px sloid",
              borderColor: "primary.500",
            }}
          />
        )}
      />
    </Fragment>
  );
}
