import { axiosInstance } from "./axiosInstance-hris";
declare module ContactApi {
  type AddContact = {
    id?: string;
    name: string;
    isActive: boolean;
    accountNumber: string;
    primaryPerson: {
      firstName: string;
      lastName: string;
      email: string;
    };
    additionalPersons: Array<AdditionalPersonData>;

    businessInformation: {
      phoneCountry: string;
      phoneArea: string;
      phoneNumber: string;
      website: string;
      ABN: string;
      ACN: string;
      notes: string;
    };
    deliveryAddress: {
      attention: string;
      addresss: string;
      city?: string;
      state?: string;
      zipCode?: string;
      country?: string;
    };
    billingAddress: {
      attention: string;
      addresss: string;
      city?: string;
      state?: string;
      zipCode?: string;
      country?: string;
    };
    financialDetails: {
      bankAccountName: string;
      BSBCode: string;
      bankAccountNumber: string;
      reference: string;
      currency: string;
    };
    salesDefaults: {
      salesAccountId: string;
      invoiceDueDay: number;
      invoiceDueDateOption: string;
      saleGST: string;
      amountType: string;
      region: string;
      discount: number;
      creditLimitAmount: number;
      blockNewInvoiceWhenLimitReached: boolean;
      hrisNetworkKey: string;
    };
    purchaseDefaults: {
      purchaseAccountId: string;
      billDueDay: number;
      billDueDateOption: string;
      purchaseGST: string;
      amountType: string;
      region: string;
    };
  };
  type AdditionalPersonData = {
    firstName: string;
    lastName: string;
    email: string;
  };

  type FetchContact = {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    accountNumber: string;
    isActive: boolean;
    deliveryAddress: {
      id: string;
      createdAt: string;
      updatedAt: string;
      attention: string;
      addresss: string;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    };
    businessInformation: {
      id: string;
      createdAt: string;
      updatedAt: string;
      phoneCountry: string;
      phoneArea: string;
      phoneNumber: string;
      website: string;
      ABN: string;
      ACN: string;
      notes: string;
    };
  };
  type DataForArchive = {
    contactIds: string[];
    isActive: boolean;
  };
}
module ContactApi {
  const URL = "/contacts";
  export function PostContacts(
    data: AddContact,
    success: (data: AddContact) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${URL}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function PostArchiveContacts(
    data: DataForArchive,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${URL}/archive`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function FetchContactDetails(
    params: {
      name?: string;
      isActive?: boolean;
      limit?: number;
      page?: number;
    },
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}`, { params })
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function FetchContactDetailsById(
    id: string,
    success: (response: AddContact) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}/by-id/${id}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function UpdateContact(
    id: string,
    data: Partial<AddContact>,
    success: (data: AddContact) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .put(`${URL}/${id}`, data)
      .then((response) => {
        success(response.data.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function DeleteContacts(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${URL}/${id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
}

export { ContactApi };
