import { Button, Stack, Text, useDisclosure } from "@chakra-ui/react";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import { Inspection } from "../../../../../modules/Audit";
import { PeriodicalSchedule } from "../../../../../modules/Audit/PeriodicalSchedule";
import UpdateStatusModal from "./Modals/UpdateStatusModal";

interface TodayPeriodicEventTagProps {
  inspectionsList: Inspection.InpsectionMetaPayload[];
  ScheduleData: PeriodicalSchedule.GetPeriodicalScheduleToday;
  setLastUpdate: (timestamp: number) => void;
}

export default function TodayPeriodicEventTag({
  inspectionsList,
  ScheduleData,
  setLastUpdate,
}: TodayPeriodicEventTagProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const form = useFormContext<Inspection.PostPeriodicalActivities>();
  const statusOptions: Array<{ label: string; value: number }> = [
    {
      label: "PENDING",
      value: 0,
    },
    {
      label: "COMPLETED",
      value: 1,
    },
    {
      label: "OVERDUE",
      value: 2,
    },
  ];
  return (
    <Stack
      // onClick={onClicked}

      w="100%"
      border="1px"
      borderLeftWidth="4px"
      borderColor="primary.500"
      marginLeft={{ xs: "0px", md: "16px" }}
      spacing={0}
      direction="row"
      px="15px"
      py="12px"
    >
      <Stack spacing="2px" flex={1}>
        <Text fontSize="18px" fontWeight={500}>
          {ScheduleData?.periodicActivity?.name}
        </Text>
        <Text
          fontSize="12px"
          fontWeight={400}
          color="icons.primary"
          lineHeight="1.375"
          display="block"
        >
          {`Starts on ${moment(ScheduleData.dateRange.startDate).format(
            "DD/MM/YYYY"
          )} - Due on ${moment(ScheduleData.dateRange.endDate).format(
            "DD/MM/YYYY"
          )}`}
        </Text>
        <Text
          fontSize="12px"
          fontWeight={400}
          color="icons.primary"
          lineHeight="1.375"
          display="block"
        >
          Status: {ScheduleData.status}
        </Text>
      </Stack>
      <Button
        h="41px"
        px="20px"
        py="12px"
        fontWeight={500}
        fontSize="14px"
        borderRadius="8px"
        onClick={() => {
          form.reset({
            completedBy: ScheduleData.completedBy || "",
            inspectionId: ScheduleData.inspectionId || undefined,
            ids: [ScheduleData.id] || undefined,
            status:
              statusOptions.find((opt) => opt.label === ScheduleData.status)
                ?.value || 0,
          });
          onOpen();
        }}
      >
        Change Status
      </Button>
      <UpdateStatusModal
        isOpen={isOpen}
        onClose={onClose}
        inspectionsList={inspectionsList}
        statusOptions={statusOptions}
        setLastUpdate={setLastUpdate}
      />
    </Stack>
  );
}
