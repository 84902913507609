import {
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { Fragment } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { BsFilterLeft } from "react-icons/bs";
import { FiCheck } from "react-icons/fi";
import { MCQ } from "../../../../../../modules/Audit/MCQs";
import Content from "../../../Content";

interface ResponseTableSmProps {
  onRowClick: (globalOptions: any) => void;
  data: Array<MCQ.IMCQ>;
  setLastUpdate: (timestamp: number) => void;
}
export default function ResponseTableSm({
  onRowClick,
  data,
  setLastUpdate,
}: ResponseTableSmProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sortitems: Array<{
    label: string;
  }> = [
    {
      label: "Name",
    },
    {
      label: "Last modified",
    },
  ];
  return (
    <TableContainer>
      <Table>
        <Thead bg="backgrounds.primary">
          <Tr>
            <Th
              p="8px"
              borderTop="1px"
              borderBottom="1px"
              borderColor="borders.accent"
            >
              <HStack justifyContent="space-between">
                <HStack>
                  <Text
                    fontSize="16px"
                    fontWeight={400}
                    lineHeight="1rem"
                    color="textcolors.primary"
                    textTransform="none"
                  >
                    Last modified
                  </Text>
                  <AiOutlineArrowDown size={18} color="#3f495a" />
                </HStack>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="sort"
                    icon={<BsFilterLeft size={18} color="#3f495a" />}
                    backgroundColor="transparent"
                    _hover={{ backgroundColor: "transparent" }}
                    _active={{ backgroundColor: "transparent" }}
                  />
                  <MenuList minW="180px">
                    {sortitems.map((menu, i) => {
                      return (
                        <Fragment key={i}>
                          <MenuItem px="16px" py="14px">
                            <HStack>
                              <FiCheck size="18px" color="#1da5d4" />
                              <Text fontSize="14px" color="textcolors.primary">
                                {menu.label}
                              </Text>
                            </HStack>
                          </MenuItem>
                        </Fragment>
                      );
                    })}
                  </MenuList>
                </Menu>
              </HStack>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((globalOptions, i) => {
            return (
              <Tr
                onClick={() => {
                  onRowClick(globalOptions);
                }}
              >
                <Td px="8px" py="12px">
                  <Stack>
                    <Content fontSize="14px" color="textcolors.primary">
                      {globalOptions.name}
                    </Content>
                    <HStack spacing="3px">
                      <Content fontSize="14px" color="textcolors.primary">
                        Last modified:
                      </Content>
                      <Content fontSize="14px">
                        {moment(globalOptions.updatedAt).fromNow()}
                      </Content>
                    </HStack>
                    <HStack spacing="3px">
                      <Content fontSize="14px" color="textcolors.primary">
                        Responses:
                      </Content>
                      <Content fontSize="14px">
                        {globalOptions.options.length}
                      </Content>
                    </HStack>
                  </Stack>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
