import { Container, HStack, Stack, Text, useToast } from "@chakra-ui/react";
import { Country, State } from "country-state-city";
import { useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { BusinessTaxRate } from "../../Api/BusinessTaxRate";
import { ChartOfAccounts } from "../../Api/ChartOfAccounts";
import { ContactApi } from "../../Api/Contact";

interface PurchaseProp {
  purchaseDetailsRef: React.RefObject<HTMLDivElement>;

  taxRateData: BusinessTaxRate.FetchTaxRate[];
  chartOfAccounts: ChartOfAccounts.FetchChartOfAccounts[];
}
export function PurchaseDetails({
  purchaseDetailsRef,

  taxRateData,
  chartOfAccounts,
}: PurchaseProp) {
  const form = useFormContext<ContactApi.AddContact>();
  const [provinces, setProvinces] = useState<
    Array<{
      name: string;
    }>
  >([]);
  const watchInvoiceDue = useWatch({
    control: form.control,
    name: "purchaseDefaults.billDueDateOption",
  });
  let countryData = Country.getAllCountries();
  const { businessesList, currentBusinessId } = useBusinessContext();
  useEffect(() => {
    const provinceOptions = State.getStatesOfCountry(
      countryData.find(
        (country) =>
          country.name ===
          businessesList.find((business) => business.id === currentBusinessId)
            ?.country
      )?.isoCode
    );
    const province = provinceOptions.map((province: any) => {
      return { name: province?.name };
    });
    setProvinces(province);
  }, []);

  const toast = useToast();

  const billingOptions: Array<{ label: string; value: string }> = [
    {
      label: "of the following month",
      value: "of the following month",
    },
    {
      label: "days(s) after the invoice date",
      value: "days(s) after the invoice date",
    },
    {
      label: "days(s) after the invoice month",
      value: "days(s) after the invoice month",
    },
    {
      label: "off the current month",
      value: "off the current month",
    },
  ];

  const amountOptions: Array<{ label: string; value: string }> = [
    {
      label: "Use organisation settings",
      value: "Use organisation settings",
    },
    {
      label: "Tax inclusive",
      value: "Tax inclusive",
    },
    {
      label: "Tax exclusive",
      value: "Tax exclusive",
    },
    {
      label: "No Tax",
      value: "No Tax",
    },
  ];

  return (
    <Stack
      ref={purchaseDetailsRef}
      _dark={{ bgColor: "customColor.dark.50" }}
      _light={{ bgColor: "customColor.white" }}
      paddingBottom={"30px"}
      borderRight={"1px solid #e5e7eb"}
      borderLeft={"1px solid #e5e7eb"}
    >
      <Container maxW={"450px"}>
        <Text
          padding={"16px 0px"}
          margin={"14px 0px"}
          fontSize={"17px"}
          color={"customColor.black7"}
          fontWeight={"700"}
        >
          Purchase Defaults
        </Text>
        <Stack spacing={"25px"}>
          <Text fontSize={"15px"}>
            Defaults can be overridden on individual spend money, bills and
            purchase orders
          </Text>
          <Stack gap={0}>
            <Text
              fontSize={".845rem"}
              fontWeight={"600"}
              color={"customColor.gray.6b7885"}
              margin={"0px 12px 8px 0px"}
            >
              Purchase account
            </Text>
            <Controller
              name={"purchaseDefaults.purchaseAccountId"}
              control={form.control}
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  placeholder=""
                  withValidation
                  options={chartOfAccounts?.map((acc: any) => ({
                    value: acc.id,
                    label: `${acc.code}: ${acc.name}`, // Including code before name
                  }))}
                  value={chartOfAccounts
                    ?.map((acc: any) => ({
                      value: acc.id,
                      label: `${acc.code}: ${acc.name}`, // Including code before name
                    }))
                    .find((option: any) => option.value === field.value)}
                  onChange={(newValue: any) => field.onChange(newValue?.value)}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              )}
            />
          </Stack>
          <Stack gap={0}>
            <Text
              fontSize={".845rem"}
              fontWeight={"600"}
              color={"customColor.gray.6b7885"}
              margin={"0px 12px 8px 0px"}
            >
              Bill due date
            </Text>
            <HStack gap={"0.5"} alignItems={"baseline"}>
              <Stack flex={1}>
                <Controller
                  name="purchaseDefaults.billDueDay"
                  control={form.control}
                  rules={{
                    validate: (value) => {
                      if (value < 0) {
                        return "Day Of Month cannot be negative";
                      } else if (
                        value > 31 &&
                        (watchInvoiceDue === "FOLLOWING_MONTH" ||
                          watchInvoiceDue === "CURRENT_MONTH")
                      ) {
                        return "Day Of Month cannot be more than 31";
                      }
                      return true;
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        fontSize: ".845rem",
                        h: "38px",
                        variant: "outline",
                        type: "text",
                        onChange: (e) => {
                          field.onChange(Number(e.target.value));
                        },

                        borderTopRadius: "3px",
                        borderRightRadius: "0px",
                        alignContent: "center",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Stack>
              <Stack flex={6}>
                <Controller
                  control={form.control}
                  name="purchaseDefaults.billDueDateOption"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select"
                      // marginBottom="30px"
                      // width={"48em"}

                      withValidation
                      options={billingOptions}
                      value={billingOptions.find(
                        (billingOptions) => billingOptions.value === field.value
                      )}
                      onChange={(newValue: any) =>
                        field.onChange(newValue?.value)
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </Stack>
            </HStack>
          </Stack>
          <Stack gap={0}>
            <Text
              fontSize={".845rem"}
              fontWeight={"600"}
              color={"customColor.gray.6b7885"}
              margin={"0px 12px 8px 0px"}
            >
              Amount are
            </Text>
            <Controller
              name={"purchaseDefaults.amountType"}
              control={form.control}
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  key={field.value}
                  options={amountOptions}
                  withValidation
                  placeholder="Select..."
                  value={amountOptions.find((op) => op.value === field.value)}
                  onChange={(newValue: any) => {
                    field.onChange(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              )}
            />
          </Stack>
          <Stack gap={0}>
            <Text
              fontSize={".845rem"}
              fontWeight={"600"}
              color={"customColor.gray.6b7885"}
              margin={"0px 12px 8px 0px"}
            >
              Purchase GST
            </Text>
            <Controller
              name={`purchaseDefaults.purchaseGST`}
              control={form.control}
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  placeholder=""
                  withValidation
                  options={taxRateData}
                  value={
                    field.value
                      ? {
                          id: field.value,
                          name:
                            taxRateData.find(
                              (taxRateData) => taxRateData.id === field.value
                            )?.name ?? "",
                        }
                      : undefined
                  }
                  onChange={(val: any) => {
                    field.onChange(val?.id);
                  }}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.id}
                />
              )}
            />
          </Stack>

          <Stack gap={0}>
            <Text
              fontSize={".845rem"}
              fontWeight={"600"}
              color={"customColor.gray.6b7885"}
              margin={"0px 12px 8px 0px"}
            >
              Region
            </Text>
            <Controller
              control={form.control}
              name={`purchaseDefaults.region`}
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  key={field.value}
                  options={provinces}
                  ErrorMessage="Please Select Region"
                  placeholder="Select..."
                  value={provinces?.find((op: any) => op.name === field.value)}
                  onChange={(newValue: any) => {
                    field.onChange(newValue?.name);
                  }}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.name}
                />
              )}
            />
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
}
