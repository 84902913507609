import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { ShiftsTransfer } from "../../../../../TimeAttendance/API/ShiftsTransfer";

interface AcceptRequestModalProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (time: number) => void;
  shiftId?: string;
}
export default function AcceptRequestModal({
  isOpen,
  onClose,
  setLastUpdated,
  shiftId,
}: AcceptRequestModalProps) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Accept Request
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text
            fontSize={"0.8125rem"}
            fontWeight={"600"}
            _light={{
              color: "customColor.black7",
            }}
          >
            {" "}
            Are you sure you want to accept this Request?{" "}
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={onClose}
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"customColor.black7"}
            padding={"12px 16px"}
            marginRight={"5px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
          >
            Cancel
          </Button>
          <Button
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            bg={"primary.500"}
            padding={"12px 16px"}
            marginRight={"5px"}
            fontWeight={600}
            isLoading={isLoading}
            onClick={() => {
              setIsLoading(true);
              ShiftsTransfer.AcceptShiftTransferRequest(
                shiftId!,

                () => {
                  toast({
                    title: "Success",
                    description: "Request accepted successfully",
                    status: "success",
                  });
                  setLastUpdated(Date.now());
                  setIsLoading(false);
                  onClose();
                },
                (error) => {
                  toast({
                    title: "Error",
                    description: error,
                    status: "error",
                  });
                  setIsLoading(false);
                }
              );
            }}
          >
            Accept
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
