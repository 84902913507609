import { Input, Stack } from "@chakra-ui/react";
import { useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Inspection } from "../../../modules/Audit";
import { useInspectionContext } from "../hooks/InspectionContext";
import { useAuditQuestionContainer } from "./AuditQuestionContainer";

export default function DocumentNumber() {
  const template = useFormContext();
  const { question, getItemField } = useAuditQuestionContainer();
  const format: string = useWatch({
    control: template.control,
    name: getItemField("question.properties.documentNumberFormat"),
  });

  useEffect(() => {
    template.setValue(getItemField("question.questionValue"), format);
  }, []);
  const { setIsSaving } = useInspectionContext();
  return (
    <Stack>
      <Controller
        name={getItemField("question.questionValue")}
        control={template.control}
        rules={{
          onBlur(event) {
            const answerId = template.getValues(
              getItemField("question.answerId")
            );
            const type = template.getValues(getItemField("question.type"));
            const inspectionMetaID = template.getValues("inspection.id");
            const notes = template.getValues(getItemField("question.notes"));
            setIsSaving(true);
            Inspection.CachingInspection(
              answerId,
              {
                question_value: event.target.value,
                notes: notes,
                inspection_meta_id: inspectionMetaID,
                type: type,
              },
              () => {
                setIsSaving(false);
              },
              () => {
                setIsSaving(false);
              }
            );
          },
        }}
        render={({ field }) => (
          <Input
            {...field}
            w={{ xs: "100%", md: "50%" }}
            border="1px solid"
            borderColor="borders.accent"
            fontSize="16px"
            fontWeight={400}
            color="textcolors.primary"
            padding="8px 16px"
            _focusVisible={{
              boxShadow: "none",
              border: "1px sloid",
              borderColor: "primary.500",
            }}
          />
        )}
      />
    </Stack>
  );
}
