import {
  Center,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { FaRegFilePdf } from "react-icons/fa";
import { Inspection } from "../../../modules/Audit";

export default function InspectionDocuments() {
  const form = useFormContext<Inspection.InpsectionPayload>();
  return (
    <Stack
      borderRadius="12px"
      p="24px"
      border="1px"
      borderColor="borders.accent"
      align="flex-start"
    >
      <HStack justifyContent="space-between" w="full">
        <Text fontSize="13px" fontWeight="500" color="#373857">
          Inspection Related Documents
        </Text>
      </HStack>
      <Wrap>
        {form.getValues("templateMedia")?.map((doc, i) => {
          return (
            <WrapItem key={i} cursor="pointer">
              <Center w="100px" h="80px">
                <Link href={doc.attachmentUrl} isExternal>
                  <Icon as={FaRegFilePdf} boxSize={5} />
                  <Text fontSize="11px">{doc.attachmentName}</Text>
                </Link>
              </Center>
            </WrapItem>
          );
        })}
      </Wrap>
    </Stack>
  );
}
