import axiosInstanceGateway from "../Payroll/Api/axiosInstance-gateway";
import { InviteUser } from "./InviteUser";

declare module AccessControl {
  type UserDetailMin = {
    accessLevel: string;
    user: {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
    };
  };
  type UserDetail = {
    id: string;
    accessLevel: string;
    allowedFeatures: {
      payroll: {
        payRuns: boolean;
        timesheets: boolean;
        payrollSettings: boolean;
      };
      reports: {
        employeeCheckIn: boolean;
        employeeDetails: boolean;
      };
      accounting: {
        taxRates: boolean;
        chartOfAccounts: boolean;
      };
      timeAndAttendance: {
        locations: boolean;
        schedules: boolean;
        notifications: boolean;
      };
    };
    user: {
      firstName: string;
      lastName: string;
      email: string;
      id: string;
    };
  };
}
module AccessControl {
  const API_URL = "/access-control";
  export function GetAllUser(
    success: (response: Array<UserDetail>) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}/users`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchActiveUsers(
    success: (response: Array<UserDetail>) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}/users`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function DeleteUserById(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .delete(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchUserById(
    id: string,
    success: (response: InviteUser.UserInviteDetail) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchAllUsersMin(
    success: (response: Array<UserDetailMin>) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}/allUsers`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function UpdateUserById(
    id: string,
    data: Partial<InviteUser.UserInviteDetail>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .patch(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function GetLoggedInUserPermissions(
    success: (data: UserDetail) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}/permissions`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function checkUserAccess(
    userId: string,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}/check/${userId}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function revokeAccess(
    userId: string,
    revoke: boolean,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}/revokeAccess/${userId}/${revoke}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
}
export { AccessControl };
