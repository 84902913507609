import { DeleteIcon } from "@chakra-ui/icons";
import {
  AbsoluteCenter,
  Box,
  Button,
  IconButton,
  Image,
  Spinner,
  Stack,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { RiPenNibFill } from "react-icons/ri";
import { UploadAttachments } from "../../../../Payroll/Api/UploadAttachments";
import { fileToBase64 } from "../../../modules/api";
import { Inspection } from "../../../modules/Audit";
import { compressImage } from "../../../modules/utils";
import { useInspectionContext } from "../hooks/InspectionContext";
import { useAuditQuestionContainer } from "./AuditQuestionContainer";

export default function Annotation() {
  const template = useFormContext();
  const { getItemField, required } = useAuditQuestionContainer();
  const inputRef = useRef<HTMLInputElement>(null);
  const { setIsSaving } = useInspectionContext();

  const toast = useToast({
    position: "top",
  });
  const [isImageUploading, setIsImageUploading] = useBoolean(false);
  return (
    <Stack>
      <Stack justifyContent="flex-end" direction={{ xs: "column", md: "row" }}>
        <Button
          h="41px"
          variant="solid"
          borderRadius="8px"
          padding="12px 20px"
          fontSize="14px"
          fontWeight="500"
          leftIcon={<RiPenNibFill size="17px" />}
          onClick={() => {
            inputRef.current?.click();
          }}
        >
          Annotate
        </Button>
      </Stack>
      <Controller
        name={getItemField("question.questionValue")}
        control={template.control}
        render={({ field }) => (
          <Fragment>
            <input
              hidden
              accept="image/*,video/*,.pdf"
              type="file"
              ref={inputRef}
              onChange={async (e) => {
                const file = e.target.files?.[0];
                const compressedImage = await compressImage(file);
                if (file) {
                  const base64 = await fileToBase64(compressedImage);
                  if (base64) {
                    setIsImageUploading.on();
                    const answerId = template.getValues(
                      getItemField("question.answerId")
                    );
                    const type = template.getValues(
                      getItemField("question.type")
                    );
                    const inspectionMetaID =
                      template.getValues("inspection.id");
                    setIsSaving(true);
                    const formData = new FormData();
                    formData.append("docType", "INSPECTION");
                    formData.append("base64", base64);
                    formData.append("fileName", file.name);
                    formData.append(
                      "inspectionId",
                      template.getValues("inspection.id")
                    );
                    UploadAttachments.UploadAttachment(
                      formData,
                      (path) => {
                        field.onChange(path.url);
                        Inspection.CachingInspection(
                          answerId,
                          {
                            question_value: path.url,
                            inspection_meta_id: inspectionMetaID,
                            type: type,
                          },
                          () => {
                            setIsSaving(false);
                            setIsImageUploading.off();
                          },
                          () => {
                            setIsSaving(false);
                            setIsImageUploading.off();
                          }
                        );
                        setIsImageUploading.off();
                      },
                      (err) => {
                        toast({
                          title: "Error",
                          description: err,
                        });
                        setIsImageUploading.off();
                      }
                    );
                  }
                }
              }}
            />
            {(isImageUploading || field.value?.length > 0) && (
              <Box
                mt="16px"
                background="blue"
                boxSize="64px"
                borderRadius="8px"
                position="relative"
                role="group"
              >
                <IconButton
                  icon={<DeleteIcon />}
                  aria-label="Delete"
                  position="absolute"
                  variant="ghost"
                  colorScheme="red"
                  top="4px"
                  right="4px"
                  display="none"
                  _hover={{ background: "tranparent" }}
                  _groupHover={{
                    display: "block",
                  }}
                  onClick={() => {
                    field.onChange("");
                    const answerId = template.getValues(
                      getItemField("question.answerId")
                    );
                    const type = template.getValues(
                      getItemField("question.type")
                    );
                    const inspectionMetaID =
                      template.getValues("inspection.id");
                    setIsSaving(true);
                    Inspection.CachingInspection(
                      answerId,
                      {
                        question_value: "",
                        inspection_meta_id: inspectionMetaID,
                        type: type,
                      },
                      () => {
                        setIsSaving(false);
                        setIsImageUploading.off();
                      },
                      () => {
                        setIsSaving(false);
                        setIsImageUploading.off();
                      }
                    );
                  }}
                />

                {field.value?.length > 0 && (
                  <Image
                    h="100%"
                    w="100%"
                    borderRadius="8px"
                    src={field.value}
                  />
                )}
                {isImageUploading && (
                  <AbsoluteCenter>
                    <Spinner />
                  </AbsoluteCenter>
                )}
              </Box>
            )}
          </Fragment>
        )}
      />
    </Stack>
  );
}
