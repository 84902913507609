import {
  Button,
  Collapse,
  Container,
  HStack,
  IconButton,
  Tag,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {useFormContext} from 'react-hook-form';
import {FiChevronDown, FiChevronRight} from 'react-icons/fi';
import {Report} from '../../../../../../modules/Audit';

export default function FlaggedItems() {
  const flaggedItemsValues = useFormContext<Report.IReport>();
  const FlaggedValues = flaggedItemsValues.getValues('flaggedItems');
  const {isOpen, onToggle} = useDisclosure();
  return (
    <Container
      p="16px"
      maxW="container.md"
      borderRadius="16px"
      backgroundColor="backgrounds.primary"
      lineHeight={1}>
      <HStack spacing={0}>
        <Button
          marginRight="8px"
          as={IconButton}
          justifyContent="start"
          p={0}
          aria-label={'dropdown'}
          backgroundColor="transparent"
          _hover={{backgroundColor: 'transparent'}}
          onClick={onToggle}
          icon={
            isOpen ? (
              <FiChevronDown color={'#828ea0'} size={24} />
            ) : (
              <FiChevronRight color={'#828ea0'} size={24} />
            )
          }
        />
        <HStack flex={1}>
          <Text fontSize="21px" fontWeight={500} color="#3f495a">
            Flagged Items
          </Text>
          {/* <IoInformationCircleOutline size={21} color="#3f495a" />
          <Tag
            px="8px"
            py="4px"
            size="md"
            border="1px solid"
            borderColor="icons.primary"
            borderRadius="16px"
            backgroundColor="backgrounds.header"
            color="icons.primary">
            Sample
          </Tag> */}
        </HStack>
        <Tag
          px="8px"
          py="4px"
          minW="35px"
          borderRadius="16px"
          backgroundColor="red"
          color="white"
          justifyContent="center">
          {FlaggedValues.length}
        </Tag>
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        {FlaggedValues.map((item, i) => {
          return (
            <VStack
              // opacity="0.5"
              mt="16px"
              backgroundColor="white"
              p="16px"
              borderRadius="16px"
              align="start">
              {item.page_title && (
                <Text lineHeight={1.2} fontSize="13px" color="rgb(84, 95, 112)">
                  {item.page_title}
                </Text>
              )}
              {item.question_title && (
                <Text
                  lineHeight={1.2}
                  fontSize="16px"
                  color="textcolors.primary">
                  {item.question_title}
                </Text>
              )}
              <Tag
                px="8px"
                py="4px"
                minW="35px"
                backgroundColor={item.question_color}
                borderRadius="16px"
                color="white"
                justifyContent="center">
                {item.question_value}
              </Tag>
            </VStack>
          );
        })}
      </Collapse>
    </Container>
  );
}
