import {
  Box,
  Button,
  HStack,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FiChevronRight } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { IoMdInformationCircle } from "react-icons/io";
import { IoArrowBackSharp } from "react-icons/io5";
import { MdFileCopy } from "react-icons/md";
import { useParams } from "react-router";
import { ReactSelect } from "../../../../../../Common/ReactSelect";
import { ReportLayout } from "../../../../../modules/Audit/ReportLayout";
import { useTemplateReport } from "../../report";
import SideBar from "../sidebar";

export default function ReportLayoutHistory() {
  const form = useFormContext<ReportLayout.ReportLayout>();
  console.log("form", form.getValues());
  const [selectedLayout, setSelectedLayout] = useState<number>();
  const [allLayouts, setAllLayouts] = useState<
    Array<ReportLayout.GetAllLayouts>
  >([]);
  const toast = useToast();
  const { id } = useParams();
  useEffect(() => {
    ReportLayout.FetchAllByTemplateId(
      { templateId: Number(id) },
      (data) => {
        setAllLayouts(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, [selectedLayout]);
  const {
    setDisplayedComponent,
    isDrawer,
    isNewTemplate,
    setIsNewTemplate,
    ReportDrawer,
  } = useTemplateReport();
  const watchedLayoutId = useWatch({
    control: form.control,
    name: "id",
  });
  // useEffect(() => {
  //   // if (id) {
  //   //   form.reset({
  //   //     ...form.getValues(),
  //   //   });
  //   // }
  //   if (watchedLayoutId) {
  //     form.setValue('isLoading', true);
  //     ReportLayout.FetchLayoutById(
  //       watchedLayoutId,
  //       data => {
  //         console.log('forData', form.getValues());
  //         console.log('Api Data', data);
  //         form.reset({
  //           ...form.getValues(),
  //           ...data,
  //         });

  //         form.setValue('isLoading', false);
  //       },
  //       error => {
  //         toast({
  //           title: 'Error',
  //           description: error,
  //           status: 'error',
  //         });
  //         form.setValue('isLoading', false);
  //       },
  //     );
  //   }
  // }, [watchedLayoutId]);
  return (
    <VStack
      align="start"
      w="100%"
      h="calc(100vh - 62px)"
      bg="white"
      spacing={0}
    >
      {isDrawer && (
        <Button
          mt="24px"
          px="16px"
          variant="ghost"
          onClick={() => {
            if (isDrawer) {
              ReportDrawer?.onClose?.();
            } else {
            }
          }}
          _hover={{ backgroundColor: "none" }}
          _active={{ backgroundColor: "none" }}
          leftIcon={<IoArrowBackSharp size={21} />}
        >
          Back
        </Button>
      )}
      <VStack align="start" w="100%" bg="white" spacing={0} px="24px">
        <Box w="100%">
          <Stack
            w="100%"
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            pt="16px"
            mb="16px"
            spacing={0}
          >
            <Text
              fontWeight={500}
              fontSize="21px"
              color="textcolors.primary"
              mr="8px"
            >
              Report Layout
            </Text>
            <Tooltip
              borderRadius="8px"
              p="8px"
              hasArrow
              label="Report layouts are a way to personalize your finished inspections. When reviewing a report, these layouts will be available.Learn more."
              placement="left"
            >
              <Box
                onClick={() => {
                  console.log("clicked", form.getValues());
                }}
              >
                <IoMdInformationCircle size={18} color="#3f495a" />
              </Box>
            </Tooltip>

            {/* <IconButton
            aria-label=""
            icon={<IoMdInformationCircle size={18} color="#3f495a" />}
            _hover={{backgroundColor: 'transparent'}}
            borderRadius="50%"
            backgroundColor="transparent"
          /> */}
          </Stack>
          <Button
            h="41px"
            w="100%"
            my="8px"
            borderRadius="8px"
            fontSize="14px"
            fontWeight={500}
            leftIcon={<GoPlus size={14} />}
            variant="solid"
            onClick={() => {
              form.reset({
                reportStructure: form.getValues("reportStructure"),
                name: "untitled layout",
                id: undefined,
                active: false,
                showReportStructure: true,
                layout: {
                  coverPage: "",
                  logo: "",
                  action: true,
                  checkboxes: true,
                  disclaimer: true,
                  questionDivider: true,
                  flaggedItems: true,
                  footer: {
                    addFooter: true,
                    additionalInfo: true,
                    image: "",
                    inspectionTitle: true,
                    notes: "",
                    showPicture: true,
                  },
                  header: {
                    addHeader: true,
                    image: "",
                    showPicture: true,
                  },
                  inspectionResults: true,
                  instructions: true,
                  mediaSummary: true,
                  scores: true,
                  tableOfContents: false,
                  unansweredQuestions: true,
                },
              });

              setDisplayedComponent(<SideBar />);
            }}
            _hover={{ backgroundColor: "#0981aa" }}
          >
            New layout
          </Button>
          <Text
            mt="12px"
            mb="8px"
            pl="16px"
            color="icons.primary"
            fontSize="13px"
            fontWeight={400}
            lineHeight="16px"
          >
            Default layout
          </Text>
          <ReactSelect
            placeholder="Select a layout"
            options={allLayouts.map((layout) => ({
              label: layout?.layout?.name,
              value: layout?.id,
            }))}
            onChange={(e: any) => {
              ReportLayout.ActiveReportLayout(
                e.value,
                () => {
                  toast({
                    title: "Success",
                    description: "Layout has been set as active",
                    status: "success",
                  });
                },
                () => {
                  toast({
                    title: "Error",
                    description: "Error setting layout as active",
                    status: "error",
                  });
                }
              );
              setSelectedLayout(e?.value);
            }}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
          />
          <Text
            color="icons.primary"
            fontSize="13px"
            fontWeight={400}
            // lineHeight="16px"
            pl="16px"
            mt="16px"
            mb="8px"
          >
            Your layouts
          </Text>
        </Box>
        <VStack w="100%" pb="16px" overflowY="scroll" spacing={"8px"}>
          {allLayouts?.map((layout, i) => (
            <Stack
              key={i}
              w="100%"
              direction="row"
              justifyContent={"space-between"}
              alignItems="center"
              pl="16px"
              pr="8px"
              py="12px"
              _hover={{
                backgroundColor: "primary.50",
                borderRadius: "12px",
              }}
            >
              <HStack>
                <MdFileCopy size={14} color="#3f495a" />
                <Text
                  fontSize="16px"
                  fontWeight={400}
                  color="textcolors.secondary"
                >
                  {layout?.layout?.name}
                </Text>
              </HStack>
              <HStack>
                <Box
                  // bg="#e0e4ff"
                  bg="primary.50"
                  borderRadius="16px"
                  px="8px"
                  py="2px"
                  border="1px solid"
                >
                  <Text fontSize="13px" color="textcolors.secondary">
                    {layout?.active ? "Active" : "Inactive"}
                  </Text>
                </Box>

                <IconButton
                  aria-label=""
                  icon={<FiChevronRight size={24} color="#3f495a" />}
                  _hover={{ backgroundColor: "#bfc6d4" }}
                  borderRadius="50%"
                  backgroundColor="transparent"
                  onClick={() => {
                    setDisplayedComponent(<SideBar />);
                    setIsNewTemplate(false);
                    form.setValue("id", layout?.layout?.id);
                    form.setValue("showReportStructure", true);
                    if (layout?.layout?.id) {
                      form.setValue("isLoading", true);
                      ReportLayout.FetchLayoutById(
                        layout?.layout?.id,
                        (data) => {
                          console.log("forData", form.getValues());
                          console.log("Api Data", data);
                          form.reset({
                            ...form.getValues(),
                            ...data,
                          });

                          form.setValue("isLoading", false);
                        },
                        (error) => {
                          toast({
                            title: "Error",
                            description: error,
                            status: "error",
                          });
                          form.setValue("isLoading", false);
                        }
                      );
                    }
                  }}
                />
              </HStack>
            </Stack>
          ))}
        </VStack>
      </VStack>
    </VStack>
  );
}

{
  /* <Menu matchWidth={true} autoSelect={false}>
          {({isOpen}) => (
            <>
              <MenuButton
                w="100%"
                h="43.6px"
                as={Button}
                px="16px"
                py="8px"
                background="transparent"
                backgroundColor="none"
                fontSize="16px"
                fontWeight={400}
                border="1px solid"
                borderColor="borders.accent"
                borderRadius="5px"
                rightIcon={
                  isOpen ? (
                    <FiChevronUp color="#545f70" size="16px" />
                  ) : (
                    <FiChevronDown color="#545f70" size="16px" />
                  )
                }
                textAlign="start"
                lineHeight="24px"
                textColor="backgrounds.secondary"
                _active={{background: 'transparent'}}
                _focus={{background: 'transparent'}}
                _focusVisible={{background: 'transparent'}}>
                Select...
              </MenuButton>
              <MenuList py="8px" boxShadow="md">
                <InputGroup px="8px" pb="8px" alignItems="center">
                  <InputLeftElement
                    pl="16px"
                    pr="8px"
                    pointerEvents="none"
                    children={<AiOutlineSearch color="#545f70" size="18px" />}
                  />
                  <Input
                    w="100%"
                    placeholder="Search"
                    border="1px solid"
                    borderColor="borders.accent"
                    fontSize="16px"
                    fontWeight={400}
                    color="textcolors.primary"
                    py="8px"
                    pl="32px"
                    _focusVisible={{
                      boxShadow: 'none',
                      border: '1px sloid',
                      borderColor: 'primary.500',
                    }}></Input>
                </InputGroup>
                <MenuItem px="16px" py="8px">
                  hello
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu> */
}
