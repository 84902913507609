import {Container, VStack} from '@chakra-ui/react';
import {DevTool} from '@hookform/devtools';
import {Fragment} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {Template} from '../../../../modules/Audit';
import TemplateBuilderHeader from '../../components/Header';
import Page from '../../components/Page';
import TemplateDocuments from '../../components/TemplateDocuments';
function TemplateBuilder() {
  const template = useFormContext<Template.Template>();

  const pageArr = useFieldArray({
    control: template.control,
    name: 'templateItems',
    keyName: 'uniqueId',
  });
  return (
    <Fragment>
      <DevTool control={template.control} />
      <Container
        maxW="container.lg"
        w="full"
        px={{
          md: '50px',
          xs: '10px',
        }}>
        <TemplateBuilderHeader form={template} key={'PageTitle'} />
        <VStack w="100%" align="start">
          {(pageArr.fields as unknown as Array<Template.TemplateItem>).map(
            (page, pageIndex) => (
              <Page
                key={page.id}
                pageIndex={pageIndex}
                onPageAdd={() => {
                  let item: Template.TemplateItem = {
                    title: 'New Page',
                    status: false,
                    children: [Template.Question_INIT],
                    orderIndex: pageArr.fields.length,
                    type: 'PAGE',
                    officeUse: false,
                  };
                  pageArr.append(item);
                }}
                onPageDelete={index => {
                  pageArr.remove(index);
                  if (page.id) {
                    const deletedItems =
                      template.getValues('deletedItems') ?? [];
                    template.setValue('deletedItems', [
                      ...deletedItems,
                      {id: page.id, type: 'PAGE'},
                    ]);
                  }
                }}
              />
            ),
          )}
        </VStack>
        {template.getValues('template.inductionTemplate') && (
          <TemplateDocuments form={template} />
        )}
      </Container>
    </Fragment>
  );
}

export default TemplateBuilder;
