import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Input,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, useForm, useFormContext, useWatch } from "react-hook-form";
import { Template } from "../../../../modules/Audit";
import { getFieldKey } from "../../../../modules/form-apis";
import useDrawer from "../../hooks/drawersContext";
interface SliderDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function SliderDrawer({ isOpen, onClose }: SliderDrawerProps) {
  const [sliderValue, setSliderValue] = useState<number>();

  const labelStyles = {
    mt: "24px",
    fontSize: "sm",
  };

  const template = useFormContext();
  const { questionItemDetails } = useDrawer();

  const { control, handleSubmit } = useForm<Template.QuestionProperties>({
    defaultValues: template.getValues(
      getFieldKey(
        questionItemDetails ?? { pageIndex: 0 },
        "question.properties"
      )
    ),
  });

  const watchedMinValue = useWatch({
    control,
    name: "min",
  });
  const watchedMaxValue = useWatch({
    control,
    name: "max",
  });
  const watchedIncrementValue = useWatch({
    control,
    name: "increment",
  });

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      size={{ xs: "full", md: "md" }}
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent
        backgroundColor={"backgrounds.header"}
        overflowY="auto"
        minH="100vh"
      >
        <DrawerHeader
          padding={"24px"}
          borderBottom={"1px solid"}
          borderColor={"borders.accent"}
        >
          <VStack>
            <Text
              w={"100%"}
              fontSize={"21px"}
              fontWeight={500}
              color={"textcolors.primary"}
              textAlign={"start"}
            >
              Slider
            </Text>
            <Text
              w={"100%"}
              fontSize={"14px"}
              fontWeight={400}
              color={"icons.primary"}
              textAlign={"start"}
            >
              You can define the range of response with the slider.
            </Text>
          </VStack>
        </DrawerHeader>

        <DrawerBody
          // minH={{xs: '168px', md: '126px'}}
          maxH="385px"
          backgroundColor={"white"}
          padding="24px"
        >
          <Stack spacing={0}>
            <Box>
              <Text
                fontSize="14px"
                fontWeight={400}
                marginTop="8px"
                color="icons.primary"
              >
                Preview
              </Text>
            </Box>
            <Box px="24px">
              {/* <Slider defaultValue={60} min={1} max={10} step={1}>
                  <SliderTrack bg="red.100">
                    <Box position="relative" right={10} />
                    <SliderFilledTrack bg="tomato" />
                  </SliderTrack>
                  <SliderThumb boxSize={6} />
                </Slider> */}

              <Slider
                min={Number(watchedMinValue)}
                max={Number(watchedMaxValue)}
                step={Number(watchedIncrementValue)}
                maxW="full"
                marginTop="36px"
                focusThumbOnChange={false}
                // // marginRight="24px"
                // marginLeft="24px"
                marginBottom="44px"
                onChange={(value) => setSliderValue(value)}
              >
                <SliderMark
                  fontWeight={sliderValue == watchedMinValue ? 500 : 400}
                  value={Number(watchedMinValue) ?? 0}
                  {...labelStyles}
                >
                  {watchedMinValue}
                </SliderMark>
                {sliderValue != watchedMinValue &&
                  sliderValue != watchedMaxValue && (
                    <SliderMark
                      value={sliderValue ?? Number(watchedMinValue)}
                      fontSize="sm"
                      fontWeight={500}
                      mt="24px"
                    >
                      {sliderValue}
                    </SliderMark>
                  )}
                <SliderMark
                  fontWeight={sliderValue == watchedMaxValue ? 500 : 400}
                  value={Number(watchedMaxValue) ?? 0}
                  {...labelStyles}
                >
                  {watchedMaxValue}
                </SliderMark>
                <SliderTrack bg="primary.50">
                  <SliderFilledTrack bg="primary.500" />
                </SliderTrack>
                <SliderThumb boxSize={6} />
              </Slider>
            </Box>
            <Box>
              <Divider
                border={"0.4px solid"}
                borderColor="borders.accent"
                marginBottom="32px"
              />
            </Box>

            <Stack spacing={0} direction="row" align="center">
              <VStack spacing={"4px"} maxW="217.6">
                <Text
                  w={"100%"}
                  fontSize="14px"
                  fontWeight={400}
                  color="textcolors.primary"
                >
                  Min
                </Text>
                <Controller
                  control={control}
                  name="min"
                  render={({ field }) => (
                    <Input
                      defaultValue={1}
                      fontSize={"14px"}
                      fontWeight={400}
                      padding={"8px 16px"}
                      border="1px"
                      borderStyle={"solid"}
                      borderColor={"#abb5c4"}
                      _focusVisible={{
                        boxShadow: "0px",
                        borderColor: "primary.500",
                      }}
                      {...field}
                      onChange={(e) => {
                        field.onChange(Number(e.target.value));
                      }}
                    />
                  )}
                />
              </VStack>
              <Spacer />

              <VStack spacing={"4px"} maxW="217.6">
                <Text
                  w={"100%"}
                  fontSize="14px"
                  fontWeight={400}
                  color="textcolors.primary"
                >
                  Max
                </Text>
                <Controller
                  control={control}
                  name="max"
                  render={({ field }) => (
                    <Input
                      defaultValue={10}
                      fontSize={"14px"}
                      fontWeight={400}
                      padding={"8px 16px"}
                      border="1px"
                      borderStyle={"solid"}
                      borderColor={"#abb5c4"}
                      _focusVisible={{
                        boxShadow: "0px",
                        borderColor: "primary.500",
                      }}
                      {...field}
                      onChange={(e) => {
                        field.onChange(Number(e.target.value));
                      }}
                    />
                  )}
                />
              </VStack>
            </Stack>
            <Box>
              <Stack spacing={"4px"} marginTop="32px">
                <Text
                  w={"100%"}
                  fontSize="14px"
                  fontWeight={400}
                  color="textcolors.primary"
                >
                  Increament
                </Text>
                <Controller
                  control={control}
                  name="increment"
                  render={({ field }) => (
                    <Input
                      defaultValue={1}
                      fontSize={"14px"}
                      fontWeight={400}
                      padding={"8px 16px"}
                      border="1px"
                      borderStyle={"solid"}
                      borderColor={"#abb5c4"}
                      _focusVisible={{
                        boxShadow: "0px",
                        borderColor: "primary.500",
                      }}
                      maxW="196.8px"
                      {...field}
                      onChange={(e) => {
                        field.onChange(Number(e.target.value));
                      }}
                    />
                  )}
                />
              </Stack>
            </Box>
          </Stack>
        </DrawerBody>

        <DrawerFooter
          borderTop="1px solid"
          borderColor={"borders.accent"}
          padding={"24px"}
          backgroundColor={"backgrounds.header"}
          flexDirection="column"
        >
          <Stack spacing={0} w="100%">
            <HStack direction={{ xs: "column", md: "row" }} w="100%">
              <Button
                h="40px"
                w={"auto"}
                borderRadius="5px"
                fontSize="14px"
                fontWeight={500}
                marginRight="16px"
                padding={"12px 20px"}
                onClick={handleSubmit(
                  (data) => {
                    if (questionItemDetails?.questionIndex !== undefined) {
                      template.setValue(
                        getFieldKey(questionItemDetails, "question.properties"),
                        data
                      );
                      template.setValue(
                        getFieldKey(questionItemDetails, "question.score"),
                        data.max
                      );

                      onClose();
                    }
                  },
                  (error) => {
                    console.log(error);
                  }
                )}
              >
                Save and apply
              </Button>
              <Button
                h="40px"
                borderRadius="5px"
                fontSize="14px"
                fontWeight={500}
                variant="outline"
                padding={"12px 20px"}
                onClick={onClose}
              >
                Cancel
              </Button>
            </HStack>
          </Stack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
