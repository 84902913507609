import {
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Stack,
  Text,
  useBoolean,
  useBreakpointValue,
  useDisclosure,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FiCheck, FiMoreVertical } from "react-icons/fi";
import { useDebouncedCallback } from "use-debounce";
import { TemplateMeta } from "../../../../../../modules/Audit";
import SubHeading from "../../../Sub-Heading";
import Filter from "../Filter";
import Accesspopover from "./Accesspopover";
import ArchiveTableLg from "./ArchiveTableLg";
import ArchiveTableSm from "./ArchiveTableSm";
import Sharedpopover from "./Sharedpopover";

export default function Archive() {
  const GroupDisclosure = useDisclosure();
  const [totalTemplates, setTotalTemplate] = useState<number>(0);
  const Userpopover = useDisclosure();
  const futureImplemetation = true;
  const [searchText, setSearchText] = useState("");
  const debounced = useDebouncedCallback((value) => {
    setSearchText(value);
  }, 1000);
  const [templatesList, setTemplatesList] = useState<
    Array<TemplateMeta.TemplateMeta>
  >([]);
  const searchedTemplates = useCallback(() => {
    return templatesList.filter((temp) =>
      temp.title.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, templatesList]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isLoading, setIsLoading] = useBoolean(true);
  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const toast = useToast();
  const [limit, setLimit] = useState<number>(25);
  const pageCount = Math.ceil(totalTemplates / limit);
  const TableSize = useBreakpointValue({
    xs: <ArchiveTableSm />,
    md: (
      <ArchiveTableLg
        data={searchedTemplates()}
        isLoading={isLoading}
        setLastUpdate={setLastUpdate}
      />
    ),
  });
  useEffect(() => {
    TemplateMeta.Fetch(
      { page: pageNumber, limit: limit, archive: true, search: searchText },
      (data, total) => {
        setTemplatesList(data);
        setTotalTemplate(total);
        setIsLoading.off();
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
        setIsLoading.off();
      }
    );
  }, [pageNumber, lastUpdate, searchText]);
  return (
    <Stack px={{ sm: "0px", md: "32px", xl: "50px" }} py="32px">
      <SubHeading fontSize="24px" mb="16px">
        Template archive
      </SubHeading>
      <Stack
        px={{ xs: "16px", md: "0px" }}
        // justifyContent="space-between"
        direction={{ xs: "column", md: "row" }}
      >
        <Stack direction={{ xs: "column", md: "row" }} flex={1}>
          <Stack>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<AiOutlineSearch color="#545f70" size="18px" />}
              />
              <Input
                minW={{ xs: "100%", md: "400px" }}
                placeholder="Search"
                border="1px solid"
                borderColor="borders.accent"
                fontSize="16px"
                lineHeight="1.5rem"
                fontWeight={400}
                color="textcolors.primary"
                py="8px"
                pl="38px"
                _focusVisible={{
                  boxShadow: "none",
                  border: "1px sloid",
                  borderColor: "primary.500",
                }}
                onChange={(e) => {
                  debounced(e.target.value);
                }}
              ></Input>
            </InputGroup>
          </Stack>
          {futureImplemetation ?? (
            <HStack flex={1}>
              <Wrap spacing="8px">
                <WrapItem>
                  <ButtonGroup
                    size="md"
                    isAttached
                    variant="outline"
                    fontSize="14px"
                  >
                    <Popover
                      isOpen={Userpopover.isOpen}
                      placement="bottom-start"
                    >
                      <PopoverTrigger>
                        <Button
                          px="12.5px"
                          py="12px"
                          borderLeftRadius="8px"
                          maxW="350px"
                          onClick={Userpopover.onToggle}
                        >
                          Access:
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent w="400px">
                        <PopoverBody>
                          <Accesspopover
                            options={[
                              {
                                label: "me",
                              },
                            ]}
                          />
                        </PopoverBody>
                        <PopoverFooter p={0} textAlign="end">
                          <Button
                            h="32px"
                            margin="8px"
                            variant="solid"
                            px="13px"
                            fontSize="13px"
                            borderRadius="8px"
                            lineHeight={1}
                          >
                            Done
                          </Button>
                        </PopoverFooter>
                      </PopoverContent>
                    </Popover>
                    <IconButton
                      zIndex={1}
                      backgroundColor="white"
                      p="12px"
                      borderRightRadius="8px"
                      aria-label="Add to friends"
                      icon={<AiOutlineClose />}
                    />
                  </ButtonGroup>
                </WrapItem>
                <WrapItem>
                  <Filter
                    isOpen={GroupDisclosure.isOpen}
                    onClick={GroupDisclosure.onToggle}
                    onClose={GroupDisclosure.onClose}
                    Label="Shared with"
                    Body={
                      <Sharedpopover
                        options={[
                          {
                            label: "danish",
                          },
                          {
                            label: "saoud",
                          },
                          {
                            label: "asif",
                          },
                        ]}
                        groupoptions={[
                          {
                            label: "QSComply",
                          },
                        ]}
                      />
                    }
                  />
                </WrapItem>
              </Wrap>
              <Menu>
                <MenuButton
                  as={Button}
                  h="41px"
                  minW="110px"
                  variant="ghost"
                  px="20px"
                  py="12px"
                  fontWeight={500}
                  iconSpacing={0.5}
                  color="primary.500"
                  borderRadius="8px"
                >
                  + Add filter
                </MenuButton>
                <MenuList minW="160px">
                  {["Access", "Shared with"].map((menu, i) => {
                    return (
                      <Fragment>
                        <MenuItem
                          px="14px"
                          py="9.6px"
                          fontSize="14px"
                          fontWeight={400}
                          color="textcolors.primary"
                        >
                          {menu}
                        </MenuItem>
                      </Fragment>
                    );
                  })}
                </MenuList>
              </Menu>
            </HStack>
          )}
        </Stack>
        <HStack spacing={0} justify="end">
          <Text fontSize="14px" color="icons.primary">
            {`(${limit * (pageNumber - 1) + 1}-${
              templatesList.length + limit * (pageNumber - 1)
            } of ${totalTemplates})`}
          </Text>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<FiMoreVertical size={24} color="#545f70" />}
              variant="outline"
              borderRadius={"50%"}
              border={"none"}
              _hover={{ backgroundColor: "transparent" }}
              _active={{
                backgroundColor: "transparent",
              }}
            />
            <MenuList minW="160px">
              <MenuItem px="14px" py="9.5px">
                <Text fontSize="13px" fontWeight={400} lineHeight="1rem">
                  Results per page
                </Text>
              </MenuItem>
              {[25, 50, 100].map((menu, i) => {
                return (
                  <Fragment>
                    <MenuItem px="16px" py="8px">
                      <HStack
                        onClick={() => {
                          setLimit(menu);
                          {
                            menu >= totalTemplates && setPageNumber(1);
                          }
                        }}
                      >
                        <FiCheck size="18px" color="#1da5d4" />
                        <Text fontSize="14px" color="textcolors.primary">
                          {menu}
                        </Text>
                      </HStack>
                    </MenuItem>
                  </Fragment>
                );
              })}
            </MenuList>
          </Menu>
        </HStack>
      </Stack>
      {TableSize}
      <HStack spacing="13px" justify={{ xs: "center", md: "end" }}>
        <IconButton
          aria-label="back"
          backgroundColor="transparent"
          _hover={{ backgroundColor: "transparent" }}
          icon={
            <BsChevronLeft
              size={19}
              color={
                pageNumber <= 1 || pageNumber > pageCount
                  ? "#828ea0"
                  : "#000000"
              }
            />
          }
          isDisabled={pageNumber <= 1 || pageNumber > pageCount ? true : false}
          onClick={() => {
            if (pageNumber > 1 && pageNumber <= pageCount)
              pageNumberInputRef.current?.value &&
                (pageNumberInputRef.current.value = String(pageNumber - 1));
            setPageNumber(pageNumber - 1);
            console.log(pageNumber);
          }}
        />
        <Input
          ref={pageNumberInputRef}
          h="37px"
          w="56px"
          backgroundColor="backgrounds.primary"
          defaultValue={pageNumber.toString()}
          textAlign="center"
          borderColor="borders.accent"
          onBlur={(e) => {
            const pageValue = e.target.value;
            setPageNumber(parseInt(pageValue));
          }}
        />

        <Text color="textcolors.secondary">/</Text>
        <Text w="40px" textAlign="center">
          {pageCount}
        </Text>
        <IconButton
          aria-label="right"
          backgroundColor="transparent"
          _hover={{ backgroundColor: "transparent" }}
          icon={
            <BsChevronRight
              size={19}
              color={
                pageNumber >= pageCount || pageNumber < 1
                  ? "#828ea0"
                  : "#000000"
              }
            />
          }
          isDisabled={pageNumber >= pageCount || pageNumber < 1 ? true : false}
          onClick={() => {
            if (pageNumber < pageCount && pageNumber >= 1) {
              pageNumberInputRef.current?.value &&
                (pageNumberInputRef.current.value = String(pageNumber + 1));
              setPageNumber(pageNumber + 1);
            }

            console.log(pageNumber);
          }}
        />
      </HStack>
    </Stack>
  );
}
