import { Button, Drawer, HStack, Stack, Text, useToast } from '@chakra-ui/react';
import { Fragment, useEffect } from 'react';
import { AiFillCloseCircle, AiOutlineDownload } from 'react-icons/ai';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { RiFileCopy2Fill } from 'react-icons/ri';
import { TemplateMeta } from '../../../../../../modules/Audit';

interface BuildBottomTemplatesProps{
  isOpen : boolean,
  onClose: ()=>void,
  templateIds : Array<number>
  setLastUpdate: (timestamp: number) => void;
}
export default function BuildBottomTemplates({isOpen,onClose,templateIds,setLastUpdate}:BuildBottomTemplatesProps) {
  const toast = useToast();
  const btns: Array<{
    label: String;
    icon: any;
    onClick: any;
  }> = [
    {
      label: 'Archive',
      icon: <AiOutlineDownload size={16} fill="#1da5d4" />,
      onClick: ()=>{
        TemplateMeta.bulkArchive (
            {archive:true},
          {templateIds:templateIds},
          ()=>{
            toast({
              title: 'Templates archived',
              status: 'success',
            });
            setLastUpdate(Date.now());
            onClose()
          },
          (error)=>{
            toast({
              title: 'Error',
              description: error,
              status: 'error',
            });
          }
        )
      },
    },
    {
      label: 'Duplicate',
      icon: <RiFileCopy2Fill size={16} fill="#1da5d4" />,
      onClick: () => {
        TemplateMeta.bulkDuplicate (
            {templateIds:templateIds},
            ()=>{
              toast({
                title: 'Templates Dulicates Created',
                status: 'success',
              });
              setLastUpdate(Date.now());
              onClose()
            },
            (error)=>{
              toast({
                title: 'Error',
                description: error,
                status: 'error',
              });
            }
          )
      },
    },
    // {
    //   label: 'Delete',
    //   icon: <MdDelete size={16} fill="#1da5d4" />,
    //   onClick: () => {},
    // },
  ];
  useEffect(()=>{

    {templateIds.length == 0 && onClose()}
  },[templateIds])
  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
    <Stack
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      bgColor="White"
      w="100%"
      zIndex={21}
      padding="0px"
      backgroundColor={'primary.500'}>
      <HStack h="65px" justifyContent="space-between" px="96px" py="16px">
        <HStack>
          <IoCheckmarkCircle fill="white" size={23} />
          <Text
            fontSize="16px"
            fontWeight={400}
            color="white"
            lineHeight="24px">
            {`${templateIds.length} items selected`}
          </Text>
        </HStack>

        <HStack spacing={4}>
          {btns.map((menu, i) => {
            return (
              <Fragment key={i}>
                <Button
                  lineHeight="16px"
                  px="12.8px"
                  py="8px"
                  borderRadius="8px"
                  fontSize="13px"
                  fontWeight={500}
                  backgroundColor="white"
                  color="primary.500"
                  leftIcon={menu.icon}
                  _hover={{backgroundColor: 'none'}}
                  _active={{backgroundColor: 'none'}}
                  onClick={menu.onClick}>
                  {menu.label}
                </Button>
              </Fragment>
            );
          })}
          {/* <BulkEdit isOpen={isOpen} onClose={onClose} /> */}

          <AiFillCloseCircle fill="white" size={19} onClick={onClose}/>
        </HStack>
      </HStack>
    </Stack>
    </Drawer>
  );
}
