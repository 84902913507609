import { axiosInstance } from "./axiosInstance-aud";

declare module AccessControl {
  type AccessMeta = {
    inspectionUserId: string;
    templateUserId: string;
    templatePermissionId: number;
    inspectionPermissionId: number;
    templateId: number;
  };
  type FetchAccessDetails = {
    id: number;
    inspectionUserId: string;
    templateUserId: string;
    templatePermissionId: number;
    inspectionPermissionId: number;
    templateId: number;
  };
}
module AccessControl {
  const URL = "/user-template-permissions-control";
  export function FetchAllUsersTemplatePermission(
    templateId: number,
    success: (response: Array<FetchAccessDetails>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}`, {
        params: {
          templateId: templateId,
        },
      })
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function CreateTemplatePermission(
    data: AccessMeta,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${URL}`, data)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchTemplatePermissionById(
    id: number,
    success: (response: AccessMeta) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function UpdateTemplatePermissionById(
    id: number,
    data: Partial<AccessMeta>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function DeleteTemplatePermission(
    id: number,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchPermissionsForTemplateById(
    templateId: number,
    success: (response: AccessMeta) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}/template/${templateId}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { AccessControl };
