import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  Image,
  Input,
  Spinner,
  Text,
  useBoolean,
  useToast,
  VStack,
} from "@chakra-ui/react";

import { Fragment, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { AiOutlineUpload } from "react-icons/ai";
import { FiImage, FiUpload } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { UploadAttachments } from "../../../../../../../Payroll/Api/UploadAttachments";
import { fileToBase64 } from "../../../../../../modules/api";
import { ReportLayout } from "../../../../../../modules/Audit/ReportLayout";
import { compressImage } from "../../../../../../modules/utils";

export default function HeaderImage() {
  const { id } = useParams();

  const toast = useToast({
    position: "top",
  });
  const form = useFormContext<ReportLayout.ReportLayout>();
  const watchedImage = useWatch({
    control: form.control,
    name: "layout.header.image",
  });
  const [isImageUploading, setIsImageUploading] = useBoolean(false);
  const imageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsImageUploading.on();
      const file = e.target.files[0];
      const compressedImage = await compressImage(file);
      fileToBase64(compressedImage)
        .then((base64) => {
          // Inspection.UploadMedia(
          //   {
          //     base64,
          //     fileName: file.name,
          //     inspectionId: `template_${id}`,
          //   },
          //   path => {
          //     form.setValue('layout.header.image', path);
          //     setIsImageUploading.off();
          //   },
          //   error => {
          //     setIsImageUploading.off();
          //     toast({
          //       title: 'Error',
          //       description: error,
          //       status: 'error',
          //     });
          //   },
          // );
          const formData = new FormData();
          formData.append("docType", "TEMPLATE");
          formData.append("file", file);
          formData.append("templateId", id!);
          UploadAttachments.UploadAttachment(
            formData,
            (success) => {
              form.setValue("layout.header.image", success.url);
              setIsImageUploading.off();
            },
            (err) => {
              toast({
                title: "Error",
                description: err,
                status: "error",
              });
              setIsImageUploading.off();
            }
          );
        })
        .catch((e) => {
          setIsImageUploading.off();
          toast({
            title: "Error",
            description: "Error uploading image",
            status: "error",
          });
        });
    }
  };

  const imageRef = useRef<HTMLInputElement>(null);

  const FileInput = () => (
    <Input
      ref={imageRef}
      type={"file"}
      hidden
      accept="image/*"
      onChange={imageChange}
    />
  );

  if (watchedImage) {
    return (
      <Box
        role="group"
        w="100%"
        h="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={watchedImage}
          w="100%"
          h="100%"
          borderRadius={"12px"}
          objectFit={"fill"}
        />
        <Flex
          position="absolute"
          display="none"
          _groupHover={{ display: "flex" }}
        >
          <IconButton
            icon={<AiOutlineUpload size={24} />}
            aria-label="ReUpload"
            variant="ghost"
            colorScheme="blue"
            _hover={{ background: "tranparent" }}
            onClick={() => {
              imageRef.current?.click();
            }}
          />
          <IconButton
            icon={<DeleteIcon />}
            aria-label="Delete"
            variant="ghost"
            colorScheme="red"
            _hover={{ background: "tranparent" }}
            onClick={() => {
              form.setValue("layout.header.image", "");
            }}
          />
        </Flex>
        <FileInput />
      </Box>
    );
  } else {
    if (isImageUploading) {
      return (
        <Box
          w="100%"
          h="100%"
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Spinner />
        </Box>
      );
    } else {
      return (
        <Fragment>
          <Box
            w={"100%"}
            h={"100%"}
            justifyContent="center"
            alignItems="center"
            display="flex"
            _groupHover={{
              display: "none",
            }}
          >
            <FiImage size={48} color={"blue"} />
          </Box>
          <Box
            w={"100%"}
            h={"100%"}
            display="none"
            _groupHover={{
              display: "block",
            }}
            onClick={() => {
              imageRef.current?.click();
            }}
          >
            <VStack
              w={"100%"}
              h={"100%"}
              spacing={1}
              backgroundColor="#d7dee9"
              justifyContent={"center"}
            >
              <FiUpload size={20} color="#545f70" />
              <Text color="icons.primary" fontSize={"14px"} fontWeight={500}>
                Upload image
              </Text>
            </VStack>
          </Box>
          <FileInput />
        </Fragment>
      );
    }
  }
}
