import {
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

interface ViewImageModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedImage: string;
}
export default function ViewImageModal({
  isOpen,
  onClose,
  selectedImage,
}: ViewImageModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>View Media</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Image src={selectedImage} maxW="100%" />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
