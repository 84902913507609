import {
  Box,
  Button,
  HStack,
  Input,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { TemplateMeta } from "../../../modules/Audit";
import { PeriodicalEvents } from "../../../modules/Audit/PeriodicalEvents";
import { PeriodicalSchedule } from "../../../modules/Audit/PeriodicalSchedule";
import ReportTable from "./ReportTable";

export default function PeriodicalScheduleReport() {
  const [allEvents, setALLEvents] = useState<Array<PeriodicalEvents.GetEvents>>(
    []
  );
  const toast = useToast();
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  const [reportData, setReportData] = useState<
    Array<PeriodicalSchedule.PeriodicalScheduleReport>
  >([]);
  let startDate = moment("2023-01-01");
  let endDate = moment("2023-12-31");
  let dates: Array<any> = [];

  while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
    dates.push(startDate.format("YYYY-MM-DD"));
    startDate = startDate.add(1, "days");
  }
  const [dateRange, setDateRange] = useState<{
    startDate: Moment;
    endDate: Moment;
  }>({
    startDate: moment().startOf("year"),
    endDate: moment().endOf("year"),
  });

  const { locationList, businessesList, currentBusinessId } =
    useBusinessContext();
  const frequencyOptions = [
    {
      label: "Daily",
      value: "DAILY",
    },
    {
      label: "Weekly",
      value: "WEEKLY",
    },

    {
      label: "Monthly",
      value: "MONTHLY",
    },

    {
      label: "Annually",
      value: "ANNUALLY",
    },
    {
      label: "On Request",
      value: "ON_REQUEST",
    },
  ];
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const getEventOptions = () => {
    const unselectOption = { id: "unselect", name: "Unselect Event" };
    return selectedEvent ? [unselectOption, ...allEvents] : allEvents;
  };
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const getLocationOptions = () => {
    const unselectOption = { id: "unselect", name: "Unselect" };
    return selectedLocation
      ? [unselectOption, { ...locationList }]
      : locationList;
  };
  const [selectedFrequency, setSelectedFrequency] = useState<any>(null);
  const getFrequencyOptions = () => {
    const unselectOption = { value: "unselectFrequency", label: "Unselect" };
    return selectedFrequency
      ? [unselectOption, ...frequencyOptions]
      : frequencyOptions;
  };
  const [templatesList, setTemplatesList] = useState<
    Array<TemplateMeta.TemplateMeta>
  >([]);
  const [selectTemplate, setSelectedTemplate] = useState<any>(null);
  const getTemplateOptions = () => {
    const unselectOption = { id: "unselect", title: "Unselect" };
    return selectTemplate ? [unselectOption, ...templatesList] : templatesList;
  };
  const CurrentBusinessData = businessesList.find(
    (business) => business.id === currentBusinessId
  );
  useEffect(() => {
    PeriodicalEvents.FetchPeriodicalEvents(
      (data) => {
        setALLEvents(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, []);
  useEffect(() => {
    PeriodicalSchedule.FetchPeriodicalScheduleReport(
      {
        frequency: selectedFrequency?.value,
        startDate: dateRange.startDate.format("YYYY-MM-DD"),
        endDate: dateRange.endDate.format("YYYY-MM-DD"),
        activityId: selectedEvent?.id,
        templateId: selectTemplate?.id,
        locationId: selectedLocation?.id,
      },
      (data) => {
        setReportData(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, [lastUpdated]);
  useEffect(() => {
    TemplateMeta.Fetch(
      {
        limit: 100,
        archive: false,
      },
      (data) => {
        setTemplatesList(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
        // setIsLoading.off();
      }
    );
  }, []);
  const captureScreenshot = async () => {
    const div = document.querySelector("#scrollable-component");

    if (div) {
      const totalColPadding =
        (div
          ?.getElementsByTagName("thead")
          .item(0)
          ?.getElementsByTagName("tr")
          .item(0)
          ?.getElementsByTagName("th").length ?? 0) * 12;

      const doc = new jsPDF({
        orientation: "p",
        unit: "px",
        format: [
          div.scrollWidth,
          (div.scrollHeight < 150 ? 400 : div.scrollHeight) + 240,
        ],
      });

      const img = {
        width: 100,
        height: 100,
      };
      const addCustomHeader = (data: any) => {
        if (data?.pageNumber === 1) {
          if (CurrentBusinessData?.logo) {
            doc.addImage(
              CurrentBusinessData?.logo +
                "?r=" +
                Math.floor(Math.random() * 100000),
              "JPEG",
              data.settings.margin.left,
              30,
              img.width,
              img.height
            );
          }
          doc.setFontSize(30);
          doc.setTextColor(0, 0, 0);
          doc.text(
            "Periodic Report",
            data.settings.margin.left,
            img.height + 50
          );
          doc.setFontSize(20);
          doc.setTextColor(0, 0, 0);
          doc.text(
            CurrentBusinessData?.name ?? "",
            data.settings.margin.left,
            img.height + 70
          );

          doc.setFontSize(20);
          doc.setTextColor(0, 0, 0);
          doc.text(
            `For the period ${dateRange.startDate.format(
              "DD/MM/YYYY"
            )} to ${dateRange.endDate.format("DD/MM/YYYY")}`,
            data.settings.margin.left,
            img.height + 90
          );

          // doc.setFontSize(20);
          // doc.setTextColor(0, 0, 0);
          // doc.text(
          //   `Report Type: ${selectedReport.label}`,
          //   data.settings.margin.left,
          //   img.height + 110,
          // );
        }
        data.settings.margin.top = 50;
      };

      autoTable(doc, {
        html: "#scrollable-component",
        pageBreak: "auto",
        margin: {
          top: (img?.height ?? 0) + 140,
          right: 50,
          bottom: 10,
          left: 50,
        },
        theme: "grid",
        includeHiddenHtml: true,
        willDrawPage: addCustomHeader,
        useCss: true,
        tableWidth: "auto",
      });

      doc.save("Periodic Report.pdf");
    }
  };
  return (
    <Stack h="calc(100vh - 70px)">
      <Stack px="15px" pt="15px">
        <Text
          fontSize="24px"
          fontWeight="500"
          color="textcolor.primary"
          textAlign="center"
        >
          Periodic Report
        </Text>
        <Text
          fontSize="16px"
          fontWeight="500"
          color="textcolor.primary"
          textAlign="center"
        >
          {CurrentBusinessData?.name}
        </Text>
        <SimpleGrid minChildWidth={"270px"} spacing={"20px"}>
          <Box>
            <Text fontSize="16px" fontWeight="400" color="textcolor.primary">
              Date range
            </Text>
            <Box>
              <HStack gap={0}>
                <Input
                  // maxW={{xs: '100%', md: '130px'}}
                  h="36px"
                  px="8px"
                  placeholder="Select Date"
                  border="1px solid"
                  borderColor="#b7bec5"
                  borderRightRadius={0}
                  fontSize="14px"
                  fontWeight={400}
                  color="textcolors.primary"
                  backgroundColor="white"
                  type="date"
                  _focusVisible={{
                    boxShadow: "none",
                    border: "1px solid",
                    borderColor: "primary.500",
                  }}
                  value={dateRange.startDate.format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setDateRange((prev) => ({
                      ...prev,
                      startDate: moment(e.target.value),
                    }));
                  }}
                />
                <Input
                  h="36px"
                  px="8px"
                  placeholder="Select Date"
                  border="1px solid"
                  borderColor="#b7bec5"
                  fontSize="14px"
                  borderLeftRadius={0}
                  // borderRightRadius={0}
                  fontWeight={400}
                  color="textcolors.primary"
                  backgroundColor="white"
                  type="date"
                  _focusVisible={{
                    boxShadow: "none",
                    border: "1px solid",
                    borderColor: "primary.500",
                  }}
                  value={dateRange.endDate.format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setDateRange((prev) => ({
                      ...prev,
                      endDate: moment(e.target.value),
                    }));
                  }}
                />
              </HStack>
            </Box>
          </Box>

          <Box>
            <Text fontSize="16px" fontWeight="400" color="textcolor.primary">
              Frequency
            </Text>
            <Box minW="270px">
              <ReactSelect
                withValidation
                placeholder="Please select frequency"
                options={getFrequencyOptions()}
                value={selectedFrequency}
                onChange={(selectedOption: any) => {
                  setSelectedFrequency(
                    selectedOption &&
                      selectedOption.value === "unselectFrequency"
                      ? null
                      : selectedOption
                  );
                }}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            </Box>
          </Box>
          <Box>
            <Text fontSize="16px" fontWeight="400" color="textcolor.primary">
              Location
            </Text>
            <Box minW="270px">
              <ReactSelect
                withValidation
                placeholder="Please select location"
                options={getLocationOptions()}
                value={selectedLocation}
                onChange={(selectedOption: any) => {
                  if (selectedOption && selectedOption.id === "unselect") {
                    setSelectedLocation(null);
                  } else {
                    setSelectedLocation(selectedOption);
                  }
                }}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
              />
            </Box>
          </Box>
          <Box>
            <Text fontSize="16px" fontWeight="400" color="textcolor.primary">
              Events
            </Text>
            <Box minW="270px">
              <ReactSelect
                withValidation
                placeholder="Please select Events"
                options={getEventOptions()}
                value={selectedEvent}
                onChange={(selectedOption: any) => {
                  if (selectedOption && selectedOption.id === "unselect") {
                    setSelectedEvent(null);
                  } else {
                    setSelectedEvent(selectedOption);
                  }
                }}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
              />
            </Box>
          </Box>
          <Box>
            <Text fontSize="16px" fontWeight="400" color="textcolor.primary">
              Templates
            </Text>
            <Box minW="270px">
              <ReactSelect
                withValidation
                placeholder="Please select template"
                options={getTemplateOptions()}
                value={selectTemplate}
                onChange={(selectedOption: any) => {
                  if (selectedOption && selectedOption.id === "unselect") {
                    setSelectedTemplate(null);
                  } else {
                    setSelectedTemplate(selectedOption);
                  }
                }}
                getOptionLabel={(option: any) => option.title}
                getOptionValue={(option: any) => option.id}
              />
            </Box>
          </Box>
          {/* )} */}

          {/* <Button
            maxW="200px"
            mt="8px"
            w="100%"
            h="36px"
            borderRadius="5px"
            border="1px solid #b7bec5"
            variant="outline"
            alignSelf="flex-end"
            fontWeight="bold"
            justifyContent="left"
            leftIcon={<FaFilter color="#1da5d4" />}
            onClick={onOpen}>
            Filter
          </Button> */}
        </SimpleGrid>
        {/* <FiltersModal
          isOpen={isOpen}
          onClose={onClose}
          filters={allFilters}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
        /> */}
        <HStack justifyContent="space-between">
          <Box>
            <Button
              // isLoading={isLoading}
              paddingLeft={"12xp"}
              paddingRight={"12px"}
              lineHeight={"1rem"}
              minH={"40px"}
              padding={"11px 16px"}
              border={"1px solid"}
              borderRadius={"5px"}
              onClick={() => {
                // console.log(convertToWeeklyFormat(reportData));
                console.log(dates);
                setLastUpdated(Date.now());
              }}
            >
              Update
            </Button>
            <Button
              paddingLeft={"12xp"}
              paddingRight={"12px"}
              lineHeight={"1rem"}
              minH={"40px"}
              padding={"11px 16px"}
              border={"1px solid"}
              borderRadius={"5px"}
              onClick={captureScreenshot}
            >
              PDF
            </Button>
          </Box>
        </HStack>
      </Stack>

      <ReportTable allEvents={allEvents} reportData={reportData} />
    </Stack>
  );
}
