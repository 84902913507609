import {
  Box,
  Center,
  Spinner,
  Stack,
  Text,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Inspection } from "../../../../../../modules/Audit";
import { PeriodicalSchedule } from "../../../../../../modules/Audit/PeriodicalSchedule";
import TodayPeriodicEventTag from "../TodayPeriodicEventTag";
interface AllPeriodicalListsProps {
  lastUpdate: number;
  inspectionsList: Inspection.InpsectionMetaPayload[];
  setLastUpdate: (timestamp: number) => void;
}
export default function AllPeriodicalLists({
  lastUpdate,
  inspectionsList,
  setLastUpdate,
}: AllPeriodicalListsProps) {
  const [isLoading, setIsLoading] = useBoolean(true);
  const [periodicalSchedule, setPeriodicalSchedule] = useState<
    Array<PeriodicalSchedule.GetPeriodicalScheduleToday>
  >([]);
  const toast = useToast();
  useEffect(() => {
    setIsLoading.on();
    PeriodicalSchedule.FetchPeriodicalScheduleToday(
      {
        showEventsFor: "all",
      },
      (data) => {
        setPeriodicalSchedule(data);
        setIsLoading.off();
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
        setIsLoading.off();
      }
    );
  }, [lastUpdate]);

  return (
    <Box maxH="80vh" overflowY="scroll" py="5px">
      {isLoading ? (
        <Stack w="100%" h="30vh" justify="center" align="center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="primary.500"
            size="xl"
          />
        </Stack>
      ) : periodicalSchedule.length > 0 ? (
        <Stack spacing="16px" pr="30px">
          {periodicalSchedule.map((sch, index) => (
            <TodayPeriodicEventTag
              key={index}
              ScheduleData={sch}
              inspectionsList={inspectionsList}
              setLastUpdate={setLastUpdate}
            />
          ))}
        </Stack>
      ) : (
        <Center w="100%">
          <Text>No Scheduled Events Found !</Text>
        </Center>
      )}
    </Box>
  );
}
