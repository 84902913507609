import {
  Box,
  Container,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  Link,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useDebouncedCallback } from "use-debounce";
import { ExpenseClaimApi } from "../../Api/ExpenseClaimApi";
import { ExpenseTag } from "../EmployeeDetails/Tabs/ExpenseClaim/ExpenseTag";
import { PaginationControl } from "../PayrollEmployee/PaginationControl/PaginationControl";
import { ClaimFilters } from "./ClaimFilters";

export function ClaimHistoryTab() {
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  const [pageNumber, setPageNumber] = useState<number>(1);
  const toast = useToast();
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [submittedClaims, setSubmittedClaimsData] = useState<
    Array<ExpenseClaimApi.FetchExpenseClaim>
  >([]);
  const [approvedClaims, setApprovedClaimsData] = useState<
    Array<ExpenseClaimApi.FetchExpenseClaim>
  >([]);
  const [searchText, setSearchText] = useState("");
  const [approvedCount, setApprovedCount] = useState<number>(0);
  const [submittedCount, setSubmittedCount] = useState<number>(0);
  const [personalClaim, setPersonalClaim] = useState<boolean>(false);
  const [companyClaim, setCompanyClaim] = useState<boolean>(false);
  const [mileageClaimCheck, setMileageClaimCheck] = useState<boolean>(false);
  const debounced = useDebouncedCallback((value) => {
    setSearchText(value);
    setPageNumber(1);
  }, 1000);
  useEffect(() => {
    ExpenseClaimApi.GetAllExpenseClaim(
      {
        expenseClaimPersonalMoney: personalClaim,
        expenseClaimCompanyMoney: companyClaim,
        milageClaim: mileageClaimCheck,
        status: "SUBMITTED",
        page: 1,
        limit: 25,
      },
      (data) => {
        setSubmittedClaimsData(data.data);
        setSubmittedCount(data?.count);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      }
    );
    ExpenseClaimApi.GetAllExpenseClaim(
      {
        expenseClaimPersonalMoney: personalClaim,
        expenseClaimCompanyMoney: companyClaim,
        milageClaim: mileageClaimCheck,
        status: "APPROVED",
        page: 1,
        limit: 25,
      },
      (data) => {
        setApprovedClaimsData(data.data);
        setApprovedCount(data?.count);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      }
    );
  }, [
    lastUpdated,
    pageNumber,
    pageLimit,
    searchText,
    personalClaim,
    companyClaim,
    mileageClaimCheck,
  ]);
  const [allCount, setAllCount] = useState<number>(0);
  const [allClaims, setAllClaimsData] = useState<
    Array<ExpenseClaimApi.FetchExpenseClaim>
  >([]);
  useEffect(() => {
    const combinedClaims = [...approvedClaims, ...submittedClaims];
    setAllClaimsData(combinedClaims);
    setAllCount(approvedCount + submittedCount);
  }, [approvedClaims, submittedClaims]);

  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };

  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const pageCount = Math.ceil(allCount / pageLimit);

  const paginatedClaims = allClaims.slice(
    (pageNumber - 1) * pageLimit,
    pageNumber * pageLimit
  );

  const renderPageNumbers = () => {
    const pageButtons = [];

    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            aria-label={`Page ${i}`}
            border={"1px solid #e8eaf2"}
            borderRadius={"full"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }
    } else {
      pageButtons.push(
        <IconButton
          key={1}
          aria-label={`Page 1`}
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          backgroundColor={pageNumber === 1 ? "#3454D1" : "#fff"}
          color={pageNumber === 1 ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === 1 ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>1</Text>}
          onClick={() => handlePageChange(1)}
        />
      );

      if (pageNumber > 3) {
        pageButtons.push(<Text key="left-dots">...</Text>);
      }

      const startPage = Math.max(2, pageNumber - 1);
      const endPage = Math.min(pageCount - 1, pageNumber + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            border={"1px solid #e8eaf2"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            aria-label={`Page ${i}`}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }

      if (pageNumber < pageCount - 2) {
        pageButtons.push(<Text key="right-dots">...</Text>);
      }

      pageButtons.push(
        <IconButton
          key={pageCount}
          border={"1px solid #e8eaf2"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          borderRadius={"full"}
          aria-label={`Page ${pageCount}`}
          backgroundColor={pageNumber === pageCount ? "#3454D1" : "#fff"}
          color={pageNumber === pageCount ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === pageCount ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>{pageCount}</Text>}
          onClick={() => handlePageChange(pageCount)}
        />
      );
    }

    return pageButtons;
  };
  return (
    <Stack
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      padding={"20px"}
    >
      {" "}
      <Container maxW={"container.xl"} padding={0}>
        <Stack
          borderRadius={"3px"}
          boxShadow={"0 0 0 1px rgba(0,10,30,.2)"}
          padding={"4px"}
          margin={"24px 0px 20px"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
        >
          <Stack
            padding={0}
            direction={{ xs: "column", md: "row" }}
            alignItems={"center"}
          >
            <Image
              borderRadius="0"
              w="285px"
              h="180px"
              src="https://edge.xero.com/people/payroll/assets/images/video-thumbs/settings-video.png"
              alt="Payroll Settings"
            />
            <VStack
              _light={{ color: "customColor.black4" }}
              _dark={{ color: "customColor.white" }}
              fontSize="18px"
              align="left"
              maxW={"580px"}
            >
              <Text
                fontWeight="bold"
                fontSize={"15px"}
                _dark={{
                  color: "customColor.dark.150",
                }}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Expense Claim History
              </Text>

              <Text
                fontWeight="normal"
                fontSize={"15px"}
                _dark={{
                  color: "customColor.dark.150",
                }}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Manage requests for leave for one or more employees. Review and
                approve requests, schedule leave , and see who’s away. See our
                guide to{" "}
                <Link href="#" color="primary.950">
                  managing leave for employees.
                </Link>
              </Text>
            </VStack>
          </Stack>
        </Stack>

        <Stack
          // maxWidth={"1164px"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          borderRadius="4px"
          bg={"#fff"}
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          borderWidth="1px"
          borderStyle={"solid"}
          borderColor="#f6f6f6"
          padding={"25px"}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            margin={"5px 0px 0px 5px"}
          >
            <Box w={"100%"}>
              <PaginationControl
                limit={pageLimit}
                setLimit={setPageLimit}
                setPageNumber={setPageNumber}
              />
            </Box>
            <HStack p="12px 0px 0px">
              <Stack display={"flex"} flex={1}>
                <Stack gap={0} direction={"row"} alignItems={"center"}>
                  <Text
                    fontSize={"13.44px"}
                    _light={{
                      color: "customColor.gray.6b7885",
                    }}
                  >
                    Search:
                  </Text>
                  <InputGroup ml={[0, "7px"]}>
                    <Input
                      type="text"
                      placeholder="Search"
                      borderRadius={"3px"}
                      minW={"100px"}
                      h={["auto", "40px"]}
                      _focusVisible={{
                        border: "1px solid grey",
                      }}
                      onChange={(e) => {
                        debounced(e.target.value);
                      }}
                    />
                  </InputGroup>
                </Stack>
              </Stack>
              <HStack>
                <ClaimFilters
                  setPersonalClaim={setPersonalClaim}
                  setCompanyClaim={setCompanyClaim}
                  setMileageClaimCheck={setMileageClaimCheck}
                />
              </HStack>
            </HStack>
          </Stack>

          <Heading
            textAlign="left"
            fontSize=".9975rem"
            fontWeight="bold"
            padding="20px 0px 20px 0px"
            as="h2"
            size="md"
            display={"flex"}
            flex={1}
            _light={{
              color: "customColor.black7",
            }}
          >
            History
          </Heading>
          {paginatedClaims && paginatedClaims.length > 0 ? (
            paginatedClaims.map((expenseClaimData, i) => (
              <ExpenseTag
                expenseClaimData={expenseClaimData}
                setLastUpdated={setLastUpdated}
              />
            ))
          ) : (
            <Text
              _light={{
                color: "customColor.black7",
              }}
              fontSize={".9975rem"}
            >
              No pending claim requests
            </Text>
          )}
        </Stack>
        {paginatedClaims && paginatedClaims.length > 0 && (
          <HStack
            marginTop={"9.35px"}
            p={"25px 0px 0px 0px"}
            fontSize={"13px"}
            _light={{
              color: "customColor.black2",
            }}
            _dark={{
              color: "customColor.white",
            }}
            justifyContent={"space-between"}
          >
            <Text
              fontWeight={"normal"}
              color={"customColor.6b7885"}
              _dark={{
                color: "customColor.dark.200",
              }}
              fontSize={"11px"}
            >
              Showing {pageLimit * (pageNumber - 1) + 1} to{" "}
              {pageLimit + pageLimit * (pageNumber - 1)} of{" "}
              {paginatedClaims?.length} entries
            </Text>
            <HStack spacing="13px" justify={{ xs: "center", md: "end" }}>
              <IconButton
                aria-label="back"
                border={"1px solid #e8eaf2"}
                borderRadius={"full"}
                backgroundColor="transparent"
                _hover={{
                  backgroundColor: "#3454D1",
                  color: "white",
                }}
                onMouseEnter={() => setIsHovered2(true)}
                onMouseLeave={() => setIsHovered2(false)}
                icon={
                  <FaArrowLeft
                    size={15}
                    color={
                      isHovered2
                        ? "#FFFFFF"
                        : pageNumber <= 1 || pageNumber > pageCount
                        ? "#828ea0"
                        : "#283c50"
                    }
                  />
                }
                isDisabled={pageNumber <= 1 || pageNumber > pageCount}
                onClick={() => {
                  if (pageNumber > 1 && pageNumber <= pageCount) {
                    pageNumberInputRef.current?.value &&
                      (pageNumberInputRef.current.value = String(
                        pageNumber - 1
                      ));
                    setPageNumber(pageNumber - 1);
                  }
                }}
              />
              <HStack spacing="13px">{renderPageNumbers()}</HStack>
              <IconButton
                aria-label="right"
                backgroundColor="transparent"
                border={"1px solid #e8eaf2"}
                borderRadius={"full"}
                _hover={{
                  backgroundColor: "#3454D1",
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                icon={
                  <FaArrowRight
                    size={15}
                    color={
                      isHovered
                        ? "#FFFFFF"
                        : pageNumber >= pageCount || pageNumber < 1
                        ? "#828ea0"
                        : "#283c50"
                    }
                    style={{ transition: "color 0.2s" }} // Add smooth transition for color change
                  />
                }
                isDisabled={pageNumber >= pageCount || pageNumber < 1}
                onClick={() => {
                  if (pageNumber < pageCount && pageNumber >= 1) {
                    pageNumberInputRef.current?.value &&
                      (pageNumberInputRef.current.value = String(
                        pageNumber + 1
                      ));
                    setPageNumber(pageNumber + 1);
                  }
                }}
              />
            </HStack>
          </HStack>
        )}
      </Container>
    </Stack>
  );
}
