import * as yup from "yup";
import {
  default as axiosInstance,
  default as axiosInstanceAud,
} from "./axiosInstance-aud";
import { Template } from "./Template";

declare module Inspection {
  interface Question extends Template.Question {
    questionValue: string;
    questionColor: string;
    media: Array<Template.MediaObject>;
    questionScore?: string;
    flagged?: boolean;
  }
  type ItemsWithOmittedQuestion = Omit<
    Template.TemplateItem,
    "question" | "children"
  >;

  interface InspectionQuestion extends ItemsWithOmittedQuestion {
    question?: Question;
    children: Array<InspectionQuestion>;
  }

  type PostInspectionInventory = {
    inventoryItem: string;
    itemQuantity: number;
    id?: number;
    inspectionId: number;
    inventoryCategory: string;
  };
  type InspectionDocuments = {
    id: number;
    attachmentUrl: string;
    attachmentName: string;
    templateId: number;
  };
  type UpdateActivityStatus = {
    status: number;
    periodicScheduleEventId?: number;
    inspectionId?: number | null;
    completedBy?: string | null;
  };

  type PostPeriodicalActivities = {
    ids: Array<number>;
    status?: number;
    inspectionId?: number;
    completedBy?: string | null;
  };

  type FetchPeriodicalActivities = {
    events: Array<periodicActivity>;
    locationId?: string;
  };

  type periodicActivity = {
    id: number;

    templateId: number;
    inspectionId: number | null;
    status: string;

    dateRange: {
      startDate: string;
      endDate: string;
    };
    timeRange: {
      endTime: string;
      startTime: string;
    };
    completedBy: string | null;
    color: string;
    scheduleId: number;
    employees: Array<string>;
    activityId: number;
    location: string;
    frequency: string;
    areas: Array<string>;
    periodicActivity: {
      id: number;

      name: string;
      description: string;
      code: string;
      color: string | null;
    };
  };

  type InventoryResponse = {
    createdAt: string;
    updatedAt: string;
    inventoryItem: string;
    inspectionId: number;
    itemQuantity: string;
    deletedAt: null;
    id: number;
  };

  type InpsectionPayload = {
    template: Template.TemplateMeta;
    templateItems: Array<InspectionQuestion>;
    mediaUniqueId: string;
    inspection?: InpsectionMetaPayload;
    inspectionCompleted: boolean;
    archive?: boolean;
    inspectionScore: number;
    inspectionInventory: Array<PostInspectionInventory>;
    templateMedia: Array<InspectionDocuments>;
    deletedInventoryItems: Array<number>;
    siteName?: string;
    siteLocation?: string;
    scheduleEvent?: PostPeriodicalActivities;
    scheduleEvents?: FetchPeriodicalActivities;
  };
  type FetchInpsectionPayload = {
    template: Template.TemplateMeta;
    templateItems: Array<InspectionQuestion>;
    mediaUniqueId: string;
    inspection?: InpsectionMetaPayload;
    inspectionCompleted: boolean;
    inspectionScore: number;
    inspectionInventory: Array<PostInspectionInventory>;
    scheduleEvents: Array<UpdateActivityStatus>;
  };

  type InspectionSubmit = {
    inspectionScore: number;
    id: number;
    siteName: string;
    siteLocation: string;

    // inspectionInventory: Array<PostInspectionInventory>;
  };

  type InpsectionMetaPayload = {
    EDIT_PERMISSION: boolean;
    DELETE_PERMISSION: boolean;
    createdAt: string;
    updatedAt: string;
    id: number;
    templateId: number;
    inspectedBy: string;
    inspectedOn: string;
    inspectionTemplateId: number;
    startedOn: string;
    inspectionScore: number;
    inductionInspection: boolean;
    warehouseInspection: boolean;
    mediaUniqueId: string;
    inspectionCompleted: boolean;
    templateMeta: TemplateMeta;
    inspectionTemplate: {
      image: string;
      title: string;
    };
    title: string;
  };

  interface InspectionMeta {
    createdAt: string;
    updatedAt: string;
    id: number;
    deletedAt?: string;
    templateId: number;
    inspectedBy: number;
    inspectedOn: string;
    template: {
      createdAt: string;
      updatedAt: string;
      id: number;
      deletedAt?: string;
      title: string;
      description: string;
      image: string;
      businessId: number;
      createdBy: number;
      totalScore: number;
      published: boolean;
    };
  }

  type InspectionDateWise = {
    [key: string]: Inspection.InpsectionMetaPayload[];
  };

  type CachingInspection = {
    inspection_meta_id: number;
    question_value: string | null;
    notes: string;
    type: string;
    question_score: number;
    question_color: string;
    deletedMedia: Array<number>;
    media: Array<Template.MediaObject>;
  };

  type TemplateMeta = {
    createdAt: string;
    updatedAt: string;
    id: number;
    deletedAt?: string;
    inspectionId: number;
    site: string;
    inspectionDate: string;
    person: string;
    inspectionLocation: string;
    documentNumber: string;
    inductionInspection: boolean;
  };
  type duplicateInspection = {
    inspectionId: number;
  };
  type inspectionIds = {
    inspectionIds: Array<number>;
  };
  type pdfsDownload = {
    ids: Array<number>;
  };
}
module Inspection {
  const BASE_URL = "/inspection-meta";
  export function SubmitInspection(
    data: InpsectionPayload,
    success: (message: FetchInpsectionPayload) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(BASE_URL, data)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((err) => {
        error(err?.response?.data?.message ?? err?.message);
      });
  }

  export function SubmitCompletedInspection(
    data: InspectionSubmit,
    success: (message: FetchInpsectionPayload) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post("/inspection-meta/submit/inspection", data)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((err) => {
        error(err?.response?.data?.message ?? err?.message);
      });
  }

  export function SubmitActivitiesInspection(
    data: PostPeriodicalActivities,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post("/periodic-activity-schedules/update/status", data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(err?.response?.data?.message ?? err?.message);
      });
  }

  const cacheURL = "/answers";
  export function CachingInspection(
    id: number,
    data: Partial<CachingInspection>,
    success: (message: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${cacheURL}/${id}`, data)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function PostInspectionInventory(
    data: PostInspectionInventory,
    success: (response: InventoryResponse) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post("/inspection-inventory", data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function PostActivityStatus(
    data: Array<UpdateActivityStatus>,
    success: (response: InventoryResponse) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post("/periodic-activity-schedules/update/status", data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function DeleteInspectionInventory(
    id: number,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`/inspection-inventory/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function CreateDuplicateInspection(
    data: duplicateInspection,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post("/inspection-meta/duplicate/inspection", data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function Update(
    id: number,
    data: Partial<InpsectionPayload>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${BASE_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function Fetch(
    params: {
      page?: number;
      limit?: number;
      archive?: boolean;
      search?: string;
      inspectionType?: string;
    },
    success: (
      inspections: Array<InpsectionMetaPayload>,
      totalInspections: number
    ) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${BASE_URL}/all`, {
        params,
      })
      .then(({ data }) => {
        success(data?.data, data.total);
      })
      .catch((err) => {
        error(err?.response?.data?.message ?? err?.message);
      });
  }

  export function bulkArchive(
    params: {
      archive: boolean;
    },
    data: inspectionIds,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post("/inspection-meta/archive/inspection", data, {
        params,
      })
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function bulkInspectionDelete(
    data: inspectionIds,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post("/inspection-meta/delete/inspection", data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function bulkInspectionReportDownload(
    data: pdfsDownload,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post("/inspection-meta/bulk/download-reports", data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchById(
    id: number,
    success: (inspections: InpsectionPayload) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${BASE_URL}/edit/${id}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((err) => {
        error(err?.response?.data?.message ?? err?.message);
      });
  }

  export function UploadMedia(
    data: {
      inspectionId: string;
      fileName: string;
      base64: string;
    },
    success: (path: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceAud
      .post("/documents/api/inspection", data)
      .then(({ data }) => {
        success(data?.path);
      })
      .catch((err) => {
        error(err?.response?.data?.message ?? err?.message);
      });
  }
  export function DeleteInspection(
    id: number,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${BASE_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  const QuestionValidationSchema = yup.object().shape({
    required: yup.boolean(),
    questionValue: yup.mixed().when("required", {
      is: true,
      then: (schema) => schema.required(`Question Value is required`),
      otherwise: (schema) => schema.nullable(),
    }),
  });
  export const ValidationSchema = yup.object().shape({
    mediaUniqueId: yup.string().required("Media ID is required"),
    templateItems: yup.array().of(
      yup.object().shape({
        children: yup.array().of(
          yup.object().shape({
            question: QuestionValidationSchema.when(["children", "type"], {
              is: (children: any, type: any) => {
                return type === "QUESTION";
              },
              then: (schema) => schema.required("Question is required"),
              otherwise: (schema) => schema.strip(),
            }).optional(),

            children: yup
              .array()
              .of(
                yup.object().shape({
                  question: QuestionValidationSchema.when(
                    ["children", "type"],
                    {
                      is: (children: any, type: any) => {
                        return type === "QUESTION";
                      },
                      then: (schema) => schema.required("Question is required"),
                      otherwise: (schema) => schema.strip(),
                    }
                  ).optional(),
                })
              )
              .optional(),
            type: yup.string().oneOf(["PAGE", "SECTION", "QUESTION"]),
          })
        ),
      })
    ),
  });
}
export { Inspection };
