import {
  Box,
  Button,
  Checkbox,
  Divider,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverTrigger,
  Stack,
  Text,
  useBoolean,
  useOutsideClick,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Controller,
  useFieldArray,
  useFormContext,
  UseFormReturn,
  useWatch,
} from "react-hook-form";
import { FaCircle } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { RxDragHandleDots2 } from "react-icons/rx";
import { useParams } from "react-router-dom";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { MCQ } from "../../../modules/Audit/MCQs";
import { getFieldKey } from "../../../modules/form-apis";
import { getMaxScoreMultichoice } from "../../../modules/utils";
import { useItemContext } from "../hooks/auditItemContext";
import useDrawer from "../hooks/drawersContext";
import { useMultiChoiceContext } from "../hooks/multiChoiceContext";
import ColorsPopover from "./ColorsPopover";

interface MultipleChoiceResponseBodyProps {
  multiResponseForm: UseFormReturn<MCQ.IMCQ, any>;
  onCancel: () => void;
}

interface SortableListOptions {
  items: Array<MCQ.MCQOption>;
}
interface SortableOption {
  item: MCQ.MCQOption;
  optionIndex: number;
}
export default function MultipleChoiceResponseBody({
  multiResponseForm,
  onCancel,
}: MultipleChoiceResponseBodyProps) {
  const urlParams = useParams();
  const template = useFormContext();
  const { item } = useItemContext();

  const {
    MultipleChoiceResponseModal,
    questionItemDetails,
    setQuestionItemDetails,
  } = useDrawer();
  const { updateOptionSets, setTemplateOptionSets } = useMultiChoiceContext();

  const [isLoading, setIsLoading] = useBoolean(false);

  const multiResponseArr = useFieldArray({
    control: multiResponseForm.control,
    name: "options",
    keyName: "optionUUID",
  });

  const deleteOptions = useFieldArray({
    control: multiResponseForm.control,
    name: "deletedOptions",
  });

  const toast = useToast({
    position: "top",
  });

  const MemorizedOption = useMemo(
    () =>
      ({ item, optionIndex }: SortableOption) => {
        return (
          <MultipleChoiceOption
            key={item.id}
            optionIndex={optionIndex}
            multiResponseForm={multiResponseForm}
            onDelete={() => {
              multiResponseArr.remove(optionIndex);
              if (item.id) {
                deleteOptions.append({
                  id: item.id,
                  name: item.value,
                });
              }
            }}
          />
        );
      },
    []
  );

  const SortableItem = SortableElement<SortableOption>(MemorizedOption);

  const SortableList = SortableContainer<SortableListOptions>(
    ({ items }: SortableListOptions) => {
      return (
        <Box w="full">
          {items.map((op, i) => (
            <SortableItem key={op.id} index={i} optionIndex={i} item={op} />
          ))}
        </Box>
      );
    }
  );

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    multiResponseArr.move(oldIndex, newIndex);
  };

  return (
    <VStack>
      <SortableList
        useDragHandle
        key={multiResponseArr.fields.map((op) => op.id).join("-")}
        items={multiResponseArr.fields}
        onSortEnd={onSortEnd}
      />

      <Stack w="100%" padding="8px">
        <Button
          w="150px"
          border="0px"
          borderRadius="5px"
          leftIcon={<FiPlus />}
          variant="outline"
          _focus={{ backgroundColor: "tarnsparent" }}
          _hover={{ backgroundColor: "tarnsparent" }}
          _active={{ backgroundColor: "tarnsparent" }}
          onClick={() => {
            let option: MCQ.MCQOption = {
              color: "#808080",
              value: "",
              flagged: false,
              orderIndex: multiResponseArr.fields.length,
            };
            multiResponseArr.append(option);
          }}
        >
          Add response
        </Button>
      </Stack>
      <Box flexDirection={{ xs: "column", md: "row" }} padding="17.6px">
        <Button
          isLoading={isLoading}
          h="40px"
          w="192px"
          borderRadius="5px"
          fontSize="14px"
          fontWeight={500}
          marginRight="16px"
          padding={"12px 20px"}
          onClick={multiResponseForm.handleSubmit(
            (data) => {
              MCQ.Create(
                { ...data, templateId: Number(urlParams?.id), isGlobal: false },
                (mcq) => {
                  updateOptionSets();
                  setTemplateOptionSets([]);
                  multiResponseForm.reset({});
                  MultipleChoiceResponseModal?.onClose();
                },
                console.log
              );
              if (questionItemDetails?.questionIndex !== undefined) {
                template.setValue(
                  getFieldKey(questionItemDetails, "question.mcqsData"),
                  data
                );
                template.setValue(
                  getFieldKey(questionItemDetails, "question.score"),
                  getMaxScoreMultichoice(data.options)
                );
                // setQuestionItemDetails(undefined);
              }
            },
            (error) => {
              console.log(error);
            }
          )}
        >
          Save
        </Button>
        <Button
          h="40px"
          borderRadius="5px"
          fontSize="14px"
          fontWeight={500}
          variant="outline"
          padding={"12px 20px"}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Box>
    </VStack>
  );
}

const SortableHandleIcon = SortableHandle(() => (
  <Box padding="5px 10px 0px 0px" justifyContent="center">
    <RxDragHandleDots2 size={21} color={"#bfc6d4"} cursor="grab" />
  </Box>
));
function MultipleChoiceOption({
  optionIndex,
  multiResponseForm,
  onDelete,
}: {
  optionIndex: number;
  multiResponseForm: UseFormReturn<MCQ.IMCQ, any>;
  onDelete: () => void;
}) {
  const responseContainerRef = React.useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: responseContainerRef,
    handler: () => setIconVisible(false),
  });
  const [iconVisible, setIconVisible] = useState(false);

  const isScoringEnabled = useWatch({
    control: multiResponseForm.control,
    name: "isScoringEnabled",
  });

  useEffect(() => {
    multiResponseForm.setValue(
      `options.${optionIndex}.orderIndex`,
      optionIndex
    );
  }, []);

  return (
    <VStack
      zIndex="9999"
      ref={responseContainerRef}
      onClick={() => setIconVisible(true)}
      w={"100%"}
      alignItems="end"
      padding="5px"
      backgroundColor="white"
      borderBottom={"1px solid"}
      borderColor={"borders.accent"}
      spacing={0}
    >
      <HStack w="100%" spacing={0}>
        <SortableHandleIcon />
        <HStack flex={"auto"}>
          <InputGroup zIndex={iconVisible ? 9 : undefined}>
            <InputRightElement
              paddingBottom={"5px"}
              children={
                <Controller
                  control={multiResponseForm.control}
                  name={`options.${optionIndex}.color`}
                  render={({ field }) => (
                    <Popover placement="right">
                      <PopoverTrigger>
                        <IconButton
                          //   as="a"
                          variant="unstyled"
                          aria-label=""
                          borderRadius={"50%"}
                          size="xs"
                          icon={<FaCircle color={field.value} size="xs" />}
                        />
                      </PopoverTrigger>
                      <ColorsPopover
                        optionIndex={optionIndex}
                        formControl={multiResponseForm.control}
                      />
                    </Popover>
                  )}
                />
              }
            />
            <Controller
              control={multiResponseForm.control}
              name={`options.${optionIndex}.value`}
              render={({ field }) => (
                <Input
                  h="35.6px"
                  padding="5px"
                  w="100%"
                  placeholder="Enter text..."
                  borderColor="primary.500"
                  borderWidth={iconVisible ? "1px" : "0px"}
                  _hover={{ borderColor: "primary.500" }}
                  _focusVisible={{
                    boxShadow: "none",
                  }}
                  {...field}
                />
              )}
            />
          </InputGroup>
        </HStack>
      </HStack>
      {iconVisible ? (
        <HStack paddingTop="8px">
          <Box>
            <Controller
              control={multiResponseForm.control}
              name={`options.${optionIndex}.flagged`}
              render={({ field }) => (
                <Checkbox
                  borderColor="icons.primary"
                  textColor="textcolors.secondary"
                  fontSize="16px"
                  fontWeight={400}
                  {...field}
                  value={undefined}
                  isChecked={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                >
                  Mark as flaged
                </Checkbox>
              )}
            />
          </Box>
          <Divider
            orientation="vertical"
            h={"14px"}
            border="1px solid"
            borderColor="borders.accent"
          />
          <Controller
            control={multiResponseForm.control}
            name={`options.${optionIndex}.score`}
            render={({ field }) => (
              <HStack>
                <Text
                  color="textcolors.secondary"
                  fontSize="16px"
                  fontWeight={400}
                >
                  Score:
                </Text>

                <Input
                  w={"4rem"}
                  h={"2rem"}
                  border="1px solid"
                  borderColor="primary.500"
                  placeholder="/"
                  _hover={{ borderColor: "primary.500" }}
                  _focusVisible={{
                    boxShadow: "0px",
                  }}
                  {...field}
                />
              </HStack>
            )}
          />

          <Divider
            orientation="vertical"
            h={"14px"}
            border="1px solid"
            borderColor="borders.accent"
          />
          <Box>
            <IconButton
              backgroundColor="transparent"
              borderRadius={"50%"}
              border={"none"}
              size="22.5px"
              _hover={{ backgroundColor: "#bfc6d4" }}
              aria-label="delete"
              icon={<MdDelete color="#545f70" size="22.5px" />}
              onClick={(e) => {
                onDelete();
                e.stopPropagation();
              }}
            />
          </Box>
        </HStack>
      ) : (
        <HStack
          padding="0px 4px"
          spacing={2}
          divider={
            <Divider
              borderColor="#bfc6d4"
              orientation="vertical"
              height="10px"
              borderWidth="1px"
            />
          }
        >
          {multiResponseForm.getValues(`options.${optionIndex}.flagged`) && (
            <Text
              color="backgrounds.secondary"
              fontWeight={400}
              fontSize="14px"
            >
              Marked as flagged
            </Text>
          )}
          {isScoringEnabled && (
            <Fragment>
              <Text
                color="backgrounds.secondary"
                fontWeight={400}
                fontSize="14px"
              >
                Score:{" "}
              </Text>
              <Text
                color="backgrounds.secondary"
                fontWeight={400}
                fontSize="14px"
              >
                {multiResponseForm.getValues(`options.${optionIndex}.score`)}
              </Text>
            </Fragment>
          )}
        </HStack>
      )}
    </VStack>
  );
}
