import {
  Box,
  HStack,
  Icon,
  IconButton,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";

import { FaCheck } from "react-icons/fa";
import { GoBell } from "react-icons/go";
import { IoIosClose } from "react-icons/io";

export function Notifications() {
  return (
    <Popover trigger="hover" placement="bottom-start">
      <PopoverTrigger>
        <IconButton
          aria-label="Options"
          alignItems="center"
          border={"none"}
          justifyContent="center"
          borderRadius="50%"
          w="46px"
          h="46px"
          px="12px"
          py="12px"
          fontSize="22px"
          color="#6B7280"
          _hover={{
            color: "#3454d1",
            bg: "#eaebef",
          }}
          _active={{
            color: "#3454d1",
            bg: "#eaebef",
          }}
          icon={<GoBell />}
          variant="outline"
        />
      </PopoverTrigger>
      <PopoverContent
        padding={0}
        borderRadius={"4px"}
        border={"1px solid #e5e7eb"}
        w={"20rem"}
        zIndex="1003"
      >
        <Stack
          direction={"row"}
          gap={0}
          padding={"20px"}
          alignItems={"center"}
          justifyContent={"space-between"}
          borderRadius={"2px 2px 0 0"}
          borderBottom={"1px solid #e5e7eb"}
        >
          <Text
            fontSize={".9375rem"}
            fontWeight={"700"}
            _light={{
              color: "customColor.black7",
            }}
          >
            Notifications
          </Text>
          <Tooltip hasArrow label="MAKE AS READ" fontSize={"xs"}>
            <Stack direction={"row"}>
              <Icon
                color={"#17c666"}
                fontSize={"16px"}
                fontWeight={"400"}
                as={FaCheck}
              />
              <Text fontSize={"11px"} fontWeight={"700"} color={"#17c666"}>
                Make as read
              </Text>
            </Stack>
          </Tooltip>
        </Stack>
        {[
          {
            image: "https://bit.ly/dan-abramov",
            Title: "Malanie Hanvey We should talk about at lunch!",
            timeStamp: "2 minutes ago",
          },
          {
            image: "https://bit.ly/prosper-baba",
            Title: "Valentine Maton You can download the latest invoices...",
            timeStamp: "36 minutes ago",
          },
          {
            image: "https://bit.ly/sage-adebayo",
            Title:
              "Archie Cantonese Don't forget to pickup Jeremy after school!",
            timeStamp: "56 minutes ago",
          },
        ].map((item, i) => (
          <Stack
            key={i}
            padding={"15px 20px"}
            transition={"all .3s ease"}
            cursor={"pointer"}
            direction={"row"}
          >
            <Image
              boxSize="60px"
              border={"1px solid #e5e7eb"}
              marginRight={"1rem!important"}
              borderRadius={"4px"}
              objectFit="cover"
              src={item.image}
              alt="Notification"
            />
            <Stack>
              <Text
                fontSize={"0.84rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
                _hover={{
                  _light: {
                    color: "#3454d1",
                  },
                }}
              >
                {item.Title}
              </Text>
              <HStack justifyContent={"space-between"}>
                <Text
                  fontSize={"11px"}
                  fontWeight={"400"}
                  _light={{
                    color: "customColor.gray.64748B",
                  }}
                >
                  {item.timeStamp}
                </Text>
                <HStack>
                  <Tooltip hasArrow label="MAKE AS READ" fontSize={"xs"}>
                    <Box
                      as="span"
                      display="inline-block"
                      w="8px"
                      h="8px"
                      borderRadius="50%"
                      bg={"#e5e7eb!important"}
                    />
                  </Tooltip>
                  <Icon as={IoIosClose} color={"red"} />
                </HStack>
              </HStack>
            </Stack>
          </Stack>
        ))}
        <Stack
          padding={"15px 20px"}
          borderRadius={"0 0 2px 2px"}
          borderTop={"1px solid #e5e7eb"}
          textAlign={"center"}
        >
          <Text
            fontSize={"13px"}
            fontWeight={"600!important"}
            _light={{
              color: "customColor.black7",
            }}
            _hover={{
              _light: {
                color: "#3454d1",
                cursor: "pointer",
              },
            }}
          >
            All Notifications
          </Text>
        </Stack>
      </PopoverContent>
    </Popover>
  );
}
