import { axiosInstance } from "./axiosInstance-hris";
declare module EmployeeEmployment {
  type AddNewEmployeeEmployment = {
    empType: string;
    empIncomeType: string;
    gratuityPayItemId?: string | null;
    empBasis: string;
    payrollPayCalenderId: string;
    employeeGroupId: string;
    holidayGroupId: string;
    ratesId: string;
    startDate: string;

    benefits: boolean;
    department: string;
    jobTitle: string;
    confirmationDate?: string;
    probationNoticePeriod?: {
      value?: number | null;
      unit?: EmployementNoticePeriodEnum | null;
    };
    noticePeriod?: {
      value?: number | null;
      unit?: EmployementNoticePeriodEnum | null;
    };

    includeHolidaysPayslips: boolean;
    approveLeaveAuthorization: boolean;
    approveLeaveTimesheets: boolean;
    id: string;
    workPercentageOnPartTime: number;
    isManagementStaff: boolean;
    manager: string;
  };
  type FetchEmployees = {
    empId: string;
    empType: string;
    empIncomeType: string;
    empBasis: string;
    payrollPayCalenderId: string;
    employeeGroupId: string;

    department: string;
    holidayGroupId: string;
    ratesId: string;
    startDate: string;
    jobTitle: string;
    terminationDate: string;

    includeHolidaysPayslips: boolean;
    approveLeaveAuthorization: boolean;
    approveLeaveTimesheets: boolean;
    id: string;
    workPercentageOnPartTime: number;
  };
}
module EmployeeEmployment {
  export enum EmployementNoticePeriodEnum {
    DAYS = "days",
    WEEKS = "weeks",
    MONTHS = "months",
    YEARS = "years",
  }
  const API_URL = "/employee-employement-details";
  export function NewEmploymentAdded(
    empId: string | undefined,
    data: AddNewEmployeeEmployment,
    success: (data: AddNewEmployeeEmployment) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/${empId}`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function GetEmploymentDetails(
    empId: string | undefined,
    success: (data: FetchEmployees) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${empId}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
}
export { EmployeeEmployment };
