import {Text} from '@chakra-ui/react';

interface QuestionTitleProps {
  question: string;
}
export function QuestionTitle({question}: QuestionTitleProps) {
  return (
    <Text pb="16px" fontSize="16px" fontWeight={400} color="textcolors.primary">
      {question}
    </Text>
  );
}
