import {
  Avatar,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Fragment } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { BsFilterLeft } from "react-icons/bs";
import { FiCheck } from "react-icons/fi";

export default function ArchiveTableSm() {
  const sortitems: Array<{
    label: string;
  }> = [
    {
      label: "Name",
    },
    {
      label: "Last published",
    },
    {
      label: "Last modified",
    },
  ];
  return (
    <TableContainer>
      <Table>
        <Thead bg="backgrounds.primary">
          <Tr>
            <Th
              p="8px"
              borderTop="1px"
              borderBottom="1px"
              borderColor="borders.accent"
            >
              <HStack justifyContent="space-between">
                <HStack>
                  <Text
                    fontSize="16px"
                    fontWeight={400}
                    lineHeight="1rem"
                    color="textcolors.primary"
                    textTransform="none"
                  >
                    Last published
                  </Text>
                  <AiOutlineArrowDown size={18} color="#3f495a" />
                </HStack>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="sort"
                    icon={<BsFilterLeft size={18} color="#3f495a" />}
                    backgroundColor="transparent"
                    _hover={{ backgroundColor: "transparent" }}
                    _active={{ backgroundColor: "transparent" }}
                  />
                  <MenuList minW="180px">
                    {sortitems.map((menu, i) => {
                      return (
                        <Fragment key={i}>
                          <MenuItem px="16px" py="14px">
                            <HStack>
                              <FiCheck size="18px" color="#1da5d4" />
                              <Text fontSize="14px" color="textcolors.primary">
                                {menu.label}
                              </Text>
                            </HStack>
                          </MenuItem>
                        </Fragment>
                      );
                    })}
                  </MenuList>
                </Menu>
              </HStack>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td py="16px" pr="8px" pl="0px" borderColor="borders.accent">
              <HStack>
                <Avatar w="30px" h="30px" ml="8px" />
                <Stack>
                  <Stack spacing={0}>
                    <Text fontWeight={500} color="textcolors.primary">
                      Untitled
                    </Text>
                  </Stack>
                  <Stack spacing="3px" direction="row">
                    <Text
                      fontSize="14px"
                      lineHeight="1.25rem"
                      color="textcolors.primary"
                    >
                      Last published:
                    </Text>
                    <Text
                      fontSize="14px"
                      lineHeight="1.25rem"
                      color="icons.primary"
                    >
                      16 feb 2023
                    </Text>
                  </Stack>
                </Stack>
              </HStack>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
}
