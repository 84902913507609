import { axiosInstance } from "./axiosInstance-hris";

declare module EmployeesReport {
  type DateRange = {
    from?: string;
    to?: string;
  };
  type ReportDateRange = {
    dateRange: DateRange;
  };
  type EmployeesContactDetailData = {
    email: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    dateOfBirth: string;
    address: string;
    phoneNumber: string;
    province: string;
    city: string;
    postalCode: string;
    age: number;
  };
  type fetchReportData = {
    data: Array<EmployeesContactDetailData>;
  };
  type PayrollActivityDetailReport = {
    columns: {
      uniqueId: boolean;

      amount: boolean;
      date: boolean;
      employee: boolean;
      payItem: boolean;
      payItemType: boolean;
      quantity: boolean;
      ratePerUnit: boolean;
    };
    filters: {
      date?: {
        from?: string;
        to?: string;
      };
      empId?: string;
      payItem?: string;
      payItemType?: string;
    };
    pagination?: {
      limit: number;
      page: number;
    };
  };
  type EmployeesDetailsReport = {
    columns: {
      address: boolean;
      age: boolean;
      dateOfBirth: boolean;
      email: boolean;
      mobileNumber: boolean;
      postalCode: boolean;
      province: boolean;
      city: boolean;
      phoneNumber: boolean;
      firstName: boolean;
      lastName: boolean;
      clearanceReceived: boolean;
      comments: boolean;
      department: boolean;
      empBasis: boolean;
      empIncomeType: boolean;
      empType: boolean;
      exemptionReason: boolean;
      grossSalary: boolean;
      idCardReturned: boolean;
      interviewDate: boolean;
      isManagementStaff: boolean;
      jobTitle: boolean;
      manager: boolean;
      noticePeriodCompleted: boolean;
      payrollPayCalender: boolean;
      reason: boolean;
      residencyStatus: boolean;
      resignationDate: boolean;
      separationDate: boolean;
      startDate: boolean;
      taxNumber: boolean;
      accountName: boolean;
      accountNumber: boolean;
      bankName: boolean;
      branchCode: boolean;
      iban: boolean;
      cnic: boolean;
      uniqueId: boolean;
      relationType: boolean;
      parentOrPartner: boolean;
    };
    filters: {
      dateOfBirth?: {
        from?: string;
        to?: string;
      };
      address?: string;
      city?: string;
      active?: boolean;
      age?: number;
      email?: string;
      bankName?: string;
      empId?: string;
      firstName?: string;
      lastName?: string;
      mobileNumber?: string;
      phoneNumber?: string;
      postalCode?: string;
      province?: string;
      clearanceReceived?: boolean;
      comments?: string;
      department?: string;
      empBasis?: string;
      empIncomeType?: string;
      empType?: string;
      exemptionReason?: string;
      grossSalary?: {
        from?: number;
        to?: number;
      };
      idCardReturned?: boolean;
      interviewDate?: {
        from?: string;
        to?: string;
      };
      isManagementStaff?: boolean;
      jobTitle?: string;
      noticePeriodCompleted?: boolean;
      payrollPayCalender?: string;
      residencyStatus?: string;
      resignationDate?: {
        from?: string;
        to?: string;
      };
      separationDate?: {
        from?: string;
        to?: string;
      };
      startDate?: {
        from?: string;
        to?: string;
      };
      taxNumber?: string;
    };
    pagination?: {
      limit?: number;
      page?: number;
    };
  };
  type EmployeesContactDetailReport = {
    columns: {
      uniqueId: boolean;
      address: boolean;
      age: boolean;
      dateOfBirth: boolean;
      email: boolean;
      mobileNumber: boolean;
      postalCode: boolean;
      province: boolean;
      city: boolean;
      phoneNumber: boolean;
      firstName: boolean;
      lastName: boolean;
    };
    filters: {
      dateOfBirth?: {
        from?: string;
        to?: string;
      };
      address?: string;
      city?: string;
      active?: boolean;
      age?: string;
      email?: string;
      empId?: string;
      firstName?: string;
      lastName?: string;
      mobileNumber?: string;
      phoneNumber?: string;
      postalCode?: string;
      province?: string;
      employeeId?: string;
    };
    pagination?: {
      limit?: number;
      page?: number;
    };
  };

  type GroupingEmployeeRemunerationReport = {
    groupBy?: "NONE" | "EMPLOYEE" | "EMPLOYEE_GROUP";
  };
  type EmployeeRemunerationReportData = {
    annualSalary: string;
    classification: string;
    employee: string;
    employeeGroup: string;
    employeeBasis: string;
    payCalendar: string;
    standardUnit: string;
    startDate: string;
    terminationDate: string;
    unitRate: string;
  };
  type PayrollActivitySummaryReportData = {
    amount?: number;
    payItem?: string;
    payItemType?: string;
    units?: string | null;
  };
  type fetchGroupByEmployeeRemunerationReportData = {
    [groupBy: string]: Array<EmployeeRemunerationReportData>;
  };
  type fetchEmployeeRemunerationReportData = {
    data: Array<EmployeeRemunerationReportData>;
  };
  type fetchPayrollActivitySummaryReport = {
    data: Array<PayrollActivitySummaryReportData>;
  };

  type EmployeeRemunerationReport = {
    columns: {
      uniqueId: boolean;
      earnings: boolean;
      firstName: boolean;
      lastName: boolean;
      startDate: boolean;
      separationDate: boolean;
      empBasis: boolean;
      jobTitle: boolean;
      payrollCalendarName: boolean;
    };
    filters: {
      startDate?: {
        from?: string;
        to?: string;
      };
      earnings?: number;
      empBasis?: string;
      jobTitle?: string;
      payrollCalendarName?: string;
      separationDate?: {
        from?: string;
        to?: string;
      };
      active?: boolean;
      empId?: string;
    };
    pagination: {
      limit: number;
      page: number;
    };
  };
  type PayrollTransactionListingDetailReport = {
    columns: {
      amount: boolean;
      payItem: boolean;
      payItemType: boolean;
      date: boolean;
      employee: boolean;
      uniqueId: boolean;
    };
    filters: {
      dateRange?: {
        from?: string;
        to?: string;
      };
      payItem?: string;
      payItemType?: string;
      employeeId?: string;
      date?: {
        from?: string;
        to?: string;
      };
    };
    groupBy?: string;
  };
  type PayrollTransactionListingSummaryReport = {
    columns: {
      amount: boolean;
      payItem: boolean;
      payItemType: boolean;
    };
    filters: {
      dateRange?: {
        from?: string;
        to?: string;
      };
      payItem?: string;
      payItemType?: string;
      employeeId?: string;
    };
    pagination: {
      limit: 10;
      page: 1;
    };
    groupBy?: string;
  };
  type PayrollActivitySummaryReport = {
    columns: {
      amount: boolean;
      payItem: boolean;
      payItemType: boolean;
      units: boolean;
    };
    filters: {
      dateRange?: {
        from?: string;
        to?: string;
      };
      payItem?: string;
      payItemType?: string;
      employeeId?: string;
    };
    pagination: {
      limit: number;
      page: number;
    };
    groupBy?: string;
  };
  type GroupingLeaveBalanceReport = {
    groupBy?: "NONE" | "EMPLOYEE" | "EMPLOYEE_GROUP" | "LEAVE_TYPE";
  };
  type LeaveBalanceReportData = {
    amount: string;

    employee: string;
    employeeGroup: string;
    leaveType: string;
    units: string;
  };
  type fetchGroupByLeaveBalanceReportData = {
    [groupBy: string]: Array<LeaveBalanceReportData>;
  };
  type fetchLeaveBalanceData = {
    data: Array<LeaveBalanceReportData>;
  };

  type LeaveBalanceReport = {
    columns: {
      employee: boolean;
      leaveType: boolean;
      units: boolean;
      uniqueId: boolean;
      currentBalance: boolean;
    };
    filters: {
      date?: string;
      empId?: string;
      leaveType?: string;
      units?: {
        from?: number;
        to?: number;
      };
    };
    pagination: {
      limit: number;
      page: number;
    };
    groupBy: "EMPLOYEE";
  };
  type GroupingLeaveRequestReport = {
    groupBy?: "NONE" | "EMPLOYEE" | "EMPLOYEE_GROUP" | "LEAVE_TYPE";
  };
  type LeaveRequestReportData = {
    id: string;
    startDate: string;
    endDate: string;
    quantity: number;
    status: string;
    employee: {
      firstName: string;
      lastName: string;
    };
    leaveEntitlement: {
      id: string;
      payrollPayItemLeave: {
        name: string;
      };
    };
  };
  type fetchGroupByLeaveRequestReport = {
    [key: string]: any[];
  };
  type fetchLeaveRequestReport = {
    data: Array<LeaveRequestReportData>;
  };

  type LeaveRequestReport = {
    columns: {
      uniqueId: boolean;
      firstName: boolean;
      lastName: boolean;
      startDate: boolean;
      endDate: boolean;
      status: boolean;
      leaveType: boolean;
      quantity: boolean;
    };
    filters: {
      amount?: {
        from?: number;
        to?: number;
      };
      status?: string;
      leaveType?: string;
      quantity?: {
        from?: number;
        to?: number;
      };
      empId?: string;
      endDate?: string;
      startDate?: string;
    };
    pagination: {
      limit?: number;
      page?: number;
    };
    groupBy?: string;
  };
  type GroupingLeaveTransactionsReport = {
    groupBy?:
      | "NONE"
      | "DESCRIPTION"
      | "EMPLOYEE"
      | "EMPLOYEE_GROUP"
      | "LEAVE_TYPE";
  };
  type LeaveTransactionsReportData = {
    description: string;
    employee: string;
    employeeGroup: string;

    leaveType: string;
    unitType: string;
    units: string;
  };
  type fetchGroupByLeaveTransactionsReport = {
    [groupBy: string]: Array<LeaveTransactionsReportData>;
  };
  type fetchLeaveTransactionsReport = {
    data: Array<LeaveTransactionsReportData>;
  };

  type LeaveTransactionsReport = {
    columns: {
      description: boolean;
      employee: boolean;
      employeeGroup: boolean;

      leaveType: boolean;
      unitType: boolean;
      units: boolean;
    };
  };

  type PayrollEmployeeSummaryReportData = {
    totalearnings: number;
    totaldeductions: number;
    totaltax: number;
    totalreimbursements: number;
    totalretirements: number;
    netpay: number;
    firstname: string;
    lastname: string;
  };
  type fetchGroupByPayrollEmployeeSummaryReport = {
    [groupBy: string]: Array<PayrollEmployeeSummaryReportData>;
  };
  type fetchPayrollEmployeeSummaryReport = {
    data: Array<PayrollEmployeeSummaryReportData>;
  };
  type PayrollEmployeeSummaryReport = {
    columns: {
      totalDeductions: boolean;
      totalEarnings: boolean;
      employee: boolean;
      netPay: boolean;
      totalReimbursements: boolean;
      totalRetirements: boolean;
      totalTax: boolean;
      uniqueId: boolean;
    };
    filters?: {
      totalEarnings?: {
        from?: number;
        to?: number;
      };
      totalDeductions?: {
        from?: number;
        to?: number;
      };
      netPay?: {
        from?: number;
        to?: number;
      };
      totalReimbursements?: {
        from?: number;
        to?: number;
      };
      totalRetirements?: {
        from?: number;
        to?: number;
      };
      totalTax?: {
        from?: number;
        to?: number;
      };
      employeeId?: string;
      dateRange?: DateRange;
    };
    pagination?: {
      limit?: number;
      page?: number;
    };
  };

  type GroupingSuperannuationAccrualsReport = {
    groupBy?:
      | "NONE"
      | "CONTRIBUTION_TYPE"
      | "EMPLOYEE"
      | "EMPLOYEE_GROUP"
      | "EMPLOYEE_NUMBER"
      | "STATUS"
      | "SUPER_FUND_NAME"
      | "USI";
  };
  type SuperannuationAccrualsReportData = {
    amount: string;
    contributionType: string;
    dueDate: string;
    employee: string;

    employeeGroup: string;
    employeeNumber: string;
    paymentDate: string;
    status: string;
    superFundName: string;
    USI: string;
  };
  type fetchGroupBySuperannuationAccrualsReport = {
    [groupBy: string]: Array<SuperannuationAccrualsReportData>;
  };
  type fetchSuperannuationAccrualsReport = {
    data: Array<SuperannuationAccrualsReportData>;
  };

  type SuperannuationAccrualsReport = {
    columns: {
      amount: boolean;
      contributionType: boolean;
      dueDate: boolean;
      employee: boolean;

      employeeGroup: boolean;
      employeeNumber: boolean;
      paymentDate: boolean;
      status: boolean;
      superFundName: boolean;
      USI: boolean;
    };
  };
  type GroupingSuperannuationPaymentsReport = {
    groupBy?:
      | "NONE"
      | "CONTRIBUTION_TYPE"
      | "EMPLOYEE"
      | "REFERENCE"
      | "SUPER_FUND_NAME";
  };
  type SuperannuationPaymentsReportData = {
    amount: string;
    contributionType: string;
    dueDate: string;
    employee: string;

    paymentDate: string;
    reference: string;
    sentToFund: string;
    superFundName: string;
  };
  type fetchGroupBySuperannuationPaymentsReport = {
    [groupBy: string]: Array<SuperannuationPaymentsReportData>;
  };
  type fetchSuperannuationPaymentsReport = {
    data: Array<SuperannuationPaymentsReportData>;
  };

  type SuperannuationPaymentsReport = {
    columns: {
      amount: boolean;
      contributionType: boolean;
      dueDate: boolean;
      employee: boolean;

      paymentDate: boolean;
      reference: boolean;
      sentToFund: boolean;
      superFundName: boolean;
    };
  };
  type GroupingTimesheetDetailsReport = {
    groupBy?:
      | "NONE"
      | "CALENDAR"
      | "EARNING_RATE"
      | "EMPLOYEE"
      | "EMPLOYEE_GROUP"
      | "TIMESHEET_CATEGORY";
  };
  type TimesheetDetailsReportData = {
    calendar: string;
    earningRate: string;

    employee: string;
    employeeGroup: string;
    fri: string;
    mon: string;
    sat: string;
    sun: string;
    thu: string;
    timesheetCategory: string;
    total: string;
    tue: string;
    wed: string;
    weekEnding: string;
  };
  type fetchGroupByTimesheetDetailsReport = {
    [groupBy: string]: Array<TimesheetDetailsReportData>;
  };
  type fetchTimesheetDetailsReport = {
    data: Array<TimesheetDetailsReportData>;
  };

  type TimesheetDetailsReport = {
    columns: {
      calendar: boolean;
      earningRate: boolean;

      employee: boolean;
      employeeGroup: boolean;
      fri: boolean;
      mon: boolean;
      sat: boolean;
      sun: boolean;
      thu: boolean;
      timesheetCategory: boolean;
      total: boolean;
      tue: boolean;
      wed: boolean;
      weekEnding: boolean;
    };
  };
  type GroupingTimesheetSummaryReport = {
    groupBy?:
      | "NONE"
      | "EARNING_RATE"
      | "EMPLOYEE"
      | "EMPLOYEE_GROUP"
      | "TIMESHEET_CATEGORY";
  };
  type TimesheetSummaryReportData = {
    calendar: string;
    earningRate: string;

    employee: string;
    employeeGroup: string;
    fri: string;
    mon: string;
    sat: string;
    sun: string;
    thu: string;
    timesheetCategory: string;
    total: string;
    tue: string;
    wed: string;
    weekEnding: string;
  };
  type fetchGroupByTimesheetSummaryReport = {
    [groupBy: string]: Array<TimesheetSummaryReportData>;
  };
  type fetchTimesheetSummaryReport = {
    data: Array<TimesheetSummaryReportData>;
  };

  type TimesheetSummaryReport = {
    columns: {
      calendar: boolean;
      earningRate: boolean;

      employee: boolean;
      employeeGroup: boolean;

      timesheetCategory: boolean;
      units: boolean;
    };
  };
}

module EmployeesReport {
  const API_URL = "/employees";
  export function CreateEmployeeDetailsReport(
    data: EmployeesReport.EmployeesDetailsReport,
    success: (data: EmployeesReport.fetchReportData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/employees/employee-full-detail-report`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreateEmployeeDetailsReportExport(
    data: EmployeesReport.EmployeesDetailsReport,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/employees/employee-full-detail-report-export`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreateEmployeeContactDetailReport(
    data: EmployeesReport.EmployeesContactDetailReport,
    success: (data: EmployeesReport.fetchReportData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/employees/employee-contact-report`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreateEmployeeContactDetailReportExport(
    data: EmployeesReport.EmployeesContactDetailReport,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/employees/employee-contact-report-export`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreateEmployeeRemunerationReport(
    data: EmployeesReport.EmployeeRemunerationReport,
    success: (
      data: EmployeesReport.fetchEmployeeRemunerationReportData
    ) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/employee-employement-details/remuneration-report`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreateEmployeeRemunerationReportExport(
    data: EmployeesReport.EmployeeRemunerationReport,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/employee-employement-details/remuneration-report-export`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreatePayrollActivitySummaryReport(
    data: EmployeesReport.PayrollActivitySummaryReport,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/pay-run-items/payroll-activity-summary-report`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreatePayrollActivitySummaryReportExport(
    data: EmployeesReport.PayrollActivitySummaryReport,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/pay-run-items/payroll-activity-summary-report-export`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreatePayrollTransactionListingDetailReport(
    data: EmployeesReport.PayrollTransactionListingDetailReport,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/pay-run-items/transaction-listing-detail-report`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreatePayrollTransactionListingDetailReportExport(
    data: EmployeesReport.PayrollTransactionListingDetailReport,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/pay-run-items/transaction-listing-detail-report-export`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreatePayrollTransactionListingSummaryReport(
    data: EmployeesReport.PayrollTransactionListingSummaryReport,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/pay-run-items/transaction-listing-summary-report`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreatePayrollTransactionListingSummaryReportExport(
    data: EmployeesReport.PayrollTransactionListingSummaryReport,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/pay-run-items/transaction-listing-summary-report-export`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreatePayrollActivityDetailReport(
    data: EmployeesReport.PayrollActivityDetailReport,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/pay-run-items/payroll-activity-report`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreatePayrollActivityDetailReportExport(
    data: EmployeesReport.PayrollActivityDetailReport,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/pay-run-items/payroll-activity-report-export`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreateLeaveBalanceReport(
    data: EmployeesReport.LeaveBalanceReport,
    success: (data: EmployeesReport.fetchLeaveBalanceData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`employee-leave-entitlements/leave-balance-report`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreateLeaveBalanceReportExport(
    data: EmployeesReport.LeaveBalanceReport,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`employee-leave-entitlements/leave-balance-report-export`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreateLeaveRequestReport(
    data: EmployeesReport.LeaveRequestReport,
    success: (data: EmployeesReport.fetchLeaveRequestReport) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/employee-leaves/leave-requests-report`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreateLeaveRequestReportExport(
    data: EmployeesReport.LeaveRequestReport,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/employee-leaves/leave-requests-report-export`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreateLeaveTransactionsReport(
    data: EmployeesReport.LeaveTransactionsReport,
    success: (data: EmployeesReport.fetchLeaveTransactionsReport) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/report`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreatePayrollEmployeeSummaryReport(
    data: EmployeesReport.PayrollEmployeeSummaryReport,
    success: (data: EmployeesReport.fetchPayrollEmployeeSummaryReport) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/payslips/payroll-employee-summary`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreatePayrollEmployeeSummaryReportExport(
    data: EmployeesReport.PayrollEmployeeSummaryReport,
    success: (data: EmployeesReport.fetchPayrollEmployeeSummaryReport) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/payslips/payroll-employee-summary-export`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreateSuperannuationAccrualsReport(
    data: EmployeesReport.SuperannuationAccrualsReport,
    success: (data: EmployeesReport.fetchSuperannuationAccrualsReport) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/report`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreateSuperannuationPaymentsReport(
    data: EmployeesReport.SuperannuationPaymentsReport,
    success: (data: EmployeesReport.fetchSuperannuationPaymentsReport) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/report`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreateTimesheetSummaryReport(
    data: EmployeesReport.TimesheetSummaryReport,
    success: (data: EmployeesReport.fetchTimesheetSummaryReport) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/report`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CreateTimesheetDetailsReport(
    data: EmployeesReport.TimesheetDetailsReport,
    success: (data: EmployeesReport.fetchTimesheetDetailsReport) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/report`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}

export { EmployeesReport };
