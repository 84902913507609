import {
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import { RiArrowUpDownFill } from "react-icons/ri";

interface Props {
  setPersonalClaim: React.Dispatch<React.SetStateAction<boolean>>;
  setCompanyClaim: React.Dispatch<React.SetStateAction<boolean>>;
  setMileageClaimCheck: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ClaimFilters({
  setPersonalClaim,
  setCompanyClaim,
  setMileageClaimCheck,
}: Props) {
  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<RiArrowUpDownFill />}
        alignItems={"center"}
        verticalAlign={"top"}
        lineHeight={"1rem"}
        minH={"40px"}
        minW={"40px"}
        padding={"11px 16px"}
        bg={"transparent"}
        borderRadius={"3px"}
        color={"rgba(0,10,30,.65)"}
        _dark={{
          color: "customColor.dark.250",
        }}
        fontWeight={"700"}
        fontSize={"15px"}
        _hover={{
          bg: "rgba(0,10,30,.05)",
          color: "rgba(0,10,30,.75)",
        }}
        _active={{
          bg: "rgba(0,10,30,.05)",
          color: "rgba(0,10,30,.75)",
        }}
      >
        Filters
      </MenuButton>
      <MenuList>
        <Stack spacing={2} padding={2}>
          <MenuItem
            fontSize={"15px"}
            color={"customColor.black7"}
            closeOnSelect={false}
          >
            <Checkbox onChange={(e) => setPersonalClaim(e.target.checked)}>
              Expense Claim (Personal Money)
            </Checkbox>
          </MenuItem>
          <MenuItem
            fontSize={"15px"}
            color={"customColor.black7"}
            closeOnSelect={false}
          >
            <Checkbox onChange={(e) => setCompanyClaim(e.target.checked)}>
              Expense Claim (Company Money)
            </Checkbox>
          </MenuItem>
          <MenuItem
            fontSize={"15px"}
            color={"customColor.black7"}
            closeOnSelect={false}
          >
            <Checkbox onChange={(e) => setMileageClaimCheck(e.target.checked)}>
              Mileage Claim
            </Checkbox>
          </MenuItem>
        </Stack>
      </MenuList>
    </Menu>
  );
}
