import { Box, Stack, Text, VStack } from "@chakra-ui/react";
import { AiOutlinePlus } from "react-icons/ai";
import { RiArrowRightSFill } from "react-icons/ri";
import { TbSection } from "react-icons/tb";
import { useItemContext } from "../hooks/auditItemContext";
interface SidePopoverProps {}
export default function SidePopover({}: SidePopoverProps) {
  const { appendQuestion, appendSection } = useItemContext();
  return (
    <Stack
      direction="column"
      borderRadius="5px"
      backgroundColor="white"
      spacing={0}
      zIndex={5}
      shadow="2xl"
      border="1px solid"
      borderColor="borders.accent"
    >
      <Box position="absolute" top="40px" right="-15px">
        <RiArrowRightSFill color="white" size="32px" />
      </Box>

      <VStack
        spacing={0}
        padding={"12.6px 6.4px 9.6px 6.4px"}
        cursor="pointer"
        onClick={() => {
          appendQuestion();
        }}
      >
        <AiOutlinePlus color="green" cursor={"pointer"} size="18px" />
        <Text
          cursor={"pointer"}
          padding="4px 0px 0px 0px"
          fontSize="11px"
          fontWeight={400}
          color="icons.primary"
        >
          Question
        </Text>
      </VStack>
      <VStack
        spacing={0}
        padding={"0px 6.4px 9.6px 6.4px"}
        cursor="pointer"
        onClick={() => {
          appendSection();
        }}
      >
        <TbSection color="blue" cursor={"pointer"} size="24px" />
        <Text
          cursor={"pointer"}
          fontSize="11px"
          fontWeight={400}
          padding="4px 0px 0px 0px"
          color="icons.primary"
        >
          Section
        </Text>
      </VStack>
    </Stack>
  );
}
