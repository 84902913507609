import { axiosInstance } from "./axiosInstance-aud";
import { Template } from "./Template";
declare module ReportLayout {
  type Header = {
    addHeader: boolean;
    showPicture: boolean;
    image: string;
  };
  type Footer = {
    addFooter: boolean;
    image: string;
    inspectionTitle: boolean;
    additionalInfo: boolean;
    notes: string;
    showPicture: boolean;
  };
  type Layout = {
    coverPage: string;
    logo: string;
    flaggedItems: boolean;
    action: boolean;
    disclaimer: boolean;
    inspectionResults: boolean;
    mediaSummary: boolean;
    scores: boolean;
    instructions: boolean;
    unansweredQuestions: boolean;
    checkboxes: boolean;
    header: Header;
    footer: Footer;
    questionDivider: boolean;
    tableOfContents: boolean;
  };
  type ReportLayout = {
    id?: number;
    templateId: number;
    active: boolean;
    name: string;
    layout: Layout;
    template?: Template.Template;
    reportStructure?: Array<ReportStructureItem>;
    isLoading?: boolean;
    showReportStructure?: boolean;
  };

  type GetAllLayouts = {
    id: number;
    templateId: number;
    reportLayoutId: number;
    active: boolean;
    layout: {
      id: number;
      layout: Layout;
      name: string;
    };
  };
  type ReportStructureItem = {
    id: number;
    type: Template.TemplateItem["type"];
    pageId: number;
    sectionId?: number;
    questionId?: number;
    display: boolean;
    alignment?: string;
    children: Array<ReportStructureItem>;
    title: string;
    question?: Template.TemplateItem["question"];
    orderIndex?: number;
  };
}
module ReportLayout {
  const APi_Url = "/report-layouts";
  export function postReportLayout(
    data: ReportLayout,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${APi_Url}/custom/report-layout`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function getReportLayout(
    id: string,
    success: (data: ReportLayout) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${APi_Url}/template/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function updateReportLayout(
    id: string,
    data: Partial<ReportLayout>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${APi_Url}/custom/report-layout/${id}`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchAllByTemplateId(
    params: {
      templateId: number;
    },
    success: (data: Array<GetAllLayouts>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${APi_Url}/custom/report-layout`, {
        params,
      })
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchLayoutById(
    id: number,
    success: (data: ReportLayout) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${APi_Url}/custom/report-layout/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function ActiveReportLayout(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${APi_Url}/custom/active/report-layout/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function DeleteReportLayout(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${APi_Url}/custom/report-layout/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { ReportLayout };
