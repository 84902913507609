import {
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import { Controller, useFormContext } from "react-hook-form";
import { IoIosArrowDown } from "react-icons/io";
import { CustomInput } from "../../../Common/CustomInput";

export function IssueDate() {
  const form = useFormContext();
  return (
    <Stack>
      <HStack gap={0}>
        <Text
          fontSize="13px"
          display={"block"}
          fontWeight="600"
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.white",
          }}
        >
          Issue Date
        </Text>
      </HStack>

      <Box>
        <HStack gap={0}>
          <Box width={"100%"}>
            <Controller
              control={form.control}
              // name="filters.dateRange.from"
              name={"issueDate"}
              defaultValue={moment().format("YYYY-MM-DD")}
              rules={{
                onChange(event) {
                  if (event.target.value === "") {
                    form.setValue("issueDate", undefined);
                  }
                },
              }}
              render={({ field }) => (
                <CustomInput
                  input={{
                    h: "40px",
                    fontSize: ".845rem",
                    bg: "#fff",
                    variant: "outline",
                    type: "date",
                    _hover: {
                      border: "1px solid hsl(0, 0%, 70%)",
                    },
                    _focus: {
                      boxShadow: "0 0 0 1px #2684FF",
                      _hover: {
                        borderWidth: "1px",
                        borderColor: "#2684FF",
                        boxShadow: "0 0 0 1px #2684FF",
                      },
                    },
                    padding: "12px 15px",
                    borderLeftRadius: "4px",
                    max: "9999-12-31",
                    value: field.value,
                    onChange: field.onChange,
                  }}
                />
              )}
            />
          </Box>
          <Menu>
            <MenuButton
              as={IconButton}
              h={"40px"}
              _light={{
                bgColor: "customColor.white",
                color: "customColor.black7",
              }}
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              fontWeight={"400"}
              borderTop={"1px solid #e5e7eb"}
              borderRight={"1px solid #e5e7eb"}
              borderBottom={"1px solid #e5e7eb"}
              borderRightRadius={"4px"}
              _hover={{
                color: "customColor.black7",
                bg: "#f2f3f4",
                border: "1px solid hsl(0, 0%, 70%)",
              }}
              _active={{
                color: "customColor.black7",
                bg: "#f2f3f4",
                borderWidth: "1px",
                boxShadow: "0 0 0 1px #2684FF",
                _hover: {
                  borderWidth: "1px",
                  borderColor: "#2684FF",
                  boxShadow: "0 0 0 1px #2684FF",
                },
              }}
              icon={<IoIosArrowDown />}
            />

            <MenuList overflowY="auto" zIndex={"overlay"} minW={"300px"}>
              <MenuItem
                key={1}
                padding={"0px 12px"}
                _hover={{
                  backgroundColor: "rgba(0,10,30,.1)",
                }}
              >
                <HStack
                  justifyContent="space-between"
                  width="100%"
                  onClick={() => {
                    form.setValue("issueDate", moment().format("YYYY-MM-DD"));
                  }}
                >
                  <HStack alignItems="center">
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                    >
                      Today
                    </Text>
                  </HStack>

                  <HStack alignItems="center">
                    <Text
                      padding={"8px"}
                      margin={"0px 0px 0px 12px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                    >
                      {moment().format("Do MMM  YYYY")}
                    </Text>
                  </HStack>
                </HStack>
              </MenuItem>
              <MenuItem
                key={1}
                padding={"0px 12px"}
                _hover={{
                  backgroundColor: "rgba(0,10,30,.1)",
                }}
              >
                <HStack
                  justifyContent="space-between"
                  width="100%"
                  onClick={() => {
                    // Set the value of issueDate to tomorrow's date
                    form.setValue(
                      "issueDate",
                      moment().add(1, "days").format("YYYY-MM-DD")
                    );
                  }}
                >
                  <HStack alignItems="center">
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                    >
                      Tomorrow
                    </Text>
                  </HStack>

                  <HStack alignItems="center">
                    <Text
                      padding={"8px"}
                      margin={"0px 0px 0px 12px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                    >
                      {/* Display tomorrow's date */}
                      {moment().add(1, "days").format("Do MMM YYYY")}
                    </Text>
                  </HStack>
                </HStack>
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Box>
    </Stack>
  );
}
