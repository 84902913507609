import {
  FormControl,
  FormErrorMessage,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Inspection, Template } from "../../../modules/Audit";
import { useInspectionContext } from "../hooks/InspectionContext";
import { useAuditQuestionContainer } from "./AuditQuestionContainer";

export default function Number() {
  const template = useFormContext();
  const { format, question, getItemField, required } =
    useAuditQuestionContainer();

  const properties: Template.QuestionProperties = useWatch({
    control: template.control,
    name: getItemField("question.properties"),
  });

  const TemperatureMessage: {
    [key in Template.QuestionConditions]: string;
  } = {
    BETWEEN: `Temperature should be between ${properties.smaller}${properties.dUnit} and ${properties.greater}${properties.dUnit}`,
    GREATER_AND_EQUAL: `Temperature should be greater than or equal to ${properties.greater} ${properties.dUnit}`,
    LESS_AND_EQUAL: `Temperature should be less than or equal to ${properties.smaller} ${properties.dUnit}`,
  };
  const { setIsSaving } = useInspectionContext();
  return (
    <Stack>
      {format === "TEMP" && (
        <Text fontSize="sm">
          {TemperatureMessage[properties?.condition ?? "BETWEEN"]}
        </Text>
      )}
      <Controller
        name={getItemField("question.questionValue")}
        control={template.control}
        rules={{
          onBlur: (e) => {
            template.trigger(getItemField("question.questionValue"));

            const answerId = template.getValues(
              getItemField("question.answerId")
            );
            const type = template.getValues(getItemField("question.type"));
            const inspectionMetaID = template.getValues("inspection.id");
            const notes = template.getValues(getItemField("question.notes"));
            setIsSaving(true);
            Inspection.CachingInspection(
              answerId,
              {
                question_value: e.target.value,
                notes: notes,
                inspection_meta_id: inspectionMetaID,
                type: type,
              },
              () => {
                setIsSaving(false);
              },
              () => {
                setIsSaving(false);
              }
            );
          },
          required: {
            value: required,
            message: "This field is required",
          },

          validate: (value) => {
            if (format === "TEMP" && properties.smaller && properties.greater) {
              if (properties?.condition === "BETWEEN") {
                if (
                  value < +properties.smaller ||
                  value > +properties.greater
                ) {
                  return TemperatureMessage[properties.condition];
                }
              } else if (properties?.condition === "GREATER_AND_EQUAL") {
                if (value < +properties.greater) {
                  return TemperatureMessage[properties.condition];
                }
              } else if (properties?.condition === "LESS_AND_EQUAL") {
                if (value > +properties.smaller) {
                  return TemperatureMessage[properties.condition];
                }
              }
            }
            return false;
          },
        }}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl isInvalid={(error?.message?.length ?? 0) > 0}>
              <Input
                {...field}
                w={{ xs: "100%", md: "50%" }}
                type="number"
                border="1px solid"
                borderColor="borders.accent"
                fontSize="16px"
                fontWeight={400}
                color="textcolors.primary"
                padding="8px 16px"
                _focusVisible={{
                  boxShadow: "none",
                  border: "1px sloid",
                  borderColor: "primary.500",
                }}
              />
              <FormErrorMessage>{error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </Stack>
  );
}
