import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";

import { Controller, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../../Common/CustomInput";
import { PeriodicalEvents } from "../../../../modules/Audit/PeriodicalEvents";

interface EventsModalProps {
  isOpen: boolean;
  onClose: () => void;
  setUpdate: (timestamp: number) => void;
}
export default function EventsModal({
  isOpen,
  onClose,
  setUpdate,
}: EventsModalProps) {
  const form = useFormContext<PeriodicalEvents.AddPeriodicalEvents>();
  const toast = useToast();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Events</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Controller
              control={form.control}
              name="name"
              rules={{
                required: "Event title is required",
              }}
              render={({ field, fieldState }) => (
                <CustomInput
                  input={{
                    ...field,
                    variant: "outline",
                    isInvalid: (fieldState.error?.message?.length ?? 0) > 0,
                    value: field.value,
                    type: "text",
                  }}
                  label="Event title"
                  withValidation
                  validationMessage={fieldState.error?.message}
                />
              )}
            />
            <Controller
              control={form.control}
              name="code"
              render={({ field, fieldState }) => (
                <CustomInput
                  input={{
                    ...field,
                    variant: "outline",
                    // isInvalid: (fieldState.error?.message?.length ?? 0) > 0,
                    // value: field.value,
                    type: "text",
                  }}
                  label="Event Code (Optional)"
                  //   withValidation
                  //   validationMessage={fieldState.error?.message}
                />
              )}
            />

            <Stack justifyContent="space-between" mt="8px">
              <Text fontSize="13px" fontWeight={500} color="#373857">
                Event Description(Optional)
              </Text>
              <Box>
                <Controller
                  control={form.control}
                  name="description"
                  render={({ field, fieldState }) => (
                    <Textarea
                      {...field}
                      placeholder="Add event description"
                      isInvalid={fieldState.invalid}
                    />
                  )}
                />
              </Box>
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button borderRadius="5px" variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            borderRadius="5px"
            onClick={form.handleSubmit((data) => {
              if (form.getValues("id")) {
                PeriodicalEvents.UpdatePeriodicalEvents(
                  form.getValues("id")!,
                  data,
                  () => {
                    toast({
                      title: "Success",
                      description: "Event updated successfully",
                      status: "success",
                    });
                    setUpdate(Date.now());
                    onClose();
                  },
                  (error) => {
                    toast({
                      title: "Error",
                      description: error,
                      status: "error",
                    });
                  }
                );
              } else {
                PeriodicalEvents.AddPeriodicalEvents(
                  data,
                  () => {
                    toast({
                      title: "Success",
                      description: "Event added successfully",
                      status: "success",
                    });
                    setUpdate(Date.now());
                    onClose();
                  },
                  (error) => {
                    toast({
                      title: "Error",
                      description: error,
                      status: "error",
                    });
                  }
                );
              }
            })}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
