import {Button} from '@chakra-ui/react';
import {FiChevronLeft, FiChevronRight} from 'react-icons/fi';
interface NavigationButtonProps {
  direction: 'next' | 'previous' | 'submit' | 'draft';
  onClick: () => void;
  children: React.ReactNode;
  isLoading?: boolean;
}
export default function NavigationButton({
  direction,
  onClick,
  children,
  isLoading,
}: NavigationButtonProps) {
  const icon =
    direction === 'next' ? (
      <FiChevronRight color="white" size={'17px'} />
    ) : direction === 'previous' ? (
      <FiChevronLeft color="white" size={'17px'} />
    ) : (
      <FiChevronLeft color="white" size={'17px'} />
    );
  return (
    <Button
      isLoading={isLoading}
      h="41.6px"
      padding="12px 20px"
      iconSpacing={'2px'}
      rightIcon={direction === 'next' ? icon : undefined}
      leftIcon={direction === 'previous' ? icon : undefined}
      border="none"
      borderRadius="8px"
      fontWeight={500}
      fontSize="14px"
      colorScheme={direction === 'draft' ? 'green' : 'primary'}
      onClick={onClick}>
      {children}
    </Button>
  );
}
