import { Button, Container, Stack, Text } from "@chakra-ui/react";
import { FaCheck } from "react-icons/fa";

interface CompleteImportPros {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}
export function CompleteContact({
  setActiveStep,
  activeStep,
}: CompleteImportPros) {
  return (
    <Container maxW={"560px"} padding={"0px 12px"}>
      <Text
        margin={"21px 0px"}
        _light={{
          color: "customColor.black7",
        }}
        fontSize={"21px"}
        fontWeight={"700"}
      >
        Import summary
      </Text>
      <Text
        margin={"15px 0px"}
        _light={{
          color: "customColor.black7",
        }}
        fontSize={"15px"}
        fontWeight={"700"}
      >
        15 of 15 items will be imported:
      </Text>
      <Stack direction={"row"} alignItems={"center"} paddingLeft={"8px"}>
        <FaCheck color="green" fontSize={"13px"} />
        <Text
          _light={{
            color: "customColor.black7",
          }}
          fontSize={"15px"}
          fontWeight={"400"}
        >
          15 items will be added or updated
        </Text>
      </Stack>

      <Stack
        paddingTop={"20px"}
        direction={"row"}
        paddingBottom={"10px"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Button
          backgroundColor={"#fff"}
          borderRadius={"3px"}
          color={"#ea4d4d"}
          border={"1px solid #dcdee4"}
          fontSize={"15px"}
          h={"40px"}
          variant={"solid"}
          padding={"12px 16px"}
          fontWeight={700}
          _hover={{
            backgroundColor: "#fff",
          }}
          onClick={() => setActiveStep(activeStep - 1)}
        >
          Back
        </Button>
        <Button
          fontSize={"15px"}
          bgColor="#3454D1"
          h={"40px"}
          borderRadius={"3px"}
          borderWidth={"1px"}
          borderColor={"#3454d1!important"}
          variant={"solid"}
          padding={"12px 16px"}
          fontWeight={700}
          _hover={{
            bgColor: "#3454D1",
          }}
        >
          Complete Import
        </Button>
      </Stack>
    </Container>
  );
}
