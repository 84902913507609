import { Box, HStack, Icon, Stack, Text, useToast } from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { Controller, useWatch } from "react-hook-form";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { CustomInput } from "../../../../Common/CustomInput";
import { PayItemRetirement } from "../../../Api/PayItemRetirement";
import { PayTemp } from "../../../Api/PayTemp";

interface RetirementProps {
  form?: any;
  retirementFieldArray?: any;
  currentEmployeeId?: string | undefined;
  retirementLine?: any;
  accountRetirement?: PayItemRetirement.FetchRetirementFund[];
  setAccountRetirement?: (accountRetirement: any) => void;
  setResetData?: (resetData: any) => void;
}
export function Retirement({
  form,
  retirementFieldArray,
  accountRetirement,
  retirementLine,
  currentEmployeeId,
  setAccountRetirement,
  setResetData,
}: RetirementProps) {
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  const toast = useToast();
  useEffect(() => {
    PayItemRetirement.FetchRetirementFund(
      (res) => {
        if (setAccountRetirement) {
          setAccountRetirement(res);
        }
      },
      (err) => {
        toast({
          title: "Error",
          description: err,
          status: "error",
        });
      }
    );
  }, []);
  const earnings = useWatch({
    control: form.control,
    name: "earnings",
  });
  const [socialSecurityAmounts, setSocialSecurityAmounts] = useState<{
    [key: string]: number;
  }>({});
  const socialSecurityRequired = useWatch({
    control: form.control,
    name: "isSocialSecurityRequired",
  });
  useEffect(() => {
    // Step 1: Calculate total amount for each Social Security object
    const newSocialSecurityAmounts = retirementFieldArray.fields?.reduce(
      (acc: any, socialSecurity: any) => {
        if (socialSecurity.calculationType === "SOCIAL_SECURITY") {
          const earningIds = socialSecurity?.payItemRetirement?.earningPayItems;

          // Step 2: Filter relevant earnings
          const filteredEarnings =
            earnings?.filter((e: any) =>
              earningIds?.includes(e.payItemEarningId)
            ) || [];

          // Step 3: Calculate the total amount for relevant earning pay items
          const totalEarnings = filteredEarnings.reduce(
            (acc: any, e: any) => acc + (e.amount || e.earningFixedAmount || 0),
            0
          );

          // Check if total earnings are below the minimum wage
          if (totalEarnings < socialSecurity?.payItemRetirement?.minimumWage) {
            acc[socialSecurity.payItemRetirementId] =
              totalEarnings * (socialSecurity.percentage / 100);
          } else {
            if (socialSecurityRequired) {
              acc[socialSecurity.payItemRetirementId] =
                (socialSecurity?.payItemRetirement?.minimumWage ?? 0) *
                ((socialSecurity.percentage ?? 0) / 100);
            } else {
              acc[socialSecurity.payItemRetirementId] = 0;
            }
          }
        }
        return acc;
      },
      {}
    );
    console.log("newSocialSecurityAmounts", newSocialSecurityAmounts);
    setSocialSecurityAmounts(newSocialSecurityAmounts);
  }, [earnings, retirementFieldArray.fields]);
  useEffect(() => {
    if (!retirementFieldArray.fields) return;

    retirementFieldArray.fields?.forEach((socialSecurity: any, i: any) => {
      if (socialSecurity.calculationType === "SOCIAL_SECURITY") {
        // Calculate total earnings for relevant earning items
        const earningIds = socialSecurity?.payItemRetirement?.earningPayItems;
        const filteredEarnings =
          earnings?.filter((e: any) =>
            earningIds?.includes(e.payItemEarningId)
          ) || [];
        console.log("filteredEarnings", filteredEarnings);
        const totalEarnings = filteredEarnings.reduce(
          (acc: any, e: any) => acc + (e.amount || e.earningFixedAmount || 0),
          0
        );

        // Determine the minimum wage based on calculated earnings
        let calculatedMinimumWage =
          socialSecurity?.payItemRetirement?.minimumWage;
        console.log("earnings", earnings);
        console.log("retirementFieldArray.fields", retirementFieldArray.fields);
        console.log("totalEarnings", totalEarnings);
        if (totalEarnings < socialSecurity?.payItemRetirement?.minimumWage) {
          calculatedMinimumWage = totalEarnings;
        }
        console.log("calculatedMinimumWage", calculatedMinimumWage);
        form.setValue(`retirements.${i}.minimumWage`, calculatedMinimumWage);

        form.setValue(
          `retirements.${i}.amount`,
          socialSecurityAmounts[socialSecurity.payItemRetirementId] || 0
        );
      }
    });
  }, [earnings, retirementFieldArray.fields, socialSecurityAmounts]);
  // useEffect(() => {
  //   retirementFieldArray.fields?.forEach((socialSecurity: any, i: number) => {
  //     if (socialSecurity?.calculationType === "SOCIAL_SECURITY") {
  //       let minimumWage = socialSecurity.minimumWage;
  //       form.setValue(
  //         `retirements.${i}.amount`,
  //         socialSecurityAmounts[socialSecurity.payItemRetirementId] || 0
  //       );
  //       if (
  //         socialSecurityAmounts[socialSecurity.payItemRetirementId] <
  //         socialSecurity.minimumWage
  //       ) {
  //         minimumWage =
  //           socialSecurityAmounts[socialSecurity.payItemRetirementId];
  //       } else {
  //         minimumWage = socialSecurity.minimumWage;
  //       }
  //       form.setValue(`retirements.${i}.minimumWage`, minimumWage);
  //     }
  //   });
  // }, [socialSecurityAmounts]);
  return (
    <Box gap={0}>
      <Text
        fontSize={"12px "}
        margin={"0px 0px 4px"}
        _light={{
          color: "customColor.black7",
        }}
        _dark={{
          color: "customColor.dark.250",
        }}
        fontWeight={"700"}
      >
        Statuary Benefit (Employer Contribution)
      </Text>
      {retirementFieldArray?.fields.map(
        (item: PayTemp.RetirementRate & { uniqueId: string }, i: any) => (
          <Stack direction="row" alignItems={"end"} key={item.uniqueId}>
            <Stack flex={1}>
              <Text
                color={"primary.950"}
                padding={"6px 0px 0px"}
                fontSize={"12px"}
                borderTop={"1px solid #CCC !important"}
                borderBottom={"1px solid #E8EAEC !important"}
                marginBottom={"5px"}
                _hover={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (decodedToken.accessLevel !== "EMPLOYEE") {
                    retirementLine.onOpen();
                  }

                  if (setResetData) {
                    setResetData({ ...item, index: i });
                  }
                }}
              >
                {(accountRetirement &&
                  accountRetirement.find(
                    (accountRetirement) =>
                      accountRetirement.id === item.payItemRetirementId
                  )?.name) ??
                  ""}
                {/* {form.getValues(`reimbursments.${i}.description`) && ": "}
                {form.getValues(`reimbursments.${i}.description`)} */}
              </Text>
            </Stack>

            {form.getValues(`retirements.${i}.calculationType`) ===
              "EMPLOYER_PROVIDENT_FUND" && (
              <Stack alignItems={"flex-end"}>
                <HStack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Percentage is required",
                      },
                    }}
                    control={form.control}
                    name={`retirements.${i}.percentage`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          bg: "#fff",
                          isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                          type: "number",
                          onChange: (e) => {
                            field.onChange(Number(e.target.valueAsNumber));
                          },

                          maxW: "91.33px",
                          _light: {
                            color: "customColor.black7",
                          },
                          height: "26.33px",

                          padding: "12px 15px",
                          boxShadow: "none",
                          placeholder: "",
                        }}
                        label="Percentage "
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                </HStack>
              </Stack>
            )}
            {form.getValues(`retirements.${i}.calculationType`) ===
              "GRATUITY" && (
              <Stack alignItems={"flex-end"}>
                <HStack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Percentage is required",
                      },
                    }}
                    control={form.control}
                    name={`retirements.${i}.percentage`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          bg: "#fff",
                          isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                          type: "number",
                          onChange: (e) => {
                            field.onChange(Number(e.target.valueAsNumber));
                          },

                          maxW: "91.33px",
                          height: "26.33px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          boxShadow: "none",
                          placeholder: "",
                        }}
                        label="Percentage "
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                </HStack>
              </Stack>
            )}
            {form.getValues(`retirements.${i}.calculationType`) ===
              "SOCIAL_SECURITY" && (
              <Stack alignItems={"flex-end"}>
                <HStack>
                  {/* <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Percentage is required",
                      },
                    }}
                    control={form.control}
                    name={`retirements.${i}.percentage`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          bg: "#fff",
                          isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                          type: "number",
                          onChange: (e) => {
                            field.onChange(Number(e.target.valueAsNumber));
                          },

                          maxW: "91.33px",
                          height: "26.33px",
                          padding: "12px 15px",
                          boxShadow: "none",
                          _light: {
                            color: "customColor.black7",
                          },
                          placeholder: "",
                        }}
                        label="Percentage "
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  /> */}
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Minimum Wage is required",
                      },
                    }}
                    control={form.control}
                    name={`retirements.${i}.minimumWage`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          bg: "#fff",
                          type: "text",
                          value: field.value
                            ? Number(field.value).toLocaleString()
                            : "",
                          onChange: (e: any) => {
                            const value = e.target.value.replace(/,/g, "");
                            field.onChange(Number(value));
                          },

                          maxW: "91.33px",
                          height: "26.33px",
                          padding: "12px 15px",
                          boxShadow: "none",
                          isDisabled: true,
                          _light: {
                            color: "customColor.black7",
                          },
                          placeholder: "",
                        }}
                        label="Minimum Wage "
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Amount is required",
                      },
                    }}
                    control={form.control}
                    name={`retirements.${i}.amount`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          bg: "#fff",
                          type: "text",

                          maxW: "91.33px",
                          height: "26.33px",
                          padding: "12px 15px",
                          boxShadow: "none",
                          isDisabled: true,
                          _light: {
                            color: "customColor.black7",
                          },
                          placeholder: "",
                        }}
                        label="Amount"
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                </HStack>
              </Stack>
            )}
            {form.getValues(`retirements.${i}.calculationType`) ===
              "EMPLOYER_EOBI" && (
              <Stack alignItems={"flex-end"}>
                <HStack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Percentage is required",
                      },
                    }}
                    control={form.control}
                    name={`retirements.${i}.percentage`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          bg: "#fff",
                          type: "number",
                          onChange: (e) => {
                            field.onChange(Number(e.target.valueAsNumber));
                          },
                          isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                          maxW: "91.33px",
                          height: "26.33px",
                          _light: {
                            color: "customColor.black7",
                          },
                          padding: "12px 15px",
                          boxShadow: "none",
                          placeholder: "",
                        }}
                        label="Percentage "
                        labelProps={{
                          fontSize: "12px",

                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Minimum Wage is required",
                      },
                    }}
                    control={form.control}
                    name={`retirements.${i}.minimumWage`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          bg: "#fff",
                          type: "text",
                          value: field.value
                            ? Number(field.value).toLocaleString()
                            : "",
                          onChange: (e: any) => {
                            const value = e.target.value.replace(/,/g, "");
                            field.onChange(Number(value));
                          },

                          maxW: "91.33px",
                          height: "26.33px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          boxShadow: "none",
                          isDisabled: true,
                          placeholder: "",
                        }}
                        label="Minimum Wage "
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                  <CustomInput
                    withValidation
                    input={{
                      fontSize: "12px",
                      variant: "outline",
                      bg: "#fff",
                      type: "text",
                      value: Number(
                        (form.watch(`retirements.${i}.minimumWage`) *
                          form.watch(`retirements.${i}.percentage`)) /
                          100
                      ).toLocaleString(),
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",

                      isDisabled: true,
                      maxW: "91.33px",
                      _light: {
                        color: "customColor.black7",
                      },
                      height: "26.33px",
                      padding: "12px 15px",
                      boxShadow: "none",
                      placeholder: "",
                      _hover: {
                        borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                      },
                    }}
                    label="Amount"
                    labelProps={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      _light: {
                        color: "customColor.black7",
                      },
                      margin: "0px 0px 4px",
                    }}
                  />
                </HStack>
              </Stack>
            )}
            {decodedToken.accessLevel !== "EMPLOYEE" && (
              <Stack>
                <Icon
                  fontSize={"20px"}
                  as={IoIosCloseCircleOutline}
                  marginTop={"15px"}
                  color={"grey"}
                  _hover={{
                    color: "red.500",
                  }}
                  onClick={() => retirementFieldArray.remove(i)}
                />
              </Stack>
            )}
          </Stack>
        )
      )}
    </Box>
  );
}
