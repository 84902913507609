import {
  Button,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { IoMdInformationCircle } from "react-icons/io";
import { CustomInput } from "../../../../../../Common/CustomInput";

interface adduserprops {
  isOpen: boolean;
  onClose: () => void;
}
export default function AddUser({ isOpen, onClose }: adduserprops) {
  //   const {isOpen, onOpen, onClose} = useDisclosure();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Text
              flex={1}
              fontSize="21px"
              fontWeight={500}
              lineHeight={1.75}
              color="textcolors.primary"
            >
              Add a user to your team
            </Text>
            <IconButton
              aria-label="close"
              backgroundColor="transparent"
              borderRadius="50%"
              onClick={onClose}
              _hover={{ backgroundColor: "transparent" }}
              _active={{ backgroundColor: "transparent" }}
              _focus={{ backgroundColor: "transparent" }}
              icon={<AiOutlineClose size={"24px"} color="#545F70" />}
            />
          </HStack>
        </ModalHeader>
        <ModalBody>
          <CustomInput
            input={{
              variant: "outline",

              type: "text",
            }}
            label="Email"
          />
          <HStack mt="8px">
            <CustomInput
              input={{
                variant: "outline",

                type: "text",
              }}
              label="First Name"
            />
            <CustomInput
              input={{
                variant: "outline",

                type: "text",
              }}
              label="Last Name"
            />
          </HStack>
          <Flex
            w="100%"
            mt="24px"
            fontSize="14px"
            backgroundColor="primary.50"
            px="16px"
            py="8px"
            borderRadius="5px"
            border="1px"
            borderColor="primary.500"
          >
            <IoMdInformationCircle size={16} color="#1da5d4" />
            <HStack w="100%">
              <Text
                color="primary.500"
                fontSize="14px"
                fontWeight={500}
                ml="8px"
                lineHeight="16px"
              >
                Free seats available:
              </Text>
              <Text
                color="primary.500"
                fontSize="14px"
                fontWeight={500}
                ml="4px"
                lineHeight="16px"
              >
                8
              </Text>
            </HStack>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button
              h="40px"
              borderRadius="5px"
              fontSize="14px"
              fontWeight={500}
              variant="outline"
              padding={"12px 20px"}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              h="40px"
              w={"auto"}
              borderRadius="5px"
              fontSize="14px"
              fontWeight={500}
              leftIcon={<AiOutlinePlus />}
              marginRight="16px"
              padding={"12px 20px"}
            >
              Add to team
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
