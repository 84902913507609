import {
  Checkbox,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Fragment } from "react";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { FiChevronLeft } from "react-icons/fi";
import AddUser from "../Modals/AddUser";
interface Userprops {
  onBack?: () => void;
  options: Array<{ label: string; value?: any }>;
}
export default function User({ onBack, options }: Userprops) {
  const adduserhook = useDisclosure();
  return (
    <Fragment>
      <Stack p="10px">
        {onBack && (
          <HStack
            textAlign="center"
            lineHeight="16px"
            p="6px"
            borderBottom="1px"
            borderColor="backgrounds.primary"
          >
            <HStack spacing={1} onClick={onBack}>
              <FiChevronLeft size={14} color="#1da5d4" />
              <Text fontSize="14px" fontWeight={400} color="textcolors.primary">
                Back
              </Text>
            </HStack>
            <Text flex={1} fontSize="14px" fontWeight={500} color="primary.500">
              Select User
            </Text>
          </HStack>
        )}
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<AiOutlineSearch color="#545f70" size="18px" />}
          />
          <Input
            w="100%"
            placeholder="Search"
            border="1px solid"
            borderColor="borders.accent"
            borderRadius="4px"
            fontSize="16px"
            fontWeight={400}
            color="textcolors.primary"
            py="8px"
            pl="32px"
            _focusVisible={{
              boxShadow: "none",
              border: "1px sloid",
              borderColor: "primary.500",
            }}
          ></Input>
        </InputGroup>
        <Stack pt="8px" spacing={0}>
          <HStack p="8px" onClick={adduserhook.onOpen}>
            <AiOutlinePlus size={16} color="#1da5d4" />
            <Text
              flex={1}
              fontSize="14px"
              fontWeight={400}
              lineHeight="16px"
              color="primary.500"
              _hover={{ textDecoration: "underline" }}
            >
              Add user to team
            </Text>
          </HStack>
          <AddUser isOpen={adduserhook.isOpen} onClose={adduserhook.onClose} />
        </Stack>
        <Stack spacing={0}>
          <Text
            pt="16px"
            pb="8px"
            fontSize="13px"
            fontWeight={400}
            color="icons.primary"
            lineHeight="16px"
          >
            SELECTED ITEMS
          </Text>
          <Checkbox
            lineHeight="20px"
            p="8px"
            fontSize="14px"
            fontWeight={400}
            color="textcolors.primary"
            _hover={{ backgroundColor: "primary.50" }}
          >
            QSComply
          </Checkbox>
        </Stack>
        <Stack pb="8px" spacing={0}>
          <Text
            pt="16px"
            pb="8px"
            fontSize="13px"
            fontWeight={400}
            color="icons.primary"
            lineHeight="16px"
          >
            Users
          </Text>

          {options.map((option, i) => {
            return (
              <Checkbox
                key={i}
                lineHeight="20px"
                p="8px"
                fontSize="14px"
                fontWeight={400}
                color="textcolors.primary"
                _hover={{ backgroundColor: "primary.50" }}
                value={option.value}
              >
                {option.label}
              </Checkbox>
            );
          })}
        </Stack>
      </Stack>
    </Fragment>
  );
}
