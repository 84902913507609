import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  TabList,
  Tabs,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiOutlineCaretDown } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { BusinessTaxRate } from "../../Api/BusinessTaxRate";
import { ChartOfAccounts } from "../../Api/ChartOfAccounts";
import { ContactApi } from "../../Api/Contact";
import { Address } from "./Components/Address";
import { ContactDetail } from "./Components/ContactDetail";
import { FinancialDetails } from "./Components/FinancialDetails";
import { SalesDetails } from "./Components/SalesDefault";
import { PurchaseDetails } from "./PurchaseDefault";

export function CreateContact() {
  const navigate = useNavigate();
  const isMediumScreen = useBreakpointValue({ base: false, md: true });

  const [isLoading, setIsLoading] = React.useState(false);
  const tabCategories = [
    {
      label: "Contact Details",
      index: 0,
      flexValue: 1,
    },
    {
      label: "Address",
      index: 1,
      flexValue: 1,
    },
    {
      label: "Financial Details",
      index: 2,
      flexValue: 1,
    },
    {
      label: "Sales defaults",
      index: 3,
      flexValue: 1,
    },
    {
      label: "Purchase defaults",
      index: 4,
      flexValue: 1,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(0);

  // Create refs for each section
  const contactDetailsRef = useRef<HTMLDivElement>(null);
  const addressRef = useRef<HTMLDivElement>(null);
  const financialDetailsRef = useRef<HTMLDivElement>(null);
  const salesDefaultsRef = useRef<HTMLDivElement>(null);
  const purchaseDetailsRef = useRef<HTMLDivElement>(null);

  const refs = [
    contactDetailsRef,
    addressRef,
    financialDetailsRef,
    salesDefaultsRef,
    purchaseDetailsRef,
  ];
  const form = useForm<ContactApi.AddContact>({
    defaultValues: {
      name: undefined,
      isActive: true,
      accountNumber: undefined,
      primaryPerson: {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
      },
      // additionalPersons: [
      //   {
      //     firstName: undefined,
      //     lastName: undefined,
      //     email: undefined,
      //   },
      // ],
      businessInformation: {
        phoneCountry: undefined,
        phoneArea: undefined,
        phoneNumber: undefined,
        website: undefined,
        ABN: undefined,
        ACN: undefined,
        notes: undefined,
      },
      deliveryAddress: {
        attention: undefined,
        addresss: undefined,
        city: undefined,
        state: undefined,
        zipCode: undefined,
        country: undefined,
      },
      billingAddress: {
        attention: undefined,
        addresss: undefined,
        city: undefined,
        state: undefined,
        zipCode: undefined,
        country: undefined,
      },
      financialDetails: {
        bankAccountName: undefined,
        BSBCode: undefined,
        bankAccountNumber: undefined,
        reference: undefined,
        currency: undefined,
      },
      salesDefaults: {
        salesAccountId: undefined,
        invoiceDueDay: undefined,
        invoiceDueDateOption: undefined,
        saleGST: undefined,
        amountType: undefined,
        region: undefined,
        discount: undefined,
        creditLimitAmount: undefined,
        blockNewInvoiceWhenLimitReached: undefined,
      },
      purchaseDefaults: {
        purchaseAccountId: undefined,
        billDueDay: undefined,
        billDueDateOption: undefined,
        purchaseGST: undefined,
        amountType: undefined,
        region: undefined,
      },
    },
  });
  let location = useLocation();
  const id = location.state.contactId;

  useEffect(() => {
    if (!id) return;

    setIsLoading(true);

    ContactApi.FetchContactDetailsById(
      id,
      (data) => {
        form.reset(data);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }, [id]);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
    refs[index].current?.scrollIntoView({ behavior: "smooth" });
  };
  const [taxRateData, setTaxRateData] = useState<
    BusinessTaxRate.FetchTaxRate[]
  >([]);
  const [chartOfAccounts, setChartOfAccounts] = useState<
    Array<ChartOfAccounts.FetchChartOfAccounts>
  >([]);
  useEffect(() => {
    ChartOfAccounts.GetAllAccounts(
      {
        category: "",

        page: 1,
        limit: 200,
      },
      (res) => {
        setChartOfAccounts(res.data);
      },
      (error) => {}
    );
    BusinessTaxRate.GetTaxRates(
      (data) => {
        setTaxRateData(data);
      },
      (error) => {}
    );
  }, []);
  return (
    <FormProvider {...form}>
      <Stack
        gap={0}
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
        h={"calc(100vh - 70px)"}
        overflowY="auto"
      >
        <Stack
          h={"60px"}
          justifyContent={"center"}
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          borderBottom="1px solid #e5e7eb"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          gap={0}
        >
          <Container maxW={"container.xl"} p={{ xs: "16px", lg: "16px 50px" }}>
            <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
              <BreadcrumbItem>
                <BreadcrumbLink
                  href="AllContact"
                  color={"#3454d1"}
                  fontSize={"13px"}
                >
                  Contact
                </BreadcrumbLink>
              </BreadcrumbItem>

              <BreadcrumbItem></BreadcrumbItem>
            </Breadcrumb>
            <Heading
              fontSize="17px"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{ color: "customColor.white" }}
              fontWeight="bold"
            >
              Add Contact
            </Heading>
          </Container>
        </Stack>
        <Container maxW={"container.lg"} padding={"25px"}>
          <Stack
            marginBottom={"20px"}
            gap={0}
            borderRadius="8px"
            boxShadow={isMediumScreen ? "0 2px 4px rgba(15,34,58,.12)" : 0}
            border={isMediumScreen ? "1px solid #e5e7eb" : 0}
            p={"0px"}
          >
            <Stack
              borderTopRadius={"10px"}
              borderRadius="8px"
              borderTop={isMediumScreen ? "1px solid #ddd" : 0}
              borderLeft={isMediumScreen ? "1px solid #ddd" : 0}
              borderRight={isMediumScreen ? "1px solid #ddd" : 0}
              transition={"all .3s ease"}
            >
              {isMediumScreen ? (
                <Tabs
                  index={selectedTab}
                  orientation="horizontal"
                  _light={{
                    bgColor: "customColor.white",
                  }}
                  borderRadius="4px"
                  onChange={handleTabChange}
                  isLazy
                >
                  <TabList>
                    {tabCategories.map((tab, i) => (
                      <Tab
                        key={i}
                        flex={tab.flexValue}
                        borderRight={
                          tab.index !== 5 ? "1px solid #ddd" : "none"
                        }
                        fontSize={"13.44px"}
                        padding={"20px 15px"}
                        fontWeight={"600"}
                        _light={{ color: "#283c50" }}
                        _selected={{
                          _light: { color: "#3454d1" },
                          fontWeight: 600,
                          bg: "rgba(52,84,209,.075)",
                          borderBottom: "3px solid #3454d1",
                        }}
                        _hover={{
                          _light: { color: "#3454d1" },
                          fontWeight: 600,
                        }}
                      >
                        {tab.label}
                      </Tab>
                    ))}
                  </TabList>
                </Tabs>
              ) : (
                <Box alignContent={"flex-end"} justifyContent={"flex-end"}>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<AiOutlineCaretDown />}
                      backgroundColor={"#fff"}
                      borderRadius={"3px"}
                      justifyContent="flex-end"
                      border={"1px solid #dcdee4"}
                      fontSize={"13px"}
                      h={"40px"}
                      minW={"170px"}
                      variant={"solid"}
                      textColor={"customColor.black7"}
                      padding={"12px 16px"}
                      fontWeight={600}
                      _hover={{
                        color: "primary.950",
                        backgroundColor: "#fff",
                      }}
                      cursor={"pointer"}
                      _active={{
                        backgroundColor: "#fff",
                        color: "primary.950",
                      }}
                    >
                      {tabCategories[selectedTab].label}
                    </MenuButton>
                    <MenuList>
                      {tabCategories.map((tab) => (
                        <MenuItem
                          fontSize={"13.44px"}
                          key={tab.index}
                          onClick={() => handleTabChange(tab.index)}
                        >
                          {tab.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </Box>
              )}
            </Stack>
          </Stack>

          <ContactDetail contactDetailsRef={contactDetailsRef} />
          <Address addressRef={addressRef} />
          <FinancialDetails financialDetailsRef={financialDetailsRef} />
          <SalesDetails
            salesDefaultsRef={salesDefaultsRef}
            taxRateData={taxRateData}
            chartOfAccounts={chartOfAccounts}
          />
          <PurchaseDetails
            purchaseDetailsRef={purchaseDetailsRef}
            taxRateData={taxRateData}
            chartOfAccounts={chartOfAccounts}
          />
          <Stack
            h={"80px"}
            bg={"#fff"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            paddingRight={"20px"}
            direction={"row"}
            borderTop={"1px solid #e5e7eb"}
            borderBottom={"1px solid #e5e7eb"}
            borderRight={"1px solid #e5e7eb"}
            borderLeft={"1px solid #e5e7eb"}
            position={"sticky"}
            bottom={0}
            zIndex={1000}
          >
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              color={"#ea4d4d"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => {
                navigate("/app/payroll/AllContact");
              }}
            >
              Cancel
            </Button>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#2c48b5",
              }}
              isLoading={isLoading}
              onClick={(e) => {
                form.handleSubmit((data) => {
                  setIsLoading(true);
                  if (id) {
                    ContactApi.UpdateContact(
                      id!,
                      data,
                      (success) => {
                        form.reset(success);
                        setIsLoading(false);
                        navigate("/app/payroll/AllContact");
                      },
                      (err) => {
                        setIsLoading(false);
                      }
                    );
                  } else {
                    ContactApi.PostContacts(
                      data,
                      (success) => {
                        setIsLoading(false);
                        navigate("/app/payroll/AllContact");
                      },
                      (err) => {
                        setIsLoading(false);
                      }
                    );
                  }
                })(e);
              }}
            >
              {id ? "Update" : "Save"}
            </Button>
          </Stack>
        </Container>
      </Stack>
    </FormProvider>
  );
}
