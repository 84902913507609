import {
  Button,
  Container,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tag,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import { FiMoreVertical } from "react-icons/fi";
import { GiGraduateCap } from "react-icons/gi";
import { IoIosEye } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { BusinessTaxRate } from "../../Api/BusinessTaxRate";
import { ChartOfAccounts } from "../../Api/ChartOfAccounts";
import { ContactApi } from "../../Api/Contact";
import { AddInvoices } from "../../Api/Invoices";
import { ProductAndService } from "../../Api/ProductAndServices";
import { generateStringValues } from "../TimeSheet/utils";
import AddDetailsDrawer from "./AddDetailsDrawer";
import { AddPayments } from "./AddPayment";
import InvoicesHistory from "./Components/HistoryComponent";
import { CreateInvoicesTable } from "./CreateInvoiceTable";
import { InvoiceTemplatePopUp } from "./invoiceTemplatePopUp";

export function Invoices() {
  const navigate = useNavigate();

  const { isOpen, onClose } = useDisclosure();

  let location = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const uniqueNumber = location.state.uniqueNumber;
  const id = location.state.id;
  const paymentModal = useDisclosure();
  const currentDate = moment().format("YYYY-MM-DD");
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  const [paymentLastUpdated, setPaymentLastUpdated] = useState<number>(
    Date.now()
  );

  const [paymentData, setPaymentData] = useState<
    Array<AddInvoices.PaymentData>
  >([]);

  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  const [contactData, setContactData] = useState<ContactApi.AddContact[]>([]);
  const [itemData, setItemData] = useState<
    ProductAndService.FetchAllProductAndService[]
  >([]);
  const toast = useToast();
  const [amountPaid, setPaidAmount] = useState<number | undefined>(0); // Ensure it's a number type
  useEffect(() => {
    if (id) {
      AddInvoices.GetDueAmount(
        id!,
        (data) => {
          const numericAmount = Number(data.data); // Convert the data to a number
          setPaidAmount(numericAmount);
        },
        (err) => {
          toast({
            title: "Error",
            description: err,
            status: "error",
          });
          setIsLoading(false);
        }
      );
    }
  }, [paymentLastUpdated]);

  useEffect(() => {
    if (id) {
      AddInvoices.FetchPayment(
        id,
        (data) => {
          setPaymentData(data);

          setIsLoading(false);
        },
        (err) => {
          toast({
            title: "Error",
            description: err,
            status: "error",
          });
          setIsLoading(false);
        }
      );
    }
  }, [paymentLastUpdated]);
  const PaymentForm = useForm<AddInvoices.PaymentData>({
    defaultValues: {
      id: undefined,
      amountPaid: undefined,
      datePaid: undefined,
      accountId: undefined,
      reference: undefined,
    },
  });
  const form = useForm<AddInvoices.NewInvoiceData>({
    defaultValues: {
      id: id ?? undefined,
      invoiceNumber: uniqueNumber ?? undefined,
      items: [
        {
          itemId: undefined,
          description: undefined,
          quantity: undefined,
          price: undefined,
          accountId: undefined,
          taxRateId: undefined,
          discount: undefined,
        },
      ],
      issueDate: currentDate ?? undefined,
      dueDate: undefined,
      reference: undefined,
      paymentMethods: [],
      currency: undefined,
      amountType: undefined,
      contactId: undefined,

      autoTaxes: [],
      manualTaxes: [],
    },
  });
  const { control } = form;
  const { dirtyFields } = useFormState({ control });
  const [initialValues, setInitialValues] =
    useState<AddInvoices.NewInvoiceData | null>(form.getValues());

  useEffect(() => {
    if (!id) return;

    setIsLoading(true);

    AddInvoices.FetchInvoiceDetailsById(
      id,
      (data) => {
        // Convert price and amount in each item to a number
        const updatedData = {
          ...data,
          items: data.items.map((item: any) => ({
            ...item,
            price: item.price ? parseFloat(item.price) : undefined,
            amount: item.amount ? parseFloat(item.amount) : undefined,
          })),
        };

        form.reset(updatedData); // Reset form with updated data
        setIsLoading(false);
      },
      (err) => {
        toast({
          title: "Error",
          description: err,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, [id, lastUpdated]);
  useEffect(() => {
    if (form.getValues("id")) {
      setInitialValues(form.getValues());
    }
  }, [form.getValues("id")]);
  const [chartOfAccountLastUpdated, setChartOfAccountLastUpdated] =
    useState<number>(Date.now());
  const [taxRateLastUpdated, setTaxRateLastUpdated] = useState<number>(
    Date.now()
  );
  const [chartOfAccounts, setChartOfAccounts] = useState<
    Array<ChartOfAccounts.FetchChartOfAccounts>
  >([]);
  const [taxRateData, setTaxRateData] = useState<
    BusinessTaxRate.FetchTaxRate[]
  >([]);
  const [taxRateKeyValues, setTaxRateKeyValues] = useState<{
    [key: string]: any;
  }>({});
  useEffect(() => {
    ChartOfAccounts.GetAllAccounts(
      {
        category: "",

        page: 1,
        limit: 100,
      },
      (res) => {
        setChartOfAccounts(res.data);
      },
      (error) => {}
    );
  }, [chartOfAccountLastUpdated]);
  useEffect(() => {
    BusinessTaxRate.GetTaxRates(
      (data) => {
        setTaxRateData(data);
        const keyValues = data.reduce((acc: any, curr: any) => {
          const totalTax = curr?.tax_components?.reduce(
            (sum: any, component: any) => sum + component?.tax,
            0
          );
          acc[curr.id] = {
            name: curr.name,
            rate: totalTax,
          };
          return acc;
        }, {});
        setTaxRateKeyValues(keyValues);
      },
      (error) => {}
    );
  }, [taxRateLastUpdated]);
  const productAndServiceMapping = itemData.reduce((acc, account) => {
    acc[account.id] = `${account.code}: ${account.name}`;
    return acc;
  }, {} as Record<string, string>);
  const contactMapping = contactData.reduce((acc, account) => {
    acc[account.id!] = `${account.name}`;
    return acc;
  }, {} as Record<string, string>);
  const accountMapping = chartOfAccounts.reduce((acc, account) => {
    acc[account.id] = `${account.code}: ${account.name}`;
    return acc;
  }, {} as Record<string, string>);
  const rateTypeMapping = taxRateData.reduce((acc: any, rate: any) => {
    acc[rate.id] = rate.name;
    return acc;
  }, {} as Record<string, string>);
  return (
    <Stack>
      <FormProvider {...form}>
        <Stack
          gap={0}
          _dark={{
            bgColor: "customColor.dark.100",
          }}
          _light={{
            bgColor: "customColor.gray.400",
          }}
          h={"calc(100vh - 70px)"}
          // h="calc(100vh - 130px)"
          overflowY="auto"
        >
          <Stack
            boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
            border="1px solid #e5e7eb"
            borderTop={0}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
            padding={"14px 30px"}
            gap={0}
          >
            <Stack
              direction={"row"}
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent={"space-between"}
            >
              <Stack direction={"row"}>
                <Text
                  _light={{ color: "customColor.black7" }}
                  fontSize={"17px"}
                  fontWeight={"700"}
                >
                  {form.getValues("id") !== undefined ? "Edit" : "New"} Invoice
                </Text>
                <Tag
                  fontSize={"11px"}
                  fontWeight={700}
                  borderRadius={"2px"}
                  style={{
                    backgroundColor:
                      form.getValues("status") === "Paid"
                        ? "#f1f3fb"
                        : form.getValues("status") === "Awaiting Payment"
                        ? "#E1FBED"
                        : form.getValues("status") === "Awaiting Approval"
                        ? "#FDEDED"
                        : form.getValues("status") === "Draft" ||
                          form.getValues("status") === undefined
                        ? "#E9ECEF"
                        : "transparent",
                    color:
                      form.getValues("status") === "Paid"
                        ? "#3454d1"
                        : form.getValues("status") === "Awaiting Payment"
                        ? "#17C666"
                        : form.getValues("status") === "Awaiting Approval"
                        ? "#EA4D4D"
                        : form.getValues("status") === "Draft" ||
                          form.getValues("status") === undefined
                        ? "#283C50"
                        : "#283C50",
                  }}
                >
                  {form.getValues("status") === undefined
                    ? "Draft"
                    : form.getValues("status")}
                </Tag>
              </Stack>
              {form.getValues("status") !== "Paid" && (
                <Stack direction={{ xs: "column", md: "row" }}>
                  <Button
                    variant={"ghost"}
                    display={"none"}
                    h={"40px"}
                    color={"#3454d1"}
                    leftIcon={<GiGraduateCap fontSize={"20px"} />}
                    _hover={{
                      bg: "rgba(0,10,30,.05)",
                    }}
                  >
                    Tips and Tricks
                  </Button>
                  <Button
                    color={"#3454d1"}
                    h={"40px"}
                    variant={"ghost"}
                    leftIcon={<IoIosEye fontSize={"20px"} />}
                    _hover={{
                      bg: "rgba(0,10,30,.05)",
                    }}
                    onClick={() => {
                      navigate(
                        "/app/payroll/invoicePreview/" + form.getValues("id")
                      );
                    }}
                  >
                    Preview
                  </Button>

                  <Button
                    borderRadius={"3px"}
                    backgroundColor={"#fff"}
                    border={"1px solid #dcdee4"}
                    fontSize={"13px"}
                    h={"40px"}
                    variant={"solid"}
                    textColor={"customColor.black7"}
                    padding={"12px 16px"}
                    fontWeight={600}
                    _hover={{
                      backgroundColor: "#fff",
                      color: "#3454D1",
                    }}
                    isLoading={isLoading}
                    onClick={(e) => {
                      form.handleSubmit((data) => {
                        setIsLoading(true);

                        const stringValues = generateStringValues(
                          dirtyFields,
                          initialValues,
                          data,
                          {
                            chartAccountId: accountMapping,
                            rateType: rateTypeMapping,
                            contactId: contactMapping,
                            itemId: productAndServiceMapping,
                          }
                        );
                        const StringifyStringValues =
                          JSON.stringify(stringValues);
                        console.log("accountMapping", accountMapping);
                        console.log("contactMapping", contactMapping);
                        console.log("rateTypeMapping", rateTypeMapping);
                        console.log(
                          "productAndServiceMapping",
                          productAndServiceMapping
                        );
                        if (id) {
                          AddInvoices.UpdateInvoice(
                            id!,
                            data,
                            (success) => {
                              console.log("Initial Values", initialValues);
                              console.log("dirty field", dirtyFields);

                              AddInvoices.PostHistoryLogs(
                                id,
                                {
                                  date: moment().format("YYYY-MM-DD HH:mm:ss"),
                                  userId: decodedToken?.userId,
                                  action: "Edited",
                                  detail: StringifyStringValues,
                                },
                                (approvalSuccess) => {
                                  form.reset(success);
                                  setIsLoading(false);
                                  navigate("/app/payroll/InvoicesParent");
                                },
                                (approvalError) => {
                                  toast({
                                    title: "Failed To post History",

                                    status: "error",
                                  });
                                }
                              );
                            },
                            (err) => {
                              toast({
                                title: "Error",
                                description: err,
                                status: "error",
                              });
                              setIsLoading(false);
                            }
                          );
                        } else {
                          // Create new invoice
                          AddInvoices.PostInvoices(
                            data,
                            (success) => {
                              const invoiceId = success?.id;
                              if (invoiceId) {
                                // Log history for added invoice
                                AddInvoices.PostHistoryLogs(
                                  invoiceId,
                                  {
                                    date: moment().format(
                                      "YYYY-MM-DD HH:mm:ss"
                                    ),
                                    userId: decodedToken?.userId,
                                    action: "Post",
                                    detail: StringifyStringValues, // Pass the filtered dynamic log message
                                  },
                                  (approvalSuccess) => {
                                    setIsLoading(false);
                                    navigate("/app/payroll/InvoicesParent");
                                  },
                                  (approvalError) => {
                                    toast({
                                      title: "Error",
                                      description: approvalError,
                                      status: "error",
                                    });
                                    setIsLoading(false);
                                  }
                                );
                              } else {
                                setIsLoading(false);
                              }

                              setIsLoading(false);
                            },
                            (err) => {
                              toast({
                                title: "Error",
                                description: err,
                                status: "error",
                              });
                              setIsLoading(false);
                            }
                          );
                        }
                      })(e);
                    }}
                  >
                    {form.getValues("status") === "Awaiting Payment"
                      ? "Update"
                      : "Save and close"}
                  </Button>

                  {form.getValues("status") === "Awaiting Payment" && (
                    <Button
                      fontSize={"13px"}
                      bgColor="#3454D1"
                      h={"40px"}
                      borderRadius={"3px"}
                      borderRightRadius={0}
                      borderWidth={"1px"}
                      borderColor={"#3454d1!important"}
                      variant={"solid"}
                      padding={"12px 16px"}
                      fontWeight={700}
                      _hover={{
                        bgColor: "#3454D1",
                      }}
                      onClick={() => {
                        PaymentForm.reset({
                          id: form.getValues("id"),
                          amountPaid: amountPaid,
                          datePaid: undefined,
                          accountId: undefined,
                          reference: undefined,
                        });

                        paymentModal.onOpen();
                      }}
                    >
                      Add Payment
                    </Button>
                  )}
                  <HStack
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={0}
                  >
                    {form.getValues("status") !== "Awaiting Payment" && (
                      <Button
                        fontSize={"13px"}
                        bgColor="#3454D1"
                        h={"40px"}
                        borderRadius={"3px"}
                        borderWidth={"1px"}
                        borderColor={"#3454d1!important"}
                        variant={"solid"}
                        padding={"12px 16px"}
                        fontWeight={700}
                        _hover={{
                          bgColor: "#3454D1",
                        }}
                        onClick={() => {
                          {
                            form.handleSubmit((data) => {
                              setIsLoading(true);
                              if (id) {
                                AddInvoices.Approval(
                                  id!,
                                  (success) => {
                                    setIsLoading(false);
                                    navigate("/app/payroll/InvoicesParent");
                                  },
                                  (err) => {
                                    setIsLoading(false);
                                  }
                                );
                              } else {
                                AddInvoices.PostInvoices(
                                  data,
                                  (postSuccess) => {
                                    // After successfully posting, get the ID from the response

                                    const invoiceId = postSuccess?.id; // Assuming the response has an 'id' property
                                    if (invoiceId) {
                                      // Now, submit the invoice for approval using the ID
                                      AddInvoices.Approval(
                                        invoiceId,
                                        (approvalSuccess) => {
                                          setIsLoading(false);
                                          navigate(
                                            "/app/payroll/InvoicesParent"
                                          );
                                        },
                                        (approvalError) => {
                                          toast({
                                            title: "Error",
                                            description: approvalError,
                                            status: "error",
                                          });
                                          setIsLoading(false);
                                        }
                                      );
                                    } else {
                                      setIsLoading(false);
                                    }
                                  },
                                  (err) => {
                                    toast({
                                      title: "Error",
                                      description: err,
                                      status: "error",
                                    });
                                    setIsLoading(false);
                                  }
                                );
                              }
                            })();
                          }
                        }}
                      >
                        Approve
                      </Button>
                    )}

                    <Menu>
                      <MenuButton
                        as={IconButton}
                        padding={0}
                        backgroundColor={"transparent"}
                        h={"34.5px"}
                        w={"34.5px"}
                        marginLeft={"8px"}
                        color={"black"}
                        display={{
                          lg: "flex",
                          md: "flex",
                          sm: "flex",
                          xs: "flex",
                        }}
                        borderRadius={"full"}
                        _hover={{
                          backgroundColor: "rgba(0,10,30,.05)",
                          color: "#000a1e",
                        }}
                        _active={{
                          backgroundColor: "rgba(0,10,30,.05)",
                          color: "#000a1e",
                        }}
                        icon={<FiMoreVertical fontSize={"19px"} />}
                      />
                      <MenuList overflowY="auto" zIndex={"overlay"} w={"300px"}>
                        {form.getValues("status") !== "Awaiting Payment" && (
                          <>
                            <MenuItem
                              key={1}
                              padding={"0px 12px"}
                              _hover={{
                                backgroundColor: "rgba(0,10,30,.1)",
                              }}
                              onClick={(e) => {
                                form.handleSubmit((data) => {
                                  setIsLoading(true);

                                  const stringValues = generateStringValues(
                                    dirtyFields,
                                    initialValues,
                                    data,
                                    {
                                      chartAccountId: accountMapping,
                                      rateType: rateTypeMapping,
                                      contactId: contactMapping,
                                      itemId: productAndServiceMapping,
                                    }
                                  );
                                  const StringifyStringValues =
                                    JSON.stringify(stringValues);

                                  console.log("accountMapping", accountMapping);
                                  console.log("contactMapping", contactMapping);
                                  console.log(
                                    "rateTypeMapping",
                                    rateTypeMapping
                                  );
                                  console.log(
                                    "productAndServiceMapping",
                                    productAndServiceMapping
                                  );

                                  if (id) {
                                    // Update Invoice
                                    AddInvoices.UpdateInvoice(
                                      id!,
                                      data,
                                      (success) => {
                                        console.log(
                                          "Initial Values",
                                          initialValues
                                        );
                                        console.log("dirty field", dirtyFields);

                                        AddInvoices.PostHistoryLogs(
                                          id,
                                          {
                                            date: moment().format(
                                              "YYYY-MM-DD HH:mm:ss"
                                            ),
                                            userId: decodedToken?.userId,
                                            action: "Edited",
                                            detail: StringifyStringValues,
                                          },
                                          (approvalSuccess) => {
                                            AddInvoices.GenerateUniqueNumber(
                                              (success) => {
                                                // Store response data in the state
                                                navigate(
                                                  "/app/payroll/invoices",
                                                  {
                                                    state: {
                                                      id: undefined,
                                                      uniqueNumber:
                                                        success.uniqueNumber,
                                                    },
                                                  }
                                                );
                                                // Refresh the page after success
                                                window.location.reload();
                                              },
                                              (error) => {
                                                toast({
                                                  title: "Error",
                                                  description: error,
                                                  status: "error",
                                                });
                                                setIsLoading(false);
                                              }
                                            );
                                            form.reset(success);
                                            setIsLoading(false);
                                          },
                                          (err) => {
                                            toast({
                                              title: "Error",
                                              description: err,
                                              status: "error",
                                            });
                                            setIsLoading(false);
                                          }
                                        );
                                      },
                                      (err) => {
                                        setIsLoading(false);
                                      }
                                    );
                                  } else {
                                    // Create new invoice
                                    AddInvoices.PostInvoices(
                                      data,
                                      (success) => {
                                        const invoiceId = success?.id;
                                        if (invoiceId) {
                                          // Log history for added invoice
                                          AddInvoices.PostHistoryLogs(
                                            invoiceId,
                                            {
                                              date: moment().format(
                                                "YYYY-MM-DD HH:mm:ss"
                                              ),
                                              userId: decodedToken?.userId,
                                              action: "Post",
                                              detail: StringifyStringValues, // Pass the filtered dynamic log message
                                            },
                                            (approvalSuccess) => {
                                              AddInvoices.GenerateUniqueNumber(
                                                (success) => {
                                                  // Store response data in the state
                                                  navigate(
                                                    "/app/payroll/invoices",
                                                    {
                                                      state: {
                                                        id: undefined,
                                                        uniqueNumber:
                                                          success.uniqueNumber,
                                                      },
                                                    }
                                                  );
                                                  // Refresh the page after success
                                                  window.location.reload();
                                                },
                                                (err) => {
                                                  toast({
                                                    title: "Error",
                                                    description: err,
                                                    status: "error",
                                                  });
                                                  setIsLoading(false);
                                                }
                                              );
                                              setIsLoading(false);
                                            },
                                            (approvalError) => {
                                              setIsLoading(false);
                                            }
                                          );
                                        } else {
                                          setIsLoading(false);
                                        }

                                        setIsLoading(false);
                                      },
                                      (err) => {
                                        setIsLoading(false);
                                      }
                                    );
                                  }
                                })(e);
                              }}
                            >
                              {" "}
                              <Text
                                padding={"8px"}
                                margin={"0px 12px 0px 0px"}
                                _light={{
                                  color: "customColor.black7",
                                }}
                                _dark={{
                                  color: "customColor.pearlWhite",
                                }}
                                fontSize={"15px"}
                              >
                                Save & add another
                              </Text>
                            </MenuItem>

                            <MenuItem
                              key={1}
                              padding={"0px 12px"}
                              _hover={{
                                backgroundColor: "rgba(0,10,30,.1)",
                              }}
                              onClick={(e) => {
                                form.handleSubmit((data) => {
                                  setIsLoading(true);
                                  if (id) {
                                    AddInvoices.SubmitForApproval(
                                      id!,

                                      (success) => {
                                        setIsLoading(false);
                                        navigate("/app/payroll/InvoicesParent");
                                      },
                                      (err) => {
                                        setIsLoading(false);
                                      }
                                    );
                                  } else {
                                    AddInvoices.PostInvoices(
                                      data,
                                      (postSuccess) => {
                                        // After successfully posting, get the ID from the response

                                        const invoiceId = postSuccess?.id; // Assuming the response has an 'id' property
                                        if (invoiceId) {
                                          // Now, submit the invoice for approval using the ID
                                          AddInvoices.SubmitForApproval(
                                            invoiceId,
                                            (approvalSuccess) => {
                                              setIsLoading(false);
                                              navigate(
                                                "/app/payroll/InvoicesParent"
                                              );
                                            },
                                            (approvalError) => {
                                              setIsLoading(false);
                                            }
                                          );
                                        } else {
                                          setIsLoading(false);
                                        }
                                      },
                                      (err) => {
                                        toast({
                                          title: "Error",
                                          description: err,
                                          status: "error",
                                        });
                                        setIsLoading(false);
                                      }
                                    );
                                  }
                                })(e);
                              }}
                            >
                              {" "}
                              <Text
                                padding={"8px"}
                                margin={"0px 12px 0px 0px"}
                                _light={{
                                  color: "customColor.black7",
                                }}
                                _dark={{
                                  color: "customColor.pearlWhite",
                                }}
                                fontSize={"15px"}
                              >
                                Submit for approval
                              </Text>
                            </MenuItem>
                            <MenuItem
                              padding={"0px 12px"}
                              _hover={{
                                backgroundColor: "rgba(0,10,30,.1)",
                              }}
                              onClick={() => {
                                {
                                  form.handleSubmit((data) => {
                                    setIsLoading(true);
                                    if (id) {
                                      AddInvoices.Approval(
                                        id!,
                                        (success) => {
                                          setIsLoading(false);
                                          AddInvoices.GenerateUniqueNumber(
                                            (success) => {
                                              // Store response data in the state
                                              navigate(
                                                "/app/payroll/invoices",
                                                {
                                                  state: {
                                                    id: undefined,
                                                    uniqueNumber:
                                                      success.uniqueNumber,
                                                  },
                                                }
                                              );
                                              // Refresh the page after success
                                              window.location.reload();
                                            },
                                            (error) => {
                                              // Show error message in toast
                                            }
                                          );
                                        },
                                        (err) => {
                                          toast({
                                            title: "Error",
                                            description: err,
                                            status: "error",
                                          });
                                          setIsLoading(false);
                                        }
                                      );
                                    } else {
                                      AddInvoices.PostInvoices(
                                        data,
                                        (postSuccess) => {
                                          // After successfully posting, get the ID from the response

                                          const invoiceId = postSuccess?.id; // Assuming the response has an 'id' property
                                          if (invoiceId) {
                                            // Now, submit the invoice for approval using the ID
                                            AddInvoices.Approval(
                                              invoiceId,
                                              (approvalSuccess) => {
                                                setIsLoading(false);
                                                window.location.reload();
                                              },
                                              (approvalError) => {
                                                setIsLoading(false);
                                              }
                                            );
                                          } else {
                                            setIsLoading(false);
                                            toast({
                                              title: "Failed To Approve",

                                              status: "error",
                                            });
                                          }
                                        },
                                        (err) => {
                                          toast({
                                            title: "Error",
                                            description: err,
                                            status: "error",
                                          });
                                          setIsLoading(false);
                                        }
                                      );
                                    }
                                  })();
                                }
                              }}
                            >
                              <Text
                                padding={"8px"}
                                margin={"0px 12px 0px 0px"}
                                _light={{
                                  color: "customColor.black7",
                                }}
                                _dark={{
                                  color: "customColor.pearlWhite",
                                }}
                                fontSize={"15px"}
                              >
                                Approve & Add Next
                              </Text>
                            </MenuItem>
                          </>
                        )}
                        {[
                          { name: "Print PDF" },
                          // "Email",
                          // "Copy to draft invoices",
                          // "Copy to draft quote",
                          {
                            name: "Delete",
                            onclick: () => {
                              form.handleSubmit((data) => {
                                setIsLoading(true);
                                if (id) {
                                  setIsLoading(true);
                                  AddInvoices.DeleteInvoices(
                                    id,
                                    (success) => {
                                      setIsLoading(false);
                                      setLastUpdated(Date.now());
                                      navigate("/app/payroll/InvoicesParent");
                                    },
                                    (err) => {
                                      toast({
                                        title: "Error",
                                        description: err,
                                        status: "error",
                                      });
                                    }
                                  );
                                }
                              })();
                            },
                          },
                        ].map((item, i) => {
                          return (
                            <MenuItem
                              key={i}
                              padding={"0px 12px"}
                              _hover={{
                                backgroundColor: "rgba(0,10,30,.1)",
                              }}
                              onClick={item.onclick ? item.onclick : undefined}
                            >
                              {" "}
                              <Text
                                padding={"8px"}
                                margin={"0px 12px 0px 0px"}
                                _light={{
                                  color: "customColor.black7",
                                }}
                                _dark={{
                                  color: "customColor.pearlWhite",
                                }}
                                fontSize={"15px"}
                              >
                                {item.name}
                              </Text>
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Menu>
                  </HStack>
                </Stack>
              )}
            </Stack>
          </Stack>
          <Container maxW={"100%"} padding={"30px 30px 10px 30px"}>
            <InvoiceTemplatePopUp />
            <CreateInvoicesTable
              setLastUpdated={setLastUpdated}
              setItemData={setItemData}
              itemData={itemData}
              lastUpdated={lastUpdated}
              paymentData={paymentData}
              chartOfAccounts={chartOfAccounts}
              taxRateData={taxRateData}
              setChartOfAccountLastUpdated={setChartOfAccountLastUpdated}
              setTaxRateLastUpdated={setTaxRateLastUpdated}
              taxRateKeyValues={taxRateKeyValues}
              setContactData={setContactData}
              contactData={contactData}
            />
            <InvoicesHistory id={id} />
          </Container>
          <AddDetailsDrawer isOpen={isOpen} onClose={onClose} />
        </Stack>
      </FormProvider>
      <FormProvider {...PaymentForm}>
        <AddPayments
          isOpen={paymentModal.isOpen}
          onClose={paymentModal.onClose}
          setLastUpdated={setPaymentLastUpdated}
          chartOfAccounts={chartOfAccounts}
        />
      </FormProvider>
    </Stack>
  );
}
