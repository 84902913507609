import {Checkbox} from '@chakra-ui/react';
import {Controller, useFormContext} from 'react-hook-form';
import {Inspection} from '../../../modules/Audit';
import {useInspectionContext} from '../hooks/InspectionContext';
import {useAuditQuestionContainer} from './AuditQuestionContainer';

export default function CheckBox() {
  const template = useFormContext();
  const {question, getItemField, onScoreChanged, score} =
    useAuditQuestionContainer();
  const {setIsSaving} = useInspectionContext();
  return (
    <Controller
      control={template.control}
      name={getItemField('question.questionValue')}
      render={({field}) => (
        <Checkbox
          {...field}
          isChecked={field.value === 'true'}
          onChange={e => {
            const isChecked = e.target.checked;
            field.onChange(String(isChecked));
            if (score) {
              if (isChecked) {
                onScoreChanged?.(score, 0);
              } else {
                onScoreChanged?.(-score, 0);
              }
            }
            const answerId = template.getValues(
              getItemField('question.answerId'),
            );
            const type = template.getValues(getItemField('question.type'));
            const inspectionMetaID = template.getValues('inspection.id');
            const notes = template.getValues(getItemField('question.notes'));
            const question_score = template.getValues(
              getItemField('question.score'),
            );
            setIsSaving(true);
            Inspection.CachingInspection(
              answerId,
              {
                question_value: String(isChecked),
                notes: notes,
                inspection_meta_id: inspectionMetaID,
                type: type,
                question_score: question_score,
              },
              () => {
                setIsSaving(false);
              },
              () => {
                setIsSaving(false);
              },
            );
          }}
          // as="a"
          paddingBottom="16px"
          fontSize="16px"
          fontWeight={400}
          color="textcolors.primary">
          {question}
        </Checkbox>
      )}
    />
  );
}
