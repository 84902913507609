import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import { CustomInput } from "../../../../../Common/CustomInput";
import { ReactSelect } from "../../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../../Hooks/BusinessContext";
import { EmployeeLeave } from "../../../../Api/EmployeeLeave";
import { EmployeeLeaveEntitlement } from "../../../../Api/EmployeeLeaveEntitlement";
import { PayItemLeaveApi } from "../../../../Api/PayItemLeave";
import { RejectLeave } from "../RejectLeave";

interface LeaveProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (timeStamp: number) => void;
  FecthedLeaves: Array<PayItemLeaveApi.LeaveById>;
  employeeId: string;
  lastUpdated: number;
}

export default function Leave({
  isOpen,
  onClose,
  setLastUpdated,
  FecthedLeaves,
  employeeId,
  lastUpdated,
}: // selectedLeaveType,
LeaveProps) {
  const form = useFormContext<EmployeeLeave.NewLeave>();
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const watchSelectedLeaveType = useWatch({
    control: form.control,
    name: "payrollPayItemLeaveId",
  });
  const [selectedPayperiodsString, setSelectedPayperiodsStrings] = useState<
    Array<string>
  >([""]);
  const rejectModal = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [hours, setHours] =
    useState<EmployeeLeaveEntitlement.fetchEntitlementsHours>();
  useEffect(() => {
    if (watchSelectedLeaveType) {
      EmployeeLeaveEntitlement.FetchEntitlementsHours(
        employeeId,
        watchSelectedLeaveType,
        (success) => {
          setHours(success);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
        }
      );
    }
  }, [watchSelectedLeaveType]);
  const { employeesList } = useBusinessContext();
  const watchStartDate = useWatch({
    control: form.control,
    name: "startDate",
  });
  const watchEndDate = useWatch({
    control: form.control,
    name: "endDate",
  });
  useEffect(() => {
    if (employeeId && watchStartDate && watchEndDate) {
      const startDate = new Date(watchStartDate);
      const endDate = new Date(watchEndDate);
      if (startDate > endDate) {
        toast({
          title: "Error",
          description: "End date should be greater than start date",
          status: "error",
        });
      } else {
        EmployeeLeave.LeavePayPeriods(
          {
            empId: employeeId,
            startDate: watchStartDate,
            endDate: watchEndDate,
          },
          (success) => {
            setSelectedPayperiodsStrings(
              success.map((period) => period?.string ?? "")
            );
            const payPeriods = success.map((period) => {
              const existingPayPeriod = form
                .getValues("payPeriods")
                ?.find(
                  (p: any) =>
                    p.startDate === period.startDate &&
                    p.endDate === period.endDate
                );
              return {
                ...period,
                quantity: existingPayPeriod?.quantity ?? 0,
                id: existingPayPeriod?.id,
                string: existingPayPeriod?.string,
              };
            });
            form.setValue("payPeriods", payPeriods);
          },
          (error) => {
            toast({
              title: "Error",
              description: error,
              status: "error",
            });
          }
        );
      }
    }
  }, [employeeId, watchStartDate, watchEndDate]);
  const LeavePayPeriodsQuantity = useFieldArray({
    control: form.control,
    name: "payPeriods",
  });
  // const daysDuration =
  //   watchStartDate && watchEndDate
  //     ? moment(watchEndDate).diff(watchStartDate, "days")
  //     : 0;
  const quantity =
    watchStartDate && watchEndDate
      ? moment(moment(watchEndDate).format("YYYY-MM-DD")).diff(
          moment(moment(watchStartDate).format("YYYY-MM-DD")),
          "days"
        ) + 1
      : 0;
  const difference = (hours?.balance ?? 0) - quantity;
  return (
    <FormProvider {...form}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          // _light={{
          //   color: "customColor.black2",
          // }}
          // _dark={{
          //   color: "customColor.white",
          // }}
          maxW="400px"
          maxH="550px"
        >
          <ModalHeader
            _dark={{
              color: "customColor.dark.150",
            }}
            padding={"12px 20px"}
            _light={{
              color: "customColor.black7",
            }}
            fontSize=".9975rem"
            fontWeight={"700"}
            lineHeight={"1.75rem"}
            borderBottom={"1px solid #e8e8e8"}
          >
            Leave Request
          </ModalHeader>
          <ModalCloseButton />
          <Box overflowY="auto">
            <ModalBody padding={"8px 20px"}>
              <Stack>
                <Text
                  fontSize=".8125rem"
                  fontWeight="600"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  lineHeight={"1.3077"}
                  marginBottom="-7px"
                  padding={"1px 0px 4px"}
                >
                  Employee
                </Text>

                <HStack
                  border="1px solid #e5e7eb"
                  padding={"5px"}
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  _light={{
                    bgColor: "customColor.white",
                    color: "customColor.black7",
                  }}
                  borderRadius={"3px"}
                >
                  <Avatar
                    name={
                      employeesList?.find((emp) => emp.id === employeeId)
                        ?.firstName +
                      " " +
                      employeesList?.find((emp) => emp.id === employeeId)
                        ?.lastName
                    }
                    h={"24px"}
                    w={"24px"}
                    size={"sm"}
                    marginLeft={"8px"}
                    bg={"#daa3e4"}
                    color={"#3f1946"}
                  />
                  <Text fontSize={"15px"}>
                    {employeesList?.find((emp) => emp.id === employeeId)
                      ?.firstName +
                      " " +
                      employeesList?.find((emp) => emp.id === employeeId)
                        ?.lastName}
                  </Text>
                </HStack>

                <Text
                  marginTop={"15px"}
                  fontSize=".8125rem"
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontWeight="600"
                  _dark={{
                    color: "customColor.white",
                  }}
                  lineHeight={"1.3077"}
                  marginBottom="-7px"
                  padding={"1px 0px 4px"}
                >
                  Leave Type
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Select Employee Status is required",
                    },
                  }}
                  control={form.control}
                  name="payrollPayItemLeaveId"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      options={FecthedLeaves?.map((leave) => ({
                        value: leave.id,
                        label: leave.name,
                      }))}
                      withValidation
                      isDisabled={form.getValues("id") ? true : false}
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Please Select Status"
                      placeholder="Select..."
                      value={
                        FecthedLeaves?.find((leave) => leave.id === field.value)
                          ? {
                              value: field.value,
                              label: FecthedLeaves?.find(
                                (leave) => leave.id === field.value
                              )?.name,
                            }
                          : null
                      }
                      onChange={(selectedOption: any) => {
                        field.onChange(selectedOption.value);
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
                {watchSelectedLeaveType && (
                  <Stack>
                    <HStack borderBottom={"1px solid grey"}>
                      <Heading
                        display={"flex"}
                        flex={1}
                        fontSize={".8125rem"}
                        fontWeight={"600"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                      >
                        Current Leave Balance
                      </Heading>
                      <Heading
                        fontSize={".8125rem"}
                        fontWeight={"600"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                      >
                        {FecthedLeaves.find(
                          (op) => op.id === watchSelectedLeaveType
                        )?.unitType ?? "Days"}
                      </Heading>
                    </HStack>
                    <HStack>
                      <Heading
                        display={"flex"}
                        flex={1}
                        fontSize={".8125rem"}
                        fontWeight={"600"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                      >
                        {
                          FecthedLeaves.find(
                            (op) => op.id === watchSelectedLeaveType
                          )?.name
                        }
                      </Heading>
                      <Heading
                        fontSize={".8125rem"}
                        fontWeight={"600"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                      >
                        {hours?.balance}
                      </Heading>
                    </HStack>
                    <Stack
                      direction="row"
                      spacing={0}
                      w={"100%"}
                      alignItems={"baseline"}
                    >
                      <Stack flex={1}>
                        <Controller
                          rules={{
                            required: {
                              value: true,
                              message: "Start Date is required",
                            },
                          }}
                          control={form.control}
                          name="startDate"
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              ref={field.ref}
                              validationMessage={error?.message}
                              withValidation
                              input={{
                                ...field,

                                isInvalid: error !== undefined,
                                w: "100%",
                                fontSize: "15px",
                                variant: "outline",
                                type: "date",

                                borderTopRadius: "3px",
                              }}
                              placeholder=""
                              label="Start date"
                              labelProps={{
                                fontSize: ".8125rem",
                                marginTop: "15px",
                                fontWeight: "600",
                                color: "customColor.black7",
                                lineHeight: "1.3077",
                                marginBottom: "-4px",
                                padding: "1px 0px 4px",
                              }}
                            />
                          )}
                        />
                      </Stack>
                      <Stack flex={1}>
                        <Controller
                          rules={{
                            required: {
                              value: true,
                              message: "End Date is required",
                            },
                          }}
                          control={form.control}
                          name="endDate"
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              ref={field.ref}
                              validationMessage={error?.message}
                              withValidation
                              input={{
                                ...field,
                                isInvalid: error !== undefined,
                                w: "100%",
                                fontSize: "15px",
                                variant: "outline",
                                type: "date",

                                borderTopRadius: "3px",
                              }}
                              placeholder=""
                              label="End date"
                              labelProps={{
                                fontSize: ".8125rem",
                                marginTop: "15px",
                                fontWeight: "600",
                                color: "customColor.black7",
                                lineHeight: "1.3077",
                                marginBottom: "-4px",
                                padding: "1px 0px 4px",
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Stack>
                    <HStack justifyContent="space-between">
                      <Heading
                        fontSize={".8125rem"}
                        fontWeight={"600"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                      >
                        Total leave submission days
                      </Heading>
                      <Heading
                        fontSize={".8125rem"}
                        fontWeight={"600"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                      >
                        {quantity}
                      </Heading>
                    </HStack>

                    {difference < 0 && (
                      <HStack justifyContent="space-between">
                        <Heading
                          fontSize={".8125rem"}
                          fontWeight={"normal"}
                          _light={{
                            color: "#47C5E8",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          paddingBottom={"5px"}
                        >
                          {employeesList?.find((emp) => emp.id === employeeId)
                            ?.firstName +
                            " " +
                            employeesList?.find((emp) => emp.id === employeeId)
                              ?.lastName}{" "}
                          your unpaid leave will be
                        </Heading>
                        <Heading
                          fontSize={".8125rem"}
                          fontWeight={"600"}
                          _light={{
                            color: "#47C5E8",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          paddingBottom={"5px"}
                        >
                          {difference}
                        </Heading>
                      </HStack>
                    )}

                    <Text
                      fontSize=".8125rem"
                      marginTop={"15px"}
                      fontWeight="600"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      lineHeight={"1.3077"}
                      marginBottom="-12px"
                      padding={"1px 0px 4px"}
                    >
                      Description (optional)
                    </Text>
                    <Controller
                      control={form.control}
                      name="description"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            fontSize: "15px",
                            variant: "outline",
                            type: "text",

                            borderTopRadius: "3px",
                          }}
                          placeholder=""
                        />
                      )}
                    />

                    <HStack marginTop={"20px"} borderBottom={"1px solid grey"}>
                      <Heading
                        display={"flex"}
                        flex={1}
                        fontSize={".8125rem"}
                        fontWeight={"600"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                      >
                        Pay Period
                      </Heading>
                      <Heading
                        fontSize={".8125rem"}
                        fontWeight={"600"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                      >
                        {FecthedLeaves.find(
                          (op) => op.id === watchSelectedLeaveType
                        )?.unitType ?? "Days"}
                      </Heading>
                    </HStack>
                    {
                      LeavePayPeriodsQuantity.fields.map((field, index) => (
                        <HStack marginTop={"10px"} key={field.id}>
                          <Heading
                            display={"flex"}
                            flex={1}
                            fontSize={".8125rem"}
                            _light={{
                              color: "customColor.black2",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            paddingBottom={"5px"}
                          >
                            {selectedPayperiodsString[index]}
                          </Heading>
                          <Stack>
                            <Controller
                              rules={{
                                required: {
                                  value: true,
                                  message: "Days is required",
                                },
                              }}
                              control={form.control}
                              name={`payPeriods.${index}.quantity`}
                              render={({ field, fieldState: { error } }) => (
                                <CustomInput
                                  ref={field.ref}
                                  validationMessage={error?.message}
                                  withValidation
                                  input={{
                                    ...field,
                                    isInvalid: error !== undefined,
                                    alignItems: "flex-end",
                                    w: "100px",
                                    fontSize: "15px",
                                    textAlign: "right",
                                    variant: "outline",
                                    type: "text",

                                    borderTopRadius: "3px",
                                    onChange: (e) => {
                                      field.onChange(Number(e.target.value));
                                    },
                                  }}
                                  placeholder=""
                                />
                              )}
                            />
                          </Stack>
                        </HStack>
                      ))
                      //   <HStack marginTop={"10px"}>
                      //   <Heading
                      //     display={"flex"}
                      //     flex={1}
                      //     fontSize={"17px"}
                      //     _light={{
                      //       color: "customColor.black2",
                      //     }}
                      //     _dark={{
                      //       color: "customColor.white",
                      //     }}
                      //     paddingBottom={"5px"}
                      //   >
                      //     Week ending 05 Oct 2023
                      //   </Heading>
                      //   <Stack>
                      //     <Controller
                      //       rules={{
                      //         required: {
                      //           value: true,
                      //           message: "Days is required",
                      //         },
                      //       }}
                      //       control={form.control}
                      //       name="quantity"
                      //       render={({ field, fieldState: { error } }) => (
                      //         <CustomInput

                      //           validationMessage={error?.message}
                      //           withValidation
                      //           input={{
                      //             ...field,
                      //             isInvalid: error !== undefined,
                      //             alignItems: "flex-end",
                      //             w: "100px",
                      //             fontSize: "15px",
                      //             textAlign: "right",
                      //             variant: "outline",
                      //             type: "number",
                      //              border:"1px solid #e5e7eb",
                      //             borderTopRadius: "3px",
                      //             onChange: (e) => {
                      //               field.onChange(Number(e.target.value));
                      //             },
                      //           }}
                      //           placeholder=""
                      //         />
                      //       )}
                      //     />
                      //   </Stack>
                      // </HStack>
                    }
                  </Stack>
                )}
              </Stack>
            </ModalBody>
          </Box>
          <ModalFooter padding={"20px"} borderTop={"1px solid #e8e8e8"}>
            {/* {form.getValues("id") && (
              <ButtonGroup
                minH={"40px"}
                spacing="1px"
                colorScheme={"red"}
                flex={1}
              >
                <Button
                  borderLeftRadius="3px"
                  w="100px"
                  borderRight="0px"
                  minH={"40px"}
                  px="16px"
                  isLoading={isLoading}
                  width="fit-content"
                  onClick={() => {
                    setIsLoading(true);
                    EmployeeLeave.LeaveRejected(
                      form.getValues("id")!,
                      {},
                      () => {
                        setLastUpdated(Date.now());
                        toast({
                          title: "leave Rejected",
                          status: "success",
                        });
                        setIsLoading(false);
                      },
                      (error) => {
                        toast({
                          title: "Error",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  }}
                >
                  Reject
                </Button>
                <Menu placement="bottom-end">
                  <MenuButton
                    as={IconButton}
                    borderRightRadius="3px"
                    aria-label="dropdown menu"
                    icon={<FiChevronDown />}
                    minH={"40px"}
                  />
                  <MenuList minW="100px">
                    <MenuItem
                      fontSize="13px"
                      onClick={() => {
                        rejectModal.onOpen();
                      }}
                    >
                      Reject with a note
                    </MenuItem>
                  </MenuList>
                </Menu>
              </ButtonGroup>
            )} */}
            <Flex>
              <Button
                backgroundColor={"transparent"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                textColor={"#ea4d4d"}
                padding={"12px 16px"}
                marginRight={"5px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                isLoading={isLoading}
                onClick={(e) => {
                  form.handleSubmit((data) => {
                    // const QuantitySum = data.payPeriods.reduce(
                    //   (acc, current) => acc + current.quantity,
                    //   0
                    // );
                    setIsLoading(true);
                    if (form.getValues("id")) {
                      EmployeeLeave.UpdateLeave(
                        form.getValues("id")!,
                        employeeId!,
                        { ...data, quantity: quantity },
                        (success) => {
                          toast({
                            title: "Leave is updated",

                            status: "success",
                          });
                          setIsLoading(false);
                          setLastUpdated(Date.now());
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Update Leave Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    } else {
                      EmployeeLeave.CreateLeave(
                        employeeId!,
                        { ...data, quantity: quantity },
                        (success) => {
                          toast({
                            title: "Leave is added",

                            status: "success",
                          });
                          setIsLoading(false);
                          setLastUpdated(Date.now());
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Add Leave Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    }
                  })(e);
                }}
              >
                {form.getValues("id") ? "Update" : "Add"}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <RejectLeave
        isOpen={rejectModal.isOpen}
        onClose={rejectModal.onClose}
        setLastUpdated={setLastUpdated}
      />
    </FormProvider>
  );
}
