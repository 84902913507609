import {useDisclosure, useToast} from '@chakra-ui/react';
import moment from 'moment';
import {createContext, useContext, useState} from 'react';
import {MCQ} from '../../../modules/Audit/MCQs';
import GlobalResponseSet from '../components/Drawers/GlobalResponseSet';
import {useMultiChoiceContext} from './multiChoiceContext';

interface GlobalResponseDrawerContext {
  globalResponses: MCQ.IMCQ;
  setGlobalResponses: React.Dispatch<React.SetStateAction<MCQ.IMCQ>>;
  openDrawer: (responseSet?: MCQ.IMCQ) => void;
  closeDrawer: () => void;
  Save: (data: MCQ.IMCQ) => void;
}
export const GlobalResponseDrawerContext =
  createContext<GlobalResponseDrawerContext>({
    globalResponses: {options: [], isGlobal: false, isScoringEnabled: false},
    setGlobalResponses: () => {},
    openDrawer: () => {},
    closeDrawer: () => {},
    Save: () => {},
  });
export function GlobalResponseDrawerProvider({children}: any) {
  const {updateOptionSets} = useMultiChoiceContext();
  const toast = useToast({position: 'top'});
  const INIT_STATE = {
    isGlobal: false,
    isScoringEnabled: false,
    options: [{color: '', flagged: false, value: '', orderIndex: 0}],
  };
  const [globalResponses, setGlobalResponses] = useState<MCQ.IMCQ>(INIT_STATE);
  // Global Responses
  const GlobalResponsesModalOperations = useDisclosure();

  function openDrawer(responseSet?: MCQ.IMCQ) {
    setGlobalResponses(responseSet ?? INIT_STATE);
    GlobalResponsesModalOperations.onOpen();
  }

  function closeDrawer() {
    setGlobalResponses(INIT_STATE);
    GlobalResponsesModalOperations.onClose();
  }

  function Save(data: MCQ.IMCQ) {
    MCQ.Create(
      data,
      () => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Global Response Set Created',
        });
        GlobalResponsesModalOperations.onClose();
        updateOptionSets();
      },
      error => {
        toast({
          status: 'error',
          title: 'Error',
          description: error,
        });
      },
    );
  }
  return (
    <GlobalResponseDrawerContext.Provider
      value={{
        globalResponses,
        setGlobalResponses,
        openDrawer,
        closeDrawer,
        Save,
      }}>
      {children}
      <GlobalResponseSet
        isOpen={GlobalResponsesModalOperations.isOpen}
        onClose={GlobalResponsesModalOperations.onClose}
        canBeDeleted={false}
        key={globalResponses.id ?? moment().unix()}
      />
    </GlobalResponseDrawerContext.Provider>
  );
}
export function useGlobalResponseDrawer() {
  const context = useContext<GlobalResponseDrawerContext>(
    GlobalResponseDrawerContext,
  );
  if (context === undefined) {
    throw new Error(
      'useGlobalResponse must be used within a GlobalResponseProvider',
    );
  }
  return context;
}
