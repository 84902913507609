import {
  Avatar,
  Box,
  Button,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import { RiArchiveDrawerFill } from "react-icons/ri";
import { TbGitMerge } from "react-icons/tb";
import { AddNewGroup } from "./Components/AddNewGroup";

import { useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { ContactApi } from "../../Api/Contact";
import { PaginationControl } from "../PayrollEmployee/PaginationControl/PaginationControl";
import { Archive } from "./Components/Modal/Archieve";
import DeleteContact from "./Components/Modal/DeleteContact";
import { MergeModal } from "./Components/Modal/MergeModal";
import { SortingFilter } from "./Components/SortingFilter";

interface Props {
  setLastUpdated: React.Dispatch<React.SetStateAction<number>>;
  lastUpdated: number;
  activeState: boolean;
  index: number;
  setSelectedId: React.Dispatch<React.SetStateAction<string[]>>;
  selectedId: string[];
}
export function AllContactsTable({
  lastUpdated,
  setLastUpdated,
  index,
  activeState,
  setSelectedId,
  selectedId,
}: Props) {
  const achieveModal = useDisclosure();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const deleteModal = useDisclosure();
  const mergeModal = useDisclosure();

  const [contactData, setContactData] = useState<
    Array<ContactApi.FetchContact>
  >([]);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState(undefined);
  const [limit, setLimit] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const navigate = useNavigate();
  const pageCount = Math.ceil(count / limit);
  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };
  const renderPageNumbers = () => {
    const pageButtons = [];

    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            aria-label={`Page ${i}`}
            border={"1px solid #e8eaf2"}
            borderRadius={"full"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }
    } else {
      pageButtons.push(
        <IconButton
          key={1}
          aria-label={`Page 1`}
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          backgroundColor={pageNumber === 1 ? "#3454D1" : "#fff"}
          color={pageNumber === 1 ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === 1 ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>1</Text>}
          onClick={() => handlePageChange(1)}
        />
      );

      if (pageNumber > 3) {
        pageButtons.push(<Text key="left-dots">...</Text>);
      }

      const startPage = Math.max(2, pageNumber - 1);
      const endPage = Math.min(pageCount - 1, pageNumber + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            border={"1px solid #e8eaf2"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            aria-label={`Page ${i}`}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }

      if (pageNumber < pageCount - 2) {
        pageButtons.push(<Text key="right-dots">...</Text>);
      }

      pageButtons.push(
        <IconButton
          key={pageCount}
          border={"1px solid #e8eaf2"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          borderRadius={"full"}
          aria-label={`Page ${pageCount}`}
          backgroundColor={pageNumber === pageCount ? "#3454D1" : "#fff"}
          color={pageNumber === pageCount ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === pageCount ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>{pageCount}</Text>}
          onClick={() => handlePageChange(pageCount)}
        />
      );
    }

    return pageButtons;
  };
  const debounced = useDebouncedCallback((value) => {
    setSearchText(value);
    setPageNumber(1);
  }, 1000);
  useEffect(() => {
    ContactApi.FetchContactDetails(
      {
        name: searchText ? searchText : undefined,
        isActive: activeState,
        limit: limit,
        page: pageNumber,
      },
      (data) => {
        setContactData(data.data);
        setCount(data?.total);
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      }
    );
  }, [lastUpdated, searchText, limit, pageNumber, activeState]);

  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedRows, setCheckedRows] = useState<number[]>([]);

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isAllChecked = event.target.checked;
    if (isAllChecked) {
      const allRowIds = contactData.map((item, i) => i);
      setCheckedRows(isCheckedAll ? [] : allRowIds);

      setSelectedId(contactData.map((item) => item.id));
    } else {
      setCheckedRows([]);
      setSelectedId([]);
    }

    setIsCheckedAll(isAllChecked);
  };

  const handleCheckRow = (rowIndex: number) => {
    const updatedRows = [...checkedRows];
    const rowIndexInCheckedRows = updatedRows.indexOf(rowIndex);

    if (rowIndexInCheckedRows !== -1) {
      updatedRows.splice(rowIndexInCheckedRows, 1);
    } else {
      updatedRows.push(rowIndex);
    }

    setCheckedRows(updatedRows);
    setIsCheckedAll(updatedRows.length === contactData.length);
    const selectedIds = updatedRows.map((index) => contactData?.[index]?.id);
    setSelectedId(selectedIds);
  };
  return (
    <Stack
      _light={{
        bg: "customColor.white",
      }}
    >
      <Stack
        gap={0}
        // maxWidth={"1164px"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        borderRadius="4px"
        bg={"#fff"}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        borderWidth="1px"
        borderStyle={"solid"}
        borderColor="#f6f6f6"
        p={"0px"}
      >
        <Stack p={"25px"} gap={0} borderBottom={"1px solid #e5e7eb"}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px"}
          >
            Search
          </Text>
          <HStack>
            <Stack gap={0} flex={1}>
              <InputGroup ml={[0, "0"]}>
                <Input
                  type="text"
                  placeholder="Search"
                  borderRadius={"3px"}
                  h={["auto", "38px"]}
                  _focusVisible={{
                    border: "1px solid grey",
                  }}
                  onChange={(e) => {
                    debounced(e.target.value);
                  }}
                />
              </InputGroup>
            </Stack>
            <SortingFilter />
          </HStack>
        </Stack>

        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          p={"10px 25px"}
        >
          <AddNewGroup
            selectedId={selectedId}
            lastUpdated={lastUpdated}
            setLastUpdated={setLastUpdated}
          />

          <Button
            h={"32px"}
            isDisabled={!selectedId || selectedId.length === 0}
            color={"#3454d1"}
            fontSize={"15px"}
            bgColor={"customColor.white"}
            leftIcon={<TbGitMerge />}
            fontWeight={"700"}
            variant={"ghost"}
            _hover={{
              bg: "#f2f3f4",
            }}
            _active={{
              bg: "#f2f3f4",
            }}
            onClick={() => {
              mergeModal.onOpen();
            }}
          >
            Merge
          </Button>

          <Button
            h={"32px"}
            color={"#3454d1"}
            fontSize={"15px"}
            bgColor={"customColor.white"}
            isDisabled={!selectedId || selectedId.length === 0}
            leftIcon={<RiArchiveDrawerFill />}
            fontWeight={"700"}
            variant={"ghost"}
            _hover={{
              bg: "#f2f3f4",
            }}
            _active={{
              bg: "#f2f3f4",
            }}
            onClick={() => achieveModal.onOpen()}
          >
            {index === 0 ? "Archive" : index === 3 ? "Unarchive" : ""}
          </Button>
        </Stack>

        <DeleteContact
          isOpen={deleteModal.isOpen}
          onClose={deleteModal.onClose}
          id={deleteId}
          setLastUpdated={setLastUpdated}
        />
        <Archive
          isOpen={achieveModal.isOpen}
          onClose={achieveModal.onClose}
          selectedId={selectedId}
          setLastUpdated={setLastUpdated}
          index={index}
        />

        <MergeModal isOpen={mergeModal.isOpen} onClose={mergeModal.onClose} />
        <Box w={"100%"} padding={"10px 25px"} justifyContent={"flex-end"}>
          <PaginationControl
            limit={limit}
            setLimit={setLimit}
            setPageNumber={setPageNumber}
          />
        </Box>
        <Skeleton isLoaded={!isLoading} height="100%">
          <Box overflowX="auto" p="0px">
            <Table variant="simple" w="100%" overflowX="auto">
              <Thead h={"36.8px"}>
                <Tr
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                >
                  <Th
                    padding="8px 26px 8px 30px"
                    borderBottomColor="borders.tableBorder"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    fontWeight={700}
                    whiteSpace="nowrap"
                    fontSize="12px"
                    textTransform="none"
                  >
                    <Checkbox
                      size="md"
                      borderColor="#adb5bd"
                      bgColor="white"
                      isChecked={isCheckedAll}
                      onChange={handleCheckAll}
                      //   isChecked={isCheckedAll}
                      //   onChange={handleHeaderCheckboxChange}
                    />
                  </Th>
                  {[
                    {
                      name: "Contact",
                      value: "75%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "You owe",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "They owe",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },

                    {
                      name: "",
                      value: "5%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                  ].map((item, i) => {
                    return (
                      <Th
                        key={i}
                        padding={item.paddingValue}
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                        width={item.value}
                      >
                        {item.name}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody padding={0}>
                {contactData.map((item, i) => {
                  return (
                    <Tr
                      key={i}
                      _hover={{
                        bgColor: "#f2f3f8",
                        cursor: "pointer",
                      }}
                    >
                      <Td
                        padding="15px 15px 15px 30px"
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        fontWeight={500}
                        whiteSpace="nowrap"
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                        onClick={(e: any) => e.stopPropagation()}
                      >
                        <Checkbox
                          size="md"
                          borderColor="#adb5bd"
                          bgColor="white"
                          isChecked={checkedRows.includes(i)}
                          onChange={() => handleCheckRow(i)}
                          // Stop propagation here
                        />
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        <Stack direction={"row"}>
                          <Avatar
                            name={item.name}
                            borderRadius={"3px"}
                            size={"sm"}
                          />
                          <Stack gap={0}>
                            <Text
                              fontWeight={600}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                            >
                              {item.name}
                            </Text>
                            <Text
                              fontWeight={400}
                              fontSize="11px"
                              color={"customColor.gray.64748B"}
                            >
                              {`${item.deliveryAddress?.addresss ?? ""}
                            `}
                            </Text>
                            <HStack>
                              {/* <Text
                                fontWeight={400}
                                fontSize="11px"
                                color={"customColor.gray.64748B"}
                              >
                                {item.primaryPerson?.email}
                              </Text> */}
                              <UnorderedList>
                                <ListItem
                                  fontWeight={400}
                                  fontSize="11px"
                                  color={"customColor.gray.64748B"}
                                >
                                  {" "}
                                  {`${
                                    item.businessInformation?.phoneCountry ?? ""
                                  }${
                                    item.businessInformation?.phoneArea ?? ""
                                  }${
                                    item.businessInformation?.phoneNumber ?? ""
                                  }`}
                                </ListItem>
                              </UnorderedList>
                            </HStack>
                          </Stack>
                        </Stack>
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        {""}
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        {""}
                      </Td>

                      <Td
                        padding="15px"
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        <Menu>
                          <MenuButton
                            as={Button}
                            padding={0}
                            backgroundColor={"transparent"}
                            h={"34.5px"}
                            marginLeft={"8px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            display={{
                              lg: "flex",
                              md: "flex",
                              sm: "flex",
                              xs: "flex",
                            }}
                            borderRadius={"full"}
                            _hover={{
                              backgroundColor: "primary.50",
                            }}
                            _active={{
                              backgroundColor: "primary.100",
                            }}
                          >
                            <Icon as={FiMoreVertical} />
                          </MenuButton>
                          <MenuList style={{ minWidth: "140px" }}>
                            {[
                              {
                                name: "Edit",
                                onClick: () => {
                                  navigate("/app/payroll/CreateContact", {
                                    state: {
                                      contactId: item.id,
                                    },
                                  });
                                },
                              },
                              {
                                name:
                                  index === 0
                                    ? "Archive"
                                    : index === 3
                                    ? "Unarchive"
                                    : "",
                                onClick: () => {
                                  achieveModal.onOpen();
                                  setSelectedId([item.id]);
                                },
                              },
                              {
                                name: "Delete",
                                onClick: () => {
                                  setDeleteId(item.id);
                                  deleteModal.onOpen();
                                },
                              },
                            ].map((menuItem, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  padding={"1px 0px 1px 20px"}
                                  onClick={menuItem.onClick}
                                  _light={{
                                    color: "customColor.black7",
                                  }}
                                  _dark={{
                                    color: "customColor.white",
                                  }}
                                >
                                  <Button
                                    variant={"ghost"}
                                    fontWeight={"normal"}
                                    fontSize={"13px"}
                                    padding={"0px 20px 0px 0px"}
                                    _light={{
                                      color: "customColor.black7",
                                    }}
                                    _dark={{
                                      color: "customColor.white",
                                    }}
                                    _hover={{ bg: "none" }}
                                  >
                                    {menuItem.name}
                                  </Button>
                                </MenuItem>
                              );
                            })}
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          <Stack borderTop={"1px solid #dcdee4 "}>
            <HStack
              marginTop={"9.35px"}
              padding={"25px"}
              fontSize={"13px"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
              justifyContent={"space-between"}
            >
              <Text
                fontWeight={"normal"}
                color={"customColor.6b7885"}
                _dark={{
                  color: "customColor.dark.200",
                }}
                fontSize={"11px"}
              >
                Showing {limit * (pageNumber - 1) + 1} to{" "}
                {limit + limit * (pageNumber - 1)} of {contactData?.length}{" "}
                entries
              </Text>
              <HStack spacing="13px" justify={{ xs: "center", md: "end" }}>
                <IconButton
                  aria-label="back"
                  border={"1px solid #e8eaf2"}
                  borderRadius={"full"}
                  backgroundColor="transparent"
                  _hover={{
                    backgroundColor: "#3454D1",
                    color: "white",
                  }}
                  onMouseEnter={() => setIsHovered2(true)}
                  onMouseLeave={() => setIsHovered2(false)}
                  icon={
                    <FaArrowLeft
                      size={15}
                      color={
                        isHovered2
                          ? "#FFFFFF"
                          : pageNumber <= 1 || pageNumber > pageCount
                          ? "#828ea0"
                          : "#283c50"
                      }
                    />
                  }
                  isDisabled={pageNumber <= 1 || pageNumber > pageCount}
                  onClick={() => {
                    if (pageNumber > 1 && pageNumber <= pageCount) {
                      pageNumberInputRef.current?.value &&
                        (pageNumberInputRef.current.value = String(
                          pageNumber - 1
                        ));
                      setPageNumber(pageNumber - 1);
                    }
                  }}
                />
                <HStack spacing="13px">{renderPageNumbers()}</HStack>
                <IconButton
                  aria-label="right"
                  backgroundColor="transparent"
                  border={"1px solid #e8eaf2"}
                  borderRadius={"full"}
                  _hover={{
                    backgroundColor: "#3454D1",
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  icon={
                    <FaArrowRight
                      size={15}
                      color={
                        isHovered
                          ? "#FFFFFF"
                          : pageNumber >= pageCount || pageNumber < 1
                          ? "#828ea0"
                          : "#283c50"
                      }
                      style={{ transition: "color 0.2s" }} // Add smooth transition for color change
                    />
                  }
                  isDisabled={pageNumber >= pageCount || pageNumber < 1}
                  onClick={() => {
                    if (pageNumber < pageCount && pageNumber >= 1) {
                      pageNumberInputRef.current?.value &&
                        (pageNumberInputRef.current.value = String(
                          pageNumber + 1
                        ));
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                />
              </HStack>
            </HStack>
          </Stack>
        </Skeleton>
      </Stack>
    </Stack>
  );
}
