import {
  Box,
  Button,
  ButtonGroup,
  Editable,
  EditablePreview,
  EditableTextarea,
  HStack,
  Icon,
  IconButton,
  Text,
  Textarea,
  useBreakpointValue,
  useEditableControls,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { GiNotebook } from "react-icons/gi";
import { MdDelete, MdOutlineAttachFile } from "react-icons/md";
import { RiCheckboxMultipleFill, RiCheckboxMultipleLine } from "react-icons/ri";
import { UploadAttachments } from "../../../../Payroll/Api/UploadAttachments";
import { fileToBase64 } from "../../../modules/api";
import { Inspection, Template } from "../../../modules/Audit";
import { compressImage } from "../../../modules/utils";
import { useInspectionContext } from "../hooks/InspectionContext";
import { useAuditQuestionContainer } from "./AuditQuestionContainer";

interface AuditItemActionsProps {
  noMediaOption?: boolean;
}
export default function AuditItemActions({
  noMediaOption,
}: AuditItemActionsProps) {
  const template = useFormContext();
  const noteText = useBreakpointValue({
    md: "Add Note...",
    xs: "Note...",
  });
  const mediaText = useBreakpointValue({
    md: "Attach Media",
    xs: "Media",
  });
  const actionText = useBreakpointValue({
    md: "Create Action",
    xs: "Action",
  });
  const { notesEditorOperations, createActionOperations, getItemField } =
    useAuditQuestionContainer();

  const inputRef = useRef<HTMLInputElement>(null);
  const watchedMedia = useFieldArray({
    control: template.control,
    name: getItemField("question.media"),
    keyName: "uniqueId",
  });

  const [uploadStatus, setUploadStatus] = useState({});
  const toast = useToast({
    position: "top",
  });
  const { setIsSaving, isSaving } = useInspectionContext();

  const handleFileUpload = async (file: File, index: any) => {
    try {
      const compressedImage = await compressImage(file);
      const base64 = await fileToBase64(compressedImage);
      if (base64) {
        const formData = new FormData();
        formData.append("docType", "INSPECTION");
        formData.append("base64", base64);
        formData.append("fileName", file.name);
        formData.append("inspectionId", template.getValues("mediaUniqueId"));
        UploadAttachments.UploadAttachment(
          formData,
          (path) => {
            watchedMedia.append({
              mediaType: file.type,
              mediaUrl: path.url,
              mediaName: file.name,
              generic: true,
            } as Template.MediaObject);

            setUploadStatus((prev) => ({ ...prev, [index]: "complete" }));
          },
          (err) => {
            toast({
              title: "Error",
              description: err,
            });
            setUploadStatus((prev) => ({ ...prev, [index]: "error" }));
          }
        );
      }
    } catch (err: any) {
      toast({
        title: "Error",
        description: err.message || "An error occured while uploading media",
      });
      setUploadStatus((prev) => ({ ...prev, [index]: "error" }));
    }
  };

  const handleChange = async (e: any) => {
    const files = e.target.files;
    if (files) {
      setUploadStatus({});
      for (let i = 0; i < files.length; i++) {
        setUploadStatus((prev) => ({ ...prev, [i]: "uploading" }));
        handleFileUpload(files[i], i);
      }
    }
  };

  const allUploadsComplete = () =>
    Object.values(uploadStatus).every((status) => status === "complete");

  const callAPIAfterUpload = () => {
    if (allUploadsComplete()) {
      const answerId = template.getValues(getItemField("question.answerId"));
      const type = template.getValues(getItemField("question.type"));
      const inspectionMetaID = template.getValues("inspection.id");
      setIsSaving(true);
      Inspection.CachingInspection(
        answerId,
        {
          inspection_meta_id: inspectionMetaID,
          type: type,
          media: watchedMedia.fields as unknown as Array<Template.MediaObject>,
        },
        () => {
          setIsSaving(false);
        },
        () => {
          setIsSaving(false);
        }
      );
    }
  };
  useEffect(() => {
    if (Object.keys(uploadStatus).length > 0) {
      callAPIAfterUpload();
    }
  }, [uploadStatus]);

  // const watchedMediaArr = useWatch({
  //   control: template.control,
  //   name: getItemField('question.media'),
  // });

  return (
    <HStack pt="12px" justify="end">
      <ButtonGroup variant="outline" spacing="0" border="none" fontSize="14px">
        <Button
          padding="4px 8px"
          leftIcon={<GiNotebook color="#1da5d4" size={"16"} />}
          border="none"
          fontWeight={400}
          onClick={notesEditorOperations?.[1].on}
        >
          {noteText}
        </Button>
        {!noMediaOption && (
          <Fragment>
            <Button
              padding="4px 8px"
              leftIcon={<MdOutlineAttachFile color="#1da5d4" size={"16"} />}
              border="none"
              fontWeight={400}
              onClick={() => {
                inputRef.current?.click();
              }}
            >
              {mediaText}
            </Button>

            <input
              ref={inputRef}
              hidden
              multiple
              accept="image/*,video/*,application/pdf"
              type="file"
              onChange={handleChange}
              // onChange={
              // async e => {
              // const files = e.target.files;
              // if (files) {
              //   for (let i = 0; i < files.length; i++) {
              //     const compressedImage = await compressImage(files[i]);
              //     const base64 = await fileToBase64(compressedImage);

              //     watchedMedia.append({
              //       generic: true,
              //       mediaName: files[i].name,
              //       mediaType: files[i].name.split('.').pop() ?? '',
              //       base64,
              //     } as Template.MediaObject);
              //   }
              // }

              // const files = e.target.files;
              //   if (files) {
              //     console.log(files);
              //     const mediaPromises: Array<any> = [];
              //     console.timeLog('files', files);
              //     for (let i = 0; i < files.length; i++) {
              //       console.log(i);
              //       const compressedImage = await compressImage(files[i]);

              //       const base64 = await fileToBase64(files[i]);

              //       mediaPromises.push(
              //         new Promise(async (resolve, reject) => {
              //           try {
              //             Inspection.UploadMedia(
              //               {
              //                 base64: base64,
              //                 fileName: files[i].name,
              //                 inspectionId: template.getValues('mediaUniqueId'),
              //               },
              //               path => {
              //                 resolve({
              //                   generic: true,
              //                   mediaName: files[i].name,
              //                   mediaType: files[i].name.split('.').pop() ?? '',
              //                   mediaUrl: path,
              //                 });
              //               },
              //               error => {
              //                 reject(error);
              //               },
              //             );
              //           } catch (error) {
              //             reject(error);
              //           }
              //         }),
              //       );
              //     }
              //     console.timeEnd('files');

              //     Promise.all(mediaPromises)
              //       .then((mediaObjects: any) => {
              //         watchedMedia.append(mediaObjects);
              //         const type = template.getValues(
              //           getItemField('question.type'),
              //         );
              //         const answerId = template.getValues(
              //           getItemField('question.answerId'),
              //         );
              //         const InspectionId = template.getValues('inspection.id');
              //         Inspection.CachingInspection(
              //           answerId,
              //           {
              //             media: mediaObjects,
              //             type: type,
              //             inspection_meta_id: InspectionId,
              //           },
              //           () => {},
              //           () => {},
              //         );
              //       })
              //       .catch(error => {
              //         console.error('Error processing media files:', error);
              //       });
              //   }
              // }
              // }
            />
          </Fragment>
        )}
        <Button
          padding="4px 8px"
          leftIcon={<RiCheckboxMultipleLine color="#1da5d4" size={"16"} />}
          border="none"
          fontWeight={400}
          onClick={createActionOperations?.[1].on}
        >
          {actionText}
        </Button>
      </ButtonGroup>
    </HStack>
  );
}

export function AddNote() {
  const template = useFormContext();
  const { getItemField } = useAuditQuestionContainer();
  const inputRef = useRef<HTMLDivElement>(null);
  const { notesEditorOperations } = useAuditQuestionContainer();
  const { setIsSaving, isSaving } = useInspectionContext();
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const existingNotes =
    template.getValues(getItemField("question.notes")) ?? "";
  const [notes, setNotes] = useState(existingNotes);

  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <HStack marginTop="8px" justifyContent="space-between">
        {/* <ButtonGroup justifyContent="center" size="sm"> */}
        <Button
          h="41px"
          variant="solid"
          borderRadius="8px"
          padding="12px 20px"
          fontSize="14px"
          fontWeight="500"
          {...getSubmitButtonProps({
            onClick: () => {
              console.log(isSaving);
              template.setValue(getItemField("question.notes"), notes);

              const type = template.getValues(getItemField("question.type"));
              const answerId = template.getValues(
                getItemField("question.answerId")
              );
              const InspectionId = template.getValues("inspection.id");
              setIsSaving(true);
              Inspection.CachingInspection(
                answerId,
                {
                  notes: notes,
                  type: type,
                  inspection_meta_id: InspectionId,
                },
                () => {
                  setIsSaving(false);
                },
                () => {
                  setIsSaving(false);
                }
              );
              console.log(isSaving);
            },
          })}
        >
          Save
        </Button>
        <Button
          h="41px"
          variant="outline"
          borderRadius="8px"
          padding="12px 20px"
          fontSize="14px"
          fontWeight="500"
          {...getCancelButtonProps({
            onClick: () => {
              console.log("Cancelled");
            },
          })}
        >
          Cancel
        </Button>
        {/* </ButtonGroup> */}
        <IconButton
          justifyContent="center"
          alignSelf="center"
          backgroundColor="transparent"
          borderRadius={"50%"}
          border={"none"}
          size="22.5px"
          _hover={{ backgroundColor: "#bfc6d4" }}
          aria-label="delete"
          icon={<MdDelete color="#545f70" size="22.5px" />}
          onClick={notesEditorOperations?.[1].off}
        />
      </HStack>
    ) : (
      <EditablePreview
        w="100%"
        fontSize={"14px"}
        fontWeight={"400"}
        color="textcolors.secondary"
        padding={"4px"}
        _hover={{
          color: "icons.primary",
        }}
        ref={inputRef}
        {...getEditButtonProps()}
      />
    );
  }

  return (
    <Editable
      w="100%"
      placeholder="Type your notes..."
      marginTop={"8px"}
      isPreviewFocusable={false}
      color={"textcolors.primary"}
      whiteSpace="pre-line"
      value={notes}
      onChange={(e) => {
        setNotes(e);
      }}
    >
      <EditableTextarea as={Textarea} />
      <EditableControls />
    </Editable>
    // <Textarea
    //   // ref={inputRef}
    //   w="100%"
    //   placeholder="Type your notes..."
    //   marginTop={'8px'}
    //   color={'textcolors.primary'}
    //   value={notes}
    //   onChange={e => {
    //     setNotes(e.target.value);
    //   }}
    // />
  );
}

export function CreatedAction() {
  return (
    <HStack
      w="100%"
      justifyContent="space-between"
      border="1px solid"
      borderColor="borders.accent"
      borderRadius="8px"
      padding="8px 12px"
    >
      <HStack>
        <Icon as={RiCheckboxMultipleFill} boxSize={"16px"} />
        <Text fontSize="12px" fontWeight={400} color="textcolors.primary">
          hello
        </Text>
      </HStack>
      <Box
        padding="4px 8px"
        border="1px solid"
        borderColor="#bd5800"
        backgroundColor="#fffae5"
        borderRadius="16px"
      >
        <Text fontSize="12px" fontWeight={400}>
          To do
        </Text>
      </Box>
    </HStack>
  );
}
export function CreateAction() {
  const { createActionOperations } = useAuditQuestionContainer();
  return (
    <VStack>
      <Textarea
        w={"100%"}
        placeholder="What needs to be done?"
        border="1px solid"
        borderColor="borders.accent"
        fontSize="16px"
        fontWeight={400}
        color="textcolors.primary"
        padding="8px 16px"
        maxLength={255}
        _focusVisible={{
          boxShadow: "none",
          border: "1px sloid",
          borderColor: "primary.500",
        }}
      />
      <HStack spacing={2} w="100%">
        <Button
          h="41px"
          variant="solid"
          borderRadius="8px"
          padding="12px 20px"
          fontSize="14px"
          fontWeight="500"
        >
          Create
        </Button>
        <Button
          h="41px"
          variant="outline"
          borderRadius="8px"
          padding="12px 20px"
          fontSize="14px"
          fontWeight="500"
          onClick={createActionOperations?.[1].off}
        >
          Cancel
        </Button>
      </HStack>
    </VStack>
  );
}
