import { axiosInstance } from "./axiosInstance-aud";
import { Inspection } from "./Inspection";

declare module PeriodicalSchedule {
  type Day =
    | "MONDAY"
    | "TUESDAY"
    | "WEDNESDAY"
    | "THURSDAY"
    | "FRIDAY"
    | "SATURDAY"
    | "SUNDAY";
  type Month =
    | "JANUARY"
    | "FEBRUARY"
    | "MARCH"
    | "APRIL"
    | "MAY"
    | "JUNE"
    | "JULY"
    | "AUGUST"
    | "SEPTEMBER"
    | "OCTOBER"
    | "NOVEMBER"
    | "DECEMBER";
  type Position = "FIRST" | "SECOND" | "THIRD" | "FOURTH" | "LAST";
  type AddPeriodicSchedule = {
    templateId: number;
    location: string;
    areas: Array<string>;
    employees: Array<string>;
    dateRange: {
      startDate: string;
      endDate: string;
    };
    frequency: "DAILY" | "WEEKLY" | "MONTHLY" | "ANNUALLY" | "ON_REQUEST";
    timeRange: {
      startTime: string;
      endTime: string;
    };
    locationTimeZone: string;
    activityId: number;
    color: string;
    properties: {
      selection:
        | "DAY"
        | "WEEKDAY"
        | "WEEK"
        | "NO_OF_MONTH"
        | "CUSTOM_MONTH"
        | "NO_OF_YEAR"
        | "CUSTOM_YEAR"
        | "ON_REQUEST";

      daily?: {
        every?: number;
        onlyWeekdays?: boolean;
      };
      weekly?: {
        numOfWeeks: number;
        days: Array<Day>;
      };
      monthly?: {
        dayOfMonth?: number;
        every: number;
        dayPosition?: Position;
        day?: Day;
      };
      annually?: {
        every: number;
        month: Month;
        monthDate?: number;
        dayPosition?: Position;
        day?: Day;
      };
    };
  };
  type GetPeriodicalSchedule = {
    id: number;
    templateId: number;
    location: string;
    areas: Array<string>;
    employees: Array<string>;
    dateRange: {
      startDate: string;
      endDate: string;
    };
    frequency: "DAILY" | "WEEKLY" | "MONTHLY" | "ANNUALLY" | "ON_REQUEST";
    timeRange: {
      startTime: string;
      endTime: string;
    };

    activityId: number;
    color: string;
    properties: {
      selection:
        | "DAY"
        | "WEEKDAY"
        | "WEEK"
        | "NO_OF_MONTH"
        | "CUSTOM_MONTH"
        | "NO_OF_YEAR"
        | "CUSTOM_YEAR"
        | "ON_REQUEST";

      daily?: {
        every?: number;
        onlyWeekdays?: boolean;
      };
      weekly?: {
        numOfWeeks: number;
        days: Array<Day>;
      };
      monthly?: {
        dayOfMonth?: number;
        every: number;
        dayPosition?: Position;
        day?: Day;
      };
      annually?: {
        every: number;
        month: Month;
        monthDate?: number;
        dayPosition?: Position;
        day?: Day;
      };
    };
  };
  type GetPeriodicalScheduleToday = {
    id: number;
    templateId: number;
    inspectionId: number | null;
    status: string;
    dateRange: {
      startDate: string;
      endDate: string;
    };
    timeRange: {
      endTime: string;
      startTime: string;
    };
    completedBy: string | null;
    color: string;
    scheduleId: number;
    periodicActivity: {
      createdAt: string;
      updatedAt: string;
      id: number;
      name: string;
      description: string;
      code: string;
      color: string | null;
    };
  };

  type UpdateStatus = {
    status: number;
    periodicScheduleEventId: number;
    inspectionId?: number | null;
    completedBy?: string | null;
  };

  type ReportEventsData = {
    eventid: string;
    color: string;
    status: string;
    inspectionId: number | null;
    templateId: number;
    completedDate: string | null;
    completedBy: string | null;
    location: string;
    areas: Array<string>;
    activityId: number;
    dateRange: {
      startDate: string;
      endDate: string;
    };
  };
  type PeriodicalScheduleReport = {
    weekName: string;
    weekDates: Array<{
      date: string;
      day: string;
      events: Array<ReportEventsData>;
    }>;
  };
}
module PeriodicalSchedule {
  const URL = "/periodic-activity-schedules";
  export function AddPeriodicalSchedule(
    data: AddPeriodicSchedule,
    success: (response: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${URL}`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function UpdatePeriodicalSchedule(
    id: number,
    data: Partial<AddPeriodicSchedule>,
    success: (response: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${URL}/${id}`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function UpdatePeriodicEventStatus(
    data: Inspection.PostPeriodicalActivities,
    success: (response: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post("/periodic-activity-schedules/update/status", data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchPeriodicalSchedule(
    success: (response: Array<GetPeriodicalSchedule>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchPeriodicalScheduleToday(
    params: {
      showEventsFor: "today" | "last 7 days" | "last month" | "all";
    },
    success: (response: Array<GetPeriodicalScheduleToday>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}/get-schedule-events`, { params })
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchPeriodicalScheduleForInspection(
    params: {
      locationId: string;
      status: string;
    },
    success: (response: Array<GetPeriodicalScheduleToday>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}/get-all-schedule-events`, { params })
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchPeriodicalScheduleReport(
    params: {
      frequency?: string;
      startDate?: string;
      endDate?: string;
      templateId?: number;
      activityId?: number;
      locationId?: string;
    },
    success: (response: Array<PeriodicalScheduleReport>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}/search`, { params })
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function DeletePeriodicalSchedule(
    id: number,
    success: (response: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${URL}/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { PeriodicalSchedule };
