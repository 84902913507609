import { axiosInstance } from "./axiosInstance-aud";
import { Common } from "./Common";

// TODO: Need to review whole module with EMAN
declare module WidgetValue {
  interface WidgetValue {
    id: number;
    questionId: number;
    attributeName: string;
    attributeValue: string;
  }
}
module WidgetValue {
  const API_BASE_URL = "/widget-values";
  export function Fetch(
    success: (response: Array<WidgetValue>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(API_BASE_URL)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function Create(
    data: WidgetValue,
    success: (response: WidgetValue) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(API_BASE_URL, data)
      .then(({ data }) => {
        const response = data as Common.Success<WidgetValue>;
        success(response.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function GetById(
    id: number,
    success: (response: WidgetValue) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_BASE_URL}/${id}`)
      .then(({ data }) => {
        const response = data as Common.Success<WidgetValue>;
        success(response.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function Update(
    id: number,
    questionId: number,
    attributeName: string,
    data: Partial<WidgetValue>,
    success: (response: WidgetValue) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .put(`${API_BASE_URL}/${questionId}/${attributeName}`, data)
      .then(({ data }) => {
        const response = data as Common.Success<WidgetValue>;
        success(response.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function Delete(
    questionId: number,
    attributeName: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_BASE_URL}/${questionId}/${attributeName}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { WidgetValue };
