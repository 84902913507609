import { axiosInstance } from "./axiosInstance-aud";
import { Common } from "./Common";

// TODO: fix templateItems after confirming from EMAN
declare module Widget {
  interface Widget {
    name: string;
    type: string;
    disabled: boolean;
    id: number;
  }
}
module Widget {
  const API_BASE_URL = "/widgets";
  export function Fetch(
    success: (response: Array<Widget>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(API_BASE_URL)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function Create(
    data: Widget,
    success: (response: Widget) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(API_BASE_URL, data)
      .then(({ data }) => {
        const response = data as Common.Success<Widget>;
        success(response.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function GetById(
    id: number,
    success: (response: Widget) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_BASE_URL}/${id}`)
      .then(({ data }) => {
        const response = data as Common.Success<Widget>;
        success(response.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function Update(
    id: number,
    data: Partial<Widget>,
    success: (response: Widget) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_BASE_URL}/${id}`, data)
      .then(({ data }) => {
        const response = data as Common.Success<Widget>;
        success(response.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function Delete(
    id: number,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_BASE_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { Widget };
