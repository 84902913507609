import {
  Button,
  HStack,
  IconButton,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { FaPen } from "react-icons/fa";
import { MCQ } from "../../../modules/Audit/MCQs";
import { getFieldKey } from "../../../modules/form-apis";
import { useItemContext } from "../hooks/auditItemContext";
import { useGlobalResponseDrawer } from "../hooks/globalResponsesDrawer";
import { useMultiChoiceContext } from "../hooks/multiChoiceContext";
import { useTemplateBuilder } from "../hooks/templateBuilderContext";

interface GlobalOptionprops {
  onPopoverClose: Function;
}
export default function GlobalOptions({ onPopoverClose }: GlobalOptionprops) {
  const { openDrawer, globalResponses } = useGlobalResponseDrawer();
  const { globalOptionSets } = useMultiChoiceContext();
  return (
    <Fragment>
      <VStack
        display={{
          xs: "block",
          md: "block",
        }}
        w={"100%"}
        spacing={0}
      >
        <HStack
          w={"100%"}
          padding={"9.5px 14px"}
          justifyContent={"space-between"}
        >
          <Text
            align={"start"}
            fontSize={"13px"}
            fontWeight={400}
            color={"icons.primary"}
          >
            Global Response Sets
          </Text>
          <Link
            fontSize={"13px"}
            fontWeight={400}
            onClick={() => {
              // setSelectedOption(QuestionTypeButtons.GLOBAL_RESPONSE);
              onPopoverClose();
              setTimeout(() => {
                openDrawer();
              }, 300);
            }}
          >
            + Responses
          </Link>
        </HStack>

        <VStack spacing="8px">
          {globalOptionSets.map((set) => (
            <GlobalOptionButton {...set} onPopoverClose={onPopoverClose} />
          ))}

          <Button
            color="#4740d4"
            border={"1px"}
            borderColor={"borders.accent"}
            variant="outline"
            fontSize={"14px"}
            fontWeight={500}
            borderRadius={"5px"}
            onClick={() => {
              onPopoverClose();
              setTimeout(() => {
                openDrawer();
              }, 300);
            }}
            display={globalOptionSets.length > 0 ? "none" : "block"}
          >
            Create my first Global Response Set
          </Button>
        </VStack>
      </VStack>
    </Fragment>
  );
}

function GlobalOptionButton({
  onPopoverClose,
  ...props
}: MCQ.IMCQ & { onPopoverClose: Function }) {
  const { widgets } = useTemplateBuilder();
  const { openDrawer, closeDrawer } = useGlobalResponseDrawer();
  const { item, updateQuestionType } = useItemContext();
  const template = useFormContext();
  return (
    <HStack
      w={"100%"}
      justifyContent={"space-between"}
      padding={"9.6px 14px"}
      _hover={{ backgroundColor: "gray.200" }}
      onClick={() => {
        const widgetDetails = widgets.find((w) => w.type === "MCQs");
        if (widgetDetails) {
          updateQuestionType("MCQs", widgetDetails.id);
          template.setValue(getFieldKey(item, "question.mcqsData"), props);
          onPopoverClose();
        }
      }}
    >
      <Text fontSize="14px" color="textcolors.primary">
        {props.name}
      </Text>
      <IconButton
        aria-label="edit"
        variant="ghost"
        _hover={{ backgroundColor: "gray.400" }}
        borderRadius="50%"
        icon={<FaPen color="#545f70" />}
        onClick={() => {
          openDrawer(props);
        }}
      />
    </HStack>
  );
}
