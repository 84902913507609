import { Box, HStack, Stack, VStack } from "@chakra-ui/react";
import { RequireAuth, useAuthHeader } from "react-auth-kit";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import {
  BusinessContextProvider,
  useBusinessContext,
} from "./Hooks/BusinessContext";
import { Invite } from "./Payroll/Pages/Accounting/Advanced/InviteUser/InviteUser";
import { UpdatePermission } from "./Payroll/Pages/Accounting/Advanced/UpdatePermission";
import { User } from "./Payroll/Pages/Accounting/Advanced/User";
import { AddAccount } from "./Payroll/Pages/Accounting/ChartOfAccounting/AddAccount";
import { AddBankAccount } from "./Payroll/Pages/Accounting/ChartOfAccounting/AddBankAccount";
import { ChartOfAccount } from "./Payroll/Pages/Accounting/ChartOfAccounting/ChartOfAccount";
import { TaxRates } from "./Payroll/Pages/Accounting/TaxRates/TaxRates";
import { AddEmployee } from "./Payroll/Pages/AddEmployee/AddEmployee";
import { AllFeatures } from "./Payroll/Pages/all Features/allFeatures";
import { Employee } from "./Payroll/Pages/Employee/Employee";
import { EmployeeDetail } from "./Payroll/Pages/EmployeeDetails/EmployeeDetail";
import LeaveDummy from "./Payroll/Pages/EmployeeDetails/Tabs/Leaves/LeaveDummy";
import { ConfirmPasswordReset } from "./Payroll/Pages/Forgot/ConfirmPasswordReset";
import { ForgotPassword } from "./Payroll/Pages/Forgot/forgotPassword";
import { ResetPassword } from "./Payroll/Pages/Forgot/ResetPassword";
import { Login } from "./Payroll/Pages/Login/login";
import Menu from "./Payroll/Pages/Menu/Menu";
import { AdvancedSettings } from "./Payroll/Pages/Navbar/Setting/AdvancedSettings";
import { OrganisationSettings } from "./Payroll/Pages/Navbar/Setting/OrganisationSettings";

import { OrganizationDetailParent } from "./Payroll/Pages/Orgdetails/OrganizationDetailParent";
import PayEmployee from "./Payroll/Pages/PayEmployee/PayEmployee";
import { PayRunDetail } from "./Payroll/Pages/PayEmployee/PayRunDetail";
import { PayRunPaySlip } from "./Payroll/Pages/PayEmployee/PayRunPaySlip";
import { Finish } from "./Payroll/Pages/PayEmployee/Screens/Finish";
import SingleTouchSettings from "./Payroll/Pages/PayEmployee/SingleTouchSettings";
import { PayrollEmployee } from "./Payroll/Pages/PayrollEmployee/PayrollEmployee";
import Orgtab from "./Payroll/Pages/PayrollSettings/Orgtab";
// import { DetailReport } from "./Payroll/Pages/Report/DetailReport";
import EmployeeReport from "./Payroll/Pages/Report/EmployeeReport";
import { SignUp } from "./Payroll/Pages/SignUp/signup";
import { TimeSheetDetail } from "./Payroll/Pages/TimeSheet/TimeSheetDetail";
import { TimeSheetEnterDetail } from "./Payroll/Pages/TimeSheet/TimeSheetEnterDetail";
import WelcomePage from "./Payroll/Pages/WelcomePage/WelcomePage";

import { useState } from "react";
import NewHeader from "./Common/Header/NewHeader";
import NewDashboard from "./Common/NewDashbaord/NewDashboard";
import { default as PageNotFound } from "./Common/PageNotFound";

import { DashboardContextProvider } from "./Common/Hooks/DashboardContext";
import BasicLayout from "./Common/NewDashbaord/ResizableDashboard";
import SideBar from "./Common/SideBar/Sidebar";
import {
  AccessControlContextProps,
  AccessControlProvider,
  useAccessControl,
} from "./Hooks/AccessControlContext";
import { Import } from "./Payroll/Pages/Accounting/ChartOfAccounting/Tabs/Import/Import";
import Reports from "./Payroll/Pages/Accounting/Reports/Reports";
import LeaveTab from "./Payroll/Pages/Leave/LeaveTab";
import { VerifyLogin } from "./Payroll/Pages/Login/VerifyLogin";

import { jwtDecode } from "jwt-decode";
import AnalogClock from "./Common/NewDashbaord/Components/AnalogClock";
import CollapseSetupGuide from "./Common/NewDashbaord/Guide/CollapseSetupGuide";
import { BillsToPayParent } from "./Payroll/Pages/BillsToPay/BillsToPayParent";

import { BillsToPay } from "./Payroll/Pages/BillsToPay/BillsToPay";
import { CreditBill } from "./Payroll/Pages/BillsToPay/CreditBill";
import { ChangePassword } from "./Payroll/Pages/ChangePassword/ChangePassword";
import { ChangePasswordEmail } from "./Payroll/Pages/ChangePassword/ChangePasswordEmail";
import { EmployeeDashboard } from "./Payroll/Pages/EmployeeDashboard/EmployeeDashboard";
import { TimesheetOver } from "./Payroll/Pages/EmployeeDashboard/Timesheet/TimeSheetOverView";
import { Timesheet } from "./Payroll/Pages/EmployeeDashboard/Timesheet/Timesheets";
import { EmployeeNotifications } from "./Payroll/Pages/EmployeeNotifications/EmployeeNotifications";
import { EmployeeTracking } from "./Payroll/Pages/EmployeeTracking/EmployeeTracking";
import { ClassicInvoice } from "./Payroll/Pages/Invoices/ClassicInvoice";
import { ImportForInvoices } from "./Payroll/Pages/Invoices/ImportForInvoices";
import { Invoices } from "./Payroll/Pages/Invoices/Invoices";
import { InvoicesParent } from "./Payroll/Pages/Invoices/InvoicesParent";
import { NewCreditNotes } from "./Payroll/Pages/Invoices/NewCreditNote";
import { MappingWindow } from "./Payroll/Pages/MappingModule/MappingWindow";
import SampleForOnBoarding from "./Payroll/Pages/onBoardingSample/SampleForOnBoarding";

import { AuditorDashboard } from "./Auditor/Pages/AuditorDashboard/auditorDashboard";
import AllInspection from "./Auditor/Pages/inspection/AllInspection-components/AllInspection";
import ConductInspection from "./Auditor/Pages/inspection/conduct-inspection";
import AuditorNotification from "./Auditor/Pages/Notification/notification";
import PeriodicalSchedule from "./Auditor/Pages/PeriodicalSchedule/PeriodicalSchedule";
import PeriodicalScheduleReport from "./Auditor/Pages/PeriodicalSchedule/PeriodicalScheduleReport/PeriodicalScheduleReport";
import ScheduleInspection from "./Auditor/Pages/ScheduleInspection/ScheduleInspection";
import AccessTable from "./Auditor/Pages/template/pages/access-components/AccessTable";
import CreateTemplate from "./Auditor/Pages/template/pages/build-components/create-template";
import TemplatesPage from "./Auditor/Pages/template/pages/MainPage-components/templates-page";
import WebPreview from "./Auditor/Pages/template/pages/report-components/Previews/WebPreview/WebPreview";
import { ClearanceForm } from "./Payroll/Pages/ClearanceForm/ClearanceForm";
import { AllContact } from "./Payroll/Pages/Contacts/AllContact";
import { CreateContact } from "./Payroll/Pages/Contacts/CreateContact";
import { ImportContact } from "./Payroll/Pages/Contacts/ImportContact";
import BulkExpenseClaim from "./Payroll/Pages/EmployeeExpenseClaim/BulkExpenseClaim";
import InvoicePreview from "./Payroll/Pages/Invoices/Components/InvoicePreview/InvoicePreview";
import { PaymentSummary } from "./Payroll/Pages/Invoices/PaymentSummary";
import { LeaveHistory } from "./Payroll/Pages/Leave/LeaveHistory";
import PostedPayslip from "./Payroll/Pages/PayEmployee/PostPayRun/PostedPaySlip";
import { PostPayRunDetails } from "./Payroll/Pages/PayEmployee/PostPayRun/PostPayRunDetails";
import { EmployeeBulkUploadPreview } from "./Payroll/Pages/PayrollEmployee/EmployeeBulkUploadPreview";
import PayslipDownloadMobile from "./Payroll/Pages/PayslipDownloadMobileComponent/PayslipDownloadMobile";
import { ImportForItems } from "./Payroll/Pages/Prooduct&Services/Import/ImportForItems";
import { OpeningBalances } from "./Payroll/Pages/Prooduct&Services/Import/OpeningBalances";
import { ProductAndServices } from "./Payroll/Pages/Prooduct&Services/ProductAndServices";
import { EmployeeContactDetailReport } from "./Payroll/Pages/Report/Reports/EmployeeContactDetailReport";
import { EmployeeDetailsReport } from "./Payroll/Pages/Report/Reports/EmployeeDetailsReport";
import { EmployeeRemunerationReport } from "./Payroll/Pages/Report/Reports/EmployeeRemunerationReport";
import { LeaveBalanceReport } from "./Payroll/Pages/Report/Reports/LeaveBalance";
import { LeaveRequestReport } from "./Payroll/Pages/Report/Reports/LeaveRequestReport";
import { PayrollActivityDetails } from "./Payroll/Pages/Report/Reports/PayrollActivityDetails";
import { PayrollActivitySummary } from "./Payroll/Pages/Report/Reports/PayrollActivitySummary";
import { PayrollEmployeeSummaryReport } from "./Payroll/Pages/Report/Reports/PayrollEmployeeSummaryReport";
import { TransactionLisitingDetailReport } from "./Payroll/Pages/Report/Reports/TransactionLisitingDetailReport";
import { TransactionLisitingSummaryReport } from "./Payroll/Pages/Report/Reports/TransactionLisitingSummaryReport";
import SubscriptionPlans from "./Payroll/Pages/SubscriptionModel/SubscriptionPlans";
import { Superannuation } from "./Payroll/Pages/Superannuation Folder/Superannuation";
import { SuperannuationPayment } from "./Payroll/Pages/Superannuation Folder/SuperannuationPayment";
import { SuperannuationPaymentDetails } from "./Payroll/Pages/Superannuation Folder/SuperannuationPaymentDetails";
import RedirectingBusiness from "./Payroll/Pages/SwitchBusiness/RedirectingBusiness";
import { SwitchBusiness } from "./Payroll/Pages/SwitchBusiness/SwitchBusiness";
import { TimeSheet } from "./Payroll/Pages/TimeSheet/TimeSheet";
import { TimeSheetParents } from "./Payroll/Pages/TimeSheet/TimesheetsParent";
import { TimeSheetEnterQuantityDetails } from "./Payroll/Pages/TimesheetQuantity/TimeSheetEnterQuantityDetails";
import { TimeSheetQuantity } from "./Payroll/Pages/TimesheetQuantity/TimeSheetQuantity";
import { TimeSheetQuantityDetail } from "./Payroll/Pages/TimesheetQuantity/TimeSheetQuantityDetail";
import { TrackingCategories } from "./Payroll/Pages/TrackingCategories/TrackingCategories";
import { UserProfile } from "./Payroll/Pages/UserProfile/UserProfile";
import Pricing from "./Payroll/Pricing/Pricing";
import AttendanceReport from "./TimeAttendance/pages/AttendanceReport/AttendanceReport";
import { InvoicesSetting } from "./TimeAttendance/pages/InvoicesSettings/InvoicesSetting";
import { AddLocation } from "./TimeAttendance/pages/Location/AddLocation";
import { LocationMain } from "./TimeAttendance/pages/Location/LocationMain";
import Notifications from "./TimeAttendance/pages/Notifications/Notifications";
import TimesheetReport from "./TimeAttendance/pages/Report/TimesheetReport";
import Schedule from "./TimeAttendance/pages/Schedule/Schedule";
export default function Router() {
  // const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box>
      {/* <Button onClick={toggleColorMode}>
        Toggle {colorMode === "light" ? "Dark" : "Light"}
      </Button> */}
      <Routes>
        <Route path="/" element={<MainPageRoutes />}></Route>
        <Route path="/welcome" element={<WelcomePage />}></Route>
        <Route path="/login" element={<Login />} />
        <Route path="/subscription" element={<SubscriptionPlans />} />
        <Route path="/downloadPaySlip" element={<PayslipDownloadMobile />} />
        <Route path="/ClearanceForm/:clearanceId" element={<ClearanceForm />} />
        <Route path="/signup" element={<SignUp />} />

        <Route path="/forgot" element={<ForgotPassword />} />
        {/* <Route path="/modal" element={<AddBusiness/>}/> */}
        {/* <Route path="/footer" element={<Footer />} /> */}
        {/* <Route path="/employee" element={<Contacts />} /> */}
        {/* <Route path="/payrollsettings" element={<Orgtab />} /> */}

        <Route path="/LeaveDummy" element={<LeaveDummy />} />
        <Route path="/Menu" element={<Menu />} />
        <Route path="/allFeatures" element={<AllFeatures />} />

        {/* <Route path="/Verify" element={<ConfirmEmail />} /> */}
        <Route path="/ResetPassword" element={<ResetPassword />} />

        <Route
          path="/ConfirmPasswordReset"
          element={<ConfirmPasswordReset />}
        />
        <Route path="/ConfirmEmail" element={<VerifyLogin />} />
        <Route path="/Finish" element={<Finish />} />
        <Route
          path={"/app/*"}
          element={
            <RequireAuth loginPath={"/login"}>
              <MainRoutes />
            </RequireAuth>
          }
        />
      </Routes>
    </Box>
  );
}
function MainRoutes() {
  return (
    <BusinessContextProvider>
      <Routes>
        <Route path="/SwitchBusiness" element={<SwitchBusiness />} />
        <Route path="/rediretingtobusiness" element={<RedirectingBusiness />} />
        <Route
          path="*"
          element={
            <AccessControlProvider>
              <ParentRoutes />
            </AccessControlProvider>
          }
        />
      </Routes>
    </BusinessContextProvider>
  );
}
function ParentRoutes() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const { currentBusinessId, setupGuide } = useBusinessContext();

  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  const isAllCompleted = (inputData: any): boolean => {
    return Object?.values(inputData?.completedItems)?.every(
      (value) => value === true
    );
  };
  const GuideCompleted = isAllCompleted(setupGuide);

  return (
    <HStack spacing={0}>
      <Box
        display={{
          xs: "absolute",
          md: "block",
        }}
        // width={"20%"}
        shadow="md"
        zIndex="1050"
      >
        {decodedToken.accessLevel !== "EMPLOYEE" && (
          <SideBar
            setIsSidebarOpen={setIsSidebarOpen}
            isSidebarOpen={isSidebarOpen}
            setIsHovered={setIsHovered}
            isHovered={isHovered}
          />
        )}
      </Box>
      <VStack
        width={
          decodedToken.accessLevel === "EMPLOYEE"
            ? "100%"
            : isHovered && decodedToken.accessLevel !== "EMPLOYEE"
            ? "calc(100% - 254.33px)"
            : isSidebarOpen && decodedToken.accessLevel !== "EMPLOYEE"
            ? "calc(100% - 254.33px)"
            : "calc(100% - 70px)"
        }
        h="100vh-70px"
        spacing={0}
      >
        <Box w="100%">
          <NewHeader
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        </Box>

        <Box w="100%">
          <Routes>
            <Route path="/Payroll/*" element={<PayrollRoutes />} />
            <Route
              path="/TimeAttendance/*"
              element={<TimeAttendanceRoutes />}
            />
            <Route path="/auditor/*" element={<AuditorRoutes />} />
          </Routes>
        </Box>
      </VStack>
      {decodedToken.accessLevel !== "EMPLOYEE" && (
        <Stack>{!GuideCompleted && <CollapseSetupGuide />}</Stack>
      )}
    </HStack>
  );
}
function MainPageRoutes() {
  return (
    <Box>
      {/* <MainNavBar /> */}
      <Box>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<Login />} />
        </Routes>
      </Box>
      {/* <Footer /> */}
    </Box>
  );
}

function TimeAttendanceRoutes() {
  const access = useAccessControl();

  const routesWithAccess: Array<{
    accessKey: keyof AccessControlContextProps;
    routes: Array<JSX.Element>;
  }> = [
    {
      accessKey: "timeAndAttendance.locations",
      routes: [
        <Route path="Location" element={<LocationMain />} />,
        <Route path="addLocation/:id?" element={<AddLocation />} />,
      ],
    },
    {
      accessKey: "timeAndAttendance.schedules",
      routes: [
        <Route path="Schedule" element={<Schedule />} />,
        <Route path="attendance-report" element={<AttendanceReport />} />,
      ],
    },
    {
      accessKey: "timeAndAttendance.statistics",
      routes: [
        <Route path="employeeTracking" element={<EmployeeTracking />} />,
      ],
    },
    {
      accessKey: "timeAndAttendance.checkinReport",
      routes: [<Route path="/checkinreport" element={<TimesheetReport />} />],
    },
    {
      accessKey: "timeAndAttendance.notifications",
      // routes: [
      //   <Route path="Notifications" element={<Notifications />} />,
      //   <Route
      //     path="/notification/:id?"
      //     element={<CreateUpdateNotification />}
      //   />,
      // ],
      routes: [
        <Route path="notificationSettings" element={<Notifications />} />,
        // <Route path="oldNotificationSettings" element={<Notifications />} />,
        // <Route
        //   path="/notification/:id?"
        //   element={<CreateUpdateNotification />}
        // />,
      ],
    },
  ];

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/timesheet/dashboard" />} />

      {routesWithAccess
        .filter((route) => access[route.accessKey])
        .map((route) => route.routes)}

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
function AuditorRoutes() {
  const access = useAccessControl();
  const routesWithAccess: Array<{
    accessKey: keyof AccessControlContextProps;
    routes: Array<JSX.Element>;
  }> = [
    {
      accessKey: "auditor.templates",
      routes: [
        <Route path="/templates" element={<TemplatesPage />} />,
        <Route path="/template-edit/:id?" element={<CreateTemplate />} />,
        <Route path="/AccessTable" element={<AccessTable />} />,
      ],
    },
    {
      accessKey: "auditor.inspections",
      routes: [
        <Route path="/inspections" element={<AllInspection />} />,
        <Route
          path="/inspection-conduct/:id?"
          element={<ConductInspection />}
        />,
        <Route path="/report/:id" element={<WebPreview />} />,
      ],
    },
    {
      accessKey: "auditor.schedule",
      routes: [
        <Route path="/schedule_inspection" element={<ScheduleInspection />} />,
      ],
    },
    {
      accessKey: "auditor.periodicalSchedule",
      routes: [
        <Route path="/periodical_schedule" element={<PeriodicalSchedule />} />,
      ],
    },
    {
      accessKey: "auditor.periodicalReports",
      routes: [
        <Route
          path="/periodical_report"
          element={<PeriodicalScheduleReport />}
        />,
      ],
    },
    {
      accessKey: "auditor.notificationSettings",
      routes: [
        <Route path="/notification" element={<AuditorNotification />} />,
      ],
    },
  ];
  return (
    <Routes>
      <Route path="/dashboard" element={<AuditorDashboard />} />
      {/* <Route path="/bottom" element={<BuildBottom />} /> */}
      {/* <Route path="/schedule" element={<ScheduleInspectionModal />} /> */}{" "}
      {routesWithAccess
        .filter((route) => access[route.accessKey])
        .map((route) => route.routes)}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
function PayrollRoutes() {
  const access = useAccessControl();

  const routesWithAccess: Array<{
    accessKey: keyof AccessControlContextProps;
    routes: Array<JSX.Element>;
  }> = [
    {
      accessKey: "payroll.timesheets",
      routes: [
        <Route path="Timesheets" element={<TimeSheetParents />} />,
        <Route path="EmployeeTimesheets" element={<TimeSheet />} />,
        <Route path="TimesheetsDetail/:id" element={<TimeSheetDetail />} />,
        <Route
          path="TimesheetsEnterDetail"
          element={<TimeSheetEnterDetail />}
        />,
      ],
    },
    {
      accessKey: "payroll.timesheets",
      routes: [
        <Route path="ProductivityTracker" element={<TimeSheetQuantity />} />,
        <Route
          path="TimesheetsQuantityDetail/:id"
          element={<TimeSheetQuantityDetail />}
        />,
        <Route
          path="TimesheetsEnterQuatityDetail"
          element={<TimeSheetEnterQuantityDetails />}
        />,
      ],
    },
    {
      accessKey: "payroll.payRuns",
      routes: [
        <Route path="PayEmployee" element={<PayEmployee />} />,
        <Route path="payRunDetail/:id" element={<PayRunDetail />} />,
        <Route path="postedPayRun/:id" element={<PostPayRunDetails />} />,
        <Route path="payRunPaySlip" element={<PayRunPaySlip />} />,
        <Route path="paySlip" element={<PostedPayslip />} />,
      ],
    },

    {
      accessKey:
        "payroll.payrollSettings" || "businessSettings.payrollSettings",
      routes: [<Route path="payrollsettings" element={<Orgtab />} />],
    },
    {
      accessKey: "accounting.chartOfAccounts",
      routes: [
        <Route path="ChartOfAccount" element={<ChartOfAccount />} />,
        <Route path="AddBankAccount" element={<AddBankAccount />} />,
        <Route path="/Import" element={<Import />} />,
      ],
    },
    {
      accessKey: "accounting.taxRates",
      routes: [<Route path="TaxRates" element={<TaxRates />} />],
    },

    {
      accessKey: "onDemandNotifications.onDemandNotifications",
      routes: [
        <Route
          path="/employeeNotification"
          element={<EmployeeNotifications />}
        />,
      ],
    },
    {
      accessKey: "contacts.allContacts",
      routes: [
        <Route path="/AllContact" element={<AllContact />} />,
        <Route path="/CreateContact" element={<CreateContact />} />,
        <Route path="/ImportContact" element={<ImportContact />} />,
      ],
    },
    {
      accessKey: "employee.employees",
      routes: [
        <Route path="/PayrollEmployee" element={<PayrollEmployee />} />,
        <Route path="/EmployeeDetail/:id" element={<EmployeeDetail />} />,
        <Route path="/AddEmployee" element={<AddEmployee />} />,
        <Route
          path="/EmployeeBulkUploadPreview"
          element={<EmployeeBulkUploadPreview />}
        />,
      ],
    },
    {
      accessKey: "employee.leave",
      routes: [
        <Route path="/Leave" element={<LeaveTab />} />,
        <Route path="/LeaveHistory" element={<LeaveHistory />} />,
      ],
    },
    {
      accessKey: "expenseClaim.expenseClaim",
      routes: [
        <Route path="/BulkExpenseClaim" element={<BulkExpenseClaim />} />,
      ],
    },
    {
      accessKey: "statuaryBenefits.statuaryBenefits",
      routes: [
        <Route
          path="/statuary-benefits-PaymentDetails"
          element={<SuperannuationPaymentDetails />}
        />,
        <Route
          path="/statuary-Payment/:id"
          element={<SuperannuationPayment />}
        />,
        <Route path="/statuary-benefits" element={<Superannuation />} />,
      ],
    },
    {
      accessKey: "business.billsToPay",
      routes: [
        <Route path="/BillsToPay" element={<BillsToPayParent />} />,
        <Route path="/CreateBill" element={<BillsToPay />} />,
        <Route path="/CreditBill" element={<CreditBill />} />,
      ],
    },
    {
      accessKey: "business.invoices",
      routes: [
        <Route path="/InvoicesParent" element={<InvoicesParent />} />,
        <Route path="/Invoices" element={<Invoices />} />,
        <Route path="/invoicePreview/:id" element={<InvoicePreview />} />,
        <Route path="/ImportForInvoices" element={<ImportForInvoices />} />,
        <Route path="/ClassicInvoice" element={<ClassicInvoice />} />,
        <Route path="/NewCreditNotes" element={<NewCreditNotes />} />,
        <Route path="/PaymentSummary" element={<PaymentSummary />} />,
      ],
    },
    {
      accessKey: "business.productsAndServices",
      routes: [
        <Route path="/ProductAndServices" element={<ProductAndServices />} />,
        <Route path="/OpeningBalances" element={<OpeningBalances />} />,
        <Route path="/ImportForItems" element={<ImportForItems />} />,
      ],
    },
    {
      accessKey: "businessSettings.businessSetup",
      routes: [
        <Route
          path="/OrganizationDetailParent"
          element={<OrganizationDetailParent />}
        />,
      ],
    },
    {
      accessKey: "businessSettings.users",
      routes: [
        <Route path="/user" element={<User />} />,
        <Route path="/InviteUser" element={<Invite />} />,
        <Route path="/updatePermission/:id" element={<UpdatePermission />} />,
      ],
    },
    {
      accessKey: "businessSettings.mappingWidow",
      routes: [<Route path="/MappingWindow" element={<MappingWindow />} />],
    },
    {
      accessKey: "businessSettings.invoiceSettings",
      routes: [<Route path="/invoiceSettings" element={<InvoicesSetting />} />],
    },
    {
      accessKey: "reports.employeeDetails",
      routes: [
        <Route
          path="/EmployeeDetailsReport"
          element={<EmployeeDetailsReport />}
        />,
      ],
    },
    {
      accessKey: "reports.employeeContactDetails",
      routes: [
        <Route
          path="/EmployeeContactDetailReport"
          element={<EmployeeContactDetailReport />}
        />,
      ],
    },
    {
      accessKey: "reports.employeeRemuneration",
      routes: [
        <Route
          path="/EmployeeRemunerationReport"
          element={<EmployeeRemunerationReport />}
        />,
      ],
    },
    {
      accessKey: "reports.leaveBalance",
      routes: [
        <Route path="/LeaveBalanceReport" element={<LeaveBalanceReport />} />,
      ],
    },
    {
      accessKey: "reports.leaveRequests",
      routes: [
        <Route path="/LeaveRequestReport" element={<LeaveRequestReport />} />,
      ],
    },
    {
      accessKey: "reports.payrollActivityDetail",
      routes: [
        <Route
          path="/PayrollActivityDetails"
          element={<PayrollActivityDetails />}
        />,
      ],
    },
    {
      accessKey: "reports.payrollActivitySummary",
      routes: [
        <Route
          path="/PayrollActivitySummary"
          element={<PayrollActivitySummary />}
        />,
      ],
    },
    {
      accessKey: "reports.payrollEmployeeSummary",
      routes: [
        <Route
          path="/PayrollEmployeeSummaryReport"
          element={<PayrollEmployeeSummaryReport />}
        />,
      ],
    },
    {
      accessKey: "reports.attendanceReport",
      routes: [
        <Route path="/attendance-report" element={<AttendanceReport />} />,
      ],
    },
    {
      accessKey: "reports.transactionListingDetail",
      routes: [
        <Route
          path="/TransactionLisitingDetailReport"
          element={<TransactionLisitingDetailReport />}
        />,
      ],
    },
    {
      accessKey: "reports.transactionListingSummary",
      routes: [
        <Route
          path="/TransactionLisitingSummaryReport"
          element={<TransactionLisitingSummaryReport />}
        />,
      ],
    },
  ];
  return (
    <Box>
      <Routes>
        <Route path="/OpeningBalances" element={<OpeningBalances />} />
        <Route path="/Employee" element={<Employee />} />
        {/* <Route path="/EmployeeInduction" element={<EmployeeInduction />} />
        <Route path="/CurrentEmployee" element={<Current />} />
        <Route path="/PastEmployee" element={<Past />} /> */}

        <Route path="Timesheet" element={<Timesheet />} />
        <Route path="TimesheetOver" element={<TimesheetOver />} />

        <Route
          path="/organisationSettings"
          element={<OrganisationSettings />}
        />

        <Route path="/advancedSettings" element={<AdvancedSettings />} />

        <Route path="/SampleForOnBoarding" element={<SampleForOnBoarding />} />

        <Route path="/EmployeeDashboard/:id" element={<EmployeeDashboard />} />
        <Route path="/TrackingCategories" element={<TrackingCategories />} />

        <Route
          path="/Dashboard"
          element={
            <DashboardContextProvider>
              <NewDashboard />
            </DashboardContextProvider>
          }
        />
        <Route path="/resizabledashboard" element={<BasicLayout />} />

        {/* <Route
          path="/LeaveTransactionsReport"
          element={<LeaveTransactionsReport />}
        /> */}

        {/* <Route
          path="/SuperannuationAccrualsReport"
          element={<SuperannuationAccrualsReport />}
        /> */}
        {/* <Route
          path="/SuperannuationPaymentsReport"
          element={<SuperannuationPaymentsReport />}
        /> */}
        {/* <Route
          path="/TimesheetDetailsReport"
          element={<TimesheetDetailsReport />}
        />
        <Route
          path="/TimesheetSummaryReport"
          element={<TimesheetSummaryReport />}
        />
        <Route
          path="/PaymentSummaryDetails"
          element={<PaymentSummaryDetails />}
        /> */}

        {/* <Route path="/ReportSample" element={<DetailReport />} /> */}
        <Route path="/AddAccount" element={<AddAccount />} />
        {/* <Route path="/LeaveAdded" element={<LeaveAdded />} /> */}

        <Route path="/singleTouchSettings" element={<SingleTouchSettings />} />
        <Route path="/full-details/:id" element={<EmployeeReport />} />

        <Route path="/UserProfile" element={<UserProfile />} />
        <Route path="/ChangePassword" element={<ChangePassword />} />
        <Route path="/ChangePasswordEmail" element={<ChangePasswordEmail />} />
        {/* <Route path="/taxslabs" element={<TaxSlabs />} /> */}

        {/* <Route path="/NewLeaveRequest" element={<NewLeaveRequest />} />
        <Route path="/PendingLeaveRequest" element={<PendingLeaveRequest />} /> */}

        <Route path="/Reports" element={<Reports />} />

        {/* <Route path="/SingleNotification" element={<NotificationSetting />} />
        <Route
          path="/GroupNotification"
          element={<ManageNotificationGroup />}
        />
        <Route
          path="/BirthdayNotifications"
          element={<BirthdayNotifications />}
        /> */}
        {/* <Route
          path="/createUpdateNotification"
          element={<CreateUpdateNotification />}
        /> */}
        <Route path="/analog" element={<AnalogClock />} />

        <Route path="/Pricing" element={<Pricing />} />
        {routesWithAccess
          .filter((route) => access[route.accessKey])
          .map((route) => route.routes)}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Box>
  );
}
