import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { IoMdInformationCircle } from "react-icons/io";
import { useParams } from "react-router-dom";
import { ReactSelect } from "../../../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../../../Hooks/BusinessContext";
import { AccessControl } from "../../../../../modules/Audit/AccessControl";
import { Permission } from "../../../../../modules/Audit/Permission";
import UserMenu from "../Menu/UserMenu";

import ViewerMenu from "../Menu/ViewerMenu";

interface AccessRuleprops {
  isOpen: boolean;
  onClose: () => void;
  templateOptionSelect: (optionId: number) => void;
  allTemplatePermission: Array<Permission.Permissions>;
  inspectionOptionSelect: (optionId: number) => void;
  allInspectionPermission: Array<Permission.Permissions>;
  templateSelectedOption: number;
  inspectionSelectedOption: number | undefined;
  selectedId?: number;
  setLastUpdate: (timestamp: number) => void;
}
export default function AccessRule({
  isOpen,
  onClose,
  allTemplatePermission,
  templateOptionSelect,
  allInspectionPermission,
  inspectionOptionSelect,
  inspectionSelectedOption,
  templateSelectedOption,
  selectedId,
  setLastUpdate,
}: AccessRuleprops) {
  const form = useFormContext<AccessControl.AccessMeta>();
  const { allUsers } = useBusinessContext();
  // const [selectedValues, setSelectedValues] = useState({
  //   templateUserId: null,
  //   inspectionUserId: null,
  //   templatePermissionId: 0,
  //   inspectionPermissionId: 0,
  //   templateId: 0,
  // });
  const { id } = useParams();
  const toast = useToast();
  return (
    <Drawer isOpen={isOpen} placement="right" size="md" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent
        backgroundColor={"backgrounds.header"}
        overflowY="auto"
        minH="100vh"
      >
        <DrawerHeader p={"24px"}>
          <Text fontSize="21px" fontWeight={500} color="textcolors.primary">
            Add a new access rule
          </Text>
        </DrawerHeader>
        <DrawerBody
          //   minH={{xs: '311px', md: '292px'}}
          maxH="500px"
          backgroundColor={"white"}
          px="24px"
          py="40px"
        >
          <Text
            fontSize="16px"
            fontWeight={500}
            color="textcolors.primary"
            mb="4px"
          >
            Template is available to
          </Text>
          <Box w="100%">
            {/* <TemplateInput /> */}
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Employee is required",
                },
              }}
              control={form.control}
              name="templateUserId"
              render={({ field, fieldState }) => {
                return (
                  <ReactSelect
                    {...field}
                    withValidation
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Please Select User"
                    placeholder="Select User"
                    options={allUsers.map((user) => {
                      return {
                        id: user.user.id,
                        firstName: user.user.firstName,
                        lastName: user.user.lastName,
                        access: user.accessLevel,
                      };
                    })}
                    getOptionLabel={(option: any) => {
                      return `${option?.firstName} ${option?.lastName} (${option?.access})`;
                    }}
                    getOptionValue={(option: any) => option.id}
                    value={
                      field.value
                        ? {
                            id: field.value,
                            firstName:
                              allUsers.find(
                                (user) => user?.user?.id === field.value
                              )?.user?.firstName ?? "",
                            lastName:
                              allUsers.find(
                                (user) => user?.user?.id === field.value
                              )?.user?.lastName ?? "",
                            access:
                              allUsers.find(
                                (user) => user?.user?.id === field.value
                              )?.accessLevel ?? "",
                          }
                        : undefined
                    }
                    onChange={(val: any) => {
                      field.onChange(val?.id);
                    }}
                  />
                );
              }}
            />
          </Box>
          <HStack
            spacing={0}
            justify="end"
            mt="8px"
            lineHeight="16px"
            mb="40px"
          >
            <Flex
              align="center"
              _hover={{ backgroundColor: "primary.50" }}
              _active={{ backgroundColor: "none" }}
              px="8px"
            >
              <Text
                fontSize="16px"
                fontWeight={400}
                color="textcolors.secondary"
              >
                Access level:
              </Text>
              {/* <UserMenu onSelect={templateOptionSelect} templatePermission={allTemplatePermission} selectedOption={templateSelectedOption} /> */}
              <Controller
                control={form.control}
                name="templatePermissionId"
                defaultValue={templateSelectedOption}
                rules={{
                  required: {
                    value: true,
                    message: "Template Permission is required",
                  },
                  validate: (value) => {
                    return value > 0 ?? "Template Permission is required";
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <UserMenu
                    onSelect={(optionId) => {
                      field.onChange(optionId);
                      templateOptionSelect(optionId);
                    }}
                    templatePermission={allTemplatePermission}
                    selectedOption={field.value}
                    isInvalid={invalid}
                  />
                )}
              />
            </Flex>
          </HStack>
          <Text
            fontSize="16px"
            fontWeight={500}
            color="textcolors.primary"
            mb="4px"
          >
            Inspection result is available to
          </Text>
          <Box w="100%">
            {/* <InspectionInput /> */}
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Employee is required",
                },
              }}
              control={form.control}
              name="inspectionUserId"
              render={({ field, fieldState }) => {
                return (
                  <ReactSelect
                    {...field}
                    withValidation
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Please Select User"
                    placeholder="Select User"
                    options={allUsers.map((user) => {
                      return {
                        id: user.user.id,
                        firstName: user.user.firstName,
                        lastName: user.user.lastName,
                        access: user.accessLevel,
                      };
                    })}
                    getOptionLabel={(option: any) => {
                      return `${option?.firstName} ${option?.lastName} (${option?.access})`;
                    }}
                    getOptionValue={(option: any) => option.id}
                    value={
                      field.value
                        ? {
                            id: field.value,
                            firstName:
                              allUsers.find(
                                (user) => user?.user?.id === field.value
                              )?.user?.firstName ?? "",
                            lastName:
                              allUsers.find(
                                (user) => user?.user?.id === field.value
                              )?.user?.lastName ?? "",
                            access:
                              allUsers.find(
                                (user) => user?.user?.id === field.value
                              )?.accessLevel ?? "",
                          }
                        : undefined
                    }
                    onChange={(val: any) => {
                      field.onChange(val?.id);
                    }}
                  />
                );
              }}
            />
          </Box>
          <HStack
            spacing={0}
            justify="end"
            mt="8px"
            lineHeight="16px"
            mb="40px"
          >
            <Flex
              align="center"
              _hover={{ backgroundColor: "primary.50" }}
              _active={{ backgroundColor: "none" }}
              px="8px"
            >
              <Text
                fontSize="16px"
                fontWeight={400}
                color="textcolors.secondary"
              >
                Access level:
              </Text>
              {/* <ViewerMenu InspectionPermission={allInspectionPermission} onSelect={inspectionOptionSelect} selectedOption={inspectionSelectedOption} /> */}
              <Controller
                control={form.control}
                name="inspectionPermissionId"
                defaultValue={inspectionSelectedOption}
                rules={{
                  required: {
                    value: true,
                    message: "Inspection Permission is required",
                  },
                  validate: (value) => {
                    return value > 0 ?? "Inspection Permission is required";
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <ViewerMenu
                    InspectionPermission={allInspectionPermission}
                    onSelect={(optionId) => {
                      field.onChange(optionId);
                      inspectionOptionSelect(optionId);
                    }}
                    selectedOption={field.value}
                    isInvalid={invalid}
                  />
                )}
              />
            </Flex>
          </HStack>

          <Flex w="100%" mt="12px" fontSize="14px">
            <IoMdInformationCircle size={16} color="#545f70" />
            <Text
              w="100%"
              color="icons.primary"
              fontSize="14px"
              fontWeight={400}
              ml="8px"
              lineHeight="16px"
            >
              <b>Access rules</b> configure who can use this template to conduct
              inspections, and who automatically receives the results of those
              inspections. Users are always able to see and edit any inspections
              they have conducted themselves.
            </Text>
          </Flex>
          <Box mt="24px" ml="24px">
            <Link fontSize="14px">Learn more</Link>
          </Box>
        </DrawerBody>

        <DrawerFooter
          borderTop="1px solid"
          borderColor={"borders.accent"}
          padding={"24px"}
          backgroundColor={"backgrounds.header"}
          flexDirection="column"
        >
          <Stack w="100%" spacing={0}>
            <Box flexDirection={{ xs: "column", md: "row" }}>
              <Button
                h="40px"
                w={"auto"}
                borderRadius="5px"
                fontSize="14px"
                fontWeight={500}
                marginRight="16px"
                padding={"12px 20px"}
                onClick={form.handleSubmit(
                  (data) => {
                    if (selectedId) {
                      AccessControl.UpdateTemplatePermissionById(
                        selectedId,
                        data,
                        () => {
                          toast({
                            title: "permission Updated",
                            status: "success",
                          });
                          setLastUpdate(Date.now());
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Error",
                            description: error,
                            status: "error",
                          });
                        }
                      );
                    } else {
                      AccessControl.CreateTemplatePermission(
                        data,
                        () => {
                          toast({
                            title: " permission Created",
                            status: "success",
                          });
                          setLastUpdate(Date.now());
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Error",
                            description: error,
                            status: "error",
                          });
                        }
                      );
                    }
                  },
                  (error) => {
                    console.log(error);
                  }
                )}
              >
                {selectedId ? "Update" : "Save"}
              </Button>
              <Button
                h="40px"
                borderRadius="5px"
                fontSize="14px"
                fontWeight={500}
                variant="outline"
                padding={"12px 20px"}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Box>
          </Stack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
