import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CustomInput } from "../../../../../Common/CustomInput";
import { useBusinessContext } from "../../../../../Hooks/BusinessContext";
import { BusinessDocumentsApi } from "../../../../Api/BusinessDocumentsApi";
import { UploadAttachments } from "../../../../Api/UploadAttachments";
interface UploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdate?: any;
}
export default function UploadModal({
  isOpen,
  onClose,
  setLastUpdate,
}: UploadModalProps) {
  const { currentBusinessId } = useBusinessContext();
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<any>();
  const toast = useToast();
  const form = useForm<{
    fileName: string;
    fileUrl: string;
  }>();
  useEffect(() => {
    if (!isOpen) {
      form.reset();
      setUploadedFile(null);
    }
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Document</ModalHeader>
        <ModalBody>
          <Stack>
            <input
              type="file"
              onChange={(e) => {
                if (e.target.files) {
                  setUploadedFile(e.target.files[0]);
                }
              }}
            />
            <Controller
              control={form.control}
              name="fileName"
              rules={{
                required: "File name is required",
              }}
              render={({ field, fieldState }) => (
                <CustomInput
                  input={{
                    ...field,
                    variant: "outline",
                    isInvalid: (fieldState.error?.message?.length ?? 0) > 0,
                    value: field.value,
                    type: "text",
                  }}
                  label="Save as"
                  withValidation
                  validationMessage={fieldState.error?.message}
                  labelProps={{
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                />
              )}
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup borderRadius="5px">
            <Button
              colorScheme="blue"
              borderRadius="5px"
              onClick={() => {
                if (!uploadedFile) {
                  toast({
                    title: "Please upload a file",
                    status: "error",
                  });
                  return;
                }
                const formData = new FormData();
                formData.append("docType", "BUSINESS_DOCUMENT");
                formData.append("file", uploadedFile!);
                formData.append("businessId", currentBusinessId!);
                setIsDocumentLoading(true);
                UploadAttachments.UploadAttachment(
                  formData,
                  (success) => {
                    BusinessDocumentsApi.UploadDocument(
                      {
                        fileName: form.getValues("fileName"),
                        fileUrl: success.url,
                      },
                      (message) => {
                        toast({
                          title: message,
                          status: "success",
                        });
                        setLastUpdate(Date.now());
                        form.reset();
                        onClose();
                      },
                      (err) => {
                        toast({
                          title: "Error uploading photo",
                          status: "error",
                          description: err,
                        });
                        setIsDocumentLoading(false);
                      }
                    );
                  },
                  (err) => {
                    toast({
                      title: "Error uploading photo",
                      status: "error",
                      description: err,
                    });
                    setIsDocumentLoading(false);
                  }
                );
              }}
            >
              Upload
            </Button>
            <Button
              variant="ghost"
              colorScheme="gray"
              borderRadius="5px"
              onClick={onClose}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
