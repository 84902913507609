import { Heading, Stack, Text } from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import { Fragment, useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { ExpenseClaimApi } from "../../../../Api/ExpenseClaimApi";
import { ExpenseTag } from "./ExpenseTag";

interface Props {
  claimData: ExpenseClaimApi.FetchExpenseClaim[];
  setLastUpdated: React.Dispatch<React.SetStateAction<number>>;
}
export function ClaimTable({ claimData, setLastUpdated }: Props) {
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  const [draftClaim, setDraftClaim] = useState<
    Array<ExpenseClaimApi.FetchExpenseClaim>
  >([]);
  const [submittedClaim, setSubmittedClaim] = useState<
    Array<ExpenseClaimApi.FetchExpenseClaim>
  >([]);
  const [approvedClaim, setApprovedClaim] = useState<
    Array<ExpenseClaimApi.FetchExpenseClaim>
  >([]);

  useEffect(() => {
    setDraftClaim(claimData.filter((claim) => claim.status === "DRAFT"));
    setApprovedClaim(claimData.filter((claim) => claim.status === "APPROVED"));
    setSubmittedClaim(
      claimData.filter((claim) => claim.status === "SUBMITTED")
    );
  }, [claimData]);
  return (
    <Fragment>
      {decodedToken.accessLevel !== "EMPLOYEE" && (
        <Stack padding={"30px 20px"}>
          <Heading
            textAlign="left"
            fontSize=".9975rem"
            fontWeight="bold"
            padding="20px 0px 20px 0px"
            as="h2"
            size="md"
            display={"flex"}
            flex={1}
            _light={{
              color: "customColor.black7",
            }}
          >
            Upcoming
          </Heading>
          {claimData && claimData.length > 0 ? (
            claimData.map((expenseClaimData, i) => (
              <ExpenseTag
                expenseClaimData={expenseClaimData}
                setLastUpdated={setLastUpdated}
              />
            ))
          ) : (
            <Text
              _light={{
                color: "customColor.black7",
              }}
              fontSize={".9975rem"}
            >
              No pending claim requests
            </Text>
          )}
        </Stack>
      )}
      {decodedToken.accessLevel === "EMPLOYEE" && (
        <Stack padding={"30px 20px"}>
          {draftClaim.length > 0 && (
            <Stack>
              <Heading
                textAlign="left"
                fontSize=".9975rem"
                fontWeight="bold"
                padding="20px 0px 20px 0px"
                as="h2"
                size="md"
                display={"flex"}
                flex={1}
                _light={{
                  color: "orange",
                }}
              >
                Draft
              </Heading>
              {draftClaim?.map((expenseClaimData: any, i: any) => {
                return (
                  <ExpenseTag
                    expenseClaimData={expenseClaimData}
                    setLastUpdated={setLastUpdated}
                  />
                );
              })}
            </Stack>
          )}
          {submittedClaim.length > 0 && (
            <Stack>
              <Heading
                textAlign="left"
                fontSize=".9975rem"
                fontWeight="bold"
                padding="20px 0px 20px 0px"
                as="h2"
                size="md"
                display={"flex"}
                flex={1}
                _light={{
                  color: "orange",
                }}
              >
                Submitted
              </Heading>
              {submittedClaim?.map((expenseClaimData: any, i: any) => {
                return (
                  <ExpenseTag
                    expenseClaimData={expenseClaimData}
                    setLastUpdated={setLastUpdated}
                  />
                );
              })}
            </Stack>
          )}
          {approvedClaim.length > 0 && (
            <Stack>
              <Heading
                textAlign="left"
                fontSize=".9975rem"
                fontWeight="bold"
                padding="20px 0px 20px 0px"
                as="h2"
                size="md"
                display={"flex"}
                flex={1}
                _light={{
                  color: "orange",
                }}
              >
                Approved
              </Heading>
              {approvedClaim?.map((expenseClaimData: any, i: any) => {
                return (
                  <ExpenseTag
                    expenseClaimData={expenseClaimData}
                    setLastUpdated={setLastUpdated}
                  />
                );
              })}
            </Stack>
          )}
        </Stack>
      )}
    </Fragment>
  );
}
