import {createContext, useContext, useState} from 'react';

interface InspectionContextProps {
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
}
const InspectionContext = createContext<InspectionContextProps>({
  isSaving: false,
  setIsSaving: () => {},
});
interface InspectionContextProviderProps {
  children: any;
}
const InspectionContextProvider = ({
  children,
}: InspectionContextProviderProps) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  return (
    <InspectionContext.Provider
      value={{
        isSaving,
        setIsSaving,
      }}>
      {children}
    </InspectionContext.Provider>
  );
};
function useInspectionContext() {
  return useContext<InspectionContextProps>(InspectionContext);
}
export {InspectionContext, InspectionContextProvider, useInspectionContext};
