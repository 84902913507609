import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { PayrollCalendar } from "../../../Api/PayRollCalendar";
import { PayRun } from "../../../Api/PayRun";
import { getFinancialYearOptions } from "../../TimeSheet/utils";

interface AddPayRunPros {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (timeStamp: number) => void;
}
export function AddPayRun({ isOpen, onClose, setLastUpdated }: AddPayRunPros) {
  const form = useFormContext<PayRun.CreatePayrun>();
  const { currentBusinessId, businessesList } = useBusinessContext();
  const currentBusinessDetails = businessesList.find(
    (business) => business.id === currentBusinessId
  );
  const [allPayPeriods, setAllPayPeriods] = useState<Array<PayRun.PayPeriod>>(
    []
  );
  const [unscheduledPayperiods, setUnscheduledPayperiods] = useState<
    Array<PayRun.PayPeriod>
  >([]);
  const [payOptions, setPayOptions] = useState<PayrollCalendar.Calendar[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedPayperiodsDates, setSelectedPayperiodsDates] = useState<{
    label: string;
    value: { startDate: string; endDate: string };
  }>({
    label: "",
    value: { startDate: "", endDate: "" },
  });
  const toast = useToast();
  const financialYearOptions = getFinancialYearOptions({
    financialYearEndDay: currentBusinessDetails?.last_date_financial_year!,
    financialYearEndMonth: currentBusinessDetails?.last_month_financial_year!,
  });
  useEffect(() => {
    if (isOpen) {
      setSelectedPayperiodsDates({
        label: "",
        value: { startDate: "", endDate: "" },
      });
      PayRun.FetchPayPeriod(
        (data) => {
          setAllPayPeriods(data);
          setIsLoading(false);
        },
        (error) => {
          toast({
            title: error,
            status: "error",
          });
          setIsLoading(false);
        }
      );
    }
  }, [isOpen]);

  const watchPayrollCalender = useWatch({
    control: form.control,
    name: ["payPeriod.payrollCalendarId"],
  });
  const watchPayPeriodString = useWatch({
    control: form.control,
    name: ["payPeriod.string"],
  });
  useEffect(() => {
    if (watchPayPeriodString[0] === "Unscheduled pay run") {
      PayrollCalendar.FetchCalendar(
        (data) => {
          setPayOptions(data);

          setIsLoading(false);
        },
        (error) => {
          toast({
            title: "Error",
            status: "error",
          });
          setIsLoading(false);
        }
      );
    }
  }, [watchPayPeriodString]);
  const [financialYear, setFinancialYear] = useState<string | undefined>(
    undefined
  );
  useEffect(() => {
    if (
      form.getValues("payPeriod.payrollCalendarId") &&
      !!financialYear &&
      watchPayPeriodString[0] === "Unscheduled pay run"
    ) {
      PayRun.UnscheduledPayPeriod(
        {
          payrollCalendarId: form.getValues("payPeriod.payrollCalendarId"),
          businessFYStartDate: financialYear,
        },

        (data) => {
          setUnscheduledPayperiods(data);
          setIsLoading(false);
        },
        (error) => {
          toast({
            title: error,
            status: "error",
          });
          setIsLoading(false);
        }
      );
    }
  }, [watchPayrollCalender, financialYear, watchPayPeriodString]);

  return (
    <>
      <FormProvider {...form}>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent w={"350px"}>
            <ModalHeader
              _light={{
                color: "customColor.black7",
              }}
              fontSize=".9975rem"
              _dark={{
                color: "customColor.white",
              }}
              padding={"15px 5px 15px 15px"}
              fontWeight={"700"}
              lineHeight={"1.75rem"}
              h={"52px"}
            >
              Add a Pay Run
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody
              padding={"15px"}
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "#FBFBFB",
              }}
              border={"1px solid #E5E5E5"}
            >
              <Box>
                <Text
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.250",
                  }}
                  lineHeight={"1.3077"}
                  padding={"0px 0px 5px"}
                >
                  Select a pay period
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Pay Period is required",
                    },
                  }}
                  control={form.control}
                  name="payPeriod"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select "
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Pay Period"
                      withValidation
                      options={[
                        ...allPayPeriods,
                        {
                          string: "Unscheduled pay run",
                          value: "Unscheduled pay run",
                        },
                      ]}
                      value={field.value}
                      onChange={(newValue: any) => {
                        field.onChange(newValue);
                        setSelectedPayperiodsDates({
                          label: "",
                          value: { startDate: "", endDate: "" },
                        });
                      }}
                      getOptionLabel={(option: any) => option.string}
                      getOptionValue={(option: any) => option}
                    />
                  )}
                />
              </Box>

              {form.getValues("payPeriod.string") === "Unscheduled pay run" && (
                <Fragment>
                  <Box>
                    <Text
                      fontSize={"0.8125rem"}
                      fontWeight={"600"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.250",
                      }}
                      lineHeight={"1.3077"}
                      padding={"0px 0px 5px"}
                    >
                      Select a payroll calender
                    </Text>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Payroll Calender is required",
                        },
                      }}
                      control={form.control}
                      name="payPeriod.payrollCalendarId"
                      render={({ field, fieldState }) => (
                        <ReactSelect
                          {...field}
                          placeholder="Select "
                          isInvalid={fieldState.invalid}
                          ErrorMessage="Select Payroll Calender"
                          withValidation
                          options={payOptions.map((option) => ({
                            id: option.id,
                            name: option.name,
                          }))}
                          value={
                            payOptions.find(
                              (option) => option.id === field.value
                            ) ?? null
                          }
                          onChange={(newValue: any) => {
                            console.log(newValue);
                            field.onChange(newValue?.id);
                            form.reset({
                              payPeriod: {
                                taxFinancialYearId: form.getValues(
                                  "payPeriod.taxFinancialYearId"
                                ),
                                type: "UNSCHEDULED",
                                payrollCalendarId: newValue?.id,
                                startDate: undefined,
                                endDate: undefined,
                                string: "Unscheduled pay run",
                              },
                            });
                          }}
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option.id}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Text
                      fontSize={"0.8125rem"}
                      fontWeight={"600"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.250",
                      }}
                      lineHeight={"1.3077"}
                      padding={"0px 0px 5px"}
                    >
                      Select financial year
                    </Text>
                    <ReactSelect
                      placeholder="Select "
                      options={financialYearOptions.map((option) => ({
                        label: option.label,
                        value: option.value,
                      }))}
                      value={
                        financialYearOptions.find(
                          (option) => option.value === financialYear
                        ) ?? null
                      }
                      onChange={(newValue: any) => {
                        console.log(newValue);
                        setFinancialYear(newValue?.value);
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  </Box>
                  <Box>
                    <Text
                      fontSize={"0.8125rem"}
                      fontWeight={"600"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.250",
                      }}
                      lineHeight={"1.3077"}
                      padding={"0px 0px 5px"}
                    >
                      Select a unscheduled pay period
                    </Text>
                    <ReactSelect
                      placeholder="Select "
                      options={unscheduledPayperiods.map((option) => ({
                        label: option.string,
                        value: {
                          startDate: option.startDate,
                          endDate: option.endDate,
                        },
                      }))}
                      value={selectedPayperiodsDates}
                      onChange={(newValue: any) => {
                        console.log(newValue);
                        setSelectedPayperiodsDates(newValue);
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  </Box>
                </Fragment>
              )}
            </ModalBody>

            <ModalFooter>
              <ButtonGroup size="sm" marginLeft="700px">
                <Button
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  isLoading={isLoading}
                  onClick={(e) => {
                    setIsLoading(true);
                    form.handleSubmit(
                      (data) => {
                        if (data.payPeriod.string === "Unscheduled pay run") {
                          const payloadData = {
                            ...data.payPeriod,
                            startDate: selectedPayperiodsDates.value.startDate,
                            endDate: selectedPayperiodsDates.value.endDate,
                            type: "UNSCHEDULED",
                          };
                          PayRun.CreatePayRun(
                            payloadData,
                            (success) => {
                              toast({
                                title: "Unscheduled pay run is Added",
                                status: "success",
                              });
                              setLastUpdated(Date.now());
                              onClose();
                            },
                            (err) => {
                              toast({
                                title: "Error",
                                status: "error",
                                description: err,
                              });
                              setIsLoading(false);
                            }
                          );
                        } else {
                          PayRun.CreatePayRun(
                            { ...data.payPeriod, type: "NORMAL" },
                            (success) => {
                              toast({
                                title: "PayRun is Added",
                                status: "success",
                              });
                              setLastUpdated(Date.now());
                              onClose();
                            },
                            (err) => {
                              toast({
                                title: "Error",
                                status: "error",
                                description: err,
                              });
                              setIsLoading(false);
                            }
                          );
                        }
                      },
                      (error) => {
                        toast({
                          title: " Failed",
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    )(e);
                  }}
                >
                  {isLoading ? "Next....." : "Next"}
                </Button>
                <Button
                  backgroundColor={"transparent"}
                  borderRadius={"3px"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  h={"40px"}
                  variant={"solid"}
                  textColor={"#ea4d4d"}
                  padding={"12px 16px"}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </FormProvider>
    </>
  );
}
