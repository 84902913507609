import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Heading,
  HStack,
  Icon,
  Link,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MdDelete, MdEdit } from "react-icons/md";
import { Insurance } from "../../Api/Insurance";
import DeleteInsurance from "./DeleteInsurance";
import InsuranceModal from "./InsuranceModal";
interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export function InsuranceParent({ setSelectedTab, selectedTab }: TabProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkboxes, setCheckboxes] = useState<{ [key: string]: boolean }>({});
  const toast = useToast();
  const [insuranceData, setInsuranceData] = useState<
    Array<Insurance.FetchInsurance>
  >([]);
  useEffect(() => {
    const initialCheckboxes = insuranceData.reduce((acc: any, item) => {
      acc[item.id] = false;
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [insuranceData]);

  const handleCheckboxChange = (id: string) => {
    setCheckboxes({
      ...checkboxes,
      [id]: !checkboxes[id],
    });
  };

  const handleCheckAll = () => {
    const updatedCheckboxes = Object.keys(checkboxes).reduce(
      (acc, key) => ({
        ...acc,
        [key]: !checkboxes[key],
      }),
      {}
    );
    setCheckboxes(updatedCheckboxes);
  };

  const form = useForm<Insurance.AddInsurance>({
    defaultValues: {
      type: "HEALTH",
      name: "",
      plan: "",
      amount: undefined,
      coverage: "",
      insuranceType: "",
      otherInformation: "",
      roomCharges: undefined,

      accidentalDeathAmount: undefined,
      naturalDeathAmount: undefined,
      opdAmount: undefined,
    },
  });
  const InsuranceType: Array<{ label: string; value: string }> = [
    {
      label: "Health",
      value: "HEALTH",
    },
    {
      label: "Group Life",
      value: "GROUP_LIFE",
    },
    {
      label: "Others",
      value: "OTHER",
    },
    {
      label: "OPD",
      value: "OPD",
    },
  ];

  const [deleteId, setDeleteId] = useState<string>("");
  const deleteModal = useDisclosure();
  useEffect(() => {
    Insurance.GetInsuranceDetails(
      (data) => {
        setInsuranceData(data);

        setIsLoading(false);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, [lastUpdated]);
  return (
    <FormProvider {...form}>
      <Fragment>
        <Stack
          marginTop={"15px"}
          p="0px"
          // maxW="940px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          // border="1px solid #d6dade"
          // borderRadius="md"
          marginBottom={"1.25rem"}
          border={"1px solid #f6f6f6"}
          borderRadius="4px"
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        >
          <Box
            justifyContent="center"
            alignItems="center"
            p="0px"
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
            // border="1px solid #d6dade"
            // borderRadius="md"
          >
            <HStack gap={0}>
              <Heading
                textAlign="left"
                fontSize=".9975rem"
                fontWeight="bold"
                padding="20px"
                _light={{
                  color: "customColor.black7",
                }}
                as="h2"
                flex={1}
                size="md"
              >
                Insurance
              </Heading>
              <Link
                color="primary.950"
                fontSize=" var(--x-font-size-small, .8125rem"
                paddingBottom="1px"
                href="/app/payroll/organisationSettings"
              >
                Organisation Settings <Icon as={ChevronRightIcon} />
              </Link>
            </HStack>

            <Divider orientation="horizontal" />

            <Box
              justifyContent="center"
              alignItems="center"
              p="0px"
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "customColor.white",
              }}
            >
              <HStack justifyContent="space-between">
                <Heading
                  textAlign="left"
                  fontSize=".9975rem"
                  fontWeight="bold"
                  _light={{
                    color: "customColor.black7",
                  }}
                  padding="20px"
                  as="h2"
                  size="md"
                >
                  Insurance
                </Heading>
                <Button
                  variant="link"
                  mr="20px"
                  _light={{
                    color: "primary.950",
                  }}
                  onClick={() => {
                    form.reset({
                      type: "",
                      name: "",
                      plan: "",
                      amount: 0,
                      coverage: "",
                      roomCharges: 0,
                      insuranceType: "",
                      otherInformation: "",

                      accidentalDeathAmount: 0,
                      naturalDeathAmount: 0,
                      opdAmount: 0,
                    });
                    onOpen();
                  }}
                >
                  Add Insurance
                </Button>
              </HStack>

              <Divider orientation="horizontal" />
              <Skeleton isLoaded={!isLoading} height="100%">
                <Box overflowX="auto" p="0px">
                  <Table variant="simple" w="100%" overflowX="auto">
                    <Thead h={"36.8px"}>
                      <Tr
                        borderTopWidth={"1px"}
                        borderTopColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                      >
                        <Th
                          padding="8px 26px 8px 30px"
                          width={"10%"}
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          <Checkbox
                            size="md"
                            borderColor="#adb5bd"
                            bgColor="white"
                            isChecked={Object.values(checkboxes).every(
                              (value) => value
                            )}
                            onChange={handleCheckAll}
                          />
                        </Th>
                        <Th
                          padding="8px 26px 8px 15px"
                          width={"15%"}
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Name
                        </Th>
                        <Th
                          width={"10%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Plan
                        </Th>
                        <Th
                          width={"10%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Type
                        </Th>
                        <Th
                          width={"10%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Category
                        </Th>
                        <Th
                          width={"10%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Coverage Amount
                        </Th>
                        <Th
                          width={"10%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          OPD Coverage Amount
                        </Th>
                        <Th
                          width={"10%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Natural death coverage amount
                        </Th>
                        <Th
                          width={"10%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Accidental death coverage amount
                        </Th>
                        <Th
                          width={"10%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Per Day Charge
                        </Th>
                        <Th
                          width={"15%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Coverage
                        </Th>
                        <Th
                          width={"15%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Other Information
                        </Th>
                        <Th
                          width={"10%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Action Required
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {insuranceData.map((item, i) => {
                        return (
                          <Tr
                            key={i}
                            _hover={{
                              bgColor: "#f2f3f8",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              onOpen();
                              form.reset({
                                id: item.id,
                                name: item.name,
                                plan: item.plan,
                                type: item.type,
                                accidentalDeathAmount:
                                  item.accidentalDeathAmount,
                                naturalDeathAmount: item.naturalDeathAmount,
                                opdAmount: item.opdAmount,
                                amount: item.amount,
                                coverage: item.coverage,
                                roomCharges: item.roomCharges,
                                insuranceType: item.insuranceType,
                                otherInformation: item.otherInformation,
                              });
                            }}
                          >
                            <Td
                              padding="15px 15px 15px 30px"
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              fontWeight={500}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Checkbox
                                size="md"
                                borderColor="#adb5bd"
                                bgColor="white"
                                isChecked={checkboxes[item.id]}
                                onChange={() => handleCheckboxChange(item.id)}
                              />
                            </Td>
                            <Tooltip
                              label={`${item.name}`}
                              placement="top"
                              hasArrow
                              bg="gray.800"
                              color="white"
                              fontSize="sm"
                              p="2"
                              rounded="md"
                              overflowWrap="anywhere"
                              whiteSpace="normal"
                              zIndex="10"
                            >
                              <Td
                                maxW="100px"
                                isTruncated
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                {item.name && item.name.trim()
                                  ? item.name
                                  : "NA"}
                              </Td>
                            </Tooltip>
                            <Td
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {item.plan && item.plan.trim() ? item.plan : "NA"}
                            </Td>
                            <Td
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {item.insuranceType && item.insuranceType.trim()
                                ? item.insuranceType
                                : "NA"}
                            </Td>

                            <Td
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {
                                InsuranceType.find(
                                  (ref) => ref.value === item.type
                                )?.label
                              }
                            </Td>
                            <Td
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {item.amount?.toLocaleString() ?? "NA"}
                            </Td>
                            <Td
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {item.opdAmount?.toLocaleString() ?? "NA"}
                            </Td>
                            <Td
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {item.naturalDeathAmount?.toLocaleString() ??
                                "NA"}
                            </Td>
                            <Td
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {item.accidentalDeathAmount?.toLocaleString() ??
                                "NA"}
                            </Td>
                            <Td
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {item.roomCharges?.toLocaleString() ?? "NA"}
                            </Td>

                            <Td
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {item.coverage === "SINGLE"
                                ? "Single"
                                : item.coverage === "MARRIED_WITH_CHILDREN"
                                ? "Spouse,Children"
                                : item.coverage === "WITH_PARENTS"
                                ? "Parents"
                                : item.coverage}
                            </Td>
                            <Tooltip
                              label={`${item.otherInformation}`}
                              placement="top"
                              hasArrow
                              bg="gray.800"
                              color="white"
                              fontSize="sm"
                              p="2"
                              rounded="md"
                              overflowWrap="anywhere"
                              whiteSpace="normal"
                              zIndex="10"
                            >
                              <Td
                                maxW="150px"
                                isTruncated
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                {item.otherInformation &&
                                item.otherInformation.trim()
                                  ? item.otherInformation
                                  : "NA"}
                              </Td>
                            </Tooltip>

                            <Td
                              padding="10px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              <HStack alignItems={"center"}>
                                <Icon
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="50%"
                                  borderStyle={"solid"}
                                  borderWidth={"1px"}
                                  borderColor={"borders.tableBorder"}
                                  bg={"#fff"}
                                  w="25px"
                                  h="25px"
                                  px="5px"
                                  py="5px"
                                  as={MdEdit}
                                  fontSize="22px"
                                  color="#6B7280"
                                  _hover={{
                                    color: "#3454d1",
                                  }}
                                  onClick={() => {
                                    onOpen();
                                    form.reset(item);
                                  }}
                                />
                                <Icon
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="50%"
                                  borderStyle={"solid"}
                                  borderWidth={"1px"}
                                  borderColor={"borders.tableBorder"}
                                  bg={"#fff"}
                                  w="25px"
                                  h="25px"
                                  px="5px"
                                  py="5px"
                                  as={MdDelete}
                                  fontSize="22px"
                                  color="#6B7280"
                                  _hover={{
                                    color: "#3454d1",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDeleteId(item.id);
                                    deleteModal.onOpen();
                                  }}
                                />
                                {/* <Button
                                  as={IconButton}
                                  icon={<MdEdit size="16px" />}
                                  colorScheme="primary"
                                  variant="outline"
                                  size="xs"
                                  borderRadius="5px"
                                  borderColor={"gray.400"}
                                  onClick={() => {
                                    onOpen();
                                    form.reset(item);
                                  }}
                                />
                                <Button
                                  size="xs"
                                  as={IconButton}
                                  icon={<MdDelete size="16px" />}
                                  colorScheme="primary"
                                  variant="outline"
                                  borderRadius="5px"
                                  borderColor={"gray.400"}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDeleteId(item.id);
                                    deleteModal.onOpen();
                                  }}
                                ></Button> */}
                              </HStack>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </Box>
                <Stack alignItems={"flex-end"} margin={"20px 20px 20px 0px"}>
                  <Button
                    colorScheme={"green"}
                    borderRadius={"3px"}
                    border={"1px solid #dcdee4"}
                    fontSize={"13px"}
                    h={"40px"}
                    variant={"solid"}
                    textColor={"customColor.white"}
                    padding={"12px 16px"}
                    marginRight={"5px"}
                    fontWeight={600}
                    onClick={() => setSelectedTab(selectedTab + 1)}
                  >
                    Next
                  </Button>
                </Stack>
              </Skeleton>
            </Box>
          </Box>
        </Stack>

        <InsuranceModal
          isOpen={isOpen}
          onClose={onClose}
          setLastUpdated={setLastUpdated}
        />
        <DeleteInsurance
          isOpen={deleteModal.isOpen}
          onClose={deleteModal.onClose}
          id={deleteId}
          setLastUpdated={setLastUpdated}
        />
      </Fragment>
    </FormProvider>
  );
}
