import { axiosInstance } from "./axiosInstance-aud";

declare module NotificationSetting {
  interface NotificationItem {
    businessId: string;
    notificationTypeId: number;
    eventId: number;
    id?: number;
  }

  type EventType = {
    createdAt: string;
    updatedAt: string;
    id: number;
    deletedAt?: string;
    name: string;
    description?: string;
    events: Array<{
      createdAt: string;
      updatedAt: string;
      id: number;
      deletedAt: string;
      title: string;
      description: string;
    }>;
  };

  type NotificationType = {
    createdAt: string;
    updatedAt: string;
    id: number;
    deletedAt?: string;
    name: "Email" | "SMS" | "Push";
  };

  type NotificationPayload = {
    business_notification_settings: {
      business: string;
      email: boolean;
      push: boolean;
      sms: boolean;
    };
    event_notification_settings: Array<NotificationItem>;
  };
}
module NotificationSetting {
  const BASE_URL = ``;
  export function Fetch(
    success: (response: NotificationPayload) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${BASE_URL}/business-notifications`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function BulkCreateBusinessNotifications(
    requestData: NotificationPayload,
    success: (response: NotificationPayload) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${BASE_URL}/business-notifications/bulk-create`, requestData)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function GetNotificationTypes(
    success: (response: Array<NotificationType>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${BASE_URL}/notification-types`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function GetEventTypes(
    success: (response: Array<EventType>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${BASE_URL}/event-types`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { NotificationSetting };
