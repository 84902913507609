import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from '@chakra-ui/react';
import {useTemplateReport} from '../../../../report';

export default function ConfirmationModal(props: Omit<ModalProps, 'children'>) {
  const {
    isDrawer,
    setDisplayedComponent,
    GoBackToPrevComponent,
    isNewTemplate,
    isAllTemplate,
  } = useTemplateReport();
  return (
    <Modal size="lg" isCentered={true} {...props}>
      <ModalOverlay />
      <ModalContent p="24px" m={0}>
        <ModalHeader p={0} mb="8px">
          <Text
            flex={1}
            fontSize="21px"
            fontWeight={500}
            color="textcolors.primary">
            Discard Report layout changes?
          </Text>
        </ModalHeader>
        <ModalBody p={0}>
          <Text
            fontSize="13px"
            fontWeight={400}
            color="icons.primary"
            lineHeight="24px">
            You've made changes to a Report layout that haven't been saved yet.
            All changes will be lost if you leave this page.
          </Text>
        </ModalBody>

        <ModalFooter p={0} mt="32px">
          <HStack direction={{xs: 'column', md: 'row'}} w="100%" justify="end">
            <Button
              h="40px"
              borderRadius="5px"
              fontSize="14px"
              fontWeight={500}
              variant="outline"
              padding={'12px 20px'}
              onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              h="40px"
              w={'auto'}
              borderRadius="5px"
              fontSize="14px"
              fontWeight={500}
              marginRight="16px"
              padding={'12px 20px'}
              onClick={() => GoBackToPrevComponent()}>
              Yes, discard changes
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
