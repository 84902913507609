import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Stack,
  Text,
  useBoolean,
  useBreakpointValue,
  useDisclosure,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import moment from "moment";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FiCheck, FiMoreVertical } from "react-icons/fi";
import { useDebouncedCallback } from "use-debounce";
import { ReactSelect } from "../../../../../Common/ReactSelect";
import { Inspection } from "../../../../modules/Audit";
import Group from "../../../template/pages/access-components/Popovers/Group";
import Filter from "../../../template/pages/MainPage-components/components/Filter";
import SubHeading from "../../../template/pages/Sub-Heading";
import ConductedDate from "./ConductedDate";
import InspectionDrawer from "./Drawers/InspectionDrawer";
import InspectionTableLg from "./InspectionTableLg";
import InspectionTableSm from "./InspectionTableSm";
export default function Inspections() {
  const customdate = useDisclosure();
  const inspectiondrawer = useDisclosure();
  const toast = useToast({ position: "top" });
  const [searchText, setSearchText] = useState("");
  const [inspectionsTimeWise, setInspectionsTimeWise] =
    useState<Inspection.InspectionDateWise>({});
  const [isLoading, setIsLoading] = useBoolean(true);
  const [inspection, setInspection] =
    useState<Inspection.InpsectionMetaPayload>();
  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const searchedInspections = useCallback(() => {
    const filteredInspectionsTimeWise = Object.entries(
      inspectionsTimeWise
    ).reduce(
      (
        acc: { [key: string]: Inspection.InpsectionMetaPayload[] },
        [date, inspections]
      ) => {
        const filteredInspections = inspections?.filter((inspection) =>
          `${inspection?.templateMeta?.site} / ${inspection?.templateMeta?.person}`
            .toLowerCase()
            .includes(searchText.toLowerCase())
        );
        if (filteredInspections?.length > 0) {
          acc[date] = filteredInspections;
        }
        return acc;
      },
      {}
    );
    return filteredInspectionsTimeWise;
  }, [searchText, inspectionsTimeWise]);
  // const limit = 10;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const [totalInspections, setTotalInspections] = useState<number>(0);
  const pageCount = Math.ceil(totalInspections / limit);
  const futureImplemetation = true;
  const debounced = useDebouncedCallback((value) => {
    setSearchText(value);
  }, 1000);
  const Types: Array<{ label: string; value?: string }> = [
    {
      label: "All",
      value: undefined,
    },
    {
      label: "Audit",
      value: "audit",
    },
    {
      label: "Induction",
      value: "induction",
    },
    {
      label: "Warehouse",
      value: "warehouse",
    },
  ];
  const [inspectionType, setInspectionType] = useState<string | undefined>(
    undefined
  );
  useEffect(() => {
    Inspection.Fetch(
      {
        page: pageNumber,
        limit: limit,
        archive: false,
        search: searchText,
        inspectionType: inspectionType,
      },
      (data, total) => {
        const timeWise = data.reduce((acc, curr) => {
          const date = moment(curr.inspectedOn);
          const key = date.format("DD/MM/YYYY");
          if (acc[key]) {
            acc[key].push(curr);
          } else {
            acc[key] = [curr];
          }
          return acc;
        }, {} as { [key: string]: Inspection.InpsectionMetaPayload[] });
        setInspectionsTimeWise(timeWise);
        setTotalInspections(total);
        setIsLoading.off();
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
        setIsLoading.off();
      }
    );
  }, [pageNumber, lastUpdate, searchText, limit, inspectionType]);

  const TableSize = useBreakpointValue({
    xs: (
      <InspectionTableSm
        onRowClick={(inspecion) => {
          inspectiondrawer.onOpen();
          setInspection(inspecion);
        }}
        data={searchedInspections()}
        isLoading={isLoading}
      />
    ),
    md: (
      <InspectionTableLg
        data={searchedInspections()}
        onRowClick={(inspecion) => {
          inspectiondrawer.onOpen();
          setInspection(inspecion);
        }}
        isLoading={isLoading}
        setLastUpdate={setLastUpdate}
      />
    ),
  });
  return (
    <Fragment>
      <InspectionDrawer
        isOpen={inspectiondrawer.isOpen}
        onClose={inspectiondrawer.onClose}
        inspection={inspection}
      />
      <Stack px={{ sm: "0px", md: "32px", xl: "50px" }} py="32px">
        <SubHeading fontSize="24px" px={{ xs: "16px", md: "0px" }}>
          Inspections
        </SubHeading>
        <Stack
          px={{ xs: "16px", md: "0px" }}
          // justifyContent="space-between"
          direction={{ xs: "column", md: "row" }}
        >
          <Stack direction={{ xs: "column", md: "row" }} flex={1}>
            <Stack>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<AiOutlineSearch color="#545f70" size="18px" />}
                />
                <Input
                  minW={{ xs: "100%", md: "400px" }}
                  placeholder="Search Template name"
                  border="1px solid"
                  borderColor="borders.accent"
                  fontSize="16px"
                  lineHeight="1.5rem"
                  fontWeight={400}
                  color="textcolors.primary"
                  py="8px"
                  pl="38px"
                  _focusVisible={{
                    boxShadow: "none",
                    border: "1px sloid",
                    borderColor: "primary.500",
                  }}
                  onChange={(e) => {
                    debounced(e.target.value);
                  }}
                />
              </InputGroup>
            </Stack>
            <Stack marginTop={{ xs: "0px", md: "-27px" }} w={"250px"}>
              <ReactSelect
                placeholder="Type"
                options={Types}
                value={
                  Types.find((op) => op.value === inspectionType) || Types[0]
                }
                onChange={(newValue: any) => {
                  setInspectionType(newValue?.value);
                }}
                label="Type"
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            </Stack>
            {futureImplemetation ?? (
              <HStack flex={1}>
                <Wrap spacing="8px">
                  <WrapItem>
                    <Filter
                      isOpen={customdate.isOpen}
                      onClick={customdate.onToggle}
                      onClose={customdate.onClose}
                      Body={<ConductedDate />}
                      Label="Conducted date"
                    />
                  </WrapItem>
                  <WrapItem>
                    <ButtonGroup
                      size="md"
                      isAttached
                      variant="outline"
                      fontSize="14px"
                    >
                      <Popover
                        //   isOpen={GroupDisclosure.isOpen}
                        placement="bottom-start"
                      >
                        <PopoverTrigger>
                          <Button
                            px="12.5px"
                            py="12px"
                            borderLeftRadius="8px"
                            maxW="350px"
                            //   onClick={GroupDisclosure.onToggle}
                          >
                            Available to:
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent w="400px">
                          <PopoverBody>
                            <Group />
                          </PopoverBody>
                          <PopoverFooter p={0} textAlign="end">
                            <Button
                              h="32px"
                              margin="8px"
                              variant="solid"
                              px="13px"
                              fontSize="13px"
                              borderRadius="8px"
                              lineHeight={1}
                            >
                              Done
                            </Button>
                          </PopoverFooter>
                        </PopoverContent>
                      </Popover>
                      <IconButton
                        zIndex={1}
                        backgroundColor="white"
                        p="12px"
                        borderRightRadius="8px"
                        aria-label="Add to friends"
                        icon={<AiOutlineClose />}
                      />
                    </ButtonGroup>
                  </WrapItem>
                </Wrap>
                <Menu>
                  <MenuButton
                    as={Button}
                    h="41px"
                    minW="110px"
                    variant="ghost"
                    px="20px"
                    py="12px"
                    fontWeight={500}
                    iconSpacing={0.5}
                    color="primary.500"
                    borderRadius="8px"
                  >
                    + Add filter
                  </MenuButton>
                  <MenuList minW="160px">
                    {[
                      "Access",
                      "Template",
                      "Groups",
                      "Site",
                      "Conducted date",
                    ].map((menu, i) => {
                      return (
                        <Fragment>
                          <MenuItem
                            px="14px"
                            py="9.6px"
                            fontSize="14px"
                            fontWeight={400}
                            color="textcolors.primary"
                          >
                            {menu}
                          </MenuItem>
                        </Fragment>
                      );
                    })}
                  </MenuList>
                </Menu>
              </HStack>
            )}
          </Stack>
          <HStack spacing={0} justify="end">
            <Text fontSize="14px" color="icons.primary">
              {`(${limit * (pageNumber - 1) + 1}-${
                Object.values(searchedInspections()).reduce(
                  (acc, inspections) => acc + inspections.length,
                  0
                ) +
                limit * (pageNumber - 1)
              } of ${totalInspections})`}
            </Text>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FiMoreVertical size={24} color="#545f70" />}
                variant="outline"
                borderRadius={"50%"}
                border={"none"}
                _hover={{ backgroundColor: "transparent" }}
                _active={{
                  backgroundColor: "transparent",
                }}
              />
              <MenuList minW="160px">
                <MenuGroup title="Results per page." fontWeight={400}>
                  {[25, 50, 100].map((menu, i) => {
                    const isSelected = menu === limit;
                    return (
                      <Fragment>
                        <MenuItem
                          px="16px"
                          py="8px"
                          onClick={() => {
                            setLimit(menu);
                            {
                              menu >= totalInspections && setPageNumber(1);
                            }
                          }}
                        >
                          <HStack w="100%">
                            <Box w="15%">
                              {isSelected && (
                                <FiCheck size="18px" color="#1da5d4" />
                              )}
                            </Box>
                            <Text
                              fontSize="14px"
                              color="textcolors.primary"
                              w="85%"
                            >
                              {menu}
                            </Text>
                          </HStack>
                        </MenuItem>
                      </Fragment>
                    );
                  })}
                </MenuGroup>
              </MenuList>
            </Menu>
          </HStack>
        </Stack>
        {TableSize}
        <HStack spacing="13px" justify={{ xs: "center", md: "end" }}>
          <IconButton
            aria-label="back"
            backgroundColor="transparent"
            _hover={{ backgroundColor: "transparent" }}
            icon={
              <BsChevronLeft
                size={19}
                color={
                  pageNumber <= 1 || pageNumber > pageCount
                    ? "#828ea0"
                    : "#000000"
                }
              />
            }
            isDisabled={
              pageNumber <= 1 || pageNumber > pageCount ? true : false
            }
            onClick={() => {
              if (pageNumber > 1 && pageNumber <= pageCount)
                pageNumberInputRef.current?.value &&
                  (pageNumberInputRef.current.value = String(pageNumber - 1));
              setPageNumber(pageNumber - 1);
              console.log(pageNumber);
            }}
          />
          <Input
            ref={pageNumberInputRef}
            h="37px"
            w="56px"
            backgroundColor="backgrounds.primary"
            defaultValue={pageCount}
            textAlign="center"
            borderColor="borders.accent"
            // onChange={e => {
            //   const pageValue = e.target.value;
            //   setPageNumber(parseInt(pageValue));
            // }}
            onBlur={(e) => {
              const pageValue = e.target.value;
              setPageNumber(parseInt(pageValue));
            }}
          />

          <Text color="textcolors.secondary">/</Text>
          <Text w="40px" textAlign="center">
            {pageCount}
          </Text>
          <IconButton
            aria-label="right"
            backgroundColor="transparent"
            _hover={{ backgroundColor: "transparent" }}
            icon={
              <BsChevronRight
                size={19}
                color={
                  pageNumber >= pageCount || pageNumber < 1
                    ? "#828ea0"
                    : "#000000"
                }
              />
            }
            isDisabled={
              pageNumber >= pageCount || pageNumber < 1 ? true : false
            }
            onClick={() => {
              if (pageNumber < pageCount && pageNumber >= 1) {
                pageNumberInputRef.current?.value &&
                  (pageNumberInputRef.current.value = String(pageNumber + 1));
                setPageNumber(pageNumber + 1);
              }

              console.log(pageNumber);
            }}
          />
        </HStack>
      </Stack>
    </Fragment>
  );
}
