import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  TabList,
  Tabs,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiOutlineCaretDown } from "react-icons/ai";
import { FiMoreVertical } from "react-icons/fi";
import { LuArrowDownToLine, LuArrowUpToLine } from "react-icons/lu";
import { MdOutlineReceiptLong } from "react-icons/md";
import { RiArrowDownSFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { ContactApi } from "../../Api/Contact";
import { GroupForContact } from "../../Api/GroupForContact";
import { AllContactsTable } from "./AllContactsTable";
import { TableForGroupData } from "./TableForGroupData";

export function AllContact() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  const [clicked, setClicked] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string[]>([]);

  useEffect(() => {
    GroupForContact.FetchGroupDetails(
      {
        name: undefined,
        isActive: true,
        limit: 500,
        page: 1,
      },
      (data) => {
        setGroupData(data.data);
        setMenuId(undefined);
      },
      (err) => {}
    );
  }, [lastUpdated]);
  const [groupData, setGroupData] = useState<Array<GroupForContact.FetchGroup>>(
    []
  );
  const [contactData, setContactData] =
    useState<GroupForContact.FetchGroupDataById>();
  const isMediumScreen = useBreakpointValue({ base: false, md: true });

  const tabCategories = [
    {
      label: "All",
      index: 0,
      flexValue: 1,
      component: (
        <AllContactsTable
          setLastUpdated={setLastUpdated}
          lastUpdated={lastUpdated}
          activeState={true}
          index={0}
          setSelectedId={setSelectedId}
          selectedId={selectedId}
        />
      ),
    },
    {
      label: "Customer",
      index: 1,
      flexValue: 1,
      // component: <AllContactsTable />,
    },
    {
      label: "Suppliers",
      index: 2,
      flexValue: 1,
      // component: <AllContactsTable />,
    },
    {
      label: "Achieved",
      index: 3,
      flexValue: 1,
      component: (
        <AllContactsTable
          setLastUpdated={setLastUpdated}
          lastUpdated={lastUpdated}
          activeState={false}
          setSelectedId={setSelectedId}
          selectedId={selectedId}
          index={3}
        />
      ),
    },
    {
      label: "Groups",
      index: 4,
      flexValue: 1,
      isMenu: true,
      component: (
        <TableForGroupData
          contactData={contactData}
          setLastUpdated={setLastUpdated}
          lastUpdated={lastUpdated}
          index={4}
        />
      ),
      menuOptions: groupData.map((group, i) => ({
        ...group,
        index: 4, // Assign the index based on the current iteration
      })),
    },
  ];

  const handleTabChange = (index: any) => {
    setSelectedTab(index);
    setSelectedId([""]);

    if (index !== 4) {
      setMenuId(undefined); // Only call setMenuId if index is not 4
    }
  };
  const form = useForm<ContactApi.AddContact>({
    defaultValues: {
      // id: undefined,
      name: undefined,
      isActive: true,
      accountNumber: undefined,
      primaryPerson: {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
      },
      // additionalPersons: [
      //   {
      //     firstName: undefined,
      //     lastName: undefined,
      //     email: undefined,
      //   },
      // ],
      businessInformation: {
        phoneCountry: undefined,
        phoneArea: undefined,
        phoneNumber: undefined,
        website: undefined,
        ABN: undefined,
        ACN: undefined,
        notes: undefined,
      },
      deliveryAddress: {
        attention: undefined,
        addresss: undefined,
        city: undefined,
        state: undefined,
        zipCode: undefined,
        country: undefined,
      },
      billingAddress: {
        attention: undefined,
        addresss: undefined,
        city: undefined,
        state: undefined,
        zipCode: undefined,
        country: undefined,
      },
      financialDetails: {
        bankAccountName: undefined,
        BSBCode: undefined,
        bankAccountNumber: undefined,
        reference: undefined,
        currency: undefined,
      },
      salesDefaults: {
        salesAccountId: undefined,
        invoiceDueDay: undefined,
        invoiceDueDateOption: undefined,
        saleGST: undefined,
        amountType: undefined,
        region: undefined,
        discount: undefined,
        creditLimitAmount: undefined,
        blockNewInvoiceWhenLimitReached: undefined,
      },
      purchaseDefaults: {
        purchaseAccountId: undefined,
        billDueDay: undefined,
        billDueDateOption: undefined,
        purchaseGST: undefined,
        amountType: undefined,
        region: undefined,
      },
    },
  });
  const [menuId, setMenuId] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (!!menuId) {
      GroupForContact.FetchGroupDetailsById(
        menuId!,
        (response) => {
          setContactData(response);
          console.log("Get Detail Api Success");
        },
        (err) => {
          console.log("Get Detail Api failed");
        }
      );
    }
  }, [menuId, lastUpdated]);
  return (
    <FormProvider {...form}>
      <Stack
        gap={0}
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
        h="calc(100vh - 70px)"
        overflowY="auto"
      >
        <Stack
          h={"60px"}
          justifyContent={"center"}
          borderBottom={"1px solid #d6dade"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
        >
          <Container maxW={"container.xl"} p={{ xs: "16px", lg: "16px 50px" }}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Stack>
                <Heading
                  fontSize="17px"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{ color: "customColor.white" }}
                  fontWeight="bold"
                >
                  Contacts
                </Heading>
              </Stack>
              <Stack>
                <HStack gap={0}>
                  <Button
                    fontSize={"13px"}
                    bgColor="#3454D1"
                    h={"40px"}
                    borderRadius={"3px"}
                    borderWidth={"1px"}
                    borderColor={"#3454d1!important"}
                    variant={"solid"}
                    padding={"12px 16px"}
                    fontWeight={700}
                    _hover={{
                      bgColor: "#2c48b5",
                    }}
                    onClick={() => {
                      navigate("/app/payroll/CreateContact", {
                        state: {
                          contactId: undefined,
                        },
                      });
                    }}
                  >
                    New Contact
                  </Button>

                  <Menu>
                    <MenuButton
                      as={Button}
                      padding={0}
                      backgroundColor={"transparent"}
                      h={"34.5px"}
                      marginLeft={"8px"}
                      color={"customColor.black7"}
                      borderRadius={"full"}
                      _hover={{
                        color: "#fff",
                        backgroundColor: "#c1caf1",
                      }}
                      _active={{
                        color: "#fff",
                        backgroundColor: "#97a7e7",
                      }}
                    >
                      <Icon as={FiMoreVertical} />
                    </MenuButton>
                    <MenuList style={{ minWidth: "140px" }}>
                      {[
                        { name: "Import", icon: <LuArrowDownToLine /> },
                        { name: "Export", icon: <LuArrowUpToLine /> },
                        {
                          name: "Send Statement",
                          icon: <MdOutlineReceiptLong />,
                        },
                      ].map((menuItem, i) => (
                        <MenuItem key={i}>
                          <Button
                            leftIcon={menuItem.icon}
                            variant={"ghost"}
                            fontWeight={"600"}
                            color={"customColor.black7"}
                            fontSize={"13.5px"}
                            _hover={{ bg: "none" }}
                          >
                            {menuItem.name}
                          </Button>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </HStack>
              </Stack>
            </Stack>
          </Container>
        </Stack>
        <Stack
          justifyContent={"center"}
          borderBottom={"1px solid #d6dade"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
        >
          <Container maxW={"container.xl"} p={{ xs: "16px", lg: "0px 50px" }}>
            <Stack
              marginTop={"24px"}
              borderTopRadius={"10px"}
              borderTop={isMediumScreen ? "1px solid #ddd" : 0}
              borderLeft={isMediumScreen ? "1px solid #ddd" : 0}
              borderRight={isMediumScreen ? "1px solid #ddd" : 0}
              transition={"all .3s ease"}
            >
              {isMediumScreen ? (
                <Tabs index={selectedTab} orientation="horizontal" isLazy>
                  <TabList>
                    {tabCategories.map((tab, i) => {
                      return tab.isMenu ? (
                        <Menu key={i}>
                          <MenuButton
                            onClick={() => setClicked(true)}
                            fontSize={"13.44px"}
                            fontWeight={"600"}
                            marginBottom={"-2px"}
                            flex={tab.flexValue}
                            borderBottom={
                              clicked ? "3px solid #3454d1" : "none"
                            }
                            color={clicked ? "#3454d1" : "black"}
                            bg={
                              clicked ? "rgba(52,84,209,.075)" : "transparent"
                            }
                          >
                            {tab.label}
                          </MenuButton>
                          <MenuList>
                            {tab.menuOptions.map((menu, index) => (
                              <MenuItem
                                key={index}
                                fontSize={"13.5px"}
                                fontWeight={"600"}
                                onClick={() => {
                                  setMenuId(menu.id);

                                  handleTabChange(menu.index);
                                }}
                              >
                                {menu.name}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </Menu>
                      ) : (
                        <Tab
                          onClick={() => {
                            handleTabChange(tab.index);
                            setClicked(false);
                          }}
                          key={i}
                          flex={tab.flexValue}
                          borderRight={
                            tab.index !== 5 ? "1px solid #ddd" : "none"
                          }
                          fontSize={"13.44px"}
                          padding={"20px 15px"}
                          fontWeight={"600"}
                          _light={{ color: "#283c50" }}
                          _selected={{
                            _light: { color: "#3454d1" },
                            fontWeight: 600,
                            bg: "rgba(52,84,209,.075)",
                            borderBottom: "3px solid #3454d1",
                          }}
                          _hover={{
                            _light: { color: "#3454d1" },
                            fontWeight: 600,
                          }}
                        >
                          {tab.label}
                        </Tab>
                      );
                    })}
                  </TabList>
                </Tabs>
              ) : (
                <Box alignContent={"flex-end"} justifyContent={"flex-end"}>
                  <Menu closeOnSelect={false}>
                    {/* Outer Menu */}
                    {({ onClose }) => (
                      <>
                        <MenuButton
                          as={Button}
                          rightIcon={<AiOutlineCaretDown />}
                          backgroundColor={"#fff"}
                          borderRadius={"3px"}
                          justifyContent="flex-end"
                          border={"1px solid #dcdee4"}
                          fontSize={"13px"}
                          h={"40px"}
                          minW={"170px"}
                          variant={"solid"}
                          textColor={"customColor.black7"}
                          padding={"12px 16px"}
                          fontWeight={600}
                          _hover={{
                            color: "primary.950",
                            backgroundColor: "#fff",
                          }}
                          cursor={"pointer"}
                          _active={{
                            backgroundColor: "#fff",
                            color: "primary.950",
                          }}
                        >
                          {tabCategories[selectedTab].label}
                        </MenuButton>
                        <MenuList>
                          {tabCategories.slice(0, 4).map((tab) => (
                            <MenuItem
                              fontSize={"13.44px"}
                              key={tab.index}
                              onClick={() => {
                                setSelectedTab(tab.index);
                                onClose(); // Close the outer menu when a tab is selected
                              }}
                            >
                              {tab.label}
                            </MenuItem>
                          ))}
                          {/* Nested Menu for "Groups" */}
                          <MenuItem fontSize={"13.44px"} key={4} width="100%">
                            <Menu closeOnSelect={true}>
                              {" "}
                              {/* Close the inner menu on selection */}
                              <MenuButton
                                as={Button}
                                rightIcon={<RiArrowDownSFill />}
                                width="100%"
                                h={"23px"}
                                fontWeight={"500"}
                                textAlign="left"
                                fontSize={"13.44px"}
                                _hover={{
                                  bg: "transparent",
                                }}
                                _active={{
                                  bg: "transparent",
                                }}
                                padding={0}
                                color={"black"}
                                bg={"transparent"}
                              >
                                Groups
                              </MenuButton>
                              <MenuList width="100%">
                                {tabCategories[4]?.menuOptions &&
                                tabCategories[4].menuOptions.length > 0 ? (
                                  tabCategories[4].menuOptions.map((group) => (
                                    <MenuItem
                                      width="100%"
                                      key={group.id}
                                      fontSize={"13.44px"}
                                      onClick={() => {
                                        setMenuId(group.id);

                                        handleTabChange(group.index);
                                      }}
                                    >
                                      {group.name}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem fontSize={"13.44px"} disabled>
                                    No Groups Available
                                  </MenuItem>
                                )}
                              </MenuList>
                            </Menu>
                          </MenuItem>
                        </MenuList>
                      </>
                    )}
                  </Menu>
                </Box>
              )}
            </Stack>
          </Container>
        </Stack>
        <Container
          maxW={"container.xl"}
          padding={{ xs: "16px", lg: "30px 50px" }}
        >
          <Box>{tabCategories[selectedTab].component}</Box>
        </Container>
      </Stack>
    </FormProvider>
  );
}
