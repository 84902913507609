import {
  Checkbox,
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import {Fragment} from 'react';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import {AiFillDelete, AiOutlineNodeIndex} from 'react-icons/ai';
import {FaPaste} from 'react-icons/fa';
import {FiMoreVertical} from 'react-icons/fi';
import {Template} from '../../../modules/Audit';
import {getFieldKey} from '../../../modules/form-apis';
import {useItemContext} from '../hooks/auditItemContext';

interface QuestionSettingSmProps {
  questionType: Template.QuestionType;
}
export function QuestionSettingSm({questionType}: QuestionSettingSmProps) {
  function List() {
    if (questionType === 'TEXT_ANSWER') return <TextMenu />;

    if (questionType === 'NUMBER') return <NumberMenu />;
    if (questionType === 'INSPECTION_DATE') return <InspectionDateMenu />;
    if (questionType === 'INSPECTION_LOCATION')
      return <InspectionLocationMenu />;
    if (questionType === 'SITE') return <SiteMenu />;
    if (questionType === 'DOCUMENT_NUMBER') return <DocumentNumberMenu />;
    if (questionType === 'CHECKBOX') return <CheckboxMenu />;
    if (questionType === 'DATE_TIME') return <DateTimeMenu />;
    if (questionType === 'MEDIA') return <MediaMenu />;
    if (questionType === 'SLIDER') return <SliderMenu />;
    if (questionType === 'ANNOTATION') return <AnnotationMenu />;
    if (questionType === 'SIGNATURE') return <SignatureMenu />;
    if (questionType === 'LOCATION') return <LocationMenu />;
    if (questionType === 'INSTRUCTIONS') return <InstructionMenu />;
    if (questionType === 'PERSON') return <PersonMenu />;
    if (questionType === 'MULTICHOICE_RADIO') return <MultiOptionMenu />;
    return <div></div>;
  }

  return (
    <Fragment>
      <Menu closeOnSelect={false}>
        <MenuButton as={'div'}>
          <Icon as={() => <FiMoreVertical size={24} color="#545f70" />} />
        </MenuButton>

        <List />
      </Menu>
    </Fragment>
  );
}

export function MultiOptionMenu() {
  return (
    <MenuList minWidth={'100px'} display={{xs: 'block', md: 'none'}}>
      <Required />
      <MultipleSelection />
      <Flagged />
      <Addlogic />
      <Delete />
    </MenuList>
  );
}

export function DocumentNumberMenu() {
  return (
    <MenuList minWidth={'100px'} display={{xs: 'block', md: 'none'}}>
      <Required />
      <DocNumber />
      <Addlogic />
      <Delete />
      <Paste />
    </MenuList>
  );
}
export function SiteMenu() {
  return (
    <MenuList minWidth={'100px'} display={{xs: 'block', md: 'none'}}>
      <Required />
      <Delete />
      <Paste />
    </MenuList>
  );
}

export function InspectionDateMenu() {
  return (
    <MenuList minWidth={'100px'} display={{xs: 'block', md: 'none'}}>
      <Required />
      <Date />
      <Time />

      <Delete />
      <Paste />
    </MenuList>
  );
}
export function InspectionLocationMenu() {
  return (
    <MenuList minWidth={'100px'} display={{xs: 'block', md: 'none'}}>
      <Required />
      <Delete />
      <Paste />
    </MenuList>
  );
}

export function TextMenu() {
  return (
    <MenuList minWidth={'100px'} display={{xs: 'block', md: 'none'}}>
      <Required />
      <FormatText />
      <Addlogic />
      <Delete />
      <Paste />
    </MenuList>
  );
}

export function NumberMenu() {
  return (
    <MenuList minWidth={'100px'} display={{xs: 'block', md: 'none'}}>
      <Required />
      <FormatNumber />
      <Addlogic />
      <Delete />
      <Paste />
    </MenuList>
  );
}

export function SliderMenu() {
  return (
    <MenuList minWidth={'100px'} display={{xs: 'block', md: 'none'}}>
      <Required />
      <SliderBar />
      <Addlogic />
      <Delete />
      <Paste />
    </MenuList>
  );
}
export function CheckboxMenu() {
  return (
    <MenuList minWidth={'100px'} display={{xs: 'block', md: 'none'}}>
      <Required />
      <Addlogic />
      <Delete />
      <Paste />
    </MenuList>
  );
}
export function DateTimeMenu() {
  return (
    <MenuList minWidth={'100px'} display={{xs: 'block', md: 'none'}}>
      <Required />
      <Date />
      <Time />

      <Delete />
      <Paste />
    </MenuList>
  );
}
export function MediaMenu() {
  return (
    <MenuList minWidth={'100px'} display={{xs: 'block', md: 'none'}}>
      <Required />
      <Delete />
      <Paste />
    </MenuList>
  );
}

export function AnnotationMenu() {
  return (
    <MenuList minWidth={'100px'} display={{xs: 'block', md: 'none'}}>
      <Required />
      <UploadImage />
      <Delete />
      <Paste />
    </MenuList>
  );
}
export function SignatureMenu() {
  return (
    <MenuList minWidth={'100px'} display={{xs: 'block', md: 'none'}}>
      <Required />
      <Addlogic />
      <Delete />
      <Paste />
    </MenuList>
  );
}
export function LocationMenu() {
  return (
    <MenuList minWidth={'100px'} display={{xs: 'block', md: 'none'}}>
      <Required />
      <Delete />
      <Paste />
    </MenuList>
  );
}
export function InstructionMenu() {
  return (
    <MenuList minWidth={'100px'} display={{xs: 'block', md: 'none'}}>
      <UploadDoc />
      <Delete />
      <Paste />
    </MenuList>
  );
}
export function PersonMenu() {
  return (
    <MenuList minWidth={'100px'} display={{xs: 'block', md: 'none'}}>
      <Required />
      <Addlogic />
      <Delete />
      <Paste />
    </MenuList>
  );
}

export function Delete() {
  return (
    <MenuItem
      icon={<FaPaste size={18} color="#545f70" />}
      fontSize={'14px'}
      fontWeight={400}
      backgroundColor={'transparent'}
      color={'textcolors.primary'}>
      Delete
    </MenuItem>
  );
}
export function Paste() {
  return (
    <MenuItem
      icon={<AiFillDelete size={18} color="#545f70" />}
      fontSize={'14px'}
      fontWeight={400}
      backgroundColor={'transparent'}
      color={'textcolors.primary'}>
      Paste questions
    </MenuItem>
  );
}

export function Required() {
  return <MenuItem as={Checkbox}>Required</MenuItem>;
}
export function Flagged() {
  return (
    <MenuItem>
      <HStack>
        <Checkbox defaultChecked>Flagged responses</Checkbox>
        <Text
          fontSize="13px"
          fontWeight={400}
          borderRadius="12px"
          px="6.4px"
          py="3.2px"
          backgroundColor="#c600221a"
          color="#c60022">
          Poor
        </Text>
      </HStack>
    </MenuItem>
  );
}
export function MultipleSelection() {
  const template = useFormContext();
  const {item} = useItemContext();
  return (
    <MenuItem>
      <Controller
        name={getFieldKey(item, 'question.mcqsData.multiSelect')}
        control={template.control}
        render={({field}) => (
          <Checkbox
            {...field}
            isChecked={field.value}
            onChange={e => {
              field.onChange(e.target.checked);
            }}>
            Multiple Selection
          </Checkbox>
        )}
      />
    </MenuItem>
  );
}
export function Date() {
  return (
    <MenuItem>
      <Checkbox defaultChecked>Date</Checkbox>
    </MenuItem>
  );
}

export function Time() {
  return (
    <MenuItem>
      <Checkbox defaultChecked>Time</Checkbox>
    </MenuItem>
  );
}

export function Addlogic() {
  return (
    <MenuItem
      icon={<AiOutlineNodeIndex size={18} color="#545f70" />}
      fontSize={'14px'}
      fontWeight={400}
      backgroundColor={'transparent'}
      color={'textcolors.primary'}>
      Add logic
    </MenuItem>
  );
}
export function FormatText() {
  const template = useFormContext();
  const {item} = useItemContext();
  const watchedFormat = useWatch({
    control: template.control,
    name: getFieldKey(item, 'question.format'),
  });

  return (
    <MenuItem
      backgroundColor={'transparent'}
      color={'textcolors.primary'}
      onClick={() => {}}>
      <HStack>
        <Text fontSize={'14px'} fontWeight={400} color={'textcolors.primary'}>
          Format:
        </Text>
        <Text fontSize={'14px'} fontWeight={400} color={'blue'}>
          {watchedFormat}
        </Text>
      </HStack>
    </MenuItem>
  );
}
export function FormatNumber() {
  return (
    <MenuItem backgroundColor={'transparent'}>
      <HStack>
        <Text fontSize={'14px'} fontWeight={400} color={'textcolors.primary'}>
          Format:
        </Text>
        <Text fontSize={'14px'} fontWeight={400} color={'blue'}>
          number
        </Text>
      </HStack>
    </MenuItem>
  );
}
export function SliderBar() {
  return (
    <MenuItem fontSize={'14px'} fontWeight={400} color={'blue'}>
      <HStack>
        <Text>Range:</Text>
        <Text>1</Text>
        <Text>-</Text>
        <Text>10</Text>
      </HStack>
    </MenuItem>
  );
}
export function UploadImage() {
  return (
    <MenuItem
      fontSize={'14px'}
      fontWeight={400}
      backgroundColor={'transparent'}
      color={'blue'}>
      Upload image to annotate
    </MenuItem>
  );
}

export function UploadDoc() {
  return (
    <MenuItem backgroundColor={'transparent'}>
      <VStack>
        <Text color={'blue'} fontSize={'14px'} fontWeight={400}>
          Upload media attachment
        </Text>
        <Text color={'textcolors.primary'} fontSize={'12px'} fontWeight={400}>
          One image or PDF document can be uploaded
        </Text>
      </VStack>
    </MenuItem>
  );
}
export function DocNumber() {
  return (
    <MenuItem backgroundColor={'transparent'}>
      <HStack>
        <Text fontSize={'14px'} fontWeight={400} color={'textcolors.primary'}>
          Format:
        </Text>
        <Link fontSize={'14px'} fontWeight={400} color={'blue'}>
          12345
        </Link>
      </HStack>
    </MenuItem>
  );
}
