import {Button, Stack, Text, useDisclosure, useToast} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import {FiUpload} from 'react-icons/fi';
import {TemplateMeta} from '../../../../../../modules/Audit';
import Content from '../../../Content';
import PublicLibraryTable from './PublicLibraryTable';
import UploadTemplateModal from './UploadTemplateModal';

export default function PublicLibrary() {
  const uploadModal = useDisclosure();
  const [templatesList, setTemplatesList] = useState<
    Array<TemplateMeta.TemplateMeta>
  >([]);
  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const toast = useToast();

  useEffect(() => {
    TemplateMeta.Fetch(
      {archive: false},
      data => {
        setTemplatesList(data);
      },
      error => {
        toast({
          title: 'Error',
          description: error,
          status: 'error',
        });
      },
    );
  }, []);
  return (
    <Stack
      px={{xs: '0px', md: '32px', xl: '96px'}}
      py={{xs: '12px', md: '32px'}}
      spacing="24px">
      <Stack
        justifyContent="space-between"
        direction={{xs: 'column', md: 'row'}}
        px={{xs: '12px', md: '0px'}}>
        <Stack spacing={0}>
          <Text
            fontSize="24px"
            fontWeight={500}
            lineHeight="2rem"
            color="textcolors.primary">
            Public Library
          </Text>
          <Content fontSize="14px">
            Choose from our library of ready-to-go industry templates and
            customize as you go.
          </Content>
        </Stack>
        <Button
          h="41px"
          w="218px"
          leftIcon={<FiUpload size={17} />}
          px="20px"
          py="12px"
          borderRadius="8px"
          variant="outline"
          onClick={uploadModal.onOpen}>
          Upload to public library
        </Button>
      </Stack>
      <UploadTemplateModal
        isOpen={uploadModal.isOpen}
        onClose={uploadModal.onClose}
        templatesList={templatesList}
        setLastUpdate={setLastUpdate}
      />
      <PublicLibraryTable lastUpdate={lastUpdate} />
    </Stack>
  );
}
