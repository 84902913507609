import {
  Button,
  Drawer,
  HStack,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import FileSaver from "file-saver";
import { useEffect } from "react";
import { AiFillCloseCircle, AiOutlineDownload } from "react-icons/ai";
import { IoCheckmarkCircle } from "react-icons/io5";
import { Inspection } from "../../../../modules/Audit";
import { axiosInstance } from "../../../../modules/Audit/axiosInstance-aud";

interface BuildBottomProps {
  isOpen: boolean;
  onClose: () => void;
  inspectionIds: Array<number>;
  setLastUpdate: (timestamp: number) => void;
}

export default function BuildBottom({
  isOpen,
  onClose,
  inspectionIds,
  setLastUpdate,
}: BuildBottomProps) {
  // const {size, elapsed, percentage, download, cancel, error, isInProgress} =
  //   useDownloader({
  //     headers: {
  //       Authorization: `Bearer ${user?.token}`,
  //     },
  //     // body: JSON.stringify({
  //     //   ids: inspectionIds,
  //     // }),
  //   });
  useEffect(() => {
    inspectionIds.length == 0 && onClose();
  }, [inspectionIds]);
  const toast = useToast();
  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <Stack
        position="fixed"
        bottom={0}
        left={0}
        right={0}
        bgColor="White"
        w="100%"
        zIndex={21}
        padding="0px"
        backgroundColor={"primary.500"}
      >
        <HStack h="65px" justifyContent="space-between" px="96px" py="16px">
          <HStack>
            <IoCheckmarkCircle fill="white" size={23} />
            <Text
              fontSize="16px"
              fontWeight={400}
              color="white"
              lineHeight="24px"
            >
              {`${inspectionIds.length} items selected`}
            </Text>
          </HStack>

          <HStack spacing={4}>
            <Button
              // isLoading={isInProgress}
              // loadingText={`Downloaded ${percentage}%`}
              lineHeight="16px"
              px="12.8px"
              py="8px"
              borderRadius="8px"
              fontSize="13px"
              fontWeight={500}
              backgroundColor="white"
              color="primary.500"
              leftIcon={<AiOutlineDownload size={16} fill="#1da5d4" />}
              _hover={{ backgroundColor: "none" }}
              _active={{ backgroundColor: "none" }}
              onClick={() => {
                const inspectionIdsArray = inspectionIds.join(",");
                let URL = `inspection-meta/bulk/download-reports?ids=${inspectionIdsArray}`;

                // download(
                //   `${process.env.REACT_APP_API_URL}/${URL}`,
                //   // `http://localhost:3000/${URL}`,
                //   // `${form.getValues('templateMeta.inspectionDate')}.pdf`,
                //   'reports.zip',
                // ).catch(e => {
                //   console.log(e);
                // });

                axiosInstance({
                  url: `${process.env.REACT_APP_API_URL_AUDITOR}/${URL}`,
                  method: "GET",
                  responseType: "blob", // Important
                })
                  .then((response) => {
                    const blob = new Blob([response.data]);
                    FileSaver.saveAs(blob, "reports.zip");
                  })
                  .catch((e) => {
                    toast({
                      title: "Error",
                      description: e?.response?.data?.message || e.message,
                      status: "error",
                    });
                  });
              }}
            >
              PDF
            </Button>
            <Button
              lineHeight="16px"
              px="12.8px"
              py="8px"
              borderRadius="8px"
              fontSize="13px"
              fontWeight={500}
              backgroundColor="white"
              color="primary.500"
              leftIcon={<AiOutlineDownload size={16} fill="#1da5d4" />}
              _hover={{ backgroundColor: "none" }}
              _active={{ backgroundColor: "none" }}
              onClick={() => {
                Inspection.bulkArchive(
                  { archive: true },
                  { inspectionIds: inspectionIds },
                  () => {
                    toast({
                      title: "Inspections archived",
                      status: "success",
                    });
                    setLastUpdate(Date.now());
                    onClose();
                  },
                  (error) => {
                    toast({
                      title: "Error",
                      description: error,
                      status: "error",
                    });
                  }
                );
              }}
            >
              Archive
            </Button>

            {/* <BulkEdit isOpen={isOpen} onClose={onClose} /> */}

            <AiFillCloseCircle fill="white" size={19} onClick={onClose} />
          </HStack>
        </HStack>
      </Stack>
    </Drawer>
  );
}
