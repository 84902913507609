import {
  Button,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { IoMdInformationCircle } from "react-icons/io";
import { CustomInput } from "../../../../../../Common/CustomInput";
import User from "../Popovers/User";
interface CreateGroupProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function CreateGroup({ isOpen, onClose }: CreateGroupProps) {
  const InputField = useDisclosure();
  const Userpopover = useDisclosure();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Text
              flex={1}
              fontSize="21px"
              fontWeight={500}
              lineHeight={1.75}
              color="textcolors.primary"
            >
              Create Group
            </Text>
            <IconButton
              aria-label="close"
              backgroundColor="transparent"
              borderRadius="50%"
              onClick={onClose}
              _hover={{ backgroundColor: "transparent" }}
              _active={{ backgroundColor: "transparent" }}
              _focus={{ backgroundColor: "transparent" }}
              icon={<AiOutlineClose size={"24px"} color="#545F70" />}
            />
          </HStack>
        </ModalHeader>
        <ModalBody>
          <CustomInput
            input={{
              variant: "outline",

              type: "text",
            }}
            label="Group Name"
          />
          <Stack mt="8px">
            <Text
              color="textcolors.inputlabel"
              fontSize="14px"
              fontWeight={500}
            >
              Add Users
            </Text>

            <Popover matchWidth={true} isOpen={Userpopover.isOpen}>
              <PopoverTrigger>
                <HStack
                  border="1px solid"
                  borderColor="borders.accent"
                  _hover={{ borderColor: "primary.500" }}
                  borderRadius="4px"
                  px="16px"
                  py="8px"
                  onClick={Userpopover.onToggle}
                >
                  <Stack flex={1}>
                    <Text fontSize="16px" fontWeight={400} lineHeight="24px">
                      Danish
                    </Text>
                  </Stack>
                  {Userpopover.isOpen ? <FiChevronUp /> : <FiChevronDown />}
                </HStack>
              </PopoverTrigger>
              <PopoverContent w="100%">
                <PopoverBody>
                  <User
                    options={[
                      {
                        label: "danish",
                      },
                      {
                        label: "saoud",
                      },
                      {
                        label: "asif",
                      },
                    ]}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Stack>
          <Flex w="100%" mt="24px" fontSize="14px">
            <IoMdInformationCircle size={16} color="#545f70" />
            <Text
              w="100%"
              color="icons.primary"
              fontSize="14px"
              fontWeight={400}
              ml="8px"
              lineHeight="16px"
            >
              You can make changes to your groups at any time from inside your
              organization settings.
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter mt="16px">
          <HStack>
            <Button
              h="40px"
              borderRadius="5px"
              fontSize="14px"
              fontWeight={500}
              variant="outline"
              padding={"12px 20px"}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              h="40px"
              w={"auto"}
              borderRadius="5px"
              fontSize="14px"
              fontWeight={500}
              marginRight="16px"
              padding={"12px 20px"}
            >
              Create Group
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
