import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../Common/CustomInput";
import { GroupForContact } from "../../../Api/GroupForContact";

interface AddGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedContactId: string | undefined;
  setLastUpdated: (time: number) => void;
}
export default function AddGroupModal({
  isOpen,
  onClose,
  selectedContactId,
  setLastUpdated,
}: AddGroupModalProps) {
  const form = useFormContext<GroupForContact.AddGroup>();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          {selectedContactId ? "Update Group" : "Add Group"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Controller
            rules={{
              required: {
                value: true,
                message: "Name is required",
              },
            }}
            control={form.control}
            name="name"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  h: "38px",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "text",
                  borderTopRadius: "3px",
                }}
                placeholder=""
                label="Group name"
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
              />
            )}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            color={"#ea4d4d"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            variant={"solid"}
            h={"40px"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              backgroundColor: "#fff",
            }}
            margin={"0px 0px 0px 10px"}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            marginLeft={"5px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#2c48b5",
            }}
            onClick={(e) => {
              form.handleSubmit((data) => {
                setIsLoading(true);
                if (selectedContactId) {
                  GroupForContact.UpdateGroups(
                    selectedContactId!,
                    data,
                    (success) => {
                      setIsLoading(false);
                      setLastUpdated(Date.now());
                      onClose();
                    },
                    (err) => {
                      setIsLoading(false);
                    }
                  );
                } else {
                  GroupForContact.PostGroup(
                    data,
                    (success) => {
                      setIsLoading(false);
                      setLastUpdated(Date.now());
                      onClose();
                    },
                    (err) => {
                      setIsLoading(false);
                    }
                  );
                }
              })(e);
            }}
          >
            {selectedContactId ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
