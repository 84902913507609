import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import {useFormContext} from 'react-hook-form';
import {ReportLayout} from '../../../../../../modules/Audit/ReportLayout';
import {useTemplateReport} from '../../../report';
import ReportLayoutHistory from '../../Layouts/ReportLayoutHistory';

interface DeleteReportLayoutModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function DeleteReportLayoutModal({
  isOpen,
  onClose,
}: DeleteReportLayoutModalProps) {
  const form = useFormContext<ReportLayout.ReportLayout>();
  const toast = useToast();
  const {setDisplayedComponent} = useTemplateReport();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Report Layout</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Are you sure you want to delete this report layout?</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            onClick={() => {
              ReportLayout.DeleteReportLayout(
                form.getValues('id')?.toString()!,
                () => {
                  toast({
                    title: 'Success',
                    description: 'Report Layout Deleted',
                    status: 'success',
                  });
                  onClose();
                  setDisplayedComponent(<ReportLayoutHistory />);
                },
                error => {
                  toast({
                    title: 'Error',
                    description: error,
                    status: 'error',
                  });
                },
              );
            }}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
