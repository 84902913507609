import {
  Button,
  ButtonGroup,
  Drawer,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {Fragment, useEffect} from 'react';
import {AiFillCloseCircle} from 'react-icons/ai';
import {BsArrowCounterclockwise} from 'react-icons/bs';
import {IoCheckmarkCircle} from 'react-icons/io5';
import {MdDelete} from 'react-icons/md';
import {TemplateMeta} from '../../../../../../modules/Audit';

interface BuildBottomTemplatesArchiveProps {
  isOpen: boolean;
  onClose: () => void;
  templateIds: Array<number>;
  setLastUpdate: (timestamp: number) => void;
}
export default function BuildBottomArchiveTemplates({
  isOpen,
  onClose,
  templateIds,
  setLastUpdate,
}: BuildBottomTemplatesArchiveProps) {
  const toast = useToast();
  const deleteModal = useDisclosure();
  const btns: Array<{
    label: String;
    icon: any;
    onClick: any;
  }> = [
    {
      label: 'Restore',
      icon: <BsArrowCounterclockwise size={16} fill="#1da5d4" />,
      onClick: () => {
        TemplateMeta.bulkArchive(
          {archive: false},
          {templateIds: templateIds},
          () => {
            toast({
              title: 'Templates Restored',
              status: 'success',
            });
            setLastUpdate(Date.now());
            onClose();
          },
          error => {
            toast({
              title: 'Error',
              description: error,
              status: 'error',
            });
          },
        );
      },
    },
    {
      label: 'Delete',
      icon: <MdDelete size={16} fill="#1da5d4" />,
      onClick: () => {
        deleteModal.onOpen();
      },
    },
    // {
    //   label: 'Delete',
    //   icon: <MdDelete size={16} fill="#1da5d4" />,
    //   onClick: () => {},
    // },
  ];
  useEffect(() => {
    {
      templateIds.length == 0 && onClose();
    }
  }, [templateIds]);
  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <Stack
        position="fixed"
        bottom={0}
        left={0}
        right={0}
        bgColor="White"
        w="100%"
        zIndex={21}
        padding="0px"
        backgroundColor={'primary.500'}>
        <HStack h="65px" justifyContent="space-between" px="96px" py="16px">
          <HStack>
            <IoCheckmarkCircle fill="white" size={23} />
            <Text
              fontSize="16px"
              fontWeight={400}
              color="white"
              lineHeight="24px">
              {`${templateIds.length} items selected`}
            </Text>
          </HStack>

          <HStack spacing={4}>
            {btns.map((menu, i) => {
              return (
                <Fragment key={i}>
                  <Button
                    lineHeight="16px"
                    px="12.8px"
                    py="8px"
                    borderRadius="8px"
                    fontSize="13px"
                    fontWeight={500}
                    backgroundColor="white"
                    color="primary.500"
                    leftIcon={menu.icon}
                    _hover={{backgroundColor: 'none'}}
                    _active={{backgroundColor: 'none'}}
                    onClick={menu.onClick}>
                    {menu.label}
                  </Button>
                  <Modal
                    isOpen={deleteModal.isOpen}
                    onClose={deleteModal.onClose}>
                    <ModalOverlay />
                    <ModalContent mx="10px">
                      <ModalHeader fontWeight="semibold" py="4px">
                        Confirmation
                      </ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        Are you sure you want to Delete this?
                      </ModalBody>

                      <ModalFooter
                        display="flex"
                        justifyContent="flex-end"
                        pt="16px"
                        border="0px">
                        <ButtonGroup
                          size="xs"
                          variant="solid"
                          borderRadius="5px">
                          <Button
                            onClick={deleteModal.onClose}
                            borderRadius="5px">
                            No
                          </Button>
                          <Button
                            colorScheme="red"
                            borderRadius="5px"
                            onClick={() => {
                              TemplateMeta.bulkDelete(
                                {templateIds: templateIds},
                                () => {
                                  toast({
                                    title: 'Templates Deleted',
                                    status: 'success',
                                  });
                                  setLastUpdate(Date.now());
                                  deleteModal.onClose();
                                  onClose();
                                },
                                error => {
                                  toast({
                                    title: 'Error',
                                    description: error,
                                    status: 'error',
                                  });
                                  deleteModal.onClose();
                                },
                              );
                            }}>
                            Yes
                          </Button>
                        </ButtonGroup>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Fragment>
              );
            })}
            {/* <BulkEdit isOpen={isOpen} onClose={onClose} /> */}

            <AiFillCloseCircle fill="white" size={19} onClick={onClose} />
          </HStack>
        </HStack>
      </Stack>
    </Drawer>
  );
}
