import {Text, TextProps} from '@chakra-ui/react';

export function Content({children, ...props}: TextProps) {
  return (
    <Text
      color="icons.primary"
      fontSize="16px"
      fontWeight={400}
      lineHeight="1.5rem"
      {...props}>
      {children}
    </Text>
  );
}
export default Content;
