import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { ChartOfAccounts } from "../../Api/ChartOfAccounts";
import { AddInvoices } from "../../Api/Invoices";

interface AddContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (time: number) => void;
  chartOfAccounts: ChartOfAccounts.FetchChartOfAccounts[];
}
export function AddPayments({
  isOpen,
  onClose,
  setLastUpdated,
  chartOfAccounts,
}: AddContactModalProps) {
  const form = useFormContext<AddInvoices.PaymentData>();

  const filteredChartOfAccounts = chartOfAccounts.filter(
    (account) =>
      account.accountType === "CURRENT_LIABILITY" ||
      account.accountType === "EQUITY"
  );

  return (
    <FormProvider {...form}>
      <Modal size="md" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW={"600px"} maxH={"350px"} overflowX={"auto"}>
          <HStack
            w={"100%"}
            padding={"12px 20px"}
            alignItems={"center"}
            justifyContent={"space-between"}
            boxShadow={"0 1px 0 0 rgba(0,10,30,.2)"}
          >
            <ModalHeader
              flex={1}
              fontSize="var(--x-font-size-large, 1.0625rem)"
              _light={{
                color: "customColor.black7",
              }}
              fontWeight="700"
              padding={0}
            >
              Add New Payment
            </ModalHeader>
            <Stack
              justifyContent={"flex-end"}
              alignItems={"center"}
              direction={"row"}
            >
              <Button
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                color={"#ea4d4d"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#2c48b5",
                }}
                onClick={(e) => {
                  form.handleSubmit((data) => {
                    AddInvoices.PostPayment(
                      form.getValues("id")!,
                      data,
                      (success) => {
                        setLastUpdated(Date.now());

                        onClose();
                      },
                      (err) => {}
                    );
                  })(e);
                }}
              >
                {"Save"}
              </Button>
            </Stack>
          </HStack>

          <ModalBody p={"20px"}>
            <HStack marginBottom={"30px"} alignItems={"baseline"}>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Amount is required",
                  },
                }}
                control={form.control}
                name="amountPaid"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    validationMessage={error?.message}
                    withValidation
                    ref={field.ref}
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      w: "100%",
                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "number", // Set input type as "number"
                      borderTopRadius: "3px",
                      onChange: (e) => {
                        // Convert the string value to a number
                        const value = Number(e.target.value);
                        field.onChange(isNaN(value) ? "" : value); // Ensure we handle invalid numbers (NaN) gracefully
                      },
                    }}
                    placeholder=""
                    label="Amount"
                    labelProps={{
                      fontSize: ".845rem",
                      fontWeight: "600",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                )}
              />

              <Controller
                // rules={{
                //   required: {
                //     value: true,
                //     message: "Primary Person Email is required",
                //   },
                // }}
                control={form.control}
                name="datePaid"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    validationMessage={error?.message}
                    withValidation
                    ref={field.ref}
                    input={{
                      ...field,

                      // isInvalid: error !== undefined,
                      w: "100%",

                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      borderLeftRadius: "4px",
                      borderTopRadius: "4px",
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "date",
                    }}
                    placeholder=""
                    label="Date Paid"
                    labelProps={{
                      fontSize: ".845rem",
                      fontWeight: "600",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                )}
              />
            </HStack>
            <HStack marginBottom={"30px"} alignItems={"baseline"}>
              <FormControl>
                <FormLabel
                  mb={0}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize="13px"
                  fontWeight="700"
                  margin={"0px 12px 8px 0px"}
                >
                  Account
                </FormLabel>
                <Controller
                  control={form.control}
                  name="accountId"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder=""
                      ErrorMessage="Select chart of account"
                      withValidation
                      options={filteredChartOfAccounts?.map((acc: any) => ({
                        value: acc.id,
                        label: `${acc.code}: ${acc.name} (${
                          acc.accountType === "EQUITY"
                            ? "Equity"
                            : "Current Liability"
                        })`, // Conditional label
                      }))}
                      value={filteredChartOfAccounts
                        ?.map((acc: any) => ({
                          value: acc.id,
                          label: `${acc.code}: ${acc.name} (${
                            acc.accountType === "EQUITY"
                              ? "Equity"
                              : "Current Liability"
                          })`, // Conditional label
                        }))
                        .find((option: any) => option.value === field.value)}
                      onChange={(newValue: any) =>
                        field.onChange(newValue?.value)
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </FormControl>
              <Controller
                control={form.control}
                name="reference"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    validationMessage={error?.message}
                    withValidation
                    ref={field.ref}
                    input={{
                      ...field,

                      // isInvalid: error !== undefined,
                      w: "100%",

                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      borderLeftRadius: "4px",
                      borderTopRadius: "4px",
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",
                    }}
                    placeholder=""
                    label="Reference"
                    labelProps={{
                      fontSize: ".845rem",
                      fontWeight: "600",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                )}
              />
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
