import {
  Box,
  Divider,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useDebouncedCallback } from "use-debounce";
import { ReactSelect } from "../../../../../../../Common/ReactSelect";
import { PublicLibraryModule } from "../../../../../../modules/Audit/PublicLibrary";
import Content from "../../../Content";
import PreviewDrawer from "./PreviewDrawer";

// interface PublicLibraryTableProps {
//   publicTemplates: Array<PublicLibraryModule.FetchLibraryTemplates>;
// }
interface PublicLibraryTableProps {
  lastUpdate: number;
}
export default function PublicLibraryTable({
  lastUpdate,
}: PublicLibraryTableProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [libraryTemplates, setLibraryTemplates] = useState<
    Array<PublicLibraryModule.FetchLibraryTemplates>
  >([]);
  const [searchText, setSearchText] = useState("");
  const searchedLibraryTemplates = useCallback(() => {
    return libraryTemplates.filter((temp) =>
      temp.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, libraryTemplates]);
  const toast = useToast();
  const debounced = useDebouncedCallback((value) => {
    setSearchText(value);
  }, 1000);
  const [selectedTemplate, setSelectedTemplate] = useState<
    PublicLibraryModule.FetchLibraryTemplates | undefined
  >();
  const industries = [
    "Aged care industry",
    "Agricultural industry",
    "Airline operations",
    "Airport operations",
    "Aluminium industry",
    "Ambulance and patient transport",
    "Amusement, events and recreation industry",
    "Animal care and veterinary services",
    "Aquaculture",
    "Asphalt industry",
    "Australian Capital Territory",
    "Banking, finance and insurance industry",
    "Broadcasting and recorded entertainment industry",
    "Building services",
    "Building, metal and civil construction industries",
    "Business equipment industry",
    "Cement and concrete products",
    "Cemetery operations",
    "Children's services",
    "Christmas Island",
    "Cleaning services",
    "Clerical industry",
    "Clothing industry",
    "Coal export terminals",
    "Coal industry",
    "Cocos (Keeling) Islands",
    "Commercial sales",
    "Commonwealth employment",
    "Contract call centre industry",
    "Corrections and detentions",
    "Diving services",
    "Dredging industry",
    "Dry cleaning and laundry services",
    "Educational services",
    "Electrical contracting industry",
    "Electrical power industry",
    "Fast food industry",
    "Federal police operations",
    "Fire fighting services",
    "Food, beverages and tobacco manufacturing industry",
    "Funeral directing services",
    "Gardening services",
    "Grain handling industry",
    "Graphic arts",
    "Hair and beauty",
    "Health and welfare services",
    "Hospitality industry",
    "Indigenous organisations and services",
    "Journalism",
    "Licensed and registered clubs",
    "Live performance industry",
    "Local government administration",
    "Mannequins and modelling",
    "Manufacturing and associated industries",
    "Marine tourism and charter vessels",
    "Market and business consultancy services",
    "Meat industry",
    "Mining industry",
    "Miscellaneous",
    "Norfolk Island",
    "Northern Territory",
    "Nursery industry",
    "Passenger vehicle transport (non rail) industry",
    "Pet food manufacturing",
    "Pharmaceutical industry",
    "Pharmacy operations",
    "Plumbing industry",
    "Port authorities",
    "Postal services",
    "Poultry processing",
    "Publishing industry",
    "Quarrying industry",
    "Racing industry",
    "Rail industry",
    "Real estate industry",
    "Restaurants",
    "Retail industry",
    "Road transport industry",
    "Rubber, plastic and cable making industry",
    "Salt industry",
    "Scientific services",
    "Seafood processing",
    "Security services",
    "Social, community, home care and disability services",
    "Sporting organisations",
    "State and Territory government administration",
    "Stevedoring industry",
    "Storage services",
    "Sugar industry",
    "Tasmania",
    "Technical services",
    "Telecommunications services",
    "Textile industry",
    "Timber and paper products industry",
    "Tourism industry",
    "Uranium mining (incl. construction)",
    "Vehicle industry",
    "Waste management industry",
    "Water, sewerage and drainage services",
    "Wine industry",
    "Wool storage, sampling and testing industry",
  ].map((industry) => ({ label: industry, value: industry }));
  const [selectedIndustry, setSelectedIndustry] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const [totalTemplates, setTotalTemplate] = useState<number>(0);

  const [limit, setLimit] = useState<number>(10);
  const pageCount = Math.ceil(totalTemplates / limit);
  useEffect(() => {
    PublicLibraryModule.FetchLibraryTemplates(
      {
        page: pageNumber,
        limit: limit,
        search: searchText,
        industry: selectedIndustry?.label,
      },
      (data, total) => {
        setLibraryTemplates(data);
        setTotalTemplate(total);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, [pageNumber, lastUpdate, searchText, selectedIndustry?.label]);
  return (
    <Stack>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing="16px"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Stack flex={2}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<AiOutlineSearch color="#545f70" size="18px" />}
            />
            <Input
              h="38px"
              minW={{ xs: "100%", md: "400px" }}
              placeholder="Search template name"
              border="1px solid"
              borderColor="borders.accent"
              fontSize="16px"
              lineHeight="1.5rem"
              fontWeight={400}
              color="textcolors.primary"
              py="8px"
              pl="38px"
              _focusVisible={{
                boxShadow: "none",
                border: "1px sloid",
                borderColor: "primary.500",
              }}
              onChange={(e) => {
                debounced(e.target.value);
              }}
            />
          </InputGroup>
        </Stack>
        {/* <Menu matchWidth={true}>
          <MenuButton
            as={Button}
            minH="41px"
            flex={1}
            variant="outline"
            borderRadius="5px"
            textAlign="start"
            backgroundColor="white"
            borderColor="borders.accent"
            _hover={{
              backgroundColor: 'transparent',
              borderColor: 'primary.500',
            }}
            _active={{
              backgroundColor: 'transparent',
              borderColor: 'primary.500',
            }}
            rightIcon={<FiChevronDown />}
            fontSize="16px"
            fontWeight="400"
            color="textcolors.primary">
            Filter by industry
          </MenuButton>
          <MenuList>
            <VStack
              maxH="320px"
              overflowY="auto"
              borderBottom="1px"
              borderColor="borders.accent">
              {selectoptions.map((menu, i) => {
                return <MenuItem key={i}>{menu.label}</MenuItem>;
              })}
            </VStack>
            <Button
              px="12.8px"
              py="8px"
              m="8px"
              variant="outline"
              borderRadius="8px">
              Clear
            </Button>
          </MenuList>
        </Menu> */}
        <Box w="400px">
          <ReactSelect
            options={industries}
            placeholder="Filter by industry"
            ErrorMessage="Select Industry"
            isClearable={true}
            // label="Industry"
            value={selectedIndustry}
            onChange={(newValue: any) => {
              console.log(newValue?.value);
              setSelectedIndustry(newValue);
            }}
          />
        </Box>
      </Stack>

      <Box
        border="1px"
        borderColor="borders.accent"
        borderRadius="4px"
        backgroundColor="white"
      >
        {searchedLibraryTemplates().map((item, i) => {
          return (
            <>
              <Stack
                spacing="4px"
                p="16px"
                onClick={() => {
                  setSelectedTemplate(item);
                  onOpen();
                }}
                key={i}
              >
                <Content fontSize="14px" color={"primary.500"}>
                  {item.name}
                </Content>
                <Content fontSize="13px" fontStyle="italic" lineHeight="1rem">
                  {`Created by ${item.author} . ${item.downloads} downloads`}
                </Content>
              </Stack>
              <Divider border="1px" borderColor="borders.accent" />
            </>
          );
        })}
      </Box>
      <HStack spacing="13px" justify={{ xs: "center", md: "end" }}>
        <IconButton
          aria-label="back"
          backgroundColor="transparent"
          _hover={{ backgroundColor: "transparent" }}
          icon={
            <BsChevronLeft
              size={19}
              color={
                pageNumber <= 1 || pageNumber > pageCount
                  ? "#828ea0"
                  : "#000000"
              }
            />
          }
          isDisabled={pageNumber <= 1 || pageNumber > pageCount ? true : false}
          onClick={() => {
            if (pageNumber > 1 && pageNumber <= pageCount)
              pageNumberInputRef.current?.value &&
                (pageNumberInputRef.current.value = String(pageNumber - 1));
            setPageNumber(pageNumber - 1);
            console.log(pageNumber);
          }}
        />
        <Input
          ref={pageNumberInputRef}
          h="37px"
          w="56px"
          backgroundColor="backgrounds.primary"
          defaultValue={pageNumber.toString()}
          textAlign="center"
          borderColor="borders.accent"
          onBlur={(e) => {
            const pageValue = e.target.value;
            setPageNumber(parseInt(pageValue));
          }}
        />

        <Text color="textcolors.secondary">/</Text>
        <Text w="40px" textAlign="center">
          {pageCount}
        </Text>
        <IconButton
          aria-label="right"
          backgroundColor="transparent"
          _hover={{ backgroundColor: "transparent" }}
          icon={
            <BsChevronRight
              size={19}
              color={
                pageNumber >= pageCount || pageNumber < 1
                  ? "#828ea0"
                  : "#000000"
              }
            />
          }
          isDisabled={pageNumber >= pageCount || pageNumber < 1 ? true : false}
          onClick={() => {
            if (pageNumber < pageCount && pageNumber >= 1) {
              pageNumberInputRef.current?.value &&
                (pageNumberInputRef.current.value = String(pageNumber + 1));
              setPageNumber(pageNumber + 1);
            }

            console.log(pageNumber);
          }}
        />
      </HStack>
      <PreviewDrawer
        isOpen={isOpen}
        onClose={onClose}
        libraryTemplate={selectedTemplate}
      />
    </Stack>
  );
}
