import { axiosInstance } from "./axiosInstance-aud";
import { Common } from "./Common";

declare module TemplateMeta {
  interface TemplateMeta {
    title: string;
    description: string;
    image: string;
    businessId: number;
    createdBy: string;
    totalScore: number;
    published: boolean;
    id: number;
    createdAt: string;
    lastModified: string;
    lastPublished: string;
    inductionTemplate: boolean;
    warehouseTemplate: boolean;
    site: string;
    person: string;
    inspectionDate: string;
    EDIT_PERMISSION: boolean;
    archive?: boolean;
    countOfPermissions: number;
    scheduled: boolean;
  }
}

type bulkIds = {
  templateIds: Array<number>;
};
module TemplateMeta {
  const API_BASE_URL = "/template";
  export function Fetch(
    params: {
      page?: number;
      limit?: number;
      archive?: boolean;
      search?: string;
      templateType?: string;
    },
    success: (templateMeta: Array<TemplateMeta>, totalTemplate: number) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(API_BASE_URL, {
        params,
      })
      .then(({ data }) => {
        success(data?.data, data.total);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function Create(
    data: Omit<TemplateMeta, "id">,
    success: (template: TemplateMeta) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(API_BASE_URL, data)
      .then(({ data }) => {
        const response = data as Common.Success<TemplateMeta>;
        success(response.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function bulkArchive(
    params: { archive: boolean },
    data: bulkIds,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post("/template/archive/template", data, {
        params,
      })
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function bulkDelete(
    data: bulkIds,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post("/template/delete/template", data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function bulkDuplicate(
    data: bulkIds,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post("/global-template/duplicate", data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function GetById(
    id: number,
    success: (template: TemplateMeta) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_BASE_URL}/${id}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((err) => {
        error(err?.response?.data?.message ?? err?.message);
      });
  }

  export function Update(
    id: number,
    data: Partial<TemplateMeta>,
    success: (template: TemplateMeta) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_BASE_URL}/${id}`, data)
      .then(({ data }) => {
        const response = data as Common.Success<TemplateMeta>;
        success(response.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function Delete(
    id: number,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_BASE_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { TemplateMeta };
