import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Container,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  TagLeftIcon,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useBoolean,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { FormProvider, useForm } from "react-hook-form";
import { AiOutlineCopy, AiOutlinePlus } from "react-icons/ai";
import { BiLinkExternal } from "react-icons/bi";
import { BsFillPersonFill } from "react-icons/bs";
import { FiMoreVertical, FiSettings } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";
import { useParams } from "react-router-dom";
import { useBusinessContext } from "../../../../../Hooks/BusinessContext";
import { Employees } from "../../../../../Payroll/Api/Employees";
import { AccessControl } from "../../../../modules/Audit/AccessControl";
import { Permission } from "../../../../modules/Audit/Permission";
import AccessRule from "./Drawers/AccessRule";

export default function AccessTable() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ManageOwnerDrawer = useDisclosure();
  const { employeesList } = useBusinessContext();
  const [employees, setEmployees] = useState<Array<Employees.FetchEmployees>>(
    []
  );
  const [allTemplatePermission, setAllTemplatePermission] = useState<
    Permission.Permissions[]
  >([]);
  const [allUsersPermission, setAllUsersPermission] = useState<
    AccessControl.FetchAccessDetails[]
  >([]);
  const [allInspectionPermission, setAllInspectionPermission] = useState<
    Permission.Permissions[]
  >([]);
  const [templateSelectedOption, setTemplateSelectedOption] =
    useState<number>(1);
  const [inspectionSelectedOption, setInspectionSelectedOption] = useState<
    number | undefined
  >();
  const [selectedId, setSelectedId] = useState<number | undefined>();
  const [lastUpdate, setLastUpdate] = useState<number>();
  const [isLoading, setIsLoading] = useBoolean(true);
  const user = useAuthUser();
  const UserDetail = user();

  const { id } = useParams();
  const deleteModal = useDisclosure();
  const form = useForm<AccessControl.AccessMeta>({
    defaultValues: {
      templateId: +(id ?? 0),
      inspectionPermissionId: 0,
      inspectionUserId: "",
      templatePermissionId: 0,
      templateUserId: "",
    },
  });
  const templateOptionSelect = (optionId: number) => {
    setTemplateSelectedOption(optionId);
  };

  const inspectionOptionSelect = (optionId: number) => {
    setInspectionSelectedOption(optionId);
    console.log("Selected option:", optionId);
  };
  // const tableheader: Array<{
  //   label: string;
  // }> = [
  //     {
  //       label: 'Template available to',
  //     },
  //     {
  //       label: 'Access level',
  //     },
  //     {
  //       label: 'Inspection results available to',
  //     },
  //     {
  //       label: 'Access level',
  //     },
  //     {
  //       label: 'Action',
  //     },
  //   ];
  //   const MenuButtons: Array<{
  //     title: string;
  //     onClick?: (...args: any) => void;
  //   }> = [
  //     {
  //       title: 'Edit Access',
  //       onClick: () => {}
  //     },
  //     {
  //       title: 'Delete',
  //       onClick: ()=>{}
  //     }
  //   ];
  const menuitems: Array<{
    label: string;
    icon: any;
  }> = [
    {
      label: "Import rules from another template",
      icon: <AiOutlineCopy size={21} color="#1f2533" />,
    },
    {
      label: "Apply rules to past inspections",
      icon: <TbArrowBackUp size={21} color="#1f2533" />,
    },
    {
      label: "Manage default access",
      icon: <BiLinkExternal size={21} color="#1f2533" />,
    },
  ];
  const toast = useToast();
  useEffect(() => {
    setEmployees([
      ...(employeesList ?? []),
      {
        address: "",
        bloodGroup: "N/A",
        city: "",
        cnic: "",
        cnicExpiry: "",
        country: "",
        dateOfBirth: "",
        cnicIssuance: "",
        driverLicenseExpiry: "",
        driverLicenseIssuance: "",
        driverLicenseNumber: "",
        email: UserDetail?.email,

        firstName: UserDetail?.firstName,
        gender: "N/A",
        id: UserDetail?.id,
        lastName: UserDetail?.lastName,
        maritalStatus: "N/A",
        middleName: "",
        mobileNumber: UserDetail?.phone,
        nationality: UserDetail?.country,
        parentOrPartner: "",
        passportExpiry: "",
        passportIssuance: "",
        passportNumber: "",
        phoneNumber: UserDetail?.phone,
        photo: UserDetail?.photo,
        postalCode: "",
        province: "",
        title: "N/A",
        relationType: "N/A",
        religion: "N/A",
        sendInvitation: false,
        uniqueId: "",
        userId: UserDetail?.id,
      },
    ]);

    id &&
      AccessControl.FetchAllUsersTemplatePermission(
        +id,
        (data) => {
          setAllUsersPermission(data);
          setIsLoading.off();
        },

        (error) => {
          console.error("Error fetching Data:", error);
          setIsLoading.off();
        }
      );
    Permission.FetchPermissionByType(
      "TEMPLATE",
      (data) => {
        console.log(data);
        setAllTemplatePermission(data);
      },

      (error) => {
        console.error("Error fetching permissions:", error);
      }
    );
    Permission.FetchPermissionByType(
      "INSPECTION",
      (data) => {
        console.log(data);
        setAllInspectionPermission(data);
      },

      (error) => {
        console.error("Error fetching permissions:", error);
      }
    );
  }, [lastUpdate]);

  const handleFormReset = (index: number) => {
    const selectedAccessRule = allUsersPermission[index];
    form.reset(selectedAccessRule);
  };

  return (
    <FormProvider {...form}>
      <Container
        maxW="full"
        p={0}
        backgroundColor="backgrounds.header"
        h="calc(100vh - 62px)"
      >
        <Stack mb="80px" px="32px" pb="32px" pt="40px">
          <Text
            fontSize="14px"
            fontWeight={400}
            color="textcolors.primary"
            lineHeight={"1.25rem"}
          >
            Untitled template
          </Text>
          <HStack>
            <Text
              flex={1}
              fontSize="24px"
              fontWeight={500}
              color="textcolors.primary"
              lineHeight={"2rem"}
            >
              Who can access this template and its inspections?
            </Text>
            <Menu placement="bottom-end">
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FiMoreVertical size={24} color="#545f70" />}
                variant="outline"
                borderRadius={"50%"}
                border={"none"}
                _hover={{ backgroundColor: "primary.50" }}
                _active={{ backgroundColor: "none" }}
              />
              <MenuList minWidth={"100px"} boxShadow="lg" mr="15px">
                {menuitems.map((menu, i) => {
                  return (
                    <Fragment key={i}>
                      <MenuItem
                        px="14px"
                        py="17.6px"
                        icon={menu.icon}
                        fontSize={"14px"}
                        fontWeight={400}
                        color={"textcolors.primary"}
                      >
                        {menu.label}
                      </MenuItem>
                    </Fragment>
                  );
                })}
              </MenuList>
            </Menu>
          </HStack>

          {/* <Stack
            mb="24px"
            backgroundColor="white"
            border="1px solid"
            borderColor="borders.accent"
            borderRadius="8px"
            spacing={{ xs: '8px', lg: '0px' }}>
            <HStack
              px="24px"
              py="16px"
              display={{ xs: 'none', lg: 'flex' }}
              borderBottom="1px solid"
              borderColor="borders.accent">
              {tableheader.map((menu, i) => {
                return (
                  <Fragment key={i}>
                    <Text
                      flex={1}
                      borderColor="borders.accent"
                      fontSize="12px"
                      fontWeight={400}
                      color="textcolors.secondary">
                      {menu.label}
                    </Text>
                  </Fragment>
                );
              })}
            </HStack> */}
          <Fragment>
            <TableContainer mt="4px" _hover={{ boxShadow: "card" }}>
              <Table
                variant="simple"
                border="1px"
                borderColor="borders.accent"
                backgroundColor="white"
              >
                <Thead bgColor="gray.100">
                  <Tr>
                    {[
                      "Template available to",
                      "Access level",
                      "Inspection results available to",
                      "Access level",
                      undefined,
                    ].map((label, i) => {
                      return (
                        <Th
                          key={i}
                          px="10px"
                          py="8px"
                          fontSize="14px"
                          fontWeight={500}
                          borderColor="borders.accent"
                          color="table.text"
                          lineHeight={1.5}
                        >
                          {label}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {isLoading ? (
                    <Tr>
                      <Td
                        px="10px"
                        py="8px"
                        borderColor={"borders.accent"}
                        colSpan={6}
                      >
                        <Center>
                          <Spinner size="lg" color="secondary.500" />
                        </Center>
                      </Td>
                    </Tr>
                  ) : allUsersPermission.length > 0 ? (
                    allUsersPermission.map((usersPer, i) => {
                      return (
                        <Tr
                          key={i}
                          fontSize="14px"
                          fontWeight={400}
                          color="table.text"
                          _hover={{
                            bgColor: "gray.200",
                          }}
                        >
                          <Td px="10px" py="8px" borderColor="borders.accent">
                            <Tag
                              //  backgroundColor="primary.50"
                              size="md"
                            >
                              <TagLeftIcon
                                boxSize="16px"
                                color="textcolors.primary"
                                as={BsFillPersonFill}
                              />
                              <TagLabel
                                color="textcolors.primary"
                                fontSize="16px"
                                fontWeight={400}
                              >
                                {
                                  employees.find(
                                    (employee) =>
                                      employee.id === usersPer.templateUserId
                                  )?.firstName
                                }
                              </TagLabel>
                            </Tag>
                          </Td>
                          <Td px="10px" py="8px" borderColor="borders.accent">
                            {
                              allTemplatePermission.find(
                                (tPer) =>
                                  tPer.id == usersPer.templatePermissionId
                              )?.permissionName
                            }
                          </Td>
                          <Td px="10px" py="8px" borderColor="borders.accent">
                            <Tag
                              // backgroundColor="primary.50"
                              size="md"
                            >
                              <TagLeftIcon
                                boxSize="16px"
                                color="textcolors.primary"
                                as={BsFillPersonFill}
                              />
                              <TagLabel
                                color="textcolors.primary"
                                fontSize="16px"
                                fontWeight={400}
                              >
                                {
                                  employees.find(
                                    (employee) =>
                                      employee.id === usersPer.inspectionUserId
                                  )?.firstName
                                }
                              </TagLabel>
                            </Tag>
                          </Td>
                          <Td px="10px" py="8px" borderColor="borders.accent">
                            {
                              allInspectionPermission.find(
                                (iPer) =>
                                  iPer.id == usersPer.inspectionPermissionId
                              )?.permissionName
                            }
                          </Td>
                          <Td
                            px="10px"
                            py="8px"
                            borderColor="borders.accent"
                            textAlign="end"
                          >
                            <Menu placement="bottom-end">
                              <MenuButton
                                as={IconButton}
                                // borderRightRadius="8px"
                                backgroundColor="transparent"
                                _hover={{ backgroundColor: "transparent" }}
                                _active={{ backgroundColor: "transparent" }}
                                aria-label="dropdown menu"
                                icon={<FiSettings color="#545f70" size={20} />}
                              />
                              <MenuList minW="100px">
                                <MenuItem
                                  fontSize="13px"
                                  onClick={() => {
                                    setSelectedId(usersPer.id);
                                    handleFormReset(i);
                                    onOpen();
                                  }}
                                >
                                  Edit Access
                                </MenuItem>
                                <MenuItem
                                  fontSize="13px"
                                  onClick={() => {
                                    setSelectedId(usersPer.id);
                                    deleteModal.onOpen();
                                  }}
                                >
                                  Delete
                                </MenuItem>
                                <Modal
                                  isOpen={deleteModal.isOpen}
                                  onClose={deleteModal.onClose}
                                >
                                  <ModalOverlay />
                                  <ModalContent mx="10px">
                                    <ModalHeader fontWeight="semibold" py="4px">
                                      Confirmation
                                    </ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                      Are you sure you want to Delete this?
                                    </ModalBody>

                                    <ModalFooter
                                      display="flex"
                                      justifyContent="flex-end"
                                      pt="16px"
                                      border="0px"
                                    >
                                      <ButtonGroup
                                        size="xs"
                                        variant="solid"
                                        borderRadius="5px"
                                      >
                                        <Button
                                          onClick={deleteModal.onClose}
                                          borderRadius="5px"
                                        >
                                          No
                                        </Button>
                                        <Button
                                          colorScheme="red"
                                          borderRadius="5px"
                                          onClick={() => {
                                            if (selectedId) {
                                              AccessControl.DeleteTemplatePermission(
                                                selectedId,
                                                () => {
                                                  toast({
                                                    title: "Success",
                                                    description:
                                                      "Permission deleted sucessfully",
                                                    status: "success",
                                                  });
                                                  setLastUpdate(Date.now());
                                                  deleteModal.onClose();
                                                },
                                                (error) => {
                                                  toast({
                                                    title: "Error",
                                                    description: error,
                                                    status: "error",
                                                  });
                                                }
                                              );
                                            }
                                          }}
                                        >
                                          Yes
                                        </Button>
                                      </ButtonGroup>
                                    </ModalFooter>
                                  </ModalContent>
                                </Modal>
                              </MenuList>
                            </Menu>
                          </Td>
                        </Tr>
                      );
                    })
                  ) : (
                    <Tr>
                      <Td
                        px="10px"
                        py="8px"
                        borderColor="borders.accent"
                        colSpan={6}
                      >
                        <Center w="100%">
                          <Text>No Permissions Found !</Text>
                        </Center>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
                <Tfoot>
                  <Box w="100%" p="12px">
                    <Button
                      h="40px"
                      borderRadius="8px"
                      fontSize="14px"
                      fontWeight={500}
                      marginRight="16px"
                      padding={"12px 20px"}
                      leftIcon={<AiOutlinePlus size={17} />}
                      onClick={() => {
                        form.reset({
                          templateId: +(id ?? 0),
                          inspectionPermissionId: 0,
                          inspectionUserId: "",
                          templatePermissionId: 0,
                          templateUserId: "",
                        });

                        setSelectedId(undefined);
                        onOpen();
                      }}
                    >
                      New access rules
                    </Button>
                    <AccessRule
                      isOpen={isOpen}
                      onClose={onClose}
                      allTemplatePermission={allTemplatePermission}
                      templateOptionSelect={templateOptionSelect}
                      allInspectionPermission={allInspectionPermission}
                      inspectionOptionSelect={inspectionOptionSelect}
                      inspectionSelectedOption={inspectionSelectedOption}
                      templateSelectedOption={templateSelectedOption}
                      selectedId={selectedId}
                      setLastUpdate={setLastUpdate}
                    />
                  </Box>
                </Tfoot>
              </Table>
            </TableContainer>
          </Fragment>

          {/* {allUsersPermission.map((usersPer, i) => {
              return (
                <Stack
                  key={i}
                  spacing={{ xs: '8px', lg: '0px' }}
                  direction={{ xs: 'column', lg: 'row' }}
                  px="24px"
                  py="24px"
                  borderBottom="1px solid"
                  borderColor="borders.accent"
                  alignItems="center"
                  flex={5}>
                  <HStack
                    spacing={{ xs: '8px', lg: '0px' }}
                    flex={2}
                    display={{ xs: 'flex', lg: 'none' }}>
                    <Text flex={1} color="icons.primary">
                      Template available to
                    </Text>
                    <Text flex={1} color="icons.primary">
                      Access level
                    </Text>
                  </HStack>
                  <HStack flex={2} spacing={{ xs: '8px', lg: '0px' }} align="top">
                    <Flex flex={1} justifyContent='center'>
                      <Tag
                      //  backgroundColor="primary.50" 
                       size="md">
                        <TagLeftIcon
                          boxSize="16px"
                          color="textcolors.primary"
                          as={BsFillPersonFill}
                        />
                        <TagLabel
                          color="textcolors.primary"
                          fontSize="16px"
                          fontWeight={400}>
                          {employees.find((employee) => employee.id === usersPer.templateUserId)?.preferredTitle}
                        </TagLabel>
                      </Tag>
                    </Flex>
                    
                    <Flex flex={1} justifyContent='center'>
                     
                      {/* <UserMenu onSelect={templateOptionSelect} templatePermission={allTemplatePermission} selectedOption={usersPer.templatePermissionId} /> */}
          {/* <Text color="textcolors.primary"
                          fontSize="16px"
                          fontWeight={400}>
                      {allTemplatePermission.find((tPer)=> tPer.id == usersPer.templatePermissionId)?.permissionName}
                    </Text>
                      <ManageTemplateOwnership
                        isOpen={ManageOwnerDrawer.isOpen}
                        onClose={ManageOwnerDrawer.onClose}
                      />
                    </Flex>
                  </HStack>
                  <HStack
                    spacing={{ xs: '8px', lg: '0px' }}
                    flex={2}
                    display={{ xs: 'flex', lg: 'none' }}
                    align="top"
                    >
                    <Text flex={1} color="icons.primary" >
                      Inspection results available to
                    </Text>
                    <Text flex={1} color="icons.primary">
                      Access level
                    </Text>
                  </HStack>
                  <HStack spacing={{ xs: '8px', lg: '0px' }} flex={2} align="top">
                    <Flex flex={1} align="start" justifyContent='center'>

                      <Tag  */}
          {/* // backgroundColor="primary.50"
                      //  size="md">
                      //   <TagLeftIcon
                      //     boxSize="16px"
                      //     color="textcolors.primary"
                      //     as={BsFillPersonFill}
                      //   />
                      //   <TagLabel
                      //     color="textcolors.primary"
                      //     fontSize="16px"
                      //     fontWeight={400}>
                      //     {employees.find((employee) => employee.id === usersPer.inspectionUserId)?.preferredTitle}
                      //   </TagLabel>
                      // </Tag> */}

          {/* <AccessRule isOpen={isOpen} onClose={onClose} employees={employees} allTemplatePermission={allTemplatePermission} templateOptionSelect={templateOptionSelect} allInspectionPermission={allInspectionPermission} inspectionOptionSelect={inspectionOptionSelect} inspectionSelectedOption={inspectionSelectedOption} templateSelectedOption={templateSelectedOption} selectedId={selectedId}/> */}
          {/* </Flex> */}

          {/* <Flex flex={1} justifyContent='center'> */}
          {/* <ViewMenu InspectionPermission={allInspectionPermission} onSelect={inspectionOptionSelect} selectedOption={usersPer.inspectionPermissionId} /> */}
          {/* <Text color="textcolors.primary"
                          fontSize="16px"
                          fontWeight={400}>
                      {allInspectionPermission.find((iPer)=> iPer.id == usersPer.inspectionPermissionId)?.permissionName}
                    </Text>
                    </Flex>
                  </HStack>

                  <HStack spacing={{ xs: '8px', lg: '0px' }} flex={1} align='top'>
                    

                    <Flex flex={1} justifyContent='center'>
                    <Menu placement="bottom-end">
                          <MenuButton
                            as={IconButton}
                            // borderRightRadius="8px"
                            backgroundColor='transparent'
                            _hover={{backgroundColor:'transparent'}}
                            _active={{backgroundColor:'transparent'}}
                            aria-label="dropdown menu"
                            
                            icon={<AiFillSetting color='#000000' size={20}/>
                          }
                          />
                          <MenuList minW="100px">
                                <MenuItem
                                fontSize="13px" onClick={()=>{
                                  setSelectedId(usersPer.id)
                                  handleFormReset(i)
                                  onOpen();}}>
                                  Edit Access
                                </MenuItem>
                                <MenuItem
                                fontSize="13px" 
                                onClick={()=>{
                                  setSelectedId(usersPer.id)
                                  deleteModal.onOpen()
                                    }}>
                                  Delete
                                </MenuItem>
                                <Modal isOpen={deleteModal.isOpen} onClose={deleteModal.onClose}>
              <ModalOverlay />
              <ModalContent mx="10px">
                <ModalHeader fontWeight="semibold" py="4px">
                  Confirmation
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>Are you sure you want to Delete this?</ModalBody>

                <ModalFooter
                  display="flex"
                  justifyContent="flex-end"
                  pt="16px"
                  border="0px">
                  <ButtonGroup size="xs" variant="solid" borderRadius="5px">
                    <Button onClick={deleteModal.onClose} borderRadius="5px">
                      No
                    </Button>
                    <Button
                      colorScheme="red"
                      borderRadius="5px"
                      onClick={() => {
                        if (selectedId) {
                          AccessControl.DeleteTemplatePermission(
                            selectedId,
                            () => {
                              toast({
                                title: 'Success',
                                description: 'Permission deleted sucessfully',
                                status: 'success',
                              });
                              setLastUpdate(Date.now())
                              deleteModal.onClose();
                              
                            },
                            error => {
                              toast({
                                title: 'Error',
                                description: error,
                                status: 'error',
                              });
                            },
                          );
                        }
                      }}>
                      Yes
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </ModalContent>
            </Modal>
            </MenuList>
                        </Menu>
                    </Flex>
                  </HStack>
                </Stack>
              )
            })}
            <Box w="100%" p="24px" >
              <Button
                h="40px"
                borderRadius="8px"
                fontSize="14px"
                fontWeight={500}
                marginRight="16px"
                padding={'12px 20px'}
                leftIcon={<AiOutlinePlus size={17} />}
                onClick={() => {
                  form.reset({});
                  setSelectedId(undefined);
                  onOpen()
                }}>
                New access rules
              </Button>
              <AccessRule isOpen={isOpen} onClose={onClose} employees={employees} allTemplatePermission={allTemplatePermission} templateOptionSelect={templateOptionSelect} allInspectionPermission={allInspectionPermission} inspectionOptionSelect={inspectionOptionSelect} inspectionSelectedOption={inspectionSelectedOption} templateSelectedOption={templateSelectedOption} selectedId={selectedId} setLastUpdate={setLastUpdate}/>
            </Box> */}
        </Stack>
      </Container>
    </FormProvider>
  );
}
