import { Checkbox, SimpleGrid, Skeleton, Stack, Text } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { InviteUser } from "../../../../../api/InviteUser";
interface AuditorPermissionsProps {
  isLoading?: boolean;
}
export default function AuditorPermissions({
  isLoading,
}: AuditorPermissionsProps) {
  const form = useFormContext<InviteUser.UserInviteDetail>();
  return (
    <Stack paddingLeft={"2.5px"} gap={0} paddingTop={"10px"}>
      <Stack
        padding={0}
        alignItems={"center"}
        direction={"row"}
        gap={0}
      ></Stack>
      <Skeleton height={"100%"} isLoaded={!isLoading} mb="2">
        <SimpleGrid columns={3}>
          <Stack direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.auditor.templates"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  value={field.value?.toString()}
                  isChecked={field.value}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Templates
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>

          <Stack direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.auditor.inspections"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  isChecked={field.value}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Inspections
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>

          <Stack direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.auditor.schedule"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  isChecked={field.value}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Schedule
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.auditor.periodicalSchedule"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  value={field.value?.toString()}
                  isChecked={field.value}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Periodical Schedule
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.auditor.periodicalReports"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  value={field.value?.toString()}
                  isChecked={field.value}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Periodical Reports
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.auditor.notificationSettings"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  value={field.value?.toString()}
                  isChecked={field.value}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Notification Settings
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
        </SimpleGrid>
      </Skeleton>
    </Stack>
  );
}
