import {
  Button,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { Fragment } from "react";
import { BiMessageRoundedError } from "react-icons/bi";
import InspectionArchive from "./componenets/InspectionArchive";
import Inspections from "./componenets/Inspections";

export default function AllInspection() {
  return (
    <Stack h="calc(100vh - 70px)">
      <Tabs isLazy>
        <HStack
          px="16px"
          pt="8px"
          borderBottom="1px"
          borderColor="borders.accent"
          justifyContent="space-between"
        >
          <TabList color="textcolors.primary" border="0px">
            {["Inspections", "Archive"].map((menu, i) => {
              return (
                <Fragment key={i}>
                  <Tab
                    fontSize="14px"
                    px="16px"
                    py="12px"
                    sx={{ borderBottomWidth: "4px" }}
                  >
                    {menu}
                  </Tab>
                </Fragment>
              );
            })}
          </TabList>
          <Button
            display={{ xs: "none", md: "flex" }}
            variant="ghost"
            leftIcon={<BiMessageRoundedError size={16} />}
            px="16px"
            py="8px"
          >
            Send feedback
          </Button>
        </HStack>

        <TabPanels>
          <TabPanel p={0}>
            <Inspections />
          </TabPanel>
          <TabPanel p={0}>
            <InspectionArchive />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
