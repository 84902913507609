import {
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tag,
  useToast,
} from '@chakra-ui/react';
import {useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {FiSettings} from 'react-icons/fi';
import {RiArrowGoBackFill, RiArrowGoForwardFill} from 'react-icons/ri';
import {useParams} from 'react-router-dom';
import {Template} from '../../../modules/Audit';
import useDrawer from '../hooks/drawersContext';
export default function BuildHeader() {
  const {id} = useParams();
  const template = useFormContext<Template.Template>();
  const toast = useToast({
    position: 'top',
  });

  const [isLoading, setLoading] = useState<boolean>(false);

  const {PreviewModalOperations} = useDrawer();

  return (
    <Stack
      position="sticky"
      top="60px"
      bgColor="White"
      w="100%"
      zIndex={9}
      padding="0px">
      <HStack h="60px" justifyContent="space-between" padding="0px 16px">
        <HStack h="39.6px">
          {/* <Button
            leftIcon={<AiOutlineEye size="16px" />}
            h="100%"
            borderRadius="5px"
            fontSize="14px"
            fontWeight={400}
            variant="outline"
            padding={'8px 16px'}
            // display={{
            //   xl: 'none',
            //   xs: 'flex',
            // }}

            onClick={() => {
              PreviewModalOperations?.onOpen();
            }}>
            Preview
          </Button>
          <Button
            h="100%"
            borderRadius="5px"
            fontSize="14px"
            fontWeight={400}
            variant="outline"
            padding={'8px 16px'}>
            Bulk edit
          </Button> */}
          <ButtonGroup spacing={0} display="none">
            <IconButton
              aria-label="back"
              backgroundColor="transparent"
              borderRadius="5px"
              _hover={{backgroundColor: 'transparent'}}
              icon={<RiArrowGoBackFill color="#828ea0" size={'16px'} />}
            />
            <IconButton
              aria-label="forward"
              backgroundColor="transparent"
              _hover={{backgroundColor: 'transparent'}}
              borderRadius="5px"
              icon={<RiArrowGoForwardFill color="#828ea0" size={'16px'} />}
            />
          </ButtonGroup>
        </HStack>
        <HStack h="39.6px" spacing={3}>
          <Tag
            display={{xs: 'none', md: 'flex'}}
            color="textcolors.primary"
            fontSize="14px"
            fontWeight={400}
            background="backgrounds.primary">
            Unpublished changes saved
          </Tag>
          {/* <HStack display={{xs: 'none', lg: 'flex'}}>
            <Text color="#3f495a" fontSize="14px" fontWeight={400}>
              Last published:
            </Text>
            <Text color="#3f495a" fontSize="14px" fontWeight={400}>
              22/01/2023
            </Text>
          </HStack> */}
          <Button
            isLoading={isLoading}
            loadingText="Saving Changes"
            h="100%"
            borderRadius="5px"
            fontSize="14px"
            fontWeight={400}
            lineHeight="22px"
            padding={'8px 16px'}
            onClick={template.handleSubmit(
              validTemplate => {
                setLoading(true);
                const showSuccess = (data: any) => {
                  toast({
                    title: 'Success',
                    description: 'Template Created Successfully',
                    status: 'success',
                    // duration: 2000,
                    isClosable: true,
                  });
                  template.reset(data);
                  setLoading(false);
                };
                const showError = (error: string) => {
                  toast({
                    title: 'Error',
                    description: error,
                    status: 'error',
                    // duration: 2000,
                    isClosable: true,
                  });
                  setLoading(false);
                };
                if (id) {
                  Template.Create(validTemplate, showSuccess, showError);
                } else {
                  Template.Update(
                    Number(id),
                    validTemplate,
                    showSuccess,
                    showError,
                  );
                }
              },
              error => {},
            )}>
            {id ? 'Save Changes' : 'Publish'}
          </Button>
          <Menu>
            <MenuButton
              as={IconButton}
              // borderRightRadius="8px"
              backgroundColor="transparent"
              _hover={{backgroundColor: 'transparent'}}
              _active={{backgroundColor: 'transparent'}}
              aria-label="dropdown menu"
              icon={<FiSettings color="#545f70" size={20} />}
            />
            <MenuList minW="150px">
              <MenuItem
                as={Button}
                fontSize="14px"
                fontWeight={400}
                lineHeight="22px"
                padding={'8px 16px'}
                _hover={{backgroundColor: 'gray.100'}}>
                Bulk edit
              </MenuItem>

              <MenuItem
                as={Button}
                fontSize="14px"
                fontWeight={400}
                lineHeight="22px"
                padding={'8px 16px'}
                _hover={{backgroundColor: 'gray.100'}}
                // display={{
                //   xl: 'none',
                //   xs: 'flex',
                // }}

                onClick={() => {
                  PreviewModalOperations?.onOpen();
                }}>
                Preview
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </HStack>
    </Stack>
  );
}
