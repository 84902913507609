import {
  Button,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { FiCheck, FiMoreVertical } from "react-icons/fi";
import { MCQ } from "../../../../../../modules/Audit/MCQs";
import UploadCsvModal from "../../../../components/Modals/UploadCsvModal";
import { useGlobalResponseDrawer } from "../../../../hooks/globalResponsesDrawer";
import ResponseTableLg from "./ResponseTableLg";
import ResponseTableSm from "./ResponseTableSm";

export default function Response() {
  const uploadcsv = useDisclosure();
  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const [globalOptionsList, setGlobalOptionsList] = useState<Array<MCQ.IMCQ>>(
    []
  );
  const [searchText, setSearchText] = useState("");
  const searchedResponses = useCallback(() => {
    return globalOptionsList.filter((opt) =>
      opt.name?.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, globalOptionsList]);
  const { globalResponses, Save, setGlobalResponses, openDrawer } =
    useGlobalResponseDrawer();

  const TableSize = useBreakpointValue({
    xs: (
      <ResponseTableSm
        onRowClick={(globalOptions) => {
          openDrawer(globalOptions);
        }}
        data={searchedResponses()}
        setLastUpdate={setLastUpdate}
      />
    ),
    md: (
      <ResponseTableLg
        onRowClick={(globalOptions) => {
          openDrawer(globalOptions);
        }}
        data={searchedResponses()}
        setLastUpdate={setLastUpdate}
      />
    ),
  });
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    MCQ.Fetch(
      { isGlobal: true },
      (options) => {
        setGlobalOptionsList(options);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, [lastUpdate, lastUpdate]);

  return (
    <Fragment>
      <Stack px={{ sm: "0px", md: "32px", xl: "50px" }} py="32px">
        <HStack justifyContent="space-between">
          <Text
            fontSize="24px"
            fontWeight={500}
            lineHeight="2rem"
            color="textcolors.primary"
          >
            Global Response Sets
          </Text>
          <Button
            h="41px"
            leftIcon={<AiOutlinePlus size={17} />}
            px="20px"
            py="12px"
            borderRadius="8px"
            onClick={() => {
              openDrawer();
            }}
          >
            Create Global Response Set
          </Button>

          <UploadCsvModal
            isOpen={uploadcsv.isOpen}
            onClose={uploadcsv.onClose}
          />
        </HStack>
        <Stack direction={{ xs: "column", md: "row" }}>
          <Stack flex={1}>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<AiOutlineSearch color="#545f70" size="18px" />}
              />
              <Input
                maxW={{ xs: "100%", md: "400px" }}
                placeholder="Search"
                border="1px solid"
                borderColor="borders.accent"
                fontSize="16px"
                lineHeight="1.5rem"
                fontWeight={400}
                color="textcolors.primary"
                py="8px"
                pl="38px"
                _focusVisible={{
                  boxShadow: "none",
                  border: "1px sloid",
                  borderColor: "primary.500",
                }}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              ></Input>
            </InputGroup>
          </Stack>
          <HStack spacing={0} justify="end">
            <Text fontSize="14px" color="icons.primary">
              {`1-${globalOptionsList.length} of ${globalOptionsList.length} results`}
            </Text>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FiMoreVertical size={24} color="#545f70" />}
                variant="outline"
                borderRadius={"50%"}
                border={"none"}
                _hover={{ backgroundColor: "transparent" }}
                _active={{
                  backgroundColor: "transparent",
                }}
              />
              <MenuList minW="160px">
                <MenuItem px="14px" py="9.5px">
                  <Text fontSize="13px" fontWeight={400} lineHeight="1rem">
                    Results per page
                  </Text>
                </MenuItem>
                {[25, 50, 100].map((menu, i) => {
                  return (
                    <Fragment>
                      <MenuItem px="16px" py="8px">
                        <HStack>
                          <FiCheck size="18px" color="#1da5d4" />
                          <Text fontSize="14px" color="textcolors.primary">
                            {menu}
                          </Text>
                        </HStack>
                      </MenuItem>
                    </Fragment>
                  );
                })}
              </MenuList>
            </Menu>
          </HStack>
        </Stack>
        {TableSize}
        {/* <HStack spacing="13px" justify={{xs: 'center', md: 'end'}}>
          <BsChevronLeft size={19} color="#828ea0" />
          <Input
            h="37px"
            w="56px"
            backgroundColor="backgrounds.primary"
            value={1}
            textAlign="center"
            borderColor="borders.accent"
          />

          <Text color="textcolors.secondary">/</Text>
          <Text w="40px" textAlign="center">
            1
          </Text>
          <BsChevronRight size={19} color="#828ea0" />
        </HStack> */}
      </Stack>
    </Fragment>
  );
}
