import {
  Container,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useBoolean,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import { Inspection, Template } from "../../modules/Audit";
import useDrawer from "../template/hooks/drawersContext";
import Annotation from "./components/Annotation";
import AuditQuestionContainer from "./components/AuditQuestionContainer";
import CheckBox from "./components/CheckBox";
import DateTime from "./components/DateTime";
import DocumentNumber from "./components/DocumentNumber";
import GlobalResponse from "./components/GlobalResponse";
import InspectionDate from "./components/InpectionDate";
import InspectionDocuments from "./components/InpectionDocuments";
import InspectionLocation from "./components/InspectionLocation";
import InspectionTable from "./components/InspectionTable";
import Instruction from "./components/Instrustion";
import InventoryItems from "./components/InventoryItems";
import IsSavingComponent from "./components/isSavingComponent";
import Location from "./components/Location";
import Media from "./components/Media";
import NavigationButton from "./components/NavigationButton";
import NumberTypeComponent from "./components/Number";
import PeriodicalListStatus from "./components/PeriodicalListStatus";
import PersonPreview from "./components/PersonPreview";
import Signature from "./components/Signature";
import Site2 from "./components/Site2";
import SliderPreview from "./components/SliderPreview";
import TemplateMultiChoice from "./components/TemplateMultiChoice";
import TextAnswer from "./components/TextAnswer";
import {
  InspectionContextProvider,
  useInspectionContext,
} from "./hooks/InspectionContext";
import InpectionFormSection from "./InpectionFormSection";
import { ReduceTotalPageScoreObject } from "./types";

interface InspectionFormProps {
  mode: "PREVIEW" | "CONDUCT" | "INSPECTION_PREVIEW";
}

export default function InspectionForm({ mode }: InspectionFormProps) {
  const template = useFormContext<Inspection.InpsectionPayload>();
  const futureImplemetation = false;
  const { PreviewModalOperations } = useDrawer();
  const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
  const [deletedInventoryItems, setDeletedInventoryItems] = useState<
    Array<number>
  >([]);
  const pagesArr: Inspection.InpsectionPayload["templateItems"] = useWatch({
    control: template.control,
    name: `templateItems`,
  });
  const { isSaving } = useInspectionContext();

  const navigate = useNavigate();

  const toast = useToast({
    position: "top",
    isClosable: true,
  });

  const [isSubmitting, setIsSubmitting] = useBoolean(false);

  const [scoreToBeReduced, setScoreToBeReduced] = useState<
    Array<ReduceTotalPageScoreObject>
  >([]);
  function reduceTotalPageScore(
    item: ReduceTotalPageScoreObject,
    operation: "ADD" | "REMOVE"
  ) {
    if (operation === "ADD") {
      setScoreToBeReduced((prev) => {
        return [...prev, item];
      });
    } else {
      setScoreToBeReduced((prev) => {
        return prev.filter((score) => {
          return score.questionPath !== item.questionPath;
        });
      });
    }
  }
  const pageTotalScore = useMemo(() => {
    const pageScore = pagesArr?.[selectedPageIndex].children?.reduce(
      (prev, curr, currIndex) => {
        if (curr.type === "SECTION") {
          return (
            prev +
            curr?.children?.reduce((prev, curr, sectionIndex) => {
              return prev + parseInt(curr.question?.score ?? "0");
            }, 0)
          );
        } else {
          return prev + parseInt(curr.question?.score ?? "0");
        }
      },
      0
    );

    const reducedScore = scoreToBeReduced
      ?.filter(
        (item) => +item.questionPath.split(".")?.[1] === selectedPageIndex
      )
      .reduce((prev, curr) => {
        return prev + curr.score;
      }, 0);

    if (pageScore !== reducedScore) {
      return pageScore - reducedScore;
    }
  }, [selectedPageIndex, scoreToBeReduced]);

  const [pageScore, setPageScore] = useState<number>(0);
  const scoreInPercentage = (pageScore / (pageTotalScore ?? 0)) * 100;

  function onScoreChanged(score: number, previousScore?: number) {
    setPageScore((prev) => {
      return prev + score - (previousScore ?? 0);
    });
  }

  const [totalPagesScorePercentages, setTotalPagesScorePercentages] = useState<{
    [key: number]: {
      pageScore: number;
      pageTotalScore: number;
    };
  }>({});
  useEffect(() => {
    if (pageTotalScore) {
      setTotalPagesScorePercentages((prev) => ({
        ...prev,
        [selectedPageIndex]: {
          pageScore,
          pageTotalScore,
        },
      }));
    } else {
      setTotalPagesScorePercentages((prev) => {
        delete prev[selectedPageIndex];
        return prev;
      });
    }
  }, [scoreInPercentage, selectedPageIndex, pageScore]);

  useEffect(() => {
    let calcScore = template
      .getValues("templateItems")
      ?.[selectedPageIndex]?.children?.reduce((prev, curr, currIndex) => {
        if (curr.type === "SECTION") {
          return (
            prev +
            curr?.children?.reduce((prev, curr, sectionIndex) => {
              return prev + +parseInt(curr.question?.questionScore ?? "0");
            }, 0)
          );
        } else {
          return prev + parseInt(curr.question?.questionScore ?? "0");
        }
      }, 0);
    setPageScore(calcScore);
  }, [selectedPageIndex]);
  return (
    <InspectionContextProvider>
      <Container
        maxW="full"
        background="white"
        padding={0}
        h="calc(100vh - 70px)"
      >
        <Stack
          display={mode === "CONDUCT" ? "flex" : "none"}
          position="sticky"
          top="0px"
          bgColor="White"
          w="100%"
          zIndex={11}
          px="8px"
          h={mode === "CONDUCT" ? "60px" : "0px"}
          borderBottom="1px solid"
          borderColor="borders.accent"
          justifyContent="space-between"
        >
          <HStack h={mode === "CONDUCT" ? "60px" : "0px"}>
            <IconButton
              aria-label="back"
              backgroundColor="transparent"
              borderRadius={"50%"}
              border={"none"}
              _hover={{ backgroundColor: "#bfc6d4" }}
              icon={<RiArrowLeftLine color="#1f2533" size={"18px"} />}
              onClick={() => {
                if (mode === "PREVIEW") {
                  PreviewModalOperations?.onClose();
                } else {
                  navigate(-1);
                }
              }}
            />
            <Text fontSize="21px" fontWeight={500} color="textcolors.primary">
              {mode === "PREVIEW"
                ? "Preview"
                : template.getValues("template.title")}
            </Text>
          </HStack>
          <IsSavingComponent />
        </Stack>

        <Container maxW="container.lg" pb="120px">
          <Stack
            position="sticky"
            top={mode === "CONDUCT" ? "60px" : "0px"}
            bgColor="White"
            w="100%"
            zIndex={11}
            h="81px"
            borderBottom="1px solid"
            borderX="1px solid"
            borderColor="borders.accent"
            backgroundColor="backgrounds.header"
          >
            <Menu matchWidth={true} autoSelect={false}>
              {({ isOpen }) => (
                <>
                  <MenuButton p="16px">
                    <Stack direction="row" justifyContent="space-between">
                      <VStack align={"start"}>
                        <HStack>
                          {isOpen ? (
                            <FiChevronUp color="#545f70" size="16px" />
                          ) : (
                            <FiChevronDown color="#545f70" size="16px" />
                          )}
                          <Text
                            fontSize="13px"
                            fontWeight={400}
                            color="icons.primary"
                          >
                            Page {selectedPageIndex + 1} of {pagesArr.length}
                          </Text>
                        </HStack>
                        <Text
                          fontSize="16px"
                          fontWeight={400}
                          color="textcolors.primary"
                        >
                          {pagesArr?.[selectedPageIndex]?.title}
                        </Text>
                      </VStack>
                      <VStack
                        align={"end"}
                        display={(pageTotalScore ?? 0) > 0 ? "block" : "none"}
                      >
                        <Text
                          fontSize="13px"
                          fontWeight={400}
                          color="icons.primary"
                        >
                          Score
                        </Text>
                        <Text
                          fontSize="16px"
                          fontWeight={400}
                          color="textcolors.primary"
                        >
                          {`${pageScore}/${pageTotalScore} (${
                            !Number.isNaN(scoreInPercentage)
                              ? scoreInPercentage.toFixed(2)
                              : 0
                          }%)`}
                        </Text>
                      </VStack>
                    </Stack>
                  </MenuButton>
                  <MenuList>
                    {pagesArr?.map((page, i) => (
                      <MenuItem
                        key={page.id ?? i}
                        px="16px"
                        py="8px"
                        onClick={() => {
                          setSelectedPageIndex(i);
                        }}
                      >
                        {page.title}
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Stack>

          <Stack mt="8px" mb="64px">
            {pagesArr?.[selectedPageIndex]?.children?.map((child, i) => {
              if (child.type === "SECTION") {
                return (
                  <InpectionFormSection
                    key={`page:${selectedPageIndex}-section:${i}`}
                    onReducePageScore={reduceTotalPageScore}
                    pageIndex={selectedPageIndex}
                    sectionIndex={i}
                    onScoreChanged={onScoreChanged}
                    selectedPageIndex={selectedPageIndex}
                    scoreToBeReduced={scoreToBeReduced}
                  />
                );
              } else {
                return (
                  <MemorisedInspectionQuestion
                    key={`page:${selectedPageIndex}-question:${i}`}
                    child={child}
                    i={i}
                    selectedPageIndex={selectedPageIndex}
                    onScoreChanged={onScoreChanged}
                    onReducePageScore={reduceTotalPageScore}
                  />
                );
              }
            })}

            {selectedPageIndex === pagesArr.length - 1 &&
              futureImplemetation && (
                <InventoryItems
                  setDeletedInventoryItems={setDeletedInventoryItems}
                />
              )}
            {selectedPageIndex === pagesArr.length - 1 &&
              futureImplemetation && <PeriodicalListStatus />}
            {selectedPageIndex === pagesArr.length - 1 &&
              template.getValues("templateMedia") &&
              template.getValues("templateMedia").length > 0 && (
                <InspectionDocuments />
              )}
          </Stack>

          <HStack
            justify={selectedPageIndex !== 0 ? "space-between" : "flex-end"}
          >
            {selectedPageIndex !== 0 && (
              <NavigationButton
                direction="previous"
                onClick={() => {
                  setSelectedPageIndex(selectedPageIndex - 1);
                }}
              >
                Previous Page
              </NavigationButton>
            )}

            <HStack>
              {mode === "CONDUCT" && (
                <NavigationButton
                  direction="draft"
                  isLoading={isSubmitting}
                  onClick={() => {
                    const data = template.getValues();
                    const sumOfPageScores = Object.values(
                      totalPagesScorePercentages
                    ).reduce((prev, curr) => {
                      return prev + curr.pageScore;
                    }, 0);
                    const sumOfPageTotalScores = Object.values(
                      totalPagesScorePercentages
                    ).reduce((prev, curr) => {
                      return prev + curr.pageTotalScore;
                    }, 0);
                    const finalPercentage =
                      (sumOfPageScores / sumOfPageTotalScores) * 100;
                    setIsSubmitting.on();
                    const inspectionMetaID =
                      template.getValues("inspection.id");
                    const siteName = template.getValues("siteName");
                    const siteLocation = template.getValues("siteLocation");
                    const InspectionsActivities = data?.scheduleEvent?.ids;
                    delete data?.scheduleEvent;
                    Inspection.SubmitCompletedInspection(
                      {
                        id: inspectionMetaID,
                        inspectionScore: +finalPercentage?.toFixed(2),
                        siteName: siteName!,
                        siteLocation: siteLocation!,
                        // inspectionInventory: template.getValues(
                        //   'inspectionInventory',
                        // ),
                      },
                      (message) => {
                        // console.log(InspectionsActivities);
                        if (
                          InspectionsActivities &&
                          InspectionsActivities.length > 0
                        ) {
                          Inspection.SubmitActivitiesInspection(
                            {
                              ids: InspectionsActivities,
                              status: 1,
                              inspectionId: inspectionMetaID,
                            },
                            (success) => {
                              toast({
                                title: "Acticites Status Updated",
                                status: "success",
                              });
                            },
                            () => {
                              toast({
                                title: "Error",
                                description:
                                  "Error Occured while Updating Activities Status",
                                status: "error",
                              });
                            }
                          );
                        }

                        setIsSubmitting.off();
                        toast({
                          title: "Inspection Saved",
                          status: "success",
                        });

                        navigate(-1);
                      },
                      (error) => {
                        setIsSubmitting.off();
                        toast({
                          title: "Error",
                          description:
                            "Error Occured while Submitting Inspection",
                          status: "error",
                        });
                      }
                    );
                  }}
                >
                  Save as Draft
                </NavigationButton>
              )}
              {selectedPageIndex === pagesArr.length - 1 ? (
                mode === "CONDUCT" ? (
                  <NavigationButton
                    isLoading={isSubmitting}
                    direction="submit"
                    onClick={template.handleSubmit(
                      (data) => {
                        setIsSubmitting.on();
                        const sumOfPageScores = Object.values(
                          totalPagesScorePercentages
                        ).reduce((prev, curr) => {
                          return prev + curr.pageScore;
                        }, 0);
                        const sumOfPageTotalScores = Object.values(
                          totalPagesScorePercentages
                        ).reduce((prev, curr) => {
                          return prev + curr.pageTotalScore;
                        }, 0);
                        const finalPercentage =
                          (sumOfPageScores / sumOfPageTotalScores) * 100;
                        const inspectionMetaID =
                          template.getValues("inspection.id");
                        const siteName = template.getValues("siteName");
                        const siteLocation = template.getValues("siteLocation");
                        const InspectionsActivities = data?.scheduleEvent?.ids;
                        delete data?.scheduleEvent;
                        Inspection.SubmitCompletedInspection(
                          {
                            id: inspectionMetaID,
                            inspectionScore: +finalPercentage?.toFixed(2),
                            siteName: siteName!,
                            siteLocation: siteLocation!,
                          },
                          (message) => {
                            setIsSubmitting.off();
                            if (
                              InspectionsActivities &&
                              InspectionsActivities?.length > 0
                            ) {
                              Inspection.SubmitActivitiesInspection(
                                {
                                  ids: InspectionsActivities,
                                  status: 1,
                                  inspectionId: inspectionMetaID,
                                },
                                (success) => {
                                  toast({
                                    title: "Activities Status Updated",
                                    status: "success",
                                  });
                                },
                                () => {
                                  toast({
                                    title: "Error",
                                    description:
                                      "Error Occured while Updating Activities Status",
                                    status: "error",
                                  });
                                }
                              );
                            }
                            toast({
                              title: "Inspection Submitted",
                              status: "success",
                            });
                            navigate(-1);
                          },
                          (error) => {
                            setIsSubmitting.off();
                            toast({
                              title: "Error",
                              description:
                                "Error Occured while Submitting Inspection",
                              status: "error",
                            });
                          }
                        );
                      },
                      (error) => {
                        const templateItems = Object.entries(
                          error
                        )?.[0]?.[1] as Array<any>;
                        const inValidFields = [];
                        for (let page in templateItems) {
                          for (let child in templateItems[page]) {
                            for (let question_or_section_index in templateItems[
                              page
                            ][child]) {
                              if (
                                templateItems[page][child][
                                  question_or_section_index
                                ]?.["children"]
                              ) {
                                for (let section_question_index in templateItems[
                                  page
                                ][child][question_or_section_index][
                                  "children"
                                ]) {
                                  inValidFields.push({
                                    page,
                                    question: section_question_index,
                                    section: question_or_section_index,
                                  });
                                }
                              } else {
                                inValidFields.push({
                                  page,
                                  question: question_or_section_index,
                                });
                              }
                            }
                          }
                        }

                        setSelectedPageIndex(+inValidFields?.[0].page);

                        setIsSubmitting.off();
                        toast({
                          title: "Error",
                          description: `You have missed ${inValidFields.length} questions to answer`,
                          status: "error",
                        });
                      }
                    )}
                  >
                    Submit Inspection
                  </NavigationButton>
                ) : (
                  <></>
                )
              ) : (
                <NavigationButton
                  direction="next"
                  onClick={() => {
                    setSelectedPageIndex(selectedPageIndex + 1);
                  }}
                >
                  Next Page
                </NavigationButton>
              )}
            </HStack>
          </HStack>
        </Container>
      </Container>
    </InspectionContextProvider>
  );
}

function InspectionQuestion({
  child,
  i,
  selectedPageIndex,
  sectionIndex,
  onScoreChanged,
  onReducePageScore,
}: {
  child: Template.TemplateItem;
  i: number;
  selectedPageIndex: number;
  sectionIndex?: number;
  onScoreChanged?: (score: number, previousScore?: number) => void;
  onReducePageScore: (
    item: ReduceTotalPageScoreObject,
    operation: "ADD" | "REMOVE"
  ) => void;
}) {
  return (
    <InspectionContextProvider>
      <AuditQuestionContainer
        onReducePageScore={onReducePageScore}
        haveNotes={(child.question?.notes?.length ?? 0) > 0}
        score={child.question?.score ? +child.question?.score : undefined}
        question={child.title}
        removeMediaOption={["MEDIA", "SIGNATURE", "ANNOTATION"].includes(
          child.question?.type ?? ""
        )}
        removeExtraOption={["INSTRUCTIONS"].includes(
          child.question?.type ?? ""
        )}
        removeQuestion={child.question?.type === "INSTRUCTIONS"}
        pageIndex={selectedPageIndex}
        questionIndex={i}
        sectionIndex={sectionIndex}
        isRequired={child.question?.required}
        onScoreChanged={onScoreChanged}
        key={child.id ?? `${selectedPageIndex}-${sectionIndex}-${i}`}
      >
        {child.question?.type === "ANNOTATION" && <Annotation />}
        {child.question?.type === "CHECKBOX" && <CheckBox />}
        {child.question?.type === "DATE_TIME" && <DateTime />}
        {child.question?.type === "DOCUMENT_NUMBER" && <DocumentNumber />}
        {child.question?.type === "MCQs" &&
          child.question.mcqsData?.isGlobal && <GlobalResponse />}
        {child.question?.type === "INSPECTION_DATE" && <InspectionDate />}
        {child.question?.type === "INSPECTION_LOCATION" && (
          <InspectionLocation />
        )}
        {child.question?.type === "INSTRUCTIONS" && <Instruction />}
        {child.question?.type === "LOCATION" && <Location />}
        {child.question?.type === "MEDIA" && <Media />}
        {child.question?.type === "MCQs" &&
          !child.question.mcqsData?.isGlobal && <TemplateMultiChoice />}
        {child.question?.type === "NUMBER" && <NumberTypeComponent />}
        {child.question?.type === "PERSON" && <PersonPreview />}
        {child.question?.type === "SIGNATURE" && <Signature />}
        {child.question?.type === "SITE" && <Site2 />}
        {child.question?.type === "SLIDER" && <SliderPreview />}
        {child.question?.type === "TEXT_ANSWER" && <TextAnswer />}
        {child.question?.type === "TABLE" && <InspectionTable />}
      </AuditQuestionContainer>
    </InspectionContextProvider>
  );
}

export const MemorisedInspectionQuestion = React.memo(
  InspectionQuestion,
  (prev, next) => prev.child.question?.id === next.child.question?.id
);
