import {
  Box,
  Center,
  Checkbox,
  Collapse,
  Divider,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
  useOutsideClick,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef } from "react";
import {
  Controller,
  UseFieldArrayReturn,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { FaPaste } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { RxDragHandleDots2 } from "react-icons/rx";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { Template } from "../../../modules/Audit";
import { getFieldKey } from "../../../modules/form-apis";
import { ItemProvider } from "../hooks/auditItemContext";

import { ScoreContext, useScoreContext } from "../hooks/templateBuilderContext";
import { QuestionSettingLg } from "./QuestionSettingLg";
import { QuestionSettingSm } from "./QuestionSettingSm";
import ScorePopover from "./Scorepopover";
import Section from "./Section";
import SidePopover from "./SidePopover";
import Types from "./Types";

interface QuestionTableProps {
  pageIndex: number;

  pageFieldArray: UseFieldArrayReturn<Template.Template, "templateItems", "id">;
}
export type SortableElementType = {
  value: Template.TemplateItem;
  questionIndex: number;
  pageIndex: number;

  pageFieldArray: UseFieldArrayReturn<Template.Template, "templateItems", "id">;
  sectionIndex?: number;
};

export default function QuestionTable({
  pageIndex,
  pageFieldArray,
}: QuestionTableProps) {
  const scoreContext = useScoreContext();

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    pageFieldArray.move(oldIndex, newIndex);
  };

  const DragContainer = SortableContainer<{ children: any }>(
    ({ children }: any) => <div>{children}</div>
  );

  const DraggableItem = SortableElement<SortableElementType>(QuestionItemMemo);

  return (
    <Stack
      // margin={'20px'}
      spacing={0}
      // boxShadow="lg"
      // borderLeftWidth="1px"
      borderTop={"1px solid"}
      borderLeft={"1px solid"}
      borderRight={"1px solid"}
      borderTopRadius="10px"
      borderColor={"borders.accent"}
    >
      <Stack
        direction={"row"}
        w={"100%"}
        spacing={0}
        borderTopRadius="10px"
        backgroundColor={"backgrounds.header"}
        position="relative"
      >
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label={"AddButton"}
            position={"absolute"}
            padding={0}
            top={0}
            right={"-10px"}
            size="xs"
            backgroundColor={"white"}
            icon={<IoMdAdd color={"#373857"} size={15} />}
            variant="outline"
            borderRadius={"50%"}
            border={"1px"}
            borderColor={"#828ea0"}
            _active={{ backgroundColor: "white", bg: "white" }}
            _hover={{ backgroundColor: "white" }}
            _focus={{ backgroundColor: "white" }}
            _focusVisible={{ backgroundColor: "white" }}
          />
          <MenuList minWidth={"100px"}>
            <MenuItem
              as={Checkbox}
              onChange={(e: any) => {
                // scoreContext.showScore = e.target.checked;
                scoreContext.setShowScore(e.target.checked);
              }}
              backgroundColor={"transparent"}
            >
              Score
            </MenuItem>
          </MenuList>
        </Menu>
        <Box
          flex={3}
          borderBottom={"1px"}
          borderRight={{ xs: "-1px", md: "1px" }}
          borderStyle={"solid"}
          borderColor={{ xs: "borders.accent", md: "borders.accent" }}
        >
          <HStack w={"100%"} justifyContent={"space-between"}>
            <Text
              padding={{
                xs: "8px 0px 8px 14px",
                md: "8px 0px 8px 14px",
              }}
              color={"#373857"}
              fontSize={"12px"}
              fontWeight={400}
            >
              Question
            </Text>
            <Stack
              color={"#373857"}
              fontSize={"12px"}
              fontWeight={400}
              display={{ xs: "block", md: "none" }}
              padding={"8px 14px 8px 0px"}
            >
              <Menu>
                <MenuButton
                  as={"a"}
                  _active={{
                    backgroundColor: "transparent",
                    bg: "transparent",
                  }}
                  _hover={{ backgroundColor: "transparent" }}
                  _focus={{ backgroundColor: "transparent" }}
                >
                  Extra Options
                </MenuButton>
                <MenuList minWidth={"100px"}>
                  <MenuItem as={Checkbox} backgroundColor={"transparent"}>
                    Score
                  </MenuItem>
                </MenuList>
              </Menu>
            </Stack>
          </HStack>
        </Box>
        <Box
          // flex={2}
          borderBottom={"1px solid"}
          borderColor={"borders.accent"}
          display={{ xs: "none", md: "block" }}
          w={{
            xs: "100%",
            md: "300px",
            lg: "400px",
          }}
        >
          <Text
            w={"100%"}
            padding={{ xs: "8px 10px 8px 20px", md: "8px 0px 8px 20px" }}
            textAlign={"start"}
            color={"#373857"}
            fontSize={"12px"}
            fontWeight={400}
          >
            Type of Responses
          </Text>
        </Box>
        {scoreContext.showScore && (
          <Box
            // flex={1}
            borderLeft={"1px"}
            borderBottom={"1px"}
            borderStyle={"solid"}
            borderColor={"borders.accent"}
            display={{ xs: "none", md: "block" }}
            w={{
              xs: "100%",
              md: "110px",
            }}
          >
            <Text
              w={"100%"}
              padding={{ xs: "8px 10px 8px 20px", md: "8px 0px 8px 20px" }}
              textAlign={"start"}
              color={"#373857"}
              fontSize={"12px"}
              fontWeight={400}
            >
              Max score
            </Text>
          </Box>
        )}
      </Stack>

      <DragContainer onSortEnd={onSortEnd} useDragHandle>
        {pageFieldArray.fields.map((item, i) => {
          return (
            <DraggableItem
              key={i}
              index={i}
              value={item}
              pageIndex={pageIndex}
              pageFieldArray={pageFieldArray}
              questionIndex={item.type === "QUESTION" ? i : 0}
              sectionIndex={item.type === "SECTION" ? i : undefined}
            />
          );
        })}
      </DragContainer>
    </Stack>
  );
}
export const QuestionItemMemo = React.memo(
  ({
    questionIndex,
    value,
    pageIndex,
    sectionIndex,
    pageFieldArray,
  }: SortableElementType) => {
    return value.type === "SECTION" && sectionIndex !== undefined ? (
      <Section
        key={`page:${pageIndex}-section:${sectionIndex}`}
        pageIndex={pageIndex}
        sectionIndex={sectionIndex}
        onDelete={() => {
          pageFieldArray.remove(sectionIndex);
        }}
      />
    ) : (
      <ItemProvider
        pageFieldArray={pageFieldArray}
        key={`page:${pageIndex}-section:${sectionIndex}-question:${questionIndex}`}
        questionIndex={questionIndex}
        pageIndex={pageIndex}
      >
        <QuestionItem
          questionIndex={questionIndex}
          pageIndex={pageIndex}
          sectionIndex={sectionIndex}
          onDelete={(index) => {
            pageFieldArray.remove(index);
          }}
        />
      </ItemProvider>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.value.type !== nextProps.value.type;
  }
);
export function QuestionItem({
  // item,
  questionIndex,
  pageIndex,
  isSection,
  sectionIndex,
  onDelete,
}: {
  // item: Audit.AuditItem;
  questionIndex: number;
  pageIndex: number;
  sectionIndex?: number;
  isSection?: boolean;
  onDelete: (questionIndex: number) => void;
}) {
  const template = useFormContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const boxRef = useRef<HTMLDivElement>(null);

  const scoreContext = useContext(ScoreContext);
  useOutsideClick({
    ref: boxRef,
    handler: onClose,
  });

  const DragHandle = SortableHandle(() => (
    <RxDragHandleDots2 size={24} color={"#828ea0"} />
  ));

  const isQuestionRequired = useWatch({
    control: template.control,
    name: getFieldKey(
      { pageIndex, sectionIndex, questionIndex },
      "question.required"
    ),
  });

  const questionType = useWatch({
    control: template.control,
    name: getFieldKey(
      { pageIndex, sectionIndex, questionIndex },
      "question.type"
    ),
  });

  const questionId = useWatch({
    control: template.control,
    name: getFieldKey({ pageIndex, sectionIndex, questionIndex }, "id"),
  });

  useEffect(() => {
    template.setValue(
      getFieldKey({ pageIndex, questionIndex, sectionIndex }, "orderIndex"),
      questionIndex
    );
  }, []);

  return (
    <Box ref={boxRef} onFocus={onOpen}>
      <Stack
        _hover={{ backgroundColor: "gray.200" }}
        bgColor="white"
        direction={{ xs: "column", md: "row" }}
        w={"100%"}
        spacing={0}
        position="relative"
      >
        {isOpen && (
          <IconButton
            display={{ xs: "none", md: "flex" }}
            backgroundColor="transparent"
            _hover={{ backgroundColor: "transparent" }}
            position="absolute"
            aria-label="delete"
            icon={<MdDelete color="#545f70" size="21px" />}
            right={"-30px"}
            onClick={() => {
              onDelete(questionIndex);
              if (questionId) {
                const deletedItems = template.getValues(`deletedItems`) ?? [];
                deletedItems.push({ id: questionId, type: "QUESTION" });
                template.setValue(`deletedItems`, deletedItems);
              }
            }}
          />
        )}
        {isOpen && (
          <Box
            position="absolute"
            left={isSection ? "-115px" : "-65px"}
            top="-30px"
            zIndex={5}
          >
            <SidePopover />
          </Box>
        )}
        <Box
          flex={3}
          h="43.2px"
          // borderLeft={{xs: '-1px', md: '-1px'}}
          borderRight={{ xs: "-1px", md: "1px" }}
          borderBottom={"1px"}
          borderColor={{ xs: "borders.accent", md: "borders.accent" }}
        >
          <HStack h={"100%"} marginLeft={"10px"}>
            {isSection && (
              <Center width="38px" h="100%">
                <Box bgColor="red" width="2px" height="100%" />
              </Center>
            )}
            <DragHandle />

            {isQuestionRequired && <Text>*</Text>}
            <Controller
              control={template.control}
              name={
                ((sectionIndex ?? -1) > -1 && (questionIndex ?? -1) > -1
                  ? `templateItems.${pageIndex}.children.${sectionIndex}.children.${questionIndex}.title`
                  : `templateItems.${pageIndex}.children.${questionIndex}.title`) as any
              }
              render={({ field }) => (
                <Input
                  placeholder="New Question"
                  borderColor="transparent"
                  _hover={{
                    borderColor: "transparent",
                  }}
                  _focusVisible={{
                    borderColor: "primary.500",
                    boxShadow: "0 0 0 1px var(--chakra-colors-primary-500)",
                  }}
                  {...field}
                />
              )}
            />
          </HStack>
        </Box>
        <Box
          overflow="hidden"
          // flex={2}
          borderBottom={"1px"}
          borderColor={"borders.accent"}
          borderStyle={"solid"}
          h="43.2px"
          w={{
            xs: "100%",
            md: "300px",
            lg: "400px",
          }}
        >
          <HStack
            w={"100%"}
            justifyContent={"space-between"}
            spacing={0}
            h={"100%"}
          >
            <Stack w={"100%"} overflowX="auto">
              <Types
                pageIndex={pageIndex}
                sectionIndex={sectionIndex}
                questionIndex={questionIndex}
              />
            </Stack>
            <Stack
              display={{ xs: "block", md: "none" }}
              borderLeft={"1px"}
              borderColor={"borders.accent"}
              borderStyle={"solid"}
            >
              {/* Question Setting for small screen */}
              <QuestionSettingSm questionType={questionType} />
            </Stack>
          </HStack>
        </Box>
        {scoreContext.showScore && (
          <Box
            // flex={1}
            borderLeft={{ xs: "-1px", md: "1px" }}
            borderBottom={"1px"}
            borderStyle={"solid"}
            borderColor={{ xs: "borders.accent", md: "borders.accent" }}
            h="43.2px"
            w={{
              xs: "100%",
              md: "110px",
            }}
          >
            {(
              ["MCQs", "CHECKBOX", "SLIDER"] as Array<Template.QuestionType>
            ).includes(questionType) ? (
              <ScorePopover
                key={`${pageIndex}-${sectionIndex}-${questionIndex}`}
              />
            ) : (
              <VStack h="100%" justify="center" align="start" px="1rem">
                <Text color="chakra-placeholder-color" lineHeight="24px">
                  /
                </Text>
              </VStack>
            )}
          </Box>
        )}
      </Stack>
      <Collapse in={isOpen} animateOpacity>
        <Box
          display={{ xs: "none", md: "block" }}
          w={"100%"}
          backgroundColor={"backgrounds.header"}
          borderBottom={"1px"}
          borderColor={"borders.accent"}
        >
          <HStack w={"100%"} justifyContent={"space-between"}>
            <HStack spacing={3} marginLeft={"10px"}>
              {questionType !== "INSTRUCTIONS" &&
                questionType !== "DOCUMENT_NUMBER" && (
                  <Controller
                    control={template.control}
                    name={getFieldKey(
                      { pageIndex, questionIndex, sectionIndex },
                      "question.required"
                    )}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        isChecked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      >
                        Required
                      </Checkbox>
                    )}
                  />
                )}
              {questionType !== "INSTRUCTIONS" &&
                questionType !== "DOCUMENT_NUMBER" && (
                  <Divider
                    orientation="vertical"
                    border="1px"
                    borderColor="borders.accent"
                    h="20px"
                  />
                )}
              {questionType !== "INSTRUCTIONS" && (
                <Controller
                  control={template.control}
                  name={getFieldKey(
                    { pageIndex, questionIndex, sectionIndex },
                    "question.includeTimestamp"
                  )}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    >
                      Timestamp
                    </Checkbox>
                  )}
                />
              )}
              {/* Question setting for large screen */}
              <QuestionSettingLg questionType={questionType} />
            </HStack>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FiMoreVertical size={24} color="#545f70" />}
                variant="outline"
                borderRadius={"50%"}
                border={"none"}
                _hover={{ backgroundColor: "backgrounds.secondary" }}
                _active={{
                  backgroundColor: "transparent",
                  _hover: { backgroundColor: "backgrounds.secondary" },
                }}
              />
              <MenuList minWidth={"100px"}>
                <MenuItem
                  icon={<FaPaste size={18} color="#545f70" />}
                  fontSize={"14px"}
                  fontWeight={400}
                  color={"textcolors.primary"}
                >
                  Paste questions
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Box>
      </Collapse>
    </Box>
  );
}
