import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Input,
  Link,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import { Fragment, useEffect } from "react";
import { Controller, useForm, useFormContext, useWatch } from "react-hook-form";
import { Template } from "../../../../modules/Audit";
import { getFieldKey } from "../../../../modules/form-apis";
import useDrawer from "../../hooks/drawersContext";
interface TemperatureDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function TemperatureDrawer({
  isOpen,
  onClose,
}: TemperatureDrawerProps) {
  const { questionItemDetails } = useDrawer();
  const template = useFormContext();

  const temperatureForm = useForm<Template.QuestionProperties>({
    defaultValues: template.getValues(
      getFieldKey(
        questionItemDetails ?? { pageIndex: 0 },
        "question.properties"
      )
    ),
  });
  const watchedCondition = useWatch({
    control: temperatureForm.control,
    name: "condition",
  });
  useEffect(() => {
    if (questionItemDetails?.questionIndex)
      temperatureForm.reset(
        template.getValues(
          getFieldKey(questionItemDetails, "question.properties")
        )
      );
  }, [questionItemDetails?.questionIndex]);

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      size={{ xs: "full", md: "md" }}
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent
        backgroundColor={"backgrounds.header"}
        overflowY="auto"
        minH="100vh"
      >
        <DrawerHeader
          padding={"24px"}
          borderBottom={"1px solid"}
          borderColor={"borders.accent"}
        >
          <VStack>
            <Text
              w={"100%"}
              fontSize={"21px"}
              fontWeight={500}
              color={"textcolors.primary"}
              textAlign={"start"}
            >
              Temperature range
            </Text>
            <Stack
              fontSize={"14px"}
              fontWeight={400}
              color={"icons.primary"}
              spacing={0}
            >
              <Text>
                SafetyCulture can capture temperature readings from Bluetooth
                thermometers.
              </Text>
              <Text>Supported devices are:</Text>
              <UnorderedList>
                <ListItem>Cooper-Atkins Blue2 thermometer</ListItem>
                <ListItem>RayTemp Blue thermometer</ListItem>
              </UnorderedList>
            </Stack>
            <Link
              w="100%"
              textDecoration="underline"
              fontSize={"14px"}
              fontWeight={400}
            >
              How do Bluetooth thermometer readings work?
            </Link>
          </VStack>
        </DrawerHeader>

        <DrawerBody
          minH={{ xs: "168px", md: "126px" }}
          maxH="126px"
          backgroundColor={"white"}
          padding="24px"
        >
          <Stack>
            <Text fontSize="14px" fontWeight={400} color="textcolors.primary">
              Temperature should be
            </Text>
            <Stack w={"100%"} direction={{ xs: "column", md: "row" }}>
              <Controller
                control={temperatureForm.control}
                name="condition"
                render={({ field }) => (
                  <Menu>
                    {({ isOpen }) => (
                      <>
                        <MenuButton
                          minH="40px"
                          isActive={isOpen}
                          as={Button}
                          backgroundColor={"white"}
                          fontSize={"14px"}
                          fontWeight={400}
                          color={"textcolors.primary"}
                          border={"1px solid"}
                          borderColor="primary.500"
                          borderRadius={"5px"}
                          padding={"8px 16px"}
                          _hover={{ backgroundColor: "white" }}
                          _focus={{ backgroundColor: "white" }}
                          _focusVisible={{ backgroundColor: "white" }}
                          _active={{ backgroundColor: "white" }}
                          rightIcon={
                            isOpen ? (
                              <ChevronUpIcon color={"#1f2533"} />
                            ) : (
                              <ChevronDownIcon color={"#1f2533"} />
                            )
                          }
                        >
                          {
                            Template.QUESTION_CONDITIONS[
                              field.value ?? "BETWEEN"
                            ].label
                          }
                        </MenuButton>
                        <MenuList
                          fontSize={"14px"}
                          fontWeight={400}
                          color={"textcolors.primary"}
                          minW={"100px"}
                          padding={"8px 0px"}
                        >
                          <MenuItem
                            _hover={{ backgroundColor: "blue.100" }}
                            onClick={() => {
                              field.onChange(
                                Template.QUESTION_CONDITIONS.BETWEEN.value
                              );
                            }}
                          >
                            Between
                          </MenuItem>
                          <MenuItem
                            _hover={{ backgroundColor: "blue.100" }}
                            onClick={() => {
                              field.onChange(
                                Template.QUESTION_CONDITIONS.GREATER_AND_EQUAL
                                  .value
                              );
                              temperatureForm.setValue("smaller", undefined);
                            }}
                          >
                            Greater than or equal to
                          </MenuItem>
                          <MenuItem
                            _hover={{ backgroundColor: "blue.100" }}
                            onClick={() => {
                              field.onChange(
                                Template.QUESTION_CONDITIONS.LESS_AND_EQUAL
                                  .value
                              );
                              temperatureForm.setValue("greater", undefined);
                            }}
                          >
                            Less than or equal to
                          </MenuItem>
                        </MenuList>
                      </>
                    )}
                  </Menu>
                )}
              />

              <HStack spacing={3} w={{ md: "350px", xs: "auto" }}>
                {(watchedCondition === "BETWEEN" ||
                  watchedCondition === "LESS_AND_EQUAL") && (
                  <Controller
                    control={temperatureForm.control}
                    name="smaller"
                    render={({ field }) => (
                      <Input
                        fontSize={"14px"}
                        fontWeight={400}
                        padding={"8px 16px"}
                        flex={2}
                        border="1px"
                        borderStyle={"solid"}
                        borderColor={"#abb5c4"}
                        _focusVisible={{
                          boxShadow: "0px",
                          borderColor: "primary.500",
                        }}
                        type="number"
                        width="auto"
                        {...field}
                      />
                    )}
                  />
                )}

                {(watchedCondition === "BETWEEN" ||
                  watchedCondition === "GREATER_AND_EQUAL") && (
                  <Fragment>
                    <Divider w={"2px"} borderWidth="1px" borderColor="black" />
                    <Controller
                      control={temperatureForm.control}
                      name="greater"
                      render={({ field }) => (
                        <Input
                          fontSize={"14px"}
                          fontWeight={400}
                          padding={"8px 16px"}
                          flex={2}
                          border="1px"
                          borderStyle={"solid"}
                          borderColor={"#abb5c4"}
                          _focusVisible={{
                            boxShadow: "0px",
                            borderColor: "primary.500",
                          }}
                          type="number"
                          width="auto"
                          {...field}
                        />
                      )}
                    />
                  </Fragment>
                )}
                <Controller
                  control={temperatureForm.control}
                  name="dUnit"
                  render={({ field }) => (
                    <Menu>
                      {({ isOpen }) => (
                        <>
                          <MenuButton
                            minH="40px"
                            isActive={isOpen}
                            as={Button}
                            backgroundColor={"white"}
                            fontSize={"14px"}
                            fontWeight={400}
                            color={"textcolors.primary"}
                            border={"1px solid"}
                            borderColor="primary.500"
                            borderRadius={"5px"}
                            padding={"8px 16px"}
                            _hover={{ backgroundColor: "white" }}
                            _focus={{ backgroundColor: "white" }}
                            _focusVisible={{ backgroundColor: "white" }}
                            _active={{ backgroundColor: "white" }}
                            rightIcon={
                              isOpen ? (
                                <ChevronUpIcon color={"#1f2533"} />
                              ) : (
                                <ChevronDownIcon color={"#1f2533"} />
                              )
                            }
                          >
                            °{field.value}
                          </MenuButton>
                          <MenuList
                            fontSize={"14px"}
                            fontWeight={400}
                            color={"textcolors.primary"}
                            minW={"100px"}
                            padding={"8px 0px"}
                          >
                            <MenuItem
                              _hover={{ backgroundColor: "blue.100" }}
                              onClick={() => {
                                field.onChange("C");
                              }}
                            >
                              °C
                            </MenuItem>
                            <MenuItem
                              _hover={{ backgroundColor: "blue.100" }}
                              onClick={() => {
                                field.onChange("F");
                              }}
                            >
                              °F
                            </MenuItem>
                          </MenuList>
                        </>
                      )}
                    </Menu>
                  )}
                />
              </HStack>
            </Stack>
          </Stack>
        </DrawerBody>

        <DrawerFooter
          borderTop="1px solid"
          borderColor={"borders.accent"}
          padding={"24px"}
          backgroundColor={"backgrounds.header"}
          flexDirection="column"
        >
          <Stack spacing={0}>
            <Text
              fontSize={"14px"}
              fontWeight={400}
              color={"icons.primary"}
              marginBottom="16px"
            >
              Integration with Bluetooth thermometers is a premium feature. You
              need to upgrade to use Bluetooth thermometers to capture
              temperature readings.
            </Text>
            <HStack direction={{ xs: "column", md: "row" }} w="100%">
              <Button
                h="40px"
                w={"auto"}
                borderRadius="5px"
                fontSize="14px"
                fontWeight={500}
                marginRight="16px"
                padding={"12px 20px"}
                onClick={temperatureForm.handleSubmit(
                  (data) => {
                    if (questionItemDetails?.questionIndex) {
                      template.setValue(
                        getFieldKey(questionItemDetails, "question.properties"),
                        data
                      );
                      onClose();
                    }
                  },
                  (error) => {
                    console.log(error);
                  }
                )}
              >
                Save and apply
              </Button>
              <Button
                h="40px"
                borderRadius="5px"
                fontSize="14px"
                fontWeight={500}
                variant="outline"
                padding={"12px 20px"}
                onClick={onClose}
              >
                Cancel
              </Button>
            </HStack>
          </Stack>
          <Box w={"100%"} marginTop="32px">
            <Link fontSize={"14px"} fontWeight={500}>
              Buy Premium plan to use a Bluetooth thermometer
            </Link>
          </Box>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
