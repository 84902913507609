import {
  Box,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  ResponsiveValue,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { TextAlign } from "chart.js";
import moment from "moment";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { DebouncedState } from "use-debounce";
import { PayRun } from "../../Api/PayRun";
import { PaginationControl } from "../PayrollEmployee/PaginationControl/PaginationControl";
interface PayEmployeeTableProps {
  PayRunData: PayRun.PayRun[];
  count: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  pageNumber: number;
  pageLimit: number;
  setPageLimit: React.Dispatch<React.SetStateAction<number>>;
  debounced: DebouncedState<(value: any) => void>;
}
export default function PayEmployeeTable({
  PayRunData,
  count,
  debounced,
  setPageNumber,
  pageNumber,
  pageLimit,
  setPageLimit,
}: PayEmployeeTableProps) {
  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };
  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const pageCount = Math.ceil(count / pageLimit);
  const renderPageNumbers = () => {
    const pageButtons = [];

    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            aria-label={`Page ${i}`}
            border={"1px solid #e8eaf2"}
            borderRadius={"full"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }
    } else {
      pageButtons.push(
        <IconButton
          key={1}
          aria-label={`Page 1`}
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          backgroundColor={pageNumber === 1 ? "#3454D1" : "#fff"}
          color={pageNumber === 1 ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === 1 ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>1</Text>}
          onClick={() => handlePageChange(1)}
        />
      );

      if (pageNumber > 3) {
        pageButtons.push(<Text key="left-dots">...</Text>);
      }

      const startPage = Math.max(2, pageNumber - 1);
      const endPage = Math.min(pageCount - 1, pageNumber + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            border={"1px solid #e8eaf2"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            aria-label={`Page ${i}`}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }

      if (pageNumber < pageCount - 2) {
        pageButtons.push(<Text key="right-dots">...</Text>);
      }

      pageButtons.push(
        <IconButton
          key={pageCount}
          border={"1px solid #e8eaf2"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          borderRadius={"full"}
          aria-label={`Page ${pageCount}`}
          backgroundColor={pageNumber === pageCount ? "#3454D1" : "#fff"}
          color={pageNumber === pageCount ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === pageCount ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>{pageCount}</Text>}
          onClick={() => handlePageChange(pageCount)}
        />
      );
    }

    return pageButtons;
  };
  const navigate = useNavigate();
  return (
    <Stack overflowX="auto" p="0px" gap={0}>
      <Stack
        p={"25px"}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box w={"100%"}>
          <PaginationControl
            limit={pageLimit}
            setLimit={setPageLimit}
            setPageNumber={setPageNumber}
          />
        </Box>{" "}
        <Stack gap={0} direction={"row"} alignItems={"center"}>
          <Text
            fontSize={"13.44px"}
            _light={{
              color: "customColor.gray.6b7885",
            }}
          >
            Search:
          </Text>
          <InputGroup ml={[0, "7px"]}>
            <Input
              type="text"
              placeholder="Search"
              borderRadius={"3px"}
              h={["auto", "40px"]}
              _focusVisible={{
                border: "1px solid grey",
              }}
              onChange={(e) => {
                debounced(e.target.value);
              }}
            />
          </InputGroup>
        </Stack>
      </Stack>
      <Box overflowX="auto" p="0px">
        <Table variant="simple" w="100%" overflowX="auto">
          <Thead h={"36.8px"}>
            <Tr
              borderTopWidth={"1px"}
              borderTopColor="borders.tableBorder"
              color={"customColor.black7"}
              _dark={{
                color: "customColor.dark.150",
              }}
            >
              {[
                {
                  name: "Calendar Name",
                  textAlign: "left",
                  paddingValue: "8px 26px 8px 30px",
                  value: "9%",
                },
                {
                  name: "Period",
                  textAlign: "left",
                  paddingValue: "8px 26px 8px 15px",
                  value: "9%",
                },
                {
                  name: "Payrun Type",
                  textAlign: "left",
                  paddingValue: "8px 26px 8px 15px",
                  value: "9%",
                },
                {
                  name: "Payment Date",
                  textAlign: "left",
                  paddingValue: "8px 26px 8px 15px",
                  value: "9%",
                },
                {
                  name: "Earnings",
                  textAlign: "center",
                  paddingValue: "8px 26px 8px 15px",
                  value: "9%",
                },
                {
                  name: "Deductions",
                  textAlign: "center",
                  paddingValue: "8px 26px 8px 15px",
                  value: "9%",
                },
                {
                  name: "Tax",
                  textAlign: "center",
                  paddingValue: "8px 26px 8px 15px",
                  value: "9%",
                },
                {
                  name: "Statutory Benefits",
                  textAlign: "center",
                  paddingValue: "8px 26px 8px 15px",
                  value: "9%",
                },
                {
                  name: "Reimbursements",
                  textAlign: "center",
                  paddingValue: "8px 26px 8px 15px",
                  value: "9%",
                },
                {
                  name: "Net Pay",
                  textAlign: "center",
                  paddingValue: "8px 26px 8px 15px",
                  value: "9%",
                },

                {
                  name: "View Details",
                  textAlign: "center",
                  paddingValue: "8px 26px 8px 15px",
                  value: "9%",
                },
              ].map((item, i) => {
                return (
                  <Th
                    key={i}
                    textAlign={
                      item.textAlign as ResponsiveValue<TextAlign> | undefined
                    }
                    padding={item.paddingValue}
                    borderBottomColor="borders.tableBorder"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    fontWeight={700}
                    whiteSpace="nowrap"
                    fontSize="12px"
                    textTransform="none"
                    width={item.value}
                  >
                    {item.name}
                  </Th>
                );
              })}
            </Tr>
          </Thead>

          <Tbody padding={0}>
            {PayRunData.map((item: PayRun.PayRun, i: any) => {
              return (
                <Tr
                  key={i}
                  _hover={{
                    bgColor: "#f2f3f8",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    item?.status === "DRAFT"
                      ? navigate(
                          `/app/payroll/PayRunDetail/${item.id}/?page=1&limit=25`,
                          {
                            state: {
                              nextPayDate: moment(item?.paymentDate).format(
                                "D MMM YYYY"
                              ),
                              endDate: item?.endDate,
                              calenderName: item?.payrollCalendar?.name,
                              status: item?.status,
                              payPeriod: item?.payrollCalendar?.payPeriod,
                            },
                          }
                        )
                      : navigate(
                          `/app/payroll/postedPayRun/${item.id}/?page=1&limit=25`,
                          {
                            state: {
                              nextPayDate: moment(item?.paymentDate).format(
                                "D MMM YYYY"
                              ),
                              endDate: item?.endDate,
                              calenderName: item?.payrollCalendar?.name,
                              status: item?.status,
                              payPeriod: item?.payrollCalendar?.payPeriod,
                            },
                          }
                        );
                  }}
                >
                  <Tooltip
                    label={`${item?.payrollCalendar?.name}`}
                    placement="top"
                    hasArrow
                    bg="gray.800"
                    color="white"
                    fontSize="sm"
                    p="2"
                    rounded="md"
                    overflowWrap="anywhere"
                    whiteSpace="normal"
                    zIndex="10"
                  >
                    <Td
                      maxW="100px"
                      isTruncated
                      padding="15px 15px 15px 30px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      <Text>{item?.payrollCalendar?.name}</Text>
                    </Td>
                  </Tooltip>

                  <Tooltip
                    label={`${item?.payrollCalendar?.name} ending ${moment(
                      item?.endDate
                    ).format("D MMM YYYY")}`}
                    placement="top"
                    hasArrow
                    bg="gray.800"
                    color="white"
                    fontSize="sm"
                    p="2"
                    rounded="md"
                    overflowWrap="anywhere"
                    whiteSpace="normal"
                    zIndex="10"
                  >
                    <Td
                      maxW="150px"
                      // overflow="hidden"
                      // textOverflow="ellipsis"
                      // whiteSpace="nowrap"
                      isTruncated
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {`${item?.payrollCalendar?.name} ending ${moment(
                        item?.endDate
                      ).format("D MMM YYYY")}`}
                    </Td>
                  </Tooltip>
                  <Tooltip
                    label={`${
                      item?.type === "FULL_AND_FINAL"
                        ? "Full and Final"
                        : item?.type === "NORMAL"
                        ? "Normal"
                        : item?.type === "UNSCHEDULED"
                        ? "Unscheduled"
                        : "Unknown"
                    }`}
                    placement="top"
                    hasArrow
                    bg="gray.800"
                    color="white"
                    fontSize="sm"
                    p="2"
                    rounded="md"
                    overflowWrap="anywhere"
                    whiteSpace="normal"
                    zIndex="10"
                  >
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {`${
                        item?.type === "FULL_AND_FINAL"
                          ? "Full and Final"
                          : item?.type === "NORMAL"
                          ? "Normal"
                          : item?.type === "UNSCHEDULED"
                          ? "Unscheduled"
                          : "Unknown"
                      }`}
                    </Td>
                  </Tooltip>
                  <Td
                    padding="15px"
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    {moment(item?.paymentDate).format("D MMM YYYY")}
                  </Td>
                  <Td
                    padding="15px"
                    fontWeight={500}
                    textAlign={"center"}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    {item?.totalEarnings?.toLocaleString()}
                  </Td>
                  <Td
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    textAlign={"center"}
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    {item?.totalDeductions.toLocaleString()}
                  </Td>
                  <Td
                    padding="15px"
                    fontWeight={500}
                    textAlign={"center"}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    {" "}
                    {item?.totalTaxes.toLocaleString()}
                  </Td>
                  <Td
                    padding="15px"
                    textAlign={"center"}
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    {item?.totalRetirements.toLocaleString()}
                  </Td>
                  <Td
                    padding="15px"
                    fontWeight={500}
                    textAlign={"center"}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    {item?.totalReimbursements.toLocaleString()}
                  </Td>
                  <Td
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    textAlign={"center"}
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    {(
                      item?.totalEarnings -
                      item?.totalDeductions -
                      item?.totalTaxes +
                      item?.totalReimbursements
                    ).toLocaleString()}
                  </Td>

                  <Td
                    padding="10px"
                    fontSize="13.44px"
                    textAlign={"center"}
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <HStack alignItems="center" justifyContent="center">
                      <Icon
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="50%"
                        borderStyle={"solid"}
                        borderWidth={"1px"}
                        borderColor={"borders.tableBorder"}
                        bg={"#fff"}
                        w="25px"
                        h="25px"
                        px="5px"
                        py="5px"
                        as={MdOutlineRemoveRedEye}
                        fontSize="22px"
                        color="#6B7280"
                        _hover={{
                          color: "#3454d1",
                        }}
                        onClick={() => {
                          item?.status === "DRAFT"
                            ? navigate(`/app/payroll/PayRunDetail/${item.id}`, {
                                state: {
                                  payPeriod: item?.payrollCalendar?.payPeriod,
                                  nextPayDate: moment(item?.paymentDate).format(
                                    "D MMM YYYY"
                                  ),
                                  endDate: item?.endDate,
                                  calenderName: item?.payrollCalendar?.name,
                                  status: item?.status,
                                },
                              })
                            : navigate(`/app/payroll/postedPayRun/${item.id}`, {
                                state: {
                                  nextPayDate: moment(item?.paymentDate).format(
                                    "D MMM YYYY"
                                  ),
                                  endDate: item?.endDate,
                                  calenderName: item?.payrollCalendar?.name,
                                  status: item?.status,
                                  payPeriod: item?.payrollCalendar?.payPeriod,
                                },
                              });
                        }}
                      />
                    </HStack>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      <Stack borderTop={"1px solid #dcdee4 "}>
        <HStack
          marginTop={"9.35px"}
          padding={"25px"}
          fontSize={"13px"}
          _light={{
            color: "customColor.black2",
          }}
          _dark={{
            color: "customColor.white",
          }}
          justifyContent={"space-between"}
        >
          <Text
            fontWeight={"normal"}
            color={"customColor.6b7885"}
            _dark={{
              color: "customColor.dark.200",
            }}
            fontSize={"11px"}
          >
            Showing {pageLimit * (pageNumber - 1) + 1} to{" "}
            {pageLimit + pageLimit * (pageNumber - 1)} of {PayRunData?.length}{" "}
            entries
          </Text>
          <HStack spacing="13px" justify={{ xs: "center", md: "end" }}>
            <IconButton
              aria-label="back"
              border={"1px solid #e8eaf2"}
              borderRadius={"full"}
              backgroundColor="transparent"
              _hover={{
                backgroundColor: "#3454D1",
                color: "white",
              }}
              onMouseEnter={() => setIsHovered2(true)}
              onMouseLeave={() => setIsHovered2(false)}
              icon={
                <FaArrowLeft
                  size={15}
                  color={
                    isHovered2
                      ? "#FFFFFF"
                      : pageNumber <= 1 || pageNumber > pageCount
                      ? "#828ea0"
                      : "#283c50"
                  }
                />
              }
              isDisabled={pageNumber <= 1 || pageNumber > pageCount}
              onClick={() => {
                if (pageNumber > 1 && pageNumber <= pageCount) {
                  pageNumberInputRef.current?.value &&
                    (pageNumberInputRef.current.value = String(pageNumber - 1));
                  setPageNumber(pageNumber - 1);
                }
              }}
            />
            <HStack spacing="13px">{renderPageNumbers()}</HStack>
            <IconButton
              aria-label="right"
              backgroundColor="transparent"
              border={"1px solid #e8eaf2"}
              borderRadius={"full"}
              _hover={{
                backgroundColor: "#3454D1",
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              icon={
                <FaArrowRight
                  size={15}
                  color={
                    isHovered
                      ? "#FFFFFF"
                      : pageNumber >= pageCount || pageNumber < 1
                      ? "#828ea0"
                      : "#283c50"
                  }
                  style={{ transition: "color 0.2s" }} // Add smooth transition for color change
                />
              }
              isDisabled={pageNumber >= pageCount || pageNumber < 1}
              onClick={() => {
                if (pageNumber < pageCount && pageNumber >= 1) {
                  pageNumberInputRef.current?.value &&
                    (pageNumberInputRef.current.value = String(pageNumber + 1));
                  setPageNumber(pageNumber + 1);
                }
              }}
            />
          </HStack>
        </HStack>
      </Stack>
    </Stack>
  );
}
