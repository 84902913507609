import {useDisclosure, UseDisclosureReturn} from '@chakra-ui/react';
import React, {useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {MCQ} from '../../../modules/Audit/MCQs';
import DocumentNumberFormatDrawer from '../components/Drawers/DocumentNumberFormatDrawer';
import MultipleChoiceResponse from '../components/Drawers/MultipleChoiceResponse';
import SliderDrawer from '../components/Drawers/SliderDrawer';
import TableDrawer from '../components/Drawers/TableDrawer';
import TemperatureDrawer from '../components/Drawers/TemperatureDrawer';
import DocumentsUploadModal from '../components/Modals/DocumentsUploadModal';
import PreviewModal from '../components/Preview.modal';

const QuestionItemINITDetails = {
  isItemSection: false,
  pageIndex: 0,
  questionIndex: 0,
  sectionIndex: 0,
};
type QuestionItemDetails = {
  isItemSection?: boolean;
  pageIndex: number;
  sectionIndex?: number;
  questionIndex?: number;
};
interface DrawerContextProps {
  MultipleChoiceResponseModal?: UseDisclosureReturn;
  TemperatureDrawer?: UseDisclosureReturn;
  SliderDrawer?: UseDisclosureReturn;
  TableDrawer?: UseDisclosureReturn;
  DocumentsModal?: UseDisclosureReturn;
  DocNumberDrawer?: UseDisclosureReturn;
  PreviewModalOperations?: UseDisclosureReturn;
  multiChoiceOptions: MCQ.IMCQ;
  setMultiChoiceOptions: (
    multiChoiceOptions: MCQ.IMCQ,
    openDrawer: boolean,
  ) => void;
  questionItemDetails?: QuestionItemDetails;
  setQuestionItemDetails: React.Dispatch<
    React.SetStateAction<QuestionItemDetails | undefined>
  >;
}

const Options_INIT: MCQ.IMCQ = {
  options: [{color: '', value: '', flagged: false, orderIndex: 0}],
  isGlobal: false,
  isScoringEnabled: false,
};
export const DrawerContext = React.createContext<DrawerContextProps>({
  multiChoiceOptions: Options_INIT,
  setMultiChoiceOptions: () => {},
  questionItemDetails: QuestionItemINITDetails,
  setQuestionItemDetails: () => {},
});

export function DrawerProvider({children}: any) {
  const template = useFormContext();
  const [questionItemDetails, setQuestionItemDetails] = useState<
    QuestionItemDetails | undefined
  >(QuestionItemINITDetails);

  // Multi Choice
  const MultipleChoiceResponseModal = useDisclosure({
    onClose: () => {
      setQuestionItemDetails(undefined);
      setMultiChoiceOptionsState(Options_INIT);
    },
  });
  const [multiChoiceOptions, setMultiChoiceOptionsState] =
    useState<MCQ.IMCQ>(Options_INIT);

  function setMultiChoiceOptions(
    multiChoiceOptions: MCQ.IMCQ,
    openDrawer: boolean,
  ) {
    setMultiChoiceOptionsState(multiChoiceOptions);
    if (openDrawer) {
      MultipleChoiceResponseModal.onOpen();
    }
  }

  // Question Item Details

  // PreviewModal
  const PreviewModalOperations = useDisclosure();

  // Temperature Drawer
  const TemperatureDrawerOps = useDisclosure();

  // Temperature Drawer
  const SliderDrawerOps = useDisclosure();

  // Document Number Format Drawer
  const DocNumberDrawerOps = useDisclosure();
  const TableDrawerops = useDisclosure();
  const DocumentsModalops = useDisclosure();

  return (
    <DrawerContext.Provider
      value={{
        MultipleChoiceResponseModal,
        multiChoiceOptions,
        setMultiChoiceOptions,
        questionItemDetails,
        setQuestionItemDetails,
        PreviewModalOperations,
        TemperatureDrawer: TemperatureDrawerOps,
        SliderDrawer: SliderDrawerOps,
        DocNumberDrawer: DocNumberDrawerOps,
        TableDrawer: TableDrawerops,
        DocumentsModal: DocumentsModalops,
      }}>
      {children}
      <MultipleChoiceResponse
        key={`MultipleChoiceResponse-drawer-${questionItemDetails?.pageIndex}-${questionItemDetails?.sectionIndex}-${questionItemDetails?.questionIndex}`}
        isOpen={MultipleChoiceResponseModal.isOpen}
        onClose={MultipleChoiceResponseModal.onClose}
      />

      <PreviewModal
        onClose={PreviewModalOperations.onClose}
        isOpen={PreviewModalOperations.isOpen}
        size="full"
        placement="top"
      />
      <TemperatureDrawer
        isOpen={TemperatureDrawerOps.isOpen}
        onClose={TemperatureDrawerOps.onClose}
        key={`Temperature-drawer-${questionItemDetails?.pageIndex}-${questionItemDetails?.sectionIndex}-${questionItemDetails?.questionIndex}`}
      />
      <SliderDrawer
        isOpen={SliderDrawerOps.isOpen}
        onClose={SliderDrawerOps.onClose}
        key={`Slider-drawer-${questionItemDetails?.pageIndex}-${questionItemDetails?.sectionIndex}-${questionItemDetails?.questionIndex}`}
      />
      <DocumentNumberFormatDrawer
        isOpen={DocNumberDrawerOps.isOpen}
        onClose={DocNumberDrawerOps.onClose}
        key={`Doc-number-drawer-${questionItemDetails?.pageIndex}-${questionItemDetails?.sectionIndex}-${questionItemDetails?.questionIndex}`}
      />
      <TableDrawer
        isOpen={TableDrawerops.isOpen}
        onClose={TableDrawerops.onClose}
        key={`Table-drawer-${questionItemDetails?.pageIndex}-${questionItemDetails?.sectionIndex}-${questionItemDetails?.questionIndex}`}
      />
      <DocumentsUploadModal
        isOpen={DocumentsModalops.isOpen}
        onClose={DocumentsModalops.onClose}
        key={`Documents-modal-${questionItemDetails?.pageIndex}-${questionItemDetails?.sectionIndex}-${questionItemDetails?.questionIndex}`}
      />
    </DrawerContext.Provider>
  );
}

export default function useDrawer() {
  return React.useContext<DrawerContextProps>(DrawerContext);
}
