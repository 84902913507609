import {Text, TextProps} from '@chakra-ui/react';

export function SubHeading({children, ...props}: TextProps) {
  return (
    <Text
      color="textcolors.primary"
      fontSize="16px"
      fontWeight={500}
      lineHeight="1.5rem"
      {...props}>
      {children}
    </Text>
  );
}
export default SubHeading;
