import { axiosInstance } from "./axiosInstance-hris";

declare module AddInvoices {
  type UpdateInvoiceDate = {
    issueDate: string;
    dueDate: string;
    reference: string;
    paymentMethods: string[];
    currency: string;
    amountType: string;

    contactId: string;
  };

  type FetchInvoices = {
    id: string;
    createdAt: string;
    updatedAt: string;
    issueDate: string;
    dueDate: string;
    invoiceNumber: string;
    reference: string;
    paymentMethods: [];
    currency: string;
    totalAmount: number;
    dueAmount: number;
    paidAmount: number;
    amountType: string;
    status: string;
  };
  type FetchInvoicesByIdData = {
    id: string;
    createdAt: string;
    updatedAt: string;
    issueDate: string;
    dueDate: string;
    invoiceNumber: string;
    reference: string;
    paymentMethods: string[];
    currency: string;
    amountType: string;
    status: string;
    items: string[];
    contact: {
      id: string;
      createdAt: string;
      updatedAt: string;
      name: string;
      accountNumber: string;
      isActive: string;
    };
  };
  type NewInvoiceData = {
    id?: string;
    status?: string;
    invoiceNumber: string;
    items: Array<invoiceComponent>;
    issueDate: string;
    dueDate: string;
    reference: string;
    paymentMethods: string[];
    currency: string;
    amountType: string;
    contactId: string;
    taxableAmount: number;
    totalAmount: number;
    notes?: string;
    autoTaxes: Array<{
      taxRateId: string;
      taxAmount: number;
      isManual: boolean;
    }>;
    manualTaxes: Array<{
      taxRateId: string;
      taxAmount: number;
      isManual: boolean;
    }>;
  };
  type invoiceComponent = {
    id?: string | undefined;
    itemId: string | undefined;
    description: string | undefined;
    quantity: number | undefined;
    price: number | undefined;
    accountId: string | undefined;
    taxRateId: string | undefined;
    discount: number | undefined;
    amount: number | undefined;
    taxAmount: number | undefined;
  };
  type PaymentData = {
    id?: string;
    amountPaid: number;
    datePaid: string;
    accountId: string;
    reference: string;
  };
  type HistoryLog = {
    id?: string;
    date: string;
    userId: string;
    action: string;
    detail: string;
  };
}
module AddInvoices {
  const API_URL = "/invoices";
  export function PostHistoryLogs(
    id: string,
    data: HistoryLog,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/${id}/history-logs`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function UploadMediaFiles(
    id: string,
    data: FormData,
    success: (response: { url: string }) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/${id}/media-files`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchMediaFiles(
    id: string,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${id}/media-files`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function DeleteMediaFiles(
    id: string,
    media_file_id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}/media-files/${media_file_id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function GetDueAmount(
    id: string,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${id}/due-amount`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function FetchHistoryLogs(
    id: string,
    success: (response: Array<HistoryLog>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${id}/history-logs`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function PostPayment(
    id: string,
    data: PaymentData,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/${id}/payments`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function FetchPayment(
    id: string,
    success: (response: Array<PaymentData>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${id}/payments`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function PostInvoices(
    data: NewInvoiceData,
    success: (data: NewInvoiceData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then((response) => {
        success(response.data.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function PostItemInInvoices(
    data: invoiceComponent,
    id: string,
    success: (data: invoiceComponent) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/${id}/invoice-items`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function UpdateItemInInvoices(
    data: Partial<invoiceComponent>,
    id: string,
    item_id: string,
    success: (data: invoiceComponent) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .put(`${API_URL}/${id}/invoice-items/${item_id}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function SubmitForApproval(
    id: string,

    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .put(`${API_URL}/${id}/submit-for-approval`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function Approval(
    id: string,

    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .put(`${API_URL}/${id}/approve`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function DeleteInvoices(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function DeleteItemsInInvoices(
    id: string,
    item_id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}/invoice-items/${item_id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function FetchInvoicesDetails(
    params: {
      limit?: number;
      page?: number;
    },
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`, { params })
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function UpdateInvoice(
    id: string,
    data: Partial<UpdateInvoiceDate>,
    success: (data: UpdateInvoiceDate) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}`, data)
      .then((response) => {
        success(response.data.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function FetchInvoiceDetailsById(
    id: string,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/by-id/${id}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function FetchInvoiceItemDetailsById(
    id: string,
    success: (response: invoiceComponent) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${id}/invoice-items`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function GenerateUniqueNumber(
    success: (response: any) => void,
    error: (message: string) => void,
    invoiceData: any = {}
  ) {
    axiosInstance
      .get(`${API_URL}/generate-unique-number`, invoiceData)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        const errorMessage =
          err.response?.data?.message &&
          typeof err.response.data.message === "string"
            ? err.response.data.message
            : Array.isArray(err.response?.data?.message)
            ? err.response.data.message.join(", ")
            : err.message ?? "An unknown error occurred";

        error(errorMessage);
      });
  }
}
export { AddInvoices };
