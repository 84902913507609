import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { CustomInput } from "../../../Common/CustomInput";
import { Correspondence } from "../../Api/Correspondence";

interface InsuranceModalProps {
  isOpen: boolean;
  onClose: () => void;

  setLastUpdated: (time: number) => void;
  // insuranceType?: Array<{ label: string; value: string }>;
}
export default function InsuranceModal({
  isOpen,
  onClose,
  setLastUpdated,
}: InsuranceModalProps) {
  const CoverageOptions: Array<{ label: string; value: string }> = [
    {
      label: "Single",
      value: "SINGLE",
    },

    {
      label: "Spouse,Children",
      value: "MARRIED_WITH_CHILDREN",
    },
    {
      label: "Parent",
      value: "WITH_PARENTS",
    },
  ];
  const form = useFormContext<Correspondence.AddCorrespondence>();
  const toast = useToast();

  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Correspondence
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Flex justifyContent="space-between" mt="8px">
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Name
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  name="name"
                  rules={{
                    pattern: {
                      value: /^[A-Za-z ]+$/,
                      message: "Only alphabetic characters are allowed",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <CustomInput
                      ref={field.ref}
                      // withValidation
                      // validationMessage="This field is required"
                      input={{
                        ...field,
                        w: "100%",
                        isInvalid: fieldState.invalid,
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",
                        // color: "#ccc",

                        lineHeight: "1.2",
                        h: "36px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        type: "text",

                        borderRadius: "4px",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>
            <Flex justifyContent="space-between" mt="8px">
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Position
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  name="position"
                  // rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <CustomInput
                      ref={field.ref}
                      // withValidation
                      // validationMessage="This field is required"
                      input={{
                        ...field,
                        w: "100%",
                        // isInvalid: fieldState.invalid,
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",
                        // color: "#ccc",

                        lineHeight: "1.2",
                        h: "36px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        type: "text",

                        borderRadius: "4px",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>
            <Flex justifyContent="space-between" mt="8px">
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Email
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  name="email"
                  rules={{
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <CustomInput
                      ref={field.ref}
                      withValidation
                      validationMessage="This field is required"
                      input={{
                        ...field,
                        w: "100%",
                        isInvalid: fieldState.invalid,
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",
                        // color: "#ccc",

                        lineHeight: "1.2",
                        h: "36px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        type: "email",

                        borderRadius: "4px",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>
            <Flex justifyContent="space-between" mt="8px">
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Phone
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  name="phone"
                  rules={{
                    required: {
                      value: true,
                      message: "Phone is required",
                    },
                    pattern: {
                      value: /^[0-9]+$/, // Only numeric characters are allowed
                      message: "Only numeric characters are allowed",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <CustomInput
                      ref={field.ref}
                      withValidation
                      validationMessage={
                        fieldState.error?.message || "This field is required"
                      }
                      input={{
                        ...field,
                        w: "100%",
                        isInvalid: fieldState.invalid,
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",
                        lineHeight: "1.2",
                        h: "36px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        value: field.value ?? "",
                        type: "text", // Keep it as text to prevent issues with leading zeros
                        inputMode: "numeric", // For numeric keypad on mobile
                        borderRadius: "4px",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>
            <Flex justifyContent="space-between" mt="8px">
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Department
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  name="department"
                  // rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <CustomInput
                      ref={field.ref}
                      // withValidation
                      // validationMessage="This field is required"
                      input={{
                        ...field,
                        w: "100%",
                        // isInvalid: fieldState.invalid,
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",
                        // color: "#ccc",

                        lineHeight: "1.2",
                        h: "36px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        type: "text",

                        borderRadius: "4px",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"8px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            isLoading={isLoading}
            onClick={(e) => {
              form.handleSubmit((data) => {
                setIsLoading(true);
                if (form.getValues("id")) {
                  Correspondence.UpdateCorrespondence(
                    form.getValues("id")!,
                    data,
                    () => {
                      setLastUpdated(Date.now());
                      onClose();
                      toast({
                        title: "Correspondence Updated",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                      });
                      setIsLoading(false);
                    },
                    (error) => {
                      toast({
                        title: error,
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                      setIsLoading(false);
                    }
                  );
                } else {
                  Correspondence.PostCorrespondence(
                    data,
                    (data) => {
                      setLastUpdated(Date.now());
                      onClose();
                      toast({
                        title: "Correspondence Added",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                      });
                      setIsLoading(false);
                    },
                    (err) => {
                      toast({
                        title: err,
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                      setIsLoading(false);
                    }
                  );
                }
              })(e);
            }}
          >
            {isLoading ? "Saving" : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
