import { axiosInstance } from "./axiosInstance-hris";
import { PayTemp } from "./PayTemp";
declare module PayRun {
  type PayRun = {
    id: string;
    isPosted: boolean;
    startDate: string;
    endDate: string;
    paymentDate: string;
    type?: string;
    payrollCalendar: {
      id: string;
      payPeriod: string;
      name: string;
    };
    totalEarnings: number;
    totalDeductions: number;
    totalTaxes: number;
    totalRetirements: number;
    totalReimbursements: number;
    status: string;
  };

  type PayRunById = {
    id: string;
    employeeId: string;
    payRunId: string;
    paymentDate: string;
    isIncludeInPayRun: boolean;
    totalEarnings: number;
    totalDeductions: number;
    totalTax: number;
    totalLeaves: number;
    totalReimbursements: number;
    totalRetirements: number;
    employee: {
      firstName: string;
      lastName: string;
    };
  };
  type PayPeriodByCalenderID = {
    startDate: string;
    endDate: string;
    string: string;
  };

  type CreatePayrun = {
    payPeriod: PayPeriod;
  };
  type PayPeriod = {
    type: string;
    startDate?: string;
    endDate?: string;
    string?: string;
    payrollCalendarId: string;
    taxFinancialYearId: string;
  };

  type PayrunByEmpId = {
    id?: string;
    earnings: Array<PayrunItem>;
    deductions: Array<PayrunItem>;
    reimbursments: Array<PayrunItem>;
    retirements: Array<PayrunItem>;
    taxes: Array<PayrunItem>;
    leaves: Array<PayrunItem>;
    paymentDate: string;
    bankDetails: {
      id: string;
      empId: string;
      accountName: string;
      accountNumber: string;
      bankName: string;
      branchCode: string;
      iban: string;
    };
    totalEarnings?: number;
    totalDeductions?: number;
    totalTax?: number;
    totalLeaves?: number;
    totalReimbursements?: number;
    totalRetirements?: number;
    isSocialSecurityRequired: boolean;
    note: string;
    employee: {
      id: string;
      firstName: string;
      lastName: string;
    };
    payRun: {
      endDate: string;
      payrollCalendar: {
        payPeriod: string;
        name: string;
      };
    };
  };

  // Created by Saoud

  interface PayrunItem {
    id?: string;
    payRunId: string;
    employeeId: string;
    calculationType: PayTemp.PayTemplateCalcTypeEnum;
    hours?: number;
    rate?: number;
    amount?: number;
    description?: string | null;
    percentage?: number;
    category: PayTemp.PayTemplateItemTypeEnum;
    payItemEarningId?: string;
    payItemDeductionId?: string;
    payItemReimbursementId?: string;
    payItemLeaveId?: string;
    payItemRetirementId?: string;
    isTaxable?: boolean;
    minimumWage?: number;
    payItemRetirement?: PayRunItemPayItem;
    payItemDeduction?: PayRunItemPayItem;
    payItemReimbursement?: PayRunItemPayItem;
    payItemLeave?: PayRunItemPayItem;
    payItemEarning?: {
      id?: string;
      name?: string;
      rateType?: string;
      isTaxable?: boolean;
      baseSalaryPercentage?: number | null;
      allowancesPercentage: number | null;
      percentage?: number | null;
    };
    payslipId: string;
    timesheetId?: string;
  }
  type payRunData = {
    payslips: Array<PayRunById>;
    paymentDate: string;
    endDate: string;
    note: string;
    payrollCalendar: {
      payPeriod: string;
      name: string;
    };
    status: string;
  };
  type PayRunItemPayItem = {
    id?: string;
    minimumWage?: number | null;
    earningPayItems?: Array<string>;
    isTaxable?: boolean;
    name?: string;
  };
  type unscheduledPayPeriodsPayload = {
    payrollCalendarId: string;
    businessFYStartDate: string;
  };
}
module PayRun {
  const API_URL = "/pay-run";
  export function CreatePayRun(
    data: PayPeriod,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function FetchPayRun(
    params: {
      status: "POSTED" | "DRAFT" | "FINALISED";
      page?: number;
      limit?: number;
    },
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`, { params })
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function FetchPayRunById(
    id: string,
    success: (response: payRunData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${id}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function FetchPayPeriodByCalenderID(
    payrollCalendarId: string,
    success: (response: Array<PayPeriodByCalenderID>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/payperiod/${payrollCalendarId}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function getPayItemByEmpId(
    payslipId: string,
    success: (response: PayrunByEmpId) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/payrun/${payslipId}/items`)
      .then((response) => {
        success(response?.data);
      })
      .catch((err) => {
        error(err?.response?.data?.message);
      });
  }
  // payrun/:payslip/items/:payrunId
  export function UpdatePayItemByEmpId(
    payslipId: string,
    payrunId: string,
    data: PayrunByEmpId,
    success: (response: PayrunByEmpId) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/payrun/${payslipId}/items/${payrunId}`, data)
      .then((response) => {
        success(response?.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function FetchPayPeriod(
    success: (response: Array<PayPeriod>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/payperiods/all`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function FetchFnFPayPeriod(
    data: unscheduledPayPeriodsPayload,
    success: (response: Array<PayPeriod>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/fnfpayperiods`, data)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function UnscheduledPayPeriod(
    data: unscheduledPayPeriodsPayload,
    success: (response: Array<PayPeriod>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/unscheduled-payperiods`, data)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function DeletePayRun(
    id: string,
    success: (response: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function ResetPayRun(
    id: string,
    success: (response: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/resetpayrun/${id}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function ResetPaySlip(
    payload: {
      payRunId: string;
      employeeId: string;
      payslipId: string;
    },
    success: (message: PayrunByEmpId) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/pay-run-items/resetPaySlip`, payload)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(err?.response?.data?.message);
      });
  }
  export function PostPayrun(
    payRunId: string,
    success: (response: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/post/${payRunId}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function UpdateNotes(
    payRunId: string,
    data: { note: string },
    success: (response: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/notes/${payRunId}`, data)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function ReverttoDraft(
    payRunId: string,
    success: (response: { message: string; status: string }) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/draft/${payRunId}`)
      .then((response) => {
        success(response.data?.status);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function changePaymentDate(
    payRunId: string,
    data: { paymentDate: string },
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/paymentDate/${payRunId}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}

export { PayRun };
