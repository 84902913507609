import {
  AbsoluteCenter,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  Spinner,
  Stack,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { AccessControl } from "../../../../api/AccessControl";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { NotificationSettingModule } from "../../../API/NotificationSettingModule";
interface NotificationProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (timstamp: number) => void;
  notificationId?: string;
}
export default function NotificationDrawer({
  isOpen,
  onClose,
  setLastUpdated,
  notificationId,
}: NotificationProps) {
  const deleteModal = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);

  const toast = useToast({
    position: "top",
  });
  const [allActiveUsers, setAllActiveUsers] = useState<any>([]);

  useEffect(() => {
    AccessControl.FetchActiveUsers(
      (data) => {
        setAllActiveUsers(data);
        setIsLoading(false);
      },
      (err) => {
        toast({
          title: "Error",
          description: err,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, []);

  const form = useForm<NotificationSettingModule.notificationData>({
    defaultValues: {
      usersToNotify: undefined,
      eventType: "Shift_Created",
      id: undefined,
      isActive: true,
      interval: undefined,
      isImmediate: false,
      types: [],
    },
  });
  useEffect(() => {
    if (isOpen) {
      form.reset({
        usersToNotify: undefined,
        eventType: "Shift_Created",
        id: undefined,
        isActive: true,
        interval: undefined,
        isImmediate: false,
        types: [],
      });
    }
  }, [isOpen]);
  const eventTypeWatch = useWatch({
    control: form.control,
    name: "eventType",
  });
  const eventType = [
    {
      label: "Start shift reminder",
      value: "Shift_Start_Reminder",
    },
    {
      label: "End shift reminder",
      value: "Shift_End_Reminder",
    },
    {
      label: "Shift Transfer",
      value: "Shift_Transfer",
    },
    {
      label: "Shift Created",
      value: "Shift_Created",
    },
    {
      label: "Shift Updated",
      value: "Shift_Updated",
    },
    {
      label: "Shift Deleted",
      value: "Shift_Deleted",
    },
  ];

  const noticationType = [
    {
      label: "SMS",
      value: "SMS",
    },
    {
      label: "Email",
      value: "Email",
    },
    {
      label: "Push",
      value: "Push",
    },
  ];

  const remider = [
    {
      label: "15 minutes before",
      value: 15,
    },
    {
      label: "30 minutes before",
      value: 30,
    },
    {
      label: "45 minutes before",
      value: 45,
    },
    {
      label: "1 hour before",
      value: 60,
    },
    {
      label: "2 hour before",
      value: 120,
    },
    {
      label: "3 hour before",
      value: 180,
    },
    {
      label: "4 hour before",
      value: 240,
    },
    {
      label: "5 hour before",
      value: 300,
    },
  ];

  useEffect(() => {
    if (notificationId) {
      setIsLoading(true);
      NotificationSettingModule.FetchById(
        notificationId,
        (res) => {
          form.reset(res);
          setIsLoading(false);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
          setIsLoading(false);
        }
      );
    }
  }, [notificationId]);

  if (notificationId && isLoading) {
    return (
      <AbsoluteCenter>
        <Spinner size="lg" color="secondary.500" />
      </AbsoluteCenter>
    );
  }
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={() => {
        onClose();
        form.reset();
      }}
    >
      <DrawerOverlay />
      <DrawerContent maxW="35vw">
        <DrawerCloseButton />
        <DrawerHeader>
          <Heading
            fontSize={"18px"}
            textAlign={"left"}
            _light={{
              color: "customColor.black7",
            }}
          >
            {notificationId ? "Update" : "Create"} Notification
          </Heading>
        </DrawerHeader>
        <DrawerBody>
          <Stack spacing="16px">
            {/* <Stack>
            <Text
              fontSize=".845rem"
              fontWeight="600"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              When to Notify?
            </Text>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Notify is required",
                },
              }}
              control={control}
              name="notify"
              render={({ field, fieldState: { invalid } }) => (
                <ReactSelect
                  withValidation
                  ErrorMessage="Please Select When to Notify"
                  isInvalid={invalid}
                  options={notifyTime}
                  // label="When to Nofity?"
                  // labelProps={{
                  //   fontSize: "15px",
                  //   fontWeight: 500,
                  //   color: "#373857",
                  // }}
                  {...field}
                  value={
                    notifyTime?.find(
                      (option) => option.value === field.value
                    ) as any
                  }
                  onChange={(val: any) => {
                    field.onChange(val.value);
                  }}
                />
              )}
            />
          </Stack> */}

            {/* <Controller
              control={control}
              name="title"
              render={() => (
                <Stack>
                  <Text
                    fontSize=".845rem"
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Title
                  </Text>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Message is required",
                      },
                    }}
                    control={control}
                    name="title"
                    render={({ field, fieldState: { invalid } }) => (
                      <Input
                        placeholder="Name of the Notification"
                        border="1px solid"
                        borderColor="borders.tableBorder"
                        borderRadius={"3px"}
                        fontSize=".845rem"
                        fontWeight={400}
                        _light={{
                          color: "customColor.black7",
                        }}
                        padding="12px 15px"
                        _focus={{
                          boxShadow: "none",
                          borderColor: "#3454d1",
                        }}
                        _focusVisible={{
                          boxShadow: "none",
                          border: "1px solid",
                          borderColor: "#3454d1",
                        }}
                        isInvalid={invalid}
                        {...field}
                      />
                    )}
                  />
                </Stack>
              )}
            /> */}
            <Stack>
              <Text
                fontSize=".845rem"
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                When to Notify?
              </Text>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Notify is required",
                  },
                }}
                control={form.control}
                name="eventType"
                render={({ field, fieldState: { invalid } }) => (
                  <ReactSelect
                    {...field}
                    withValidation
                    ErrorMessage="Please Select When to Notify"
                    isInvalid={invalid}
                    options={eventType}
                    value={eventType.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(val: any) => {
                      form.setValue("interval", undefined);
                      form.setValue("isActive", true);
                      form.setValue("types", []);
                      form.setValue("usersToNotify", undefined);
                      field.onChange(val.value);
                      if (
                        val.value === "Shift_Start_Reminder" ||
                        val.value === "Shift_End_Reminder"
                      ) {
                        form.setValue("interval", 15);
                      }
                    }}
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                )}
              />
            </Stack>
            {eventTypeWatch === "Shift_Transfer" && (
              <Stack>
                <Text
                  fontSize=".845rem"
                  fontWeight="600"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Who to Notify?
                </Text>

                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Users are required ",
                    },
                  }}
                  control={form.control}
                  name="usersToNotify"
                  render={({ field, fieldState }) => {
                    return (
                      <ReactSelect
                        {...field}
                        isMulti
                        withValidation
                        isInvalid={fieldState.invalid}
                        ErrorMessage="Please Select Users"
                        placeholder="Select Users"
                        options={allActiveUsers}
                        getOptionLabel={(option: any) =>
                          option?.user?.firstName + " " + option?.user?.lastName
                        }
                        getOptionValue={(option: any) => option.id}
                        value={
                          field.value
                            ? allActiveUsers?.filter((user: any) =>
                                field.value?.includes(user.id)
                              )
                            : undefined
                        }
                        onChange={(val: any) => {
                          const options = val as unknown as Array<any>;
                          field.onChange(options.map((user) => user.id));
                        }}
                        // label="Employee"
                      />
                    );
                  }}
                />
              </Stack>
            )}
            {/* <Stack>
              <Text
                fontSize=".845rem"
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Who to Notify?
              </Text>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Notify is required",
                  },
                }}
                control={control}
                name="notify"
                render={({ field, fieldState: { invalid } }) => (
                  <ReactSelect
                    withValidation
                    ErrorMessage="Please Select When to Notify"
                    isInvalid={invalid}
                    options={notifyTime}
                    // label="When to Nofity?"
                    // labelProps={{
                    //   fontSize: "15px",
                    //   fontWeight: 500,
                    //   color: "#373857",
                    // }}
                    {...field}
                    value={
                      notifyTime?.find(
                        (option) => option.value === field.value
                      ) as any
                    }
                    onChange={(val: any) => {
                      field.onChange(val.value);
                    }}
                  />
                )}
              />
            </Stack> */}
            {/* <Stack>
              <Text
                fontSize=".845rem"
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Location
              </Text>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Location is required",
                  },
                }}
                control={control}
                name="locations"
                render={({ field, fieldState }) => {
                  return (
                    <ReactSelect
                      {...field}
                      isMulti
                      withValidation
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Please Select Location"
                      placeholder="Select Location"
                      options={locations}
                      value={
                        field.value
                          ? locations?.filter((location) =>
                              field.value.includes(location.id)
                            )
                          : undefined
                      }
                      onChange={(val: any) => {
                        const options = val as unknown as Array<any>;
                        field.onChange(options.map((location) => location.id));
                      }}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                      // label="Location"
                    />
                  );
                }}
              />
            </Stack> */}
            {(eventTypeWatch === "Shift_Start_Reminder" ||
              eventTypeWatch === "Shift_End_Reminder") && (
              <Stack>
                <Text
                  fontSize=".845rem"
                  fontWeight="600"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Reminder
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Reminder is required",
                    },
                  }}
                  control={form.control}
                  name="interval"
                  render={({ field, fieldState: { invalid } }) => (
                    <ReactSelect
                      {...field}
                      withValidation
                      isInvalid={invalid}
                      ErrorMessage="Please Select Reminder Time"
                      placeholder="Select Reminder Time"
                      // label="Reminder"
                      options={remider}
                      value={
                        remider?.find(
                          (option) => option.value === field.value
                        ) as any
                      }
                      onChange={(val: any) => {
                        field.onChange(val.value);
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </Stack>
            )}
            <Stack>
              <Text
                fontSize=".845rem"
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Notification Type/s
              </Text>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Notication Type/s is required",
                  },
                }}
                control={form.control}
                name="types"
                render={({ field, fieldState }) => {
                  return (
                    <ReactSelect
                      {...field}
                      isMulti
                      withValidation
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Please Select Notification Type/s"
                      placeholder="Select Notification Type/s"
                      options={noticationType}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                      value={
                        field.value
                          ? noticationType?.filter((type) =>
                              field.value.includes(type.value)
                            )
                          : undefined
                      }
                      onChange={(val: any) => {
                        const options = val as unknown as Array<any>;
                        field.onChange(options.map((type) => type.value));
                      }}
                      // label="Employee"
                    />
                  );
                }}
              />
            </Stack>
            {/* <Stack>
              <Text
                fontSize=".845rem"
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Message
              </Text>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Message is required",
                  },
                }}
                control={control}
                name="message"
                render={({ field, fieldState: { invalid } }) => (
                  <Textarea
                    placeholder="Write a message for employee"
                    fontSize=".845rem"
                    fontWeight={400}
                    border="1px solid #e5e7eb"
                    _hover={{
                      border: "1px solid hsl(0, 0%, 70%)",
                    }}
                    _focus={{
                      borderWidth: "1px",

                      boxShadow: "0 0 0 1px #2684FF",
                      _hover: {
                        borderWidth: "1px",
                        borderColor: "#2684FF",
                        boxShadow: "0 0 0 1px #2684FF",
                      },
                    }}
                    _focusVisible={{
                      borderWidth: "1px",
                      boxShadow: "0 0 0 1px #2684FF",
                      _hover: {
                        borderWidth: "1px",
                        borderColor: "#2684FF",
                        boxShadow: "0 0 0 1px #2684FF",
                      },
                    }}
                    _light={{
                      color: "customColor.black7",
                    }}
                    padding="12px 15px"
                    isInvalid={invalid}
                    {...field}
                  />
                )}
              />
            </Stack> */}
            {/* <VStack w="full">
              <HStack justifyContent="space-between" w="full">
                <Text
                  fontSize=".845rem"
                  fontWeight="600"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Push Notification
                </Text>
                <Controller
                  control={control}
                  name="push"
                  render={({ field }) => (
                    <Switch
                      size="md"
                      {...field}
                      value={undefined}
                      isChecked={field.value}
                    />
                  )}
                />
              </HStack>
              <HStack justifyContent="space-between" w="full">
                <Text
                  fontSize=".845rem"
                  fontWeight="600"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  SMS
                </Text>
                <Controller
                  control={control}
                  name="sms"
                  render={({ field }) => (
                    <Switch
                      size="md"
                      {...field}
                      value={undefined}
                      isChecked={field.value}
                    />
                  )}
                />
              </HStack>
              <HStack justifyContent="space-between" w="full">
                <Text
                  fontSize=".845rem"
                  fontWeight="600"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Email
                </Text>
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <Switch
                      size="md"
                      {...field}
                      value={undefined}
                      isChecked={field.value}
                    />
                  )}
                />
              </HStack>
            </VStack> */}
            <HStack justifyContent="space-between">
              <Text
                fontSize=".845rem"
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Turn on this notification
              </Text>
              <Controller
                control={form.control}
                name="isActive"
                render={({ field }) => (
                  <Switch
                    size="md"
                    {...field}
                    value={undefined}
                    isChecked={field.value}
                  />
                )}
              />
            </HStack>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <HStack paddingTop={"10px"} paddingBottom={"10px"}>
            <ButtonGroup variant="outline" spacing="3">
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                borderRadius={"3px"}
                h={"40px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                type="submit"
                onClick={form.handleSubmit(
                  (data) => {
                    const PayloadData = {
                      ...data,
                      isImmediate:
                        data.eventType === "Shift_End_Reminder" ||
                        data.eventType === "Shift_Start_Reminder"
                          ? false
                          : true,
                      interval:
                        data.eventType === "Shift_End_Reminder" ||
                        data.eventType === "Shift_Start_Reminder"
                          ? data.interval
                          : undefined,
                      usersToNotify:
                        data.eventType === "Shift_Transfer"
                          ? data.usersToNotify
                          : undefined,
                    };
                    if (notificationId) {
                      NotificationSettingModule.Update(
                        notificationId,
                        PayloadData,
                        (res) => {
                          setLastUpdated(Date.now());
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Error",
                            description: error,
                            status: "error",
                          });
                        }
                      );
                    } else {
                      NotificationSettingModule.Create(
                        PayloadData,
                        (res) => {
                          setLastUpdated(Date.now());
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Error",
                            description: error,
                            status: "error",
                          });
                        }
                      );
                    }
                  },
                  (error) => {
                    toast({
                      title: "Error",
                      description: "Please fill all required information",
                      status: "error",
                    });
                  }
                )}
              >
                {notificationId ? "Update" : "Save"}
              </Button>
              <Button
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                textColor={"#ea4d4d"}
                padding={"12px 16px"}
                marginRight={"5px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
