import {
  Box,
  Button,
  Checkbox,
  Container,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  FaArrowLeft,
  FaArrowRight,
  FaCarAlt,
  FaCaretDown,
} from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { IoMdArrowDropdown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { ProductAndService } from "../../Api/ProductAndServices";
import { PaginationControl } from "../PayrollEmployee/PaginationControl/PaginationControl";
import { AddItem } from "./AddNewItem";
import { BulkDelete } from "./BulkDelete";
import { Delete } from "./Delete";

export function ProductAndServices() {
  const [filter, setFilter] = useState("Active");
  const deleteModal = useDisclosure();

  const bulkDeleteModal = useDisclosure();
  const addNewItem = useDisclosure();
  const navigate = useNavigate();

  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  const form = useForm<ProductAndService.AddProductAndService>({
    defaultValues: {
      code: undefined,
      name: undefined,
      description: undefined,
      purchaseInfo: {
        costPrice: undefined,
        purchaseAccountId: undefined,
        taxRate: undefined,
        description: undefined,
      },
      sellInfo: {
        salePrice: undefined,
        saleAccountId: undefined,
        taxRate: undefined,
        description: undefined,
      },
    },
  });
  const [productAndServiceItemData, setProductAndServiceItemData] = useState<
    Array<ProductAndService.FetchAllProductAndService>
  >([]);
  const [deleteId, setDeleteId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState(undefined);
  const [limit, setLimit] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const pageCount = Math.ceil(count / limit);
  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };
  const debounced = useDebouncedCallback((value) => {
    setSearchText(value);
    setPageNumber(1);
  }, 1000);
  const renderPageNumbers = () => {
    const pageButtons = [];

    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            aria-label={`Page ${i}`}
            border={"1px solid #e8eaf2"}
            borderRadius={"full"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }
    } else {
      pageButtons.push(
        <IconButton
          key={1}
          aria-label={`Page 1`}
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          backgroundColor={pageNumber === 1 ? "#3454D1" : "#fff"}
          color={pageNumber === 1 ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === 1 ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>1</Text>}
          onClick={() => handlePageChange(1)}
        />
      );

      if (pageNumber > 3) {
        pageButtons.push(<Text key="left-dots">...</Text>);
      }

      const startPage = Math.max(2, pageNumber - 1);
      const endPage = Math.min(pageCount - 1, pageNumber + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            border={"1px solid #e8eaf2"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            aria-label={`Page ${i}`}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }

      if (pageNumber < pageCount - 2) {
        pageButtons.push(<Text key="right-dots">...</Text>);
      }

      pageButtons.push(
        <IconButton
          key={pageCount}
          border={"1px solid #e8eaf2"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          borderRadius={"full"}
          aria-label={`Page ${pageCount}`}
          backgroundColor={pageNumber === pageCount ? "#3454D1" : "#fff"}
          color={pageNumber === pageCount ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === pageCount ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>{pageCount}</Text>}
          onClick={() => handlePageChange(pageCount)}
        />
      );
    }

    return pageButtons;
  };
  const [selectedId, setSelectedId] = useState<string[]>([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedRows, setCheckedRows] = useState<number[]>([]);

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isAllChecked = event.target.checked;
    if (isAllChecked) {
      const allRowIds = productAndServiceItemData.map((item, i) => i);
      setCheckedRows(isCheckedAll ? [] : allRowIds);

      setSelectedId(productAndServiceItemData.map((item) => item.id));
    } else {
      setCheckedRows([]);
      setSelectedId([]);
    }

    setIsCheckedAll(isAllChecked);
  };

  const handleCheckRow = (rowIndex: number) => {
    const updatedRows = [...checkedRows];
    const rowIndexInCheckedRows = updatedRows.indexOf(rowIndex);

    if (rowIndexInCheckedRows !== -1) {
      updatedRows.splice(rowIndexInCheckedRows, 1);
    } else {
      updatedRows.push(rowIndex);
    }

    setCheckedRows(updatedRows);
    setIsCheckedAll(updatedRows.length === productAndServiceItemData.length);
    const selectedIds = updatedRows.map(
      (index) => productAndServiceItemData?.[index]?.id
    );
    setSelectedId(selectedIds);
  };
  const [isActiveValue, setIsActiveValue] = useState<boolean>(true);
  useEffect(() => {
    ProductAndService.GetProductAndServiceItemDetails(
      {
        name: searchText ? searchText : undefined,
        isActive: isActiveValue,
        limit: limit,
        page: pageNumber,
      },
      (data) => {
        setProductAndServiceItemData(data.data);
        setCount(data.total);
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      }
    );
  }, [lastUpdated, searchText, limit, pageNumber, isActiveValue]);

  return (
    <FormProvider {...form}>
      <Stack
        gap={0}
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
        h={"calc(100vh - 70px)"}
        // h="calc(100vh - 130px)"
        overflowY="auto"
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          border="1px solid #e5e7eb"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          padding={"16px"}
          gap={0}
        >
          <Text
            _light={{ color: "customColor.black7" }}
            fontSize={"17px"}
            fontWeight={"700"}
          >
            Products and services
          </Text>
          <Stack direction={"row"} gap={0}>
            <Menu>
              <MenuButton
                as={Button}
                borderRadius={"3px"}
                marginLeft={"16px"}
                backgroundColor={"#fff"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  color: "customColor.black7",
                  bg: "#fff",
                  border: "1px solid hsl(0, 0%, 70%)",
                }}
                _active={{
                  color: "#3545d1",
                  bg: "#fff",
                  borderWidth: "1px",
                  boxShadow: "0 0 0 1px #2684FF",
                  _hover: {
                    borderWidth: "1px",
                    borderColor: "#2684FF",
                    boxShadow: "0 0 0 1px #2684FF",
                  },
                }}
                rightIcon={<FaCaretDown />}
              >
                Import
              </MenuButton>
              <MenuList overflowY="auto" zIndex={"overlay"}>
                <MenuItem
                  key={1}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  {" "}
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                    onClick={() => {
                      navigate("/app/payroll/ImportForItems");
                    }}
                  >
                    Items
                  </Text>
                </MenuItem>
                <MenuItem
                  key={1}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  {" "}
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                    onClick={() => {
                      navigate("/app/payroll/OpeningBalances");
                    }}
                  >
                    Opening Balances
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton
                as={Button}
                borderRadius={"3px"}
                backgroundColor={"#fff"}
                border={"1px solid #dcdee4"}
                marginLeft={"12px"}
                fontSize={"13px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  color: "customColor.black7",
                  bg: "#fff",
                  border: "1px solid hsl(0, 0%, 70%)",
                }}
                _active={{
                  color: "#3545d1",
                  bg: "#fff",
                  borderWidth: "1px",
                  boxShadow: "0 0 0 1px #2684FF",
                  _hover: {
                    borderWidth: "1px",
                    borderColor: "#2684FF",
                    boxShadow: "0 0 0 1px #2684FF",
                  },
                }}
                rightIcon={<FaCaretDown />}
              >
                Export
              </MenuButton>
              <MenuList overflowY="auto" zIndex={"overlay"}>
                <MenuItem
                  key={1}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  {" "}
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    CSV
                  </Text>
                </MenuItem>
                <MenuItem
                  key={1}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  {" "}
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    PDF
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              borderRadius={"3px"}
              marginLeft={"12px"}
              borderRightRadius={0}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={() => {
                addNewItem.onOpen();
                form.reset({
                  code: undefined,
                  name: undefined,
                  description: undefined,
                  purchaseInfo: {
                    costPrice: undefined,
                    purchaseAccountId: undefined,
                    taxRate: undefined,
                    description: undefined,
                  },
                  sellInfo: {
                    salePrice: undefined,
                    saleAccountId: undefined,
                    taxRate: undefined,
                    description: undefined,
                  },
                });
              }}
            >
              New item
            </Button>
            <Menu>
              <MenuButton
                as={IconButton}
                padding={0}
                backgroundColor={"transparent"}
                h={"34.5px"}
                marginLeft={"12px"}
                color={"black"}
                display={{
                  lg: "flex",
                  md: "flex",
                  sm: "flex",
                  xs: "flex",
                }}
                borderRadius={"full"}
                _hover={{
                  backgroundColor: "rgba(0,10,30,.05)",
                  color: "#000a1e",
                }}
                _active={{
                  backgroundColor: "rgba(0,10,30,.05)",
                  color: "#000a1e",
                }}
                icon={<FiMoreVertical fontSize={"19px"} />}
              ></MenuButton>
              <MenuList overflowY="auto" zIndex={"overlay"}>
                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Stack alignItems={"center"} direction={"row"}>
                    <FaCarAlt fontSize="17px" color="customColor.black7" />
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                    >
                      Give feedBack
                    </Text>
                  </Stack>
                </MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        </Stack>
        <Container maxW={"container.lg"} padding={"25px"}>
          <Stack
            gap={0}
            // maxWidth={"1164px"}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
            borderRadius="4px"
            bg={"#fff"}
            boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
            borderWidth="1px"
            borderStyle={"solid"}
            borderColor="#f6f6f6"
            p={"0"}
          >
            <Stack direction={"row"} p={"16px"} alignItems={"center"} gap={0}>
              <Stack gap={0} direction={"row"} alignItems={"center"} flex={1}>
                <Text
                  fontSize={"13.44px"}
                  _light={{
                    color: "customColor.gray.6b7885",
                  }}
                >
                  Search:
                </Text>
                <InputGroup ml={[0, "7px"]}>
                  <Input
                    type="text"
                    placeholder="Search"
                    borderRadius={"3px"}
                    h={["auto", "40px"]}
                    _focusVisible={{
                      border: "1px solid grey",
                    }}
                    onChange={(e) => {
                      debounced(e.target.value);
                    }}
                  />
                </InputGroup>
              </Stack>
              <Menu>
                <MenuButton
                  as={Button}
                  variant={"ghost"}
                  h={"40px"}
                  fontSize={".845rem"}
                  marginLeft={"12px"}
                  color={"#3454d1"}
                  rightIcon={<IoMdArrowDropdown fontSize={"18px"} />}
                  _hover={{
                    bg: "#f2f3f4",
                  }}
                  _active={{
                    bg: "#f2f3f4",
                  }}
                >
                  {filter}
                </MenuButton>
                <MenuList overflowY="auto" zIndex={"overlay"}>
                  <MenuItem
                    key={1}
                    padding={"0px 12px"}
                    _hover={{
                      backgroundColor: "rgba(0,10,30,.1)",
                    }}
                    onClick={() => {
                      setFilter("Active");
                      setIsActiveValue(true);
                    }}
                  >
                    {" "}
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                    >
                      Active
                    </Text>
                  </MenuItem>
                  <MenuItem
                    key={1}
                    padding={"0px 12px"}
                    _hover={{
                      backgroundColor: "rgba(0,10,30,.1)",
                    }}
                    onClick={() => {
                      setFilter("Archive");
                      setIsActiveValue(false);
                    }}
                  >
                    {" "}
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                    >
                      Archive
                    </Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Stack>
            <Stack
              direction={"row"}
              p={"16px"}
              gap={0}
              borderTop={"1px solid #e5e7eb"}
              borderBottom={"1px solid #e5e7eb"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Stack direction={"row"} alignItems={"center"}>
                <PaginationControl
                  limit={limit}
                  setLimit={setLimit}
                  setPageNumber={setPageNumber}
                />
                <Text
                  fontSize={"13px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  {selectedId.length} selected items
                </Text>
              </Stack>

              <Stack alignItems={"center"} direction={"row"}>
                <Button
                  variant={"ghost"}
                  color={"red"}
                  _hover={{
                    bg: "rgba(0,10,30,.05)",
                  }}
                  onClick={() => {
                    bulkDeleteModal.onOpen();
                  }}
                  isDisabled={!selectedId || selectedId.length === 0}
                >
                  Bulk Delete
                </Button>

                <Button
                  variant={"ghost"}
                  color={"#3454d1"}
                  _hover={{
                    bg: "rgba(0,10,30,.05)",
                  }}
                  isDisabled={
                    !selectedId || selectedId.length === 0 || isActiveValue
                  }
                  onClick={(e) => {
                    form.handleSubmit((data) => {
                      setIsLoading(true);
                      ProductAndService.RestoreProductAndServiceItem(
                        { ids: selectedId },

                        (success) => {
                          setIsLoading(false);
                          setLastUpdated(Date.now());
                        },
                        (err) => {
                          setIsLoading(false);
                        }
                      );
                    })(e);
                  }}
                >
                  Restore
                </Button>
                <Button
                  variant={"ghost"}
                  color={"#3454d1"}
                  _hover={{
                    bg: "rgba(0,10,30,.05)",
                  }}
                  isDisabled={
                    !selectedId || selectedId.length === 0 || !isActiveValue
                  }
                  onClick={(e) => {
                    form.handleSubmit((data) => {
                      setIsLoading(true);
                      ProductAndService.ArchiveProductAndServiceItem(
                        { ids: selectedId },

                        (success) => {
                          setIsLoading(false);
                          setLastUpdated(Date.now());
                        },
                        (err) => {
                          setIsLoading(false);
                        }
                      );
                    })(e);
                  }}
                >
                  Archive
                </Button>
              </Stack>
            </Stack>
            <Skeleton isLoaded={!isLoading} height="100%">
              <Box overflowX="auto" p="0px">
                <Table variant="simple" w="100%" overflowX="auto">
                  <Thead h={"36.8px"}>
                    <Tr
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                    >
                      <Th
                        padding="8px 26px 8px 30px"
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                      >
                        <Checkbox
                          size="md"
                          borderColor="#adb5bd"
                          bgColor="white"
                          isChecked={isCheckedAll}
                          onChange={handleCheckAll}
                        />
                      </Th>
                      {[
                        {
                          name: "Code",
                          value: "15%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Name",
                          value: "40%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Cost price",
                          value: "15%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Sale price",
                          value: "15%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Quantity",
                          value: "10%",
                          paddingValue: "8px 26px 8px 15px",
                        },

                        {
                          name: "",
                          value: "5%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                      ].map((item, i) => {
                        return (
                          <Th
                            key={i}
                            padding={item.paddingValue}
                            borderBottomColor="borders.tableBorder"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            fontWeight={700}
                            whiteSpace="nowrap"
                            fontSize="12px"
                            textTransform="none"
                            width={item.value}
                          >
                            {item.name}
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody padding={0}>
                    {productAndServiceItemData.map((item, i) => {
                      return (
                        <Tr
                          key={i}
                          _hover={{
                            bgColor: "#f2f3f8",
                            cursor: "pointer",
                          }}
                        >
                          <Td
                            padding="15px 15px 15px 30px"
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            fontWeight={500}
                            whiteSpace="nowrap"
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            onClick={(e: any) => e.stopPropagation()}
                          >
                            <Checkbox
                              size="md"
                              borderColor="#adb5bd"
                              bgColor="white"
                              isChecked={checkedRows.includes(i)}
                              onChange={() => handleCheckRow(i)}
                              // Stop propagation here
                            />
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Tag
                              fontSize={"11px"}
                              borderRadius={"2px"}
                              bg={"#fff"}
                              border={"1px solid #e5e7eb"}
                            >
                              {item?.code}
                            </Tag>
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            {item?.name}
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            {/* {item?.purchaseInfo.costPrice &&
                            item?.purchaseInfo.costPrice !== undefined
                              ? item.purchaseInfo.costPrice.toLocaleString()
                              : "N.A"} */}
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            {/* {item?.sellInfo.salePrice
                              ? item?.sellInfo.salePrice.toLocaleString()
                              : "N.A"} */}
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            {/* {item?.quantity} */}
                          </Td>
                          <Td
                            padding="15px"
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Menu>
                              <MenuButton
                                as={Button}
                                padding={0}
                                backgroundColor={"transparent"}
                                h={"34.5px"}
                                marginLeft={"8px"}
                                _light={{
                                  color: "customColor.black7",
                                }}
                                display={{
                                  lg: "flex",
                                  md: "flex",
                                  sm: "flex",
                                  xs: "flex",
                                }}
                                borderRadius={"full"}
                                _hover={{
                                  backgroundColor: "primary.50",
                                }}
                                _active={{
                                  backgroundColor: "primary.100",
                                }}
                              >
                                <Icon as={FiMoreVertical} />
                              </MenuButton>
                              <MenuList style={{ minWidth: "140px" }}>
                                {[
                                  {
                                    name: "Edit",
                                    onClick: () => {
                                      addNewItem.onOpen();
                                      form.reset({ id: item.id });
                                    },
                                  },
                                  ...(filter === "Active"
                                    ? [
                                        {
                                          name: "Archive",
                                          onClick: () => {
                                            form.handleSubmit((data) => {
                                              setIsLoading(true);
                                              ProductAndService.ArchiveProductAndServiceItem(
                                                { ids: [item.id] },
                                                (success) => {
                                                  setIsLoading(false);
                                                  setLastUpdated(Date.now());
                                                },
                                                (err) => {
                                                  setIsLoading(false);
                                                }
                                              );
                                            })();
                                          },
                                        },
                                      ]
                                    : filter === "Archive"
                                    ? [
                                        {
                                          name: "Restore",
                                          onClick: () => {
                                            setIsLoading(true);
                                            ProductAndService.RestoreProductAndServiceItem(
                                              { ids: [item.id] },
                                              (success) => {
                                                setIsLoading(false);
                                                setLastUpdated(Date.now());
                                              },
                                              (err) => {
                                                setIsLoading(false);
                                              }
                                            );
                                          },
                                        },
                                      ]
                                    : []),
                                  {
                                    name: "Delete",
                                    onClick: () => {
                                      deleteModal.onOpen();
                                      setDeleteId(item.id);
                                    },
                                  },
                                ].map((menuItem, i) => (
                                  <MenuItem
                                    key={i}
                                    padding={"1px 0px 1px 20px"}
                                    onClick={menuItem.onClick}
                                    _light={{ color: "customColor.black7" }}
                                    _dark={{ color: "customColor.white" }}
                                  >
                                    <Button
                                      variant={"ghost"}
                                      fontWeight={"normal"}
                                      fontSize={"13px"}
                                      padding={"0px 20px 0px 0px"}
                                      _light={{ color: "customColor.black7" }}
                                      _dark={{ color: "customColor.white" }}
                                      _hover={{ bg: "none" }}
                                    >
                                      {menuItem.name}
                                    </Button>
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </Menu>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
              <Stack borderTop={"1px solid #dcdee4 "}>
                <HStack
                  marginTop={"9.35px"}
                  padding={"25px"}
                  fontSize={"13px"}
                  _light={{
                    color: "customColor.black2",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  justifyContent={"space-between"}
                >
                  <Text
                    fontWeight={"normal"}
                    color={"customColor.6b7885"}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontSize={"11px"}
                  >
                    Showing {limit * (pageNumber - 1) + 1} to{" "}
                    {limit + limit * (pageNumber - 1)} of{" "}
                    {productAndServiceItemData?.length} entries
                  </Text>
                  <HStack spacing="13px" justify={{ xs: "center", md: "end" }}>
                    <IconButton
                      aria-label="back"
                      border={"1px solid #e8eaf2"}
                      borderRadius={"full"}
                      backgroundColor="transparent"
                      _hover={{
                        backgroundColor: "#3454D1",
                        color: "white",
                      }}
                      onMouseEnter={() => setIsHovered2(true)}
                      onMouseLeave={() => setIsHovered2(false)}
                      icon={
                        <FaArrowLeft
                          size={15}
                          color={
                            isHovered2
                              ? "#FFFFFF"
                              : pageNumber <= 1 || pageNumber > pageCount
                              ? "#828ea0"
                              : "#283c50"
                          }
                        />
                      }
                      isDisabled={pageNumber <= 1 || pageNumber > pageCount}
                      onClick={() => {
                        if (pageNumber > 1 && pageNumber <= pageCount) {
                          pageNumberInputRef.current?.value &&
                            (pageNumberInputRef.current.value = String(
                              pageNumber - 1
                            ));
                          setPageNumber(pageNumber - 1);
                        }
                      }}
                    />
                    <HStack spacing="13px">{renderPageNumbers()}</HStack>
                    <IconButton
                      aria-label="right"
                      backgroundColor="transparent"
                      border={"1px solid #e8eaf2"}
                      borderRadius={"full"}
                      _hover={{
                        backgroundColor: "#3454D1",
                      }}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      icon={
                        <FaArrowRight
                          size={15}
                          color={
                            isHovered
                              ? "#FFFFFF"
                              : pageNumber >= pageCount || pageNumber < 1
                              ? "#828ea0"
                              : "#283c50"
                          }
                          style={{ transition: "color 0.2s" }} // Add smooth transition for color change
                        />
                      }
                      isDisabled={pageNumber >= pageCount || pageNumber < 1}
                      onClick={() => {
                        if (pageNumber < pageCount && pageNumber >= 1) {
                          pageNumberInputRef.current?.value &&
                            (pageNumberInputRef.current.value = String(
                              pageNumber + 1
                            ));
                          setPageNumber(pageNumber + 1);
                        }
                      }}
                    />
                  </HStack>
                </HStack>
              </Stack>
            </Skeleton>
          </Stack>
        </Container>
        <Delete
          isOpen={deleteModal.isOpen}
          onClose={deleteModal.onClose}
          deleteId={deleteId}
          setLastUpdated={setLastUpdated}
        />
        <BulkDelete
          isOpen={bulkDeleteModal.isOpen}
          onClose={bulkDeleteModal.onClose}
          selectedId={selectedId}
          setLastUpdated={setLastUpdated}
        />
        <AddItem
          isOpen={addNewItem.isOpen}
          onClose={addNewItem.onClose}
          setLastUpdated={setLastUpdated}
        />
      </Stack>
    </FormProvider>
  );
}
