import {HStack, Tag, Text, VStack} from '@chakra-ui/react';
import BuildHeader from '../components/BuildHeader';
import MobilePreview from './build-components/mobile-preview';
import TemplateBuilder from './build-components/template-builder';

interface TemplateBuildProps {}
export default function TemplateBuild({}: TemplateBuildProps) {
  return (
    <VStack pb="300px">
      <BuildHeader />
      {/* <BuildBottom /> */}
      <HStack
        justify="end"
        align="end"
        w="100%"
        px={{
          xs: '20px',
        }}>
        <Tag
          display={{xs: 'flex', md: 'none'}}
          color="textcolors.primary"
          fontSize="14px"
          fontWeight={400}
          background="#dbe0eb">
          Unpublished changes saved
        </Tag>
        <HStack display={{xs: 'none', md: 'flex', lg: 'none'}}>
          <Text color="#3f495a" fontSize="14px" fontWeight={400}>
            Last published:
          </Text>
          <Text color="#3f495a" fontSize="14px" fontWeight={400}>
            22/01/2022
          </Text>
        </HStack>
      </HStack>
      <HStack
        spacing={0}
        position="relative"
        w="100%"
        align="start"
        justify="space-around">
        <TemplateBuilder />
        <MobilePreview />
      </HStack>
    </VStack>
  );
}
