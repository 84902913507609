import axios from "axios";

const axiosInstanceAud = axios.create({
  baseURL:
    // process.env.REACT_APP_ENV === 'development'
    //   ? 'http://localhost:3000'
    //   :
    process.env.REACT_APP_API_URL_AUDITOR,
});

axiosInstanceAud.interceptors.request.use(function (config) {
  config.headers["Accept"] = "application/json";
  const token = localStorage.getItem("_auth");

  if (token) {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["ngrok-skip-browser-warning"] = true;
    }
  }
  return config;
});

export default axiosInstanceAud;
export { axiosInstanceAud as axiosInstance };
