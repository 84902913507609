import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from "@chakra-ui/react";
import {
  Document,
  Font,
  Page,
  pdf,
  Image as PdfImage,
  Text as PdfText,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import saveAs from "file-saver";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { BsDownload } from "react-icons/bs";

import { FiEdit } from "react-icons/fi";
import { IoPaperPlaneOutline, IoPrintOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { BusinessDetail } from "../../../../Api/BusinessDetail";
import { BusinessTaxRate } from "../../../../Api/BusinessTaxRate";
import { AddInvoices } from "../../../../Api/Invoices";

export default function InvoicePreview() {
  const data = [
    {
      GST: 10,
      description:
        "Modern & Minimal Multipurpose Bootstrap Admin Dashboard gjhjaha jhkjasjak ajdkjaksja ajdkjakj",
      rate: 50,
      qty: 10,
      amount: 500,
      Discount: 100,
    },
    {
      GST: 10,
      description: "Multipurpose Bootstrap4 Admin Dashboard Template",
      rate: 120,
      qty: 10,
      amount: 1200,
    },
    {
      GST: 10,
      description: "Advanced Metric Analysis Admin Template",
      rate: 75,
      qty: 8,
      amount: 600,
    },
    {
      GST: 10,
      description: "Lightweight Admin Dashboard and Analytics Tool",
      rate: 90,
      qty: 12,
      amount: 1080,
    },
    {
      GST: 10,
      description: "Responsive and Customizable E-commerce Template",
      rate: 110,
      qty: 5,
      amount: 550,
    },
    {
      GST: 10,
      description: "Cloud Management Dashboard for Enterprises",
      rate: 130,
      qty: 7,
      amount: 910,
    },
    {
      GST: 10,
      description: "Next-Gen Analytics and Reporting Dashboard",
      rate: 200,
      qty: 3,
      amount: 600,
    },
    {
      GST: 10,
      description: "Comprehensive Admin and CRM Dashboard",
      rate: 250,
      qty: 2,
      amount: 500,
    },
    {
      GST: 10,
      description: "AI-Driven Insights and Data Visualization Tool",
      rate: 300,
      qty: 4,
      amount: 1200,
    },
    {
      GST: 10,
      description: "Real-Time Monitoring and Performance Dashboard",
      rate: 95,
      qty: 6,
      amount: 570,
    },
    {
      GST: 5,
      description: "Real-Time Monitoring and Performance Dashboard",
      rate: 95,
      qty: 6,
      amount: 570,
    },
    {
      GST: 10,
      description: "Real-Time Monitoring and Performance Dashboard",
      rate: 95,
      qty: 6,
      amount: 570,
    },
    {
      GST: 10,
      description: "Real-Time Monitoring and Performance Dashboard",
      rate: 95,
      qty: 6,
      amount: 570,
    },
  ];
  const pdfData = {
    comapnyDetails: {
      name: "Duralux Pvt. Ltd.",
      address: "DeLorean New York",
      VATNo: "VAT No: 2617 348 2752",
      TaxNo: "Tax No: 2617 348 2752",
      logo: "https://hrisonline-prod.s3.ap-south-1.amazonaws.com/uploads/business-logos/dddc8d0b-2d99-459b-b56e-0ecd57fea689.png",
    },
    invoiceDetails: {
      invoicedTo: {
        name: "Ellen Louise Ripley",
        address: "Nostromo PO Box 29618",
        VATNo: "VAT No: 295 3932 6119",
        country: "United Kingdom",
      },
      reference: "sample for testing kjhkkdjhkfsddfjjdfjkdhfkjdhfk",
      invoiceNo: "#NXL369852",
      dueDate: "28 Feb, 2023",
      issuedDate: "25 Jan, 2023",
      currency: "USD",
      status: "Pending",
    },
    paymentAdvice: {
      name: "Duralux Pvt. Ltd.",
      address: "DeLorean New York",
      VATNo: "VAT No: 2617 348 2752",
      TaxNo: "Tax No: 2617 348 2752",
      customer: "ABC Furniture",
      invoiceNo: "NXL369852",
      amountDue: "3681.56",
      dueDate: "28 Feb, 2023",
      IBANNo: "PK92SONE21543546535",
    },
    items: data,
    notes: "hi how are you\ni am fine \nblah blah blah....",
    subTotal: 3850,
    discount: 577.5,
    estimatedTax: [{ 10: 307.5 }, { 5: 153.75 }],
    totalAmount: 3681.56,
  };
  const { id } = useParams<{ id: string }>();
  const [businessData, setBusinessData] = useState<any>({});
  const [invoiceData, setInvoiceData] = useState<any>({});
  const [taxRateKeyValues, setTaxRateKeyValues] = useState<any>({});
  useEffect(() => {
    if (!id) return;

    BusinessDetail.GetBusinessDetail(
      (success) => {
        setBusinessData(success);
      },
      (error) => {}
    );

    AddInvoices.FetchInvoiceDetailsById(
      id,
      (data) => {
        // Convert price and amount in each item to a number
        // const updatedData = {
        //   ...data,
        //   items: data.items.map((item: any) => ({
        //     ...item,
        //     price: item.price ? parseFloat(item.price) : undefined,
        //     amount: item.amount ? parseFloat(item.amount) : undefined,
        //   })),
        // };
        setInvoiceData(data);
      },
      (error) => {}
    );

    BusinessTaxRate.GetTaxRates(
      (data) => {
        const keyValues = data.reduce((acc: any, curr: any) => {
          const totalTax = curr?.tax_components?.reduce(
            (sum: any, component: any) => sum + component?.tax,
            0
          );
          acc[curr.id] = {
            taxRate: totalTax,
            taxName: curr?.name,
          };
          return acc;
        }, {});
        setTaxRateKeyValues(keyValues);
      },
      (error) => {}
    );
  }, [id]);
  const totalDiscount = invoiceData?.items?.reduce(
    (acc: number, item: any) => acc + (item?.discount ?? 0),
    0
  );
  const subTotal = invoiceData?.items?.reduce((acc: number, item: any) => {
    const price =
      invoiceData?.amountType === "Tax inclusive"
        ? (
            item?.price /
            (1 + taxRateKeyValues[item?.taxRateId]?.taxRate / 100)
          ).toFixed(4)
        : item?.price?.toFixed(4);
    const discount =
      invoiceData?.amountType === "Tax inclusive"
        ? (
            item?.discount /
            (1 + taxRateKeyValues[item?.taxRateId]?.taxRate / 100)
          ).toFixed(4)
        : item?.discount?.toFixed(2);
    const quantity = (item?.quantity).toFixed(2) ?? 0;
    const totalAmountWithoutDiscount = (price ?? 0) * (quantity ?? 0);
    const totaAmount = (totalAmountWithoutDiscount ?? 0) - (discount ?? 0);
    return acc + totaAmount;
  }, 0);

  function calculateTaxByRate(data: any): Record<string, number> {
    // Initialize an empty object to store tax totals by rate
    const taxTotals: Record<string, number> = {};
    // Skip calculation if "No tax" is applicable
    if (data.amountType === "No tax") {
      return taxTotals; // Return empty object as no tax is applicable
    }
    data?.items?.forEach((item: any) => {
      if (!item.taxRateId) return; // Skip if no tax rate is selected
      const taxRate = taxRateKeyValues[item?.taxRateId]?.taxRate; // Look up the tax rate percentage
      if (!taxRate) return; // Skip if tax rate is not found
      const taxableAmount =
        data?.amountType === "Tax inclusive"
          ? item.amount / (1 + taxRate / 100)
          : item.amount;
      const taxAmount = (taxRate / 100) * taxableAmount; // Calculate tax amount
      const key = `${taxRate}`; // Use tax rate as the key

      // Aggregate tax amounts by rate
      taxTotals[key] = (taxTotals[key] || 0) + taxAmount;
    });
    const sortedTaxTotals: any = {};

    Object.keys(taxTotals)
      .sort((a, b) => Number(b) - Number(a)) // Sort keys as numbers in descending order
      .forEach((key) => {
        sortedTaxTotals[key] = taxTotals[key]; // Add sorted key-value pairs to the new object
      });
    return sortedTaxTotals;
  }
  const taxResults = calculateTaxByRate(invoiceData);
  const totalAmountofTax = Object.values(taxResults).reduce(
    (acc: number, tax: number) => acc + tax,
    0
  );
  const totalAmountOfManualTaxes = invoiceData?.manualTaxes?.reduce(
    (acc: number, tax: any) => acc + (tax?.taxAmount ?? 0),
    0
  );
  const totalAmountofInvoice =
    (subTotal ?? 0) + (totalAmountofTax ?? 0) + (totalAmountOfManualTaxes ?? 0);
  function getCurrencyCode(data: string): string {
    return data?.split(":")[0]?.trim();
  }
  const currencyCode = getCurrencyCode(invoiceData?.currency);

  const getFieldVisibility = (): Record<string, boolean> => {
    const fieldsState = localStorage.getItem("fieldsState");
    return fieldsState ? JSON.parse(fieldsState) : {};
  };

  const fieldsVisibility = getFieldVisibility();
  console.log(fieldsVisibility);
  const discountColumn = fieldsVisibility?.Discount ?? true;
  console.log(discountColumn);
  const CompanyBankAccount = "PK98HABB0053957000144755";
  Font.register({
    family: "Poppins",

    fonts: [
      {
        src: "/Fonts/Poppins-Regular.ttf", // Path to Regular font
        fontWeight: "normal",
      },
      {
        src: "/Fonts/Poppins-Bold.ttf", // Path to Bold font
        fontWeight: "bold",
      },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontSize: 12,
      fontFamily: "Poppins",
      backgroundColor: "#FFFFFF",
    },
    header: {
      fontSize: 16,
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: 20,
      color: "#283c50",
    },
    companyDetails: {
      marginBottom: 20,
      color: "#283c50",
    },
    invoiceDetails: {
      marginBottom: 20,
      color: "#283c50",
    },
    table: {
      marginTop: 20,
      width: "100%",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 0.1,
      borderBottomColor: "#000000",
      borderBottomStyle: "solid",
    },
    tableHeader: {
      backgroundColor: "#FFFFFF",
    },
    tableColHeader: {
      width: "20%",
      paddingVertical: 7,
      paddingHorizontal: 5,
      textAlign: "center",
      fontWeight: "bold",
      fontSize: 9.2,
      color: "#283c50",
    },
    tableCol: {
      width: "20%",
      paddingVertical: 7,
      paddingHorizontal: 5,
      textAlign: "center",
      fontSize: 8.5,
      color: "#283c50",
    },
  });
  console.log(taxRateKeyValues);
  const InvoicePDF = ({ data }: any) => (
    <Document>
      <Page style={styles.page} size="A4">
        {/* Company & Invoice Details */}
        {businessData?.logo && (
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-end",
              // alignItems: "center",
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            <PdfImage
              style={{
                width: "40%",
                maxHeight: 112.5,
                objectFit: "contain",
              }}
              src={businessData?.logo}
            />
          </View>
        )}

        <View
          style={{
            justifyContent: "space-between",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "75%",
            }}
          >
            <PdfText
              style={{
                fontSize: 22,
                fontWeight: 800,
                color: "#283c50",
                textTransform: "uppercase",
                marginBottom: 20,
              }}
            >
              {["Awaiting Payment", "Paid"].includes(invoiceData?.status)
                ? "Tax Invoice"
                : "Draft Invoice"}
            </PdfText>
          </View>
          <View
            style={{
              width: "25%",
              marginLeft: 20,
            }}
          >
            <PdfText
              style={{
                fontSize: 9,
                color: "#283c50",
              }}
            >
              {businessData?.legalName}
            </PdfText>
            <PdfText
              style={{
                fontSize: 9,
                color: "#283c50",
              }}
            >
              {businessData?.streetAddress}
            </PdfText>
            {!!businessData?.taxNumber && (
              <PdfText
                style={{
                  fontSize: 9,
                  color: "#283c50",
                }}
              >
                Tax No: {businessData?.taxNumber}
              </PdfText>
            )}
          </View>
        </View>

        <View
          style={{
            justifyContent: "space-between",
            width: "100%",
            flexDirection: "row",
          }}
        >
          <View
            style={{
              width: "35%",
              flexDirection: "column",
              justifyContent: "space-between",
              marginRight: 20,
            }}
          >
            <PdfText
              style={{
                fontSize: 10,
                fontWeight: "bold",
                color: "#283c50",
                marginBottom: 12,
              }}
            >
              Invoiced To:
            </PdfText>
            <PdfText
              style={{
                fontSize: 9,
                color: "#283c50",
              }}
            >
              {invoiceData?.contact?.name}
            </PdfText>
            <PdfText
              style={{
                fontSize: 9,
                color: "#283c50",
              }}
            >
              {invoiceData?.contact?.billingAddress?.addresss}
            </PdfText>
          </View>
          {!!invoiceData?.reference && (
            <View
              style={{
                width: "40%",
                flexDirection: "column",
                marginRight: 10,
                marginTop: 12,
              }}
            >
              <PdfText
                style={{
                  marginBottom: 14,
                }}
              ></PdfText>
              <View
                style={{
                  width: "90%",
                }}
              >
                <PdfText
                  style={{
                    fontSize: 9,
                    color: "#283c50",
                    fontWeight: "bold",
                  }}
                >
                  Reference
                </PdfText>
                <PdfText
                  style={{
                    fontSize: 9,
                    color: "#283c50",
                  }}
                >
                  {invoiceData?.reference}
                </PdfText>
              </View>
            </View>
          )}

          <View
            style={{
              width: "25%",
              flexDirection: "column",
              marginTop: 12,
            }}
          >
            <PdfText
              style={{
                marginBottom: 12,
              }}
            ></PdfText>
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <PdfText
                style={{
                  fontSize: 9,
                  color: "#283c50",
                  fontWeight: "bold",
                }}
              >
                Invoice:{" "}
              </PdfText>
              <PdfText
                style={{
                  fontSize: 9,
                  color: "#283c50",
                }}
              >
                #{invoiceData?.invoiceNumber}
              </PdfText>
            </View>

            <PdfText
              style={{
                fontSize: 9,
                color: "#283c50",
                marginTop: 5,
              }}
            >
              Due Date: {moment(invoiceData?.dueDate).format("DD MMM, YYYY")}
            </PdfText>
            <PdfText
              style={{
                fontSize: 9,
                color: "#283c50",
                marginTop: 5,
              }}
            >
              Issued Date:{" "}
              {moment(invoiceData?.issuedDate).format("DD MMM, YYYY")}
            </PdfText>
          </View>
        </View>

        {/* Table */}
        <View style={styles.table}>
          {/* Table Header */}
          <View
            style={[
              styles.tableRow,
              styles.tableHeader,
              {
                borderBottomWidth: 1,
                borderBottomColor: "#000000",
                borderBottomStyle: "solid",
              },
            ]}
          >
            <PdfText
              style={[
                styles.tableColHeader,
                {
                  width: discountColumn ? "45%" : "55%",
                  textAlign: "left",
                  paddingLeft: 0,
                },
              ]}
            >
              Description
            </PdfText>
            <PdfText
              style={[
                styles.tableColHeader,
                { width: "10%", textAlign: "right" },
              ]}
            >
              Quantity
            </PdfText>

            <PdfText
              style={[
                styles.tableColHeader,
                { width: "10%", textAlign: "right" },
              ]}
            >
              Unit Price
            </PdfText>
            {discountColumn && (
              <PdfText
                style={[
                  styles.tableColHeader,
                  { width: "10%", textAlign: "right" },
                ]}
              >
                Discount
              </PdfText>
            )}

            <PdfText
              style={[
                styles.tableColHeader,
                { width: "10%", textAlign: "center" },
              ]}
            >
              GST
            </PdfText>
            <PdfText
              style={[
                styles.tableColHeader,
                { width: "15%", textAlign: "right" },
              ]}
            >
              Amount {currencyCode}
            </PdfText>
          </View>

          {/* Table Rows */}
          {invoiceData?.items?.map((item: any, index: any) => (
            <View style={styles.tableRow} key={index} wrap={false}>
              <PdfText
                style={[
                  styles.tableCol,
                  {
                    width: discountColumn ? "45%" : "55%",
                    textAlign: "left",
                    paddingLeft: 0,
                  },
                ]}
              >
                {item?.description}
              </PdfText>
              <PdfText
                style={[styles.tableCol, { width: "10%", textAlign: "right" }]}
              >
                {item?.quantity?.toFixed(2)}
              </PdfText>
              <PdfText
                style={[styles.tableCol, { width: "10%", textAlign: "right" }]}
              >
                {invoiceData?.amountType === "Tax inclusive"
                  ? (
                      item?.price /
                      (1 + taxRateKeyValues[item?.taxRateId]?.taxRate / 100)
                    ).toFixed(4)
                  : item?.price?.toFixed(4)}
              </PdfText>
              {discountColumn && (
                <PdfText
                  style={[
                    styles.tableCol,
                    { width: "10%", textAlign: "right" },
                  ]}
                >
                  {item?.discount?.toFixed(2)}
                </PdfText>
              )}

              <PdfText
                style={[styles.tableCol, { width: "10%", textAlign: "center" }]}
              >
                {invoiceData?.amountType === "No tax"
                  ? "No GST"
                  : `${taxRateKeyValues[item?.taxRateId]?.taxName ?? 0}`}{" "}
                {`(${taxRateKeyValues[item?.taxRateId]?.taxRate ?? 0}%)`}
              </PdfText>
              <PdfText
                style={[styles.tableCol, { width: "15%", textAlign: "right" }]}
              >
                {invoiceData?.amountType === "Tax inclusive"
                  ? (
                      item?.quantity *
                        (item?.price /
                          (1 +
                            taxRateKeyValues[item?.taxRateId]?.taxRate / 100)) -
                      item?.discount /
                        (1 + taxRateKeyValues[item?.taxRateId]?.taxRate / 100)
                    ).toFixed(2)
                  : (item?.amount).toFixed(2)}
              </PdfText>
            </View>
          ))}
          <View
            style={[
              styles.tableRow,
              {
                borderBottomWidth: 0,
                borderBottomColor: "#FFFFFF",
              },
            ]}
            wrap={false}
          >
            <PdfText
              style={[
                styles.tableCol,
                {
                  textAlign: "left",
                  paddingLeft: 0,
                  flex: 1,
                  paddingVertical: 3.5,
                },
              ]}
            ></PdfText>
            <PdfText
              style={[
                styles.tableCol,
                {
                  textAlign: "right",
                  flex: 1,
                  paddingVertical: 3.5,
                },
              ]}
            ></PdfText>
            <PdfText
              style={[
                styles.tableCol,
                {
                  textAlign: "right",
                  flex: discountColumn ? 3 : 2,
                  paddingVertical: 3.5,
                },
              ]}
            >
              {discountColumn
                ? `Sub Total (includes a discount of ${totalDiscount?.toFixed(
                    2
                  )})`
                : "Sub Total"}
            </PdfText>
            <PdfText
              style={[
                styles.tableCol,
                { textAlign: "right", flex: 1, paddingVertical: 3.5 },
              ]}
            >
              {subTotal?.toFixed(2)}
            </PdfText>
          </View>
          {/* <View
            style={[
              styles.tableRow,
              {
                borderBottomWidth: 0,
                borderBottomColor: "#FFFFFF",
              },
            ]}
            wrap={false}
          >
            <PdfText
              style={[
                styles.tableCol,
                {
                  textAlign: "left",
                  paddingLeft: 0,
                  flex: 1,
                },
              ]}
            ></PdfText>
            <PdfText
              style={[styles.tableCol, { textAlign: "right", flex: 1 }]}
            ></PdfText>
            <PdfText
              style={[
                styles.tableCol,
                { textAlign: "right", flex: discountColumn ? 3 : 2 },
              ]}
            >
              Discount (15%)
            </PdfText>
            <PdfText style={[styles.tableCol, { textAlign: "right", flex: 1 }]}>
              {pdfData?.discount?.toFixed(2)}
            </PdfText>
          </View> */}
          {invoiceData?.autoTaxes?.map((tax: any, index: any) => (
            <View
              style={[
                styles.tableRow,
                {
                  borderBottomWidth: 0,
                  borderBottomColor: "#FFFFFF",
                },
              ]}
              wrap={false}
            >
              <PdfText
                style={[
                  styles.tableCol,
                  {
                    textAlign: "left",
                    paddingLeft: 0,
                    flex: 1,
                    paddingTop: 0,
                    paddingBottom: 3.5,
                  },
                ]}
              ></PdfText>
              <PdfText
                style={[
                  styles.tableCol,
                  {
                    textAlign: "right",
                    flex: 1,
                    paddingTop: 0,
                    paddingBottom: 3.5,
                  },
                ]}
              ></PdfText>

              <PdfText
                style={[
                  styles.tableCol,
                  {
                    textAlign: "right",
                    flex: discountColumn ? 3 : 2,
                    paddingTop: 0,
                    paddingBottom: 3.5,
                  },
                ]}
              >
                Total {taxRateKeyValues[tax?.taxRateId]?.taxName}{" "}
                {`(${taxRateKeyValues[tax?.taxRateId]?.taxRate}%)`}
              </PdfText>
              <PdfText
                style={[
                  styles.tableCol,
                  {
                    width: "10%",
                    textAlign: "right",
                    flex: 1,
                    paddingTop: 0,
                    paddingBottom: 3.5,
                  },
                ]}
              >
                {tax?.taxAmount?.toFixed(2)}
              </PdfText>
            </View>
          ))}
          {invoiceData?.manualTaxes?.map((tax: any, index: any) => (
            <View
              style={[
                styles.tableRow,
                {
                  borderBottomWidth: 0,
                  borderBottomColor: "#FFFFFF",
                },
              ]}
              wrap={false}
            >
              <PdfText
                style={[
                  styles.tableCol,
                  {
                    textAlign: "left",
                    paddingLeft: 0,
                    flex: 1,
                    paddingTop: 0,
                    paddingBottom: 3.5,
                  },
                ]}
              ></PdfText>
              <PdfText
                style={[
                  styles.tableCol,
                  {
                    textAlign: "right",
                    flex: 1,
                    paddingTop: 0,
                    paddingBottom: 3.5,
                  },
                ]}
              ></PdfText>

              <PdfText
                style={[
                  styles.tableCol,
                  {
                    textAlign: "right",
                    flex: discountColumn ? 3 : 2,
                    paddingTop: 0,
                    paddingBottom: 3.5,
                  },
                ]}
              >
                Total {taxRateKeyValues[tax?.taxRateId]?.taxName}{" "}
                {`(${taxRateKeyValues[tax?.taxRateId]?.taxRate}%)`}
              </PdfText>
              <PdfText
                style={[
                  styles.tableCol,
                  {
                    width: "10%",
                    textAlign: "right",
                    flex: 1,
                    paddingTop: 0,
                    paddingBottom: 3.5,
                  },
                ]}
              >
                {tax?.taxAmount?.toFixed(2)}
              </PdfText>
            </View>
          ))}

          <View
            style={[
              styles.tableRow,
              {
                borderBottomWidth: 0,
                borderBottomColor: "#FFFFFF",
              },
            ]}
            wrap={false}
          >
            <PdfText
              style={[
                styles.tableCol,
                {
                  textAlign: "left",
                  paddingLeft: 0,
                  flex: 1,
                  paddingVertical: 3.5,
                },
              ]}
            ></PdfText>
            <PdfText
              style={[
                styles.tableCol,
                { textAlign: "right", flex: 1, paddingVertical: 3.5 },
              ]}
            ></PdfText>
            <PdfText
              style={[
                styles.tableCol,
                {
                  textAlign: "right",
                  flex: discountColumn ? 3 : 2,
                  borderTopWidth: 1,
                  borderTopColor: "#283c50",
                  borderTopStyle: "solid",
                  fontSize: 9,
                  fontWeight: "bold",
                  paddingVertical: 3.5,
                },
              ]}
            >
              Total Amount {currencyCode}
            </PdfText>
            <PdfText
              style={[
                styles.tableCol,
                {
                  textAlign: "right",
                  flex: 1,
                  borderTopWidth: 1,
                  borderTopColor: "#283c50",
                  borderTopStyle: "solid",
                  fontSize: 9,
                  fontWeight: "bold",
                  paddingVertical: 3.5,
                },
              ]}
            >
              {totalAmountofInvoice?.toFixed(2)}
            </PdfText>
          </View>
        </View>
        <View
          wrap={false}
          style={{
            paddingVertical: 5,
          }}
        >
          <PdfText
            style={{
              fontSize: 9,
              color: "#283c50",
              marginTop: 20,
              fontWeight: "bold",
            }}
          >
            Notes:
          </PdfText>
          {invoiceData.notes.split("\n").map((line: any, index: any) => (
            <PdfText
              key={index}
              style={{
                fontSize: 9,
                color: "#283c50",
                marginTop: 5,
              }}
            >
              {line}
            </PdfText>
          ))}
        </View>
        <View
          wrap={false}
          style={{
            marginBottom: 18.75,
            marginTop: "auto",
            borderTop: "1px dashed #000000",
          }}
        >
          <View
            style={{
              justifyContent: "space-between",
              width: "100%",
              flexDirection: "row",
              paddingTop: 7.5,
              height: 187.5,
            }}
          >
            <View>
              <PdfText
                style={{
                  fontSize: 22,
                  fontWeight: 700,
                  color: "#283c50",
                  lineHeight: 0.9,
                  marginBottom: 12,
                  textTransform: "uppercase",
                }}
              >
                Payment Advice
              </PdfText>
              <PdfText
                style={{
                  fontSize: 9,
                  color: "#283c50",
                }}
              >
                {businessData.legalName}
              </PdfText>
              <PdfText
                style={{
                  fontSize: 9,
                  color: "#283c50",
                }}
              >
                {businessData.streetAddress}
              </PdfText>
              {businessData?.taxNumber && (
                <PdfText
                  style={{
                    fontSize: 9,
                    color: "#283c50",
                  }}
                >
                  Tax No: {businessData.taxNumber}
                </PdfText>
              )}
            </View>
            <View
              style={{
                flexDirection: "column",
                width: 280,
              }}
            >
              {/* Customer Row */}
              <View
                style={{ flexDirection: "row", width: "100%", marginBottom: 6 }}
              >
                <PdfText
                  style={{
                    fontSize: 9,
                    color: "#283c50",
                    fontWeight: "bold",
                    flex: 1,
                  }}
                >
                  Customer
                </PdfText>
                <PdfText style={{ fontSize: 9, color: "#283c50", flex: 2 }}>
                  {invoiceData?.contact?.name}
                </PdfText>
              </View>

              {/* Invoice Number Row */}
              <View
                style={{ flexDirection: "row", width: "100%", marginBottom: 6 }}
              >
                <PdfText
                  style={{
                    fontSize: 9,
                    color: "#283c50",
                    fontWeight: "bold",
                    flex: 1,
                  }}
                >
                  Invoice Number
                </PdfText>
                <PdfText style={{ fontSize: 9, color: "#283c50", flex: 2 }}>
                  {invoiceData?.invoiceNumber}
                </PdfText>
              </View>

              {/* Amount Due Row */}
              <View
                style={{ flexDirection: "row", width: "100%", marginBottom: 6 }}
              >
                <PdfText
                  style={{
                    fontSize: 9,
                    color: "#283c50",
                    fontWeight: "bold",
                    flex: 1,
                  }}
                >
                  Amount Due
                </PdfText>
                <PdfText
                  style={{
                    fontWeight: "bold",
                    fontSize: 9,
                    color: "#283c50",
                    flex: 2,
                  }}
                >
                  {totalAmountofInvoice && totalAmountofInvoice?.toFixed(2)}
                </PdfText>
              </View>

              {/* Due Date Row */}
              <View
                style={{ flexDirection: "row", width: "100%", marginBottom: 6 }}
              >
                <PdfText
                  style={{
                    fontSize: 9,
                    color: "#283c50",
                    fontWeight: "bold",
                    flex: 1,
                  }}
                >
                  Due Date
                </PdfText>
                <PdfText style={{ fontSize: 9, color: "#283c50", flex: 2 }}>
                  {moment(invoiceData?.dueDate).format("DD MMM, YYYY")}
                </PdfText>
              </View>

              {/* IBAN Number Row */}
              <View
                style={{ flexDirection: "row", width: "100%", marginBottom: 6 }}
              >
                <PdfText
                  style={{
                    fontSize: 9,
                    color: "#283c50",
                    fontWeight: "bold",
                    flex: 1,
                  }}
                >
                  IBAN Number
                </PdfText>
                <PdfText style={{ fontSize: 9, color: "#283c50", flex: 2 }}>
                  {CompanyBankAccount}
                </PdfText>
              </View>

              {/* Amount Enclosed Row */}
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <PdfText
                  style={{
                    fontSize: 9,
                    color: "#283c50",
                    fontWeight: "bold",
                    flex: 1,
                  }}
                >
                  Amount Enclosed
                </PdfText>
                <View style={{ flex: 2 }}>
                  <View
                    style={{
                      marginTop: 12,

                      borderBottom: "1px solid #000000",
                    }}
                  ></View>
                  <PdfText style={{ fontSize: 7.5, color: "#283c50" }}>
                    Enter the amount you are paying above
                  </PdfText>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  const downloadPdf = async (data: any) => {
    const fileName = "Invoice.pdf";
    const blob = await pdf(<InvoicePDF data={data} />).toBlob();
    saveAs(blob, fileName);
  };
  return (
    <Container
      maxW="full"
      bgColor="#f3f4f6"
      h="calc(100vh - 70px)"
      overflowY="auto"
    >
      <Box maxW="1200px" mx="auto" p={6}>
        <Box bg="#FFFFFF" rounded="md" shadow="md" overflow="hidden">
          <Flex
            justify="space-between"
            align="center"
            p="25px"
            bg="#FFFFFF"
            borderBottom="1px solid #e5e7eb"
          >
            <Heading fontSize="16px" color="#283c50" fontWeight="700">
              Invoice Preview
            </Heading>
            <HStack spacing={4}>
              <Tooltip label="Send Invoice">
                <IconButton
                  icon={<IoPaperPlaneOutline />}
                  aria-label="Send Invoice"
                  bg="transparent"
                  color={"#283c50"}
                  border="1px solid #e5e7eb"
                  borderRadius="50%"
                  _hover={{
                    color: "#fff",
                    bg: "#3454d1",
                  }}
                />
              </Tooltip>
              <Tooltip label="Print Invoice">
                <IconButton
                  icon={<IoPrintOutline />}
                  aria-label="Print Invoice"
                  bg="transparent"
                  color={"#283c50"}
                  border="1px solid #e5e7eb"
                  borderRadius="50%"
                  _hover={{
                    color: "#fff",
                    bg: "#3454d1",
                  }}
                />
              </Tooltip>
              {/* <Tooltip label="Add Payment">
                <IconButton
                  icon={<HiOutlinePlus />}
                  aria-label="Add Payment"
                  bg="transparent"
                  color={"#283c50"}
                  border="1px solid #e5e7eb"
                  borderRadius="50%"
                />
              </Tooltip> */}
              <Tooltip label="Download Invoice">
                <IconButton
                  icon={<BsDownload />}
                  aria-label="Download Invoice"
                  bg="transparent"
                  color={"#283c50"}
                  border="1px solid #e5e7eb"
                  borderRadius="50%"
                  _hover={{
                    color: "#fff",
                    bg: "#3454d1",
                  }}
                  onClick={() => downloadPdf(data)}
                />
              </Tooltip>
              <Tooltip label="Edit Invoice">
                <IconButton
                  icon={<FiEdit />}
                  aria-label="Edit Invoice"
                  bg="transparent"
                  color={"#283c50"}
                  border="1px solid #e5e7eb"
                  borderRadius="50%"
                  _hover={{
                    color: "#fff",
                    bg: "#3454d1",
                  }}
                />
              </Tooltip>
            </HStack>
          </Flex>
          {businessData.logo && (
            <Flex w={"100%"} justifyContent="flex-end" px="24px" mt={"10px"}>
              <Image
                src={businessData?.logo}
                alt="Company Logo"
                maxW={"300px"}
                maxH="150px"
                objectFit={"contain"}
              />
            </Flex>
          )}

          <Flex px="24px" pt="24px">
            <Heading
              fontSize="24px"
              fontWeight={800}
              color="#283c50"
              textTransform="uppercase"
              mt="15px"
              w={"83%"}
            >
              {["Awaiting Payment", "Paid"].includes(invoiceData?.status)
                ? "Tax Invoice"
                : "Draft Invoice"}
            </Heading>
            <VStack align="start" spacing={0} w="17%" pl="8px">
              <Text fontSize="13.44px" color="#283c50">
                {businessData.legalName}
              </Text>
              <Text fontSize="13.44px" color="#283c50">
                {businessData.streetAddress}
              </Text>
              {businessData?.taxNumber && (
                <Text fontSize="13.44px" color="#283c50">
                  Tax No: {businessData.taxNumber}
                </Text>
              )}
            </VStack>
          </Flex>
          {/* <Divider my="25px" borderColor="#91a1b6" borderStyle="dashed" /> */}
          <Box px="24px" py="16px" mt="24px" w="100%">
            <HStack h="160px" spacing="0px" alignItems="start" w={"100%"}>
              <Box textAlign="start" w="43%">
                <Text
                  fontSize="16px"
                  fontWeight="700"
                  color="#283c50"
                  mb="16px"
                >
                  Invoiced To:
                </Text>
                <Text fontSize="13.44px" color="#283c50" lineHeight={2}>
                  {invoiceData?.contact?.name}
                </Text>
                <Text
                  w={"40%"}
                  fontSize="13.44px"
                  color="#283c50"
                  mb="16px"
                  lineHeight={2}
                >
                  {invoiceData?.contact?.billingAddress?.addresss}
                </Text>
              </Box>
              <VStack spacing={1} w="45%">
                <Text
                  fontSize="16px"
                  fontWeight="700"
                  color="#283c50"
                  mb="38px"
                ></Text>
                {invoiceData?.reference && (
                  <VStack spacing={1} w="100%" px="16px">
                    <Text
                      fontWeight="bold"
                      color="#283c50"
                      fontSize="13.44px"
                      textAlign="left"
                      w={"100%"}
                    >
                      Reference
                    </Text>
                    <Text
                      color="#283c50"
                      fontSize="13.44px"
                      whiteSpace="normal"
                      wordBreak="break-word"
                      textAlign={"start"}
                      w={"100%"}
                    >
                      {invoiceData?.reference}
                    </Text>
                  </VStack>
                )}
              </VStack>

              <VStack align="start" spacing={1} w="17%">
                <Text
                  fontSize="16px"
                  fontWeight="700"
                  color="#283c50"
                  mb="38px"
                ></Text>
                <HStack spacing={1}>
                  <Text fontWeight="bold" color="#283c50" fontSize="13.44px">
                    Invoice:
                  </Text>
                  <Text fontWeight="bold" color="#283c50" fontSize="13.44px">
                    #{invoiceData?.invoiceNumber}
                  </Text>
                </HStack>
                <HStack spacing={1}>
                  <Text fontWeight="bold" color="#283c50" fontSize="13.44px">
                    Due Date:
                  </Text>
                  <Text color="#283c50" fontSize="13.44px">
                    {moment(invoiceData?.dueDate).format("DD MMM, YYYY")}
                  </Text>
                </HStack>
                <HStack spacing={1}>
                  <Text fontWeight="bold" color="#283c50" fontSize="13.44px">
                    Issued Date:
                  </Text>
                  <Text color="#283c50" fontSize="13.44px">
                    {moment(invoiceData?.issueDate).format("DD MMM, YYYY")}
                  </Text>
                </HStack>
              </VStack>

              {/* <Divider
                orientation="vertical"
                borderColor="#91a1b6"
                borderStyle="dashed"
                height="100%"
              />

              <Box textAlign="start">
                <Text
                  fontSize="16px"
                  fontWeight="700"
                  color="#283c50"
                  mb="16px"
                >
                  Payment Details:
                </Text>
                <HStack lineHeight={2} spacing={1}>
                  <Text color="#64748b" fontSize="13.44px">
                    Total Due:
                  </Text>
                  <Text color="#283c50" fontSize="13.44px" fontWeight={"700"}>
                    $249 USD
                  </Text>
                </HStack>
                <HStack lineHeight={2} spacing={1}>
                  <Text color="#64748b" fontSize="13.44px">
                    Payout Status:
                  </Text>
                  <Text color="#ffa21d" fontSize="13.44px" fontWeight={"700"}>
                    Pending
                  </Text>
                </HStack>
                <HStack lineHeight={2} spacing={1}>
                  <Text color="#64748b" fontSize="13.44px">
                    Card Holder:
                  </Text>
                  <Text color="#283c50" fontSize="13.44px" fontWeight={"700"}>
                    Alexandra Della
                  </Text>
                </HStack>
                <HStack lineHeight={2} spacing={1}>
                  <Text color="#64748b" fontSize="13.44px">
                    Payment Method:
                  </Text>
                  <Text color="#283c50" fontSize="13.44px" fontWeight={"700"}>
                    Mastercard
                  </Text>
                </HStack>
              </Box> */}
            </HStack>
          </Box>

          {/* <Divider mt="25px" borderColor="#91a1b6" borderStyle="dashed" /> */}

          <TableContainer px="24px">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th
                    color={"#283c50"}
                    py="8px"
                    fontSize="11px"
                    borderColor="#000000"
                    borderBottomWidth="1.2px"
                    w={"45%"}
                    textAlign={"start"}
                    pl="0px"
                  >
                    Description
                  </Th>
                  <Th
                    color={"#283c50"}
                    py="8px"
                    fontSize="11px"
                    borderColor="#000000"
                    borderBottomWidth="1.2px"
                    w={"10%"}
                    textAlign="right"
                  >
                    Quantity
                  </Th>
                  <Th
                    color={"#283c50"}
                    py="8px"
                    fontSize="11px"
                    borderColor="#000000"
                    borderBottomWidth="1.2px"
                    w={"10%"}
                    textAlign="right"
                  >
                    Unit Price
                  </Th>
                  {discountColumn && (
                    <Th
                      color={"#283c50"}
                      py="8px"
                      fontSize="11px"
                      borderColor="#000000"
                      borderBottomWidth="1.2px"
                      w={"10%"}
                      textAlign="center"
                    >
                      Discount
                    </Th>
                  )}

                  <Th
                    color={"#283c50"}
                    py="8px"
                    fontSize="11px"
                    borderColor="#000000"
                    borderBottomWidth="1.2px"
                    w={"10%"}
                    textAlign="center"
                  >
                    GST
                  </Th>
                  <Th
                    color={"#283c50"}
                    py="8px"
                    fontSize="11px"
                    borderColor="#000000"
                    borderBottomWidth="1.2px"
                    w={"15%"}
                    textAlign="right"
                  >
                    Amount {currencyCode}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {invoiceData?.items?.map((item: any, index: any) => (
                  <Tr key={index} fontSize="13.44px">
                    <Td
                      py="9px"
                      color="#283c50"
                      borderColor="#000000"
                      w="45%"
                      whiteSpace="normal"
                      wordBreak="break-word"
                      textAlign={"start"}
                      pl="0px"
                    >
                      {item.description}
                    </Td>
                    <Td
                      py="9px"
                      color="#283c50"
                      borderColor="#000000"
                      textAlign="right"
                      w="10%"
                    >
                      {item?.quantity?.toFixed(2)}
                    </Td>
                    <Td
                      py="9px"
                      color="#283c50"
                      borderColor="#000000"
                      textAlign="right"
                      w="10%"
                    >
                      {invoiceData?.amountType === "Tax inclusive"
                        ? (
                            item?.price /
                            (1 +
                              taxRateKeyValues[item?.taxRateId]?.taxRate / 100)
                          ).toFixed(4)
                        : item?.price?.toFixed(4)}
                    </Td>
                    {discountColumn && (
                      <Td
                        py="9px"
                        color="#283c50"
                        borderColor="#000000"
                        w={"10%"}
                        textAlign="right"
                      >
                        {item?.discount?.toFixed(2)}
                      </Td>
                    )}

                    <Td
                      py="9px"
                      color="#283c50"
                      borderColor="#000000"
                      w={"10%"}
                      textAlign="center"
                    >
                      {invoiceData?.amountType === "No tax"
                        ? "No GST"
                        : `${
                            taxRateKeyValues[item?.taxRateId]?.taxName ?? 0
                          }`}{" "}
                      {`(${taxRateKeyValues[item?.taxRateId]?.taxRate ?? 0}%)`}
                    </Td>
                    <Td
                      py="9px"
                      color="#283c50"
                      borderColor="#000000"
                      textAlign={"right"}
                      w={"15%"}
                    >
                      {invoiceData?.amountType === "Tax inclusive"
                        ? (
                            item?.quantity *
                              (item?.price /
                                (1 +
                                  taxRateKeyValues[item?.taxRateId]?.taxRate /
                                    100)) -
                            item?.discount /
                              (1 +
                                taxRateKeyValues[item?.taxRateId]?.taxRate /
                                  100)
                          ).toFixed(2)
                        : (item?.amount).toFixed(2)}
                    </Td>
                  </Tr>
                ))}

                {/* Add other rows similarly */}
                <Tr>
                  <Td border="0px" pt="15px" pb="0px"></Td>
                  <Td border="0px" pt="15px" pb="0px"></Td>
                  <Td
                    colSpan={discountColumn ? 3 : 2}
                    pt="15px"
                    color="#283c50"
                    fontSize="13.44px"
                    textAlign={"right"}
                    border="0px"
                    w={"10%"}
                    pb="0px"
                  >
                    {discountColumn
                      ? `Sub Total (includes a discount of ${totalDiscount?.toFixed(
                          2
                        )})`
                      : "Sub Total"}
                  </Td>
                  <Td
                    pt="15px"
                    color="#283c50"
                    fontSize="13.44px"
                    textAlign={"right"}
                    border="0px"
                    w={"15%"}
                    pb="0px"
                  >
                    {subTotal?.toFixed(2)}
                  </Td>
                </Tr>
                {/* <Tr>
                  <Td border="0px" pt="7.5px" pb="0px"></Td>
                  <Td border="0px" pt="7.5px" pb="0px"></Td>
                  <Td
                    colSpan={discountColumn ? 3 : 2}
                    pt="7.5px"
                    color="#283c50"
                    fontSize="13.44px"
                    textAlign={"right"}
                    border="0px"
                    w={"10%"}
                    pb="0px"
                  >
                    Discount
                  </Td>
                  <Td
                    pt="7.5px"
                    color="#283c50"
                    fontSize="13.44px"
                    textAlign={"right"}
                    border="0px"
                    w={"15%"}
                    pb="0px"
                  >
                    {totalDiscount?.toFixed(2)}
                  </Td>
                </Tr> */}
                {invoiceData?.autoTaxes?.map((tax: any, index: any) => (
                  <Tr key={index}>
                    <Td border="0px" pt="7.5px" pb="0px"></Td>
                    <Td border="0px" pt="7.5px" pb="0px"></Td>
                    <Td
                      colSpan={discountColumn ? 3 : 2}
                      py="7.5px"
                      color="#283c50"
                      fontSize="13.44px"
                      textAlign={"right"}
                      borderColor="#000000"
                      borderBottomWidth="0px"
                      w={"10%"}
                    >
                      Total {taxRateKeyValues[tax?.taxRateId]?.taxName}{" "}
                      {`(${taxRateKeyValues[tax?.taxRateId]?.taxRate}%)`}
                    </Td>
                    <Td
                      py="7.5px"
                      color="#283c50"
                      fontSize="13.44px"
                      textAlign={"right"}
                      borderColor="#000000"
                      borderBottomWidth="0px"
                      w={"15%"}
                    >
                      {tax?.taxAmount?.toFixed(2)}
                    </Td>
                  </Tr>
                ))}
                {invoiceData?.manualTaxes?.map((tax: any, index: any) => (
                  <Tr key={index}>
                    <Td border="0px" pt="7.5px" pb="0px"></Td>
                    <Td border="0px" pt="7.5px" pb="0px"></Td>
                    <Td
                      colSpan={discountColumn ? 3 : 2}
                      py="7.5px"
                      color="#283c50"
                      fontSize="13.44px"
                      textAlign={"right"}
                      borderColor="#000000"
                      borderBottomWidth="0px"
                      w={"10%"}
                    >
                      Total {taxRateKeyValues[tax?.taxRateId]?.taxName}{" "}
                      {`(${taxRateKeyValues[tax?.taxRateId]?.taxRate}%)`}
                    </Td>
                    <Td
                      py="7.5px"
                      color="#283c50"
                      fontSize="13.44px"
                      textAlign={"right"}
                      borderColor="#000000"
                      borderBottomWidth="0px"
                      w={"15%"}
                    >
                      {tax?.taxAmount?.toFixed(2)}
                    </Td>
                  </Tr>
                ))}

                <Tr pb="24px">
                  <Td borderColor="#000000" borderBottomWidth="0px"></Td>
                  <Td borderColor="#000000" borderBottomWidth="0px"></Td>
                  <Td
                    colSpan={discountColumn ? 3 : 2}
                    fontWeight="600"
                    py="15px"
                    color="#283c50"
                    fontSize="13.44px"
                    textAlign={"right"}
                    borderBottomWidth="0px"
                    borderTopWidth="1.2px"
                    borderColor="#000000"
                    w={"10%"}
                  >
                    Total Amount {currencyCode}
                  </Td>
                  <Td
                    fontWeight="700"
                    py="15px"
                    color="#283c50"
                    fontSize="15px"
                    textAlign={"right"}
                    borderBottomWidth="0px"
                    borderTopWidth="1.2px"
                    borderColor="#000000"
                    w={"15%"}
                  >
                    {totalAmountofInvoice?.toFixed(2)}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          {invoiceData?.notes && (
            <Box
              fontSize="13.44px"
              color="#283c50"
              textAlign="left"
              w={"100%"}
              mt="16px"
              px={"24px"}
            >
              <Text fontWeight="600">Notes</Text>
              <Text style={{ whiteSpace: "pre-line" }}>
                {invoiceData?.notes}
              </Text>
            </Box>
          )}

          <Box mt="24px" px="24px">
            <Divider borderStyle={"dashed"} borderColor="#000000" />
          </Box>

          {/* <Box px="24px" mt="25px" mb="16px">
            <Alert
              bgColor="#fff6e9"
              color="#ffa21d"
              border={"1px dashed #ffd69d"}
              borderRadius="6px"
              p="24px"
              fontSize={"13.44px"}
            >
              <AlertDescription>
                <strong>NOTES:</strong> All accounts are to be paid within 7
                days from receipt of invoice. <br />
                To be paid by cheque or credit card or direct payment online.
                <br />
                If account is not paid within 7 days the credits details
                supplied as confirmation of work undertaken will be charged the
                agreed quoted fee noted above.
              </AlertDescription>
            </Alert>
          </Box> */}

          <Flex
            justify="space-between"
            px="24px"
            pt="10px"
            mb="25px"
            h={"250px"}
            w={"100%"}
          >
            <Box maxW={"20%"}>
              <Text
                fontWeight="700"
                color="#283c50"
                lineHeight={1.2}
                mb="16px"
                fontSize="24px"
                textTransform={"uppercase"}
              >
                Payment Advice
              </Text>
              <Box mb="16px">
                <Text fontSize="13.44px" color="#283c50">
                  {businessData.legalName}
                </Text>
                <Text fontSize="13.44px" color="#283c50">
                  {businessData.streetAddress}
                </Text>
                {businessData?.taxNumber && (
                  <Text fontSize="13.44px" color="#283c50">
                    Tax No: {businessData.taxNumber}
                  </Text>
                )}
              </Box>
            </Box>
            {/* <Flex flexDirection="column" alignItems="center">
              <Image
                src="https://duralux-react-dashboard.vercel.app/images/general/signature.png"
                alt="Signature"
                width="100px"
              />
              <Text fontSize="13px" fontWeight="700" color="#283c50">
                Account Manager
              </Text>
              <Text fontSize="11px" color="#64748b" fontWeight="600" mb="16px">
                26 MAY 2024, 10:35 PM
              </Text>
            </Flex> */}
            <Flex flexDirection="column" w="400px">
              <HStack w="100%">
                <Text
                  fontSize="13.44px"
                  color="#283c50"
                  flex={1}
                  fontWeight="700"
                >
                  Customer
                </Text>
                <Text fontSize="13.44px" color="#283c50" flex={2}>
                  {invoiceData?.contact?.name}
                </Text>
              </HStack>
              <HStack w="100%">
                <Text
                  fontSize="13.44px"
                  color="#283c50"
                  flex={1}
                  fontWeight="700"
                >
                  Invoive Number
                </Text>
                <Text fontSize="13.44px" color="#283c50" flex={2}>
                  {invoiceData?.invoiceNumber}
                </Text>
              </HStack>
              <HStack w="100%">
                <Text
                  fontSize="13.44px"
                  color="#283c50"
                  flex={1}
                  fontWeight="700"
                >
                  Amount Due
                </Text>
                <Text
                  fontSize="13.44px"
                  color="#283c50"
                  fontWeight="700"
                  flex={2}
                >
                  {totalAmountofInvoice?.toFixed(2)}
                </Text>
              </HStack>
              <HStack w="100%">
                <Text
                  fontSize="13.44px"
                  color="#283c50"
                  flex={1}
                  fontWeight="700"
                >
                  Due Date
                </Text>
                <Text fontSize="13.44px" color="#283c50" flex={2}>
                  {moment(invoiceData?.dueDate).format("DD MMM, YYYY")}
                </Text>
              </HStack>
              <HStack w="100%">
                <Text
                  fontSize="13.44px"
                  color="#283c50"
                  flex={1}
                  fontWeight="700"
                >
                  IBAN Number
                </Text>
                <Text fontSize="13.44px" color="#283c50" flex={2}>
                  {CompanyBankAccount}
                </Text>
              </HStack>
              <HStack w="100%" spacing={0} display="flex" alignItems="start">
                {/* Label */}
                <Text
                  fontSize="13.44px"
                  color="#283c50"
                  flex={1}
                  fontWeight="700"
                >
                  Amount Enclosed
                </Text>

                <Box mt="16px" flex={2}>
                  <Box
                    w="95%"
                    borderBottom="2px solid"
                    borderColor="#000000"
                    ml="6px"
                  ></Box>

                  {/* Helper Text */}
                  <Text fontSize="12px" color="#283c50" ml="6px">
                    Enter the amount you are paying above
                  </Text>
                </Box>
              </HStack>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Container>
  );
}
