import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { EmployeesReport } from "../../../Api/EmployeeReport";

import { ReactSelect } from "../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { DownloadExcel } from "../../TimeSheet/utils";
import { ColumnsComponent } from "../Components/Columns";
import { FilterComponent } from "../Components/FilterComponent";
import ListLimit from "../Components/ListLimit";
import PaginationComponent from "../Components/PaginationComponent";
import { ReportingTable } from "../Tables/ReportingTable";
import { Column } from "./Columns";
import ReportsHeader from "./ReportsHeader";
interface GenericObject {
  [key: string]: any;
}
export function EmployeeRemunerationReport() {
  const [selectedGroupBy, setSelectedGroupBy] = useState<
    | EmployeesReport.GroupingEmployeeRemunerationReport["groupBy"]
    | EmployeesReport.GroupingLeaveBalanceReport["groupBy"]
    | EmployeesReport.GroupingTimesheetSummaryReport["groupBy"]
    | EmployeesReport.GroupingTimesheetDetailsReport["groupBy"]
    | EmployeesReport.GroupingSuperannuationAccrualsReport["groupBy"]
    | EmployeesReport.GroupingSuperannuationPaymentsReport["groupBy"]
    | EmployeesReport.GroupingLeaveTransactionsReport["groupBy"]
    | EmployeesReport.GroupingLeaveRequestReport["groupBy"]
    | "EMPLOYEE"
    | "EMPLOYEE_GROUP"
    | undefined
  >(undefined);
  const [selectedClonedGroupBy, setSelectedClonedGroupBy] = useState<
    | EmployeesReport.GroupingEmployeeRemunerationReport["groupBy"]
    | EmployeesReport.GroupingLeaveBalanceReport["groupBy"]
    | EmployeesReport.GroupingTimesheetSummaryReport["groupBy"]
    | EmployeesReport.GroupingTimesheetDetailsReport["groupBy"]
    | EmployeesReport.GroupingSuperannuationAccrualsReport["groupBy"]
    | EmployeesReport.GroupingSuperannuationPaymentsReport["groupBy"]
    | EmployeesReport.GroupingLeaveTransactionsReport["groupBy"]
    | EmployeesReport.GroupingLeaveRequestReport["groupBy"]
    | "EMPLOYEE"
    | "EMPLOYEE_GROUP"
    | undefined
  >(undefined);
  const GroupingBtn: Array<{ label: string; value?: string }> = [
    {
      label: "None",
      value: undefined,
    },
    {
      label: "Employee",
      value: "EMPLOYEE",
    },
  ];
  const employeeBasisOptions: Array<{ label: string; value?: string }> = [
    {
      label: "All",
      value: undefined,
    },
    {
      label: "Full Time",
      value: "FULL_TIME",
    },
    {
      label: "Part Time",
      value: "PART_TIME",
    },
    {
      label: "Casual",
      value: "CASUAL",
    },
  ];
  const [employeeBasis, setEmployeeBasis] = useState<string | undefined>(
    undefined
  );

  const [selectedEmployeeStatus, setSelectedEmployeeStatus] = useState<
    boolean | undefined
  >(undefined);
  const EmployeeStatus: Array<{ label: string; value?: boolean }> = [
    {
      label: "All",
      value: undefined,
    },
    {
      label: "Active",
      value: true,
    },
    {
      label: "Terminated",
      value: false,
    },
  ];
  const name = " Employee Remuneration Report";
  const FilterData: Array<{
    name: string;
    startRate?: string;
    endRate?: string;
    dataType?: string;
    endDate?: string;
    startDate?: string;
    StartKeyName?: string;
    EndKeyName?: string;
    singleProperty?: string;
    PayLoadDataType?: any;
  }> = [
    {
      name: "Earnings",
      startRate: "text",
      endRate: "text",
      StartKeyName: "filters.earnings.from",
      EndKeyName: "filters.earnings.to",
      PayLoadDataType: Number,
    },
    {
      name: "Job Title",
      dataType: "text",
      singleProperty: "filters.jobTitle",
      PayLoadDataType: String,
    },
    {
      name: "Pay Calendar",
      dataType: "text",
      singleProperty: "filters.payrollCalendarName",
      PayLoadDataType: String,
    },
    {
      name: "Start Date",
      startDate: "date",
      endDate: "date",
      StartKeyName: "filters.startDate.from",
      EndKeyName: "filters.startDate.to",
      PayLoadDataType: String,
    },
    {
      name: "Separation Date",
      startDate: "date",
      endDate: "date",
      StartKeyName: "filters.separationDate.from",
      EndKeyName: "filters.separationDate.to",
      PayLoadDataType: String,
    },
  ];
  const toast = useToast();
  const reportForm = useForm<EmployeesReport.EmployeeRemunerationReport>({
    defaultValues: {
      columns: {
        uniqueId: true,
        earnings: true,
        empBasis: true,
        firstName: true,
        lastName: true,
        jobTitle: true,
        payrollCalendarName: true,
        separationDate: true,
        startDate: true,
      },
      filters: {},
      pagination: {
        page: 1,
        limit: 10,
      },
    },
  });
  const [selectedColumns, setSelectedColumns] = useState<Array<Column>>([
    colBtns[0].colItem[0],
    colBtns[0].colItem[1],
    colBtns[0].colItem[2],
  ]);
  const [clonedSelectedColumns, setClonedSelectedColumns] = useState<
    Array<Column>
  >([...colBtns[0].colItem]);

  const [selectedData, setSelectedData] = useState<
    EmployeesReport.fetchEmployeeRemunerationReportData | GenericObject
  >();
  const [totalCount, setTotalCount] = useState<number>(0);
  const { allEmployeesList } = useBusinessContext();
  const [selectedEmployee, setSelectedEmployee] = useState<string | undefined>(
    undefined
  );
  const [excelData, setExcelData] = useState<any>(undefined);
  const postPayload = reportForm.handleSubmit((data) => {
    let payloadData = { ...data };
    const isEmptyObject = (obj: any) => {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    const removeEmpty = (obj: any) => {
      let newObj: any = {};
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === "object") {
          newObj[key] = removeEmpty(obj[key]);
          if (isEmptyObject(newObj[key]) && key !== "filters") {
            delete newObj[key];
          }
        } else if (obj[key] !== undefined) {
          newObj[key] = obj[key];
        }
      });
      return newObj;
    };
    const payload = {
      ...payloadData,
      filters: {
        ...payloadData.filters,
        empId: selectedEmployee,
        empBasis: employeeBasis,
        active: selectedEmployeeStatus,
      },
      groupBy: selectedGroupBy,

      columns: colBtns[0].colItem.reduce((acc, item) => {
        return {
          ...acc,
          [item.column]: selectedColumns.some(
            (col) => col.column === item.column
          ),
        };
      }, {} as any),
    };
    const cleanedPayload = removeEmpty(payload);
    EmployeesReport.CreateEmployeeRemunerationReport(
      cleanedPayload,
      (success: any) => {
        setSelectedData(success);
        setTotalCount(success.count);
        setClonedSelectedColumns(selectedColumns);
        setExcelData(cleanedPayload);
        toast({
          title: "Report Created",
          description: "Report Created Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setSelectedClonedGroupBy(selectedGroupBy);
      },
      (error: any) => {
        toast({
          title: "Report Creation Failed",
          description: "Report Creation Failed",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    );
  });
  const watchedLimit = useWatch({
    control: reportForm.control,
    name: "pagination.limit",
  });
  const watchedPage = useWatch({
    control: reportForm.control,
    name: "pagination.page",
  });
  useEffect(() => {
    if (selectedData !== undefined) {
      if (selectedData?.data.length > 0) {
        postPayload();
      }
    }
  }, [watchedLimit, watchedPage]);
  return (
    <FormProvider {...reportForm}>
      <Stack
        h="calc(100vh - 70px)"
        overflowY="auto"
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
      >
        <Stack
          direction={"row"}
          gap={0}
          boxShadow={"0 1px 0 0 rgba(0,10,30,.2)"}
          _light={{
            bgColor: "customColor.white",
          }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          alignItems={"center"}
          justifyContent={"space-between"} // Add this line
          width={"100%"}
        >
          <ReportsHeader name={name} />
          <Box
            display="flex"
            justifyContent="flex-end"
            paddingLeft={"10px"}
            marginRight={"16px"}
          >
            <Menu>
              <MenuButton
                as={Button}
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                h={"40px"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                _active={{
                  backgroundColor: "#fff",
                }}
                rightIcon={<ChevronDownIcon />}
                marginRight={"8px"}
              >
                Export
              </MenuButton>
              <MenuList style={{ minWidth: "120px" }}>
                <MenuItem
                  // onClick={downloadExcel}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                  onClick={() => {
                    if (excelData === undefined) {
                      toast({
                        title: "Report",
                        description: "Fetch Data First",
                        status: "warning",
                        duration: 9000,
                        isClosable: true,
                      });
                    } else {
                      EmployeesReport.CreateEmployeeRemunerationReportExport(
                        excelData,
                        (success) => {
                          DownloadExcel(
                            success.data,
                            "Employee Remuneration Report.xlsx"
                          );
                          toast({
                            title: "Report Downloaded",
                            description: "Report Downloaded Successfully",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                          });
                        },
                        () => {
                          toast({
                            title: "Report Creation Failed",
                            description: "Report Creation Failed",
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          });
                        }
                      );
                    }
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Excel
                  </Text>
                </MenuItem>

                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    PDF
                  </Text>
                </MenuItem>
                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Google Sheet
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => {
                reportForm.reset({
                  columns: {
                    earnings: true,
                    empBasis: true,
                    firstName: true,
                    jobTitle: true,
                    lastName: true,
                    payrollCalendarName: true,
                    separationDate: true,
                    startDate: true,
                    uniqueId: true,
                  },
                  filters: {},
                  pagination: { page: 1, limit: 10 },
                });
                setSelectedColumns([
                  colBtns[0].colItem[0],
                  colBtns[0].colItem[1],
                  colBtns[0].colItem[2],
                ]);
                setSelectedEmployee(undefined);
                setEmployeeBasis(undefined);
                setSelectedEmployeeStatus(undefined);
                reportForm.setValue("pagination", { page: 1, limit: 10 });
              }}
            >
              Clear Filters
            </Button>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              marginLeft={"8px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={postPayload}
            >
              Upload
            </Button>
            {/* <Button onClick={downloadExcel} borderRadius={"3px"}>
              Download as Excel
            </Button> */}
          </Box>
        </Stack>

        <Container padding={"0px 12px"} maxW={"1150px"}>
          <Stack padding={"0px"} marginTop={"10px"}>
            <SimpleGrid columns={{ xs: 2, sm: 2, md: 3, lg: 4 }}>
              <Box margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
                <HStack gap={0} mb="8px">
                  <Text
                    fontSize="13px"
                    display={"block"}
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Employee
                  </Text>
                </HStack>

                <ReactSelect
                  placeholder="Employee"
                  options={[
                    { value: undefined, label: "All" }, // Add 'All' option
                    ...(allEmployeesList?.map((employee: any) => ({
                      value: employee.id,
                      label: `${employee.firstName} ${employee.lastName}`,
                    })) || []),
                  ]}
                  value={
                    selectedEmployee
                      ? {
                          value: selectedEmployee,
                          label: `${
                            allEmployeesList?.find(
                              (employee) => employee.id === selectedEmployee
                            )?.firstName
                          } ${
                            allEmployeesList?.find(
                              (employee) => employee.id === selectedEmployee
                            )?.lastName
                          }`,
                        }
                      : { value: undefined, label: "All" }
                  }
                  onChange={(newValue: any) => {
                    setSelectedEmployee(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Box>
              <Box margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
                <HStack gap={0} mb="8px">
                  <Text
                    fontSize="13px"
                    display={"block"}
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Employee Basis (Required)
                  </Text>
                </HStack>

                <ReactSelect
                  placeholder="Employee Basis"
                  options={employeeBasisOptions}
                  value={
                    employeeBasisOptions.find(
                      (op) => op.value === employeeBasis
                    ) || employeeBasisOptions[0]
                  }
                  onChange={(newValue: any) => {
                    setEmployeeBasis(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Box>
              <Box margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
                <HStack gap={0} mb="8px">
                  <Text
                    fontSize="13px"
                    display={"block"}
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Employee Status (Required)
                  </Text>
                </HStack>

                <ReactSelect
                  placeholder="Employee Status"
                  options={EmployeeStatus}
                  value={
                    EmployeeStatus.find(
                      (status) => status.value === selectedEmployeeStatus
                    ) || EmployeeStatus[0]
                  }
                  onChange={(newValue: any) => {
                    setSelectedEmployeeStatus(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Box>

              <ColumnsComponent
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                colBtns={colBtns}
              />
              {/* <Grouping
                setSelectedGroupBy={setSelectedGroupBy}
                groupingBtn={groupingBtn}
              /> */}
              <Box margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
                <HStack gap={0} mb="8px">
                  <Text
                    fontSize="13px"
                    display={"block"}
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Group By
                  </Text>
                </HStack>

                <ReactSelect
                  placeholder="Group By"
                  options={GroupingBtn}
                  value={
                    GroupingBtn.find((btn) => btn.value === selectedGroupBy) ||
                    GroupingBtn[0]
                  }
                  onChange={(newValue: any) => {
                    setSelectedGroupBy(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Box>
              <FilterComponent FilterData={FilterData} />
            </SimpleGrid>
          </Stack>
        </Container>
        {(selectedData?.data?.length > 0 || selectedData !== undefined) && (
          <Container maxW={"1150px"} paddingLeft={"22px"} paddingRight={"22px"}>
            <Box w={"100%"} display="flex" justifyContent="flex-end">
              <ListLimit totalCount={totalCount} />
            </Box>
            <ReportingTable
              name={name}
              Data={selectedData?.data}
              DataWithKey={selectedData?.data}
              selectedColumns={clonedSelectedColumns}
              selectedData={selectedData}
              // groupBy={clonedSelectedGroupBy}
              groupBy={selectedClonedGroupBy}
              totalsColumns={["earnings"]}
              // tabRef={tabRef}
            />
            <Box w={"100%"}>
              <PaginationComponent
                totalCount={totalCount}
                // dataLength={selectedData?.data.length}
              />
            </Box>
          </Container>
        )}
        {/* <Box
          borderTop="1px solid #ddd"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          position="fixed"
          left={0}
          bottom="0"
          w="full"
          h="50px"
          // zIndex={"overlay"}
        >
          <Container
            justifyContent="center"
            alignItems="flex-end"
            textAlign={"right"}
            maxW={"90%"}
          >
            <Menu>
              <MenuButton
                as={Button}
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                _active={{
                  backgroundColor: "#fff",
                }}
                rightIcon={<ChevronDownIcon />}
                marginTop={"10px"}
              >
                Export
              </MenuButton>
              <MenuList style={{ minWidth: "120px" }}>
                <MenuItem
                  // onClick={downloadExcel}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                  onClick={() => {
                    if (excelData === undefined) {
                      toast({
                        title: "Report",
                        description: "Fetch Data First",
                        status: "warning",
                        duration: 9000,
                        isClosable: true,
                      });
                    } else {
                      EmployeesReport.CreateEmployeeRemunerationReportExport(
                        excelData,
                        (success) => {
                          DownloadExcel(
                            success.data,
                            "Employee Remuneration Report.xlsx"
                          );
                          toast({
                            title: "Report Downloaded",
                            description: "Report Downloaded Successfully",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                          });
                        },
                        () => {
                          toast({
                            title: "Report Creation Failed",
                            description: "Report Creation Failed",
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          });
                        }
                      );
                    }
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Excel
                  </Text>
                </MenuItem>

                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    PDF
                  </Text>
                </MenuItem>
                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Google Sheet
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
          </Container>
        </Box> */}
      </Stack>
    </FormProvider>
  );
}

const colBtns: Array<{
  colItem: Array<Column>;
}> = [
  {
    colItem: [
      {
        colLabel: "Employee ID",
        column: "uniqueId",
        position: 0,

        dataKey: "uniqueId",
      },
      {
        colLabel: "First Name ",
        column: "firstName",
        position: 1,
        groupingKey: "EMPLOYEE",
        dataKey: "firstName",
      },
      {
        colLabel: "Last Name",
        column: "lastName",
        groupingKey: "EMPLOYEE",
        position: 2,
        dataKey: "lastName",
      },
      {
        colLabel: "Start Date",
        column: "startDate",
        position: 3,
        dataKey: "startDate",
      },
      {
        colLabel: "Separation Date",
        column: "separationDate",
        position: 4,
        dataKey: "separationDate",
      },
      {
        colLabel: "Employee Basis ",
        column: "empBasis",
        position: 5,
        dataKey: "empBasis",
      },
      {
        colLabel: "Job Title",
        column: "jobTitle",
        position: 6,
        dataKey: "jobTitle",
      },
      {
        colLabel: "Pay Calendar",
        column: "payrollCalendarName",
        position: 7,
        dataKey: "payrollCalendarName",
      },
      {
        colLabel: "Earnings",
        column: "earnings",
        position: 8,
        dataKey: "earnings",
      },
    ],
  },
];
