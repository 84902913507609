import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  Image,
  Input,
  Spinner,
  Text,
  useBoolean,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Fragment, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { AiOutlineUpload } from "react-icons/ai";
import { FiImage, FiUpload } from "react-icons/fi";
import { useBusinessContext } from "../../../../../../../Hooks/BusinessContext";
import { UploadAttachments } from "../../../../../../../Payroll/Api/UploadAttachments";
import { fileToBase64 } from "../../../../../../modules/api";
import { compressImage } from "../../../../../../modules/utils";

interface InventoryImageProps {
  index: number;
}
export default function InventoryImage({ index }: InventoryImageProps) {
  const form = useFormContext();
  const watchedImage = form.watch(`inventory.${index}.mediaUrl`);
  const [isImageUploading, setIsImageUploading] = useBoolean(false);
  const toast = useToast();
  const { currentBusinessId } = useBusinessContext();
  const imageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsImageUploading.on();
      const file = e.target.files[0];
      const compressedImage = await compressImage(file);
      fileToBase64(compressedImage)
        .then((base64) => {
          // Inspection.UploadMedia(
          //   {
          //     base64,
          //     fileName: file.name,
          //     inspectionId: `business_${currentBusinessId}`,
          //   },
          //   (path) => {
          //     form.setValue(`inventory.${index}.mediaUrl`, path);
          //     setIsImageUploading.off();
          //   },
          //   (error) => {
          //     setIsImageUploading.off();
          //     toast({
          //       title: "Error",
          //       description: error,
          //       status: "error",
          //     });
          //   }
          // );
          const formData = new FormData();
          formData.append("docType", "INVENTORY");
          formData.append("file", file);
          formData.append("businessId", currentBusinessId!);
          UploadAttachments.UploadAttachment(
            formData,
            (success) => {
              form.setValue(`inventory.${index}.mediaUrl`, success.url);
              setIsImageUploading.off();
            },
            (err) => {
              toast({
                title: "Error",
                description: err,
                status: "error",
              });
              setIsImageUploading.off();
            }
          );
        })
        .catch((e) => {
          setIsImageUploading.off();
          toast({
            title: "Error",
            description: "Error uploading image",
            status: "error",
          });
        });
    }
  };
  const imageRef = useRef<HTMLInputElement>(null);

  const FileInput = () => (
    <Input
      ref={imageRef}
      type={"file"}
      hidden
      accept="image/*"
      onChange={imageChange}
    />
  );
  if (watchedImage) {
    return (
      <Box
        role="group"
        w="100%"
        h="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
      >
        <Image
          src={watchedImage}
          w="100%"
          h="100%"
          borderRadius={"12px"}
          objectFit={"fill"}
        />
        <Flex
          position="absolute"
          display="none"
          _groupHover={{ display: "flex" }}
          justifyContent="center"
          alignItems="center"
          top="0"
          right="0"
          bottom="0"
          left="0"
        >
          <IconButton
            icon={<AiOutlineUpload size={24} />}
            aria-label="ReUpload"
            variant="ghost"
            colorScheme="blue"
            _hover={{
              bg: "transparent",
            }}
            onClick={() => imageRef.current?.click()}
          />
          <IconButton
            icon={<DeleteIcon />}
            aria-label="Delete"
            variant="ghost"
            colorScheme="red"
            _hover={{
              bg: "transparent",
            }}
            onClick={() => form.setValue(`inventory.${index}.mediaUrl`, "")}
          />
        </Flex>
        <FileInput />
      </Box>
    );
  } else {
    if (isImageUploading) {
      return (
        <Box
          w="100%"
          h="100%"
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Spinner />
        </Box>
      );
    } else {
      return (
        <Fragment>
          <Box
            w={"100%"}
            h={"100%"}
            justifyContent="center"
            alignItems="center"
            display="flex"
            _groupHover={{
              display: "none",
            }}
            onClick={() => {
              imageRef.current?.click();
            }}
          >
            <FiImage size={48} color={"blue"} />
          </Box>
          <Box
            w={"100%"}
            h={"100%"}
            display="none"
            _groupHover={{
              display: "block",
            }}
          >
            <VStack
              w={"100%"}
              h={"100%"}
              spacing={1}
              backgroundColor="#d7dee9"
              justifyContent={"center"}
            >
              <FiUpload size={20} color="#545f70" />
              <Text color="icons.primary" fontSize={"14px"} fontWeight={500}>
                Upload image
              </Text>
            </VStack>
          </Box>
          <FileInput />
        </Fragment>
      );
    }
  }
}
