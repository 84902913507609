import {
  Button,
  ButtonGroup,
  Center,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { FiSettings } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useBusinessContext } from "../../../../../../Hooks/BusinessContext";
import { TemplateMeta } from "../../../../../modules/Audit";
import { PeriodicalEvents } from "../../../../../modules/Audit/PeriodicalEvents";
import { PeriodicalSchedule } from "../../../../../modules/Audit/PeriodicalSchedule";
import PeriodicalScheduleModal from "../../Modals/PeriodicalScheduleModal";

interface ManagePeriodicalSchedulesLgProps {
  // onRowClick: (inspecion: any) => void;
  data: PeriodicalSchedule.GetPeriodicalSchedule[];
  isLoading: boolean;
  templatesList: TemplateMeta.TemplateMeta[];
  setLastUpdate: (timestamp: number) => void;
  allEvents: PeriodicalEvents.GetEvents[];
}
export default function ManagePeriodicalSchedulesLg({
  data,
  isLoading,
  templatesList,
  setLastUpdate,
  allEvents,
}: ManagePeriodicalSchedulesLgProps) {
  const navigate = useNavigate();
  const { allUsers, locationList } = useBusinessContext();
  const form = useFormContext<PeriodicalSchedule.AddPeriodicSchedule>();
  const [selectedId, setSelectedId] = useState<number | undefined>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const deleteModal = useDisclosure();
  const toast = useToast({ position: "top" });
  // const [selectAll, setSelectAll] = useState(false);
  // const schedulebottom = useDisclosure();
  // const [selectedScheduleIds, setSelectedScheduleIds] = useState<number[]>([]);
  // const handleFormReset = (index: number) => {
  //   const selectedScheduleInspection = data[index];
  //   form.reset(selectedScheduleInspection);
  // };
  // const handleCheckboxClick = (scheduleId: number) => {
  //   if (selectedScheduleIds.includes(scheduleId)) {
  //     setSelectedScheduleIds(prevIds =>
  //       prevIds.filter(id => id !== scheduleId),
  //     );
  //   } else {
  //     setSelectedScheduleIds(prevIds => [...prevIds, scheduleId]);
  //     schedulebottom.onOpen();
  //   }
  // };
  return (
    <TableContainer
      border="1px solid"
      borderRadius="5px"
      borderColor="borders.accent"
    >
      <Table variant="simple">
        <Thead bg="backgrounds.primary">
          <Tr>
            {/* <Th w="40px" p="8px" borderColor="borders.accent">
              <Checkbox
                w="21px"
                h="21px"
                borderColor="grey"
                isChecked={
                  data.length > 0 && data.length === selectedScheduleIds.length
                }
                onChange={e => {
                  if (e.target.checked) {
                    const allScheduleIds = data.map(temp => temp.id);
                    setSelectedScheduleIds(allScheduleIds);
                    schedulebottom.onOpen();
                  } else {
                    setSelectedScheduleIds([]);
                  }
                }}
              />
            </Th> */}
            <Th
              display={{ sm: "none", md: "table-cell" }}
              maxW="400px"
              py="8px"
              px="16px"
              fontSize="13px"
              fontWeight={400}
              lineHeight="1rem"
              color="textcolors.primary"
              borderColor="borders.accent"
            >
              Events
            </Th>
            <Th
              display={{ sm: "none", md: "table-cell" }}
              maxW="200px"
              py="8px"
              px="16px"
              fontSize="13px"
              fontWeight={400}
              lineHeight="1rem"
              color="textcolors.primary"
              borderColor="borders.accent"
            >
              Location
            </Th>
            <Th
              display={{ sm: "none", md: "table-cell" }}
              w="200px"
              py="8px"
              px="16px"
              fontSize="13px"
              fontWeight={400}
              lineHeight="1rem"
              color="textcolors.primary"
              borderColor="borders.accent"
            >
              Employees
            </Th>
            <Th
              display={{ sm: "none", md: "table-cell" }}
              maxW="200px"
              py="8px"
              px="16px"
              fontSize="13px"
              fontWeight={400}
              lineHeight="1rem"
              color="textcolors.primary"
              textDecoration="underline"
              borderColor="borders.accent"
            >
              Frequency
            </Th>
            <Th
              display={{ sm: "none", md: "table-cell" }}
              maxW="200px"
              py="8px"
              px="16px"
              fontSize="13px"
              fontWeight={400}
              lineHeight="1rem"
              color="textcolors.primary"
              textDecoration="underline"
              borderColor="borders.accent"
            >
              Date Range
            </Th>
            <Th
              display={{ sm: "none", md: "table-cell" }}
              maxW="200px"
              py="8px"
              px="16px"
              fontSize="13px"
              fontWeight={400}
              lineHeight="1rem"
              color="textcolors.primary"
              textDecoration="underline"
              borderColor="borders.accent"
            ></Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <Tr>
              <Td px="10px" py="8px" borderColor={"borders.accent"} colSpan={6}>
                <Center>
                  <Spinner size="lg" color="secondary.500" />
                </Center>
              </Td>
            </Tr>
          ) : data.length > 0 ? (
            data.map((schpr, i) => {
              return (
                <Tr
                  onClick={() => {
                    //   onRowClick(inspection);
                  }}
                  backgroundColor="white"
                >
                  {/* <Td
                    maxW="40px"
                    py="16px"
                    px="8px"
                    borderTop="1px"
                    borderColor="borders.accent"
                    onClick={e => {
                      e.stopPropagation();
                    }}>
                    <Checkbox
                      w="21px"
                      h="21px"
                      borderColor="grey"
                      isChecked={selectedScheduleIds.includes(schIns.id)}
                      onChange={() => {
                        handleCheckboxClick(schIns.id);
                      }}
                    />
                  </Td> */}
                  <Td
                    display={{ sm: "none", md: "table-cell" }}
                    py="16px"
                    pl="16px"
                    borderTop="1px"
                    borderColor="borders.accent"
                    fontSize="14px"
                    color="textcolors.secondary"
                  >
                    {
                      allEvents.find((event) => event.id === schpr.activityId)
                        ?.name
                    }
                  </Td>
                  <Td
                    display={{ sm: "none", md: "table-cell" }}
                    py="16px"
                    pl="16px"
                    borderTop="1px"
                    borderColor="borders.accent"
                    fontSize="14px"
                    color="textcolors.secondary"
                  >
                    {
                      locationList?.find(
                        (location) => location.id === schpr.location
                      )?.name
                    }
                  </Td>
                  <Td
                    display={{ sm: "none", md: "table-cell" }}
                    py="16px"
                    pl="16px"
                    borderTop="1px"
                    borderColor="borders.accent"
                    fontSize="14px"
                    color="textcolors.secondary"
                  >
                    {schpr.employees
                      .map((emp) => {
                        return (
                          allUsers.find((user) => user?.user?.id === emp)?.user
                            ?.firstName +
                          " " +
                          allUsers.find((user) => user?.user?.id === emp)?.user
                            ?.lastName
                        );
                      })
                      .join(", ")}
                  </Td>
                  <Td
                    display={{ sm: "none", md: "table-cell" }}
                    py="16px"
                    pl="16px"
                    borderTop="1px"
                    borderColor="borders.accent"
                    fontSize="14px"
                    color="textcolors.secondary"
                  >
                    {schpr.frequency}
                  </Td>
                  <Td
                    display={{ sm: "none", md: "table-cell" }}
                    p="16px"
                    borderTop="1px"
                    borderColor="borders.accent"
                    fontSize="14px"
                    color="textcolors.primary"
                  >
                    {`${schpr.dateRange.startDate} - ${schpr.dateRange.endDate}`}
                  </Td>
                  <Td
                    px="10px"
                    py="8px"
                    borderColor="borders.accent"
                    textAlign="end"
                  >
                    <Menu placement="bottom-end">
                      <MenuButton
                        as={IconButton}
                        // borderRightRadius="8px"
                        backgroundColor="transparent"
                        _hover={{ backgroundColor: "transparent" }}
                        _active={{ backgroundColor: "transparent" }}
                        aria-label="dropdown menu"
                        icon={<FiSettings color="#545f70" size={20} />}
                      />
                      <MenuList minW="100px">
                        <MenuItem
                          fontSize="13px"
                          onClick={() => {
                            setSelectedId(schpr.id);
                            form.reset(schpr);
                            onOpen();
                          }}
                        >
                          Edit Schedule
                        </MenuItem>
                        <MenuItem
                          fontSize="13px"
                          onClick={() => {
                            setSelectedId(schpr.id);
                            deleteModal.onOpen();
                          }}
                        >
                          Delete
                        </MenuItem>
                        <Modal
                          isOpen={deleteModal.isOpen}
                          onClose={deleteModal.onClose}
                        >
                          <ModalOverlay />
                          <ModalContent mx="10px">
                            <ModalHeader fontWeight="semibold" py="4px">
                              Confirmation
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                              Are you sure you want to Delete this?
                            </ModalBody>

                            <ModalFooter
                              display="flex"
                              justifyContent="flex-end"
                              pt="16px"
                              border="0px"
                            >
                              <ButtonGroup
                                size="xs"
                                variant="solid"
                                borderRadius="5px"
                              >
                                <Button
                                  onClick={deleteModal.onClose}
                                  borderRadius="5px"
                                >
                                  No
                                </Button>
                                <Button
                                  colorScheme="red"
                                  borderRadius="5px"
                                  onClick={() => {
                                    if (selectedId) {
                                      PeriodicalSchedule.DeletePeriodicalSchedule(
                                        selectedId,
                                        () => {
                                          toast({
                                            title: "Success",
                                            description:
                                              "Schedule deleted sucessfully",
                                            status: "success",
                                          });
                                          setLastUpdate(Date.now());
                                          deleteModal.onClose();
                                        },
                                        (error) => {
                                          toast({
                                            title: "Error",
                                            description: error,
                                            status: "error",
                                          });
                                        }
                                      );
                                    }
                                  }}
                                >
                                  Yes
                                </Button>
                              </ButtonGroup>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td px="10px" py="8px" borderColor="borders.accent" colSpan={6}>
                <Center w="100%">
                  <Text>No Scheduled Inspections Found !</Text>
                </Center>
              </Td>
            </Tr>
          )}
          <PeriodicalScheduleModal
            isOpen={isOpen}
            onClose={onClose}
            selectedId={selectedId}
            templatesList={templatesList}
            setLastUpdate={setLastUpdate}
            listOfEvents={allEvents}
          />
        </Tbody>
        {/* <ManagePeriodicalScheduleBottomBar
          isOpen={schedulebottom.isOpen}
          onClose={schedulebottom.onClose}
          scheduleIds={selectedScheduleIds}
          setLastUpdate={setLastUpdate}
        /> */}
      </Table>
    </TableContainer>
  );
}
