import {
  Avatar,
  Button,
  HStack,
  Icon,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tag,
  Text,
  UnorderedList,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import moment from "moment-timezone";
import { useAuthHeader } from "react-auth-kit";
import { FormProvider, useForm } from "react-hook-form";
import { FiMoreVertical } from "react-icons/fi";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { EmployeeLeave } from "../../Api/EmployeeLeave";
import { EmployeeLeaveEntitlement } from "../../Api/EmployeeLeaveEntitlement";
import { Leaves } from "../../Api/Leaves";
import { PayItemLeaveApi } from "../../Api/PayItemLeave";
import LeaveForEmployee from "../EmployeeDashboard/LeaveForEmployee";
import CashOutLeave from "../EmployeeDetails/Modal/NewRequest/CashOutLeave";
import Leave from "../EmployeeDetails/Modal/NewRequest/Leave";
import RejectLeave from "./RejectLeave";

interface LeaveTagProps {
  leave: Leaves.fetchBusinessLeaves;
  setlastUpdated: (lastUpdated: number) => void;
  FetchLeaves: Array<PayItemLeaveApi.LeaveById>;
  lastUpdated: number;
  FetchLeavesEligibility?: Array<EmployeeLeaveEntitlement.LeaveEntitlement>;
}
export default function LeaveTag({
  leave,
  setlastUpdated,
  FetchLeaves,
  lastUpdated,
  FetchLeavesEligibility,
}: LeaveTagProps) {
  const { allEmployeesList } = useBusinessContext();
  const rejectLeave = useDisclosure();
  const cashOutLeave = useDisclosure();
  const leaveModal = useDisclosure();
  const toast = useToast();
  const form = useForm<{
    status: string;
    note: string;
  }>();
  const LeaveForm = useForm<EmployeeLeave.Leave>();
  const auth = useAuthHeader();

  const decodedToken = jwtDecode<any>(auth());

  return (
    <HStack
      border={"1px solid #e5e7eb"}
      padding={"12px 0px"}
      _hover={{
        bg: "#f2f3f4",
        cursor: "pointer",
      }}
      h={"fit-content"}
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      alignItems={"center"}
      borderRadius={"3px"}
      paddingRight={"20px"}
    >
      <Avatar
        name={
          allEmployeesList?.find((emp) => emp.id === leave.empId)?.firstName +
          " " +
          allEmployeesList?.find((emp) => emp.id === leave.empId)?.lastName
        }
        h={"38.5px"}
        w={"38.5px"}
        size={"sm"}
        fontWeight={"bold"}
        marginLeft={"8px"}
      />

      <Stack flex={1} direction={{ xs: "column", md: "row" }} gap={0}>
        <Stack spacing="0px">
          <Stack direction={{ xs: "row", md: "row" }}>
            <Text
              fontSize=".9975rem"
              fontWeight={"bold"}
              textAlign={"left"}
              _light={{
                color: "customColor.black7",
              }}
              _hover={{
                _light: {
                  color: "#3454d1",
                },
              }}
            >
              {allEmployeesList?.find((emp) => emp.id === leave.empId)
                ?.firstName +
                " " +
                allEmployeesList?.find((emp) => emp.id === leave.empId)
                  ?.lastName}
            </Text>
            <Stack display={{ xs: "flex", md: "none" }}>
              {leave.leaveCategory === "NORMAL_LEAVE" ? (
                <Text fontSize="13.44px" whiteSpace={"nowrap"}>
                  {moment(leave?.startDate).format("DD/MM/YYYY")}-{" "}
                  {moment(leave?.endDate).format("DD/MM/YYYY")}
                </Text>
              ) : (
                <Text
                  fontSize="13.44px"
                  _light={{
                    color: "customColor.black7",
                  }}
                  whiteSpace={"nowrap"}
                >
                  {moment(leave?.payoutDate).format("DD/MM/YYYY")}
                </Text>
              )}
            </Stack>
          </Stack>

          <HStack>
            <Stack display={{ xs: "none", md: "flex" }}>
              {leave.leaveCategory === "NORMAL_LEAVE" ? (
                <Text fontSize="13.44px" whiteSpace={"nowrap"}>
                  {moment(leave?.startDate).format("DD/MM/YYYY")}-{" "}
                  {moment(leave?.endDate).format("DD/MM/YYYY")}
                </Text>
              ) : (
                <Text
                  whiteSpace={"nowrap"}
                  fontSize="13.44px"
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  {moment(leave?.payoutDate).format("DD/MM/YYYY")}
                </Text>
              )}
            </Stack>
            <UnorderedList>
              <ListItem
                fontSize="13.44px"
                _light={{
                  color: "customColor.black7",
                }}
              >
                {leave?.description}
              </ListItem>
            </UnorderedList>

            <Text
              fontSize="13.44px"
              _light={{
                color: "customColor.black7",
              }}
            >{`(${leave.quantity} days)`}</Text>
          </HStack>
        </Stack>
        <HStack
          justifyContent={{ xs: "flex-start", md: "flex-end" }}
          flex={1}
          marginTop={{ xs: "5px", md: "0" }}
        >
          <Text
            lineHeight={"1.3077"}
            fontSize=".9975rem"
            _light={{
              color: "customColor.black7",
            }}
            _hover={{
              _light: {
                color: "#3454d1",
              },
            }}
            justifyContent={{ xs: "flex-start", md: "flex-end" }}
            fontWeight={"bold"}
            padding={{ xs: "0px 12px 0px 0px", md: "0px 12px 0px 12px" }}
            display={"flex"}
            flex={1}
          >
            {
              FetchLeaves.find(
                (FetchLeaves) => FetchLeaves.id === leave.payrollPayItemLeaveId
              )?.name
            }
          </Text>

          <Tag
            color={
              leave.status === "REJECTED"
                ? "#EA4D4D"
                : leave.status === "PENDING"
                ? "#3454d1" // Background color for pending
                : leave.status === "APPROVED"
                ? "#17C666"
                : "defaultBgColor" // fallback background color
            }
            padding="5px 6px"
            fontSize={"11px"}
            fontWeight={600}
            lineHeight={1.5}
            borderRadius={"3px"}
            backgroundColor={
              leave.status === "REJECTED"
                ? "#fdeded"
                : leave.status === "PENDING"
                ? "#ebeefa" // Background color for pending
                : leave.status === "APPROVED"
                ? "#e1fbed"
                : "defaultBgColor" // fallback background color
            }
          >
            {leave.status}
          </Tag>

          {/* )} */}

          {decodedToken.accessLevel !== "EMPLOYEE" && (
            <Menu>
              <MenuButton
                as={Button}
                padding={"0"}
                backgroundColor={"transparent"}
                marginLeft={"8px"}
                _hover={{ backgroundColor: "transparent" }}
                _active={{ backgroundColor: "transparent" }}
                _focus={{ backgroundColor: "transparent" }}
              >
                <Icon
                  as={FiMoreVertical}
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="50%"
                  borderStyle={"solid"}
                  borderWidth={"1px"}
                  borderColor={"borders.tableBorder"}
                  bg={"#fff"}
                  w="25px"
                  h="25px"
                  px="5px"
                  py="5px"
                  fontSize="22px"
                  color="black"
                  _hover={{
                    color: "#3454d1",
                  }}
                />
              </MenuButton>
              <MenuList style={{ minWidth: "140px" }}>
                <MenuItem
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  onClick={() => {
                    LeaveForm.reset({
                      empId: leave.empId,
                      id: leave.id,
                      description: leave.description,
                      leaveCategory: leave.leaveCategory,
                      endDate: leave.endDate,
                      quantity: leave.quantity,
                      note: leave.note,
                      payoutDate: leave.payoutDate,
                      payrollPayItemLeaveId: leave.payrollPayItemLeaveId,
                      startDate: leave.startDate,
                      status: leave.status,
                      leaveEntitlementId: leave.leaveEntitlementId,
                      payPeriods: leave.payPeriods,
                    });

                    leave.leaveCategory === "NORMAL_LEAVE"
                      ? leaveModal.onOpen()
                      : cashOutLeave.onOpen();
                  }}
                >
                  Edit
                </MenuItem>
                {decodedToken.accessLevel !== "EMPLOYEE" &&
                  (leave.status === "PENDING" ||
                    leave.status === "REJECTED") && (
                    <MenuItem
                      fontSize={"0.8125rem"}
                      fontWeight={"600"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      onClick={() => {
                        Leaves.changeLeaveStatus(
                          leave.id,
                          {
                            status: "APPROVED",
                            note: "Leave Approved",
                          },
                          (success) => {
                            toast({
                              title: "Success",
                              description: success,
                              status: "success",
                            });
                            setlastUpdated(Date.now());
                          },
                          (err) => {
                            toast({
                              title: "Error",
                              description: err,
                              status: "error",
                            });
                          }
                        );
                      }}
                    >
                      Approve
                    </MenuItem>
                  )}
                {decodedToken.accessLevel !== "EMPLOYEE" &&
                  (leave.status === "PENDING" ||
                    leave.status === "APPROVED") && (
                    <MenuItem
                      fontSize={"0.8125rem"}
                      fontWeight={"600"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      onClick={() => {
                        rejectLeave.onOpen();
                      }}
                    >
                      Reject
                    </MenuItem>
                  )}
              </MenuList>
            </Menu>
          )}
        </HStack>

        <FormProvider {...form}>
          <RejectLeave
            isOpen={rejectLeave.isOpen}
            onClose={rejectLeave.onClose}
            leaveId={leave.id}
            setlastUpdated={setlastUpdated}
          />
        </FormProvider>
        <FormProvider {...LeaveForm}>
          <CashOutLeave
            isOpen={cashOutLeave.isOpen}
            onClose={cashOutLeave.onClose}
            setLastUpdated={setlastUpdated}
            employeeId={leave.empId}
            FecthedLeaves={FetchLeaves}
          />
          {decodedToken.accessLevel !== "EMPLOYEE" && (
            <Leave
              isOpen={leaveModal.isOpen}
              onClose={leaveModal.onClose}
              setLastUpdated={setlastUpdated}
              employeeId={leave.empId}
              FecthedLeaves={FetchLeaves}
              lastUpdated={lastUpdated}
            />
          )}
          {decodedToken.accessLevel === "EMPLOYEE" && (
            <LeaveForEmployee
              isOpen={leaveModal.isOpen}
              onClose={leaveModal.onClose}
              setLastUpdated={setlastUpdated}
              employeeId={leave.empId}
              FecthedLeaves={FetchLeaves}
              lastUpdated={lastUpdated}
              FetchLeavesEligibility={FetchLeavesEligibility}
            />
          )}
        </FormProvider>
      </Stack>
    </HStack>
  );
}
