import {
  Button,
  Collapse,
  Container,
  HStack,
  IconButton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {useMemo} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {FiChevronDown, FiChevronRight} from 'react-icons/fi';
import {Report} from '../../../../../../modules/Audit/Report';
import AnswersType from './AnswersType';

interface ReportPageProps {
  pageIndex: number;
}
export default function ReportPage({pageIndex}: ReportPageProps) {
  const {isOpen, onToggle} = useDisclosure();
  const report = useFormContext<Report.IReport>();
  const itemsArr = useFieldArray({
    control: report.control,
    name: `pages.${pageIndex}.data`,
  });
  const pageScore = useMemo(() => {
    return itemsArr.fields.reduce((acc, item) => {
      if (item.section_id) {
        return (
          acc +
          (item.questions ?? [])?.reduce((prev, secItem) => {
            return prev + +(secItem?.question_score ?? 0);
          }, 0)
        );
      }
      return acc + +(item?.question_score ?? 0);
    }, 0);
  }, []);
  const totalPageScore = useMemo(() => {
    return itemsArr.fields.reduce((acc, item) => {
      if (item.section_id) {
        return (
          acc +
          (item.questions ?? [])?.reduce((prev, secItem) => {
            if (
              // +(!!secItem?.question_score ?? '-1') > -1 ||
              // secItem.question_value === 'NOT_SELECTED'
              !!secItem?.question_value &&
              secItem?.question_score === null
            ) {
              return prev;
            } else {
              return prev + +(secItem?.question_max_score ?? 0);
            }
          }, 0)
        );
      }

      if (
        // +(item?.question_score ?? '-1') > -1 ||
        // item.question_value === 'NOT_SELECTED'
        !!item?.question_value &&
        item?.question_score === null
      ) {
        return acc;
      } else {
        return acc + +(item?.question_max_score ?? 0);
      }
    }, 0);
  }, []);

  const scoreInPercentage = ((pageScore / totalPageScore) * 100).toFixed(2);
  return (
    <Container
      p="16px"
      maxW="container.md"
      borderRadius="16px"
      backgroundColor="backgrounds.primary"
      lineHeight={1}>
      <HStack spacing={0}>
        <Button
          marginRight="8px"
          as={IconButton}
          justifyContent="start"
          p={0}
          aria-label={'dropdown'}
          backgroundColor="transparent"
          _hover={{backgroundColor: 'transparent'}}
          onClick={onToggle}
          icon={
            isOpen ? (
              <FiChevronDown color={'#828ea0'} size={24} />
            ) : (
              <FiChevronRight color={'#828ea0'} size={24} />
            )
          }
        />

        <Text flex={1} fontSize="21px" fontWeight={500} color="#3f495a">
          {report.getValues(`pages.${pageIndex}.page_title`)}
        </Text>

        {!Number.isNaN(+scoreInPercentage) && (
          <Text fontSize="14px" color="icons.primary">
            {`${scoreInPercentage} %`}
          </Text>
        )}
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        <Stack>
          {itemsArr.fields.map((item, itemIndex) => {
            if ((item.section_id ?? 0) > 0) {
              return (
                <Section
                  key={itemIndex}
                  sectionIndex={itemIndex}
                  pageIndex={pageIndex}
                />
              );
            } else {
              return (
                <AnswersType {...item} key={itemIndex} />

                // <Stack
                //   key={itemIndex}
                //   direction="column"
                //   mt="16px"
                //   padding="16px"
                //   backgroundColor="white"
                //   borderRadius="16px">
                //   <Text
                //     fontSize="16px"
                //     fontWeight={400}
                //     color="textcolors.primary">
                //     {item?.question_title}
                //   </Text>
                //   <Text
                //     fontSize="16px"
                //     fontWeight={400}
                //     color="#828ea0"
                //     lineHeight={1}>
                //     {item?.question_value}
                //   </Text>
                // </Stack>
              );
            }
          })}
        </Stack>
      </Collapse>
    </Container>
  );
}

interface SectionProps {
  sectionIndex: number;
  pageIndex: number;
}
export function Section({sectionIndex, pageIndex}: SectionProps) {
  const {isOpen, onToggle} = useDisclosure();
  const report = useFormContext<Report.IReport>();
  const questionsArr = useFieldArray({
    control: report.control,
    name: `pages.${pageIndex}.data.${sectionIndex}.questions` as any,
    keyName: 'uniqueId' as any,
  });

  const sectionScore = useMemo(() => {
    return questionsArr.fields.reduce((acc, item) => {
      if (item?.question_score) {
        return acc + +item.question_score;
      }
      return acc;
    }, 0);
  }, []);
  const totalSectionScore = useMemo(() => {
    return questionsArr.fields.reduce((acc, item) => {
      if (
        // +(item?.question_score ?? '-1') > -1 ||
        // item.question_value === 'NOT_SELECTED'
        !!item?.question_value &&
        item?.question_score === null
      ) {
        return acc;
      }
      return acc + +(item?.question_max_score ?? 0);
    }, 0);
  }, []);

  const sectionScoreInPercentage = (
    (sectionScore / totalSectionScore) *
    100
  ).toFixed(2);

  return (
    <>
      <HStack onClick={onToggle} justifyContent="space-between">
        <HStack>
          <IconButton
            marginRight="8px"
            p={0}
            aria-label={'dropdown'}
            colorScheme="primary"
            borderRadius="50%"
            // _hover={{backgroundColor: 'transparent'}}
            icon={
              isOpen ? (
                <FiChevronDown color={'#FFFFFF'} size={24} />
              ) : (
                <FiChevronRight color={'#FFFFFF'} size={24} />
              )
            }
          />
          <Text fontWeight={500}>
            {report.getValues(
              `pages.${pageIndex}.data.${sectionIndex}.section_title`,
            )}
          </Text>
        </HStack>

        {!Number.isNaN(+sectionScoreInPercentage) && (
          <Text fontSize="14px" color="icons.primary">
            {`${sectionScoreInPercentage} %`}
          </Text>
        )}
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        <Stack>
          {(
            questionsArr.fields as unknown as Array<Report.IReportPageData>
          )?.map(question => (
            <AnswersType {...question} />
          ))}
        </Stack>
      </Collapse>
    </>
  );
}

export function Question() {
  return (
    <Stack backgroundColor="white" borderRadius="16px" p="16px" mt="16px">
      <Text fontSize="16px" fontWeight={400} color="#828ea0" lineHeight={1}>
        Unanswered
      </Text>
    </Stack>
  );
}
