import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Input,
  Stack,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { ReactSelect } from "../../../../../../../Common/ReactSelect";
import { InventoryModule } from "../../../../../../modules/Audit/Inventory";
import { InventoryCategoryModule } from "../../../../../../modules/Audit/InventoryCategories";
import InventoryImage from "./InventoryImage";

interface UploadInventoryDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  inventoryTypes:
    | Array<InventoryCategoryModule.FetchInventoryCategoryResponse>
    | undefined;
  setLastUpdate: (timestamp: number) => void;
}
export default function UploadInventoryDrawer({
  isOpen,
  onClose,
  inventoryTypes,
  setLastUpdate,
}: UploadInventoryDrawerProps) {
  const toast = useToast();
  const form = useForm<InventoryModule.AddInventory>({
    defaultValues: {
      inventory: [
        {
          name: "",
          description: "",
          mediaUrl: "",
        },
      ],
    },
  });
  const ItemssArr = useFieldArray({
    control: form.control,
    name: "inventory",
    keyName: "itemId",
  });

  const [searchText, setSearchText] = useState("");
  const filteredItems = useCallback(() => {
    return ItemssArr.fields.filter((item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, ItemssArr.fields]);

  useEffect(() => {
    form.setValue("inventory", []);
  }, [isOpen]);
  const [isLoading, setLoading] = useState<boolean>(false);
  return (
    <FormProvider {...form}>
      <Drawer
        isOpen={isOpen}
        placement="right"
        size={{ xs: "full", md: "md" }}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent
          backgroundColor={"backgrounds.header"}
          overflowY="auto"
          minH="100vh"
        >
          <DrawerHeader
            padding={"16px 0px 0px 0px"}
            borderBottom={"2px solid"}
            borderColor={"borders.accent"}
          >
            <VStack w="100%" spacing={0} h="50px">
              {/* <Box ml="auto" padding="0px 24px 14px 0px">
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<FiMoreVertical size={18} color="#1f2533" />}
                  variant="outline"
                  borderRadius={'50%'}
                  border={'none'}
                  _hover={{backgroundColor: '#bfc6d4'}}
                  _active={{
                    backgroundColor: '#bfc6d4',
                  }}
                  onClick={() => {
                    console.log(searchText);
                  }}
                />
                <MenuList padding="10px 0px 10px 0px" shadow="lg">
                  <MenuItem
                    padding="8px 16px"
                    fontSize="16px"
                    color="textcolors.primary"
                    onClick={uploadcsv.onOpen}>
                    <HStack>
                      <Icon as={IoMdCloudUpload} boxSize={'18px'} />
                      <Text>Upload responses as CSV</Text>
                    </HStack>
                  </MenuItem>
                  <UploadCsvModal
                    isOpen={uploadcsv.isOpen}
                    onClose={uploadcsv.onClose}
                  />

                  <MenuItem
                    padding="8px 16px"
                    fontSize="16px"
                    color="textcolors.primary">
                    <HStack>
                      <Icon as={MdDelete} boxSize={'18px'} />
                      <Text>Delete</Text>
                    </HStack>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box> */}

              <Box w="100%" padding="0px 24px 0px 24px">
                {" "}
                Add Inventory Items
              </Box>
            </VStack>
          </DrawerHeader>

          <DrawerBody padding="0px">
            {filteredItems().map((item, i) => {
              return (
                <HStack
                  key={item.itemId}
                  w="100%"
                  spacing={0}
                  minH="47px"
                  padding="5px"
                  backgroundColor="white"
                  borderBottom={"1px solid"}
                  borderColor={"borders.accent"}
                  align="flex-start"
                >
                  <HStack flex={"auto"}>
                    <Box w="80px" h="50px">
                      <InventoryImage index={i} />
                    </Box>
                    <Box w="180px">
                      <Controller
                        control={form.control}
                        name={`inventory.${i}.name`}
                        rules={{
                          required: {
                            value: true,
                            message: "Item name is required",
                          },
                          // onBlur: () => {
                          //   setIconVisible(false);
                          // },
                        }}
                        render={({ field, fieldState: { error } }) => (
                          // <FormControl isInvalid={error !== undefined}>
                          <Input
                            isInvalid={error !== undefined}
                            h="38px"
                            padding="5px"
                            w="100%"
                            border="0px"
                            // onFocus={e => {
                            //   setIconVisible(true);
                            // }}
                            placeholder="Enter item"
                            _focusVisible={{
                              boxShadow: "0px",
                              border: "1px solid",
                              borderColor: "primary.500",
                            }}
                            {...field}
                          />
                          //   <FormErrorMessage>{error?.message}</FormErrorMessage>
                          // </FormControl>
                        )}
                      />
                    </Box>
                    <Box
                      w="180px"
                      // display="flex"
                      // alignItems="center"
                      // justifyContent="center"
                      mt="8px"
                    >
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Category is required",
                          },
                        }}
                        name={`inventory.${i}.inventoryCategoryId`}
                        control={form.control}
                        render={({ field, fieldState }) => (
                          <ReactSelect
                            key={field.value}
                            {...field}
                            withValidation
                            isInvalid={fieldState.invalid}
                            placeholder="Select Category"
                            ErrorMessage="Category is required"
                            options={inventoryTypes}
                            value={
                              field.value
                                ? {
                                    id: field.value,
                                    name:
                                      inventoryTypes?.find(
                                        (type) => type?.id === field.value
                                      )?.name ?? "",
                                  }
                                : undefined
                            }
                            onChange={(val: any) => {
                              field.onChange(val?.id);
                            }}
                            getOptionLabel={(option: any) => option?.name}
                            getOptionValue={(option: any) => option?.id}
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      <IconButton
                        backgroundColor="transparent"
                        margin="8px 8px 0px 8px"
                        borderRadius={"50%"}
                        border={"none"}
                        size="22.5px"
                        _hover={{ backgroundColor: "#bfc6d4" }}
                        aria-label="delete"
                        icon={<MdDelete color="#545f70" size="22.5px" />}
                        onClick={() => {
                          ItemssArr.remove(i);
                        }}
                      />
                    </Box>
                  </HStack>
                </HStack>
              );
            })}
          </DrawerBody>

          <DrawerFooter
            padding="0px"
            backgroundColor={"backgrounds.header"}
            flexDirection="column"
          >
            <Stack spacing={0} w="100%" direction="column">
              <Stack
                w="100%"
                h="58.6px"
                padding="8px"
                backgroundColor="white"
                border="1px solid"
                borderColor={"borders.accent"}
              >
                <Button
                  w="150px"
                  h="41px"
                  border="0px"
                  borderRadius="5px"
                  leftIcon={<FiPlus />}
                  variant="outline"
                  onClick={() => {
                    ItemssArr.append({
                      name: "",
                      inventoryCategoryId: null,
                      mediaUrl: "",
                    });
                  }}
                >
                  Add Item
                </Button>
              </Stack>

              <Box flexDirection={{ xs: "column", md: "row" }} padding="17.6px">
                <Button
                  isLoading={isLoading}
                  h="40px"
                  w="192px"
                  borderRadius="5px"
                  fontSize="14px"
                  fontWeight={500}
                  marginRight="16px"
                  padding={"12px 20px"}
                  onClick={form.handleSubmit((data) => {
                    setLoading(true);
                    data.inventory.length > 0
                      ? InventoryModule.UploadInventory(
                          data.inventory,
                          () => {
                            toast({
                              title: "Success",
                              description: "Items uploaded successfully",
                              status: "success",
                            });
                            setLoading(false);
                            setLastUpdate(Date.now());
                            form.reset({});
                            onClose();
                          },
                          (error) => {
                            toast({
                              title: "Error",
                              description: error,
                              status: "error",
                            });
                          }
                        )
                      : toast({
                          title: "Error",
                          description: "Atleast one item is required",
                          status: "error",
                        });
                  })}
                >
                  Save and apply
                </Button>
                <Button
                  h="40px"
                  borderRadius="5px"
                  fontSize="14px"
                  fontWeight={500}
                  variant="outline"
                  padding={"12px 20px"}
                  onClick={() => {
                    form.setValue("inventory", []);
                    onClose();
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Stack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </FormProvider>
  );
}
