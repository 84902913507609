import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Heading,
  HStack,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StatutoryBenefitsApi } from "../../Api/StautoryBenefitsApi";
import ApproveSuperannuation from "./ApproveModal";
import DeleteSuperannuation from "./DeleteSuperannuationModal";
import Header from "./Header";
import ProcessSuperannuation from "./ProcessModal";

export function SuperannuationPayment() {
  const deleteModal = useDisclosure();
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  const approveModal = useDisclosure();
  const processModal = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();

  const param = useParams();
  const id = param.id;
  const [statutoryBenefits, setStatutoryBenefits] =
    useState<StatutoryBenefitsApi.GetPaymentData>();
  useEffect(() => {
    StatutoryBenefitsApi.GetPaymentsDetails(
      id!,
      (data) => {
        setStatutoryBenefits(data);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      }
    );
  }, []);
  return (
    <Stack
      h="calc(100vh - 70px)"
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      gap={0}
    >
      <Container
        h="64px"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        maxW="100%"
        justifyContent={"center"}
        borderBottom={"1px solid #cfd2d4"}
        padding={"0px 20px"}
      >
        <Breadcrumb
          separator={<ChevronRightIcon color="gray.500" />}
          paddingTop={"5px"}
        >
          <BreadcrumbItem>
            <BreadcrumbLink
              href="/app/payroll/statuary-benefits"
              fontSize={"11px"}
              color={"primary.950"}
            >
              Statuary Benefits
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              href=""
              fontSize={"11px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              padding={"0px 20px 0px 0px"}
            >
              New Payment
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Heading
          fontWeight={"bold"}
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.white",
          }}
          fontSize={"24px"}
          margin={"0px 4px 0px 0px"}
        >
          Statuary Benefits Payment Details
        </Heading>
        {/* <Divider padding="10px" orientation="horizontal" /> */}
      </Container>

      <Header
        status={statutoryBenefits?.status}
        total={statutoryBenefits?.amount}
      />

      <Stack paddingLeft={"20px"} paddingRight={"20px"} marginBottom={"20px"}>
        <Container maxW={"container.xl"} p={0}>
          <Stack
            _light={{
              color: "customColor.black7",
              bgColor: "customColor.white",
            }}
            fontSize={"16px"}
            _dark={{
              bgColor: "customColor.dark.50",
              color: "customColor.dark.250",
            }}
            gap={0}
            border="1px solid #f6f6f6"
            borderRadius="4px"
            boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          >
            <Box overflowX="auto" p="0px">
              <Table variant="simple" w="100%" overflowX="auto">
                <Thead h={"36.8px"}>
                  <Tr
                    borderTopWidth={"1px"}
                    borderTopColor="borders.tableBorder"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                  >
                    {[
                      {
                        name: "Period",
                        value: "30%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                      {
                        name: "Due Date",
                        value: "20%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                      {
                        name: "Employee",
                        value: "25%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                      {
                        name: "Type",
                        value: "15%",
                        paddingValue: "8px 26px 8px 15px",
                      },

                      {
                        name: "Amount",
                        value: "10%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                    ].map((item, i) => {
                      return (
                        <Th
                          key={i}
                          padding={item.paddingValue}
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                          width={item.value}
                        >
                          {item.name}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {statutoryBenefits &&
                    statutoryBenefits.statutoryBenefits?.map((item, i) => {
                      return (
                        <Tr
                          key={i}
                          _hover={{
                            bgColor: "#f2f3f8",
                            cursor: "pointer",
                          }}
                        >
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Text>{`${
                              item.payrun.payrollCalendar.name
                            } ending ${moment(item.payrun.endDate).format(
                              "DD MMM YYYY"
                            )}`}</Text>
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Text>
                              {moment(item.dueDate).format("DD MMM YYYY")}
                            </Text>
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Text>{`${item.employee.firstName} ${item.employee.lastName}`}</Text>
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Text>
                              {item.type ===
                              StatutoryBenefitsApi
                                .PayrollPayItemRetirementCategoryEnum
                                .EMPLOYER_EOBI
                                ? "Employer Eobi"
                                : item.type ===
                                  StatutoryBenefitsApi
                                    .PayrollPayItemRetirementCategoryEnum
                                    .EMPLOYER_PROVIDENT_FUND
                                ? "Employer Provident Fund"
                                : item.type ===
                                  StatutoryBenefitsApi
                                    .PayrollPayItemRetirementCategoryEnum
                                    .GRATUITY
                                ? "Gratuity"
                                : item.type ===
                                  StatutoryBenefitsApi
                                    .PayrollPayItemRetirementCategoryEnum
                                    .SOCIAL_SECURITY
                                ? "Social Security"
                                : ""}
                            </Text>
                          </Td>

                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Text>{(item.amount ?? "0").toLocaleString()}</Text>
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </Box>
          </Stack>
          <HStack alignItems={"center"} justifyContent={"flex-end"} gap={0}>
            {statutoryBenefits?.status ===
              StatutoryBenefitsApi.StatutoryBenefitStatusEnum
                .PENDING_APPROVAL && (
              <Stack
                alignItems={"center"}
                justifyContent={"flex-end"}
                direction={"row"}
                paddingTop={"20px"}
                paddingLeft={"20px"}
                paddingBottom={"20px"}
              >
                <Button
                  variant="solid"
                  fontSize={"13px"}
                  h={"32px"}
                  margin={"0px 0px 0px 10px"}
                  padding={"10px 15px"}
                  borderRadius={"3px"}
                  border={"1px solid #FDEDED"}
                  bg={"#FDEDED"}
                  color={"#EA4D4D"}
                  minW={"75px"}
                  _hover={{
                    border: "1px solid #EA4D4D",
                    bg: "#EA4D4D",
                    color: "#fff",
                  }}
                  onClick={() => {
                    deleteModal.onOpen();
                  }}
                >
                  Delete Payment
                </Button>
                <Button
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  borderRadius={"3px"}
                  h={"32px"}
                  borderWidth={"1px"}
                  marginRight={"8px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  // onClick={() => {
                  //   approveModal.onOpen();
                  // }}
                  onClick={(e) => {
                    StatutoryBenefitsApi.GenerateApprovalCodeApi(
                      id!,
                      (success) => {
                        toast({
                          title: "Code is sent via Email",
                          status: "success",
                        });
                        setLastUpdated(Date.now());
                        approveModal.onOpen();
                      },
                      (error) => {
                        toast({
                          title: "Failed to Email with Code",
                          description: error,
                          status: "error",
                        });
                      }
                    );
                  }}
                >
                  Approval
                </Button>
              </Stack>
            )}
            {statutoryBenefits?.status ===
              StatutoryBenefitsApi.StatutoryBenefitStatusEnum.APPROVED && (
              <Stack
                alignItems={"center"}
                justifyContent={"flex-end"}
                direction={"row"}
                paddingTop={"20px"}
                paddingLeft={"20px"}
                paddingBottom={"20px"}
              >
                <Button
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  borderRadius={"3px"}
                  h={"32px"}
                  marginRight={"8px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  onClick={() => {
                    processModal.onOpen();
                  }}
                >
                  Make it Process
                </Button>
              </Stack>
            )}
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"32px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => {
                navigate("/app/payroll/statuary-benefits");
              }}
            >
              Cancel
            </Button>
          </HStack>
        </Container>
      </Stack>
      <DeleteSuperannuation
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
        id={id}
        setLastUpdated={setLastUpdated}
      />
      <ApproveSuperannuation
        selectedIds={id}
        isOpen={approveModal.isOpen}
        setLastUpdated={setLastUpdated}
        onClose={approveModal.onClose}
      />
      <ProcessSuperannuation
        isOpen={processModal.isOpen}
        setLastUpdated={setLastUpdated}
        onClose={processModal.onClose}
      />
    </Stack>
  );
}
