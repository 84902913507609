import {Box, Text} from '@chakra-ui/react';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {ReportLayout} from '../../../../../modules/Audit/ReportLayout';
import {DraggableItem} from './DraggableItem';
import DraggableQuestion from './DraggableQuestion';
export function DraggableSection({
  title,
  children = [],
  onItemsChanged,
  pageIndex,
  sectionIndex,
}: ReportLayout.ReportStructureItem & {
  onItemsChanged: (
    updatedChildrenArray: ReportLayout.ReportStructureItem[],
  ) => void;
  pageIndex: number;
  sectionIndex: number;
}) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;

    if (active.id !== over?.id) {
      const oldIndex = children?.findIndex(item => item.id === active.id);
      const newIndex = children?.findIndex(item => item.id === over?.id);
      let updatedChildrenArray = arrayMove(children, oldIndex, newIndex);
      updatedChildrenArray = updatedChildrenArray.map((item, index) => ({
        ...item,
        orderIndex: index,
      }));

      onItemsChanged(updatedChildrenArray);
    }
  }

  return (
    <Box marginTop="20px">
      <Text
        fontSize="14px"
        fontWeight={500}
        color="textcolors.primary"
        lineHeight={1}>
        {title} - Section
      </Text>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}>
        <SortableContext
          items={children as any}
          strategy={verticalListSortingStrategy}>
          {children.map((item, childIndex) => (
            <DraggableItem
              key={item.id}
              id={item.id}
              pageIndex={pageIndex}
              sectionIndex={sectionIndex}
              questionIndex={childIndex}>
              <DraggableQuestion
                {...item}
                pageIndex={pageIndex}
                sectionIndex={sectionIndex}
                questionIndex={childIndex}
              />
            </DraggableItem>
          ))}
        </SortableContext>
      </DndContext>
    </Box>
  );
}
