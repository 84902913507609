import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Container,
  HStack,
  IconButton,
  Image,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { Report } from "../../../../../../modules/Audit";

export function Overview() {
  const { isOpen, onToggle } = useDisclosure();
  const report = useFormContext<Report.IReport>();
  return (
    <Container
      p="16px"
      maxW="container.md"
      borderRadius="16px"
      backgroundColor="backgrounds.primary"
      lineHeight={1}
    >
      <HStack spacing={0}>
        <Button
          marginRight="8px"
          as={IconButton}
          justifyContent="start"
          p={0}
          aria-label={"dropdown"}
          backgroundColor="transparent"
          _hover={{ backgroundColor: "transparent" }}
          onClick={onToggle}
          icon={
            isOpen ? (
              <FiChevronDown color={"#828ea0"} size={24} />
            ) : (
              <FiChevronRight color={"#828ea0"} size={24} />
            )
          }
        />
        <Text flex={1} fontSize="21px" fontWeight={500} color="#3f495a">
          Overview
        </Text>
        <Text color="#007a52" fontSize="16px" fontWeight={400}>
          Complete
        </Text>
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        <Stack spacing={0} mt="16px">
          <Stack
            backgroundColor="white"
            px="16px"
            pt="16px"
            pb="24px"
            spacing="8px"
            borderTopRadius="16px"
          >
            {report.getValues("template.image") && (
              <Image
                h="200px"
                w="200px"
                borderRadius="8px"
                src={report.getValues("template.image")}
              />
            )}

            <Text
              fontSize="24px"
              fontWeight={500}
              color="textcolors.primary"
              pt="16px"
            >
              {report.getValues("template.title")}
            </Text>
            {/* <InspectionTitleModal /> */}

            {/* <Text fontSize="16px" fontWeight={400} color="icons.primary">
              {`${moment(
                report.getValues('templateMeta.inspectionDate'),
              ).format('DD MMM YYYY')} / ${report.getValues(
                'templateMeta.site',
              )} / ${report.getValues('templateMeta.person')}`}
            </Text> */}

            <HStack
              divider={
                <Text fontSize="16px" fontWeight={400} color="icons.primary">
                  /
                </Text>
              }
            >
              {report.getValues("templateMeta.inspectionDate") && (
                <Text fontSize="16px" fontWeight={400} color="icons.primary">
                  {moment(
                    report.getValues("templateMeta.inspectionDate")
                  ).format("DD MMM YYYY")}
                </Text>
              )}
              {report.getValues("templateMeta.site") && (
                <Text fontSize="16px" fontWeight={400} color="icons.primary">
                  {report.getValues("templateMeta.site")}
                </Text>
              )}
              {report.getValues("templateMeta.person") && (
                <Text fontSize="16px" fontWeight={400} color="icons.primary">
                  {report.getValues("templateMeta.person")}
                </Text>
              )}
            </HStack>
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={0}
            backgroundColor="white"
            borderBottomRadius="16px"
          >
            <Box
              border="1px solid"
              borderColor="borders.accent"
              borderRadius={{ xs: "16px", md: "0px" }}
              borderRightWidth={{ xs: "1px", md: "0px" }}
              borderLeftWidth={{ xs: "1px", md: "0px" }}
              borderBottomWidth={{ xs: "1px", md: "0px" }}
              padding="16px"
              flex={1}
            >
              <Text fontSize="13px" fontWeight={400} color="icons.primary">
                Inspection score
              </Text>
              <Text
                pt="12px"
                fontSize="24px"
                fontWeight={500}
                color="textcolors.primary"
              >
                {/* {averageScore ? (averageScore.toFixed(2)) : ('0')} % */}
                {Number(
                  report.getValues("templateMeta.inspectionScore")
                ).toFixed(2)}{" "}
                %
              </Text>
            </Box>
            <Box
              border="1px solid"
              borderColor="borders.accent"
              padding="16px"
              flex={1}
              borderRadius={{ xs: "16px", md: "0px" }}
              borderRightWidth={{ xs: "1px", md: "0px" }}
              borderBottomWidth={{ xs: "1px", md: "0px" }}
            >
              <Text fontSize="13px" fontWeight={400} color="icons.primary">
                Flagged items
              </Text>
              <Text
                pt="12px"
                fontSize="24px"
                fontWeight={500}
                color="textcolors.primary"
              >
                {report.getValues("flaggedItems")?.length || 0}
              </Text>
            </Box>
            <Box
              border="1px solid"
              borderColor="borders.accent"
              padding="16px"
              flex={1}
              borderRadius={{ xs: "16px", md: "0px" }}
              borderRightWidth={{ xs: "1px", md: "0px" }}
              borderBottomWidth={{ xs: "1px", md: "0px" }}
            >
              <Text fontSize="13px" fontWeight={400} color="icons.primary">
                Created actions
              </Text>
              <Text
                pt="12px"
                fontSize="24px"
                fontWeight={500}
                color="textcolors.primary"
              >
                NA
              </Text>
            </Box>
          </Stack>
        </Stack>

        {/* 
        <Divider my="16px" borderWidth="1px" borderColor="borders.accent" />
        <OverviewCollapse />
        <Person label="person" />
        <Unanswered />
        <TitleAnswer label="inspection" />
        <CheckBox />
        <Person label="slider" />
        <Stack
          background="backgrounds.header"
          borderRadius="16px"
          p="16px"
          mt="16px">
          <Text
            fontSize="16px"
            fontWeight={400}
            color="textcolors.primary"
            lineHeight={1}>
            instruction
          </Text>
        </Stack> */}
      </Collapse>
    </Container>
  );
}

interface Titleprops {
  label: string;
  value?: any;
}
export function TitleAnswer({ label, value }: Titleprops) {
  return (
    <Stack
      direction="column"
      mt="16px"
      padding="16px"
      backgroundColor="white"
      borderRadius="16px"
    >
      <Text fontSize="13px" fontWeight={400} color="icons.primary">
        {label}
      </Text>
      <Text fontSize="16px" fontWeight={400} color="#828ea0" lineHeight={1}>
        {value ?? "Unanswered"}
      </Text>
    </Stack>
  );
}
interface personprops {
  label: String;
}
export function Person({ label }: personprops) {
  return (
    <Stack backgroundColor="white" borderRadius="16px" p="16px" mt="16px">
      <Text
        fontSize="16px"
        fontWeight={400}
        color="textcolors.primary"
        lineHeight={1}
      >
        {label}
      </Text>
      <Text
        fontSize="24px"
        fontWeight={500}
        color="textcolors.primary"
        lineHeight="32px"
      >
        0
      </Text>
      <Text
        fontSize="13px"
        fontWeight={400}
        color="icons.primary"
        lineHeight={1}
      >
        From 1 to 10
      </Text>
    </Stack>
  );
}

export function CheckBox() {
  return (
    <Stack backgroundColor="white" borderRadius="16px" p="16px" mt="16px">
      <Checkbox isChecked={true} isDisabled={true}>
        checkbox
      </Checkbox>
    </Stack>
  );
}
