import {
  Button,
  Container,
  HStack,
  Icon,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { CustomInput } from "../../../../Common/CustomInput";
import { ContactApi } from "../../../Api/Contact";

interface ContactProp {
  contactDetailsRef: React.RefObject<HTMLDivElement>;
}
export function ContactDetail({ contactDetailsRef }: ContactProp) {
  const form = useFormContext<ContactApi.AddContact>();
  const contactComponent = useFieldArray({
    control: form.control,
    name: "additionalPersons",
  });
  return (
    <Stack
      ref={contactDetailsRef}
      _dark={{ bgColor: "customColor.dark.50" }}
      _light={{ bgColor: "customColor.white" }}
      paddingBottom={"30px"}
      borderTop={"1px solid #e5e7eb"}
      borderRight={"1px solid #e5e7eb"}
      borderLeft={"1px solid #e5e7eb"}
      borderBottom={"2px solid #e5e7eb"}
    >
      <Container maxW={"450px"}>
        <Text
          padding={"16px 0px"}
          margin={"14px 0px"}
          fontSize={"17px"}
          color={"customColor.black7"}
          fontWeight={"700"}
        >
          Contact Details
        </Text>
        <Stack spacing={"25px"}>
          <Controller
            rules={{
              required: {
                value: true,
                message: "Name is required",
              },
            }}
            control={form.control}
            name="name"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                validationMessage={error?.message}
                withValidation
                ref={field.ref}
                input={{
                  ...field,

                  isInvalid: error !== undefined,
                  w: "100%",

                  h: "38px",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "text",

                  borderTopRadius: "3px",
                }}
                placeholder=""
                label="Contact Name (required)"
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.gray.6b7885",
                  },
                }}
              />
            )}
          />
          <Controller
            // rules={{
            //   required: {
            //     value: true,
            //     message: "Account number is required",
            //   },
            // }}
            control={form.control}
            name="accountNumber"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                validationMessage={error?.message}
                withValidation
                ref={field.ref}
                input={{
                  ...field,

                  // isInvalid: error !== undefined,
                  w: "100%",

                  h: "38px",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "number",

                  borderTopRadius: "3px",
                }}
                placeholder=""
                label="Account Number "
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.gray.6b7885",
                  },
                }}
              />
            )}
          />
          <Stack>
            <Text fontSize={"17px"} fontWeight={"700"} paddingBottom={"10px"}>
              Primary Person
            </Text>
            <HStack gap={0}>
              <Controller
                // rules={{
                //   required: {
                //     value: true,
                //     message: "Person First Name is required",
                //   },
                // }}
                control={form.control}
                name="primaryPerson.firstName"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    validationMessage={error?.message}
                    withValidation
                    ref={field.ref}
                    input={{
                      ...field,

                      // isInvalid: error !== undefined,
                      w: "100%",
                      marginRight: "0.5px",
                      h: "38px",
                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      borderRightRadius: "none",
                      borderTopLeftRadius: "4px",
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",
                    }}
                    placeholder=""
                    label="First Name "
                    labelProps={{
                      fontSize: ".845rem",
                      fontWeight: "600",
                      _light: {
                        color: "customColor.gray.6b7885",
                      },
                    }}
                  />
                )}
              />{" "}
              <Controller
                // rules={{
                //   required: {
                //     value: true,
                //     message: "Primary Person Last Name is required",
                //   },
                // }}
                control={form.control}
                name="primaryPerson.lastName"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    validationMessage={error?.message}
                    withValidation
                    ref={field.ref}
                    input={{
                      ...field,

                      // isInvalid: error !== undefined,
                      w: "100%",

                      h: "38px",
                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      borderLeftRadius: "none",
                      borderTopRightRadius: "4px",
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",
                    }}
                    placeholder=""
                    label="Last Name "
                    labelProps={{
                      fontSize: ".845rem",
                      fontWeight: "600",
                      _light: {
                        color: "customColor.gray.6b7885",
                      },
                    }}
                  />
                )}
              />
            </HStack>
          </Stack>{" "}
          <Controller
            // rules={{
            //   required: {
            //     value: true,
            //     message: "Primary Person Email is required",
            //   },
            // }}
            control={form.control}
            name="primaryPerson.email"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                validationMessage={error?.message}
                withValidation
                ref={field.ref}
                input={{
                  ...field,

                  // isInvalid: error !== undefined,
                  w: "100%",

                  h: "38px",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  borderLeftRadius: "4px",
                  borderTopRadius: "4px",
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "email",
                }}
                placeholder=""
                label="Email"
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.gray.6b7885",
                  },
                }}
              />
            )}
          />
          <Stack paddingTop={"16px"}>
            <Text fontSize={"17px"} fontWeight={"700"} paddingBottom={"10px"}>
              Additional people{" "}
            </Text>
            {contactComponent.fields.map((field, index) => (
              <HStack key={field.id} gap={0} alignItems={"center"}>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "First Name is required",
                    },
                  }}
                  name={`additionalPersons.${index}.firstName`}
                  control={form.control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      validationMessage={error?.message}
                      withValidation
                      ref={field.ref}
                      input={{
                        ...field,

                        isInvalid: error !== undefined,
                        w: "100%",
                        marginRight: "0.5px",
                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        borderRightRadius: "none",
                        borderTopLeftRadius: "4px",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",
                      }}
                      placeholder=""
                      label="First Name "
                      labelProps={{
                        fontSize: ".845rem",
                        fontWeight: "600",
                        _light: {
                          color: "customColor.gray.6b7885",
                        },
                      }}
                    />
                  )}
                />

                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Last Name is required",
                    },
                  }}
                  name={`additionalPersons.${index}.lastName`}
                  control={form.control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      validationMessage={error?.message}
                      withValidation
                      ref={field.ref}
                      input={{
                        ...field,

                        isInvalid: error !== undefined,
                        w: "100%",
                        marginRight: "0.5px",
                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        borderRightRadius: "none",
                        borderTopLeftRadius: "4px",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",
                      }}
                      placeholder=""
                      label="Last Name "
                      labelProps={{
                        fontSize: ".845rem",
                        fontWeight: "600",
                        _light: {
                          color: "customColor.gray.6b7885",
                        },
                      }}
                    />
                  )}
                />

                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                  }}
                  name={`additionalPersons.${index}.email`}
                  control={form.control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      validationMessage={error?.message}
                      withValidation
                      ref={field.ref}
                      input={{
                        ...field,

                        isInvalid: error !== undefined,
                        w: "100%",
                        marginRight: "0.5px",
                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        borderRightRadius: "none",
                        borderTopLeftRadius: "4px",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",
                      }}
                      placeholder=""
                      label="Email "
                      labelProps={{
                        fontSize: ".845rem",
                        fontWeight: "600",
                        _light: {
                          color: "customColor.gray.6b7885",
                        },
                      }}
                    />
                  )}
                />
                {contactComponent.fields.length > 1 && (
                  <Icon
                    marginTop={"25px"}
                    fontSize={"20px"}
                    onClick={() => {
                      contactComponent.remove(index);
                    }}
                    as={IoIosCloseCircleOutline}
                    color={"grey"}
                    _hover={{
                      color: "red.500",
                    }}
                  />
                )}
              </HStack>
            ))}
            <Button
              w={"fit-content"}
              leftIcon={<FiPlus />}
              margin={"8px 0px 0px"}
              alignItems={"center"}
              variant={"ghost"}
              fontSize={"13px"}
              borderRadius={"3px"}
              color={"#3454d1"}
              padding={"12px 16px"}
              onClick={() => {
                contactComponent.append({
                  firstName: "",
                  lastName: "",
                  email: "",
                });
              }}
            >
              Add another person
            </Button>
          </Stack>
          <Stack>
            <Text fontSize={"17px"} fontWeight={"700"} paddingBottom={"10px"}>
              Business Info
            </Text>

            <Stack>
              <Text
                fontSize={"0.845rem"}
                color={"customColor.gray.6b7885"}
                fontWeight={"600"}
              >
                Phone Number
              </Text>
              <HStack gap={0}>
                <Stack flex={5}>
                  <Controller
                    // rules={{
                    //   required: {
                    //     value: true,
                    //     message: "Business Phone Country is required",
                    //   },
                    // }}
                    control={form.control}
                    name="businessInformation.phoneCountry"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        // validationMessage={error?.message}
                        // withValidation
                        ref={field.ref}
                        input={{
                          ...field,

                          // isInvalid: error !== undefined,
                          w: "100%",
                          marginRight: "0.5px",
                          h: "38px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          borderRightRadius: "none",
                          borderTopLeftRadius: "4px",
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "text",
                        }}
                        placeholder="Country"
                      />
                    )}
                  />
                </Stack>
                <Stack flex={3}>
                  <Controller
                    // rules={{
                    //   required: {
                    //     value: true,
                    //     message: "Business Phone Area is required",
                    //   },
                    // }}
                    control={form.control}
                    name="businessInformation.phoneArea"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        // validationMessage={error?.message}
                        // withValidation
                        ref={field.ref}
                        input={{
                          ...field,

                          // isInvalid: error !== undefined,
                          w: "100%",

                          h: "38px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          marginRight: "0.5px",
                          borderLeftRadius: "none",
                          borderTopRightRadius: "none",
                          borderBottomRightRadius: "none",
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "text",
                        }}
                        placeholder="Area"
                      />
                    )}
                  />
                </Stack>
                <Stack flex={5}>
                  <Controller
                    // rules={{
                    //   required: {
                    //     value: true,
                    //     message: "Business Phone Number is required",
                    //   },
                    // }}
                    control={form.control}
                    name="businessInformation.phoneNumber"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        // validationMessage={error?.message}
                        // withValidation
                        ref={field.ref}
                        input={{
                          ...field,

                          // isInvalid: error !== undefined,
                          w: "100%",

                          h: "38px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          marginRight: "0.5px",
                          borderLeftRadius: "none",
                          borderTopRightRadius: "none",
                          borderBottomRightRadius: "none",
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "number",
                        }}
                        placeholder="Number"
                      />
                    )}
                  />
                </Stack>
              </HStack>
            </Stack>
          </Stack>
          <Controller
            // rules={{
            //   required: {
            //     value: true,
            //     message: "Business Website is required",
            //   },
            // }}
            control={form.control}
            name="businessInformation.website"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                validationMessage={error?.message}
                withValidation
                ref={field.ref}
                input={{
                  ...field,

                  // isInvalid: error !== undefined,
                  w: "100%",
                  marginRight: "0.5px",
                  h: "38px",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  borderRightRadius: "none",
                  borderTopLeftRadius: "4px",
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "text",
                }}
                placeholder=""
                label="Website "
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.gray.6b7885",
                  },
                }}
              />
            )}
          />
          <Controller
            // rules={{
            //   required: {
            //     value: true,
            //     message: "Business ABN is required",
            //   },
            // }}
            control={form.control}
            name="businessInformation.ABN"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                validationMessage={error?.message}
                withValidation
                ref={field.ref}
                input={{
                  ...field,

                  // isInvalid: error !== undefined,
                  w: "100%",
                  marginRight: "0.5px",
                  h: "38px",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  borderRightRadius: "none",
                  borderTopLeftRadius: "4px",
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "number",
                }}
                placeholder=""
                label="ABN "
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.gray.6b7885",
                  },
                }}
              />
            )}
          />
          <Controller
            // rules={{
            //   required: {
            //     value: true,
            //     message: "Business ACN is required",
            //   },
            // }}
            control={form.control}
            name="businessInformation.ACN"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                validationMessage={error?.message}
                withValidation
                ref={field.ref}
                input={{
                  ...field,

                  // isInvalid: error !== undefined,
                  w: "100%",
                  marginRight: "0.5px",
                  h: "38px",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  borderRightRadius: "none",
                  borderTopLeftRadius: "4px",
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "number",
                }}
                placeholder=""
                label="ACN "
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.gray.6b7885",
                  },
                }}
              />
            )}
          />
          <Stack gap={0}>
            <Text
              margin={"0px 12px 8px 0px"}
              fontWeight={"600"}
              _light={{
                color: "customColor.gray.6b7885",
              }}
              fontSize={".845rem"}
            >
              Note
            </Text>
            <Controller
              name="businessInformation.notes"
              // rules={{
              //   required: {
              //     value: true,
              //     message: "Type a description",
              //   },
              // }}
              control={form.control}
              render={({ field, fieldState }) => (
                <>
                  <Textarea
                    {...field}
                    // isInvalid={fieldState.invalid}
                    errorBorderColor="Red"
                    fontSize=".845rem"
                    fontWeight={400}
                    border="1px solid #e5e7eb"
                    _hover={{
                      border: "1px solid hsl(0, 0%, 70%)",
                    }}
                    _focus={{
                      borderWidth: "1px",

                      boxShadow: "0 0 0 1px #3454d1",
                      _hover: {
                        borderWidth: "1px",
                        borderColor: "#3454d1",
                        boxShadow: "0 0 0 1px #3454d1",
                      },
                    }}
                    _focusVisible={{
                      borderWidth: "1px",
                      boxShadow: "0 0 0 1px #3454d1",
                      _hover: {
                        borderWidth: "1px",
                        borderColor: "#3454d1",
                        boxShadow: "0 0 0 1px #3454d1",
                      },
                    }}
                    _light={{
                      color: "customColor.black7",
                    }}
                    padding="12px 15px"
                    overflowX="hidden"
                    maxLength={300}
                    // shadow="inner"
                    borderRadius={"0"}
                  />
                </>
              )}
            />
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
}
