import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Editable,
  EditableInput,
  EditablePreview,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { FiChevronDown, FiChevronRight, FiMoreVertical } from "react-icons/fi";
import { Template } from "../../../modules/Audit";
import { useTemplateBuilder } from "../hooks/templateBuilderContext";

import QuestionTable from "./QuestionTable";

interface PageProps {
  pageIndex: number;

  onPageAdd: () => void;
  onPageDelete: (index: number) => void;
}
export default function Page({
  pageIndex,
  onPageAdd,
  onPageDelete,
}: PageProps) {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const { widgets } = useTemplateBuilder();
  const template = useFormContext<Template.Template>();

  const childrensArr = useFieldArray({
    control: template.control,
    name: `templateItems.${pageIndex}.children` as "templateItems",
  });

  const newQuestion = {
    format: Template.QUESTION_TYPES.TEXT_ANSWER.format,
    required: false,
    includeTimestamp: false,
    widgetId: widgets.find((w) => w.name === "TEXT_ANSWER")?.id,
    type: Template.QUESTION_TYPES.TEXT_ANSWER.type,
  };

  return (
    <Stack
      paddingLeft={5}
      paddingRight={5}
      backgroundColor="backgrounds.primary"
      w="100%"
    >
      <Stack spacing={0}>
        <HStack
          spacing={0}
          justifyContent={"space-between"}
          backgroundColor="primary.500"
          borderRadius="8px"
        >
          <HStack w={"100%"} alignItems="center" justify="center">
            <IconButton
              onClick={onToggle}
              pl="8px"
              size="30px"
              textAlign="center"
              aria-label={""} // justifySelf="center"
            >
              {isOpen ? (
                <FiChevronDown color="#FFFFFF" />
              ) : (
                <FiChevronRight color="#FFFFFF" />
              )}
            </IconButton>
            <Box w={"100%"}>
              <Controller
                name={`templateItems.${pageIndex}.title`}
                control={template.control}
                render={({ field }) => (
                  <Editable
                    placeholder="Your template title"
                    fontSize={"21px"}
                    fontWeight={"500"}
                    color={"#FFFFFF"}
                    {...field}
                  >
                    <EditablePreview
                      padding={"8px"}
                      _hover={{
                        color: "icons.primary",
                      }}
                    />
                    <EditableInput
                      backgroundColor={"white"}
                      padding={"8px"}
                      border="1px"
                      borderStyle={"solid"}
                      borderColor={"primary.500"}
                      color={"black"}
                      _focusVisible={{ boxShadow: "0px" }}
                    />
                  </Editable>
                )}
              />
            </Box>
          </HStack>
          <Box>
            {isOpen && (
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<FiMoreVertical size={24} color="#FFFFFF" />}
                  variant="outline"
                  borderRadius={"50%"}
                  border={"none"}
                  // _hover={{backgroundColor: '#FFFFFF', color: '#000000'}}
                  // _active={{
                  //   backgroundColor: 'transparent',
                  //   _hover: {backgroundColor: 'backgrounds.secondary'},
                  // }}
                />
                <MenuList minWidth={"100px"}>
                  <MenuItem>
                    <Controller
                      control={template.control}
                      name={`templateItems.${pageIndex}.officeUse`}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          isChecked={field.value}
                          size={"md"}
                          value={field.value?.toString()}
                        >
                          Office Use
                        </Checkbox>
                      )}
                    />
                  </MenuItem>
                  <MenuItem>Move page</MenuItem>
                  <MenuItem
                    onClick={() => {
                      onPageDelete(pageIndex);
                    }}
                  >
                    Delete page
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Box>
        </HStack>

        <Collapse
          key={pageIndex}
          in={isOpen}
          animateOpacity
          style={{
            overflow: "visible",
            // textAlign: 'end',
          }}
        >
          <Box p="0px" color="black" mt="4" bg="white" rounded="md" shadow="md">
            <QuestionTable
              pageIndex={pageIndex}
              pageFieldArray={childrensArr}
            />
          </Box>
          <HStack mt="10px">
            <Button
              borderRadius="5px"
              onClick={() => {
                childrensArr?.append({
                  orderIndex: childrensArr.fields.length,
                  children: [],
                  status: false,
                  title: "",
                  type: "QUESTION",
                  question: newQuestion,
                } as Template.TemplateItem);
              }}
            >
              Add Question
            </Button>
            <Button
              borderRadius="5px"
              onClick={() => {
                childrensArr.append({
                  orderIndex: childrensArr.fields.length,

                  status: false,
                  templateId: 0,
                  title: "New Section",
                  type: "SECTION",
                  children: [
                    {
                      orderIndex: 0,
                      children: [],
                      status: false,
                      title: "",
                      type: "QUESTION",
                      question: newQuestion,
                    },
                  ],
                } as Template.TemplateItem);
              }}
            >
              Add Section
            </Button>
          </HStack>
          {/* <Button variant="link" mt="10px" onClick={onPageAdd}>
            Add Page
          </Button> */}
        </Collapse>
      </Stack>
      <Box marginTop="16px">
        <Button variant="link" onClick={onPageAdd}>
          Add Page
        </Button>
      </Box>
    </Stack>
  );
}
