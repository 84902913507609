import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { Fragment } from "react";
import { FiChevronDown } from "react-icons/fi";
import Content from "../../pages/Content";
import SubHeading from "../../pages/Sub-Heading";
import ResponseListModal from "./ResponseListModal";

interface uploadcsvdataprops {
  updateComponent: any;
}
export default function CSVDataModal({ updateComponent }: uploadcsvdataprops) {
  const reviewdata = useDisclosure();
  return (
    <Fragment>
      <HStack spacing="32px">
        <SubHeading
          flex={1}
          minW={{ xs: "200px", md: "100px" }}
          fontSize="11px"
          fontWeight={400}
          lineHeight="16px"
        >
          Label in SafetyCulture
        </SubHeading>
        <SubHeading flex={2} minW="450px" fontSize="11px" fontWeight={400}>
          Your CSV Field
        </SubHeading>
      </HStack>
      <HStack spacing="32px" mt="16px">
        <SubHeading
          flex={1}
          minW={{ xs: "200px", md: "100px" }}
          fontSize="16px"
          fontWeight={400}
        >
          Label
        </SubHeading>
        <Menu matchWidth={true}>
          <MenuButton
            as={Button}
            h="41px"
            variant="outline"
            rightIcon={<FiChevronDown />}
            flex={2}
            px="16px"
            py="8px"
            borderColor="borders.accent"
            borderRadius="4px"
            fontWeight={400}
            textAlign="start"
            color="textcolors.primary"
            _active={{ backgroundColor: "transparent" }}
            _hover={{ backgroundColor: "transparent" }}
          >
            Actions
          </MenuButton>
          <MenuList>
            <MenuItem
              px="14px"
              py="9.6px"
              fontSize="14px"
              fontWeight={400}
              color="textcolors.primary"
            >
              Label
            </MenuItem>
          </MenuList>
        </Menu>
        <Content
          flex={2}
          fontSize="12px"
          fontWeight={500}
          display={{ xs: "none", md: "block" }}
        >
          Danish, Saoud and 25 more
        </Content>
      </HStack>
      <HStack mt="8px" justify="end">
        <Button
          h="42px"
          variant="outline"
          px="20px"
          py="12px"
          borderRadius="8px"
          borderColor="borders.accent"
          _hover={{ backgroundColor: "transparent" }}
          // onClick={onClose}
        >
          Back
        </Button>
        <Button
          h="42px"
          variant="solid"
          px="20px"
          py="12px"
          borderRadius="8px"
          onClick={() => {
            updateComponent(
              <ResponseListModal updateComponent={updateComponent} />
            );
          }}
        >
          Review data
        </Button>
      </HStack>
    </Fragment>
  );
}
