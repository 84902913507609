import { axiosInstance } from "./axiosInstance-aud";

declare module ScheduleInspectionModule {
  type ScheduleMeta = {
    templateId: number;
    locationId: string;
    employeeId: string;
    frequency: string;
    title: string;
    timeRange: {
      after: string;
      before: string;
    };
    customFrequency: {
      entity: string;
      number: number;
    };
    allowedAfterTime: boolean;
    numberOfDays?: number;
    days?: Array<string>;
    status?: string;
  };
  type FetchScheduleMeta = {
    id: number;
    status: string;
    templateId: number;
    locationId: string;
    employeeId: string;
    assignedBy: string;
    frequency: string;
    title: string;
    timeRange: {
      after: string;
      before: string;
    };
    allowedAfterTime: boolean;
    numberOfDays?: number;
    days?: Array<string>;
  };

  type GetWeeklyScheduledInspections = {
    templateId: number;
    locationId: string;
    title: string;
    startDate: string;
    dueDate: string;
    allowedAfterTime: boolean;
    employeeId: string;
  };
  type changeStatus = {
    ids: Array<number>;
    status: string;
  };
  type scheduleIds = {
    ids: Array<number>;
  };
}
module ScheduleInspectionModule {
  const URL = "/schedule-inspection";

  export function PostScheduleInspection(
    data: ScheduleMeta,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${URL}`, data)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function UpdateScheduleInspection(
    id: number,
    data: Partial<ScheduleMeta>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchScheduledInspections(
    params: {
      page?: number;
      limit?: number;
      search?: string;
    },
    success: (reponse: Array<FetchScheduleMeta>, totalTemplate: number) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(URL, {
        params,
      })
      .then(({ data }) => {
        success(data?.data, data?.total);
      })
      .catch((e) => {
        error(e?.reponse?.data?.message ?? e?.message);
      });
  }

  export function bulkScheduleUpdate(
    data: changeStatus,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post("/schedule-inspection/bulk/update", data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function bulkScheduleDelete(
    data: scheduleIds,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post("/schedule-inspection/bulk/delete", data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchWeeklyScheduledInspections(
    success: (reponse: Array<GetWeeklyScheduledInspections>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}/my-schedule`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.reponse?.data?.message ?? e?.message);
      });
  }

  export function FetchScheduledInspectionById(
    id: number,
    success: (response: FetchScheduleMeta) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function DeleteScheduledInspection(
    id: number,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { ScheduleInspectionModule };
