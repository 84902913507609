import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Fragment, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../Common/CustomInput";
import getRandomHexCode from "../../../../Payroll/Api/getRandomHexCode";
import { LocationApi } from "../../../API/LocationAPi";
import QRCodeModal, { QRArea } from "../Modal/QRCodeModal";

export function Areas() {
  const form = useFormContext<LocationApi.Location>();
  const AreasList = useFieldArray({
    control: form.control,
    name: "areas",
    keyName: "uniqueId",
  });
  const QRcodeDisclosure = useDisclosure();
  const [areaName, setAreaName] = useState("");
  const [selectedArea, setSelectedArea] = useState<QRArea>();
  return (
    <Fragment>
      <QRCodeModal
        isOpen={QRcodeDisclosure.isOpen}
        onClose={QRcodeDisclosure.onClose}
        data={selectedArea}
      />

      <Stack paddingRight={"8px"}>
        <Stack borderWidth={"1px "} h={"500px"}>
          <Stack padding={"12px 20px"} borderBottomWidth={"1px "}>
            <Heading
              fontSize={"14px"}
              _light={{
                color: "customColor.black7",
              }}
            >
              Areas
            </Heading>
          </Stack>

          <Stack padding={"0px 20px"} direction={"row"} alignItems={"center"}>
            <CustomInput
              withValidation
              input={{
                w: "100%",
                height: "38px",
                fontSize: ".845rem",
                variant: "outline",
                _light: {
                  color: "customColor.black7",
                },
                type: "text",
                padding: "12px 15px",
                borderRightRadius: "none",
                borderTopLeftRadius: "3px",
                value: areaName,
                onChange: (e) => setAreaName(e.target.value),
              }}
              placeholder="New Area Name"
            />
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={() => {
                AreasList.append({
                  name: areaName,
                  color: getRandomHexCode(),
                });
                setAreaName("");
              }}
            >
              Add Area
            </Button>
          </Stack>
          <SimpleGrid columns={{ xs: 1, sm: 1, lg: 1 }} spacing="8px" px="20px">
            {AreasList.fields.map((data, i) => {
              return (
                <Fragment key={i}>
                  <Card
                    border="1px"
                    borderColor="borders.accent"
                    borderRadius={0}
                    _hover={{
                      boxShadow: "lg",
                    }}
                    _focus={{
                      border: "1px solid green",
                    }}
                    borderBottomColor={data.color}
                    borderBottomWidth={"5px"}
                  >
                    <CardBody py={"10px"} px="20px">
                      <HStack justifyContent="space-between">
                        <Stack
                          bg={data.color}
                          padding={"5px"}
                          borderRadius="5px"
                        >
                          <Text
                            fontSize={"14px"}
                            fontWeight={500}
                            color={"#FFFFFF"}
                            marginBottom={"3px"}
                            noOfLines={1}
                          >
                            {`${data.name} - ${form.getValues(
                              "name"
                            )} - ${form.getValues("address")}`}
                          </Text>
                        </Stack>
                        <ButtonGroup>
                          <Button
                            display={data.id ? "block" : "none"}
                            fontSize={"13px"}
                            bgColor="#3454D1"
                            borderRadius={"3px"}
                            borderWidth={"1px"}
                            borderColor={"#3454d1!important"}
                            variant={"solid"}
                            fontWeight={700}
                            _hover={{
                              bgColor: "#3454D1",
                            }}
                            onClick={() => {
                              if (data.id) {
                                setSelectedArea({
                                  areaId: data.id,
                                  areaName: data.name,
                                  locationId: data?.locationId!,
                                  locationAddress: form.getValues("address"),
                                  locationName: form.getValues("name"),
                                });
                                QRcodeDisclosure.onOpen();
                              }
                            }}
                          >
                            View
                          </Button>
                          <Button
                            variant="solid"
                            fontSize={"13px"}
                            borderRadius={"3px"}
                            border={"1px solid #FDEDED"}
                            bg={"#FDEDED"}
                            color={"#EA4D4D"}
                            _hover={{
                              border: "1px solid #EA4D4D",
                              bg: "#EA4D4D",
                              color: "#fff",
                            }}
                            onClick={() => {
                              AreasList.remove(i);
                            }}
                          >
                            Delete
                          </Button>
                        </ButtonGroup>
                      </HStack>
                    </CardBody>
                    {/* <CardFooter padding={'5px 10px 15px 15px'} justify="end">
                      
                    </CardFooter> */}
                  </Card>
                </Fragment>
              );
            })}
          </SimpleGrid>
        </Stack>
      </Stack>
    </Fragment>
  );
}
