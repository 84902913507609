import { Stack, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ShiftsTransfer } from "../../../../TimeAttendance/API/ShiftsTransfer";
import AssignedTag from "./ShiftsTag/AssignedTag";

export default function ShiftsAssigned() {
  const [AssignedShifts, setAssignedShifts] = useState<any>([]);
  const toast = useToast();
  useEffect(() => {
    ShiftsTransfer.GetShiftTransferRequests(
      (data) => {
        setAssignedShifts(data);
      },
      (err) => {
        toast({
          title: "Error",
          description: err,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    );
  }, []);
  return (
    <Stack
      minH={"100px"}
      padding={"24px"}
      overflowY="auto"
      sx={{
        "::-webkit-scrollbar": {
          width: "0px", // Initially, the scrollbar is hidden
          background: "transparent",
        },
        ":hover::-webkit-scrollbar": {
          width: "8px", // Scrollbar visible on hover
        },
        ":active::-webkit-scrollbar": {
          width: "8px", // Scrollbar visible when active
        },
        ":focus::-webkit-scrollbar": {
          width: "8px", // Scrollbar visible on focus
        },
        // Add for Firefox and other browsers if needed
        scrollbarWidth: "none",
        ":hover": {
          scrollbarWidth: "thin",
        },
        ":active": {
          scrollbarWidth: "thin",
        },
        ":focus": {
          scrollbarWidth: "thin",
        },
      }}
      maxH="700px"
    >
      {AssignedShifts?.map((shift: any) => {
        return <AssignedTag shift={shift} />;
      })}
    </Stack>
  );
}
