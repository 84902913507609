import {
  Box,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {Fragment} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {IoArrowBackSharp} from 'react-icons/io5';
import {MdDelete} from 'react-icons/md';
import {useParams} from 'react-router';
import {ReportLayout} from '../../../../modules/Audit/ReportLayout';
import {useTemplateReport} from '../report';
import ReportLayoutHistory from './Layouts/ReportLayoutHistory';
import DeleteReportLayoutModal from './Previews/ReportLayoutComponents/DeleteReportLayoutModal';
import ConfirmationModal from './Previews/WebPreview/Modals/ConfirmationModal';
import Display from './tabs/Display';
interface SideBarProps {}
export default function SideBar({}: SideBarProps) {
  const {
    isDrawer,
    setDisplayedComponent,
    GoBackToPrevComponent,
    isNewTemplate,
    isAllTemplate,
  } = useTemplateReport();
  const {isOpen, onClose, onOpen} = useDisclosure();
  const toast = useToast();
  const {id} = useParams();
  const form = useFormContext<ReportLayout.ReportLayout>();

  const DeleteModal = useDisclosure();
  console.log(form.getValues());
  return (
    <Fragment>
      <ConfirmationModal isOpen={isOpen} onClose={onClose} />
      <DeleteReportLayoutModal
        isOpen={DeleteModal.isOpen}
        onClose={DeleteModal.onClose}
      />
      <VStack
        align="start"
        w="100%"
        h={isDrawer ? '100vh' : 'calc(100vh - 62px)'}
        bg="white"
        spacing={0}>
        <Box
          w="100%"
          // h={isAllTemplate ? '165px' : '145px'}
        >
          <Button
            px="16px"
            mt="5px"
            mb="8px"
            variant="ghost"
            _hover={{backgroundColor: 'none'}}
            _active={{backgroundColor: 'none'}}
            onClick={() => {
              setDisplayedComponent(<ReportLayoutHistory />);
              form.setValue('reportStructure', []);
              form.setValue('showReportStructure', false);
            }}
            leftIcon={<IoArrowBackSharp size={21} />}>
            Back
          </Button>

          <HStack px="24px" justifyContent="space-between">
            <Text fontSize="21px" fontWeight={500} color="textcolors.primary">
              Report Layout
            </Text>
            {form.getValues('id') && (
              <MdDelete
                size={16}
                color="#545f70"
                onClick={() => {
                  DeleteModal.onOpen();
                }}
              />
            )}
          </HStack>

          {/* {isAllTemplate && (
            <Text
              px="24px"
              color="icons.primary"
              fontSize="13px"
              fontWeight={400}>
              All templates
            </Text>
          )} */}
          <Controller
            control={form.control}
            name="name"
            render={({field}) => (
              <Editable
                px="24px"
                w="100%"
                placeholder="Add a title"
                fontSize={'21px'}
                fontWeight={'500'}
                color={'#545f70'}
                {...field}>
                <EditablePreview
                  w="100%"
                  mt="8px"
                  _hover={{
                    color: '#545f70',
                  }}
                />
                <EditableInput
                  backgroundColor={'white'}
                  padding={'8px'}
                  mt="8px"
                  border="1px"
                  borderStyle={'solid'}
                  borderColor={'primary.500'}
                  color={'black'}
                  _focusVisible={{boxShadow: '0px'}}
                />
              </Editable>
            )}
          />
        </Box>

        {/* Height Formula calc( <Parent Height> - <Layout Details Box Height> - <Bottom Component Height> ) */}
        <Tabs
          w="100%"
          h={
            isAllTemplate
              ? 'calc(100% - 165px  - 74px)'
              : 'calc(100% - 145px  - 74px)'
          }
          isFitted
          colorScheme="primary">
          <TabList h="41px" mt="8px">
            {['Display'].map((tabLabel, i) => (
              <Tab
                key={i}
                fontSize="14px"
                fontWeight={500}
                borderBottomWidth="3px"
                color="gray"
                px="20px"
                py="12px"
                borderTopRadius="8px"
                _selected={{
                  backgroundColor: 'gray.100',
                  borderColor: 'primary.500',
                  color: 'primary.500',
                }}>
                {tabLabel}
              </Tab>
            ))}
          </TabList>

          {/* Height Formula calc( <Parent Height> - <Bottom Component Height> ) */}
          <TabPanels maxHeight="calc(100% - 13px)" overflowY="auto">
            <TabPanel padding={0}>
              <Display isDrawer={isDrawer} />
            </TabPanel>
            {/* <TabPanel padding={0}>
              <Email />
            </TabPanel> */}
          </TabPanels>
        </Tabs>
      </VStack>
    </Fragment>
  );
}
