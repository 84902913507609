import { CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  IconButton,
  Image,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useParams } from "react-router-dom";
import { UploadAttachments } from "../../../../../../Api/UploadAttachments";

interface Props {
  form: UseFormReturn<any, any, undefined>;
}

export function AddExpenseRightSection({ form }: Props) {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { id } = useParams();
  const toast = useToast();

  // Check for existing image on initial render (e.g., when modal is opened for update)
  useEffect(() => {
    const existingImage = form.getValues("attachments")?.[0];
    if (existingImage) {
      setPreviewUrl(existingImage);
    }
  }, [form]);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type.startsWith("image/")) {
      const preview = URL.createObjectURL(droppedFile);
      setPreviewUrl(preview);
      handleFileUpload(droppedFile);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      const preview = URL.createObjectURL(selectedFile);
      setPreviewUrl(preview);
      handleFileUpload(selectedFile);
    }
    e.target.value = ""; // Clear file input after handling file
  };

  const handleFileUpload = (file: File) => {
    const formData = new FormData();
    formData.append("docType", "EMPLOYEE_DOCUMENT");
    formData.append("file", file);
    formData.append("empId", id!);

    UploadAttachments.UploadAttachment(
      formData,
      (success) => {
        form.setValue("attachments", [success.url]);
      },
      (err) => {
        toast({
          title: "Error uploading photo",
          status: "error",
        });
      }
    );
  };

  const handleDelete = () => {
    setPreviewUrl(null);
    form.setValue("attachments", []); // Clear the attachment from form state
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <Stack>
      <Text margin={"21px 0px"} fontSize={"21px"} fontWeight={"700"}>
        Upload items
      </Text>
      <Text fontSize={"13px"} paddingBottom={"4px"}>
        <b>File to upload</b> (required)
      </Text>
      <Stack
        border={"1px dashed #a6a9b0"}
        alignItems={"center"}
        justifyContent={"center"}
        padding={"20px"}
        h={"400px"}
        textAlign={"center"}
        borderRadius={"3px"}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        position="relative"
      >
        {previewUrl ? (
          <>
            <Image
              src={previewUrl}
              alt="Dropped file"
              w={"100%"}
              h={"100%"}
              borderRadius="3px"
            />
            <IconButton
              icon={<CloseIcon />}
              size="sm"
              colorScheme="red"
              position="absolute"
              top="10px"
              right="10px"
              onClick={handleDelete}
              aria-label="Delete Image"
            />
          </>
        ) : (
          <>
            <Text fontSize={"15px"} marginBottom={"12px"}>
              Drag and drop file or select manually
            </Text>
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              variant={"solid"}
              padding={"12px 16px"}
              color={"#3454D1"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
                color: "#3454D1",
              }}
              onClick={() => {
                if (fileInputRef.current) {
                  fileInputRef.current.click();
                }
              }}
            >
              Select File
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
}
