import {
  Button,
  Checkbox,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

interface ShowAndHideModalProps {
  isOpen: boolean;
  onClose: () => void;
  fieldNames: string[];
  fieldsState: any;
  handleCheckboxChange: (field: string, checked: boolean) => void;
  setFieldsState: React.Dispatch<any>;
  defaultState: {
    [key: string]: boolean;
  };
}

export default function ShowAndHideModal({
  isOpen,
  onClose,
  fieldNames,
  fieldsState,
  setFieldsState,
  handleCheckboxChange,
  defaultState,
}: ShowAndHideModalProps) {
  // Save state to localStorage when Save button is clicked
  const handleSave = () => {
    const newFieldsState = { ...fieldsState };

    // If "Invoice Number" is unchecked, hide "Reference" field
    if (!newFieldsState["Invoice Number"]) {
      newFieldsState["Reference"] = false;
    }

    // Save the updated fieldsState to localStorage
    localStorage.setItem("fieldsState", JSON.stringify(newFieldsState));
    setFieldsState(newFieldsState); // Update the state immediately
    onClose(); // Close the modal after saving
  };

  // Handle modal close/reset the state to default values when closing the modal
  const handleModalClose = () => {
    // Reset state to default if modal is being closed
    setFieldsState(defaultState);
    onClose(); // Close the modal
  };

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose} isCentered>
      <ModalOverlay />
      <ModalContent maxH={"650px"} overflowX={"auto"}>
        <ModalHeader fontSize="17px">Show/hide fields</ModalHeader>
        <ModalCloseButton onClick={handleModalClose} />
        <ModalBody borderTop={"1px solid #ddd"} borderBottom={"1px solid #ddd"}>
          <Text fontWeight={400} fontSize={"15px"} paddingBottom={"16px"}>
            Select fields depending on your invoicing needs. This won't change
            how your invoices look to customers.
          </Text>
          <Stack marginBottom={"20px"}>
            <Text fontWeight={600} fontSize={"13px"}>
              Invoice fields
            </Text>
            <Stack border={"1px solid #a6a9b0"} borderRadius={"3px"} gap={0}>
              {fieldNames.slice(0, 4).map((field) => (
                <Stack
                  key={field}
                  padding={"7px"}
                  borderTop={"1px solid #a6a9b0"}
                >
                  <Checkbox
                    size={"lg"}
                    isChecked={fieldsState[field] || false}
                    onChange={(e) =>
                      handleCheckboxChange(field, e.target.checked)
                    }
                  >
                    <Text fontSize="15px" fontWeight={"500"}>
                      {field}
                    </Text>
                  </Checkbox>
                </Stack>
              ))}
            </Stack>
          </Stack>

          <Stack>
            <Text fontWeight={600} fontSize={"13px"}>
              Table fields
            </Text>
            <Stack border={"1px solid #a6a9b0"} borderRadius={"3px"} gap={0}>
              {fieldNames.slice(4).map((field) => (
                <Stack
                  key={field}
                  padding={"7px"}
                  borderTop={"1px solid #a6a9b0"}
                >
                  <Checkbox
                    size={"lg"}
                    isChecked={fieldsState[field] || false}
                    onChange={(e) =>
                      handleCheckboxChange(field, e.target.checked)
                    }
                  >
                    <Text fontSize="15px" fontWeight={"500"}>
                      {field}
                    </Text>
                  </Checkbox>
                </Stack>
              ))}
            </Stack>
          </Stack>

          <Text paddingTop={"12px"} fontSize={"12px"}>
            To Change what customer see on invoice, edit the{" "}
            <Link color={"#3454d1"}>branding theme</Link>
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"8px"}
            fontWeight={600}
            _hover={{ backgroundColor: "#fff" }}
            onClick={handleModalClose} // Ensure state resets when clicking Cancel
          >
            Cancel
          </Button>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{ bgColor: "#3454D1" }}
            onClick={handleSave} // Save to localStorage when clicked
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
