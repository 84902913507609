import {Box, Button, Checkbox, Flex, Text, VStack} from '@chakra-ui/react';
import {IoMdInformationCircle} from 'react-icons/io';
import {IoArrowBackSharp} from 'react-icons/io5';
import {useTemplateReport} from '../../report';

export default function Actions() {
  const {GoBackToPrevComponent} = useTemplateReport();
  return (
    <VStack
      align="start"
      w="100%"
      h="calc(100vh - 62px)"
      bg="white"
      spacing={0}>
      {/* <Flex
        px="16px"
        mt="24px"
        mb="8px"
        align="center"
        onClick={() => GoBackToPrevComponent()}>
        <IoArrowBackSharp color="#1da5d4" size={21} />
        <Text ml="8px" fontSize="16px" fontWeight={500} color="primary.500">
          Back
        </Text>
      </Flex> */}
      <Button
        px="16px"
        mt="24px"
        mb="8px"
        variant="ghost"
        _hover={{backgroundColor: 'none'}}
        _active={{backgroundColor: 'none'}}
        onClick={() => GoBackToPrevComponent()}
        leftIcon={<IoArrowBackSharp size={21} />}>
        Back
      </Button>
      <Box px="24px" pt="8px">
        <Text
          fontWeight={500}
          fontSize="21px"
          color="textcolors.primary"
          mr="8px">
          Actions
        </Text>
      </Box>
      <Box px="28.8px" w="100%">
        <Flex
          w="100%"
          mt="12px"
          fontSize="14px"
          px="16px"
          pt="8px"
          pb="6.4px"
          border="1px solid"
          borderColor="primary.500"
          borderRadius="5px"
          backgroundColor="primary.50">
          <Box ml="4px" mr="12px" mt="2px">
            <IoMdInformationCircle size={16} color="#1da5d4" />
          </Box>
          <Text color="primary.500" fontWeight={300} w="100%">
            You can hide actions in the summary page while showing them in your
            report.
          </Text>
        </Flex>
      </Box>
      <Flex w="100%">
        <Box
          w="100%"
          mt="28.8px"
          borderRadius="12px"
          mx="8px"
          _hover={{backgroundColor: 'primary.50'}}>
          <Checkbox
            borderColor="icons.primary"
            px="24px"
            py="8px"
            fontWeight={400}
            color="#3f495a"
            spacing={4}>
            Action in summary page
          </Checkbox>
        </Box>
      </Flex>
    </VStack>
  );
}
