import { Box } from "@chakra-ui/react";
import { ArcElement, Chart, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
Chart.register(ArcElement, Tooltip, Legend);

interface TurnOverChartProps {
  newHiring?: string;
  separation?: string;
}

export default function TurnOverChart({
  newHiring = "0",
  separation = "0",
}: TurnOverChartProps) {
  const turnover = {
    labels: ["New Hiring", "Separation"],
    datasets: [
      {
        data: [newHiring, separation].map(Number), // Convert to numbers
        backgroundColor: [
          "rgba(75, 192, 192, 0.2)", // Light green color with some transparency
          "rgba(54, 162, 235, 0.2)", // Light blue color with some transparency
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)", // Solid green color
          "rgba(54, 162, 235, 1)", // Solid blue color
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem: any) {
            const value = tooltipItem.raw;
            return value > 0 ? `${tooltipItem.label}: ${value}` : ""; // Hide 0 values
          },
        },
        bodyFont: {
          size: 10,
        },
        titleFont: {
          size: 10,
        },
      },
      datalabels: {
        display: function (context: any) {
          return context.dataset.data[context.dataIndex] > 0; // Only show labels if the value is greater than 0
        },
      },
    },
  };

  return (
    <Box w="60px" h="60px" marginRight={"5px"}>
      <Doughnut data={turnover} options={options} />
    </Box>
  );
}
