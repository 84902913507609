import {ChevronDownIcon, ChevronRightIcon} from '@chakra-ui/icons';
import {
  Box,
  Collapse,
  HStack,
  Icon,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {useEffect, useMemo, useState} from 'react';
import {useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import {Inspection} from '../../modules/Audit';
import {MemorisedInspectionQuestion} from './InspectionForm';
import {ReduceTotalPageScoreObject} from './types';

interface InpectionFormSectionProps {
  sectionIndex: number;
  pageIndex: number;
  selectedPageIndex: number;
  onScoreChanged: (score: number, previousScore?: number) => void;
  onReducePageScore: (
    item: ReduceTotalPageScoreObject,
    operation: 'ADD' | 'REMOVE',
  ) => void;
  scoreToBeReduced: ReduceTotalPageScoreObject[];
}
export default function InpectionFormSection({
  pageIndex,
  sectionIndex,
  onScoreChanged,
  selectedPageIndex,
  onReducePageScore,
  scoreToBeReduced,
}: InpectionFormSectionProps) {
  const {isOpen, onToggle} = useDisclosure();
  const template = useFormContext();
  const watchedTitle = useWatch({
    control: template.control,
    name: `templateItems.${pageIndex}.children.${sectionIndex}.title`,
  });

  const sectionQuestions = useFieldArray({
    control: template.control,
    name: `templateItems.${pageIndex}.children.${sectionIndex}.children`,
  });

  const [sectionScoreToBeReduced, setSectionScoreToBeReduced] = useState<
    Array<ReduceTotalPageScoreObject>
  >([]);
  function reduceTotalSectionScore(
    item: ReduceTotalPageScoreObject,
    operation: 'ADD' | 'REMOVE',
  ) {
    if (operation === 'ADD') {
      setSectionScoreToBeReduced(prev => {
        return [...prev, item];
      });
    } else {
      setSectionScoreToBeReduced(prev => {
        return prev.filter(score => {
          return score.questionPath !== item.questionPath;
        });
      });
    }
    onReducePageScore(item, operation);
  }

  // const [sectionScore, setSectionScore] = useState(0);
  // const sectionTotalScore = useMemo(() => {
  //   return (
  //     sectionQuestions.fields as unknown as Inspection.InpsectionPayload['templateItems']
  //   ).reduce((prev, curr) => {
  //     // let score =
  //     //   curr.question?.questionScore !== undefined &&
  //     //   curr.question?.questionScore !== null &&
  //     //   +curr.question?.questionScore > -1
  //     //     ? curr.question?.score
  //     //     : '0';
  //     return prev + parseInt(curr.question?.score ?? '0');
  //   }, 0);
  //   // -
  //   // sectionScoreToBeReduced.reduce((prev, curr) => {
  //   //   return prev + +(curr.score ?? 0);
  //   // }, 0)
  // }, [sectionScoreToBeReduced]);

  const [sectionScore, setSectionScore] = useState(0);

  const sectionTotalScore = useMemo(() => {
    const initialSectionScore = (
      sectionQuestions.fields as unknown as Inspection.InpsectionPayload['templateItems']
    ).reduce((prev: any, curr: any) => {
      return prev + parseInt(curr.question?.score ?? '0');
    }, 0);
    const reducedSectionScore = sectionScoreToBeReduced.reduce((prev, curr) => {
      return prev + (+curr.score ?? 0);
    }, 0);

    return initialSectionScore - reducedSectionScore;
  }, [sectionQuestions, sectionScoreToBeReduced]);

  const scoreInPercentage = (sectionScore / sectionTotalScore) * 100;

  useEffect(() => {
    let calcScore = (
      sectionQuestions.fields as unknown as Inspection.InpsectionPayload['templateItems']
    ).reduce((prev, curr: any) => {
      return prev + +(curr.question?.questionScore ?? 0);
    }, 0);

    setSectionScore(calcScore);
  }, []);
  return (
    <Stack>
      <Box
        onClick={onToggle}
        p="16px"
        mt="8px"
        display="flex"
        justifyContent="space-between"
        backgroundColor="primary.500"
        borderRadius="12px">
        <HStack>
          <Icon
            as={!isOpen ? ChevronDownIcon : ChevronRightIcon}
            boxSize="20px"
            color="#FFFFFF"
          />
          <Text fontSize="16px" fontWeight={500} color="white">
            {watchedTitle}
          </Text>
        </HStack>
        <HStack>
          <Text
            fontSize="16px"
            fontWeight={500}
            color="white"
            display={sectionTotalScore ? 'block' : 'none'}>
            {`${sectionScore}/${sectionTotalScore} (${
              !Number.isNaN(scoreInPercentage)
                ? scoreInPercentage.toFixed(2)
                : 0
            }%)`}
          </Text>
        </HStack>
      </Box>
      <Collapse in={!isOpen} animateOpacity>
        {(
          sectionQuestions.fields as unknown as Inspection.InpsectionPayload['templateItems']
        )?.map((innerChild, innerIndex) => (
          <MemorisedInspectionQuestion
            onReducePageScore={reduceTotalSectionScore}
            key={innerIndex}
            child={innerChild}
            i={innerIndex}
            selectedPageIndex={selectedPageIndex}
            sectionIndex={sectionIndex}
            onScoreChanged={(score, prevScore) => {
              setSectionScore(prev => prev + score - (prevScore ?? 0));
              onScoreChanged(score, prevScore);
            }}
          />
        ))}
      </Collapse>
    </Stack>
  );
}
