import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Container,
  Flex,
  HStack,
  IconButton,
  Image,
  Stack,
  Text,
  useDisclosure,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import saveAs from "file-saver";
import JSZip from "jszip";
import { Fragment, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FiChevronDown, FiChevronRight, FiEye } from "react-icons/fi";
import { Report } from "../../../../../../modules/Audit";
import ViewImageModal from "./Modals/ViewImageModal";

export default function MediaSummary() {
  const { isOpen, onToggle } = useDisclosure();
  const mediaSummary = useFormContext<Report.IReport>();
  const ImageModal = useDisclosure();
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedMedia, setSelectedMedia] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const inspectionMedia = mediaSummary.getValues("inspectionMedia");
  const handleImageClick = (imageUrl: any) => {
    setSelectedImage(imageUrl);
    ImageModal.onOpen();
  };
  const handleCheckboxChange = (imageUrl: string) => {
    setSelectedMedia((prev) =>
      prev.includes(imageUrl)
        ? prev.filter((url) => url !== imageUrl)
        : [...prev, imageUrl]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedMedia([]);
    } else {
      setSelectedMedia(inspectionMedia?.map((media) => media.mediaUrl) || []);
    }
    setSelectAll(!selectAll);
  };

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const downloadSelectedImages = async () => {
    const zip = new JSZip();

    // Iterate over URLs and fetch each file
    for (let i = 0; i < selectedMedia.length; i++) {
      setIsLoading(true);
      try {
        const response = await fetch(selectedMedia[i]);
        const blob = await response.blob();
        const fileName = selectedMedia[i].split("/").pop() || `file${i + 1}`;
        zip.file(fileName, blob);
        setIsLoading(false);
        setSelectAll(false);
        setSelectedMedia([]);
      } catch (error) {
        toast({
          title: "Error",
          description: "Couldn't download the images",
          status: "error",
        });
        setIsLoading(false);
        return;
      }
    }

    // Generate ZIP file and trigger download
    zip.generateAsync({ type: "blob" }).then((content: any) => {
      saveAs(content, "images.zip");
    });
  };
  return (
    <Fragment>
      <ViewImageModal
        isOpen={ImageModal.isOpen}
        onClose={ImageModal.onClose}
        selectedImage={selectedImage}
      />
      <Container
        p="16px"
        maxW="container.md"
        borderRadius="16px"
        backgroundColor="backgrounds.primary"
        lineHeight={1}
      >
        <HStack spacing={0}>
          <Button
            marginRight="8px"
            as={IconButton}
            justifyContent="start"
            p={0}
            aria-label={"dropdown"}
            backgroundColor="transparent"
            _hover={{ backgroundColor: "transparent" }}
            onClick={onToggle}
            icon={
              isOpen ? (
                <FiChevronDown color={"#828ea0"} size={24} />
              ) : (
                <FiChevronRight color={"#828ea0"} size={24} />
              )
            }
          />
          <Text flex={1} fontSize="21px" fontWeight={500} color="#3f495a">
            Media Summary
          </Text>
          <Text color="#007a52" fontSize="16px" fontWeight={400}>
            {`${inspectionMedia?.length} media files`}
          </Text>
        </HStack>
        <Collapse in={isOpen} animateOpacity>
          <Stack spacing={4} mt="16px">
            <Flex justifyContent="flex-end">
              <Checkbox
                isChecked={selectAll}
                onChange={handleSelectAll}
                colorScheme="green"
              >
                Select All
              </Checkbox>
              <Button
                ml={4}
                isLoading={isLoading}
                borderRadius="5px"
                isDisabled={selectedMedia.length === 0}
                onClick={downloadSelectedImages}
                // onClick={() => {
                //   setIsLoading(true);
                //   ZipFile.DownloadPictures(
                //     { fileName: `images-${Date.now()}`, images: selectedMedia }, // Request payload
                //     (res) => {
                //       // Create a Blob from the API response
                //       const blob = new Blob([res], { type: "application/zip" });
                //       FileSaver.saveAs(blob, `images-${Date.now()}`);
                //       setSelectedMedia([]);
                //       setSelectAll(false);
                //       setIsLoading(false);
                //     },
                //     (err) => {
                //       toast({
                //         title: "Error",
                //         description: "Couldn't download the images",
                //         status: "error",
                //       });
                //       setIsLoading(false);
                //     }
                //   );
                // }}
                colorScheme="primary"
              >
                Download
              </Button>
            </Flex>
            <Wrap mt="16px">
              {inspectionMedia?.map((ms, i) => (
                <WrapItem key={i}>
                  <Box position="relative" w="112px" h="112px">
                    <Checkbox
                      position="absolute"
                      top="8px"
                      right="8px"
                      zIndex={2}
                      isChecked={selectedMedia.includes(ms.mediaUrl)}
                      onChange={() => handleCheckboxChange(ms.mediaUrl)}
                      colorScheme="green"
                    />
                    <Image
                      h="full"
                      w="full"
                      borderRadius="8px"
                      src={ms.mediaUrl}
                      cursor="pointer"
                    />
                    <Box
                      position="absolute"
                      top="0"
                      left="0"
                      right="0"
                      bottom="0"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      opacity="0"
                      _hover={{
                        opacity: 1,
                        backgroundColor: "rgba(0,0,0,0.5)",
                      }}
                    >
                      <IconButton
                        aria-label="View Image"
                        icon={<FiEye color="white" />}
                        variant="unstyled"
                        onClick={() => handleImageClick(ms.mediaUrl)}
                      />
                    </Box>
                  </Box>
                </WrapItem>
              ))}
            </Wrap>
          </Stack>
        </Collapse>
      </Container>
    </Fragment>
  );
}
