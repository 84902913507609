import {
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import ReactCountryFlag from "react-country-flag";
import { FaArrowRight } from "react-icons/fa";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { LuMenu } from "react-icons/lu";
import countryList from "react-select-country-list";
import Hrislogo from "../../assets/HRISMainLogo.png";
import { useBusinessContext } from "../../Hooks/BusinessContext";
import SetupGuide from "../NewDashbaord/Guide/SetupGuide";
import { AvatarMenu } from "./Components/AvatarMenu";
import { Notifications } from "./Components/Notifications";
import { Search } from "./Components/Search";
interface NewHeaderProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (value: boolean) => void;
}
export default function NewHeader({
  isSidebarOpen,
  setIsSidebarOpen,
}: NewHeaderProps) {
  const { businessesList, currentBusinessId } = useBusinessContext();

  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());

  const countryName = businessesList.find(
    (business) => business.id === currentBusinessId
  )?.country;

  const countries = countryList().getData();

  const countryCode = countries.find(
    (country) => country.label === countryName
  )?.value;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [background, setBackground] = useState("#FFFFFF");
  return (
    <Stack
      w={"100%"}
      top={0}
      position="sticky"
      maxH={"70px"}
      zIndex="1002"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      borderBottom={`1px solid #e5e7eb`}
    >
      <Flex justifyContent="space-between" alignItems="center">
        {decodedToken.accessLevel === "EMPLOYEE" && (
          <Box
            w={"135px"}
            h="70px"
            position="sticky"
            top={0}
            zIndex="9999"
            ml="10px"
            pl="12px"
          >
            <Flex
              justifyContent="space-between"
              align="center"
              justify="center"
              w="100%"
              h={"100%"}
            >
              <Image src={Hrislogo} display={{ xs: "flex", lg: "flex" }} />
            </Flex>
          </Box>
        )}
        <Flex
          // w="550px"
          py="16px"
          ml="10px"
          display="flex"
          alignItems="center"
          // justifyContent="center"
        >
          {decodedToken.accessLevel !== "EMPLOYEE" && (
            <IconButton
              display="block"
              fontWeight="900"
              fontStyle="normal"
              color={"#283c50"}
              _hover={{ bg: "none" }}
              aria-label={"Sidebar-button"}
              icon={
                isSidebarOpen ? (
                  <LuMenu size="100%" />
                ) : (
                  <FaArrowRight size="100%" />
                )
              }
              variant="outline"
              borderWidth="0px"
              h="24px"
              w="24px"
              onClick={() => {
                setIsSidebarOpen(!isSidebarOpen);
              }}
            />
          )}
          <Text
            fontSize="18px"
            fontWeight="700"
            color="#3454d1"
            ml="10px"
            display={{ xs: "none", lg: "flex" }}
          >
            {
              businessesList.find(
                (business) => business.id === currentBusinessId
              )?.name
            }
          </Text>
        </Flex>

        <Flex ml="10px" alignItems="center">
          <Search />

          <Box
            w="46px"
            h="46px"
            px="12px"
            py="12px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            bgColor={background}
            onClick={onOpen}
          >
            <Icon
              as={IoIosHelpCircleOutline}
              fontSize="22px"
              color="#6B7280"
              w="46px"
              h="46px"
              px="12px"
              py="12px"
              borderRadius="50%"
              _hover={{
                color: "#3454d1",
                bg: "#eaebef",
              }}
              _active={{
                color: "#3454d1",
                bg: "#eaebef",
              }}
            />
          </Box>

          <SetupGuide isOpen={isOpen} onClose={onClose} />

          <Box
            w="47px"
            h="47px"
            px="12px"
            py="12px"
            display="flex"
            borderRadius="50%"
            alignItems="center"
            justifyContent="center"
            _hover={{
              bg: "#eaebef",
            }}
          >
            <ReactCountryFlag
              countryCode={countryCode!}
              svg
              style={{
                width: "24.375px",
                height: "16px",
              }}
              title={countryCode}
            />
          </Box>
          <Notifications />

          <AvatarMenu decodedToken={decodedToken} />
        </Flex>
      </Flex>
    </Stack>
  );
}
