import { axiosInstance } from "./axiosInstance-aud";

declare module MCQ {
  interface IMCQ {
    id?: number;
    name?: string;
    isGlobal: boolean;
    templateId?: number;
    options: Array<MCQOption>;
    isScoringEnabled: boolean; // By Default true
    updatedAt?: string;
    deletedOptions?: Array<{
      id: number;
      name: string;
    }>;
  }
  type MCQOption = {
    value: string;
    color: string;
    score?: number;
    flagged: boolean; // By Default false
    id?: number;
    orderIndex: number;
  };
}
module MCQ {
  const BASE_URL = "/multi-choice-response";
  export function Fetch(
    criteria: { isGlobal?: boolean; templateId?: number },
    success: (options: Array<IMCQ>) => void,
    error: (message: string) => void
  ) {
    // generate url with query params

    let url = `${BASE_URL}/criteria`;
    if (criteria.isGlobal) {
      url += `?isGlobal=${criteria.isGlobal}`;
    }
    if (criteria.templateId) {
      if (criteria.isGlobal) {
        url += `&templateId=${criteria.templateId}`;
      } else {
        url += `?templateId=${criteria.templateId}`;
      }
    }

    axiosInstance
      .get(url)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e.response?.data?.message ?? e?.message);
      });
  }
  export function Create(
    data: IMCQ,
    success: (options: IMCQ) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(BASE_URL, data)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e.response?.data?.message ?? e?.message);
      });
  }
  export function Update(
    id: number,
    data: IMCQ,
    success: (options: IMCQ) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${BASE_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e.response?.data?.message ?? e?.message);
      });
  }

  export function Delete(
    id: number,

    success: (message: IMCQ) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${BASE_URL}/${id}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e.response?.data?.message ?? e?.message);
      });
  }
}
export { MCQ };
