import { HStack, Icon, Stack, Text, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { CustomInput } from "../../../../Common/CustomInput";
import { PayItemDeductionApi } from "../../../Api/PayItemDeduction";
import { PayRun } from "../../../Api/PayRun";
import { PayTemp } from "../../../Api/PayTemp";

interface AddDeductionProps {
  form: any;
  deductionFieldArray: any;
  deductionLine: any;
  accountDeduction: PayItemDeductionApi.DeductionById[];
  setAccountDeduction: (accountDeduction: any) => void;
  totalEarnings: number;
  resetPaySlip: Function;
  setResetData: (resetData: any) => void;
}
export default function AddDeduction({
  accountDeduction,
  deductionFieldArray,
  deductionLine,
  form,
  setAccountDeduction,
  totalEarnings,
  resetPaySlip,
  setResetData,
}: AddDeductionProps) {
  const toast = useToast();
  useEffect(() => {
    PayItemDeductionApi.FetchDeduction(
      (res) => {
        setAccountDeduction(res);
      },
      (err) => {
        toast({
          title: "Error",
          description: err,
          status: "error",
        });
      }
    );
  }, []);

  return (
    <Stack gap={0}>
      {deductionFieldArray.fields.map(
        (item: PayRun.PayrunItem & { uniqueId: string }, i: any) => (
          <Stack direction="row" alignItems={"center"} key={item.uniqueId}>
            <Stack flex={1}>
              <Text
                fontSize={"12px "}
                margin={"0px 0px 4px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.250",
                }}
                fontWeight={"700"}
              >
                Deduction Rate{" "}
              </Text>
              <Text
                color={"#3454d1"}
                padding={"6px 0px 0px"}
                fontSize={"12px"}
                borderTop={"1px solid #CCC !important"}
                borderBottom={"1px solid #E8EAEC !important"}
                marginBottom={"5px"}
                _hover={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  deductionLine.onOpen();
                  setResetData({ ...item, index: i });
                }}
              >
                {accountDeduction.find(
                  (accountDeduction) =>
                    accountDeduction.id === item.payItemDeductionId
                )?.name ?? ""}{" "}
                {!!item?.description && `(${item?.description})`}
              </Text>
            </Stack>

            <Stack alignItems={"flex-end"}>
              <HStack>
                {[
                  PayTemp.PayTemplateCalcTypeEnum["PRE-TAX"],
                  PayTemp.PayTemplateCalcTypeEnum["POST-TAX"],
                  PayTemp.PayTemplateCalcTypeEnum.EMPLOYEE_EOBI,
                  PayTemp.PayTemplateCalcTypeEnum.EMPLOYEE_PROVIDENT_FUND,
                  PayTemp.PayTemplateCalcTypeEnum.SOCIAL_SECURITY,
                ].includes(
                  form.getValues(`deductions.${i}.calculationType`)
                ) && (
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Percentage is required",
                      },
                      onBlur(event) {
                        resetPaySlip();
                      },
                    }}
                    control={form.control}
                    name={`deductions.${i}.percentage`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          type: "number",
                          onChange: (e) => {
                            field.onChange(Number(e.target.valueAsNumber));
                          },
                          isDisabled: [
                            PayTemp.PayTemplateCalcTypeEnum.EMPLOYEE_EOBI,
                            PayTemp.PayTemplateCalcTypeEnum
                              .EMPLOYEE_PROVIDENT_FUND,
                            PayTemp.PayTemplateCalcTypeEnum.SOCIAL_SECURITY,
                          ].includes(
                            form.getValues(`deductions.${i}.calculationType`)
                          ),
                          maxW: "91.33px",
                          height: "26.33px",
                          padding: "12px 15px",

                          placeholder: "",
                        }}
                        label="Percentage "
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: "customColor.black7",
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                )}
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Amount is required",
                    },
                    onBlur(event) {
                      resetPaySlip();
                    },
                  }}
                  control={form.control}
                  name={`deductions.${i}.amount`}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        fontSize: "12px",
                        variant: "outline",
                        type: "text",
                        value:
                          field.value !== null && field.value !== undefined
                            ? Number(field.value).toLocaleString()
                            : "",
                        onChange: (e: any) => {
                          const value = e.target.value.replace(/,/g, "");
                          field.onChange(Number(value));
                        },

                        maxW: "91.33px",
                        height: "26.33px",
                        padding: "12px 15px",
                        boxShadow: "none",
                        placeholder: "",
                        isDisabled: [
                          PayTemp.PayTemplateCalcTypeEnum["PRE-TAX"],
                          PayTemp.PayTemplateCalcTypeEnum["POST-TAX"],
                          PayTemp.PayTemplateCalcTypeEnum.EMPLOYEE_EOBI,
                          PayTemp.PayTemplateCalcTypeEnum
                            .EMPLOYEE_PROVIDENT_FUND,
                        ].includes(
                          form.getValues(`deductions.${i}.calculationType`)
                        ),
                        _hover: {
                          borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                        },
                      }}
                      label="Amount "
                      labelProps={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "customColor.black7",
                        margin: "0px 0px 4px",
                      }}
                    />
                  )}
                />
              </HStack>
            </Stack>

            <Stack>
              <Icon
                fontSize={"20px"}
                as={IoIosCloseCircleOutline}
                marginTop={"15px"}
                color={"grey"}
                _hover={{
                  color: "red.500",
                }}
                onClick={() => {
                  deductionFieldArray.remove(i);
                  resetPaySlip();
                }}
              />
            </Stack>
          </Stack>
        )
      )}
    </Stack>
  );
}
