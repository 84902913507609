import {
  Box,
  Divider,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Fragment, useState } from "react";
import {
  AiOutlineBulb,
  AiOutlineUpload,
  AiTwotoneCalendar,
} from "react-icons/ai";
import { BiCameraMovie, BiLinkExternal } from "react-icons/bi";
import { FiMoreVertical, FiSend } from "react-icons/fi";
import { IoPlayCircleOutline } from "react-icons/io5";
import { MdUpdate } from "react-icons/md";
import {
  RiArrowLeftLine,
  RiMessage2Line,
  RiQuestionFill,
} from "react-icons/ri";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TemplateBuilderProvider } from "../../hooks/templateBuilderContext";
import TemplateAccess from "../access";
import TemplateBuild from "../build";
import TemplateReport from "../report";

export default function CreateTemplate() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const tabs = [
    {
      label: "Build",
      tab: <TemplateBuild />,
    },
    {
      label: "Report",
      tab: <TemplateReport />,
    },
    {
      label: "Access",
      tab: <TemplateAccess />,
    },
  ];

  const [tabIndex, setTabIndex] = useState(
    tabs.findIndex(
      ({ label }) =>
        label.toLowerCase() === searchParams.get("tab")?.toLowerCase()
    ) ?? 0
  );
  function changeTab(index: number) {
    setTabIndex(index);
    setSearchParams((params) => ({ ...params, tab: tabs?.[index].label }));
  }

  const menu1 = [
    { title: "Starter guide" },
    { title: "Give feedback" },
    { title: "Toggle expand pages" },
    { title: "Disable scoring" },
  ];

  const menu2: Array<{
    title: String;
    icon: any;
    desc: String;
    isDivider?: boolean;
  }> = [
    {
      title: "Book a demonstration",
      icon: <BiCameraMovie size={20} color="#424242" />,
      desc: "Let us show you how to get started.",
    },
    {
      title: "Webinars",
      icon: <AiTwotoneCalendar size={20} color="#424242" />,
      desc: "Save a spot at a live training event.",
    },
    {
      title: "Digitalize your forms",
      icon: <AiOutlineUpload size={20} color="#424242" />,
      desc: "Your forms converted into templates.",
      isDivider: true,
    },
    {
      title: "Knowledge base",
      icon: <AiOutlineBulb size={20} color="#424242" />,
      desc: "Find help in our support articles.",
    },
    {
      title: "Tutorials",
      icon: <IoPlayCircleOutline size={20} color="#424242" />,
      desc: "Learn about SafetyCulture with short videos.",
    },
    {
      title: "Product Updates",
      icon: <MdUpdate size={20} color="#424242" />,
      desc: "",
      isDivider: true,
    },
    {
      title: "Send feedback",
      icon: <FiSend size={20} color="#424242" />,
      desc: "",
    },
  ];

  return (
    <Box h="calc(100vh - 70px)" overflowY="auto">
      <Tabs index={tabIndex} onChange={changeTab} isLazy>
        <TabList h="60px" position="sticky" zIndex={9} top={0} bgColor="white">
          <HStack flex="1" mx="4">
            <HStack>
              <IconButton
                aria-label="back"
                backgroundColor="transparent"
                borderRadius={"50%"}
                border={"none"}
                _hover={{ backgroundColor: "#bfc6d4" }}
                icon={<RiArrowLeftLine color="#1f2533" size={"18px"} />}
                onClick={() => {
                  navigate(-1);
                }}
              />

              <Box display={{ xs: "none", md: "block" }}>
                <Link
                  fontSize="16px"
                  fontWeight={500}
                  color="textcolors.primary"
                  _hover={{ fontStyle: "none" }}
                >
                  Templates
                </Link>
              </Box>
            </HStack>
            <Spacer />
            <HStack>
              {tabs.map((_, i) => (
                <Tab
                  key={i}
                  borderBottomWidth="0.25rem"
                  // w={{xs: '16px', md: '112px'}}
                  w={"auto"}
                  p="1rem"
                  fontWeight="500"
                  fontSize="16px"
                >
                  {_.label}
                </Tab>
              ))}
            </HStack>
            <Spacer />
            <HStack>
              <Box>
                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<RiQuestionFill size={24} color="#545f70" />}
                    variant="outline"
                    marginInlineEnd={"12.8px"}
                    borderRadius={"50%"}
                    border={"none"}
                    _hover={{ backgroundColor: "#bfc6d4" }}
                    _active={{
                      backgroundColor: "#bfc6d4",
                    }}
                  />
                  <MenuList w={"300px"}>
                    <MenuItem padding={"10px 16px"}>
                      <HStack spacing={0} align="top" w="100%">
                        <Box margin="2px 11.2px 0px 0px">
                          <RiMessage2Line size={20} color="#424242" />
                        </Box>
                        <Text
                          color="textcolors.secondary"
                          fontSize={"14px"}
                          fontWeight={400}
                        >
                          Live chat with Support
                        </Text>
                      </HStack>
                    </MenuItem>
                    {menu2.map((menu, i) => {
                      return (
                        <Fragment key={i}>
                          {menu.isDivider && (
                            <Divider
                              borderWidth="1px solid"
                              borderColor="borders.accent"
                              style={{
                                margin: "8px 0px 8px 0px",
                              }}
                            />
                          )}
                          <MenuItem padding={"10px 16px"}>
                            <HStack
                              spacing={0}
                              justifyContent={"space-between"}
                              align="top"
                              w="100%"
                            >
                              <HStack spacing={0} align="top">
                                <Box margin="2px 11.2px 0px 0px">
                                  {menu.icon}
                                </Box>
                                <VStack spacing={0} align="start">
                                  <Text
                                    color="textcolors.secondary"
                                    fontSize={"14px"}
                                    fontWeight={400}
                                  >
                                    {menu.title}
                                  </Text>
                                  <Text
                                    color="icons.primary"
                                    fontSize={"12px"}
                                    fontWeight={400}
                                  >
                                    {menu.desc}
                                  </Text>
                                </VStack>
                              </HStack>
                              <Box>
                                <BiLinkExternal size={14} color="#545f70" />
                              </Box>
                            </HStack>
                          </MenuItem>
                        </Fragment>
                      );
                    })}
                  </MenuList>
                </Menu>
              </Box>
              <Box>
                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<FiMoreVertical size={24} color="#545f70" />}
                    variant="outline"
                    marginInlineEnd={"12.8px"}
                    borderRadius={"50%"}
                    border={"none"}
                    _hover={{ backgroundColor: "#bfc6d4" }}
                    _active={{
                      backgroundColor: "#bfc6d4",
                    }}
                  />
                  <MenuList minWidth={"100px"}>
                    {menu1.map((menu, i) => {
                      return (
                        <Fragment key={i}>
                          <MenuItem
                            color="textcolors.primary"
                            fontSize="14px"
                            fontWeight={400}
                          >
                            {menu.title}
                          </MenuItem>
                        </Fragment>
                      );
                    })}
                  </MenuList>
                </Menu>
              </Box>
            </HStack>
          </HStack>
        </TabList>
        <TemplateBuilderProvider>
          <TabPanels
            backgroundColor="backgrounds.primary"
            minH="calc(100vh - 62px)"
          >
            {tabs.map((_, i) => (
              <TabPanel key={i} p="0">
                {_.tab}
              </TabPanel>
            ))}
          </TabPanels>
        </TemplateBuilderProvider>
      </Tabs>
    </Box>
  );
}
