import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Collapse,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Stack,
  Text,
  useRadioGroup,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useController, useForm } from "react-hook-form";
import { AiFillInfoCircle } from "react-icons/ai";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { GiTakeMyMoney } from "react-icons/gi";
import { IoTime } from "react-icons/io5";
import { MdAccountBalance, MdAdd, MdPeople, MdRemove } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AccessControl } from "../../../../api/AccessControl";
import { InviteUser } from "../../../../api/InviteUser";
import { Business } from "./Components/Business";
import { Payroll } from "./Components/Payroll";
import { Project } from "./Components/Project";
import { UserProfile } from "./Components/UserProfile";
import { XeroApp } from "./Components/XeroApp";
import AccountingPermissions from "./InviteUser/AccountingPermissions";
import AuditorPermissions from "./InviteUser/AuditorPermissions";
import BusinessPermissions from "./InviteUser/BusinessPermissions";
import BusinessSettingsPermissions from "./InviteUser/BusinessSettingsPermissions";
import ContactsPermission from "./InviteUser/ContatctsPermission";
import CustomRadio from "./InviteUser/CustomRadio";
import EmployeesPermission from "./InviteUser/EmployeesPermsion";
import ExpenseClaimPermission from "./InviteUser/ExpenseClaimPermission";
import OnDemandNotification from "./InviteUser/OnDemandNotification";
import PayrollPermissions from "./InviteUser/PayrollPermissions";
import ReportPermissions from "./InviteUser/ReportPermissions";
import StatuaryBenefitsPermissions from "./InviteUser/StatuaryBenefitsPermissions";
import TimeAttendancePermissions from "./InviteUser/Time&AttendancePermissions";
type ToggleKeys =
  | "ondemand"
  | "contacts"
  | "employee"
  | "expenseClaim"
  | "timeAttendance"
  | "auditor"
  | "payroll"
  | "reports"
  | "statuaryBenefits"
  | "business"
  | "accounting"
  | "businessSetting";
export function UpdatePermission() {
  const userForm = useForm<InviteUser.UserInviteDetail>({
    defaultValues: {
      email: "",
      user: {
        firstName: "",
        lastName: "",
      },
      userId: "",
      allowedFeatures: {
        onDemandNotifications: {
          onDemandNotifications: false,
        },
        contacts: {
          allContacts: false,
        },
        employee: {
          financialDetails: false,
          personalDetails: false,
          employees: false,
          leave: false,
        },
        expenseClaim: {
          expenseClaim: false,
        },

        timeAndAttendance: {
          locations: false,
          checkinReport: false,
          notifications: false,
          schedules: false,
          statistics: false,
        },
        auditor: {
          templates: false,
          inspections: false,
          schedule: false,
          periodicalSchedule: false,
          periodicalReports: false,
          notificationSettings: false,
        },
        payroll: {
          timesheets: false,
          payRuns: false,
          payrollSettings: false,
        },
        reports: {
          employeeDetails: false,
          employeeContactDetails: false,
          employeeRemuneration: false,
          leaveRequests: false,
          leaveBalance: false,
          payrollActivityDetail: false,
          payrollActivitySummary: false,
          payrollEmployeeSummary: false,
          attendanceReport: false,
          transactionListingDetail: false,
          transactionListingSummary: false,
          employeeCheckIn: true,
        },
        statuaryBenefits: {
          statuaryBenefits: false,
        },
        business: {
          billsToPay: false,
          invoices: false,
          productsAndServices: false,
        },
        accounting: {
          chartOfAccounts: false,
          taxRates: false,
        },
        businessSettings: {
          businessSetup: false,
          users: false,
          mappingWidow: false,
          payrollSettings: false,
          invoiceSettings: false,
          editBusinessPermission: false,
        },
      },
      accessLevel: "ADMIN",
      status: "PENDING",
      id: "",
    },
  });
  const [toggles, setToggles] = useState<Record<ToggleKeys, boolean>>({
    ondemand: false,
    contacts: false,
    employee: false,
    expenseClaim: false,
    timeAttendance: false,
    auditor: false,
    payroll: false,
    reports: false,
    statuaryBenefits: false,
    business: false,
    accounting: false,
    businessSetting: false,
  });
  const handleToggle = (key: ToggleKeys) => {
    setToggles((prev) => ({ ...prev, [key]: !prev[key] }));
  };
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  let location = useLocation();
  const toast = useToast();
  useEffect(() => {
    if (id) {
      if (location.state?.status === "PENDING") {
        InviteUser.FetchUserById(
          id,
          (res) => {
            userForm.reset(res);

            setIsLoading(false);
          },
          (err) => {
            toast({
              title: "Error",
              description: err,
              status: "error",
            });
            setIsLoading(false);
          }
        );
      } else {
        AccessControl.FetchUserById(
          id,
          (res) => {
            userForm.reset(res);

            setIsLoading(false);
          },
          (err) => {
            toast({
              title: "Error",
              description: err,
              status: "error",
            });
            setIsLoading(false);
          }
        );
      }
    }
  }, [id]);
  const options = [
    {
      name: "Admin",
      value: "ADMIN",
    },
    {
      name: "Manager",
      value: "MANAGER",
    },
    {
      name: "Supervisor",
      value: "SUPERVISOR",
    },
    {
      name: "Client",
      value: "CLIENT",
    },
  ];

  // const options = ["react", "vue", "svelte"];
  const AccessLevelController = useController({
    name: "accessLevel",
    defaultValue: "ADMIN",
    control: userForm.control,
  });
  const { getRootProps, getRadioProps } = useRadioGroup({
    ...AccessLevelController.field,
  });

  const group = getRootProps();
  const navigate = useNavigate();

  return (
    <FormProvider {...userForm}>
      <Stack h="calc(100vh - 70px)" overflowY="auto">
        <Stack gap={0} marginBottom={"70px"}>
          <HStack
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
            justifyContent="space-between"
            h={"auto"}
            borderBottom={"1px solid #d6dade"}
          >
            <Stack flex={1} direction={{ xs: "column", md: "row" }}>
              <Stack gap={0} padding={"16px 0px"} margin={"0px 4px 0px 32px"}>
                <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
                  <BreadcrumbItem>
                    <BreadcrumbLink
                      href="/app/payroll/organisationSettings"
                      fontSize={"11px"}
                      color={"primary.950"}
                    >
                      Organisation Settings
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <BreadcrumbLink
                      href="/app/payroll/user"
                      fontSize={"11px"}
                      color={"primary.950"}
                      padding={"0px 20px 0px 0px"}
                    >
                      User
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>

                <Text
                  fontWeight={"bold"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize={"17px"}
                  padding={"0px 12px 0px 0px"}
                >
                  Update permissions
                </Text>
              </Stack>
            </Stack>
          </HStack>
          <Stack
            padding={"24px 16px"}
            direction={"row"}
            _dark={{
              bgColor: "customColor.dark.100",
            }}
            _light={{
              bgColor: "customColor.gray.400",
            }}
          >
            <Container
              maxW={"container.xl"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Stack direction={{ xs: "column", md: "row" }}>
                <UserProfile />
                <Stack>
                  <Project />
                  <Payroll />
                  <Stack
                    gap={0}
                    padding={"24px"}
                    boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                    borderWidth="1px"
                    borderStyle={"solid"}
                    borderColor="#f6f6f6"
                    _dark={{
                      bgColor: "customColor.dark.50",
                    }}
                    _light={{
                      bgColor: "customColor.white",
                    }}
                    borderRadius={"5px"}
                    w={{ md: "100%", lg: "700px", xl: "850px" }}
                  >
                    <Text
                      fontSize={"15px"}
                      fontWeight={"700"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      flex={1}
                      textAlign={"left"}
                    >
                      Module Access
                    </Text>

                    <Text
                      _light={{
                        color: "customColor.black7",
                      }}
                      padding={"8px 0px"}
                      fontSize={"15px"}
                      fontWeight={"400"}
                    >
                      It allows admin,manager,supervisor and client full access
                      to the module.
                    </Text>

                    <Heading
                      padding={"8px 0px"}
                      fontSize={"15px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"700"}
                    >
                      How much access do you need?
                    </Heading>

                    <HStack {...group}>
                      {options.map((value) => {
                        const radio = getRadioProps({
                          value: value.value,
                        });
                        return (
                          <CustomRadio key={value.value} {...radio}>
                            {value.name}
                          </CustomRadio>
                        );
                      })}
                    </HStack>

                    <Stack
                      _light={{
                        bg: "rgb(242, 243, 244)",
                      }}
                      _dark={{
                        bg: "customColor.gray.100",
                      }}
                      margin={"16px 0px 20px"}
                      padding={"4px"}
                      direction={"row"}
                      gap={0}
                    >
                      <Stack padding={"12px 0px 0px 8px"}>
                        {" "}
                        <Icon as={AiFillInfoCircle} />
                      </Stack>
                      <Stack padding={"8px 12px"}>
                        <Text
                          lineHeight={"1.5rem"}
                          fontSize={"13px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.dark.200",
                          }}
                          margin={"-5px 0px 0px 12px"}
                        >
                          This role suits business owners and admin staff that
                          manage the day-to-day business and accounting.
                          <Link
                            textDecoration={"underline"}
                            _light={{
                              color: "primary.950",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            _hover={{
                              color: "#0078C8",
                            }}
                          >
                            Learn more about user roles
                            <Icon as={BsBoxArrowUpRight} />
                          </Link>
                        </Text>
                      </Stack>
                    </Stack>

                    {/* <Stack flex={1} gap={0}>
                      <Stack
                        padding={"12px 0px"}
                        borderTop={"1px solid  #ccced2"}
                        direction={"row"}
                        gap={0}
                        alignItems={"center"}
                      >
                        <Icon
                          as={RiAppsFill}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          height={"24px"}
                          width={"24px"}
                          paddingLeft={"6px"}
                        />
                        <Text
                          fontSize={"13px"}
                          padding={"8px 0px 8px 3px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          fontWeight={"bold"}
                        >
                          Report
                        </Text>
                      </Stack>

                      <ReportPermissions isLoading={isLoading} />
                    </Stack> */}
                    {/* <Stack flex={1} gap={0}>
                      <Stack
                        onClick={handleToggle}
                        padding={"12px 0px"}
                        borderTop={"1px solid #ccced2"}
                        direction={"row"}
                        gap={0}
                        alignItems={"center"}
                        cursor="pointer"
                        justifyContent={"space-between"}
                      >
                        <HStack alignItems={"center"}>
                          <Icon
                            as={RiAppsFill}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            height={"24px"}
                            width={"24px"}
                            paddingLeft={"6px"}
                          />
                          <Text
                            fontSize={"13px"}
                            padding={"8px 0px 8px 3px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            fontWeight={"bold"}
                          >
                            Report
                          </Text>
                        </HStack>
                        <IconButton
                          fontSize={"20px"}
                          aria-label={"sum-button"}
                          icon={show ? <MdRemove /> : <MdAdd />}
                          bg={"transparent"}
                          color={"black"}
                          _hover={{
                            bg: "transparent",
                          }}
                        />
                      </Stack>

                      <Collapse in={show} animateOpacity>
                        <ReportPermissions isLoading={isLoading} />
                      </Collapse>
                    </Stack>
                    <Stack gap={0} flex={1}>
                      <Stack
                        onClick={handleToggle2}
                        padding={"12px 0px"}
                        borderTop={"1px solid #ccced2"}
                        direction={"row"}
                        gap={0}
                        alignItems={"center"}
                        cursor="pointer"
                        justifyContent={"space-between"}
                      >
                        <HStack alignItems={"center"}>
                          <Icon
                            as={MdAccountBalance}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            height={"24px"}
                            width={"24px"}
                            paddingLeft={"6px"}
                          />
                          <Text
                            fontSize={"13px"}
                            padding={"8px 0px 8px 3px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            fontWeight={"bold"}
                          >
                            Accounting
                          </Text>
                        </HStack>
                        <IconButton
                          fontSize={"20px"}
                          aria-label={"sum-button"}
                          icon={show2 ? <MdRemove /> : <MdAdd />}
                          bg={"transparent"}
                          color={"black"}
                          _hover={{
                            bg: "transparent",
                          }}
                        />
                      </Stack>
                      <Collapse in={show2} animateOpacity>
                        <AccountingPermissions isLoading={isLoading} />
                      </Collapse>
                    </Stack>

                    <Stack gap={0} flex={1}>
                      <Stack
                        onClick={handleToggle3}
                        padding={"12px 0px"}
                        borderTop={"1px solid #ccced2"}
                        direction={"row"}
                        gap={0}
                        alignItems={"center"}
                        cursor="pointer"
                        justifyContent={"space-between"}
                      >
                        <HStack alignItems={"center"}>
                          <Icon
                            as={IoCash}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            height={"24px"}
                            width={"24px"}
                            paddingLeft={"6px"}
                          />
                          <Text
                            fontSize={"13px"}
                            padding={"8px 0px 8px 3px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            fontWeight={"bold"}
                          >
                            PayRoll
                          </Text>
                        </HStack>
                        <IconButton
                          fontSize={"20px"}
                          aria-label={"sum-button"}
                          icon={show3 ? <MdRemove /> : <MdAdd />}
                          bg={"transparent"}
                          color={"black"}
                          _hover={{
                            bg: "transparent",
                          }}
                        />
                      </Stack>
                      <Collapse in={show3} animateOpacity>
                        <PayrollPermissions isLoading={isLoading} />
                      </Collapse>
                    </Stack>
                    <Stack gap={0} flex={1}>
                      <Stack
                        onClick={handleToggle4}
                        padding={"12px 0px"}
                        borderTop={"1px solid #ccced2"}
                        direction={"row"}
                        gap={0}
                        alignItems={"center"}
                        cursor="pointer"
                        justifyContent={"space-between"}
                      >
                        <HStack alignItems={"center"}>
                          <Icon
                            as={MdPeople}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            height={"24px"}
                            width={"24px"}
                            paddingLeft={"6px"}
                          />
                          <Text
                            fontSize={"13px"}
                            padding={"8px 0px 8px 3px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            fontWeight={"bold"}
                          >
                            Employees
                          </Text>
                        </HStack>
                        <IconButton
                          fontSize={"20px"}
                          aria-label={"sum-button"}
                          icon={show4 ? <MdRemove /> : <MdAdd />}
                          bg={"transparent"}
                          color={"black"}
                          _hover={{
                            bg: "transparent",
                          }}
                        />
                      </Stack>
                      <Collapse in={show4} animateOpacity>
                        <EmployeesPermission isLoading={isLoading} />
                      </Collapse>
                    </Stack>
                    <Stack gap={0} flex={1}>
                      <Stack
                        onClick={handleToggle5}
                        padding={"12px 0px"}
                        borderTop={"1px solid #ccced2"}
                        direction={"row"}
                        gap={0}
                        alignItems={"center"}
                        cursor="pointer"
                        justifyContent={"space-between"}
                      >
                        <HStack alignItems={"center"}>
                          <Icon
                            as={IoTime}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            height={"24px"}
                            width={"24px"}
                            paddingLeft={"6px"}
                          />
                          <Text
                            fontSize={"13px"}
                            padding={"8px 0px 8px 3px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            fontWeight={"bold"}
                          >
                            Timesheet
                          </Text>
                        </HStack>
                        <IconButton
                          fontSize={"20px"}
                          aria-label={"sum-button"}
                          icon={show5 ? <MdRemove /> : <MdAdd />}
                          bg={"transparent"}
                          color={"black"}
                          _hover={{
                            bg: "transparent",
                          }}
                        />
                      </Stack>
                      <Collapse in={show5} animateOpacity>
                        <TimeAttendancePermissions isLoading={isLoading} />
                      </Collapse>
                    </Stack> */}
                    <Stack spacing={6}>
                      <Stack
                        flex={1}
                        gap={0}
                        boxShadow={
                          "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
                        }
                        _hover={{
                          boxShadow: "0 2px 4px rgba(15,34,58,.12)",
                        }}
                        borderWidth="1px"
                        borderStyle={"solid"}
                        borderColor="#f6f6f6"
                        padding={"20px"}
                      >
                        <Stack
                          onClick={() => handleToggle("ondemand")}
                          direction={"row"}
                          gap={0}
                          alignItems={"center"}
                          cursor="pointer"
                          justifyContent={"space-between"}
                        >
                          <HStack alignItems={"center"}>
                            <Icon
                              as={MdPeople}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              height={"20px"}
                              width={"20px"}
                            />
                            <Text
                              fontSize={"14px"}
                              padding={"8px 0px 8px 3px"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontWeight={"bold"}
                            >
                              On Demand Notifications
                            </Text>
                          </HStack>
                          <IconButton
                            fontSize={"20px"}
                            aria-label={"sum-button"}
                            icon={toggles.ondemand ? <MdRemove /> : <MdAdd />}
                            bg={"transparent"}
                            color={"black"}
                            _hover={{
                              bg: "transparent",
                            }}
                          />
                        </Stack>
                        <Collapse in={toggles.ondemand} animateOpacity>
                          <OnDemandNotification isLoading={isLoading} />
                        </Collapse>
                      </Stack>
                      <Stack
                        flex={1}
                        gap={0}
                        boxShadow={
                          "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
                        }
                        _hover={{
                          boxShadow: "0 2px 4px rgba(15,34,58,.12)",
                        }}
                        borderWidth="1px"
                        borderStyle={"solid"}
                        borderColor="#f6f6f6"
                        padding={"20px"}
                      >
                        <Stack
                          onClick={() => handleToggle("contacts")}
                          direction={"row"}
                          gap={0}
                          alignItems={"center"}
                          cursor="pointer"
                          justifyContent={"space-between"}
                        >
                          <HStack alignItems={"center"}>
                            <Icon
                              as={MdPeople}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              height={"20px"}
                              width={"20px"}
                            />
                            <Text
                              fontSize={"14px"}
                              padding={"8px 0px 8px 3px"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontWeight={"bold"}
                            >
                              Contacts
                            </Text>
                          </HStack>
                          <IconButton
                            fontSize={"20px"}
                            aria-label={"sum-button"}
                            icon={toggles.contacts ? <MdRemove /> : <MdAdd />}
                            bg={"transparent"}
                            color={"black"}
                            _hover={{
                              bg: "transparent",
                            }}
                          />
                        </Stack>
                        <Collapse in={toggles.contacts} animateOpacity>
                          <ContactsPermission isLoading={isLoading} />
                        </Collapse>
                      </Stack>
                      <Stack
                        flex={1}
                        gap={0}
                        boxShadow={
                          "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
                        }
                        _hover={{
                          boxShadow: "0 2px 4px rgba(15,34,58,.12)",
                        }}
                        borderWidth="1px"
                        borderStyle={"solid"}
                        borderColor="#f6f6f6"
                        padding={"20px"}
                      >
                        <Stack
                          onClick={() => handleToggle("employee")}
                          direction={"row"}
                          gap={0}
                          alignItems={"center"}
                          cursor="pointer"
                          justifyContent={"space-between"}
                        >
                          <HStack alignItems={"center"}>
                            <Icon
                              as={MdPeople}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              height={"20px"}
                              width={"20px"}
                            />
                            <Text
                              fontSize={"14px"}
                              padding={"8px 0px 8px 3px"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontWeight={"bold"}
                            >
                              Employees
                            </Text>
                          </HStack>
                          <IconButton
                            fontSize={"20px"}
                            aria-label={"sum-button"}
                            icon={toggles.employee ? <MdRemove /> : <MdAdd />}
                            bg={"transparent"}
                            color={"black"}
                            _hover={{
                              bg: "transparent",
                            }}
                          />
                        </Stack>
                        <Collapse in={toggles.employee} animateOpacity>
                          <EmployeesPermission isLoading={isLoading} />
                        </Collapse>
                      </Stack>
                      <Stack
                        flex={1}
                        gap={0}
                        boxShadow={
                          "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
                        }
                        _hover={{
                          boxShadow: "0 2px 4px rgba(15,34,58,.12)",
                        }}
                        borderWidth="1px"
                        borderStyle={"solid"}
                        borderColor="#f6f6f6"
                        padding={"20px"}
                      >
                        <Stack
                          onClick={() => handleToggle("expenseClaim")}
                          direction={"row"}
                          gap={0}
                          alignItems={"center"}
                          cursor="pointer"
                          justifyContent={"space-between"}
                        >
                          <HStack alignItems={"center"}>
                            <Icon
                              as={MdPeople}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              height={"20px"}
                              width={"20px"}
                            />
                            <Text
                              fontSize={"14px"}
                              padding={"8px 0px 8px 3px"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontWeight={"bold"}
                            >
                              Expense Claim
                            </Text>
                          </HStack>
                          <IconButton
                            fontSize={"20px"}
                            aria-label={"sum-button"}
                            icon={
                              toggles.expenseClaim ? <MdRemove /> : <MdAdd />
                            }
                            bg={"transparent"}
                            color={"black"}
                            _hover={{
                              bg: "transparent",
                            }}
                          />
                        </Stack>
                        <Collapse in={toggles.expenseClaim} animateOpacity>
                          <ExpenseClaimPermission isLoading={isLoading} />
                        </Collapse>
                      </Stack>
                      <Stack
                        flex={1}
                        gap={0}
                        boxShadow={
                          "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
                        }
                        _hover={{
                          boxShadow: "0 2px 4px rgba(15,34,58,.12)",
                        }}
                        borderWidth="1px"
                        borderStyle={"solid"}
                        borderColor="#f6f6f6"
                        padding={"20px"}
                      >
                        <Stack
                          onClick={() => handleToggle("timeAttendance")}
                          direction={"row"}
                          gap={0}
                          alignItems={"center"}
                          cursor="pointer"
                          justifyContent={"space-between"}
                        >
                          <HStack alignItems={"center"}>
                            <Icon
                              as={IoTime}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              height={"20px"}
                              width={"20px"}
                            />
                            <Text
                              fontSize={"14px"}
                              padding={"8px 0px 8px 3px"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontWeight={"bold"}
                            >
                              Time & Attendance
                            </Text>
                          </HStack>
                          <IconButton
                            fontSize={"20px"}
                            aria-label={"sum-button"}
                            icon={
                              toggles.timeAttendance ? <MdRemove /> : <MdAdd />
                            }
                            bg={"transparent"}
                            color={"black"}
                            _hover={{
                              bg: "transparent",
                            }}
                          />
                        </Stack>
                        <Collapse in={toggles.timeAttendance} animateOpacity>
                          <TimeAttendancePermissions isLoading={isLoading} />
                        </Collapse>
                      </Stack>
                      <Stack
                        flex={1}
                        gap={0}
                        boxShadow={
                          "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
                        }
                        _hover={{
                          boxShadow: "0 2px 4px rgba(15,34,58,.12)",
                        }}
                        borderWidth="1px"
                        borderStyle={"solid"}
                        borderColor="#f6f6f6"
                        padding={"20px"}
                      >
                        <Stack
                          onClick={() => handleToggle("auditor")}
                          direction={"row"}
                          gap={0}
                          alignItems={"center"}
                          cursor="pointer"
                          justifyContent={"space-between"}
                        >
                          <HStack alignItems={"center"}>
                            <Icon
                              as={IoTime}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              height={"20px"}
                              width={"20px"}
                            />
                            <Text
                              fontSize={"14px"}
                              padding={"8px 0px 8px 3px"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontWeight={"bold"}
                            >
                              Auditor
                            </Text>
                          </HStack>
                          <IconButton
                            fontSize={"20px"}
                            aria-label={"sum-button"}
                            icon={toggles.auditor ? <MdRemove /> : <MdAdd />}
                            bg={"transparent"}
                            color={"black"}
                            _hover={{
                              bg: "transparent",
                            }}
                          />
                        </Stack>
                        <Collapse in={toggles.auditor} animateOpacity>
                          <AuditorPermissions isLoading={isLoading} />
                        </Collapse>
                      </Stack>

                      <Stack
                        flex={1}
                        gap={0}
                        boxShadow={
                          "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
                        }
                        _hover={{
                          boxShadow: "0 2px 4px rgba(15,34,58,.12)",
                        }}
                        borderWidth="1px"
                        borderStyle={"solid"}
                        borderColor="#f6f6f6"
                        padding={"20px"}
                      >
                        <Stack
                          onClick={() => handleToggle("payroll")}
                          direction={"row"}
                          gap={0}
                          alignItems={"center"}
                          cursor="pointer"
                          justifyContent={"space-between"}
                        >
                          <HStack alignItems={"center"}>
                            <Icon
                              as={GiTakeMyMoney}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              height={"20px"}
                              width={"20px"}
                            />
                            <Text
                              fontSize={"14px"}
                              padding={"8px 0px 8px 3px"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontWeight={"bold"}
                            >
                              PayRoll
                            </Text>
                          </HStack>
                          <IconButton
                            fontSize={"20px"}
                            aria-label={"sum-button"}
                            icon={toggles.payroll ? <MdRemove /> : <MdAdd />}
                            bg={"transparent"}
                            color={"black"}
                            _hover={{
                              bg: "transparent",
                            }}
                          />
                        </Stack>
                        <Collapse in={toggles.payroll} animateOpacity>
                          <PayrollPermissions isLoading={isLoading} />
                        </Collapse>
                      </Stack>
                      <Stack
                        flex={1}
                        gap={0}
                        boxShadow={
                          "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
                        }
                        _hover={{
                          boxShadow: "0 2px 4px rgba(15,34,58,.12)",
                        }}
                        borderWidth="1px"
                        borderStyle={"solid"}
                        borderColor="#f6f6f6"
                        padding={"20px"}
                      >
                        <Stack
                          onClick={() => handleToggle("reports")}
                          direction={"row"}
                          gap={0}
                          alignItems={"center"}
                          cursor="pointer"
                          justifyContent={"space-between"}
                        >
                          <HStack alignItems={"center"}>
                            <Icon
                              as={TbReportSearch}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              height={"20px"}
                              width={"20px"}
                            />
                            <Text
                              fontSize={"14px"}
                              padding={"8px 0px 8px 3px"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontWeight={"bold"}
                            >
                              Reports
                            </Text>
                          </HStack>
                          <IconButton
                            fontSize={"20px"}
                            aria-label={"sum-button"}
                            icon={toggles.reports ? <MdRemove /> : <MdAdd />}
                            bg={"transparent"}
                            color={"black"}
                            _hover={{
                              bg: "transparent",
                            }}
                          />
                        </Stack>

                        <Collapse in={toggles.reports} animateOpacity>
                          <ReportPermissions isLoading={isLoading} />
                        </Collapse>
                      </Stack>
                      <Stack
                        flex={1}
                        gap={0}
                        boxShadow={
                          "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
                        }
                        _hover={{
                          boxShadow: "0 2px 4px rgba(15,34,58,.12)",
                        }}
                        borderWidth="1px"
                        borderStyle={"solid"}
                        borderColor="#f6f6f6"
                        padding={"20px"}
                      >
                        <Stack
                          onClick={() => handleToggle("statuaryBenefits")}
                          direction={"row"}
                          gap={0}
                          alignItems={"center"}
                          cursor="pointer"
                          justifyContent={"space-between"}
                        >
                          <HStack alignItems={"center"}>
                            <Icon
                              as={IoTime}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              height={"20px"}
                              width={"20px"}
                            />
                            <Text
                              fontSize={"14px"}
                              padding={"8px 0px 8px 3px"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontWeight={"bold"}
                            >
                              Statuary Benefits
                            </Text>
                          </HStack>
                          <IconButton
                            fontSize={"20px"}
                            aria-label={"sum-button"}
                            icon={
                              toggles.statuaryBenefits ? (
                                <MdRemove />
                              ) : (
                                <MdAdd />
                              )
                            }
                            bg={"transparent"}
                            color={"black"}
                            _hover={{
                              bg: "transparent",
                            }}
                          />
                        </Stack>
                        <Collapse in={toggles.statuaryBenefits} animateOpacity>
                          <StatuaryBenefitsPermissions isLoading={isLoading} />
                        </Collapse>
                      </Stack>
                      <Stack
                        flex={1}
                        gap={0}
                        boxShadow={
                          "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
                        }
                        _hover={{
                          boxShadow: "0 2px 4px rgba(15,34,58,.12)",
                        }}
                        borderWidth="1px"
                        borderStyle={"solid"}
                        borderColor="#f6f6f6"
                        padding={"20px"}
                      >
                        <Stack
                          onClick={() => handleToggle("business")}
                          direction={"row"}
                          gap={0}
                          alignItems={"center"}
                          cursor="pointer"
                          justifyContent={"space-between"}
                        >
                          <HStack alignItems={"center"}>
                            <Icon
                              as={IoTime}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              height={"20px"}
                              width={"20px"}
                            />
                            <Text
                              fontSize={"14px"}
                              padding={"8px 0px 8px 3px"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontWeight={"bold"}
                            >
                              Business
                            </Text>
                          </HStack>
                          <IconButton
                            fontSize={"20px"}
                            aria-label={"sum-button"}
                            icon={toggles.business ? <MdRemove /> : <MdAdd />}
                            bg={"transparent"}
                            color={"black"}
                            _hover={{
                              bg: "transparent",
                            }}
                          />
                        </Stack>
                        <Collapse in={toggles.business} animateOpacity>
                          <BusinessPermissions isLoading={isLoading} />
                        </Collapse>
                      </Stack>
                      <Stack
                        flex={1}
                        gap={0}
                        boxShadow={
                          "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
                        }
                        _hover={{
                          boxShadow: "0 2px 4px rgba(15,34,58,.12)",
                        }}
                        borderWidth="1px"
                        borderStyle={"solid"}
                        borderColor="#f6f6f6"
                        padding={"20px"}
                      >
                        <Stack
                          onClick={() => handleToggle("accounting")}
                          direction={"row"}
                          gap={0}
                          alignItems={"center"}
                          cursor="pointer"
                          justifyContent={"space-between"}
                        >
                          <HStack alignItems={"center"}>
                            <Icon
                              as={MdAccountBalance}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              height={"20px"}
                              width={"20px"}
                            />
                            <Text
                              fontSize={"14px"}
                              padding={"8px 0px 8px 3px"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontWeight={"bold"}
                            >
                              Accounting
                            </Text>
                          </HStack>
                          <IconButton
                            fontSize={"20px"}
                            aria-label={"sum-button"}
                            icon={toggles.accounting ? <MdRemove /> : <MdAdd />}
                            bg={"transparent"}
                            color={"black"}
                            _hover={{
                              bg: "transparent",
                            }}
                          />
                        </Stack>
                        <Collapse in={toggles.accounting} animateOpacity>
                          <AccountingPermissions isLoading={isLoading} />
                        </Collapse>
                      </Stack>
                      <Stack
                        flex={1}
                        gap={0}
                        boxShadow={
                          "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
                        }
                        _hover={{
                          boxShadow: "0 2px 4px rgba(15,34,58,.12)",
                        }}
                        borderWidth="1px"
                        borderStyle={"solid"}
                        borderColor="#f6f6f6"
                        padding={"20px"}
                      >
                        <Stack
                          onClick={() => handleToggle("businessSetting")}
                          direction={"row"}
                          gap={0}
                          alignItems={"center"}
                          cursor="pointer"
                          justifyContent={"space-between"}
                        >
                          <HStack alignItems={"center"}>
                            <Icon
                              as={IoTime}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              height={"20px"}
                              width={"20px"}
                            />
                            <Text
                              fontSize={"14px"}
                              padding={"8px 0px 8px 3px"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontWeight={"bold"}
                            >
                              Business Settings
                            </Text>
                          </HStack>
                          <IconButton
                            fontSize={"20px"}
                            aria-label={"sum-button"}
                            icon={
                              toggles.businessSetting ? <MdRemove /> : <MdAdd />
                            }
                            bg={"transparent"}
                            color={"black"}
                            _hover={{
                              bg: "transparent",
                            }}
                          />
                        </Stack>
                        <Collapse in={toggles.businessSetting} animateOpacity>
                          <BusinessSettingsPermissions isLoading={isLoading} />
                        </Collapse>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Business />
                  <XeroApp />
                </Stack>
                {/* <UserProfile /> */}
              </Stack>
            </Container>
            <Box
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "customColor.white",
              }}
              position="fixed"
              left={0}
              bottom="0"
              w="full"
              h="72px"
              borderTop={"1px solid #d6dade"}
              zIndex={"overlay"}
              boxShadow={
                "1 -1px 0 0 rgba(0,10,30,.2), 0 -3px 0 0 rgba(0,10,30,.05)"
              }
            >
              <Stack
                justifyContent="center"
                alignItems="flex-end"
                textAlign={"right"}
              >
                <ButtonGroup size="sm" mt="10px" margin={"16px"}>
                  <Button
                    backgroundColor={"#fff"}
                    borderRadius={"3px"}
                    color={"#ea4d4d"}
                    border={"1px solid #dcdee4"}
                    fontSize={"13px"}
                    h={"40px"}
                    variant={"solid"}
                    padding={"12px 16px"}
                    fontWeight={700}
                    _hover={{
                      backgroundColor: "#fff",
                    }}
                    onClick={() => navigate("/app/payroll/user")}
                  >
                    Cancel
                  </Button>
                  <Button
                    fontSize={"13px"}
                    bgColor="#3454D1"
                    h={"40px"}
                    borderRadius={"3px"}
                    borderWidth={"1px"}
                    borderColor={"#3454d1!important"}
                    variant={"solid"}
                    padding={"12px 16px"}
                    fontWeight={700}
                    _hover={{
                      bgColor: "#3454D1",
                    }}
                    // bg={"#80858F"}
                    // color={"#FFF"}
                    // border={"1px solid #80858F"}
                    // margin={"0px 0px 0px 12px"}
                    // fontSize={"15px"}
                    // minH={"40px"}
                    // padding={"7.52px 12px"}
                    // borderRadius={"3px"}
                    // isDisabled={false}
                    // _hover={{
                    //   bg: "#686d75",
                    //   color: "#FFF",
                    //   border: "1px solid #686d75",
                    // }}
                    isLoading={isLoading}
                    onClick={() => {
                      if (id) {
                        setIsLoading(true);
                        if (location.state?.status === "PENDING") {
                          InviteUser.UpdateUserById(
                            id,
                            userForm.getValues(),
                            (res) => {
                              toast({
                                title: "Success",
                                description: "User updated successfully",
                                status: "success",
                              });
                              setIsLoading(false);
                              navigate("/app/payroll/user");
                            },
                            (err) => {
                              toast({
                                title: "Error",
                                description: err,
                                status: "error",
                              });
                              setIsLoading(false);
                            }
                          );
                        } else {
                          AccessControl.UpdateUserById(
                            id,
                            userForm.getValues(),
                            (res) => {
                              toast({
                                title: "Success",
                                description: "User updated successfully",
                                status: "success",
                              });
                              setIsLoading(false);
                              navigate("/app/payroll/user");
                            },
                            (err) => {
                              toast({
                                title: "Error",
                                description: err,
                                status: "error",
                              });
                              setIsLoading(false);
                            }
                          );
                        }
                      }
                    }}
                  >
                    Update permissions
                  </Button>
                </ButtonGroup>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
