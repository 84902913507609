import {
  Button,
  Container,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Image,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { BsArrowReturnRight } from "react-icons/bs";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { CustomInput } from "../../../Common/CustomInput";
import { ClearanceFormApi } from "../../Api/ClearanceForm";
import { UploadAttachments } from "../../Api/UploadAttachments";
import HRISLOGO from "./../../../assets/hris-logo.png";
export function ClearanceForm() {
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      attachment: "",
      comment: "",
      clientCorrespondenceId: "",
      status: "",
      deductions: [
        {
          deduction: "",
          amount: 0,
        },
      ],
    },
  });
  const toast = useToast();
  const param = useParams();

  const clearanceId = param.clearanceId;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  useEffect(() => {
    const existingImage = form.getValues("attachment");
    if (existingImage) {
      setPreviewUrl(existingImage); // Set the preview URL if there's an existing file
    }
  }, [form]);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (
      droppedFile &&
      (droppedFile.type.startsWith("image/") ||
        droppedFile.type === "application/pdf")
    ) {
      const preview = droppedFile.type.startsWith("image/")
        ? URL.createObjectURL(droppedFile)
        : null;
      setPreviewUrl(preview); // Update the preview URL only for images
      handleFileUpload(droppedFile); // Upload the file
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      const preview = selectedFile.type.startsWith("image/")
        ? URL.createObjectURL(selectedFile)
        : null;
      setPreviewUrl(preview); // Update the preview URL only for images
      handleFileUpload(selectedFile); // Upload the file
    }
    e.target.value = ""; // Clear the file input after handling the file
  };

  const handleFileUpload = (file: File) => {
    const formData = new FormData();
    formData.append("docType", "EMPLOYEE_DOCUMENT");
    formData.append("file", file);

    UploadAttachments.UploadAttachment(
      formData,
      (success) => {
        form.setValue("attachment", success.url); // Set the uploaded file URL in the form
        toast({
          title: "File uploaded successfully",
          status: "success",
        });
      },
      (err) => {
        toast({
          title: "Error uploading file",
          status: "error",
        });
      }
    );
  };

  const handleDelete = () => {
    setPreviewUrl(null); // Clear the preview URL
    form.setValue("attachment", ""); // Clear the attachment from the form state
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the file input
    }
  };

  const clearanceComponents = useFieldArray({
    control: form.control,
    name: "deductions",
  });

  const watchedClearanceComponents = useWatch({
    control: form.control,
    name: "deductions",
  });
  return (
    <FormProvider {...form}>
      <Stack
        gap={0}
        _light={{
          backgroundColor: "customColor.gray.900",
        }}
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        h={"100vh"}
        w={"100wh"}
        overflow={"hidden"}
      >
        <Stack
          gap={0}
          overflowX="hidden"
          padding={{ xs: "30px 30px", md: "78px 98px" }}
          flex={{ base: 1, md: 4 }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          // issue in _light
          _light={{
            backgroundColor: "customColor.gray.400",
          }}
          borderLeft={"1px solid #ddd"}
          zIndex={1}
        >
          <Container
            maxW={"container.md"}
            bgColor={"#fff"}
            padding={"20px"}
            borderRadius={"10px"}
            boxShadow={
              "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
            }
          >
            <Stack padding={"0px"} gap={0}>
              <Image
                objectFit="fill"
                w="115px"
                margin={"0px 0px 24px"}
                src={HRISLOGO}
                alt="Logo"
                onClick={() => {
                  navigate("/");
                }}
              />
              <Heading
                fontSize={"24px"}
                fontWeight={"700"}
                margin={"0px 0px 24px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Clearance Form
              </Heading>
              <Text
                fontSize={"13px"}
                fontWeight={"700"}
                margin={"0px 0px 24px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
              >
                Enter the below data , and attach relevant proof to claim your
                clearance
              </Text>
            </Stack>
            <Stack spacing={3} gap={0}>
              <Stack justifyContent={"space-between"} direction={"row"}>
                <FormLabel
                  mb={0}
                  fontSize="13.44px"
                  fontWeight="700"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.pearlWhite",
                  }}
                  paddingBottom={"10px"}
                >
                  Deduction
                </FormLabel>
                <FormLabel
                  mb={0}
                  fontSize="13.44px"
                  fontWeight="700"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.pearlWhite",
                  }}
                  paddingBottom={"10px"}
                >
                  Amount
                </FormLabel>
              </Stack>

              <Table variant="simple" w="100%">
                <Tbody
                  borderTop={"1px solid #ccc"}
                  borderBottom={"1px solid #ccc"}
                >
                  {clearanceComponents.fields.map((field, index) => (
                    <Tr key={field.id}>
                      <Td padding={"5px"} w={"200px"}>
                        <Stack padding={"7px 0px 10px"}>
                          <Controller
                            name={`deductions.${index}.deduction`}
                            control={form.control}
                            rules={{
                              required: {
                                value: true,
                                message:
                                  "Clearance Component's deduction is required",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                ref={field.ref}
                                validationMessage={error?.message}
                                withValidation
                                input={{
                                  ...field,
                                  isInvalid: error !== undefined,
                                  placeholder: "",

                                  h: "32px",

                                  padding: "12px 15px",
                                  _light: {
                                    color: "customColor.black7",
                                  },
                                  fontSize: ".845rem",
                                  variant: "outline",
                                  type: "text",

                                  borderTopRadius: "3px",
                                  bgColor: "#fff",
                                }}
                              />
                            )}
                          />
                        </Stack>
                      </Td>

                      <Td padding={"5px"} w={"280px"}></Td>
                      <Td padding={"5px"} w={"100px"}>
                        <Stack
                          padding={"7px 0px 10px"}
                          direction={"row"}
                          gap={0}
                        >
                          <Controller
                            name={`deductions.${index}.amount`}
                            control={form.control}
                            rules={{
                              required: {
                                value: true,
                                message:
                                  "Clearance Component's Amount is required",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                ref={field.ref}
                                validationMessage={error?.message}
                                withValidation
                                input={{
                                  ...field,
                                  isInvalid: error !== undefined,
                                  placeholder: "",
                                  type: "number",
                                  value: field.value ?? "",
                                  step: "0.01",
                                  onChange: (e) => {
                                    const inputValue = e.target.value;

                                    // If the input value is empty, set the state to an empty string.
                                    if (inputValue === "") {
                                      field.onChange("");
                                    } else {
                                      // Otherwise, set the numeric value
                                      field.onChange(Number(e.target.value));
                                    }
                                  },
                                  h: "32px",

                                  padding: "12px 15px",
                                  _light: {
                                    color: "customColor.black7",
                                  },
                                  fontSize: ".845rem",
                                  variant: "outline",

                                  borderTopRadius: "3px",
                                  bgColor: "#fff",
                                }}
                              />
                            )}
                          />
                        </Stack>
                      </Td>
                      {clearanceComponents.fields.length > 1 && (
                        <Td
                          padding={"5px"}
                          w={"25px"}
                          onClick={() => {
                            clearanceComponents.remove(index);
                          }}
                        >
                          <Icon
                            fontSize={"20px"}
                            as={IoIosCloseCircleOutline}
                            color={"grey"}
                            _hover={{
                              color: "red.500",
                            }}
                          />
                        </Td>
                      )}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Stack
                marginBottom={"24px"}
                direction={{ xs: "column", sm: "row" }}
                gap={0}
                paddingTop={"15px"}
                justifyContent={"space-between"}
              >
                <Stack flex={1}>
                  <Button
                    leftIcon={<BsArrowReturnRight fontSize={"20px"} />}
                    margin={"0px 0px 0px 10px"}
                    fontSize={"13px"}
                    bgColor="#3454D1"
                    maxW={"250px"}
                    borderRadius={"3px"}
                    borderWidth={"1px"}
                    borderColor={"#3454d1!important"}
                    variant={"solid"}
                    padding={"12px 16px"}
                    fontWeight={700}
                    _hover={{
                      bgColor: "#3454D1",
                    }}
                    onClick={() => {
                      clearanceComponents.append({
                        deduction: "",
                        amount: 0,
                      });
                    }}
                  >
                    Add a new clearance
                  </Button>
                </Stack>

                <Stack
                  flex={1}
                  padding={"5px 0px"}
                  direction={"row"}
                  gap={0}
                  justifyContent={"space-between"}
                >
                  <Text
                    paddingRight={"5px"}
                    top={"5px"}
                    fontSize={"13.44px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    fontWeight={"bold"}
                  >
                    Total clearance Claim
                  </Text>
                  <Text
                    textAlign={"end"}
                    fontSize={"13.44px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    fontWeight={"bold"}
                  >
                    {watchedClearanceComponents
                      .reduce((prev, curr) => {
                        return prev + Number(curr.amount);
                      }, 0)
                      .toFixed(2)}
                  </Text>
                </Stack>
              </Stack>
              <Stack gap={0} marginBottom={"24px"}>
                <FormLabel
                  fontSize="13.44px"
                  fontWeight="700"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  margin={"0px 0px 8px"}
                >
                  Comments
                </FormLabel>
                <Controller
                  name="comment"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <>
                      <Textarea
                        {...field}
                        //   isInvalid={fieldState.invalid}
                        //   errorBorderColor="Red"
                        border="1px solid #e5e7eb"
                        fontSize=".845rem"
                        _hover={{
                          border: "1px solid hsl(0, 0%, 70%)",
                        }}
                        _focus={{
                          borderWidth: "1px",

                          boxShadow: "0 0 0 1px #2684FF",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#2684FF",
                            boxShadow: "0 0 0 1px #2684FF",
                          },
                        }}
                        _focusVisible={{
                          borderWidth: "1px",
                          boxShadow: "0 0 0 1px #2684FF",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#2684FF",
                            boxShadow: "0 0 0 1px #2684FF",
                          },
                        }}
                        _dark={{
                          bgColor: "customColor.dark.50",
                        }}
                        _light={{
                          bgColor: "customColor.white",
                          color: "customColor.black7",
                        }}
                        mb="15px"
                        overflowX="hidden"
                        maxLength={300}
                        maxW={"400px"}
                        shadow="inner"
                        placeholder="Type a Comment"
                        h="80px"
                      />
                    </>
                  )}
                />
              </Stack>
              {/* <Stack>
                <Text margin={"21px 0px"} fontSize={"21px"} fontWeight={"700"}>
                  Upload items
                </Text>
                <Text fontSize={"13px"} paddingBottom={"4px"}>
                  <b>File to upload</b> (required)
                </Text>
                <Stack
                  border={"1px dashed #a6a9b0"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  padding={"20px"}
                  h={"200px"}
                  maxW={"400px"}
                  textAlign={"center"}
                  borderRadius={"3px"}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  position="relative"
                >
                  {previewUrl ? (
                    <>
                      <Image
                        src={previewUrl}
                        alt="Uploaded file preview"
                        maxW={"400px"}
                        h={"180px"}
                        borderRadius="3px"
                      />
                      <IconButton
                        icon={<CloseIcon />}
                        size="sm"
                        colorScheme="red"
                        position="absolute"
                        top="10px"
                        right="10px"
                        onClick={handleDelete}
                        aria-label="Delete Image"
                      />
                    </>
                  ) : (
                    <>
                      <Text fontSize={"15px"} marginBottom={"12px"}>
                        Drag and drop file or select manually
                      </Text>
                      <Button
                        backgroundColor={"transparent"}
                        borderRadius={"3px"}
                        border={"1px solid #dcdee4"}
                        fontSize={"13px"}
                        variant={"solid"}
                        padding={"12px 16px"}
                        color={"#3454D1"}
                        fontWeight={600}
                        _hover={{
                          backgroundColor: "#fff",
                          color: "#3454D1",
                        }}
                        onClick={() => {
                          if (fileInputRef.current) {
                            fileInputRef.current.click();
                          }
                        }}
                      >
                        Select File
                      </Button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept=".jpg,.jpeg,.png,.pdf," // Accept specified file types
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                    </>
                  )}
                </Stack>
              </Stack> */}
            </Stack>
            <HStack
              marginTop={"24px"}
              alignItems={"flex-end"}
              justifyContent={"flex-end"}
            >
              <Button
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                color={"#ea4d4d"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  console.log("clicked");
                  window.close();
                }}
              >
                Close
              </Button>

              <Button
                type="submit"
                data-testid="login-button"
                height={"40px"}
                size={"md"}
                fontSize={"13px"}
                borderRadius={"3px"}
                fontWeight={700}
                colorScheme="Cornflower"
                padding={"15px 20px"}
                lineHeight={"1rem"}
                // onClick={form.handleSubmit(onSubmit)}
                onClick={(e) => {
                  form.handleSubmit((data) => {
                    ClearanceFormApi.NewClearanceAdded(
                      clearanceId!,
                      data,
                      (success) => {
                        toast({
                          title: "Clearance Added",
                          status: "success",
                        });
                        //wait for 2 seconds
                        setTimeout(() => {
                          window.close();
                        }, 2000);
                      },
                      (err) => {
                        toast({
                          title: err,
                          status: "error",
                        });
                      }
                    );
                  })(e);
                }}
              >
                Send Clearance
              </Button>
            </HStack>
          </Container>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
