import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { EmployeesReport } from "../../../Api/EmployeeReport";
import { DownloadExcel } from "../../TimeSheet/utils";
import { ColumnsComponent } from "../Components/Columns";
import { DateRange } from "../Components/DateRange";
import FEListLimit from "../Components/FEListLimit";
import { FilterComponent } from "../Components/FilterComponent";
import { ReportingTable } from "../Tables/ReportingTable";
import { Column } from "./Columns";
import ReportsHeader from "./ReportsHeader";
interface GenericObject {
  [key: string]: any;
}
export function TransactionLisitingDetailReport() {
  const name = "Transaction Lisiting Detail Report";
  const [selectedGroupBy, setSelectedGroupBy] = useState<
    | EmployeesReport.GroupingEmployeeRemunerationReport["groupBy"]
    | EmployeesReport.GroupingLeaveBalanceReport["groupBy"]
    | EmployeesReport.GroupingTimesheetSummaryReport["groupBy"]
    | EmployeesReport.GroupingTimesheetDetailsReport["groupBy"]
    | EmployeesReport.GroupingSuperannuationAccrualsReport["groupBy"]
    | EmployeesReport.GroupingSuperannuationPaymentsReport["groupBy"]
    | EmployeesReport.GroupingLeaveTransactionsReport["groupBy"]
    | EmployeesReport.GroupingLeaveRequestReport["groupBy"]
    | "EMPLOYEE"
    | "EMPLOYEE_GROUP"
    | undefined
  >(undefined);
  const [selectedClonedGroupBy, setSelectedClonedGroupBy] = useState<
    | EmployeesReport.GroupingEmployeeRemunerationReport["groupBy"]
    | EmployeesReport.GroupingLeaveBalanceReport["groupBy"]
    | EmployeesReport.GroupingTimesheetSummaryReport["groupBy"]
    | EmployeesReport.GroupingTimesheetDetailsReport["groupBy"]
    | EmployeesReport.GroupingSuperannuationAccrualsReport["groupBy"]
    | EmployeesReport.GroupingSuperannuationPaymentsReport["groupBy"]
    | EmployeesReport.GroupingLeaveTransactionsReport["groupBy"]
    | EmployeesReport.GroupingLeaveRequestReport["groupBy"]
    | "EMPLOYEE"
    | "EMPLOYEE_GROUP"
    | undefined
  >(undefined);
  const payItemTypeOptions: Array<{ label: string; value?: string }> = [
    {
      label: "All",
      value: undefined,
    },
    {
      label: "Earning",
      value: "EARNING",
    },
    {
      label: "Deduction",
      value: "DEDUCTION",
    },
    {
      label: "Reimbursement",
      value: "REIMBURSEMENT",
    },
    {
      label: "Retirement",
      value: "RETIREMENT",
    },
    {
      label: "Tax",
      value: "TAX",
    },
    {
      label: "Leave",
      value: "LEAVE",
    },
  ];
  const GroupingBtn: Array<{ label: string; value?: string }> = [
    {
      label: "None",
      value: undefined,
    },
    {
      label: "Employee",
      value: "employee",
    },
    {
      label: "Pay Item",
      value: "payItem",
    },
    {
      label: "Pay Item Type",
      value: "payItemType",
    },
  ];
  const FilterData: Array<{
    name: string;
    startRate?: string;
    endRate?: string;
    dataType?: string;
    endDate?: string;
    startDate?: string;
    StartKeyName?: string;
    EndKeyName?: string;
    singleProperty?: string;
    PayLoadDataType?: any;
  }> = [
    {
      name: "Date",
      startDate: "text",
      endDate: "text",
      StartKeyName: "filters.date.from",
      EndKeyName: "filters.date.to",
      PayLoadDataType: String,
    },
    {
      name: "Pay Item",
      dataType: "text",
      singleProperty: "filters.payItem",
      PayLoadDataType: String,
    },
  ];
  const toast = useToast();
  const reportForm =
    useForm<EmployeesReport.PayrollTransactionListingDetailReport>({
      defaultValues: {
        columns: {
          amount: true,
          date: true,
          employee: true,
          payItemType: true,
          payItem: true,
          uniqueId: true,
        },
        filters: {},

        groupBy: undefined,
      },
    });
  const [selectedColumns, setSelectedColumns] = useState<Array<Column>>([
    colBtns[0].colItem[0],
    colBtns[0].colItem[1],
    colBtns[0].colItem[2],
    colBtns[0].colItem[3],
    colBtns[0].colItem[4],
    colBtns[0].colItem[5],
  ]);
  const [clonedSelectedColumns, setClonedSelectedColumns] = useState<
    Array<Column>
  >([...colBtns[0].colItem]);

  const [selectedData, setSelectedData] = useState<
    EmployeesReport.fetchEmployeeRemunerationReportData | GenericObject
  >();
  const { allEmployeesList } = useBusinessContext();
  const [selectedEmployee, setSelectedEmployee] = useState<string | undefined>(
    undefined
  );
  const [payItemType, setPayItemType] = useState<string | undefined>(undefined);
  const [excelData, setExcelData] = useState<any>(undefined);
  const postPayload = reportForm.handleSubmit((data) => {
    let payloadData = { ...data };
    if (
      data.filters?.dateRange?.from === undefined ||
      data.filters?.dateRange?.to === undefined
    ) {
      delete payloadData.filters?.dateRange;
    }
    const isEmptyObject = (obj: any) => {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    const removeEmpty = (obj: any) => {
      let newObj: any = {};
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === "object") {
          newObj[key] = removeEmpty(obj[key]);
          if (isEmptyObject(newObj[key]) && key !== "filters") {
            delete newObj[key];
          }
        } else if (obj[key] !== undefined) {
          newObj[key] = obj[key];
        }
      });
      return newObj;
    };
    const payload = {
      ...payloadData,
      filters: {
        ...payloadData.filters,
        empId: selectedEmployee,
        payItemType: payItemType,
      },
      groupBy: undefined,

      columns: colBtns[0].colItem.reduce((acc, item) => {
        return {
          ...acc,
          [item.column]: selectedColumns.some(
            (col) => col.column === item.column
          ),
        };
      }, {} as any),
    };
    const cleanedPayload = removeEmpty(payload);
    EmployeesReport.CreatePayrollTransactionListingDetailReport(
      cleanedPayload,
      (success: any) => {
        setSelectedData(success);
        setClonedSelectedColumns(selectedColumns);
        setExcelData(cleanedPayload);
        toast({
          title: "Report Created",
          description: "Report Created Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setSelectedClonedGroupBy(selectedGroupBy);
      },
      (error: any) => {
        toast({
          title: "Report Creation Failed",
          description: "Report Creation Failed",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    );
  });
  useEffect(() => {
    if (selectedData !== undefined) {
      if (selectedData?.data.length > 0) {
        postPayload();
      }
    }
  }, [selectedGroupBy]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState("10");
  const indexOfLastItem = currentPage * Number(itemsPerPage);
  const indexOfFirstItem = indexOfLastItem - Number(itemsPerPage);

  const groupBy = (data: any, key: string) => {
    return data?.reduce((acc: any, obj: any) => {
      const keyValue = String(obj[key]);
      if (!acc[keyValue]) {
        acc[keyValue] = [];
      }
      acc[keyValue].push(obj);
      return acc;
    }, {});
  };
  const currentItems = selectedData?.data.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <FormProvider {...reportForm}>
      <Stack
        h="calc(100vh - 70px)"
        overflowY="auto"
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
      >
        <Stack
          direction={"row"}
          gap={0}
          boxShadow={"0 1px 0 0 rgba(0,10,30,.2)"}
          _light={{
            bgColor: "customColor.white",
          }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          alignItems={"center"}
          justifyContent={"space-between"} // Add this line
          width={"100%"}
        >
          <ReportsHeader name={name} />
          <Box
            display="flex"
            justifyContent="flex-end"
            paddingLeft={"10px"}
            marginRight={"16px"}
          >
            <Menu>
              <MenuButton
                as={Button}
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                _active={{
                  backgroundColor: "#fff",
                }}
                rightIcon={<ChevronDownIcon />}
                marginRight={"8px"}
              >
                Export
              </MenuButton>
              <MenuList style={{ minWidth: "120px" }}>
                <MenuItem
                  // onClick={downloadExcel}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                  onClick={() => {
                    if (excelData === undefined) {
                      toast({
                        title: "Report",
                        description: "Fetch Data First",
                        status: "warning",
                        duration: 9000,
                        isClosable: true,
                      });
                    } else {
                      EmployeesReport.CreatePayrollTransactionListingDetailReportExport(
                        excelData,
                        (success) => {
                          DownloadExcel(
                            success.data,
                            "TransactionListingDetailReport.xlsx"
                          );
                          toast({
                            title: "Report Downloaded",
                            description: "Report Downloaded Successfully",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                          });
                        },
                        () => {
                          toast({
                            title: "Report Creation Failed",
                            description: "Report Creation Failed",
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          });
                        }
                      );
                    }
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black5",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Excel
                  </Text>
                </MenuItem>

                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black5",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    PDF
                  </Text>
                </MenuItem>
                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black5",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Google Sheet
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => {
                reportForm.reset({
                  columns: {
                    amount: true,
                    date: true,
                    employee: true,
                    payItemType: true,
                    payItem: true,
                    uniqueId: true,
                  },
                  filters: {},
                });
                setSelectedColumns([
                  colBtns[0].colItem[0],
                  colBtns[0].colItem[1],
                  colBtns[0].colItem[2],
                  colBtns[0].colItem[3],
                  colBtns[0].colItem[4],
                  colBtns[0].colItem[5],
                ]);
                setSelectedEmployee(undefined);
                setPayItemType(undefined);
              }}
            >
              Clear Filters
            </Button>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              marginLeft={"8px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={postPayload}
            >
              Upload
            </Button>
          </Box>
        </Stack>
        <Container padding={"0px 12px"} maxW={"1150px"} marginTop={"10px"}>
          <Stack padding={"0px"}>
            <SimpleGrid columns={{ xs: 2, sm: 2, md: 3, lg: 4 }}>
              <Box margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
                <HStack gap={0} mb="8px">
                  <Text
                    fontSize="13px"
                    display={"block"}
                    fontWeight="700"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Employee
                  </Text>
                </HStack>

                <ReactSelect
                  placeholder="Employee"
                  options={[
                    { value: undefined, label: "All" }, // Add 'All' option
                    ...(allEmployeesList?.map((employee: any) => ({
                      value: employee.id,
                      label: `${employee.firstName} ${employee.lastName}`,
                    })) || []),
                  ]}
                  value={
                    selectedEmployee
                      ? {
                          value: selectedEmployee,
                          label: `${
                            allEmployeesList?.find(
                              (employee) => employee.id === selectedEmployee
                            )?.firstName
                          } ${
                            allEmployeesList?.find(
                              (employee) => employee.id === selectedEmployee
                            )?.lastName
                          }`,
                        }
                      : { value: undefined, label: "All" }
                  }
                  onChange={(newValue: any) => {
                    setSelectedEmployee(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Box>
              <Box margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
                <HStack gap={0} mb="8px">
                  <Text
                    fontSize="13px"
                    display={"block"}
                    fontWeight="700"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Pay Item Type (Required)
                  </Text>
                </HStack>

                <ReactSelect
                  placeholder="Pay Item Type"
                  options={payItemTypeOptions}
                  value={
                    payItemTypeOptions.find((op) => op.value === payItemType) ||
                    payItemTypeOptions[0]
                  }
                  onChange={(newValue: any) => {
                    setPayItemType(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Box>
              <DateRange
                startDateKey="filters.dateRange.from"
                endDateKey="filters.dateRange.to"
              />
              <ColumnsComponent
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                colBtns={colBtns}
              />
              {/* <Grouping
                setSelectedGroupBy={setSelectedGroupBy}
                groupingBtn={groupingBtn}
              /> */}
              <Box margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
                <HStack gap={0} mb="8px">
                  <Text
                    fontSize="13px"
                    display={"block"}
                    fontWeight="700"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Group By
                  </Text>
                </HStack>

                <ReactSelect
                  placeholder="Group By"
                  options={GroupingBtn}
                  value={
                    GroupingBtn.find((btn) => btn.value === selectedGroupBy) ||
                    GroupingBtn[0]
                  }
                  onChange={(newValue: any) => {
                    setSelectedGroupBy(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Box>
              <FilterComponent FilterData={FilterData} />
            </SimpleGrid>

            {/* <Button onClick={downloadExcel} borderRadius={"3px"}>
              Download as Excel
            </Button> */}
          </Stack>
        </Container>
        {(selectedData?.data?.length > 0 || selectedData !== undefined) && (
          <Container maxW={"1150px"} mb="60px">
            <Box w={"100%"} display="flex" justifyContent="flex-end">
              <FEListLimit setItemsPerPage={setItemsPerPage} />
            </Box>
            <ReportingTable
              name={name}
              Data={currentItems}
              DataWithKey={groupBy(currentItems, selectedGroupBy as string)}
              selectedColumns={clonedSelectedColumns}
              selectedData={selectedData}
              // groupBy={clonedSelectedGroupBy}
              groupBy={selectedClonedGroupBy}
              totalsColumns={["amount"]}
              totalReqired={false}
              // tabRef={tabRef}
            />
            <Pagination
              itemsPerPage={Number(itemsPerPage)}
              totalItems={selectedData?.data.length}
              paginate={setCurrentPage}
              currentPage={currentPage}
            />
          </Container>
        )}
        {/* <Box
          borderTop="1px solid #ddd"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          position="fixed"
          left={0}
          bottom="0"
          w="full"
          h="50px"
          // zIndex={"overlay"}
        >
          <Container
            justifyContent="center"
            alignItems="flex-end"
            textAlign={"right"}
            maxW={"90%"}
          >
            <Menu>
              <MenuButton
                as={Button}
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                _active={{
                  backgroundColor: "#fff",
                }}
                rightIcon={<ChevronDownIcon />}
                marginTop={"10px"}
              >
                Export
              </MenuButton>
              <MenuList style={{ minWidth: "120px" }}>
                <MenuItem
                  // onClick={downloadExcel}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                  onClick={() => {
                    if (excelData === undefined) {
                      toast({
                        title: "Report",
                        description: "Fetch Data First",
                        status: "warning",
                        duration: 9000,
                        isClosable: true,
                      });
                    } else {
                      EmployeesReport.CreatePayrollTransactionListingDetailReportExport(
                        excelData,
                        (success) => {
                          DownloadExcel(
                            success.data,
                            "TransactionListingDetailReport.xlsx"
                          );
                          toast({
                            title: "Report Downloaded",
                            description: "Report Downloaded Successfully",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                          });
                        },
                        () => {
                          toast({
                            title: "Report Creation Failed",
                            description: "Report Creation Failed",
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          });
                        }
                      );
                    }
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black5",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Excel
                  </Text>
                </MenuItem>

                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black5",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    PDF
                  </Text>
                </MenuItem>
                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black5",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Google Sheet
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
          </Container>
        </Box> */}
      </Stack>
    </FormProvider>
  );
}
const colBtns: Array<{
  colItem: Array<Column>;
}> = [
  {
    colItem: [
      {
        colLabel: "Employee ID",
        column: "uniqueId",
        position: 0,
        dataKey: "uniqueId",
      },
      {
        colLabel: "Date",
        column: "date",
        position: 1,
        dataKey: "date",
      },
      {
        colLabel: "Pay Item Type",
        column: "payItemType",
        position: 2,
        // groupingKey: "EMPLOYEE",
        dataKey: "payItemType",
      },
      {
        colLabel: "Employee",
        column: "employee",
        // groupingKey: "EMPLOYEE",
        position: 3,
        dataKey: "employee",
      },
      {
        colLabel: "Pay Item",
        column: "payItem",
        position: 4,
        dataKey: "payItem",
      },
      {
        colLabel: "Amount",
        column: "amount",
        position: 5,
        dataKey: "amount",
      },
    ],
  },
];
const Pagination = ({
  itemsPerPage,
  totalItems,
  paginate,
  currentPage,
}: any) => {
  const [isLeftHovered, setIsLeftHovered] = useState(false);
  const [isRightHovered, setIsRightHovered] = useState(false);
  const handlePageChange = (pageNumber: any) => {
    paginate(pageNumber);
  };
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const renderPageNumbers = () => {
    const pageButtons = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            marginRight={"10px"}
            aria-label={`Page ${i}`}
            border={"1px solid #e8eaf2"}
            borderRadius={"full"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            backgroundColor={currentPage === i ? "#3454D1" : "#fff"}
            color={currentPage === i ? "white" : "black"}
            _hover={{
              backgroundColor: "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }
    } else {
      // Always show the first page
      pageButtons.push(
        <IconButton
          key={1}
          aria-label={`Page 1`}
          marginRight={"10px"}
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          backgroundColor={currentPage === 1 ? "#3454D1" : "#fff"}
          color={currentPage === 1 ? "white" : "black"}
          _hover={{
            backgroundColor: currentPage === 1 ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>1</Text>}
          onClick={() => handlePageChange(1)}
        />
      );

      // Show ellipsis if currentPage is greater than 2
      if (currentPage > 2) {
        pageButtons.push(
          <Text key="left-dots" marginRight={"10px"}>
            ...
          </Text>
        );
      }

      // Show the currentPage and its adjacent pages
      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            border={"1px solid #e8eaf2"}
            marginRight={"10px"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            aria-label={`Page ${i}`}
            backgroundColor={currentPage === i ? "#3454D1" : "#fff"}
            color={currentPage === i ? "white" : "black"}
            _hover={{
              backgroundColor: "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }

      // Show ellipsis if currentPage is less than totalPages - 1
      if (currentPage < totalPages - 1) {
        pageButtons.push(
          <Text key="right-dots" marginRight={"10px"}>
            ...
          </Text>
        );
      }

      // Always show the last page
      pageButtons.push(
        <IconButton
          key={totalPages}
          border={"1px solid #e8eaf2"}
          marginRight={"10px"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          borderRadius={"full"}
          aria-label={`Page ${totalPages}`}
          backgroundColor={currentPage === totalPages ? "#3454D1" : "#fff"}
          color={currentPage === totalPages ? "white" : "black"}
          _hover={{
            backgroundColor: currentPage === totalPages ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>{totalPages}</Text>}
          onClick={() => handlePageChange(totalPages)}
        />
      );
    }

    return pageButtons;
  };
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    // <nav>
    //   <ul className="pagination">
    //     {pageNumbers.map((number) => (
    //       <li key={number} className="page-item">
    //         <a onClick={() => paginate(number)} className="page-link">
    //           {number}
    //         </a>
    //       </li>
    //     ))}
    //   </ul>
    // </nav>
    <Stack
      direction="row"
      alignItems="center"
      gap={0}
      paddingLeft={"12px"}
      paddingRight={"5px"}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingY="10px"
        gap={0}
      >
        <IconButton
          aria-label="left"
          backgroundColor="transparent"
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          _hover={{
            backgroundColor: "#3454D1",
          }}
          onMouseEnter={() => setIsLeftHovered(true)}
          onMouseLeave={() => setIsLeftHovered(false)}
          icon={
            <FaArrowLeft
              size={15}
              color={
                isLeftHovered
                  ? "#FFFFFF"
                  : currentPage <= 1 || currentPage > currentPage
                  ? "#828ea0"
                  : "#283c50"
              }
              style={{ transition: "color 0.2s" }}
            />
          }
          marginRight={"10px"}
          onClick={() => {
            paginate((prev: any) => Math.max(prev - 1, 1));
            // paginate((prev:any) => Math.max(prev - 1, 1));
          }}
          isDisabled={currentPage <= 1 || currentPage > currentPage}
        />

        {renderPageNumbers()}

        <IconButton
          aria-label="right"
          backgroundColor="transparent"
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          _hover={{
            backgroundColor: "#3454D1",
          }}
          onMouseEnter={() => setIsRightHovered(true)}
          onMouseLeave={() => setIsRightHovered(false)}
          icon={
            <FaArrowRight
              size={15}
              color={
                isRightHovered
                  ? "#FFFFFF"
                  : currentPage === totalPages
                  ? "#828ea0"
                  : "#283c50"
              }
              style={{ transition: "color 0.2s" }}
            />
          }
          marginRight={"10px"}
          onClick={() => {
            paginate((prev: any) => Math.min(prev + 1, totalPages));
            // pagi((prev) => Math.min(prev + 1, totalPages));
          }}
          isDisabled={currentPage === totalPages}
        />
      </Stack>
    </Stack>
  );
};
