import {
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Stack,
  useBoolean,
  useBreakpointValue,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { PeriodicalEvents } from "../../../../modules/Audit/PeriodicalEvents";
import { PeriodicalSchedule } from "../../../../modules/Audit/PeriodicalSchedule";
import { TemplateMeta } from "../../../../modules/Audit/Template-Meta";
import ManagePeriodicalSchedulesLg from "./ManagePeriodicalSchedule/ManagePeriodicalScheduleLg";
import ManagePeriodicalScheduleSm from "./ManagePeriodicalSchedule/ManageScheduleSm";

interface ManagePeriodicalScheduleProps {
  templatesList: TemplateMeta.TemplateMeta[];
  setLastUpdate: (timestamp: number) => void;
  lastUpdate: number;
  allEvents: PeriodicalEvents.GetEvents[];
}
export default function ManagePeriodicalSchedule({
  templatesList,
  setLastUpdate,
  lastUpdate,
  allEvents,
}: ManagePeriodicalScheduleProps) {
  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast({ position: "top" });
  const [totalScheduleInspections, setTotalScheduleInspections] =
    useState<number>(0);
  const futureImplemetation = true;
  const [isLoading, setIsLoading] = useBoolean(true);
  const [periodicSchedule, setPeriodicSchedule] = useState<
    PeriodicalSchedule.GetPeriodicalSchedule[]
  >([]);
  // const debounced = useDebouncedCallback(value => {
  //   setSearchText(value);
  // }, 1000);
  // const [limit, setLimit] = useState<number>(25);
  // const [searchText, setSearchText] = useState('');
  // const searchedScheduleInspections = useCallback(() => {
  //   return scheduleInspection.filter(insp =>
  //     insp.title.toLowerCase().includes(searchText.toLowerCase()),
  //   );
  // }, [searchText, scheduleInspection]);

  // const [pageNumber, setPageNumber] = useState<number>(1);
  // const limit = 10;
  // const pageCount = Math.ceil(totalScheduleInspections / limit);
  useEffect(() => {
    PeriodicalSchedule.FetchPeriodicalSchedule(
      (data) => {
        setPeriodicSchedule(data);

        setIsLoading.off();
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
        setIsLoading.off();
      }
    );
  }, [lastUpdate]);

  const TableSize = useBreakpointValue({
    xs: (
      <ManagePeriodicalScheduleSm
        data={periodicSchedule}
        templatesList={templatesList}
        isLoading={isLoading}
        setLastUpdate={setLastUpdate}
        allEvents={allEvents}
      />
    ),
    md: (
      <ManagePeriodicalSchedulesLg
        data={periodicSchedule}
        templatesList={templatesList}
        isLoading={isLoading}
        setLastUpdate={setLastUpdate}
        allEvents={allEvents}
      />
    ),
  });
  return (
    <Fragment>
      <Stack px={{ sm: "0px", md: "32px", xl: "50px" }} py="32px">
        <Stack
          px={{ xs: "16px", md: "0px" }}
          // justifyContent="space-between"
          direction={{ xs: "column", md: "row" }}
        >
          <Stack direction={{ xs: "column", md: "row" }} flex={1}>
            <Stack>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<AiOutlineSearch color="#545f70" size="18px" />}
                />
                <Input
                  minW={{ xs: "100%", md: "400px" }}
                  placeholder="Search"
                  border="1px solid"
                  borderColor="borders.accent"
                  fontSize="16px"
                  lineHeight="1.5rem"
                  fontWeight={400}
                  color="textcolors.primary"
                  py="8px"
                  pl="38px"
                  _focusVisible={{
                    boxShadow: "none",
                    border: "1px sloid",
                    borderColor: "primary.500",
                  }}
                  // onChange={e => {
                  //   debounced(e.target.value);
                  // }}
                />
              </InputGroup>
            </Stack>
            {futureImplemetation ?? (
              <HStack flex={1}>
                <Wrap spacing="8px">
                  <WrapItem>
                    <ButtonGroup
                      size="md"
                      isAttached
                      variant="outline"
                      fontSize="14px"
                    >
                      <Popover
                        //   isOpen={GroupDisclosure.isOpen}
                        placement="bottom-start"
                      >
                        <PopoverTrigger>
                          <Button
                            px="12.5px"
                            py="12px"
                            borderLeftRadius="8px"
                            maxW="350px"
                            //   onClick={GroupDisclosure.onToggle}
                          >
                            Template
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent w="400px">
                          <PopoverBody>{/* <Group /> */}</PopoverBody>
                          <PopoverFooter p={0} textAlign="end">
                            <Button
                              h="32px"
                              margin="8px"
                              variant="solid"
                              px="13px"
                              fontSize="13px"
                              borderRadius="8px"
                              lineHeight={1}
                            >
                              Done
                            </Button>
                          </PopoverFooter>
                        </PopoverContent>
                      </Popover>
                      <IconButton
                        zIndex={1}
                        backgroundColor="white"
                        p="12px"
                        borderRightRadius="8px"
                        aria-label="Add to friends"
                        icon={<AiOutlineClose />}
                      />
                    </ButtonGroup>
                  </WrapItem>
                </Wrap>
                <Menu>
                  <MenuButton
                    as={Button}
                    h="41px"
                    minW="110px"
                    variant="ghost"
                    px="20px"
                    py="12px"
                    fontWeight={500}
                    iconSpacing={0.5}
                    color="primary.500"
                    borderRadius="8px"
                  >
                    + Add filter
                  </MenuButton>
                  <MenuList minW="160px">
                    {["Template"].map((menu, i) => {
                      return (
                        <Fragment>
                          <MenuItem
                            px="14px"
                            py="9.6px"
                            fontSize="14px"
                            fontWeight={400}
                            color="textcolors.primary"
                          >
                            {menu}
                          </MenuItem>
                        </Fragment>
                      );
                    })}
                  </MenuList>
                </Menu>
              </HStack>
            )}
          </Stack>
          {/* <HStack spacing={0} justify="end">
            <Text fontSize="14px" color="icons.primary">
              {`(${limit * (pageNumber - 1) + 1}-${
                scheduleInspection.length + limit * (pageNumber - 1)
              } of ${totalScheduleInspections})`}
            </Text>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FiMoreVertical size={24} color="#545f70" />}
                variant="outline"
                borderRadius={'50%'}
                border={'none'}
                _hover={{backgroundColor: 'transparent'}}
                _active={{
                  backgroundColor: 'transparent',
                }}
              />
              <MenuList minW="160px">
                <MenuGroup title="Results per page." fontWeight={400}>
                  {[25, 50, 100].map((menu, i) => {
                    const isSelected = menu === limit;
                    return (
                      <Fragment>
                        <MenuItem
                          px="16px"
                          py="8px"
                          onClick={() => {
                            setLimit(menu);
                          }}>
                          <HStack w="100%">
                            <Box w="15%">
                              {isSelected && (
                                <FiCheck size="18px" color="#1da5d4" />
                              )}
                            </Box>
                            <Text
                              fontSize="14px"
                              color="textcolors.primary"
                              w="85%">
                              {menu}
                            </Text>
                          </HStack>
                        </MenuItem>
                      </Fragment>
                    );
                  })}
                </MenuGroup>
              </MenuList>
            </Menu>
          </HStack> */}
        </Stack>
        {TableSize}
        {/* <HStack spacing="13px" justify={{xs: 'center', md: 'end'}}>
          <IconButton
            aria-label="back"
            backgroundColor="transparent"
            _hover={{backgroundColor: 'transparent'}}
            icon={
              <BsChevronLeft
                size={19}
                color={
                  pageNumber <= 1 || pageNumber > pageCount
                    ? '#828ea0'
                    : '#000000'
                }
              />
            }
            isDisabled={
              pageNumber <= 1 || pageNumber > pageCount ? true : false
            }
            onClick={() => {
              if (pageNumber > 1 && pageNumber <= pageCount)
                pageNumberInputRef.current?.value &&
                  (pageNumberInputRef.current.value = String(pageNumber - 1));
              setPageNumber(pageNumber - 1);
              console.log(pageNumber);
            }}
          />
          <Input
            ref={pageNumberInputRef}
            h="37px"
            w="56px"
            backgroundColor="backgrounds.primary"
            defaultValue={pageNumber.toString()}
            textAlign="center"
            borderColor="borders.accent"
            // onChange={e => {
            //   const pageValue = e.target.value;
            //   setPageNumber(parseInt(pageValue));
            // }}
            onBlur={e => {
              const pageValue = e.target.value;
              setPageNumber(parseInt(pageValue));
            }}
          />

          <Text color="textcolors.secondary">/</Text>
          <Text w="40px" textAlign="center">
            {pageCount}
          </Text>
          <IconButton
            aria-label="right"
            backgroundColor="transparent"
            _hover={{backgroundColor: 'transparent'}}
            icon={
              <BsChevronRight
                size={19}
                color={
                  pageNumber >= pageCount || pageNumber < 1
                    ? '#828ea0'
                    : '#000000'
                }
              />
            }
            isDisabled={
              pageNumber >= pageCount || pageNumber < 1 ? true : false
            }
            onClick={() => {
              if (pageNumber < pageCount && pageNumber >= 1) {
                pageNumberInputRef.current?.value &&
                  (pageNumberInputRef.current.value = String(pageNumber + 1));
                setPageNumber(pageNumber + 1);
              }

              console.log(pageNumber);
            }}
          />
        </HStack> */}
      </Stack>
    </Fragment>
  );
}
