import {
  Button,
  CSSReset,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Country, State } from "country-state-city";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import { AddressInput } from "../../../Common/AddressInput";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { ContactApi } from "../../Api/Contact";

interface AddContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (time: number) => void;
}
export function AddContactModal({
  isOpen,
  onClose,
  setLastUpdated,
}: AddContactModalProps) {
  const form = useFormContext<ContactApi.AddContact>();

  const [addDeliveryAddress, setAddDeliveryAddress] = useState(false);
  const [provinces, setProvinces] = useState<
    Array<{
      name: string;
    }>
  >([]);
  let countryData = Country.getAllCountries();
  const toast = useToast();
  useEffect(() => {
    const provinceOptions = State.getStatesOfCountry(
      countryData.find(
        (country) => country.name === form.getValues("billingAddress.country")
      )?.isoCode
    );
    const province = provinceOptions.map((province: any) => {
      return { name: province?.name };
    });
    setProvinces(province);
  }, [form.watch("billingAddress.country")]);
  const [deliveryProvinces, setDeliveryProvinces] = useState<
    Array<{
      name: string;
    }>
  >([]);
  useEffect(() => {
    const provinceOptions = State.getStatesOfCountry(
      countryData.find(
        (country) => country.name === form.getValues("deliveryAddress.country")
      )?.isoCode
    );
    const province = provinceOptions.map((province: any) => {
      return { name: province?.name };
    });
    setDeliveryProvinces(province);
  }, [form.watch("deliveryAddress.country")]);
  return (
    <FormProvider {...form}>
      <Modal size="md" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay zIndex={"1111"} />
        <ModalContent
          maxW={"480px"}
          maxH={"350px"}
          overflowX={"auto"}
          zIndex={"1111"}
        >
          <HStack
            w={"100%"}
            padding={"12px"}
            alignItems={"center"}
            justifyContent={"space-between"}
            boxShadow={"0 1px 0 0 rgba(0,10,30,.2)"}
            zIndex={"1111"}
          >
            <ModalHeader
              flex={1}
              fontSize="var(--x-font-size-large, 1.0625rem)"
              _light={{
                color: "customColor.black7",
              }}
              fontWeight="700"
              padding={0}
            >
              Add New Contact
            </ModalHeader>
            <Stack
              justifyContent={"flex-end"}
              alignItems={"center"}
              direction={"row"}
            >
              <Button
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                color={"#ea4d4d"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  setAddDeliveryAddress(false);
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#2c48b5",
                }}
                onClick={(e) => {
                  form.handleSubmit((data) => {
                    ContactApi.PostContacts(
                      data,
                      (success) => {
                        toast({
                          title: "Contact Added",
                          status: "success",
                        });
                        setLastUpdated(Date.now());
                        setAddDeliveryAddress(false);
                        onClose();
                      },
                      (err) => {
                        toast({
                          title: err,
                          status: "error",
                        });
                      }
                    );
                  })(e);
                }}
              >
                {"Save"}
              </Button>
            </Stack>
          </HStack>

          <ModalBody p={"20px"} zIndex={"1111"}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Name is required",
                },
              }}
              control={form.control}
              name="name"
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  validationMessage={error?.message}
                  withValidation
                  ref={field.ref}
                  input={{
                    ...field,

                    isInvalid: error !== undefined,
                    w: "100%",
                    marginBottom: "32px",
                    h: "38px",
                    padding: "12px 15px",
                    _light: {
                      color: "customColor.black7",
                    },
                    fontSize: ".845rem",
                    variant: "outline",
                    type: "text",

                    borderTopRadius: "3px",
                  }}
                  placeholder=""
                  label="Contact"
                  labelProps={{
                    fontSize: ".845rem",
                    fontWeight: "600",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                />
              )}
            />
            <Controller
              // rules={{
              //   required: {
              //     value: true,
              //     message: "Primary Person Email is required",
              //   },
              // }}
              control={form.control}
              name="primaryPerson.email"
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  validationMessage={error?.message}
                  withValidation
                  ref={field.ref}
                  input={{
                    ...field,

                    // isInvalid: error !== undefined,
                    w: "100%",

                    h: "38px",
                    marginBottom: "32px",
                    padding: "12px 15px",
                    _light: {
                      color: "customColor.black7",
                    },
                    borderLeftRadius: "4px",
                    borderTopRadius: "4px",
                    fontSize: ".845rem",
                    variant: "outline",
                    type: "email",
                  }}
                  placeholder=""
                  label="Email"
                  labelProps={{
                    fontSize: ".845rem",
                    fontWeight: "600",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                />
              )}
            />
            <Stack gap={0}>
              <Text
                fontSize={".845rem"}
                fontWeight={"600"}
                color={"customColor.black7"}
                marginBottom={"8px"}
              >
                Billing Address
              </Text>
              <Stack gap={0}>
                <AddressInput
                  onPlaceSelected={(place) => {
                    form.setValue(
                      "billingAddress.addresss",
                      place?.formatted_address
                    );
                    form.setValue("billingAddress.city", place?.city);
                    form.setValue("billingAddress.country", place?.country);
                    form.setValue("billingAddress.state", place?.state);
                    form.setValue("billingAddress.zipCode", place?.postcode);
                    console.log(place?.state);
                  }}
                  input={{
                    _light: {
                      color: "customColor.black7",
                    },
                    zIndex: 9999, // Make sure zIndex is higher than your modal
                    borderTopRadius: "3px",
                    border: "1px solid #e5e7eb",
                    _hover: {
                      border: "1px solid hsl(0, 0%, 70%)",
                    },
                    _focus: {
                      borderWidth: "1px",
                      boxShadow: "0 0 0 1px #3454d1",
                      _hover: {
                        borderWidth: "1px",
                        borderColor: "#3454d1",
                        boxShadow: "0 0 0 1px #3454d1",
                      },
                    },
                    _focusVisible: {
                      borderWidth: "1px",
                      boxShadow: "0 0 0 1px #3454d1",
                      _hover: {
                        borderWidth: "1px",
                        borderColor: "#3454d1",
                        boxShadow: "0 0 0 1px #3454d1",
                      },
                    },
                    fontSize: ".845rem",
                    marginTop: "10px",
                    padding: "12px 15px",
                    variant: "outline",
                    placeholder: "Search address",
                    type: "text",
                    position: "relative", // Ensure positioning context for z-index
                  }}
                />
              </Stack>
              <Controller
                name="billingAddress.addresss"
                control={form.control}
                render={({ field }) => (
                  <CustomInput
                    withValidation
                    ref={field.ref}
                    input={{
                      ...field,
                      h: "38px",
                      padding: "12px 15px",
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",
                      borderTopRadius: "3px",
                      alignContent: "center",
                      onChange: (e) => {
                        field.onChange(e.target.value);
                      },
                    }}
                    placeholder="Street address or PO box"
                  />
                )}
              />
              <Controller
                name="billingAddress.city"
                control={form.control}
                // rules={{ maxLength: 20 }}
                render={({ field }) => (
                  <CustomInput
                    withValidation
                    ref={field.ref}
                    input={{
                      ...field,
                      h: "38px",
                      padding: "12px 15px",
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",

                      borderTopRadius: "3px",
                      marginTop: "1px",
                      alignContent: "center",
                      onChange: (e) => {
                        field.onChange(e.target.value);
                      },
                    }}
                    placeholder="Town / City"
                  />
                )}
              />
              <HStack gap={0} alignItems={"baseline"}>
                <Stack flex={1}>
                  <Controller
                    control={form.control}
                    name="billingAddress.state"
                    render={({ field, fieldState }) => (
                      <ReactSelect
                        {...field}
                        key={field.value}
                        options={provinces}
                        ErrorMessage="Please Select State"
                        placeholder="Select..."
                        value={provinces?.find(
                          (op: any) => op.name === field.value
                        )}
                        onChange={(newValue: any) => {
                          field.onChange(newValue?.name);
                        }}
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.name}
                      />
                    )}
                  />
                </Stack>
                <Stack flex={1}>
                  <Controller
                    name="billingAddress.zipCode"
                    control={form.control}
                    // rules={{ maxLength: 10 }}
                    render={({ field }) => (
                      <CustomInput
                        withValidation
                        ref={field.ref}
                        input={{
                          ...field,
                          h: "38px",
                          padding: "12px 15px",
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "number",
                          onChange: (e) => {
                            field.onChange(e.target.value);
                          },
                          borderRightRadius: "3px",
                          alignContent: "center",
                        }}
                        placeholder="Postal / Zip code"
                      />
                    )}
                  />
                </Stack>
              </HStack>
              <CSSReset />
              <Controller
                control={form.control}
                name="billingAddress.country"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder="Select Country"
                    // marginBottom="30px"
                    // width={"48em"}

                    withValidation
                    options={countryData}
                    value={countryData.find((op) => op.name === field.value)}
                    onChange={(newValue: any) => {
                      field.onChange(newValue?.name);
                    }}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.name}
                  />
                )}
              />
            </Stack>
            <Button
              leftIcon={<FiPlus />}
              w={"100%"}
              display={addDeliveryAddress === false ? "flex" : "none"}
              alignItems={"center"}
              variant={"ghost"}
              fontSize={"13px"}
              borderRadius={"3px"}
              marginBottom={"32px"}
              color={"#3454d1"}
              padding={"12px 16px"}
              onClick={() => {
                setAddDeliveryAddress(true);
              }}
            >
              Add Delivery Address
            </Button>
            {addDeliveryAddress === true && (
              <Stack gap={0} marginTop={"32px"} marginBottom={"32px"}>
                <Text
                  fontSize={".845rem"}
                  fontWeight={"600"}
                  color={"customColor.black7"}
                  marginBottom={"8px"}
                >
                  Delivery address
                </Text>
                <Stack gap={0}>
                  <AddressInput
                    onPlaceSelected={(place) => {
                      form.setValue(
                        "deliveryAddress.addresss",
                        place?.formatted_address
                      );
                      form.setValue("deliveryAddress.city", place?.city);
                      form.setValue("deliveryAddress.country", place?.country);
                      form.setValue("deliveryAddress.state", place?.state);
                      form.setValue("deliveryAddress.zipCode", place?.postcode);
                    }}
                    input={{
                      _light: {
                        color: "customColor.black7",
                      },
                      zIndex: "9999",
                      borderTopRadius: "3px",
                      border: "1px solid #e5e7eb",
                      _hover: {
                        border: "1px solid hsl(0, 0%, 70%)",
                      },
                      _focus: {
                        borderWidth: "1px",

                        boxShadow: "0 0 0 1px #3454d1",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#3454d1",
                          boxShadow: "0 0 0 1px #3454d1",
                        },
                      },
                      _focusVisible: {
                        borderWidth: "1px",
                        boxShadow: "0 0 0 1px #3454d1",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#3454d1",
                          boxShadow: "0 0 0 1px #3454d1",
                        },
                      },
                      fontSize: ".845rem",
                      marginTop: "10px",
                      padding: "12px 15px",
                      variant: "outline",
                      placeholder: "Search address",
                      type: "text",
                    }}
                  />
                </Stack>
                <Controller
                  name="deliveryAddress.addresss"
                  control={form.control}
                  render={({ field }) => (
                    <CustomInput
                      withValidation
                      ref={field.ref}
                      input={{
                        ...field,
                        h: "38px",
                        padding: "12px 15px",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",
                        borderTopRadius: "3px",
                        alignContent: "center",
                        onChange: (e) => {
                          field.onChange(e.target.value);
                        },
                      }}
                      placeholder="Street address or PO box"
                    />
                  )}
                />
                <Controller
                  name="deliveryAddress.city"
                  control={form.control}
                  // rules={{ maxLength: 20 }}
                  render={({ field }) => (
                    <CustomInput
                      withValidation
                      ref={field.ref}
                      input={{
                        ...field,
                        h: "38px",
                        padding: "12px 15px",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",

                        borderTopRadius: "3px",
                        marginTop: "1px",
                        alignContent: "center",
                        onChange: (e) => {
                          field.onChange(e.target.value);
                        },
                      }}
                      placeholder="Town / City"
                    />
                  )}
                />
                <HStack gap={0} alignItems={"baseline"}>
                  <Stack flex={1}>
                    <Controller
                      control={form.control}
                      name="deliveryAddress.state"
                      render={({ field, fieldState }) => (
                        <ReactSelect
                          {...field}
                          key={field.value}
                          options={deliveryProvinces}
                          ErrorMessage="Please Select State"
                          placeholder="Select..."
                          value={deliveryProvinces?.find(
                            (op: any) => op.name === field.value
                          )}
                          onChange={(newValue: any) => {
                            field.onChange(newValue?.name);
                          }}
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option.name}
                        />
                      )}
                    />
                  </Stack>
                  <Stack flex={1}>
                    <Controller
                      name="deliveryAddress.zipCode"
                      control={form.control}
                      // rules={{ maxLength: 10 }}
                      render={({ field }) => (
                        <CustomInput
                          withValidation
                          ref={field.ref}
                          input={{
                            ...field,
                            h: "38px",
                            padding: "12px 15px",
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "number",
                            onChange: (e) => {
                              field.onChange(e.target.value);
                            },
                            borderRightRadius: "3px",
                            alignContent: "center",
                          }}
                          placeholder="Postal / Zip code"
                        />
                      )}
                    />
                  </Stack>
                </HStack>
                <CSSReset />
                <Controller
                  control={form.control}
                  name="deliveryAddress.country"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select Country"
                      // marginBottom="30px"
                      // width={"48em"}

                      withValidation
                      options={countryData}
                      value={countryData.find((op) => op.name === field.value)}
                      onChange={(newValue: any) => {
                        field.onChange(newValue?.name);
                      }}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.name}
                    />
                  )}
                />
              </Stack>
            )}
            <Stack marginBottom={"32px"}>
              <Text
                fontSize={"0.845rem"}
                color={"customColor.black7"}
                fontWeight={"600"}
              >
                Phone Number
              </Text>
              <HStack gap={0}>
                <Stack flex={5}>
                  <Controller
                    // rules={{
                    //   required: {
                    //     value: true,
                    //     message: "Business Phone Country is required",
                    //   },
                    // }}
                    control={form.control}
                    name="businessInformation.phoneCountry"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        // validationMessage={error?.message}
                        withValidation
                        ref={field.ref}
                        input={{
                          ...field,

                          // isInvalid: error !== undefined,
                          w: "100%",
                          marginRight: "0.5px",
                          h: "38px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          borderRightRadius: "none",
                          borderTopLeftRadius: "4px",
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "text",
                        }}
                        placeholder="Country"
                      />
                    )}
                  />
                </Stack>
                <Stack flex={3}>
                  <Controller
                    // rules={{
                    //   required: {
                    //     value: true,
                    //     message: "Business Phone Area is required",
                    //   },
                    // }}
                    control={form.control}
                    name="businessInformation.phoneArea"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        // validationMessage={error?.message}
                        withValidation
                        ref={field.ref}
                        input={{
                          ...field,

                          // isInvalid: error !== undefined,
                          w: "100%",

                          h: "38px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          marginRight: "0.5px",
                          borderLeftRadius: "none",
                          borderTopRightRadius: "none",
                          borderBottomRightRadius: "none",
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "text",
                        }}
                        placeholder="Area"
                      />
                    )}
                  />
                </Stack>
                <Stack flex={5}>
                  <Controller
                    // rules={{
                    //   required: {
                    //     value: true,
                    //     message: "Business Phone Number is required",
                    //   },
                    // }}
                    control={form.control}
                    name="businessInformation.phoneNumber"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        // validationMessage={error?.message}
                        withValidation
                        ref={field.ref}
                        input={{
                          ...field,

                          // isInvalid: error !== undefined,
                          w: "100%",

                          h: "38px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          marginRight: "0.5px",
                          borderLeftRadius: "none",
                          borderTopRightRadius: "none",
                          borderBottomRightRadius: "none",
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "number",
                        }}
                        placeholder="Number"
                      />
                    )}
                  />
                </Stack>
              </HStack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
