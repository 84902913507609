import {
  Button,
  Center,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBoolean,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import { Fragment, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FaFileSignature } from "react-icons/fa";
import { MdAddPhotoAlternate } from "react-icons/md";
import SignatureCanvas from "react-signature-canvas";
import { UploadAttachments } from "../../../../Payroll/Api/UploadAttachments";
import { fileToBase64 } from "../../../modules/api";
import { Inspection, Template } from "../../../modules/Audit";
import { compressImage } from "../../../modules/utils";
import { useInspectionContext } from "../hooks/InspectionContext";
import { useAuditQuestionContainer } from "./AuditQuestionContainer";

export default function Signature() {
  const template = useFormContext();
  const { getItemField } = useAuditQuestionContainer();
  const inputRef = useRef<HTMLInputElement>(null);
  const toast = useToast({
    position: "top",
  });
  const [isImageUploading, setIsImageUploading] = useBoolean(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [signatureImage, setSignatureImage] = useState<Template.MediaObject>();

  const signPadRef = useRef<SignatureCanvas>(null);
  const { setIsSaving } = useInspectionContext();
  return (
    <Fragment>
      <Stack>
        <HStack w="100%">
          <Controller
            name={getItemField("question.questionValue")}
            control={template.control}
            rules={{
              onBlur(event) {
                const answerId = template.getValues(
                  getItemField("question.answerId")
                );
                const type = template.getValues(getItemField("question.type"));
                const inspectionMetaID = template.getValues("inspection.id");
                const notes = template.getValues(
                  getItemField("question.notes")
                );
                setIsSaving(true);
                Inspection.CachingInspection(
                  answerId,
                  {
                    question_value: event.target.value,
                    notes: notes,
                    inspection_meta_id: inspectionMetaID,
                    type: type,
                  },
                  () => {
                    setIsSaving(false);
                  },
                  () => {
                    setIsSaving(false);
                  }
                );
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                defaultValue={field.value ?? ""}
                border="1px solid"
                borderColor="borders.accent"
                fontSize="16px"
                fontWeight={400}
                color="textcolors.primary"
                padding="8px 16px"
                placeholder="Full name"
                _focusVisible={{
                  boxShadow: "none",
                  border: "1px sloid",
                  borderColor: "primary.500",
                }}
              />
            )}
          />

          <Button
            h="41.6px"
            padding="12px 20px"
            iconSpacing={"2px"}
            leftIcon={<FaFileSignature color="white" size={"17px"} />}
            border="none"
            borderRadius="8px"
            fontWeight={500}
            fontSize="14px"
            onClick={() => {
              // inputRef.current?.click();
              onOpen();
            }}
          >
            Add signature
          </Button>
        </HStack>
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Signture</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs
              onChange={() => {
                setSignatureImage(undefined);
                signPadRef.current?.clear();
              }}
            >
              <TabList>
                <Tab>Upload</Tab>
                <Tab>Draw</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Center
                    borderColor="borders.accent"
                    borderWidth="3px"
                    borderRadius="lg"
                    width={370}
                    height={200}
                    bgImage={
                      signatureImage?.base64 &&
                      `url(data:image/png;base64,${signatureImage?.base64})`
                    }
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    bgPos="center"
                    role="group"
                  >
                    <VStack
                      _groupHover={{
                        display: "flex",
                      }}
                      display={signatureImage?.base64 ? "none" : "flex"}
                      bgColor={"rgba(0,0,0,0.1)"}
                      h="full"
                      w="full"
                      justify="center"
                      onClick={() => {
                        inputRef.current?.click();
                      }}
                    >
                      <Icon as={MdAddPhotoAlternate} boxSize={50} />
                      <Text>Upload Image</Text>
                    </VStack>
                    <Controller
                      name={getItemField("question.media")}
                      control={template.control}
                      render={({ field }) => (
                        <HStack>
                          <input
                            hidden
                            accept="image/*,video/*,.pdf"
                            type="file"
                            ref={inputRef}
                            onChange={async (e) => {
                              const file = e.target.files?.[0];
                              if (file) {
                                const compressedImage = await compressImage(
                                  file
                                );
                                const base64 = await fileToBase64(
                                  compressedImage
                                );
                                if (base64) {
                                  setSignatureImage({
                                    generic: false,
                                    mediaName: file.name,
                                    base64,
                                    mediaType: file.name.split(".").pop() ?? "",
                                  });
                                }
                              }
                            }}
                          />
                        </HStack>
                      )}
                    />
                  </Center>
                </TabPanel>
                <TabPanel>
                  <Center
                    borderColor="borders.accent"
                    borderWidth="3px"
                    borderRadius="lg"
                  >
                    <SignatureCanvas
                      ref={signPadRef}
                      penColor="black"
                      backgroundColor="white"
                      canvasProps={{
                        className: "sigCanvas",
                        width: 350,
                        height: 200,
                      }}
                      onEnd={(e) => {
                        setSignatureImage({
                          generic: false,
                          mediaName: "signature.png",
                          base64: signPadRef.current
                            ?.toDataURL()
                            .split("base64,")[1],
                          mediaType: "png",
                        });
                      }}
                    />
                  </Center>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isImageUploading}
              disabled={signatureImage?.base64?.length === 0}
              onClick={() => {
                let inspectionData = template.getValues("inspection");
                if (signatureImage) {
                  setIsImageUploading.on();
                  const formData = new FormData();
                  formData.append("docType", "INSPECTION");
                  formData.append("base64", signatureImage?.base64 ?? "");
                  formData.append("fileName", signatureImage?.mediaName ?? "");
                  formData.append("inspectionId", inspectionData.id);
                  UploadAttachments.UploadAttachment(
                    formData,
                    (success) => {
                      template.setValue(
                        getItemField("question.deletedMedia"),
                        template
                          .getValues(getItemField("question.media"))
                          ?.map((img: any) => img?.id)
                      );
                      template.setValue(getItemField("question.media"), [
                        {
                          generic: false,
                          mediaName: signatureImage.mediaName,
                          mediaUrl: success.url,
                          mediaType: signatureImage.mediaType,
                        } as Template.MediaObject,
                      ]);
                      template.setValue(
                        getItemField("question.questionValue"),
                        `${template.getValues(
                          getItemField("question.questionValue")
                        )} (${moment().format("DD/MM/YYYY hh:mm a")})`
                      );
                      const answerId = template.getValues(
                        getItemField("question.answerId")
                      );
                      const type = template.getValues(
                        getItemField("question.type")
                      );
                      const inspectionMetaID = inspectionData.id;
                      setIsSaving(true);
                      Inspection.CachingInspection(
                        answerId,
                        {
                          inspection_meta_id: inspectionMetaID,
                          type: type,
                          media: [
                            {
                              generic: false,
                              mediaName: signatureImage.mediaName,
                              mediaUrl: success.url,
                              mediaType: signatureImage.mediaType,
                            } as Template.MediaObject,
                          ],
                          deletedMedia: template.getValues(
                            getItemField("question.deletedMedia")
                          ),
                        },
                        () => {
                          setIsSaving(false);
                        },
                        () => {
                          setIsSaving(false);
                        }
                      );
                      setIsImageUploading.off();
                      onClose();
                    },
                    (err) => {
                      toast({
                        title: "Error",
                        description: err,
                        status: "error",
                      });
                      setIsImageUploading.off();
                    }
                  );
                  // Inspection.UploadMedia(
                  //   {
                  //     base64: signatureImage.base64 ?? "",
                  //     fileName: signatureImage?.mediaName,
                  //     inspectionId: template.getValues("mediaUniqueId"),
                  //   },
                  //   (path) => {
                  //     template.setValue(
                  //       getItemField("question.deletedMedia"),
                  //       template
                  //         .getValues(getItemField("question.media"))
                  //         ?.map((img: any) => img?.id)
                  //     );
                  //     template.setValue(getItemField("question.media"), [
                  //       {
                  //         generic: false,
                  //         mediaName: signatureImage.mediaName,
                  //         mediaUrl: path,
                  //         mediaType: signatureImage.mediaType,
                  //       } as Template.MediaObject,
                  //     ]);
                  //     template.setValue(
                  //       getItemField("question.questionValue"),
                  //       `${template.getValues(
                  //         getItemField("question.questionValue")
                  //       )} (${moment().format("DD/MM/YYYY hh:mm a")})`
                  //     );
                  //     const answerId = template.getValues(
                  //       getItemField("question.answerId")
                  //     );
                  //     const type = template.getValues(
                  //       getItemField("question.type")
                  //     );
                  //     const inspectionMetaID =
                  //       template.getValues("inspection.id");
                  //     setIsSaving(true);
                  //     Inspection.CachingInspection(
                  //       answerId,
                  //       {
                  //         inspection_meta_id: inspectionMetaID,
                  //         type: type,
                  //         media: [
                  //           {
                  //             generic: false,
                  //             mediaName: signatureImage.mediaName,
                  //             mediaUrl: path,
                  //             mediaType: signatureImage.mediaType,
                  //           } as Template.MediaObject,
                  //         ],
                  //         deletedMedia: template.getValues(
                  //           getItemField("question.deletedMedia")
                  //         ),
                  //       },
                  //       () => {
                  //         setIsSaving(false);
                  //       },
                  //       () => {
                  //         setIsSaving(false);
                  //       }
                  //     );
                  //     setIsImageUploading.off();
                  //     onClose();
                  //   },
                  //   (err) => {
                  //     toast({
                  //       title: "Error",
                  //       description: err,
                  //     });
                  //     setIsImageUploading.off();
                  //   }
                  // );
                }
              }}
            >
              Upload
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
}
