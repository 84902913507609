import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Center,
  Container,
  Heading,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export function AdvancedSettings() {
  const navigate = useNavigate();
  const [clickedItems, setClickedItems] = useState<Array<number>>([]);
  const [clickedItems2, setClickedItems2] = useState<Array<number>>([]);

  const handleClick = (index: number) => {
    if (clickedItems.includes(index)) {
      setClickedItems((prev) => prev.filter((item) => item !== index));
    } else {
      setClickedItems((prev) => [...prev, index]);
    }
  };
  const handleClick2 = (index: number) => {
    if (clickedItems2.includes(index)) {
      setClickedItems2((prev) => prev.filter((item) => item !== index));
    } else {
      setClickedItems2((prev) => [...prev, index]);
    }
  };
  const data1: Array<{
    title: string;
    desc: string;
    cursor?: string;
    onClick?: () => void;
  }> = [
    {
      title: "Find and recode",
      desc: "Fix incorrect categorization across multiple transactions at once",
      cursor: "not-allowed",
    },
    {
      title: "Manual journals",
      desc: "Work directly with the general ledger",
      cursor: "not-allowed",
    },
    {
      title: "Fixed assets",
      desc: "Create and manage assets",
      cursor: "not-allowed",
    },
    {
      title: " Export accounting data ",
      desc: "Export data from HRIS-360 for importing into other systems",
      cursor: "not-allowed",
    },
    {
      title: "Assurance Dashboard",
      desc: "Monitor the accuracy of financial data within your organisation",
      cursor: "not-allowed",
    },
    {
      title: " History and notes ",
      desc: "View a summary of the actions made by all users to your transactions",
      cursor: "not-allowed",
    },
  ];
  const data2: Array<{
    title: string;
    desc: string;
    cursor?: string;
    onClick?: () => void;
  }> = [
    {
      title: "Financial settings",
      desc: "Edit financial settings like tax periods and lock dates",
      cursor: "not-allowed",
    },
    {
      title: "Chart of accounts",
      desc: "Add, edit, archive, delete, import or export your accounts",
      onClick: () => navigate("/app/payroll/ChartOfAccount"),
      cursor: "pointer",
    },
    {
      title: "Tax Rates",
      desc: "Add, edit or delete tax rates",
      onClick: () => navigate("/app/payroll/taxRates"),
      cursor: "pointer",
    },
    {
      title: " Fixed assets categories  ",
      desc: "Manage asset types and account defaults",
      cursor: "not-allowed",
    },
    {
      title: "Tracking categories ",
      desc: "Manage tracking items for more powerful reporting",
      onClick: () => navigate("/app/payroll/TrackingCategories"),
      cursor: "pointer",
    },
    {
      title: "Reporting Preferences ",
      desc: "Manage reporting method and report display",
      cursor: "not-allowed",
    },
    {
      title: "Conversion balances ",
      desc: "Update account balances from previous accounting systems",
      cursor: "not-allowed",
    },
  ];
  return (
    <Stack
      h="calc(100vh - 70px)"
      overflowY="auto"
      gap={0}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
    >
      <Stack
        borderBottom={"1px solid #d6dade"}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        width={"100%"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
      >
        <Container padding={0} maxW={"940px"}>
          <Stack h={"60px"}>
            <Heading
              fontSize="17px"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              paddingLeft={{ xs: "15px", md: "0" }}
              fontWeight="700"
              marginTop={"18px"}
            >
              Advanced Accounting
            </Heading>
          </Stack>
        </Container>
      </Stack>
      <Container maxW={"940px"} padding={"30px 0px 30px"}>
        <Center>
          <Stack
            spacing={6}
            direction={{ xs: "column", lg: "row" }}
            w={"100%"}
            paddingLeft={{ xs: "15px", md: "0" }}
            paddingRight={{ xs: "15px", md: "0" }}
          >
            <Accordion
              flex={1}
              w={"100%"}
              allowMultiple
              allowToggle
              defaultIndex={[0]}
              _dark={{
                bgColor: "customColor.dark.100",
              }}
            >
              <AccordionItem
                border={"1px solid #ccced2"}
                boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
              >
                {" "}
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  padding={"10px 5px"}
                >
                  <AccordionButton
                    w={"30px"}
                    padding={0}
                    h={"30px"}
                    borderRadius={"full"}
                    justifyContent={"center"}
                    _focus={{
                      boxShadow: "0 0 0 1px #fff, 0 0 0 4px rgba(0,10,30,.5)",
                      transition: "box-shadow .1s",
                      color: "#000a1e",
                    }}
                  >
                    <AccordionIcon />
                  </AccordionButton>
                  <Text
                    margin={"0px 0px 10px 0px"}
                    fontSize={"15px"}
                    fontWeight={"700"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                  >
                    Advanced features
                  </Text>
                </Stack>
                <AccordionPanel flex={1} w={"100%"}>
                  <Stack spacing={3}>
                    {data1.map((item, i) => {
                      const isClicked = clickedItems.includes(i);
                      return (
                        <Stack
                          key={i}
                          direction={"row"}
                          border={"1px dashed #ccced2"}
                          w={"100%"}
                          _dark={{
                            bgColor: "customColor.dark.50",
                          }}
                          _light={{
                            bgColor: "customColor.white",
                            _hover: { bgColor: "#f2f3f4" },
                          }}
                          p={"15px 20px"}
                          // _hover={{ boxShadow: "0 0 2px 2px lightgrey" }}
                          _hover={{
                            bgColor: "#f2f3f4",
                          }}
                          onClick={item.onClick}
                          cursor={item.cursor}
                          // w={{ lg: "510px", md: "510px", sm: "510px", base: "510px" }}
                        >
                          <Icon
                            as={AiFillStar}
                            w={5}
                            h={5}
                            color={isClicked ? "#0078C8" : "#ccced2"}
                            _hover={{
                              color: "#0078C8",
                            }}
                            onClick={() => {
                              handleClick(i);
                              if (item.onClick) item.onClick();
                            }}
                          />

                          <Stack>
                            <Heading
                              fontSize={{ base: "sm", md: "15px" }}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              _light={{
                                color: "customColor.black7",
                              }}
                              fontWeight={"500"}
                            >
                              {item.title}
                            </Heading>
                            <Heading
                              fontSize={{ base: "xs", md: "13px" }}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.dark.200",
                              }}
                              fontWeight={"400"}
                            >
                              {item.desc}
                            </Heading>
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Accordion
              flex={1}
              w={"100%"}
              allowMultiple
              allowToggle
              defaultIndex={[0]}
              _dark={{
                bgColor: "customColor.dark.100",
              }}
            >
              <AccordionItem
                border={"1px solid #ccced2"}
                boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  padding={"10px 5px"}
                >
                  <AccordionButton
                    w={"30px"}
                    padding={0}
                    h={"30px"}
                    borderRadius={"full"}
                    justifyContent={"center"}
                    _focus={{
                      boxShadow: "0 0 0 1px #fff, 0 0 0 4px rgba(0,10,30,.5)",
                      transition: "box-shadow .1s",
                      color: "#000a1e",
                    }}
                  >
                    <AccordionIcon />
                  </AccordionButton>
                  <Text
                    margin={"0px 0px 10px 0px"}
                    fontSize={"15px"}
                    fontWeight={"700"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                  >
                    Advanced settings
                  </Text>
                </Stack>

                <AccordionPanel flex={1} w={"100%"}>
                  <Stack spacing={3}>
                    {data2.map((item, i) => {
                      const isClicked = clickedItems2.includes(i);
                      return (
                        <Stack
                          key={i}
                          direction={"row"}
                          border={"1px dashed #ccced2"}
                          w={"100%"}
                          _dark={{
                            bgColor: "customColor.dark.50",
                          }}
                          _light={{
                            bgColor: "customColor.white",
                            _hover: { bgColor: "#f2f3f4" },
                          }}
                          p={"15px 20px"}
                          // _hover={{ boxShadow: "0 0 2px 2px lightgrey" }}
                          _hover={{
                            bgColor: "#f2f3f4",
                          }}
                          onClick={item.onClick}
                          cursor={item.cursor}
                        >
                          <Stack>
                            <Icon
                              as={AiFillStar}
                              w={5}
                              h={5}
                              color={isClicked ? "#0078C8" : "#ccced2"}
                              _hover={{
                                color: "#0078C8",
                              }}
                              onClick={() => {
                                handleClick2(i);
                                if (item.onClick) item.onClick();
                              }}
                            />
                          </Stack>
                          <Stack paddingLeft={"10px"} onClick={item.onClick}>
                            <Heading
                              fontSize={{ base: "sm", md: "15px" }}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              _light={{
                                color: "customColor.black7",
                              }}
                              fontWeight={"500"}
                            >
                              {item.title}
                            </Heading>
                            <Heading
                              fontSize={{ base: "xs", md: "13px" }}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.dark.200",
                              }}
                              fontWeight={"400"}
                            >
                              {item.desc}
                            </Heading>
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
        </Center>
        <Center>
          <Button
            marginTop="20px"
            variant="ghost"
            color={"primary.950"}
            fontWeight="400"
            fontSize="13px"
            padding="11px 15px"
            _hover={{
              _light: {
                bgColor: "#f2f3f4",
              },
              _dark: {
                bgColor: "customColor.dark.50",
              },
            }}
            onClick={() => navigate("/app/payroll/organisationSettings")}
          >
            Looking for organisation settings?
          </Button>
        </Center>
      </Container>
    </Stack>
  );
}
