import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ReactSelect } from "../../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../../Hooks/BusinessContext";
import { ShiftsTransfer } from "../../../../../TimeAttendance/API/ShiftsTransfer";

interface shiftTransferModalProps {
  isOpen: boolean;
  onClose: () => void;
  shift: any;
  setUpdatedRoasterData: (time: number) => void;
  department?: string;
}
export default function ShiftTransferModal({
  isOpen,
  onClose,
  shift,
  setUpdatedRoasterData,
  department,
}: shiftTransferModalProps) {
  const form = useForm<ShiftsTransfer.ShiftsTransfer>({
    defaultValues: {
      targetEmployeeId: "",
      shiftId: "",
    },
  });
  const { currentEmployeeId, employeesList } = useBusinessContext();
  const toast = useToast();
  const departmentWiseEmployeesWithOutSelf = employeesList?.filter(
    (emp) =>
      emp?.employeeEmployementDetails?.department === department &&
      emp.id !== currentEmployeeId
  );
  return (
    <FormProvider {...form}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={{ xs: "350px", md: "480px" }}>
          <ModalHeader>Transfer Shift</ModalHeader>
          <ModalBody>
            <Text fontSize="13px" fontWeight={500}>
              Are you sure you want to transfer this shift to another employee?
            </Text>
            <Text fontSize="13px" fontWeight={500}>
              Shift: {moment(shift?.shiftStartDate).format("DD-MM-yyyy")} (
              {moment(
                `${shift?.shiftStartDate}T${shift?.shiftStartTime}`
              ).format("hh:mm A")}
              )
            </Text>
            <Stack spacing="8px" w="100%">
              <Text
                mt="16px"
                mb={0}
                fontSize=".845rem"
                fontWeight={600}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Employee
              </Text>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Employee is required",
                  },
                }}
                control={form.control}
                name="targetEmployeeId"
                render={({ field, fieldState }) => {
                  return (
                    <ReactSelect
                      {...field}
                      withValidation
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Please Select Employee"
                      placeholder="Select Employee"
                      options={departmentWiseEmployeesWithOutSelf}
                      value={
                        field.value
                          ? {
                              id: field.value,
                              firstName:
                                departmentWiseEmployeesWithOutSelf?.find(
                                  (emp: any) => emp.id === field.value
                                )?.firstName ?? "",
                              lastName:
                                departmentWiseEmployeesWithOutSelf?.find(
                                  (emp: any) => emp.id === field.value
                                )?.lastName ?? "",
                            }
                          : undefined
                      }
                      onChange={(val: any) => {
                        field.onChange(val?.id);
                      }}
                      getOptionLabel={(option: any) =>
                        option.firstName + " " + option.lastName
                      }
                      getOptionValue={(option: any) => option.id}
                    />
                  );
                }}
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"8px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={form.handleSubmit((data) => {
                ShiftsTransfer.RequestShiftTransfer(
                  { ...data, shiftId: shift.id },
                  () => {
                    onClose();
                    toast({
                      title: "Success",
                      description: "Shift Transferred Successfully",
                      status: "success",
                      duration: 3000,
                      isClosable: true,
                    });
                    setUpdatedRoasterData(Date.now());
                  },
                  (err) => {
                    toast({
                      title: "Error",
                      description: err,
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                  }
                );
              })}
            >
              Transfer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
