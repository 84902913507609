import {Container} from '@chakra-ui/react';
import AccessTable from './access-components/AccessTable';

function TemplateAccess() {
  return (
    <Container maxW="full" backgroundColor="backgrounds.header" p={0}>
      <AccessTable />
    </Container>
  );
}

export default TemplateAccess;
