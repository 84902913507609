import { Box, Button, Checkbox, Input, Text, VStack } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { IoArrowBackSharp } from "react-icons/io5";
import { ReportLayout } from "../../../../../modules/Audit/ReportLayout";
import { useTemplateReport } from "../../report";
import SideBar from "../sidebar";
import FooterImage from "./ImagesUploading/FooterImage";

export default function Footer() {
  const { GoBackToPrevComponent, setDisplayedComponent } = useTemplateReport();
  const form = useFormContext<ReportLayout.ReportLayout>();
  return (
    <VStack
      align="start"
      w="100%"
      h="calc(100vh - 62px)"
      bg="white"
      spacing={0}
    >
      {/* <Flex
        px="16px"
        mt="24px"
        mb="8px"
        align="center"
        onClick={() => GoBackToPrevComponent()}>
        <IoArrowBackSharp color="#1da5d4" size={21} />
        <Text ml="8px" fontSize="16px" fontWeight={500} color="primary.500">
          Back
        </Text>
      </Flex> */}
      <Button
        px="16px"
        mt="24px"
        mb="8px"
        variant="ghost"
        _hover={{ backgroundColor: "none" }}
        _active={{ backgroundColor: "none" }}
        onClick={() => setDisplayedComponent(<SideBar />)}
        leftIcon={<IoArrowBackSharp size={21} />}
      >
        Back
      </Button>
      <Box px="24px" pt="8px">
        <Text
          fontWeight={500}
          fontSize="21px"
          color="textcolors.primary"
          mr="8px"
        >
          Footer
        </Text>
      </Box>
      <VStack w="100%" spacing={0} px="8px">
        <Box
          w="100%"
          borderRadius="12px"
          px="16px"
          py="8px"
          mb="8px"
          _hover={{ backgroundColor: "primary.50" }}
        >
          <Controller
            control={form.control}
            name="layout.footer.showPicture"
            render={({ field: { value, ...field }, fieldState: { error } }) => (
              <Checkbox
                borderColor="icons.primary"
                fontWeight={400}
                color="#3f495a"
                spacing={4}
                isInvalid={error !== undefined}
                isChecked={value}
                {...field}
              >
                Show Picture
              </Checkbox>
            )}
          />
        </Box>
        <Box
          w={"100%"}
          h={"100px"}
          alignItems={"center"}
          justifyContent={"center"}
          borderWidth="1px"
          borderStyle="dashed"
          borderColor="borders.accent"
          borderRadius={"12px"}
          backgroundColor={"white"}
          role="group"
        >
          <FooterImage />
        </Box>
        <Box
          w="100%"
          borderRadius="12px"
          px="16px"
          py="8px"
          mt="8px"
          _hover={{ backgroundColor: "primary.50" }}
        >
          <Controller
            control={form.control}
            name="layout.footer.inspectionTitle"
            render={({ field: { value, ...field }, fieldState: { error } }) => (
              <Checkbox
                borderColor="icons.primary"
                fontWeight={400}
                color="#3f495a"
                spacing={4}
                isInvalid={error !== undefined}
                isChecked={value}
                {...field}
              >
                Show inspection title
              </Checkbox>
            )}
          />
        </Box>
        <Box
          w="100%"
          borderRadius="12px"
          px="16px"
          py="8px"
          _hover={{ backgroundColor: "primary.50" }}
        >
          <Controller
            control={form.control}
            name="layout.footer.additionalInfo"
            render={({ field: { value, ...field }, fieldState: { error } }) => (
              <Checkbox
                borderColor="icons.primary"
                fontWeight={400}
                color="#3f495a"
                spacing={4}
                isInvalid={error !== undefined}
                isChecked={value}
                {...field}
              >
                Additional information
              </Checkbox>
            )}
          />
        </Box>
      </VStack>
      <Box w="100%" px="24px">
        <Controller
          control={form.control}
          name="layout.footer.notes"
          render={({ field: { value, ...field }, fieldState: { error } }) => (
            <Input
              {...field}
              w="100%"
              px="16px"
              py="8px"
              value={value ?? ""}
              fontSize="16px"
              color="textcolors.primary"
              placeholder="Add a description"
              isInvalid={error !== undefined}
              _focus={{ shadow: "none" }}
            />
          )}
        />
      </Box>
    </VStack>
  );
}
