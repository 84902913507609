import { Box, Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import { useBusinessContext } from "../../../../../Hooks/BusinessContext";
interface AssignedTagProps {
  shift: any;
}
export default function AssignedTag({ shift }: AssignedTagProps) {
  const { locationList, businessesList, currentBusinessId, allEmployeesList } =
    useBusinessContext();
  const calculateDuration = (start: any, end: any) => {
    const startTime = moment(start, "YYYY-MM-DDTHH:mm:ss");
    const endTime = moment(end, "YYYY-MM-DDTHH:mm:ss");
    const duration = moment.duration(endTime.diff(startTime));
    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${hours}h ${minutes}m`;
  };
  return (
    <Box
      h="auto"
      w={"100%"}
      border="1px"
      borderColor="gray.200"
      borderRadius="md"
      p="10px"
      alignItems="center"
      justifyContent="space-between"
      mb="10px"
    >
      <Flex>
        <Text
          fontWeight={"600"}
          flex={1}
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.white",
          }}
          fontSize={"15px"}
        >
          {moment(shift?.shift?.shiftStartDate).format("DD MMM YYYY")} -{" "}
          {
            locationList?.find(
              (location: any) => location.id === shift?.shift?.locationId
            )?.name
          }
        </Text>
        <Text
          fontWeight={"600"}
          color={
            shift?.shift?.status === "pending"
              ? "#FFA500"
              : shift?.shift?.status === "started"
              ? "#000000"
              : "#FF0000"
          }
          fontSize={"12px"}
        >
          {shift?.shift?.status === "pending"
            ? "Pending"
            : shift?.shift?.status === "started"
            ? "Started"
            : "Ended"}
        </Text>
      </Flex>
      <Flex>
        <Box flex={1}>
          <Text
            fontWeight={"400"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"15px"}
          >
            {`${moment(
              `${shift?.shift?.shiftStartDate}T${shift?.shift?.shiftStartTime}`
            ).format("hh:mm A")} - ${moment(
              `${shift?.shift?.shiftEndDate}T${shift?.shift?.shiftEndTime}`
            ).format("hh:mm A")}`}
          </Text>
          <Text
            fontWeight={"400"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"11px"}
          >
            Employee name:{" "}
            {
              allEmployeesList?.find(
                (emp: any) => emp.id === shift?.targetEmployeeId
              )?.firstName
            }{" "}
            {
              allEmployeesList?.find(
                (emp: any) => emp.id === shift?.targetEmployeeId
              )?.lastName
            }
          </Text>
        </Box>
        <Box>
          <Text
            fontWeight={"400"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"11px"}
          >
            Total Shift time:{" "}
            {calculateDuration(
              `${shift?.shift?.shiftStartDate}T${shift?.shift?.shiftStartTime}`,
              `${shift?.shift?.shiftEndDate}T${shift?.shift?.shiftEndTime}`
            )}
          </Text>
          <Text
            fontWeight={"400"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"11px"}
            textAlign="right"
          >
            Business:{" "}
            {
              businessesList?.find(
                (business: any) => business.id === currentBusinessId
              )?.name
            }
          </Text>
        </Box>
      </Flex>
      <Flex></Flex>
    </Box>
  );
}
