import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import {InventoryModule} from '../../../../../../modules/Audit/Inventory';

interface BulkDeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  itemIds: Array<number>;
  setLastUpdate: (timestamp: number) => void;
  setSelectedItemIds: (itemIds: Array<number>) => void;
}
export default function BulkDeleteModal({
  isOpen,
  onClose,
  itemIds,
  setLastUpdate,
  setSelectedItemIds,
}: BulkDeleteModalProps) {
  const toast = useToast();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mx="10px">
        <ModalHeader fontWeight="semibold" py="4px">
          Confirmation
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>Are you sure you want to Delete this?</ModalBody>

        <ModalFooter
          display="flex"
          justifyContent="flex-end"
          pt="16px"
          border="0px">
          <ButtonGroup size="xs" variant="solid" borderRadius="5px">
            <Button
              onClick={() => {
                setSelectedItemIds([]);
                onClose();
              }}
              borderRadius="5px">
              No
            </Button>
            <Button
              colorScheme="red"
              borderRadius="5px"
              onClick={() => {
                InventoryModule.bulkDelete(
                  {ids: itemIds},
                  () => {
                    toast({
                      title: 'Items Deleted',
                      status: 'success',
                    });
                    setLastUpdate(Date.now());
                    setSelectedItemIds([]);
                    onClose();
                  },
                  error => {
                    toast({
                      title: 'Error',
                      description: error,
                      status: 'error',
                    });
                    onClose();
                  },
                );
              }}>
              Yes
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
