import {Wrap, WrapItem} from '@chakra-ui/react';
import {useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import {Inspection, Template} from '../../../modules/Audit';
import {useInspectionContext} from '../hooks/InspectionContext';
import {ImageView} from './AuditMediaTypes';
import {useAuditQuestionContainer} from './AuditQuestionContainer';

export default function MediaGallery() {
  const template = useFormContext();
  const {getItemField} = useAuditQuestionContainer();
  const medias: Array<Template.MediaObject> | undefined = useWatch({
    control: template.control,
    name: getItemField('question.media'),
  });

  const mediaArr = useFieldArray({
    control: template.control,
    name: getItemField('question.media'),
    keyName: 'uniqueId',
  });

  const deletedMediaArr = useFieldArray({
    control: template.control,
    name: getItemField('question.deletedMedia'),
    keyName: 'uniqueId',
  });
  const {setIsSaving} = useInspectionContext();

  // useEffect(() => {
  //   const promises = [];
  //   let index = 0;
  //   for (let watchedMediaObject of medias ?? []) {
  //     if (watchedMediaObject.base64 && !watchedMediaObject.mediaUrl) {
  //       promises.push(
  //         new Promise((resolve, reject) => {
  //           Inspection.UploadMedia(
  //             {
  //               base64: watchedMediaObject.base64!,
  //               fileName: watchedMediaObject.mediaName,
  //               inspectionId: template.getValues('mediaUniqueId'),
  //             },
  //             path => {
  //               delete watchedMediaObject['base64'];
  //               resolve({
  //                 mediaUrl: path,
  //                 generic: watchedMediaObject.generic,
  //                 mediaName: watchedMediaObject.mediaName,
  //                 mediaType: watchedMediaObject.mediaType,
  //               });
  //             },
  //             error => {
  //               reject(error);
  //             },
  //           );
  //         }),
  //       );
  //     }
  //     index++;
  //   }

  //   Promise.all(promises)
  //     .then(mediaObjects => {
  //       console.log(mediaObjects);
  //       // for (let mediaObject of mediaObjects) {
  //       //   mediaArr.append(mediaObject);
  //       // }
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });

  //   // template.setValue(getItemField(`question.media.${index}`), {
  //   //   mediaUrl: path,
  //   //   generic: watchedMediaObject.generic,
  //   //   mediaName: watchedMediaObject.mediaName,
  //   //   mediaType: watchedMediaObject.mediaType,
  //   // } as Template.MediaObject);
  // }, [medias]);

  return (
    <Wrap mt="8px">
      {(medias as Array<Template.MediaObject>)?.map((media, i) => (
        <WrapItem key={`${i}-${media.mediaName}`}>
          <ImageView
            watchedMediaObject={media}
            key={i}
            onclick={() => {
              mediaArr.remove(i);
              if (media.id) {
                deletedMediaArr.append(media.id);
                const answerId = template.getValues(
                  getItemField('question.answerId'),
                );
                const inspection_meta_id = template.getValues('inspection.id');
                const type = template.getValues(getItemField('question.type'));
                setIsSaving(true);
                Inspection.CachingInspection(
                  answerId,
                  {
                    inspection_meta_id: inspection_meta_id,
                    type: type,
                    deletedMedia: [media.id],
                    question_value:
                      type === 'MEDIA'
                        ? (medias?.length ?? 0) - 1 > 0
                          ? ((medias?.length ?? 1) - 1).toString()
                          : null
                        : undefined,
                  },
                  () => {
                    setIsSaving(false);
                    if (type === 'MEDIA') {
                      template.setValue(
                        getItemField('question.questionValue'),
                        type === 'MEDIA'
                          ? (medias?.length ?? 0) - 1 > 0
                            ? ((medias?.length ?? 1) - 1).toString()
                            : null
                          : undefined,
                      );
                    }
                  },
                  () => {
                    setIsSaving(false);
                  },
                );
              }
            }}
            index={i}
          />
        </WrapItem>
      ))}
    </Wrap>
  );
}
