import { axiosInstance } from "./axiosInstance-aud";

declare module Permission {
  type Permissions = {
    createdAt: string;
    updatedAt: string;
    id: number;
    deletedAt: string;
    permissionName: string;
    permissionType: PermissionType;
  };
  type PermissionType = "INSPECTION" | "TEMPLATE";
}
module Permission {
  const URL = "/permission";
  export function FetchPermissionByType(
    permissionType: PermissionType,
    success: (response: Array<Permissions>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}`, {
        params: {
          permissionType: permissionType,
        },
      })
      .then(({ data }) => {
        console.log(data);
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { Permission };
