import axiosInstanceTimeAttendance from "./axiosInstanceTime";

declare module BusinessLevelNotificationSetting {
  type noticationSetting = {
    isSMSActive: boolean;
    isEmailActive: boolean;
    isPushActive: boolean;
  };
}
module BusinessLevelNotificationSetting {
  const API_URL = "general-settings";
  export function PostNotificationSetting(
    data: noticationSetting,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTimeAttendance
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function GetNotificationSetting(
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTimeAttendance
      .get(`${API_URL}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { BusinessLevelNotificationSetting };
