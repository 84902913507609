import { useToast } from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { AccessControl } from "../api/AccessControl";
import { Business } from "../Payroll/Api/Business";
import { Employees } from "../Payroll/Api/Employees";
import { SetupGuideApi } from "../Payroll/Api/SetupGuideApi";
import { LocationApi } from "../TimeAttendance/API/LocationAPi";

interface BusinessContextProps {
  businessesList: Business.GetBusinessDetails[];
  allUsers: AccessControl.UserDetailMin[];
  employeesList?: Employees.FetchEmployees[];
  pastEmployeesList?: Employees.FetchEmployees[];
  allEmployeesList?: Employees.FetchEmployees[];
  locationList?: LocationApi.Location[];
  lastUpdated: number;
  lastUpdatedLocations?: number;
  refereshEmployees: boolean;
  setLastUpdated: React.Dispatch<React.SetStateAction<number>>;
  setLastUpdatedLocations: React.Dispatch<React.SetStateAction<number>>;
  setRefereshEmployees: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentBusinessId: React.Dispatch<React.SetStateAction<string>>;
  currentBusinessId?: string;
  currentEmployeeId?: string;
  setCurrentEmployeeId: React.Dispatch<React.SetStateAction<string>>;
  setSetupGuide: React.Dispatch<
    React.SetStateAction<SetupGuideApi.SetupGuideData>
  >;
  setupGuide: SetupGuideApi.SetupGuideData;
}

const BusinessContext = createContext<BusinessContextProps>({
  businessesList: [],
  employeesList: [],
  allUsers: [],
  allEmployeesList: [],
  pastEmployeesList: [],
  locationList: [],
  lastUpdated: 0,
  lastUpdatedLocations: 0,
  setLastUpdated: () => {},
  setCurrentBusinessId: () => {},
  currentBusinessId: undefined,
  currentEmployeeId: undefined,
  setCurrentEmployeeId: () => {},
  refereshEmployees: false,
  setRefereshEmployees: () => {},
  setLastUpdatedLocations: () => {},
  setSetupGuide: () => {},
  setupGuide: {
    completed: false,
    completedItems: {
      users: false,
      employees: false,
      orgDetails: false,
      chartOfAccounts: false,
      payrollSettings: false,
    },
    businessId: "",
    id: "",
  },
});
interface BusinessContextProviderProps {
  children: any;
}
const BusinessContextProvider = ({
  children,
}: BusinessContextProviderProps) => {
  const [businessesList, setBusinessesList] = useState<
    Array<Business.GetBusinessDetails>
  >([]);
  const [employeesList, setEmployeesList] = useState<
    Array<Employees.FetchEmployees>
  >([]);
  const [allEmployeesList, setAllEmployeesList] = useState<
    Array<Employees.FetchEmployees>
  >([]);
  const [allUsers, setAllUsers] = useState<Array<AccessControl.UserDetailMin>>(
    []
  );
  const [pastEmployeesList, setPastEmployeesList] = useState<
    Array<Employees.FetchEmployees>
  >([]);
  const [locationList, setLocationList] = useState<Array<LocationApi.Location>>(
    []
  );
  const [lastUpdatedLocations, setLastUpdatedLocations] = useState<number>(0);
  const [lastUpdated, setLastUpdated] = useState<number>(0);
  const [refereshEmployees, setRefereshEmployees] = useState<boolean>(false);
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  const [currentBusinessId, setCurrentBusinessId] = useState<string>(
    decodedToken?.businessId
  );
  const [currentEmployeeId, setCurrentEmployeeId] = useState<string>(
    decodedToken?.employeeId
  );
  const [setupGuide, setSetupGuide] = useState<SetupGuideApi.SetupGuideData>({
    completed: false,
    completedItems: {
      users: false,
      employees: false,
      orgDetails: false,
      chartOfAccounts: false,
      payrollSettings: false,
    },
    businessId: currentBusinessId,
    id: undefined,
  });
  const toast = useToast();
  useEffect(() => {
    Business.GetAllBusiness(
      (data) => {
        setBusinessesList(data);
      },

      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, [lastUpdated]);
  useEffect(() => {
    AccessControl.FetchAllUsersMin(
      (data) => {
        setAllUsers(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, [lastUpdated]);
  useEffect(() => {
    AccessControl.FetchAllUsersMin(
      (data) => {
        setAllUsers(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, [lastUpdated]);
  useEffect(() => {
    if (currentBusinessId) {
      SetupGuideApi.SetupGuide(
        (data) => {
          setSetupGuide(data);
        },
        (error) => {
          toast({
            title: "Failed to get api",
            description: error,
            status: "error",
          });
        }
      );
    }
  }, [currentBusinessId]);
  useEffect(() => {
    if (currentBusinessId) {
      LocationApi.FetchLocations(
        (data) => {
          setLocationList(data);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
          });
        }
      );
    }
  }, [lastUpdated, auth(), lastUpdatedLocations, currentBusinessId]);
  useEffect(() => {
    if (currentBusinessId) {
      Employees.FetchEmployees(
        {
          empStatus: "CURRENT",
        },
        (data) => {
          setEmployeesList(data);
          setRefereshEmployees(false);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
          });
          setRefereshEmployees(false);
        }
      );
      Employees.FetchEmployees(
        {
          empStatus: "PAST",
        },
        (data) => {
          setPastEmployeesList(data);
          setRefereshEmployees(false);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
          });
          setRefereshEmployees(false);
        }
      );
      Employees.FetchEmployees(
        {
          empStatus: "ALL",
        },
        (data) => {
          setAllEmployeesList(data);
          setRefereshEmployees(false);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
          });
          setRefereshEmployees(false);
        }
      );
    }
  }, [lastUpdated, auth(), refereshEmployees, currentBusinessId]);

  return (
    <BusinessContext.Provider
      value={{
        businessesList,
        employeesList,
        lastUpdated,
        lastUpdatedLocations,
        setLastUpdated,
        setLastUpdatedLocations,
        setCurrentBusinessId,
        currentBusinessId,
        currentEmployeeId,
        setCurrentEmployeeId,
        locationList,
        refereshEmployees,
        setRefereshEmployees,
        allEmployeesList,
        setSetupGuide,
        setupGuide,
        pastEmployeesList,
        allUsers,
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
};
function useBusinessContext() {
  return useContext<BusinessContextProps>(BusinessContext);
}
export { BusinessContext, BusinessContextProvider, useBusinessContext };
