import { Box, HStack, IconButton, Input } from "@chakra-ui/react";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { MdDelete } from "react-icons/md";
import { RxDragHandleDots2 } from "react-icons/rx";
import { MCQ } from "../../../modules/Audit/MCQs";
interface GlobalResponseItemProps {
  index: number;
  onDelete?: () => void;
}
export default function GlobalResponseItem({
  index,
  onDelete,
}: GlobalResponseItemProps) {
  const [iconVisible, setIconVisible] = useState(false);
  const { control } = useFormContext<MCQ.IMCQ>();

  return (
    <HStack
      w="100%"
      spacing={0}
      h="47px"
      padding="5px"
      backgroundColor="white"
      borderBottom={"1px solid"}
      borderColor={"borders.accent"}
    >
      <Box padding="5px 10px 0px 0px" justifyContent="center">
        <RxDragHandleDots2 size={21} color={"#bfc6d4"} cursor="grab" />
      </Box>
      <HStack flex={"auto"}>
        <Controller
          control={control}
          name={`options.${index}.value`}
          rules={{
            onBlur: () => {
              setIconVisible(false);
            },
          }}
          render={({ field }) => (
            <Input
              h="35.6px"
              padding="5px"
              w="100%"
              border="0px"
              onFocus={() => setIconVisible(true)}
              placeholder="Enter text..."
              _focusVisible={{
                boxShadow: "0px",
                border: "1px solid",
                borderColor: "primary.500",
              }}
              {...field}
            />
          )}
        />

        <Box>
          <IconButton
            backgroundColor="transparent"
            margin="8px 8px 0px 8px"
            borderRadius={"50%"}
            border={"none"}
            size="22.5px"
            _hover={{ backgroundColor: "#bfc6d4" }}
            aria-label="delete"
            icon={<MdDelete color="#545f70" size="22.5px" />}
            onClick={onDelete}
          />
        </Box>
      </HStack>
    </HStack>
  );
}
