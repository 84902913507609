import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { Controller, useForm } from "react-hook-form";
import { ReactSelect } from "../../../../../../../Common/ReactSelect";
import { TemplateMeta } from "../../../../../../modules/Audit";
import { PublicLibraryModule } from "../../../../../../modules/Audit/PublicLibrary";

interface UploadTemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
  templatesList: Array<TemplateMeta.TemplateMeta>;
  setLastUpdate: (timestamp: number) => void;
}
export default function UploadTemplateModal({
  isOpen,
  onClose,
  templatesList,
  setLastUpdate,
}: UploadTemplateModalProps) {
  const { control, handleSubmit, reset } =
    useForm<PublicLibraryModule.UploadTemplate>({
      defaultValues: {
        templateId: undefined,
        industry: "",
        author: "",
      },
    });
  const user = useAuthUser();
  const UserDetails = user();
  const toast = useToast();
  const [isLoading, setLoading] = useState<boolean>(false);
  const industries = [
    "Aged care industry",
    "Agricultural industry",
    "Airline operations",
    "Airport operations",
    "Aluminium industry",
    "Ambulance and patient transport",
    "Amusement, events and recreation industry",
    "Animal care and veterinary services",
    "Aquaculture",
    "Asphalt industry",
    "Australian Capital Territory",
    "Banking, finance and insurance industry",
    "Broadcasting and recorded entertainment industry",
    "Building services",
    "Building, metal and civil construction industries",
    "Business equipment industry",
    "Cement and concrete products",
    "Cemetery operations",
    "Children's services",
    "Christmas Island",
    "Cleaning services",
    "Clerical industry",
    "Clothing industry",
    "Coal export terminals",
    "Coal industry",
    "Cocos (Keeling) Islands",
    "Commercial sales",
    "Commonwealth employment",
    "Contract call centre industry",
    "Corrections and detentions",
    "Diving services",
    "Dredging industry",
    "Dry cleaning and laundry services",
    "Educational services",
    "Electrical contracting industry",
    "Electrical power industry",
    "Fast food industry",
    "Federal police operations",
    "Fire fighting services",
    "Food, beverages and tobacco manufacturing industry",
    "Funeral directing services",
    "Gardening services",
    "Grain handling industry",
    "Graphic arts",
    "Hair and beauty",
    "Health and welfare services",
    "Hospitality industry",
    "Indigenous organisations and services",
    "Journalism",
    "Licensed and registered clubs",
    "Live performance industry",
    "Local government administration",
    "Mannequins and modelling",
    "Manufacturing and associated industries",
    "Marine tourism and charter vessels",
    "Market and business consultancy services",
    "Meat industry",
    "Mining industry",
    "Miscellaneous",
    "Norfolk Island",
    "Northern Territory",
    "Nursery industry",
    "Passenger vehicle transport (non rail) industry",
    "Pet food manufacturing",
    "Pharmaceutical industry",
    "Pharmacy operations",
    "Plumbing industry",
    "Port authorities",
    "Postal services",
    "Poultry processing",
    "Publishing industry",
    "Quarrying industry",
    "Racing industry",
    "Rail industry",
    "Real estate industry",
    "Restaurants",
    "Retail industry",
    "Road transport industry",
    "Rubber, plastic and cable making industry",
    "Salt industry",
    "Scientific services",
    "Seafood processing",
    "Security services",
    "Social, community, home care and disability services",
    "Sporting organisations",
    "State and Territory government administration",
    "Stevedoring industry",
    "Storage services",
    "Sugar industry",
    "Tasmania",
    "Technical services",
    "Telecommunications services",
    "Textile industry",
    "Timber and paper products industry",
    "Tourism industry",
    "Uranium mining (incl. construction)",
    "Vehicle industry",
    "Waste management industry",
    "Water, sewerage and drainage services",
    "Wine industry",
    "Wool storage, sampling and testing industry",
  ].map((industry) => ({ label: industry, value: industry }));
  const [isSwitchedOn, setIsSwitchedOn] = useState(true);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text color="textcolors.primary">
            Upload your template to Public Library
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color="textcolors.secondary" fontSize="14px">
            Share your template with the world. Once uploaded, it will appear in
            the Public Library. Anyone can view it or make a copy to customize
            it.
          </Text>
          <Box marginTop="24px">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Template is required",
                },
              }}
              name="templateId"
              control={control}
              render={({ field, fieldState }) => (
                <ReactSelect
                  key={field.value}
                  {...field}
                  label="Template"
                  withValidation
                  isInvalid={fieldState.invalid}
                  ErrorMessage="Please select template"
                  placeholder="Select template"
                  options={templatesList}
                  value={
                    field.value
                      ? {
                          id: field.value,
                          title:
                            templatesList.find(
                              (temp) => temp.id === field.value
                            )?.title ?? "",
                        }
                      : undefined
                  }
                  onChange={(val: any) => {
                    field.onChange(val?.id);
                  }}
                  getOptionLabel={(option: any) => option.title}
                  getOptionValue={(option: any) => option.id}
                />
              )}
            />

            <Box marginTop="16px">
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Industry is required",
                  },
                }}
                control={control}
                name="industry"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    options={industries}
                    withValidation
                    isInvalid={fieldState.invalid}
                    placeholder="Choose industry"
                    ErrorMessage="Select Industry"
                    label="Industry"
                    value={industries.find((op) => op.value === field.value)}
                    onChange={(newValue: any) => {
                      field.onChange(newValue?.value);
                    }}
                  />
                )}
              />
            </Box>
            <Text fontSize="14px" color="textcolors.primary" marginTop="16px">
              Author
            </Text>
            <HStack justifyContent="space-between">
              <Text fontSize="16px" color="textcolors.primary">
                {isSwitchedOn
                  ? UserDetails?.firstName + " " + UserDetails?.lastName
                  : "Anonymous"}
              </Text>
              <Switch
                id="author"
                isChecked={isSwitchedOn}
                value={isSwitchedOn.toString()}
                onChange={() => {
                  setIsSwitchedOn(!isSwitchedOn);
                }}
              />
            </HStack>
          </Box>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button
              variant="outline"
              borderRadius="8px"
              h="43px"
              px="20px"
              py="12px"
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              isLoading={isLoading}
              variant="solid"
              borderRadius="8px"
              h="43px"
              px="20px"
              py="12px"
              onClick={handleSubmit((data) => {
                setLoading(true);
                data.author = isSwitchedOn
                  ? UserDetails?.firstName + " " + UserDetails?.lastName
                  : "Anonymous";
                PublicLibraryModule.UploadTemplate(
                  data,
                  () => {
                    toast({
                      title: "Success",
                      description: "Template uploaded successfully",
                      status: "success",
                    });
                    setLoading(false);
                    setLastUpdate(Date.now());
                    reset({});
                    onClose();
                  },
                  (error) => {
                    toast({
                      title: "Error",
                      description: error,
                      status: "error",
                    });
                  }
                );
              })}
            >
              Upload
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
