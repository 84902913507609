import {
  Box,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Skeleton,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import moment from "moment";
import { useRef, useState } from "react";
import { BiTrash } from "react-icons/bi";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AddInvoices } from "../../Api/Invoices";
import { Header } from "./Header";

interface Props {
  count: number;
  category: string;
  limit: number;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setLastUpdated: React.Dispatch<React.SetStateAction<number>>;
  invoicesData: AddInvoices.FetchInvoices[];
}
export function InvoiceTable({
  count,
  limit,
  category,
  setPageNumber,
  pageNumber,
  isLoading,
  setLimit,
  setIsLoading,
  setLastUpdated,
  invoicesData,
}: Props) {
  const pageNumberInputRef = useRef<HTMLInputElement>(null);

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const navigate = useNavigate();
  const pageCount = Math.ceil(count / limit);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedRows, setCheckedRows] = useState<number[]>([]);

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isAllChecked = event.target.checked;
    if (isAllChecked) {
      const allRowIds = invoicesData.map((item, i) => i);
      setCheckedRows(isCheckedAll ? [] : allRowIds);

      setSelectedIds(invoicesData.map((item) => item.id));
    } else {
      setCheckedRows([]);
      setSelectedIds([]);
    }

    setIsCheckedAll(isAllChecked);
  };

  const handleCheckRow = (rowIndex: number) => {
    const updatedRows = [...checkedRows];
    const rowIndexInCheckedRows = updatedRows.indexOf(rowIndex);

    if (rowIndexInCheckedRows !== -1) {
      updatedRows.splice(rowIndexInCheckedRows, 1);
    } else {
      updatedRows.push(rowIndex);
    }

    setCheckedRows(updatedRows);
    setIsCheckedAll(updatedRows.length === invoicesData.length);
    const selectedIds = updatedRows.map((index) => invoicesData?.[index]?.id);
    setSelectedIds(selectedIds);
  };
  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };
  const renderPageNumbers = () => {
    const pageButtons = [];

    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            aria-label={`Page ${i}`}
            border={"1px solid #e8eaf2"}
            borderRadius={"full"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }
    } else {
      pageButtons.push(
        <IconButton
          key={1}
          aria-label={`Page 1`}
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          backgroundColor={pageNumber === 1 ? "#3454D1" : "#fff"}
          color={pageNumber === 1 ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === 1 ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>1</Text>}
          onClick={() => handlePageChange(1)}
        />
      );

      if (pageNumber > 3) {
        pageButtons.push(<Text key="left-dots">...</Text>);
      }

      const startPage = Math.max(2, pageNumber - 1);
      const endPage = Math.min(pageCount - 1, pageNumber + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            border={"1px solid #e8eaf2"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            aria-label={`Page ${i}`}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }

      if (pageNumber < pageCount - 2) {
        pageButtons.push(<Text key="right-dots">...</Text>);
      }

      pageButtons.push(
        <IconButton
          key={pageCount}
          border={"1px solid #e8eaf2"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          borderRadius={"full"}
          aria-label={`Page ${pageCount}`}
          backgroundColor={pageNumber === pageCount ? "#3454D1" : "#fff"}
          color={pageNumber === pageCount ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === pageCount ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>{pageCount}</Text>}
          onClick={() => handlePageChange(pageCount)}
        />
      );
    }

    return pageButtons;
  };

  return (
    <Stack
      gap={0}
      // maxWidth={"1164px"}
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      borderRadius="10px"
      bg={"#fff"}
      boxShadow={"0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"}
      borderWidth="1px"
      borderStyle={"solid"}
      borderColor="#f6f6f6"
      transition={"all .3s ease"}
      p={"0px"}
    >
      <Header
        limit={limit}
        category={category}
        setLimit={setLimit}
        setPageNumber={setPageNumber}
        selectedId={selectedId}
        selectedIds={selectedIds}
        setLastUpdated={setLastUpdated}
      />
      <Skeleton isLoaded={!isLoading} height="100%">
        <Box overflowX="auto" p="0px">
          <Table variant="simple" w="100%" overflowX="auto">
            <Thead h={"36.8px"}>
              <Tr
                borderTopWidth={"1px"}
                borderTopColor="borders.tableBorder"
                color={"customColor.black7"}
                _dark={{
                  color: "customColor.dark.150",
                }}
              >
                <Th
                  padding="8px 26px 8px 30px"
                  borderBottomColor="borders.tableBorder"
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  fontWeight={700}
                  whiteSpace="nowrap"
                  fontSize="12px"
                  textTransform="none"
                >
                  <Checkbox
                    size="md"
                    borderColor="#adb5bd"
                    bgColor="white"
                    isChecked={isCheckedAll}
                    onChange={handleCheckAll}
                  ></Checkbox>
                </Th>
                {[
                  {
                    name: "NUMBER",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "REF",
                    value: "15%",
                    paddingValue: "8px 26px 8px 15px",
                  },

                  {
                    name: "DATE",
                    value: "15%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "DUE DATE",
                    value: "15%",
                    paddingValue: "8px 26px 8px 15px",
                  },

                  {
                    name: "PAID",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "DUE",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "TOTAL",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "STATUS",
                    value: "15%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "ACTION",
                    value: "5%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                ].map((item, i) => {
                  return (
                    <Th
                      key={i}
                      padding={item.paddingValue}
                      borderBottomColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={"bold"}
                      whiteSpace="nowrap"
                      fontSize="11px"
                      width={item.value}
                    >
                      {item.name}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody padding={0}>
              {invoicesData.map((item, i) => {
                return (
                  <Tr
                    key={i}
                    _hover={{
                      bgColor: "#f2f3f8",
                      cursor: "pointer",
                    }}
                  >
                    <Td
                      padding="15px 15px 15px 30px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      fontWeight={500}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Checkbox
                        size="md"
                        borderColor="#adb5bd"
                        bgColor="white"
                        isChecked={checkedRows.includes(i)}
                        onChange={() => {
                          handleCheckRow(i);
                          setSelectedId(item.id);
                        }}
                      />
                    </Td>
                    <Td
                      padding="15px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      fontWeight={600}
                      whiteSpace="nowrap"
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {item.invoiceNumber ?? "N.A"}
                    </Td>
                    <Tooltip label={item.reference ?? "N.A"}>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        maxW={"100px"}
                        isTruncated
                        borderTopColor={"borders.tableBorder"}
                      >
                        {item.reference ?? "N.A"}
                      </Td>
                    </Tooltip>

                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item.issueDate
                        ? moment(item.issueDate).format("DD MMM YYYY")
                        : "N.A"}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item.dueDate
                        ? moment(item.dueDate).format("DD MMM YYYY")
                        : "N.A"}
                    </Td>

                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item?.paidAmount
                        ? item.paidAmount.toLocaleString()
                        : "N.A"}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item?.dueAmount
                        ? item.dueAmount.toLocaleString()
                        : "N.A"}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item?.totalAmount
                        ? item.totalAmount.toLocaleString()
                        : "N.A"}
                    </Td>

                    <Td
                      padding="15px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      <Tag
                        fontSize={"11px"}
                        fontWeight={500}
                        borderRadius={"2px"}
                        color={
                          item?.status === "Awaiting Payment"
                            ? "#17C666"
                            : item?.status === "Draft"
                            ? "customColor.black7"
                            : item?.status === "Awaiting Approval"
                            ? "#EA4D4D"
                            : item?.status === "Paid"
                            ? "#3454d1"
                            : "black"
                        }
                        bg={
                          item?.status === "Awaiting Payment"
                            ? "#E1FBED"
                            : item?.status === "Draft"
                            ? "#E9ECEF"
                            : item?.status === "Awaiting Approval"
                            ? "#FDEDED"
                            : item?.status === "Paid"
                            ? "#f1f3fb"
                            : "#fff"
                        }
                        border={"1px solid #e5e7eb"}
                      >
                        {item?.status}
                      </Tag>
                    </Td>

                    <Td
                      padding="15px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      <HStack alignItems={"center"}>
                        <Icon
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="50%"
                          borderStyle={"solid"}
                          borderWidth={"1px"}
                          borderColor={"borders.tableBorder"}
                          bg={"#fff"}
                          w="25px"
                          h="25px"
                          px="5px"
                          py="5px"
                          as={MdEdit}
                          fontSize="22px"
                          color="#6B7280"
                          _hover={{
                            color: "#3454d1",
                          }}
                          onClick={() => {
                            navigate("/app/payroll/invoices", {
                              state: {
                                id: item.id,
                                uniqueNumber: item.invoiceNumber,
                              },
                            });
                          }}
                        />

                        <Icon
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="50%"
                          borderStyle={"solid"}
                          borderWidth={"1px"}
                          borderColor={"borders.tableBorder"}
                          bg={"#fff"}
                          w="25px"
                          h="25px"
                          px="5px"
                          py="5px"
                          as={BiTrash}
                          fontSize="22px"
                          color="#6B7280"
                          _hover={{
                            color: "red",
                          }}
                          onClick={() => {
                            setIsLoading(true);
                            AddInvoices.DeleteInvoices(
                              item.id,
                              (success) => {
                                setIsLoading(false);
                                setLastUpdated(Date.now());
                              },
                              () => {
                                setIsLoading(false);
                              }
                            );
                          }}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Skeleton>
      <Stack borderTop={"1px solid #dcdee4 "}>
        <HStack
          marginTop={"9.35px"}
          padding={"25px"}
          fontSize={"13px"}
          _light={{
            color: "customColor.black2",
          }}
          _dark={{
            color: "customColor.white",
          }}
          justifyContent={"space-between"}
        >
          <Text
            fontWeight={"normal"}
            color={"customColor.6b7885"}
            _dark={{
              color: "customColor.dark.200",
            }}
            fontSize={"11px"}
          >
            Showing {limit * (pageNumber - 1) + 1} to{" "}
            {limit + limit * (pageNumber - 1)} of {invoicesData?.length} entries
          </Text>
          <HStack spacing="13px" justify={{ xs: "center", md: "end" }}>
            <IconButton
              aria-label="back"
              border={"1px solid #e8eaf2"}
              borderRadius={"full"}
              backgroundColor="transparent"
              _hover={{
                backgroundColor: "#3454D1",
                color: "white",
              }}
              onMouseEnter={() => setIsHovered2(true)}
              onMouseLeave={() => setIsHovered2(false)}
              icon={
                <FaArrowLeft
                  size={15}
                  color={
                    isHovered2
                      ? "#FFFFFF"
                      : pageNumber <= 1 || pageNumber > pageCount
                      ? "#828ea0"
                      : "#283c50"
                  }
                />
              }
              isDisabled={pageNumber <= 1 || pageNumber > pageCount}
              onClick={() => {
                if (pageNumber > 1 && pageNumber <= pageCount) {
                  pageNumberInputRef.current?.value &&
                    (pageNumberInputRef.current.value = String(pageNumber - 1));
                  setPageNumber(pageNumber - 1);
                }
              }}
            />
            <HStack spacing="13px">{renderPageNumbers()}</HStack>
            <IconButton
              aria-label="right"
              backgroundColor="transparent"
              border={"1px solid #e8eaf2"}
              borderRadius={"full"}
              _hover={{
                backgroundColor: "#3454D1",
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              icon={
                <FaArrowRight
                  size={15}
                  color={
                    isHovered
                      ? "#FFFFFF"
                      : pageNumber >= pageCount || pageNumber < 1
                      ? "#828ea0"
                      : "#283c50"
                  }
                  style={{ transition: "color 0.2s" }} // Add smooth transition for color change
                />
              }
              isDisabled={pageNumber >= pageCount || pageNumber < 1}
              onClick={() => {
                if (pageNumber < pageCount && pageNumber >= 1) {
                  pageNumberInputRef.current?.value &&
                    (pageNumberInputRef.current.value = String(pageNumber + 1));
                  setPageNumber(pageNumber + 1);
                }
              }}
            />
          </HStack>
        </HStack>
      </Stack>
    </Stack>
  );
}
