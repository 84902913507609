import {Text, TextProps} from '@chakra-ui/react';

export function ComponentLabel({children, ...props}: TextProps) {
  return (
    <Text
      color="gray.500"
      fontSize="13px"
      fontWeight={400}
      lineHeight={1}
      pb="8px"
      {...props}>
      {children}
    </Text>
  );
}

export function ComponentAnswer({children, ...props}: TextProps) {
  return (
    <Text
      color="textcolors.primary"
      fontSize="16px"
      fontWeight={400}
      lineHeight={1.5}
      pb="24px"
      {...props}>
      {children}
    </Text>
  );
}
