import ExcelJS from "exceljs";
import moment from "moment";

export function splitArrayIntoChunks(arr: any[]): any[][] {
  const chunkSize = 7;
  const result: any[][] = [];

  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }

  return result;
}

export function DownloadExcel(data: any, fileName: string) {
  const bufferArray = new Uint8Array(data);

  // Create a Blob from the Uint8Array
  const blob = new Blob([bufferArray], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a download link for the Blob
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName; // You can specify the filename here

  // Append the link, trigger download, and then remove the link
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  // Optionally, you can add the success toast here as needed
}

type FlattenedObject = { [key: string]: any };

export function flattenArrayObjects(
  data: any | any[]
): FlattenedObject | FlattenedObject[] {
  const flattenHelper = (obj: any, prefix = ""): FlattenedObject => {
    let flattened: FlattenedObject = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = prefix ? `${prefix}_${key}` : key;
        if (typeof obj[key] === "object" && obj[key] !== null) {
          Object.assign(flattened, flattenHelper(obj[key], newKey));
        } else {
          flattened[newKey] = obj[key];
        }
      }
    }
    return flattened;
  };

  if (Array.isArray(data)) {
    return data.map((item) => flattenHelper(item));
  } else {
    return flattenHelper(data);
  }
}
export function isDate(value: any): boolean {
  const date = new Date(value);
  return !isNaN(date.getTime());
}

// Helper function to format the date
export function formatDate(value: string): string {
  const date = moment(value).format("YYYY-MM-DD");
  return date; // You can customize the format as needed
}

export const getLocation = (successCallback: any, errorCallback: any) => {
  if (navigator.geolocation) {
    console.log("Geolocation is supported by this browser.");
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  } else {
    errorCallback(new Error("Geolocation is not supported by this browser."));
  }
};

export const fetchLocation = () => {
  return new Promise((resolve, reject) => {
    getLocation(
      (position: any) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error: any) => {
        console.error(error);
        reject(error);
      }
    );
  });
};
// function formatFieldName(fieldName: string): string {
//   // Split the fieldName based on camelCase or snake_case
//   const words = fieldName
//     .replace(/([A-Z])/g, " $1") // Add space before capital letters
//     .replace(/_/g, " ") // Replace underscores with spaces
//     .toLowerCase()
//     .split(" ");

//   // Capitalize each word
//   const formattedName = words
//     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
//     .join(" ");

//   return formattedName;
// }
// export function trackChanges(
//   fieldName: string,
//   oldValue: any,
//   newValue: any,
//   mapping: Record<string, string> = {}
// ): string | null {
//   const formattedFieldName = formatFieldName(fieldName);
//   if (Array.isArray(oldValue) && Array.isArray(newValue)) {
//     // Handle array differences
//     const oldNames = oldValue.map((id: string) => mapping[id] || id);
//     const newNames = newValue.map((id: string) => mapping[id] || id);

//     const added = newNames.filter((name) => !oldNames.includes(name));
//     const removed = oldNames.filter((name) => !newNames.includes(name));

//     let changeString = "";
//     if (added.length > 0) {
//       changeString += `Added ${formattedFieldName}: [${added.join(", ")}] `;
//     }
//     if (removed.length > 0) {
//       changeString += `Removed ${formattedFieldName}: [${removed.join(", ")}] `;
//     }

//     return changeString || null;
//   } else if (oldValue !== newValue) {
//     // Handle simple value changes
//     console.log(oldValue, newValue);
//     const oldName = mapping[oldValue] || oldValue;
//     const newName = mapping[newValue] || newValue;
//     const action = oldValue ? "UPDATE" : "ADDED";
//     return action === "UPDATE"
//       ? `${formattedFieldName} changed from '${oldName}' to '${newName}'`
//       : `Added ${formattedFieldName}: '${newName}'`;
//   }

//   return null;
// }

// export function generateStringValues(
//   dirtyFields: any,
//   initialValues: any,
//   data: any,
//   mappings: { [key: string]: Record<string, string> }
// ) {
//   let changes: string[] = [];
//   if (Object.keys(dirtyFields).length > 0) {
//     Object.keys(dirtyFields).forEach((field) => {
//       const fieldName: string = field;
//       const oldValue = initialValues[fieldName];
//       const newValue = data[fieldName];

//       const changeString = trackChanges(
//         fieldName,
//         oldValue,
//         newValue,
//         mappings[fieldName] || {}
//       );
//       if (changeString) {
//         changes.push(changeString);
//       }
//     });
//   }
//   const result = [...changes]; // Copy the changes array
//   changes.length = 0; // Clear the array
//   return result;
// }
function formatFieldName(fieldName: string): string {
  const words = fieldName
    .replace(/([A-Z])/g, " $1")
    .replace(/_/g, " ")
    .toLowerCase()
    .split(" ");

  const formattedName = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedName;
}

// function formatItem(item: any, mappings: any): string {
//   // Format item object into a human-readable string, extracting key properties like description, quantity, price
//   const itemDetails = `Description: ${item.description}, Quantity: ${item.quantity}, Price: ${item.price} `;

//   // Check for and format the related fields using the provided mappings

//   const chartAccountName =
//     mappings.chartAccountId && mappings.chartAccountId[item.accountId]
//       ? mappings.chartAccountId[item.accountId]
//       : "Unknown Account";

//   const rateTypeName =
//     mappings.rateType && mappings.rateType[item.taxRateId]
//       ? mappings.rateType[item.taxRateId]
//       : "Unknown Rate Type";

//   const productServiceName =
//     mappings.itemId && mappings.itemId[item.itemId]
//       ? mappings.itemId[item.itemId]
//       : "Unknown Product/Service";

//   // Log the IDs and their corresponding values
//   console.log("chartAccountId (ID):", item.accountId);
//   console.log(
//     "chartAccountId (Value):",
//     mappings.chartAccountId
//       ? mappings.chartAccountId[item.accountId]
//       : undefined
//   );

//   console.log("rateTypeId (ID):", item.taxRateId);
//   console.log(
//     "rateTypeId (Value):",
//     mappings.rateType ? mappings.rateType[item.taxRateId] : undefined
//   );

//   console.log("itemId (ID):", item.itemId);
//   console.log(
//     "itemId (Value):",
//     mappings.itemId ? mappings.itemId[item.itemId] : undefined
//   );
//   console.log(mappings);

//   return `Item: {${itemDetails}, Account: ${chartAccountName}, Rate Type: ${rateTypeName}, Product/Service: ${productServiceName}}`;
// }
function formatItem(item: any, mappings: any): string {
  const changes: string[] = [];
  console.log("mapping in formatItem", mappings);
  const fields = ["accountId", "taxRateId", "itemId"];

  fields.forEach((field) => {
    const oldValue = item[`old_${field}`] || undefined;
    const newValue = item[field] || undefined;

    const mappedOldValue =
      mappings && mappings[field] && mappings[field][oldValue]
        ? mappings[field][oldValue]
        : oldValue;

    const mappedNewValue =
      mappings && mappings[field] && mappings[field][newValue]
        ? mappings[field][newValue]
        : newValue;

    console.log(`Field: ${field}`);
    console.log(`Old Value: ${oldValue}, New Value: ${newValue}`);
    console.log(
      `Mapped Old Value: ${mappedOldValue}, Mapped New Value: ${mappedNewValue}`
    );

    if (oldValue !== newValue) {
      changes.push(
        `Field "${field}" changed from "${mappedOldValue || "N/A"}" to "${
          mappedNewValue || "N/A"
        }"`
      );
    }
  });

  const itemDetails = `Description: ${item.description || "N/A"}, Quantity: ${
    item.quantity || "N/A"
  }, Price: ${item.price || "N/A"}`;
  const formattedChanges = changes.length
    ? `Changes: ${changes.join("; ")}`
    : "No changes detected";

  return `Item: {${itemDetails}, ${formattedChanges}}`;
}

export function trackChanges(
  fieldName: string,
  oldValue: any,
  newValue: any,
  mapping: Record<string, any> = {}
): string | null {
  const formattedFieldName =
    fieldName.charAt(0).toUpperCase() + fieldName.slice(1);

  // Handle arrays (e.g., items)
  if (Array.isArray(oldValue) && Array.isArray(newValue)) {
    const added = newValue.filter((value) => !oldValue.includes(value));
    const removed = oldValue.filter((value) => !newValue.includes(value));
    console.log("mapping in trackChanges", mapping);
    let changeString = "";
    if (added.length > 0) {
      const formattedAdded = added
        .map((item: any) => formatItem(item, mapping))
        .join(", ");
      changeString += `Added ${formattedFieldName}: [${formattedAdded}] `;
    }
    if (removed.length > 0) {
      const formattedRemoved = removed
        .map((item: any) => formatItem(item, mapping))
        .join(", ");
      changeString += `Removed ${formattedFieldName}: [${formattedRemoved}] `;
    }
    return changeString || null;
  }

  // Handle objects
  if (typeof oldValue === "object" && typeof newValue === "object") {
    const changes: string[] = [];
    for (const key in oldValue) {
      if (oldValue[key] !== newValue[key]) {
        changes.push(
          trackChanges(
            `${formattedFieldName}.${key}`,
            oldValue[key],
            newValue[key],
            mapping
          ) || ""
        );
      }
    }
    return changes.filter(Boolean).join("; ");
  }

  // Handle primitive values
  if (oldValue !== newValue) {
    const oldMappedValue = mapping[oldValue] || oldValue;
    const newMappedValue = mapping[newValue] || newValue;
    return `${formattedFieldName} changed from '${oldMappedValue}' to '${newMappedValue}'`;
  }

  return null;
}
export function generateStringValues(
  dirtyFields: any,
  initialValues: any,
  data: any,
  mappings: { [key: string]: Record<string, string> }
): string[] {
  const changes: string[] = [];
  console.log("Mappings in generateStringValues:", mappings);

  Object.keys(dirtyFields).forEach((field) => {
    const oldValue = initialValues[field];
    const newValue = data[field];
    console.log(`Processing field: ${field}`);
    console.log(`Old Value: ${oldValue}, New Value: ${newValue}`);
    console.log(`Mapping:`, mappings[field]);

    if (field === "items") {
      // Handle nested items
      const itemChanges = trackChanges(field, oldValue, newValue, mappings);
      if (itemChanges) {
        changes.push(itemChanges);
      }
    } else {
      // Handle top-level fields
      const changeString = trackChanges(
        field,
        oldValue,
        newValue,
        mappings[field] || {}
      );
      if (changeString) {
        changes.push(changeString);
      }
    }
  });

  return changes;
}
export function loadGoogleMapsScript(callback: any) {
  if (!window.google) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=IzaSyCZ6p-O1f1OsTcmPNOEitFCQBBDML9fOr4&callback=initMap&libraries=places`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
    script.onload = () => callback();
  } else {
    callback();
  }
}
export const capitalizeFirstLetter = (text: string): string => {
  if (!text) return ""; // Return an empty string if the input is empty or null
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};
export function ReadeableFormatDate(value: string): string {
  const date = moment(value).format("DD-MM-YYYY");
  return date; // You can customize the format as needed
}
export function ReadeableFormatTimeWithoutDate(
  dateString: string,
  timeString: string
): string {
  const time = moment(`${dateString}T${timeString}`).format("hh:mm A");
  return time; // You can customize the format as needed
}
export function convertminutesToHours(totalMinutes: number): string {
  let duration = moment.duration(totalMinutes, "minutes");
  let hours = Math.floor(duration.asHours());
  let minutes = duration.minutes();
  let formattedDuration = `${hours} hr ${minutes} min`;
  return formattedDuration;
}
export function calculateDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number {
  const R: number = 6371; // Radius of the Earth in kilometers

  // Helper function to convert degrees to radians
  const rad = (deg: number): number => deg * (Math.PI / 180);

  // Calculate deltas between coordinates
  const deltaLat: number = rad(lat2 - lat1);
  const deltaLon: number = rad(lon2 - lon1);

  // Haversine formula
  const a: number =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(rad(lat1)) *
      Math.cos(rad(lat2)) *
      Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2);
  const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance: number = R * c; // Distance in kilometers

  return distance;
}
type GeofenceResult = {
  withinRange: boolean;
  distance?: number;
};
export async function isWithinGeofence(
  userLocation: { latitude: number; longitude: number },
  shift: any
): Promise<GeofenceResult> {
  if (!shift.geoFencingEnabled) return { withinRange: true };

  const geofenceCenter = shift.location.addressCoordinates;
  const distance = calculateDistance(
    geofenceCenter.latitude,
    geofenceCenter.longitude,
    userLocation.latitude,
    userLocation.longitude
  );

  if (distance > 1) {
    // Assuming a default radius of 1km
    return {
      withinRange: false,
      distance: distance, // Provide the actual distance if out of range
    };
  }

  return { withinRange: true };
}
interface FinancialYearOption {
  label: string;
  value: string;
}

interface FinancialYearSelectProps {
  financialYearEndMonth: number; // Month of the financial year end (1 = January, 12 = December)
  financialYearEndDay: number; // Day of the financial year end
}
export function getFinancialYearOptions({
  financialYearEndDay,
  financialYearEndMonth,
}: FinancialYearSelectProps) {
  const currentDate = moment();
  // Define the end date of the financial year within the current calendar year
  const currentYearFinancialYearEnd = moment().set({
    year:
      currentDate.month() <= financialYearEndMonth - 1
        ? currentDate.year()
        : currentDate.year() + 1,
    month: financialYearEndMonth - 1,
    date: financialYearEndDay,
  });
  const currentFinancialYearStartDate = moment(currentYearFinancialYearEnd)
    .subtract(1, "year")
    .add(1, "day");
  // Determine if we are in the first quarter of the financial year
  const firstQuarterEnd = currentFinancialYearStartDate
    .clone()
    .add(3, "months");
  const isFirstQuarter = currentDate.isBefore(firstQuarterEnd);

  // Determine the start date of the last financial year
  const lastFinancialYearStart = currentFinancialYearStartDate
    .clone()
    .subtract(1, "year");

  // Prepare the options array
  const options: FinancialYearOption[] = [
    {
      label: "Current Financial Year",
      value: currentFinancialYearStartDate.format("YYYY-MM-DD"),
    },
  ];

  // Add the last financial year if in the first quarter
  if (isFirstQuarter) {
    options.unshift({
      label: "Last Financial Year",
      value: lastFinancialYearStart.format("YYYY-MM-DD"),
    });
  }
  return options;
}

//Download schedule excel report utility function
// export function DownloadDynamicEmployeeScheduleReport(
//   data: any,
//   employeesList: any
// ) {
//   const dates = data.map((day: any) => day.date);

//   // Build sheet headers
//   const headers = ["Employee ID", "Employee Name", "Site", ...dates];

//   // Initialize rows as an empty array
//   const rows: any[] = [];

//   employeesList.forEach((employee: any) => {
//     const processedRows: any[] = [];

//     // Iterate over shift data to create rows for each shift
//     data.forEach((day: any) => {
//       const shiftsForDay = day.shifts.filter(
//         (shift: any) => shift.employeeId === employee.id
//       );

//       if (shiftsForDay.length > 0) {
//         shiftsForDay.forEach((shift: any) => {
//           const site = shift.location?.name || "N/A";

//           // Create a row for each shift
//           const row = Array(headers.length).fill("No Shift");
//           row[0] = employee.uniqueId; // Employee ID
//           row[1] = employee.firstName + " " + employee.lastName; // Employee Name
//           row[2] = site; // Site
//           row[3 + dates.indexOf(day.date)] = `${
//             shift.shiftStartTime || "N/A"
//           } - ${shift.shiftEndTime || "N/A"}`;

//           processedRows.push(row);
//         });
//       }
//     });

//     // Add rows for days with no shifts to ensure coverage for all dates
//     if (processedRows.length === 0) {
//       // If no shifts exist for this employee, create a single "No Shift" row
//       const noShiftRow = [
//         employee.uniqueId,
//         employee.firstName + " " + employee.lastName,
//         "N/A",
//         ...dates.map(() => "No Shift"),
//       ];
//       rows.push(noShiftRow);
//     } else {
//       // Add missing "No Shift" values for each row's empty date cells
//       processedRows.forEach((row, index) => {
//         dates.forEach((date: any, i: any) => {
//           if (!row[3 + i]) {
//             row[3 + i] = "No Shift";
//           }
//         });
//         rows.push(row);
//       });
//     }
//   });

//   // Combine headers and rows into a single sheet data array
//   const sheetData = [headers, ...rows];

//   // Check if sheetData has any rows; add a fallback if empty
//   if (sheetData.length === 0) {
//     sheetData.push(["No Data Available"]);
//   }

//   // Create worksheet and workbook
//   const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
//   const workbook = XLSX.utils.book_new();

//   // Add a custom header row above the data
//   XLSX.utils.sheet_add_aoa(
//     worksheet,
//     [
//       ["Employee Schedule Report"], // Title
//       [`From ${dates[0]} to ${dates[dates.length - 1]}`], // Date range
//       ["Craftsmen Media (Pvt) Ltd"], // Company name
//     ],
//     { origin: "A1" } // Add at the top-left corner
//   );

//   // Merge and style the title rows
//   worksheet["!merges"] = [
//     { s: { r: 0, c: 0 }, e: { r: 0, c: headers.length - 1 } }, // Merge for title
//     { s: { r: 1, c: 0 }, e: { r: 1, c: headers.length - 1 } }, // Merge for date range
//     { s: { r: 2, c: 0 }, e: { r: 2, c: headers.length - 1 } }, // Merge for company name
//   ];

//   // Style the header rows
//   const styleHeader = (cell: any) => {
//     cell.s = {
//       font: { bold: true, size: 16 },
//       alignment: { horizontal: "center", vertical: "center" },
//     };
//   };
//   ["A1", "A2", "A3"].forEach((cellRef) => {
//     if (worksheet[cellRef]) styleHeader(worksheet[cellRef]);
//   });

//   // Add headers and rows to the worksheet
//   XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A4" });
//   const headerRowNumber = 4; // The row where the headers are
//   headers.forEach((_, columnIndex) => {
//     const cellAddress = XLSX.utils.encode_cell({
//       r: headerRowNumber - 1,
//       c: columnIndex,
//     });
//     const cell = worksheet[cellAddress];
//     if (cell) {
//       cell.s = {
//         font: { bold: true },
//         alignment: { horizontal: "center", vertical: "center" },
//       };
//     }
//   });
//   XLSX.utils.sheet_add_aoa(worksheet, rows, { origin: "A5" });

//   // Append the worksheet to the workbook
//   XLSX.utils.book_append_sheet(workbook, worksheet, "Employee Schedule");

//   // Download the Excel file
//   XLSX.writeFile(workbook, "EmployeeScheduleReport.xlsx");
// }

export function DownloadDynamicEmployeeScheduleReport(
  data: any,
  employeesList: any,
  businessName: string | undefined
) {
  const dates = data.map((day: any) => moment(day.date).format("DD-MM-YYYY"));

  // Create a new workbook and worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Employee Schedule");

  // Add custom headers
  worksheet.mergeCells("A1", "G1");
  worksheet.mergeCells("A2", "G2");
  worksheet.mergeCells("A3", "G3");

  worksheet.getCell("A1").value = "Employee Schedule Report";
  worksheet.getCell("A2").value = `From ${dates[0]} to ${
    dates[dates.length - 1]
  }`;
  worksheet.getCell("A3").value = businessName ?? "Client Name";

  // Style the custom headers
  ["A1", "A2", "A3"].forEach((cellRef) => {
    const cell = worksheet.getCell(cellRef);
    cell.font = { bold: true, size: 16 }; // Bold and large font
    cell.alignment = { horizontal: "left", vertical: "middle" }; // Centered text
  });

  // Add column headers (main table headers)
  const headers = ["Employee ID", "Employee Name", "Site", ...dates];
  worksheet.addRow(headers).font = { bold: true }; // Bold font for header row

  // Fill in employee data
  employeesList.forEach((employee: any) => {
    const rowsForEmployee: any[] = [];

    // Group shifts by site and date
    const shiftsGroupedBySiteDate: {
      [site: string]: { [date: string]: string[] };
    } = {};

    data.forEach((day: any) => {
      const shiftsForDay = day.shifts.filter(
        (shift: any) => shift.employeeId === employee.id
      );

      shiftsForDay.forEach((shift: any) => {
        const site = shift.location?.name || "N/A";
        const date = moment(day.date).format("DD-MM-YYYY");
        const timing = `${shift.shiftStartTime || "N/A"} - ${
          shift.shiftEndTime || "N/A"
        }`;

        if (!shiftsGroupedBySiteDate[site]) {
          shiftsGroupedBySiteDate[site] = {};
        }
        if (!shiftsGroupedBySiteDate[site][date]) {
          shiftsGroupedBySiteDate[site][date] = [];
        }
        shiftsGroupedBySiteDate[site][date].push(timing);
      });
    });

    // Generate rows for each site and date
    if (Object.keys(shiftsGroupedBySiteDate).length === 0) {
      // If no shifts, create a single "No Shift" row for all dates
      const noShiftRow = [
        employee.uniqueId,
        employee.firstName + " " + employee.lastName,
        "N/A",
        ...dates.map(() => "No Shift"),
      ];
      worksheet.addRow(noShiftRow);
    } else {
      Object.keys(shiftsGroupedBySiteDate).forEach((site) => {
        const rowTemplate = Array(headers.length).fill("No Shift");
        rowTemplate[0] = employee.uniqueId;
        rowTemplate[1] = employee.firstName + " " + employee.lastName;
        rowTemplate[2] = site;

        // Add shift timings for each date
        dates.forEach((date: any, i: any) => {
          const shiftsForDate = shiftsGroupedBySiteDate[site][date];
          if (shiftsForDate && shiftsForDate.length > 0) {
            shiftsForDate.forEach((timing, index) => {
              if (index === 0) {
                // Add the first shift to the current row
                rowTemplate[3 + i] = timing;
              } else {
                // Create a new row for additional shifts
                const additionalRow = Array(headers.length).fill("No Shift");
                additionalRow[0] = employee.uniqueId;
                additionalRow[1] = employee.firstName + " " + employee.lastName;
                additionalRow[2] = site;
                additionalRow[3 + i] = timing;
                rowsForEmployee.push(additionalRow);
              }
            });
          }
        });

        // Add the primary row for the site
        rowsForEmployee.push([...rowTemplate]);
      });
    }

    // Add all rows for this employee to the worksheet
    rowsForEmployee.forEach((row) => worksheet.addRow(row));
  });

  // Style table headers
  worksheet.getRow(4).font = { bold: true }; // Make table headers bold

  // Adjust column widths
  worksheet.columns.forEach((column) => {
    column.width = 20; // Adjust the width of all columns
  });

  // Save the file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "EmployeeScheduleReport.xlsx";
    a.click();
    window.URL.revokeObjectURL(url);
  });
}

export function DownloadDynamicEmployeeHourlyScheduleReport(
  data: any,
  employeesList: any,
  businessName: string | undefined
) {
  const dates = data.map((day: any) => moment(day.date).format("DD-MM-YYYY"));

  // Create a new workbook and worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Employee Hourly Schedule");

  // Add custom headers
  worksheet.mergeCells("A1", "G1");
  worksheet.mergeCells("A2", "G2");
  worksheet.mergeCells("A3", "G3");

  worksheet.getCell("A1").value = "Employee Hourly Schedule Report";
  worksheet.getCell("A2").value = `From ${dates[0]} to ${
    dates[dates.length - 1]
  }`;
  worksheet.getCell("A3").value = businessName ?? "Client Name";

  // Style the custom headers
  ["A1", "A2", "A3"].forEach((cellRef) => {
    const cell = worksheet.getCell(cellRef);
    cell.font = { bold: true, size: 16 }; // Bold and large font
    cell.alignment = { horizontal: "left", vertical: "middle" }; // Centered text
  });

  // Add column headers (main table headers)
  const headers = ["Employee ID", "Employee Name", "Site", ...dates];
  worksheet.addRow(headers).font = { bold: true }; // Bold font for header row

  // Fill in employee data
  employeesList.forEach((employee: any) => {
    const rowsForEmployee: any[] = [];

    // Group shifts by site and date
    const shiftsGroupedBySiteDate: {
      [site: string]: { [date: string]: string[] };
    } = {};

    data.forEach((day: any) => {
      const shiftsForDay = day.shifts.filter(
        (shift: any) => shift.employeeId === employee.id
      );

      shiftsForDay.forEach((shift: any) => {
        const calculatedDuration = calculateDuration(
          shift.shiftStartDate,
          shift.shiftEndDate,
          shift.shiftStartTime,
          shift.shiftEndTime
        );
        const site = shift.location?.name || "N/A";
        const date = moment(day.date).format("DD-MM-YYYY");
        const timing = calculatedDuration;

        if (!shiftsGroupedBySiteDate[site]) {
          shiftsGroupedBySiteDate[site] = {};
        }
        if (!shiftsGroupedBySiteDate[site][date]) {
          shiftsGroupedBySiteDate[site][date] = [];
        }
        shiftsGroupedBySiteDate[site][date].push(timing);
      });
    });

    // Generate rows for each site and date
    if (Object.keys(shiftsGroupedBySiteDate).length === 0) {
      // If no shifts, create a single "No Shift" row for all dates
      const noShiftRow = [
        employee.uniqueId,
        employee.firstName + " " + employee.lastName,
        "N/A",
        ...dates.map(() => "No Shift"),
      ];
      worksheet.addRow(noShiftRow);
    } else {
      Object.keys(shiftsGroupedBySiteDate).forEach((site) => {
        const rowTemplate = Array(headers.length).fill("No Shift");
        rowTemplate[0] = employee.uniqueId;
        rowTemplate[1] = employee.firstName + " " + employee.lastName;
        rowTemplate[2] = site;

        // Add shift timings for each date
        dates.forEach((date: any, i: any) => {
          const shiftsForDate = shiftsGroupedBySiteDate[site][date];
          if (shiftsForDate && shiftsForDate.length > 0) {
            shiftsForDate.forEach((timing, index) => {
              if (index === 0) {
                // Add the first shift to the current row
                rowTemplate[3 + i] = timing;
              } else {
                // Create a new row for additional shifts
                const additionalRow = Array(headers.length).fill("No Shift");
                additionalRow[0] = employee.uniqueId;
                additionalRow[1] = employee.firstName + " " + employee.lastName;
                additionalRow[2] = site;
                additionalRow[3 + i] = timing;
                rowsForEmployee.push(additionalRow);
              }
            });
          }
        });

        // Add the primary row for the site
        rowsForEmployee.push([...rowTemplate]);
      });
    }

    // Add all rows for this employee to the worksheet
    rowsForEmployee.forEach((row) => worksheet.addRow(row));
  });

  // Style table headers
  worksheet.getRow(4).font = { bold: true }; // Make table headers bold

  // Adjust column widths
  worksheet.columns.forEach((column) => {
    column.width = 20; // Adjust the width of all columns
  });

  // Save the file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "EmployeeHourlyScheduleReport.xlsx";
    a.click();
    window.URL.revokeObjectURL(url);
  });
}

// calculate total duration from start time and end time
export function calculateDuration(
  startDate: string,
  endDate: string,
  startTime: string,
  endTime: string
) {
  // Combine date and time for start and end
  const startDateTime = moment(
    `${startDate} ${startTime}`,
    "YYYY-MM-DD h:mm A"
  );
  const endDateTime = moment(`${endDate} ${endTime}`, "YYYY-MM-DD h:mm A");

  // Calculate the duration in minutes and convert to hours with 2 decimal places
  const durationInMinutes = moment
    .duration(endDateTime.diff(startDateTime))
    .asMinutes();
  const durationInHours = (durationInMinutes / 60).toFixed(2);

  return durationInHours; // Return the duration in hours
}
