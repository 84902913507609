import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Divider,
  HStack,
  Icon,
  Stack,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
export function AuditorDashboard() {
  const { currentBusinessId } = useBusinessContext();

  // const DashboardOptions: Required<NavBarProps['menu']> = [
  //   {
  //     Icon: FiGitPullRequest,
  //     title: 'Templates',
  //     subMenu: [
  //       {
  //         title: 'All Templates',
  //         path: '/app/auditor/templates',
  //         accessLevel: ['ROLE_ADMIN', 'ROLE_EMPLOYER', 'ROLE_SUPERVISOR'],
  //         desc: 'Manage Inpsection Templates',
  //         icon: HiTemplate,
  //       },
  //     ],
  //     moduleName: 'AUDITOR',
  //     baseURL: process.env.REACT_APP_AUDITOR_URL,
  //   },
  //   {
  //     Icon: FiGitPullRequest,
  //     title: 'Inspections',
  //     subMenu: [
  //       {
  //         title: 'All Inspections',
  //         path: '/app/auditor/inspections',
  //         accessLevel: [
  //           'ROLE_ADMIN',
  //           'ROLE_CLIENT',
  //           'ROLE_EMPLOYEE',
  //           'ROLE_EMPLOYER',
  //           'ROLE_SUPERVISOR',
  //         ],
  //         desc: 'Manage Inspections',
  //         icon: FaClipboardCheck,
  //       },
  //     ],
  //     moduleName: 'AUDITOR',
  //     baseURL: process.env.REACT_APP_AUDITOR_URL,
  //   },
  //   {
  //     Icon: FiGitPullRequest,
  //     title: 'Schedule',
  //     subMenu: [
  //       {
  //         title: 'Manage Schedule',
  //         path: '/app/auditor/schedule_inspection',
  //         accessLevel: [
  //           'ROLE_ADMIN',
  //           'ROLE_CLIENT',
  //           'ROLE_EMPLOYEE',
  //           'ROLE_EMPLOYER',
  //         ],
  //         desc: 'Manage Schedule for Inspections',
  //         icon: FaClipboardCheck,
  //       },
  //     ],
  //     moduleName: 'AUDITOR',
  //     baseURL: process.env.REACT_APP_AUDITOR_URL,
  //   },
  //   {
  //     Icon: FiGitPullRequest,
  //     title: 'Notication',
  //     subMenu: [
  //       {
  //         title: 'Manage Notifications',
  //         path: '/app/auditor/notification',
  //         accessLevel: [
  //           'ROLE_ADMIN',
  //           'ROLE_CLIENT',
  //           'ROLE_EMPLOYEE',
  //           'ROLE_EMPLOYER',
  //         ],
  //         desc: 'Manage notifications setting',
  //         icon: FaClipboardCheck,
  //       },
  //     ],
  //     moduleName: 'AUDITOR',
  //     baseURL: process.env.REACT_APP_AUDITOR_URL,
  //   },
  // ];

  return (
    <Box>
      <Stack justifyContent={"space-between"} h={"100%"} w={"100%"} padding={5}>
        <Breadcrumb
          spacing="8px"
          separator={<Icon as={FiChevronRight} color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="#" fontSize={"13px"}>
              Pages
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="#" fontSize={"13px"}>
              Auditor Dashboard
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Text fontSize={"20px"} fontWeight={"bold"}>
          Welcome to Auditor Dashboard
        </Text>

        <Container
          maxW="full"
          as={HStack}
          justify="space-around"
          borderColor="border.primary"
          borderWidth="1px"
          boxShadow="card"
          p="10px"
        >
          <VStack align="flex-start">
            {/* <Heading>Welcome to {business?.businessName}</Heading> */}
            <Text color="gray">
              This is your portal. Utilise it to manage your services providers
              and know what is being delivered at your sites.
            </Text>
          </VStack>
          {/* <Image src={business?.logoPath} boxSize="200px" objectFit="contain" /> */}
        </Container>

        <Divider
          style={{
            marginTop: "20px",
            marginBottom: "20px",
          }}
        />

        <Wrap spacingX="5" w="100%">
          {/* {DashboardOptions.map((menu, i) => (
            <WrapItem
              w={{
                lg: `calc(100% / 4 - 20px)`,
                md: `calc(100% / 2 - 20px)`,
                xs: '100%',
              }}
              key={menu.title}>
              <NavContainer key={i} wrapperProps={{w: '100%'}} menu={menu} />
            </WrapItem>
          ))} */}
        </Wrap>

        {/* <Stack
              direction={{
                xs: 'column',
                sm: 'column',
                md: 'column',
                lg: 'column',
                xl: 'row'
              }}
            >
              <Box width={{ md: '100%', xl: '60%' }} backgroundColor="white">
                <Card
                  className="card-hover"
                  border={'1px solid'}
                  borderColor={'#e6e7efd9'}
                  borderRadius={0}
                >
                  <CardHeader>
                    <Heading size={'sm'}>
                      <HStack w={'100%'} justifyContent={'space-between'}>
                        <HStack>
                          <Text
                            color={'#06072d'}
                            fontWeight={500}
                            fontSize={'14px'}
                          >
                            Top Sales Representative
                          </Text>
                        </HStack>
                        <HStack fontSize={'14px'} fontWeight={500}>
                          <FiSave className="changecolor" />
                          <FiPrinter className="changecolor" />
                          <FiMoreHorizontal className="changecolor" />
                        </HStack>
                      </HStack>
                    </Heading>
                  </CardHeader>
                  <CardBody padding={0}>
                    <TableContainer w={'100%'}>
                      <Table variant={'simple'} w={'100%'}>
                        <Thead w={'100%'}>
                          <Tr
                            borderTop={'1px solid'}
                            backgroundColor={'#f9f9fd'}
                            borderColor={'#e6e7efd9'}
                          >
                            <Th
                              w={'35%'}
                              padding={{
                                sm: '8px 10px 8px 20px',
                                md: '8px 0px 8px 20px'
                              }}
                              color={'#373857'}
                              fontSize={'12px'}
                              fontWeight={500}
                              borderBottom={'1px solid'}
                              borderColor={'#e6e7efd9'}
                            >
                              Name
                            </Th>
                            <Th
                              w={'15%'}
                              padding={{ sm: '8px 10px', md: '8px 0px' }}
                              textAlign={'end'}
                              color={'#373857'}
                              fontSize={'12px'}
                              fontWeight={500}
                              borderBottom={'1px solid'}
                              borderColor={'#e6e7efd9'}
                            >
                              No. Of Wins
                            </Th>
                            <Th
                              w={'25%'}
                              padding={{ sm: '8px 10px', md: '8px 0px' }}
                              textAlign={'end'}
                              color={'#373857'}
                              fontSize={'12px'}
                              fontWeight={500}
                              borderBottom={'1px solid'}
                              borderColor={'#e6e7efd9'}
                            >
                              Win Rate
                            </Th>
                            <Th
                              w={'25%'}
                              padding={{
                                sm: '8px 20px 8px 10px',
                                md: '8px 20px 8px 0px'
                              }}
                              textAlign={'end'}
                              color={'#373857'}
                              fontSize={'12px'}
                              fontWeight={500}
                              borderBottom={'1px solid'}
                              borderColor={'#e6e7efd9'}
                            >
                              Won Revenvue ($)
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody w={'100%'}>
                          {TableList2.map((menu, i) => {
                            return (
                              <Fragment key={i}>
                                <Tr w={'100%'}>
                                  <Td
                                    padding={{
                                      sm: '8px 10px 8px 20px',
                                      md: '10px 0px 10px 20px'
                                    }}
                                    borderBottom={'1px solid'}
                                    borderColor={'#e6e7efd9'}
                                  >
                                    <HStack
                                      w={'100%'}
                                      justifyContent={'space-between'}
                                    >
                                      <HStack align="center">
                                        <Avatar size="sm" name={'Danish Khowaja'} />
                                        <Text
                                          fontWeight="500"
                                          fontSize="13px"
                                          color={'#373857'}
                                        >
                                          {menu.title}
                                        </Text>
                                      </HStack>
                                    </HStack>
                                  </Td>
                                  <Td
                                    padding={{ sm: '8px 10px', md: '10px 0px' }}
                                    fontSize={'13px'}
                                    fontWeight={400}
                                    textAlign={'end'}
                                    color={'#575d78'}
                                    borderBottom={'1px solid'}
                                    borderColor={'#e6e7efd9'}
                                  >
                                    {menu.wins}
                                  </Td>
                                  <Td
                                    padding={{ sm: '8px 10px', md: '10px 0px' }}
                                    borderBottom={'1px solid'}
                                    borderColor={'#e6e7efd9'}
                                  >
                                    <HStack
                                      fontSize={'13px'}
                                      justifyContent={'right'}
                                      spacing={0}
                                      color={'#575d78'}
                                      fontWeight={400}
                                    >
                                      <Text mr={'3px'}>{menu.percentage}</Text>
                                      <FiStar fill={'#fdb92c'} strokeWidth={0} />
                                      <FiStar fill={'#fdb92c'} strokeWidth={0} />
                                      <FiStar fill={'#fdb92c'} strokeWidth={0} />
                                      <FiStar fill={'#fdb92c'} strokeWidth={0} />
                                      <FiStar fill={'#fdb92c'} strokeWidth={0} />
                                    </HStack>
                                  </Td>
                                  <Td
                                    padding={{
                                      sm: '8px 20px 8px 10px',
                                      md: '10px 20px 10px 0px'
                                    }}
                                    fontSize={'13px'}
                                    fontWeight={400}
                                    textAlign={'end'}
                                    color={'#575d78'}
                                    borderBottom={'1px solid'}
                                    borderColor={'#e6e7efd9'}
                                  >
                                    {menu.revenue}
                                  </Td>
                                </Tr>
                              </Fragment>
                            );
                          })}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </CardBody>
                  <CardFooter padding={'12px 20px'}>
                    <a href="#" className={'cardfooter'}>
                      <HStack spacing={0}>
                        <Text fontSize={'12px'} fontWeight={500} marginEnd={'2px'}>
                          Show More 5
                        </Text>
                        <FiChevronRight fontSize={'14px'} fontWeight={500} />
                      </HStack>
                    </a>
                  </CardFooter>
                </Card>
              </Box>
              <Box width={{ md: '100%', xl: '40%' }} backgroundColor="white">
                <Card
                  className="card-hover"
                  border={'1px solid'}
                  borderColor={'#e6e7efd9'}
                  borderRadius={0}
                >
                  <CardHeader
                    padding={'20px'}
                    borderBottom={'1px solid'}
                    borderColor={'#e6e7efd9'}
                  >
                    <Heading size={'sm'}>
                      <HStack w={'100%'} justifyContent={'space-between'}>
                        <HStack>
                          <Text
                            color={'#06072d'}
                            fontSize={'14px'}
                            fontWeight={500}
                          >
                            Recent Transactions
                          </Text>
                        </HStack>
                        <HStack fontSize={'14px'} fontWeight={500}>
                          <FiDownload className="changecolor" />
                          <FiPrinter className="changecolor" />
                          <FiMoreHorizontal className="changecolor" />
                        </HStack>
                      </HStack>
                    </Heading>
                  </CardHeader>
                  <CardBody padding={0}>
                    <TableContainer>
                      <Table variant={'simple'}>
                        <Tbody>
                          {TableList3.map((menu, i) => {
                            return (
                              <Fragment key={i}>
                                <Tr w={'100%'}>
                                  <Td
                                    padding={'12px 20px'}
                                    borderBottom={'1px solid'}
                                    borderColor={'#e6e7efd9'}
                                    borderStyle={'dashed'}
                                  >
                                    <HStack
                                      w={'100%'}
                                      justifyContent={'space-between'}
                                    >
                                      <HStack align="center">
                                        <Avatar size="sm" name={'Danish Khowaja'} />
                                        <VStack alignItems="flex-start" spacing={0}>
                                          <Text
                                            fontSize={'13px'}
                                            fontWeight={500}
                                            color={'#373857'}
                                          >
                                            {menu.receipt}
                                          </Text>
                                          <Text
                                            color={'#37385799'}
                                            fontSize={'12px'}
                                            fontWeight={400}
                                          >
                                            {menu.date}
                                          </Text>
                                        </VStack>
                                      </HStack>
                                      <VStack spacing={0} align={'end'}>
                                        <Text
                                          color={'#373857'}
                                          fontSize={'13px'}
                                          fontWeight={500}
                                        >
                                          {menu.price}
                                        </Text>
                                        <Text
                                          color={'#37385799'}
                                          fontSize={'12px'}
                                          fontWeight={400}
                                        >
                                          {menu.source}
                                        </Text>
                                      </VStack>
                                    </HStack>
                                  </Td>
                                </Tr>
                              </Fragment>
                            );
                          })}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </CardBody>
                  <CardFooter
                    padding={'12px 20px'}
                    borderTop={'1px solid'}
                    borderColor={'#e6e7efd9'}
                    m={'auto'}
                  >
                    <a href="#" className={'cardfooter'}>
                      <HStack spacing={0}>
                        <Text fontSize={'12px'} fontWeight={500} marginEnd={'2px'}>
                          View All Trasactions
                        </Text>
                        <FiChevronRight fontSize={'14px'} fontWeight={500} />
                      </HStack>
                    </a>
                  </CardFooter>
                </Card>
              </Box>
            </Stack> */}
      </Stack>
    </Box>
  );
}
