import { Container, Stack, useBoolean } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Template } from "../../../modules/Audit";
import { getFieldKey } from "../../../modules/form-apis";
import { ReduceTotalPageScoreObject } from "../types";
import AuditItemActions, { AddNote } from "./AuditItemActions";
import MediaGallery from "./MediaGallery";
import { QuestionTitle } from "./QuestionTitle";

type useBooleanType = readonly [
  boolean,
  {
    on: () => void;
    off: () => void;
    toggle: () => void;
  }
];
interface AuditQuestionContainerContextProps {
  notesEditorOperations?: useBooleanType;
  createActionOperations?: useBooleanType;
  questionIndex?: number;
  sectionIndex?: number;
  pageIndex: number;
  getItemField: (extendedKey?: string) => string;
  question: string;
  format?: Template.QuestionFormat;
  required: boolean;
  score?: number;
  onScoreChanged?: (score: number, previousScore?: number) => void;
  onReducePageScore: (
    item: ReduceTotalPageScoreObject,
    operation: "ADD" | "REMOVE"
  ) => void;
}
const AuditQuestionContainerContext =
  React.createContext<AuditQuestionContainerContextProps>({
    pageIndex: 0,
    questionIndex: 0,
    sectionIndex: undefined,
    getItemField: () => {
      return "";
    },
    question: "",
    required: false,
    onReducePageScore: () => {},
  });

interface AuditQuestionContainerProps {
  children: React.ReactNode;
  questionIndex?: number;
  sectionIndex?: number;
  pageIndex: number;
  isRequired?: boolean;
  question: string;
  removeExtraOption?: boolean;
  removeQuestion?: boolean;
  score?: number;
  onScoreChanged?: (score: number, previousScore?: number) => void;
  removeMediaOption?: boolean;
  haveNotes: boolean;
  onReducePageScore: (
    item: ReduceTotalPageScoreObject,
    operation: "ADD" | "REMOVE"
  ) => void;
}
export default function AuditQuestionContainer({
  children,
  pageIndex,
  questionIndex,
  sectionIndex,
  isRequired,
  question,
  removeExtraOption,
  removeQuestion,
  score,
  onScoreChanged,
  removeMediaOption,
  haveNotes,
  onReducePageScore,
}: AuditQuestionContainerProps) {
  const notesEditorOperations = useBoolean(haveNotes ?? false);
  const createActionOperations = useBoolean(false);

  // function getItemField(extendedKey?: string) {
  //   if (extendedKey) {
  //     return sectionIndex !== undefined
  //       ? `templateItems.${pageIndex}.children.${sectionIndex}.children.${questionIndex}.${extendedKey}`
  //       : `templateItems.${pageIndex}.children.${questionIndex}.${extendedKey}`;
  //   }
  //   return sectionIndex !== undefined
  //     ? `templateItems.${pageIndex}.children.${sectionIndex}.children.${questionIndex}`
  //     : `templateItems.${pageIndex}.children.${questionIndex}`;
  // }

  function getItemField(extendedKey?: string) {
    return getFieldKey({ pageIndex, questionIndex, sectionIndex }, extendedKey);
  }

  const template = useFormContext();
  const format: Template.QuestionFormat = useWatch({
    control: template.control,
    name: getItemField("question.format"),
  });

  const answer: string | undefined = useWatch({
    control: template.control,
    name: getItemField("question.questionValue"),
  });

  return (
    <Container
      maxW="full"
      border="1px solid"
      borderColor="borders.accent"
      borderRadius="12px"
      p="24px"
      position="relative"
      _before={
        isRequired && (answer?.toString()?.length ?? 0) === 0
          ? {
              content: '""',
              transition: "all 0.3s ease 0s",
              position: "absolute",
              height: "calc(100% - 0.25rem)",
              top: "0.125rem",
              left: "-0.0625rem",
              borderTopLeftRadius: "0.75rem",
              borderBottomLeftRadius: "0.75rem",
              borderLeft: "0.25rem solid rgb(168, 36, 42)",
            }
          : undefined
      }
    >
      <AuditQuestionContainerContext.Provider
        value={{
          notesEditorOperations,
          pageIndex,
          questionIndex,
          sectionIndex,
          createActionOperations,
          getItemField,
          question,
          format,
          required: isRequired ?? false,
          score,
          onScoreChanged,
          onReducePageScore,
        }}
      >
        {!removeQuestion && <QuestionTitle question={question} />}
        {children}
        {!removeExtraOption && (
          <Fragment>
            <Stack w="100%" mt="20px">
              <MediaGallery />
              {notesEditorOperations?.[0] && <AddNote />}
              {/* <CreatedAction /> */}
              {/* {createActionOperations?.[0] && <CreateAction />} */}
            </Stack>
            <AuditItemActions noMediaOption={removeMediaOption} />
          </Fragment>
        )}
      </AuditQuestionContainerContext.Provider>
    </Container>
  );
}

export function useAuditQuestionContainer() {
  return React.useContext<AuditQuestionContainerContextProps>(
    AuditQuestionContainerContext
  );
}
