import { axiosInstance } from "./axiosInstance-hris";

declare module ClearanceFormApi {
  type AddClearanceClaim = {
    clientCorrespondenceId?: string;
    status?: string;
    attachment: string;
    comment: string;
    deductions: Array<clearanceComponents>;
  };

  type clearanceComponents = {
    deduction: string;
    amount: number;
  };
}
module ClearanceFormApi {
  const API_URL = `/employee-separations`;
  export function NewClearanceAdded(
    clearanceId: string,
    data: AddClearanceClaim,

    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/clearances/${clearanceId}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
}
export { ClearanceFormApi };
