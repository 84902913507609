import {
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { Employees } from "../../Api/Employees";
import { Roaster } from "./ScheduleTabs/Roaster";
import ShiftsAssigned from "./ScheduleTabs/ShiftsAssigned";
import ShiftsRequest from "./ScheduleTabs/ShiftsRequest";
interface DetailsProps {
  employeeData: Employees.EmployeeAllDetail | undefined;
}
export function Schedule({ employeeData }: DetailsProps) {
  return (
    <Stack minH={"100px"} gap={0} marginTop={"5px"}>
      <Tabs isLazy orientation="horizontal">
        <TabList justifyContent={"flex-end"}>
          <SimpleGrid columns={3}>
            <Tab
              fontSize={"13.44px"}
              padding={"20px 30px"}
              fontWeight={"600"}
              _light={{ color: "#283c50" }}
              _selected={{
                _light: { color: "#3454d1" },
                fontWeight: 600,
                bg: "rgba(52,84,209,.075)",
                borderBottom: "3px solid #3454d1",
              }}
              _hover={{ _light: { color: "#3454d1" }, fontWeight: 600 }}
            >
              Roaster
            </Tab>
            <Tab
              fontSize={"13.44px"}
              padding={"20px 30px"}
              fontWeight={"600"}
              _light={{ color: "#283c50" }}
              _selected={{
                _light: { color: "#3454d1" },
                fontWeight: 600,
                bg: "rgba(52,84,209,.075)",
                borderBottom: "3px solid #3454d1",
              }}
              _hover={{ _light: { color: "#3454d1" }, fontWeight: 600 }}
            >
              Shifts Requests
            </Tab>
            <Tab
              fontSize={"13.44px"}
              padding={"20px 30px"}
              fontWeight={"600"}
              _light={{ color: "#283c50" }}
              _selected={{
                _light: { color: "#3454d1" },
                fontWeight: 600,
                bg: "rgba(52,84,209,.075)",
                borderBottom: "3px solid #3454d1",
              }}
              _hover={{
                _light: { color: "#3454d1" },
                fontWeight: 600,
              }}
            >
              Shifts Assigned
            </Tab>
          </SimpleGrid>
        </TabList>

        <TabPanels>
          <TabPanel p={0}>
            <Roaster
              department={employeeData?.employeeEmployementDetails?.department}
            />
          </TabPanel>
          <TabPanel p={0}>
            <ShiftsRequest />
          </TabPanel>
          <TabPanel p={0}>
            <ShiftsAssigned />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
