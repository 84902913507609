import {
  Box,
  Checkbox,
  HStack,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Fragment } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FiChevronRight } from "react-icons/fi";
import { IoMdInformationCircle } from "react-icons/io";
import { ReportLayout } from "../../../../../modules/Audit/ReportLayout";
import { useTemplateReport } from "../../report";
import ActionDrawer from "../Drawers/ActionDrawer";
import Footer from "../Layouts/Footer";
import Header from "../Layouts/Header";
import CoverImage from "../Layouts/ImagesUploading/CoverImage";
import LogoImage from "../Layouts/ImagesUploading/LogoImage";
interface DisplayProps {
  isDrawer?: boolean;
}
export default function Display({ isDrawer }: DisplayProps) {
  const ActionDrawerHook = useDisclosure();
  const { setDisplayedComponent } = useTemplateReport();
  const form = useFormContext<ReportLayout.ReportLayout>();
  return (
    <Fragment>
      <ActionDrawer
        isOpen={ActionDrawerHook.isOpen}
        onClose={ActionDrawerHook.onClose}
        placement={isDrawer ? "right" : "left"}
      />
      <VStack spacing={0}>
        <VStack align="start" w="100%" px="24px" pt="24px">
          <HStack>
            <Text fontSize="13px" fontWeight={400} color="icons.primary">
              Cover Page
            </Text>
            <IoMdInformationCircle size={18} color="#3f495a" />
          </HStack>
          {/* <Flex
            w={'100%'}
            h={'88px'}
            alignItems={'center'}
            justifyContent={'center'}
            borderWidth="1px"
            borderStyle="dashed"
            borderColor="#abb5c4"
            borderRadius={'12px'}
            backgroundColor={'backgrounds.primary'}>
            <HStack
              w="100%"
              spacing={1}
              justifyContent="center"
              color="#828ea0"
              fontSize="14px">
              <AiOutlineCloudUpload color="#828ea0" size={30} />
              <Text>Drag your file here or</Text>
              <Link color="textcolors.tertiary">browse</Link>
            </HStack>
          </Flex> */}
          <Box
            w={"100%"}
            h={"100px"}
            alignItems={"center"}
            justifyContent={"center"}
            borderWidth="1px"
            borderStyle="dashed"
            borderColor="borders.accent"
            borderRadius={"12px"}
            backgroundColor={"white"}
            role="group"
          >
            <CoverImage />
          </Box>
        </VStack>
        <VStack align="start" w="100%" p="24px">
          <HStack>
            <Text fontSize="13px" fontWeight={400} color="icons.primary">
              Logo
            </Text>
            <IoMdInformationCircle size={18} color="#3f495a" />
          </HStack>
          {/* <Flex
            w={'100%'}
            h={'88px'}
            alignItems={'center'}
            justifyContent={'center'}
            borderWidth="1px"
            borderStyle="dashed"
            borderColor="#abb5c4"
            borderRadius={'12px'}
            backgroundColor={'white'}>
            <HStack
              w="100%"
              spacing={1}
              justifyContent="center"
              color="#828ea0"
              fontSize="14px">
              <AiOutlineCloudUpload color="#1da5d4" size={30} />
              <Text>Drag your file here or</Text>
              <Link>browse</Link>
            </HStack>
          </Flex> */}
          <Box
            w={"100%"}
            h={"100px"}
            alignItems={"center"}
            justifyContent={"center"}
            borderWidth="1px"
            borderStyle="dashed"
            borderColor="borders.accent"
            borderRadius={"12px"}
            backgroundColor={"white"}
            role="group"
          >
            <LogoImage />
          </Box>
        </VStack>
        <VStack w="100%" align="start" spacing={0}>
          <Text
            px="24px"
            fontSize="13px"
            fontWeight={400}
            color="icons.primary"
          >
            Content
          </Text>
          <Stack
            w="100%"
            direction="row"
            px="24px"
            py="8px"
            justifyContent={"space-between"}
            alignItems="center"
            _hover={{
              backgroundColor: "primary.50",
              borderRadius: "12px",
            }}
          >
            <Controller
              control={form.control}
              name="layout.flaggedItems"
              render={({
                field: { value, ...field },
                fieldState: { error },
              }) => (
                <Checkbox
                  {...field}
                  isInvalid={error !== undefined}
                  isChecked={value}
                  spacing={4}
                >
                  Flagged items
                </Checkbox>
              )}
            />

            {/* <IconButton
              aria-label=""
              icon={<FiChevronRight size={24} color="#3f495a" />}
              _hover={{backgroundColor: '#bfc6d4'}}
              borderRadius="50%"
              backgroundColor="transparent"
              onClick={() => {
                // ActionDrawerHook.onOpen();
                setDisplayedComponent(<FlaggedItems />);
              }}
            /> */}
          </Stack>
          <Stack
            w="100%"
            direction="row"
            px="24px"
            py="8px"
            justifyContent={"space-between"}
            alignItems="center"
            _hover={{
              backgroundColor: "primary.50",
              borderRadius: "12px",
            }}
          >
            <Controller
              control={form.control}
              name="layout.action"
              render={({
                field: { value, ...field },
                fieldState: { error },
              }) => (
                <Checkbox
                  {...field}
                  isInvalid={error !== undefined}
                  isChecked={value}
                  spacing={4}
                >
                  Actions
                </Checkbox>
              )}
            />

            {/* <IconButton
              aria-label=""
              icon={<FiChevronRight size={24} color="#3f495a" />}
              _hover={{backgroundColor: '#bfc6d4'}}
              borderRadius="50%"
              backgroundColor="transparent"
              onClick={() => {
                // ActionDrawerHook.onOpen();
                setDisplayedComponent(<Actions />);
              }}
            /> */}
          </Stack>
          <Stack
            w="100%"
            px="24px"
            py="8px"
            _hover={{
              backgroundColor: "primary.50",
              borderRadius: "12px",
            }}
          >
            <Controller
              control={form.control}
              name="layout.disclaimer"
              render={({
                field: { value, ...field },
                fieldState: { error },
              }) => (
                <Checkbox
                  {...field}
                  isInvalid={error !== undefined}
                  isChecked={value}
                  spacing={4}
                >
                  Disclaimer
                </Checkbox>
              )}
            />
          </Stack>
          <Stack
            w="100%"
            px="24px"
            py="8px"
            _hover={{
              backgroundColor: "primary.50",
              borderRadius: "12px",
            }}
          >
            <Controller
              control={form.control}
              name="layout.inspectionResults"
              render={({
                field: { value, ...field },
                fieldState: { error },
              }) => (
                <Checkbox
                  {...field}
                  isInvalid={error !== undefined}
                  isChecked={value}
                  spacing={4}
                >
                  Inspection results
                </Checkbox>
              )}
            />
          </Stack>
          <Stack
            w="100%"
            direction="row"
            px="24px"
            py="8px"
            justifyContent={"space-between"}
            alignItems="center"
            _hover={{
              backgroundColor: "primary.50",
              borderRadius: "12px",
            }}
          >
            <Controller
              control={form.control}
              name="layout.mediaSummary"
              render={({
                field: { value, ...field },
                fieldState: { error },
              }) => (
                <Checkbox
                  {...field}
                  isInvalid={error !== undefined}
                  isChecked={value}
                  spacing={4}
                >
                  Media summary
                </Checkbox>
              )}
            />

            <Tooltip
              borderRadius="8px"
              p="8px"
              hasArrow
              label="All photos captured within the inspection will be attached at the end of the report."
              placement="left"
              fontSize="xs"
            >
              <IconButton
                aria-label=""
                icon={<IoMdInformationCircle size={18} color="#3f495a" />}
                _hover={{ backgroundColor: "transparent" }}
                borderRadius="50%"
                backgroundColor="transparent"
              />
            </Tooltip>
          </Stack>
          <Stack
            w="100%"
            px="24px"
            py="8px"
            _hover={{
              backgroundColor: "primary.50",
              borderRadius: "12px",
            }}
          >
            <Controller
              control={form.control}
              name="layout.scores"
              render={({
                field: { value, ...field },
                fieldState: { error },
              }) => (
                <Checkbox
                  {...field}
                  isInvalid={error !== undefined}
                  isChecked={value}
                  spacing={4}
                >
                  Scores
                </Checkbox>
              )}
            />
          </Stack>
          <Stack
            w="100%"
            px="24px"
            py="8px"
            _hover={{
              backgroundColor: "primary.50",
              borderRadius: "12px",
            }}
          >
            <Controller
              control={form.control}
              name="layout.questionDivider"
              render={({
                field: { value, ...field },
                fieldState: { error },
              }) => (
                <Checkbox
                  {...field}
                  isInvalid={error !== undefined}
                  isChecked={value}
                  spacing={4}
                >
                  Divider
                </Checkbox>
              )}
            />
          </Stack>
          <Stack
            w="100%"
            px="24px"
            py="8px"
            _hover={{
              backgroundColor: "primary.50",
              borderRadius: "12px",
            }}
          >
            <Controller
              control={form.control}
              name="layout.instructions"
              render={({
                field: { value, ...field },
                fieldState: { error },
              }) => (
                <Checkbox
                  {...field}
                  isInvalid={error !== undefined}
                  isChecked={value}
                  spacing={4}
                >
                  Instructions
                </Checkbox>
              )}
            />
          </Stack>
          <Stack
            w="100%"
            px="24px"
            py="8px"
            _hover={{
              backgroundColor: "primary.50",
              borderRadius: "12px",
            }}
          >
            <Controller
              control={form.control}
              name="layout.unansweredQuestions"
              render={({
                field: { value, ...field },
                fieldState: { error },
              }) => (
                <Checkbox
                  {...field}
                  isInvalid={error !== undefined}
                  isChecked={value}
                  spacing={4}
                >
                  Notes
                </Checkbox>
              )}
            />
          </Stack>
          <Stack
            w="100%"
            direction="row"
            px="24px"
            py="8px"
            justifyContent={"space-between"}
            alignItems="center"
            _hover={{
              backgroundColor: "primary.50",
              borderRadius: "12px",
            }}
          >
            <Controller
              control={form.control}
              name="layout.checkboxes"
              render={({
                field: { value, ...field },
                fieldState: { error },
              }) => (
                <Checkbox
                  {...field}
                  isInvalid={error !== undefined}
                  isChecked={value}
                  spacing={4}
                >
                  Checkboxes
                </Checkbox>
              )}
            />

            {/* <IconButton
              aria-label=""
              icon={<FiChevronRight size={24} color="#3f495a" />}
              _hover={{backgroundColor: '#bfc6d4'}}
              borderRadius="50%"
              backgroundColor="transparent"
              onClick={() => {
                setDisplayedComponent(<CheckBoxes />);
              }}
            /> */}
          </Stack>
          <Stack
            w="100%"
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            px="24px"
            pt="32px"
            pb="4px"
          >
            <Text fontSize="13px" fontWeight={400} color="icons.primary">
              PDF and Word
            </Text>
            <Tooltip
              borderRadius="8px"
              p="8px"
              hasArrow
              label="To preview this filter, switch to the PDF view."
              placement="left"
              fontSize="xs"
            >
              <IconButton
                aria-label=""
                icon={<IoMdInformationCircle size={18} color="#3f495a" />}
                _hover={{ backgroundColor: "transparent" }}
                borderRadius="50%"
                backgroundColor="transparent"
              />
            </Tooltip>
          </Stack>
          {/* <Stack
            w="100%"
            direction="row"
            justifyContent={'space-between'}
            alignItems="center"
            px="24px"
            py="8px"
            _hover={{
              backgroundColor: 'primary.50',
              borderRadius: '12px',
            }}>
            <Text>Media layout</Text>
            <IconButton
              aria-label=""
              icon={<FiChevronRight size={24} color="#3f495a" />}
              _hover={{backgroundColor: '#bfc6d4'}}
              borderRadius="50%"
              backgroundColor="transparent"
              onClick={() => {
                // ActionDrawerHook.onOpen();
                setDisplayedComponent(<MediaLayout />);
              }}
            />
          </Stack> */}

          <Stack
            w="100%"
            direction="row"
            px="24px"
            py="8px"
            justifyContent={"space-between"}
            alignItems="center"
            _hover={{
              backgroundColor: "primary.50",
              borderRadius: "12px",
            }}
          >
            <Controller
              control={form.control}
              name="layout.header.addHeader"
              render={({
                field: { value, ...field },
                fieldState: { error },
              }) => (
                <Checkbox
                  {...field}
                  isInvalid={error !== undefined}
                  isChecked={value}
                  spacing={4}
                >
                  Header
                </Checkbox>
              )}
            />

            <IconButton
              aria-label=""
              icon={<FiChevronRight size={24} color="#3f495a" />}
              _hover={{ backgroundColor: "#bfc6d4" }}
              borderRadius="50%"
              backgroundColor="transparent"
              onClick={() => {
                // ActionDrawerHook.onOpen();
                setDisplayedComponent(<Header />);
              }}
            />
          </Stack>
          <Stack
            w="100%"
            direction="row"
            px="24px"
            py="8px"
            justifyContent={"space-between"}
            alignItems="center"
            _hover={{
              backgroundColor: "primary.50",
              borderRadius: "12px",
            }}
          >
            <Controller
              control={form.control}
              name="layout.footer.addFooter"
              render={({
                field: { value, ...field },
                fieldState: { error },
              }) => (
                <Checkbox
                  {...field}
                  isInvalid={error !== undefined}
                  isChecked={value}
                  spacing={4}
                >
                  Footer
                </Checkbox>
              )}
            />

            <IconButton
              aria-label=""
              icon={<FiChevronRight size={24} color="#3f495a" />}
              _hover={{ backgroundColor: "#bfc6d4" }}
              borderRadius="50%"
              backgroundColor="transparent"
              onClick={() => {
                // ActionDrawerHook.onOpen();
                setDisplayedComponent(<Footer />);
              }}
            />
          </Stack>
          <Stack
            w="100%"
            px="24px"
            py="8px"
            _hover={{
              backgroundColor: "primary.50",
              borderRadius: "12px",
            }}
          >
            <Controller
              control={form.control}
              name="layout.tableOfContents"
              render={({
                field: { value, ...field },
                fieldState: { error },
              }) => (
                <Checkbox
                  {...field}
                  isInvalid={error !== undefined}
                  isChecked={value}
                  spacing={4}
                >
                  Table of contents
                </Checkbox>
              )}
            />
          </Stack>
          {/* <Stack
            w="100%"
            direction="row"
            px="24px"
            py="8px"
            justifyContent={'space-between'}
            alignItems="center"
            _hover={{
              backgroundColor: 'primary.50',
              borderRadius: '12px',
            }}>
            <Checkbox spacing={4} defaultChecked>
              Page break
            </Checkbox>
            Untick to remove breaks between pages to save space in your report.
            <Tooltip
              borderRadius="8px"
              p="8px"
              hasArrow
              label="Untick to remove breaks between pages to save space in your report."
              placement="left"
              fontSize="xs">
              <IconButton
                aria-label=""
                icon={<IoMdInformationCircle size={18} color="#3f495a" />}
                _hover={{backgroundColor: 'transparent'}}
                borderRadius="50%"
                backgroundColor="transparent"
              />
            </Tooltip>
          </Stack> */}
        </VStack>
      </VStack>
    </Fragment>
  );
}
