import { CheckCircleIcon, CheckIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  List,
  ListIcon,
  ListItem,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

export default function SubscriptionPlans() {
  const cardBg = "#487FFF";
  const textColor = useColorModeValue("white", "gray.200");
  const badgeBg = useColorModeValue("whiteAlpha.700", "whiteAlpha.400");

  return (
    <Box className="card" p={0} rounded="xl" overflow="hidden" mt="6">
      <Box
        bg="gray.100"
        borderBottom="1px solid"
        borderColor="gray.200"
        py="4"
        px="6"
      >
        <Heading as="h6" size="md" mb="0">
          Simple Pricing Plan
        </Heading>
      </Box>
      <Box p="10">
        <Flex direction="column" align="center">
          <Heading size="md" mb="4">
            Simple, Transparent Pricing
          </Heading>
          <Text fontSize="lg" color="gray.500" textAlign="center">
            Lorem ipsum dolor sit amet consectetur adipiscing elit dolor posuere
            vel venenatis eu sit massa volutpat.
          </Text>
        </Flex>

        <Flex justify="center" mt="10" align="center" gap="4">
          <Text fontSize="md" color="gray.500">
            Monthly
          </Text>
          <Switch size="md" colorScheme="blue" />
          <Text fontSize="md" color="gray.500">
            Annually
          </Text>
        </Flex>

        <Stack
          direction={{ base: "column", md: "row" }}
          spacing="8"
          mt="8"
          justify="center"
        >
          {/* Basic Plan */}
          <Box
            borderWidth="1px"
            rounded="2xl"
            overflow="hidden"
            bg="white"
            p="6"
            maxW="sm"
            shadow="md"
          >
            <HStack spacing="4" mb="4">
              <Flex
                justify="center"
                align="center"
                w="72px"
                h="72px"
                bg="blue.100"
                rounded="lg"
              >
                <Image src="https://laravel.wowdash.wowtheme7.com/assets/images/pricing/price-icon4.png" />
              </Flex>
              <Box>
                <Text fontSize="md" color="gray.500">
                  For individuals
                </Text>
                <Heading as="h6" size="sm">
                  Basic
                </Heading>
              </Box>
            </HStack>
            <Text color="gray.500" mb="6">
              Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing
              elit.
            </Text>
            <Heading size="lg" mb="4">
              $99{" "}
              <Text
                as="span"
                fontSize="md"
                fontWeight="medium"
                color="gray.500"
              >
                /monthly
              </Text>
            </Heading>
            <Text fontWeight="medium" mb="4">
              What’s included
            </Text>
            <List spacing="3" mb="6">
              {[
                "All analytics features",
                "Up to 250,000 tracked visits",
                "Normal support",
                "Up to 3 team members",
              ].map((item) => (
                <ListItem key={item} display="flex" alignItems="center" gap="3">
                  <ListIcon as={CheckCircleIcon} color="blue.500" />
                  <Text color="gray.500">{item}</Text>
                </ListItem>
              ))}
            </List>
            <Button colorScheme="blue" size="sm" w="full">
              Get Started
            </Button>
          </Box>

          {/* Pro Plan */}
          <Box
            bg={cardBg}
            color={textColor}
            borderRadius="24px"
            overflow="hidden"
            p={6}
            position="relative"
          >
            {/* Background Image */}
            <Image
              src="https://laravel.wowdash.wowtheme7.com/assets/images/pricing/pricing-shape.png"
              alt="Background Shape"
              position="absolute"
              top="10%"
              right="0"
            />

            {/* Popular Badge */}
            <Badge
              position="absolute"
              top="0"
              right="0"
              py="8px"
              px="24px"
              fontSize="sm"
              bg={badgeBg}
              color={textColor}
              borderRadius="0 24px 0 24px"
            >
              Popular
            </Badge>

            {/* Icon and Title */}
            <HStack align="center" spacing={4} mb={4}>
              <Flex
                w="72px"
                h="72px"
                bg="whiteAlpha.300"
                justify="center"
                align="center"
                borderRadius="16px"
              >
                <Image
                  src="https://laravel.wowdash.wowtheme7.com/assets/images/pricing/price-icon2.png"
                  alt="Icon"
                />
              </Flex>
              <VStack align="start" spacing={0}>
                <Text fontWeight="medium" fontSize="md">
                  For startups
                </Text>
                <Heading size="md">Pro</Heading>
              </VStack>
            </HStack>

            {/* Description */}
            <Text mt={4} mb={6}>
              Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing
              elit.
            </Text>

            {/* Pricing */}
            <Heading size="lg" mb={6}>
              $199{" "}
              <Text as="span" fontWeight="medium" fontSize="md">
                /monthly
              </Text>
            </Heading>

            {/* What's Included */}
            <Text fontWeight="medium" mb={4}>
              What’s included
            </Text>

            {/* Features List */}
            <VStack align="start" spacing={4}>
              {[
                "All analytics features",
                "Up to 250,000 tracked visits",
                "Normal support",
                "Up to 3 team members",
              ].map((feature, index) => (
                <HStack key={index} spacing={4}>
                  <Flex
                    w="24px"
                    h="24px"
                    bg="white"
                    color={cardBg}
                    justify="center"
                    align="center"
                    borderRadius="full"
                  >
                    <Icon as={CheckIcon} />
                  </Flex>
                  <Text>{feature}</Text>
                </HStack>
              ))}
            </VStack>

            {/* Button */}
            <Button
              mt={8}
              w="full"
              colorScheme="whiteAlpha"
              variant="outline"
              borderColor="white"
              onClick={() => alert("Get Started!")}
            >
              Get started
            </Button>
          </Box>

          {/* Enterprise Plan */}
          <Box
            borderWidth="1px"
            rounded="2xl"
            overflow="hidden"
            bg="white"
            p="6"
            maxW="sm"
            shadow="md"
          >
            <HStack spacing="4" mb="4">
              <Flex
                justify="center"
                align="center"
                w="72px"
                h="72px"
                bg="blue.100"
                rounded="lg"
              >
                <Image src="https://laravel.wowdash.wowtheme7.com/assets/images/pricing/price-icon5.png" />
              </Flex>
              <Box>
                <Text fontSize="md" color="gray.500">
                  For big companies
                </Text>
                <Heading as="h6" size="sm">
                  Enterprise
                </Heading>
              </Box>
            </HStack>
            <Text color="gray.500" mb="6">
              Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing
              elit.
            </Text>
            <Heading size="lg" mb="4">
              $399{" "}
              <Text
                as="span"
                fontSize="md"
                fontWeight="medium"
                color="gray.500"
              >
                /monthly
              </Text>
            </Heading>
            <Text fontWeight="medium" mb="4">
              What’s included
            </Text>
            <List spacing="3" mb="6">
              {[
                "All analytics features",
                "Up to 250,000 tracked visits",
                "Normal support",
                "Up to 3 team members",
              ].map((item) => (
                <ListItem key={item} display="flex" alignItems="center" gap="3">
                  <ListIcon as={CheckCircleIcon} color="blue.500" />
                  <Text color="gray.500">{item}</Text>
                </ListItem>
              ))}
            </List>
            <Button colorScheme="blue" size="sm" w="full">
              Get Started
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
