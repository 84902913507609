import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { UploadAttachments } from "../../../../../Payroll/Api/UploadAttachments";
import { Template } from "../../../../modules/Audit";
import { getFieldKey } from "../../../../modules/form-apis";
import { useItemContext } from "../../hooks/auditItemContext";
import useDrawer from "../../hooks/drawersContext";
interface DocumentsUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function DocumentsUploadModal({
  isOpen,
  onClose,
}: DocumentsUploadModalProps) {
  const { item } = useItemContext();
  const template = useFormContext();
  console.log(template.getValues());
  const { questionItemDetails } = useDrawer();
  const { control, handleSubmit } = useForm<Template.QuestionProperties>({
    defaultValues: template.getValues(
      getFieldKey(
        questionItemDetails ?? { pageIndex: 0 },
        "question.properties"
      )
    ),
  });
  const [isFileUploading, setIsFileUploading] = useBoolean(false);
  const fileRef = useRef<HTMLInputElement>(null);

  const FileInput = () => (
    <Input multiple ref={fileRef} type={"file"} accept="application/pdf" />
  );
  const toast = useToast();
  const handleFileUpload = async () => {
    const files = fileRef.current?.files;
    if (files && files.length > 0) {
      setIsFileUploading.on();
      const promises = Array.from(files).map(
        (file) =>
          new Promise((resolve, rejects) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              const base64Data = reader.result as string;
              const base64Content = base64Data.split(",")[1];
              resolve({
                inspectionId: `template_${template.getValues("template.id")}`,
                fileName: file.name,
                base64: base64Content,
              });
            };
            reader.onerror = (error) => rejects(error);
          })
      );
      Promise.all(promises)
        .then((results) => {
          results.forEach((fileData: any) =>
            // Inspection.UploadMedia(
            //   {
            //     base64: fileData.base64,
            //     fileName: fileData.fileName,
            //     inspectionId: fileData.inspectionId,
            //   },
            //   path => {
            //     if (questionItemDetails?.questionIndex !== undefined) {
            //       template.setValue(
            //         getFieldKey(questionItemDetails, 'question.properties'),
            //         [
            //           ...(template.getValues(
            //             getFieldKey(questionItemDetails, 'question.properties'),
            //           ) ?? []),
            //           path,
            //         ],
            //       );
            //     }

            //     console.log(`Uploaded to: ${path}`);
            //   },
            //   message => console.error(`Upload failed: ${message}`),
            // ),
            {
              const formData = new FormData();
              formData.append("docType", "TEMPLATE");
              formData.append("file", fileData);
              formData.append("inspectionId", fileData.inspectionId!);
              UploadAttachments.UploadAttachment(
                formData,
                (success) => {
                  if (questionItemDetails?.questionIndex !== undefined) {
                    template.setValue(
                      getFieldKey(questionItemDetails, "question.properties"),
                      [
                        ...(template.getValues(
                          getFieldKey(
                            questionItemDetails,
                            "question.properties"
                          )
                        ) ?? []),
                        success.url,
                      ]
                    );
                  }
                },
                (err) => {
                  toast({
                    title: "Error",
                    description: err,
                    status: "error",
                  });
                }
              );
            }
          );
        })
        .catch((error) => console.error("Error reading files:", error))
        .finally(() => setIsFileUploading.off());
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Documents</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FileInput />
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleFileUpload}
            isLoading={isFileUploading}
          >
            Upload
          </Button>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
