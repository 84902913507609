import { Button, HStack, useDisclosure } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { IoLocationSharp } from "react-icons/io5";
import { AddressInput } from "../../../../Common/AddressInput";
import { Inspection } from "../../../modules/Audit";
import { useInspectionContext } from "../hooks/InspectionContext";
import { useAuditQuestionContainer } from "./AuditQuestionContainer";
import MapModal from "./MapModal";

export default function Location() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const template = useFormContext();
  const { getItemField, question } = useAuditQuestionContainer();
  const { setIsSaving } = useInspectionContext();
  return (
    <HStack w="100%">
      <Controller
        control={template.control}
        name={getItemField("question.questionValue")}
        rules={{
          onBlur(event) {
            const answerId = template.getValues(
              getItemField("question.answerId")
            );
            const type = template.getValues(getItemField("question.type"));
            const inspectionMetaID = template.getValues("inspection.id");
            const notes = template.getValues(getItemField("question.notes"));
            setIsSaving(true);
            Inspection.CachingInspection(
              answerId,
              {
                question_value: event.target.value,
                notes: notes,
                inspection_meta_id: inspectionMetaID,
                type: type,
              },
              () => {
                setIsSaving(false);
              },
              () => {
                setIsSaving(false);
              }
            );
          },
        }}
        render={({ field, fieldState }) => (
          <AddressInput
            onPlaceSelected={(place) => {
              const answerId = template.getValues(
                getItemField("question.answerId")
              );
              const type = template.getValues(getItemField("question.type"));
              const inspectionMetaID = template.getValues("inspection.id");
              const notes = template.getValues(getItemField("question.notes"));
              setIsSaving(true);
              field.onChange(
                place.formatted_address,
                Inspection.CachingInspection(
                  answerId,
                  {
                    question_value: place.formatted_address,
                    notes: notes,
                    inspection_meta_id: inspectionMetaID,
                    type: type,
                  },
                  () => {
                    setIsSaving(false);
                  },
                  () => {
                    setIsSaving(false);
                  }
                )
              );
            }}
            input={{
              ...field,
              variant: "outline",
              isInvalid: (fieldState.error?.message?.length ?? 0) > 0,
              type: "text",
            }}
            // label="Select Location"
            withValidation
            validationMessage={fieldState.error?.message}
          />
        )}
      />
      <Button
        display="none"
        h="41.6px"
        padding="12px 20px"
        iconSpacing={"2px"}
        leftIcon={<IoLocationSharp color="white" size={"14"} />}
        border="none"
        borderRadius="8px"
        onClick={() => {
          onOpen();
        }}
        fontWeight={500}
        fontSize="14px"
      >
        Map
      </Button>
      <MapModal isOpen={isOpen} onClose={onClose} />
    </HStack>
  );
}
