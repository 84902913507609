import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Input,
  Stack,
} from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import InspectionInput from "../../../../template/pages/access-components/Popovers/InspectionInput";
import Content from "../../../../template/pages/Content";
import InspectionSitePopover from "../Popovers/InspectionSitePopover";
import PriorityPopover from "../Popovers/PriorityPopover";

interface actiondrawerprops {
  isOpen: boolean;
  onClose: () => void;
}
export default function InspectionActionDrawer({
  isOpen,
  onClose,
}: actiondrawerprops) {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader p="18px" borderBottom="1px" borderColor="borders.accent">
          <IconButton
            aria-label="close"
            icon={<AiOutlineClose size={24} color="#545F70" />}
            backgroundColor="transparent"
            _hover={{ backgroundColor: "transparent" }}
            _active={{
              backgroundColor: "transparent",
            }}
            onClick={onClose}
          />
        </DrawerHeader>

        <DrawerBody p={0}>
          <Stack px="32px" py="18px">
            <Input
              fontSize="24px"
              fontWeight="500"
              border="none"
              value="Title of action"
              p="0px"
              color="icons.primary"
              lineHeight="16px"
            />
            <Input
              fontSize="16px"
              fontWeight="400"
              border="none"
              value="Add description..."
              p="0px"
              color="icons.primary"
              lineHeight="24px"
            />
            <Stack>
              <Content fontSize="14px">Sites</Content>
              <InspectionSitePopover />
            </Stack>
            <Stack>
              <Content fontSize="14px">Assignee</Content>
              <InspectionInput />
            </Stack>
            <Stack>
              <Content fontSize="14px">Priority</Content>
              <PriorityPopover />
            </Stack>
            <Stack>
              <Content fontSize="14px">Label</Content>
              <InspectionSitePopover />
            </Stack>
            <Stack>
              <Content fontSize="14px">Due Date</Content>
              <InspectionSitePopover />
            </Stack>
          </Stack>
        </DrawerBody>

        <DrawerFooter borderTop="1px" borderColor="borders.accent" p="8px">
          <Button px={"20px"} py="12px" h="41px" borderRadius="8px">
            Create
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
