import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {MCQ} from '../../../modules/Audit/MCQs';
import {useTemplateBuilder} from './templateBuilderContext';

const OPTIONS_DUMMY: Array<MCQ.IMCQ> = [
  {
    isScoringEnabled: false,
    isGlobal: false,
    options: [
      {
        color: '#880808',
        value: 'NO',
        flagged: false,
        orderIndex: 0,
      },
      {
        color: '#00FF00',
        value: 'YES',
        flagged: false,
        orderIndex: 1,
      },
    ],
  },

  {
    isScoringEnabled: false,
    isGlobal: false,
    options: [
      {
        color: '#880808',
        value: 'Fail',
        flagged: false,
        orderIndex: 0,
      },
      {
        color: '#00FF00',
        value: 'PASS',
        flagged: false,
        orderIndex: 1,
      },
    ],
  },
];

interface MultiChoiceContextProps {
  templateOptionSets: MCQ.IMCQ[];
  setTemplateOptionSets: React.Dispatch<React.SetStateAction<MCQ.IMCQ[]>>;
  globalOptionSets: MCQ.IMCQ[];
  setGlobalOptionSets: React.Dispatch<React.SetStateAction<MCQ.IMCQ[]>>;
  updateOptionSets: () => void;
}
export const MultiChoiceContext = React.createContext<MultiChoiceContextProps>({
  templateOptionSets: [],
  setTemplateOptionSets: () => {},
  globalOptionSets: [],
  setGlobalOptionSets: () => {},
  updateOptionSets: () => {},
});

export function MultiChoiceProvider({children}: any) {
  const urlParams = useParams();
  const [templateOptionSets, setTemplateOptionSets] =
    useState<Array<MCQ.IMCQ>>(OPTIONS_DUMMY);
  const [globalOptionSets, setGlobalOptionSets] =
    useState<Array<MCQ.IMCQ>>(OPTIONS_DUMMY);
  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const {refreshUpdatedOptions} = useTemplateBuilder();

  useEffect(() => {
    MCQ.Fetch(
      {
        isGlobal: false,
        templateId: Number(urlParams?.id),
      },
      mcqs => {
        setTemplateOptionSets(mcqs);
        refreshUpdatedOptions();
      },
      error => {
        console.log(error);
      },
    );
    MCQ.Fetch(
      {
        isGlobal: true,
      },
      mcqs => {
        setGlobalOptionSets(mcqs);
      },
      error => {
        console.log(error);
      },
    );
  }, [lastUpdate]);

  function updateOptionSets() {
    setLastUpdate(Date.now());
  }
  return (
    <MultiChoiceContext.Provider
      value={{
        templateOptionSets,
        setTemplateOptionSets,
        updateOptionSets,
        globalOptionSets,
        setGlobalOptionSets,
      }}>
      {children}
    </MultiChoiceContext.Provider>
  );
}

export function useMultiChoiceContext() {
  return React.useContext(MultiChoiceContext);
}
