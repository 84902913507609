import { axiosInstance } from "./axiosInstance-hris";
declare module GroupForContact {
  type AddGroup = {
    id?: string;
    name: string;
    contactIds: string[];
  };
  type AddContactInGroup = {
    contactIds: string[];
  };
  type UpdateGroup = {
    name: string;
  };
  type FetchGroup = {
    id: string;
    name: string;
    contactIds: string[];
  };
  type FetchGroupDataById = {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    contacts: [
      {
        id: string;
        createdAt: string;
        updatedAt: string;
        name: string;
        accountNumber: string;
        isActive: boolean;
      }
    ];
  };
}
module GroupForContact {
  const URL = "/groups";
  export function PostGroup(
    data: AddGroup,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${URL}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function FetchGroupDetails(
    params: {
      name?: string;
      isActive?: boolean;
      limit?: number;
      page?: number;
    },
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}`, { params })
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function FetchGroupDetailsById(
    id: string,
    success: (response: FetchGroupDataById) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}/by-id/${id}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function UpdateGroups(
    id: string,
    data: Partial<UpdateGroup>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .put(`${URL}/${id}/rename`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function DeleteGroups(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${URL}/${id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function RemoveContactFromGroups(
    id: string,
    contactIds: string[],
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${URL}/${id}/contacts`, {
        data: { contactIds },
      })
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function PostContactInGroup(
    id: string,
    data: AddContactInGroup,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${URL}/${id}/contacts`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
}

export { GroupForContact };
