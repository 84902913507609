import { axiosInstance } from "./axiosInstance-hris";
declare module ProductAndService {
  type AddProductAndService = {
    id?: string;
    code: string;
    inventoryAccount: string;
    name: string;
    description: string;
    purchaseInfo: {
      costPrice: number;
      purchaseAccountId: string;
      taxRate: string;
      description: string;
    };
    sellInfo: {
      salePrice: number;
      saleAccountId: string;
      taxRate: string;
      description: string;
    };
  };
  type DeleteMany = {
    ids: string[];
  };
  type FetchAllProductAndService = {
    id: string;
    createdAt: string;
    updatedAt: string;
    code: string;
    name: string;
    description: string;
    sellInfo: {
      id: string;
      createdAt: string;
      updatedAt: string;
      salePrice: number;
      saleAccountId: string;
      taxRate: string;
      description: string;
    };
    purchaseInfo: {
      id: string;
      createdAt: string;
      updatedAt: string;
      costPrice: number;
      purchaseAccountId: string;
      taxRate: string;
      description: string;
    };
  };
  type FetchProductAndServiceByID = {
    id: string;
    createdAt: string;
    updatedAt: string;
    code: string;
    name: string;
    description: string;
    sellInfo: {
      id: string;
      createdAt: string;
      updatedAt: string;
      salePrice: number;
      saleAccountId: string;
      taxRate: string;
      description: string;
    };
    purchaseInfo: {
      id: string;
      createdAt: string;
      updatedAt: string;
      costPrice: number;
      purchaseAccountId: string;
      taxRate: string;
      description: string;
    };
  };
}
module ProductAndService {
  const API_URL = "/items";
  export function PostProductAndServiceItem(
    data: AddProductAndService,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function GetProductAndServiceItemDetails(
    params: {
      name?: string;
      isActive?: boolean;
      limit?: number;
      page?: number;
    },
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`, { params })
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function GetProductAndServiceItemDetailsById(
    id: string,
    success: (response: FetchProductAndServiceByID) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/by-id/${id}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function UpdateProductAndServiceItem(
    id: string,
    data: Partial<AddProductAndService>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .put(`${API_URL}/${id}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function DeleteProductAndServiceItem(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function DeleteManyProductAndServiceItem(
    ids: string[],
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/delete/many`, {
        data: { ids },
      })
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response?.data?.message || "An error occurred");
      });
  }
  export function ArchiveProductAndServiceItem(
    data: { ids: string[] },
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .put(`${API_URL}/update/archive`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response?.data?.message || "An error occurred");
      });
  }
  export function RestoreProductAndServiceItem(
    data: { ids: string[] },
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .put(`${API_URL}/update/restore`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response?.data?.message || "An error occurred");
      });
  }
}
export { ProductAndService };
