import axiosInstanceTime from "./axiosInstanceTime";

declare module ShiftsTransfer {
  type ShiftsTransfer = {
    shiftId: string;
    targetEmployeeId: string;
  };
}
module ShiftsTransfer {
  const API_URL = "shift-assignment-request";
  export function RequestShiftTransfer(
    data: ShiftsTransfer,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .post(`${API_URL}/request-shift`, data)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function GetAssignedShifts(
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .get(`${API_URL}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function GetShiftTransferRequests(
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .get(`${API_URL}/me`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function AcceptShiftTransferRequest(
    requestId: string,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .patch(`${API_URL}/approve-request/${requestId}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function RejectShiftTransferRequest(
    requestId: string,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .patch(`${API_URL}/reject-request/${requestId}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { ShiftsTransfer };
