import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { useLocation, useSearchParams } from "react-router-dom";
import { Inspection, TemplateMeta } from "../../modules/Audit";
import { PeriodicalEvents } from "../../modules/Audit/PeriodicalEvents";
import { PeriodicalSchedule as PeriodicalScheduleApi } from "../../modules/Audit/PeriodicalSchedule";
import SubHeading from "../template/pages/Sub-Heading";
import ManagePeriodicalEvents from "./Components/ManagePeriocicalSchedule/ManagePeriodicalEvents";
import ManagePeriodicalSchedule from "./Components/ManagePeriocicalSchedule/ManagePeriodicalSchedule";
import PeriodicalLists from "./Components/ManagePeriocicalSchedule/TodayPeriodicalSchedule/PeriodicalLists";
import EventsModal from "./Components/Modals/EventsModal";
import PeriodicalScheduleModal from "./Components/Modals/PeriodicalScheduleModal";

export default function PeriodicalSchedule() {
  const { state } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: state?.triggerCreateModal,
  });
  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const [templatesList, setTemplatesList] = useState<
    Array<TemplateMeta.TemplateMeta>
  >([]);
  const [inspectionsList, setInspectionsList] = useState<
    Array<Inspection.InpsectionMetaPayload>
  >([]);
  const [searchParam] = useSearchParams();
  const eventModal = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allEvents, setALLEvents] = useState<Array<PeriodicalEvents.GetEvents>>(
    []
  );
  const [Update, setUpdate] = useState<number>(0);
  const form = useForm<PeriodicalScheduleApi.AddPeriodicSchedule>({
    defaultValues: {
      activityId: undefined,
      color: "#000000",
      dateRange: {
        endDate: "",
        startDate: "",
      },
      timeRange: {
        endTime: "",
        startTime: "",
      },
      areas: [],
      employees: [],
      frequency: "DAILY",
      location: undefined,
      locationTimeZone: undefined,
      templateId: undefined,
      properties: {
        selection: "DAY",
        annually: {
          day: "MONDAY",
          month: "JANUARY",
          dayPosition: "FIRST",
          every: 1,
          monthDate: 1,
        },
        daily: {
          every: undefined,
          onlyWeekdays: false,
        },
        monthly: {
          day: "MONDAY",
          dayPosition: "FIRST",
          every: 1,
          dayOfMonth: 1,
        },
        weekly: {
          days: [],
          numOfWeeks: 1,
        },
      },
    },
  });
  const toast = useToast();
  useEffect(() => {
    setIsLoading(true);
    PeriodicalEvents.FetchPeriodicalEvents(
      (data) => {
        setALLEvents(data);
        setIsLoading(false);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, [Update]);
  useEffect(() => {
    TemplateMeta.Fetch(
      { archive: false },
      (data) => {
        setTemplatesList(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );

    Inspection.Fetch(
      { archive: false, limit: 150 },
      (data) => {
        setInspectionsList(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, []);
  const EventsForm = useForm<PeriodicalEvents.AddPeriodicalEvents>({
    defaultValues: {
      name: "",
      code: "",
      description: "",
    },
  });
  const todayForm = useForm<PeriodicalScheduleApi.UpdateStatus>({
    defaultValues: {
      status: 0,
      completedBy: "",
      inspectionId: undefined,
      periodicScheduleEventId: undefined,
    },
  });
  return (
    <Stack h="calc(100vh - 70px)">
      <HStack px="32px" pt="16px" justifyContent="space-between">
        <SubHeading fontSize="24px" px={{ xs: "16px", md: "0px" }}>
          Periodical Schedule
        </SubHeading>

        <Menu>
          <MenuButton
            as={Button}
            h="41px"
            iconSpacing={1}
            leftIcon={<AiOutlinePlus />}
            px="16px"
            py="8px"
            fontSize="14px"
            fontWeight={400}
            lineHeight="22px"
            borderRadius="8px"
          >
            Manage
          </MenuButton>
          <MenuList>
            <FormProvider {...form}>
              <MenuItem
                onClick={() => {
                  form.reset({
                    activityId: undefined,
                    color: "#000000",
                    dateRange: {
                      endDate: "",
                      startDate: "",
                    },
                    timeRange: {
                      endTime: "",
                      startTime: "",
                    },
                    areas: [],
                    employees: [],
                    frequency: "DAILY",
                    location: undefined,
                    locationTimeZone: undefined,
                    templateId: undefined,
                    properties: {
                      selection: "DAY",
                      // annually: {
                      //   day: undefined,
                      //   month: undefined,
                      //   dayPosition: undefined,
                      //   every: undefined,
                      //   monthDate: undefined,
                      // },
                      daily: {
                        every: undefined,
                        // onlyWeekdays: false,
                      },
                      // monthly: {
                      //   day: undefined,
                      //   dayPosition: undefined,
                      //   every: undefined,
                      //   dayOfMonth: undefined,
                      // },
                      // weekly: {
                      //   days: [],
                      //   numOfWeeks: undefined,
                      // },
                    },
                  });

                  onOpen();
                }}
              >
                Periodical Schedule
              </MenuItem>
            </FormProvider>
            <FormProvider {...EventsForm}>
              <MenuItem
                onClick={() => {
                  eventModal.onOpen();
                  EventsForm.reset({
                    name: "",
                    code: "",
                    description: "",
                  });
                }}
              >
                Add Events
              </MenuItem>
            </FormProvider>
          </MenuList>
        </Menu>

        {/* <Button
            h="41px"
            iconSpacing={1}
            leftIcon={<AiOutlinePlus />}
            px="16px"
            py="8px"
            fontSize="14px"
            fontWeight={400}
            lineHeight="22px"
            borderRadius="8px">
            Periodical Schedule
          </Button> */}
      </HStack>
      <FormProvider {...form}>
        <PeriodicalScheduleModal
          isOpen={isOpen}
          onClose={onClose}
          templatesList={templatesList}
          setLastUpdate={setLastUpdate}
          listOfEvents={allEvents}
        />
      </FormProvider>
      <FormProvider {...EventsForm}>
        <EventsModal
          isOpen={eventModal.isOpen}
          onClose={eventModal.onClose}
          setUpdate={setUpdate}
        />
      </FormProvider>
      <Tabs isLazy>
        <HStack
          px="16px"
          pt="8px"
          borderBottom="1px"
          borderColor="borders.accent"
          justifyContent="space-between"
        >
          <TabList color="textcolors.primary" border="0px">
            {[
              "Periodical Schedule",
              "Manage Periodical Schedules",
              "Manage Events",
            ].map((menu, i) => {
              return (
                <Fragment key={i}>
                  <Tab
                    fontSize="14px"
                    px="16px"
                    py="12px"
                    sx={{ borderBottomWidth: "4px" }}
                  >
                    {menu}
                  </Tab>
                </Fragment>
              );
            })}
          </TabList>
        </HStack>

        <TabPanels>
          <FormProvider {...todayForm}>
            <TabPanel p={{ xs: "4px", md: "16px" }}>
              <PeriodicalLists
                lastUpdate={lastUpdate}
                inspectionsList={inspectionsList}
                setLastUpdate={setLastUpdate}
              />
            </TabPanel>
          </FormProvider>
          <FormProvider {...form}>
            <TabPanel p={0}>
              <ManagePeriodicalSchedule
                templatesList={templatesList}
                setLastUpdate={setLastUpdate}
                lastUpdate={lastUpdate}
                allEvents={allEvents}
              />
            </TabPanel>
          </FormProvider>
          <FormProvider {...EventsForm}>
            <TabPanel p={0}>
              <ManagePeriodicalEvents
                isOpen={eventModal.isOpen}
                onClose={eventModal.onClose}
                onOpen={eventModal.onOpen}
                setUpdate={setUpdate}
                update={Update}
                allEvents={allEvents}
                isLoading={isLoading}
              />
            </TabPanel>
          </FormProvider>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
