import {
  Button,
  ButtonGroup,
  Heading,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { Inspection } from "../../../../../modules/Audit";
import AllPeriodicalLists from "./PeriodicalLists/AllPeriodicalList";
import MonthlyPeriodicalList from "./PeriodicalLists/MonthlyPeriodicalList";
import TodayPeriodicalList from "./PeriodicalLists/TodayPeriodicalList";
import WeeklyPeriodicalList from "./PeriodicalLists/WeeklyPeridicalList";

interface PeriodicalListsProps {
  lastUpdate: number;
  inspectionsList: Inspection.InpsectionMetaPayload[];
  setLastUpdate: (timestamp: number) => void;
}
export default function PeriodicalLists({
  inspectionsList,
  lastUpdate,
  setLastUpdate,
}: PeriodicalListsProps) {
  const [selectedLayout, setSelectedLayout] = useState<number>(0);
  const Today = useBreakpointValue({
    md: "Today",
    xs: "T",
  });
  const Weekly = useBreakpointValue({
    md: "Last Week",
    xs: "LW",
  });
  const Monthly = useBreakpointValue({
    md: "Last Month",
    xs: "LM",
  });
  const All = useBreakpointValue({
    md: "Previous",
    xs: "P",
  });

  const tabs = [
    {
      label: Today,
      index: 0,
    },
    {
      label: Weekly,
      index: 1,
    },
    {
      label: Monthly,
      index: 2,
    },
    {
      label: All,
      index: 3,
    },
  ];
  return (
    <Stack
      // maxH="100vh"

      border="1px"
      borderColor="borders.accent"
      boxShadow="0 4px 12px 0 rgb(131 146 165 / 15%), 2px 2px 5px 0 rgb(60 70 83 / 4%)"
    >
      <Tabs variant="unstyled" isLazy>
        <HStack
          px="15px"
          position="sticky"
          top="0px"
          borderBottom="1px"
          borderColor="borders.accent"
          minH="55px"
          backgroundColor="white"
          zIndex={9}
          justifyContent="space-between"
        >
          <Heading as="h3" size="lg" color="textcolors.primary">
            Scheduled Events
          </Heading>
          <TabList as={ButtonGroup} isAttached>
            {tabs.map((tab, i) => {
              return (
                <Tab
                  as={Button}
                  key={i}
                  variant="outline"
                  px="15px"
                  py="0px"
                  h="32px"
                  border="1px"
                  color="icons.primary"
                  borderColor="borders.accent"
                  _hover={{ backgroundColor: "transparent" }}
                  _selected={{
                    zIndex: 1,
                    color: "primary.500",
                    bg: "backgrounds.header",
                    borderColor: "primary.500",
                  }}
                  fontSize="13px"
                  fontWeight={{ xs: 500, md: 400 }}
                  onClick={() => setSelectedLayout(tab.index)}
                >
                  {tab.label}
                </Tab>
              );
            })}
          </TabList>
        </HStack>
        <TabPanels tabIndex={selectedLayout}>
          <TabPanel p={0}>
            <TodayPeriodicalList
              lastUpdate={lastUpdate}
              inspectionsList={inspectionsList}
              setLastUpdate={setLastUpdate}
            />
          </TabPanel>
          <TabPanel p={0}>
            <WeeklyPeriodicalList
              lastUpdate={lastUpdate}
              inspectionsList={inspectionsList}
              setLastUpdate={setLastUpdate}
            />
          </TabPanel>
          <TabPanel p={0}>
            <MonthlyPeriodicalList
              lastUpdate={lastUpdate}
              inspectionsList={inspectionsList}
              setLastUpdate={setLastUpdate}
            />
          </TabPanel>
          <TabPanel p={0}>
            <AllPeriodicalLists
              lastUpdate={lastUpdate}
              inspectionsList={inspectionsList}
              setLastUpdate={setLastUpdate}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
