import {
  Button,
  Grid,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { AiFillSchedule } from "react-icons/ai";
import {
  FaFileInvoiceDollar,
  FaMoneyBill,
  FaUmbrellaBeach,
  FaUserClock,
} from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FiSearch } from "react-icons/fi";
import { GiTakeMyMoney } from "react-icons/gi";
import { GoLaw } from "react-icons/go";
import { TiShoppingCart } from "react-icons/ti";

import { IoIosSearch } from "react-icons/io";
import {
  MdAddBusiness,
  MdContacts,
  MdNotificationsActive,
  MdOutlineAccountBalance,
  MdOutlineAttachMoney,
  MdPeople,
} from "react-icons/md";
import { TbReport, TbReportSearch } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

export function Search() {
  const managementData = [
    {
      title: "Contacts",
      subArray: [
        {
          menulabel: "All Contacts",
          menuIcon: MdContacts,
          href: "/app/payroll/AllContact",
        },
      ],
    },
    {
      title: "Employee Management",
      subArray: [
        {
          menulabel: "Employees",
          menuIcon: MdPeople,
          href: "/app/payroll/PayrollEmployee/?page=1&limit=25",
        },
        {
          menulabel: "Leave",
          menuIcon: FaUmbrellaBeach,
          href: "/app/payroll/Leave",
        },
      ],
    },
    {
      title: "Time & Attendance",
      subArray: [
        {
          menulabel: "Location",
          menuIcon: FaLocationDot,
          href: "/app/TimeAttendance/Location",
        },
        {
          menulabel: "Schedule",
          menuIcon: AiFillSchedule,
          href: "/app/TimeAttendance/Schedule",
        },
        {
          menulabel: "Checkin Report",
          menuIcon: TbReport,
          href: "/app/TimeAttendance/checkinreport",
        },
        {
          menulabel: "Notifications",
          menuIcon: MdNotificationsActive,
          href: "/app/TimeAttendance/notificationSettings",
        },
      ],
    },
    {
      title: "Payroll processing",
      subArray: [
        {
          menulabel: "Timesheets",
          menuIcon: FaUserClock,
          href: "/app/payroll/Timesheets",
        },
        {
          menulabel: "Pay Employee",
          menuIcon: FaMoneyBill,
          href: "/app/payroll/PayEmployee",
        },
        {
          menulabel: "Payroll setting",
          menuIcon: GiTakeMyMoney,
          href: "/app/payroll/payrollsettings",
        },
      ],
    },
    {
      title: "Reports",
      subArray: [
        {
          menulabel: "Reports",
          menuIcon: TbReportSearch,
          href: "/app/payroll/Reports",
        },
      ],
    },
    {
      title: "Statuary Benefits",
      subArray: [
        {
          menulabel: "Statuary Benefits",
          menuIcon: GoLaw,
          href: "/app/payroll/statuary-benefits",
        },
      ],
    },
    {
      title: "Business",
      subArray: [
        {
          menulabel: "Bills to pay",
          menuIcon: MdAddBusiness,
          href: "/app/payroll/BillsToPay",
        },
        {
          menulabel: "Invoices",
          menuIcon: FaFileInvoiceDollar,
          href: "/app/payroll/InvoicesParent",
        },
        {
          menulabel: "Products and services",
          menuIcon: TiShoppingCart,
          href: "/app/payroll/ProductAndServices",
        },
      ],
    },
    {
      title: "Advanced",
      subArray: [
        {
          menulabel: "Chart of account",
          menuIcon: MdOutlineAccountBalance,
          href: "/app/payroll/ChartOfAccount",
        },
        {
          menulabel: "Tax Rates",
          menuIcon: MdOutlineAttachMoney,
          href: "/app/payroll/TaxRates",
        },
      ],
    },
  ];

  const [selectedTitle, setSelectedTitle] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleTitleClick = (title: string) => {
    setSelectedTitle(selectedTitle === title ? null : title); // Toggle title selection
  };

  const navigate = useNavigate();
  const handleSubArrayClick = (href: string) => {
    navigate(href); // Navigate to the respective link
  };

  // Filter the management data based on the search query
  const filteredData = managementData.filter((item) =>
    //   item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.subArray.some((subItem) =>
      subItem.menulabel.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <Popover trigger="hover" placement="bottom-start">
      <PopoverTrigger>
        <IconButton
          aria-label="Options"
          alignItems="center"
          border={"none"}
          justifyContent="center"
          borderRadius="50%"
          w="46px"
          h="46px"
          px="12px"
          py="12px"
          fontSize="22px"
          color="#6B7280"
          _hover={{
            color: "#3454d1",
            bg: "#eaebef",
          }}
          _active={{
            color: "#3454d1",
            bg: "#eaebef",
          }}
          icon={<IoIosSearch />}
          variant="outline"
        />
      </PopoverTrigger>
      <PopoverContent
        w={"425px"}
        border={"1px solid #e5e7eb"}
        paddingTop={""}
        padding={"0px 0 15px 0px"}
        boxShadow={"0 10px 24px 0 rgba(62,57,107,.18)"}
        borderRadius={"0 0 10px 10px"}
        maxH={"calc(100vh - 150px)"}
        overflowX={"hidden"}
        zIndex="1003"
      >
        <Stack borderBottom={"1px solid #e5e7eb"} padding={"7px 10px"}>
          <InputGroup ml={[0, "0px"]} alignItems={"center"}>
            <InputLeftElement>
              <Icon as={FiSearch} />
            </InputLeftElement>
            <Input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              border={"none"}
              placeholder="Search"
              borderRadius={"3px"}
              h={["auto", "40px"]}
              _focusVisible={{
                border: "1px solid grey",
              }}
            />
          </InputGroup>
        </Stack>

        <Stack
          paddingTop={".5rem"}
          paddingBottom={".5rem"}
          paddingRight={"1.5rem"}
          paddingLeft={"1.5rem"}
          gap={0}
        >
          <Text
            marginTop={"0.5rem"}
            fontSize={"12px"}
            fontWeight={"400"}
            _light={{
              color: "customColor.gray.64748B",
            }}
            marginBottom={"1rem"}
          >
            I'm searching for
          </Text>

          <Grid templateColumns="repeat(3, 1fr)" gap={1}>
            {filteredData.map((item, i) => (
              <Button
                key={i}
                variant={"outline"}
                borderRadius={"4px"}
                fontSize={"11px"}
                _light={{
                  color: "customColor.black7",
                }}
                textAlign={"center"}
                padding={".25rem 0.5rem .25rem .5rem"}
                border={"1px solid #e5e7eb"}
                _hover={{
                  _light: {
                    color: "#3454d1",
                  },
                }}
                onClick={() => handleTitleClick(item.title)}
              >
                {item.title}
              </Button>
            ))}
          </Grid>
        </Stack>

        {selectedTitle && (
          <Stack gap={0} padding={".5rem 1.5rem .5rem 1.5rem"}>
            <Text
              fontSize={"15px"}
              fontWeight={"700"}
              _light={{
                color: "#3454d1",
              }}
              marginBottom={"10px"}
            >
              {selectedTitle}
            </Text>
            {managementData
              .find((item) => item.title === selectedTitle)
              ?.subArray.filter((subItem) =>
                subItem.menulabel
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              )
              .map((subItem, idx) => (
                <Stack
                  key={idx}
                  direction={"row"}
                  marginBottom={"1.5rem"}
                  justifyContent={"space-between"}
                  gap={0}
                >
                  <Stack direction={"row"} gap={3}>
                    <Stack
                      w={"40px"}
                      h={"40px"}
                      maxH={"40px"}
                      maxW={"40px"}
                      minH={"40px"}
                      minW={"40px"}
                      cursor={"pointer"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      bg={"#fff"}
                      border={"1px solid #dcdee4"}
                      borderRadius={"4px"}
                    >
                      <Icon as={subItem.menuIcon} h={"16px"} w={"16px"} />
                    </Stack>
                    <Stack gap={0}>
                      <Text
                        fontSize={".84rem"}
                        fontWeight={"700!important"}
                        marginBottom={".25rem!important"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _hover={{
                          _light: {
                            color: "#3454d1",
                          },
                        }}
                        onClick={() => handleSubArrayClick(subItem.href)}
                        cursor="pointer"
                      >
                        {subItem.menulabel}
                      </Text>
                      <Link
                        href={subItem.href}
                        fontSize={"11px"}
                        _light={{
                          color: "customColor.gray.64748B",
                        }}
                      >
                        {subItem.href}
                      </Link>
                    </Stack>
                  </Stack>
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    padding={"5px 6px"}
                    h={"28px"}
                    borderRadius={"4px"}
                    gap={0}
                    border={"1px solid #e5e7eb"}
                    direction={"row"}
                  >
                    <Text
                      fontSize={"11px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={600}
                      _hover={{
                        _light: {
                          color: "#3454d1",
                        },
                      }}
                    >
                      {subItem.menulabel}
                    </Text>
                  </Stack>
                </Stack>
              ))}
          </Stack>
        )}
      </PopoverContent>
    </Popover>
  );
}
