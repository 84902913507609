import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { getFieldKey } from "../../../../modules/form-apis";
import useDrawer from "../../hooks/drawersContext";
interface DocumentNumberProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function DocumentNumberFormatDrawer({
  isOpen,
  onClose,
}: DocumentNumberProps) {
  const template = useFormContext();
  const { questionItemDetails } = useDrawer();
  const [documentNumberFormat, setDocumentNumberFormat] = useState<string>(
    template.getValues(
      getFieldKey(
        questionItemDetails ?? { pageIndex: 0 },
        "question.properties.documentNumberFormat"
      )
    ) ?? "REF-[number]-[number]"
  );
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      size={{ xs: "full", sm: "sm" }}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      <DrawerContent
        backgroundColor={"backgrounds.header"}
        overflowY="auto"
        minH="100vh"
      >
        <DrawerHeader
          padding={"24px"}
          borderBottom={"1px solid"}
          borderColor={"borders.accent"}
        >
          <VStack>
            <Text
              w={"100%"}
              fontSize={"21px"}
              fontWeight={500}
              color={"textcolors.primary"}
              textAlign={"start"}
            >
              Document number format
            </Text>
            <Text
              w={"100%"}
              fontSize={"14px"}
              fontWeight={400}
              color={"icons.primary"}
              textAlign={"start"}
            >
              Each inspection generates an individual number. You can customize
              the format below:
            </Text>
          </VStack>
        </DrawerHeader>

        <DrawerBody
          minH={{ xs: "311px", md: "292px" }}
          maxH="292px"
          backgroundColor={"white"}
          padding="24px"
        >
          <Stack spacing={0}>
            <Text fontSize="14px" fontWeight={400} color="icons.primary">
              Preview
            </Text>
            <Text fontSize="14px" fontWeight={400} color="icons.primary">
              {["000001", "000002", "000003"]
                .map((num) => documentNumberFormat.replace("[number]", num))
                .join(", ")}
            </Text>
            <Box>
              <Divider
                orientation="horizontal"
                w="100%"
                borderWidth="0.8px"
                borderColor="borders.accent"
                margin="24px 0px"
              />
            </Box>
            <Text fontSize="14px" fontWeight={400} color="icons.primary">
              Document number format
            </Text>
            <Box w="100%">
              <Input
                value={documentNumberFormat}
                minH="41px"
                minW="100%"
                fontSize={"14px"}
                fontWeight={400}
                padding={"8px 16px"}
                marginTop="8px"
                flex={2}
                border="1px"
                borderStyle={"solid"}
                borderColor={"#abb5c4"}
                _focusVisible={{
                  boxShadow: "0px",
                  borderColor: "primary.500",
                }}
                width="auto"
                onChange={(e) => setDocumentNumberFormat(e.target.value)}
              />
            </Box>
            <Text
              fontSize="14px"
              fontWeight={400}
              color="icons.primary"
              marginTop="8"
            >
              Customize the document number format by inserting text. For
              example, "Safety R[number]" generates "Safety R000001, Safety
              R000002, ...".
            </Text>
          </Stack>
        </DrawerBody>

        <DrawerFooter
          borderTop="1px solid"
          borderColor={"borders.accent"}
          padding={"24px"}
          backgroundColor={"backgrounds.header"}
          flexDirection="column"
        >
          <Stack w="100%" spacing={0}>
            <Box flexDirection={{ xs: "column", md: "row" }}>
              <Button
                h="40px"
                w={"auto"}
                borderRadius="5px"
                fontSize="14px"
                fontWeight={500}
                marginRight="16px"
                padding={"12px 20px"}
                onClick={() => {
                  if (questionItemDetails?.questionIndex) {
                    template.setValue(
                      getFieldKey(
                        questionItemDetails,
                        "question.properties.documentNumberFormat"
                      ),
                      documentNumberFormat
                    );
                    onClose();
                  }
                }}
              >
                Save and apply
              </Button>
              <Button
                h="40px"
                borderRadius="5px"
                fontSize="14px"
                fontWeight={500}
                variant="outline"
                padding={"12px 20px"}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Box>
          </Stack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
