import { axiosInstance } from "./axiosInstance-aud";
import { InventoryCategoryModule } from "./InventoryCategories";

declare module InventoryModule {
  type AddInventory = {
    inventory: Array<InventoryData>;
  };

  type InventoryData = {
    name: string;
    description?: string;
    inventoryCategoryId: number | null;
    mediaUrl: string;
  };

  type FetchInventoryTypeResponse = {
    createdAt: string;
    updatedAt: string;
    id: number;
    deletedAt: null;
    name: string;
    description: string;
    inventoryCategoryId: number;
    inventoryCategory: InventoryCategoryModule.FetchInventoryCategoryResponse;
    mediaUrl: string;
  };
  type bulkIds = {
    ids: Array<number>;
  };
}
module InventoryModule {
  const URL = "/inventory-items";
  export function UploadInventory(
    data: Array<InventoryData>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(URL, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchInventory(
    params: {
      page?: number;
      limit?: number;
      search?: string;
      inventoryCategoryId?: number;
    },
    success: (
      response: Array<FetchInventoryTypeResponse>,
      total: number
    ) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(URL, {
        params,
      })
      .then(({ data }) => {
        success(data?.items, data?.total);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function UpdateInventoryItem(
    id: number,
    data: Partial<InventoryData>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function DeleteItem(
    id: number,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function bulkDelete(
    data: bulkIds,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${URL}/bulk-delete`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  // export function FetchInventoryTypeResponse(
  //   success: (items: Array<FetchInventoryTypeResponse>) => void,
  //   error: (message: string) => void,
  // ) {
  //   axiosInstance
  //     .get(URL)
  //     .then(({data}) => {
  //       success(data.items);
  //     })
  //     .catch(e => {
  //       error(e?.response?.data?.message ?? e?.message);
  //     });
  // }
}
export { InventoryModule };
