import {
  Box,
  Checkbox,
  Container,
  CSSReset,
  HStack,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Country, State } from "country-state-city";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FaMoneyBillWave, FaTruckMoving } from "react-icons/fa";
import { AddressInput } from "../../../../Common/AddressInput";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { ContactApi } from "../../../Api/Contact";

interface AddressProp {
  addressRef: React.RefObject<HTMLDivElement>;
}
export function Address({ addressRef }: AddressProp) {
  const form = useFormContext<ContactApi.AddContact>();
  let countryData = Country.getAllCountries();
  const [provinces, setProvinces] = useState<
    Array<{
      name: string;
    }>
  >([]);
  useEffect(() => {
    const provinceOptions = State.getStatesOfCountry(
      countryData.find(
        (country) => country.name === form.getValues("billingAddress.country")
      )?.isoCode
    );
    const province = provinceOptions.map((province: any) => {
      return { name: province?.name };
    });
    setProvinces(province);
  }, [form.watch("billingAddress.country")]);
  const [deliveryProvinces, setDeliveryProvinces] = useState<
    Array<{
      name: string;
    }>
  >([]);
  useEffect(() => {
    const provinceOptions = State.getStatesOfCountry(
      countryData.find(
        (country) => country.name === form.getValues("deliveryAddress.country")
      )?.isoCode
    );
    const province = provinceOptions.map((province: any) => {
      return { name: province?.name };
    });
    setDeliveryProvinces(province);
  }, [form.watch("deliveryAddress.country")]);

  const [billingAddressManually, setBillingAddressManually] = useState(false);
  const [deliveryAddressManually, setDeliveryAddressManually] = useState(false);
  const [sameAsDelivery, setSameAsDelivery] = useState(false);
  const [sameAsBilling, setSameAsBilling] = useState(false);
  return (
    <Stack
      ref={addressRef}
      _dark={{ bgColor: "customColor.dark.50" }}
      _light={{ bgColor: "customColor.white" }}
      paddingBottom={"35px"}
      borderRight={"1px solid #e5e7eb"}
      borderLeft={"1px solid #e5e7eb"}
      borderBottom={"2px solid #e5e7eb"}
    >
      <Container maxW={"450px"}>
        <Text
          padding={"16px 0px"}
          color={"customColor.black7"}
          margin={"14px 0px"}
          fontSize={"17px"}
          fontWeight={"700"}
        >
          Address
        </Text>
        <Stack spacing={"35px"}>
          {!sameAsDelivery && (
            <Stack
              border={"1px solid #e5e7eb"}
              padding={"16px"}
              borderRadius={"4px"}
            >
              <HStack justifyContent={"space-between"} alignItems={"center"}>
                <HStack alignItems={"center"} gap={0}>
                  <Icon as={FaMoneyBillWave} />
                  <Text
                    fontSize={"15px"}
                    color={"customColor.black7"}
                    margin={"0px 0px 0px 12px"}
                    fontWeight={"700"}
                  >
                    Billing address
                  </Text>
                </HStack>
                <HStack alignItems={"center"}>
                  <Text
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    fontSize=".8125rem"
                    fontWeight="700"
                    marginLeft="auto"
                  >
                    Enter address manually
                  </Text>
                  <Checkbox
                    size="md"
                    onChange={(e) => {
                      setBillingAddressManually(e.target.checked);
                      if (e.target.checked) {
                        form.setValue("billingAddress.addresss", "");
                        form.setValue("billingAddress.city", "");
                        form.setValue("billingAddress.country", "");
                        form.setValue("billingAddress.state", "");
                        form.setValue("billingAddress.zipCode", "");
                      }
                    }}
                  />
                </HStack>
              </HStack>
              {!billingAddressManually && (
                <>
                  <Stack gap={0}>
                    <Text
                      color={"customColor.gray.6b7885"}
                      fontSize={".845rem"}
                      fontWeight={"600"}
                    >
                      Search address
                    </Text>

                    <AddressInput
                      onPlaceSelected={(place) => {
                        form.setValue(
                          "billingAddress.addresss",
                          place?.formatted_address
                        );
                        form.setValue("billingAddress.city", place?.city);
                        form.setValue("billingAddress.country", place?.country);
                        form.setValue("billingAddress.state", place?.state);
                        form.setValue(
                          "billingAddress.zipCode",
                          place?.postcode
                        );
                      }}
                      input={{
                        _light: {
                          color: "customColor.black7",
                        },
                        borderTopRadius: "3px",
                        border: "1px solid #e5e7eb",
                        _hover: {
                          border: "1px solid hsl(0, 0%, 70%)",
                        },
                        _focus: {
                          borderWidth: "1px",

                          boxShadow: "0 0 0 1px #3454d1",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#3454d1",
                            boxShadow: "0 0 0 1px #3454d1",
                          },
                        },
                        _focusVisible: {
                          borderWidth: "1px",
                          boxShadow: "0 0 0 1px #3454d1",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#3454d1",
                            boxShadow: "0 0 0 1px #3454d1",
                          },
                        },
                        fontSize: ".845rem",
                        marginTop: "10px",
                        padding: "12px 15px",
                        variant: "outline",
                        placeholder: "Search address",
                        type: "text",
                      }}
                    />
                  </Stack>
                  <Stack direction={"row"} gap={0}>
                    <Text
                      fontSize={"12px"}
                      fontWeight={"600"}
                      color={"customColor.black7"}
                    >
                      {"Address: "}
                    </Text>
                    <Text
                      fontSize={"12px"}
                      fontWeight={"500"}
                      color={"customColor.black7"}
                    >
                      {form.watch("billingAddress.addresss")}
                    </Text>
                  </Stack>
                </>
              )}
              {billingAddressManually && (
                <Box>
                  <Controller
                    name="billingAddress.attention"
                    control={form.control}
                    // rules={{ maxLength: 20 }}
                    render={({ field }) => (
                      <CustomInput
                        withValidation
                        ref={field.ref}
                        input={{
                          ...field,

                          padding: "12px 15px",
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "text",

                          borderTopRadius: "3px",
                          marginTop: "1px",
                          alignContent: "center",
                          onChange: (e) => {
                            field.onChange(e.target.value);
                            if (sameAsBilling) {
                              form.setValue(
                                "deliveryAddress.attention",
                                e.target.value
                              );
                            }
                          },
                        }}
                        placeholder="Attention"
                      />
                    )}
                  />
                  <Controller
                    name="billingAddress.addresss"
                    control={form.control}
                    render={({ field }) => (
                      <CustomInput
                        withValidation
                        ref={field.ref}
                        input={{
                          ...field,

                          padding: "12px 15px",
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "text",
                          borderTopRadius: "3px",
                          alignContent: "center",
                          onChange: (e) => {
                            field.onChange(e.target.value);
                            if (sameAsBilling) {
                              form.setValue(
                                "deliveryAddress.addresss",
                                e.target.value
                              );
                            }
                          },
                        }}
                        placeholder="Street address or PO box"
                      />
                    )}
                  />
                  <Controller
                    name="billingAddress.city"
                    control={form.control}
                    // rules={{ maxLength: 20 }}
                    render={({ field }) => (
                      <CustomInput
                        withValidation
                        ref={field.ref}
                        input={{
                          ...field,

                          padding: "12px 15px",
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "text",

                          borderTopRadius: "3px",
                          marginTop: "1px",
                          alignContent: "center",
                          onChange: (e) => {
                            field.onChange(e.target.value);
                            if (sameAsBilling) {
                              form.setValue(
                                "deliveryAddress.city",
                                e.target.value
                              );
                            }
                          },
                        }}
                        placeholder="Town / City"
                      />
                    )}
                  />

                  <HStack gap={0} alignItems={"baseline"}>
                    <Stack flex={1}>
                      <Controller
                        control={form.control}
                        name="billingAddress.state"
                        render={({ field, fieldState }) => (
                          <ReactSelect
                            {...field}
                            key={field.value}
                            options={provinces}
                            ErrorMessage="Please Select State"
                            placeholder="Select..."
                            value={provinces?.find(
                              (op: any) => op.name === field.value
                            )}
                            onChange={(newValue: any) => {
                              field.onChange(newValue?.name);
                              if (sameAsBilling) {
                                form.setValue(
                                  "deliveryAddress.state",
                                  newValue?.name
                                );
                              }
                            }}
                            getOptionLabel={(option: any) => option.name}
                            getOptionValue={(option: any) => option.name}
                          />
                        )}
                      />
                    </Stack>
                    <Stack flex={1}>
                      <Controller
                        name="billingAddress.zipCode"
                        control={form.control}
                        // rules={{ maxLength: 10 }}
                        render={({ field }) => (
                          <CustomInput
                            withValidation
                            ref={field.ref}
                            input={{
                              ...field,
                              h: "38px",
                              padding: "12px 15px",
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "number",
                              onChange: (e) => {
                                field.onChange(e.target.value);
                                if (sameAsBilling) {
                                  form.setValue(
                                    "deliveryAddress.zipCode",
                                    e.target.value
                                  );
                                }
                              },
                              borderRightRadius: "3px",
                              alignContent: "center",
                            }}
                            placeholder="Postal / Zip code"
                          />
                        )}
                      />
                    </Stack>
                  </HStack>
                  <CSSReset />
                  <Box
                    textAlign="left"
                    h={"48px"}
                    marginTop="10px"
                    borderTopRadius="3px"
                    placeholder="Country"
                  >
                    {/* <Countryselector onChange={handleCountryChange} /> */}
                    <Controller
                      control={form.control}
                      name="billingAddress.country"
                      render={({ field, fieldState }) => (
                        <ReactSelect
                          {...field}
                          placeholder="Select Country"
                          // marginBottom="30px"
                          // width={"48em"}

                          withValidation
                          options={countryData}
                          value={countryData.find(
                            (op) => op.name === field.value
                          )}
                          onChange={(newValue: any) => {
                            field.onChange(newValue?.name);
                            if (sameAsBilling) {
                              form.setValue(
                                "deliveryAddress.country",
                                newValue?.name
                              );
                            }
                          }}
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option.name}
                        />
                      )}
                    />
                  </Box>
                </Box>
              )}
              <HStack alignItems={"center"} marginTop={"10px"}>
                <Text
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  fontSize=".8125rem"
                  fontWeight="700"
                  marginLeft="auto"
                >
                  Same as Delivery address
                </Text>
                <Checkbox
                  size="md"
                  onChange={(e) => {
                    setSameAsDelivery(e.target.checked);
                    if (e.target.checked) {
                      form.setValue(
                        "billingAddress.attention",
                        form.getValues("deliveryAddress.attention")
                      );
                      form.setValue(
                        "billingAddress.addresss",
                        form.getValues("deliveryAddress.addresss")
                      );
                      form.setValue(
                        "billingAddress.city",
                        form.getValues("deliveryAddress.city")
                      );
                      form.setValue(
                        "billingAddress.country",
                        form.getValues("deliveryAddress.country")
                      );
                      form.setValue(
                        "billingAddress.state",
                        form.getValues("deliveryAddress.state")
                      );
                      form.setValue(
                        "billingAddress.zipCode",
                        form.getValues("deliveryAddress.zipCode")
                      );
                    } else if (!e.target.checked) {
                      form.setValue("billingAddress.addresss", "");
                      form.setValue("billingAddress.city", "");
                      form.setValue("billingAddress.country", "");
                      form.setValue("billingAddress.state", "");
                      form.setValue("billingAddress.zipCode", "");
                    }
                  }}
                  // isChecked={hideInputs}
                  // onChange={handleCheckboxToggle}
                />
              </HStack>
            </Stack>
          )}

          {!sameAsBilling && (
            <Stack
              border={"1px solid #e5e7eb"}
              padding={"16px"}
              borderRadius={"4px"}
            >
              <HStack justifyContent={"space-between"} alignItems={"center"}>
                <HStack alignItems={"center"} gap={0}>
                  <Icon as={FaTruckMoving} />
                  <Text
                    fontSize={"15px"}
                    color={"customColor.black7"}
                    margin={"0px 0px 0px 12px"}
                    fontWeight={"700"}
                  >
                    Delivery address
                  </Text>
                </HStack>
                <HStack alignItems={"center"}>
                  <Text
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    fontSize=".8125rem"
                    fontWeight="700"
                    marginLeft="auto"
                  >
                    Enter address manually
                  </Text>
                  <Checkbox
                    size="md"
                    onChange={(e) => {
                      setDeliveryAddressManually(e.target.checked);
                      if (e.target.checked) {
                        form.setValue("deliveryAddress.addresss", "");
                        form.setValue("deliveryAddress.city", "");
                        form.setValue("deliveryAddress.country", "");
                        form.setValue("deliveryAddress.state", "");
                        form.setValue("deliveryAddress.zipCode", "");
                      }
                    }}
                  />
                </HStack>
              </HStack>
              {!deliveryAddressManually && (
                <>
                  <Stack gap={0}>
                    <Text
                      color={"customColor.gray.6b7885"}
                      fontSize={".845rem"}
                      fontWeight={"600"}
                    >
                      Search address
                    </Text>

                    <AddressInput
                      onPlaceSelected={(place) => {
                        form.setValue(
                          "deliveryAddress.addresss",
                          place?.formatted_address
                        );
                        form.setValue("deliveryAddress.city", place?.city);
                        form.setValue(
                          "deliveryAddress.country",
                          place?.country
                        );
                        form.setValue("deliveryAddress.state", place?.state);
                        form.setValue(
                          "deliveryAddress.zipCode",
                          place?.postcode
                        );
                      }}
                      input={{
                        _light: {
                          color: "customColor.black7",
                        },
                        borderTopRadius: "3px",
                        border: "1px solid #e5e7eb",
                        _hover: {
                          border: "1px solid hsl(0, 0%, 70%)",
                        },
                        _focus: {
                          borderWidth: "1px",

                          boxShadow: "0 0 0 1px #3454d1",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#3454d1",
                            boxShadow: "0 0 0 1px #3454d1",
                          },
                        },
                        _focusVisible: {
                          borderWidth: "1px",
                          boxShadow: "0 0 0 1px #3454d1",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#3454d1",
                            boxShadow: "0 0 0 1px #3454d1",
                          },
                        },
                        fontSize: ".845rem",
                        marginTop: "10px",
                        padding: "12px 15px",
                        variant: "outline",
                        placeholder: "Search address",
                        type: "text",
                      }}
                    />
                  </Stack>
                  <Stack direction={"row"} gap={0}>
                    <Text
                      fontSize={"12px"}
                      fontWeight={"600"}
                      color={"customColor.black7"}
                    >
                      {"Address: "}
                    </Text>
                    <Text
                      fontSize={"12px"}
                      fontWeight={"500"}
                      color={"customColor.black7"}
                    >
                      {form.watch("deliveryAddress.addresss")}
                    </Text>
                  </Stack>
                </>
              )}
              {deliveryAddressManually && (
                <Box>
                  <Controller
                    name="deliveryAddress.attention"
                    control={form.control}
                    // rules={{ maxLength: 20 }}
                    render={({ field }) => (
                      <CustomInput
                        withValidation
                        ref={field.ref}
                        input={{
                          ...field,

                          padding: "12px 15px",
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "text",

                          borderTopRadius: "3px",
                          marginTop: "1px",
                          alignContent: "center",
                          onChange: (e) => {
                            field.onChange(e.target.value);
                            if (sameAsDelivery) {
                              form.setValue(
                                "billingAddress.attention",
                                e.target.value
                              );
                            }
                          },
                        }}
                        placeholder="Attention"
                      />
                    )}
                  />
                  <Controller
                    name="deliveryAddress.addresss"
                    control={form.control}
                    render={({ field }) => (
                      <CustomInput
                        withValidation
                        ref={field.ref}
                        input={{
                          ...field,

                          padding: "12px 15px",
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "text",
                          borderTopRadius: "3px",
                          alignContent: "center",
                          onChange: (e) => {
                            field.onChange(e.target.value);
                            if (sameAsDelivery) {
                              form.setValue(
                                "billingAddress.addresss",
                                e.target.value
                              );
                            }
                          },
                        }}
                        placeholder="Street address or PO box"
                      />
                    )}
                  />
                  <Controller
                    name="deliveryAddress.city"
                    control={form.control}
                    // rules={{ maxLength: 20 }}
                    render={({ field }) => (
                      <CustomInput
                        withValidation
                        ref={field.ref}
                        input={{
                          ...field,

                          padding: "12px 15px",
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "text",

                          borderTopRadius: "3px",
                          marginTop: "1px",
                          alignContent: "center",
                          onChange: (e) => {
                            field.onChange(e.target.value);
                            if (sameAsDelivery) {
                              form.setValue(
                                "billingAddress.city",
                                e.target.value
                              );
                            }
                          },
                        }}
                        placeholder="Town / City"
                      />
                    )}
                  />

                  <HStack gap={0} alignItems={"baseline"}>
                    <Stack flex={1}>
                      <Controller
                        control={form.control}
                        name="deliveryAddress.state"
                        render={({ field, fieldState }) => (
                          <ReactSelect
                            {...field}
                            key={field.value}
                            options={deliveryProvinces}
                            ErrorMessage="Please Select State"
                            placeholder="Select..."
                            value={deliveryProvinces?.find(
                              (op: any) => op.name === field.value
                            )}
                            onChange={(newValue: any) => {
                              field.onChange(newValue?.name);
                              if (sameAsDelivery) {
                                form.setValue(
                                  "billingAddress.state",
                                  newValue?.name
                                );
                              }
                            }}
                            getOptionLabel={(option: any) => option.name}
                            getOptionValue={(option: any) => option.name}
                          />
                        )}
                      />
                    </Stack>
                    <Stack flex={1}>
                      <Controller
                        name="deliveryAddress.zipCode"
                        control={form.control}
                        // rules={{ maxLength: 10 }}
                        render={({ field }) => (
                          <CustomInput
                            withValidation
                            ref={field.ref}
                            input={{
                              ...field,
                              h: "38px",
                              padding: "12px 15px",
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "number",
                              onChange: (e) => {
                                field.onChange(e.target.value);
                                if (sameAsDelivery) {
                                  form.setValue(
                                    "billingAddress.zipCode",
                                    e.target.value
                                  );
                                }
                              },
                              borderRightRadius: "3px",
                              alignContent: "center",
                            }}
                            placeholder="Postal / Zip code"
                          />
                        )}
                      />
                    </Stack>
                  </HStack>
                  <CSSReset />
                  <Box
                    textAlign="left"
                    h={"48px"}
                    marginTop="10px"
                    borderTopRadius="3px"
                    placeholder="Country"
                  >
                    {/* <Countryselector onChange={handleCountryChange} /> */}
                    <Controller
                      control={form.control}
                      name="deliveryAddress.country"
                      render={({ field, fieldState }) => (
                        <ReactSelect
                          {...field}
                          placeholder="Select Country"
                          // marginBottom="30px"
                          // width={"48em"}

                          withValidation
                          options={countryData}
                          value={countryData.find(
                            (op) => op.name === field.value
                          )}
                          onChange={(newValue: any) => {
                            field.onChange(newValue?.name);
                            if (sameAsDelivery) {
                              form.setValue(
                                "billingAddress.country",
                                newValue?.name
                              );
                            }
                          }}
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option.name}
                        />
                      )}
                    />
                  </Box>
                </Box>
              )}
              <HStack alignItems={"center"} marginTop={"10px"}>
                <Text
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  fontSize=".8125rem"
                  fontWeight="700"
                  marginLeft="auto"
                >
                  Same as Billing address
                </Text>
                <Checkbox
                  size="md"
                  onChange={(e) => {
                    setSameAsBilling(e.target.checked);
                    if (e.target.checked) {
                      form.setValue(
                        "deliveryAddress.attention",
                        form.getValues("billingAddress.attention")
                      );
                      form.setValue(
                        "deliveryAddress.addresss",
                        form.getValues("billingAddress.addresss")
                      );
                      form.setValue(
                        "deliveryAddress.city",
                        form.getValues("billingAddress.city")
                      );
                      form.setValue(
                        "deliveryAddress.country",
                        form.getValues("billingAddress.country")
                      );
                      form.setValue(
                        "deliveryAddress.state",
                        form.getValues("billingAddress.state")
                      );
                      form.setValue(
                        "deliveryAddress.zipCode",
                        form.getValues("billingAddress.zipCode")
                      );
                    } else if (!e.target.checked) {
                      form.setValue("deliveryAddress.addresss", "");
                      form.setValue("deliveryAddress.city", "");
                      form.setValue("deliveryAddress.country", "");
                      form.setValue("deliveryAddress.state", "");
                      form.setValue("deliveryAddress.zipCode", "");
                    }
                  }}
                  // isChecked={hideInputs}
                  // onChange={handleCheckboxToggle}
                />
              </HStack>
            </Stack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
}
