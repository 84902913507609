import { QuestionIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { ReactSelectForPayRollSetting } from "../../../../Common/ReactSelectForPayRollSetting";
import { PayItemRetirement } from "../../../Api/PayItemRetirement";
import { PayTemp } from "../../../Api/PayTemp";

interface StaturyBenefitsModalProps {
  isOpen: boolean;
  onClose: () => void;
  resetData: {
    payItemRetirementId: string;
    calculationType: PayTemp.PayTemplateCalcTypeEnum | string;
    index?: number;
  };

  onPressOK: (data: {
    payItemRetirementId: string;
    calculationType: PayTemp.PayTemplateCalcTypeEnum | string;
    category: PayTemp.PayTemplateItemTypeEnum;
    index?: number;
    minimumWage?: number;
    percentage?: number;
    payItemRetirement: {
      earningPayItems: Array<string>;
      minimumWage?: number;
    };
  }) => void;
  account: Array<PayItemRetirement.FetchRetirementFund>;
}
export function StaturyBenefitsModal({
  isOpen,
  resetData,
  onClose,
  onPressOK,
  account,
}: StaturyBenefitsModalProps) {
  const [eobiSetting, setEobiSetting] = useState<{
    minimumWage?: number;
    percentage?: number;
  }>();
  const [payItemId, setPayItemId] = useState("");
  const [calculationType, setCalculationType] = useState<
    PayTemp.PayTemplateCalcTypeEnum | string
  >("");
  const [earningPayItems, setEarningPayItems] = useState<Array<string>>([]);
  const [index, setIndex] = useState<number | undefined>(resetData?.index);
  const handlePayItemChange = (newValue: any) => {
    const calculationType =
      newValue?.category === PayTemp.PayTemplateCalcTypeEnum.EMPLOYER_EOBI
        ? PayTemp.PayTemplateCalcTypeEnum.EMPLOYER_EOBI
        : newValue?.category ===
          PayTemp.PayTemplateCalcTypeEnum.EMPLOYER_PROVIDENT_FUND
        ? PayTemp.PayTemplateCalcTypeEnum.EMPLOYER_PROVIDENT_FUND
        : newValue?.category === PayTemp.PayTemplateCalcTypeEnum.SOCIAL_SECURITY
        ? PayTemp.PayTemplateCalcTypeEnum.SOCIAL_SECURITY
        : newValue?.category === PayTemp.PayTemplateCalcTypeEnum.GRATUITY
        ? PayTemp.PayTemplateCalcTypeEnum.GRATUITY
        : "";
    setCalculationType(calculationType);
    setPayItemId(newValue?.id);
    setEobiSetting({
      minimumWage: newValue?.minimumWage,
      percentage: newValue?.percentage,
    });
    setEarningPayItems(newValue?.earningPayItems ?? []);
  };

  useEffect(() => {
    setPayItemId(resetData.payItemRetirementId);
    setIndex(resetData.index);
  }, [resetData]);
  return (
    <Modal size="sm" isOpen={isOpen} onClose={() => onClose()} isCentered>
      <ModalOverlay />
      <ModalContent maxW={"420px"}>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Add an Retirement Line
        </ModalHeader>
        <ModalCloseButton />
        <Divider orientation="horizontal" />
        <ModalBody
          border="1px solid #ddd"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "gray.50",
          }}
        >
          <Stack maxW={"300px"} gap={0}>
            <Text
              fontSize={"12px"}
              marginBottom={"8px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.dark.250",
              }}
              fontWeight={"bold"}
            >
              Statuary Benefit <Icon color="teal.500" as={QuestionIcon} />
            </Text>

            <ReactSelectForPayRollSetting
              placeholder="Select"
              ErrorMessage="Select Account type"
              withValidation
              options={account}
              value={{
                id: payItemId,
                name:
                  account.find((account) => account.id === payItemId)?.name ??
                  "",
              }}
              onChange={handlePayItemChange}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
            />
            {/* <CustomInput
              withValidation
              input={{
                isInvalid: false,
                fontSize: "12px",
                variant: "outline",
                type: "text",
                value: description,
                onChange: (e: any) => handleDescriptionChange(e.target.value),
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                height: "25px",
                padding: "0px 0px 0px 3px",
                boxShadow: "none",
                placeholder: "",
                _hover: {
                  borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                },
              }}
              label="Description"
              labelProps={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "#444",
                marginBottom: "5px",
              }}
            /> */}

            <FormControl>
              <FormLabel
                mb={0}
                fontWeight="bold"
                marginTop="10px"
                fontSize="12px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.250",
                }}
              >
                Control of Expense Account
              </FormLabel>

              <Text
                textAlign={"left"}
                fontSize="12px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
                marginTop={"5px"}
                marginBottom={"5px"}
                borderTop={"1px solid #999"}
                borderBottom={"1px solid #ccc"}
              >
                {/* {
                  account.find((account) => account.id === payItemId)
                    ?.chartOfAccount?.code
                }
                {":"}
                {
                  account.find((account) => account.id === payItemId)
                    ?.chartOfAccount?.name
                } */}
              </Text>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter h="50px">
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            mr={2}
            type="submit"
            onClick={() => {
              onPressOK({
                payItemRetirementId: payItemId,
                calculationType,
                index: index,
                payItemRetirement: {
                  earningPayItems: earningPayItems,
                  minimumWage: eobiSetting?.minimumWage,
                },
                category: PayTemp.PayTemplateItemTypeEnum.RETIREMENT,
                ...eobiSetting,
              });
              onClose();
            }}
          >
            Add
          </Button>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            color={"#ea4d4d"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
