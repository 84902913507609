import { Box } from "@chakra-ui/react";
import { ArcElement, Chart, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
Chart.register(ArcElement, Tooltip, Legend);
interface StaffChartProps {
  maleGenderEmpCount?: string;
  femaleGenderEmpCount?: string;
  otherGenderEmpCount?: string;
}
export default function StaffChart({
  femaleGenderEmpCount,
  maleGenderEmpCount,
  otherGenderEmpCount,
}: StaffChartProps) {
  const staff = {
    labels: ["Male", "Female", "Others"],
    datasets: [
      {
        data: [
          maleGenderEmpCount,
          femaleGenderEmpCount,
          otherGenderEmpCount,
        ].map(Number),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 255, 0, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 255, 0, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem: any) {
            const value = tooltipItem.raw;
            return value > 0 ? `${tooltipItem.label}: ${value}` : ""; // Hide 0 values
          },
        },
        bodyFont: {
          size: 10,
        },
        titleFont: {
          size: 10,
        },
      },
      datalabels: {
        display: function (context: any) {
          return context.dataset.data[context.dataIndex] > 0; // Only show labels if the value is greater than 0
        },
      },
    },
  };
  return (
    <Box w="60px" h="60px" marginRight={"7px"}>
      {/* <CircularProgress
                  value={42}
                  color="orange.400"
                  thickness="11px"
                  size="40px"
                /> */}
      <Doughnut data={staff} options={options} />
    </Box>
  );
}
