import {Template} from '../../../modules/Audit';
import {ReportLayout} from '../../../modules/Audit/ReportLayout';

type ExistingReportStructureProppertiesType = {
  alignment: string;
  display: boolean;
  orderIndex?: number;
};
export type ExistingReportStructureType = {
  innerChildren: {
    [id: number]: ExistingReportStructureProppertiesType;
  };
  children: {
    [id: number]: ExistingReportStructureProppertiesType;
  };
};
export type ExludeItemsFromTemplateItems = {
  innerChildren: Array<number>;
  children: Array<number>;
};
export function restructureTemplateItems(
  array: Template.TemplateItem[],
  existingStructure: ExistingReportStructureType,
) {
  return array.map((page, pageIndex) => {
    return {
      type: page.type,
      id: page.id!,
      pageId: page.id!,
      display: true,

      title: page.title,
      children:
        page.children
          ?.sort((a, b) => {
            return (
              existingStructure.children[a.id!]?.orderIndex! -
              existingStructure.children[b.id!]?.orderIndex!
            );
          })
          .map((child, childIndex) => {
            return {
              type: child.type,
              question: child.question,
              id: child.id,
              title: child.title,
              pageId: page.id,
              sectionId: child.type === 'SECTION' ? child.id : undefined,
              questionId: child.type === 'QUESTION' ? child.id : undefined,
              orderIndex: childIndex,
              display: existingStructure.children[child.id!]?.display ?? false,
              alignment:
                child.type === 'QUESTION'
                  ? existingStructure.children[child.id!]?.alignment ?? 'left'
                  : undefined,
              children:
                child.children
                  ?.sort((a, b) => {
                    return (
                      existingStructure.innerChildren[a.id!]?.orderIndex! -
                      existingStructure.innerChildren[b.id!]?.orderIndex!
                    );
                  })
                  .map((innerChild, innerChildIndex) => {
                    return {
                      type: innerChild.type,
                      question: innerChild.question,
                      id: innerChild.id,
                      title: innerChild.title,
                      pageId: page.id,
                      sectionId:
                        innerChild.type === 'SECTION'
                          ? innerChild.id
                          : undefined,
                      questionId:
                        innerChild.type === 'QUESTION'
                          ? innerChild.id
                          : undefined,
                      display:
                        existingStructure.innerChildren[innerChild.id!]
                          ?.display ?? false,
                      alignment:
                        existingStructure.innerChildren[innerChild.id!]
                          ?.alignment ?? 'left',
                      orderIndex: innerChildIndex,
                    } as ReportLayout.ReportStructureItem;
                  }) ?? [],
            } as ReportLayout.ReportStructureItem;
          }) ?? [],
    };
  });
}
