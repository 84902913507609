import { Button, Stack, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { BiImages } from "react-icons/bi";
import { UploadAttachments } from "../../../../Payroll/Api/UploadAttachments";
import { fileToBase64 } from "../../../modules/api";
import { Inspection, Template } from "../../../modules/Audit";
import { compressImage } from "../../../modules/utils";
import { useInspectionContext } from "../hooks/InspectionContext";
import { useAuditQuestionContainer } from "./AuditQuestionContainer";

export default function Media() {
  const template = useFormContext();
  const { getItemField } = useAuditQuestionContainer();
  const { setIsSaving, isSaving } = useInspectionContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const banner = template.getValues(getItemField("question.properties.banner"));

  const medias = useFieldArray({
    control: template.control,
    name: getItemField("question.media"),
    keyName: "uniqueId",
  });

  const [uploadStatus, setUploadStatus] = useState({});
  const toast = useToast({
    position: "top",
  });
  const questionType = useWatch({
    control: template.control,
    name: getItemField("question.type"),
  });
  const [deletedMediaIds, setDeletedMediaIds] = useState<Array<number>>([]);
  const handleFileUpload = async (file: File, index: any) => {
    try {
      const compressedImage = await compressImage(file);
      const base64 = await fileToBase64(compressedImage);
      if (base64) {
        const formData = new FormData();
        formData.append("docType", "INSPECTION");
        formData.append("base64", base64);
        formData.append("fileName", file.name);
        formData.append("inspectionId", template.getValues("mediaUniqueId"));
        UploadAttachments.UploadAttachment(
          formData,
          (path) => {
            if (banner) {
              medias.replace([
                {
                  mediaType: file.type,
                  mediaUrl: path.url,
                  mediaName: file.name,
                  generic: true,
                } as Template.MediaObject,
              ]);
            } else {
              medias.append({
                mediaType: file.type,
                mediaUrl: path.url,
                mediaName: file.name,
                generic: true,
              } as Template.MediaObject);
            }
            setUploadStatus((prev) => ({ ...prev, [index]: "complete" }));
          },
          (err) => {
            toast({
              title: "Error",
              description: err,
            });
            setUploadStatus((prev) => ({ ...prev, [index]: "error" }));
          }
        );
      }
    } catch (err: any) {
      toast({
        title: "Error",
        description: err.message || "An error occured while uploading media",
      });
      setUploadStatus((prev) => ({ ...prev, [index]: "error" }));
    }
  };

  const handleChange = async (e: any) => {
    const files = e.target.files;
    if (files) {
      setUploadStatus({});
      if (banner) {
        setDeletedMediaIds(medias.fields.map((m: any) => m.id!));
        handleFileUpload(files[0], 0);
        if (questionType === "MEDIA") {
          files.length > 0
            ? template.setValue(
                getItemField("question.questionValue"),
                files.length
              )
            : template.setValue(getItemField("question.questionValue"), null);
        }
      } else {
        for (let i = 0; i < files.length; i++) {
          setUploadStatus((prev) => ({ ...prev, [i]: "uploading" }));
          handleFileUpload(files[i], i);
          setIsSaving(true);
          if (questionType === "MEDIA") {
            files.length > 0
              ? template.setValue(
                  getItemField("question.questionValue"),
                  files.length
                )
              : template.setValue(getItemField("question.questionValue"), null);
          }
        }
      }
    }
  };

  const allUploadsComplete = () =>
    Object.values(uploadStatus).every((status) => status === "complete");

  const callAPIAfterUpload = () => {
    if (allUploadsComplete()) {
      const answerId = template.getValues(getItemField("question.answerId"));
      const type = template.getValues(getItemField("question.type"));
      const inspectionMetaID = template.getValues("inspection.id");

      setIsSaving(true);
      console.log(medias.fields.length);
      Inspection.CachingInspection(
        answerId,
        {
          inspection_meta_id: inspectionMetaID,
          type: type,
          media: medias.fields as unknown as Array<Template.MediaObject>,
          deletedMedia: deletedMediaIds,
          question_value:
            medias.fields.length > 0 ? medias.fields.length.toString() : null,
        },
        (success) => {
          medias.replace(success?.media ?? []);
          setIsSaving(false);
        },
        () => {
          setIsSaving(false);
        }
      );
    }
  };

  useEffect(() => {
    if (Object.keys(uploadStatus).length > 0) {
      callAPIAfterUpload();
    }
  }, [uploadStatus]);

  return (
    <Stack>
      <Stack justifyContent="flex-end" direction={{ xs: "column", md: "row" }}>
        <Button
          h="41px"
          variant="solid"
          borderRadius="8px"
          padding="12px 20px"
          fontSize="14px"
          fontWeight="500"
          leftIcon={<BiImages size="17px" />}
          onClick={() => {
            inputRef.current?.click();
          }}
        >
          Add media
        </Button>
        <input
          hidden
          accept="image/*,video/*,.pdf"
          multiple={!banner}
          type="file"
          ref={inputRef}
          onChange={
            handleChange
            //   async e => {
            //   const files = e.target.files;
            //   if (files) {
            //     for (let i = 0; i < files.length; i++) {
            //       const file = files[i];

            //       const compressedImage = await compressImage(file);

            //       const base64 = await fileToBase64(compressedImage);

            //       if (base64) {
            //         Inspection.UploadMedia(
            //           {
            //             base64,
            //             fileName: file.name,
            //             inspectionId: template.getValues('mediaUniqueId'),
            //           },
            //           path => {
            //             medias.append({
            //               mediaUrl: path,
            //               mediaName: file.name,
            //               generic: true,
            //             } as Template.MediaObject);
            //           },
            //           err => {
            //             toast({
            //               title: 'Error',
            //               description: err,
            //             });
            //           },
            //         );
            //       }
            //     }
            //     console.log(medias.fields);
            //     const media: Array<any> = template.getValues(
            //       getItemField('question.media'),
            //     );
            //     template.setValue(
            //       getItemField('question.questionValue'),
            //       media?.length > 0 ? media.length : undefined,
            //       {shouldValidate: true},
            //     );
            //   }
            // }
          }
        />
      </Stack>

      {/* <Wrap mt="16px">
        {(medias.fields as unknown as Array<Template.MediaObject>)?.map(
          (media, i) => (
            <WrapItem key={i}>
              
              <ImageView
                onclick={() => {
                  medias.remove(i);
                }}
                src={media.mediaUrl}
              />
            </WrapItem>
          ),
        )}
      </Wrap>
      <PdfView /> */}
    </Stack>
  );
}
