import {
  Button,
  HStack,
  Spinner,
  Stack,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import FileSaver from "file-saver";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiOutlineDownload } from "react-icons/ai";
import { MdOutlineArrowBack } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { Report, TemplateMeta } from "../../../../../../modules/Audit";
import { axiosInstance } from "../../../../../../modules/Audit/axiosInstance-aud";
import FlaggedItems from "./FlaggedItems";
import InspectionDocuments from "./InspectionDocuments";
import InspectionInventory from "./InspectionInventory";
import MediaSummary from "./MediaSummary";
import { Overview } from "./OverViewItems";
import PeriodicalActivities from "./PeriodicalActivities";
import ReportPage from "./ReportPage";

export default function WebPreview() {
  const { id } = useParams();
  const form = useForm<Report.IReport>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useBoolean(true);
  const [overviewData, setOverviewData] = useState<
    TemplateMeta.TemplateMeta | undefined
  >();
  const [isInProgress, setIsInProgress] = useBoolean(false);
  useEffect(() => {
    if (id) {
      Report.FetchReportbyId(
        +id,
        (data) => {
          form.reset(data);
          setIsLoading.off();
          // calculatePageScores(data.pages);
        },
        (err) => {
          toast({
            title: "Error",
            description: "Failed to fetch report",
            status: "error",
          });
          setIsLoading.off();
        }
      );
    }
  }, []);
  const toast = useToast();
  return (
    <FormProvider {...form}>
      <Stack h="calc(100vh - 70px)">
        <HStack
          backgroundColor="white"
          px="28px"
          py="11px"
          h="68px"
          justifyContent="space-between"
          zIndex={2}
          position="sticky"
          top={0}
          borderBottom="1px"
          borderColor={"borders.accent"}
        >
          <Button
            display="flex"
            alignItems="center"
            justifyContent="center"
            leftIcon={<MdOutlineArrowBack size="24px" />}
            fontSize="14px"
            fontWeight={400}
            color="textcolor.primary"
            variant={"unstyled"}
            onClick={() => {
              navigate(-1);
            }}
          >
            Back to list
          </Button>
          <Button
            isLoading={isInProgress}
            // loadingText={`Downloaded ${percentage}%`}
            px="21px"
            py="12px"
            h="44px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            lineHeight={1}
            leftIcon={<AiOutlineDownload size="24px" />}
            fontSize="14px"
            fontWeight={500}
            textTransform="uppercase"
            borderRadius="8px"
            variant={"outline"}
            onClick={() => {
              setIsInProgress.on();
              if (id) {
                let URL = `inspection-meta/download-report/${id}`;
                let site = form.getValues(`templateMeta.site`);
                let date = form.getValues(`templateMeta.inspectionDate`);
                let formattedDate = moment(date).format("DD MMM YYYY");
                let person = form.getValues(`templateMeta.person`);

                const fileNameComponents: Array<string> = [];

                if (site) fileNameComponents.push(site);
                if (date) fileNameComponents.push(formattedDate);
                if (person) fileNameComponents.push(person);

                axiosInstance({
                  url: `${process.env.REACT_APP_API_URL_AUDITOR}/${URL}`,
                  // url: `http://localhost:3000/${URL}`,
                  method: "GET",
                  responseType: "blob", // Important
                })
                  .then((response) => {
                    const blob = new Blob([response.data]);
                    FileSaver.saveAs(
                      blob,
                      fileNameComponents.length > 0
                        ? `${fileNameComponents.join(" - ")}.pdf`
                        : "unnamed.pdf"
                    );
                    setIsInProgress.off();
                  })
                  .catch((err) => {
                    toast({
                      title: "Error",
                      description: "Failed to download report",
                      status: "error",
                    });
                    setIsInProgress.off();
                  });

                // download(
                //   // `${process.env.REACT_APP_API_URL}/${URL}`,
                //   `http://localhost:3000/${URL}`,
                //   // `${form.getValues('templateMeta.inspectionDate')}.pdf`,
                //   fileNameComponents.length > 0
                //     ? `${fileNameComponents.join(' - ')}.pdf`
                //     : 'unnamed.pdf',
                // );
              }
            }}
          >
            pdf
          </Button>
        </HStack>
        {isLoading ? (
          <Stack w="100%" h="80vh" justify="center" align="center">
            <Spinner color="primary.500" size="xl" />
          </Stack>
        ) : (
          <Stack spacing="16px" my="16px" align="center" h="calc(100vh - 70px)">
            <Overview />
            {/* <Disclaimer />
        <FlaggedItems />
        <Actions /> */}
            {form.getValues("flaggedItems")?.length > 0 && <FlaggedItems />}
            {form?.getValues("pages")?.map((page, pageIndex) => {
              return <ReportPage pageIndex={pageIndex} />;
            })}
            {form.getValues("inspectionInventory")?.length > 0 && (
              <InspectionInventory />
            )}
            {form.getValues("periodicScheduleEvents")?.length > 0 && (
              <PeriodicalActivities />
            )}
            {form.getValues("inspectionMedia")?.length > 0 && <MediaSummary />}
            {form.getValues("templateMedia")?.length > 0 && (
              <InspectionDocuments />
            )}
          </Stack>
        )}
      </Stack>
    </FormProvider>
  );
}
