import {
  Box,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import { useAuthHeader } from "react-auth-kit";
import { useFormContext } from "react-hook-form";
import { EmployeeLeaveEntitlement } from "../../../../Api/EmployeeLeaveEntitlement";
import { PayItemLeaveApi } from "../../../../Api/PayItemLeave";
import { AssignType } from "../../Modal/AssignType";
interface LeaveEligibilityBalanceProps {
  LeavesEligibilityBalance: Array<EmployeeLeaveEntitlement.LeaveEntitlement>;
  payItemLeaves: Array<PayItemLeaveApi.LeaveById>;
  empId: string;
  setLastUpdated: (timeStamp: number) => void;
}
export default function LeaveEligibilityBalance({
  LeavesEligibilityBalance,
  payItemLeaves,
  empId,
  setLastUpdated,
}: LeaveEligibilityBalanceProps) {
  const assignType = useDisclosure();
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());

  const form = useFormContext<EmployeeLeaveEntitlement.LeaveEntitlement>();
  return (
    <SimpleGrid
      columns={{ xs: 2, md: 3 }}
      spacing={{ xs: 4, md: 10 }}
      p="30px 20px"
    >
      {LeavesEligibilityBalance.map((leave, i) => (
        <Stack
          paddingRight={"30px"}
          borderRight={"1px solid #ddd"}
          key={i}
          cursor={
            decodedToken.accessLevel === "EMPLOYEE" ? "not-allowed" : "pointer"
          }
        >
          <Text
            fontWeight={"600"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{ color: "customColor.white" }}
            fontSize="13.44px"
          >
            {
              payItemLeaves.find(
                (payItemLeave) =>
                  payItemLeave.id === leave.payrollPayItemLeaveId
              )?.name
            }
          </Text>
          <Box>
            <HStack
              onClick={() => {
                if (decodedToken.accessLevel !== "EMPLOYEE") {
                  form.reset(leave);
                  assignType.onOpen();
                }
              }}
            >
              <Text color={"primary.950"} fontWeight={"bold"} fontSize={"14px"}>
                Entitlement
              </Text>
              <Text color={"#E97132"} fontWeight={"bold"} fontSize={"12px"}>
                {leave.accruedQuantity}
              </Text>
              <Text
                color={"#E97132"}
                fontWeight={"bold"}
                fontSize={"12px"}
                marginLeft={"-5px"}
              >
                Days
              </Text>
            </HStack>
            <HStack
              onClick={() => {
                if (decodedToken.accessLevel !== "EMPLOYEE") {
                  form.reset(leave);
                  assignType.onOpen();
                }
              }}
            >
              <Text color={"primary.950"} fontWeight={"bold"} fontSize={"14px"}>
                Current Balance
              </Text>
              <Text color={"#E97132"} fontWeight={"bold"} fontSize={"12px"}>
                {leave.balance}
              </Text>
              <Text
                color={"#E97132"}
                fontWeight={"bold"}
                fontSize={"12px"}
                marginLeft={"-5px"}
              >
                Days
              </Text>
            </HStack>
          </Box>
        </Stack>
      ))}
      <AssignType
        isOpen={assignType.isOpen}
        onClose={assignType.onClose}
        FecthedLeaves={payItemLeaves}
        empId={empId}
        setlastUpdated={setLastUpdated}
      />
    </SimpleGrid>
  );
}
