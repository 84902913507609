import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
// import { differenceInDays, differenceInMonths, parseISO } from "date-fns";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NavigateFunction, useParams } from "react-router-dom";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { Correspondence } from "../../../Api/Correspondence";
import { EmployeeSeparationApi } from "../../../Api/EmployeeSeparationApi";
import HistoryAccordion from "../../Superannuation/History/HistoryAccordion";
import ForceFullySeparateAlertModal from "./Modals/ForceFullySeparateAlertModal";
interface TabProps {
  navigate: NavigateFunction;
}
export function EmployeeSeparation({ navigate }: TabProps) {
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState<string>("");

  const terminationReason = [
    "Resignation - Undisclosed Reason",
    "Resignation - Domestic reason",
    "Resignation - (Family Commitment)",
    "Resignation - Better Prospects (Local)",
    "Resignation - Better Prospects (Overseas)",
    "Resignation - Further Studies",
    "Resignation - Solicited",
    "Termination - Fraud",
    "Termination - Insubordination",
    "Termination - No Show",
    "Termination - Harassment",
    "Termination - Misconduct",
    "Termination - Contract Conclusion",
    "III health",
    "Deceased",
    "Redundancy",
    "Contract cessation",
    "Transfer",
  ].map((options) => ({ label: options, value: options }));
  const form = useForm<EmployeeSeparationApi.SeparationData>({
    defaultValues: {
      separationDate: "",
      reason: "",
      interviewDate: "",
      resignationDate: "",
      waiveOffNoticePeriod: false,
      comments: "",
      clearances: [{ clientCorrespondenceId: "" }],
    },
  });

  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const [SeparationData, setSeparationData] =
    useState<EmployeeSeparationApi.FetchSeperationData>();
  const [separationDate, setSeparationDate] = useState<string | undefined>(
    undefined
  );
  useEffect(() => {
    if (id) {
      EmployeeSeparationApi.FetchEmployeeSeparation(
        id,
        (data) => {
          form.reset(data);
          setSeparationData(data);
        },
        (error) => {
          toast({
            title: error,
            status: "error",
          });
        }
      );
    } else {
      toast({
        title: "Employee not found",
        status: "error",
      });
    }
  }, [id]);
  const [CorrespondenceData, setCorrespondenceData] = useState<
    Array<Correspondence.FetchCorrespondence>
  >([]);
  useEffect(() => {
    Correspondence.GetCorrespondenceDetails(
      (data) => {
        setCorrespondenceData(data);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      }
    );
  }, []);
  const [selectedId, setSelectedId] = useState<string[]>([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedRows, setCheckedRows] = useState<number[]>([]);

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isAllChecked = event.target.checked;
    if (isAllChecked) {
      const allRowIds = CorrespondenceData.map((item, i) => i);
      setCheckedRows(isCheckedAll ? [] : allRowIds);

      setSelectedId(CorrespondenceData.map((item) => item.id));
    } else {
      setCheckedRows([]);
      setSelectedId([]);
    }

    setIsCheckedAll(isAllChecked);
  };

  const handleCheckRow = (rowIndex: number) => {
    const updatedRows = [...checkedRows];
    const rowIndexInCheckedRows = updatedRows.indexOf(rowIndex);

    if (rowIndexInCheckedRows !== -1) {
      updatedRows.splice(rowIndexInCheckedRows, 1);
    } else {
      updatedRows.push(rowIndex);
    }

    setCheckedRows(updatedRows);
    setIsCheckedAll(updatedRows.length === CorrespondenceData.length);
    const selectedIds = updatedRows.map(
      (index) => CorrespondenceData?.[index]?.id
    );
    setSelectedId(selectedIds);
  };
  const calculateDuration = (resignDate: any, separationDate: any) => {
    if (!resignDate || !separationDate) return;

    const startDate = moment(resignDate);
    const endDate = moment(separationDate);

    const duration = moment.duration(endDate.diff(startDate));
    const months = duration.months();
    const days = duration.days();

    setDuration(`${duration.years() * 12 + months} months and ${days} days`);
  };

  useEffect(() => {
    const { resignationDate, separationDate } = form.getValues();
    calculateDuration(resignationDate, separationDate);
  }, [form.watch("resignationDate"), form.watch("separationDate")]);
  const forceFully = useDisclosure();
  const [forceFullySeparate, setForceFullySeparate] = useState(false);
  return (
    <Fragment>
      <ForceFullySeparateAlertModal
        isOpen={forceFully.isOpen}
        onClose={forceFully.onClose}
        setForceFullySeparate={setForceFullySeparate}
      />
      <Stack
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        marginBottom={"30px"}
        border={"1px solid #f6f6f6"}
        borderRadius="4px"
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      >
        <Stack>
          <Box
            justifyContent="center"
            alignItems="center"
            p="0px"
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
          >
            <Heading
              textAlign="left"
              fontSize=".9975rem"
              fontWeight="600"
              padding="20px"
              as="h2"
              size="md"
              _light={{
                color: "customColor.black7",
              }}
            >
              Full and Final Settlement
            </Heading>
            <Divider orientation="horizontal" />
            <Container
              maxW="400px"
              marginTop="15px"
              paddingTop="20px"
              pb="8px"
              marginX="auto"
              color={"rgb(0,10,30,.75)"}
              px="0px"
            >
              <Stack spacing="35px">
                <FormControl>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Resign Date is required",
                      },
                    }}
                    control={form.control}
                    name="resignationDate"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          w: "100%",
                          h: "38px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "date",
                          onChange: (e) => {
                            const resignDate = e.target.value;
                            field.onChange(resignDate);
                            // let totalDays = 0;
                            // if (
                            //   SeparationData?.employee
                            //     ?.employeeEmployementDetails?.noticePeriod
                            //     ?.unit ===
                            //   EmployeeEmployment.EmployementNoticePeriodEnum
                            //     .DAYS
                            // ) {
                            //   totalDays =
                            //     SeparationData?.employee
                            //       ?.employeeEmployementDetails?.noticePeriod
                            //       ?.value;
                            // } else if (
                            //   SeparationData?.employee
                            //     ?.employeeEmployementDetails?.noticePeriod
                            //     ?.unit ===
                            //   EmployeeEmployment.EmployementNoticePeriodEnum
                            //     .WEEKS
                            // ) {
                            //   totalDays =
                            //     SeparationData?.employee
                            //       ?.employeeEmployementDetails?.noticePeriod
                            //       ?.value * 7;
                            // } else if (
                            //   SeparationData?.employee
                            //     ?.employeeEmployementDetails?.noticePeriod
                            //     ?.unit ===
                            //   EmployeeEmployment.EmployementNoticePeriodEnum
                            //     .MONTHS
                            // ) {
                            //   totalDays =
                            //     SeparationData?.employee
                            //       ?.employeeEmployementDetails?.noticePeriod
                            //       ?.value * 30;
                            // } else if (
                            //   SeparationData?.employee
                            //     ?.employeeEmployementDetails?.noticePeriod
                            //     ?.unit ===
                            //   EmployeeEmployment.EmployementNoticePeriodEnum
                            //     .YEARS
                            // ) {
                            //   totalDays =
                            //     SeparationData?.employee
                            //       ?.employeeEmployementDetails?.noticePeriod
                            //       ?.value * 365;
                            // }
                            setSeparationDate(
                              moment(resignDate)
                                .add(
                                  SeparationData?.employee
                                    ?.employeeEmployementDetails?.noticePeriod
                                    ?.value,
                                  SeparationData?.employee
                                    ?.employeeEmployementDetails?.noticePeriod
                                    ?.unit as any
                                )
                                .format("YYYY-MM-DD")
                            );
                            form.setValue(
                              "separationDate",
                              moment(resignDate)
                                .add(
                                  SeparationData?.employee
                                    ?.employeeEmployementDetails?.noticePeriod
                                    ?.value,
                                  SeparationData?.employee
                                    ?.employeeEmployementDetails?.noticePeriod
                                    ?.unit as any
                                )
                                .format("YYYY-MM-DD")
                            );
                          },

                          borderTopRadius: "3px",
                        }}
                        placeholder=""
                        label="Resignation Date"
                        labelProps={{
                          fontSize: ".845rem",
                          _light: {
                            color: "customColor.black7",
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
                <HStack spacing={0} align={"baseline"}>
                  <Stack flex={1}>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Separation Date is required",
                        },
                      }}
                      control={form.control}
                      name="separationDate"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            h: "38px",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "date",

                            borderTopRadius: "3px",
                          }}
                          placeholder=""
                          label="Separation Date"
                          labelProps={{
                            fontSize: ".845rem",
                            _light: {
                              color: "customColor.black7",
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack flex={2}>
                    <FormControl>
                      <FormLabel
                        fontSize=".845rem"
                        fontWeight="500"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                      >
                        Reason
                      </FormLabel>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Select Reason is required",
                          },
                        }}
                        control={form.control}
                        name="reason"
                        render={({ field, fieldState }) => (
                          <ReactSelect
                            {...field}
                            key={field.value}
                            options={terminationReason}
                            withValidation
                            isInvalid={fieldState.invalid}
                            ErrorMessage="Please Select Reason"
                            placeholder="Select..."
                            value={terminationReason.find(
                              (op) => op.value === field.value
                            )}
                            onChange={(newValue: any) => {
                              field.onChange(newValue?.value);
                            }}
                            getOptionLabel={(option: any) => option.label}
                            getOptionValue={(option: any) => option.value}
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                </HStack>
                <Stack gap={0}>
                  <Text fontSize=".845rem" fontWeight="700" color="red.500">
                    Duration: {duration}
                  </Text>
                </Stack>
                <Controller
                  name="waiveOffNoticePeriod"
                  control={form.control}
                  render={({ field }) => (
                    <Checkbox
                      colorScheme="blue"
                      size="md"
                      borderColor="#adb5bd"
                      bgColor="white"
                      isChecked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    >
                      Waive off Notice Period
                    </Checkbox>
                  )}
                />
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Exit Interview Date is required",
                    },
                  }}
                  control={form.control}
                  name="interviewDate"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        w: "100%",
                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "date",

                        borderTopRadius: "3px",
                      }}
                      placeholder=""
                      label="Exit Interview Date"
                      labelProps={{
                        fontSize: ".845rem",
                        _light: {
                          color: "customColor.black7",
                        },
                      }}
                    />
                  )}
                />
                <Stack gap={0}>
                  <FormLabel
                    fontSize=".845rem"
                    fontWeight="500"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    margin={"0px 0px 8px"}
                  >
                    Comments
                  </FormLabel>
                  <Controller
                    name="comments"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <>
                        <Textarea
                          {...field}
                          //   isInvalid={fieldState.invalid}
                          //   errorBorderColor="Red"
                          border="1px solid #e5e7eb"
                          fontSize=".845rem"
                          _hover={{
                            border: "1px solid hsl(0, 0%, 70%)",
                          }}
                          _focus={{
                            borderWidth: "1px",

                            boxShadow: "0 0 0 1px #2684FF",
                            _hover: {
                              borderWidth: "1px",
                              borderColor: "#2684FF",
                              boxShadow: "0 0 0 1px #2684FF",
                            },
                          }}
                          _focusVisible={{
                            borderWidth: "1px",
                            boxShadow: "0 0 0 1px #2684FF",
                            _hover: {
                              borderWidth: "1px",
                              borderColor: "#2684FF",
                              boxShadow: "0 0 0 1px #2684FF",
                            },
                          }}
                          _dark={{
                            bgColor: "customColor.dark.50",
                          }}
                          _light={{
                            bgColor: "customColor.white",
                            color: "customColor.black7",
                          }}
                          mb="15px"
                          overflowX="hidden"
                          maxLength={300}
                          w="400px"
                          shadow="inner"
                          placeholder="Type a Comment"
                          h="80px"
                        />
                        {/* {fieldState.invalid && (
                          <Text color="red" fontSize="12px">
                            {fieldState.error?.message}
                          </Text>
                        )} */}
                      </>
                    )}
                  />
                </Stack>
              </Stack>
            </Container>
            <Container
              maxW="860px"
              marginTop="15px"
              paddingTop="20px"
              pb="8px"
              marginX="auto"
              color={"rgb(0,10,30,.75)"}
              px="0px"
            >
              <Box
                overflowX="auto"
                p="0px"
                borderRight={"1px solid #e5e7eb"}
                borderLeft={"1px solid #e5e7eb"}
                borderBottom={"1px solid #e5e7eb"}
              >
                <Table variant="simple" w="100%" overflowX="auto">
                  <Thead h={"36.8px"}>
                    <Tr
                      borderTopWidth={"1px"}
                      borderTopColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                    >
                      <Th
                        padding="8px 26px 8px 30px"
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                      >
                        <Checkbox
                          size="md"
                          borderColor="#adb5bd"
                          bgColor="white"
                          isChecked={isCheckedAll}
                          onChange={handleCheckAll}
                        />
                      </Th>
                      {[
                        {
                          name: "Name",
                          padding: "8px 26px 8px 15px",
                          widthValue: "10%",
                        },
                        {
                          name: "Position",
                          padding: "8px 26px 8px 15px",
                          widthValue: "20%",
                        },
                        {
                          name: "Email",
                          padding: "8px 26px 8px 15px",
                          widthValue: "20%",
                        },
                        {
                          name: "Phone",
                          padding: "8px 26px 8px 15px",
                          widthValue: "10%",
                        },
                        {
                          name: "Department",
                          padding: "8px 26px 8px 15px",
                          widthValue: "20%",
                        },
                        {
                          name: "Status",
                          padding: "8px 26px 8px 15px",
                          widthValue: "20%",
                        },
                      ].map((item, i) => {
                        return (
                          <Th
                            key={i}
                            width={item.widthValue}
                            padding={item.padding}
                            borderBottomColor="borders.tableBorder"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            fontWeight={700}
                            whiteSpace="nowrap"
                            fontSize="12px"
                            textTransform="none"
                          >
                            {item.name}
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {CorrespondenceData.map((item, i) => {
                      return (
                        <Tr
                          key={i}
                          _hover={{
                            bgColor: "#f2f3f8",
                            cursor: "pointer",
                          }}
                        >
                          <Td
                            padding="15px 15px 15px 30px"
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            fontWeight={500}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Checkbox
                              size="md"
                              borderColor="#adb5bd"
                              bgColor="white"
                              isChecked={checkedRows.includes(i)}
                              onChange={() => handleCheckRow(i)}
                            />
                          </Td>
                          <Tooltip
                            label={`${item.name}`}
                            placement="top"
                            hasArrow
                            bg="gray.800"
                            color="white"
                            fontSize="sm"
                            p="2"
                            rounded="md"
                            overflowWrap="anywhere"
                            whiteSpace="normal"
                            zIndex="10"
                          >
                            <Td
                              maxW="100px"
                              isTruncated
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {item.name && item.name.trim() ? item.name : "NA"}
                            </Td>
                          </Tooltip>
                          <Tooltip
                            label={`${item.position}`}
                            placement="top"
                            hasArrow
                            bg="gray.800"
                            color="white"
                            fontSize="sm"
                            p="2"
                            rounded="md"
                            overflowWrap="anywhere"
                            whiteSpace="normal"
                            zIndex="10"
                          >
                            <Td
                              maxW="100px"
                              isTruncated
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {item.position && item.position.trim()
                                ? item.position
                                : "NA"}
                            </Td>
                          </Tooltip>
                          <Tooltip
                            label={`${item.email}`}
                            placement="top"
                            hasArrow
                            bg="gray.800"
                            color="white"
                            fontSize="sm"
                            p="2"
                            rounded="md"
                            overflowWrap="anywhere"
                            whiteSpace="normal"
                            zIndex="10"
                          >
                            <Td
                              maxW="100px"
                              isTruncated
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {item.email && item.email.trim()
                                ? item.email
                                : "NA"}
                            </Td>
                          </Tooltip>

                          <Tooltip
                            label={`${item.phone}`}
                            placement="top"
                            hasArrow
                            bg="gray.800"
                            color="white"
                            fontSize="sm"
                            p="2"
                            rounded="md"
                            overflowWrap="anywhere"
                            whiteSpace="normal"
                            zIndex="10"
                          >
                            <Td
                              maxW="100px"
                              isTruncated
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {item.phone && item.phone.trim()
                                ? item.phone
                                : "NA"}
                            </Td>
                          </Tooltip>
                          <Tooltip
                            label={`${item.department}`}
                            placement="top"
                            hasArrow
                            bg="gray.800"
                            color="white"
                            fontSize="sm"
                            p="2"
                            rounded="md"
                            overflowWrap="anywhere"
                            whiteSpace="normal"
                            zIndex="10"
                          >
                            <Td
                              maxW="100px"
                              isTruncated
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {item.department && item.department.trim()
                                ? item.department
                                : "NA"}
                            </Td>
                          </Tooltip>
                          <Td
                            maxW="100px"
                            isTruncated
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Tag
                              padding={"5px 6px"}
                              borderRadius={"3px"}
                              fontSize={"11px"}
                              fontWeight={"600"}
                              color={
                                SeparationData?.clearances?.find(
                                  (clearance) =>
                                    clearance.clientCorrespondenceId === item.id
                                )?.status === "PROVIDED"
                                  ? "#17C666"
                                  : "#3454d1"
                              }
                              bgColor={
                                SeparationData?.clearances?.find(
                                  (clearance) =>
                                    clearance.clientCorrespondenceId === item.id
                                )?.status === "PROVIDED"
                                  ? "#E1FBED"
                                  : "#EBEEFA"
                              }
                            >
                              {/* {SeparationData?.clearances?.find(
                              (clearance) =>
                                clearance.clientCorrespondenceId === item.id
                            )?.status === "PROVIDED"
                              ? "Provided"
                              : SeparationData?.clearances?.find(
                                  (clearance) =>
                                    clearance.clientCorrespondenceId === item.id
                                )?.status || "Not Provided"} */}
                              {SeparationData?.clearances?.find(
                                (clearance) =>
                                  clearance.clientCorrespondenceId === item.id
                              )?.status === "PROVIDED"
                                ? "Provided"
                                : "" || "Not Provided"}
                            </Tag>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
            </Container>
            <Stack
              h={"80px"}
              borderTop={"2px solid #d6dade"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              paddingRight={"20px"}
              direction={"row"}
              marginTop={"30px"}
            >
              <Button
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                color={"#ea4d4d"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  navigate(-1);
                  // navigate("/app/payroll/PayrollEmployee");
                }}
              >
                Cancel
              </Button>

              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                isLoading={loading}
                onClick={(e) => {
                  form.handleSubmit(
                    (data) => {
                      setLoading(true);
                      const updatedClearances = selectedId.map(
                        (id: string) => ({
                          clientCorrespondenceId: id,
                        })
                      );
                      const updatedData = {
                        ...data,
                        clearances: updatedClearances,
                      };
                      if (
                        form.getValues("separationDate") < separationDate! &&
                        !forceFullySeparate
                      ) {
                        forceFully.onOpen();
                        setLoading(false);
                        return;
                      }
                      EmployeeSeparationApi.CreateEmployeeSeparation(
                        id!,
                        updatedData,
                        (success) => {
                          toast({
                            title: "Employee Separation Added/Updated",
                            status: "success",
                            duration: 2000,
                            isClosable: true,
                          });
                          setLoading(false);
                        },
                        (error) => {
                          toast({
                            title: error,
                            status: "error",
                            duration: 2000,
                            isClosable: true,
                          });
                          setLoading(false);
                        }
                      );
                    },
                    (error) => {
                      toast({
                        title: "Error",
                        status: "error",
                      });
                      setLoading(false);
                    }
                  )(e);
                }}
              >
                {forceFullySeparate ? "Forcefully Save" : "Save"}
              </Button>
            </Stack>
          </Box>
        </Stack>
        <Stack
          marginX={"auto"}
          borderTop={"1px solid #d6dade"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          marginRight={"auto"}
          marginLeft={"auto"}
          w="100%"
          marginTop={"20px"}
        >
          <HistoryAccordion />
        </Stack>
      </Stack>
    </Fragment>
  );
}
