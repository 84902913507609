import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Editable,
  EditableInput,
  EditablePreview,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { FiChevronDown, FiChevronRight, FiMoreVertical } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { RxDragHandleDots2 } from "react-icons/rx";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { Template } from "../../../modules/Audit";
import { getFieldKey } from "../../../modules/form-apis";
import { ItemProvider } from "../hooks/auditItemContext";
import { useScoreContext } from "../hooks/templateBuilderContext";
import { QuestionItem } from "./QuestionTable";

interface SectionProps {
  sectionIndex: number;
  pageIndex: number;
  sectionId?: number;
  onDelete: (sectionIndex: number) => void;
}
export default function Section({
  sectionIndex,
  pageIndex,
  sectionId,
  onDelete,
}: SectionProps) {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const scoreContext = useScoreContext();

  const template = useFormContext<Template.Template>();

  const sectionFieldArray = useFieldArray({
    control: template.control,
    name: `templateItems.${pageIndex}.children.${sectionIndex}.children` as any,
    keyName: "uniqueId",
  });

  const DragHandle = SortableHandle(() => (
    <RxDragHandleDots2 size={24} color={"#828ea0"} />
  ));

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    sectionFieldArray.move(oldIndex, newIndex);
  };

  const DragContainer = SortableContainer<{ children: any }>(
    ({ children }: any) => <div>{children}</div>
  );

  const DraggableItem = SortableElement<{
    questionIndex: number;
    pageIndex: number;
    sectionIndex?: number;
    isSection?: boolean;
    id?: string;
    onDelete: (questionIndex: number) => void;
  }>(QuestionItem);

  useEffect(() => {
    template.setValue(
      getFieldKey(
        { pageIndex, questionIndex: sectionIndex },
        "orderIndex"
      ) as any,
      sectionIndex
    );
  }, []);

  return (
    <Stack spacing={0}>
      <Stack
        direction={"row"}
        backgroundColor={"backgrounds.primary"}
        role="group"
        spacing={0}
      >
        <Stack direction={"column"} flex={3} spacing={0}>
          <HStack w={"100"} spacing={0} padding={"8px 0px"}>
            <Button
              marginLeft="12px"
              marginRight="8px"
              as={IconButton}
              aria-label={"dropdown"}
              padding={0}
              size="sm"
              // backgroundColor={'primary.500'}
              colorScheme="primary"
              onClick={onToggle}
              //   icon={<FiChevronRight color={'white'} size={24} />}
              icon={
                isOpen ? (
                  <FiChevronDown color={"white"} size={24} />
                ) : (
                  <FiChevronRight color={"white"} size={24} />
                )
              }
              // variant="outline"
              borderRadius={"50%"}
              // border={'1px'}
              // borderColor={'#828ea0'}
              //   active={{backgroundColor: 'green'}}
              // _hover={{backgroundColor: 'blue'}}
              //   _focus={{backgroundColor: 'green'}}
              //   _focusVisible={{backgroundColor: 'green'}}
            />
            <Button
              as={IconButton}
              aria-label={"dropdown"}
              padding={0}
              size="sm"
              backgroundColor={"transparent"}
              icon={<DragHandle />}
              borderRadius={"50%"}
              borderColor={"#828ea0"}
              _active={{ backgroundColor: "gray.300" }}
              _hover={{ backgroundColor: "gray.300" }}
              _focus={{ backgroundColor: "gray.300" }}
              _focusVisible={{ backgroundColor: "gray.300" }}
            />
            <Controller
              control={template.control}
              name={
                `templateItems.${pageIndex}.children.${sectionIndex}.title` as any
              }
              render={({ field }) => (
                <Editable
                  flex={1}
                  placeholder={"Type section title"}
                  fontSize={"13px"}
                  fontWeight={"500"}
                  color={"textcolors.primary"}
                  isDisabled={!isOpen}
                  {...field}
                >
                  <EditablePreview
                    padding={"0px 0px 0px 16px"}
                    _hover={{
                      color: "black",
                    }}
                  />

                  <EditableInput
                    backgroundColor={"white"}
                    padding={"8px"}
                    border="1px"
                    borderStyle={"solid"}
                    borderColor={"blue"}
                    color={"black"}
                    _focusVisible={{ boxShadow: "0px" }}
                  />
                </Editable>
              )}
            />
          </HStack>
          {!isOpen && (
            <Text
              padding={"0px 0px 16px 80px"}
              fontSize="13px"
              fontWeight={400}
              color="icons.primary"
              _hover={{ color: "backgrounds.secondary" }}
            >
              {sectionFieldArray.fields.length} items hidden
            </Text>
          )}
        </Stack>
        <Stack
          // flex={2}
          direction="row"
          spacing={0}
          alignItems={"center"}
          justifyContent={"space-between"}
          w={{
            xs: "100%",
            md: "300px",
            lg: "400px",
          }}
          display={{ xs: "none", md: "flex" }}
        >
          <Checkbox
            marginLeft={"12.8px"}
            borderColor={"gray.500"}
            fontWeight={400}
            fontSize="14px"
            color="textcolors.primary"
            display="none"
            _groupHover={{ display: "flex" }}
          >
            Repeat this section
          </Checkbox>
          <Box>
            <Menu closeOnSelect={false}>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FiMoreVertical size={24} color="#545f70" />}
                variant="outline"
                marginInlineEnd={"12.8px"}
                borderRadius={"50%"}
                border={"none"}
                _hover={{ backgroundColor: "backgrounds.secondary" }}
                _active={{
                  backgroundColor: "transparent",
                  _hover: { backgroundColor: "backgrounds.secondary" },
                }}
              />
              <MenuList minWidth={"100px"}>
                <MenuItem
                  as={Checkbox}
                  borderColor={"gray.500"}
                  fontWeight={400}
                  fontSize="14px"
                  color="textcolors.primary"
                >
                  Required
                </MenuItem>
                <MenuItem
                  icon={<MdDelete size={"16px"} color="#545f70" />}
                  fontWeight={400}
                  fontSize="14px"
                  justifyContent="flex-start"
                  // color="textcolors.primary"
                  onClick={() => {
                    const sectionId = template.getValues(
                      getFieldKey(
                        { pageIndex, questionIndex: sectionIndex },
                        "id"
                      ) as any
                    );
                    if (sectionId) {
                      const deletedItems =
                        template.getValues(`deletedItems`) ?? [];
                      deletedItems.push({ id: sectionId, type: "SECTION" });
                      template.setValue(`deletedItems`, deletedItems);
                    }
                    onDelete(sectionIndex);
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Stack>
        {scoreContext.showScore && (
          <Stack
            // flex={1}
            display={{ xs: "none", md: "block" }}
            borderLeft={{ xs: "-1px", md: "1px" }}
            borderBottom={{ xs: "-1px", md: "1px" }}
            borderStyle={"solid"}
            borderColor={{ xs: "borders.accent", md: "borders.accent" }}
            justifyContent={"center"}
            w={{
              xs: "100%",
              md: "110px",
            }}
          >
            <Text
              w={"100%"}
              padding={{
                xs: "8px 10px 8px 20px",
                md: "8px 0px 8px 20px",
              }}
              textAlign={"start"}
              color={"#373857"}
              fontSize={"12px"}
              fontWeight={500}
            >
              Score
            </Text>
          </Stack>
        )}
      </Stack>
      <Collapse
        in={isOpen}
        animateOpacity
        style={{
          overflow: "visible",
        }}
      >
        <HStack h="100%" spacing={0} position="relative">
          <Box
            display={{ xs: "none", md: "flex" }}
            position="absolute"
            top={2}
            bottom={2}
            left={6}
            bgColor="primary.500"
            width="4px"
          />

          <Stack
            w={"100%"}
            direction="column"
            spacing={0}
            marginInlineStart={{ xs: "none", md: "45px !important" }}
          >
            <DragContainer useDragHandle onSortEnd={onSortEnd}>
              {(
                sectionFieldArray.fields as unknown as Array<Template.Question>
              ).map((field, i) => {
                return (
                  <ItemProvider
                    pageFieldArray={sectionFieldArray}
                    pageIndex={pageIndex}
                    sectionIndex={sectionIndex}
                    questionIndex={i}
                  >
                    <DraggableItem
                      // key={field.id}
                      index={i}
                      // id={field.id}
                      pageIndex={pageIndex}
                      questionIndex={i}
                      sectionIndex={sectionIndex}
                      onDelete={(index: any) => {
                        sectionFieldArray.remove(index);
                        if (sectionId) {
                          const deletedItems =
                            template.getValues(`deletedItems`) ?? [];
                          deletedItems.push({ id: sectionId, type: "SECTION" });
                          template.setValue(`deletedItems`, deletedItems);
                        }
                      }}
                    />
                  </ItemProvider>
                );
              })}
            </DragContainer>
          </Stack>
        </HStack>
      </Collapse>
    </Stack>
  );
}
