import {
  AbsoluteCenter,
  Box,
  HStack,
  Spinner,
  Text,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import {Inspection, Template} from '../../modules/Audit';
import InspectionForm from './InspectionForm';

export default function ConductInspection() {
  const {id} = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useBoolean(true);
  const toast = useToast();

  const template = useForm({
    defaultValues: {},
    resolver: yupResolver(Inspection.ValidationSchema),
  });
  useEffect(() => {
    const sliptedParam = id?.split('_');
    const mode = sliptedParam?.[0];
    const templateId = sliptedParam?.[1];
    if (templateId) {
      if (mode === 'NEW') {
        Template.GetById(
          +templateId,
          temp => {
            template.reset({...temp, mediaUniqueId: uuidv4()});
            setIsLoading.off();
          },
          error => {
            console.log(error);
            toast({
              title: 'Error',
              description: 'You do not have permission to submit this resource',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            navigate(-1);
          },
        );
      } else {
        Inspection.FetchById(
          +templateId,
          inspection => {
            template.reset({
              ...inspection,
              mediaUniqueId: inspection?.mediaUniqueId ?? uuidv4(),
            });
            setIsLoading.off();
          },
          error => {
            console.log(error);
            toast({
              title: 'Error',
              description: 'Inspection already submitted',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            navigate(-1);
          },
        );
      }
    }
  }, []);
  if (isLoading) {
    return (
      <Box position="relative" h="calc(100vh - 70px)" w="full">
        <AbsoluteCenter>
          <HStack>
            <Spinner />
            <Text>Loading Template</Text>
          </HStack>
        </AbsoluteCenter>
      </Box>
    );
  } else {
    return (
      <FormProvider {...template}>
        <InspectionForm mode="CONDUCT" />
      </FormProvider>
    );
  }
}
