import { Box, Button, Stack, useDisclosure } from "@chakra-ui/react";
import { Fragment } from "react";
import SubHeading from "../../pages/Sub-Heading";
import UploadCompleteModal from "./UploadCompleteModal";

interface reviewdataprops {
  updateComponent: any;
}
export default function ResponseListModal({
  updateComponent,
}: reviewdataprops) {
  const completemodal = useDisclosure();
  return (
    <Fragment>
      <Box
        border="2px solid"
        borderColor="borders.accent"
        borderRadius="4px"
        h="395px"
        overflowY="auto"
      >
        <Stack
          p="8px"
          backgroundColor="backgrounds.primary"
          align="center"
          borderBottom="1px"
          borderColor="borders.accent"
          position="sticky"
          top={0}
        >
          <SubHeading>Label</SubHeading>
        </Stack>
        <Stack>
          {[
            "Danish",
            "Saoud",
            "Asif",
            "Imtiaz",
            "Sameer",
            "Ali",
            "Ahmed",
            "arslan",
            "asad",
          ].map((items, i) => {
            return (
              <Stack
                p="8px"
                borderTop="2px"
                borderColor="borders.accent"
                key={i}
              >
                <SubHeading>{items}</SubHeading>
              </Stack>
            );
          })}
        </Stack>
      </Box>
      <Button
        onClick={() => {
          updateComponent(
            <UploadCompleteModal updateComponent={updateComponent} />
          );
        }}
      >
        Button
      </Button>
    </Fragment>
  );
}
