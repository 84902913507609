import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerProps,
} from '@chakra-ui/react';
import React from 'react';
import Preview from '../../inspection/InspectionForm';

function PreviewModal(props: Omit<DrawerProps, 'children'>) {
  return (
    <Drawer {...props}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody p={0}>
          <Preview mode="PREVIEW" />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default React.memo(PreviewModal);
