import {
  Button,
  Flex,
  HStack,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { IoMdInformationCircle } from "react-icons/io";
import Group from "../Popovers/Group";

interface MembershipProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function SiteGroupMembership({
  isOpen,
  onClose,
}: MembershipProps) {
  const GroupDisclosure = useDisclosure();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Text
              flex={1}
              fontSize="21px"
              fontWeight={500}
              lineHeight={1.75}
              color="textcolors.primary"
            >
              Add site-based access rule
            </Text>
            <IconButton
              aria-label="close"
              backgroundColor="transparent"
              borderRadius="50%"
              onClick={onClose}
              _hover={{ backgroundColor: "transparent" }}
              _active={{ backgroundColor: "transparent" }}
              _focus={{ backgroundColor: "transparent" }}
              icon={<AiOutlineClose size={"24px"} color="#545F70" />}
            />
          </HStack>
        </ModalHeader>
        <ModalBody>
          <Text
            fontSize="16px"
            fontWeight={700}
            color="textcolors.primary"
            mb="8px"
          >
            Results are available to
          </Text>
          <RadioGroup mb="8px">
            <Stack direction="column">
              <Radio value="1">All members of selected site</Radio>
              <Radio value="2">
                Members of selected site who are also in...
              </Radio>
            </Stack>
          </RadioGroup>
          <Popover matchWidth={true} isOpen={GroupDisclosure.isOpen}>
            <PopoverTrigger>
              <HStack
                border="1px solid"
                borderColor="borders.accent"
                _hover={{ borderColor: "primary.500" }}
                borderRadius="4px"
                px="16px"
                py="8px"
                onClick={GroupDisclosure.onToggle}
              >
                <Stack flex={1}>
                  <Text fontSize="16px" fontWeight={400} lineHeight="24px">
                    Danish
                  </Text>
                </Stack>
                {GroupDisclosure.isOpen ? <FiChevronUp /> : <FiChevronDown />}
              </HStack>
            </PopoverTrigger>
            <PopoverContent w="100%">
              <PopoverBody>
                <Group />
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <Flex
            w="100%"
            mt="24px"
            mb="16px"
            fontSize="14px"
            backgroundColor="primary.50"
            px="16px"
            py="8px"
            borderRadius="5px"
            border="1px"
            borderColor="primary.500"
          >
            <IoMdInformationCircle size={16} color="#1da5d4" />
            <HStack w="100%">
              <Text
                color="primary.500"
                fontSize="14px"
                fontWeight={500}
                ml="8px"
                lineHeight="16px"
              >
                <b>How will this work?</b> This template will be available to
                members of the sites selected here if they are also a member of
                one of these groups.
              </Text>
            </HStack>
          </Flex>
          <Link textDecoration="underline" fontSize="14px">
            Learn more about advanced access
          </Link>
        </ModalBody>

        <ModalFooter mt="16px">
          <HStack>
            <Button
              h="40px"
              borderRadius="5px"
              fontSize="14px"
              fontWeight={500}
              variant="outline"
              padding={"12px 20px"}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              h="40px"
              w={"auto"}
              borderRadius="5px"
              fontSize="14px"
              fontWeight={500}
              marginRight="16px"
              padding={"12px 20px"}
            >
              Add rule
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
