declare module Common {
  interface Success<T> {
    message: string;
    data: T;
    success: {};
  }
  interface Error {
    message: string;
    data: any;
    error: any;
    success: boolean;
    stackTrace: string;
  }
}
module Common {
  export function Echo() {
    console.log('Echo');
  }
}
export {Common};
