import {
  Avatar,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { Fragment } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { BsFilterLeft } from "react-icons/bs";
import { FiCheck } from "react-icons/fi";
import { Inspection } from "../../../../modules/Audit";
import Content from "../../../template/pages/Content";
interface InspectionArchiveTableSmProps {
  onRowClick: (inspection: any) => void;
  data: Inspection.InspectionDateWise;
  isLoading: boolean;
}

export default function InspectionArchiveTableSm({
  onRowClick,
  data,
  isLoading,
}: InspectionArchiveTableSmProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sortitems: Array<{
    label: string;
  }> = [
    {
      label: "Inspection",
    },
    {
      label: "Score",
    },
    {
      label: "Conducted",
    },
    {
      label: "Completed",
    },
  ];
  return (
    <TableContainer>
      <Table>
        <Thead bg="backgrounds.primary">
          <Tr>
            <Th
              p="8px"
              borderTop="1px"
              borderBottom="1px"
              borderColor="borders.accent"
            >
              <HStack justifyContent="space-between">
                <HStack>
                  <Text
                    fontSize="16px"
                    fontWeight={400}
                    lineHeight="1rem"
                    color="textcolors.primary"
                    textTransform="none"
                  >
                    Score
                  </Text>
                  <AiOutlineArrowDown size={18} color="#3f495a" />
                </HStack>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="sort"
                    icon={<BsFilterLeft size={18} color="#3f495a" />}
                    backgroundColor="transparent"
                    _hover={{ backgroundColor: "transparent" }}
                    _active={{ backgroundColor: "transparent" }}
                  />
                  <MenuList minW="180px">
                    {sortitems.map((menu, i) => {
                      return (
                        <Fragment key={i}>
                          <MenuItem px="16px" py="14px">
                            <HStack>
                              <FiCheck size="18px" color="#1da5d4" />
                              <Text fontSize="14px" color="textcolors.primary">
                                {menu.label}
                              </Text>
                            </HStack>
                          </MenuItem>
                        </Fragment>
                      );
                    })}
                  </MenuList>
                </Menu>
              </HStack>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <Stack w="100%" h="30vh" justify="center" align="center">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="primary.500"
                size="xl"
              />
            </Stack>
          ) : (
            Object.entries(data).map(([date, inspections]) => {
              const conductedDate = moment(date, "DD/MM/YYYY");
              return (
                <Fragment>
                  <Tr backgroundColor="backgrounds.header">
                    <Td
                      py="8px"
                      px="8px"
                      border="0px"
                      fontSize="14px"
                      color="textcolors.primary"
                    >
                      {conductedDate.isSame(moment(), "day")
                        ? "Today"
                        : conductedDate.fromNow()}
                    </Td>
                  </Tr>
                  {inspections.map((inspection, i) => (
                    <Tr
                      onClick={() => {
                        onRowClick(inspection);
                      }}
                    >
                      <Td
                        py="16px"
                        pr="8px"
                        pl="0px"
                        borderTop="1px"
                        borderColor="borders.accent"
                      >
                        <HStack>
                          {inspection?.inspectionTemplate?.image ? (
                            <Image
                              h="40px"
                              w="40px"
                              ml="8px"
                              src={inspection?.inspectionTemplate.image}
                              borderRadius="8px"
                            />
                          ) : (
                            <Avatar
                              w="40px"
                              h="40px"
                              ml="8px"
                              name={inspection?.inspectionTemplate?.title}
                              backgroundColor="primary.500"
                            />
                          )}
                          <Stack spacing={0}>
                            <HStack
                              divider={
                                <Text
                                  fontWeight={500}
                                  color="textcolors.primary"
                                  mx="1"
                                >
                                  /
                                </Text>
                              }
                            >
                              <Text fontWeight={500} color="textcolors.primary">
                                {moment(inspection?.inspectedOn).format(
                                  "DD MMM YYYY"
                                )}
                              </Text>

                              {inspection?.templateMeta?.site && (
                                <Text
                                  fontWeight={500}
                                  color="textcolors.primary"
                                >
                                  {inspection?.templateMeta?.site}
                                </Text>
                              )}
                              {inspection?.templateMeta?.person && (
                                <Text
                                  fontWeight={500}
                                  color="textcolors.primary"
                                >
                                  {inspection?.templateMeta?.person}
                                </Text>
                              )}
                            </HStack>
                            <Content lineHeight="16px" fontSize="14px" mt="4px">
                              {inspection?.inspectionTemplate?.title}
                            </Content>
                          </Stack>
                        </HStack>

                        {/* <Stack spacing="3px" direction="row">
                          <Text
                            fontSize="14px"
                            lineHeight="1.25rem"
                            color="textcolors.primary">
                            Status
                          </Text>
                          <Text
                            fontSize="14px"
                            lineHeight="1.25rem"
                            color="icons.primary">
                            {inspection.inspectionCompleted
                              ? 'Completed'
                              : 'Draft'}
                          </Text>
                        </Stack> */}
                      </Td>
                    </Tr>
                  ))}
                </Fragment>
              );
            })
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
