import { axiosInstance } from "./axiosInstance-hris";
declare module PayItemDeductionApi {
  type AddNewDeduction = {
    id?: string;
    category: string;
    name: string;
    chartAccountId: string;
    // reduceWithholdingTax: boolean;
    // reduceRetirementContribution: boolean;
    // excludeActivityStatement: boolean;
    isTaxable: boolean;
    province?: string;
    showOnPayslip: boolean;
    percentage?: number;
    minimumWage?: number;
    earningPayItems?: Array<string>;
  };
  type DeductionById = {
    id: string;
    category: string;
    name: string;
    reduceWithholdingTax: boolean;
    reduceRetirementContribution: boolean;
    excludeActivityStatement: boolean;
    active: boolean;
    province?: string;
    chartOfAccount: {
      id: string;
      code: number;
      name: string;
    };
    rateType: string;
  };
}
module PayItemDeductionApi {
  const API_URL = "/payroll-pay-items-deductions";
  export function NewDeductionAdded(
    data: AddNewDeduction,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function FetchDeduction(
    success: (data: Array<DeductionById>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function UpdateDeduction(
    id: string,
    data: Partial<AddNewDeduction>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function IsActive(
    id: string,
    isActive: boolean,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/status/${id}/${isActive}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function DeleteDeduction(
    id: string | undefined,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message && e.message);
      });
  }
  export function FetchDeductionById(
    id: string,
    success: (data: DeductionById) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { PayItemDeductionApi };
