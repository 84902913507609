import { axiosInstance } from "./axiosInstance-aud";

declare module InventoryCategoryModule {
  type AddInventoryCategories = {
    inventoryCategory: Array<InventoryCategoryData>;
  };

  type InventoryCategoryData = {
    name: string;
    description?: string;
    code?: string;
  };

  type FetchInventoryCategoryResponse = {
    createdAt: string;
    updatedAt: string;
    id: number;
    deletedAt: null;
    name: string;
    description: string;
    code: string;
  };
}
module InventoryCategoryModule {
  const URL = "/inventory-categories";
  export function UploadInventoryCategory(
    data: Array<InventoryCategoryData>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(URL, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchInventoryCategory(
    success: (response: Array<FetchInventoryCategoryResponse>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(URL)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function UpdateInventoryCategory(
    id: number,
    data: Partial<InventoryCategoryData>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function DeleteCategory(
    id: number,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { InventoryCategoryModule };
