import { Stack } from "@chakra-ui/react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { Inspection } from "../../../modules/Audit";
import { useInspectionContext } from "../hooks/InspectionContext";
import { useAuditQuestionContainer } from "./AuditQuestionContainer";

export default function GlobalResponse() {
  const template = useFormContext();
  const { pageIndex, questionIndex, sectionIndex, getItemField, question } =
    useAuditQuestionContainer();

  const GlobalOptions = useWatch({
    control: template.control,
    name: getItemField("question.mcqsData"),
  });
  const { setIsSaving } = useInspectionContext();
  return (
    <Stack w="100%">
      <Controller
        control={template.control}
        name={getItemField("question.questionValue")}
        rules={{
          onBlur: (e) => {
            template.trigger(getItemField("question.questionValue"));

            const answerId = template.getValues(
              getItemField("question.answerId")
            );
            const type = template.getValues(getItemField("question.type"));
            const inspectionMetaID = template.getValues("inspection.id");
            const notes = template.getValues(getItemField("question.notes"));
            setIsSaving(true);
            Inspection.CachingInspection(
              answerId,
              {
                question_value: e.target.value,
                notes: notes,
                inspection_meta_id: inspectionMetaID,
                type: type,
              },
              () => {
                setIsSaving(false);
              },
              () => {
                setIsSaving(false);
              }
            );
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <ReactSelect
              {...field}
              withValidation
              isInvalid={fieldState.invalid}
              isMulti={GlobalOptions.multiSelect}
              ErrorMessage="Please Select Global Responce"
              placeholder="Select Global Response"
              options={GlobalOptions.options ?? []}
              value={GlobalOptions.options.find(
                (op: any) => op.value === field.value
              )}
              onChange={(newValue: any) => {
                if (GlobalOptions.multiSelect) {
                  field.onChange(
                    (newValue ?? []).map((nv: any) => nv.value).join(",")
                  );
                } else {
                  field.onChange(newValue?.value);
                }
              }}
              getOptionLabel={(option: any) => option.value}
              getOptionValue={(option: any) => option.id}
            />
          );
        }}
      />
    </Stack>
  );
}
