import {
  Container,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Leaves } from "../../Api/Leaves";
import { PayItemLeaveApi } from "../../Api/PayItemLeave";

import { Current } from "./Current";
import { HistoryTab } from "./History";

export default function LeaveTab() {
  const toast = useToast();

  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  const [fetchLeaves, setFetchLeaves] = useState<
    Array<PayItemLeaveApi.LeaveById>
  >([]);

  useEffect(() => {
    PayItemLeaveApi.FetchLeave(
      (data) => {
        setFetchLeaves(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, []);

  const [selectedLeaveId, setSelectedLeaveId] = useState<string>();
  const form = useForm<Leaves.CreateLeavesBulk>({
    defaultValues: {
      empIds: [],
      employeesPayPeriods: [
        {
          empId: "",
          payPeriods: [
            {
              endDate: "",
              quantity: 0,
              id: "",
              startDate: "",
              string: "",
            },
          ],
        },
      ],
      leaveRequest: {
        startDate: "",
        endDate: "",
        quantity: 0,
        description: "",
        leaveCategory: "NORMAL_LEAVE",
        payrollPayItemLeaveId: "",
      },
    },
  });
  return (
    <FormProvider {...form}>
      <Stack h={"calc(100vh - 80px)"} overflowY="auto">
        <Tabs>
          <Stack
            boxShadow={"0 1px 0 0 rgba(0,10,30,.2)"}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
            h={{ sm: "100px", md: "60px" }}
          >
            <Container
              maxW={"100%"}
              padding={0}
              paddingLeft={"20px"}
              paddingRight={"20px"}
            >
              <HStack justifyContent="space-between">
                <Stack flex={1} direction={{ xs: "column", md: "row" }}>
                  <Text
                    fontWeight={"bold"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    _light={{
                      color: "customColor.black7",
                    }}
                    padding={"16px 12px 16px 0px "}
                    fontSize={"17px"}
                    margin={"0px 4px 0px 0px"}
                  >
                    Leave
                  </Text>

                  <TabList padding={0}>
                    <Tab fontSize={"15px"} h={"60px"}>
                      Current
                    </Tab>
                    <Tab fontSize={"15px"}>History</Tab>
                  </TabList>
                </Stack>
              </HStack>
            </Container>
          </Stack>
          <TabPanels>
            <TabPanel padding={0}>
              <Current
                selectedLeaveId={selectedLeaveId}
                setLastUpdated={setLastUpdated}
                fetchLeaves={fetchLeaves}
                lastUpdated={lastUpdated}
              />
            </TabPanel>
            <TabPanel padding={0}>
              <HistoryTab
                selectedLeaveId={selectedLeaveId}
                setLastUpdated={setLastUpdated}
                fetchLeaves={fetchLeaves}
                lastUpdated={lastUpdated}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </FormProvider>
  );
}
