import {
  Container,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import { ClaimHistoryTab } from "./ClaimHistoryTab";
import { EmployeeExpenseClaim } from "./EmployeeExpenseClaim";

export default function BulkExpenseClaim() {
  const toast = useToast();

  return (
    <Stack h={"calc(100vh - 80px)"} overflowY="auto">
      <Tabs>
        <Stack
          boxShadow={"0 1px 0 0 rgba(0,10,30,.2)"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          h={{ sm: "100px", md: "60px" }}
        >
          <Container
            maxW={"100%"}
            padding={0}
            paddingLeft={"20px"}
            paddingRight={"20px"}
          >
            <HStack justifyContent="space-between">
              <Stack flex={1} direction={{ xs: "row", md: "row" }}>
                <Text
                  fontWeight={"bold"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  _light={{
                    color: "customColor.black7",
                  }}
                  padding={"16px 12px 16px 0px "}
                  fontSize={"17px"}
                  margin={"0px 4px 0px 0px"}
                >
                  Expense Claim
                </Text>

                <TabList padding={0}>
                  <Tab fontSize={"15px"} h={"60px"}>
                    Current
                  </Tab>
                  <Tab fontSize={"15px"}>History</Tab>
                </TabList>
              </Stack>
            </HStack>
          </Container>
        </Stack>
        <TabPanels>
          <TabPanel padding={0}>
            <EmployeeExpenseClaim />
          </TabPanel>
          <TabPanel padding={0}>
            <ClaimHistoryTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
