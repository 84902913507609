import {
  Avatar,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Fragment } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { BsFilterLeft } from "react-icons/bs";
import { FiCheck } from "react-icons/fi";
import { useBusinessContext } from "../../../../../../../Hooks/BusinessContext";
import { TemplateMeta } from "../../../../../../modules/Audit";
interface TemplateTableSmProps {
  onRowClick: (temp: any) => void;
  data: Array<TemplateMeta.TemplateMeta>;
  isLoading: boolean;
}
export default function SmTable({
  onRowClick,
  data,
  isLoading,
}: TemplateTableSmProps) {
  const { allUsers } = useBusinessContext();
  const futureImplemetation = true;
  const sortitems: Array<{
    label: string;
  }> = [
    {
      label: "Template",
    },
    {
      label: "Last published",
    },
    {
      label: "Last modified",
    },
  ];
  return (
    <TableContainer>
      <Table>
        <Thead bg="backgrounds.primary">
          <Tr>
            <Th
              p="8px"
              borderTop="1px"
              borderBottom="1px"
              borderColor="borders.accent"
            >
              <HStack justifyContent="space-between">
                <HStack>
                  <Text
                    fontSize="16px"
                    fontWeight={400}
                    lineHeight="1rem"
                    color="textcolors.primary"
                    textTransform="none"
                  >
                    Last published
                  </Text>
                  <AiOutlineArrowDown size={18} color="#3f495a" />
                </HStack>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="sort"
                    icon={<BsFilterLeft size={18} color="#3f495a" />}
                    backgroundColor="transparent"
                    _hover={{ backgroundColor: "transparent" }}
                    _active={{ backgroundColor: "transparent" }}
                  />
                  <MenuList minW="180px">
                    {sortitems.map((menu, i) => {
                      return (
                        <Fragment key={i}>
                          <MenuItem px="16px" py="14px">
                            <HStack>
                              <FiCheck size="18px" color="#1da5d4" />
                              <Text fontSize="14px" color="textcolors.primary">
                                {menu.label}
                              </Text>
                            </HStack>
                          </MenuItem>
                        </Fragment>
                      );
                    })}
                  </MenuList>
                </Menu>
              </HStack>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <Stack w="100%" h="30vh" justify="center" align="center">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="primary.500"
                size="xl"
              />
            </Stack>
          ) : (
            data.map((temp, i) => {
              return (
                <Tr>
                  <Td py="16px" pr="8px" pl="0px" borderColor="borders.accent">
                    <HStack
                      onClick={() => {
                        onRowClick(temp);
                      }}
                    >
                      {temp.image ? (
                        <Image
                          h="40px"
                          w="40px"
                          ml="8px"
                          src={temp.image}
                          borderRadius="8px"
                        />
                      ) : (
                        <Avatar
                          w="40px"
                          h="40px"
                          ml="8px"
                          name={temp.title}
                          backgroundColor="primary.500"
                        />
                      )}
                      <Stack spacing="1px">
                        <Stack spacing={0}>
                          <Text fontWeight={500} color="textcolors.primary">
                            {temp.title}
                          </Text>
                          <Text textColor="textcolors.primary" fontWeight={400}>
                            {allUsers.find(
                              (user) => user.user?.id === temp.createdBy
                            )?.user?.firstName +
                              " " +
                              allUsers.find(
                                (user) => user.user?.id === temp.createdBy
                              )?.user?.lastName}
                          </Text>
                        </Stack>
                        <Stack spacing="3px" direction="row">
                          <Text
                            fontSize="14px"
                            lineHeight="1.25rem"
                            color="textcolors.primary"
                          >
                            Template Type:
                          </Text>
                          <Text
                            fontSize="14px"
                            lineHeight="1.25rem"
                            color="icons.primary"
                          >
                            {temp.inductionTemplate
                              ? "Induction"
                              : temp.warehouseTemplate
                              ? "Warehouse"
                              : "Audit"}
                          </Text>
                        </Stack>
                        <Stack spacing="3px" direction="row">
                          <Text
                            fontSize="14px"
                            lineHeight="1.25rem"
                            color="textcolors.primary"
                          >
                            Last published:
                          </Text>
                          <Text
                            fontSize="14px"
                            lineHeight="1.25rem"
                            color="icons.primary"
                          >
                            {temp.lastPublished}
                          </Text>
                        </Stack>
                      </Stack>
                    </HStack>
                  </Td>
                </Tr>
              );
            })
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
