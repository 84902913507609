import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import Site from "../../../../template/pages/access-components/Popovers/Site";

export default function InspectionSitePopover() {
  const sitedisclosure = useDisclosure();
  return (
    <Popover
      isOpen={sitedisclosure.isOpen}
      placement="bottom-start"
      matchWidth={true}
    >
      <PopoverTrigger>
        <Button
          variant="outline"
          h="41px"
          borderColor="borders.accent"
          px="16px"
          py="8px"
          _hover={{ backgroundColor: "none", borderColor: "primary.500" }}
          borderRadius="5px"
          textAlign="start"
          color="textcolors.primary"
          fontWeight="400"
          justifyContent="space-between"
          onClick={sitedisclosure.onToggle}
          rightIcon={
            sitedisclosure.isOpen ? <FiChevronUp /> : <FiChevronDown />
          }
        >
          Add Site
        </Button>
      </PopoverTrigger>
      <PopoverContent w="400px">
        <PopoverBody>
          <Site />
        </PopoverBody>
        <PopoverFooter p={0} textAlign="end">
          <Button
            h="32px"
            margin="8px"
            variant="outline"
            px="13px"
            fontSize="13px"
            borderRadius="8px"
            lineHeight={1}
          >
            Clear selection
          </Button>
          <Button
            h="32px"
            margin="8px"
            variant="solid"
            px="13px"
            fontSize="13px"
            borderRadius="8px"
            lineHeight={1}
          >
            Done
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
