import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Image,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Tag,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { Fragment, useRef, useState } from "react";
import { Controller, useForm, useFormContext, useWatch } from "react-hook-form";
import { AiOutlineNodeIndex } from "react-icons/ai";
import { fileToBase64 } from "../../../modules/api";
import { Template } from "../../../modules/Audit";
import { getFieldKey } from "../../../modules/form-apis";
import { compressImage } from "../../../modules/utils";
import { useItemContext } from "../hooks/auditItemContext";
import useDrawer from "../hooks/drawersContext";

type FormType = {
  templateImage: {
    base64: string;

    fileName: string;
    url: string;
  };
  title: string;
  description: string;
};

interface QuestionSettingLgProps {
  questionType: Template.QuestionType;
}
export function QuestionSettingLg({ questionType }: QuestionSettingLgProps) {
  if (questionType === "TEXT_ANSWER") {
    return <TextFormat />;
  }
  if (questionType === "NUMBER") return <NumberFormat />;
  if (questionType === "INSPECTION_DATE") return <GroupCheckBox />;
  if (questionType === "SITE") return <ManageSite />;
  if (questionType === "DOCUMENT_NUMBER") return <DocumentNumber />;
  if (questionType === "CHECKBOX") return <CheckBox />;
  if (questionType === "DATE_TIME") return <GroupCheckBox />;
  if (questionType === "SLIDER") return <SliderRange />;
  if (questionType === "ANNOTATION") return <PictureUpload />;
  if (questionType === "SIGNATURE") return <Signature />;
  if (questionType === "INSTRUCTIONS") return <Instructions />;
  if (questionType === "PERSON") return <Person />;
  if (questionType === "MULTICHOICE_RADIO") return <MultiOptions />;
  if (questionType === "MCQs") return <GlobalOptions />;
  if (questionType === "MEDIA") return <MediaAttachment />;
  if (questionType === "TABLE") return <Table />;
  // if (questionType === 'DOCUMENTS') return <Documents />;
  return <div></div>;
}

interface CheckboxButtonProps {
  pageIndex: number;
  questionIndex: number;
}
export function CheckboxButton({
  pageIndex,
  questionIndex,
}: CheckboxButtonProps) {
  const template = useFormContext();

  return (
    <Controller
      control={template.control}
      name={"isRequired"}
      render={({ field }) => <Checkbox {...field}>Required</Checkbox>}
    />
  );
}
export function DividerLine() {
  return (
    <Divider
      orientation="vertical"
      border="1px"
      borderColor="borders.accent"
      h="20px"
    />
  );
}
export function ManageSite() {
  return (
    <>
      <DividerLine />
      <Text
        fontSize="13px"
        fontWeight={400}
        color="icons.primary"
        lineHeight="16px"
      >
        Your team currently has no sites.
      </Text>
      <DividerLine />
      <Link href="https://chakra-ui.com" isExternal>
        Manage sites
        <ExternalLinkIcon mx="2px" />
      </Link>
    </>
  );
}

export function DocumentNumber() {
  const { item } = useItemContext();
  const template = useFormContext();
  const { DocNumberDrawer } = useDrawer();
  const documentFormat = useWatch({
    control: template.control,
    name: getFieldKey(item, "question.properties.documentNumberFormat"),
  });
  return (
    <Fragment>
      <HStack spacing={0} fontSize={"14px"} fontWeight={400}>
        <HStack spacing={2.5}>
          <AddLogic />
          <DividerLine />
          <HStack spacing={1}>
            <Text>Format: </Text>
            <Link
              onClick={() => {
                if (!documentFormat) {
                  template.setValue(
                    getFieldKey(
                      item,
                      "question.properties.documentNumberFormat"
                    ),
                    "DOC-[number]"
                  );
                }

                DocNumberDrawer?.onOpen();
              }}
            >
              {documentFormat}
            </Link>
          </HStack>
        </HStack>
      </HStack>
    </Fragment>
  );
}
export function MediaAttachment() {
  const { item } = useItemContext();
  const template = useFormContext();
  return (
    <Fragment>
      <DividerLine />
      <Controller
        name={getFieldKey(item, "question.properties.banner")}
        control={template.control}
        render={({ field }) => (
          <Checkbox
            {...field}
            isChecked={field.value}
            onChange={(e) => field.onChange(e.target.checked)}
          >
            Banner
          </Checkbox>
        )}
      />
    </Fragment>
  );
}
export function Table() {
  const { item } = useItemContext();
  const template = useFormContext();
  const { TableDrawer, setQuestionItemDetails } = useDrawer();
  return (
    <Fragment>
      <DividerLine />
      <Button
        variant="link"
        onClick={() => {
          setQuestionItemDetails(item);
          TableDrawer?.onOpen();
        }}
      >
        Cells
      </Button>
    </Fragment>
  );
}
export function Documents() {
  const { item } = useItemContext();
  const template = useFormContext();
  const { DocumentsModal, setQuestionItemDetails } = useDrawer();
  return (
    <Fragment>
      <DividerLine />
      <Button
        variant="link"
        onClick={() => {
          setQuestionItemDetails(item);
          DocumentsModal?.onOpen();
        }}
      >
        Documents
      </Button>
    </Fragment>
  );
}
export function SliderRange() {
  const { item } = useItemContext();
  const template = useFormContext();
  const properties = useWatch({
    control: template.control,
    name: getFieldKey(item, "question.properties"),
  });
  const { SliderDrawer, setQuestionItemDetails } = useDrawer();

  return (
    <Fragment>
      <HStack spacing={0} fontSize={"14px"} fontWeight={400}>
        <HStack spacing={2.5}>
          <DividerLine />
          <AddLogic />
          <DividerLine />
          <HStack spacing={1}>
            <Text>Range: </Text>
            <Link
              _hover={{ textDecoration: "none" }}
              onClick={() => {
                setQuestionItemDetails(item);
                SliderDrawer?.onOpen();
              }}
            >
              <HStack spacing={1}>
                <Text>{properties?.min}</Text>
                <Text>-</Text>
                <Text>{properties?.max}</Text>
              </HStack>
            </Link>
          </HStack>
        </HStack>
      </HStack>
    </Fragment>
  );
}

export function GroupCheckBox() {
  const { item } = useItemContext();
  const template = useFormContext();

  return (
    <>
      <DividerLine />
      <Stack spacing={2.5} direction="row">
        <Controller
          control={template.control}
          name={getFieldKey(item, "question.properties.date")}
          render={({ field }) => (
            <Checkbox
              {...field}
              isChecked={field.value ? JSON.parse(field.value) : false}
              onChange={(e) => {
                field.onChange(e.target.checked);
              }}
            >
              Date
            </Checkbox>
          )}
        />

        <DividerLine />
        <Controller
          control={template.control}
          name={getFieldKey(item, "question.properties.time")}
          render={({ field }) => (
            <Checkbox
              {...field}
              isChecked={field.value ? JSON.parse(field.value) : false}
              onChange={(e) => {
                field.onChange(e.target.checked);
              }}
            >
              Time
            </Checkbox>
          )}
        />
      </Stack>
    </>
  );
}
export function Signature() {
  return (
    <>
      <DividerLine />
      <AddLogic />
    </>
  );
}
export function CheckBox() {
  return (
    <>
      <DividerLine />
      <AddLogic />
    </>
  );
}
export function AddLogic() {
  return (
    <>
      <Button
        leftIcon={<AiOutlineNodeIndex color="blue" />}
        color="blue"
        variant="Link"
        fontWeight={400}
        fontSize={"14px"}
      >
        Add logic
      </Button>
    </>
  );
}
export function TextFormat() {
  const template = useFormContext();
  const { item } = useItemContext();
  const watchedFormat: any = useWatch({
    control: template.control,
    name: getFieldKey(item, "question.format"),
  });

  return (
    <HStack spacing={2.5}>
      <DividerLine />
      <AddLogic />
      <DividerLine />
      <HStack spacing={0} fontSize={"14px"} fontWeight={400}>
        <Text>Format:</Text>
        <Menu>
          <MenuButton
            as={Button}
            color="blue"
            variant="Link"
            fontWeight={400}
            fontSize={"14px"}
          >
            {
              Template.QUESTION_TYPES.TEXT_ANSWER.otherFormats?.[watchedFormat]
                .label
            }
          </MenuButton>
          <MenuList minWidth={"100px"}>
            <MenuItem
              onClick={() => {
                template.setValue(
                  getFieldKey(item, "question.format"),
                  "SHORT"
                );
              }}
            >
              Short answer
            </MenuItem>
            <MenuItem
              onClick={() => {
                template.setValue(
                  getFieldKey(item, "question.format"),
                  "PARAGRAPH"
                );
              }}
            >
              Paragraph
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </HStack>
  );
}
export function NumberFormat() {
  const template = useFormContext();
  const { item } = useItemContext();
  const watchedFormat = useWatch({
    control: template.control,
    name: getFieldKey(item, "question.format"),
  });

  const { TemperatureDrawer, setQuestionItemDetails } = useDrawer();

  return (
    <>
      <DividerLine />
      <AddLogic />
      <DividerLine />
      <HStack spacing={0} fontSize={"14px"} fontWeight={400}>
        <Text>Format:</Text>
        <Menu>
          <MenuButton>
            <Button
              color="blue"
              variant="Link"
              fontWeight={400}
              fontSize={"14px"}
            >
              {
                Template.QUESTION_TYPES.NUMBER.otherFormats?.[watchedFormat]
                  .label
              }
            </Button>
          </MenuButton>
          <MenuList minWidth={"100px"}>
            <MenuItem
              onClick={() => {
                template.setValue(
                  getFieldKey(item, "question.format"),
                  "NUMBER"
                );
              }}
            >
              Number
            </MenuItem>
            <MenuItem
              onClick={() => {
                template.setValue(getFieldKey(item, "question.format"), "TEMP");

                const properties = template.getValues(
                  getFieldKey(item, "question.properties")
                );
                if (!properties?.condition) {
                  template.setValue(getFieldKey(item, "question.properties"), {
                    greater: 1,
                    smaller: 10,
                    dUnit: "C",
                    condition: "BETWEEN",
                  } as Template.Question["properties"]);
                }
                setQuestionItemDetails(item);
                TemperatureDrawer?.onOpen();
              }}
            >
              Temperature
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </>
  );
}
export function PictureUpload() {
  const form = useForm<FormType>({
    defaultValues: {
      templateImage: {
        base64: "",
        fileName: "",
        url: "",
      },
      title: undefined,
      description: undefined,
    },
  });

  const watchedImage = form.watch("templateImage");

  const imageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const compressedImage = await compressImage(file);
      fileToBase64(compressedImage)
        .then((base64) => {
          form.setValue("templateImage", {
            base64,
            fileName: file.name,
            url: "",
          });
        })
        .catch((e) => {
          console.log({ e });
        });
    }
  };

  const imageRef = useRef<HTMLInputElement>(null);
  return (
    <Stack>
      <HStack>
        <DividerLine />
        <Button
          color="blue"
          variant="Link"
          fontWeight={400}
          fontSize={"14px"}
          onClick={() => {
            imageRef.current?.click();
          }}
        >
          Upload image to annotate
        </Button>
        <Flex
          w={"0px"}
          h={"0px"}
          alignItems={"center"}
          justifyContent={"center"}
          borderColor="borders.accent"
          borderRadius={"12px"}
          backgroundColor={"white"}
          role="group"
        >
          {watchedImage.base64 ? (
            <Image src={watchedImage.base64} />
          ) : (
            <Fragment>
              <Input
                ref={imageRef}
                type={"file"}
                hidden
                onChange={imageChange}
              />
            </Fragment>
          )}
        </Flex>
      </HStack>
    </Stack>
  );
}
export function Instructions() {
  return (
    <>
      <AttachMedia />
      <DividerLine />
      <Text
        fontSize="13px"
        fontWeight={400}
        color="icons.primary"
        lineHeight="16px"
      >
        one image or PDF can be uploaded
      </Text>
    </>
  );
}
export function MultiOptions() {
  return (
    <>
      <DividerLine />
      <AddLogic />
      <DividerLine />
      <MultipleSelection />
      <DividerLine />
      <FlaggedResponses />
    </>
  );
}
export function GlobalOptions() {
  return (
    <>
      <DividerLine />
      <AddLogic />
      <MultipleSelection />
    </>
  );
}
export function AttachMedia() {
  return (
    <Button color="blue" variant="Link" fontWeight={400} fontSize={"14px"}>
      Upload media attachment
    </Button>
  );
}
export function Person() {
  return (
    <>
      <DividerLine />
      <AddLogic />
    </>
  );
}
export function MultipleSelection() {
  const template = useFormContext();
  const { item } = useItemContext();
  return (
    <Controller
      name={getFieldKey(item, "question.mcqsData.multiSelect")}
      control={template.control}
      render={({ field }) => (
        <Checkbox
          {...field}
          isChecked={field.value}
          onChange={(e) => {
            field.onChange(e.target.checked);
          }}
        >
          Multiple Selection
        </Checkbox>
      )}
    />
  );
}

export function FlaggedResponses() {
  const [isChecked, setisChecked] = useState(false);
  const [isDefaultChecked, setIsDefaultChecked] = useState<boolean>(false);
  const [selectedOptionIndexes, setSelectedOptionIndexes] = useState<
    Array<number>
  >([]);

  const [finalTags, setFinalTags] = useState<Array<number>>([]);

  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      if (selectedOptionIndexes.length > 0) {
        setisChecked(true);
      }
      setFinalTags(selectedOptionIndexes);
    },
  });

  const template = useFormContext();
  const options = useWatch({
    control: template.control,
    name: "options",
  });

  const defaultFlagTagIndex = (options as Array<any>)?.reduce(
    (prev, curr, i) => {
      if (curr.flagged) {
        return [...prev, i];
      }

      return prev;
    },
    [] as Array<number>
  );

  return (
    <Popover isOpen={isOpen} onClose={onClose} isLazy>
      <PopoverTrigger>
        <Checkbox
          isChecked={isChecked}
          onChange={(e) => {
            if (!e.target.checked) {
              setFinalTags([]);
              setSelectedOptionIndexes([]);
              setIsDefaultChecked(false);
              setisChecked(e.target.checked);
            } else {
              onOpen();
            }
          }}
        >
          Flagged responses
          {finalTags.length > 0 && (
            <Fragment>
              <Tag
                px="8px"
                py="4px"
                ml="8px"
                fontSize="13px"
                fontWeight={400}
                minW="35px"
                borderRadius="16px"
                backgroundColor={`${options?.[finalTags?.[0]]?.branding}30`}
                color={options?.[finalTags?.[0]]?.branding}
                justifyContent="center"
              >
                {options?.[finalTags?.[0]]?.title}
              </Tag>

              {finalTags.length > 1 && `+${finalTags.length - 1}`}
            </Fragment>
          )}
        </Checkbox>
        {/* <Button>Flagged responses</Button> */}
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader px="16px" py="8px">
          <Checkbox
            isChecked={isDefaultChecked}
            onChange={(e) => {
              setIsDefaultChecked(e.target.checked);
              if (e.target.checked) {
                setSelectedOptionIndexes(defaultFlagTagIndex);
              } else {
                setSelectedOptionIndexes([]);
              }
            }}
          >
            Use default flagged responses
          </Checkbox>
        </PopoverHeader>
        <PopoverBody p={0}>
          <Stack direction="column" spacing={0}>
            {(options as Array<any>)?.map(({ title, branding }, i) => (
              <Checkbox
                key={i}
                px="16px"
                py="8px"
                disabled={isDefaultChecked}
                isChecked={selectedOptionIndexes.includes(i)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedOptionIndexes([...selectedOptionIndexes, i]);
                  } else {
                    setSelectedOptionIndexes((prev) =>
                      prev.filter((index) => index !== i)
                    );
                  }
                }}
              >
                <Tag
                  px="8px"
                  py="4px"
                  fontSize="13px"
                  fontWeight={400}
                  minW="35px"
                  borderRadius="16px"
                  backgroundColor={`${branding}30`}
                  color={branding}
                  justifyContent="center"
                >
                  {title}
                </Tag>
              </Checkbox>
            ))}
          </Stack>
        </PopoverBody>
        <PopoverFooter px="9.6px" pt="8px" pb="14px" w="100%" textAlign="end">
          <Button
            px="20px"
            py="12px"
            h="41px"
            borderRadius="8px"
            fontSize="14px"
            fontWeight={500}
            onClick={() => {
              // setisChecked()

              onClose();
            }}
          >
            Apply to this question
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
