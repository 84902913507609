import {
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { Controller, UseFormReturn } from "react-hook-form";
import { Template } from "../../../modules/Audit";
import TemplateImage from "./TemplateImage";

type FormType = {
  templateImage: {
    base64: string;

    fileName: string;
    url: string;
  };
  title: string;
  description: string;
};

interface TemplateBuilderHeader {
  form: UseFormReturn<Template.Template, any>;
}

export default function TemplateBuilderHeader({ form }: TemplateBuilderHeader) {
  return (
    <Stack w={"100%"} backgroundColor="backgrounds.primary" padding={5}>
      <Stack
        w={"100%"}
        direction={{ xs: "column", md: "column" }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box
          w={"120px"}
          h={"100px"}
          alignItems={"center"}
          justifyContent={"center"}
          borderWidth="1px"
          borderStyle="dashed"
          borderColor="borders.accent"
          borderRadius={"12px"}
          backgroundColor={"white"}
          role="group"
        >
          <TemplateImage />
        </Box>

        <VStack
          w={"100%"}
          // padding={{sm: '4px 0px 0px 0px', md: '4px 0px 0px 32px'}}
          spacing={0}
        >
          <Box w={"100%"}>
            <Controller
              control={form.control}
              name="template.title"
              render={({ field }) => (
                <Editable
                  placeholder="Your template title"
                  fontSize={"28px"}
                  fontWeight={"500"}
                  color={"#828ea0"}
                  textAlign="center"
                  {...field}
                >
                  <EditablePreview
                    padding={"8px"}
                    _hover={{
                      color: "#545f70",
                    }}
                    textAlign="center"
                  />
                  <EditableInput
                    backgroundColor={"white"}
                    padding={"8px"}
                    border="1px"
                    borderStyle={"solid"}
                    borderColor={"blue"}
                    color={"black"}
                    _focusVisible={{ boxShadow: "0px" }}
                    textAlign="center"
                  />
                </Editable>
              )}
            />
          </Box>
          <Box w={"100%"}>
            <Controller
              control={form.control}
              name="template.description"
              render={({ field }) => (
                <Editable
                  placeholder="Add a description"
                  fontSize={"16px"}
                  fontWeight={"400"}
                  color={"#828ea0"}
                  textAlign="center"
                  {...field}
                >
                  <EditablePreview
                    padding={"8px"}
                    textAlign="center"
                    _hover={{
                      color: "#545f70",
                    }}
                  />
                  <EditableInput
                    backgroundColor={"white"}
                    padding={"8px"}
                    border="1px"
                    borderStyle={"solid"}
                    borderColor={"blue"}
                    color={"black"}
                    _focusVisible={{ boxShadow: "0px" }}
                    textAlign="center"
                  />
                </Editable>
              )}
            />
          </Box>
        </VStack>
      </Stack>
    </Stack>
  );
}
