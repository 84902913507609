import {
  Box,
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { FaCaretDown, FaFilePdf, FaFileWord, FaImage } from "react-icons/fa";

import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { BiTrash } from "react-icons/bi";
import { BsArrowReturnRight } from "react-icons/bs";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { BusinessTaxRate } from "../../Api/BusinessTaxRate";
import { ChartOfAccounts } from "../../Api/ChartOfAccounts";
import { ContactApi } from "../../Api/Contact";
import { AddInvoices } from "../../Api/Invoices";
import { ProductAndService } from "../../Api/ProductAndServices";
import { AddContactModal } from "../Contacts/AddContactModal";
import AddModal from "./AddModal";

import { TableComponent } from "./Components/TableComponent";
import { DueDate } from "./DueDate";
import { IssueDate } from "./IssueDate";
import ShowAndHideModal from "./showAndHideModal";

interface Props {
  setItemData: React.Dispatch<
    React.SetStateAction<ProductAndService.FetchAllProductAndService[]>
  >;
  itemData: ProductAndService.FetchAllProductAndService[];
  setLastUpdated: React.Dispatch<React.SetStateAction<number>>;
  lastUpdated: number;
  paymentData: AddInvoices.PaymentData[];
  chartOfAccounts: ChartOfAccounts.FetchChartOfAccounts[];
  taxRateData: BusinessTaxRate.FetchTaxRate[];
  setChartOfAccountLastUpdated: React.Dispatch<React.SetStateAction<number>>;
  setTaxRateLastUpdated: React.Dispatch<React.SetStateAction<number>>;
  taxRateKeyValues: {
    [key: string]: any;
  };
  setContactData: React.Dispatch<React.SetStateAction<ContactApi.AddContact[]>>;
  contactData: ContactApi.AddContact[];
}
export function CreateInvoicesTable({
  setLastUpdated,
  itemData,
  setItemData,
  chartOfAccounts,
  taxRateData,
  paymentData,
  setChartOfAccountLastUpdated,
  setTaxRateLastUpdated,
  setContactData,
  contactData,
  taxRateKeyValues,
}: Props) {
  const form = useFormContext<AddInvoices.NewInvoiceData>();
  const invoiceComponent = useFieldArray({
    control: form.control,
    name: "items",
  });

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type.startsWith("image/")) {
      // setPreviewUrl(preview);
      handleFileUpload(droppedFile);
    }
  };
  const navigate = useNavigate();
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      handleFileUpload(selectedFile);
    }
    e.target.value = ""; // Clear file input after handling file
  };

  const handleFileUpload = (file: File) => {
    const formData = new FormData();

    formData.append("files", file);
    const currentId = form.getValues("id");

    if (currentId === undefined) {
      // If ID is undefined, first call AddInvoices.PostInvoices to create the invoice and get the ID
      AddInvoices.PostInvoices(
        form.getValues(),
        (success) => {
          const newId = success.id; // Assuming success contains the new ID after posting
          form.setValue("id", newId); // Store the new ID in the form
          // Now that we have the ID, upload the media files
          AddInvoices.UploadMediaFiles(
            form.getValues("id")!,
            formData,
            (success) => {
              setMediaFilesLastUpdated(Date.now());
            },
            (err) => {}
          );
        },
        (err) => {
          console.error("Error creating invoice:", err);
        }
      );
    } else {
      // If ID exists, directly call AddInvoices.UploadMediaFiles
      AddInvoices.UploadMediaFiles(
        form.getValues("id")!,
        formData,
        (success) => {
          setMediaFilesLastUpdated(Date.now());
        },
        (err) => {}
      );
    }
  };

  const contactModal = useDisclosure();

  const getAccountName = (accountId: string) => {
    const account = chartOfAccounts.find((acc) => acc.id === accountId);
    return account ? account.name : "Unknown Account";
  };
  const getContactName = (contactId: string) => {
    const contact = contactData.find((acc) => acc.id === contactId);
    return contact ? contact.name : "Unknown Account";
  };
  const ContactForm = useForm<ContactApi.AddContact>({
    defaultValues: {
      name: undefined,

      businessInformation: {
        phoneCountry: undefined,
        phoneArea: undefined,
        phoneNumber: undefined,
      },
      primaryPerson: {
        email: undefined,
      },
      deliveryAddress: {
        addresss: undefined,
        city: undefined,
        state: undefined,
        zipCode: undefined,
        country: undefined,
      },
      billingAddress: {
        addresss: undefined,
        city: undefined,
        state: undefined,
        zipCode: undefined,
        country: undefined,
      },
    },
  });

  const amountOptions = [
    { value: "Tax inclusive", label: "Tax inclusive" },
    { value: "Tax exclusive", label: "Tax exclusive" },
    { value: "No tax", label: "No Tax" },
  ];

  const [contactLastUpdated, setContactLastUpdated] = useState<number>(
    Date.now()
  );

  const watchItems = useWatch({
    control: form.control,
    name: "items",
  });
  const watchAccount = useWatch({
    control: form.control,
    name: "amountType",
  });
  const autoTaxes = useFieldArray({
    control: form.control,
    name: "autoTaxes", // Automatic taxes
  });
  console.log("autoTaxes", autoTaxes.fields);
  const manualTaxes = useFieldArray({
    control: form.control,
    name: "manualTaxes", // Manual taxes
  });
  const watchManualTaxes = useWatch({
    control: form.control,
    name: "manualTaxes",
  });
  const addManualTax = () => {
    manualTaxes.append({ taxRateId: "", taxAmount: 0, isManual: true });
  };
  const removeManualTax = (index: number) => {
    manualTaxes.remove(index);
  };
  const [sumOfAmount, setSumOfAmount] = useState(0);

  const previousTaxAmountsRef = useRef<{ [key: string]: number }>({});
  useEffect(() => {
    const keyValues = watchItems.reduce(
      (acc: any, curr: any, index: number) => {
        const amount = Number(curr.amount) || 0;
        const taxRateId = curr.taxRateId;
        if (!taxRateId || taxRateId === "undefined") return acc;
        const selectedTaxRate = taxRateData.find(
          (taxRate) => taxRate.id === taxRateId
        );

        // Accumulate the names of all tax components
        const taxComponentNames =
          selectedTaxRate?.tax_components
            .map((component: any) => component.name)
            .join(", ") || ""; // Join component names with a comma if there are multiple

        const tax =
          Number(
            selectedTaxRate?.tax_components.reduce(
              (sum: any, component: any) => sum + component.tax,
              0
            )
          ) || 0;

        let taxableAmount = 0;
        let totalAmount = amount;

        if (watchAccount === "Tax inclusive") {
          const originalValue = amount / (1 + tax / 100);
          taxableAmount = amount - originalValue;
        } else if (watchAccount === "Tax exclusive") {
          taxableAmount = (amount * tax) / 100;
          totalAmount += taxableAmount;
        } else if (watchAccount === "No tax") {
          taxableAmount = 0;
          const noTaxRate = taxRateData.find(
            (taxRate) => taxRate.tax_components?.[0]?.tax === 0
          );
          if (noTaxRate) {
            watchItems[index].taxRateId = noTaxRate.id;
            form.setValue(`items.${index}.taxRateId`, noTaxRate.id);
          }
        }

        // Accumulate the values based on taxRateId
        if (acc[taxRateId]) {
          acc[taxRateId].taxableAmount += taxableAmount;
          acc[taxRateId].totalAmount += totalAmount;
          acc[taxRateId].taxComponentNames.push(taxComponentNames); // Accumulate component names
        } else {
          acc[taxRateId] = {
            taxableAmount,
            totalAmount,
            tax,
            taxComponentNames: [taxComponentNames],
          };
        }

        // Calculate the tax amount based on taxableAmount
        const taxAmount = parseFloat(taxableAmount.toFixed(2));

        // Check if the taxAmount has changed before setting the form value
        if (previousTaxAmountsRef.current[index] !== taxAmount) {
          form.setValue(`items.${index}.taxAmount`, taxAmount);
          previousTaxAmountsRef.current[index] = taxAmount; // Store the new taxAmount for comparison
        }

        return acc;
      },
      {}
    );

    const transformedData = {
      taxes: Object.entries(keyValues).map(([key, value]: any) => ({
        taxRateId: key,
        taxAmount: (value as any).taxableAmount,
        isManual: false,
      })),
    };
    console.log("autoTaxes", autoTaxes.fields);
    form.setValue("autoTaxes", (transformedData.taxes as any) || []);
    console.log("autoTaxes", autoTaxes.fields);
    const sumAmount = watchItems.reduce((sum: number, curr: any) => {
      const amount = Number(curr.amount) || 0;
      const taxRateId = curr.taxRateId;
      const selectedTaxRate = taxRateData.find(
        (taxRate) => taxRate.id === taxRateId
      );
      const tax =
        Number(
          selectedTaxRate?.tax_components.reduce(
            (sum: any, component: any) => sum + component.tax,
            0
          )
        ) || 0;
      const originalValue = amount / (1 + tax / 100);
      return sum + (watchAccount === "Tax inclusive" ? originalValue : amount);
    }, 0);
    console.log("autoTaxes", autoTaxes.fields);
    // const sumTotalAmount = Object.values(keyValues).reduce(
    //   (sum: number, { totalAmount }: any) => sum + totalAmount,
    //   0
    // );
    // Update state with the calculated sums
    setSumOfAmount(sumAmount);
  }, [taxRateData, watchAccount, watchItems]);

  useEffect(() => {
    const AutoTaxTotal = autoTaxes?.fields.reduce(
      (sum: number, curr: any) => sum + curr.taxAmount,
      0
    );
    const ManualTaxTotal = watchManualTaxes?.reduce(
      (sum: number, curr: any) => sum + curr.taxAmount,
      0
    );
    const TotalAmount =
      (AutoTaxTotal ?? 0) + (ManualTaxTotal ?? 0) + (sumOfAmount ?? 0);
    form.setValue("totalAmount", TotalAmount);
    console.log("TotalAmount", form.getValues("totalAmount"));
  }, [watchManualTaxes, watchItems, watchAccount]);
  // setSumOfTotalAmount(sumTotalAmount);

  useEffect(() => {
    ContactApi.FetchContactDetails(
      {
        page: 1,
        limit: 100,
        isActive: true,
      },
      (data) => {
        setContactData(data.data);
      },
      (error) => {}
    );
  }, [contactLastUpdated]);
  const [mediaFilesLastUpdated, setMediaFilesLastUpdated] = useState<number>(
    Date.now()
  );
  const [mediaFiles, setMediaFiles] = useState<any[]>([]);
  useEffect(() => {
    const id = form.getValues("id");
    if (id && id !== "" && id !== null) {
      AddInvoices.FetchMediaFiles(
        id,
        (data) => {
          setMediaFiles(data);
        },
        (error) => {}
      );
    }
  }, [mediaFilesLastUpdated, form.getValues("id")]);

  const showHideModal = useDisclosure();
  const addModal = useDisclosure();
  const watchTotalAmount = useWatch({
    control: form.control,
    name: "totalAmount",
  });
  const currencyOptions = [
    "AED: United Arab Emirates Dirham",
    "AFN: Afghan Afghani",
    "ALL: Albanian Lek",
    "AMD: Armenian Dram",
    "ANG: Netherlands Antillean Guilder",
    "AOA: Angolan Kwanza",
    "ARS: Argentine Peso",
    "AUD: Australian Dollar",
    "AWG: Aruban Florin",
    "AZN: Azerbaijani Manat",
    "BAM: Bosnia-Herzegovina Convertible Mark",
    "BBD: Barbadian Dollar",
    "BDT: Bangladeshi Taka",
    "BGN: Bulgarian Lev",
    "BHD: Bahraini Dinar",
    "BIF: Burundian Franc",
    "BMD: Bermudan Dollar",
    "BND: Brunei Dollar",
    "BOB: Bolivian Boliviano",
    "BRL: Brazilian Real",
    "BSD: Bahamian Dollar",
    "BTC: Bitcoin",
    "BTN: Bhutanese Ngultrum",
    "BWP: Botswanan Pula",
    "BYN: Belarusian Ruble",
    "BZD: Belize Dollar",
    "CAD: Canadian Dollar",
    "CDF: Congolese Franc",
    "CHF: Swiss Franc",
    "CLF: Chilean Unit of Account (UF)",
    "CLP: Chilean Peso",
    "CNH: Chinese Yuan (Offshore)",
    "CNY: Chinese Yuan",
    "COP: Colombian Peso",
    "CRC: Costa Rican Colón",
    "CUC: Cuban Convertible Peso",
    "CUP: Cuban Peso",
    "CVE: Cape Verdean Escudo",
    "CZK: Czech Republic Koruna",
    "DJF: Djiboutian Franc",
    "DKK: Danish Krone",
    "DOP: Dominican Peso",
    "DZD: Algerian Dinar",
    "EGP: Egyptian Pound",
    "ERN: Eritrean Nakfa",
    "ETB: Ethiopian Birr",
    "EUR: Euro",
    "FJD: Fijian Dollar",
    "FKP: Falkland Islands Pound",
    "GBP: British Pound Sterling",
    "GEL: Georgian Lari",
    "GGP: Guernsey Pound",
    "GHS: Ghanaian Cedi",
    "GIP: Gibraltar Pound",
    "GMD: Gambian Dalasi",
    "GNF: Guinean Franc",
    "GTQ: Guatemalan Quetzal",
    "GYD: Guyanaese Dollar",
    "HKD: Hong Kong Dollar",
    "HNL: Honduran Lempira",
    "HRK: Croatian Kuna",
    "HTG: Haitian Gourde",
    "HUF: Hungarian Forint",
    "IDR: Indonesian Rupiah",
    "ILS: Israeli New Sheqel",
    "IMP: Manx pound",
    "INR: Indian Rupee",
    "IQD: Iraqi Dinar",
    "IRR: Iranian Rial",
    "ISK: Icelandic Króna",
    "JEP: Jersey Pound",
    "JMD: Jamaican Dollar",
    "JOD: Jordanian Dinar",
    "JPY: Japanese Yen",
    "KES: Kenyan Shilling",
    "KGS: Kyrgystani Som",
    "KHR: Cambodian Riel",
    "KMF: Comorian Franc",
    "KPW: North Korean Won",
    "KRW: South Korean Won",
    "KWD: Kuwaiti Dinar",
    "KYD: Cayman Islands Dollar",
    "KZT: Kazakhstani Tenge",
    "LAK: Laotian Kip",
    "LBP: Lebanese Pound",
    "LKR: Sri Lankan Rupee",
    "LRD: Liberian Dollar",
    "LSL: Lesotho Loti",
    "LYD: Libyan Dinar",
    "MAD: Moroccan Dirham",
    "MDL: Moldovan Leu",
    "MGA: Malagasy Ariary",
    "MKD: Macedonian Denar",
    "MMK: Myanma Kyat",
    "MNT: Mongolian Tugrik",
    "MOP: Macanese Pataca",
    "MRU: Mauritanian Ouguiya",
    "MUR: Mauritian Rupee",
    "MVR: Maldivian Rufiyaa",
    "MWK: Malawian Kwacha",
    "MXN: Mexican Peso",
    "MYR: Malaysian Ringgit",
    "MZN: Mozambican Metical",
    "NAD: Namibian Dollar",
    "NGN: Nigerian Naira",
    "NIO: Nicaraguan Córdoba",
    "NOK: Norwegian Krone",
    "NPR: Nepalese Rupee",
    "NZD: New Zealand Dollar",
    "OMR: Omani Rial",
    "PAB: Panamanian Balboa",
    "PEN: Peruvian Nuevo Sol",
    "PGK: Papua New Guinean Kina",
    "PHP: Philippine Peso",
    "PKR: Pakistani Rupee",
    "PLN: Polish Zloty",
    "PYG: Paraguayan Guarani",
    "QAR: Qatari Rial",
    "RON: Romanian Leu",
    "RSD: Serbian Dinar",
    "RUB: Russian Ruble",
    "RWF: Rwandan Franc",
    "SAR: Saudi Riyal",
    "SBD: Solomon Islands Dollar",
    "SCR: Seychellois Rupee",
    "SDG: Sudanese Pound",
    "SEK: Swedish Krona",
    "SGD: Singapore Dollar",
    "SHP: Saint Helena Pound",
    "SLL: Sierra Leonean Leone",
    "SOS: Somali Shilling",
    "SRD: Surinamese Dollar",
    "SSP: South Sudanese Pound",
    "STD: São Tomé and Príncipe Dobra (pre-2018)",
    "STN: São Tomé and Príncipe Dobra",
    "SVC: Salvadoran Colón",
    "SYP: Syrian Pound",
    "SZL: Swazi Lilangeni",
    "THB: Thai Baht",
    "TJS: Tajikistani Somoni",
    "TMT: Turkmenistani Manat",
    "TND: Tunisian Dinar",
    "TOP: Tongan Pa'anga",
    "TRY: Turkish Lira",
    "TTD: Trinidad and Tobago Dollar",
    "TWD: New Taiwan Dollar",
    "TZS: Tanzanian Shilling",
    "UAH: Ukrainian Hryvnia",
    "UGX: Ugandan Shilling",
    "USD: United States Dollar",
    "UYU: Uruguayan Peso",
    "UZS: Uzbekistan Som",
    "VEF: Venezuelan Bolívar Fuerte (Old)",
    "VES: Venezuelan Bolívar Soberano",
    "VND: Vietnamese Dong",
    "VUV: Vanuatu Vatu",
    "WST: Samoan Tala",
    "XAF: CFA Franc BEAC",
    "XAG: Silver Ounce",
    "XAU: Gold Ounce",
    "XCD: East Caribbean Dollar",
    "XDR: Special Drawing Rights",
    "XOF: CFA Franc BCEAO",
    "XPD: Palladium Ounce",
    "XPF: CFP Franc",
    "XPT: Platinum Ounce",
    "YER: Yemeni Rial",
    "ZAR: South African Rand",
    "ZMW: Zambian Kwacha",
    "ZWL: Zimbabwean Dollar",
  ].map((currency) => {
    const [symbol] = currency.split(": ");
    return {
      label: currency,
      value: currency,
      symbol: symbol,
    };
  });

  const handleCurrencyChange = (newValue: any, field: any) => {
    field.onChange(newValue?.value);
  };
  const fieldNames = [
    "Reference",
    "Invoice Number",
    "Issued Date",
    "Due Date",
    "Items",
    "Quantity",
    "Price",
    "Discount",
    "Tax Amount",
  ];

  // Initialize fieldsState from localStorage or set all fields to true by default
  const defaultState: { [key: string]: boolean } = {
    Reference: true,
    "Invoice Number": true,
    "Issued Date": true,
    "Due Date": true,
    Items: true,
    Quantity: true,
    Price: true,
    Discount: true,
    "Tax Amount": false,
  };
  const [fieldsState, setFieldsState] = useState(() => {
    const savedState = localStorage.getItem("fieldsState");
    if (savedState) {
      return JSON.parse(savedState);
    }

    fieldNames.forEach((field) => {
      defaultState[field] = true;
      defaultState["Tax Amount"] = false;
    });

    return defaultState;
  });

  // Handle checkbox state change (immediate update)
  const handleCheckboxChange = (field: string, checked: boolean) => {
    setFieldsState((prevState: any) => ({
      ...prevState,
      [field]: checked, // Update state immediately on checkbox change
    }));
  };
  return (
    <Stack>
      <FormProvider {...form}>
        <Stack
          _light={{
            bgColor: "customColor.white",
          }}
          padding={"24px 24px 0px"}
          borderRadius="10px"
          bg={"#fff"}
          boxShadow={"0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"}
          borderWidth="1px"
          borderStyle={"solid"}
          borderColor="#f6f6f6"
          transition={"all .3s ease"}
          h={"fit-content"}
        >
          <SimpleGrid
            columns={{ xs: 1, md: 3, lg: 3 }}
            spacing={5}
            marginBottom={{ xs: "0", md: "20px" }}
          >
            <Stack gap={0}>
              <Text
                fontSize={".845rem"}
                fontWeight={"600"}
                _light={{ color: "customColor.black7" }}
                margin={"0px 12px 8px 0px "}
              >
                To
              </Text>

              <Controller
                name={`contactId`}
                control={form.control}
                render={({ field, fieldState: { error } }) => {
                  // Track if the contactId has been changed (dirty)

                  const handleSelect = (selectedOption: any) => {
                    if (selectedOption?.value === "add_new_contact") {
                      // Reset the Contact form for new entry
                      ContactForm.reset({
                        name: undefined,
                        businessInformation: {
                          phoneCountry: undefined,
                          phoneArea: undefined,
                          phoneNumber: undefined,
                        },
                        deliveryAddress: {
                          attention: undefined,
                          addresss: undefined,
                          city: undefined,
                          state: undefined,
                          zipCode: undefined,
                          country: undefined,
                        },
                        billingAddress: {
                          attention: undefined,
                          addresss: undefined,
                          city: undefined,
                          state: undefined,
                          zipCode: undefined,
                          country: undefined,
                        },
                      });

                      contactModal.onOpen(); // Open the modal to add a new contact
                    } else {
                      field.onChange(selectedOption?.value);

                      const selectedContact = contactData?.find(
                        (acc) => acc.id === selectedOption?.value
                      );

                      if (selectedContact) {
                        // Update taxRateId in every item of the items array
                        form.setValue(
                          `items`,
                          form.getValues("items").map((item, index) => ({
                            ...item,
                            taxRateId: selectedContact.salesDefaults.saleGST,
                          }))
                        );
                        form.setValue(
                          `items`,
                          form.getValues("items").map((item, index) => ({
                            ...item,
                            accountId:
                              selectedContact.salesDefaults.salesAccountId,
                          }))
                        );
                        form.setValue(
                          "amountType",
                          selectedContact.salesDefaults.amountType
                        );
                      }

                      // If the contactId is dirty (changed), trigger the post function
                    }
                  };

                  // Generate options from the contactData array
                  const contactOptions = contactData?.map((contact: any) => ({
                    value: contact.id,
                    label: contact.name,
                  }));

                  // Add the "Add Contact" option
                  const addContactOption = {
                    value: "add_new_contact",
                    label: "Add Contact",
                  };

                  return (
                    <Stack>
                      <ReactSelect
                        options={[addContactOption, ...contactOptions]}
                        value={
                          contactOptions.find(
                            (option) => option.value === field.value
                          ) || null
                        }
                        onChange={handleSelect}
                        isDisabled={form.getValues("status") === "Paid"}
                        placeholder="Select a Contact"
                      />
                    </Stack>
                  );
                }}
              />
            </Stack>
            {fieldsState["Issued Date"] && <IssueDate />}

            {fieldsState["Due Date"] && <DueDate />}
          </SimpleGrid>
          <SimpleGrid
            columns={{ xs: 1, md: 3, lg: 4 }}
            spacing={5}
            marginBottom={"20px"}
          >
            {fieldsState["Invoice Number"] && (
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Invoice number is required",
                  },
                }}
                control={form.control}
                name="invoiceNumber"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      w: "100%",
                      h: "40px",

                      fontSize: ".845rem",
                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      variant: "outline",
                      type: "text",

                      borderTopRadius: "3px",
                      isReadOnly: form.getValues("status") === "Paid",
                    }}
                    placeholder=""
                    label="Invoice Number"
                    labelProps={{
                      fontSize: ".845rem",
                      marginTop: { xs: "10px", md: 0 },
                      fontWeight: "600",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                )}
              />
            )}

            {fieldsState["Reference"] && (
              <Controller
                control={form.control}
                name="reference"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    withValidation
                    input={{
                      isReadOnly: form.getValues("status") === "Paid",
                      ...field,
                      w: "100%",
                      h: "40px",
                      fontSize: ".845rem",
                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      variant: "outline",
                      type: "text",

                      borderTopRadius: "3px",
                    }}
                    placeholder=""
                    label="Reference"
                    labelProps={{
                      fontSize: ".845rem",
                      fontWeight: "600",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                )}
              />
            )}

            <Stack>
              <Text
                fontSize=".845rem"
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Currency:
              </Text>
              <Controller
                control={form.control}
                name="currency"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder="Select"
                    options={currencyOptions}
                    value={currencyOptions.find(
                      (currency) => currency.value === field.value
                    )}
                    onChange={(newValue) =>
                      handleCurrencyChange(newValue, field)
                    } // Update currency on change
                    getOptionLabel={(option: any) => option.label} // Label to show in dropdown
                    getOptionValue={(option: any) => option.value} // Value to track in form
                    isInvalid={fieldState.invalid} // Show error if any
                    isDisabled={form.getValues("status") === "Paid"}
                  />
                )}
              />
            </Stack>
            <Stack>
              <Text
                fontSize=".845rem"
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Account are
              </Text>
              <Controller
                // rules={{
                //   required: {
                //     value: true,
                //     message: "Accounts is required",
                //   },
                // }}
                // control={form.control}
                control={form.control}
                name="amountType"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder=""
                    // marginBottom="30px"
                    // width={"48em"}
                    // isInvalid={fieldState.invalid}
                    ErrorMessage="Select Account "
                    withValidation
                    options={amountOptions}
                    value={amountOptions.find(
                      (amountOptions) => amountOptions.value === field.value
                    )}
                    onChange={(newValue: any) =>
                      field.onChange(newValue?.value)
                    }
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                    isDisabled={form.getValues("status") === "Paid"}
                  />
                )}
              />
            </Stack>
          </SimpleGrid>

          <TableComponent
            form={form}
            invoiceComponent={invoiceComponent}
            chartOfAccounts={chartOfAccounts}
            taxRateData={taxRateData}
            setLastUpdated={setLastUpdated}
            setChartOfAccountLastUpdated={setChartOfAccountLastUpdated}
            setTaxRateLastUpdated={setTaxRateLastUpdated}
            setItemData={setItemData}
            itemData={itemData}
            fieldsState={fieldsState}
          />

          <Stack
            paddingTop={"15px"}
            direction={{ xs: "column", md: "row" }}
            marginTop={"15px"}
            alignItems={"baseline"}
            justifyContent={{ xs: "flex-end", md: "space-between" }}
          >
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems={{ xs: "normal", md: "center" }}
              justifyItems={"flex-end"}
              flex={2}
            >
              <Button
                leftIcon={<BsArrowReturnRight fontSize={"20px"} />}
                fontSize={"13px"}
                bgColor="#3454D1"
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={() => {
                  invoiceComponent.append({
                    itemId: undefined,
                    description: undefined,
                    quantity: undefined,
                    price: undefined,
                    accountId: undefined,
                    taxRateId: undefined,
                    discount: undefined,
                    amount: undefined,
                    taxAmount: undefined,
                  });
                }}
              >
                New Invoice Line
              </Button>
              <Button
                borderRadius={"3px"}
                borderRightRadius={0}
                backgroundColor={"#fff"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                  color: "#3454D1",
                }}
                onClick={() => {
                  showHideModal.onOpen();
                }}
              >
                Show/hide fields
              </Button>
              <Menu>
                <MenuButton
                  as={Button}
                  borderRadius={"3px"}
                  backgroundColor={"#fff"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  variant={"solid"}
                  textColor={"customColor.black7"}
                  padding={"12px 16px"}
                  fontWeight={600}
                  _hover={{
                    color: "customColor.black7",
                    bg: "#fff",
                    border: "1px solid hsl(0, 0%, 70%)",
                  }}
                  _active={{
                    color: "#3545d1",
                    bg: "#fff",
                    borderWidth: "1px",
                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  rightIcon={<FaCaretDown />}
                >
                  {mediaFiles.length > 0
                    ? `${mediaFiles.length} Files`
                    : "Attach Files"}
                </MenuButton>
                <MenuList
                  overflowY="auto"
                  zIndex={"overlay"}
                  w={"500px"}
                  minH={"fit-content"}
                  maxH={"400px"}
                  boxShadow={
                    "0 0 0 1px rgba(0,10,30,.2),0 3px 6px 0 rgba(0,10,30,.2)"
                  }
                  padding={"12px"}
                  borderRadius={"3px"}
                >
                  <Stack gap={0}>
                    <Text
                      paddingBottom={"4px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13.44px"}
                      fontWeight={"600"}
                    >
                      Attach Files
                    </Text>
                    <Stack
                      gap={0}
                      backgroundColor={"#fff"}
                      border={"1px dashed rgba(0,10,30,.5)"}
                      padding={"20px"}
                      alignItems={"center"}
                      justifyItems={"center"}
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                    >
                      <Text
                        fontSize={"15"}
                        _light={{
                          color: "customColor.black7",
                        }}
                      >
                        Drag and drop files or select manually
                      </Text>
                      <Button
                        marginTop={"12px"}
                        border={"1px solid #80858f "}
                        borderRadius={"3px"}
                        backgroundColor={"#fff"}
                        fontSize={"13px"}
                        variant={"solid"}
                        textColor={"customColor.6b7885"}
                        padding={"12px 16px"}
                        _hover={{
                          border: "1px solid #80858f ",
                          bgColor: "#fff",
                        }}
                        onClick={() => {
                          if (fileInputRef.current) {
                            fileInputRef.current.click();
                          }
                        }}
                      >
                        Upload File
                      </Button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                    </Stack>
                  </Stack>
                  {mediaFiles.length > 0 && (
                    <Stack
                      borderRadius={"3px"}
                      marginTop={"12px"}
                      boxShadow={"0 0 0 1px rgba(0,10,30,.2)"}
                    >
                      {mediaFiles.map((item, i) => {
                        const isLastItem = i === mediaFiles.length - 1; // Check if it's the last item
                        return (
                          <Stack
                            key={i}
                            borderBottom={
                              isLastItem ? "none" : "1px solid #ccced2"
                            }
                            gap={0}
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Stack gap={0} flex={1} direction={"row"}>
                              <Stack
                                w={"40px"}
                                h={"40px"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                marginLeft={"4px"}
                              >
                                <Icon
                                  as={
                                    item.fileExtension === ".jpg"
                                      ? FaImage
                                      : item.fileExtension === ".pdf"
                                      ? FaFilePdf
                                      : item.fileExtension === ".docx"
                                      ? FaFileWord
                                      : FaFilePdf // Default to FaFilePdf
                                  }
                                  w="15px"
                                  h="15px"
                                />
                              </Stack>
                              <Stack margin={"8px 0px 8px 0px"} gap={0}>
                                <Text
                                  color={"customColor.black7"}
                                  fontSize={"14px"}
                                >
                                  {item.fileName}
                                </Text>
                                <Text
                                  color={"customColor.gray.64748B"}
                                  fontSize={"14px"}
                                >
                                  {(item.fileSize / 1024).toFixed(2)} KB
                                </Text>
                              </Stack>
                            </Stack>
                            <Stack
                              margin={"8px 0px 8px 0px"}
                              gap={0}
                              justifyContent={"flex-end"}
                              direction={"row"}
                              alignItems={"center"}
                              marginRight={"4px"}
                            >
                              <Button
                                variant={"ghost"}
                                onClick={() => window.open(item.url, "_blank")}
                                color={"#3454d1"}
                                _hover={{
                                  bg: "rgba(0,10,30,.05)",
                                }}
                              >
                                View
                              </Button>
                              <Icon
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="50%"
                                bg={"#fff"}
                                w="32px"
                                h="32px"
                                px="8px"
                                py="8px"
                                as={BiTrash}
                                color="#6B7280"
                                _hover={{
                                  color: "red",
                                  bg: "rgba(0,10,30,.05)",
                                }}
                                onClick={() => {
                                  AddInvoices.DeleteMediaFiles(
                                    form.getValues("id")!,
                                    item.id,
                                    (success) => {
                                      setMediaFilesLastUpdated(Date.now());
                                    },
                                    () => {}
                                  );
                                }}
                              />
                            </Stack>
                          </Stack>
                        );
                      })}
                    </Stack>
                  )}
                </MenuList>
              </Menu>
            </Stack>
            <Stack flex={1.25} gap={0}>
              <HStack alignItems={"stretch"} gap={0} minH={"54.1px"}>
                <Text
                  padding={"15px"}
                  border={"1px solid #e5e7eb"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontWeight={"400"}
                  fontSize={"15px"}
                  flex={3}
                >
                  Subtotal
                </Text>
                <Text
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontWeight={"400"}
                  fontSize={"15px"}
                  padding={"15px"}
                  border={"1px solid #e5e7eb"}
                  flex={2}
                  textAlign={"right"}
                >
                  {/* {watchAccount === "Tax inclusive" && <>{gstValue}</>}
                {watchAccount !== "Tax inclusive" && ( */}
                  <>
                    {currencyOptions.find(
                      (currency) => currency.value === form.watch("currency")
                    )?.symbol ?? ""}{" "}
                    {sumOfAmount != null
                      ? sumOfAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""}
                  </>

                  {/* )} */}
                </Text>
              </HStack>

              {autoTaxes.fields.length > 0 &&
                autoTaxes.fields.map((tax, index) => (
                  <HStack
                    alignItems={"stretch"}
                    gap={0}
                    minH={"54.1px"}
                    key={index}
                  >
                    <Text
                      padding={"15px"}
                      border={"1px solid #e5e7eb"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"400"}
                      fontSize={"15px"}
                      flex={3}
                    >
                      {`${
                        taxRateKeyValues[tax.taxRateId.toString()]?.name ?? ""
                      }(${taxRateKeyValues[tax.taxRateId.toString()]?.rate}%)`}
                    </Text>
                    <Text
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"400"}
                      fontSize={"15px"}
                      padding={"15px"}
                      border={"1px solid #e5e7eb"}
                      flex={2}
                      textAlign={"right"}
                    >
                      <>
                        {currencyOptions.find(
                          (currency) =>
                            currency.value === form.watch("currency")
                        )?.symbol ?? ""}{" "}
                        {tax?.taxAmount != null
                          ? tax?.taxAmount?.toFixed(2)
                          : ""}
                      </>
                    </Text>
                  </HStack>
                ))}
              {/* Manual Taxes */}
              {manualTaxes.fields.map((tax, index) => (
                <HStack key={tax.id} alignItems="stretch" gap={0} flex={1}>
                  <HStack
                    flex={3}
                    gap={0.5}
                    padding={"15px"}
                    border={"1px solid #e5e7eb"}
                  >
                    <Icon
                      as={IoCloseOutline}
                      color="red"
                      marginLeft={"-11px"}
                      onClick={() => removeManualTax(index)}
                    />

                    <Box maxW="70%" minW="70%">
                      <Controller
                        name={`manualTaxes.${index}.taxRateId`}
                        control={form.control}
                        render={({ field, fieldState }) => (
                          <ReactSelect
                            {...field}
                            options={taxRateData?.map((taxRate: any) => ({
                              value: taxRate.id,
                              label: `${
                                taxRate.name
                              } (${taxRate.tax_components.reduce(
                                (sum: any, component: any) =>
                                  sum + component.tax,
                                0
                              )}%)`,
                            }))}
                            value={
                              taxRateData
                                ?.map((taxRate: any) => ({
                                  value: taxRate.id,
                                  label: `${
                                    taxRate.name
                                  } (${taxRate.tax_components.reduce(
                                    (sum: any, component: any) =>
                                      sum + component.tax,
                                    0
                                  )}%)`,
                                }))
                                .find(
                                  (option) => option.value === field.value
                                ) || null
                            }
                            onChange={(newValue: any) => {
                              field.onChange(newValue?.value);
                            }}
                            getOptionLabel={(option: any) => option.label} // Label to show in dropdown
                            getOptionValue={(option: any) => option.value} // Value to track in form
                            placeholder="Select"
                          />
                        )}
                      />
                    </Box>
                    <Box w="40%">
                      <Controller
                        name={`manualTaxes.${index}.taxAmount`}
                        control={form.control}
                        render={({ field }) => (
                          <CustomInput
                            {...field}
                            withValidation
                            input={{
                              ...field,
                              placeholder: "",
                              padding: "12px 5px",
                              _light: {
                                color: "customColor.black7",
                              },
                              onChange: (e) => {
                                field.onChange(Number(e.target.value));
                              },
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "number",
                              borderRadius: "4px",
                              bgColor: "#fff",
                            }}
                          />
                        )}
                      />
                    </Box>
                  </HStack>
                  <Text
                    flex={2}
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontWeight={"400"}
                    fontSize={"15px"}
                    textAlign="right"
                    padding={"15px"}
                    border={"1px solid #e5e7eb"}
                  >
                    <>
                      {currencyOptions.find(
                        (currency) => currency.value === form.watch("currency")
                      )?.symbol ?? ""}{" "}
                      {form.watch(`manualTaxes.${index}.taxAmount`) != null
                        ? form
                            .watch(`manualTaxes.${index}.taxAmount`)
                            .toFixed(2)
                        : ""}
                    </>
                  </Text>
                </HStack>
              ))}

              {/* Add Manual Tax Button */}
              <Button
                onClick={addManualTax}
                variant={"Link"}
                _hover={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                color={"#3454d1"}
                w="fit-content"
                p={0}
                marginTop={"10px"}
              >
                Add Manual Tax
              </Button>

              <Stack borderTop={"2px solid #e5e7eb"}>
                <HStack
                  justifyContent={"space-between"}
                  paddingTop={"10px"}
                  paddingBottom={"10px"}
                >
                  <Text
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontWeight={"400"}
                    fontSize={"15px"}
                  >
                    Total
                  </Text>
                  <Text
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontWeight={"700"}
                    fontSize={"21px"}
                  >
                    <>
                      {currencyOptions.find(
                        (currency) => currency.value === form.watch("currency")
                      )?.symbol ?? ""}{" "}
                      {watchTotalAmount != null
                        ? watchTotalAmount.toFixed(2)
                        : ""}
                    </>
                  </Text>
                </HStack>
              </Stack>
              {paymentData.length > 0 && (
                <Text
                  color={"#3454d1"}
                  fontWeight={"700"}
                  marginBottom={"15px"}
                  fontSize={"15px"}
                >
                  Payment History
                </Text>
              )}

              {paymentData.map((item, i) => {
                return (
                  <Stack key={i} direction={"row"} gap={0}>
                    <Text
                      flex={1}
                      padding={"15px"}
                      border={"1px solid #e5e7eb"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"600"}
                      fontSize={"15px"}
                      _hover={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/app/payroll/PaymentSummary", {
                          state: {
                            datePaid: item.datePaid,
                            reference: item.reference,
                            amountPaid: item.amountPaid,
                            invoiceUniqueId: form.getValues("invoiceNumber"),
                            totalAmount: form.getValues("totalAmount"),
                            issueDate: form.getValues("issueDate"),
                            accountName: getAccountName(item.accountId),
                            contactName: getContactName(
                              form.getValues("contactId")
                            ),
                          },
                        });
                      }}
                    >
                      {moment(item.datePaid).format("DD MMM YYYY")}
                    </Text>

                    <Text
                      textDecoration={"underline"}
                      padding={"15px 30px 15px 15px"}
                      border={"1px solid #e5e7eb"}
                      borderLeft={0}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"400"}
                      fontSize={"15px"}
                    >
                      {currencyOptions.find(
                        (currency) => currency.value === form.watch("currency")
                      )?.symbol ?? ""}{" "}
                      {item.amountPaid}
                    </Text>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>

          <Box w="100%" marginBottom={"15px"}>
            <Text color={"#283C50"} fontWeight={"600"} fontSize="13.52px">
              Notes
            </Text>
            <Controller
              control={form.control}
              name="notes"
              render={({ field }) => (
                <Textarea
                  {...field}
                  placeholder="Enter notes here"
                  w={"100%"}
                  h={"100px"}
                  borderRadius={"3px"}
                  padding={"12px"}
                  fontSize={"13px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                />
              )}
            />
          </Box>
        </Stack>

        <ShowAndHideModal
          isOpen={showHideModal.isOpen}
          onClose={showHideModal.onClose}
          fieldNames={fieldNames}
          fieldsState={fieldsState}
          handleCheckboxChange={handleCheckboxChange}
          setFieldsState={setFieldsState}
          defaultState={defaultState}
        />
        <AddModal isOpen={addModal.isOpen} onClose={addModal.onClose} />
      </FormProvider>
      <FormProvider {...ContactForm}>
        <AddContactModal
          isOpen={contactModal.isOpen}
          onClose={contactModal.onClose}
          setLastUpdated={setContactLastUpdated}
        />
      </FormProvider>
    </Stack>
  );
}
