import {
  Box,
  Flex,
  Icon,
  IconButton,
  Input,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { AiOutlineFileAdd } from "react-icons/ai";
import { FaRegFilePdf } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { UploadAttachments } from "../../../../Payroll/Api/UploadAttachments";
import { Template } from "../../../modules/Audit";

interface TemplateBuilderHeader {
  form: UseFormReturn<Template.Template, any>;
}
export default function TemplateDocuments({ form }: TemplateBuilderHeader) {
  const toast = useToast();
  const docsArr = useFieldArray({
    control: form.control,
    name: "templateMedia",
    keyName: "docId",
  });
  const uploadFile = (file: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result as string;
      const base64Content = base64.split(",")[1];
      // Inspection.UploadMedia(
      //   {
      //     inspectionId: `template_${form.getValues('template.id')}`, // Ensure this ID is correctly fetched
      //     fileName: file.name,
      //     base64: base64Content,
      //   },
      //   path => {
      //     docsArr.append({
      //       tempId: form.getValues('template.id')!, // Assuming this fetches the template ID correctly
      //       docUrl: path,
      //       fileName: file.name,
      //     });
      //   },
      //   errorMessage => {
      //     toast({
      //       title: 'Error',
      //       description: errorMessage,
      //       status: 'error',
      //     });
      //   },
      // );
      const formData = new FormData();
      formData.append("docType", "TEMPLATE");
      formData.append("file", file);
      formData.append("templateId", String(form.getValues("template.id")));
      UploadAttachments.UploadAttachment(
        formData,
        (success) => {
          docsArr.append({
            tempId: form.getValues("template.id")!, // Assuming this fetches the template ID correctly
            docUrl: success.url,
            fileName: file.name,
          });
        },
        (err) => {
          toast({
            title: "Error",
            description: err,
            status: "error",
          });
        }
      );
    };
    reader.readAsDataURL(file);
  };

  const handleFilesChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files) {
      Array.from(files).forEach(uploadFile);
    }
  };
  return (
    <Flex alignItems="center">
      {docsArr?.fields?.map((field, index) => (
        <Flex
          key={field.docId}
          boxSize="100px"
          maxW={"100px"}
          justifyContent="center"
          alignItems="center"
          position="relative"
          cursor="pointer"
          mr="10px"
        >
          <Link href={field.docUrl} isExternal isTruncated>
            <Box w="100%" alignItems="center">
              <Icon as={FaRegFilePdf} boxSize={5} />
              <Text fontSize="11px" isTruncated>
                {field.fileName}
              </Text>
            </Box>
          </Link>
          <IconButton
            aria-label="Delete file"
            icon={<MdDelete color="red" />}
            backgroundColor="transparent"
            _hover={{ backgroundColor: "transparent" }}
            size="sm"
            onClick={() => docsArr.remove(index)}
            position="absolute"
            top="0"
            right="0"
            colorScheme="red"
          />
        </Flex>
      ))}
      <Input
        type="file"
        accept="application/pdf"
        multiple
        onChange={handleFilesChange}
        hidden
        id="file-upload"
      />
      <Box
        onClick={() => {
          const FileInput = document.getElementById("file-upload");
          if (FileInput) {
            FileInput.click();
          } else {
            toast({
              title: "Error",
              description: "File input not found",
              status: "error",
            });
          }
        }}
        boxSize="50px"
        borderWidth="2px"
        borderColor="gray.300"
        borderStyle={"dashed"}
        borderRadius="5px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <AiOutlineFileAdd size="40px" color="#718096" />
      </Box>
    </Flex>
  );
}
