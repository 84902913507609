import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  HStack,
  IconButton,
  Image,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { MdDelete, MdOutlinePictureAsPdf } from "react-icons/md";
import { Template } from "../../../modules/Audit";
interface ImageViewProps {
  onclick: () => void;
  index: number;
  watchedMediaObject: Template.MediaObject & { base64?: string };
}

export function ImageView({
  onclick,
  index,
  watchedMediaObject,
}: ImageViewProps) {
  const template = useFormContext();
  const isBanner = template.getValues("question.properties.banner");
  const [isLoading, setIsLoading] = useState(true);
  return (
    <Box position="relative" cursor="pointer">
      {isLoading && (
        <Spinner
          size="lg"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        />
      )}
      <Box
        mt="16px"
        // background="blue"
        boxSize={isBanner ? "150px" : "64px"}
        borderRadius="8px"
        borderColor="borders.accent"
        position="relative"
        role="group"
      >
        <IconButton
          icon={<DeleteIcon />}
          aria-label="Delete"
          position="absolute"
          variant="ghost"
          colorScheme="red"
          top="4px"
          right="4px"
          display="none"
          _hover={{ background: "tranparent" }}
          _groupHover={{
            display: "block",
          }}
          // onClick={() => {
          //   medias.remove(i);
          // }}
          onClick={onclick}
        />

        <Image
          h="100%"
          w="100%"
          borderRadius="8px"
          src={
            watchedMediaObject?.mediaUrl
              ? watchedMediaObject?.mediaUrl
              : `data:image/png;base64,${watchedMediaObject?.base64}`
          }
          onLoad={() => setIsLoading(false)}
          display={isLoading ? "none" : "block"}
        />
      </Box>
    </Box>
  );
}
export function PdfView() {
  return (
    <Stack
      border="1px solid"
      borderColor="borders.accent"
      borderRadius="8px"
      px="12px"
      py="8px"
      mt="16px"
      direction="row"
      justifyContent="space-between"
    >
      <HStack>
        <MdOutlinePictureAsPdf fill="#1da5d4" size="15px" />
        <Text fontSize="12px" fontWeight={400} color="primary.500">
          File Name
        </Text>
      </HStack>
      <IconButton
        justifyContent="center"
        alignSelf="center"
        backgroundColor="transparent"
        borderRadius={"50%"}
        border={"none"}
        size="22.5px"
        _hover={{ backgroundColor: "#bfc6d4" }}
        _focus={{ backgroundColor: "transparent" }}
        _focusVisible={{ backgroundColor: "transparent" }}
        _active={{ backgroundColor: "transparent" }}
        aria-label="delete"
        icon={<MdDelete color="#545f70" size="16px" />}
      />
    </Stack>
  );
}
