import {Box, Container} from '@chakra-ui/react';

function MobilePreview() {
  return (
    <Container
      position="sticky"
      top="130px"
      bgColor="green"
      minH="full"
      w="full"
      maxW="400px"
      zIndex={2}
      // display={{
      //   xl: 'block',
      //   xs: 'none',
      // }}
      display="none">
      <Box>This is preview</Box>
    </Container>
  );
}

export default MobilePreview;
