import {
  Box,
  Button,
  Input,
  InputGroup,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { AddInvoices } from "../../Api/Invoices";
import { PaginationControl } from "../PayrollEmployee/PaginationControl/PaginationControl";

interface Props {
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setLastUpdated: React.Dispatch<React.SetStateAction<number>>;
  selectedId: string;
  selectedIds: string[];
  category: string;
}
export function Header({
  limit,
  setLimit,
  setPageNumber,
  setLastUpdated,
  selectedId,
  selectedIds,
  category,
}: Props) {
  const buttonSize = useBreakpointValue({ base: "sm", sm: "md" });
  const toast = useToast();
  return (
    <Stack gap={0}>
      <Stack
        p={"25px"}
        direction={{ xs: "column", md: "row" }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box w={"100%"}>
          <PaginationControl
            limit={limit}
            setLimit={setLimit}
            setPageNumber={setPageNumber}
          />
        </Box>
        <Stack gap={0} direction={"row"} alignItems={"center"}>
          <Text
            fontSize={"13.44px"}
            _light={{
              color: "customColor.gray.6b7885",
            }}
          >
            Search:
          </Text>
          <InputGroup ml={[0, "7px"]}>
            <Input
              type="text"
              borderRadius={"5px"}
              minW={"200px"}
              maxW={"200px"}
              h={["auto", "40px"]}
              padding={"8px 15px"}
              border={"1px solid #e5e7eb"}
              _focusVisible={{
                border: "1px solid grey",
              }}
              // onChange={(e) => {
              //   debounced(e.target.value);
              // }}
            />
          </InputGroup>
        </Stack>
      </Stack>
      {category !== "All" && (
        <Stack direction={"row"} alignItems={"center"} p={"0px 25px 15px 25px"}>
          {category === "Draft" && (
            <Button
              color={"#999"}
              isDisabled={selectedId !== "" ? false : true}
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              marginRight={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"25px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
                color: "orange",
              }}
              size={buttonSize}
              onClick={(e) => {
                if (selectedId) {
                  // Now, submit the invoice for approval using the ID
                  AddInvoices.SubmitForApproval(
                    selectedId,
                    (approvalSuccess) => {
                      setLastUpdated(Date.now());
                    },
                    (approvalError) => {}
                  );
                } else {
                }
              }}
            >
              Submit for approval
            </Button>
          )}
          {(category === "Draft" || category === "Awaiting Approval") && (
            <>
              <Button
                color={"#999"}
                isDisabled={selectedId !== "" ? false : true}
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                marginRight={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"25px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                  color: "#3454d1",
                }}
                size={buttonSize}
                onClick={(e) => {
                  if (selectedId) {
                    // Now, submit the invoice for approval using the ID
                    AddInvoices.Approval(
                      selectedId,
                      (approvalSuccess) => {
                        toast({
                          title: approvalSuccess,
                          status: "success",
                        });

                        setLastUpdated(Date.now());
                      },
                      (approvalError) => {
                        toast({
                          title: approvalError,
                          status: "error",
                        });
                      }
                    );
                  } else {
                    toast({
                      title: "Invoice ID not found",
                      status: "error",
                    });
                  }
                }}
              >
                Approve
              </Button>

              <Button
                color={"#999"}
                isDisabled={selectedId !== "" ? false : true}
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                marginRight={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"25px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                  color: "#EA4D4D",
                }}
                size={buttonSize}
                onClick={() => {
                  AddInvoices.DeleteInvoices(
                    selectedId,
                    (success) => {
                      toast({
                        title: "Invoice Deleted Successfully",
                        status: "success",
                      });

                      setLastUpdated(Date.now());
                    },
                    () => {
                      toast({
                        title: "Error Deleting Invoices",
                        status: "error",
                      });
                    }
                  );
                }}
              >
                Delete
              </Button>
            </>
          )}
          <Button
            color={"#999"}
            isDisabled={selectedId !== "" ? false : true}
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            marginRight={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"25px"}
            variant={"solid"}
            textColor={"customColor.black7"}
            padding={"12px 16px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
              color: "teal.500",
            }}
            size={buttonSize}
          >
            Print
          </Button>
          <Button
            color={"#999"}
            isDisabled={selectedId !== "" ? false : true}
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            marginRight={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"25px"}
            variant={"solid"}
            textColor={"customColor.black7"}
            padding={"12px 16px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
              color: "green",
            }}
            size={buttonSize}
          >
            Email
          </Button>{" "}
          <Button
            color={"#999"}
            isDisabled={selectedId !== "" ? false : true}
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            marginRight={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"25px"}
            variant={"solid"}
            textColor={"customColor.black7"}
            padding={"12px 16px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
              color: "#3454d1",
            }}
            size={buttonSize}
          >
            Copy to ....
          </Button>
          <Text color={"#868686"} fontSize={"11px"}>
            {selectedIds.length} account selected
          </Text>
        </Stack>
      )}
    </Stack>
  );
}
