import {
  Checkbox,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Fragment, useRef } from "react";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { FiChevronLeft, FiExternalLink } from "react-icons/fi";
import CreateGroup from "../Modals/CreateGroup";

interface GroupProps {
  onBack?: () => void;
}
export default function Group({ onBack }: GroupProps) {
  const ref = useRef(null);
  // const {isOpen, onOpen, onClose, onToggle} = useDisclosure();
  const CreateGroupModal = useDisclosure();
  return (
    // <Popover isOpen={isOpen} matchWidth={true} offset={[0, 0]}>
    //   <PopoverContent w="100%" minW="400px" pb="8px" ref={ref}>
    //     <PopoverHeader
    //       p="16px"
    //       borderBottom="1px solid"
    //       borderColor="backgrounds.primary">
    // <HStack textAlign="center" lineHeight="16px">
    //   <HStack spacing={1} onClick={onClose}>
    //     <FiChevronLeft size={14} color="#1da5d4" />
    //     <Text fontSize="14px" fontWeight={400} color="textcolors.primary">
    //       Back
    //     </Text>
    //   </HStack>
    //   <Text flex={1} fontSize="14px" fontWeight={500} color="primary.500">
    //     Select Group
    //   </Text>
    // </HStack>
    //     </PopoverHeader>
    //     <PopoverBody p="8px">
    <Fragment>
      <Stack p="10px">
        {onBack && (
          <HStack
            textAlign="center"
            lineHeight="16px"
            p="6px"
            borderBottom="1px"
            borderColor="backgrounds.primary"
          >
            <HStack spacing={1} onClick={onBack}>
              <FiChevronLeft size={14} color="#1da5d4" />
              <Text fontSize="14px" fontWeight={400} color="textcolors.primary">
                Back
              </Text>
            </HStack>
            <Text flex={1} fontSize="14px" fontWeight={500} color="primary.500">
              Select Group
            </Text>
          </HStack>
        )}
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<AiOutlineSearch color="#545f70" size="18px" />}
          />
          <Input
            w="100%"
            placeholder="Search"
            border="1px solid"
            borderColor="borders.accent"
            borderRadius="4px"
            fontSize="16px"
            fontWeight={400}
            color="textcolors.primary"
            py="8px"
            pl="32px"
            _focusVisible={{
              boxShadow: "none",
              border: "1px sloid",
              borderColor: "primary.500",
            }}
          ></Input>
        </InputGroup>
        <Stack pt="8px" spacing={0}>
          <Text
            pt="16px"
            pb="8px"
            fontSize="13px"
            fontWeight={400}
            color="icons.primary"
            lineHeight="16px"
          >
            SELECTED ITEMS
          </Text>
          <Checkbox
            lineHeight="20px"
            p="8px"
            fontSize="14px"
            fontWeight={400}
            color="textcolors.primary"
            _hover={{ backgroundColor: "primary.50" }}
          >
            QSComply
          </Checkbox>
        </Stack>
        <Stack pb="8px" spacing={0}>
          <HStack justifyContent="space-between">
            <Text
              pt="16px"
              pb="8px"
              fontSize="13px"
              fontWeight={400}
              color="icons.primary"
              lineHeight="16px"
            >
              Groups
            </Text>
            <HStack lineHeight="16px" pt="16px" pb="8px">
              <Link fontSize="13px" fontWeight={400}>
                Manage groups
              </Link>
              <FiExternalLink size={16} color="#1da5d4" />
            </HStack>
          </HStack>
          <HStack p="8px" onClick={CreateGroupModal.onOpen}>
            <AiOutlinePlus size={21} color="#1da5d4" />
            <Text
              flex={1}
              fontSize="14px"
              fontWeight={400}
              lineHeight="16px"
              color="primary.500"
              _hover={{ textDecoration: "underline" }}
            >
              Create group
            </Text>
          </HStack>
          <CreateGroup
            isOpen={CreateGroupModal.isOpen}
            onClose={CreateGroupModal.onClose}
          />
          <Checkbox
            lineHeight="20px"
            p="8px"
            fontSize="14px"
            fontWeight={400}
            color="textcolors.primary"
            _hover={{ backgroundColor: "primary.50" }}
          >
            QSComply
          </Checkbox>
        </Stack>
      </Stack>
    </Fragment>
    //     </PopoverBody>
    //     <PopoverFooter borderColor="borders.accent" textAlign="end">
    //       <Button
    //         h="40px"
    //         w={'auto'}
    //         borderRadius="5px"
    //         fontSize="14px"
    //         fontWeight={500}
    //         marginRight="16px"
    //         padding={'12px 20px'}>
    //         Done
    //       </Button>
    //     </PopoverFooter>
    //   </PopoverContent>
    // </Popover>
  );
}
