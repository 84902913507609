import axiosInstanceGateway from "./axiosInstance-gateway";

declare module Business {
  type AddNewBusiness = {
    logo: string;
    name: string;
    industry: string;
    country: string;
    timezone: string;
    currency: string;
    last_date_financial_year: string;
    last_month_financial_year: string;
    gst_registered: boolean;
    id?: string;
    activationCode: string;
  };
  type GetBusinessDetails = {
    id: string;
    logo: string;
    name: string;
    industry: string;
    country: string;
    timezone: string;
    currency: string;
    last_date_financial_year: number;
    last_month_financial_year: number;
    gst_registered: boolean;
    userId: string;
    employeeCount?: string;
    userCount?: string;
    accessLevel?: string;
    allowedFeatures: {
      onDemandNotifications: {
        onDemandNotifications: boolean;
      };
      contacts: {
        allContacts: boolean;
      };
      employee: {
        financialDetails: boolean;
        personalDetails: boolean;
        employees: boolean;
        leave: boolean;
      };
      expenseClaim: {
        expenseClaim: boolean;
      };

      timeAndAttendance: {
        locations: boolean;
        checkinReport: boolean;
        notifications: boolean;
        schedules: boolean;
        statistics: boolean;
      };
      auditor: {
        templates: boolean;
        inspections: boolean;
        schedule: boolean;
        periodicalSchedule: boolean;
        periodicalReports: boolean;
        notificationSettings: boolean;
      };
      payroll: {
        timesheets: boolean;
        payRuns: boolean;
        payrollSettings: boolean;
      };
      reports: {
        employeeDetails: boolean;
        employeeContactDetails: boolean;
        employeeRemuneration: boolean;
        leaveRequests: boolean;
        leaveBalance: boolean;
        payrollActivityDetail: boolean;
        payrollActivitySummary: boolean;
        payrollEmployeeSummary: boolean;
        attendanceReport: boolean;
        transactionListingDetail: boolean;
        transactionListingSummary: boolean;
      };
      statuaryBenefits: {
        statuaryBenefits: boolean;
      };
      business: {
        billsToPay: boolean;
        invoices: boolean;
        productsAndServices: boolean;
      };
      accounting: {
        chartOfAccounts: boolean;
        taxRates: boolean;
      };
      businessSettings: {
        businessSetup: boolean;
        users: boolean;
        mappingWidow: boolean;
        payrollSettings: boolean;
        invoiceSettings: boolean;
        editBusinessPermission: boolean;
      };
    };
  };
  type businessReponse = {
    logo: string;
    name: string;
    industry: string;
    country: string;
    timezone: string;
    currency: string;
    last_date_financial_year: number;
    last_month_financial_year: number;
    gst_registered: boolean;
    businessDetails: {
      branch: string;
      contactEmail: string;
      contactName: string;
      organizationType: string;
      contactNumber: string;
      legalName: string;
      organizationDescription: string;
      taxNumber: string;
      website: string;
      displayName: string;
      businessId: string;
      id: string;
    };
    userId: string;
    id: string;
  };
}
module Business {
  const API_URL = "/business";
  export function BusinessAdd(
    data: AddNewBusiness,
    success: (response: businessReponse) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function UpdateBusiness(
    id: string,
    data: Partial<AddNewBusiness>,
    success: (response: businessReponse) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .patch(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function DeleteBusiness(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .delete(`${API_URL}/${id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function GetAllBusiness(
    success: (response: Array<GetBusinessDetails>) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(API_URL)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function SwitchBusiness(
    id: string,
    accessLevel: string,
    success: (response: { token: string }) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}/switchBusiness/${id}/${accessLevel}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { Business };
