import { axiosInstance } from "./axiosInstance-aud";
import { Common } from "./Common";

declare module TemplateItem {
  type ItemType = "PAGE" | "SECTION" | "QUESTION";
  interface TemplateItem {
    title: string;
    type: ItemType;
    templateId: number;
    orderIndex: number;
    parentId: number;
    status: boolean;
    children?: Array<TemplateItem>;
    id?: number;
  }
}

module TemplateItem {
  const API_BASE_URL = "/template-items";
  export function Fetch(
    success: (response: Array<TemplateItem>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(API_BASE_URL)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function Create(
    data: TemplateItem,
    success: (response: TemplateItem) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(API_BASE_URL, data)
      .then(({ data }) => {
        const response = data as Common.Success<TemplateItem>;
        success(response.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function GetById(
    id: number,
    success: (response: Array<TemplateItem>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_BASE_URL}/${id}`)
      .then(({ data }) => {
        const response = data as Common.Success<Array<TemplateItem>>;
        success(response.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function Update(
    id: number,
    data: Partial<Omit<TemplateItem, "id">>,
    success: (response: Array<TemplateItem>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_BASE_URL}/${id}`, data)
      .then(({ data }) => {
        const response = data as Common.Success<Array<TemplateItem>>;
        success(response.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function Delete(
    id: number,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_BASE_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { TemplateItem };
