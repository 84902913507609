import {
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import {Inspection} from '../../../modules/Audit';
import {useInspectionContext} from '../hooks/InspectionContext';
import {useAuditQuestionContainer} from './AuditQuestionContainer';

export default function SliderPreview() {
  const labelStyles = {
    mt: '24px',
    fontSize: 'xs',
  };
  const template = useFormContext();
  const {getItemField, score, onScoreChanged} = useAuditQuestionContainer();
  const watchedScore = useWatch({
    control: template.control,
    name: getItemField('question.questionScore'),
  });
  const [showTooltip, setShowTooltip] = useState(false);
  const properties = useWatch({
    control: template.control,
    name: getItemField('question.properties'),
  });
  useEffect(() => {
    const defaultValue =
      template.getValues(getItemField('question.questionValue')) ??
      Number(properties.min);
    template.setValue(
      getItemField('question.questionValue'),

      defaultValue,
    );

    onScoreChanged?.(defaultValue);
  }, []);
  const {setIsSaving} = useInspectionContext();
  return (
    <Stack>
      <Controller
        control={template.control}
        name={getItemField('question.questionValue')}
        render={({field}) => (
          <Slider
            min={Number(properties.min)}
            max={Number(properties.max)}
            focusThumbOnChange={false}
            step={Number(properties.increment)}
            maxW="full"
            marginTop="36px"
            marginBottom="44px"
            defaultValue={field.value}
            {...field}
            onChangeEnd={value => {
              // const value = +e.target.value;

              if (score && !Number.isNaN(+value)) {
                onScoreChanged?.(+value, watchedScore);
                template.setValue(
                  getItemField('question.questionScore'),
                  +value,
                );
              }
              const answerId = template.getValues(
                getItemField('question.answerId'),
              );
              const type = template.getValues(getItemField('question.type'));
              const inspectionMetaID = template.getValues('inspection.id');
              const notes = template.getValues(getItemField('question.notes'));
              const question_score = template.getValues(
                getItemField('question.score'),
              );
              const question_value = template.getValues(
                getItemField('question.questionValue'),
              );
              setIsSaving(true);
              Inspection.CachingInspection(
                answerId,
                {
                  question_value: String(question_value),
                  notes: notes,
                  inspection_meta_id: inspectionMetaID,
                  type: type,
                  question_score: question_value,
                },
                () => {
                  setIsSaving(false);
                },
                () => {
                  setIsSaving(false);
                },
              );
            }}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}>
            <SliderMark value={Number(properties.min)} {...labelStyles}>
              {Number(properties.min)}
            </SliderMark>
            <SliderMark
              // value={Number(properties.max) / 2}
              value={field.value}
              fontSize="sm"
              fontWeight={500}
              mt="24px"></SliderMark>
            <SliderMark value={Number(properties.max)} {...labelStyles}>
              {Number(properties.max)}
            </SliderMark>
            {/* <SliderMark
              value={field.value}
              textAlign="center"
              // bg="blue.500"
              color="black"
              mt="-10"
              ml="-2.5"
              w="12">
              {field.value}
            </SliderMark> */}
            <SliderTrack bg="primary.50">
              <SliderFilledTrack bg="primary.500" />
            </SliderTrack>
            <Tooltip
              hasArrow
              bg="primary.500"
              color="white"
              placement="top"
              isOpen={showTooltip}
              label={field.value}>
              <SliderThumb boxSize={6} />
            </Tooltip>
          </Slider>
        )}
      />
    </Stack>
  );
}
