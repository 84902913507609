import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { AiFillInfoCircle } from "react-icons/ai";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { AccessControl } from "../../../../api/AccessControl";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { EmployeeEmployment } from "../../../Api/EmployeeEmployment";
import { PayItemEarningApi } from "../../../Api/PayItemEarning";
import { PayItemRetirement } from "../../../Api/PayItemRetirement";
import { PayrollCalendar } from "../../../Api/PayRollCalendar";
import { PayrollHolidayGroup } from "../../../Api/PayrollHolidayGroup";
import HistoryAccordion from "../../Superannuation/History/HistoryAccordion";
interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export function Employment({ setSelectedTab, selectedTab }: TabProps) {
  const { id } = useParams();
  const toast = useToast();
  const form = useForm<EmployeeEmployment.AddNewEmployeeEmployment>({
    defaultValues: {
      empType: "",
      empIncomeType: "",
      empBasis: "",
      payrollPayCalenderId: undefined,
      employeeGroupId: undefined,
      holidayGroupId: undefined,
      ratesId: "",
      startDate: "",
      gratuityPayItemId: null,
      department: "",
      jobTitle: "",
      confirmationDate: undefined,
      noticePeriod: {
        unit: null,
        value: null,
      },
      probationNoticePeriod: {
        unit: null,
        value: null,
      },
      includeHolidaysPayslips: false,
      approveLeaveAuthorization: false,
      approveLeaveTimesheets: false,
      id: undefined,
      workPercentageOnPartTime: 0,
      isManagementStaff: false,
      manager: "",
    },
  });
  const [earningAdded, setEarningAdded] = useState<Array<any>>([]);
  const [retirementAdded, setRetirementAdded] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    PayItemEarningApi.FetchEarning(
      (data) => {
        setEarningAdded(data);
        setIsLoading(false);
      },
      (error) => {
        toast({
          title: "Earning not found",
          status: "error",
          description: error,
        });
        setIsLoading(false);
      }
    );
    PayItemRetirement.FetchRetirementFund(
      (data) => {
        let gratuityfundsArray = data.filter(
          (item) => item.category === "GRATUITY"
        );
        setRetirementAdded(gratuityfundsArray);
        setIsLoading(false);
      },
      (error) => {
        toast({
          title: "Retirement not found",
          status: "error",
          description: error,
        });
        setIsLoading(false);
      }
    );
  }, []);
  const [users, setUsers] = useState<AccessControl.UserDetail[]>([]);

  useEffect(() => {
    if (id) {
      EmployeeEmployment.GetEmploymentDetails(
        id,
        (data) => {
          form.reset(data);
          setIsLoading(false);
        },
        (error) => {
          toast({
            title: "Error",
            status: "error",
          });
          setIsLoading(false);
        }
      );
    } else {
      toast({
        title: "Employee Employment not found",
        status: "error",
      });
      setIsLoading(false);
    }
  }, [id]);
  useEffect(() => {
    AccessControl.GetAllUser(
      (data) => {
        setUsers(data);
        //  const users = data.map((user) => ({
        //   firstName: user.user.firstName,
        //   lastName: user.user.lastName,
        //   id: user.id,
        //   accessLevel: user.accessLevel,
        //   allowedFeatures: user.allowedFeatures,
        //   user: user.user,
      },
      (error) => {
        toast({
          title: "=Users not found",
          status: "error",
        });
      }
    );
  }, []);

  const incomeTypeOptions = [
    { value: "SALARY_AND_WAGES", label: "Monthly Salary" },
    { value: "CLOSELY_HELD_PAYEES", label: "Closely held payees" },
    { value: "WORKING_HOLIDAY_MAKER", label: "Working holiday maker" },
    {
      value: "PER_HOUR_RATE",
      label: "Per Hour Rate",
    },
    {
      value: "PER_DAY_RATE",
      label: "Per Day Rate",
    },
  ];
  const contractorIncomeTypeOptions = [
    { value: "LABOUR_HIRE", label: "Labour hire" },
    { value: "NON_EMPLOYEE", label: "Non-Employee" },
  ];

  const NoticePeriodUnits = [
    {
      label: "Days",
      value: EmployeeEmployment.EmployementNoticePeriodEnum.DAYS,
    },
    {
      label: "Weeks",
      value: EmployeeEmployment.EmployementNoticePeriodEnum.WEEKS,
    },
    {
      label: "Months",
      value: EmployeeEmployment.EmployementNoticePeriodEnum.MONTHS,
    },
    {
      label: "Years",
      value: EmployeeEmployment.EmployementNoticePeriodEnum.YEARS,
    },
  ];

  const employeeBasicOptions = [
    { value: "FULL_TIME", label: "Full-time" },
    { value: "PART_TIME", label: "Part-time" },
    { value: "CASUAL", label: "Daily Wager" },
  ];
  const { setRefereshEmployees } = useBusinessContext();
  const [holidayOptions, setHolidayOptions] = useState<
    PayrollHolidayGroup.HolidayGroup[]
  >([]);
  const [payOptions, setPayOptions] = useState<PayrollCalendar.Calendar[]>([]);
  const employeeOptions = [
    { value: "6561a85a-0aee-4f60-969d-21a90ddf2975", label: "None" },
  ];
  const ordinaryEarningRateOptions = [
    { value: "6561a85a-0aee-4f60-969d-21a90ddf2975", label: "Ordinary Hours" },
  ];
  // const watchProbationNoticePeriodUnit = useWatch({
  //   control: form.control,
  //   name: "probationNoticePeriod.unit",
  // });
  // const watchProbationNoticePeriodValue = useWatch({
  //   control: form.control,
  //   name: "probationNoticePeriod.value",
  // });
  useEffect(() => {
    PayrollHolidayGroup.FetchHolidayGroup(
      (data) => {
        setHolidayOptions(data);
      },
      (error) => {
        toast({
          title: "Error",
          status: "error",
        });
      }
    );
    PayrollCalendar.FetchCalendar(
      (data) => {
        setPayOptions(data);
      },
      (error) => {
        toast({
          title: "Error",
          status: "error",
        });
      }
    );
  }, []);
  const watchSelectedValue = useWatch({
    control: form.control,
    name: "empIncomeType",
  });

  const watchRadioValue = useWatch({
    control: form.control,
    name: "empType",
  });
  const watchEmployeeBasis = useWatch({
    control: form.control,
    name: "empBasis",
  });
  // useEffect(() => {
  //   if (!!watchProbationNoticePeriodUnit && !!watchProbationNoticePeriodValue) {
  //     let totalDays = 0;
  //     if (
  //       watchProbationNoticePeriodUnit ===
  //       EmployeeEmployment.EmployementNoticePeriodEnum.DAYS
  //     ) {
  //       totalDays = watchProbationNoticePeriodValue;
  //     } else if (
  //       watchProbationNoticePeriodUnit ===
  //       EmployeeEmployment.EmployementNoticePeriodEnum.WEEKS
  //     ) {
  //       totalDays = watchProbationNoticePeriodValue * 7;
  //     } else if (
  //       watchProbationNoticePeriodUnit ===
  //       EmployeeEmployment.EmployementNoticePeriodEnum.MONTHS
  //     ) {
  //       totalDays = watchProbationNoticePeriodValue * 30;
  //     } else if (
  //       watchProbationNoticePeriodUnit ===
  //       EmployeeEmployment.EmployementNoticePeriodEnum.YEARS
  //     ) {
  //       totalDays = watchProbationNoticePeriodValue * 365;
  //     }
  //     form.setValue(
  //       "confirmationDate",
  //       moment(form.getValues("startDate"))
  //         .add(totalDays, "days")
  //         .format("YYYY-MM-DD")
  //     );
  //   }
  // }, [watchProbationNoticePeriodUnit, watchProbationNoticePeriodValue]);
  return (
    <FormProvider {...form}>
      <Skeleton isLoaded={!isLoading}>
        <Stack
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          marginBottom={"30px"}
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          borderRadius={"5px"}
          border={"1px solid #f6f6f6"}
        >
          <Stack>
            <Box
              justifyContent="center"
              alignItems="center"
              p="0px"
              // maxW="940px"
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "customColor.white",
              }}
              // border="1px solid #d6dade"
              // borderRadius="md"
            >
              <Heading
                textAlign="left"
                fontSize=".9975rem"
                fontWeight="700"
                padding="20px"
                _light={{
                  color: "customColor.black7",
                }}
              >
                Employment Details
              </Heading>
              <Divider orientation="horizontal" />
              <Container
                maxW="400px"
                marginTop="15px"
                paddingTop="20px"
                pb="8px"
                marginX="auto"
                px="0px"
              >
                <Box
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  lineHeight={"1.3077"}
                >
                  <Heading
                    alignContent={"left"}
                    fontSize=".845rem"
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    _light={{
                      color: "customColor.black7",
                    }}
                    margin={"15px 0px"}
                    fontWeight="600"
                    lineHeight={"1.5rem"}
                  >
                    Select employment type
                  </Heading>
                  <Text
                    textAlign={"left"}
                    fontSize=".845rem"
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    _light={{
                      color: "customColor.black7",
                    }}
                    margin={"15px 0px"}
                    fontWeight={"400"}
                    lineHeight={"1.5rem"}
                  >
                    The type you select affects your tax obligations.
                    <Link
                      _light={{
                        color: "primary.950",
                      }}
                    >
                      Check if your payee is an employee or contractor
                      <Icon as={BsBoxArrowUpRight} />
                    </Link>
                  </Text>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Employee Type is required",
                      },
                    }}
                    name="empType"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <RadioGroup {...field}>
                        <HStack spacing={0}>
                          <Stack
                            border={"1px solid #a6a9b0"}
                            w={"100%"}
                            padding={"13px"}
                            borderRadius={"3px"}
                            h={"100px"}
                          >
                            <Radio
                              isInvalid={fieldState.invalid}
                              size="lg"
                              value="EMPLOYEE"
                              colorScheme="blue"
                              lineHeight={"1.5rem"}
                            >
                              <Text
                                fontSize={"0.9375rem"}
                                fontWeight={"600"}
                                _light={{
                                  color: "customColor.black7",
                                }}
                              >
                                Permanent
                              </Text>
                            </Radio>

                            <Text
                              textAlign={"left"}
                              marginTop={"4px"}
                              marginBottom={"4px"}
                              fontSize={"0.8125rem"}
                              _light={{
                                color: "customColor.black2",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontWeight={"400"}
                              marginLeft={"25px"}
                            >
                              An employee works in your business and is part of
                              your business.
                            </Text>
                          </Stack>
                          <Stack
                            border={"1px solid #a6a9b0"}
                            w={"100%"}
                            padding={"13px"}
                            borderRadius={"3px"}
                            h={"100px"}
                          >
                            <Radio
                              isInvalid={fieldState.invalid}
                              size="lg"
                              value="CONTRACTOR"
                              colorScheme="blue"
                              fontSize={"0.8125rem"}
                              fontWeight="600"
                              lineHeight={"1.5rem"}
                            >
                              <Text
                                fontSize={"0.9375rem"}
                                fontWeight={"600"}
                                _light={{
                                  color: "customColor.black7",
                                }}
                              >
                                Contractual
                              </Text>
                            </Radio>

                            <Text
                              textAlign={"left"}
                              marginTop={"4px"}
                              marginBottom={"4px"}
                              fontSize={"0.8125rem"}
                              _light={{
                                color: "customColor.black2",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontWeight={"400"}
                              marginLeft={"25px"}
                            >
                              A contractor runs their own business
                            </Text>
                          </Stack>
                        </HStack>
                      </RadioGroup>
                    )}
                  />
                  <Stack spacing="35px" marginTop={"20px"}></Stack>
                </Box>
                <Divider orientation="horizontal" paddingTop={"20px"} />
                <Box paddingTop={"30px"}>
                  <Stack
                    spacing="35px"
                    marginTop={"20px"}
                    marginBottom={"15px"}
                  >
                    {watchRadioValue === "EMPLOYEE" && (
                      <Stack>
                        <Heading
                          alignContent={"left"}
                          fontSize=".8125rem"
                          fontWeight={"600"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          lineHeight={"1.5rem"}
                        >
                          Select income type
                        </Heading>
                        <Text
                          textAlign={"left"}
                          fontSize="14px"
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          _light={{
                            color: "customColor.black7",
                          }}
                          margin={"15px 0px"}
                          fontWeight={"400"}
                        >
                          The type you select affects the pay items that can be
                          included in pay templates and Monthly SalarySlips, as
                          well as which tax details should be provided.
                          <Link
                            _light={{
                              color: "primary.950",
                            }}
                          >
                            Learn more about income types on HRIS 360 Central
                            <Icon as={BsBoxArrowUpRight} />
                          </Link>
                        </Text>
                        <Stack gap={0}>
                          <Text
                            textAlign={"left"}
                            fontSize=".845rem"
                            fontWeight="600"
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            lineHeight={"1.3077"}
                            margin={"0px 0px 8px"}
                          >
                            Income type
                          </Text>

                          <Controller
                            rules={{
                              required: {
                                value: true,
                                message: "Income  type is required",
                              },
                            }}
                            control={form.control}
                            name="empIncomeType"
                            render={({ field, fieldState }) => (
                              <ReactSelect
                                {...field}
                                key={field.value}
                                placeholder="Select Income Type"
                                isInvalid={fieldState.invalid}
                                ErrorMessage="Select Account Type"
                                withValidation
                                options={incomeTypeOptions}
                                value={incomeTypeOptions.find(
                                  (op) => op.value === field.value
                                )}
                                onChange={(newValue: any) => {
                                  field.onChange(newValue?.value);
                                }}
                                getOptionLabel={(option: any) => option.label}
                                getOptionValue={(option: any) => option.value}
                              />
                            )}
                          />
                        </Stack>
                      </Stack>
                    )}

                    {watchRadioValue === "CONTRACTOR" && (
                      <Stack>
                        <Heading
                          alignContent={"left"}
                          fontSize={"0.8125rem"}
                          fontWeight={"600"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          lineHeight={"1.5rem"}
                        >
                          Select income type
                        </Heading>
                        <Text
                          textAlign={"left"}
                          fontSize="14px"
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          _light={{
                            color: "customColor.black7",
                          }}
                          margin={"15px 0px"}
                          fontWeight={"400"}
                        >
                          The type you select affects the pay items that can be
                          included in pay templates and Monthly SalarySlips, as
                          well as which tax details should be provided.
                          <Link
                            _light={{
                              color: "primary.950",
                            }}
                          >
                            Learn more about income types on HRIS 360 Central
                            <Icon as={BsBoxArrowUpRight} />
                          </Link>
                        </Text>
                        <Stack gap={0}>
                          <Text
                            textAlign={"left"}
                            fontSize=".845rem"
                            fontWeight="600"
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            lineHeight={"1.3077"}
                            margin={"0px 0px 8px"}
                          >
                            Income type
                          </Text>

                          <Controller
                            rules={{
                              required: {
                                value: true,
                                message: "Income  type is required",
                              },
                            }}
                            control={form.control}
                            name="empIncomeType"
                            render={({ field, fieldState }) => (
                              <ReactSelect
                                {...field}
                                key={field.value}
                                placeholder="Select Income Type"
                                isInvalid={fieldState.invalid}
                                ErrorMessage="Select Account Type"
                                withValidation
                                options={incomeTypeOptions}
                                value={incomeTypeOptions.find(
                                  (op) => op.value === field.value
                                )}
                                onChange={(newValue: any) => {
                                  field.onChange(newValue?.value);
                                }}
                                getOptionLabel={(option: any) => option.label}
                                getOptionValue={(option: any) => option.value}
                              />
                            )}
                          />
                        </Stack>
                      </Stack>
                    )}
                    {watchRadioValue === "CONTRACTOR" &&
                      watchSelectedValue === "NON_EMPLOYEE" && (
                        <Box
                          backgroundColor={"#f2f3f4"}
                          fontSize={".8125rem"}
                          padding={"12px 16px 12px 16px"}
                        >
                          <HStack alignItems="flex-start">
                            <Icon as={AiFillInfoCircle} />
                            <Text
                              textAlign={"left"}
                              lineHeight={"1.5rem"}
                              fontSize=".845rem"
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.dark.200",
                              }}
                              fontWeight={"400"}
                              marginTop={"-5px"}
                            >
                              <b>Non-employee</b> is used to report Provident
                              Fund liabilities for contractors and can't have
                              associated payment types.
                            </Text>
                          </HStack>
                        </Box>
                      )}
                    {watchRadioValue === "CONTRACTOR" &&
                      watchSelectedValue === "LABOUR_HIRE" && (
                        <Box
                          backgroundColor={"#f2f3f4"}
                          fontSize={".8125rem"}
                          padding={"12px 16px 12px 16px"}
                        >
                          <HStack alignItems="flex-start">
                            <Icon as={AiFillInfoCircle} />
                            <Text
                              textAlign={"left"}
                              lineHeight={"1.5rem"}
                              fontSize=".845rem"
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.dark.200",
                              }}
                              fontWeight={"400"}
                              marginTop={"-5px"}
                            >
                              <b>Labour hire</b> only applies to payments by a
                              business to a contractor. Employees of labour hire
                              businesses should be reported with the relevent
                              income type. such as Monthly Salaryand wages.
                            </Text>
                          </HStack>
                        </Box>
                      )}
                  </Stack>

                  {watchRadioValue === "EMPLOYEE" &&
                    watchSelectedValue === "WORKING_HOLIDAY_MAKER" && (
                      <Box>
                        <CustomInput
                          withValidation
                          input={{
                            w: "100%",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "text",
                            border: "1px solid #a6a9b0",
                            borderTopRadius: "3px",
                          }}
                          placeholder="It will be ReactSelect after API integration"
                          label="Payee's country of origin"
                          labelProps={{
                            fontSize: ".845rem",
                            _light: {
                              color: "customColor.black7",
                            },
                          }}
                        />

                        <Text
                          textAlign={"left"}
                          fontSize={"11px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.dark.200",
                          }}
                          margin={"0px 0px 12px"}
                          padding={"8px 0px 0px"}
                        >
                          The is used when other tax jusisdictions apply to thr
                          Payee's income
                        </Text>
                      </Box>
                    )}
                </Box>

                {watchRadioValue === "CONTRACTOR" &&
                  watchSelectedValue === "NON_EMPLOYEE" && (
                    <Stack>
                      <Divider
                        orientation="horizontal"
                        paddingTop={"20px"}
                        size={"10px"}
                        variant={"solid"}
                      />
                      <Box>
                        <CustomInput
                          withValidation
                          input={{
                            w: "100%",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "text",
                            border: "1px solid #a6a9b0",
                            borderTopRadius: "3px",
                          }}
                          placeholder=""
                          label="Australian Business Number (ABN)"
                          labelProps={{
                            fontSize: ".845rem",
                            _light: {
                              color: "customColor.black7",
                            },
                          }}
                        />

                        <Text
                          textAlign={"left"}
                          fontSize={"11px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.dark.200",
                          }}
                          margin={"0px 0px 12px"}
                          padding={"8px 0px 0px"}
                        >
                          Your contractor's ABN
                        </Text>
                      </Box>
                    </Stack>
                  )}
                <Divider
                  orientation="horizontal"
                  paddingTop={"20px"}
                  size={"10px"}
                  variant={"solid"}
                />
                {watchRadioValue === "EMPLOYEE" && (
                  <Box paddingTop={"20px"}>
                    <Stack spacing="35px" marginTop={"20px"}>
                      <Stack gap={0}>
                        <Text
                          textAlign={"left"}
                          fontSize=".845rem"
                          fontWeight="600"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          lineHeight={"1.3077"}
                          margin={"0px 0px 8px"}
                        >
                          Is it a Management Staff
                        </Text>
                        <Controller
                          name="isManagementStaff"
                          control={form.control}
                          render={({ field, fieldState }) => (
                            <RadioGroup
                              {...field}
                              value={field.value.toString()}
                            >
                              <HStack>
                                <Radio
                                  isInvalid={fieldState.invalid}
                                  size="lg"
                                  value={"true"}
                                  colorScheme="green"
                                  lineHeight={"1.5rem"}
                                  onChange={() =>
                                    form.setValue("isManagementStaff", true)
                                  }
                                >
                                  <Text
                                    fontSize={"0.8125rem"}
                                    fontWeight="600"
                                    _light={{
                                      color: "customColor.black7",
                                    }}
                                  >
                                    Yes
                                  </Text>
                                </Radio>

                                <Radio
                                  isInvalid={fieldState.invalid}
                                  size="lg"
                                  value={"false"}
                                  colorScheme="red"
                                  fontSize={"0.8125rem"}
                                  fontWeight="600"
                                  lineHeight={"1.5rem"}
                                  onChange={() =>
                                    form.setValue("isManagementStaff", false)
                                  }
                                >
                                  <Text
                                    fontSize={"0.8125rem"}
                                    fontWeight="600"
                                    _light={{
                                      color: "customColor.black7",
                                    }}
                                  >
                                    No
                                  </Text>
                                </Radio>
                              </HStack>
                            </RadioGroup>
                          )}
                        />
                      </Stack>
                      <Stack gap={0}>
                        <Text
                          textAlign={"left"}
                          fontSize=".845rem"
                          fontWeight="600"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          lineHeight={"1.3077"}
                          margin={"0px 0px 8px"}
                        >
                          Employment basis
                        </Text>
                        <Controller
                          rules={{
                            required: {
                              value: true,
                              message: "Employee Basis is required",
                            },
                          }}
                          control={form.control}
                          name="empBasis"
                          render={({ field, fieldState }) => (
                            <ReactSelect
                              {...field}
                              key={field.value}
                              placeholder="Employment basis Type"
                              isInvalid={fieldState.invalid}
                              ErrorMessage="Select Account Type"
                              withValidation
                              options={employeeBasicOptions}
                              value={employeeBasicOptions.find(
                                (op) => op.value === field.value
                              )}
                              onChange={(newValue: any) => {
                                field.onChange(newValue?.value);
                                form.setValue("empBasis", newValue?.value);
                              }}
                              getOptionLabel={(option: any) => option.label}
                              getOptionValue={(option: any) => option.value}
                            />
                          )}
                        />
                      </Stack>
                    </Stack>
                    {/* {watchEmployeeBasis === "PART_TIME" && (
                      <Stack spacing={"35px"} marginTop={"20px"}>
                        <Controller
                          rules={{
                            required: {
                              value: true,
                              message: "Percentage is required",
                            },
                          }}
                          control={form.control}
                          name="workPercentageOnPartTime"
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              ref={field.ref}
                              validationMessage={error?.message}
                              withValidation
                              input={{
                                ...field,
                                isInvalid: error !== undefined,
                                w: "100%",
                                h: "38px",
                                padding: "12px 15px",
                                _light: {
                                  color: "customColor.black7",
                                },
                                fontSize: ".845rem",
                                variant: "outline",

                                borderTopRadius: "3px",
                                type: "number",
                                onChange: (e) => {
                                  field.onChange(
                                    Number(e.target.valueAsNumber)
                                  );
                                },
                              }}
                              placeholder=""
                              label="Percentage"
                              labelProps={{
                                fontSize: ".845rem",
                                _light: {
                                  color: "customColor.black7",
                                },
                              }}
                            />
                          )}
                        />
                      </Stack>
                    )} */}
                  </Box>
                )}
                {watchRadioValue === "CONTRACTOR" && (
                  <Box paddingTop={"20px"}>
                    <Stack spacing="35px" marginTop={"20px"}>
                      <Stack gap={0}>
                        <Text
                          textAlign={"left"}
                          fontSize=".845rem"
                          fontWeight="600"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          lineHeight={"1.3077"}
                          margin={"0px 0px 8px"}
                        >
                          Is it a Management Staff
                        </Text>
                        <Controller
                          name="isManagementStaff"
                          control={form.control}
                          render={({ field, fieldState }) => (
                            <RadioGroup
                              {...field}
                              value={field.value.toString()}
                            >
                              <HStack>
                                <Radio
                                  isInvalid={fieldState.invalid}
                                  size="lg"
                                  value={"true"}
                                  colorScheme="green"
                                  fontSize={"0.8125rem"}
                                  fontWeight="600"
                                  lineHeight={"1.5rem"}
                                  onChange={() =>
                                    form.setValue("isManagementStaff", true)
                                  }
                                >
                                  <Text
                                    fontSize={"0.8125rem"}
                                    fontWeight="600"
                                    _light={{
                                      color: "customColor.black7",
                                    }}
                                  >
                                    Yes
                                  </Text>
                                </Radio>

                                <Radio
                                  isInvalid={fieldState.invalid}
                                  size="lg"
                                  value={"false"}
                                  colorScheme="red"
                                  fontSize={"0.8125rem"}
                                  fontWeight="600"
                                  lineHeight={"1.5rem"}
                                  onChange={() =>
                                    form.setValue("isManagementStaff", false)
                                  }
                                >
                                  <Text
                                    fontSize={"0.8125rem"}
                                    fontWeight="600"
                                    _light={{
                                      color: "customColor.black7",
                                    }}
                                  >
                                    No
                                  </Text>
                                </Radio>
                              </HStack>
                            </RadioGroup>
                          )}
                        />
                      </Stack>
                      <Stack gap={0}>
                        <Text
                          textAlign={"left"}
                          fontSize=".845rem"
                          fontWeight="600"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          lineHeight={"1.3077"}
                          margin={"0px 0px 8px"}
                        >
                          Employment basis
                        </Text>
                        <Controller
                          // rules={{
                          //   required: {
                          //     value: true,
                          //     message: "Employee Basis is required",
                          //   },
                          // }}
                          control={form.control}
                          name="empBasis"
                          render={({ field, fieldState }) => (
                            <ReactSelect
                              {...field}
                              key={field.value}
                              {...field}
                              options={employeeBasicOptions}
                              withValidation
                              isInvalid={fieldState.invalid}
                              isDisabled={true}
                              ErrorMessage="Please Select Employee Basis"
                              placeholder="Select..."
                              value={employeeBasicOptions.find(
                                (op) => op.value === field.value
                              )}
                              onChange={(newValue: any) => {
                                field.onChange(newValue?.value);
                              }}
                              getOptionLabel={(option: any) => option.label}
                              getOptionValue={(option: any) => option.value}
                            />
                          )}
                        />
                      </Stack>
                    </Stack>
                  </Box>
                )}
              </Container>
              {(watchSelectedValue === "SALARY_AND_WAGES" ||
                watchSelectedValue === "CLOSELY_HELD_PAYEES" ||
                watchSelectedValue === "WORKING_HOLIDAY_MAKER" ||
                watchSelectedValue === "PER_DAY_RATE" ||
                watchSelectedValue === "PER_HOUR_RATE") && (
                <Stack borderTop={"1px solid #d6dade"}>
                  <Container
                    maxW="400px"
                    padding={"20px"}
                    marginX="auto"
                    px="0px"
                  >
                    <Box padding={"32px 0px 0px"}>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Employment Start Date is required",
                          },
                        }}
                        control={form.control}
                        name="startDate"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              h: "38px",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "date",
                              borderTopRadius: "3px",
                              onChange: (e) => {
                                field.onChange(e.target.value);
                                if (
                                  !!form.getValues(
                                    "probationNoticePeriod.unit"
                                  ) &&
                                  !!form.getValues(
                                    "probationNoticePeriod.value"
                                  )
                                ) {
                                  // let totalDays = 0;
                                  // if (
                                  //   form.getValues(
                                  //     "probationNoticePeriod.unit"
                                  //   ) ===
                                  //   EmployeeEmployment
                                  //     .EmployementNoticePeriodEnum.DAYS
                                  // ) {
                                  //   totalDays = Number(
                                  //     form.getValues(
                                  //       "probationNoticePeriod.value"
                                  //     )
                                  //   );
                                  // } else if (
                                  //   form.getValues(
                                  //     "probationNoticePeriod.unit"
                                  //   ) ===
                                  //   EmployeeEmployment
                                  //     .EmployementNoticePeriodEnum.WEEKS
                                  // ) {
                                  //   totalDays =
                                  //     Number(
                                  //       form.getValues(
                                  //         "probationNoticePeriod.value"
                                  //       )
                                  //     ) * 7;
                                  // } else if (
                                  //   form.getValues(
                                  //     "probationNoticePeriod.unit"
                                  //   ) ===
                                  //   EmployeeEmployment
                                  //     .EmployementNoticePeriodEnum.MONTHS
                                  // ) {
                                  //   totalDays =
                                  //     Number(
                                  //       form.getValues(
                                  //         "probationNoticePeriod.value"
                                  //       )
                                  //     ) * 30;
                                  // } else if (
                                  //   form.getValues(
                                  //     "probationNoticePeriod.unit"
                                  //   ) ===
                                  //   EmployeeEmployment
                                  //     .EmployementNoticePeriodEnum.YEARS
                                  // ) {
                                  //   totalDays =
                                  //     Number(
                                  //       form.getValues(
                                  //         "probationNoticePeriod.value"
                                  //       )
                                  //     ) * 365;
                                  // }
                                  form.setValue(
                                    "confirmationDate",
                                    moment(form.getValues("startDate"))
                                      .add(
                                        form.getValues(
                                          "probationNoticePeriod.value"
                                        ),
                                        form.getValues(
                                          "probationNoticePeriod.unit"
                                        ) as any
                                      )
                                      .format("YYYY-MM-DD")
                                  );
                                }
                              },
                            }}
                            placeholder="dd/mm/yyyy"
                            label="Employment Start Date"
                            labelProps={{
                              fontSize: ".845rem",
                              _light: {
                                color: "customColor.black7",
                              },
                            }}
                          />
                        )}
                      />
                      <Text
                        textAlign={"left"}
                        fontSize={"11px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.200",
                        }}
                        margin={"0px 0px 12px"}
                        padding={"8px 0px 0px"}
                      >
                        The data the employee communes paid work
                      </Text>
                    </Box>
                    <Box padding={"32px 0px 0px"}>
                      <Text
                        textAlign={"left"}
                        fontSize={".845rem"}
                        fontWeight={"600"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        lineHeight={"1.3077"}
                        margin={"0px 0px 8px"}
                      >
                        Probation Period
                      </Text>
                      <Flex w="100%">
                        <Box w="30%">
                          <Controller
                            control={form.control}
                            name="probationNoticePeriod.value"
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                ref={field.ref}
                                validationMessage={error?.message}
                                withValidation
                                input={{
                                  ...field,
                                  isInvalid: error !== undefined,
                                  w: "100%",
                                  h: "38px",
                                  padding: "12px 15px",
                                  _light: {
                                    color: "customColor.black7",
                                  },
                                  fontSize: ".845rem",
                                  variant: "outline",
                                  value: field.value ?? "",
                                  type: "number",
                                  onChange: (e) => {
                                    const inputValue = e.target.value;

                                    // If the input value is empty, set the state to an empty string.
                                    if (inputValue === "") {
                                      field.onChange(null);
                                    } else {
                                      // Otherwise, set the numeric value
                                      field.onChange(Number(inputValue));
                                      if (
                                        !!form.getValues(
                                          "probationNoticePeriod.unit"
                                        ) &&
                                        inputValue !== ""
                                      ) {
                                        // let totalDays = 0;
                                        // if (
                                        //   form.getValues(
                                        //     "probationNoticePeriod.unit"
                                        //   ) ===
                                        //   EmployeeEmployment
                                        //     .EmployementNoticePeriodEnum.DAYS
                                        // ) {
                                        //   totalDays = Number(inputValue);
                                        // } else if (
                                        //   form.getValues(
                                        //     "probationNoticePeriod.unit"
                                        //   ) ===
                                        //   EmployeeEmployment
                                        //     .EmployementNoticePeriodEnum.WEEKS
                                        // ) {
                                        //   totalDays = Number(inputValue) * 7;
                                        // } else if (
                                        //   form.getValues(
                                        //     "probationNoticePeriod.unit"
                                        //   ) ===
                                        //   EmployeeEmployment
                                        //     .EmployementNoticePeriodEnum.MONTHS
                                        // ) {
                                        //   totalDays = Number(inputValue) * 30;
                                        // } else if (
                                        //   form.getValues(
                                        //     "probationNoticePeriod.unit"
                                        //   ) ===
                                        //   EmployeeEmployment
                                        //     .EmployementNoticePeriodEnum.YEARS
                                        // ) {
                                        //   totalDays = Number(inputValue) * 365;
                                        // }
                                        form.setValue(
                                          "confirmationDate",
                                          moment(form.getValues("startDate"))
                                            .add(
                                              inputValue,
                                              form.getValues(
                                                "probationNoticePeriod.unit"
                                              ) as any
                                            )
                                            .format("YYYY-MM-DD")
                                        );
                                      }
                                    }
                                  },
                                  borderTopRadius: "3px",
                                }}
                                // label="Probation Notice Period"
                                // labelProps={{
                                //   fontSize: ".845rem",
                                //   _light: {
                                //     color: "customColor.black7",
                                //   },
                                //   isTruncated: true,
                                //   title: "Probation Notice Period",
                                // }}
                              />
                            )}
                          />
                        </Box>
                        <Box w="70%">
                          <Controller
                            control={form.control}
                            name="probationNoticePeriod.unit"
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                key={field.value}
                                placeholder="Select Unit"
                                options={NoticePeriodUnits.map((op) => ({
                                  value: op.value,
                                  label: op.label,
                                }))}
                                value={
                                  field.value
                                    ? NoticePeriodUnits.find(
                                        (op) => op.value === field.value
                                      )
                                    : null
                                }
                                onChange={(newValue: any) => {
                                  field.onChange(
                                    newValue ? newValue.value : null
                                  );
                                  let unit = newValue?.value;
                                  if (
                                    !!unit &&
                                    !!form.getValues(
                                      "probationNoticePeriod.value"
                                    )
                                  ) {
                                    // let totalDays = 0;
                                    // if (
                                    //   unit ===
                                    //   EmployeeEmployment
                                    //     .EmployementNoticePeriodEnum.DAYS
                                    // ) {
                                    //   totalDays = Number(
                                    //     form.getValues(
                                    //       "probationNoticePeriod.value"
                                    //     )
                                    //   );
                                    // } else if (
                                    //   unit ===
                                    //   EmployeeEmployment
                                    //     .EmployementNoticePeriodEnum.WEEKS
                                    // ) {
                                    //   totalDays =
                                    //     Number(
                                    //       form.getValues(
                                    //         "probationNoticePeriod.value"
                                    //       )
                                    //     ) * 7;
                                    // } else if (
                                    //   unit ===
                                    //   EmployeeEmployment
                                    //     .EmployementNoticePeriodEnum.MONTHS
                                    // ) {
                                    //   totalDays =
                                    //     Number(
                                    //       form.getValues(
                                    //         "probationNoticePeriod.value"
                                    //       )
                                    //     ) * 30;
                                    // } else if (
                                    //   unit ===
                                    //   EmployeeEmployment
                                    //     .EmployementNoticePeriodEnum.YEARS
                                    // ) {
                                    //   totalDays =
                                    //     Number(
                                    //       form.getValues(
                                    //         "probationNoticePeriod.value"
                                    //       )
                                    //     ) * 365;
                                    // }
                                    form.setValue(
                                      "confirmationDate",
                                      moment(form.getValues("startDate"))
                                        .add(
                                          form.getValues(
                                            "probationNoticePeriod.value"
                                          ),
                                          unit
                                        )
                                        .format("YYYY-MM-DD")
                                    );
                                  }
                                }}
                                isClearable={true}
                                getOptionLabel={(option: any) => option.label}
                                getOptionValue={(option: any) => option.value}
                              />
                            )}
                          />
                        </Box>
                      </Flex>
                      <Text
                        textAlign={"left"}
                        fontSize={"11px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.200",
                        }}
                        margin={"0px 0px 12px"}
                        padding={"8px 0px 0px"}
                      >
                        The number of days notice required during probation
                      </Text>
                    </Box>
                    <Box padding={"32px 0px 0px"}>
                      <Controller
                        control={form.control}
                        name="confirmationDate"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              h: "38px",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "date",
                              onChange: (e) => {
                                const inputValue = e.target.value;

                                // If the input value is empty, set the state to an empty string.
                                if (inputValue === "") {
                                  field.onChange(null);
                                } else {
                                  // Otherwise, set the numeric value
                                  field.onChange(inputValue);
                                }
                              },
                              borderTopRadius: "3px",
                            }}
                            placeholder="dd/mm/yyyy"
                            label="Employment Confirmation Date"
                            labelProps={{
                              fontSize: ".845rem",
                              _light: {
                                color: "customColor.black7",
                              },
                            }}
                          />
                        )}
                      />
                      <Text
                        textAlign={"left"}
                        fontSize={"11px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.200",
                        }}
                        margin={"0px 0px 12px"}
                        padding={"8px 0px 0px"}
                      >
                        The date the employee's employment was confirmed
                      </Text>
                    </Box>

                    <Box padding={"32px 0px 0px"}>
                      <Text
                        textAlign={"left"}
                        fontSize={".845rem"}
                        fontWeight={"600"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        lineHeight={"1.3077"}
                        margin={"0px 0px 8px"}
                      >
                        Notice Period
                      </Text>
                      <Flex w="100%">
                        <Box w="30%">
                          <Controller
                            control={form.control}
                            name="noticePeriod.value"
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                ref={field.ref}
                                validationMessage={error?.message}
                                withValidation
                                input={{
                                  ...field,
                                  isInvalid: error !== undefined,
                                  w: "100%",
                                  h: "38px",
                                  padding: "12px 15px",
                                  _light: {
                                    color: "customColor.black7",
                                  },
                                  fontSize: ".845rem",
                                  variant: "outline",
                                  value: field.value ?? "",
                                  type: "number",
                                  onChange: (e) => {
                                    const inputValue = e.target.value;

                                    // If the input value is empty, set the state to an empty string.
                                    if (inputValue === "") {
                                      field.onChange(null);
                                    } else {
                                      // Otherwise, set the numeric value
                                      field.onChange(Number(inputValue));
                                    }
                                  },
                                  borderTopRadius: "3px",
                                }}
                              />
                            )}
                          />
                        </Box>
                        <Box w="70%">
                          <Controller
                            control={form.control}
                            name="noticePeriod.unit"
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                key={field.value}
                                placeholder="Select Unit"
                                options={NoticePeriodUnits.map((op) => ({
                                  value: op.value,
                                  label: op.label,
                                }))}
                                value={
                                  field.value
                                    ? NoticePeriodUnits.find(
                                        (op) => op.value === field.value
                                      )
                                    : null
                                }
                                onChange={(newValue: any) => {
                                  field.onChange(
                                    newValue ? newValue.value : null
                                  );
                                }}
                                isClearable={true}
                                getOptionLabel={(option: any) => option.label}
                                getOptionValue={(option: any) => option.value}
                              />
                            )}
                          />
                        </Box>
                      </Flex>

                      <Text
                        textAlign={"left"}
                        fontSize={"11px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.200",
                        }}
                        margin={"0px 0px 12px"}
                        padding={"8px 0px 0px"}
                      >
                        The number of days notice required
                      </Text>
                    </Box>

                    <Box padding={"16px 0px 0px"}>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Department is required",
                          },
                        }}
                        control={form.control}
                        name="department"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              h: "38px",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "text",

                              borderTopRadius: "3px",
                            }}
                            placeholder=""
                            label="Department"
                            labelProps={{
                              fontSize: ".845rem",
                              _light: {
                                color: "customColor.black7",
                              },
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Box padding={"16px 0px 0px"}>
                      <Controller
                        control={form.control}
                        name="jobTitle"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            validationMessage={error?.message}
                            withValidation
                            ref={field.ref}
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              h: "38px",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "text",

                              borderTopRadius: "3px",
                            }}
                            placeholder="Enter Job Title"
                            label="Job Title"
                            labelProps={{
                              fontSize: ".845rem",

                              _light: {
                                color: "customColor.black7",
                              },
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Stack gap={0}>
                      <Text
                        textAlign={"left"}
                        fontSize=".845rem"
                        paddingTop={"16px"}
                        fontWeight="600"
                        _light={{
                          color: "customColor.black5",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        lineHeight={"1.3077"}
                        margin={"0px 0px 8px"}
                      >
                        Line Manager
                      </Text>
                      <Controller
                        // rules={{
                        //   required: {
                        //     value: true,
                        //     message: "Line Manager is required",
                        //   },
                        // }}
                        control={form.control}
                        name="manager"
                        render={({ field, fieldState }) => (
                          <ReactSelect
                            {...field}
                            key={field.value}
                            placeholder=" Line Manager Type"
                            isInvalid={fieldState.invalid}
                            ErrorMessage="Select Account Type"
                            withValidation
                            options={users?.map((user: any) => ({
                              value: user?.user?.id,
                              label:
                                user?.user?.firstName +
                                " " +
                                user?.user?.lastName,
                            }))}
                            value={users
                              ?.map((user: any) => ({
                                value: user?.user?.id,
                                label:
                                  user?.user?.firstName +
                                  " " +
                                  user?.user?.lastName,
                              }))
                              .filter((option: any) =>
                                field?.value?.includes(option.value)
                              )}
                            onChange={(newValue: any) => {
                              field.onChange(newValue?.value);
                            }}
                            getOptionLabel={(option: any) => option.label}
                            getOptionValue={(option: any) => option.value}
                          />
                        )}
                      />
                    </Stack>

                    <Divider orientation="horizontal" paddingTop={"20px"} />
                    <Box margin={"32px 0px 0px"} padding={"0px 0px 4px"}>
                      <Text
                        textAlign={"left"}
                        fontSize=".845rem"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        margin={"0px 0px 8px"}
                        fontWeight={"600"}
                      >
                        Payroll Calendar
                      </Text>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "PayRoll Calendar is required",
                          },
                        }}
                        control={form.control}
                        name="payrollPayCalenderId"
                        render={({ field, fieldState }) => (
                          <ReactSelect
                            {...field}
                            placeholder="Select"
                            defaultValue={"804: Wages Payable - Payroll"}
                            // marginBottom="30px"
                            // width={"48em"}
                            isInvalid={fieldState.invalid}
                            ErrorMessage="Select PayRoll Calendar type"
                            withValidation
                            options={payOptions}
                            value={{
                              id: field.value,
                              name:
                                payOptions.find(
                                  (payOptions) => payOptions.id === field.value
                                )?.name ?? "",
                            }}
                            onChange={(newValue: any) =>
                              field.onChange(newValue?.id)
                            }
                            getOptionLabel={(option: any) => option.name}
                            getOptionValue={(option: any) => option.id}
                          />
                        )}
                      />
                      <Text
                        textAlign={"left"}
                        fontSize={"11px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.200",
                        }}
                        margin={"0px 0px 12px"}
                        padding={"8px 0px 0px"}
                      >
                        Payroll calendar can be updated in your payroll
                        settings.
                      </Text>
                    </Box>
                    <Box padding={"16px 0px 0px"}>
                      <Stack gap={0} direction={"row"}>
                        <Text
                          textAlign={"left"}
                          fontSize=".845rem"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          margin={"0px 0px 8px"}
                          fontWeight={"600"}
                        >
                          Employee group
                        </Text>
                        <Text
                          textAlign={"left"}
                          fontSize=".845rem"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.dark.200",
                          }}
                          margin={"0px 0px 8px"}
                          fontWeight={"normal"}
                        >
                          {" (optional)"}
                        </Text>
                      </Stack>

                      <Controller
                        control={form.control}
                        name="employeeGroupId"
                        render={({ field, fieldState }) => (
                          <ReactSelect
                            {...field}
                            key={field.value}
                            // {...field}
                            placeholder="Select Employment Group"
                            // marginBottom="30px"
                            // width={"48em"}
                            // isInvalid={fieldState.invalid}
                            ErrorMessage="Select Employment Group "
                            withValidation
                            options={employeeOptions}
                            value={employeeOptions.find(
                              (op) => op.value === field.value
                            )}
                            onChange={(newValue: any) => {
                              field.onChange(newValue?.value);
                            }}
                            getOptionLabel={(option: any) => option.label}
                            getOptionValue={(option: any) => option.value}
                          />
                        )}
                      />
                      <Text
                        textAlign={"left"}
                        fontSize={"11px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.200",
                        }}
                        margin={"0px 0px 12px"}
                        padding={"8px 0px 0px"}
                      >
                        Create employee groups on Tracking categories under
                        Advanced accounting.
                      </Text>
                    </Box>
                    <Box padding={"16px 0px 0px"}>
                      <Stack gap={0} direction={"row"}>
                        <Text
                          textAlign={"left"}
                          fontSize=".845rem"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          margin={"0px 0px 8px"}
                          fontWeight={"600"}
                        >
                          Holiday group
                        </Text>
                        <Text
                          textAlign={"left"}
                          fontSize=".845rem"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.dark.200",
                          }}
                          margin={"0px 0px 8px"}
                          fontWeight={"normal"}
                        >
                          {" (optional)"}
                        </Text>
                      </Stack>
                      {watchRadioValue === "EMPLOYEE" && (
                        <Controller
                          control={form.control}
                          name="holidayGroupId"
                          render={({ field, fieldState }) => (
                            <ReactSelect
                              {...field}
                              placeholder="Select"
                              defaultValue={"804: Wages Payable - Payroll"}
                              // marginBottom="30px"
                              // width={"48em"}
                              // isInvalid={fieldState.invalid}
                              // ErrorMessage="Select PayRoll Calendar type"
                              withValidation
                              options={holidayOptions}
                              value={{
                                id: field.value,
                                groupName:
                                  holidayOptions.find(
                                    (holidayOptions) =>
                                      holidayOptions.id === field.value
                                  )?.groupName ?? "",
                              }}
                              onChange={(newValue: any) =>
                                field.onChange(newValue?.id)
                              }
                              getOptionLabel={(option: any) => option.groupName}
                              getOptionValue={(option: any) => option.id}
                            />
                          )}
                        />
                      )}
                      {watchRadioValue === "CONTRACTOR" && (
                        <Controller
                          // rules={{
                          //   required: {
                          //     value: true,
                          //     message: " select holiday group is required",
                          //   },
                          // }}
                          control={form.control}
                          name="holidayGroupId"
                          render={({ field, fieldState }) => (
                            <ReactSelect
                              {...field}
                              placeholder="Select"
                              defaultValue={"804: Wages Payable - Payroll"}
                              // marginBottom="30px"
                              // width={"48em"}
                              isInvalid={fieldState.invalid}
                              // ErrorMessage="Select PayRoll Calendar type"
                              withValidation
                              isDisabled
                              options={holidayOptions}
                              value={{
                                id: field.value,
                                groupName:
                                  holidayOptions.find(
                                    (holidayOptions) =>
                                      holidayOptions.id === field.value
                                  )?.groupName ?? "",
                              }}
                              onChange={(newValue: any) =>
                                field.onChange(newValue?.id)
                              }
                              getOptionLabel={(option: any) => option.groupName}
                              getOptionValue={(option: any) => option.id}
                            />
                          )}
                        />
                      )}
                      <Text
                        textAlign={"left"}
                        fontSize={"11px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.200",
                        }}
                        margin={"0px 0px 12px"}
                        padding={"8px 0px 0px"}
                      >
                        Create employee groups on Tracking categories under
                        Advanced accounting.
                      </Text>
                      {watchRadioValue === "EMPLOYEE" && (
                        <Controller
                          control={form.control}
                          // rules={{
                          //   required: {
                          //     value: true,
                          //     message:
                          //       "Include holidays in Monthly SalarySlip is required",
                          //   },
                          // }}
                          name="includeHolidaysPayslips"
                          render={({
                            field: { value, ...field },
                            fieldState: { error },
                          }) => (
                            <Checkbox
                              {...field}
                              isInvalid={error !== undefined}
                              isChecked={value}
                            >
                              <Text textAlign={"left"} fontSize={"15.6px"}>
                                Include holidays in Monthly SalarySlip
                              </Text>
                            </Checkbox>
                          )}
                        />
                      )}
                      {watchRadioValue === "CONTRACTOR" && (
                        <Controller
                          control={form.control}
                          name="includeHolidaysPayslips"
                          render={({ field }) => (
                            <Checkbox
                              isChecked={field.value}
                              isDisabled
                              onChange={(e) => {
                                field.onChange(e.target.checked);
                              }}
                            >
                              <Text
                                textAlign={"left"}
                                fontSize=".845rem"
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                _light={{
                                  color: "customColor.black",
                                }}
                              >
                                Include holidays in Monthly SalarySlip
                              </Text>
                            </Checkbox>
                          )}
                        />
                      )}
                    </Box>
                    <Divider
                      orientation="horizontal"
                      paddingTop={"20px"}
                      size={"10px"}
                      variant={"solid"}
                    />
                    <Box margin={"32px 0px 0px"}>
                      <Text
                        textAlign={"left"}
                        fontSize=".845rem"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        margin={"0px 0px 8px"}
                        fontWeight={"600"}
                      >
                        Ordinary earning rate
                      </Text>
                      {watchRadioValue === "CONTRACTOR" && (
                        <Controller
                          // rules={{
                          //   required: {
                          //     value: true,
                          //     message: "  Ordinary Earning is required",
                          //   },
                          // }}
                          control={form.control}
                          name="ratesId"
                          render={({ field, fieldState }) => (
                            <ReactSelect
                              {...field}
                              key={field.value}
                              options={ordinaryEarningRateOptions}
                              withValidation
                              isDisabled
                              isInvalid={fieldState.invalid}
                              ErrorMessage=" Select Ordinary Earning"
                              placeholder="Select..."
                              value={ordinaryEarningRateOptions.find(
                                (op) => op.value === field.value
                              )}
                              onChange={(newValue: any) => {
                                field.onChange(newValue?.value);
                              }}
                              getOptionLabel={(option: any) => option.label}
                              getOptionValue={(option: any) => option.value}
                            />
                          )}
                        />
                      )}
                      {watchRadioValue === "EMPLOYEE" && (
                        <Controller
                          rules={{
                            required: {
                              value: true,
                              message: "  Ordinary Earning is required",
                            },
                          }}
                          control={form.control}
                          name="ratesId"
                          render={({ field, fieldState }) => (
                            <ReactSelect
                              {...field}
                              key={field.value}
                              withValidation
                              isInvalid={fieldState.invalid}
                              ErrorMessage=" Select Ordinary Earning"
                              placeholder="Select..."
                              options={earningAdded.map((earning) => ({
                                value: earning.id,
                                label: earning.name,
                              }))}
                              value={
                                earningAdded.find(
                                  (earning) => earning.id === field.value
                                )
                                  ? {
                                      value: field.value,
                                      label: earningAdded.find(
                                        (earning) => earning.id === field.value
                                      ).name,
                                    }
                                  : null
                              }
                              onChange={(newValue: any) =>
                                field.onChange(newValue?.value)
                              }
                              getOptionLabel={(option: any) => option.label}
                              getOptionValue={(option: any) => option.value}
                            />
                          )}
                        />
                      )}
                    </Box>
                    <Box>
                      <Text
                        textAlign={"left"}
                        fontSize=".845rem"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        margin={"0px 0px 8px"}
                        fontWeight={"600"}
                        mt={"16px"}
                      >
                        Gratuity Pay Type (if applicable)
                      </Text>
                    </Box>
                    <Controller
                      control={form.control}
                      name="gratuityPayItemId"
                      render={({ field, fieldState }) => (
                        <ReactSelect
                          {...field}
                          key={field.value}
                          withValidation
                          isInvalid={fieldState.invalid}
                          placeholder="Select..."
                          isClearable
                          options={retirementAdded?.map((retirement) => ({
                            value: retirement.id,
                            label: retirement.name,
                          }))}
                          value={
                            retirementAdded?.find(
                              (retirement) => retirement.id === field.value
                            )
                              ? {
                                  value: field.value,
                                  label: retirementAdded?.find(
                                    (retirement) =>
                                      retirement.id === field.value
                                  ).name,
                                }
                              : null
                          }
                          onChange={(newValue: any) =>
                            field.onChange(newValue ? newValue.value : null)
                          }
                          getOptionLabel={(option: any) => option.label}
                          getOptionValue={(option: any) => option.value}
                        />
                      )}
                    />
                  </Container>
                </Stack>
              )}
            </Box>
          </Stack>
          <Divider orientation="horizontal" paddingTop={"20px"} />
          <Box display={"flex"} justifyContent="flex-end" px="20px">
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={(e) => {
                form.handleSubmit(
                  (data) => {
                    setIsLoading(true);
                    EmployeeEmployment.NewEmploymentAdded(
                      id,
                      data,
                      (success) => {
                        toast({
                          title: "Employment is updated Successfully",
                          status: "success",
                        });
                        form.reset(success);
                        setRefereshEmployees(true);
                        setIsLoading(false);
                        setSelectedTab(selectedTab + 1);
                      },
                      (error) => {
                        toast({
                          title: "Error",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  },
                  (error) => {
                    toast({
                      title: "Error",
                      description: "Error in form submission",
                      status: "error",
                    });
                    setIsLoading(false);
                  }
                )(e);
              }}
            >
              Save and Next
            </Button>
            <Button
              marginLeft={"8px"}
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              isLoading={isLoading}
              onClick={(e) => {
                form.handleSubmit(
                  (data) => {
                    setIsLoading(true);
                    EmployeeEmployment.NewEmploymentAdded(
                      id,
                      data,
                      (success) => {
                        toast({
                          title: "Employment is updated Successfully",
                          status: "success",
                        });
                        form.reset(success);
                        setIsLoading(false);
                        setRefereshEmployees(true);
                      },
                      (error) => {
                        toast({
                          title: "Error",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  },
                  (error) => {
                    toast({
                      title: "Error",
                      description: "Error in form submission",
                      status: "error",
                    });
                    setIsLoading(false);
                  }
                )(e);
              }}
            >
              Save
            </Button>
          </Box>

          <Stack
            marginX={"auto"}
            borderTop={"1px solid #d6dade"}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white7",
            }}
            marginRight={"auto"}
            marginLeft={"auto"}
            w="100%"
            marginTop={"20px"}
          >
            <HistoryAccordion />
          </Stack>
        </Stack>
      </Skeleton>
    </FormProvider>
  );
}
