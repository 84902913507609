import { axiosInstance } from "./axiosInstance-hris";
declare module EmployeeSeparationApi {
  type SeparationData = {
    id?: string;
    separationDate: string;
    reason: string;
    interviewDate: string;
    resignationDate: string;
    waiveOffNoticePeriod: boolean;
    comments: string;
    // clearances: string[];
    clearances: { clientCorrespondenceId: string }[];
  };
  type FetchSeperationData = {
    id: string;
    createdAt: string;
    updatedAt: string;
    separationDate: string;
    waiveOffNoticePeriod: boolean;
    reason: string;
    interviewDate: string;
    resignationDate: string;
    employee: {
      id: string;
      employeeEmployementDetails: {
        startDate: string;
        confirmationDate: string;
        probationNoticePeriod: {
          unit: string;
          value: number;
        };
        noticePeriod: {
          unit: string;
          value: number;
        };
      };
    };
    comments: string;
    empId: string;
    clearances: [
      {
        id: string;
        createdAt: string;
        updatedAt: string;
        clientCorrespondenceId: string;
        email: string;
        status: string;
        lastEmailSentAt: string;
        deductions: [
          {
            amount: number;
            deduction: string;
          }
        ];
        comments: string;
        separationId: string;
      }
    ];
  };
}
module EmployeeSeparationApi {
  const API_URL = "/employee-separations";

  export function CreateEmployeeSeparation(
    empId: string | undefined,
    data: SeparationData,
    success: (data: SeparationData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/${empId}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function FetchEmployeeSeparation(
    empId: string | undefined,
    success: (data: FetchSeperationData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${empId}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { EmployeeSeparationApi };
