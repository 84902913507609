import { Checkbox, SimpleGrid, Skeleton, Stack, Text } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { InviteUser } from "../../../../../api/InviteUser";

interface ReportPermissionsProps {
  isLoading?: boolean;
}

export default function ReportPermissions({
  isLoading,
}: ReportPermissionsProps) {
  const form = useFormContext<InviteUser.UserInviteDetail>();
  return (
    <Stack paddingLeft={"2.5px"} gap={0} paddingTop={"10px"}>
      <Stack
        padding={0}
        alignItems={"center"}
        direction={"row"}
        gap={0}
      ></Stack>
      <Skeleton height={"100%"} isLoaded={!isLoading} mb="2">
        <SimpleGrid columns={3}>
          <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.reports.employeeDetails"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  isChecked={field.value}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Employee Details
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.reports.employeeContactDetails"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  isChecked={field.value}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Employee Contact Details
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.reports.employeeRemuneration"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  isChecked={field.value}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Employee Remuneration
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.reports.leaveRequests"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  isChecked={field.value}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Leave Requests
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.reports.leaveBalance"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  isChecked={field.value}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Leave Balance
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.reports.payrollActivityDetail"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  isChecked={field.value}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Payroll Activity Detail
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.reports.payrollActivitySummary"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  isChecked={field.value}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Payroll Activity Summary
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.reports.payrollEmployeeSummary"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  isChecked={field.value}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Payroll Employee Summary
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.reports.attendanceReport"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  isChecked={field.value}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Attendance
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.reports.transactionListingDetail"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  isChecked={field.value}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Transaction Listing Detail
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.reports.transactionListingSummary"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  isChecked={field.value}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Transaction Listing Summary
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
        </SimpleGrid>
      </Skeleton>
    </Stack>
  );
}
