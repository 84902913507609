import {
  Box,
  Button,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { MdOutlineReceiptLong } from "react-icons/md";
import { useParams } from "react-router-dom";
import { ExpenseClaimApi } from "../../../../Api/ExpenseClaimApi";
import { ClaimTable } from "./ClaimTable";
import { AddExpenseClaim } from "./Modal/AddExpenseClaim";
import { AddMileageClaim } from "./Modal/AddMileageClaim";

interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export function ExpenseClaim({ setSelectedTab, selectedTab }: TabProps) {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const [claimData, setClaimData] = useState<
    Array<ExpenseClaimApi.FetchExpenseClaim>
  >([]);
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  useEffect(() => {
    ExpenseClaimApi.GetExpenseClaim(
      id!,
      (data) => {
        setClaimData(data);
        setIsLoading(false);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, [lastUpdated]);
  // useEffect(() => {
  //   ExpenseClaimApi.GetAllExpenseClaim(
  //     (data) => {
  //       setClaimData(data);
  //       setIsLoading(false);
  //     },
  //     (err) => {
  //       toast({
  //         title: err,
  //         status: "error",
  //       });
  //       setIsLoading(false);
  //     }
  //   );
  // }, [lastUpdated]);
  const form = useForm<ExpenseClaimApi.AddExpenseClaim>({
    defaultValues: {
      claimType: ExpenseClaimApi.ClaimType.EXPENSE_CLAIM,
      amount: undefined,
      description: undefined,
      spentAt: undefined,
      date: undefined,
      status: undefined,
      bankAccountType: undefined,
      chartAccountId: undefined,
      region: undefined,
      assignTo: undefined,
      label: undefined,
      attachments: [],
      empId: id,
      taxType: undefined,
      taxRateId: undefined,
      taxableAmount: undefined,
      milageToClaim: undefined,
      milageRate: undefined,
      totalAmount: undefined,
      expenseClaimType: undefined,
      payItemReimbursementId: undefined,
    },
  });

  const expenseClaim = useDisclosure();
  const mileageClaim = useDisclosure();
  return (
    <Stack
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      marginBottom={"30px"}
      boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      borderRadius={"5px"}
      border={"1px solid #f6f6f6"}
    >
      <Stack gap={0}>
        <Box justifyContent="center" alignItems="center" p={0}>
          <HStack borderBottom={"1px solid #ddd"} paddingRight={"10px"}>
            <Heading
              textAlign="left"
              fontSize=".9975rem"
              _light={{
                color: "customColor.black7",
              }}
              fontWeight="bold"
              padding="20px"
              as="h2"
              size="md"
              flex={1}
            >
              Expense Claim
            </Heading>
            <Menu>
              <MenuButton
                color={"primary.950"}
                bg={"#FFFFFF"}
                padding={"7.52px 12px"}
                borderRadius="3px"
                fontSize={"15px"}
                border={"1px solid #a6a9b0"}
                fontWeight={"700"}
                textAlign={"center"}
                minH={"35.6px"}
                _hover={{
                  color: "#primary.950",
                  bg: "#f2f3f4",
                }}
              >
                New Expense <ChevronDownIcon />
              </MenuButton>
              <MenuList width={"144px"} padding={"10px 0px"}>
                {[
                  {
                    name: "Expense claim",
                    sideIcon: <MdOutlineReceiptLong />,
                    onClick: () => {
                      form.reset({
                        spentAt: undefined,
                        amount: undefined,

                        description: undefined,
                        claimType: ExpenseClaimApi.ClaimType.EXPENSE_CLAIM,
                        empId: id,
                        bankAccountType: undefined,
                        date: undefined,
                        status: undefined,
                        attachments: [],
                        chartAccountId: undefined,
                        region: undefined,
                        assignTo: undefined,
                        label: undefined,
                        totalAmount: undefined,
                        taxRateId: undefined,
                        taxType: undefined,
                      });
                      // setSelectedLeaveId(undefined);
                      expenseClaim.onOpen();
                    },
                  },
                  {
                    name: "Mileage Claim",
                    sideIcon: <BsFillFuelPumpFill />,
                    onClick: () => {
                      form.reset({
                        claimType: ExpenseClaimApi.ClaimType.MILAGE_CLAIM,

                        milageToClaim: undefined,
                        empId: id,
                        taxType: undefined,
                        milageRate: undefined,
                        attachments: [],
                        description: undefined,
                        date: undefined,
                        status: undefined,
                        chartAccountId: undefined,
                        region: undefined,
                        assignTo: undefined,
                        label: undefined,
                        totalAmount: undefined,
                      });
                      // setSelectedLeaveId(undefined);
                      mileageClaim.onOpen();
                    },
                  },
                ].map((item) => (
                  <MenuItem
                    padding={"0px 12px"}
                    onClick={() => {
                      // item.onClick();
                    }}
                  >
                    <Button
                      padding={"8px"}
                      leftIcon={item.sideIcon}
                      variant={"ghost"}
                      fontSize={"0.9375rem"}
                      lineHeight={"1.5rem"}
                      fontWeight={"normal"}
                      _light={{
                        color: "black",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      onClick={item.onClick}
                      _hover={{
                        bg: "transparent",
                      }}
                    >
                      {item.name}
                    </Button>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </HStack>
        </Box>
        <ClaimTable claimData={claimData} setLastUpdated={setLastUpdated} />

        <Stack alignItems={"flex-end"} margin={"20px 20px 20px 0px"}>
          <Button
            colorScheme="green"
            border={"1px solid green"}
            textColor={"customColor.white"}
            borderRadius={"3px"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            padding={"12px 16px"}
            marginRight={"5px"}
            fontWeight={600}
            onClick={() => setSelectedTab(selectedTab + 1)}
          >
            Next
          </Button>
        </Stack>
        <FormProvider {...form}>
          <AddExpenseClaim
            setLastUpdated={setLastUpdated}
            isOpen={expenseClaim.isOpen}
            onClose={expenseClaim.onClose}
          />

          <AddMileageClaim
            setLastUpdated={setLastUpdated}
            isOpen={mileageClaim.isOpen}
            onClose={mileageClaim.onClose}
          />
        </FormProvider>
      </Stack>
    </Stack>
  );
}
