import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { BusinessTaxRate } from "../../../Api/BusinessTaxRate";
import { ChartOfAccounts } from "../../../Api/ChartOfAccounts";
// import { CustomSelect } from "../../../Common/CustomSelect";.
interface AccmodalProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (timestamp: number) => void;
  taxRateData: BusinessTaxRate.FetchTaxRate[];
}
export default function Accmodal({
  isOpen,
  onClose,
  setLastUpdated,
  taxRateData,
}: AccmodalProps) {
  const toast = useToast();
  const FormChartOfAccounts = useFormContext<
    ChartOfAccounts.AddNewAccount & { id: string }
  >();

  const accountTypeOption = [
    {
      label: ChartOfAccounts.AccountCategory.ASSETS,
      options: [
        {
          label: "Current Asset",
          value: ChartOfAccounts.AccountType.CURRENT_ASSET,
        },
        {
          label: "Fixed Asset",
          value: ChartOfAccounts.AccountType.FIXED_ASSET,
        },
        {
          label: "Inventory",
          value: ChartOfAccounts.AccountType.INVENTORY,
        },
        {
          label: "Non Current Asset",
          value: ChartOfAccounts.AccountType.NON_CURRENT_ASSET,
        },
        {
          label: "Prepayment",
          value: ChartOfAccounts.AccountType.PREPAYMENT,
        },
      ],
    },
    {
      label: ChartOfAccounts.AccountCategory.EQUITY,
      options: [
        {
          label: "Equity",
          value: ChartOfAccounts.AccountType.EQUITY,
        },
      ],
    },
    {
      label: ChartOfAccounts.AccountCategory.EXPENSES,
      options: [
        {
          label: "Depreciation",
          value: ChartOfAccounts.AccountType.DEPRECIATION,
        },
        {
          label: "Direct Costs",
          value: ChartOfAccounts.AccountType.DIRECT_COSTS,
        },
        {
          label: "Expense",
          value: ChartOfAccounts.AccountType.EXPENSE,
        },
        {
          label: "Overhead",
          value: ChartOfAccounts.AccountType.OVERHEAD,
        },
      ],
    },
    {
      label: ChartOfAccounts.AccountCategory.LIABILITY,

      options: [
        {
          label: "Current Liability",
          value: ChartOfAccounts.AccountType.CURRENT_LIABILITY,
        },
        {
          label: "Liability",
          value: ChartOfAccounts.AccountType.LIABILITY,
        },
        {
          label: "Non Current Liability",
          value: ChartOfAccounts.AccountType.NON_CURRENT_LIABILITY,
        },
      ],
    },
    {
      label: ChartOfAccounts.AccountCategory.REVENUE,
      options: [
        {
          label: "Other Income",
          value: ChartOfAccounts.AccountType.OTHER_INCOME,
        },
        {
          label: "Revenue",
          value: ChartOfAccounts.AccountType.REVENUE,
        },
        {
          label: "Sales",
          value: ChartOfAccounts.AccountType.SALES,
        },
      ],
    },
  ];
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            padding={"12px 15px"}
            fontSize=".9975rem"
            lineHeight={"1.75rem"}
            fontWeight={"bold"}
            _light={{
              color: "customColor.black7",
            }}
            borderBottom={"1px solid #ddd"}
          >
            {FormChartOfAccounts.getValues("id")
              ? "Edit Account"
              : "Add New Account"}
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody borderBottom={"1px solid #ddd"}>
            <HStack spacing={3} alignItems={"flex-start"}>
              <Stack gap={0} flex={1}>
                <Stack spacing={2}>
                  <Text
                    mb={0}
                    fontSize="11px"
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                  >
                    Code
                  </Text>
                  <Text
                    mt={0}
                    fontSize="11px"
                    _light={{
                      color: "customColor.gray.64748B",
                    }}
                    marginTop={"-7px"}
                  >
                    A unique code/number for this account (limited to 10
                    characters)
                  </Text>
                  {/* <Input
                      p={0}
                      size="xs"
                      w="80px"
                      borderRadius="0px"
                     _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
                      shadow="inner"
                      type="number"
                    /> */}
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Code is required",
                      },
                    }}
                    control={FormChartOfAccounts.control}
                    name="code"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          placeholder: "",
                          type: "text", // Keeping it as "text"

                          h: "18.6px",
                          w: "86.6px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          variant: "outline",

                          borderTopRadius: "3px",
                          bgColor: "#fff",
                        }}
                      />
                    )}
                  />
                </Stack>
                <Stack spacing={2}>
                  <Text
                    mb={0}
                    mt="10px"
                    fontSize="11px"
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                  >
                    Name
                  </Text>
                  <Text
                    mt={0}
                    fontSize="11px"
                    _light={{
                      color: "customColor.gray.64748B",
                    }}
                    marginTop={"-7px"}
                  >
                    A short title for this account (limited to 150 characters)
                  </Text>

                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Name is required",
                      },
                    }}
                    control={FormChartOfAccounts.control}
                    name="name"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          placeholder: "",

                          h: "18.6px",

                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "text",
                          bgColor: "#fff",

                          borderTopRadius: "3px",
                        }}
                      />
                    )}
                  />
                </Stack>

                <Stack spacing={2}>
                  <Text
                    mb={0}
                    display="flex"
                    mt="10px"
                    flexDirection="row"
                    fontSize="11px"
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                  >
                    Description<Text fontWeight="light">(Optional)</Text>
                  </Text>
                  <Text
                    mt={0}
                    fontSize="11px"
                    _light={{
                      color: "customColor.gray.64748B",
                    }}
                    marginTop={"-7px"}
                  >
                    A description of how this account should be used
                  </Text>

                  <Controller
                    control={FormChartOfAccounts.control}
                    name="description"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,

                          placeholder: "",
                          type: "text",
                          h: "28.6px",

                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          variant: "outline",

                          bgColor: "#fff",
                        }}
                      />
                    )}
                  />
                </Stack>
              </Stack>
              <Stack
                gap={0}
                flex={1}
                borderLeft={"1px solid #ddd"}
                paddingLeft={"15px"}
              >
                <Stack spacing={2}>
                  <Text
                    mb={0}
                    fontSize="11px"
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                  >
                    Account Type
                  </Text>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Type is required",
                      },
                    }}
                    control={FormChartOfAccounts.control}
                    name="accountType"
                    render={({ field, fieldState }) => (
                      <ReactSelect
                        {...field}
                        placeholder="Select Type"
                        isInvalid={fieldState.invalid}
                        ErrorMessage="Select Type"
                        withValidation
                        options={accountTypeOption}
                        value={accountTypeOption
                          .flatMap((option) => option.options)
                          .find((opt) => opt.value === field.value)}
                        onChange={(newValue: any) => {
                          field.onChange(newValue?.value);

                          const selectedCategory = accountTypeOption.find(
                            (category) =>
                              category.options.some(
                                (option) => option.value === newValue?.value
                              )
                          );

                          if (selectedCategory) {
                            FormChartOfAccounts.setValue(
                              "accountCategory",
                              selectedCategory?.label
                            );
                          }
                        }}
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                      />
                    )}
                  />{" "}
                </Stack>
                <Stack spacing={2} marginTop={"10px"}>
                  <Text
                    mb={0}
                    fontSize="11px"
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                  >
                    Tax
                  </Text>
                  <Text
                    mt={0}
                    fontSize="11px"
                    _light={{
                      color: "customColor.gray.64748B",
                    }}
                    marginTop={"-7px"}
                  >
                    The default tax setting for this account
                  </Text>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Tax is required",
                      },
                    }}
                    control={FormChartOfAccounts.control}
                    name="businesTaxRateId"
                    render={({ field, fieldState }) => (
                      <ReactSelect
                        {...field}
                        placeholder="Select Tax type"
                        isInvalid={fieldState.invalid}
                        ErrorMessage="Select Tax type"
                        withValidation
                        options={taxRateData}
                        value={
                          field.value
                            ? {
                                id: field.value,
                                name:
                                  taxRateData.find(
                                    (taxRateData) =>
                                      taxRateData.id === field.value
                                  )?.name ?? "",
                              }
                            : undefined
                        }
                        onChange={(val: any) => {
                          field.onChange(val?.id);
                        }}
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.id}
                      />
                    )}
                  />
                </Stack>
                <VStack spacing={2} mt="10px" w="200px">
                  <Box>
                    <Controller
                      control={FormChartOfAccounts.control}
                      name="showDashboardWatchlist"
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: "Agree with T&Cs and privacy policy",
                      //   },
                      // }}
                      render={({
                        field: { value, ...field },
                        fieldState: { error },
                      }) => (
                        <Checkbox
                          {...field}
                          isInvalid={error !== undefined}
                          isChecked={value}
                          mr={5}
                          // onChange={() => handleCheckboxChange("option1")}
                        >
                          <Text
                            fontSize="11px"
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.pearlWhite",
                            }}
                          >
                            Show on Dashboard Watchlist
                          </Text>
                        </Checkbox>
                      )}
                    />
                    <Controller
                      control={FormChartOfAccounts.control}
                      name="showExpenseClaims"
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: "Agree with T&Cs and privacy policy",
                      //   },
                      // }}
                      render={({
                        field: { value, ...field },
                        fieldState: { error },
                      }) => (
                        <Checkbox
                          {...field}
                          isInvalid={error !== undefined}
                          isChecked={value}
                          mr={5}
                          // onChange={() => handleCheckboxChange("option1")}
                        >
                          <Text
                            fontSize="11px"
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.pearlWhite",
                            }}
                          >
                            Show in Expense Claims
                          </Text>
                        </Checkbox>
                      )}
                    />
                    <Controller
                      control={FormChartOfAccounts.control}
                      name="enablePayments"
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: "Agree with T&Cs and privacy policy",
                      //   },
                      // }}
                      render={({
                        field: { value, ...field },
                        fieldState: { error },
                      }) => (
                        <Checkbox
                          {...field}
                          isInvalid={error !== undefined}
                          isChecked={value}
                          mr={5}
                          // onChange={() => handleCheckboxChange("option1")}
                        >
                          <Text
                            fontSize="11px"
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.pearlWhite",
                            }}
                          >
                            Enable payments to this account
                          </Text>
                        </Checkbox>
                      )}
                    />
                  </Box>
                </VStack>
              </Stack>
            </HStack>

            {/* <Box w="50%">
          
                <Image
                  w="348px"
                  h="412px"
                  src="https://go.xero.com/assets/dd7ee16b76a12cc84b36cc069aeb706f.png"
                />
                <Text
                  mt="20px"
                  ml="15px"
                  fontSize="11px"
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  You can also modify where accounts appear in your reports
                  using <Link>Customised Report Layouts</Link>
                </Text>
              </Box> */}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup alignItems={"flex-end"}>
              <Button
                variant={"solid"}
                // bg="#77c123"
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                isLoading={isLoading}
                onClick={(e) => {
                  FormChartOfAccounts.handleSubmit(
                    (data) => {
                      setIsLoading(true);
                      if (data.id) {
                        ChartOfAccounts.UpdateChartOfAccounts(
                          data.id,
                          data,
                          (success) => {
                            toast({
                              title: "Account Updated",
                              status: "success",
                            });
                            setIsLoading(false);
                            setLastUpdated(Date.now());
                            onClose();
                          },
                          (error) => {
                            toast({
                              title: "Update Account Failed",
                              description: error,
                              status: "error",
                            });
                            setIsLoading(false);
                          }
                        );
                      } else {
                        ChartOfAccounts.NewAccountAdded(
                          data,
                          (success) => {
                            toast({
                              title: "Account Added",
                              status: "success",
                            });
                            setIsLoading(false);
                            setLastUpdated(Date.now());
                            onClose();
                          },
                          (error) => {
                            toast({
                              title: "Add Account Failed",
                              description: error,
                              status: "error",
                            });
                            setIsLoading(false);
                          }
                        );
                      }
                    },
                    (error) => {
                      toast({
                        title: "Add Account Failed",

                        status: "error",
                      });
                      setIsLoading(false);
                    }
                  )(e);
                }}
              >
                {FormChartOfAccounts.getValues("id") ? "Update" : "Save"}
              </Button>
              <Button
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                color={"#ea4d4d"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
