import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Input,
  Stack,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { InventoryCategoryModule } from "../../../../../../modules/Audit/InventoryCategories";
interface UploadInventoryCategoryDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdate: (timestamp: number) => void;
}
export default function UploadInventoryCategoryDrawer({
  isOpen,
  onClose,
  setLastUpdate,
}: UploadInventoryCategoryDrawerProps) {
  const toast = useToast();
  const [isLoading, setLoading] = useState<boolean>(false);
  const form = useForm<InventoryCategoryModule.AddInventoryCategories>({});
  const [iconVisible, setIconVisible] = useState(false);
  const ItemssArr = useFieldArray({
    control: form.control,
    name: "inventoryCategory",
    keyName: "categoryId",
  });
  useEffect(() => {
    form.setValue("inventoryCategory", []);
  }, [isOpen]);
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      size={{ xs: "full", md: "md" }}
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent
        backgroundColor={"backgrounds.header"}
        overflowY="auto"
        minH="100vh"
      >
        <DrawerHeader
          padding={"16px 0px 0px 0px"}
          borderBottom={"2px solid"}
          borderColor={"borders.accent"}
        >
          <VStack w="100%" spacing={0} h="50px">
            <Box w="100%" padding="0px 24px 0px 24px">
              Add inventory categories
            </Box>
            {/* <Box w="100%" padding="20px">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<AiOutlineSearch size={'18px'} color="gray.300" />}
                />
                <Input
                  type="search"
                  placeholder="Search"
                  border="1px"
                  fontSize="16px"
                  fontWeight={400}
                  color="textcolors.primary"
                  borderStyle={'solid'}
                  borderColor={'#abb5c4'}
                  padding={'8px 16px 8px 38px'}
                  _focusVisible={{
                    boxShadow: '0px',
                    borderColor: 'primary.500',
                  }}
                  onChange={e => setSearchText(e.target.value)}
                />
              </InputGroup>
            </Box> */}
          </VStack>
        </DrawerHeader>

        <DrawerBody padding="0px">
          {ItemssArr.fields.map((cat, i) => (
            <HStack
              key={cat.categoryId}
              w="100%"
              spacing={0}
              h="47px"
              padding="5px"
              backgroundColor="white"
              borderBottom={"1px solid"}
              borderColor={"borders.accent"}
              align="center"
            >
              <HStack flex={"auto"}>
                <Controller
                  control={form.control}
                  name={`inventoryCategory.${i}.name`}
                  rules={{
                    required: {
                      value: true,
                      message: "Category name is required",
                    },
                    onBlur: () => {
                      setIconVisible(false);
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      isInvalid={error !== undefined}
                      h="38px"
                      padding="5px"
                      w="100%"
                      border="0px"
                      onFocus={() => setIconVisible(true)}
                      placeholder="Enter item"
                      _focusVisible={{
                        boxShadow: "0px",
                        border: "1px solid",
                        borderColor: "primary.500",
                      }}
                      {...field}
                    />
                  )}
                />
                <Box>
                  <IconButton
                    backgroundColor="transparent"
                    margin="8px 8px 0px 8px"
                    borderRadius={"50%"}
                    border={"none"}
                    size="22.5px"
                    _hover={{ backgroundColor: "#bfc6d4" }}
                    aria-label="delete"
                    icon={<MdDelete color="#545f70" size="22.5px" />}
                    onClick={() => {
                      ItemssArr.remove(i);
                    }}
                  />
                </Box>
              </HStack>
            </HStack>
          ))}
        </DrawerBody>

        <DrawerFooter
          padding="0px"
          backgroundColor={"backgrounds.header"}
          flexDirection="column"
        >
          <Stack spacing={0} w="100%" direction="column">
            <Stack
              w="100%"
              h="58.6px"
              padding="8px"
              backgroundColor="white"
              border="1px solid"
              borderColor={"borders.accent"}
            >
              <Button
                w="150px"
                h="41px"
                border="0px"
                borderRadius="5px"
                leftIcon={<FiPlus />}
                variant="outline"
                onClick={() => {
                  ItemssArr.append({
                    name: "",
                  });
                }}
              >
                Add Category
              </Button>
            </Stack>

            <Box flexDirection={{ xs: "column", md: "row" }} padding="17.6px">
              <Button
                isLoading={isLoading}
                h="40px"
                w="192px"
                borderRadius="5px"
                fontSize="14px"
                fontWeight={500}
                marginRight="16px"
                padding={"12px 20px"}
                onClick={form.handleSubmit((data) => {
                  setLoading(true);
                  data.inventoryCategory.length > 0
                    ? InventoryCategoryModule.UploadInventoryCategory(
                        data.inventoryCategory,
                        () => {
                          toast({
                            title: "Success",
                            description: "Categories uploaded successfully",
                            status: "success",
                          });
                          setLoading(false);
                          form.reset({});
                          setLastUpdate(Date.now());
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Error",
                            description: error,
                            status: "error",
                          });
                        }
                      )
                    : toast({
                        title: "Error",
                        description: "Atleast one category is required",
                        status: "error",
                      });
                })}
              >
                Save and apply
              </Button>
              <Button
                h="40px"
                borderRadius="5px"
                fontSize="14px"
                fontWeight={500}
                variant="outline"
                padding={"12px 20px"}
                onClick={() => {
                  form.setValue("inventoryCategory", []);
                  onClose();
                }}
              >
                Cancel
              </Button>
            </Box>
          </Stack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
