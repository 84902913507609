import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Heading,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

export function PaymentSummary() {
  let location = useLocation();
  const navigate = useNavigate();

  const datePaid = location.state.datePaid;

  const amountPaid = location.state.amountPaid;
  const reference = location.state.reference;
  const accountName = location.state.accountName;
  const invoiceUniqueId = location.state.invoiceUniqueId;
  const totalAmount = location.state.totalAmount;
  const issueDate = location.state.issueDate;
  const contactName = location.state.contactName;

  return (
    <Stack
      gap={0}
      h="calc(100vh - 70px)"
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
    >
      <Stack
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        justifyContent={"center"}
        border={"1px solid #e5e7eb"}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        gap={0}
        padding={"0px 0px 0px 20px"}
        minH={"60px"}
      >
        <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
          <BreadcrumbItem>
            <BreadcrumbLink
              href="ChartOfAccount"
              color={"#3454d1"}
              fontWeight={"normal"}
              fontSize={"11px"}
              marginTop={"5px"}
            >
              Chart of Accounts
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink
              color={"#3454d1"}
              fontWeight={"normal"}
              fontSize={"11px"}
              marginTop={"5px"}
            >
              {accountName}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Heading
          fontSize="24px"
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.white",
          }}
          fontWeight="700"
        >
          <Text>Transaction: Payment</Text>
        </Heading>
      </Stack>
      <Container maxW={"980px"} padding={"30px 20px"}>
        <Stack
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          borderColor="#ddd"
          borderWidth="1px"
          borderRadius={"3px 3px 0 0"}
          borderStyle={"solid"}
          gap={0}
        >
          <Stack
            bg={"#fff"}
            h={"52px"}
            padding={"2px 28.2px"}
            borderRadius={"3px 3px 0 0"}
            borderBottom={"1px solid #ddd"}
          />
          <Stack padding={"30px 0px 0px 15px"} direction={"row"}>
            <Stack gap={0} marginRight={"8px"}>
              <Text
                marginBottom={"8px"}
                fontSize={"12px"}
                color={"customColor.black7"}
                fontWeight={"bold"}
              >
                Payment Date
              </Text>
              <Text fontSize={"12px"} color={"customColor.black7"}>
                {datePaid && datePaid !== undefined
                  ? moment(datePaid).format("DD MMM YYYY")
                  : ""}
              </Text>
            </Stack>

            <Stack gap={0}>
              <Text
                marginBottom={"8px"}
                fontSize={"12px"}
                color={"customColor.black7"}
                fontWeight={"bold"}
              >
                Reference
              </Text>
              <Text fontSize={"12px"} color={"customColor.black7"}>
                {reference && reference !== undefined ? reference : "N.A"}
              </Text>
            </Stack>
          </Stack>
          <Stack
            borderTop={"1px solid #ddd"}
            margin={"15px 15px 10px"}
            padding={"10px 0px"}
            h={"34px"}
          />
          <Box
            overflowX="auto"
            p="0px"
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
            borderRight={"1px solid #ddd"}
            borderLeft={"1px solid #ddd"}
            borderBottom={"1px solid #ddd"}
            margin={"0px 16px"}
            marginBottom={"20px"}
          >
            <Table variant="simple" w="100%" overflowX="auto">
              <Thead h={"36.8px"}>
                <Tr
                  borderTopWidth={"1px"}
                  borderTopColor="borders.tableBorder"
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                >
                  {[
                    {
                      name: "Contact",
                      value: "20%",
                      paddingValue: "8px 26px 8px 30px",
                    },
                    {
                      name: "Inv #",
                      value: "25%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Date",
                      value: "15%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Due Date",
                      value: "15%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Total",
                      value: "15%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Payment Amount",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                  ].map((item, i) => {
                    return (
                      <Th
                        key={i}
                        padding="15px"
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        w={item.value}
                        fontWeight={700}
                        fontSize="12px"
                        textTransform="none"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        cursor="pointer"
                      >
                        <Text>{item.name}</Text>
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody padding={0}>
                <Tr
                  _hover={{
                    bgColor: "#f2f3f8",
                    cursor: "pointer",
                  }}
                >
                  <Td
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                    w={"100px"}
                    isTruncated
                  >
                    <Text h={"13.5px"}>
                      {contactName ? contactName : "N.A"}
                    </Text>
                  </Td>
                  <Td
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <Text h={"13.5px"}>
                      {" "}
                      <Text
                        h={"13.5px"}
                        color={"#3454d1"}
                        fontWeight={"600"}
                        _hover={{
                          textDecoration: "underline",
                        }}
                      >
                        {invoiceUniqueId ? invoiceUniqueId : "N.A"}
                      </Text>
                    </Text>
                  </Td>
                  <Td
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <Text h={"13.5px"}>
                      {issueDate
                        ? moment(issueDate).format("DD MMM YYYY")
                        : "N.A"}
                    </Text>
                  </Td>
                  <Td
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <Text h={"13.5px"}>
                      {datePaid
                        ? moment(datePaid).format("DD MMM YYYY")
                        : "N.A"}
                    </Text>
                  </Td>
                  <Td
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <Text h={"13.5px"}>
                      {totalAmount ? totalAmount : "N.A"}
                    </Text>
                  </Td>
                  <Td
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <Text h={"13.5px"}>{amountPaid}</Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
          <Stack
            bg={"#fff"}
            h={"52px"}
            padding={"2px 28.2px"}
            borderRadius={"3px 3px 0 0"}
            borderTop={"1px solid #ddd"}
            alignItems={"flex-end"}
            justifyContent={"center"}
          >
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              color={"#ea4d4d"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => {
                navigate(-1);
                // navigate("/app/payroll/PayrollEmployee");
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
}
