import {useFormContext} from 'react-hook-form';
import {useAuditQuestionContainer} from './AuditQuestionContainer';
import {QuestionTitle} from './QuestionTitle';

export default function Instruction() {
  const template = useFormContext();
  const {question} = useAuditQuestionContainer();

  return <QuestionTitle question={question} />;
}
