import {AbsoluteCenter, Spinner, useToast} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {Template, Widget} from '../../../modules/Audit';
import {DrawerProvider} from './drawersContext';
import {GlobalResponseDrawerProvider} from './globalResponsesDrawer';
import {MultiChoiceProvider} from './multiChoiceContext';

const FORM_INIT_STATE: Template.Template = {
  template: {
    businessId: 0,
    createdBy: 0,
    description: '',
    image: '',
    published: false,
    title: '',
    totalScore: 0,
  },
  templateItems: [Template.PAGE_INIT],
  deletedItems: [],
  templateMedia: [],
};

interface TemplateBuilderContextProps {
  clickedAuditItem?: string;
  setClickedAuditItem: React.Dispatch<React.SetStateAction<string | undefined>>;
  widgets: Array<Widget.Widget>;
  optionsLastUpdated: number;
  refreshUpdatedOptions: Function;
}
const TemplateBuilderContext = React.createContext<TemplateBuilderContextProps>(
  {
    setClickedAuditItem: () => {},
    widgets: [],
    optionsLastUpdated: Date.now(),
    refreshUpdatedOptions: () => {},
  },
);

interface TemplateBuilderProviderProps {
  children: React.ReactNode;
}

export function TemplateBuilderProvider({
  children,
}: TemplateBuilderProviderProps) {
  const urlParams = useParams();
  const templateForm = useForm<Template.Template>({
    defaultValues: FORM_INIT_STATE,
  });

  const [isLoading, setIsLoading] = useState<boolean>(
    urlParams?.id ? true : false,
  );

  const [clickedAuditItem, setClickedAuditItem] = useState<string>();

  const [widgets, setWidgets] = useState<Array<Widget.Widget>>([]);
  const toast = useToast({position: 'top'});

  const [optionsLastUpdated, setOptionLastUpdated] = useState<number>(
    Date.now(),
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);

  function refreshUpdatedOptions() {
    setOptionLastUpdated(Date.now());
  }

  useEffect(() => {
    Widget.Fetch(
      widgets => {
        setWidgets(widgets);
        if (urlParams?.id) {
          Template.GetByIdToEdit(
            Number(urlParams?.id),
            template => {
              templateForm.reset(template);
              setIsLoading(false);
            },
            e => {
              toast({
                title: 'Error',
                description: e,
                status: 'error',
              });
            },
          );
        }
      },
      error => {
        toast({
          title: 'Error',
          description: error,
          status: 'error',
        });
      },
    );
  }, []);

  if (isLoading) {
    return (
      <AbsoluteCenter>
        <Spinner color="secondary.900" size="xl" />
      </AbsoluteCenter>
    );
  }
  return (
    <FormProvider {...templateForm}>
      <TemplateBuilderContext.Provider
        value={{
          clickedAuditItem,
          setClickedAuditItem,
          widgets,
          optionsLastUpdated,
          refreshUpdatedOptions,
        }}>
        <MultiChoiceProvider>
          <GlobalResponseDrawerProvider>
            <DrawerProvider>
              <ScoreContextProvider>{children}</ScoreContextProvider>
            </DrawerProvider>
          </GlobalResponseDrawerProvider>
        </MultiChoiceProvider>
      </TemplateBuilderContext.Provider>
    </FormProvider>
  );
}

export function useTemplateBuilder() {
  return React.useContext<TemplateBuilderContextProps>(TemplateBuilderContext);
}

interface ScoreContextProps {
  showScore: boolean;
  setShowScore: React.Dispatch<React.SetStateAction<boolean>>;
}
export const ScoreContext = React.createContext<ScoreContextProps>({
  showScore: false,
  setShowScore: () => {},
});
export function ScoreContextProvider({children}: any) {
  const [showScore, setShowScore] = useState<boolean>(false);
  return (
    <ScoreContext.Provider value={{showScore, setShowScore}}>
      {children}
    </ScoreContext.Provider>
  );
}
export function useScoreContext() {
  return React.useContext<ScoreContextProps>(ScoreContext);
}
