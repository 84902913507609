import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { FiImage, FiMoreVertical } from "react-icons/fi";
import { MdDelete, MdEdit } from "react-icons/md";
import { InventoryModule } from "../../../../../../modules/Audit/Inventory";
import { InventoryCategoryModule } from "../../../../../../modules/Audit/InventoryCategories";
import ViewImageModal from "../../../report-components/Previews/WebPreview/Modals/ViewImageModal";
import BulkDeleteModal from "./BulkDeleteModal";
import UpdateInventoryModal from "./UpdateInventoryModal";

interface InventoryTableProps {
  inventoryCategories:
    | Array<InventoryCategoryModule.FetchInventoryCategoryResponse>
    | [];
  inventoryData: Array<InventoryModule.FetchInventoryTypeResponse>;
  setLastUpdate: (timestamp: number) => void;
}

export default function InventoryTable({
  inventoryCategories,
  inventoryData,
  setLastUpdate,
}: InventoryTableProps) {
  const deleteModal = useDisclosure();
  const UpdateModal = useDisclosure();
  const bulkDeleteModal = useDisclosure();
  const [selectedItem, setSelectedItem] =
    useState<InventoryModule.InventoryData>();
  const [selectId, setSelectedId] = useState<number | undefined>();
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
  const toast = useToast();
  const handleCheckboxClick = (itemId: number) => {
    if (selectedItemIds.includes(itemId)) {
      setSelectedItemIds((prevIds) => prevIds.filter((id) => id !== itemId));
    } else {
      setSelectedItemIds((prevIds) => [...prevIds, itemId]);
      // onOpen();
    }
  };
  const ImageModal = useDisclosure();
  const [selectedImage, setSelectedImage] = useState<any>();
  const handleImageClick = (imageUrl: any) => {
    setSelectedImage(imageUrl);
    ImageModal.onOpen();
  };
  return (
    <TableContainer
      border="1px solid"
      borderRadius="5px"
      borderColor="borders.accent"
    >
      <Table>
        <Thead bg="backgrounds.primary">
          <Tr>
            <Th w="2%" p="16px" borderColor="borders.accent">
              <Checkbox
                w="21px"
                h="21px"
                borderColor="grey"
                isChecked={
                  inventoryData.length > 0 &&
                  inventoryData.length === selectedItemIds.length
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    const allItemsIds = inventoryData.map((item) => item.id);
                    setSelectedItemIds(allItemsIds);
                  } else {
                    setSelectedItemIds([]);
                  }
                }}
              />
            </Th>

            <Th
              w="33%"
              p="16px"
              fontSize="13px"
              fontWeight={400}
              lineHeight="1rem"
              color="textcolors.primary"
              textDecoration="underline"
              borderColor="borders.accent"
            >
              Item
            </Th>
            <Th
              w="33%"
              p="16px"
              fontSize="13px"
              fontWeight={400}
              lineHeight="1rem"
              color="textcolors.primary"
              borderColor="borders.accent"
            >
              Type
            </Th>
            <Th w="1%" p={0} lineHeight="1rem" borderColor="borders.accent">
              {selectedItemIds.length > 0 && (
                <Icon
                  as={MdDelete}
                  boxSize={22}
                  color="#545f70"
                  onClick={() => {
                    bulkDeleteModal.onOpen();
                  }}
                />
              )}
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {inventoryData?.map((item, i) => {
            return (
              <Tr key={i}>
                <Td
                  w="2%"
                  py="16px"
                  px="16px"
                  borderColor="borders.accent"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Checkbox
                    w="21px"
                    h="21px"
                    borderColor="grey"
                    isChecked={selectedItemIds.includes(item.id)}
                    onChange={() => {
                      handleCheckboxClick(item.id);
                    }}
                  />
                </Td>
                <Td
                  pl="16px"
                  pr="0px"
                  py="16px"
                  fontSize="14px"
                  color="textcolors.secondary"
                  borderColor="borders.accent"
                >
                  <HStack>
                    {item?.mediaUrl ? (
                      <Image
                        w="40px"
                        h="40px"
                        borderRadius="50%"
                        src={item.mediaUrl}
                        alt="Media Image"
                        onClick={() => {
                          handleImageClick(item.mediaUrl);
                        }}
                      />
                    ) : (
                      <Box
                        w="40px"
                        h="40px"
                        borderRadius="50%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        bg="gray.200" // You can choose any appropriate background color
                      >
                        <FiImage />
                      </Box>
                    )}
                    <Text>{item?.name}</Text>
                  </HStack>
                </Td>
                <Td
                  pl="16px"
                  pr="0px"
                  py="16px"
                  fontSize="14px"
                  color="textcolors.secondary"
                  borderColor="borders.accent"
                >
                  {/* {item?.inventoryCategoryId} */}
                  {
                    inventoryCategories?.find(
                      (category) => category.id === item.inventoryCategoryId
                    )?.name
                  }
                </Td>
                <Td
                  p={0}
                  borderColor="borders.accent"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<FiMoreVertical size={24} color="#545f70" />}
                      backgroundColor="transparent"
                      borderRadius="50%"
                      _hover={{ backgroundColor: "gray.300" }}
                      _active={{ backgroundColor: "gray.300" }}
                    />
                    <MenuList minW="180px">
                      <MenuItem
                        px="16px"
                        py="8px"
                        onClick={() => {
                          // setSelectedId(item?.id);
                          setSelectedItem(item);
                          setSelectedId(item?.id);
                          UpdateModal.onOpen();
                        }}
                      >
                        <HStack>
                          <Icon as={MdEdit} boxSize={18} color="#1f2533" />
                          <Text fontSize="14px" color="textcolors.primary">
                            Edit
                          </Text>
                        </HStack>
                      </MenuItem>
                      <MenuItem
                        px="16px"
                        py="8px"
                        onClick={() => {
                          setSelectedId(item?.id);
                          deleteModal.onOpen();
                        }}
                      >
                        <HStack>
                          <Icon as={MdDelete} boxSize={18} color="#1f2533" />
                          <Text fontSize="14px" color="textcolors.primary">
                            Delete
                          </Text>
                        </HStack>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            );
          })}

          <Modal isOpen={deleteModal.isOpen} onClose={deleteModal.onClose}>
            <ModalOverlay />
            <ModalContent mx="10px">
              <ModalHeader fontWeight="semibold" py="4px">
                Confirmation
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>Are you sure you want to Delete this?</ModalBody>

              <ModalFooter
                display="flex"
                justifyContent="flex-end"
                pt="16px"
                border="0px"
              >
                <ButtonGroup size="xs" variant="solid" borderRadius="5px">
                  <Button onClick={deleteModal.onClose} borderRadius="5px">
                    No
                  </Button>
                  <Button
                    colorScheme="red"
                    borderRadius="5px"
                    onClick={() => {
                      if (selectId) {
                        InventoryModule.DeleteItem(
                          selectId,
                          () => {
                            toast({
                              title: "Success",
                              description: "Item deleted sucessfully",
                              status: "success",
                            });
                            setLastUpdate(Date.now());
                            deleteModal.onClose();
                          },
                          (error) => {
                            toast({
                              title: "Error",
                              description: error,
                              status: "error",
                            });
                            deleteModal.onClose();
                          }
                        );
                      }
                    }}
                  >
                    Yes
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <UpdateInventoryModal
            isOpen={UpdateModal.isOpen}
            onClose={UpdateModal.onClose}
            inventoryTypes={inventoryCategories}
            selectedItem={selectedItem}
            selectedId={selectId}
            setLastUpdate={setLastUpdate}
          />
        </Tbody>
        <BulkDeleteModal
          isOpen={bulkDeleteModal.isOpen}
          onClose={bulkDeleteModal.onClose}
          itemIds={selectedItemIds}
          setLastUpdate={setLastUpdate}
          setSelectedItemIds={setSelectedItemIds}
        />
        <ViewImageModal
          isOpen={ImageModal.isOpen}
          onClose={ImageModal.onClose}
          selectedImage={selectedImage}
        />
      </Table>
    </TableContainer>
  );
}
