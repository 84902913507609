import axiosInstanceGateway from "./axiosInstance-gateway";

declare module BusinessDocumentsApi {
  type BusinessDocument = {
    fileName: string;
    fileUrl: string;
    id?: string;
  };
}
module BusinessDocumentsApi {
  const API_URL = "business-documents";
  export function UploadDocument(
    data: BusinessDocument,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .post(`${API_URL}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function GetBusinessDocuments(
    success: (response: Array<BusinessDocument>) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}`)
      .then((response) => {
        success(response.data.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function DeleteDocument(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .delete(`${API_URL}/${id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
}
export { BusinessDocumentsApi };
