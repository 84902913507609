import { Fragment } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { useAuditQuestionContainer } from "./AuditQuestionContainer";
import MultipleChoice from "./MultipleChoice";

export default function TemplateMultiChoice() {
  const template = useFormContext();
  const { getItemField, question, required } = useAuditQuestionContainer();
  const options = useWatch({
    control: template.control,
    name: getItemField("question.mcqsData.options"),
  });
  const isMulti = useWatch({
    control: template.control,
    name: getItemField("question.mcqsData.multiSelect"),
  });

  if (isMulti) {
    return (
      <Fragment>
        <Controller
          rules={{
            required: {
              value: required,
              message: "Multi Option is required",
            },
          }}
          control={template.control}
          name="question.questionValue"
          render={({ field, fieldState }) => {
            return (
              <ReactSelect
                {...field}
                isMulti
                withValidation
                isInvalid={fieldState.invalid}
                ErrorMessage="Please Select Global Responce"
                placeholder="Select Global Response"
                options={options}
                value={options?.find((op: any) => op.value === field.value)}
                onChange={(newValue: any) => {
                  field.onChange(newValue?.value);
                }}
                getOptionLabel={(option: any) => option.value}
                getOptionValue={(option: any) => option.id}
              />
            );
          }}
        />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Controller
          rules={{
            required: {
              value: required,
              message: "Multi Option is required",
            },
          }}
          control={template.control}
          name="question.questionValue"
          render={({ field, fieldState }) => {
            return <MultipleChoice options={options} />;
          }}
        />
      </Fragment>
    );
  }
}
