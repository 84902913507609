import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { Fragment, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import {
  AiFillCheckSquare,
  AiFillMessage,
  AiFillTag,
  AiOutlineFieldNumber,
  AiOutlineSearch,
  AiTwotoneCalendar,
} from "react-icons/ai";
import { BiSliderAlt, BiText } from "react-icons/bi";
import { FaFileSignature, FaMapMarkerAlt } from "react-icons/fa";
import { FaTableCells } from "react-icons/fa6";
import { FiImage } from "react-icons/fi";
import { GoPerson } from "react-icons/go";
import { RiCalendarCheckFill, RiPenNibFill } from "react-icons/ri";
import { Template } from "../../../modules/Audit";
import { getFieldKey } from "../../../modules/form-apis";
import { getMaxScoreMultichoice } from "../../../modules/utils";
import { useItemContext } from "../hooks/auditItemContext";
import useDrawer from "../hooks/drawersContext";
import { useMultiChoiceContext } from "../hooks/multiChoiceContext";
import { useTemplateBuilder } from "../hooks/templateBuilderContext";
import GlobalOptions from "./GlobalOptions";
import MultiOptions, { MultiOptionsStack } from "./MultiOptions";
export const QuestionTypeButtons: {
  [key in Template.QuestionType]: {
    title: string;
    Icon: any;
    questionType: Template.QuestionType;
  };
} = {
  SITE: {
    title: "Site",
    Icon: <AiFillTag color="#4d96fb" size={15} />,
    questionType: "SITE",
  },
  TABLE: {
    title: "Table",
    Icon: <FaTableCells color="#4d96fb" size={15} />,
    questionType: "TABLE",
  },
  DOCUMENT_NUMBER: {
    title: "Document number",
    Icon: <RiCalendarCheckFill color="#77ab59" size={15} />,
    questionType: "DOCUMENT_NUMBER",
  },
  PERSON: {
    title: "Person",
    Icon: <GoPerson color="orange" size={15} />,
    questionType: "PERSON",
  },
  INSPECTION_DATE: {
    title: "Inspection date",
    Icon: <AiOutlineFieldNumber color="#ff781f" />,
    questionType: "INSPECTION_DATE",
  },
  INSPECTION_LOCATION: {
    title: "Inspection Location",
    Icon: <FaMapMarkerAlt color="#ff781f" />,
    questionType: "INSPECTION_LOCATION",
  },
  TEXT_ANSWER: {
    title: "Text answer",
    Icon: <BiText color="#ff781f" />,

    questionType: "TEXT_ANSWER",
  },

  NUMBER: {
    title: "Number",
    Icon: <AiOutlineFieldNumber color="orange" />,
    questionType: "NUMBER",
  },

  CHECKBOX: {
    title: "Checkbox",
    Icon: <AiFillCheckSquare color="#4d96fb" />,
    questionType: "CHECKBOX",
  },
  DATE_TIME: {
    title: "Date & Time",
    Icon: <AiTwotoneCalendar color="#77ab59" />,
    questionType: "DATE_TIME",
  },

  MEDIA: {
    title: "Media",
    Icon: <FiImage color="teal" />,
    questionType: "MEDIA",
  },
  // DOCUMENTS: {
  //   title: 'Documents',
  //   Icon: <IoDocumentsSharp color="teal" />,
  //   questionType: 'DOCUMENTS',
  // },
  SLIDER: {
    title: "Slider",
    Icon: <BiSliderAlt color="#77ab59" />,
    questionType: "SLIDER",
  },
  ANNOTATION: {
    title: "Annotation",
    Icon: <RiPenNibFill color="orange" />,
    questionType: "ANNOTATION",
  },
  SIGNATURE: {
    title: "Signature",
    Icon: <FaFileSignature color="teal" />,
    questionType: "SIGNATURE",
  },
  LOCATION: {
    title: "Location",
    Icon: <FaMapMarkerAlt color="#ff781f" />,
    questionType: "LOCATION",
  },
  INSTRUCTIONS: {
    title: "Instruction",
    Icon: <AiFillMessage color="#4d96fb" />,

    questionType: "INSTRUCTIONS",
  },
  MULTICHOICE_CHECKBOX: {
    title: "Multichoice Checkbox",
    Icon: <AiFillMessage color="#4d96fb" />,
    questionType: "MULTICHOICE_CHECKBOX",
  },
  MULTICHOICE_RADIO: {
    title: "Multichoice Radio",
    Icon: <AiFillMessage color="#4d96fb" />,
    questionType: "MULTICHOICE_RADIO",
  },
  MCQs: {
    title: "Global response",
    Icon: <AiFillMessage color="#4d96fb" />,
    questionType: "MCQs",
  },
};
interface TypesProps {
  pageIndex: number;
  sectionIndex?: number;
  questionIndex: number;
}
export default function Types({
  pageIndex,
  questionIndex,
  sectionIndex,
}: TypesProps) {
  const [isScreenLargerThan768px] = useMediaQuery("(min-width: 768px)");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const template = useFormContext();
  const { widgets } = useTemplateBuilder();
  const { updateQuestionType } = useItemContext();
  const { DocNumberDrawer, setQuestionItemDetails } = useDrawer();

  const questionType: keyof typeof QuestionTypeButtons = useWatch({
    control: template.control,
    name: getFieldKey(
      { pageIndex, sectionIndex, questionIndex },
      "question.type"
    ),
  });

  const widgetId = useWatch({
    control: template.control,
    name: getFieldKey(
      { pageIndex, sectionIndex, questionIndex },
      "question.widgetId"
    ),
  });
  const widgetName = widgets.find((widget) => widget.id === widgetId)?.type;

  // function updateQuestionType(type: Template.QuestionType, widgetId: number) {
  //   const existingValues = template.getValues(
  //     getFieldKey({pageIndex, questionIndex, sectionIndex}, 'question'),
  //   );

  //   const format = Template.QUESTION_TYPES[type]?.format ?? '';

  //   if (type !== 'MCQs') {
  //     delete existingValues.mcqsData;
  //   }

  //   template.setValue(
  //     getFieldKey({pageIndex, questionIndex, sectionIndex}, 'question'),
  //     {
  //       ...existingValues,
  //       widgetId,
  //       type,
  //       format,
  //       properties: {
  //         ...(type === 'SLIDER'
  //           ? {
  //               min: existingValues?.properties?.min ?? 1,
  //               max: existingValues?.properties?.max ?? 10,
  //               increment: existingValues?.properties?.increment ?? 1,
  //             }
  //           : {}),
  //         ...(type === 'DOCUMENT_NUMBER'
  //           ? {
  //               documentNumberFormat:
  //                 existingValues?.properties?.documentNumberFormat ??
  //                 'Ref-[number]',
  //             }
  //           : {}),

  //         ...(type === 'DATE_TIME'
  //           ? {
  //               date: existingValues?.properties?.date ?? true,
  //               time: existingValues?.properties?.time ?? true,
  //             }
  //           : {}),
  //       },
  //     },
  //   );

  //   if (type === 'DOCUMENT_NUMBER') {
  //     setQuestionItemDetails({
  //       pageIndex,
  //       questionIndex,
  //       sectionIndex,
  //     });
  //     DocNumberDrawer?.onOpen();
  //   }
  // }

  // const watchedOptions = useWatch({
  //   control: template.control,
  //   name: getFieldKey(
  //     {pageIndex, questionIndex, sectionIndex},
  //     'question.mcqsData.options',
  //   ),
  // });

  const mcqsData = useWatch({
    control: template.control,
    name: getFieldKey(
      { pageIndex, questionIndex, sectionIndex },
      "question.mcqsData"
    ),
  });

  const { optionsLastUpdated } = useTemplateBuilder();
  const { templateOptionSets } = useMultiChoiceContext();

  useEffect(() => {
    if (mcqsData?.id) {
      const updatedOptionsSet = templateOptionSets.find(
        (set) => set.id === mcqsData.id
      );
      if (updatedOptionsSet) {
        template.setValue(
          getFieldKey(
            { pageIndex, questionIndex, sectionIndex },
            "question.mcqsData"
          ),
          updatedOptionsSet
        );
        template.setValue(
          getFieldKey(
            { pageIndex, questionIndex, sectionIndex },
            "question.score"
          ),
          getMaxScoreMultichoice(updatedOptionsSet.options)
        );
      }
    }
  }, [optionsLastUpdated]);

  const TriggerButton = () => {
    return (
      <HStack
        padding={"0px 14px"}
        onClick={() => {
          onOpen();
        }}
      >
        {questionType === "MCQs" ? (
          <MultiOptionsStack
            optionSet={mcqsData?.options ?? []}
            isGlobal={mcqsData?.isGlobal}
            optionSetName={mcqsData?.name}
            onClick={onOpen}
          />
        ) : (
          <Button
            as="div"
            h={"44px"}
            w={"100%"}
            justifyContent={"start"}
            leftIcon={QuestionTypeButtons[questionType ?? widgetName]?.Icon}
            backgroundColor={"transparent"}
            color={"black"}
            fontWeight={400}
            _hover={{ backgroundColor: "gray.200" }}
          >
            {QuestionTypeButtons[questionType ?? widgetName]?.title}
          </Button>
        )}

        <ChevronDownIcon />
      </HStack>
    );
  };

  return (
    <Fragment>
      {isScreenLargerThan768px ? (
        <Popover
          isOpen={isOpen}
          closeOnEsc
          closeOnBlur
          onClose={onClose}
          preventOverflow={true}
          placement="start"
          offset={[0, -400]}
          isLazy
        >
          <PopoverTrigger>
            <Box>
              <TriggerButton />
            </Box>
          </PopoverTrigger>

          <PopoverContent w={"608px"}>
            <PopoverBody padding={"0px"} w={"100%"} h={"100%"}>
              <PopoverBodyContent
                onPopoverClose={onClose}
                onSelectedOption={updateQuestionType}
              />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ) : (
        // <Fragment>
        //   <TriggerButton />
        //   <Modal isOpen={isOpen} onClose={onClose} size="608px">
        //     <ModalOverlay />
        //     <ModalContent w={'608px'}>
        //       <ModalBody padding={'0px'} w={'100%'} maxH="80vh">
        //         <PopoverBodyContent
        //           onPopoverClose={onClose}
        //           onSelectedOption={updateQuestionType}
        //         />
        //       </ModalBody>
        //     </ModalContent>
        //   </Modal>
        // </Fragment>
        <Fragment>
          <TriggerButton />
          <Modal isOpen={isOpen} onClose={onClose} size="full">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Types of responses</ModalHeader>
              <ModalCloseButton />
              <ModalBody padding={0} overflowY="hidden">
                <PopoverBodyContent
                  onPopoverClose={onClose}
                  onSelectedOption={updateQuestionType}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
}

interface PopoverBodyContentProps {
  onSelectedOption: (type: Template.QuestionType, id: number) => void;

  onPopoverClose: Function;
}
function PopoverBodyContent({
  onPopoverClose,
  onSelectedOption,
}: PopoverBodyContentProps) {
  const multiOptionsContext = useMultiChoiceContext();
  const { widgets } = useTemplateBuilder();
  const template = useFormContext();
  const titlebuttons: Array<{
    title: string;
    Icon: any;
    isDivider?: boolean;
    questionType: Template.QuestionType;
    id?: number;
    onClick?: Function;
  }> = [
    {
      title: "Site",
      Icon: <AiFillTag color="#4d96fb" size={15} />,
      questionType: "SITE",
      id: widgets.find((widget) => widget.type === "SITE")?.id,
    },
    {
      title: "Document number",
      Icon: <RiCalendarCheckFill color="#77ab59" size={15} />,
      questionType: "DOCUMENT_NUMBER",
      id: widgets.find((widget) => widget.type === "DOCUMENT_NUMBER")?.id,
    },
    {
      title: "Person",
      Icon: <GoPerson color="orange" size={15} />,
      questionType: "PERSON",
      id: widgets.find((widget) => widget.type === "PERSON")?.id,
    },
    {
      title: "Inspection date",
      Icon: <AiOutlineFieldNumber color="#ff781f" />,
      questionType: "INSPECTION_DATE",
      id: widgets.find((widget) => widget.type === "INSPECTION_DATE")?.id,
    },
    {
      title: "Inspection Location",
      Icon: <FaMapMarkerAlt color="#ff781f" />,
      questionType: "INSPECTION_LOCATION",
      id: widgets.find((widget) => widget.type === "INSPECTION_LOCATION")?.id,
    },
    {
      title: "Text answer",
      Icon: <BiText color="#ff781f" />,
      isDivider: true,
      questionType: "TEXT_ANSWER",
      id: widgets.find((widget) => widget.type === "TEXT_ANSWER")?.id,
    },
    {
      title: "Number",
      Icon: <AiOutlineFieldNumber color="orange" />,
      questionType: "NUMBER",
      id: widgets.find((widget) => widget.type === "NUMBER")?.id,
    },
    {
      title: "Checkbox",
      Icon: <AiFillCheckSquare color="#4d96fb" />,
      questionType: "CHECKBOX",
      id: widgets.find((widget) => widget.type === "CHECKBOX")?.id,
    },
    {
      title: "Date & Time",
      Icon: <AiTwotoneCalendar color="#77ab59" />,
      questionType: "DATE_TIME",
      id: widgets.find((widget) => widget.type === "DATE_TIME")?.id,
      isDivider: true,
    },
    {
      title: "Media",
      Icon: <FiImage color="teal" />,
      questionType: "MEDIA",
      id: widgets.find((widget) => widget.type === "MEDIA")?.id,
    },
    {
      title: "Slider",
      Icon: <BiSliderAlt color="#77ab59" />,
      questionType: "SLIDER",
      id: widgets.find((widget) => widget.type === "SLIDER")?.id,
    },
    {
      title: "Annotation",
      Icon: <RiPenNibFill color="orange" />,
      questionType: "ANNOTATION",
      id: widgets.find((widget) => widget.type === "ANNOTATION")?.id,
    },
    {
      title: "Signature",
      Icon: <FaFileSignature color="teal" />,
      questionType: "SIGNATURE",
      id: widgets.find((widget) => widget.type === "SIGNATURE")?.id,
    },
    {
      title: "Location",
      Icon: <FaMapMarkerAlt color="#ff781f" />,
      questionType: "LOCATION",
      id: widgets.find((widget) => widget.type === "LOCATION")?.id,
    },
    {
      title: "Instruction",
      Icon: <AiFillMessage color="#4d96fb" />,
      isDivider: true,
      questionType: "INSTRUCTIONS",
      id: widgets.find((widget) => widget.type === "INSTRUCTIONS")?.id,
    },
    {
      title: "Table",
      Icon: <FaTableCells color="#4d96fb" />,
      isDivider: true,
      questionType: "TABLE",
      id: widgets.find((widget) => widget.type === "TABLE")?.id,
    },
    // {
    //   title: 'Documents',
    //   Icon: <IoDocumentsSharp color="teal" />,
    //   questionType: 'DOCUMENTS',
    //   id: widgets.find(widget => widget.type === 'DOCUMENTS')?.id,
    // },
  ];

  return (
    <Stack
      direction={{
        xs: "column",
        md: "row",
      }}
      w={"100%"}
      h={{ xs: "100%", md: "80vh" }}
      spacing={0}
    >
      <Stack
        w={{
          xs: "100%",
          md: "50%",
        }}
        h={{ xs: "50%", md: "100%" }}
      >
        <VStack
          w={"100%"}
          h="100%"
          spacing={0}
          overflowY="scroll"
          paddingBottom="5px"
        >
          <VStack w={"100%"} spacing={0} padding={"12.8px"}>
            <InputGroup>
              <InputLeftElement
                pointerEvents="auto"
                children={<AiOutlineSearch color="black" />}
              />
              <Input
                type="search"
                placeholder="Search"
                _focusVisible={{ boxShadow: "0px" }}
                border={"1px"}
                borderStyle={"solid"}
                borderColor={"borders.accent"}
              />
            </InputGroup>
          </VStack>

          <MultiOptions
            onPopoverClose={onPopoverClose}
            options={multiOptionsContext.templateOptionSets}
          />

          <Divider />
          <GlobalOptions onPopoverClose={onPopoverClose} />
        </VStack>
      </Stack>
      <Stack
        w={{
          xs: "100%",
          md: "50%",
        }}
        h={"100%"}
        borderLeft={{
          xs: "none",
          md: "1px",
        }}
        borderStyle={"solid"}
        borderColor={{
          xs: "transparent",
          md: "borders.accent",
        }}
        paddingY="8px"
      >
        <VStack
          w={"100%"}
          h={{ xs: "50%", md: "100%" }}
          spacing={0}
          overflowY="scroll"
        >
          <Text
            w={"100%"}
            padding={"9.5px 14px"}
            align={"start"}
            fontSize={"13px"}
            fontWeight={400}
            color={"icons.primary"}
          >
            Title page information
          </Text>
          {titlebuttons.map((menu, i) => {
            return (
              <Fragment key={i}>
                {menu.isDivider && (
                  <Divider
                    style={{
                      margin: "0px 0px 9.6px 0px",
                    }}
                  />
                )}
                <Button
                  as="div"
                  h={"44px"}
                  padding={"10px 14px"}
                  w={"100%"}
                  justifyContent={"start"}
                  leftIcon={menu.Icon}
                  backgroundColor={"transparent"}
                  color={"black"}
                  fontWeight={400}
                  _hover={{ backgroundColor: "gray.200" }}
                  onClick={() => {
                    onSelectedOption(menu.questionType, menu.id ?? 0);
                    onPopoverClose();
                  }}
                >
                  {menu.title}
                </Button>
              </Fragment>
            );
          })}
        </VStack>
      </Stack>
    </Stack>
  );
}
