import {
  Box,
  Button,
  Checkbox,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { RiArrowDownSFill } from "react-icons/ri";
import { GroupForContact } from "../../../Api/GroupForContact";
import AddGroupModal from "./AddGroupModal";
import DeleteGroup from "./DeleteGroup";

interface Props {
  selectedId: string[];
  setLastUpdated: React.Dispatch<React.SetStateAction<number>>;
  lastUpdated: number;
}

export function AddNewGroup({
  selectedId,
  setLastUpdated,
  lastUpdated,
}: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedContactId, setSelectedContactId] = useState<string>();

  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const deleteModal = useDisclosure();
  const addNewGroup = useDisclosure();

  const [groupData, setGroupData] = useState<Array<GroupForContact.FetchGroup>>(
    []
  );

  const form = useForm<GroupForContact.AddGroup>({
    defaultValues: {
      name: "",
      contactIds: [],
    },
  });

  useEffect(() => {
    GroupForContact.FetchGroupDetails(
      {
        name: undefined,
        isActive: true,
        limit: 500,
        page: 1,
      },
      (data) => {
        setGroupData(data.data);
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      }
    );
  }, [lastUpdated]);

  const handleCheckboxChange = (id: string) => {
    setSelectedGroupId((prevId) => (prevId === id ? null : id)); // Toggle selection
  };

  return (
    <FormProvider {...form}>
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          h={"32px"}
          color={"#3454d1"}
          fontSize={"15px"}
          isDisabled={selectedId.length === 0}
          bgColor={"customColor.white"}
          rightIcon={<RiArrowDownSFill />}
          fontWeight={"700"}
          variant={"ghost"}
          _hover={{ bg: "#f2f3f4" }}
          _active={{ bg: "#f2f3f4" }}
        >
          Add to group
        </MenuButton>
        <MenuList maxH={"250px"} overflowX={"auto"} paddingBottom={"0"}>
          <Stack
            h={"60px"}
            borderBottom={"1px solid #e5e7eb"}
            gap={0}
            padding={"12px"}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text
              fontSize={"15px"}
              color={"customColor.black7"}
              fontWeight={"700"}
            >
              Add to group
            </Text>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              isDisabled={!selectedGroupId || selectedGroupId.length === 0}
              _hover={{ bgColor: "#2c48b5" }}
              onClick={(e) => {
                form.handleSubmit((data) => {
                  // Set selected IDs to contactIds before submission
                  data.contactIds = selectedId; // Assuming selectedIds is the array of IDs you want to store

                  setIsLoading(true);
                  GroupForContact.PostContactInGroup(
                    selectedGroupId!,
                    data,
                    (success) => {
                      setIsLoading(false);
                      setLastUpdated(Date.now());
                      setSelectedGroupId("");
                    },
                    (err) => {
                      setIsLoading(false);
                    }
                  );
                })(e);
              }}
            >
              Add
            </Button>
          </Stack>

          <Skeleton isLoaded={!isLoading} height="100%">
            {groupData.map((item, i) => (
              <Stack gap={0} margin={"16px"} key={i}>
                <Stack
                  direction={"row"}
                  paddingTop={"12px"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Checkbox
                    size={"lg"}
                    isChecked={selectedGroupId === item.id}
                    onChange={() => handleCheckboxChange(item.id)}
                  >
                    <Text
                      fontSize={"15px"}
                      color={"customColor.black7"}
                      fontWeight={"600"}
                      onClick={() => {
                        setSelectedContactId(item.id);
                      }}
                    >
                      {item.name}
                    </Text>
                  </Checkbox>
                  <Menu>
                    <MenuButton
                      as={Button}
                      padding={0}
                      backgroundColor={"transparent"}
                      h={"34.5px"}
                      marginLeft={"8px"}
                      _light={{ color: "customColor.black7" }}
                      borderRadius={"full"}
                      _hover={{ backgroundColor: "primary.50" }}
                      _active={{ backgroundColor: "primary.100" }}
                    >
                      <Icon as={FiMoreVertical} />
                    </MenuButton>
                    <MenuList style={{ minWidth: "140px" }} zIndex={1112}>
                      {[
                        {
                          name: "Edit",
                          onClick: () => {
                            setSelectedContactId(item.id);
                            form.reset({
                              name: item.name,
                              id: item.id,
                            });
                            addNewGroup.onOpen();
                          },
                        },
                        {
                          name: "Delete",
                          onClick: () => {
                            setSelectedContactId(item.id);
                            deleteModal.onOpen();
                          },
                        },
                      ].map((menuItem, i) => (
                        <MenuItem
                          key={i}
                          padding={"1px 0px 1px 20px"}
                          _light={{ color: "customColor.black7" }}
                          _dark={{ color: "customColor.white" }}
                          zIndex={"9999"}
                          onClick={menuItem.onClick}
                        >
                          <Button
                            variant={"ghost"}
                            fontWeight={"normal"}
                            fontSize={"13px"}
                            padding={"0px 20px 0px 0px"}
                            _light={{ color: "customColor.black7" }}
                            _dark={{ color: "customColor.white" }}
                            _hover={{ bg: "none" }}
                          >
                            {menuItem.name}
                          </Button>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </Stack>
              </Stack>
            ))}
          </Skeleton>

          <Box
            borderTop={"1px solid #e5e7eb"}
            position={"sticky"}
            zIndex={1111}
            bottom={0}
            bgColor={"customColor.white"}
          >
            <Button
              margin={"12px 20px 12px 12px"}
              h={"38px"}
              leftIcon={<FaPlus />}
              color={"#3454d1"}
              fontSize={"15px"}
              padding={"5px 12px"}
              bgColor={"customColor.white"}
              fontWeight={"700"}
              variant={"ghost"}
              _hover={{ bg: "#f2f3f4" }}
              _active={{ bg: "#f2f3f4" }}
              onClick={() => {
                setSelectedContactId(undefined);
                form.reset({
                  name: "",
                  contactIds: selectedId,
                });
                addNewGroup.onOpen();
              }}
            >
              New group
            </Button>
          </Box>
        </MenuList>
      </Menu>
      <DeleteGroup
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
        setLastUpdated={setLastUpdated}
        selectedContactId={selectedContactId}
      />
      <AddGroupModal
        isOpen={addNewGroup.isOpen}
        onClose={addNewGroup.onClose}
        setLastUpdated={setLastUpdated}
        selectedContactId={selectedContactId}
      />
    </FormProvider>
  );
}
