import axios from "axios";
import { axiosInstance } from "./axiosInstance-hris";

declare module PayslipsModule {
  type includeInPayRun = {
    payRunId: string;
    payslipIds: Array<string>;
    isIncludeInPayRun: boolean;
  };
  type PayslipData = {
    id: string;
    payRunId: string;
    paymentDate: string;
    totalEarnings: number;
    totalDeductions: number;
    totalTax: number;
    totalLeaves: number;
    totalReimbursements: number;
    totalRetirements: number;
    payRun: {
      startDate: string;
      endDate: string;
      type?: string;
      payrollCalendar: {
        name: string;
      };
    };
  };
}
module PayslipsModule {
  const api_url = "/payslips";
  export function IncludeInPayRun(
    data: Partial<includeInPayRun>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${api_url}/include-in-pay-run`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function changePaymentDate(
    id: string,
    data: { paymentDate: string },
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${api_url}/change-payment-date/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function DownloadPayslip(
    id: string,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${api_url}/download/${id}`)
      .then((response) => {
        success(response?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function MobileDownloadPayslip(
    token: string,
    id: string,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axios
      .get(`${api_url}/download/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        baseURL: process.env.REACT_APP_API_URL,
      })
      .then((response) => {
        success(response?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function DownloadBulkPayslip(
    id: string,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${api_url}/payrun-payslips/${id}`)
      .then((response) => {
        success(response.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function GetPayslips(
    data: { employeeId: string },
    success: (response: Array<PayslipData>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${api_url}/employee-payslips`, { params: data })
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { PayslipsModule };
