import {
  Box,
  Checkbox,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import {Controller, useFormContext} from 'react-hook-form';
import {PeriodicalSchedule} from '../../../../../modules/Audit/PeriodicalSchedule';

export default function WeeklySchedule() {
  const daysOfWeek: Array<{
    value: PeriodicalSchedule.Day;
    label: string;
  }> = [
    {value: 'SUNDAY', label: 'Sunday'},
    {value: 'MONDAY', label: 'Monday'},
    {value: 'TUESDAY', label: 'Tuesday'},
    {value: 'WEDNESDAY', label: 'Wednesday'},
    {value: 'THURSDAY', label: 'Thursday'},
    {value: 'FRIDAY', label: 'Friday'},
    {value: 'SATURDAY', label: 'Saturday'},
  ];
  const form = useFormContext<PeriodicalSchedule.AddPeriodicSchedule>();
  return (
    <Box>
      <HStack mb="4px">
        <Text>Recur every</Text>
        <Controller
          control={form.control}
          name="properties.weekly.numOfWeeks"
          render={({field, fieldState}) => (
            <NumberInput
              {...field}
              w="100px"
              min={1}
              onChange={valueString => field.onChange(parseInt(valueString))}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          )}
        />
        <Text>week(s) on:</Text>
      </HStack>

      <Controller
        control={form.control}
        name="properties.weekly.days"
        rules={{
          validate: {
            notEmpty: value =>
              (value && value.length > 0) || 'Days selection is required',
          },
        }}
        render={({field, fieldState}) => (
          <Stack>
            <SimpleGrid columns={4} spacingX="8px" spacingY="8px">
              {daysOfWeek.map((day, index) => {
                const fieldValue = Array.isArray(field.value)
                  ? field.value
                  : [];
                const isCheckedDefault = fieldValue.some(
                  selectedDay => selectedDay === day.value,
                );

                return (
                  <Checkbox
                    key={index}
                    isChecked={isCheckedDefault}
                    onChange={e => {
                      const newDays = Array.isArray(field.value)
                        ? [...field.value]
                        : [];
                      if (e.target.checked) {
                        newDays.push(day.value);
                      } else {
                        const indexToRemove = newDays.findIndex(
                          selectedDay => selectedDay === day.value,
                        );
                        if (indexToRemove > -1) {
                          newDays.splice(indexToRemove, 1);
                        }
                      }
                      field.onChange(newDays);
                    }}>
                    {day.label}
                  </Checkbox>
                );
              })}
            </SimpleGrid>

            <Text color="red.500" fontSize="xs">
              {fieldState.error?.message}
            </Text>
          </Stack>
        )}
      />
    </Box>
  );
}
