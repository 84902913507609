import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  TabList,
  Tabs,
  Tooltip,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { BiImport } from "react-icons/bi";
import { LuSend } from "react-icons/lu";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { PiExportBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { AddInvoices } from "../../Api/Invoices";
import { InvoiceTable } from "./InvoiceTable";

export function InvoicesParent() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  const toast = useToast();
  const [invoicesData, setInvoicesData] = useState<
    Array<AddInvoices.FetchInvoices>
  >([]);
  const [draftData, setDraftData] = useState<Array<AddInvoices.FetchInvoices>>(
    []
  );
  const [paidData, setPaidData] = useState<Array<AddInvoices.FetchInvoices>>(
    []
  );
  const [awaitingPaymentData, setAwaitingPaymentData] = useState<
    Array<AddInvoices.FetchInvoices>
  >([]);
  const [awaitingApprovalData, setAwaitingApprovalData] = useState<
    Array<AddInvoices.FetchInvoices>
  >([]);

  const [draftCount, setDraftCount] = useState<number>(0);
  const [paidCount, setPaidCount] = useState<number>(0);
  const [awaitingPaymentCount, setAwaitingPaymentCount] = useState<number>(0);
  const [awaitingApprovalCount, setAwaitingApprovalCount] = useState<number>(0);

  const [searchText, setSearchText] = useState(undefined);
  const [limit, setLimit] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  useEffect(() => {
    AddInvoices.FetchInvoicesDetails(
      {
        limit: limit,
        page: pageNumber,
      },
      (data) => {
        setInvoicesData(data.data);
        setCount(data?.total);
        setIsLoading(false);
        const draftInvoices = data.data.filter(
          (invoice: any) => invoice.status === "Draft"
        );
        const paidInvoices = data.data.filter(
          (invoice: any) => invoice.status === "Paid"
        );
        const awaitingPaymentInvoices = data.data.filter(
          (invoice: any) => invoice.status === "Awaiting Payment"
        );
        const awaitingApprovalInvoices = data.data.filter(
          (invoice: any) => invoice.status === "Awaiting Approval"
        );

        // Update the state variables for each category
        setDraftData(draftInvoices);
        setPaidData(paidInvoices);
        setAwaitingPaymentData(awaitingPaymentInvoices);
        setAwaitingApprovalData(awaitingApprovalInvoices);

        // Set the count for each category
        setDraftCount(draftInvoices.length);
        setPaidCount(paidInvoices.length);
        setAwaitingPaymentCount(awaitingPaymentInvoices.length);
        setAwaitingApprovalCount(awaitingApprovalInvoices.length);
      },
      (err) => {
        toast({
          title: "Error",
          description: err,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, [lastUpdated, searchText, limit, pageNumber]);
  const tabCategories = [
    {
      label: "All",
      index: 0,
      flexValue: 1,
      component: (
        <InvoiceTable
          count={count}
          category={"All"}
          limit={limit}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          setLimit={setLimit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setLastUpdated={setLastUpdated}
          invoicesData={invoicesData}
        />
      ),
    },
    {
      label: "Draft",
      index: 1,
      flexValue: 1,
      component: (
        <InvoiceTable
          count={draftCount}
          category={"Draft"}
          limit={limit}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          setLimit={setLimit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setLastUpdated={setLastUpdated}
          invoicesData={draftData}
        />
      ),
    },
    {
      label: "Awaiting Approval",
      index: 2,
      flexValue: 3,
      component: (
        <InvoiceTable
          count={awaitingApprovalCount}
          category={"Awaiting Approval"}
          limit={limit}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          setLimit={setLimit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setLastUpdated={setLastUpdated}
          invoicesData={awaitingApprovalData}
        />
      ),
    },
    {
      label: "Awaiting Payment",
      index: 3,
      flexValue: 3,
      component: (
        <InvoiceTable
          count={awaitingPaymentCount}
          category={"Awaiting Payment"}
          limit={limit}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          setLimit={setLimit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setLastUpdated={setLastUpdated}
          invoicesData={awaitingPaymentData}
        />
      ),
    },
    {
      label: "Paid",
      index: 4,
      flexValue: 1,
      component: (
        <InvoiceTable
          count={paidCount}
          category={"Paid"}
          limit={limit}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          setLimit={setLimit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setLastUpdated={setLastUpdated}
          invoicesData={paidData}
        />
      ),
    },
    {
      label: "Repeating",
      index: 5,
      flexValue: 1,
      // component: <InvoiceTable />,
    },
  ];
  const [selectedTab, setSelectedTab] = useState(0);
  const isMediumScreen = useBreakpointValue({ base: false, md: true });
  return (
    <Stack
      gap={0}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      h={"calc(100vh - 70px)"}
      // h="calc(100vh - 130px)"
      overflowY="auto"
    >
      <Stack
        borderBottom={"1px solid #d6dade"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        h={"fit-content"}
        direction={{ xs: "column", sm: "row" }}
        justifyContent={"space-between"}
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={{ xs: "16px", lg: "10px 30px" }}
      >
        <Heading
          fontSize="16px"
          _light={{
            color: "customColor.black7",
          }}
          _dark={{ color: "customColor.white" }}
          fontWeight="bold"
        >
          Invoices
        </Heading>

        <HStack alignContent={{ xs: "flex-end", sm: "flex-end" }}>
          <Tooltip label="New Credit Note">
            <IconButton
              aria-label="new credit note"
              icon={<MdOutlineLibraryAdd />}
              bgColor="#fff"
              h={"40px"}
              w={"40px"}
              color={"#6B7885"}
              transition={"all .3s ease"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              padding={"11px"}
              _hover={{
                cursor: "non-allowed",
              }}
              isDisabled
            />
          </Tooltip>
          <Tooltip label="Send Statement">
            <IconButton
              aria-label="Send Statement"
              icon={<LuSend />}
              bgColor="#fff"
              h={"40px"}
              w={"40px"}
              color={"#6B7885"}
              transition={"all .3s ease"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              padding={"11px"}
              _hover={{
                color: "#fff",
                border: "1px solid #3454d1",
                bgColor: "#3454D1",
              }}
            />
          </Tooltip>
          <Tooltip label="Import">
            <IconButton
              aria-label="Import"
              icon={<BiImport />}
              bgColor="#fff"
              h={"40px"}
              w={"40px"}
              color={"#6B7885"}
              transition={"all .3s ease"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              padding={"11px"}
              _hover={{
                color: "#fff",
                border: "1px solid #3454d1",
                bgColor: "#3454D1",
              }}
            />
          </Tooltip>

          <Tooltip label="Export">
            <IconButton
              aria-label="Export"
              icon={<PiExportBold />}
              bgColor="#fff"
              h={"40px"}
              w={"40px"}
              color={"#6B7885"}
              transition={"all .3s ease"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              padding={"11px"}
              _hover={{
                color: "#fff",
                border: "1px solid #3454d1",
                bgColor: "#3454D1",
              }}
            />
          </Tooltip>

          <Button
            borderRadius={"3px"}
            backgroundColor={"#3454d1"}
            border={"1px solid #3454d1"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#fff"}
            padding={"12px 16px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
              color: "#3454D1",
            }}
            onClick={() => {
              // navigate("/app/payroll/invoices", {
              //   state: {
              //     id: undefined,
              //     uniqueNumber: "INV0001",
              //   },
              // });
              AddInvoices.GenerateUniqueNumber(
                (success) => {
                  // Store response data in the state

                  navigate("/app/payroll/invoices", {
                    state: {
                      id: undefined,
                      uniqueNumber: success.uniqueNumber,
                    },
                  });
                },
                (error) => {
                  toast({
                    title: "Error",
                    description: error,
                    status: "error",
                  });
                  // Show error message in toast
                }
              );
            }}
          >
            Create Invoices
          </Button>
        </HStack>
      </Stack>
      <Stack
        justifyContent={"center"}
        borderBottom={"1px solid #d6dade"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
      >
        <Container maxW={"100%"} p={{ xs: "16px", lg: "0px 30px" }}>
          <Stack
            marginTop={"24px"}
            borderTopRadius={"10px"}
            borderTop={isMediumScreen ? "1px solid #ddd" : 0}
            borderLeft={isMediumScreen ? "1px solid #ddd" : 0}
            borderRight={isMediumScreen ? "1px solid #ddd" : 0}
            transition={"all .3s ease"}
          >
            {isMediumScreen ? (
              <Tabs
                index={selectedTab}
                orientation="horizontal"
                onChange={setSelectedTab}
                isLazy
              >
                <TabList>
                  {tabCategories.map((tab, i) => (
                    <Tab
                      key={i}
                      flex={tab.flexValue}
                      borderRight={tab.index !== 5 ? "1px solid #ddd" : "none"}
                      fontSize={"13.44px"}
                      padding={"20px 15px"}
                      fontWeight={"600"}
                      _light={{ color: "#283c50" }}
                      onClick={() => setSelectedTab(tab.index)}
                      _selected={{
                        _light: { color: "#3454d1" },
                        fontWeight: 600,
                        bg: "rgba(52,84,209,.075)",
                        borderBottom: "3px solid #3454d1",
                      }}
                      _hover={{ _light: { color: "#3454d1" }, fontWeight: 600 }}
                    >
                      {tab.label}
                    </Tab>
                  ))}
                </TabList>

                {/* <TabPanels p={0}>
                  {tabCategories.map((tab, i) => (
                    <TabPanel key={i} padding={0}>
                      {" "}
                      {tab.component}
                    </TabPanel>
                  ))}
                </TabPanels> */}
              </Tabs>
            ) : (
              <Box alignContent={"flex-end"} justifyContent={"flex-end"}>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<AiOutlineCaretDown />}
                    backgroundColor={"#fff"}
                    borderRadius={"3px"}
                    justifyContent="flex-end"
                    border={"1px solid #dcdee4"}
                    fontSize={"13px"}
                    h={"40px"}
                    minW={"170px"}
                    variant={"solid"}
                    textColor={"customColor.black7"}
                    padding={"12px 16px"}
                    fontWeight={600}
                    _hover={{
                      color: "primary.950",
                      backgroundColor: "#fff",
                    }}
                    cursor={"pointer"}
                    _active={{
                      backgroundColor: "#fff",
                      color: "primary.950",
                    }}
                  >
                    {tabCategories[selectedTab].label}
                  </MenuButton>
                  <MenuList>
                    {tabCategories.map((tab) => (
                      <MenuItem
                        fontSize={"13.44px"}
                        key={tab.index}
                        onClick={() => setSelectedTab(tab.index)}
                      >
                        {tab.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Box>
            )}
          </Stack>
        </Container>
      </Stack>
      <Container maxW={"100%"} padding={{ xs: "16px", lg: "30px 30px 5px" }}>
        <Box>{tabCategories[selectedTab].component}</Box>
      </Container>
    </Stack>
  );
}
