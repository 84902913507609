import { axiosInstance } from "./axiosInstance-aud";

declare module PeriodicalEvents {
  type AddPeriodicalEvents = {
    id?: number;
    name: string;
    description: string;
    code: string;
  };

  type GetEvents = {
    id: number;
    name: string;
    description: string;
    code: string;
  };
}
module PeriodicalEvents {
  const URL = "/periodic-activities";
  export function AddPeriodicalEvents(
    data: AddPeriodicalEvents,
    success: (response: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${URL}`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function UpdatePeriodicalEvents(
    id: number,
    data: Partial<AddPeriodicalEvents>,
    success: (response: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${URL}/${id}`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchPeriodicalEvents(
    success: (response: Array<GetEvents>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function DeletePeriodicalEvents(
    id: number,
    success: (response: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${URL}/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { PeriodicalEvents };
