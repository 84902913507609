import {
  Box,
  Stack,
  useDisclosure,
  UseDisclosureProps,
  useToast,
} from "@chakra-ui/react";
import { createContext, useContext, useEffect, useState } from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import { Template } from "../../../modules/Audit";
import { ReportLayout } from "../../../modules/Audit/ReportLayout";
import ReportLayoutHistory from "./report-components/Layouts/ReportLayoutHistory";
import ReportPreview from "./report-components/report-preview";
import SideBar from "./report-components/sidebar";

interface TemplateReportContext {
  setDisplayedComponent: (component: JSX.Element) => void;
  DisplayedComponent: JSX.Element;
  isDrawer: boolean;

  GoBackToPrevComponent: Function;
  ReportDrawer?: UseDisclosureProps;
  isNewTemplate: boolean;
  setIsNewTemplate: React.Dispatch<React.SetStateAction<boolean>>;
  isAllTemplate: boolean;
  setIsAllTemplate: React.Dispatch<React.SetStateAction<boolean>>;
}
const TemplateReportContext = createContext<TemplateReportContext>({
  setDisplayedComponent: () => {},
  DisplayedComponent: <></>,
  isDrawer: false,

  GoBackToPrevComponent: () => {},
  isNewTemplate: false,
  setIsNewTemplate: () => {},
  isAllTemplate: false,
  setIsAllTemplate: () => {},
});
export default function TemplateReport() {
  const [DisplayedComponent, setDisplayedComponentState] =
    useState<JSX.Element>(<ReportLayoutHistory />);
  const [previewComponent, setPreviewComponent] = useState<JSX.Element>();
  const [isDrawer, setIsDrawer] = useState<boolean>(false);
  const [isNewTemplate, setIsNewTemplate] = useState<boolean>(false);
  const [isAllTemplate, setIsAllTemplate] = useState<boolean>(false);

  function setDisplayedComponent(component: JSX.Element) {
    setDisplayedComponentState((prevComp) => {
      setPreviewComponent(prevComp);
      return component;
    });
  }

  function GoBackToPrevComponent() {
    setDisplayedComponentState(previewComponent ?? <SideBar />);
  }

  const ReportDrawer = useDisclosure({
    onClose: () => {
      setIsDrawer(false);
    },
    onOpen: () => {
      setIsDrawer(true);
    },
  });
  const { id } = useParams();
  const templateForm = useFormContext<Template.Template>();
  const form = useForm<ReportLayout.ReportLayout>({
    defaultValues: {
      templateId: Number(id!),
      name: "Untitled Layout",
      active: false,
      layout: {
        coverPage: "",
        logo: "",
        action: true,
        checkboxes: true,
        disclaimer: true,
        questionDivider: true,
        flaggedItems: true,
        footer: {
          addFooter: true,
          additionalInfo: true,
          image: "",
          inspectionTitle: true,
          notes: "",
          showPicture: true,
        },
        header: {
          addHeader: true,
          image: "",
          showPicture: true,
        },
        inspectionResults: true,
        instructions: true,
        mediaSummary: true,
        scores: true,
        tableOfContents: false,
        unansweredQuestions: true,
      },
    },
  });

  const isLoading = useWatch({
    control: form.control,
    name: "isLoading",
  });
  const toast = useToast();
  useEffect(() => {
    if (id) {
      ReportLayout.getReportLayout(
        id,
        (data) => {
          form.reset({
            ...data,
            template: templateForm.getValues(),
            layout: { ...data.layout, ...form.getValues("layout") },
          });
        },
        (err) => {
          toast({
            title: "Error",
            description: err,
            status: "error",
          });
        }
      );
    }
  }, []);

  return (
    <FormProvider {...form}>
      <TemplateReportContext.Provider
        value={{
          setDisplayedComponent,
          DisplayedComponent,
          isDrawer,
          GoBackToPrevComponent,
          ReportDrawer,
          isNewTemplate,
          setIsNewTemplate,
          isAllTemplate,
          setIsAllTemplate,
        }}
      >
        <Stack h="calc(100vh - 70px)" spacing={0} direction="row" w="100%">
          <Box
            w={{ xs: "none", xl: "md" }}
            maxW="400px"
            borderRight="1px solid"
            borderColor="borders.accent"
          >
            {DisplayedComponent}
          </Box>
          <Box w={{ xs: "full", xl: "full" }} p="10px">
            {!isLoading && <ReportPreview />}
          </Box>
        </Stack>
      </TemplateReportContext.Provider>
    </FormProvider>
  );
}

export function useTemplateReport() {
  return useContext<TemplateReportContext>(TemplateReportContext);
}
