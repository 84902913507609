import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {Fragment, useEffect, useState} from 'react';
import {useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import {BsLaptop} from 'react-icons/bs';
import {MdPictureAsPdf} from 'react-icons/md';
import {useParams} from 'react-router';
import {ReportLayout} from '../../../../modules/Audit/ReportLayout';
import {useTemplateReport} from '../report';
import {ExistingReportStructureType, restructureTemplateItems} from '../utils';
import {DraggableItem} from './Draggable/DraggableItem';
import DraggableQuestion from './Draggable/DraggableQuestion';
import {DraggableSection} from './Draggable/DraggableSection';
import ReportLayoutHistory from './Layouts/ReportLayoutHistory';

interface ReportPreviewProps {}
export default function ReportPreview({}: ReportPreviewProps) {
  const [selectedValue, setSelectedValue] = useState('option1');
  const web = useBreakpointValue({
    xl: 'Web preview',
    xs: undefined,
  });
  const pdf = useBreakpointValue({
    xl: 'PDF preview',
    xs: undefined,
  });
  const options: Array<{
    label?: string;
    icon: any;
    rightradius: string;
    leftradius: string;
  }> = [
    {
      label: web,
      icon: <BsLaptop color="#1da5d4" size={17} />,
      leftradius: '8px',
      rightradius: '0px',
    },
    {
      label: pdf,
      icon: <MdPictureAsPdf color="#1da5d4" size={17} />,
      leftradius: '0px',
      rightradius: '8px',
    },
  ];
  // return <ReportLayoutDrawer />;
  const toast = useToast();
  const {setDisplayedComponent} = useTemplateReport();
  const {id} = useParams();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const form = useFormContext<ReportLayout.ReportLayout>();
  const templateItems = useFieldArray({
    control: form.control,
    name: 'template.templateItems',
    keyName: 'uniqueKey',
  });

  const reportStructure = useFieldArray({
    control: form.control,
    name: 'reportStructure',
    keyName: 'uniqueKey',
  });

  const watchedReportStructure = useWatch({
    control: form.control,
    name: 'reportStructure',
  });

  const watchedLayoutId = useWatch({
    control: form.control,
    name: 'id',
  });

  useEffect(() => {
    // let exclude: ExludeItemsFromTemplateItems = {
    //   innerChildren: [],
    //   children: [],
    // };

    let exisitingReportStructure: ExistingReportStructureType = {
      innerChildren: {},
      children: {},
    };

    for (let page of reportStructure.fields) {
      for (let child of page.children) {
        // exclude.children.push(child.id!);

        // if (child.type === 'SECTION') {
        //   exclude.innerChildren.push(...(child.children.map(c => c.id!) ?? []));
        // }

        exisitingReportStructure.children[child.id] = {
          alignment: child.alignment ?? 'left',
          display: child.display,
          orderIndex: child.orderIndex,
        };

        if (child.type === 'SECTION') {
          child.children.forEach(c => {
            console.log({c});
            exisitingReportStructure.innerChildren[c.id] = {
              display: c.display,
              alignment: c.alignment ?? 'left',
              orderIndex: c.orderIndex,
            };
          });
        }
      }
    }

    let reportStructureArr: Array<ReportLayout.ReportStructureItem> =
      restructureTemplateItems(templateItems.fields, exisitingReportStructure);

    console.log(exisitingReportStructure);
    form.setValue('reportStructure', reportStructureArr);
  }, [watchedLayoutId]);

  function handleDragEnd(
    updatedData: any,
    questionLocation: {
      pageIndex: number;
      childIndex?: number;
    },
  ) {
    const {pageIndex, childIndex} = questionLocation;

    if (pageIndex !== undefined && childIndex !== undefined) {
      reportStructure.fields[pageIndex].children[childIndex].children =
        updatedData;
    } else if (pageIndex !== undefined && childIndex === undefined) {
      reportStructure.fields[pageIndex].children = updatedData;
    }

    reportStructure.replace(reportStructure.fields);
  }
  const WatchLayoutLogo = useWatch({
    control: form.control,
    name: 'layout.logo',
  });
  const watchTemplateLogo = useWatch({
    control: form.control,
    name: 'template.template.image',
  });
  const watchScoreValue = useWatch({
    control: form.control,
    name: 'layout.scores',
  });
  const watchFlaggedItems = useWatch({
    control: form.control,
    name: 'layout.flaggedItems',
  });
  const watchAction = useWatch({
    control: form.control,
    name: 'layout.action',
  });
  return (
    <Fragment>
      {
        // form.getValues('reportStructure') &&
        // form?.getValues('reportStructure')?.length! > 0
        form.getValues('showReportStructure') ? (
          <Container
            maxW="container.xl"
            bgColor="white"
            maxHeight={'calc(100% - 100px)'}
            overflowY="auto"
            p={4}
            borderRadius="8px">
            {(WatchLayoutLogo || watchTemplateLogo) && (
              <Box w="140px" height="140px" borderRadius="5px">
                <Image
                  src={WatchLayoutLogo ? WatchLayoutLogo : watchTemplateLogo}
                  alt="Placeholder"
                  borderRadius="5px"
                  w="100%"
                  h="100%"
                  objectFit="contain"
                />
              </Box>
            )}
            <Heading
              fontSize="24px"
              fontWeight={500}
              color="textcolors.primary"
              lineHeight={1}
              mt="8px">
              {form.getValues('template.template.title')}
            </Heading>
            <Grid templateColumns="repeat(3, 1fr)" gap={1} mt="8px">
              {watchScoreValue && (
                <GridItem w="100%" h="10" backgroundColor="#c3c1c184">
                  <HStack
                    spacing={0}
                    justifyContent="space-between"
                    padding="8px"
                    w="100%">
                    <Text>Score</Text>
                    <Text>0.00%</Text>
                  </HStack>
                </GridItem>
              )}
              {watchFlaggedItems && (
                <GridItem w="100%" h="10" backgroundColor="#c3c1c184">
                  <HStack
                    spacing={0}
                    justifyContent="space-between"
                    padding="8px"
                    w="100%">
                    <Text>Flagged Items</Text>
                    <Text>0</Text>
                  </HStack>
                </GridItem>
              )}
              {watchAction && (
                <GridItem w="100%" h="10" backgroundColor="#c3c1c184">
                  <HStack
                    spacing={0}
                    justifyContent="space-between"
                    padding="8px"
                    w="100%">
                    <Text>Action</Text>
                    <Text>NA</Text>
                  </HStack>
                </GridItem>
              )}
            </Grid>
            {watchedReportStructure?.map((page, pageIndex) => (
              <Fragment key={pageIndex}>
                <Flex
                  alignItems="center"
                  backgroundColor="#c3c1c184"
                  padding="5px"
                  h="40px"
                  mt="8px">
                  <Text
                    fontSize="16px"
                    fontWeight={500}
                    color="textcolors.primary"
                    lineHeight={1}>
                    {page.title}
                  </Text>
                </Flex>
                <DndContext
                  key={page.pageId}
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={e => {
                    const {active, over} = e;

                    if (active.id !== over?.id) {
                      const oldIndex = page.children?.findIndex(
                        item => item.id === active.id,
                      );
                      const newIndex = page.children?.findIndex(
                        item => item.id === over?.id,
                      );
                      if (oldIndex !== undefined && newIndex !== undefined) {
                        let updatedChildrenArray = arrayMove(
                          page?.children ?? [],
                          oldIndex,
                          newIndex,
                        );
                        updatedChildrenArray = updatedChildrenArray.map(
                          (item, index) => ({
                            ...item,
                            orderIndex: index,
                          }),
                        );
                        handleDragEnd(updatedChildrenArray, {
                          pageIndex,
                        });
                      }
                    }
                  }}>
                  <SortableContext
                    items={page.children as any}
                    strategy={verticalListSortingStrategy}>
                    {page.children?.map((item, childIndex) => (
                      <DraggableItem
                        key={item.id}
                        id={item.id}
                        pageIndex={pageIndex}
                        sectionIndex={
                          item.type === 'SECTION' ? childIndex : undefined
                        }
                        questionIndex={
                          item.type === 'QUESTION' ? childIndex : undefined
                        }>
                        {item?.type === 'SECTION' ? (
                          <DraggableSection
                            {...item}
                            pageIndex={pageIndex}
                            sectionIndex={childIndex}
                            onItemsChanged={(arr: any) => {
                              handleDragEnd(arr, {
                                pageIndex,
                                childIndex,
                              });
                            }}
                          />
                        ) : (
                          <DraggableQuestion
                            {...item}
                            questionIndex={childIndex}
                            pageIndex={pageIndex}
                          />
                        )}
                      </DraggableItem>
                    ))}
                  </SortableContext>
                </DndContext>
              </Fragment>
            ))}
            <Box
              marginTop="20px"
              w={'100%'}
              display="flex"
              justifyContent="flex-end">
              <Button
                h="40px"
                borderRadius="8px"
                fontSize="14px"
                fontWeight={500}
                onClick={form.handleSubmit(data => {
                  console.log({data});
                  if (form.getValues('id')) {
                    ReportLayout.updateReportLayout(
                      form.getValues('id')?.toString()!,
                      {
                        ...data,
                        active: false,
                        templateId: Number(id),
                        name: form.getValues('name'),
                      },
                      () => {
                        toast({
                          description: 'Layout Updated successfully',
                          title: 'Success',
                          status: 'success',
                        });
                        form.reset({
                          ...form.getValues(),
                          showReportStructure: false,
                        });
                        setDisplayedComponent(<ReportLayoutHistory />);
                      },
                      err => {
                        toast({
                          description: err,
                          title: 'Error',
                          status: 'error',
                        });
                      },
                    );
                  } else {
                    ReportLayout.postReportLayout(
                      {
                        ...data,
                        active: false,
                        templateId: Number(id),
                        name: form.getValues('name'),
                      },
                      () => {
                        toast({
                          description: 'Layout saved successfully',
                          title: 'Success',
                          status: 'success',
                        });
                        form.reset({
                          ...form.getValues(),
                          showReportStructure: false,
                        });
                        setDisplayedComponent(<ReportLayoutHistory />);
                      },
                      err => {
                        toast({
                          description: err,
                          title: 'Error',
                          status: 'error',
                        });
                      },
                    );
                  }
                })}>
                {form.getValues('id') ? 'Update Changes' : 'Save Changes'}
              </Button>
            </Box>

            {/* <Button
            onClick={() => {
              console.log(form.getValues('reportStructure'));
            }}>
            Check Value
          </Button> */}
          </Container>
        ) : (
          <Box w="100%" h="100%" justifyItems="center" alignItems="center">
            <Text textAlign="center" fontSize="15px" fontWeight={500}>
              Please Select Layout
            </Text>
          </Box>
        )
      }
    </Fragment>
  );
}
