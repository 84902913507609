import {
  Avatar,
  Box,
  Button,
  Checkbox,
  HStack,
  Icon,
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";

import { useState } from "react";
import { BiTrash } from "react-icons/bi";
import { FiMoreVertical } from "react-icons/fi";
import { RiArchiveDrawerFill } from "react-icons/ri";
import { TbGitMerge } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { GroupForContact } from "../../Api/GroupForContact";
import { AddNewGroup } from "./Components/AddNewGroup";
import DeleteContactFromGroup from "./Components/DeleteContactFromGroup";
import { Archive } from "./Components/Modal/Archieve";
import { MergeModal } from "./Components/Modal/MergeModal";
import { SortingFilter } from "./Components/SortingFilter";

interface Props {
  contactData: GroupForContact.FetchGroupDataById | undefined;
  lastUpdated: number;
  setLastUpdated: React.Dispatch<React.SetStateAction<number>>;
  index: number;
}
export function TableForGroupData({
  contactData,
  setLastUpdated,
  lastUpdated,
  index,
}: Props) {
  const achieveModal = useDisclosure();

  const [deleteId, setDeleteId] = useState<string>("");
  const [contactId, setContactId] = useState<string[]>([]);

  const [contactName, setContactName] = useState<string>("");
  const [groupName, setGroupName] = useState<string>("");
  const deleteModal = useDisclosure();
  const mergeModal = useDisclosure();

  const [selectedId, setSelectedId] = useState<string[]>([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedRows, setCheckedRows] = useState<number[]>([]);
  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isAllChecked = event.target.checked;

    // Ensure allRowIds is defined as an empty array if contacts are not available
    const allRowIds = contactData?.contacts
      ? contactData.contacts.map((item, i) => i)
      : [];

    if (isAllChecked) {
      setCheckedRows(allRowIds); // Set to allRowIds if checked
      setSelectedId(
        contactData?.contacts ? contactData.contacts.map((item) => item.id) : []
      ); // Ensure it's an empty array if contacts are undefined
    } else {
      setCheckedRows([]);
      setSelectedId([]);
    }

    setIsCheckedAll(isAllChecked);
  };

  const handleCheckRow = (rowIndex: number) => {
    const updatedRows = [...checkedRows];
    const rowIndexInCheckedRows = updatedRows.indexOf(rowIndex);

    if (rowIndexInCheckedRows !== -1) {
      updatedRows.splice(rowIndexInCheckedRows, 1);
    } else {
      updatedRows.push(rowIndex);
    }

    setCheckedRows(updatedRows);
    setIsCheckedAll(updatedRows.length === contactData?.contacts.length);

    // Filter out undefined values
    const selectedIds = updatedRows
      .map((index) => contactData?.contacts?.[index]?.id)
      .filter((id): id is string => id !== undefined); // Type guard to ensure only strings are retained

    setSelectedId(selectedIds);
  };

  const navigate = useNavigate();

  return (
    <Stack
      _light={{
        bg: "customColor.white",
      }}
    >
      <Stack
        gap={0}
        // maxWidth={"1164px"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        borderRadius="4px"
        bg={"#fff"}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        borderWidth="1px"
        borderStyle={"solid"}
        borderColor="#f6f6f6"
        p={"0px"}
      >
        <Stack p={"25px"} gap={0} borderBottom={"1px solid #e5e7eb"}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px"}
          >
            Search
          </Text>
          <HStack>
            <Stack gap={0} flex={1}>
              <InputGroup ml={[0, "0"]}>
                <Input
                  type="text"
                  placeholder="Search"
                  borderRadius={"3px"}
                  h={["auto", "38px"]}
                  _focusVisible={{
                    border: "1px solid grey",
                  }}
                />
              </InputGroup>
            </Stack>
            <SortingFilter />
          </HStack>
        </Stack>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          p={"10px 25px"}
        >
          <Button
            h={"32px"}
            isDisabled={selectedId.length === 0 ? true : false}
            color={"red"}
            fontSize={"15px"}
            bgColor={"customColor.white"}
            leftIcon={<BiTrash />}
            fontWeight={"700"}
            variant={"ghost"}
            _hover={{
              bg: "#f2f3f4",
            }}
            _active={{
              bg: "#f2f3f4",
            }}
            onClick={() => {
              setDeleteId(contactData?.id ?? "");
              setContactId(selectedId);

              setGroupName(contactData?.name ?? "");

              deleteModal.onOpen();
            }}
          >
            Remove From Group
          </Button>
          <AddNewGroup
            selectedId={selectedId}
            lastUpdated={lastUpdated}
            setLastUpdated={setLastUpdated}
          />

          <Button
            h={"32px"}
            isDisabled={selectedId.length === 0 ? true : false}
            color={"#3454d1"}
            fontSize={"15px"}
            bgColor={"customColor.white"}
            leftIcon={<TbGitMerge />}
            fontWeight={"700"}
            variant={"ghost"}
            _hover={{
              bg: "#f2f3f4",
            }}
            _active={{
              bg: "#f2f3f4",
            }}
            onClick={() => {
              mergeModal.onOpen();
            }}
          >
            Merge
          </Button>

          <Button
            h={"32px"}
            color={"#3454d1"}
            fontSize={"15px"}
            bgColor={"customColor.white"}
            isDisabled={selectedId.length === 0 ? true : false}
            leftIcon={<RiArchiveDrawerFill />}
            fontWeight={"700"}
            variant={"ghost"}
            _hover={{
              bg: "#f2f3f4",
            }}
            _active={{
              bg: "#f2f3f4",
            }}
            onClick={() => achieveModal.onOpen()}
          >
            {index === 4 ? "Archive" : index === 3 ? "Unarchive" : ""}
          </Button>
        </Stack>

        <Archive
          isOpen={achieveModal.isOpen}
          onClose={achieveModal.onClose}
          selectedId={selectedId}
          setLastUpdated={setLastUpdated}
          index={index}
        />
        <MergeModal isOpen={mergeModal.isOpen} onClose={mergeModal.onClose} />
        <DeleteContactFromGroup
          isOpen={deleteModal.isOpen}
          onClose={deleteModal.onClose}
          id={deleteId}
          contact_id={contactId}
          groupName={groupName}
          contactName={contactName}
          setLastUpdated={setLastUpdated}
        />

        <Box overflowX="auto" p="0px">
          <Table variant="simple" w="100%" overflowX="auto">
            <Thead h={"36.8px"}>
              <Tr
                color={"customColor.black7"}
                _dark={{
                  color: "customColor.dark.150",
                }}
              >
                <Th
                  padding="8px 26px 8px 30px"
                  borderBottomColor="borders.tableBorder"
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  fontWeight={700}
                  whiteSpace="nowrap"
                  fontSize="12px"
                  textTransform="none"
                >
                  <Checkbox
                    size="md"
                    borderColor="#adb5bd"
                    bgColor="white"
                    isChecked={isCheckedAll}
                    onChange={handleCheckAll}
                  />
                </Th>
                {[
                  {
                    name: "Contact",
                    value: "75%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "You owe",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "They owe",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },

                  {
                    name: "",
                    value: "5%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                ].map((item, i) => {
                  return (
                    <Th
                      key={i}
                      padding={item.paddingValue}
                      borderBottomColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={700}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                      width={item.value}
                    >
                      {item.name}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody padding={0}>
              {contactData?.contacts.map((item, i) => {
                return (
                  <Tr
                    key={i}
                    _hover={{
                      bgColor: "#f2f3f8",
                      cursor: "pointer",
                    }}
                  >
                    <Td
                      padding="15px 15px 15px 30px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      fontWeight={500}
                      whiteSpace="nowrap"
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                      onClick={(e: any) => e.stopPropagation()}
                    >
                      <Checkbox
                        size="md"
                        borderColor="#adb5bd"
                        bgColor="white"
                        isChecked={checkedRows.includes(i)}
                        onChange={() => handleCheckRow(i)}
                        // Stop propagation here
                      />
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      <Stack direction={"row"}>
                        <Avatar
                          name={item.name}
                          borderRadius={"3px"}
                          size={"sm"}
                        />
                        <Stack gap={0}>
                          <Text
                            fontWeight={600}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                          >
                            {item.name}
                          </Text>
                        </Stack>
                      </Stack>
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {""}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {""}
                    </Td>

                    <Td
                      padding="15px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      <Menu>
                        <MenuButton
                          as={Button}
                          padding={0}
                          backgroundColor={"transparent"}
                          h={"34.5px"}
                          marginLeft={"8px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          display={{
                            lg: "flex",
                            md: "flex",
                            sm: "flex",
                            xs: "flex",
                          }}
                          borderRadius={"full"}
                          _hover={{
                            backgroundColor: "primary.50",
                          }}
                          _active={{
                            backgroundColor: "primary.100",
                          }}
                        >
                          <Icon as={FiMoreVertical} />
                        </MenuButton>
                        <MenuList style={{ minWidth: "140px" }}>
                          {[
                            {
                              name: "Edit",
                              onClick: () => {
                                navigate("/app/payroll/CreateContact", {
                                  state: {
                                    contactId: item.id,
                                  },
                                });
                              },
                            },
                            {
                              name:
                                index === 4
                                  ? "Archive"
                                  : index === 3
                                  ? "Unarchive"
                                  : "",
                              onClick: () => {
                                achieveModal.onOpen();
                                setSelectedId([item.id]);
                              },
                            },
                            {
                              name: "Remove from group",
                              onClick: () => {
                                setDeleteId(contactData.id);
                                setContactId([item.id]);
                                setContactName(item.name);
                                setGroupName(contactData.name);
                                deleteModal.onOpen();
                              },
                            },
                          ].map((menuItem, i) => {
                            return (
                              <MenuItem
                                key={i}
                                padding={"1px 0px 1px 20px"}
                                onClick={menuItem.onClick}
                                _light={{
                                  color: "customColor.black7",
                                }}
                                _dark={{
                                  color: "customColor.white",
                                }}
                              >
                                <Button
                                  variant={"ghost"}
                                  fontWeight={"normal"}
                                  fontSize={"13px"}
                                  padding={"0px 20px 0px 0px"}
                                  _light={{
                                    color: "customColor.black7",
                                  }}
                                  _dark={{
                                    color: "customColor.white",
                                  }}
                                  _hover={{ bg: "none" }}
                                >
                                  {menuItem.name}
                                </Button>
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Stack>
    </Stack>
  );
}
