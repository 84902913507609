import {Box, Spinner, Stack, useBoolean, useToast} from '@chakra-ui/react';
import moment from 'moment';
import {Fragment, useEffect, useState} from 'react';
import {ScheduleInspectionModule} from '../../../../../modules/Audit/ScheduleInspectionModule';
import WeekInspectionTag from './WeekInspectionTag';
interface WeeklyInspectionListProps {
  lastUpdate: number;
}
export default function WeeklyInspectionList({
  lastUpdate,
}: WeeklyInspectionListProps) {
  const [isLoading, setIsLoading] = useBoolean(true);
  const [scheduleInspection, setScheduleInspection] = useState<
    Record<string, ScheduleInspectionModule.GetWeeklyScheduledInspections[]>
  >({});
  const toast = useToast();

  useEffect(() => {
    ScheduleInspectionModule.FetchWeeklyScheduledInspections(
      data => {
        console.log('data', data);
        const weekData = data.reduce((prev: any, current) => {
          const keyDate = moment(current.startDate).format('DD-MM-YYYY');
          if (prev[keyDate]) {
            prev[keyDate].push(current);
          } else {
            prev[keyDate] = [current];
          }
          return prev;
        }, {});
        setScheduleInspection(weekData);
        console.log('weekData', weekData);
        setIsLoading.off();
      },
      error => {
        toast({
          title: 'Error',
          description: error,
          status: 'error',
        });
        setIsLoading.off();
      },
    );
  }, [lastUpdate]);

  return (
    <Box maxH="80vh" overflowY="scroll" py="5px">
      {isLoading ? (
        <Stack w="100%" h="30vh" justify="center" align="center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="primary.500"
            size="xl"
          />
        </Stack>
      ) : (
        <Stack spacing="16px">
          {Object.entries(scheduleInspection).map(([date, insp], index) => {
            return (
              <Fragment key={date}>
                {insp.map((ins, i) => (
                  <WeekInspectionTag
                    key={ins.startDate}
                    dueDate={ins.dueDate}
                    startDate={ins.startDate}
                    title={ins.title}
                    displayDate={i === 0}
                    templateId={ins.templateId}
                  />
                ))}
              </Fragment>
            );
          })}
        </Stack>
      )}
    </Box>
  );
}
