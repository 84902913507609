import {Box, HStack, Switch, Text} from '@chakra-ui/react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {Controller, useFormContext} from 'react-hook-form';
import {MdOutlineDragIndicator} from 'react-icons/md';
import {ReportLayout} from '../../../../../modules/Audit/ReportLayout';
import {generatePathForReportLayout} from '../../../../../modules/form-apis';

interface DraggableItemProps {
  children: React.ReactNode;
  id: number;

  pageIndex: number;
  sectionIndex?: number;
  questionIndex?: number;
}
export function DraggableItem({
  children,
  id,
  pageIndex,
  questionIndex,
  sectionIndex,
}: DraggableItemProps) {
  const {attributes, listeners, setNodeRef, transform, transition} =
    useSortable({id});
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const form = useFormContext<ReportLayout.ReportLayout>();

  return (
    <div ref={setNodeRef} style={style}>
      {/* <div>{id}</div> */}
      <Controller
        control={form.control}
        name={generatePathForReportLayout(
          {
            pageIndex,
            questionIndex,
            sectionIndex,
          },
          'display',
        )}
        render={({field}) => (
          <HStack w="100%">
            <MdOutlineDragIndicator
              {...attributes}
              {...listeners}
              color="#A0AEC0"
              size={20}
            />

            <Box
              opacity={field.value ? 1 : 0.5}
              flex={1}
              borderWidth={1}
              borderRadius="16px"
              px="16px"
              mt="8px"
              minH="50px"
              padding={2}>
              {children}
            </Box>
            <Box alignItems="center">
              <Text fontSize="13px" fontWeight={400} color="textcolors.primary">
                Display {String(field.value)}
              </Text>

              <Switch
                id="email-alerts"
                size={'sm'}
                // value={field.value}
                isChecked={field.value}
                onChange={e => {
                  field.onChange(e.target.checked);
                }}
              />
            </Box>
          </HStack>
        )}
      />
    </div>
  );
}
