import {
  Box,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { ReactSelect } from "../../../../../../Common/ReactSelect";
import { PeriodicalSchedule } from "../../../../../modules/Audit/PeriodicalSchedule";

export default function YearlySchedule() {
  const form = useFormContext<PeriodicalSchedule.AddPeriodicSchedule>();
  // const [value, setValue] = useState('day');
  const monthlyFrequencyDay = [
    { value: "FIRST", label: "First" },
    { value: "SECOND", label: "Second" },

    { value: "THIRD", label: "Third" },
    { value: "FOURTH", label: "Fourth" },
    { value: "FIFTH", label: "Last" },
  ];
  const YearlyFrequencyMonth = [
    { value: "JANUARY", label: "January" },
    { value: "FEBRUARY", label: "February" },

    { value: "MARCH", label: "March" },
    { value: "APRIL", label: "April" },
    { value: "MAY", label: "May" },
    { value: "JUNE", label: "June" },
    { value: "JULY", label: "July" },
    { value: "AUGUST", label: "August" },
    { value: "SEPTEMBER", label: "September" },
    { value: "OCTOBER", label: "October" },
    { value: "NOVEMBER", label: "November" },
    { value: "DECEMBER", label: "December" },
  ];
  const daysOfWeek = [
    { value: "SUNDAY", label: "Sunday" },
    { value: "MONDAY", label: "Monday" },
    { value: "TUESDAY", label: "Tuesday" },
    { value: "WEDNESDAY", label: "Wednesday" },
    { value: "THURSDAY", label: "Thursday" },
    { value: "FRIDAY", label: "Friday" },
    { value: "SATURDAY", label: "Saturday" },
  ];
  return (
    <Box>
      <HStack mb="4px">
        <Text>Recur every</Text>
        <Controller
          control={form.control}
          name="properties.annually.every"
          render={({ field, fieldState }) => (
            <NumberInput
              w="100px"
              min={1}
              onChange={(valueString) => field.onChange(parseInt(valueString))}
              value={field.value || 1}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          )}
        />
        <Text>year(s)</Text>
      </HStack>
      <HStack h="100%">
        <Controller
          control={form.control}
          name="properties.selection"
          rules={{
            onChange(event) {
              event.target.value === "CUSTOM_YEAR"
                ? form.setValue("properties.annually", {
                    month: "JANUARY",
                    every: 1,
                    monthDate: undefined,
                  })
                : form.setValue("properties.annually", {
                    month: "JANUARY",
                    every: 1,
                  });
            },
          }}
          render={(selection) => (
            <RadioGroup {...selection.field} h="100%">
              <Stack>
                <HStack>
                  <Radio value="NO_OF_YEAR" mb="8px">
                    On:
                  </Radio>
                  <Box minW="150px">
                    <Controller
                      control={form.control}
                      name="properties.annually.month"
                      render={({ field, fieldState }) => (
                        <ReactSelect
                          key={`properties.annually.month.-${selection.field.value}`}
                          isDisabled={selection.field.value !== "NO_OF_YEAR"}
                          withValidation
                          options={YearlyFrequencyMonth}
                          value={
                            field.value
                              ? YearlyFrequencyMonth.find(
                                  (option) => option.value === field.value
                                )
                              : undefined
                          }
                          onChange={(val: any) => {
                            field.onChange(val?.value);
                          }}
                          getOptionLabel={(option: any) => option.label}
                          getOptionValue={(option: any) => option.value}
                        />
                      )}
                    />
                  </Box>
                  <Controller
                    control={form.control}
                    name="properties.annually.monthDate"
                    render={(monthDate) => (
                      <NumberInput
                        key={`properties.annually.monthDate-${selection.field.value}`}
                        w="75px"
                        min={1}
                        max={31}
                        mb="8px"
                        isDisabled={selection.field.value !== "NO_OF_YEAR"}
                        {...monthDate.field}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    )}
                  />
                </HStack>
                <HStack>
                  <Radio value="CUSTOM_YEAR">On the:</Radio>
                  <Box minW="120px">
                    <Controller
                      control={form.control}
                      name="properties.annually.dayPosition"
                      render={(position) => (
                        <ReactSelect
                          key={`properties.annually.dayPosition-${selection.field.value}`}
                          isDisabled={selection.field.value !== "CUSTOM_YEAR"}
                          withValidation
                          options={monthlyFrequencyDay}
                          value={
                            position.field.value
                              ? monthlyFrequencyDay.find(
                                  (option) =>
                                    option.value === position.field.value
                                )
                              : undefined
                          }
                          onChange={(val: any) => {
                            position.field.onChange(val?.value);
                          }}
                          getOptionLabel={(option: any) => option.label}
                          getOptionValue={(option: any) => option.value}
                        />
                      )}
                    />
                  </Box>
                  <Box minW="150px">
                    <Controller
                      control={form.control}
                      name="properties.annually.day"
                      render={(day) => (
                        <ReactSelect
                          key={`properties.annually.day-${selection.field.value}`}
                          isDisabled={selection.field.value !== "CUSTOM_YEAR"}
                          withValidation
                          options={daysOfWeek}
                          value={
                            day.field.value
                              ? daysOfWeek.find(
                                  (option) => option.value === day.field.value
                                )
                              : undefined
                          }
                          onChange={(val: any) => {
                            day.field.onChange(val?.value);
                          }}
                          getOptionLabel={(option: any) => option.label}
                          getOptionValue={(option: any) => option.value}
                        />
                      )}
                    />
                  </Box>

                  <span>of</span>
                  <Box minW="150px">
                    <Controller
                      control={form.control}
                      name="properties.annually.month"
                      render={(month) => (
                        <ReactSelect
                          key={`properties.annually.month-${selection.field.value}`}
                          isDisabled={selection.field.value !== "CUSTOM_YEAR"}
                          withValidation
                          options={YearlyFrequencyMonth}
                          value={
                            month.field.value
                              ? YearlyFrequencyMonth.find(
                                  (option) => option.value === month.field.value
                                )
                              : undefined
                          }
                          onChange={(val: any) => {
                            month.field.onChange(val?.value);
                          }}
                          getOptionLabel={(option: any) => option.label}
                          getOptionValue={(option: any) => option.value}
                        />
                      )}
                    />
                  </Box>
                </HStack>
              </Stack>
            </RadioGroup>
          )}
        />

        <Box></Box>
      </HStack>
    </Box>
  );
}
