import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { Fragment } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ReactSelect } from "../../../../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../../../../Hooks/BusinessContext";
import { Inspection } from "../../../../../../modules/Audit";
import { PeriodicalSchedule } from "../../../../../../modules/Audit/PeriodicalSchedule";

interface UpdateStatusModalProps {
  isOpen: boolean;
  onClose: () => void;
  //   setUpdate: (timestamp: number) => void;
  inspectionsList: Inspection.InpsectionMetaPayload[];
  statusOptions: { label: string; value: number }[];
  setLastUpdate: (timestamp: number) => void;
}
export default function UpdateStatusModal({
  isOpen,
  onClose,
  inspectionsList,
  statusOptions,
  setLastUpdate,
}: //   setUpdate,
UpdateStatusModalProps) {
  const form = useFormContext<Inspection.PostPeriodicalActivities>();
  const { allUsers } = useBusinessContext();
  const toast = useToast();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change Status</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Status is required",
                },
              }}
              name="status"
              control={form.control}
              render={({ field, fieldState }) => (
                <ReactSelect
                  key={field.value}
                  {...field}
                  label="Status"
                  withValidation
                  isInvalid={fieldState.invalid}
                  ErrorMessage="Please select status"
                  placeholder="Select Status"
                  options={statusOptions}
                  value={
                    field.value !== undefined && field.value !== null
                      ? {
                          value: field.value,
                          label: statusOptions.find(
                            (st) => st.value === field.value
                          )?.label,
                        }
                      : null
                  }
                  onChange={(val: any) => {
                    field.onChange(val?.value);
                    if ([0, 2].includes(val?.value)) {
                      form.setValue("inspectionId", undefined);
                      form.setValue("completedBy", null);
                    }
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              )}
            />
            {form.watch("status") === 1 && (
              <Fragment>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Inspection is required",
                    },
                  }}
                  name="inspectionId"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      label="Inspection"
                      withValidation
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Please select Inspection"
                      placeholder="Select Inspection"
                      options={inspectionsList}
                      value={
                        field.value
                          ? {
                              id: field.value,
                              inspectedOn:
                                inspectionsList.find(
                                  (ins) => ins.id === field.value
                                )?.inspectedOn ?? "",
                              inspectionDate:
                                inspectionsList.find(
                                  (ins) => ins.id === field.value
                                )?.templateMeta.inspectionDate ?? "",
                              site:
                                inspectionsList.find(
                                  (ins) => ins.id === field.value
                                )?.templateMeta.site ?? "",
                              person:
                                inspectionsList.find(
                                  (ins) => ins.id === field.value
                                )?.templateMeta.person ?? "",
                            }
                          : undefined
                      }
                      onChange={(val: any) => {
                        field.onChange(val?.id);
                      }}
                      getOptionLabel={(option: any) => {
                        // return `${option?.preferredTitle} (${option?.access})`;
                        return (
                          (option?.inspectionDate
                            ? moment(option.inspectionDate).format(
                                "DD MMM YYYY"
                              )
                            : moment(option.inspectedOn).format(
                                "DD MMM YYYY"
                              )) +
                          " " +
                          (option?.site ? option.site : "") +
                          " " +
                          (option?.person ? option.person : "")
                        );
                      }}
                      getOptionValue={(option: any) => option.id}
                    />
                  )}
                />
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Employee is required",
                    },
                  }}
                  name="completedBy"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      label="Employees"
                      withValidation
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Please select User"
                      placeholder="Select User"
                      options={allUsers.map((user) => {
                        return {
                          id: user.user.id,
                          firstName: user.user.firstName,
                          lastName: user.user.lastName,
                          access: user.accessLevel,
                        };
                      })}
                      value={
                        field.value
                          ? {
                              id: field.value,
                              firstName:
                                allUsers.find(
                                  (user) => user?.user?.id === field.value
                                )?.user?.firstName ?? "",
                              lastName:
                                allUsers.find(
                                  (user) => user?.user?.id === field.value
                                )?.user?.lastName ?? "",
                              access:
                                allUsers.find(
                                  (user) => user?.user?.id === field.value
                                )?.accessLevel ?? "",
                            }
                          : undefined
                      }
                      onChange={(val: any) => {
                        field.onChange(val?.id);
                      }}
                      getOptionLabel={(option: any) => {
                        return `${option?.firstName} ${option?.lastName} (${option?.access})`;
                      }}
                      getOptionValue={(option: any) => option.id}
                    />
                  )}
                />
              </Fragment>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button borderRadius="5px" variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            borderRadius="5px"
            onClick={form.handleSubmit((data) => {
              PeriodicalSchedule.UpdatePeriodicEventStatus(
                data,
                () => {
                  toast({
                    title: "Success",
                    description:
                      "Periodic Schedule status Updated successfully",
                    status: "success",
                  });
                  setLastUpdate(Date.now());
                  onClose();
                },
                (error) => {
                  toast({
                    title: "Error",
                    description: error,
                    status: "error",
                  });
                }
              );
            })}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
