import { axiosInstanceTime } from "./axiosInstanceTime";

declare module NotificationSettingModule {
  export enum EventType {
    Shift_Created = "Shift_Created",
    Shift_Updated = "Shift_Updated",
    Shift_Deleted = "Shift_Deleted",
    Shift_Transfer = "Shift_Transfer",
    Shift_Start_Reminder = "Shift_Start_Reminder",
    Shift_End_Reminder = "Shift_End_Reminder",
  }
  export enum NotificationType {
    SMS = "SMS",
    Email = "Email",
    Push = "Push",
  }
  type notificationData = {
    id?: string;
    eventType: string;
    types: Array<string>;
    isImmediate: boolean;
    interval?: number;
    usersToNotify?: Array<string>;
    isActive: boolean;
    createdAt?: string;
  };

  // type Notification = {
  //   id: string;
  //   notify: keyof typeof NotificationType;
  //   employees: string[];
  //   locations: string[];
  //   reminders: number[];
  //   message: string;
  //   turnOn: boolean;
  //   createdAt: number;
  //   title: string;
  // };
}

module NotificationSettingModule {
  const API_BASE_URL = "notification-settings";
  export function Create(
    data: notificationData,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .post(API_BASE_URL, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function Fetch(
    success: (response: Array<notificationData>) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .get(API_BASE_URL)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function Update(
    id: string,
    data: Partial<notificationData>,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .patch(`${API_BASE_URL}/${id}`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function Delete(
    id: string,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .delete(`${API_BASE_URL}/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchById(
    id: string,
    success: (response: notificationData) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .get(`${API_BASE_URL}/${id}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}

export { NotificationSettingModule };
