import { Box, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { Inspection, Template } from "../../../modules/Audit";
import { useInspectionContext } from "../hooks/InspectionContext";
import { useAuditQuestionContainer } from "./AuditQuestionContainer";

interface MultipleChoiceProps {
  options: Array<Template.MultiChoiceOption>;
}
export default function MultipleChoice({ options }: MultipleChoiceProps) {
  const { getItemField, onScoreChanged, onReducePageScore } =
    useAuditQuestionContainer();
  const template = useFormContext();
  const { setIsSaving } = useInspectionContext();
  const valueController = useController({
    control: template.control,
    name: getItemField("question.questionValue"),
  });

  const maxScore = template.getValues(getItemField("question.score"));
  const [selectedOption, setSelectedOption] =
    useState<Template.MultiChoiceOption>();

  useEffect(() => {
    if (valueController.field.value) {
      let option = options.find(
        (op) => op.value?.toString() === valueController.field.value
      );
      setSelectedOption(option);
    }
    template.setValue(getItemField("question.questionValue"), 0);
  }, []);

  useEffect(() => {
    const currentScore = template.getValues(
      getItemField("question.questionScore")
    );
    if (selectedOption) {
      template.setValue(
        getItemField("question.questionColor"),
        selectedOption?.color
      );
      template.setValue(
        getItemField("question.questionValue"),
        selectedOption?.value
      );
      template.setValue(
        getItemField("question.flagged"),
        selectedOption?.flagged
      );
      if (selectedOption?.score) {
        template.setValue(
          getItemField("question.questionScore"),
          +selectedOption?.score
        );
        onScoreChanged?.(+selectedOption.score, currentScore);
        onReducePageScore(
          {
            questionPath: getItemField(),
            score: +maxScore,
          },
          "REMOVE"
        );
      } else {
        template.setValue(getItemField("question.questionScore"), undefined);
        onScoreChanged?.(0, currentScore);
        onReducePageScore(
          {
            questionPath: getItemField(),
            score: +maxScore,
          },
          "ADD"
        );
      }
    } else {
      template.setValue(getItemField("question.questionColor"), undefined);
      template.setValue(getItemField("question.questionValue"), "NOT_SELECTED");
      template.setValue(getItemField("question.flagged"), undefined);
      template.setValue(getItemField("question.questionScore"), undefined);
      onScoreChanged?.(0, currentScore);
      onReducePageScore(
        {
          questionPath: getItemField(),
          score: +maxScore,
        },
        "REMOVE"
      );
    }

    const answerId = template.getValues(getItemField("question.answerId"));
    const type = template.getValues(getItemField("question.type"));
    const inspectionMetaID = template.getValues("inspection.id");
    const score = template.getValues(getItemField("question.questionScore"))
      ? template.getValues(getItemField("question.questionScore"))
      : null;
    const color = template.getValues(getItemField("question.questionColor"));
    const questionValue = template.getValues(
      getItemField("question.questionValue")
    );
    setIsSaving(true);
    Inspection.CachingInspection(
      answerId,
      {
        type: type,
        inspection_meta_id: inspectionMetaID,
        question_score: score,
        question_color: color,
        question_value: questionValue,
      },
      () => {
        setIsSaving(false);
      },
      () => {
        setIsSaving(false);
      }
    );
  }, [selectedOption]);

  return (
    <SimpleGrid columns={2} spacing={1}>
      {options
        .sort((a, b) => a.orderIndex - b.orderIndex)
        .map((option) => (
          <Box
            textAlign="center"
            cursor="pointer"
            border="1px solid"
            borderWidth="1px"
            borderStyle="solid"
            borderColor={
              selectedOption?.value === option.value
                ? selectedOption.color
                : "borders.accent"
            }
            borderRadius="8px"
            color="primary.500"
            fontSize="14px"
            fontWeight={400}
            bg={
              selectedOption?.value === option.value
                ? selectedOption.color
                : "white"
            }
            textColor={
              selectedOption?.value === option.value ? "white" : "primary.500"
            }
            px={5}
            py={3}
            onClick={() => {
              if (option.value === selectedOption?.value) {
                setSelectedOption(undefined);
              } else {
                setSelectedOption(option);
              }
            }}
          >
            {option.value}
          </Box>
        ))}
    </SimpleGrid>
  );
}
