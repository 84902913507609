import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Checkbox,
  Container,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { StatutoryBenefitsApi } from "../../Api/StautoryBenefitsApi";
import Header from "./Header";

export function SuperannuationPaymentDetails() {
  const { employeesList } = useBusinessContext();
  let location = useLocation();
  const [selectedEmployee, setSelectedEmployee] = useState<string>();
  const [selectedEmployeeGroup, setSelectedEmployeeGroup] = useState("");

  const employeeOptions = [{ value: "all", label: "all" }];

  const fundOptions: Array<{ value: string; label: string }> = [
    {
      value:
        StatutoryBenefitsApi.PayrollPayItemRetirementCategoryEnum
          .EMPLOYER_PROVIDENT_FUND,
      label: "Provident Fund",
    },
    {
      value: StatutoryBenefitsApi.PayrollPayItemRetirementCategoryEnum.GRATUITY,
      label: "Gratuity",
    },
    {
      value:
        StatutoryBenefitsApi.PayrollPayItemRetirementCategoryEnum.EMPLOYER_EOBI,
      label: "EOBI",
    },
    {
      value:
        StatutoryBenefitsApi.PayrollPayItemRetirementCategoryEnum
          .SOCIAL_SECURITY,
      label: "Social Security",
    },
  ];
  const [selectedFundTypeOption, setSelectedFundTypeOption] =
    useState<string>();
  const [selectedFilterStartDate, setSelectedFilterStartDate] =
    useState<string>();
  const [selectedFilterEndDate, setSelectedFilterEndDate] = useState<string>();
  const [lastUpdated, setLastUpdated] = useState<number>(0);

  const navigate = useNavigate();
  const toast = useToast();
  const [statutoryBenefits, setStatutoryBenefits] =
    useState<StatutoryBenefitsApi.StatutoryBenefitsData>();
  const status = location?.state?.status;
  useEffect(() => {
    StatutoryBenefitsApi.PostStatutoryBenefits(
      {
        status: [status],
        startDate: selectedFilterStartDate,
        endDate: selectedFilterEndDate,
        empId: selectedEmployee,
        type: selectedFundTypeOption,
      },

      (success) => {
        setStatutoryBenefits(success);
        toast({
          title: "Statutory Benefits Updated",
          status: "success",
        });
      },
      (errorMessage) => {
        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
        });
      }
    );
  }, [lastUpdated]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]); // Array to store selected employee IDs
  const [selectAll, setSelectAll] = useState(false); // Whether the header checkbox is checked
  const headerCheckboxRef = useRef<HTMLInputElement>(null); // Ref for header checkbox
  const [selectedCount, setSelectedCount] = useState(0); // Count of selected checkboxes
  const [totalAmount, setTotalAmount] = useState(0); // Total sum of selected amounts

  // Handle the header checkbox click
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    if (newSelectAll) {
      // Select all: Add all employee IDs to selectedIds and sum the amounts
      const allIds = statutoryBenefits?.data.map((item) => item.id) || [];
      setSelectedIds(allIds);

      // Calculate total sum of selected amounts
      const total =
        statutoryBenefits?.data.reduce((sum, item) => sum + item.amount, 0) ||
        0;
      setTotalAmount(total);
    } else {
      // Deselect all
      setSelectedIds([]);
      setTotalAmount(0); // Reset total amount
    }
  };

  // Handle individual checkbox click in the table body
  const handleCheckboxClick = (id: string, amount: number) => {
    if (selectedIds.includes(id)) {
      // If already selected, remove it and subtract amount
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
      setTotalAmount(totalAmount - amount);
    } else {
      // Add the id to the array and add amount
      setSelectedIds([...selectedIds, id]);
      setTotalAmount(totalAmount + amount);
    }
  };

  // Effect to update header checkbox state and selected count
  useEffect(() => {
    if (headerCheckboxRef.current) {
      const allChecked =
        statutoryBenefits?.data.every((item) =>
          selectedIds.includes(item.id)
        ) || false;
      const someChecked = selectedIds.length > 0 && !allChecked;

      setSelectAll(allChecked);
      headerCheckboxRef.current.indeterminate = someChecked;
    }

    // Update the selected count
    setSelectedCount(selectedIds.length);
  }, [selectedIds, statutoryBenefits]);

  const FormSubmitForApproval = useForm<StatutoryBenefitsApi.SubmitForApproval>(
    {
      defaultValues: {
        ids: selectedIds,
      },
    }
  );
  useEffect(() => {
    if (FormSubmitForApproval) {
      FormSubmitForApproval.setValue("ids", selectedIds);
    }
  }, [selectedIds]);

  return (
    <Stack
      h="calc(100vh - 70px)"
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      gap={0}
    >
      <Container
        h="64px"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        maxW="100%"
        justifyContent={"center"}
        borderBottom={"1px solid #cfd2d4"}
        padding={"0px 20px"}
      >
        <Breadcrumb
          separator={<ChevronRightIcon color="gray.500" />}
          paddingTop={"5px"}
        >
          <BreadcrumbItem>
            <BreadcrumbLink
              href="/app/payroll/statuary-benefits"
              fontSize={"11px"}
              color={"primary.950"}
            >
              Statuary Benefits
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              href=""
              fontSize={"11px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              padding={"0px 20px 0px 0px"}
            >
              New Payment
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Heading
          fontWeight={"bold"}
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.white",
          }}
          fontSize={"24px"}
          margin={"0px 4px 0px 0px"}
        >
          Statuary Benefits Payment Details
        </Heading>
        {/* <Divider padding="10px" orientation="horizontal" /> */}
      </Container>

      <Stack
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
        padding={"20px"}
        borderBottom={"1px solid #dadddf"}
      >
        <Container
          justifyContent="center"
          alignItems="center"
          p="0px"
          maxW={"container.xl"}
          borderRadius="md"
        >
          <Stack
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
            borderRadius={"4px"}
            paddingTop={"10px"}
            paddingLeft={"10px"}
            margin={"0px 1px 20px"}
            border="1px solid #f6f6f6"
            padding={"7.52px 12px"}
            boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
            gap={0}
          >
            <SimpleGrid
              columns={{
                xs: 3,
                md: 4,
                lg: 5,
              }}
              spacing={3}
            >
              <Stack flex={3} gap={0}>
                <Text
                  fontSize={"12px"}
                  margin={"0px 0px 5px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.250",
                  }}
                  fontWeight={"bold"}
                >
                  Employee/s
                </Text>

                <ReactSelect
                  placeholder=""
                  options={employeesList!.map((employee: any) => ({
                    value: employee.id,
                    label: `${employee.firstName} ${employee.lastName}`,
                  }))}
                  value={
                    selectedEmployee
                      ? {
                          value: selectedEmployee,
                          label: `${
                            employeesList!.find(
                              (employee) => employee.id === selectedEmployee
                            )?.firstName
                          } ${
                            employeesList!.find(
                              (employee) => employee.id === selectedEmployee
                            )?.lastName
                          }`,
                        }
                      : ""
                  }
                  onChange={(newValue: any) => {
                    setSelectedEmployee(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Stack>

              <Stack flex={3} gap={0}>
                <Text
                  fontSize={"12px"}
                  margin={"0px 0px 5px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.250",
                  }}
                  fontWeight={"bold"}
                >
                  Type
                </Text>
                <ReactSelect
                  placeholder=""
                  options={fundOptions}
                  value={fundOptions.find(
                    (option) => option.value === selectedFundTypeOption
                  )}
                  onChange={(newValue: any) => {
                    setSelectedFundTypeOption(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Stack>
              <Stack flex={3} gap={0}>
                <Text
                  fontSize={"12px"}
                  margin={"0px 0px 5px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.250",
                  }}
                  fontWeight={"bold"}
                >
                  Employee Group
                </Text>
                <ReactSelect
                  placeholder=""
                  options={employeeOptions}
                  value={selectedEmployeeGroup}
                  onChange={(newValue: any) => {
                    setSelectedEmployeeGroup(newValue);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Stack>
              <Stack flex={2} gap={0}>
                <CustomInput
                  withValidation
                  input={{
                    isInvalid: false,
                    w: "100%",
                    fontSize: "12px",
                    value: selectedFilterStartDate,
                    variant: "outline",
                    type: "date",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc3",
                    height: "38px",
                    borderRadius: "4px",
                    onChange: (e: any) => {
                      setSelectedFilterStartDate(e.target.value);
                    },
                  }}
                  placeholder="dd/mm/yyyy"
                  label="Due Date Start"
                  labelProps={{
                    fontSize: "12px",
                    fontWeight: "700",
                    margin: "0px 0px 5px",
                    color: "#444",
                  }}
                />
              </Stack>
              <Stack flex={2} gap={0}>
                <CustomInput
                  withValidation
                  input={{
                    isInvalid: false,
                    w: "100%",
                    fontSize: "12px",
                    variant: "outline",
                    value: selectedFilterEndDate,
                    type: "date",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc3",

                    height: "38px",
                    borderRadius: "4px",
                    onChange: (e: any) => {
                      setSelectedFilterEndDate(e.target.value);
                    },
                  }}
                  placeholder="dd/mm/yyyy"
                  label="Due Date Ending"
                  labelProps={{
                    fontSize: "12px",
                    fontWeight: "700",
                    margin: "0px 0px 5px",
                    color: "#444",
                  }}
                />
              </Stack>
            </SimpleGrid>

            <Stack
              alignItems={"flex-end"}
              padding={"10px 0px 10px 10px"}
              flex={1}
            >
              <Stack direction={"row"}>
                <Button
                  marginTop={"10px"}
                  backgroundColor={"transparent"}
                  borderRadius={"3px"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  variant={"solid"}
                  textColor={"customColor.black7"}
                  padding={"12px 16px"}
                  marginRight={"5px"}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    setSelectedFundTypeOption("");
                    setSelectedFilterStartDate("");
                    setSelectedFilterEndDate("");
                    setSelectedEmployee("");
                    setSelectedEmployeeGroup("");
                  }}
                >
                  Reset Filters
                </Button>
                <Button
                  marginTop={"10px"}
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  onClick={() => {
                    setLastUpdated(Date.now());
                  }}
                >
                  Apply Filter
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Stack>

      <Header
        status={location?.state?.status}
        liability={statutoryBenefits?.totalAmount}
        total={totalAmount}
      />

      <Stack paddingLeft={"20px"} paddingRight={"20px"} marginBottom={"20px"}>
        <Container maxW={"container.xl"} p={0}>
          <Text
            fontSize={"16px"}
            _light={{
              color: "customColor.black7",
            }}
            fontWeight={"700"}
            marginBottom={"16px"}
          >
            {selectedCount ?? 0} payment Included
          </Text>
          <Stack
            _light={{
              color: "customColor.black7",
              bgColor: "customColor.white",
            }}
            fontSize={"16px"}
            _dark={{
              bgColor: "customColor.dark.50",
              color: "customColor.dark.250",
            }}
            gap={0}
            border="1px solid #f6f6f6"
            borderRadius="4px"
            boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          >
            <Box overflowX="auto" p="0px">
              <Table variant="simple" w="100%" overflowX="auto">
                <Thead h={"36.8px"}>
                  <Tr
                    borderTopWidth={"1px"}
                    borderTopColor="borders.tableBorder"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                  >
                    <Th
                      padding="8px 26px 8px 30px"
                      borderBottomColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={700}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                    >
                      <Checkbox
                        ref={headerCheckboxRef}
                        size="md"
                        borderColor="#adb5bd"
                        bgColor="white"
                        isChecked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </Th>
                    {[
                      {
                        name: "Period",
                        value: "30%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                      {
                        name: "Due Date",
                        value: "20%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                      {
                        name: "Employee",
                        value: "25%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                      {
                        name: "Type",
                        value: "15%",
                        paddingValue: "8px 26px 8px 15px",
                      },

                      {
                        name: "Amount",
                        value: "10%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                    ].map((item, i) => {
                      return (
                        <Th
                          key={i}
                          padding={item.paddingValue}
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                          width={item.value}
                        >
                          {item.name}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {statutoryBenefits &&
                    statutoryBenefits.data.map((item, i) => {
                      return (
                        <Tr
                          key={i}
                          _hover={{
                            bgColor: "#f2f3f8",
                            cursor: "pointer",
                          }}
                        >
                          <Td
                            padding="15px 15px 15px 30px"
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            fontWeight={500}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Checkbox
                              size="md"
                              borderColor="#adb5bd"
                              bgColor="white"
                              isChecked={selectedIds.includes(item.id)}
                              onChange={() =>
                                handleCheckboxClick(item.id, item.amount)
                              }
                            />
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Text>{`${
                              item.payrun.payrollCalendar.name
                            } ending ${moment(item.payrun.endDate).format(
                              "DD MMM YYYY"
                            )}`}</Text>
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Text>
                              {moment(item.dueDate).format("DD MMM YYYY")}
                            </Text>
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Text>{`${item.employee.firstName} ${item.employee.lastName}`}</Text>
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Text>
                              {item.type ===
                              StatutoryBenefitsApi
                                .PayrollPayItemRetirementCategoryEnum
                                .EMPLOYER_EOBI
                                ? "Employer Eobi"
                                : item.type ===
                                  StatutoryBenefitsApi
                                    .PayrollPayItemRetirementCategoryEnum
                                    .EMPLOYER_PROVIDENT_FUND
                                ? "Employer Provident Fund"
                                : item.type ===
                                  StatutoryBenefitsApi
                                    .PayrollPayItemRetirementCategoryEnum
                                    .GRATUITY
                                ? "Gratuity"
                                : item.type ===
                                  StatutoryBenefitsApi
                                    .PayrollPayItemRetirementCategoryEnum
                                    .SOCIAL_SECURITY
                                ? "Social Security"
                                : ""}
                            </Text>
                          </Td>

                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Text>{(item.amount ?? "0").toLocaleString()}</Text>
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </Box>
          </Stack>
          <HStack alignItems={"center"} justifyContent={"flex-end"} gap={0}>
            <Stack
              alignItems={"center"}
              justifyContent={"flex-end"}
              direction={"row"}
              paddingTop={"20px"}
              paddingLeft={"20px"}
              paddingBottom={"20px"}
            >
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                borderRadius={"3px"}
                h={"32px"}
                borderWidth={"1px"}
                marginRight={"8px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                // onClick={() => {
                //   navigate("/app/payroll/statuary-benefits");
                //   console.log(selectedIds);
                // }}
                onClick={(e) => {
                  FormSubmitForApproval.handleSubmit(
                    (data) => {
                      StatutoryBenefitsApi.SubmitForApprovalApi(
                        data,
                        (success) => {
                          toast({
                            title: "Status Changed",
                            status: "success",
                          });
                          setLastUpdated(Date.now());
                          navigate("/app/payroll/statuary-benefits");
                        },
                        (error) => {
                          toast({
                            title: "Change Status Failed",
                            description: error,
                            status: "error",
                          });
                        }
                      );
                    },
                    (error) => {
                      toast({
                        title: "Status Change Failed",
                        status: "error",
                      });
                    }
                  )(e);
                }}
              >
                Submit for approval
              </Button>
            </Stack>

            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"32px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => {
                navigate("/app/payroll/statuary-benefits");
              }}
            >
              Cancel
            </Button>
          </HStack>
        </Container>
      </Stack>
    </Stack>
  );
}
