import {
  Button,
  ButtonGroup,
  Center,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { FiSettings } from "react-icons/fi";
import { PeriodicalEvents } from "../../../../modules/Audit/PeriodicalEvents";
import EventsModal from "../Modals/EventsModal";
interface ManagePeriodicalEventsProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  setUpdate: (timestamp: number) => void;
  update: number;
  allEvents: PeriodicalEvents.GetEvents[];
  isLoading: boolean;
}
export default function ManagePeriodicalEvents({
  isOpen,
  onClose,
  onOpen,
  setUpdate,

  allEvents,
  isLoading,
}: ManagePeriodicalEventsProps) {
  const toast = useToast();

  const deleteModal = useDisclosure();
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);

  const form = useFormContext();
  return (
    <Stack px={{ sm: "0px", md: "32px", xl: "50px" }} py="32px">
      <TableContainer
        border="1px solid"
        borderRadius="5px"
        borderColor="borders.accent"
      >
        <Table variant="simple">
          <Thead bg="backgrounds.primary">
            <Tr>
              {/* <Th w="40px" p="8px" borderColor="borders.accent">
              <Checkbox
                w="21px"
                h="21px"
                borderColor="grey"
                isChecked={
                  data.length > 0 && data.length === selectedScheduleIds.length
                }
                onChange={e => {
                  if (e.target.checked) {
                    const allScheduleIds = data.map(temp => temp.id);
                    setSelectedScheduleIds(allScheduleIds);
                    schedulebottom.onOpen();
                  } else {
                    setSelectedScheduleIds([]);
                  }
                }}
              />
            </Th> */}
              <Th
                maxW="400px"
                py="8px"
                px="16px"
                fontSize="13px"
                fontWeight={400}
                lineHeight="1rem"
                color="textcolors.primary"
                borderColor="borders.accent"
              >
                Event Title
              </Th>
              <Th
                maxW="200px"
                py="8px"
                px="16px"
                fontSize="13px"
                fontWeight={400}
                lineHeight="1rem"
                color="textcolors.primary"
                borderColor="borders.accent"
              >
                Code
              </Th>
              <Th
                w="200px"
                py="8px"
                px="16px"
                fontSize="13px"
                fontWeight={400}
                lineHeight="1rem"
                color="textcolors.primary"
                borderColor="borders.accent"
              >
                Description
              </Th>
              <Th
                maxW="200px"
                py="8px"
                px="16px"
                fontSize="13px"
                fontWeight={400}
                lineHeight="1rem"
                color="textcolors.primary"
                textDecoration="underline"
                borderColor="borders.accent"
              ></Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <Tr>
                <Td
                  px="10px"
                  py="8px"
                  borderColor={"borders.accent"}
                  colSpan={6}
                >
                  <Center>
                    <Spinner size="lg" color="secondary.500" />
                  </Center>
                </Td>
              </Tr>
            ) : allEvents.length > 0 ? (
              allEvents.map((ev, i) => {
                return (
                  <Tr
                    onClick={() => {
                      //   onRowClick(inspection);
                    }}
                    backgroundColor="white"
                  >
                    {/* <Td
                    maxW="40px"
                    py="16px"
                    px="8px"
                    borderTop="1px"
                    borderColor="borders.accent"
                    onClick={e => {
                      e.stopPropagation();
                    }}>
                    <Checkbox
                      w="21px"
                      h="21px"
                      borderColor="grey"
                      isChecked={selectedScheduleIds.includes(schIns.id)}
                      onChange={() => {
                        handleCheckboxClick(schIns.id);
                      }}
                    />
                  </Td> */}
                    <Td
                      py="16px"
                      pl="16px"
                      borderTop="1px"
                      borderColor="borders.accent"
                      fontSize="14px"
                      color="textcolors.secondary"
                    >
                      {ev.name}
                    </Td>
                    {/* <Td
                    display={{sm: 'none', md: 'table-cell'}}
                    py="16px"
                    pl="16px"
                    borderTop="1px"
                    borderColor="borders.accent"
                    fontSize="12px">
                    <Avatar
                      w="30px"
                      h="30px"
                      p="16px"
                      textColor="gray.500"
                      fontSize="12px"
                      fontWeight={400}
                      // ml="8px"
                      name={
                        employeesList.find(
                          employee => employee.id === schIns.assignedBy,
                        )?.preferredTitle
                      }
                      backgroundColor="gray.100"
                    />
                  </Td> */}
                    {/* <Td
                    display={{sm: 'none', md: 'table-cell'}}
                    py="16px"
                    pl="16px"
                    borderTop="1px"
                    borderColor="borders.accent"
                    fontSize="12px">
                    <Avatar
                      w="30px"
                      h="30px"
                      p="16px"
                      textColor="gray.500"
                      fontSize="12px"
                      fontWeight={400}
                      // ml="8px"
                      name={
                        employeesList.find(
                          employee => employee.id === schIns.employeeId,
                        )?.preferredTitle
                      }
                      backgroundColor="gray.100"
                    />
                  </Td> */}
                    <Td
                      py="16px"
                      pl="16px"
                      borderTop="1px"
                      borderColor="borders.accent"
                      fontSize="14px"
                      color="textcolors.secondary"
                    >
                      {ev.code}
                    </Td>
                    <Td
                      py="16px"
                      pl="16px"
                      borderTop="1px"
                      borderColor="borders.accent"
                      fontSize="14px"
                      color="textcolors.secondary"
                    >
                      {ev.description}
                    </Td>
                    <Td
                      px="10px"
                      py="8px"
                      borderColor="borders.accent"
                      textAlign="end"
                    >
                      <Menu placement="bottom-end">
                        <MenuButton
                          as={IconButton}
                          // borderRightRadius="8px"
                          backgroundColor="transparent"
                          _hover={{ backgroundColor: "transparent" }}
                          _active={{ backgroundColor: "transparent" }}
                          aria-label="dropdown menu"
                          icon={<FiSettings color="#545f70" size={20} />}
                        />
                        <MenuList minW="100px">
                          <MenuItem
                            fontSize="13px"
                            onClick={() => {
                              setSelectedId(ev.id);
                              form.reset(ev);
                              onOpen();
                            }}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            fontSize="13px"
                            onClick={() => {
                              setSelectedId(ev.id);
                              deleteModal.onOpen();
                            }}
                          >
                            Delete
                          </MenuItem>

                          <Modal
                            isOpen={deleteModal.isOpen}
                            onClose={deleteModal.onClose}
                          >
                            <ModalOverlay />
                            <ModalContent mx="10px">
                              <ModalHeader fontWeight="semibold" py="4px">
                                Confirmation
                              </ModalHeader>
                              <ModalCloseButton />
                              <ModalBody>
                                Are you sure you want to Delete this?
                              </ModalBody>

                              <ModalFooter
                                display="flex"
                                justifyContent="flex-end"
                                pt="16px"
                                border="0px"
                              >
                                <ButtonGroup
                                  size="xs"
                                  variant="solid"
                                  borderRadius="5px"
                                >
                                  <Button
                                    onClick={deleteModal.onClose}
                                    borderRadius="5px"
                                  >
                                    No
                                  </Button>
                                  <Button
                                    colorScheme="red"
                                    borderRadius="5px"
                                    onClick={() => {
                                      if (selectedId) {
                                        PeriodicalEvents.DeletePeriodicalEvents(
                                          selectedId,
                                          () => {
                                            toast({
                                              title: "Success",
                                              description:
                                                "Event deleted sucessfully",
                                              status: "success",
                                            });
                                            //   setLastUpdate(Date.now());
                                            deleteModal.onClose();
                                            setUpdate(Date.now());
                                          },
                                          (error) => {
                                            toast({
                                              title: "Error",
                                              description: error,
                                              status: "error",
                                            });
                                          }
                                        );
                                      }
                                    }}
                                  >
                                    Yes
                                  </Button>
                                </ButtonGroup>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td px="10px" py="8px" borderColor="borders.accent" colSpan={6}>
                  <Center w="100%">
                    <Text>No Events Found !</Text>
                  </Center>
                </Td>
              </Tr>
            )}
            <EventsModal
              isOpen={isOpen}
              onClose={onClose}
              setUpdate={setUpdate}
            />
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
