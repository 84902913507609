import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Editable,
  EditableInput,
  EditablePreview,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { AiOutlineSearch } from "react-icons/ai";
import { FiMoreVertical, FiPlus } from "react-icons/fi";
import { IoMdCloudUpload } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { MCQ } from "../../../../modules/Audit/MCQs";
import { useGlobalResponseDrawer } from "../../hooks/globalResponsesDrawer";
import GlobalResponseItem from "../GlobalResponseItem";
import UploadCsvModal from "../Modals/UploadCsvModal";
interface GlobalResponseProps {
  isOpen: boolean;
  onClose: () => void;
  canBeDeleted?: boolean;
}
export default function GlobalResponseSet({
  isOpen,
  onClose,
  canBeDeleted,
}: GlobalResponseProps) {
  const uploadcsv = useDisclosure();

  const { globalResponses, Save } = useGlobalResponseDrawer();
  const form = useForm<MCQ.IMCQ>({
    defaultValues: globalResponses,
  });

  const optionsArr = useFieldArray({
    control: form.control,
    name: "options",
    keyName: "optionUUID",
  });
  const deleteOptions = useFieldArray({
    control: form.control,
    name: "deletedOptions",
  });

  useEffect(() => {
    if (globalResponses) {
      form.reset({ ...globalResponses });
    }
  }, [globalResponses]);

  const [searchText, setSearchText] = useState<string>("");
  const searchedOptions = useCallback(() => {
    return optionsArr.fields.filter((option, index) => {
      return option?.value?.toLowerCase().includes(searchText?.toLowerCase());
    });
  }, [optionsArr.fields, globalResponses, searchText]);
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      size={{ xs: "full", md: "md" }}
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent
        backgroundColor={"backgrounds.header"}
        overflowY="auto"
        minH="100vh"
      >
        <DrawerHeader
          padding={"16px 0px 0px 0px"}
          borderBottom={"2px solid"}
          borderColor={"borders.accent"}
        >
          <VStack w="100%" spacing={0}>
            <Box ml="auto" padding="0px 24px 14px 0px">
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<FiMoreVertical size={18} color="#1f2533" />}
                  variant="outline"
                  borderRadius={"50%"}
                  border={"none"}
                  _hover={{ backgroundColor: "#bfc6d4" }}
                  _active={{
                    backgroundColor: "#bfc6d4",
                  }}
                />
                <MenuList padding="10px 0px 10px 0px" shadow="lg">
                  <MenuItem
                    padding="8px 16px"
                    fontSize="16px"
                    color="textcolors.primary"
                    onClick={uploadcsv.onOpen}
                  >
                    <HStack>
                      <Icon as={IoMdCloudUpload} boxSize={"18px"} />
                      <Text>Upload responses as CSV</Text>
                    </HStack>
                  </MenuItem>
                  <UploadCsvModal
                    isOpen={uploadcsv.isOpen}
                    onClose={uploadcsv.onClose}
                  />
                  {canBeDeleted && (
                    <MenuItem
                      padding="8px 16px"
                      fontSize="16px"
                      color="textcolors.primary"
                    >
                      <HStack>
                        <Icon as={MdDelete} boxSize={"18px"} />
                        <Text>Delete</Text>
                      </HStack>
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </Box>

            <Box w="100%" padding="0px 24px 0px 24px">
              <Controller
                control={form.control}
                name="name"
                render={({ field }) => (
                  <Editable
                    w="100%"
                    marginTop="24px"
                    placeholder="Untitled response set"
                    fontSize={"24px"}
                    fontWeight={"500"}
                    color={"textcolors.primary"}
                    {...field}
                  >
                    <EditablePreview
                      w="100%"
                      padding={"8px"}
                      _hover={{
                        color: "icons.primary",
                      }}
                    />
                    <EditableInput
                      backgroundColor={"white"}
                      padding={"8px"}
                      border="1px"
                      borderStyle={"solid"}
                      borderColor={"blue"}
                      color={"black"}
                      _focusVisible={{ boxShadow: "0px" }}
                    />
                  </Editable>
                )}
              />
            </Box>
            <Box w="100%" padding="20px">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<AiOutlineSearch size={"18px"} color="gray.300" />}
                />
                <Input
                  type="search"
                  placeholder="Search"
                  border="1px"
                  fontSize="16px"
                  fontWeight={400}
                  color="textcolors.primary"
                  borderStyle={"solid"}
                  borderColor={"#abb5c4"}
                  padding={"8px 16px 8px 38px"}
                  _focusVisible={{
                    boxShadow: "0px",
                    borderColor: "primary.500",
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </InputGroup>
            </Box>
          </VStack>
        </DrawerHeader>

        <FormProvider {...form}>
          <DrawerBody padding="0px">
            {searchedOptions().map((op, i) => (
              <GlobalResponseItem
                key={op.optionUUID}
                index={i}
                onDelete={() => {
                  optionsArr.remove(i);
                  if (op.id) {
                    deleteOptions.append({
                      id: op.id,
                      name: op.value,
                    });
                  }
                }}
              />
            ))}
          </DrawerBody>
        </FormProvider>

        <DrawerFooter
          padding="0px"
          backgroundColor={"backgrounds.header"}
          flexDirection="column"
        >
          <Stack spacing={0} w="100%" direction="column">
            <Stack
              w="100%"
              h="58.6px"
              padding="8px"
              backgroundColor="white"
              border="1px solid"
              borderColor={"borders.accent"}
            >
              <Button
                w="150px"
                h="41px"
                border="0px"
                borderRadius="5px"
                leftIcon={<FiPlus />}
                variant="outline"
                onClick={() => {
                  optionsArr.append({
                    value: "",
                    flagged: false,
                    color: "#808080",
                    orderIndex: optionsArr.fields.length,
                  });
                }}
              >
                Add response
              </Button>
            </Stack>

            <Box flexDirection={{ xs: "column", md: "row" }} padding="17.6px">
              <Button
                h="40px"
                w="192px"
                borderRadius="5px"
                fontSize="14px"
                fontWeight={500}
                marginRight="16px"
                padding={"12px 20px"}
                onClick={form.handleSubmit(
                  (data) => {
                    Save({ ...data, isGlobal: true });
                  },
                  (error) => {
                    console.log(error);
                  }
                )}
              >
                Save and apply
              </Button>
              <Button
                h="40px"
                borderRadius="5px"
                fontSize="14px"
                fontWeight={500}
                variant="outline"
                padding={"12px 20px"}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Box>
          </Stack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
