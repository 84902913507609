import { HStack, Stack, Text } from "@chakra-ui/react";
import Select from "react-select";
interface PaginationControlProps {
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}
export function PaginationControl({
  limit,
  setLimit,
  setPageNumber,
}: PaginationControlProps) {
  const options = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 200, label: "200" },
    { value: 500, label: "500" },
  ];
  return (
    <HStack spacing={0}>
      <Stack direction={"row"} alignItems={"center"}>
        <Text
          _light={{
            color: "customColor.gray.6b7885",
          }}
          fontSize={"14.4px"}
        >
          Show
        </Text>
        <Select
          maxMenuHeight={200}
          options={options}
          value={options.find((option) => option.value === limit)}
          onChange={(selectedOption) => {
            setLimit(selectedOption?.value ?? 10);

            setPageNumber(1);
          }}
          styles={{
            control: (provided) => ({
              ...provided,
              minHeight: "40px",
              fontSize: "14.4px",
              height: "30px",
              borderRadius: "4px",
              border: "1px solid #E2E8F0",
              padding: "0 4px",
              _hover: { borderColor: "#CBD5E0" },
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: "0 6px",
            }),
            input: (provided) => ({
              ...provided,
              margin: "0",
              padding: "0",
            }),
            indicatorSeparator: () => ({
              display: "none",
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              padding: "0",
            }),
            menu: (provided) => ({
              ...provided,
              minWidth: "150px",
            }),
            singleValue: (provided) => ({
              ...provided,
              display: "flex",
              alignItems: "center",
            }),
          }}
        />

        <Text
          _light={{
            color: "customColor.gray.6b7885",
          }}
          fontSize={"14.4px"}
        >
          entries
        </Text>
      </Stack>
    </HStack>
  );
}
