import {
  Box,
  Container,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Controller, UseFormReturn } from "react-hook-form";
import { Insurance } from "../../../../Api/Insurance";
import { Statutory } from "../../../../Api/Statutory";

interface Props {
  form: UseFormReturn<Statutory.StatutoryBenefitsData, any, undefined>;

  insuranceData: Insurance.FetchInsurance[];
  watchBusinessInsuranceId: string | null | undefined;
}
export function GroupLifeInsurance({
  form,

  insuranceData,
  watchBusinessInsuranceId,
}: Props) {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      p="0px"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
    >
      <Heading
        textAlign="left"
        fontSize=".9975rem"
        fontWeight="bold"
        padding="20px"
        as="h2"
        size="md"
        _light={{
          color: "customColor.black7",
        }}
        borderBottom="1px solid #d6dade"
      >
        Group life Insurance
      </Heading>

      <Container
        maxW="400px"
        marginTop="15px"
        paddingTop="20px"
        pb="8px"
        marginX="auto"
        px="0px"
      >
        <Box fontSize={"0.8125rem"} fontWeight={"bold"} lineHeight={"1.3077"}>
          <Heading
            alignContent={"left"}
            fontSize=".9975rem"
            _dark={{
              color: "customColor.dark.150",
            }}
            _light={{
              color: "customColor.black7",
            }}
            margin={"15px 0px"}
            fontWeight={"700"}
            lineHeight={"1.5rem"}
            textDecor={"underline"}
          >
            Life Insurance
          </Heading>
          <Stack>
            <Controller
              control={form.control}
              name="businessInsuranceId"
              render={({ field }) => (
                <RadioGroup
                  onChange={(value) => {
                    form.setValue(
                      "businessInsuranceId",
                      value === "NA" ? null : value
                    );
                  }}
                  value={form.getValues("businessInsuranceId") ?? undefined}
                >
                  <Stack spacing={5} direction="row">
                    {insuranceData?.map((item) => (
                      <Radio key={item.id} value={item.id} colorScheme="blue">
                        <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          _light={{
                            color: "customColor.black7",
                          }}
                        >
                          {item.name}
                        </Text>
                      </Radio>
                    ))}
                    <Radio value="NA" colorScheme="blue">
                      <Text
                        fontSize={"14px"}
                        fontWeight={"700"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        _light={{
                          color: "customColor.black7",
                        }}
                      >
                        NA
                      </Text>
                    </Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </Stack>
          {watchBusinessInsuranceId && (
            <Stack
              borderBottom={"2px solid #dadddf"}
              borderTop={"2px solid #dadddf"}
              margin={"25px 0px 20px"}
            >
              <Stack paddingTop={"15px"} paddingBottom={"15px"}>
                <HStack>
                  <Stack flex={2}>
                    {[
                      "Name:",
                      "Plan:",
                      "Natural Death Coverage:",
                      "Accidental Death Coverage:",
                      "Coverage:",
                    ].map((item, i) => {
                      return (
                        <Stack key={i}>
                          <Text
                            _dark={{
                              color: "customColor.white",
                            }}
                            _light={{
                              color: "customColor.black7",
                            }}
                            fontSize={"13px"}
                            fontWeight={"700"}
                          >
                            {item}
                          </Text>
                        </Stack>
                      );
                    })}
                  </Stack>
                  <Stack flex={1}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"500"}
                      fontSize={"13px"}
                    >
                      {insuranceData?.find(
                        (item) => item.id === watchBusinessInsuranceId
                      )?.name ?? "NA"}
                    </Text>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"500"}
                      fontSize={"13px"}
                    >
                      {insuranceData?.find(
                        (item) => item.id === watchBusinessInsuranceId
                      )?.plan ?? "NA"}
                    </Text>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"500"}
                      fontSize={"13px"}
                    >
                      {insuranceData
                        ?.find((item) => item.id === watchBusinessInsuranceId)
                        ?.naturalDeathAmount?.toLocaleString() ?? "NA"}
                    </Text>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"500"}
                      fontSize={"13px"}
                    >
                      {insuranceData
                        ?.find((item) => item.id === watchBusinessInsuranceId)
                        ?.accidentalDeathAmount?.toLocaleString() ?? "NA"}
                    </Text>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"500"}
                      fontSize={"13px"}
                    >
                      {insuranceData?.find(
                        (item) => item.id === watchBusinessInsuranceId
                      )?.coverage === "SINGLE"
                        ? "Single"
                        : insuranceData?.find(
                            (item) => item.id === watchBusinessInsuranceId
                          )?.coverage === "MARRIED_WITH_CHILDREN"
                        ? "Spouse,Children"
                        : insuranceData?.find(
                            (item) => item.id === watchBusinessInsuranceId
                          )?.coverage === "WITH_PARENTS"
                        ? "Parents"
                        : insuranceData?.find(
                            (item) => item.id === watchBusinessInsuranceId
                          )?.coverage ?? "NA"}
                    </Text>
                  </Stack>
                </HStack>
              </Stack>
            </Stack>
          )}
        </Box>
      </Container>
    </Box>
  );
}
