import { axiosInstance } from "./axiosInstance-aud";

declare module PublicLibraryModule {
  type UploadTemplate = {
    templateId: number;
    author: string;
    industry: string;
  };
  type FetchLibraryTemplates = {
    templateId: number;
    author: string;
    industry: string;
    name: string;
    description: string;
    downloads: number;
    id: number;
  };
  type templateData = {
    id: number;
    title: string;
    description: string;
    image: string;
    businessId: string;
    createdBy: string;
    totalScore: number;
    published: boolean;
    archive: boolean;
    deletedAt: null;
    createdAt: string;
    updatedAt: string;
  };
}
module PublicLibraryModule {
  const URL = "/library";
  export function UploadTemplate(
    data: UploadTemplate,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${URL}/add-to-library`, data)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchLibraryTemplates(
    params: {
      page?: number;
      limit?: number;
      search?: string;
      industry?: string;
    },
    success: (response: Array<FetchLibraryTemplates>, total: number) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(URL, {
        params,
      })
      .then(({ data }) => {
        success(data?.data, data.total);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function DownloadLibraryTemplate(
    params: { recordId: number },
    success: (response: templateData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}/download/library-template`, { params })
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { PublicLibraryModule };
