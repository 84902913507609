import {HStack, Radio, RadioGroup, Stack, Text} from '@chakra-ui/react';
import {useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {FaAlignCenter, FaAlignLeft, FaAlignRight} from 'react-icons/fa';
import {ReportLayout} from '../../../../../modules/Audit/ReportLayout';
import {generatePathForReportLayout} from '../../../../../modules/form-apis';
type Alignment = 'left' | 'center' | 'right';
interface DraggableQuestionProps {
  questionIndex?: number;
  sectionIndex?: number;
  pageIndex: number;
}
export default function DraggableQuestion({
  title,
  question,
  pageIndex,
  questionIndex,
  sectionIndex,
}: ReportLayout.ReportStructureItem & DraggableQuestionProps) {
  const [alignment, setAlignment] = useState<Alignment>('left');
  const form = useFormContext<ReportLayout.ReportLayout>();
  return (
    <Stack
      backgroundColor="white"
      // borderRadius="16px"
      // px="16px"

      alignItems="center"
      // borderWidth={1}

      spacing={'2px'}>
      {/* <Button
        onClick={() => {
          console.log(pageIndex, questionIndex, sectionIndex);
        }}>
        Check
      </Button> */}
      <Controller
        control={form.control}
        name={generatePathForReportLayout(
          {
            pageIndex,
            questionIndex,
            sectionIndex,
          },
          'alignment',
        )}
        render={({field}) => (
          <RadioGroup
            value={field.value ? field.value : 'left'}
            onChange={val => {
              field.onChange(val as Alignment);
              setAlignment(val as Alignment);
            }}
            defaultValue="left"
            size="sm">
            <HStack spacing="8px">
              <Radio value="left">
                <FaAlignLeft color="#A0AEC0" />
              </Radio>
              <Radio value="center">
                <FaAlignCenter color="#A0AEC0" />
              </Radio>
              <Radio value="right">
                <FaAlignRight color="#A0AEC0" />
              </Radio>
            </HStack>
          </RadioGroup>
        )}
      />
      {/* <RadioGroup
        onChange={val => setAlignment(val as Alignment)}
        value={alignment}
        size="sm">
        <HStack spacing="8px">
          <Radio value="left">
            <FaAlignLeft color="#A0AEC0" />
          </Radio>
          <Radio value="center">
            <FaAlignCenter color="#A0AEC0" />
          </Radio>
          <Radio value="right">
            <FaAlignRight color="#A0AEC0" />
          </Radio>
        </HStack>
      </RadioGroup> */}
      <Text
        w="100%"
        flex={1}
        fontSize="16px"
        fontWeight={400}
        color="textcolors.primary"
        textAlign={'left'}>
        Question - {title}
      </Text>
      <Text
        w="100%"
        fontSize="16px"
        fontWeight={400}
        color="#828ea0"
        textAlign={alignment}
        lineHeight={1}>
        Answer
      </Text>
    </Stack>
  );
}
