import {
  Box,
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
// import {addDays} from 'date-fns';
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { CustomInput } from "../../../../../Common/CustomInput";
import { ReactSelect } from "../../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../../Hooks/BusinessContext";
import { TemplateMeta } from "../../../../modules/Audit";
import { PeriodicalEvents } from "../../../../modules/Audit/PeriodicalEvents";
import { PeriodicalSchedule } from "../../../../modules/Audit/PeriodicalSchedule";
import DailySchedule from "./Components/Daily";
import MonthlySchedule from "./Components/MonthlySchedule";
import WeeklySchedule from "./Components/WeeklySchedule";
import YearlySchedule from "./Components/YearlySchedule";

interface PeriodicalScheduleModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedId?: number;
  templatesList: TemplateMeta.TemplateMeta[];
  setLastUpdate: (timestamp: number) => void;
  listOfEvents?: PeriodicalEvents.GetEvents[];
}
export default function PeriodicalScheduleModal({
  isOpen,
  onClose,
  selectedId,
  templatesList,
  setLastUpdate,
  listOfEvents,
}: PeriodicalScheduleModalProps) {
  const form = useFormContext<PeriodicalSchedule.AddPeriodicSchedule>();
  const { allUsers, locationList } = useBusinessContext();
  const frequencyOptions = [
    {
      label: "Daily",
      value: "DAILY",
    },
    {
      label: "Weekly",
      value: "WEEKLY",
    },

    {
      label: "Monthly",
      value: "MONTHLY",
    },

    {
      label: "Annually",
      value: "ANNUALLY",
    },
    {
      label: "On Request",
      value: "ON_REQUEST",
    },
  ];
  const watchStartDate = useWatch({
    control: form.control,
    name: "dateRange.startDate",
  });
  const toast = useToast();
  const [value, setValue] = useState("day");

  const monthlyFrequencyDay = [
    { value: "1", label: "First" },
    { value: "2", label: "Second" },

    { value: "3", label: "Third" },
    { value: "4", label: "Fourth" },
    { value: "5", label: "Last" },
  ];

  const [areasList, setAreasList] = useState<any>([]);
  const watchedFrequency = useWatch({
    control: form.control,
    name: "frequency",
  });
  const watchedLocation = useWatch({
    control: form.control,
    name: "location",
  });
  const watchedTemplate = useWatch({
    control: form.control,
    name: "templateId",
  });
  const selectedAreas = useWatch({
    control: form.control,
    name: "areas",
  });

  const employeeIds = useWatch({
    control: form.control,
    name: "employees",
  });

  useEffect(() => {
    if (watchedLocation) {
      const areas = locationList?.find(
        (loc) => loc.id === watchedLocation
      )?.areas;
      setAreasList(areas ?? []);
    }
  }, [watchedLocation]);
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px="16px">Periodical Schedule</ModalHeader>
        <ModalCloseButton />
        <ModalBody px="16px">
          <Box>
            <Stack pb="16px" pt="8px" h="100%" spacing="16px">
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Template is required",
                  },
                }}
                name="templateId"
                control={form.control}
                render={({ field, fieldState }) => (
                  <ReactSelect
                    key={field.value}
                    {...field}
                    label="Template"
                    withValidation
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Please select template"
                    placeholder="Select template"
                    options={templatesList}
                    value={
                      field.value
                        ? {
                            id: field.value,
                            title:
                              templatesList.find(
                                (temp) => temp.id === field.value
                              )?.title ?? "",
                          }
                        : undefined
                    }
                    onChange={(val: any) => {
                      field.onChange(val?.id);
                    }}
                    getOptionLabel={(option: any) => option.title}
                    getOptionValue={(option: any) => option.id}
                  />
                )}
              />

              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Location is required",
                  },
                }}
                name="location"
                control={form.control}
                render={({ field, fieldState }) => (
                  <ReactSelect
                    key={field.value}
                    {...field}
                    label="Location"
                    withValidation
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Please select location"
                    placeholder="Select Location"
                    options={locationList}
                    value={
                      field.value
                        ? {
                            id: field.value,
                            name:
                              locationList?.find(
                                (loc) => loc.id === field.value
                              )?.name ?? "",
                          }
                        : undefined
                    }
                    onChange={(val: any) => {
                      field.onChange(val?.id);
                      setAreasList(val?.areas ?? []);
                      form.setValue("areas", []);
                      // const locationTimeZone =
                      //   locationList?.find(loc => loc.id === val?.id)
                      //     ?.timezone ?? '';
                      // form.setValue('locationTimeZone', locationTimeZone);
                    }}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.id}
                  />
                )}
              />
              <Box>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Areas are required",
                    },
                  }}
                  name="areas"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      label="Areas"
                      withValidation
                      isInvalid={fieldState.invalid}
                      isMulti
                      ErrorMessage="Please select Areas"
                      placeholder="Select Areas"
                      options={areasList}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                      value={areasList.filter((area: any) =>
                        field.value.includes(area.id)
                      )}
                      onChange={(val: any) => {
                        form.setValue(
                          "areas",
                          val.map((area: any) => area.id)
                        );
                      }}
                    />
                  )}
                />
                <HStack mt="8px">
                  <Checkbox
                    isChecked={
                      selectedAreas.length > 0 &&
                      selectedAreas.length === areasList.length
                    }
                    onChange={(e: any) => {
                      if (e.target.checked) {
                        form.setValue(
                          "areas",
                          areasList.map((area: any) => area.id!)
                        );
                      } else {
                        form.setValue("areas", []);
                      }
                    }}
                  />
                  <Text
                    fontSize="14px"
                    fontWeight={400}
                    lineHeight={1}
                    color="textcolors.primary"
                  >
                    Do you want to add all areas?
                  </Text>
                </HStack>
              </Box>

              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "User is required",
                  },
                }}
                name="employees"
                control={form.control}
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    label="Users"
                    withValidation
                    isMulti
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Please select User"
                    placeholder="Select User"
                    options={allUsers}
                    value={
                      allUsers.filter((user: any) =>
                        field.value.includes(user?.user?.id)
                      )

                      // ? {
                      //     id: field.value,
                      //     preferredTitle:
                      //       employeesList.find(emp => emp.id )
                      //         ?.preferredTitle ?? '',
                      //     access:
                      //       employeesList.find(emp => emp.id )
                      //         ?.access ?? '',
                      //   }
                      // : undefined
                    }
                    onChange={(val: any) => {
                      // console.log(val);
                      field.onChange(val.map((user: any) => user?.user?.id));
                      console.log(val);
                    }}
                    getOptionLabel={(option: any) => {
                      return `${option?.user?.firstName} ${option?.user?.lastName}(${option?.accessLevel})`;
                    }}
                    getOptionValue={(option: any) => option?.user?.id}
                  />
                )}
              />
              <Stack>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Frequency is required",
                    },
                  }}
                  name="frequency"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      key={field.value}
                      {...field}
                      label="Frequency"
                      withValidation
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Please Select frequency"
                      placeholder="Select Frequency"
                      options={frequencyOptions}
                      value={
                        field.value
                          ? frequencyOptions.find(
                              (option) => option.value === field.value
                            )
                          : undefined
                      }
                      onChange={(val: any) => {
                        field.onChange(val?.value);
                        // if (val?.value !== form.getValues('frequency')) {
                        form.setValue("properties", {
                          selection:
                            val?.value === "DAILY"
                              ? "DAY"
                              : val?.value === "WEEKLY"
                              ? "WEEK"
                              : val?.value === "MONTHLY"
                              ? "NO_OF_MONTH"
                              : val?.value === "ANNUALLY"
                              ? "NO_OF_YEAR"
                              : "ON_REQUEST",
                        });
                        // }
                      }}
                    />
                  )}
                />
                <HStack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Time is required",
                      },
                    }}
                    name="timeRange.startTime"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <CustomInput
                        input={{
                          ...field,
                          variant: "outline",
                          isInvalid:
                            (fieldState.error?.message?.length ?? 0) > 0,
                          // value: moment(field.value).format('YYYY-MM-DD'),
                          type: "time",
                          value: field.value,
                        }}
                        label="Start time"
                        withValidation
                        validationMessage={fieldState.error?.message}
                      />
                    )}
                  />
                  <span>~</span>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Time is required",
                      },
                    }}
                    name="timeRange.endTime"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <CustomInput
                        input={{
                          ...field,
                          variant: "outline",
                          isInvalid:
                            (fieldState.error?.message?.length ?? 0) > 0,
                          // value: moment(field.value).format('YYYY-MM-DD'),
                          type: "time",
                          value: field.value,
                        }}
                        label="End time"
                        withValidation
                        validationMessage={fieldState.error?.message}
                      />
                    )}
                  />
                </HStack>

                {form.watch("frequency") === "DAILY" && <DailySchedule />}
                {form.watch("frequency") === "WEEKLY" && <WeeklySchedule />}
                {form.watch("frequency") === "MONTHLY" && <MonthlySchedule />}
                {form.watch("frequency") === "ANNUALLY" && <YearlySchedule />}

                <HStack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Date & Time is required",
                      },
                    }}
                    name="dateRange.startDate"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <CustomInput
                        input={{
                          ...field,
                          variant: "outline",
                          isInvalid:
                            (fieldState.error?.message?.length ?? 0) > 0,
                          value: moment(field.value).format("YYYY-MM-DD"),
                          type: "date",
                        }}
                        label="Start Date"
                        withValidation
                        validationMessage={fieldState.error?.message}
                      />
                    )}
                  />

                  <Text>~</Text>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Date & Time is required",
                      },
                    }}
                    name="dateRange.endDate"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <CustomInput
                        input={{
                          ...field,
                          variant: "outline",
                          isInvalid:
                            (fieldState.error?.message?.length ?? 0) > 0,
                          value: moment(field.value).format("YYYY-MM-DD"),
                          type: "date",
                          min: watchStartDate,
                        }}
                        label="End Date"
                        withValidation
                        validationMessage={fieldState.error?.message}
                      />
                    )}
                  />
                </HStack>
              </Stack>
              <Stack>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Event is required",
                    },
                  }}
                  name="activityId"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      key={field.value}
                      {...field}
                      label="Event"
                      withValidation
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Please select Event"
                      placeholder="Select Event"
                      options={listOfEvents}
                      value={
                        field.value
                          ? {
                              id: field.value,
                              name:
                                listOfEvents?.find(
                                  (ev) => ev.id === field.value
                                )?.name ?? "",
                            }
                          : undefined
                      }
                      onChange={(val: any) => {
                        field.onChange(val?.id);
                      }}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                    />
                  )}
                />
              </Stack>
              <Box w="50px">
                <Controller
                  name="color"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <CustomInput
                      input={{
                        ...field,
                        variant: "outline",

                        value: field.value,
                        type: "color",
                        h: "50px",
                        p: 0,
                      }}
                      label="Color"
                    />
                  )}
                />
              </Box>
            </Stack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              variant="solid"
              borderRadius="8px"
              h="43px"
              px="20px"
              py="12px"
              onClick={form.handleSubmit((data) => {
                if (selectedId) {
                  PeriodicalSchedule.UpdatePeriodicalSchedule(
                    selectedId,
                    data,
                    (success) => {
                      toast({
                        title: "Success",
                        status: "success",
                        description: "Periodical Schedule updated successfully",
                      });
                      setLastUpdate(Date.now());
                      onClose();
                    },
                    (err) => {
                      toast({
                        title: "Error",
                        status: "error",
                        description: "Periodical Schedule not updated",
                      });
                    }
                  );
                } else {
                  PeriodicalSchedule.AddPeriodicalSchedule(
                    data,
                    (success) => {
                      toast({
                        title: "Success",
                        status: "success",
                        description: "Periodical Schedule added successfully",
                      });
                      setLastUpdate(Date.now());
                      onClose();
                    },
                    (err) => {
                      toast({
                        title: "Error",
                        status: "error",
                        description: "Periodical Schedule not added",
                      });
                    }
                  );
                }
              })}
            >
              {selectedId ? "Update" : "Create"}
            </Button>
            <Button
              variant="outline"
              borderRadius="8px"
              h="43px"
              px="20px"
              py="12px"
              onClick={() => {
                form.reset({
                  activityId: undefined,
                  color: "#000000",
                  dateRange: {
                    endDate: "",
                    startDate: "",
                  },
                  timeRange: {
                    endTime: "",
                    startTime: "",
                  },

                  areas: [],
                  employees: [],
                  frequency: "DAILY",
                  location: undefined,
                  locationTimeZone: undefined,
                  templateId: undefined,
                  properties: {
                    selection: "DAY",
                    annually: {
                      day: undefined,
                      month: undefined,
                      dayPosition: undefined,
                      every: undefined,
                      monthDate: undefined,
                    },
                    daily: {
                      every: undefined,
                      onlyWeekdays: false,
                    },
                    monthly: {
                      day: undefined,
                      dayPosition: undefined,
                      every: undefined,
                      dayOfMonth: undefined,
                    },
                    weekly: {
                      days: [],
                      numOfWeeks: undefined,
                    },
                  },
                });
                onClose();
              }}
            >
              Cancel
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
