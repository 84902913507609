import {
  Box,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { PeriodicalEvents } from "../../../modules/Audit/PeriodicalEvents";
import { PeriodicalSchedule } from "../../../modules/Audit/PeriodicalSchedule";

interface ReportTableProps {
  allEvents: Array<PeriodicalEvents.GetEvents>;
  reportData: Array<PeriodicalSchedule.PeriodicalScheduleReport>;
}

export default function ReportTable({
  allEvents,
  reportData,
}: ReportTableProps) {
  // Function to render the status symbol
  const renderStatusSymbol = (status: any) => {
    switch (status) {
      case "COMPLETED":
        return { symbol: "Completed", color: "green" };
      case "OVERDUE":
        return { symbol: "Overdue", color: "red" };
      case "PENDING":
        return { symbol: "Pending", color: "orange" };
      default:
        return { Symbol: "", color: "black" };
    }
  };
  const { allUsers, locationList } = useBusinessContext();
  return (
    <Stack>
      <TableContainer>
        <Table
          variant="simple"
          borderWidth="1px"
          borderColor={"gray.300"}
          id={"scrollable-component"}
        >
          <Thead
            borderBottom="1px"
            borderColor="gray.300"
            // bg="primary.500"

            h="47px"
          >
            <Tr>
              <Th
                px="12px"
                py="8px"
                fontSize="13px"
                fontWeight={400}
                border="0px"
                lineHeight={1.5}
                textAlign="center"
                color="textcolors.primary"
                textTransform="none"
              >
                Weeks
              </Th>
              <Th
                px="12px"
                py="8px"
                fontSize="13px"
                fontWeight={400}
                textAlign="center"
                lineHeight={1.5}
                borderLeftWidth="1px"
                borderLeftColor="gray.300"
                color="textcolors.primary"
                textTransform="none"
              >
                Day
              </Th>
              <Th
                px="12px"
                py="8px"
                fontSize="13px"
                fontWeight={400}
                textAlign="center"
                lineHeight={1.5}
                borderLeftWidth="1px"
                borderLeftColor="gray.300"
                color="textcolors.primary"
                textTransform="none"
              >
                Date
              </Th>
              <Th
                px="12px"
                py="8px"
                fontSize="13px"
                fontWeight={400}
                textAlign="center"
                lineHeight={1.5}
                borderLeftWidth="1px"
                borderLeftColor="gray.300"
                color="textcolors.primary"
                textTransform="none"
              >
                Location
              </Th>
              <Th
                px="12px"
                py="8px"
                fontSize="13px"
                fontWeight={400}
                maxW="300px"
                minW="200px"
                whiteSpace="normal"
                lineHeight={1.5}
                overflowWrap="break-word"
                textAlign="center"
                borderLeftWidth="1px"
                borderLeftColor="gray.300"
                color="textcolors.primary"
                textTransform="none"
              >
                Area
              </Th>
              <Th
                px="12px"
                py="8px"
                fontSize="13px"
                fontWeight={400}
                textAlign="center"
                lineHeight={1.5}
                borderLeftWidth="1px"
                borderLeftColor="gray.300"
                color="textcolors.primary"
                textTransform="none"
              >
                Employee
              </Th>
              {allEvents.map((event, i) => (
                <Th
                  key={i}
                  px="12px"
                  py="8px"
                  fontSize="13px"
                  fontWeight={400}
                  color="textcolors.primary"
                  textTransform="none"
                  textAlign="center"
                  lineHeight={1.5}
                  borderLeftWidth="1px"
                  borderLeftColor="gray.300"
                >
                  {event.name}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {reportData.map((week, weekIndex) => {
              const weekRowSpan = week.weekDates.reduce(
                (acc, day) => acc + Math.max(day.events.length, 1),
                0
              );

              return week.weekDates.flatMap((date, dateIndex) => {
                const dateRowSpan = Math.max(date.events.length, 1);
                if (date.events.length === 0) {
                  return (
                    <Tr key={`${weekIndex}-${dateIndex}`}>
                      {dateIndex === 0 && (
                        <Td
                          px="12px"
                          py="0px"
                          fontSize="13px"
                          fontWeight={400}
                          rowSpan={weekRowSpan}
                          textAlign="center"
                          borderColor="gray.300"
                        >
                          {week.weekName.split(" (")[0]} <br /> (
                          {week.weekName.split(" (")[1]}
                        </Td>
                      )}
                      <Td
                        px="12px"
                        py="0px"
                        fontSize="13px"
                        fontWeight={400}
                        borderLeft="1px"
                        textAlign="center"
                        borderColor="gray.300"
                      >
                        {date.day}
                      </Td>
                      <Td
                        px="12px"
                        py="0px"
                        fontSize="13px"
                        fontWeight={400}
                        borderLeft="1px"
                        textAlign="center"
                        borderColor="gray.300"
                      >
                        {date.date}
                      </Td>
                      <Td
                        px="12px"
                        py="0px"
                        fontSize="13px"
                        fontWeight={400}
                        colSpan={allEvents.length + 3}
                        textAlign="center"
                        borderLeft="1px"
                        borderColor="gray.300"
                      >
                        No events scheduled
                      </Td>
                    </Tr>
                  );
                }
                return date.events.map((event, eventIndex) => (
                  <Tr key={`${weekIndex}-${dateIndex}-${eventIndex}`}>
                    {dateIndex === 0 && eventIndex === 0 && (
                      <Td
                        px="12px"
                        py="0px"
                        fontSize="13px"
                        fontWeight={400}
                        rowSpan={weekRowSpan}
                        textAlign="center"
                        borderColor="gray.300"
                      >
                        {week.weekName.split(" (")[0]} <br /> (
                        {week.weekName.split(" (")[1]}
                      </Td>
                    )}
                    {eventIndex === 0 && (
                      <>
                        <Td
                          px="12px"
                          py="0px"
                          fontSize="13px"
                          fontWeight={400}
                          rowSpan={dateRowSpan}
                          borderLeft="1px"
                          textAlign="center"
                          borderColor="gray.300"
                        >
                          {date.day}
                        </Td>
                        <Td
                          px="12px"
                          py="0px"
                          fontSize="13px"
                          fontWeight={400}
                          rowSpan={dateRowSpan}
                          borderLeft="1px"
                          textAlign="center"
                          borderColor="gray.300"
                        >
                          {date.date}
                        </Td>
                      </>
                    )}
                    <Td
                      px="12px"
                      py="0px"
                      fontSize="13px"
                      fontWeight={400}
                      borderLeft="1px"
                      textAlign="center"
                      borderColor="gray.300"
                    >
                      {locationList?.find((loc) => loc.id === event.location)
                        ?.name || " - "}
                    </Td>
                    <Td
                      px="12px"
                      py="0px"
                      fontSize="13px"
                      fontWeight={400}
                      borderLeft="1px"
                      maxW="200px"
                      whiteSpace="normal"
                      overflowWrap="break-word"
                      textAlign="center"
                      borderColor="gray.300"
                    >
                      {event?.areas
                        .map((areaId) => {
                          const location = locationList?.find(
                            (loc) => loc.id === event?.location
                          );
                          const area = location?.areas.find(
                            (a) => a.id === areaId
                          );
                          return area?.name;
                        })
                        .join(", ")}
                    </Td>
                    <Td
                      px="12px"
                      py="0px"
                      fontSize="13px"
                      fontWeight={400}
                      borderLeft="1px"
                      textAlign="center"
                      borderColor="gray.300"
                    >
                      {allUsers.find(
                        (user) => user?.user?.id === event.completedBy
                      )?.user?.firstName +
                        " " +
                        allUsers.find(
                          (user) => user?.user?.id === event.completedBy
                        )?.user?.lastName || " - "}
                    </Td>
                    {allEvents.map((headerEvent, headerIndex) => {
                      const statusInfo = renderStatusSymbol(event.status);
                      return (
                        <Td
                          fontSize="13px"
                          fontWeight={400}
                          px="12px"
                          py="0px"
                          borderLeft="1px"
                          borderColor="gray.300"
                          textAlign={"center"}
                          display="table-cell"
                          alignItems="center"
                          justifyContent="center"
                          key={`${weekIndex}-${dateIndex}-${eventIndex}-${headerIndex}`}
                          backgroundColor={
                            headerEvent.id === event.activityId
                              ? `${statusInfo.color}.50`
                              : "gray.100"
                          }
                          color={
                            headerEvent.id === event.activityId
                              ? statusInfo.color
                              : "inherit"
                          }
                        >
                          {headerEvent.id === event.activityId ? (
                            <Box
                              fontSize="13px"
                              fontWeight={400}
                              as="span"
                              display="flex"
                              justifyContent="center"
                              width="100%"
                            >
                              {statusInfo.symbol}
                            </Box>
                          ) : (
                            ""
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                ));
              });
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
