import {Button, Menu, MenuButton, MenuItem, MenuList} from '@chakra-ui/react';
import {FiChevronDown} from 'react-icons/fi';
import {Permission} from '../../../../../modules/Audit/Permission';

interface UserMenuProps {
  templatePermission: Array<Permission.Permissions>;
  onSelect: (optionId: number) => void;
  selectedOption: number;
  isInvalid?: boolean;
}
export default function UserMenu({
  templatePermission,
  onSelect,
  selectedOption,
  isInvalid,
}: UserMenuProps) {
  // const [allPermission, setAllPermission] = useState<Permission.Permissions[]>([]);
  // const [selectedOption, setSelectedOption] = useState<number>(1);
  // useEffect(() => {
  //   Permission.FetchPermissionByType(
  //     'INSPECTION',
  //     (data) => {
  //       console.log(data)
  //       setAllPermission(data);
  //     },

  //     (error) => {
  //       console.error('Error fetching permissions:', error);
  //     }
  //   );
  // }, []);

  return (
    <Menu>
      <MenuButton
        as={Button}
        px="8px"
        fontSize="16px"
        fontWeight={400}
        color="textcolors.secondary"
        borderRadius="4px"
        borderWidth={isInvalid ? '1px' : undefined}
        borderColor={isInvalid ? 'red' : undefined}
        backgroundColor="transparent"
        _hover={{backgroundColor: 'none'}}
        _active={{backgroundColor: 'none'}}
        rightIcon={<FiChevronDown size={12} />}>
        {templatePermission.find(per => per.id === selectedOption)
          ?.permissionName ?? 'Select Permission'}
      </MenuButton>
      <MenuList minWidth={'100px'} boxShadow="lg" mr="15px" zIndex={999}>
        {templatePermission?.map(per => {
          return (
            <MenuItem
              key={per.id}
              px="16px"
              py="8px"
              fontSize={'14px'}
              fontWeight={400}
              color={'textcolors.primary'}
              onClick={() => {
                console.log(selectedOption);
                onSelect(per.id);
              }}>
              {per.permissionName}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}
