import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { LocationApi } from "../../../../TimeAttendance/API/LocationAPi";
import { Inspection } from "../../../modules/Audit";
import { useInspectionContext } from "../hooks/InspectionContext";
import { useAuditQuestionContainer } from "./AuditQuestionContainer";

interface Option {
  readonly id: string;
  readonly name: string;
}

const createOption = (label: string) => ({
  name: label,
  id: label.toLowerCase().replace(/\W/g, ""),
});

export default () => {
  const { locationList } = useBusinessContext();
  const [locs, setLocations] = useState<Array<LocationApi.Location>>(
    locationList ?? []
  );

  const template = useFormContext();
  const { getItemField } = useAuditQuestionContainer();

  const handleCreate = (inputValue: string) => {
    setIsLoading(true);

    const newOption = createOption(inputValue);
    setIsLoading(false);
    setLocations((prevLocations: any) => [...prevLocations, { ...newOption }]);
    setValue(newOption);
    template.setValue(getItemField("question.questionValue"), inputValue);
    template.setValue("siteName", inputValue);
  };

  useEffect(() => {
    const defaultValue = template.getValues(
      getItemField("question.questionValue")
    );

    if (
      defaultValue &&
      locs.findIndex((loc) => loc.name === defaultValue) < 0
    ) {
      handleCreate(defaultValue);
    }
  }, []);
  const { setIsSaving } = useInspectionContext();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState<Option | null>();
  const handleChange = (selectedOption: any) => {
    if (selectedOption) {
      template.setValue(
        getItemField("question.questionValue"),
        selectedOption.name
      );
      setValue(selectedOption);
    } else {
      template.resetField(getItemField("question.questionValue"));
      setValue(null);
    }
  };
  return (
    <Controller
      control={template.control}
      name={getItemField("question.questionValue")}
      rules={{
        onBlur(event) {
          const answerId = template.getValues(
            getItemField("question.answerId")
          );
          const type = template.getValues(getItemField("question.type"));
          const inspectionMetaID = template.getValues("inspection.id");
          const notes = template.getValues(getItemField("question.notes"));
          setIsSaving(true);
          Inspection.CachingInspection(
            answerId,
            {
              question_value: event.target.value,
              notes: notes,
              inspection_meta_id: inspectionMetaID,
              type: type,
            },
            () => {
              setIsSaving(false);
            },
            () => {
              setIsSaving(false);
            }
          );
        },
      }}
      render={({ field, fieldState }) => {
        return (
          <CreatableSelect
            {...field}
            placeholder="Select or Type Site"
            isClearable
            isDisabled={isLoading}
            isLoading={isLoading}
            onCreateOption={handleCreate}
            options={locs as any}
            value={
              field.value
                ? {
                    id: field.value,
                    name:
                      locs.find((loc) => loc.name === field.value)?.name ?? "",
                  }
                : value
            }
            onChange={handleChange}
            getOptionLabel={(option: any) =>
              option.label ? option.label : option.name
            }
            getOptionValue={(option: any) =>
              option.id ? option.id : option.value
            }
          />
        );
      }}
    />
  );
};
