import {
  Box,
  Checkbox,
  Flex,
  HStack,
  Image,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import moment from "moment";
import { Fragment, useState } from "react";
import { Report, Template } from "../../../../../../modules/Audit";
import ViewImageModal from "./Modals/ViewImageModal";
interface AnswersTypeProps extends Report.IReportPageData {}
export default function AnswersType({
  type,
  format,
  question_title,
  question_value,
  media,
  properties,
  question_color,
  notes,
  alignment,
  display,
}: AnswersTypeProps) {
  if (type === "SLIDER") {
    return (
      <Stack backgroundColor="white" borderRadius="16px">
        <AnswersTypeSlider
          question_title={question_title}
          question_value={question_value}
          properties={properties}
          alignment={alignment}
        />
        <QuestionImage questionMedia={media} />
        <AnswerNotes notes={notes} />
      </Stack>
    );
  } else if (type === "NUMBER" && format === "TEMP") {
    return (
      <Stack backgroundColor="white" borderRadius="16px">
        <AnswersTypeTemperature
          question_title={question_title}
          question_value={question_value}
          properties={properties}
          alignment={alignment}
        />
        <QuestionImage questionMedia={media} />
        <AnswerNotes notes={notes} />
      </Stack>
    );
  } else if (type === "TABLE") {
    return (
      <Stack backgroundColor="white" borderRadius="16px">
        <AnswersTypeTable
          question_title={question_title}
          question_value={question_value}
          properties={properties}
          alignment={alignment}
        />
        <QuestionImage questionMedia={media} />
        <AnswerNotes notes={notes} />
      </Stack>
    );
  } else if (type === "MEDIA") {
    return (
      <Stack backgroundColor="white" borderRadius="16px">
        <AnswersTypeMedia
          question_title={question_title}
          media={media}
          properties={properties}
          alignment={alignment}
        />
        <AnswerNotes notes={notes} />
      </Stack>
    );
  } else if (type === "ANNOTATION") {
    return (
      <Stack backgroundColor="white" borderRadius="16px">
        <AnswersTypeAnnotation
          question_title={question_title}
          question_value={question_value}
          alignment={alignment}
        />
        <AnswerNotes notes={notes} />
      </Stack>
    );
  } else if (type === "CHECKBOX") {
    return (
      <Stack backgroundColor="white" borderRadius="16px">
        <AnswersTypeCheckbox
          question_title={question_title}
          question_value={question_value}
          alignment={alignment}
        />
        <QuestionImage questionMedia={media} />
        <AnswerNotes notes={notes} />
      </Stack>
    );
  } else if (type === "INSPECTION_DATE" || type === "DATE_TIME") {
    return (
      <Stack backgroundColor="white" borderRadius="16px">
        <AnswersTypeDateTime
          question_title={question_title}
          question_value={question_value}
          properties={properties}
          alignment={alignment}
        />
        <QuestionImage questionMedia={media} />
        <AnswerNotes notes={notes} />
      </Stack>
    );
  } else if (type === "MCQs") {
    return (
      <Stack backgroundColor="white" borderRadius="16px">
        <AnswersTypeMcqs
          question_title={question_title}
          question_value={question_value}
          question_color={question_color}
          alignment={alignment}
        />
        <QuestionImage questionMedia={media} />
        <AnswerNotes notes={notes} />
      </Stack>
    );
  } else if (type === "SIGNATURE") {
    return (
      <Stack backgroundColor="white" borderRadius="16px">
        <AnswerSignature
          question_title={question_title}
          question_value={question_value}
          questionMedia={media}
          alignment={alignment}
        />
        <AnswerNotes notes={notes} />
      </Stack>
    );
  } else {
    return (
      <Stack backgroundColor="white" borderRadius="16px">
        <Question
          question_title={question_title}
          question_value={question_value}
          alignment={alignment}
        />
        <QuestionImage questionMedia={media} />
        <AnswerNotes notes={notes} />
      </Stack>
    );
  }
}

interface AnswersTypeSliderProps {
  question_title: string;
  question_value: string;
  properties: Template.QuestionProperties;
  alignment: "left" | "center" | "right";
}
export function AnswersTypeSlider({
  question_title,
  question_value,
  properties,
  alignment,
}: AnswersTypeSliderProps) {
  return (
    <Stack
      backgroundColor="white"
      borderRadius="16px"
      px="16px"
      py="8px"
      mt="8px"
    >
      <Fragment>
        <Text
          fontSize="16px"
          fontWeight={400}
          color="textcolors.primary"
          lineHeight={1}
        >
          {question_title}
        </Text>
        <Text
          textAlign={alignment}
          fontSize="24px"
          fontWeight={500}
          color="textcolors.primary"
          lineHeight="32px"
        >
          {question_value}
        </Text>
        <Text
          textAlign={alignment}
          fontSize="13px"
          fontWeight={400}
          color="icons.primary"
          lineHeight={1}
        >
          {`From ${properties.min} to ${properties.max}`}
        </Text>
      </Fragment>
    </Stack>
  );
}

interface AnswersTypeTableProps {
  question_title: string;
  question_value: string;
  properties: any;
  alignment: "left" | "center" | "right";
}
export function AnswersTypeTable({
  properties,
  question_title,
  question_value,
  alignment,
}: AnswersTypeTableProps) {
  let SimplifiedData;
  try {
    SimplifiedData = JSON.parse(question_value);
    if (!Array.isArray(SimplifiedData)) {
      throw new Error("Parsed data is not an array");
    }
  } catch (e) {
    SimplifiedData = [];
  }
  const hasInventoryType = properties?.tableData?.some(
    (item: any) =>
      item.type === "INVENTORY" || item.type === "INVENTORY_ITEM_CODE"
  );
  const ImageModal = useDisclosure();
  const [selectedImage, setSelectedImage] = useState("");
  const handleImageClick = (imageUrl: any) => {
    setSelectedImage(imageUrl);
    ImageModal.onOpen();
  };
  let totalSums: any = {};
  let shouldShowTotalRow = false;
  properties?.tableData?.forEach((header: any) => {
    if (header.computeTotal) {
      shouldShowTotalRow = true;
      totalSums[header.name] = 0;
    }
  });
  SimplifiedData?.forEach((data: any) => {
    properties?.tableData?.forEach((header: any) => {
      if (header.computeTotal && data[header.name]) {
        totalSums[header.name] += parseFloat(data[header.name]) || 0;
      }
    });
  });

  return (
    <Stack bg="white" px="16px" py="8px" mt="8px" borderRadius="16px">
      <Text
        fontSize="16px"
        fontWeight={400}
        color="textcolors.primary"
        lineHeight={1}
      >
        {question_title}
      </Text>
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              {hasInventoryType && (
                <Th textAlign={alignment} w="auto">
                  Image
                </Th>
              )}

              {properties?.tableData?.map((header: any, index: number) => (
                <Th
                  key={index}
                  textAlign={alignment}
                  w={header?.type === "text_area" ? "350px" : "auto"}
                >
                  {header.name}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {SimplifiedData?.map((data: any, index: number) => {
              return (
                <Tr key={index}>
                  {hasInventoryType && (
                    <Td cursor="pointer">
                      {data["mediaUrl"] ? (
                        <Image
                          h="50px"
                          w="70px"
                          borderRadius="8px"
                          src={data["mediaUrl"]}
                          onClick={() => handleImageClick(data["mediaUrl"])}
                        />
                      ) : (
                        <Text>No Image Found!</Text>
                      )}
                    </Td>
                  )}
                  <Fragment key={index}>
                    {properties?.tableData?.map(
                      (header: any, index: number) => (
                        <Td
                          key={index}
                          textAlign={alignment}
                          maxWidth={
                            header?.type === "text_area" ? "350px" : "auto"
                          }
                          overflowWrap="break-word"
                          whiteSpace="pre-line"
                        >
                          {data[header.name]}
                        </Td>
                      )
                    )}
                  </Fragment>
                </Tr>
              );
            })}
            {shouldShowTotalRow && (
              <Tr>
                {hasInventoryType && <Td></Td>}{" "}
                {/* Placeholder if image column exists */}
                {properties?.tableData?.map((header: any, index: number) => (
                  <Td key={index} textAlign={alignment} fontWeight="bold">
                    {header.computeTotal
                      ? totalSums[header.name].toFixed(2)
                      : ""}
                  </Td>
                ))}
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <ViewImageModal
        isOpen={ImageModal.isOpen}
        onClose={ImageModal.onClose}
        selectedImage={selectedImage}
      />
    </Stack>
  );
}

interface AnswersTypeTemperatureProps {
  question_title: string;
  question_value: string;
  properties: Template.QuestionProperties;
  alignment: "left" | "center" | "right";
}
export function AnswersTypeTemperature({
  question_title,
  question_value,
  properties,
  alignment,
}: AnswersTypeTemperatureProps) {
  const TemperatureMessage: {
    [key in Template.QuestionConditions]: string;
  } = {
    BETWEEN: `Temperature should be between ${properties.smaller}${properties.dUnit} and ${properties.greater}${properties.dUnit}`,
    GREATER_AND_EQUAL: `Temperature should be greater than or equal to ${properties.greater} ${properties.dUnit}`,
    LESS_AND_EQUAL: `Temperature should be less than or equal to ${properties.smaller} ${properties.dUnit}`,
  };
  return (
    <Stack
      backgroundColor="white"
      borderRadius="16px"
      px="16px"
      py="8px"
      mt="8px"
    >
      <Fragment>
        <Text
          fontSize="16px"
          fontWeight={400}
          color="textcolors.primary"
          lineHeight={1}
        >
          {question_title}
        </Text>
        <Text
          fontSize="24px"
          fontWeight={500}
          color="textcolors.primary"
          lineHeight="32px"
          textAlign={alignment}
        >
          {`${question_value}  ${properties.dUnit}`}
        </Text>
        <Text
          fontSize="13px"
          fontWeight={400}
          color="icons.primary"
          lineHeight={1}
          textAlign={alignment}
        >
          {TemperatureMessage[properties?.condition ?? "BETWEEN"]}
        </Text>
      </Fragment>
    </Stack>
  );
}

// interface AnswersTypeDateTimeProps {
//   question_title: string;
//   question_value: string;
//   properties: Template.QuestionProperties;
// }
// export function AnswersTypeDateTime({
//   question_title,
//   question_value,
//   properties,
// }: AnswersTypeDateTimeProps) {
//   const showDate = properties.date === 'true';
//   const showTime = properties.time === 'true';
//   const timestamp = question_value;
//   const date = moment(timestamp);
//   const time = moment(timestamp, 'HH:mm').format('hh:mm a');
//   return (
//     <Stack
//       backgroundColor="white"
//       borderRadius="16px"
//       px="16px"
//       py="8px"
//       mt="8px">
//       <Fragment>
//         <Text
//           fontSize="16px"
//           fontWeight={400}
//           color="textcolors.primary"
//           lineHeight={1}>
//           {question_title}
//         </Text>
//         <Text fontSize="16px" fontWeight={400} color="#828ea0" lineHeight={1}>
//           {showDate && showTime && moment(date).isValid()
//             ? `Date:
//             ${date ? date.format('DD-MM-YYYY') : 'unanswered'}, Time: ${
//                 date ? date.format('hh:mm a') : 'unanswered'
//               }`
//             : showDate
//             ? `Date: ${date.format('DD-MM-YYYY')}`
//             : showTime
//             ? `Time: ${date.format('hh:mm a')}`
//             : 'unanswered'}
//         </Text>
//       </Fragment>
//     </Stack>
//   );
// }
interface AnswersTypeDateTimeProps {
  question_title: string;
  question_value: string;
  properties: Template.QuestionProperties;
  alignment: "left" | "center" | "right";
}

export function AnswersTypeDateTime({
  question_title,
  question_value,
  properties,
  alignment,
}: AnswersTypeDateTimeProps) {
  const showDate = properties.date === "true";
  const showTime = properties.time === "true";
  const timestamp = question_value;
  const format =
    properties.date === "true" && properties.time === "true"
      ? "YYYY-MM-DDTHH:mm"
      : properties?.date === "true" && properties?.time === "false"
      ? "YYYY-MM-DD"
      : "HH:mm";
  const date = timestamp ? moment(timestamp, format) : null;
  const isValidDate = date && date.isValid();

  const formattedDate =
    isValidDate && showDate ? date.format("DD-MM-YYYY") : "No date";
  const formattedTime =
    isValidDate && showTime ? date.format("hh:mm a") : "No time";

  const dateString =
    showDate && showTime
      ? `Date: ${formattedDate}, Time: ${formattedTime}`
      : showDate
      ? `Date: ${formattedDate}`
      : showTime
      ? `Time: ${formattedTime}`
      : "unanswered";

  return (
    <Stack
      backgroundColor="white"
      borderRadius="16px"
      px="16px"
      py="8px"
      mt="8px"
    >
      <Fragment>
        <Text
          fontSize="16px"
          fontWeight={400}
          color="textcolors.primary"
          lineHeight={1}
        >
          {question_title}
        </Text>
        <Text
          textAlign={alignment}
          fontSize="16px"
          fontWeight={400}
          color="#828ea0"
          lineHeight={1}
        >
          {dateString}
        </Text>
      </Fragment>
    </Stack>
  );
}

interface QuestionProps {
  question_title: string;
  question_value: string;
  alignment: "left" | "center" | "right";
}
export function Question({
  question_title,
  question_value,
  alignment,
}: QuestionProps) {
  return (
    <Stack
      backgroundColor="white"
      borderRadius="16px"
      px="16px"
      py="8px"
      mt="8px"
    >
      <Fragment>
        <Text
          fontSize="16px"
          fontWeight={400}
          color="textcolors.primary"
          lineHeight={1}
        >
          {question_title}
        </Text>
        <Text
          fontSize="16px"
          fontWeight={400}
          color="#828ea0"
          lineHeight={1}
          textAlign={alignment}
        >
          {question_value}
        </Text>
      </Fragment>
    </Stack>
  );
}

interface AnswersTypeMediaProps {
  question_title: string;
  media: Template.MediaObject[];
  properties?: Template.QuestionProperties;
  alignment: "left" | "center" | "right";
}
export function AnswersTypeMedia({
  question_title,
  media,
  properties,
  alignment,
}: AnswersTypeMediaProps) {
  return (
    <Stack
      backgroundColor="white"
      borderRadius="16px"
      px="16px"
      py="8px"
      mt="8px"
    >
      <Text
        fontSize="16px"
        fontWeight={400}
        color="textcolors.primary"
        lineHeight={1}
      >
        {question_title}
      </Text>
      {properties?.banner === "true" ? (
        <Box
          w="100%"
          display="flex"
          justifyContent={alignment}
          alignItems="center"
          px="16px"
          py="8px"
          mt="8px"
        >
          <Image
            h="250px"
            w="100%"
            objectFit={"fill"}
            borderRadius="8px"
            src={media?.[0]?.mediaUrl}
          />
        </Box>
      ) : (
        <Fragment>
          <Wrap mt="16px">
            {media.map((item, i) => (
              <WrapItem key={i}>
                <Image
                  h="64px"
                  w="64px"
                  borderRadius="8px"
                  src={item.mediaUrl}
                />
              </WrapItem>
            ))}
          </Wrap>
        </Fragment>
      )}
    </Stack>
  );
}

interface AnswersTypeAnnotationProps {
  question_title: string;
  question_value: string;
  alignment: "left" | "center" | "right";
}
export function AnswersTypeAnnotation({
  question_title,
  question_value,
  alignment,
}: AnswersTypeAnnotationProps) {
  return (
    <Stack
      backgroundColor="white"
      borderRadius="16px"
      px="16px"
      py="8px"
      mt="8px"
    >
      <Fragment>
        <Text
          fontSize="16px"
          fontWeight={400}
          color="textcolors.primary"
          lineHeight={1}
        >
          {question_title}
        </Text>
        {question_value && (
          <Box display="flex" justifyContent={alignment} alignItems="center">
            <Image
              mt="16px"
              h="64px"
              w="64px"
              borderRadius="8px"
              src={question_value}
            />
          </Box>
        )}
      </Fragment>
    </Stack>
  );
}

interface AnswersTypeCheckboxProps {
  question_title: string;
  question_value: string;
  alignment: "left" | "center" | "right";
}
export function AnswersTypeCheckbox({
  question_title,
  question_value,
  alignment,
}: AnswersTypeCheckboxProps) {
  const checkBoxValue: boolean = JSON.parse(question_value);
  return (
    <Stack
      backgroundColor="white"
      borderRadius="16px"
      px="16px"
      py="8px"
      mt="8px"
    >
      <Box display="flex" justifyContent={alignment} alignItems="center">
        <Checkbox colorScheme="green" isChecked={checkBoxValue}>
          {question_title}
        </Checkbox>
      </Box>
    </Stack>
  );
}

interface AnswersTypeMcqsProps {
  question_title: string;
  question_value: string;
  question_color: string | undefined;
  alignment: "left" | "center" | "right";
}
export function AnswersTypeMcqs({
  question_title,
  question_value,
  question_color,
  alignment,
}: AnswersTypeMcqsProps) {
  return (
    <Stack
      backgroundColor="white"
      borderRadius="16px"
      px="16px"
      py="8px"
      mt="8px"
    >
      <Fragment>
        <Text
          fontSize="16px"
          fontWeight={400}
          color="textcolors.primary"
          lineHeight={1}
        >
          {question_title}
        </Text>
        <Box display="flex" justifyContent={alignment} alignItems="center">
          <Flex
            w="fit-content"
            mt="8px"
            mr="8px"
            borderRadius="12px"
            backgroundColor={question_color}
          >
            <Text
              py="4px"
              px="8px"
              lineHeight={1}
              color={
                question_value === "NOT_SELECTED"
                  ? "gray"
                  : question_color
                  ? "white"
                  : "black"
              }
            >
              {question_value === "NOT_SELECTED"
                ? "Not Selected"
                : question_value}
            </Text>
          </Flex>
        </Box>
      </Fragment>
    </Stack>
  );
}

interface QuestionImageProps {
  questionMedia: Template.MediaObject[];
}
export function QuestionImage({ questionMedia }: QuestionImageProps) {
  return (
    <Fragment>
      {questionMedia?.length > 0 && (
        <Wrap px="16px" pb="8px">
          {questionMedia.map((item, i) => (
            <WrapItem key={i}>
              <Image h="64px" w="64px" borderRadius="8px" src={item.mediaUrl} />
            </WrapItem>
          ))}
        </Wrap>
      )}
    </Fragment>
  );
}

interface AnswerNotesProps {
  notes?: string;
}
export function AnswerNotes({ notes }: AnswerNotesProps) {
  return (
    <Fragment>
      {notes && (
        <Stack px="16px" pb="8px">
          <Text
            fontSize="16px"
            fontWeight={400}
            color="textcolors.primary"
            lineHeight={1}
          >
            Notes
          </Text>
          <Text
            fontSize="16px"
            fontWeight={400}
            color="textcolors.primary"
            lineHeight={1}
            whiteSpace="pre-line"
          >
            {notes}
          </Text>
        </Stack>
      )}
    </Fragment>
  );
}
interface AnswerSignatureProps {
  question_title: string;
  question_value: string;
  questionMedia: Template.MediaObject[];
  alignment: "left" | "center" | "right";
}
export function AnswerSignature({
  questionMedia,
  question_title,
  question_value,
  alignment,
}: AnswerSignatureProps) {
  return (
    <Stack
      backgroundColor="white"
      borderRadius="16px"
      px="16px"
      py="8px"
      mt="8px"
    >
      <Fragment>
        <Text
          fontSize="16px"
          fontWeight={400}
          color="textcolors.primary"
          lineHeight={1}
        >
          {question_title}
        </Text>
        <HStack display="flex" justifyContent={alignment} alignItems="center">
          {questionMedia?.[0] && (
            <Image
              mt="16px"
              h="64px"
              w="64px"
              borderRadius="8px"
              src={questionMedia?.[0]?.mediaUrl}
            />
          )}

          <Text
            fontSize="16px"
            fontWeight={400}
            color="textcolors.primary"
            lineHeight={1}
          >
            {question_value}
          </Text>
        </HStack>
      </Fragment>
    </Stack>
  );
}
