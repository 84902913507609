import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { IoInformationCircle } from "react-icons/io5";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { EmployeeLeaveEntitlement } from "../../../Api/EmployeeLeaveEntitlement";
import { Employees } from "../../../Api/Employees";
import { PayItemLeaveApi } from "../../../Api/PayItemLeave";
import { DeleteLeaveEntitlement } from "./DeleteLeaveEntitlement";

interface AssignTypePros {
  isOpen: boolean;
  onClose: () => void;
  FecthedLeaves: Array<PayItemLeaveApi.LeaveById>;
  empId: string;
  setlastUpdated: (time: number) => void;
  payFrequencyAnnually?: number;
}
export function AssignType({
  isOpen,
  onClose,
  FecthedLeaves,
  empId,
  setlastUpdated,
}: AssignTypePros) {
  const form = useFormContext<EmployeeLeaveEntitlement.LeaveEntitlement>();
  const leaveCalculationOptions = [
    { value: "FIXED_AMOUNT_EACH_PERIOD", label: "Fixed Amount Each Period" },
    { value: "MANUALLY_RECOREDED_RATE", label: "Manually Recorded Rate" },
    { value: "NO_CALCULATION_REQUIRED", label: "No Calculation Required" },
  ];
  const unusedBalanceOptions = [
    { value: "NOT_PAID_OUT", label: "Not Paid Out" },
    { value: "PAID_OUT", label: "Paid Out" },
  ];
  const watchSelectedLeaveType = useWatch({
    control: form.control,
    name: "payrollPayItemLeaveId",
  });
  const watchLeaveCalculation = useWatch({
    control: form.control,
    name: "calculationMethod",
  });
  const watchUnUsedBalance = useWatch({
    control: form.control,
    name: "terminationUnusedBalance",
  });
  const watchETP = useWatch({
    control: form.control,
    name: "etpLeaveType",
  });
  useEffect(() => {
    FecthedLeaves?.find((op) => op.id === watchSelectedLeaveType)?.category ===
    "PAID_LEAVE"
      ? form.setValue("etpLeaveType", true)
      : form.setValue("etpLeaveType", false);
  }, [watchSelectedLeaveType]);
  const PAID_OUT_OPTION = { value: "PAID_OUT", label: "Paid Out" };
  const UNPAID_OUT_OPTION = { value: "NOT_PAID_OUT", label: "Unpaid Out" };
  useEffect(() => {
    const selectedLeave = FecthedLeaves?.find(
      (op) => op.id === watchSelectedLeaveType
    );
    if (selectedLeave) {
      const category = selectedLeave.category;
      if (
        form.getValues("terminationUnusedBalance") === undefined ||
        form.getValues("terminationUnusedBalance") === null
      ) {
        if (category === "PAID_LEAVE") {
          form.setValue("terminationUnusedBalance", PAID_OUT_OPTION.value);
        } else if (category === "UNPAID_LEAVE") {
          form.setValue("terminationUnusedBalance", UNPAID_OUT_OPTION.value);
        }
      }
    }
  }, [watchSelectedLeaveType, FecthedLeaves, form.setValue]);
  const toast = useToast();
  const CloseEntitlementModal = () => {
    onClose();
  };
  const [isLoading, setIsLoading] = useState(false);
  const deleteModal = useDisclosure();
  const [selectedEmployee, setSelectedEmployee] = useState<any>();
  useEffect(() => {
    if (empId) {
      Employees.FetchEmployeesPayrollEarning(
        empId!,
        (data) => {
          setSelectedEmployee(data);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
        }
      );
    }
  }, []);
  return (
    <Fragment>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="450px" maxH="550px">
          <ModalHeader
            _light={{
              color: "customColor.black7",
            }}
            fontSize=".9975rem"
            _dark={{ color: "customColor.white" }}
            padding={"15px 15px 14px"}
            fontWeight={"700"}
            lineHeight={"1.75rem"}
            h={"50px"}
            borderBottom={"1px solid #e8e8e8"}
          >
            Assign Leave Type
          </ModalHeader>
          <ModalCloseButton />
          <Box overflowY="auto">
            <ModalBody padding={"10px 15px"}>
              <Stack gap={0}>
                <Stack gap={0} margin={"0px"} padding={"7px 5px"}>
                  <Stack direction={"row"} gap={0}>
                    <Text
                      fontSize={"0.8125rem"}
                      fontWeight="600"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      lineHeight={"1.3077"}
                      margin={"0px 0px 5px"}
                      padding={"1px 0px 0px"}
                    >
                      Leave
                    </Text>
                    <Icon
                      as={IoInformationCircle}
                      color={"#999"}
                      w={"15px"}
                      h={"18px"}
                    />
                  </Stack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Select Leave Type is required",
                      },
                    }}
                    control={form.control}
                    name="payrollPayItemLeaveId"
                    render={({ field, fieldState }) => (
                      <ReactSelect
                        {...field}
                        key={field.value}
                        options={FecthedLeaves?.map((leave) => ({
                          value: leave.id,
                          label: leave.name,
                        }))}
                        withValidation
                        isDisabled={form.getValues("id") ? true : false}
                        isInvalid={fieldState.invalid}
                        ErrorMessage="Please Select Leave Type"
                        placeholder="Select..."
                        value={
                          FecthedLeaves?.find(
                            (leave) => leave.id === field.value
                          )
                            ? {
                                value: field.value,
                                label: FecthedLeaves?.find(
                                  (leave) => leave.id === field.value
                                )?.name,
                              }
                            : null
                        }
                        onChange={(selectedOption: any) =>
                          field.onChange(selectedOption.value)
                        }
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                      />
                    )}
                  />
                </Stack>
                {(watchSelectedLeaveType === "CARER'S_UNPAID" ||
                  watchSelectedLeaveType === "COMPASSIONATE_PAID" ||
                  watchSelectedLeaveType === "LONG_SERVICE" ||
                  watchSelectedLeaveType === "OTHERS_UNPAID" ||
                  watchSelectedLeaveType === "STUDY_LEAVE" ||
                  watchSelectedLeaveType === "COMPASSIONATE_UNPAID") && (
                  <Stack gap={0} margin={"0px "} padding={"7px 5px"}>
                    <Stack direction={"row"} gap={0}>
                      <Text
                        fontSize={"0.8125rem"}
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        lineHeight={"1.3077"}
                        margin={"0px 0px 5px"}
                        padding={"1px 0px 0px"}
                      >
                        Leave Category
                      </Text>
                      <Icon
                        as={IoInformationCircle}
                        color={"#999"}
                        w={"15px"}
                        h={"18px"}
                      />
                    </Stack>
                    <Text
                      fontSize="13px"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      lineHeight={"1.3077"}
                      marginBottom="-4px"
                      padding={"1px 0px 4px"}
                    >
                      {
                        FecthedLeaves?.find(
                          (op) => op.id === watchSelectedLeaveType
                        )?.name
                      }
                    </Text>
                  </Stack>
                )}

                <Stack gap={0} margin={"0px"} padding={"7px 5px"}>
                  <Stack direction={"row"} gap={0}>
                    <Text
                      fontSize={"0.8125rem"}
                      fontWeight="600"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      lineHeight={"1.3077"}
                      margin={"0px 0px 5px"}
                      padding={"1px 0px 0px"}
                    >
                      Leave Calculation Method
                    </Text>
                    <Icon
                      as={IoInformationCircle}
                      color={"#999"}
                      w={"15px"}
                      h={"18px"}
                    />
                  </Stack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Select Leave Calculation Method is required",
                      },
                    }}
                    control={form.control}
                    name="calculationMethod"
                    render={({ field, fieldState }) => (
                      <ReactSelect
                        {...field}
                        key={field.value}
                        options={leaveCalculationOptions}
                        withValidation
                        isInvalid={fieldState.invalid}
                        ErrorMessage="Select Leave Calculation Method "
                        placeholder="Select..."
                        value={leaveCalculationOptions.find(
                          (op) => op.value === field.value
                        )}
                        onChange={(newValue: any) => {
                          field.onChange(newValue?.value);
                          form.reset({
                            ...form.getValues(),
                            quantity: 0,
                            accruedQuantity: 0,
                            quantityPerPayPeriod: 0,
                            balance: 0,
                            openingBalance: 0,
                          });
                        }}
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                      />
                    )}
                  />
                </Stack>

                {watchLeaveCalculation === "MANUALLY_RECOREDED_RATE" && (
                  <Stack
                    margin={"0px"}
                    padding={"7px 5px"}
                    direction={"row"}
                    flex={1}
                  >
                    <Stack gap={0} flex={1}>
                      <Stack direction={"row"} gap={0}>
                        <Text
                          fontSize={"0.8125rem"}
                          fontWeight="600"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          lineHeight={"1.3077"}
                          margin={"0px 0px 5px"}
                          padding={"1px 0px 0px"}
                        >
                          {FecthedLeaves.find(
                            (op) => op.id === watchSelectedLeaveType
                          )?.unitType ?? "Days"}{" "}
                          Entitlement per payrun
                        </Text>
                        <Icon
                          as={IoInformationCircle}
                          color={"#999"}
                          w={"15px"}
                          h={"18px"}
                        />
                      </Stack>

                      <Controller
                        control={form.control}
                        name="quantity"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              h: "38px",
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "text",
                              bg: "#fff",
                              borderTopRadius: "3px",
                              onChange: (e) => {
                                field.onChange(Number(e.target.value));
                              },
                            }}
                            placeholder=""
                          />
                        )}
                      />
                    </Stack>
                  </Stack>
                )}

                {(watchLeaveCalculation === "FIXED_AMOUNT_EACH_PERIOD" ||
                  watchLeaveCalculation === "NO_CALCULATION_REQUIRED") && (
                  <Stack gap={0} margin={"0px"} padding={"7px 5px"}>
                    <Stack direction={"row"} gap={0}>
                      <Text
                        fontSize={"0.8125rem"}
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        lineHeight={"1.3077"}
                        margin={"0px 0px 5px"}
                        padding={"1px 0px 0px"}
                      >
                        Annual Entitlement/{" "}
                        {FecthedLeaves.find(
                          (op) => op.id === watchSelectedLeaveType
                        )?.unitType ?? "Days"}{" "}
                      </Text>
                      <Icon
                        as={IoInformationCircle}
                        color={"#999"}
                        w={"15px"}
                        h={"18px"}
                      />
                    </Stack>

                    <Controller
                      control={form.control}
                      name="accruedQuantity"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            h: "38px",
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "text",

                            borderTopRadius: "3px",
                            onChange: (e) => {
                              field.onChange(Number(e.target.value));
                              const days =
                                Number(e.target.value) /
                                selectedEmployee?.payFrequencyAnnually!;
                              form.setValue(
                                "quantity",
                                Number(days.toFixed(2))
                              );
                            },
                          }}
                          placeholder=""
                        />
                      )}
                    />
                  </Stack>
                )}
                {watchLeaveCalculation === "BASED_ON_ORDINARY_EARNINGS" && (
                  <Stack gap={0}>
                    <Stack margin={"0px"} padding={"7px 5px"} gap={0}>
                      <Stack direction={"row"} gap={0}>
                        <Text
                          fontSize={"0.8125rem"}
                          fontWeight="600"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          lineHeight={"1.3077"}
                          margin={"0px 0px 5px"}
                          padding={"1px 0px 0px"}
                        >
                          Days of leave accrued annually by a full-time employee
                        </Text>
                        <Icon
                          as={IoInformationCircle}
                          color={"#999"}
                          w={"15px"}
                          h={"18px"}
                        />
                      </Stack>

                      <Controller
                        control={form.control}
                        name="quantity"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              h: "38px",
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "text",

                              borderTopRadius: "3px",
                              onChange: (e) => {
                                field.onChange(Number(e.target.value));
                              },
                            }}
                            placeholder=""
                          />
                        )}
                      />
                    </Stack>
                    <Stack margin={"0px"} padding={"7px 5px"} gap={0}>
                      <Stack direction={"row"} gap={0}>
                        <Text
                          fontSize={"0.8125rem"}
                          fontWeight="600"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          lineHeight={"1.3077"}
                          margin={"0px 0px 5px"}
                          padding={"1px 0px 0px"}
                        >
                          Days of full-time employee working in a Fortnight pay
                        </Text>
                        <Icon
                          as={IoInformationCircle}
                          color={"#999"}
                          w={"15px"}
                          h={"18px"}
                        />
                      </Stack>

                      <Controller
                        control={form.control}
                        name="quantityPerPayPeriod"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              h: "38px",
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "text",

                              borderTopRadius: "3px",
                            }}
                            placeholder=""
                          />
                        )}
                      />
                    </Stack>
                  </Stack>
                )}

                <Stack direction={"row"} margin={"0px"} padding={"7px 5px"}>
                  <Stack gap={0} flex={1}>
                    <Stack direction={"row"} gap={0}>
                      <Text
                        fontSize={"0.8125rem"}
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        lineHeight={"1.3077"}
                        margin={"0px 0px 5px"}
                        padding={"1px 0px 0px"}
                      >
                        Opening balance this year
                      </Text>
                      <Icon
                        as={IoInformationCircle}
                        color={"#999"}
                        w={"15px"}
                        h={"18px"}
                      />
                    </Stack>
                    <Controller
                      control={form.control}
                      name="openingBalance"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            h: "38px",
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "text",
                            bg: "#fff",
                            borderTopRadius: "3px",
                            onChange: (e) => {
                              field.onChange(Number(e.target.value));
                            },
                          }}
                          placeholder=""
                        />
                      )}
                    />
                  </Stack>
                  {watchLeaveCalculation === "FIXED_AMOUNT_EACH_PERIOD" && (
                    <Stack gap={0} flex={1}>
                      <Stack
                        direction={"row"}
                        // Adjusted gap to better align text and icon
                      >
                        <Text
                          fontSize={"0.8125rem"}
                          fontWeight="600"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          lineHeight={"1.3077"}
                          margin={"0px 0px 5px"}
                          padding={"1px 0px 0px"}
                        >
                          {FecthedLeaves.find(
                            (op) => op.id === watchSelectedLeaveType
                          )?.unitType ?? "Days"}{" "}
                          Entitlement per payrun
                        </Text>
                        <Icon
                          as={IoInformationCircle}
                          color={"#999"}
                          w={"15px"}
                          h={"18px"}
                        />
                      </Stack>

                      <Controller
                        control={form.control}
                        name="quantity"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              h: "38px",
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "text",
                              isDisabled: true,

                              borderTopRadius: "3px",
                              onChange: (e) => {
                                field.onChange(Number(e.target.value));
                              },
                            }}
                            placeholder=""
                          />
                        )}
                      />
                      {/* <CustomInput
                       ref={field.ref}
                        withValidation
                        input={{
                          w: "100%",
                          padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        }, h:'38px',
                        fontSize: ".845rem",
                          variant: "outline",
                          bg: "#eee",
                          color: "#555",
                          isDisabled: true,
                          type: "text",
                          
                          borderTopRadius: "3px",
                        }}
                        placeholder=""
                      /> */}
                    </Stack>
                  )}
                </Stack>
              </Stack>
              <Stack margin={"0px"} padding={"7px 5px"} gap={0}>
                <Stack direction={"row"} gap={0}>
                  <Text
                    fontSize={"0.8125rem"}
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    lineHeight={"1.3077"}
                    margin={"0px 0px 5px"}
                    padding={"1px 0px 0px"}
                  >
                    On termination unused balance is
                  </Text>
                  <Icon
                    as={IoInformationCircle}
                    color={"#999"}
                    w={"15px"}
                    h={"18px"}
                  />
                </Stack>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: " UnUsed Balance  is required",
                    },
                  }}
                  control={form.control}
                  name="terminationUnusedBalance"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      options={unusedBalanceOptions}
                      withValidation
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select unused balance"
                      placeholder="Select..."
                      value={unusedBalanceOptions.find(
                        (op) => op.value === field.value
                      )}
                      onChange={(newValue: any) => {
                        field.onChange(newValue?.value);
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </Stack>
              {watchUnUsedBalance === "PAID_OUT" && !form.getValues("id") && (
                <Stack>
                  <Controller
                    control={form.control}
                    name="etpLeaveType"
                    render={({ field }) => (
                      <Checkbox
                        isChecked={field.value}
                        isDisabled={
                          FecthedLeaves?.find(
                            (op) => op.id === watchSelectedLeaveType
                          )?.category === "PAID_LEAVE"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      >
                        <Text
                          fontSize={"0.8125rem"}
                          fontWeight={"600"}
                          _light={{
                            color: "customColor.black7",
                          }}
                        >
                          ETP Leave Type
                        </Text>
                      </Checkbox>
                    )}
                  />
                  {watchETP && (
                    <Controller
                      control={form.control}
                      name="includeRetirementContribution"
                      render={({ field }) => (
                        <Checkbox
                          isChecked={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                          }}
                        >
                          <Text
                            fontSize={"0.8125rem"}
                            fontWeight={"600"}
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            Includes Superannuation Guarantee Contribution
                          </Text>
                        </Checkbox>
                      )}
                    />
                  )}
                </Stack>
              )}
            </ModalBody>
          </Box>

          <ModalFooter
            padding={"9px 15px 10px"}
            borderTop={"1px solid #e8e8e8"}
          >
            <HStack justifyContent="space-between" w="100%" align="end">
              {form.getValues("id") ? (
                <Button
                  h={"40px"}
                  variant="ghost"
                  borderRadius="4px"
                  padding={"6px 12px"}
                  fontSize={"13px"}
                  fontWeight={"normal"}
                  colorScheme="red"
                  onClick={() => {
                    deleteModal.onOpen();
                  }}
                >
                  Delete
                </Button>
              ) : (
                <Box flexShrink={0} visibility="hidden">
                  {" "}
                  {/* Invisible Box to maintain layout */}
                  <Button
                    variant="ghost"
                    borderRadius="4px"
                    padding={"6px 12px"}
                    fontSize={"13px"}
                    fontWeight={"normal"}
                    visibility="hidden"
                  >
                    Placeholder
                  </Button>
                </Box>
              )}
              {/* )} */}
              <ButtonGroup
                size="sm"
                marginTop="10px"
                display="flex"
                justifySelf="flex-end"
              >
                <Button
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  isLoading={isLoading}
                  onClick={(e) => {
                    form.handleSubmit((data) => {
                      setIsLoading(true);
                      const leaveData = { ...data, empId: empId! };
                      if (form.getValues("id")) {
                        EmployeeLeaveEntitlement.UpdateLeaveEntitlement(
                          form.getValues("id")!,
                          leaveData,
                          (success) => {
                            toast({
                              title: "Leave Type Updated Successfully",
                              description: success,
                              status: "success",
                            });
                            setlastUpdated(Date.now());
                            setIsLoading(false);
                            onClose();
                          },
                          (error) => {
                            toast({
                              title: "Error",
                              description: error,
                              status: "error",
                            });
                            setIsLoading(false);
                          }
                        );
                      } else {
                        EmployeeLeaveEntitlement.PostLeaveEntitlement(
                          leaveData,
                          (success) => {
                            toast({
                              title: "Leave Type Assigned Successfully",
                              description: success,
                              status: "success",
                            });
                            setlastUpdated(Date.now());
                            setIsLoading(false);
                            onClose();
                          },
                          (error) => {
                            toast({
                              title: "Error",
                              description: error,
                              status: "error",
                            });
                            setIsLoading(false);
                          }
                        );
                      }
                    })(e);
                  }}
                >
                  {isLoading ? "Saving..." : "Save"}
                </Button>
                <Button
                  backgroundColor={"transparent"}
                  borderRadius={"3px"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  h={"40px"}
                  variant={"solid"}
                  textColor={"#ea4d4d"}
                  padding={"12px 16px"}
                  marginRight={"5px"}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <DeleteLeaveEntitlement
        id={form.getValues("id")!}
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
        setLastUpdated={setlastUpdated}
        CloseEntitlementModal={CloseEntitlementModal}
      />
    </Fragment>
  );
}
