import imageCompression from 'browser-image-compression';
import {MEDIA_COMPRESS_OPTIONS} from '../constants';

export const compressImage = async (image: any) => {
  try {
    const compressedFile = await imageCompression(
      image,
      MEDIA_COMPRESS_OPTIONS,
    );
    return compressedFile;
  } catch (error) {
    throw error;
  }
};
