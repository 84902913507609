import {
  Box,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from "@chakra-ui/react";
import update from "immutability-helper";
import moment, { Moment } from "moment";
import { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AiFillCopy } from "react-icons/ai";
import { BiSolidPaste } from "react-icons/bi";
import { FaArrowLeft, FaArrowRight, FaPaste } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { MdAddBox } from "react-icons/md";
import { useDebouncedCallback } from "use-debounce";
import { DragContainer } from "../../../../Common/Dnd/drag-container";
import { Draggable } from "../../../../Common/Dnd/draggable";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { Employees } from "../../../../Payroll/Api/Employees";
import { Shift } from "../../../API/Shift";
import { Shifts } from "../../../API/Shifts";
import ShiftTag from "../Common/ShiftTag";
import { useScheduleContext } from "../Hooks/ScheduleContext";
interface FortnightProps {
  startDate: Moment;
  selectedShiftId: (shiftId: string) => void;
  selectedTimestamp: (timestamp: number) => void;
  setViewShiftsIds: (ids: string[]) => void;
  employeesList: Array<Employees.FetchEmployees>;
  pageNumber: number;
  pageCount: number;
  setPageNumber: (value: number) => void;
  setSearchText: (value: string) => void;
  setExcelShiftsData: any;
}

export default function Fortnight({
  startDate,
  selectedTimestamp,
  selectedShiftId,
  setViewShiftsIds,
  employeesList,
  pageNumber,
  pageCount,
  setPageNumber,
  setSearchText,
  setExcelShiftsData,
}: FortnightProps) {
  const daysOfWeek = Array.from(Array(14), (_, i) =>
    moment(startDate).add(i, "days")
  );
  const [shifts, setShifts] = useState<Shift.EmployeeDateWiseShift>({});
  const {
    copiedShifts,
    lastUpdated,
    setBulkCopiedShifts,
    copiedBulkShifts,
    setLastUpdated,
  } = useScheduleContext();
  const { locationList } = useBusinessContext();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [shiftsData, setShiftsData] = useState<
    Array<Shifts.FetchByDatesResponse>
  >([]);

  useEffect(() => {
    Shifts.FetchShiftsByDate(
      {
        startDate: startDate.clone().format("YYYY-MM-DD"),
        endDate: startDate.clone().add(13, "days").format("YYYY-MM-DD"),
      },
      (allShifts) => {
        setShiftsData(allShifts);
        setExcelShiftsData(allShifts);
        const shiftIds = allShifts.flatMap((shift) =>
          shift.shifts.map((s) => s.id)
        );

        setViewShiftsIds(shiftIds);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, [lastUpdated]);
  const findShiftsForEmployeeAndDate = (empId: string, date: Moment) => {
    const dayShifts = shiftsData.find(
      (shift) => shift.date === date.format("YYYY-MM-DD")
    );
    const employeeShifts = dayShifts?.shifts.filter(
      (shift) => shift.employeeId === empId
    );
    return employeeShifts || [];
  };
  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const debounced = useDebouncedCallback((value) => {
    setSearchText(value);
  }, 1000);
  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };

  const renderPageNumbers = () => {
    const pageButtons = [];

    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            aria-label={`Page ${i}`}
            border={"1px solid #e8eaf2"}
            borderRadius={"full"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }
    } else {
      pageButtons.push(
        <IconButton
          key={1}
          aria-label={`Page 1`}
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          backgroundColor={pageNumber === 1 ? "#3454D1" : "#fff"}
          color={pageNumber === 1 ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === 1 ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>1</Text>}
          onClick={() => handlePageChange(1)}
        />
      );

      if (pageNumber > 3) {
        pageButtons.push(<Text key="left-dots">...</Text>);
      }

      const startPage = Math.max(2, pageNumber - 1);
      const endPage = Math.min(pageCount - 1, pageNumber + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            border={"1px solid #e8eaf2"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            aria-label={`Page ${i}`}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }

      if (pageNumber < pageCount - 2) {
        pageButtons.push(<Text key="right-dots">...</Text>);
      }

      pageButtons.push(
        <IconButton
          key={pageCount}
          border={"1px solid #e8eaf2"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          borderRadius={"full"}
          aria-label={`Page ${pageCount}`}
          backgroundColor={pageNumber === pageCount ? "#3454D1" : "#fff"}
          color={pageNumber === pageCount ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === pageCount ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>{pageCount}</Text>}
          onClick={() => handlePageChange(pageCount)}
        />
      );
    }

    return pageButtons;
  };

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  return (
    <Stack
      _light={{
        bgColor: "customColor.white",
      }}
    >
      <DndProvider backend={HTML5Backend}>
        <Stack
          spacing="2px"
          _dark={{
            bgColor: "customColor.dark.100",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          marginBottom={"10px"}
        >
          <TableContainer backgroundColor="white" overflowY="auto">
            {/* overflowY="auto" */}
            <Table
              style={{
                borderCollapse: "separate",
                borderSpacing: "0",
              }}
            >
              <Thead
                position="sticky"
                top="0px"
                backgroundColor="white"
                zIndex={8}
              >
                <Tr zIndex={6}>
                  <Th
                    minW="150px"
                    h="48px"
                    fontWeight={500}
                    padding={"8px 12px"}
                    borderColor="borders.accent"
                    textAlign="center"
                    textColor="white"
                    backgroundColor="primary.500"
                    position="sticky"
                    left={0}
                    borderRight="1px"
                    borderRightColor="borders.accent"
                    fontSize="14px"
                    _dark={{
                      bgColor: "customColor.dark.50",
                    }}
                    _light={{
                      bgColor: "customColor.white",
                      color: "customColor.black7",
                    }}
                  >
                    {/* <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<AiOutlineSearch color="#545f70" size="18px" />}
                  />
                  <Input
                    maxW={{xs: '100%', md: '240px'}}
                    h="40px"
                    placeholder="Search Employee"
                    border="1px solid"
                    borderColor="borders.accent"
                    fontSize="12px"
                    fontWeight={400}
                    color="textcolors.primary"
                    pl="38px"
                    backgroundColor="white"
                    _focusVisible={{
                      boxShadow: 'none',
                      border: '1px sloid',
                      borderColor: 'primary.500',
                    }}
                    //   onChange={e => {
                    //     setSearchText(e.target.value);
                    //   }}
                  />
                </InputGroup> */}
                    <InputGroup ml={[0, "0px"]} backgroundColor="white">
                      <InputLeftElement>
                        <Icon as={FiSearch} />
                      </InputLeftElement>
                      <Input
                        type="text"
                        placeholder="Search"
                        borderRadius={"3px"}
                        h={["auto", "40px"]}
                        _focusVisible={{
                          border: "1px solid grey",
                        }}
                        onChange={(e) => {
                          debounced(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </Th>
                  {daysOfWeek.map((day) => {
                    return (
                      <Th
                        p={0}
                        minW="18vh"
                        borderColor="borders.accent"
                        backgroundColor="white"
                        borderRight="1px"
                        borderRightColor="borders.accent"
                        key={day.format("dddd, MMMM Do")}
                      >
                        <Stack
                          h="48px"
                          spacing="1px"
                          py="5px"
                          textAlign="center"
                          _dark={{
                            bgColor: "customColor.dark.50",
                          }}
                          _light={{
                            bgColor: "customColor.white",
                          }}
                        >
                          <Text
                            fontWeight={700}
                            fontSize="12px"
                            _dark={{
                              bgColor: "customColor.dark.50",
                            }}
                            _light={{
                              bgColor: "customColor.white",
                              color: "customColor.black7",
                            }}
                            textTransform="uppercase"
                          >
                            {day.format("dddd")}
                          </Text>
                          <Text
                            fontWeight={500}
                            fontSize="14px"
                            _dark={{
                              bgColor: "customColor.dark.50",
                            }}
                            _light={{
                              bgColor: "customColor.white",
                              color: "customColor.black7",
                            }}
                            letterSpacing="-1.5px"
                          >
                            {day.format("D")}
                          </Text>
                        </Stack>
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody position="relative">
                {employeesList?.map(
                  (emp: Employees.FetchEmployees, i: number) => {
                    return (
                      <Tr key={emp.id}>
                        <Td
                          fontSize="13px"
                          fontWeight={500}
                          px="4px"
                          py={0}
                          minH="21px"
                          borderColor="borders.accent"
                          textAlign="center"
                          color="textcolors.primary"
                          position="sticky"
                          backgroundColor="white"
                          left={0}
                          borderRight="1px"
                          borderRightColor="borders.accent"
                          zIndex={5}
                        >
                          {emp.firstName + " " + emp.lastName}
                        </Td>
                        {daysOfWeek.map((date, k) => {
                          const shiftArr = findShiftsForEmployeeAndDate(
                            emp.id,
                            date
                          );
                          return (
                            <Td
                              key={k}
                              p={0}
                              borderColor="borders.accent"
                              role="group"
                            >
                              <DragContainer<
                                Shifts.ShiftData & {
                                  dateIndex: number;
                                  shiftIndex: number;
                                }
                              >
                                accept="SHIFT"
                                onDrop={(shift) => {
                                  const dateIndex = shiftsData.findIndex(
                                    (s) => s.date === date.format("YYYY-MM-DD")
                                  );
                                  shift.shiftStartDate =
                                    date.format("YYYY-MM-DD");
                                  shift.employeeId = emp.id;
                                  setShiftsData(
                                    update(shiftsData, {
                                      ...(dateIndex === -1
                                        ? {
                                            [shiftsData.length]: {
                                              $set: {
                                                date: date.format("YYYY-MM-DD"),
                                                shifts: [shift],
                                              },
                                            },
                                          }
                                        : {
                                            [dateIndex]: {
                                              shifts: {
                                                $push: [shift],
                                              },
                                            },
                                          }),

                                      [shift.dateIndex]: {
                                        shifts: {
                                          $splice: [[shift.shiftIndex, 1]],
                                        },
                                      },
                                    })
                                  );
                                  Shifts.UpdateShift(
                                    shift.id,
                                    {
                                      shiftStartDate: date.format("YYYY-MM-DD"),
                                      shiftEndDate: date.format("YYYY-MM-DD"),
                                      employeeId: emp.id,
                                    },
                                    (success) => {
                                      toast({
                                        title: "Shift Updated",
                                        status: "success",
                                      });
                                      setLastUpdated(Date.now());
                                    },
                                    (error) => {
                                      toast({
                                        title: "Error",
                                        description: error,
                                        status: "error",
                                      });
                                    }
                                  );
                                }}
                              >
                                <Box
                                  textAlign="right"
                                  alignItems="center"
                                  borderLeft="1px"
                                  borderColor="borders.accent"
                                  h="15vh"
                                  overflowY="auto"
                                >
                                  <HStack
                                    direction="row"
                                    position="sticky"
                                    top="0px"
                                    justify="center"
                                    backgroundColor="white"
                                    zIndex={2}
                                    display={
                                      shiftArr?.length > 0 ? "flex" : "none"
                                    }
                                    _groupHover={{
                                      display: "flex",
                                    }}
                                  >
                                    <Tooltip label="Add shift" fontSize="md">
                                      <IconButton
                                        aria-label="Add-button"
                                        icon={<MdAddBox />}
                                        size="xs"
                                        variant="ghost"
                                        onClick={() => {
                                          const timestamp = date
                                            .clone()
                                            .set({
                                              hour: 0,
                                              minute: 0,
                                              second: 0,
                                            })
                                            .unix();
                                          selectedTimestamp(timestamp);
                                        }}
                                      />
                                    </Tooltip>
                                    <Tooltip
                                      label="Copy all shifts"
                                      fontSize="md"
                                    >
                                      <IconButton
                                        aria-label="copy-all"
                                        icon={<AiFillCopy />}
                                        size="xs"
                                        variant="ghost"
                                        onClick={() => {
                                          setBulkCopiedShifts({
                                            shiftId: undefined,
                                            copyAll: true,
                                            copyDate: date.format("YYYY-MM-DD"),
                                            copyFromEmployeeId: emp.id,
                                          });
                                        }}
                                      />
                                    </Tooltip>
                                    <Tooltip
                                      label="Paste all shifts"
                                      fontSize="md"
                                    >
                                      <IconButton
                                        aria-label="paste-all"
                                        icon={<FaPaste />}
                                        size="xs"
                                        variant="ghost"
                                        onClick={() => {
                                          if (copiedBulkShifts) {
                                            Shifts.CopyPasteShift(
                                              {
                                                copyAll:
                                                  copiedBulkShifts.copyAll,
                                                copyDate:
                                                  copiedBulkShifts.copyDate,
                                                copyFromEmployeeId:
                                                  copiedBulkShifts.copyFromEmployeeId,
                                                pasteDate:
                                                  date.format("YYYY-MM-DD"),
                                                copyToEmployeeId: emp.id,
                                              },
                                              () => {
                                                toast({
                                                  title: "Shifts Pasted",
                                                  status: "success",
                                                });
                                                setLastUpdated(Date.now());
                                              },
                                              () => {
                                                toast({
                                                  title: "Error",
                                                  description:
                                                    "Failed to paste",
                                                  status: "error",
                                                });
                                              }
                                            );
                                          } else {
                                            toast({
                                              title: "Error",
                                              description: "No shifts copied",
                                              status: "error",
                                            });
                                          }
                                        }}
                                      />
                                    </Tooltip>
                                    <Tooltip label="Paste" fontSize="md">
                                      <IconButton
                                        aria-label="paste"
                                        icon={<BiSolidPaste />}
                                        size="xs"
                                        variant="ghost"
                                        onClick={() => {
                                          if (copiedShifts) {
                                            Shifts.CopyPasteShift(
                                              {
                                                shiftId: copiedShifts.shiftId,
                                                copyAll: copiedShifts.copyAll,
                                                copyDate: copiedShifts.copyDate,
                                                copyFromEmployeeId:
                                                  copiedShifts.copyFromEmployeeId,
                                                pasteDate:
                                                  date.format("YYYY-MM-DD"),
                                                copyToEmployeeId: emp.id,
                                              },
                                              () => {
                                                toast({
                                                  title: "Shift Pasted",
                                                  status: "success",
                                                });
                                                setLastUpdated(Date.now());
                                              },
                                              () => {
                                                toast({
                                                  title: "Error",
                                                  description:
                                                    "Failed to paste",
                                                  status: "error",
                                                });
                                              }
                                            );
                                          } else {
                                            toast({
                                              title: "Error",
                                              description: "No shift copied",
                                              status: "error",
                                            });
                                          }
                                        }}
                                      />
                                    </Tooltip>
                                  </HStack>

                                  {shiftArr?.map((shift, shiftIndex) => (
                                    <Draggable<
                                      Shifts.ShiftData & {
                                        dateIndex: number;
                                        shiftIndex: number;
                                      }
                                    >
                                      item={{
                                        ...shift,
                                        dateIndex: shiftsData.findIndex(
                                          (s) =>
                                            s.date === date.format("YYYY-MM-DD")
                                        ),
                                        shiftIndex,
                                      }}
                                      type="SHIFT"
                                    >
                                      <ShiftTag
                                        key={shift.id}
                                        shift={shift}
                                        onClicked={() => {
                                          selectedShiftId(shift.id);
                                        }}
                                      />
                                    </Draggable>
                                  ))}
                                </Box>
                              </DragContainer>
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            </Table>
            {/* <HStack spacing="13px" justify={{ xs: "center", md: "end" }} my="8px">
            <IconButton
              aria-label="back"
              backgroundColor="transparent"
              _hover={{ backgroundColor: "transparent" }}
              icon={
                <BsChevronLeft
                  size={19}
                  color={
                    pageNumber <= 1 || pageNumber > pageCount
                      ? "#828ea0"
                      : "#000000"
                  }
                />
              }
              isDisabled={
                pageNumber <= 1 || pageNumber > pageCount ? true : false
              }
              onClick={() => {
                if (pageNumber > 1 && pageNumber <= pageCount)
                  pageNumberInputRef.current?.value &&
                    (pageNumberInputRef.current.value = String(pageNumber - 1));
                setPageNumber(pageNumber - 1);
              }}
            />
            <Input
              ref={pageNumberInputRef}
              h="37px"
              w="56px"
              backgroundColor="backgrounds.primary"
              defaultValue={pageNumber.toString()}
              textAlign="center"
              borderColor="borders.accent"
              onBlur={(e) => {
                const pageValue = e.target.value;
                setPageNumber(parseInt(pageValue));
              }}
            />

            <Text color="textcolors.secondary">/</Text>
            <Text w="40px" textAlign="center">
              {pageCount}
            </Text>
            <IconButton
              aria-label="right"
              backgroundColor="transparent"
              _hover={{ backgroundColor: "transparent" }}
              icon={
                <BsChevronRight
                  size={19}
                  color={
                    pageNumber >= pageCount || pageNumber < 1
                      ? "#828ea0"
                      : "#000000"
                  }
                />
              }
              isDisabled={
                pageNumber >= pageCount || pageNumber < 1 ? true : false
              }
              onClick={() => {
                if (pageNumber < pageCount && pageNumber >= 1) {
                  pageNumberInputRef.current?.value &&
                    (pageNumberInputRef.current.value = String(pageNumber + 1));
                  setPageNumber(pageNumber + 1);
                }
              }}
            />
          </HStack> */}
          </TableContainer>
          <HStack spacing="13px" justify={{ xs: "center", md: "end" }}>
            <IconButton
              aria-label="back"
              border={"1px solid #e8eaf2"}
              borderRadius={"full"}
              backgroundColor="transparent"
              _hover={{
                backgroundColor: "#3454D1",
                color: "white",
              }}
              onMouseEnter={() => setIsHovered2(true)}
              onMouseLeave={() => setIsHovered2(false)}
              icon={
                <FaArrowLeft
                  size={15}
                  color={
                    isHovered2
                      ? "#FFFFFF"
                      : pageNumber <= 1 || pageNumber > pageCount
                      ? "#828ea0"
                      : "#283c50"
                  }
                />
              }
              isDisabled={pageNumber <= 1 || pageNumber > pageCount}
              onClick={() => {
                if (pageNumber > 1 && pageNumber <= pageCount) {
                  pageNumberInputRef.current?.value &&
                    (pageNumberInputRef.current.value = String(pageNumber - 1));
                  setPageNumber(pageNumber - 1);
                }
              }}
            />
            <HStack spacing="13px">{renderPageNumbers()}</HStack>
            <IconButton
              aria-label="right"
              backgroundColor="transparent"
              border={"1px solid #e8eaf2"}
              borderRadius={"full"}
              _hover={{
                backgroundColor: "#3454D1",
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              icon={
                <FaArrowRight
                  size={15}
                  color={
                    isHovered
                      ? "#FFFFFF"
                      : pageNumber >= pageCount || pageNumber < 1
                      ? "#828ea0"
                      : "#283c50"
                  }
                  style={{ transition: "color 0.2s" }} // Add smooth transition for color change
                />
              }
              isDisabled={pageNumber >= pageCount || pageNumber < 1}
              onClick={() => {
                if (pageNumber < pageCount && pageNumber >= 1) {
                  pageNumberInputRef.current?.value &&
                    (pageNumberInputRef.current.value = String(pageNumber + 1));
                  setPageNumber(pageNumber + 1);
                }
              }}
            />
          </HStack>
        </Stack>
      </DndProvider>
    </Stack>
  );
}
