export function getFieldKey(
  {
    pageIndex,
    questionIndex,
    sectionIndex,
  }: {
    questionIndex?: number;
    sectionIndex?: number;
    pageIndex: number;
  },
  extendedField?: string,
) {
  if (sectionIndex !== undefined) {
    if (extendedField) {
      return `templateItems.${pageIndex}.children.${sectionIndex}.children.${questionIndex}.${extendedField}`;
    } else {
      return `templateItems.${pageIndex}.children.${sectionIndex}.children.${questionIndex}`;
    }
  } else {
    if (extendedField) {
      return `templateItems.${pageIndex}.children.${questionIndex}.${extendedField}`;
    } else {
      return `templateItems.${pageIndex}.children.${questionIndex}`;
    }
  }
}

export function generatePathForReportLayout(
  {
    pageIndex,
    questionIndex,
    sectionIndex,
  }: {
    questionIndex?: number;
    sectionIndex?: number;
    pageIndex: number;
  },
  extendedField?: string,
): any {
  let path = `reportStructure.${pageIndex}`;

  if (sectionIndex !== undefined) {
    path += `.children.${sectionIndex}`;
  }

  if (questionIndex !== undefined) {
    path += `.children.${questionIndex}`;
  }

  if (extendedField !== undefined) {
    path += `.${extendedField}`;
  }

  return path;
}
