import {
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { Fragment } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { FiMoreVertical } from "react-icons/fi";
import { MdDelete, MdEdit } from "react-icons/md";
import { MCQ } from "../../../../../../modules/Audit/MCQs";

interface ResponseTableLgProps {
  onRowClick: (options: any) => void;
  data: Array<MCQ.IMCQ>;
  setLastUpdate: (timestamp: number) => void;
}

export default function ResponseTableLg({
  onRowClick,
  data,
  setLastUpdate,
}: ResponseTableLgProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const menuitems: Array<{
    label: string;
    icon: any;
  }> = [
    {
      label: "Edit",
      icon: MdEdit,
    },
    {
      label: "Delete",
      icon: MdDelete,
    },
  ];
  return (
    <TableContainer
      border="1px solid"
      borderRadius="5px"
      borderColor="borders.accent"
    >
      <Table>
        <Thead bg="backgrounds.primary">
          <Tr>
            <Th
              w="33%"
              p="16px"
              fontSize="13px"
              fontWeight={400}
              lineHeight="1rem"
              color="textcolors.primary"
              textDecoration="underline"
              borderColor="borders.accent"
            >
              Name
            </Th>
            <Th w="33%" p="16px" borderColor="borders.accent">
              <HStack>
                <Text
                  fontSize="13px"
                  fontWeight={400}
                  lineHeight="1rem"
                  color="textcolors.primary"
                  textDecoration="underline"
                >
                  last Modified
                </Text>
                <AiOutlineArrowDown size={18} color="#3f495a" />
              </HStack>
            </Th>
            <Th
              w="33%"
              p="16px"
              fontSize="13px"
              fontWeight={400}
              lineHeight="1rem"
              color="textcolors.primary"
              borderColor="borders.accent"
            >
              Responses
            </Th>
            <Th w="1%" p="16px"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((options, i) => {
            return (
              <Tr
                key={i}
                onClick={() => {
                  onRowClick(options);
                }}
              >
                <Td
                  pl="16px"
                  pr="0px"
                  py="16px"
                  fontSize="14px"
                  color="textcolors.secondary"
                  borderColor="borders.accent"
                >
                  {options.name}
                </Td>
                <Td
                  pl="16px"
                  pr="0px"
                  py="16px"
                  fontSize="14px"
                  color="textcolors.secondary"
                  borderColor="borders.accent"
                >
                  {moment(options.updatedAt).fromNow()}
                </Td>
                <Td
                  pl="16px"
                  pr="0px"
                  py="16px"
                  fontSize="14px"
                  color="textcolors.secondary"
                  borderColor="borders.accent"
                >
                  {options.options.length}
                </Td>
                <Td
                  p={0}
                  borderColor="borders.accent"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<FiMoreVertical size={24} color="#545f70" />}
                      backgroundColor="transparent"
                      borderRadius="50%"
                      _hover={{ backgroundColor: "gray.300" }}
                      _active={{ backgroundColor: "gray.300" }}
                    />
                    <MenuList minW="180px">
                      {menuitems.map((menu, i) => {
                        return (
                          <Fragment key={i}>
                            <MenuItem
                              px="16px"
                              py="8px"
                              onClick={() => {
                                if (menu.label === "Delete") {
                                  if (options.id) {
                                    MCQ.Delete(
                                      options.id,
                                      () => {
                                        console.log("Deleted");
                                        setLastUpdate(Date.now());
                                      },
                                      () => {
                                        console.log("Error");
                                      }
                                    );
                                  }
                                } else {
                                  onRowClick(options);
                                }
                              }}
                            >
                              <HStack>
                                <Icon
                                  as={menu.icon}
                                  boxSize={18}
                                  color="#1f2533"
                                />
                                <Text
                                  fontSize="14px"
                                  color="textcolors.primary"
                                >
                                  {menu.label}
                                </Text>
                              </HStack>
                            </MenuItem>
                          </Fragment>
                        );
                      })}
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
