import {
  Box,
  Container,
  HStack,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { Controller, UseFormReturn } from "react-hook-form";

import { Country, State } from "country-state-city";
import { useEffect, useState } from "react";
import { CustomInput } from "../../../../../../../Common/CustomInput";
import { ReactSelect } from "../../../../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../../../../Hooks/BusinessContext";
import { ChartOfAccounts } from "../../../../../../Api/ChartOfAccounts";
import { ExpenseClaimApi } from "../../../../../../Api/ExpenseClaimApi";
interface Props {
  form: UseFormReturn<ExpenseClaimApi.AddExpenseClaim, any, undefined>;
}
export function AddMileageClaimLeftSection({ form }: Props) {
  const [provinces, setProvinces] = useState<
    Array<{
      name: string;
    }>
  >([]);

  useEffect(() => {
    const mileageToClaim = form.watch("milageToClaim") ?? 0;
    const mileageRate = form.watch("milageRate") ?? 0;
    const total = mileageToClaim * mileageRate;

    // Set the total value in the form
    form.setValue("totalAmount", total);
  }, [form.watch("milageToClaim"), form.watch("milageRate")]);
  useEffect(() => {
    const provinceOptions = State.getStatesOfCountry(
      countryData.find(
        (country) =>
          country.name ===
          businessesList.find((business) => business.id === currentBusinessId)
            ?.country
      )?.isoCode
    );
    const province = provinceOptions.map((province: any) => {
      return { name: province?.name };
    });
    setProvinces(province);
  }, []);

  const [chartOfAccounts, setChartOfAccounts] = useState<
    Array<ChartOfAccounts.FetchChartOfAccounts>
  >([]);

  const toast = useToast();
  useEffect(() => {
    ChartOfAccounts.GetAllAccounts(
      {
        category: "EXPENSES",

        page: 1,
        limit: 100,
      },
      (res) => {
        setChartOfAccounts(res.data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, []);
  const { businessesList, currentBusinessId } = useBusinessContext();
  let countryData = Country.getAllCountries();
  const countryName = businessesList.find(
    (business) => business.id === currentBusinessId
  )?.country;
  const currencyData = countryData.find(
    (country) => country.name === countryName
  )?.currency;
  return (
    <Container maxW={"500px"} padding={"20px"}>
      <Stack spacing={"25px"}>
        <HStack>
          <Controller
            rules={{
              required: {
                value: true,
                message: "Mileage to claim is required",
              },
            }}
            control={form.control}
            name="milageToClaim"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  h: "38px",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  fontSize: ".845rem",
                  variant: "outline",
                  borderTopRadius: "3px",
                  type: "number",
                }}
                placeholder="Mileage to claim(km)"
                label="Mileage to claim(km)"
                labelProps={{
                  fontSize: ".845rem",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
              />
            )}
          />

          <Controller
            rules={{
              required: {
                value: true,
                message: "Rate is required",
              },
            }}
            control={form.control}
            name="milageRate"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  h: "38px",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  fontSize: ".845rem",
                  variant: "outline",
                  borderTopRadius: "3px",
                  type: "number",
                }}
                placeholder="Rate"
                label="Rate (per km)"
                labelProps={{
                  fontSize: ".845rem",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
              />
            )}
          />
        </HStack>
        <Box>
          <Text
            fontSize={"13px"}
            fontWeight={"600"}
            color={"customColor.black7"}
            marginBottom={"8px"}
          >
            Description
          </Text>
          <Controller
            name="description"
            rules={{
              required: {
                value: true,
                message: "Message is required",
              },
            }}
            control={form.control}
            render={({ field, fieldState }) => (
              <>
                <Textarea
                  {...field}
                  placeholder="Add a message"
                  fontSize=".845rem"
                  isInvalid={fieldState.invalid}
                  fontWeight={400}
                  border="1px solid #e5e7eb"
                  _hover={{
                    border: "1px solid hsl(0, 0%, 70%)",
                  }}
                  _focus={{
                    borderWidth: "1px",

                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  _focusVisible={{
                    borderWidth: "1px",
                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  _light={{
                    color: "customColor.black7",
                  }}
                  padding="12px 15px"
                />
              </>
            )}
          />
        </Box>
        <Controller
          rules={{
            required: {
              value: true,
              message: "Traveled Date is required",
            },
          }}
          control={form.control}
          name="date"
          render={({ field, fieldState: { error } }) => (
            <CustomInput
              ref={field.ref}
              validationMessage={error?.message}
              withValidation
              input={{
                ...field,
                isInvalid: error !== undefined,
                w: "100%",
                h: "38px",
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: ".845rem",
                variant: "outline",

                borderTopRadius: "3px",
                type: "date",
              }}
              placeholder="TraveledOn"
              label="Traveled On"
              labelProps={{
                fontSize: ".845rem",
                _light: {
                  color: "customColor.black7",
                },
              }}
            />
          )}
        />
        <Box>
          <Text
            fontSize={"13px"}
            fontWeight={"600"}
            color={"customColor.black7"}
            marginBottom={"8px"}
          >
            Account
          </Text>
          <Controller
            name={`chartAccountId`}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Account is required",
              },
            }}
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                placeholder=""
                isInvalid={fieldState.invalid}
                ErrorMessage="Select chart of account"
                withValidation
                options={chartOfAccounts?.map((acc: any) => ({
                  value: acc.id,
                  label: `${acc.code}: ${acc.name}`, // Including code before name
                }))}
                value={chartOfAccounts
                  ?.map((acc: any) => ({
                    value: acc.id,
                    label: `${acc.code}: ${acc.name}`, // Including code before name
                  }))
                  .find((option: any) => option.value === field.value)}
                onChange={(newValue: any) => field.onChange(newValue?.value)}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            )}
          />
        </Box>
        <Box>
          <Text
            fontSize={"13px"}
            fontWeight={"600"}
            color={"customColor.black7"}
            marginBottom={"8px"}
          >
            Region
          </Text>
          <Controller
            control={form.control}
            name={`region`}
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                key={field.value}
                options={provinces}
                ErrorMessage="Please Select Region"
                placeholder="Select..."
                value={provinces?.find((op: any) => op.name === field.value)}
                onChange={(newValue: any) => {
                  field.onChange(newValue?.name);
                }}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.name}
              />
            )}
          />
        </Box>
        <Controller
          control={form.control}
          name="assignTo"
          render={({ field, fieldState: { error } }) => (
            <CustomInput
              input={{
                ...field,

                w: "100%",
                h: "38px",
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: ".845rem",
                variant: "outline",

                borderTopRadius: "3px",
                type: "text",
              }}
              placeholder="Assign to project or customer"
              label="Assign to project or customer"
              labelProps={{
                fontSize: ".845rem",
                _light: {
                  color: "customColor.black7",
                },
              }}
            />
          )}
        />
        <Controller
          control={form.control}
          name="label"
          render={({ field, fieldState: { error } }) => (
            <CustomInput
              input={{
                ...field,

                w: "100%",
                h: "38px",
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: ".845rem",
                variant: "outline",

                borderTopRadius: "3px",
                type: "text",
              }}
              placeholder="Label"
              label="Label"
              labelProps={{
                fontSize: ".845rem",
                _light: {
                  color: "customColor.black7",
                },
              }}
            />
          )}
        />
      </Stack>
      <Stack flex={1} marginTop={"15px"} borderTop={"2px solid #e5e7eb"}>
        <HStack justifyContent={"space-between"} marginTop={"5px"}>
          <Text
            _light={{
              color: "customColor.black7",
            }}
            fontWeight={"400"}
            fontSize={"15px"}
          >
            Mileage to claim (km)
          </Text>

          <Text
            _light={{
              color: "customColor.black7",
            }}
            fontWeight={"400"}
            fontSize={"15px"}
          >
            {`${currencyData} ${
              form.getValues("milageToClaim")?.toLocaleString() ?? 0
            }`}
          </Text>
        </HStack>
        <HStack justifyContent={"space-between"}>
          <Text
            _light={{
              color: "customColor.black7",
            }}
            fontWeight={"400"}
            fontSize={"15px"}
          >
            {`Rate (${currencyData} per km)`}
          </Text>
          <Stack flex={2} alignItems={"flex-end"}>
            <Text
              _light={{
                color: "customColor.black7",
              }}
              fontWeight={"400"}
              fontSize={"15px"}
            >
              {`${currencyData} ${
                form.getValues("milageRate")?.toLocaleString() ?? 0
              }`}
            </Text>
          </Stack>
        </HStack>
        <Stack borderTop={"2px solid #e5e7eb"}>
          <HStack
            justifyContent={"space-between"}
            paddingTop={"10px"}
            paddingBottom={"10px"}
          >
            <Text
              _light={{
                color: "customColor.black7",
              }}
              fontWeight={"400"}
              fontSize={"15px"}
            >
              Total
            </Text>
            <Text
              _light={{
                color: "customColor.black7",
              }}
              fontWeight={"700"}
              fontSize={"21px"}
            >
              {`${currencyData} ${
                form.watch("totalAmount")?.toLocaleString() ?? 0
              }`}
            </Text>
          </HStack>
        </Stack>
      </Stack>
    </Container>
  );
}
