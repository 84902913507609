import {
  Button,
  Collapse,
  Container,
  HStack,
  IconButton,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import {useFormContext} from 'react-hook-form';
import {FiChevronDown, FiChevronRight} from 'react-icons/fi';
import {Report} from '../../../../../../modules/Audit';

export default function PeriodicalActivities() {
  const PeriodicActivitiesData = useFormContext<Report.IReport>();
  const periodicActivities = PeriodicActivitiesData.getValues(
    'periodicScheduleEvents',
  );
  const {isOpen, onToggle} = useDisclosure();
  return (
    <Container
      p="16px"
      maxW="container.md"
      borderRadius="16px"
      backgroundColor="backgrounds.primary"
      lineHeight={1}>
      <HStack spacing={0}>
        <Button
          marginRight="8px"
          as={IconButton}
          justifyContent="start"
          p={0}
          aria-label={'dropdown'}
          backgroundColor="transparent"
          _hover={{backgroundColor: 'transparent'}}
          onClick={onToggle}
          icon={
            isOpen ? (
              <FiChevronDown color={'#828ea0'} size={24} />
            ) : (
              <FiChevronRight color={'#828ea0'} size={24} />
            )
          }
        />
        <HStack flex={1}>
          <Text fontSize="21px" fontWeight={500} color="#3f495a">
            Periodical Events
          </Text>
        </HStack>
        <Tag
          px="8px"
          py="4px"
          minW="35px"
          borderRadius="16px"
          backgroundColor="primary.500"
          color="white"
          justifyContent="center">
          {periodicActivities.length}
        </Tag>
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        <Stack bg="white" px="16px" py="8px" mt="8px" borderRadius="16px">
          <TableContainer>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th isNumeric w="50px" textAlign="center">
                    S No.
                  </Th>
                  <Th textAlign="center">Event</Th>
                  <Th textAlign="center">Frequency</Th>
                  <Th textAlign="center">Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {periodicActivities.map((ac, i) => (
                  <Tr key={i}>
                    <Td textAlign="center" w="50px">
                      {i + 1}
                    </Td>
                    <Td textAlign="center">{`${ac.periodicActivity.name} (${ac.dateRange.startDate})`}</Td>
                    <Td textAlign="center">
                      {ac.frequency === 'DAILY'
                        ? 'Daily'
                        : ac.frequency === 'WEEKLY'
                        ? 'Weekly'
                        : ac.frequency === 'MONTHLY'
                        ? 'Monthly'
                        : ac.frequency === 'ANNUALLY'
                        ? 'Annually'
                        : 'On Request'}
                    </Td>
                    <Td textAlign="center" color="green">
                      {ac.status === 'COMPLETED' && '✓'}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Collapse>
    </Container>
  );
}
