import axiosInstanceGateway from "./axiosInstance-gateway";

declare module Correspondence {
  type AddCorrespondence = {
    id?: string;
    name: string;
    position: string;
    email: string;
    phone: string;
    department: string;
  };
  type FetchCorrespondence = {
    id: string;
    name: string;
    position: string;
    email: string;
    phone: string;
    department: string;
  };
}
module Correspondence {
  const URL = "/client-correspondence";

  export function PostCorrespondence(
    data: AddCorrespondence,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .post(`${URL}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function GetCorrespondenceDetails(
    success: (response: Array<FetchCorrespondence>) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${URL}`)
      .then((response) => {
        success(response.data.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function UpdateCorrespondence(
    id: string,
    data: Partial<AddCorrespondence>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .patch(`${URL}/${id}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function DeleteCorrespondence(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .delete(`${URL}/${id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
}
export { Correspondence };
