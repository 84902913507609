import {
  Button,
  Container,
  Heading,
  HStack,
  IconButton,
  Image,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSignIn } from "react-auth-kit";
import { Controller, useForm } from "react-hook-form";
import { IoIosEyeOff, IoMdEye } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { CustomInput } from "../../../Common/CustomInput";

import HRISLOGO from "../../../assets/hris-logo.png";
import { Authentication } from "../../Api/Authentication";

const logo = require("../../../assets/backgroundImages/backgroundPictureForHRIS.png");
export function Login() {
  const toast = useToast();
  const { control, handleSubmit } = useForm<Authentication.SignIn>({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const navigate = useNavigate();
  const signIn = useSignIn();
  const [isLoading, setIsLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [backgroundImage, setBackgroundImage] = useState("");
  const getRandomImage = () => {
    const images = [logo];
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };
  useEffect(() => {
    setBackgroundImage(getRandomImage());
  }, []);
  const onSubmit = handleSubmit((data) => {
    setIsLoading(true);
    Authentication.LoginUser(
      data,
      (user) => {
        const decodedToken = jwtDecode<any>(user.token);
        const expirytime = decodedToken.exp;
        const timeDiff = moment.duration(
          expirytime - moment().unix(),
          "seconds"
        );
        setIsLoading(false);
        const cloneUser = { ...user, token: undefined };

        if (
          signIn({
            token: user.token,
            expiresIn: timeDiff.asMinutes(),
            tokenType: "Bearer",
            authState: cloneUser,
          })
        ) {
          toast({
            title: "login Successful",
            status: "success",
          });
          navigate("/app/SwitchBusiness");
        }
      },
      (error) => {
        toast({
          title: "Login Failed",
          description: error,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  });
  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default form submission
      onSubmit(); // Manually trigger form submission
    }
  };
  return (
    <form onSubmit={onSubmit}>
      <Stack
        direction={{ base: "column", md: "row" }}
        gap={0}
        _light={{
          backgroundColor: "customColor.gray.900",
        }}
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        h={"100vh"}
        w={"100wh"}
        overflow={"hidden"}
      >
        <Stack
          flex={{ base: 0, md: 9 }}
          display={{ base: "none", lg: "block" }}
          height={"130vh"}
        >
          <Image
            src={backgroundImage}
            alt="Background"
            position="absolute"
            top="0px"
            left="0px"
            right="0px"
            bottom={"0px"}
            h={"100%"}
            objectFit={"cover"}
            w={"100%"}
          />
        </Stack>
        <Stack
          gap={0}
          overflowX="hidden"
          padding={"78px 98px"}
          flex={{ base: 1, md: 4 }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          // issue in _light
          _light={{
            backgroundColor: {
              base: "customColor.gray.400",
              lg: "customColor.white",
            },
          }}
          borderLeft={"1px solid #ddd"}
          zIndex={1}
        >
          <Container
            bgColor={{
              base: "white",
              lg: "transparent",
            }}
            padding={{ base: "20px", lg: "0px 20px" }}
            borderRadius={"10px"}
            boxShadow={{
              base: "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)",
              lg: "none",
            }}
          >
            <Stack padding={"0px"} gap={0}>
              <Image
                objectFit="fill"
                w="115px"
                margin={"0px 0px 24px"}
                src={HRISLOGO}
                alt="Logo"
                onClick={() => {
                  navigate("/");
                }}
              />
              <Heading
                fontSize={"24px"}
                fontWeight={"700"}
                margin={"0px 0px 24px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Login
              </Heading>
              <Text
                fontSize={"13px"}
                fontWeight={"700"}
                margin={"0px 0px 8px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
              >
                Login to your account
              </Text>
              <Text
                fontSize={"13px"}
                _light={{
                  color: "customColor.gray.64748B",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
              >
                Thank you for get back hris360 web application, let's access our
                the best recommendation for you.
              </Text>
            </Stack>

            <Stack padding={"8px 0px 0px"} margin={"24px 0px 0px"} gap={0}>
              <Stack margin={"0px 0px 24px"} gap={0}>
                <Text
                  fontSize={"13px"}
                  margin={"0px 0px 8px"}
                  fontWeight={"700"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Email address
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                  }}
                  control={control}
                  name="email"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        variant: "outline",
                        type: "text",
                        bg: "#fff",
                        _light: {
                          color: "customColor.black7",
                        },
                        _dark: {
                          color: "customColor.white",
                        },
                        padding: "12px 15px",
                        fontSize: ".845rem",
                        border: "1px solid #e5e7eb",

                        borderTopRadius: "3px",
                      }}
                      placeholder="Email address"
                      label=""
                    />
                  )}
                />
              </Stack>
              <Stack margin={"0px 0px 40px"} gap={0}>
                <HStack
                  margin={"0px 0px 8px"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Text
                    fontSize={"13px"}
                    fontWeight={"700"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Password
                  </Text>
                  <Link
                    fontSize="13px"
                    padding="0px"
                    margin={"0px"}
                    color="#3454D1"
                    href="/forgot"
                    _hover={{
                      textDecoration: "1px underline rgb(0, 137, 220)",
                      textUnderlineOffset: "0.2rem",
                      color: "rgb(0, 137, 220)",
                    }}
                  >
                    Forgot password?
                  </Link>
                </HStack>
                <HStack gap={0}>
                  <InputGroup>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Password is required",
                        },
                      }}
                      control={control}
                      name="password"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            bg: "#fff",
                            _light: {
                              color: "customColor.black7",
                            },
                            _dark: {
                              color: "customColor.white",
                            },
                            padding: "12px 15px",
                            fontSize: ".845rem",

                            marginTop: "0px",
                            borderTop: "1px solid #e5e7eb",
                            borderLeft: "1px solid #e5e7eb",
                            borderRight: "none",
                            borderBottom: "1px solid #e5e7eb",
                            variant: "outline",
                            type: showPassword ? "text" : "password",
                            borderBottomRadius: "3px",
                          }}
                          placeholder="Password"
                          label=""
                        />
                      )}
                    />
                    <InputRightElement borderRight="1px solid #e5e7eb">
                      <IconButton
                        fontWeight="900"
                        fontStyle="normal"
                        color={"#555b6d"}
                        _hover={{ bg: "none" }}
                        aria-label={"Toggle password visibility"}
                        icon={showPassword ? <IoIosEyeOff /> : <IoMdEye />}
                        variant="outline"
                        borderWidth="0px"
                        h="24px"
                        w="20px"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </InputRightElement>
                  </InputGroup>
                </HStack>
              </Stack>

              <Button
                type="submit"
                data-testid="login-button"
                height={"40px"}
                size={"md"}
                fontSize={"13px"}
                borderRadius={"3px"}
                fontWeight={700}
                colorScheme="Cornflower"
                padding={"15px 20px"}
                lineHeight={"1rem"}
                isLoading={isLoading}
                onClick={(e) => {
                  setIsLoading(true);
                  handleSubmit(
                    (data) => {
                      Authentication.LoginUser(
                        data,
                        (user) => {
                          const decodedToken = jwtDecode<any>(user.token);
                          const expirytime = decodedToken.exp;
                          const timeDiff = moment.duration(
                            expirytime - moment().unix(),
                            "seconds"
                          );
                          setIsLoading(false);
                          const cloneUser = { ...user, token: undefined };

                          if (
                            signIn({
                              token: user.token,
                              expiresIn: timeDiff.asMinutes(),
                              tokenType: "Bearer",
                              authState: cloneUser,
                            })
                          ) {
                            toast({
                              title: "login Successful",
                              status: "success",
                            });
                            // localStorage.setItem("user", JSON.stringify(user));
                            navigate("/app/SwitchBusiness");
                          }
                        },
                        (error) => {
                          toast({
                            title: "Login Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    },
                    (error) => {
                      toast({
                        title: "Login Failed",

                        status: "error",
                      });
                      setIsLoading(false);
                    }
                  )(e);
                }}
              >
                {isLoading ? "SIGNING IN..." : "Login"}
              </Button>
            </Stack>
            {/* <Stack padding={0} margin={"48px 0px 0px"} gap={0}>
              <HStack>
                {" "}
                <Text
                  fontSize={"14px"}
                  _light={{
                    color: "customColor.gray.64748B",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Don't have an account?
                </Text>
                <Link
                  fontSize="14px"
                  padding="0px"
                  margin={"0px"}
                  color="customColor.black7"
                  fontWeight={"700"}
                  href="/signup"
                  _hover={{ color: "#3454d1" }}
                >
                  Create an Account
                </Link>
              </HStack>
            </Stack> */}
          </Container>
        </Stack>
      </Stack>
    </form>
  );
}
