import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FiCheck, FiMoreVertical } from "react-icons/fi";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDebouncedCallback } from "use-debounce";
import { ReactSelect } from "../../../../../../../Common/ReactSelect";
import { InventoryModule } from "../../../../../../modules/Audit/Inventory";
import { InventoryCategoryModule } from "../../../../../../modules/Audit/InventoryCategories";
import InventoryTable from "./InventoryTable";
import UpdateInventoryCategoryModal from "./UpdateInventoryCategoryModal";
import UploadInventoryCategoryDrawer from "./UploadInventoryCategoryDrawer";
import UploadInventoryDrawer from "./UploadInventoryDrawer";

export default function Inventory() {
  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const [selectedItemType, setSelectedItemType] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [selectedId, setSelectedId] = useState<number | undefined>();
  const deleteModal = useDisclosure();
  const UpdateModal = useDisclosure();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const typeDrawer = useDisclosure();
  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const [itemCategory, setItemCategory] = useState<
    Array<InventoryCategoryModule.FetchInventoryCategoryResponse>
  >([]);
  const [inventoryData, setInventoryData] = useState<
    InventoryModule.FetchInventoryTypeResponse[]
  >([]);
  const [totalInventoryItems, settotalInventoryItems] = useState<number>(0);
  const [selectedCategory, setSelectedCategory] =
    useState<InventoryCategoryModule.InventoryCategoryData>();
  // params implementation
  const debounced = useDebouncedCallback((value) => {
    setSearchText(value);
  }, 1000);
  const [limit, setLimit] = useState<number>(25);
  const [searchText, setSearchText] = useState("");
  const searchedInventoryItems = useCallback(() => {
    return inventoryData.filter((item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, inventoryData]);

  const [pageNumber, setPageNumber] = useState<number>(1);
  // const limit = 10;
  const pageCount = Math.ceil(totalInventoryItems / limit);
  const [filterByCategory, setFilterByCategory] = useState<
    number | undefined
  >();
  useEffect(() => {
    InventoryCategoryModule.FetchInventoryCategory(
      (data) => {
        setItemCategory(data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [lastUpdate]);
  useEffect(() => {
    InventoryModule.FetchInventory(
      {
        page: pageNumber,
        limit: limit,
        search: searchText,
        inventoryCategoryId: filterByCategory,
      },
      (items, total) => {
        setInventoryData(items);
        settotalInventoryItems(total);
      },
      () => {
        console.log("Error");
      }
    );
  }, [lastUpdate, pageNumber, searchText, limit, filterByCategory]);
  return (
    <Fragment>
      <Stack px={{ sm: "0px", md: "32px", xl: "50px" }} py="32px">
        <HStack justifyContent="space-between">
          <Text
            fontSize="24px"
            fontWeight={500}
            lineHeight="2rem"
            color="textcolors.primary"
          >
            All Inventory
          </Text>
          <Menu>
            <MenuButton
              as={Button}
              h="41px"
              leftIcon={<AiOutlinePlus size={17} />}
              px="20px"
              py="12px"
              borderRadius="8px"
            >
              Add
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  onOpen();
                }}
              >
                Add Inventory Items
              </MenuItem>
              <MenuItem
                onClick={() => {
                  typeDrawer.onOpen();
                }}
              >
                Add Inventory Categories
              </MenuItem>
            </MenuList>
          </Menu>
          {/* <Button
            >
            
          </Button> */}

          {/* <UploadCsvModal
              isOpen={uploadcsv.isOpen}
              onClose={uploadcsv.onClose}
            /> */}
        </HStack>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing="16px"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Stack direction="row" alignItems="flex-end">
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<AiOutlineSearch color="#545f70" size="18px" />}
              />
              <Input
                h="38px"
                minW={{ xs: "100%", md: "400px" }}
                placeholder="Search template name"
                border="1px solid"
                borderColor="borders.accent"
                fontSize="16px"
                lineHeight="1.5rem"
                fontWeight={400}
                color="textcolors.primary"
                py="8px"
                pl="38px"
                _focusVisible={{
                  boxShadow: "none",
                  border: "1px sloid",
                  borderColor: "primary.500",
                }}
                onChange={(e) => {
                  debounced(e.target.value);
                  setPageNumber(1);
                }}
              />
            </InputGroup>
            <Box w="300px">
              <ReactSelect
                options={itemCategory.map((category) => ({
                  label: category.name,
                  value: category.id,
                }))}
                placeholder="Filter by Item Type"
                ErrorMessage="Select Item Type"
                isClearable={true}
                // label="Industry"
                value={selectedItemType}
                onChange={(newValue: any) => {
                  setSelectedItemType(newValue);
                  setFilterByCategory(newValue?.value);
                }}
              />
            </Box>
          </Stack>

          {/* </Stack> */}
          <HStack spacing={0} justify="end">
            {/* <Text fontSize="14px" color="icons.primary">
                {`1-${globalOptionsList.length} of ${globalOptionsList.length} results`}
              </Text> */}
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FiMoreVertical size={24} color="#545f70" />}
                variant="outline"
                borderRadius={"50%"}
                border={"none"}
                _hover={{ backgroundColor: "transparent" }}
                _active={{
                  backgroundColor: "transparent",
                }}
              />
              <MenuList minW="160px">
                <MenuGroup title="Results per page." fontWeight={400}>
                  {[25, 50, 100].map((menu, i) => {
                    const isSelected = menu === limit;
                    return (
                      <Fragment>
                        <MenuItem
                          px="16px"
                          py="8px"
                          onClick={() => {
                            setLimit(menu);
                          }}
                        >
                          <HStack w="100%">
                            <Box w="15%">
                              {isSelected && (
                                <FiCheck size="18px" color="#1da5d4" />
                              )}
                            </Box>
                            <Text
                              fontSize="14px"
                              color="textcolors.primary"
                              w="85%"
                            >
                              {menu}
                            </Text>
                          </HStack>
                        </MenuItem>
                      </Fragment>
                    );
                  })}
                </MenuGroup>
              </MenuList>
            </Menu>
          </HStack>
        </Stack>
        {/* {TableSize} */}
        <Stack direction="row">
          <Box flex={2}>
            <InventoryTable
              inventoryCategories={itemCategory}
              inventoryData={searchedInventoryItems()}
              setLastUpdate={setLastUpdate}
            />
          </Box>

          <Box flex={1}>
            <TableContainer
              border="1px solid"
              borderRadius="5px"
              borderColor="borders.accent"
              maxHeight="1,421.2px"
              overflowY={"auto"}
            >
              <Table>
                <Thead bg="backgrounds.primary">
                  <Tr maxH="48px">
                    <Th
                      maxW="200px"
                      py="16px"
                      pl="8px"
                      pr="16px"
                      fontSize="13px"
                      fontWeight={400}
                      lineHeight="1rem"
                      color="textcolors.primary"
                      textDecoration="underline"
                      borderColor="borders.accent"
                    >
                      Categories
                    </Th>
                    <Th
                      w="1%"
                      p="16px"
                      fontSize="13px"
                      fontWeight={400}
                      lineHeight="1rem"
                      color="textcolors.primary"
                      borderColor="borders.accent"
                    ></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {itemCategory.map((item, i) => {
                    return (
                      <Tr key={i}>
                        <Td
                          pl="16px"
                          pr="0px"
                          py="16px"
                          fontSize="14px"
                          color="textcolors.secondary"
                          borderColor="borders.accent"
                        >
                          {item?.name}
                        </Td>
                        <Td
                          p={0}
                          borderColor="borders.accent"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              icon={
                                <FiMoreVertical size={24} color="#545f70" />
                              }
                              backgroundColor="transparent"
                              borderRadius="50%"
                              _hover={{ backgroundColor: "gray.300" }}
                              _active={{ backgroundColor: "gray.300" }}
                            />
                            <MenuList minW="180px">
                              <MenuItem
                                px="16px"
                                py="8px"
                                onClick={() => {
                                  setSelectedCategory(item);
                                  setSelectedId(item?.id);
                                  UpdateModal.onOpen();
                                }}
                              >
                                <HStack>
                                  <Icon
                                    as={MdEdit}
                                    boxSize={18}
                                    color="#1f2533"
                                  />
                                  <Text
                                    fontSize="14px"
                                    color="textcolors.primary"
                                  >
                                    Edit
                                  </Text>
                                </HStack>
                              </MenuItem>
                              <MenuItem
                                px="16px"
                                py="8px"
                                onClick={() => {
                                  setSelectedId(item?.id);
                                  deleteModal.onOpen();
                                }}
                              >
                                <HStack>
                                  <Icon
                                    as={MdDelete}
                                    boxSize={18}
                                    color="#1f2533"
                                  />
                                  <Text
                                    fontSize="14px"
                                    color="textcolors.primary"
                                  >
                                    Delete
                                  </Text>
                                </HStack>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    );
                  })}
                  <Modal
                    isOpen={deleteModal.isOpen}
                    onClose={deleteModal.onClose}
                  >
                    <ModalOverlay />
                    <ModalContent mx="10px">
                      <ModalHeader fontWeight="semibold" py="4px">
                        Confirmation
                      </ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        Are you sure you want to Delete this?
                      </ModalBody>

                      <ModalFooter
                        display="flex"
                        justifyContent="flex-end"
                        pt="16px"
                        border="0px"
                      >
                        <ButtonGroup
                          size="xs"
                          variant="solid"
                          borderRadius="5px"
                        >
                          <Button
                            onClick={deleteModal.onClose}
                            borderRadius="5px"
                          >
                            No
                          </Button>
                          <Button
                            colorScheme="red"
                            borderRadius="5px"
                            onClick={() => {
                              if (selectedId) {
                                InventoryCategoryModule.DeleteCategory(
                                  selectedId,
                                  () => {
                                    toast({
                                      title: "Success",
                                      description:
                                        "Category deleted sucessfully",
                                      status: "success",
                                    });
                                    setLastUpdate(Date.now());
                                    deleteModal.onClose();
                                  },
                                  (error) => {
                                    toast({
                                      title: "Error",
                                      description: error,
                                      status: "error",
                                    });
                                    deleteModal.onClose();
                                  }
                                );
                              }
                            }}
                          >
                            Yes
                          </Button>
                        </ButtonGroup>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                  <UpdateInventoryCategoryModal
                    isOpen={UpdateModal.isOpen}
                    onClose={UpdateModal.onClose}
                    selectedId={selectedId}
                    selectedCategory={selectedCategory}
                    setLastUpdate={setLastUpdate}
                  />
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Stack>
        <HStack spacing="13px" justify={{ xs: "center", md: "end" }}>
          <IconButton
            aria-label="back"
            backgroundColor="transparent"
            _hover={{ backgroundColor: "transparent" }}
            icon={
              <BsChevronLeft
                size={19}
                color={
                  pageNumber <= 1 || pageNumber > pageCount
                    ? "#828ea0"
                    : "#000000"
                }
              />
            }
            isDisabled={
              pageNumber <= 1 || pageNumber > pageCount ? true : false
            }
            onClick={() => {
              if (pageNumber > 1 && pageNumber <= pageCount)
                pageNumberInputRef.current?.value &&
                  (pageNumberInputRef.current.value = String(pageNumber - 1));
              setPageNumber(pageNumber - 1);
              console.log(pageNumber);
            }}
          />
          <Input
            ref={pageNumberInputRef}
            h="37px"
            w="56px"
            backgroundColor="backgrounds.primary"
            defaultValue={pageNumber.toString()}
            textAlign="center"
            borderColor="borders.accent"
            value={pageNumber}
            onChange={(e) => {
              const pageValue = e.target.value;
              setPageNumber(parseInt(pageValue));
            }}
            onBlur={(e) => {
              const pageValue = e.target.value;
              setPageNumber(parseInt(pageValue));
            }}
          />

          <Text color="textcolors.secondary">/</Text>
          <Text w="40px" textAlign="center">
            {pageCount}
          </Text>
          <IconButton
            aria-label="right"
            backgroundColor="transparent"
            _hover={{ backgroundColor: "transparent" }}
            icon={
              <BsChevronRight
                size={19}
                color={
                  pageNumber >= pageCount || pageNumber < 1
                    ? "#828ea0"
                    : "#000000"
                }
              />
            }
            isDisabled={
              pageNumber >= pageCount || pageNumber < 1 ? true : false
            }
            onClick={() => {
              if (pageNumber < pageCount && pageNumber >= 1) {
                pageNumberInputRef.current?.value &&
                  (pageNumberInputRef.current.value = String(pageNumber + 1));
                setPageNumber(pageNumber + 1);
              }

              console.log(pageNumber);
            }}
          />
        </HStack>
        {/* <HStack spacing="13px" justify={{xs: 'center', md: 'end'}}>
            <BsChevronLeft size={19} color="#828ea0" />
            <Input
              h="37px"
              w="56px"
              backgroundColor="backgrounds.primary"
              value={1}
              textAlign="center"
              borderColor="borders.accent"
            />
  
            <Text color="textcolors.secondary">/</Text>
            <Text w="40px" textAlign="center">
              1
            </Text>
            <BsChevronRight size={19} color="#828ea0" />
          </HStack> */}
      </Stack>
      {/* <UploadInventory isOpen={isOpen} onClose={onClose} /> */}
      <UploadInventoryDrawer
        isOpen={isOpen}
        onClose={onClose}
        inventoryTypes={itemCategory}
        setLastUpdate={setLastUpdate}
      />
      <UploadInventoryCategoryDrawer
        isOpen={typeDrawer.isOpen}
        onClose={typeDrawer.onClose}
        setLastUpdate={setLastUpdate}
      />
    </Fragment>
  );
}
