import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  Link,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MdDelete, MdEdit } from "react-icons/md";
import { Correspondence } from "../../Api/Correspondence";
import CorrespondenceModal from "./CorrespondenceModal";
import DeleteCorrespondence from "./DeleteCorrespondence";
interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export function CorrespondenceParent({
  setSelectedTab,
  selectedTab,
}: TabProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toast = useToast();
  const [CorrespondenceData, setCorrespondenceData] = useState<
    Array<Correspondence.FetchCorrespondence>
  >([]);

  const form = useForm<Correspondence.AddCorrespondence>({
    defaultValues: {
      name: "",
      position: "",
      email: "",
      phone: "",
      department: "",
    },
  });

  const [deleteId, setDeleteId] = useState<string>("");
  const deleteModal = useDisclosure();
  useEffect(() => {
    Correspondence.GetCorrespondenceDetails(
      (data) => {
        setCorrespondenceData(data);

        setIsLoading(false);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, [lastUpdated]);
  return (
    <FormProvider {...form}>
      <Fragment>
        <Stack
          marginTop={"15px"}
          p="0px"
          // maxW="940px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          // border="1px solid #d6dade"
          // borderRadius="md"
          marginBottom={"1.25rem"}
          border={"1px solid #f6f6f6"}
          borderRadius="4px"
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        >
          <Box
            justifyContent="center"
            alignItems="center"
            p="0px"
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
            // border="1px solid #d6dade"
            // borderRadius="md"
          >
            <HStack gap={0}>
              <Heading
                textAlign="left"
                fontSize=".9975rem"
                fontWeight="bold"
                padding="20px"
                _light={{
                  color: "customColor.black7",
                }}
                as="h2"
                flex={1}
                size="md"
              >
                Correspondence
              </Heading>
              <Link
                color="primary.950"
                fontSize=" var(--x-font-size-small, .8125rem"
                paddingBottom="1px"
                href="/app/payroll/organisationSettings"
              >
                Organisation Settings <Icon as={ChevronRightIcon} />
              </Link>
            </HStack>

            <Divider orientation="horizontal" />

            <Box
              justifyContent="center"
              alignItems="center"
              p="0px"
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "customColor.white",
              }}
            >
              <HStack justifyContent="space-between">
                <Heading
                  textAlign="left"
                  fontSize=".9975rem"
                  fontWeight="bold"
                  _light={{
                    color: "customColor.black7",
                  }}
                  padding="20px"
                  as="h2"
                  size="md"
                >
                  Correspondence
                </Heading>
                <Button
                  variant="link"
                  mr="20px"
                  _light={{
                    color: "primary.950",
                  }}
                  onClick={() => {
                    form.reset({
                      name: "",
                      position: "",
                      email: "",
                      phone: "",
                      department: "",
                    });
                    onOpen();
                  }}
                >
                  Add Correspondence
                </Button>
              </HStack>

              <Divider orientation="horizontal" />
              <Skeleton isLoaded={!isLoading} height="100%">
                <Box overflowX="auto" p="0px">
                  <Table variant="simple" w="100%" overflowX="auto">
                    <Thead h={"36.8px"}>
                      <Tr
                        borderTopWidth={"1px"}
                        borderTopColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                      >
                        <Th
                          padding="8px 26px 8px 30px"
                          width={"20%"}
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Name
                        </Th>
                        <Th
                          width={"20%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Position
                        </Th>

                        <Th
                          width={"20%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Email
                        </Th>
                        <Th
                          width={"10%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Phone
                        </Th>
                        <Th
                          width={"20%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Department
                        </Th>

                        <Th
                          width={"10%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Action Required
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {CorrespondenceData.map((item, i) => {
                        return (
                          <Tr
                            key={i}
                            _hover={{
                              bgColor: "#f2f3f8",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              onOpen();
                              form.reset({
                                id: item.id,
                                name: item.name,
                                position: item.position,
                                email: item.email,
                                phone: item.phone,
                                department: item.department,
                              });
                            }}
                          >
                            <Tooltip
                              label={`${item.name}`}
                              placement="top"
                              hasArrow
                              bg="gray.800"
                              color="white"
                              fontSize="sm"
                              p="2"
                              rounded="md"
                              overflowWrap="anywhere"
                              whiteSpace="normal"
                              zIndex="10"
                            >
                              <Td
                                maxW="100px"
                                isTruncated
                                padding="15px 15px 15px 30px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                {item.name && item.name.trim()
                                  ? item.name
                                  : "NA"}
                              </Td>
                            </Tooltip>
                            <Tooltip
                              label={`${item.position}`}
                              placement="top"
                              hasArrow
                              bg="gray.800"
                              color="white"
                              fontSize="sm"
                              p="2"
                              rounded="md"
                              overflowWrap="anywhere"
                              whiteSpace="normal"
                              zIndex="10"
                            >
                              <Td
                                maxW="100px"
                                isTruncated
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                {item.position && item.position.trim()
                                  ? item.position
                                  : "NA"}
                              </Td>
                            </Tooltip>
                            <Tooltip
                              label={`${item.email}`}
                              placement="top"
                              hasArrow
                              bg="gray.800"
                              color="white"
                              fontSize="sm"
                              p="2"
                              rounded="md"
                              overflowWrap="anywhere"
                              whiteSpace="normal"
                              zIndex="10"
                            >
                              <Td
                                maxW="100px"
                                isTruncated
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                {item.email && item.email.trim()
                                  ? item.email
                                  : "NA"}
                              </Td>
                            </Tooltip>

                            <Tooltip
                              label={`${item.phone}`}
                              placement="top"
                              hasArrow
                              bg="gray.800"
                              color="white"
                              fontSize="sm"
                              p="2"
                              rounded="md"
                              overflowWrap="anywhere"
                              whiteSpace="normal"
                              zIndex="10"
                            >
                              <Td
                                maxW="100px"
                                isTruncated
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                {item.phone && item.phone.trim()
                                  ? item.phone
                                  : "NA"}
                              </Td>
                            </Tooltip>
                            <Tooltip
                              label={`${item.department}`}
                              placement="top"
                              hasArrow
                              bg="gray.800"
                              color="white"
                              fontSize="sm"
                              p="2"
                              rounded="md"
                              overflowWrap="anywhere"
                              whiteSpace="normal"
                              zIndex="10"
                            >
                              <Td
                                maxW="100px"
                                isTruncated
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                {item.department && item.department.trim()
                                  ? item.department
                                  : "NA"}
                              </Td>
                            </Tooltip>

                            <Td
                              padding="10px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              alignItems={"center"}
                              justifyContent={"center"}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              <HStack
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                <Icon
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="50%"
                                  borderStyle={"solid"}
                                  borderWidth={"1px"}
                                  borderColor={"borders.tableBorder"}
                                  bg={"#fff"}
                                  w="25px"
                                  h="25px"
                                  px="5px"
                                  py="5px"
                                  as={MdEdit}
                                  fontSize="22px"
                                  color="#6B7280"
                                  _hover={{
                                    color: "#3454d1",
                                  }}
                                  onClick={() => {
                                    onOpen();
                                    form.reset(item);
                                  }}
                                />
                                <Icon
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="50%"
                                  borderStyle={"solid"}
                                  borderWidth={"1px"}
                                  borderColor={"borders.tableBorder"}
                                  bg={"#fff"}
                                  w="25px"
                                  h="25px"
                                  px="5px"
                                  py="5px"
                                  as={MdDelete}
                                  fontSize="22px"
                                  color="#6B7280"
                                  _hover={{
                                    color: "#3454d1",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDeleteId(item.id);
                                    deleteModal.onOpen();
                                  }}
                                />
                              </HStack>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </Box>
                <Stack alignItems={"flex-end"} margin={"20px 20px 20px 0px"}>
                  <Button
                    colorScheme={"green"}
                    borderRadius={"3px"}
                    border={"1px solid #dcdee4"}
                    fontSize={"13px"}
                    h={"40px"}
                    variant={"solid"}
                    textColor={"customColor.white"}
                    padding={"12px 16px"}
                    marginRight={"5px"}
                    fontWeight={600}
                    onClick={() => setSelectedTab(selectedTab + 1)}
                  >
                    Next
                  </Button>
                </Stack>
              </Skeleton>
            </Box>
          </Box>
        </Stack>

        <CorrespondenceModal
          isOpen={isOpen}
          onClose={onClose}
          setLastUpdated={setLastUpdated}
        />
        <DeleteCorrespondence
          isOpen={deleteModal.isOpen}
          onClose={deleteModal.onClose}
          id={deleteId}
          setLastUpdated={setLastUpdated}
        />
      </Fragment>
    </FormProvider>
  );
}
