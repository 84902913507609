import { Checkbox, SimpleGrid, Skeleton, Stack, Text } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { InviteUser } from "../../../../../api/InviteUser";
interface BusinessSettingsPermissionsProps {
  isLoading?: boolean;
}
export default function BusinessSettingsPermissions({
  isLoading,
}: BusinessSettingsPermissionsProps) {
  const form = useFormContext<InviteUser.UserInviteDetail>();
  return (
    <Stack paddingLeft={"2.5px"} gap={0} paddingTop={"10px"}>
      <Stack
        padding={0}
        alignItems={"center"}
        direction={"row"}
        gap={0}
      ></Stack>
      <Skeleton height={"100%"} isLoaded={!isLoading} mb="2">
        <SimpleGrid columns={3}>
          <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.businessSettings.businessSetup"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  isChecked={field.value}
                  size={"md"}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Business Setup
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.businessSettings.users"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  isChecked={field.value}
                  size={"md"}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Users
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.businessSettings.mappingWidow"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  isChecked={field.value}
                  size={"md"}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Mapping Window
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.businessSettings.payrollSettings"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  isChecked={field.value}
                  size={"md"}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Payroll Settings
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.businessSettings.invoiceSettings"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  isChecked={field.value}
                  size={"md"}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Invoice Settings
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
          <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.businessSettings.editBusinessPermission"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  isChecked={field.value}
                  size={"md"}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Edit Business
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
        </SimpleGrid>
      </Skeleton>
    </Stack>
  );
}
