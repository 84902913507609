import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { EmployeesReport } from "../../../Api/EmployeeReport";
import { PayItemLeaveApi } from "../../../Api/PayItemLeave";
import { DownloadExcel } from "../../TimeSheet/utils";
import { ColumnsComponent } from "../Components/Columns";
import { DateRange } from "../Components/DateRange";
import { FilterComponent } from "../Components/FilterComponent";
import ListLimit from "../Components/ListLimit";
import PaginationComponent from "../Components/PaginationComponent";
import { ReportingTable } from "../Tables/ReportingTable";
import { Column } from "./Columns";
import ReportsHeader from "./ReportsHeader";
export interface GenericObject {
  [key: string]: any;
}
export function LeaveRequestReport() {
  const leaveStatusOption: Array<{ label: string; value?: string }> = [
    {
      label: "All",
      value: undefined,
    },
    {
      label: "Approved",
      value: "APPROVED",
    },
    {
      label: "Pending",
      value: "PENDING",
    },
    {
      label: "Rejected",
      value: "REJECTED",
    },
  ];

  const [leaveStatus, setLeaveStatus] = useState<string | undefined>(undefined);
  const [leaveType, setLeaveType] = useState<string | undefined>(undefined);
  const [leaveAdded, setLeaveAdded] = useState<Array<any>>([]);

  const [selectedGroupBy, setSelectedGroupBy] = useState<
    | EmployeesReport.GroupingEmployeeRemunerationReport["groupBy"]
    | EmployeesReport.GroupingLeaveBalanceReport["groupBy"]
    | EmployeesReport.GroupingTimesheetSummaryReport["groupBy"]
    | EmployeesReport.GroupingTimesheetDetailsReport["groupBy"]
    | EmployeesReport.GroupingSuperannuationAccrualsReport["groupBy"]
    | EmployeesReport.GroupingSuperannuationPaymentsReport["groupBy"]
    | EmployeesReport.GroupingLeaveTransactionsReport["groupBy"]
    | EmployeesReport.GroupingLeaveRequestReport["groupBy"]
    | "EMPLOYEE"
    | "EMPLOYEE_GROUP"
    | undefined
  >(undefined);
  const [selectedClonedGroupBy, setSelectedClonedGroupBy] = useState<
    | EmployeesReport.GroupingEmployeeRemunerationReport["groupBy"]
    | EmployeesReport.GroupingLeaveBalanceReport["groupBy"]
    | EmployeesReport.GroupingTimesheetSummaryReport["groupBy"]
    | EmployeesReport.GroupingTimesheetDetailsReport["groupBy"]
    | EmployeesReport.GroupingSuperannuationAccrualsReport["groupBy"]
    | EmployeesReport.GroupingSuperannuationPaymentsReport["groupBy"]
    | EmployeesReport.GroupingLeaveTransactionsReport["groupBy"]
    | EmployeesReport.GroupingLeaveRequestReport["groupBy"]
    | "EMPLOYEE"
    | "EMPLOYEE_GROUP"
    | undefined
  >(undefined);
  const GroupingBtn: Array<{ label: string; value?: string }> = [
    {
      label: "None",
      value: undefined,
    },
    {
      label: "Employee",
      value: "EMPLOYEE",
    },
    {
      label: "Leave Type",
      value: "LEAVE_TYPE",
    },
  ];
  const name = " Leave Request";

  const FilterData: Array<{
    name: string;
    startRate?: string;
    endRate?: string;
    dataType?: string;
    endDate?: string;
    startDate?: string;
    StartKeyName?: string;
    EndKeyName?: string;
    singleProperty?: string;
    PayLoadDataType?: any;
  }> = [
    {
      name: "Amount",
      startRate: "text",
      endRate: "text",
      StartKeyName: "filters.amount.from",
      EndKeyName: "filters.amount.to",
      PayLoadDataType: Number,
    },
    {
      name: "Quantity",
      startRate: "text",
      endRate: "text",
      StartKeyName: "filters.quantity.from",
      EndKeyName: "filters.quantity.to",
      PayLoadDataType: Number,
    },
  ];

  const toast = useToast();
  const reportForm = useForm<EmployeesReport.LeaveRequestReport>({
    defaultValues: {
      columns: {
        uniqueId: true,
        firstName: true,
        lastName: true,
        quantity: true,
        status: true,
        endDate: true,
        leaveType: true,
        startDate: true,
      },
      filters: {},
      groupBy: undefined,
      pagination: { limit: 10, page: 1 },
    },
  });
  const [selectedColumns, setSelectedColumns] = useState<Array<Column>>([
    colBtns[0].colItem[0],
    colBtns[0].colItem[1],
    colBtns[0].colItem[2],
  ]);
  const [clonedSelectedColumns, setClonedSelectedColumns] = useState<
    Array<Column>
  >([...colBtns[0].colItem]);

  const [selectedData, setSelectedData] = useState<
    EmployeesReport.fetchLeaveRequestReport | GenericObject
  >();
  const [totalCount, setTotalCount] = useState<number>(0);
  const { allEmployeesList } = useBusinessContext();
  const [selectedEmployee, setSelectedEmployee] = useState<string | undefined>(
    undefined
  );
  // function postcolumns() {
  //   reportForm.handleSubmit((data) => {
  //     EmployeesReport.CreateLeaveRequestReport(
  //       data,
  //       (success) => {
  //         setSelectedData(success);
  //         setClonedSelectedGroupBy(selectedGroupBy);
  //         setClonedSelectedColumns(selectedColumns);
  //         toast({
  //           title: "Report Created",
  //           description: "Report Created Successfully",
  //           status: "success",
  //           duration: 9000,
  //           isClosable: true,
  //         });
  //       },
  //       (error) => {
  //         toast({
  //           title: "Report Creation Failed",
  //           description: "Report Creation Failed",
  //           status: "error",
  //           duration: 9000,
  //           isClosable: true,
  //         });
  //       }
  //     );
  //   })();
  // }
  // useEffect(() => {
  //   postcolumns();
  // }, []);

  const headerLabels: { [key: string]: string } = {
    amount: "Amount",

    employee: "Employee",
    employeeGroup: "Employee Group",
    endDate: "End Date",
    leaveStatus: "Leave Status",
    leaveType: "Leave Type",
    startDate: "Start Date",
    units: "Units",

    // ... any other custom labels
  };
  // const downloadExcel = () => {
  //   const flattenData = (data: GenericObject[]): GenericObject[] => {
  //     return data.map((item) => {
  //       const flattened: GenericObject = {};

  //       const flatten = (obj: GenericObject): GenericObject => {
  //         const result: GenericObject = {};

  //         Object.entries(obj).forEach(([key, value]) => {
  //           if (key === "employeeEmployementDetails" && value === null) {
  //             return;
  //           }

  //           if (
  //             typeof value === "object" &&
  //             value !== null &&
  //             !(value instanceof Date) &&
  //             !(value instanceof Array)
  //           ) {
  //             if (key === "employeeEmployementDetails") {
  //               Object.assign(result, flatten(value));
  //             } else {
  //               // For other nested objects, prefix the key and flatten
  //               const nestedObject = flatten(value);
  //               Object.entries(nestedObject).forEach(
  //                 ([nestedKey, nestedValue]) => {
  //                   result[`${key}_${nestedKey}`] = nestedValue;
  //                 }
  //               );
  //             }
  //           } else {
  //             // Non-object values are added directly
  //             result[key] = value;
  //           }
  //         });

  //         return result;
  //       };

  //       Object.assign(flattened, flatten(item));
  //       return flattened;
  //     });
  //   };

  //   const workbook = XLSX.utils.book_new();
  //   const flattenedData = {}
  //   const dataWithCustomHeaders = applyCustomLabels(
  //     flattenedData,
  //     headerLabels
  //   );
  //   const worksheet = XLSX.utils.json_to_sheet(dataWithCustomHeaders);
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: "xlsx",
  //     type: "array",
  //   });
  //   const blob = new Blob([excelBuffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  //   });

  //   // Use FileSaver to save the file
  //   saveAs(blob, "report.xlsx");
  // };
  // const applyCustomLabels = (
  //   data: GenericObject[],
  //   labels: { [key: string]: string }
  // ): GenericObject[] => {
  //   return data.map((item) => {
  //     const customizedItem: GenericObject = {};
  //     Object.keys(item).forEach((key) => {
  //       const newKey = labels[key] || key; // Use the custom label if it exists, otherwise use the key as is
  //       customizedItem[newKey] = item[key];
  //     });
  //     return customizedItem;
  //   });
  // };
  const [excelData, setExcelData] = useState<any>(undefined);
  const postPayload = reportForm.handleSubmit((data) => {
    let payloadData = { ...data };
    //  if (
    //    data.filters?.dateRange?.from === undefined ||
    //    data.filters?.dateRange?.to === undefined
    //  ) {
    //    delete payloadData.filters?.dateRange;
    //  }
    const isEmptyObject = (obj: any) => {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    const removeEmpty = (obj: any) => {
      let newObj: any = {};
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === "object") {
          newObj[key] = removeEmpty(obj[key]);
          if (isEmptyObject(newObj[key])) {
            delete newObj[key];
          }
        } else if (obj[key] !== undefined) {
          newObj[key] = obj[key];
        }
      });
      return newObj;
    };
    const payload = {
      ...payloadData,
      filters: {
        ...payloadData.filters,
        empId: selectedEmployee,
        status: leaveStatus,
        leaveType: leaveType,
      },
      groupBy: selectedGroupBy,

      columns: colBtns[0].colItem.reduce((acc, item) => {
        return {
          ...acc,
          [item.column]: selectedColumns.some(
            (col) => col.column === item.column
          ),
        };
      }, {} as any),
    };
    const cleanedPayload = removeEmpty(payload);
    EmployeesReport.CreateLeaveRequestReport(
      cleanedPayload,
      (success: any) => {
        setSelectedData(success);
        setTotalCount(success.total);
        setClonedSelectedColumns(selectedColumns);
        setExcelData(cleanedPayload);
        toast({
          title: "Report Created",
          description: "Report Created Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setSelectedClonedGroupBy(selectedGroupBy);
      },
      (error: any) => {
        toast({
          title: "Report Creation Failed",
          description: "Report Creation Failed",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    );
  });
  useEffect(() => {
    PayItemLeaveApi.FetchLeave(
      (data) => {
        setLeaveAdded(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, []);
  const watchedLimit = useWatch({
    control: reportForm.control,
    name: "pagination.limit",
  });
  const watchedPage = useWatch({
    control: reportForm.control,
    name: "pagination.page",
  });
  useEffect(() => {
    if (selectedData !== undefined) {
      if (selectedData?.data.length > 0) {
        postPayload();
      }
    }
  }, [watchedLimit, watchedPage]);
  const leaveTypeOptions: Array<{ label: string; value: string }> = [
    { label: "All", value: undefined },
    ...leaveAdded.map((leave) => ({
      label: leave.name,
      value: leave.id,
    })),
  ];
  return (
    <FormProvider {...reportForm}>
      <Stack
        h="calc(100vh - 70px)"
        overflowY="auto"
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
      >
        <Stack
          direction={"row"}
          gap={0}
          boxShadow={"0 1px 0 0 rgba(0,10,30,.2)"}
          _light={{
            bgColor: "customColor.white",
          }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          alignItems={"center"}
          justifyContent={"space-between"} // Add this line
          width={"100%"}
        >
          <ReportsHeader name={name} />
          <Box
            display="flex"
            justifyContent="flex-end"
            paddingLeft={"10px"}
            marginRight={"16px"}
          >
            <Menu>
              <MenuButton
                as={Button}
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                _active={{
                  backgroundColor: "#fff",
                }}
                rightIcon={<ChevronDownIcon />}
                marginRight={"8px"}
              >
                Export
              </MenuButton>
              <MenuList style={{ minWidth: "120px" }}>
                <MenuItem
                  onClick={() => {
                    if (excelData === undefined) {
                      toast({
                        title: "Report",
                        description: "Fetch Data First",
                        status: "warning",
                        duration: 9000,
                        isClosable: true,
                      });
                    } else {
                      EmployeesReport.CreateLeaveRequestReportExport(
                        excelData,
                        (success) => {
                          DownloadExcel(
                            success.data,
                            "LeaveRequestReport.xlsx"
                          );
                          toast({
                            title: "Report Downloaded",
                            description: "Report Downloaded Successfully",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                          });
                        },
                        () => {
                          toast({
                            title: "Report Creation Failed",
                            description: "Report Creation Failed",
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          });
                        }
                      );
                    }
                  }}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Excel
                  </Text>
                </MenuItem>

                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    PDF
                  </Text>
                </MenuItem>
                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Google Sheet
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => {
                reportForm.reset({
                  columns: {
                    firstName: true,
                    lastName: true,
                    quantity: true,
                    status: true,
                    endDate: true,
                    leaveType: true,
                    startDate: true,
                  },
                  filters: {},
                  pagination: { page: 1, limit: 10 },
                });
                setSelectedColumns([
                  colBtns[0].colItem[0],
                  colBtns[0].colItem[1],
                  colBtns[0].colItem[2],
                ]);
                setSelectedEmployee(undefined);
                setLeaveStatus(undefined);
                setLeaveType(undefined);
                reportForm.setValue("pagination", { page: 1, limit: 10 });
              }}
            >
              Clear Filters
            </Button>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              marginLeft={"8px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={postPayload}
            >
              Upload
            </Button>
          </Box>
        </Stack>
        <Container maxW={"1150px"} paddingLeft={"22px"} paddingRight={"22px"}>
          <Stack padding={"0px"}>
            <SimpleGrid columns={{ xs: 2, sm: 2, md: 3, lg: 4 }}>
              <Box margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
                <HStack gap={0} mb="8px">
                  <Text
                    fontSize="13px"
                    display={"block"}
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Employee
                  </Text>
                </HStack>

                <ReactSelect
                  placeholder="Employee"
                  options={[
                    { value: undefined, label: "All" }, // Add 'All' option
                    ...(allEmployeesList?.map((employee: any) => ({
                      value: employee.id,
                      label: `${employee.firstName} ${employee.lastName}`,
                    })) || []),
                  ]}
                  value={
                    selectedEmployee
                      ? {
                          value: selectedEmployee,
                          label: `${
                            allEmployeesList?.find(
                              (employee) => employee.id === selectedEmployee
                            )?.firstName
                          } ${
                            allEmployeesList?.find(
                              (employee) => employee.id === selectedEmployee
                            )?.lastName
                          }`,
                        }
                      : { value: undefined, label: "All" }
                  }
                  onChange={(newValue: any) => {
                    setSelectedEmployee(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Box>
              <Box margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
                <HStack gap={0} mb="8px">
                  <Text
                    fontSize="13px"
                    display={"block"}
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Leave Type (Required)
                  </Text>
                </HStack>

                <ReactSelect
                  placeholder="Leave Type"
                  options={leaveTypeOptions}
                  value={
                    leaveTypeOptions.find((op) => op.value === leaveType) ||
                    leaveTypeOptions[0]
                  }
                  onChange={(newValue: any) => {
                    setLeaveType(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Box>
              {/* <LeaveType leaveType={leaveType} /> */}
              <Box margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
                <HStack gap={0} mb="8px">
                  <Text
                    fontSize="13px"
                    display={"block"}
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Leave Status (Required)
                  </Text>
                </HStack>

                <ReactSelect
                  placeholder="Group By"
                  options={leaveStatusOption}
                  value={
                    leaveStatusOption.find((op) => op.value === leaveStatus) ||
                    leaveStatusOption[0]
                  }
                  onChange={(newValue: any) => {
                    setLeaveStatus(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Box>
              <DateRange
                startDateKey="filters.startDate"
                endDateKey="filters.endDate"
              />

              <ColumnsComponent
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                colBtns={colBtns}
              />
              <Box margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
                <HStack gap={0} mb="8px">
                  <Text
                    fontSize="13px"
                    display={"block"}
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Group By
                  </Text>
                </HStack>

                <ReactSelect
                  placeholder="Group By"
                  options={GroupingBtn}
                  value={
                    GroupingBtn.find((btn) => btn.value === selectedGroupBy) ||
                    GroupingBtn[0]
                  }
                  onChange={(newValue: any) => {
                    setSelectedGroupBy(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Box>
              {/* <FilterModal
                FilterData={FilterData}
                isOpen={filterModal.isOpen}
                onClose={filterModal.onClose}
              /> */}
              <FilterComponent FilterData={FilterData} />
            </SimpleGrid>

            {/* <Button onClick={downloadExcel} borderRadius={"3px"}>
              Download as Excel
            </Button> */}
          </Stack>
        </Container>
        {(selectedData?.data?.length > 0 || selectedData !== undefined) && (
          <Container maxW={"1150px"} paddingLeft={"22px"} paddingRight={"22px"}>
            <Box w={"100%"} display="flex" justifyContent="flex-end">
              <ListLimit totalCount={totalCount} />
            </Box>
            <ReportingTable
              name={name}
              Data={selectedData?.data}
              DataWithKey={selectedData?.data}
              selectedColumns={clonedSelectedColumns}
              selectedData={selectedData}
              groupBy={selectedClonedGroupBy}
              totalsColumns={["amount", "quantity"]}

              // tabRef={tabRef}
            />
            <Box w={"100%"}>
              <PaginationComponent totalCount={totalCount} />
            </Box>
          </Container>
        )}
        {/* <Box
          borderTop="1px solid #ddd"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          position="fixed"
          left={0}
          bottom="0"
          w="full"
          h="50px"
          // zIndex={"overlay"}
        >
          <Container
            justifyContent="center"
            alignItems="flex-end"
            textAlign={"right"}
            maxW={"90%"}
          >
            <Menu>
              <MenuButton
                as={Button}
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                _active={{
                  backgroundColor: "#fff",
                }}
                rightIcon={<ChevronDownIcon />}
                marginTop={"10px"}
              >
                Export
              </MenuButton>
              <MenuList style={{ minWidth: "120px" }}>
                <MenuItem
                  onClick={() => {
                    if (excelData === undefined) {
                      toast({
                        title: "Report",
                        description: "Fetch Data First",
                        status: "warning",
                        duration: 9000,
                        isClosable: true,
                      });
                    } else {
                      EmployeesReport.CreateLeaveRequestReportExport(
                        excelData,
                        (success) => {
                          DownloadExcel(
                            success.data,
                            "LeaveRequestReport.xlsx"
                          );
                          toast({
                            title: "Report Downloaded",
                            description: "Report Downloaded Successfully",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                          });
                        },
                        () => {
                          toast({
                            title: "Report Creation Failed",
                            description: "Report Creation Failed",
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          });
                        }
                      );
                    }
                  }}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Excel
                  </Text>
                </MenuItem>

                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    PDF
                  </Text>
                </MenuItem>
                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Google Sheet
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
          </Container>
        </Box> */}
      </Stack>
    </FormProvider>
  );
}

const colBtns: Array<{
  colItem: Array<Column>;
}> = [
  {
    colItem: [
      {
        colLabel: "Employee ID",
        column: "uniqueId",
        position: 0,
        dataKey: "uniqueId",
      },

      // {
      //   colLabel: "First Name",
      //   column: "firstName",
      //   position: 1,
      //   groupingKey: "EMPLOYEE",
      //   dataKey: "employee_firstName",
      // },
      // {
      //   colLabel: "Last Name",
      //   column: "lastName",
      //   position: 2,
      //   groupingKey: "EMPLOYEE",
      //   dataKey: "employee_lastName",
      // },
      {
        colLabel: "Employee",
        column: "employee",
        position: 1,
        groupingKey: "EMPLOYEE",
        dataKey: "employee",
      },

      {
        colLabel: "Leave Status",
        column: "status",
        position: 2,
        dataKey: "status",
      },
      {
        colLabel: "Leave Type",
        column: "leaveType",
        position: 3,
        groupingKey: "LEAVE_TYPE",
        dataKey: "leaveEntitlement_payrollPayItemLeave_name",
      },
      {
        colLabel: "Start Date",
        column: "startDate",
        position: 4,
        dataKey: "startDate",
      },
      {
        colLabel: "End Date",
        column: "endDate",
        position: 5,
        dataKey: "endDate",
      },
      {
        colLabel: "Quantity",
        column: "quantity",
        position: 6,
        dataKey: "quantity",
      },
    ],
  },
];
const DataWithKey = {
  NONE: [
    {
      amount: "5000",
      employee: "Irsam",
      employeeGroup: "none",
      endDate: "20 May 2024",
      leaveStatus: "APPROVED",
      leaveType: "Sick Leave",
      startDate: "06 May 2024",
      units: "Days",
    },
    {
      amount: "5000",
      employee: "Irsam",
      employeeGroup: "none",
      endDate: "20 May 2024",
      leaveStatus: "APPROVED",
      leaveType: "Sick Leave",
      startDate: "06 May 2024",
      units: "Days",
    },
    {
      amount: "5000",
      employee: "Irsam",
      employeeGroup: "none",
      endDate: "20 May 2024",
      leaveStatus: "APPROVED",
      leaveType: "Sick Leave",
      startDate: "06 May 2024",
      units: "Days",
    },
  ],
};
const Data = [
  {
    amount: "5000",
    employee: "Irsam",
    employeeGroup: "none",
    endDate: "20 May 2024",
    leaveStatus: "APPROVED",
    leaveType: "Sick Leave",
    startDate: "06 May 2024",
    units: "Days",
  },
  {
    amount: "5000",
    employee: "Irsam",
    employeeGroup: "none",
    endDate: "20 May 2024",
    leaveStatus: "APPROVED",
    leaveType: "Sick Leave",
    startDate: "06 May 2024",
    units: "Days",
  },
  {
    amount: "5000",
    employee: "Irsam",
    employeeGroup: "none",
    endDate: "20 May 2024",
    leaveStatus: "APPROVED",
    leaveType: "Sick Leave",
    startDate: "06 May 2024",
    units: "Days",
  },
];
