import {
  Box,
  Container,
  HStack,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { Country, State } from "country-state-city";
import { useEffect, useState } from "react";
import { CustomInput } from "../../../../../../../Common/CustomInput";
import { ReactSelect } from "../../../../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../../../../Hooks/BusinessContext";
import { BusinessTaxRate } from "../../../../../../Api/BusinessTaxRate";
import { ChartOfAccounts } from "../../../../../../Api/ChartOfAccounts";
import { ExpenseClaimApi } from "../../../../../../Api/ExpenseClaimApi";
import { PayItemReimbursementApi } from "../../../../../../Api/PayItemReinbursement";

export function AddExpenseLeftSection() {
  const form = useFormContext();
  const watchPayType = useWatch({
    control: form.control,
    name: "expenseClaimType",
  });
  const watchTaxType = useWatch({
    control: form.control,
    name: "taxType",
  });
  const [provinces, setProvinces] = useState<
    Array<{
      name: string;
    }>
  >([]);

  useEffect(() => {
    const provinceOptions = State.getStatesOfCountry(
      countryData.find(
        (country) =>
          country.name ===
          businessesList.find((business) => business.id === currentBusinessId)
            ?.country
      )?.isoCode
    );
    const province = provinceOptions.map((province: any) => {
      return { name: province?.name };
    });
    setProvinces(province);
  }, []);

  useEffect(() => {
    const amount = Number(form.getValues("amount")) || 0; // Ensure amount is a number
    const taxRateId = form.getValues("taxRateId");

    const selectedTaxRate = taxRateData.find(
      (taxRate) => taxRate.id === taxRateId
    );

    const tax = Number(selectedTaxRate?.tax_components?.[0]?.tax) || 0; // Ensure tax is a number

    const taxableAmount = (amount * tax) / 100; // Always calculate the taxable amount
    let totalAmount = amount; // Default totalAmount is the same as amount

    if (watchTaxType === "EXCLUDED") {
      totalAmount += taxableAmount; // Only sum the tax if it's excluded
    }

    // Set values accordingly
    form.setValue("taxableAmount", taxableAmount);
    form.setValue("totalAmount", totalAmount);
  }, [form.watch("amount"), form.watch("taxRateId"), watchTaxType]);

  const taxType = [
    { value: "INCLUDED", label: "including tax" },
    { value: "EXCLUDED", label: "excluding tax" },
  ];

  const companyBankOptions = [
    { value: "BUSINESS_BANK_ACCOUNT", label: "Business Bank Account" },
    { value: "BUSINESS_SAVING_ACCOUNT", label: "Business Saving Account" },
  ];
  const [chartOfAccounts, setChartOfAccounts] = useState<
    Array<ChartOfAccounts.FetchChartOfAccounts>
  >([]);
  const [taxRateData, setTaxRateData] = useState<
    BusinessTaxRate.FetchTaxRate[]
  >([]);
  const toast = useToast();
  useEffect(() => {
    if (
      watchPayType === ExpenseClaimApi.ExpenseClaimType.NON_REIMBURSABLE ||
      watchPayType === ExpenseClaimApi.ExpenseClaimType.REIMBURSABLE
    ) {
      ChartOfAccounts.GetAllAccounts(
        {
          category: "EXPENSES",

          page: 1,
          limit: 100,
        },
        (res) => {
          setChartOfAccounts(res.data);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
        }
      );
    } else if (
      watchPayType === ExpenseClaimApi.ExpenseClaimType.OPD ||
      watchPayType === ExpenseClaimApi.ExpenseClaimType.OTHER
    ) {
      PayItemReimbursementApi.FetchReimbursement(
        (data) => {
          const activeData = data.filter((item) => item.active === true);
          setReimbursementAdded(activeData);
        },
        (error) => {
          toast({
            title: "Reimbursement not found",
            status: "error",
            description: error,
          });
        }
      );
    }
    BusinessTaxRate.GetTaxRates(
      (data) => {
        setTaxRateData(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, [watchPayType]);
  const [reimbursementAdded, setReimbursementAdded] = useState<Array<any>>([]);

  const { businessesList, currentBusinessId } = useBusinessContext();
  let countryData = Country.getAllCountries();
  const countryName = businessesList.find(
    (business) => business.id === currentBusinessId
  )?.country;
  const currencyData = countryData.find(
    (country) => country.name === countryName
  )?.currency;
  return (
    <Container maxW={"500px"} padding={"20px"}>
      <Stack spacing={"25px"}>
        <Box>
          <Text
            fontSize={"13px"}
            fontWeight={"600"}
            color={"customColor.black7"}
            marginBottom={"8px"}
          >
            Purchase amount
          </Text>
          <InputGroup>
            <InputLeftAddon h={"38px"}>{currencyData}</InputLeftAddon>
            <Controller
              control={form.control}
              name="amount"
              rules={{
                required: {
                  value: true,
                  message: "Amount is required",
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <CustomInput
                    validationMessage={error?.message}
                    withValidation
                    ref={field.ref}
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      w: "100%",
                      h: "38px",
                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      variant: "outline",
                      value: field.value
                        ? Number(field.value).toLocaleString()
                        : "",
                      onChange: (e) => {
                        const amountValue = Number(
                          e.target.value.replace(/,/g, "")
                        ); // Remove commas before converting
                        if (!isNaN(amountValue)) {
                          field.onChange(amountValue); // Pass the number value to the field
                          e.target.value = amountValue.toLocaleString(); // Format the number with commas
                        }
                      },

                      borderLeftRadius: "0px",
                      borderTopRadius: "3px",
                    }}
                    placeholder=""
                  />
                );
              }}
            />
          </InputGroup>
        </Box>
        <Box>
          <Text
            fontSize={"13px"}
            fontWeight={"600"}
            color={"customColor.black7"}
            marginBottom={"8px"}
          >
            Description
          </Text>
          <Controller
            name="description"
            rules={{
              required: {
                value: true,
                message: "Message is required",
              },
            }}
            control={form.control}
            render={({ field, fieldState }) => (
              <>
                <Textarea
                  {...field}
                  placeholder="Add a message"
                  fontSize=".845rem"
                  isInvalid={fieldState.invalid}
                  fontWeight={400}
                  border="1px solid #e5e7eb"
                  _hover={{
                    border: "1px solid hsl(0, 0%, 70%)",
                  }}
                  _focus={{
                    borderWidth: "1px",

                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  _focusVisible={{
                    borderWidth: "1px",
                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  _light={{
                    color: "customColor.black7",
                  }}
                  padding="12px 15px"
                />
              </>
            )}
          />
        </Box>

        <Box>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            lineHeight={"1.3077"}
            margin={"0px 0px 8px"}
          >
            How did you pay
          </Text>
          <Controller
            name="expenseClaimType"
            rules={{
              required: {
                value: true,
                message: "Type is required",
              },
            }}
            control={form.control}
            render={({ field, fieldState }) => (
              <RadioGroup {...field}>
                <Stack marginBottom={"12px"}>
                  <Radio
                    isInvalid={fieldState.invalid}
                    size="lg"
                    value="NON_REIMBURSABLE"
                    colorScheme="blue"
                    lineHeight={"1.5rem"}
                  >
                    <Text
                      fontSize={"13px"}
                      fontWeight={"500"}
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      Company money (non-reimbursable)
                    </Text>
                  </Radio>
                </Stack>

                <Stack marginBottom={"12px"}>
                  <Radio
                    isInvalid={fieldState.invalid}
                    size="lg"
                    value="REIMBURSABLE"
                    colorScheme="blue"
                    lineHeight={"1.5rem"}
                  >
                    <Text
                      fontSize={"13px"}
                      fontWeight={"500"}
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      Personal money (reimbursable)
                    </Text>
                  </Radio>
                </Stack>
                <Stack marginBottom={"12px"}>
                  <Radio
                    isInvalid={fieldState.invalid}
                    size="lg"
                    value="OPD"
                    colorScheme="blue"
                    lineHeight={"1.5rem"}
                  >
                    <Text
                      fontSize={"13px"}
                      fontWeight={"500"}
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      OPD
                    </Text>
                  </Radio>
                </Stack>
                <Stack>
                  <Radio
                    isInvalid={fieldState.invalid}
                    size="lg"
                    value="OTHERS"
                    colorScheme="blue"
                    lineHeight={"1.5rem"}
                  >
                    <Text
                      fontSize={"13px"}
                      fontWeight={"500"}
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      Other via payroll
                    </Text>
                  </Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </Box>
        {watchPayType === "NON_REIMBURSABLE" && (
          <Box>
            <Text
              textAlign={"left"}
              fontSize=".845rem"
              fontWeight="600"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              lineHeight={"1.3077"}
              margin={"0px 0px 8px"}
            >
              Company bank account
            </Text>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Bank Account Type is required",
                },
              }}
              control={form.control}
              name="bankAccountType"
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  key={field.value}
                  placeholder="Select Income Type"
                  isInvalid={fieldState.invalid}
                  ErrorMessage="Select Account Type"
                  withValidation
                  options={companyBankOptions}
                  value={companyBankOptions.find(
                    (op) => op.value === field.value
                  )}
                  onChange={(newValue: any) => {
                    field.onChange(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              )}
            />
          </Box>
        )}

        <Controller
          rules={{
            required: {
              value: true,
              message: "Spent at is required",
            },
          }}
          control={form.control}
          name="spentAt"
          render={({ field, fieldState: { error } }) => (
            <CustomInput
              ref={field.ref}
              validationMessage={error?.message}
              withValidation
              input={{
                ...field,
                isInvalid: error !== undefined,
                w: "100%",
                h: "38px",
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: ".845rem",
                variant: "outline",

                borderTopRadius: "3px",
                type: "text",
              }}
              placeholder="Spent at"
              label="Spent at"
              labelProps={{
                fontSize: ".845rem",
                _light: {
                  color: "customColor.black7",
                },
              }}
            />
          )}
        />
        <Controller
          rules={{
            required: {
              value: true,
              message: "Spent on is required",
            },
          }}
          control={form.control}
          name="date"
          render={({ field, fieldState: { error } }) => (
            <CustomInput
              ref={field.ref}
              validationMessage={error?.message}
              withValidation
              input={{
                ...field,
                isInvalid: error !== undefined,
                w: "100%",
                h: "38px",
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: ".845rem",
                variant: "outline",

                borderTopRadius: "3px",
                type: "date",
              }}
              placeholder="Spent on"
              label="Spent on"
              labelProps={{
                fontSize: ".845rem",
                _light: {
                  color: "customColor.black7",
                },
              }}
            />
          )}
        />
        {(watchPayType === ExpenseClaimApi.ExpenseClaimType.OPD ||
          watchPayType === ExpenseClaimApi.ExpenseClaimType.OTHER) && (
          <Stack gap={0}>
            <Text
              textAlign={"left"}
              fontSize=".845rem"
              fontWeight="600"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              margin={"0px 0px 8px 0px"}
            >
              Reimbursement From Payroll
            </Text>
            <Controller
              control={form.control}
              name="payItemReimbursementId"
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  key={field.value}
                  withValidation
                  isInvalid={fieldState.invalid}
                  placeholder="Select..."
                  options={reimbursementAdded.map((reimbursement) => ({
                    value: reimbursement.id,
                    label: reimbursement.name,
                  }))}
                  value={
                    reimbursementAdded.find(
                      (reimbursement) => reimbursement.id === field.value
                    )
                      ? {
                          value: field.value,
                          label: reimbursementAdded.find(
                            (reimbursement) => reimbursement.id === field.value
                          ).name,
                        }
                      : null
                  }
                  onChange={(newValue: any) => field.onChange(newValue?.value)}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              )}
            />
          </Stack>
        )}

        {(watchPayType === "NON_REIMBURSABLE" ||
          watchPayType === "REIMBURSABLE") && (
          <Box>
            <Text
              fontSize={"13px"}
              fontWeight={"600"}
              color={"customColor.black7"}
              marginBottom={"8px"}
            >
              Chart of Account
            </Text>
            <Controller
              name={`chartAccountId`}
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Account is required",
                },
              }}
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  placeholder=""
                  isInvalid={fieldState.invalid}
                  ErrorMessage="Select chart of account"
                  withValidation
                  options={chartOfAccounts?.map((acc: any) => ({
                    value: acc.id,
                    label: `${acc.code}: ${acc.name}`, // Including code before name
                  }))}
                  value={chartOfAccounts
                    ?.map((acc: any) => ({
                      value: acc.id,
                      label: `${acc.code}: ${acc.name}`, // Including code before name
                    }))
                    .find((option: any) => option.value === field.value)}
                  onChange={(newValue: any) => field.onChange(newValue?.value)}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              )}
            />
          </Box>
        )}

        <Box>
          <Text
            fontSize={"13px"}
            fontWeight={"600"}
            color={"customColor.black7"}
            marginBottom={"8px"}
          >
            Region
          </Text>
          <Controller
            control={form.control}
            name={`region`}
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                key={field.value}
                options={provinces}
                placeholder="Select..."
                value={provinces?.find((op: any) => op.name === field.value)}
                onChange={(newValue: any) => {
                  field.onChange(newValue?.name);
                }}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.name}
              />
            )}
          />
        </Box>
        <Controller
          control={form.control}
          name="assignTo"
          render={({ field, fieldState: { error } }) => (
            <CustomInput
              ref={field.ref}
              input={{
                ...field,

                w: "100%",
                h: "38px",
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: ".845rem",
                variant: "outline",

                borderTopRadius: "3px",
                type: "text",
              }}
              placeholder="Assign to project or customer"
              label="Assign to project or customer"
              labelProps={{
                fontSize: ".845rem",
                _light: {
                  color: "customColor.black7",
                },
              }}
            />
          )}
        />
        <Controller
          control={form.control}
          name="label"
          render={({ field, fieldState: { error } }) => (
            <CustomInput
              ref={field.ref}
              input={{
                ...field,

                w: "100%",
                h: "38px",
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: ".845rem",
                variant: "outline",

                borderTopRadius: "3px",
                type: "text",
              }}
              placeholder="Label"
              label="Label"
              labelProps={{
                fontSize: ".845rem",
                _light: {
                  color: "customColor.black7",
                },
              }}
            />
          )}
        />
      </Stack>
      <Stack flex={1} marginTop={"15px"} borderTop={"2px solid #e5e7eb"}>
        <HStack justifyContent={"space-between"} marginTop={"5px"}>
          <HStack>
            <Text
              _light={{
                color: "customColor.black7",
              }}
              fontWeight={"400"}
              fontSize={"15px"}
            >
              Subtotal
            </Text>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Tax Type is required",
                },
              }}
              control={form.control}
              name="taxType"
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  key={field.value}
                  placeholder="Select tax Type"
                  isInvalid={fieldState.invalid}
                  ErrorMessage="Select tax Type"
                  withValidation
                  options={taxType}
                  value={taxType.find((op) => op.value === field.value)}
                  onChange={(newValue: any) => {
                    field.onChange(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              )}
            />
          </HStack>
          <Text
            _light={{
              color: "customColor.black7",
            }}
            fontWeight={"400"}
            fontSize={"15px"}
          >
            {`${currencyData} ${
              form.getValues("amount")?.toLocaleString() ?? 0
            }`}
          </Text>
        </HStack>

        <HStack justifyContent={"space-between"}>
          <Stack flex={1}>
            <Controller
              name={`taxRateId`}
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Tax Rate is required",
                },
              }}
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  placeholder=""
                  isInvalid={fieldState.invalid}
                  ErrorMessage="Select Tax type"
                  withValidation
                  options={taxRateData}
                  value={
                    field.value
                      ? {
                          id: field.value,
                          name:
                            taxRateData.find(
                              (taxRateData) => taxRateData.id === field.value
                            )?.name ?? "",
                        }
                      : undefined
                  }
                  onChange={(val: any) => {
                    field.onChange(val?.id);
                  }}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.id}
                />
              )}
            />
          </Stack>
          <Stack flex={2} alignItems={"flex-end"}>
            <Text
              _light={{
                color: "customColor.black7",
              }}
              fontWeight={"400"}
              fontSize={"15px"}
            >
              {`${currencyData} ${
                form.watch("taxableAmount")?.toLocaleString() ?? 0
              }`}
            </Text>
          </Stack>
        </HStack>

        <Stack borderTop={"2px solid #e5e7eb"}>
          <HStack
            justifyContent={"space-between"}
            paddingTop={"10px"}
            paddingBottom={"10px"}
          >
            <Text
              _light={{
                color: "customColor.black7",
              }}
              fontWeight={"400"}
              fontSize={"15px"}
            >
              Total
            </Text>
            <Text
              _light={{
                color: "customColor.black7",
              }}
              fontWeight={"700"}
              fontSize={"21px"}
            >
              {`${currencyData} ${
                form.watch("totalAmount")?.toLocaleString() ?? 0
              }`}
            </Text>
          </HStack>
        </Stack>
      </Stack>
    </Container>
  );
}
