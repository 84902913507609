import {
  HStack,
  Icon,
  IconButton,
  Input,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  SimpleGrid,
} from "@chakra-ui/react";
import { Control, Controller } from "react-hook-form";
import { MCQ } from "../../../modules/Audit/MCQs";
interface ColorsPopoverProps {
  optionIndex: number;
  formControl: Control<MCQ.IMCQ, any>;
}
export default function ColorsPopover({
  optionIndex,
  formControl,
}: ColorsPopoverProps) {
  return (
    <PopoverContent
      w="208px"
      h="184px"
      borderRadius="5px"
      padding="8px"
      background="white"
    >
      <PopoverArrow />
      <PopoverHeader padding="0px" marginBottom="16px">
        <Controller
          control={formControl}
          name={`options.${optionIndex}.color`}
          render={({ field }) => (
            <HStack spacing={0}>
              <IconButton
                as={Icon}
                aria-label=""
                backgroundColor={field.value}
                h="32px"
                w="32px"
                borderLeftRadius="3.2px"
                _hover={{ backgroundColor: "none" }}
                _focusVisible={{ backgroundColor: "none" }}
                _active={{ backgroundColor: "none" }}
                _focus={{ backgroundColor: "none" }}
              />

              <Input
                padding="8px 16px 8px 8px"
                placeholder="Hex Code"
                borderColor="borders.accent"
                fontSize="16px"
                color="textcolors.primary"
                size="sm"
                borderRadius="none"
                borderRightRadius="3.2px"
                borderLeft="none"
                _focusVisible={{ boxShadow: "none" }}
                {...field}
              />
            </HStack>
          )}
        />
      </PopoverHeader>
      <PopoverBody padding="0px">
        <SimpleGrid columns={5} spacing={2}>
          {[
            "#c60022",
            "#9c6d1e",
            "#fe8500",
            "#81b532",
            "#13855f",
            "#00b6cb",
            "#648fff",
            "#0044a3",
            "#c22dd5",
            "#dc267f",
            "#707070",
          ].map((color, i) => (
            <Controller
              key={i}
              control={formControl}
              name={`options.${optionIndex}.color`}
              render={({ field }) => (
                <Coloredbox
                  Color={color}
                  onClick={() => {
                    field.onChange(color);
                  }}
                />
              )}
            />
          ))}
          {/*           
          <Coloredbox Color="#9c6d1e" onClick={onChangeColor} />
          <Coloredbox Color="#fe8500" onClick={onChangeColor} />
          <Coloredbox Color="#ffb000" onClick={onChangeColor} />
          <Coloredbox Color="#81b532" onClick={onChangeColor} />
          <Coloredbox Color="#13855f" onClick={onChangeColor} />
          <Coloredbox Color="#00b6cb" onClick={onChangeColor} />
          <Coloredbox Color="#648fff" onClick={onChangeColor} />
          <Coloredbox Color="#0044a3" onClick={onChangeColor} />
          <Coloredbox Color="#c22dd5" onClick={onChangeColor} />
          <Coloredbox Color="#dc267f" onClick={onChangeColor} />
          <Coloredbox Color="#707070" onClick={onChangeColor} /> */}
        </SimpleGrid>
      </PopoverBody>
    </PopoverContent>
  );
}
interface Colorprops {
  Color: string;
  onClick: () => void;
}
function Coloredbox({ Color, onClick }: Colorprops) {
  return (
    <IconButton
      aria-label=""
      backgroundColor={Color}
      h="32px"
      w="32px"
      borderRadius="3.2px"
      _hover={{ backgroundColor: "none" }}
      _focusVisible={{ backgroundColor: "none" }}
      _active={{ backgroundColor: "none" }}
      _focus={{ backgroundColor: "none" }}
      onClick={() => onClick()}
    />
  );
}
