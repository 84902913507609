import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {InventoryCategoryModule} from '../../../../../../modules/Audit/InventoryCategories';
interface UpdateInventoryCategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedId: number | undefined;
  selectedCategory: InventoryCategoryModule.InventoryCategoryData | undefined;
  setLastUpdate: (timestamp: number) => void;
}
export default function UpdateInventoryCategoryModal({
  isOpen,
  onClose,
  selectedCategory,
  selectedId,
  setLastUpdate,
}: UpdateInventoryCategoryModalProps) {
  const toast = useToast();
  const [iconVisible, setIconVisible] = useState(false);
  const form = useForm<InventoryCategoryModule.InventoryCategoryData>();
  useEffect(() => {
    form.reset({
      name: selectedCategory?.name,
    });
  }, [selectedCategory]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text color="textcolors.primary">Update inventory category</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box marginTop="24px">
            <Controller
              control={form.control}
              name={'name'}
              rules={{
                required: {
                  value: true,
                  message: 'Category name is required',
                },
                onBlur: () => {
                  setIconVisible(false);
                },
              }}
              render={({field, fieldState: {error}}) => (
                <FormControl isInvalid={error !== undefined}>
                  <Input
                    isInvalid={error !== undefined}
                    h="38px"
                    padding="5px"
                    w="100%"
                    border="1px"
                    borderColor="primary.500"
                    onFocus={() => setIconVisible(true)}
                    placeholder="Enter item"
                    _focusVisible={{
                      boxShadow: '0px',
                      border: '1px solid',
                      borderColor: 'primary.500',
                    }}
                    {...field}
                  />
                  <FormErrorMessage>{error?.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button
              variant="outline"
              borderRadius="8px"
              h="43px"
              px="20px"
              py="12px"
              onClick={onClose}>
              Close
            </Button>
            <Button
              //   isLoading={isLoading}
              variant="solid"
              borderRadius="8px"
              h="43px"
              px="20px"
              py="12px"
              onClick={form.handleSubmit(data => {
                if (selectedId) {
                  InventoryCategoryModule.UpdateInventoryCategory(
                    selectedId,
                    data,
                    () => {
                      toast({
                        title: 'Success',
                        description: 'Category Updated successfully',
                        status: 'success',
                      });
                      setLastUpdate(Date.now());
                      onClose();
                    },
                    error => {
                      toast({
                        title: 'Error',
                        description: error,
                        status: 'error',
                      });
                    },
                  );
                }
              })}>
              Update
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
