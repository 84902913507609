import { PayRun } from "../../../Api/PayRun";
import { PayTemp } from "../../../Api/PayTemp";
import { TaxFinancialYear } from "../../../Api/TaxFinancialYear";

export function generatePayRunItems(
  employeeId: string,
  payRunId: string,
  payTemplateItems: Array<PayRun.PayrunItem>,
  payFrequency: number,
  taxFinancialYear: TaxFinancialYear.FetchTax,
  payslipId: string,
  socialSecurityRequired: boolean
) {
  const calcTypeOrder: any = {
    [PayTemp.PayTemplateCalcTypeEnum.FIXED_AMOUNT]: 1,
    [PayTemp.PayTemplateCalcTypeEnum.ANNUAL_SALARY]: 2,
    [PayTemp.PayTemplateCalcTypeEnum.RATE]: 3,
    [PayTemp.PayTemplateCalcTypeEnum.USE_EARNINGS_RATE_MULTIPLY]: 4,
    [PayTemp.PayTemplateCalcTypeEnum["PRE-TAX"]]: 5,
    [PayTemp.PayTemplateCalcTypeEnum["POST-TAX"]]: 6,
    [PayTemp.PayTemplateCalcTypeEnum.REIMBURSEMENT]: 7,
  };

  let totalEarnings: number = 0;
  let totalTaxableEarnings: number = 0;
  let totalOfPreTaxDeductions: number = 0;
  console.log("socialSecurityRequired", socialSecurityRequired);
  const earnings: Array<Partial<PayRun.PayrunItem>> = payTemplateItems
    .filter((i) => i.category === PayTemp.PayTemplateItemTypeEnum.EARNING)
    .map((item) => {
      let amount = item.amount;

      if (
        item.hours !== undefined &&
        item.rate !== undefined &&
        item.hours !== null &&
        item.rate !== null
      ) {
        amount = item.hours * item.rate;
      }

      if (item.isTaxable) {
        totalTaxableEarnings = totalTaxableEarnings + (amount ?? 0);
      }

      let payrunItem = {
        ...item,
        employeeId: item.employeeId ?? undefined,
        calculationType: item.calculationType ?? undefined,
        amount: amount !== undefined ? Math.round(amount) : 0,
        hours: item.hours ?? undefined,
        percentage: item.percentage ?? undefined,
        rate: item.rate ?? undefined,
        category: item.category ?? undefined,
        payItemEarningId: item.payItemEarningId ?? undefined,
        payItemLeaveId: item.payItemLeaveId ?? undefined,
        payRunId: item.payRunId ?? undefined,
        id: item.id,
        isTaxable: item.isTaxable ?? undefined,
        payslipId: item.payslipId ?? undefined,
        timesheetId: item.timesheetId,
      } as PayRun.PayrunItem;

      totalEarnings = totalEarnings + (amount ?? 0);
      return payrunItem;
    });
  const TOTAL_EARNING_TAX = calcTaxOnEarning(
    totalEarnings,
    payFrequency,
    taxFinancialYear
  );

  let totalDeductions = 0;
  let totalTaxableDeductions = 0;
  const deductions = payTemplateItems
    .filter((i) => i.category === PayTemp.PayTemplateItemTypeEnum.DEDUCTION)
    .sort(
      (a, b) =>
        calcTypeOrder[a.calculationType] - calcTypeOrder[b.calculationType]
    )
    .map((item) => {
      const isTaxable = item.isTaxable;
      if (
        item.calculationType === PayTemp.PayTemplateCalcTypeEnum.FIXED_AMOUNT
      ) {
        item.amount = item.amount;
        totalOfPreTaxDeductions = totalOfPreTaxDeductions + (item.amount ?? 0);
      }

      if (
        item.calculationType === PayTemp.PayTemplateCalcTypeEnum.EMPLOYEE_EOBI
      ) {
        item.amount =
          (item?.payItemDeduction?.minimumWage ?? 0) *
          ((item?.percentage ?? 0) / 100);
      }
      if (
        [PayTemp.PayTemplateCalcTypeEnum.SOCIAL_SECURITY].includes(
          item.calculationType
        )
      ) {
        const totalAmountToCalcSSOn = earnings
          .filter((i) =>
            i?.payItemEarningId
              ? item.payItemDeduction?.earningPayItems?.includes(
                  i?.payItemEarningId
                )
              : false
          )
          .reduce((acc, curr) => {
            return acc + (curr.amount ?? 0);
          }, 0);
        if (totalAmountToCalcSSOn < (item.payItemDeduction?.minimumWage ?? 0)) {
          item.amount = totalAmountToCalcSSOn * ((item.percentage ?? 0) / 100);
        } else {
          if (socialSecurityRequired) {
            item.amount =
              (item.payItemDeduction?.minimumWage ?? 0) *
              ((item.percentage ?? 0) / 100);
          } else {
            item.amount = 0;
          }
        }
        if (totalAmountToCalcSSOn < (item.payItemDeduction?.minimumWage ?? 0)) {
          item.minimumWage = totalAmountToCalcSSOn;
        } else {
          item.minimumWage = item.payItemDeduction?.minimumWage ?? 0;
        }
      }

      if (
        item.calculationType ===
        PayTemp.PayTemplateCalcTypeEnum.EMPLOYEE_PROVIDENT_FUND
      ) {
        const totalAmountToCalcPFOn = earnings
          .filter((i) =>
            i.payItemEarningId
              ? item?.payItemDeduction?.earningPayItems?.includes(
                  i.payItemEarningId
                )
              : false
          )
          .reduce((acc, curr) => {
            return acc + (curr.amount ?? 0);
          }, 0);

        item.amount = totalAmountToCalcPFOn * ((item.percentage ?? 0) / 100);
      }
      if (
        [
          PayTemp.PayTemplateCalcTypeEnum["POST-TAX"],
          PayTemp.PayTemplateCalcTypeEnum["PRE-TAX"],
        ].includes(item.calculationType)
      ) {
        if (
          item.calculationType === PayTemp.PayTemplateCalcTypeEnum["PRE-TAX"]
        ) {
          let value = totalEarnings * ((item.percentage ?? 0) / 100);
          item.amount = value;
          totalOfPreTaxDeductions = totalOfPreTaxDeductions + value;
        }

        if (
          item.calculationType === PayTemp.PayTemplateCalcTypeEnum["POST-TAX"]
        ) {
          item.amount =
            (totalEarnings - TOTAL_EARNING_TAX - totalOfPreTaxDeductions) *
            ((item.percentage ?? 0) / 100);
        }
      }

      if (isTaxable) totalTaxableDeductions += item.amount ?? 0;
      totalDeductions += item.amount ?? 0;
      item.amount =
        item.amount !== null || item.amount !== undefined
          ? Math.round(item.amount as any)
          : undefined;
      item.payslipId = item.payslipId ?? undefined;
      return item;
    });
  const TOTAL_FINAL_TAX = calcTaxOnEarning(
    totalTaxableEarnings - totalTaxableDeductions,
    payFrequency,
    taxFinancialYear
  );

  const reimbursments = payTemplateItems
    .filter((i) => i.category === PayTemp.PayTemplateItemTypeEnum.REIMBURSEMENT)
    .map((item) => {
      let payrunItem = {
        employeeId: item.employeeId ?? undefined,
        calculationType: item.calculationType ?? undefined,
        amount: item.amount ? Math.round(item.amount) : undefined,
        category: item.category ?? undefined,
        payItemReimbursementId: item.payItemReimbursementId ?? undefined,
        payRunId: item.payRunId ?? undefined,
        id: item.id,
        payslipId: item.payslipId ?? undefined,
      } as PayRun.PayrunItem;
      return payrunItem;
    });

  const retirements = payTemplateItems
    .filter((i) => i.category === PayTemp.PayTemplateItemTypeEnum.RETIREMENT)
    .map((item) => {
      if (
        [
          PayTemp.PayTemplateCalcTypeEnum.EMPLOYER_EOBI,
          // PayTemp.PayTemplateCalcTypeEnum.SOCIAL_SECURITY,
        ].includes(item.calculationType)
      ) {
        item.amount = (item.minimumWage ?? 0) * ((item.percentage ?? 0) / 100);
      }

      if (
        [
          PayTemp.PayTemplateCalcTypeEnum.GRATUITY,
          PayTemp.PayTemplateCalcTypeEnum.EMPLOYER_PROVIDENT_FUND,
        ].includes(item.calculationType)
      ) {
        const totalAmountToCalcPFOn = earnings
          .filter((i) =>
            i?.payItemEarningId
              ? item.payItemRetirement?.earningPayItems?.includes(
                  i?.payItemEarningId
                )
              : false
          )
          .reduce((acc, curr) => {
            return acc + (curr.amount ?? 0);
          }, 0);

        item.amount = totalAmountToCalcPFOn * ((item.percentage ?? 0) / 100);
      }
      if (
        [PayTemp.PayTemplateCalcTypeEnum.SOCIAL_SECURITY].includes(
          item.calculationType
        )
      ) {
        const totalAmountToCalcSSOn = earnings
          .filter((i) =>
            i?.payItemEarningId
              ? item.payItemRetirement?.earningPayItems?.includes(
                  i?.payItemEarningId
                )
              : false
          )
          .reduce((acc, curr) => {
            return acc + (curr.amount ?? 0);
          }, 0);
        if (
          totalAmountToCalcSSOn < (item.payItemRetirement?.minimumWage ?? 0)
        ) {
          item.amount = totalAmountToCalcSSOn * ((item.percentage ?? 0) / 100);
        } else {
          if (socialSecurityRequired) {
            item.amount =
              (item.payItemRetirement?.minimumWage ?? 0) *
              ((item.percentage ?? 0) / 100);
          } else {
            item.amount = 0;
          }
        }
        if (
          totalAmountToCalcSSOn < (item.payItemRetirement?.minimumWage ?? 0)
        ) {
          item.minimumWage = totalAmountToCalcSSOn;
        } else {
          item.minimumWage = item.payItemRetirement?.minimumWage ?? 0;
        }
      }
      item.amount =
        item.amount !== null || item.amount !== undefined
          ? Math.round(item.amount as any)
          : undefined;
      item.payslipId = item.payslipId ?? undefined;
      return item;
    });
  const leaves = payTemplateItems
    .filter((i) => i.category === PayTemp.PayTemplateItemTypeEnum.LEAVE)
    .map((item) => {
      let payrunItem = {
        ...item,
        employeeId: item.employeeId ?? undefined,
        calculationType: item.calculationType ?? undefined,
        amount: item.amount ? Math.round(item.amount) : undefined,
        category: item.category ?? undefined,
        payItemLeaveId: item.payItemLeaveId ?? undefined,
        payRunId: item.payRunId ?? undefined,
        id: item.id,
        hours: item.hours ?? undefined,
        payslipId: item.payslipId ?? undefined,
      } as PayRun.PayrunItem;
      return payrunItem;
    });

  const autoCalculatedTaxLine: PayRun.PayrunItem = {
    employeeId: employeeId ?? undefined,
    amount: TOTAL_FINAL_TAX,
    category: PayTemp.PayTemplateItemTypeEnum.TAX,
    calculationType: PayTemp.PayTemplateCalcTypeEnum.EARNING_TAX,
    payRunId,
    id: payTemplateItems.find(
      (py) => py.category === PayTemp.PayTemplateItemTypeEnum.TAX
    )?.id,
    payslipId,
  };

  return {
    earnings,
    deductions,
    reimbursments,
    retirements,
    taxes: [autoCalculatedTaxLine],
    leaves,
  };
}

export function calcTaxOnEarning(
  earningForThisPayRun: number,
  payFrequency: number,
  financialYear: TaxFinancialYear.FetchTax
) {
  if (earningForThisPayRun < 0) {
    earningForThisPayRun = 0;
  }
  let earning = earningForThisPayRun * payFrequency;

  let percentage = 0;
  let minimumAmount = 0;
  let bracketFixedAmount = 0;
  const taxBracket = financialYear.taxBrackets.find((taxBracket) => {
    return earning >= taxBracket.minimum && earning <= taxBracket.maximum;
  });
  if (taxBracket) {
    percentage = taxBracket.percentage;
    bracketFixedAmount = taxBracket.fixedAmount;
    minimumAmount = taxBracket.minimum;
  } else {
    const maxBracket = financialYear.taxBrackets.find(
      (taxBracket) => taxBracket.isHighestSlab
    );

    if (maxBracket) {
      percentage = maxBracket.percentage;
      bracketFixedAmount = maxBracket.fixedAmount;
      minimumAmount = maxBracket.minimum;
    } else {
      return 0;
    }
  }

  const fixedAmount = bracketFixedAmount;
  const tax = fixedAmount + (earning - minimumAmount) * (percentage / 100);
  return Math.round(tax / payFrequency);
}
