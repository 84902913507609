import {Button, HStack, Icon, Stack} from '@chakra-ui/react';
import {Fragment} from 'react';
import {MdOutlineCheckCircleOutline} from 'react-icons/md';
import Content from '../../pages/Content';
import SubHeading from '../../pages/Sub-Heading';

interface uploadcompleteprops {
  updateComponent: any;
}
export default function UploadCompleteModal({
  updateComponent,
}: uploadcompleteprops) {
  return (
    <Fragment>
      <Stack
        h="350px"
        display="flex"
        justifyContent="center"
        alignItems="center">
        <HStack align="top">
          <Icon
            as={MdOutlineCheckCircleOutline}
            boxSize="100px"
            color="#17966c"
          />
          <Stack pt="8px">
            <SubHeading fontSize="21px">Upload Complete</SubHeading>
            <Content>250 created</Content>
          </Stack>
        </HStack>
        <Button
          px="20px"
          py="12px"
          borderRadius="8px"
          h="41px"
          maxW="100px"
          //   onClick={onClose}
        >
          Continue
        </Button>
      </Stack>
    </Fragment>
  );
}
