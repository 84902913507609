import {
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Fragment } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { FiChevronLeft } from "react-icons/fi";

interface Siteprops {
  onBack?: () => void;
}
export default function Site({ onBack }: Siteprops) {
  // const {isOpen, onOpen, onClose, onToggle} = useDisclosure();
  return (
    // <Popover isOpen={isOpen} matchWidth={true}>
    //   <PopoverContent w="100%" minW="200px" pb="8px">
    //     <PopoverHeader
    //       p="16px"
    //       borderBottom="1px solid"
    //       borderColor="backgrounds.primary">
    //       <HStack textAlign="center" lineHeight="16px">
    //         <HStack spacing={1} onClick={onClose}>
    //           <FiChevronLeft size={14} color="#1da5d4" />
    //           <Text fontSize="14px" fontWeight={400} color="textcolors.primary">
    //             Back
    //           </Text>
    //         </HStack>
    //         <Text flex={1} fontSize="14px" fontWeight={500} color="primary.500">
    //           Select Site
    //         </Text>
    //       </HStack>
    //     </PopoverHeader>
    //     <PopoverBody p="8px">
    <Fragment>
      <Stack p="10px">
        {onBack && (
          <HStack
            textAlign="center"
            lineHeight="16px"
            p="6px"
            borderBottom="1px"
            borderColor="backgrounds.primary"
          >
            <HStack spacing={1} onClick={onBack}>
              <FiChevronLeft size={14} color="#1da5d4" />
              <Text fontSize="14px" fontWeight={400} color="textcolors.primary">
                Back
              </Text>
            </HStack>
            <Text flex={1} fontSize="14px" fontWeight={500} color="primary.500">
              Select Site
            </Text>
          </HStack>
        )}
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<AiOutlineSearch color="#545f70" size="18px" />}
          />
          <Input
            w="100%"
            placeholder="Search"
            border="1px solid"
            borderColor="borders.accent"
            borderRadius="12px"
            fontSize="16px"
            fontWeight={400}
            color="textcolors.primary"
            py="8px"
            pl="32px"
            _focusVisible={{
              boxShadow: "none",
              border: "1px sloid",
              borderColor: "primary.500",
            }}
          ></Input>
        </InputGroup>
        <VStack p="32px">
          <Text fontSize="12px" fontWeight={500} color="textcolors.primary">
            No sites
          </Text>
          <Text fontSize="14px" fontWeight={400} color="icons.primary">
            You are not a member of any sites.
          </Text>
        </VStack>
      </Stack>
    </Fragment>
    //     </PopoverBody>
    //     <PopoverFooter borderColor="borders.accent">
    //       <HStack w="100%" justify="end">
    //         <Button
    //           h="40px"
    //           borderRadius="5px"
    //           fontSize="14px"
    //           fontWeight={500}
    //           variant="outline"
    //           padding={'12px 20px'}
    //           onClick={onClose}>
    //           Clear selections
    //         </Button>
    //         <Button
    //           h="40px"
    //           w={'auto'}
    //           borderRadius="5px"
    //           fontSize="14px"
    //           fontWeight={500}
    //           marginRight="16px"
    //           padding={'12px 20px'}>
    //           Done
    //         </Button>
    //       </HStack>
    //     </PopoverFooter>
    //   </PopoverContent>
    // </Popover>
  );
}
