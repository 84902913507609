import {
  Button,
  Center,
  Collapse,
  Container,
  HStack,
  Icon,
  IconButton,
  Link,
  Stack,
  Text,
  useDisclosure,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {useFormContext} from 'react-hook-form';
import {FaRegFilePdf} from 'react-icons/fa';
import {FiChevronDown, FiChevronRight} from 'react-icons/fi';
import {Report} from '../../../../../../modules/Audit';

export default function InspectionDocuments() {
  const {isOpen, onToggle} = useDisclosure();
  const allDocuments = useFormContext<Report.IReport>();
  const inspectionDocuments = allDocuments.getValues('templateMedia');
  return (
    <Container
      p="16px"
      maxW="container.md"
      borderRadius="16px"
      backgroundColor="backgrounds.primary"
      lineHeight={1}>
      <HStack spacing={0}>
        <Button
          marginRight="8px"
          as={IconButton}
          justifyContent="start"
          p={0}
          aria-label={'dropdown'}
          backgroundColor="transparent"
          _hover={{backgroundColor: 'transparent'}}
          onClick={onToggle}
          icon={
            isOpen ? (
              <FiChevronDown color={'#828ea0'} size={24} />
            ) : (
              <FiChevronRight color={'#828ea0'} size={24} />
            )
          }
        />
        <Text flex={1} fontSize="21px" fontWeight={500} color="#3f495a">
          Inspection Documents
        </Text>
        <Text color="#007a52" fontSize="16px" fontWeight={400}>
          {`${inspectionDocuments?.length} files`}
        </Text>
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        <Stack spacing={0} mt="16px" bg="white" p="8px" borderRadius="5px">
          <Wrap>
            {inspectionDocuments?.map((doc, i) => (
              <WrapItem key={i} cursor="pointer">
                <Center w="100px" h="80px">
                  <Link href={doc.attachmentUrl} isExternal>
                    <Icon as={FaRegFilePdf} boxSize={5} />
                    <Text fontSize="11px">{doc.attachmentName}</Text>
                  </Link>
                </Center>
              </WrapItem>
            ))}
          </Wrap>
        </Stack>
      </Collapse>
    </Container>
  );
}
