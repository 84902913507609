import {
  Avatar,
  Button,
  HStack,
  Icon,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tag,
  Text,
  UnorderedList,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Country } from "country-state-city";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import { FiMoreVertical } from "react-icons/fi";
import { useBusinessContext } from "../../../../../Hooks/BusinessContext";
import { ExpenseClaimApi } from "../../../../Api/ExpenseClaimApi";

import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import DeleteClaim from "./DeleteClaim";
import { AddExpenseClaim } from "./Modal/AddExpenseClaim";
import { AddMileageClaim } from "./Modal/AddMileageClaim";

interface Props {
  setLastUpdated: React.Dispatch<React.SetStateAction<number>>;
  expenseClaimData: ExpenseClaimApi.FetchExpenseClaim;
}
export function ExpenseTag({ expenseClaimData, setLastUpdated }: Props) {
  const toast = useToast();
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  const deleteModal = useDisclosure();
  const [deleteId, setDeleteId] = useState<string>("");
  const { businessesList, currentBusinessId } = useBusinessContext();
  let countryData = Country.getAllCountries();
  const countryName = businessesList.find(
    (business) => business.id === currentBusinessId
  )?.country;
  const form = useForm<ExpenseClaimApi.FetchExpenseClaim>();
  const currencyData = countryData.find(
    (country) => country.name === countryName
  )?.currency;
  const expenseClaim = useDisclosure();
  const { allEmployeesList } = useBusinessContext();
  const mileageClaim = useDisclosure();
  const handleFormSubmit = (status: ExpenseClaimApi.ExpenseClaimStatus) => {
    form.handleSubmit((data) => {
      ExpenseClaimApi.changeClaimStatus(
        expenseClaimData.id,
        {
          status: status,
        },
        (success) => {
          toast({
            title: "Success",
            description: success,
            status: "success",
          });
          setLastUpdated(Date.now());
        },
        (err) => {
          toast({
            title: "Error",
            description: err,
            status: "error",
          });
        }
      );
    })();
  };
  return (
    <HStack
      border={"1px solid #e5e7eb"}
      padding={"12px 0px"}
      _hover={{
        bg: "#f2f3f4",
      }}
      h={"fit-content"}
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      alignItems={"flex-start"}
      borderRadius={"3px"}
      paddingRight={"20px"}
    >
      <HStack
        flex={1}
        cursor={"pointer"}
        alignItems={{ xs: "flex-start", md: "center" }}
      >
        <Avatar
          name={
            allEmployeesList?.find((emp) => emp.id === expenseClaimData.empId)
              ?.firstName +
            " " +
            allEmployeesList?.find((emp) => emp.id === expenseClaimData.empId)
              ?.lastName
          }
          h={"38.5px"}
          w={"38.5px"}
          size={"sm"}
          fontWeight={"bold"}
          marginLeft={"8px"}
        />
        <Stack flex={1} direction={{ xs: "column", md: "row" }} gap={0}>
          <Stack spacing="0px">
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
            >
              {expenseClaimData.claimType === "EXPENSE_CLAIM" && (
                <Text
                  fontSize="18px"
                  fontWeight={"bold"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _hover={{
                    _light: {
                      color: "#3454d1",
                    },
                  }}
                >
                  {expenseClaimData.spentAt}
                </Text>
              )}
              {expenseClaimData.claimType === "MILAGE_CLAIM" && (
                <Text
                  fontSize="18px"
                  fontWeight={"bold"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _hover={{
                    _light: {
                      color: "#3454d1",
                    },
                  }}
                >
                  {`${expenseClaimData.milageToClaim} KM`}
                </Text>
              )}
              <Text
                fontSize="13.44px"
                fontWeight={"normal"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                {`"${expenseClaimData.description}"`}
              </Text>
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
            >
              {expenseClaimData.claimType === "EXPENSE_CLAIM" && (
                <Text
                  fontSize="13.44px"
                  fontWeight={"normal"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  {`Spent ${moment(expenseClaimData.date).format(
                    "DD MMMM YYYY"
                  )}`}
                </Text>
              )}
              {expenseClaimData.claimType === "MILAGE_CLAIM" && (
                <Text
                  fontSize="13.44px"
                  fontWeight={"normal"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  {`Traveled ${moment(expenseClaimData.date).format(
                    "DD MMMM YYYY"
                  )}`}
                </Text>
              )}

              <UnorderedList>
                <ListItem
                  fontSize="13.44px"
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontWeight={"bold"}
                >
                  {expenseClaimData.expenseClaimType ===
                  ExpenseClaimApi.ExpenseClaimType.REIMBURSABLE
                    ? "Reimbursement"
                    : expenseClaimData.expenseClaimType ===
                      ExpenseClaimApi.ExpenseClaimType.NON_REIMBURSABLE
                    ? "Non Reimbursement"
                    : expenseClaimData.expenseClaimType ===
                      ExpenseClaimApi.ExpenseClaimType.OPD
                    ? "OPD"
                    : expenseClaimData.expenseClaimType ===
                      ExpenseClaimApi.ExpenseClaimType.OTHER
                    ? "Other"
                    : "NA"}
                </ListItem>
                {/* {leave?.note && (
                <ListItem    fontSize="13.44px"
                            _light={{
                              color: "customColor.black7",
                            }}>{leave?.note}</ListItem>
              )} */}
              </UnorderedList>

              <Text
                fontSize="13.44px"
                _light={{
                  color: "customColor.black7",
                }}
              >{`(${currencyData} ${expenseClaimData.totalAmount.toLocaleString()})`}</Text>
            </Stack>
          </Stack>
          <HStack
            justifyContent={{ xs: "flex-start", md: "flex-end" }}
            gap={0}
            flex={1}
          >
            <Text
              lineHeight={"1.3077"}
              fontSize=".9975rem"
              _light={{
                color: "customColor.black7",
              }}
              justifyContent={{ xs: "flex-start", md: "flex-end" }}
              fontWeight={"bold"}
              padding={"0px 12px 0px 0px"}
              display={"flex"}
              flex={1}
              _hover={{
                _light: {
                  color: "#3454d1",
                },
              }}
            >
              {expenseClaimData.claimType ===
              ExpenseClaimApi.ClaimType.EXPENSE_CLAIM
                ? "Expense Claim"
                : expenseClaimData.claimType ===
                  ExpenseClaimApi.ClaimType.MILAGE_CLAIM
                ? "Mileage Claim"
                : "NA"}
            </Text>

            <Tag
              color={
                expenseClaimData.status === "SUBMITTED"
                  ? "#FFA21D"
                  : expenseClaimData.status === "DRAFT"
                  ? "#3454d1" // Background color for pending
                  : expenseClaimData.status === "APPROVED"
                  ? "#17C666"
                  : "defaultBgColor" // fallback background color
              }
              padding="5px 6px"
              fontWeight={600}
              fontSize={"11px"}
              lineHeight={1.5}
              borderRadius={"3px"}
              backgroundColor={
                expenseClaimData.status === "SUBMITTED"
                  ? "#FFEBD0"
                  : expenseClaimData.status === "DRAFT"
                  ? "#ebeefa" // Background color for pending
                  : expenseClaimData.status === "APPROVED"
                  ? "#e1fbed"
                  : "defaultBgColor" // fallback background color
              }
            >
              {expenseClaimData.status}
            </Tag>

            <Menu>
              <MenuButton
                as={Button}
                padding={"0"}
                backgroundColor={"transparent"}
                marginLeft={"8px"}
                _hover={{ backgroundColor: "transparent" }}
                _active={{ backgroundColor: "transparent" }}
                _focus={{ backgroundColor: "transparent" }}
              >
                <Icon
                  as={FiMoreVertical}
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="50%"
                  borderStyle={"solid"}
                  borderWidth={"1px"}
                  borderColor={"borders.tableBorder"}
                  bg={"#fff"}
                  w="25px"
                  h="25px"
                  px="5px"
                  py="5px"
                  fontSize="22px"
                  color="black"
                  _hover={{
                    color: "#3454d1",
                  }}
                />
              </MenuButton>
              <MenuList style={{ minWidth: "140px" }}>
                <MenuItem
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  onClick={() => {
                    form.reset({
                      id: expenseClaimData.id,
                      createdAt: expenseClaimData.createdAt,
                      updatedAt: expenseClaimData.updatedAt,
                      claimType: expenseClaimData.claimType,
                      // expenseClaimType: expenseClaimData.expenseClaimType,
                      amount: expenseClaimData.amount,
                      description: expenseClaimData.description,
                      bankAccountType: expenseClaimData.bankAccountType,
                      expenseClaimType: expenseClaimData.expenseClaimType,
                      spentAt: expenseClaimData.spentAt,
                      date: expenseClaimData.date,
                      chartAccountId: expenseClaimData.chartAccountId,
                      region: expenseClaimData.region,
                      assignTo: expenseClaimData.assignTo,
                      label: expenseClaimData.label,
                      attachments: expenseClaimData.attachments,
                      empId: expenseClaimData.empId,
                      taxType: expenseClaimData.taxType,
                      taxRateId: expenseClaimData.taxRateId,
                      taxableAmount: expenseClaimData.taxableAmount,
                      milageToClaim: expenseClaimData.milageToClaim,
                      milageRate: expenseClaimData.milageRate,
                      totalAmount: expenseClaimData.totalAmount,
                      status: expenseClaimData.status,
                      payItemReimbursementId:
                        expenseClaimData.payItemReimbursementId,
                    });

                    expenseClaimData.claimType === "EXPENSE_CLAIM"
                      ? expenseClaim.onOpen()
                      : mileageClaim.onOpen();
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  onClick={(e) => {
                    setDeleteId(expenseClaimData.id);

                    deleteModal.onOpen();
                  }}
                >
                  Delete
                </MenuItem>
                {decodedToken.accessLevel !== "EMPLOYEE" &&
                  (expenseClaimData.status === "APPROVED" ||
                    expenseClaimData.status === "SUBMITTED") && (
                    <MenuItem
                      fontSize={"0.8125rem"}
                      fontWeight={"600"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      onClick={() =>
                        handleFormSubmit(
                          ExpenseClaimApi.ExpenseClaimStatus.DRAFT
                        )
                      }
                    >
                      Draft
                    </MenuItem>
                  )}
                {decodedToken.accessLevel !== "EMPLOYEE" &&
                  (expenseClaimData.status === "DRAFT" ||
                    expenseClaimData.status === "SUBMITTED") && (
                    <MenuItem
                      fontSize={"0.8125rem"}
                      fontWeight={"600"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      onClick={() =>
                        handleFormSubmit(
                          ExpenseClaimApi.ExpenseClaimStatus.APPROVED
                        )
                      }
                    >
                      Approve
                    </MenuItem>
                  )}
                {decodedToken.accessLevel !== "EMPLOYEE" &&
                  (expenseClaimData.status === "DRAFT" ||
                    expenseClaimData.status === "APPROVED") && (
                    <MenuItem
                      fontSize={"0.8125rem"}
                      fontWeight={"600"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      onClick={() =>
                        handleFormSubmit(
                          ExpenseClaimApi.ExpenseClaimStatus.SUBMITTED
                        )
                      }
                    >
                      Submit
                    </MenuItem>
                  )}
              </MenuList>
            </Menu>
          </HStack>
        </Stack>
      </HStack>

      <FormProvider {...form}>
        <AddExpenseClaim
          setLastUpdated={setLastUpdated}
          isOpen={expenseClaim.isOpen}
          onClose={expenseClaim.onClose}
        />

        <AddMileageClaim
          setLastUpdated={setLastUpdated}
          isOpen={mileageClaim.isOpen}
          onClose={mileageClaim.onClose}
        />
        <DeleteClaim
          isOpen={deleteModal.isOpen}
          onClose={deleteModal.onClose}
          id={deleteId}
          setLastUpdated={setLastUpdated}
        />
      </FormProvider>
    </HStack>
  );
}
