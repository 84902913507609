import {
  Button,
  Container,
  Divider,
  Heading,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { Country } from "country-state-city";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountWithoutBankFeed } from "./Modal/AccountWithoutBankFeed";

export function AddBankAccount() {
  let countryData = Country.getAllCountries();
  const navigate = useNavigate();
  const accountWithoutBankFeed = useDisclosure();
  const [bankList, setBankList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchBanks() {
      try {
        // Real API endpoint for fetching banks in Pakistan
        // Replace this URL with an actual working API URL that returns banks for Pakistan
        const response = await axios.get(
          "https://api.open-banking-api.com/v1/banks?country=PK"
        );

        // Assuming the API returns an array of bank objects
        setBankList(response.data.banks); // Adjust the response data according to API's structure
        setLoading(false);
      } catch (error) {
        console.error("Error fetching bank data:", error);
        setLoading(false);
      }
    }

    fetchBanks();
  }, []);

  return (
    <Stack
      h="calc(100vh - 70px)"
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
    >
      <HStack
        height={"60px"}
        borderBottom={"1px solid #a6a9b0"}
        padding={"15px"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
      >
        <Heading
          display={"flex"}
          flex={1}
          paddingRight="12px"
          _light={{
            color: "customColor.black7",
          }}
          fontSize={"0.9375rem"}
          lineHeight={"1.5rem"}
          fontWeight={"700"}
        >
          Add Bank Accounts
        </Heading>
        <Button
          h={["auto", "40px"]}
          fontSize={"13px"}
          bgColor="#3454D1"
          borderRadius={"3px"}
          borderWidth={"1px"}
          borderColor={"#3454d1!important"}
          variant={"solid"}
          padding={"12px 16px"}
          fontWeight={700}
          _hover={{
            bgColor: "#3454D1",
          }}
          onClick={() => accountWithoutBankFeed.onOpen()}
        >
          Add without bank feed
        </Button>
      </HStack>
      <Container
        p={"0px"}
        maxW="778px"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        marginTop={"20px"}
        border="1px solid #a6a9b0"
        borderRadius="md"
      >
        <Stack padding="20px" textAlign={"center"}>
          <Heading
            fontSize=".9975rem"
            fontWeight="bold"
            as="h2"
            size="md"
            _light={{
              color: "customColor.black7",
            }}
          >
            Find account
          </Heading>
          <Text
            fontSize={"15px"}
            _dark={{
              color: "customColor.dark.150",
            }}
            _light={{
              color: "customColor.black7",
            }}
          >
            Search for a bank, credit card or payment provider to connect to
            HRIS 360
          </Text>
        </Stack>
        <Divider orientation="horizontal" />
        <Container
          maxW="778px"
          pb="8px"
          marginX="auto"
          color={"rgb(0,10,30,.75)"}
          px="0px"
        >
          <Stack
            padding="16px"
            textAlign={"left"}
            borderBottom={"1px solid #a6a9b0"}
            _hover={{
              bg: "#f2f3f4",
            }}
          >
            <Heading
              fontSize=".9975rem"
              fontWeight="bold"
              as="h2"
              size="md"
              _light={{
                color: "customColor.black7",
              }}
              marginLeft={"4px"}
            >
              Popular banks in Pakistan
            </Heading>
          </Stack>
          {loading ? (
            <Text>Loading bank data...</Text>
          ) : (
            bankList.map((item, i) => {
              return (
                <Stack
                  key={i}
                  padding="16px"
                  textAlign={"left"}
                  borderBottom={"1px solid #a6a9b0"}
                  _hover={{
                    bg: "#f2f3f4",
                  }}
                >
                  <Text
                    fontSize={"15px"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    _light={{
                      color: "customColor.black7",
                    }}
                    marginLeft={"4px"}
                    onClick={() => navigate("/app/payroll/AddAccount")}
                  >
                    {item}
                  </Text>
                </Stack>
              );
            })
          )}
        </Container>
      </Container>
      <AccountWithoutBankFeed
        isOpen={accountWithoutBankFeed.isOpen}
        onClose={accountWithoutBankFeed.onClose}
      />
    </Stack>
  );
}
