import {
  Box,
  Editable,
  EditablePreview,
  EditableTextarea,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import moment from "moment";
import { Fragment, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { EmployeesReport } from "../../../Api/EmployeeReport";
import { flattenArrayObjects } from "../../TimeSheet/utils";
import { Column } from "../Reports/Columns";

interface ReportingTableProps {
  DataWithKey?: {
    [key: string]: any[];
  };
  totalsColumns?: string[];
  totalReqired?: boolean;
  Data?:
    | {
        calendar: string;
        earningRate: string;
        employee: string;

        employeeGroup: string;
        fri: string;
        mon: string;
        sat: string;
        sun: string;
        thu: string;
        timesheetCategory: string;
        total: string;
        tue: string;
        wed: string;
        weekEnding: string;
      }[]
    | {
        calendar: string;
        earningRate: string;
        employee: string;

        employeeGroup: string;
        timesheetCategory: string;
        unit: string;
      }[]
    | {
        amount: string;
        contributionType: string;
        dueDate: string;
        employee: string;

        paymentDate: string;
        reference: string;
        sentToFund: string;
        superFundName: string;
      }[]
    | {
        totalearnings: number;
        totaldeductions: number;
        totaltax: number;
        totalreimbursements: number;
        totalretirements: number;
        netpay: number;
        firstname: string;
        lastname: string;
      }[]
    | {
        employee: string;
        startDate: string;
        terminationDate: string;
        employeeBasis: string;
        employeeGroup: string;
        classification: string;
        payCalendar: string;
        standardUnit: string;
        unitRate: string;
        annualSalary: string;
      }[]
    | {
        amount: string;
        employee: string;
        employeeGroup: string;
        leaveType: string;
        unit: string;
      }[]
    | {
        description: string;
        employee: string;

        employeeGroup: string;

        leaveStatus: string;
        leaveType: string;
        unitRate: string;
        unit: string;
      }[]
    | {
        id: string;
        startDate: string;
        endDate: string;
        quantity: number;
        status: string;
        employee: {
          firstName: string;
          lastName: string;
        };
        leaveEntitlement: {
          id: string;
          payrollPayItemLeave: {
            name: string;
          };
        };
      }[]
    | {
        email: string;
        firstName: string;
        lastName: string;
        mobileNumber: string;
        dateOfBirth: string;
        address: string;
        phoneNumber: string;
        province: string;
        city: string;
        postalCode: string;
        age: number;
      }[]
    | {
        amount: string;
        contributionType: string;
        dueDate: string;
        employee: string;
        employeeGroup: string;
        employeeNumber: string;
        paymentDate: string;
        superFundName: string;
        USI: string;
      }[];
  groupBy?:
    | EmployeesReport.GroupingEmployeeRemunerationReport["groupBy"]
    | EmployeesReport.GroupingLeaveBalanceReport["groupBy"]
    | EmployeesReport.GroupingTimesheetSummaryReport["groupBy"]
    | EmployeesReport.GroupingTimesheetDetailsReport["groupBy"]
    | EmployeesReport.GroupingSuperannuationAccrualsReport["groupBy"]
    | EmployeesReport.GroupingSuperannuationPaymentsReport["groupBy"]
    | EmployeesReport.GroupingLeaveTransactionsReport["groupBy"]
    | EmployeesReport.GroupingLeaveRequestReport["groupBy"]
    | "EMPLOYEE"
    | "EMPLOYEE_GROUP"
    | undefined;
  name: string;
  selectedColumns: Array<Column>;
  isLoading?: boolean;
  selectedData:
    | EmployeesReport.fetchGroupByEmployeeRemunerationReportData
    | EmployeesReport.fetchGroupByLeaveBalanceReportData
    | EmployeesReport.fetchGroupByLeaveRequestReport
    | EmployeesReport.fetchGroupByLeaveTransactionsReport
    | EmployeesReport.fetchGroupByPayrollEmployeeSummaryReport
    | EmployeesReport.fetchGroupBySuperannuationAccrualsReport
    | EmployeesReport.fetchGroupBySuperannuationPaymentsReport
    | EmployeesReport.fetchGroupByTimesheetDetailsReport
    | EmployeesReport.fetchGroupByTimesheetSummaryReport
    | EmployeesReport.fetchEmployeeRemunerationReportData
    | EmployeesReport.fetchReportData
    | EmployeesReport.fetchLeaveBalanceData
    | EmployeesReport.fetchLeaveRequestReport
    | EmployeesReport.fetchLeaveTransactionsReport
    | EmployeesReport.fetchPayrollEmployeeSummaryReport
    | EmployeesReport.fetchSuperannuationAccrualsReport
    | EmployeesReport.fetchSuperannuationPaymentsReport
    | EmployeesReport.fetchTimesheetDetailsReport
    | EmployeesReport.fetchTimesheetSummaryReport
    | any;
}

export function ReportingTable({
  name,
  groupBy,
  selectedColumns,
  selectedData,
  DataWithKey,
  isLoading,
  Data,
  totalsColumns,
  totalReqired = true,
}: // tabRef,
ReportingTableProps) {
  const { businessesList, currentBusinessId } = useBusinessContext();
  const dataType = useMemo(() => {
    if (Array.isArray(selectedData?.data)) {
      return "NOT_GROUPED";
    } else {
      return "GROUPED";
    }
  }, [selectedData]);
  const selectedColumnsFiltered = useMemo(() => {
    const columns = selectedColumns.filter((sc) => sc.groupingKey !== groupBy);

    return columns;
  }, [selectedColumns, groupBy]);

  const sortedFilteredColumns = useMemo(() => {
    return selectedColumnsFiltered.sort((a, b) => a.position - b.position);
  }, [selectedColumnsFiltered]);
  const sortedColumns = useMemo(() => {
    return selectedColumns.sort((a, b) => a.position - b.position);
  }, [selectedColumns]);
  const currentDate = moment().format("DD MMMM YYYY");
  const form = useFormContext();
  return (
    <Stack paddingBottom={"30px"}>
      <Skeleton height="100%" isLoaded={!isLoading}>
        <Stack
          borderRadius={"3px"}
          boxShadow={"0 0 0 1px rgba(0,10,30,.2)"}
          padding={"24px 32px"}
          _light={{
            bgColor: "customColor.white",
          }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
        >
          <Stack paddingBottom={"25px"}>
            <Editable
              defaultValue={name}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.pearlWhite",
              }}
              fontSize={"24px"}
              fontWeight={"700"}
            >
              <EditablePreview />
              <EditableTextarea h={"50px"} border={"1px solid #000A1E"} />
            </Editable>

            <Text
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.pearlWhite",
              }}
              fontSize={"15px"}
            >
              {businessesList.find((b) => b.id === currentBusinessId)?.name}
            </Text>
            {form.getValues("filters.dateRange") !== undefined ? (
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
                fontSize={"15px"}
              >
                For the period{" "}
                {moment(form.getValues("filters.dateRange.from")).format(
                  "DD MMM YYYY"
                )}{" "}
                to{" "}
                {moment(form.getValues("filters.dateRange.to")).format(
                  "DD MMM YYYY"
                )}
              </Text>
            ) : form.getValues("filters.startDate") !== undefined &&
              form.getValues("filters.endDate") !== undefined ? (
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
                fontSize={"15px"}
              >
                For the period{" "}
                {moment(form.getValues("filters.startDate")).format(
                  "DD MMM YYYY"
                )}{" "}
                to{" "}
                {moment(form.getValues("filters.endDate")).format(
                  "DD MMM YYYY"
                )}
              </Text>
            ) : form.getValues("filters.date.from") !== undefined &&
              form.getValues("filters.date.to") !== undefined ? (
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
                fontSize={"15px"}
              >
                For the period{" "}
                {moment(form.getValues("filters.date.from")).format(
                  "DD MMM YYYY"
                )}{" "}
                to{" "}
                {moment(form.getValues("filters.date.to")).format(
                  "DD MMM YYYY"
                )}
              </Text>
            ) : form.getValues("filters.date") !== undefined ? (
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
                fontSize={"15px"}
              >
                As at{" "}
                {moment(form.getValues("filters.date")).format("DD MMM YYYY")}
              </Text>
            ) : (
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
                fontSize={"15px"}
              >
                As at {moment().format("DD MMM YYYY")}
              </Text>
            )}
          </Stack>

          {/* <Table variant="simple" w="100%" ref={tabRef}> */}

          <Box overflowX="auto" p="0px">
            <Table variant="simple" w="100%" overflowX="auto">
              <Thead h={"36.8px"}>
                <Tr
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                >
                  {(dataType === "GROUPED"
                    ? sortedFilteredColumns
                    : sortedColumns
                  ).map((column, index) => (
                    <Th
                      key={index}
                      padding="8px 26px 8px 15px"
                      borderBottomColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={700}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                      flex={1}
                      width={"100px"}
                    >
                      {column.colLabel}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              {groupBy !== undefined ? (
                <GroupedData
                  data={DataWithKey}
                  // data={
                  //   selectedData as
                  //     | EmployeesReport.fetchGroupByEmployeeRemunerationReportData
                  //     | EmployeesReport.fetchGroupByLeaveBalanceReportData
                  //     | EmployeesReport.fetchGroupByLeaveRequestReport
                  //     | EmployeesReport.fetchGroupByLeaveTransactionsReport
                  //     | EmployeesReport.fetchGroupByPayrollEmployeeSummaryReport
                  //     | EmployeesReport.fetchGroupBySuperannuationAccrualsReport
                  //     | EmployeesReport.fetchGroupBySuperannuationPaymentsReport
                  //     | EmployeesReport.fetchGroupByTimesheetDetailsReport
                  //     | EmployeesReport.fetchGroupByTimesheetSummaryReport
                  //     | EmployeesReport.fetchEmployeeRemunerationReportData
                  // }
                  selectedColumns={selectedColumns}
                  groupBy={
                    groupBy as
                      | EmployeesReport.GroupingEmployeeRemunerationReport["groupBy"]
                      | EmployeesReport.GroupingLeaveBalanceReport["groupBy"]
                      | EmployeesReport.GroupingTimesheetSummaryReport["groupBy"]
                      | EmployeesReport.GroupingTimesheetDetailsReport["groupBy"]
                      | EmployeesReport.GroupingSuperannuationPaymentsReport["groupBy"]
                      | EmployeesReport.GroupingSuperannuationPaymentsReport["groupBy"]
                      | EmployeesReport.GroupingLeaveTransactionsReport["groupBy"]
                      | EmployeesReport.GroupingLeaveRequestReport["groupBy"]
                  }
                  includeInTotals={totalsColumns}
                  totalReqired={totalReqired}
                />
              ) : (
                <UnGroupedData
                  // data={
                  //   selectedData as
                  //     | EmployeesReport.fetchEmployeeRemunerationReportData
                  //     | EmployeesReport.fetchReportData
                  //     | EmployeesReport.fetchLeaveBalanceData
                  //     | EmployeesReport.fetchLeaveRequestReport
                  //     | EmployeesReport.fetchLeaveTransactionsReport
                  //     | EmployeesReport.fetchPayrollEmployeeSummaryReport
                  //     | EmployeesReport.fetchSuperannuationAccrualsReport
                  //     | EmployeesReport.fetchSuperannuationPaymentsReport
                  //     | EmployeesReport.fetchTimesheetDetailsReport
                  //     | EmployeesReport.fetchTimesheetSummaryReport
                  // }
                  data={Data}
                  selectedColumns={sortedColumns}
                  includeInTotals={totalsColumns!}
                />
              )}

              {/* ) : ( */}
            </Table>
          </Box>
        </Stack>
      </Skeleton>
    </Stack>
  );
}
function UnGroupedData({
  data,
  selectedColumns,
  includeInTotals,
}: {
  // data:
  //   | EmployeesReport.fetchEmployeeRemunerationReportData
  //   | EmployeesReport.fetchReportData
  //   | EmployeesReport.fetchLeaveBalanceData
  //   | EmployeesReport.fetchLeaveRequestReport
  //   | EmployeesReport.fetchLeaveTransactionsReport
  //   | EmployeesReport.fetchPayrollEmployeeSummaryReport
  //   | EmployeesReport.fetchSuperannuationAccrualsReport
  //   | EmployeesReport.fetchSuperannuationPaymentsReport
  //   | EmployeesReport.fetchTimesheetDetailsReport
  //   | EmployeesReport.fetchTimesheetSummaryReport;
  data:
    | {
        calendar: string;
        earningRate: string;
        employee: string;

        employeeGroup: string;
        fri: string;
        mon: string;
        sat: string;
        sun: string;
        thu: string;
        timesheetCategory: string;
        total: string;
        tue: string;
        wed: string;
        weekEnding: string;
      }[]
    | {
        totalearnings: number;
        totaldeductions: number;
        totaltax: number;
        totalreimbursements: number;
        totalretirements: number;
        netpay: number;
        firstname: string;
        lastname: string;
      }[]
    | {
        employee: string;
        startDate: string;
        terminationDate: string;
        employeeBasis: string;
        employeeGroup: string;
        classification: string;
        payCalendar: string;
        standardUnit: string;
        unitRate: string;
        annualSalary: string;
      }[]
    | {
        id: string;
        startDate: string;
        endDate: string;
        quantity: number;
        status: string;
        employee: {
          firstName: string;
          lastName: string;
        };
        leaveEntitlement: {
          id: string;
          payrollPayItemLeave: {
            name: string;
          };
        };
      }[]
    | {
        amount: string;
        employee: string;
        employeeGroup: string;
        leaveType: string;
        unit: string;
      }[]
    | {
        email: string;
        firstName: string;
        lastName: string;
        mobileNumber: string;
        dateOfBirth: string;
        address: string;
        phoneNumber: string;
        province: string;
        city: string;
        postalCode: string;
        age: number;
      }[]
    | {
        description: string;
        employee: string;

        employeeGroup: string;

        leaveStatus: string;
        leaveType: string;
        unitRate: string;
        unit: string;
      }[]
    | {
        amount: string;
        contributionType: string;
        dueDate: string;
        employee: string;
        employeeGroup: string;
        employeeNumber: string;
        paymentDate: string;
        superFundName: string;
        USI: string;
      }[]
    | {
        amount: string;
        contributionType: string;
        dueDate: string;
        employee: string;
        paymentDate: string;
        reference: string;
        sentToFund: string;
        superFundName: string;
      }[]
    | {
        calendar: string;
        earningRate: string;
        employee: string;
        employeeGroup: string;
        timesheetCategory: string;
        unit: string;
      }[]
    | undefined;
  selectedColumns: Array<Column>;
  includeInTotals?: string[];
}) {
  let FlattenData;
  if (data !== undefined) {
    FlattenData = flattenArrayObjects(data);
  }
  return (
    <Tbody>
      {/* {data.data.map((item: any, i: any) => { */}
      {FlattenData?.map((item: any, i: any) => {
        const isTotalLine = item?.isTotalColumn;
        return (
          <Tr
            key={i}
            _hover={{
              bgColor: "#f2f3f8",
              cursor: "pointer",
            }}
            fontWeight={isTotalLine ? "bold" : "normal"}
          >
            {selectedColumns.map((column, j) => {
              // If it's an 'amount-only' row and the current column is the first column, display "Total"
              let content;

              if (isTotalLine) {
                // Handle total rows
                if (j === 0) {
                  // First column shows "Total"
                  content = <span style={{ fontWeight: "bold" }}>Total</span>;
                } else if (
                  includeInTotals?.includes(column.dataKey as string) &&
                  (column.dataKey as any) in item
                ) {
                  // Show the total value for specified fields
                  content = (
                    <span style={{ fontWeight: "bold" }}>
                      {item[column.dataKey as string]}
                    </span>
                  );
                } else {
                  // Leave other cells empty
                  content = "";
                }
              } else {
                // Default behavior for regular rows
                content = item[column.dataKey as keyof typeof item] ?? "N/A";
              }

              return (
                <Td
                  key={j}
                  padding="15px"
                  fontSize="13.44px"
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  lineHeight={1.5}
                  borderTopWidth="1px"
                  borderBottomWidth="0px"
                  borderTopColor={"borders.tableBorder"}
                >
                  {content}
                </Td>
              );
            })}
          </Tr>
        );
      })}
    </Tbody>
  );
}
type GroupedDataProps = {
  data?: {
    [key: string]: any[] | { [key: string]: any[] };
  };
  selectedColumns: Array<Column>;
  groupBy?: string;
  includeInTotals?: string[];
  totalReqired?: boolean;
};
function renderDataRows(
  item: any,
  selectedColumns: Column[],
  groupBy?: string,
  includeInTotals?: string[]
) {
  let flattenData: any = flattenArrayObjects(item);
  const isTotalLine = flattenData?.isTotalColumn;
  return (
    <Tr
      key={item.id} // Ensure to have a unique key for each row
      _hover={{
        bgColor: "#f2f3f8",
        cursor: "pointer",
      }}
    >
      {selectedColumns
        .filter((column) => column.groupingKey !== groupBy)
        .map((column, index) => {
          let content;
          if (isTotalLine) {
            // Handle total rows
            if (index === 0) {
              // First column shows "Total"
              content = <span style={{ fontWeight: "bold" }}>Total</span>;
            } else if (
              includeInTotals?.includes(column.dataKey as string) &&
              (column.dataKey as any) in item
            ) {
              // Show the total value for specified fields
              content = (
                <span style={{ fontWeight: "bold" }}>
                  {item[column.dataKey as string]}
                </span>
              );
            } else {
              // Leave other cells empty
              content = "";
            }
          } else {
            // Default behavior for regular rows
            content = item[column.dataKey as keyof typeof item] ?? "N/A";
          }
          return (
            <Td
              key={index}
              padding="15px"
              fontWeight={500}
              fontSize="13.44px"
              color={"customColor.black7"}
              _dark={{
                color: "customColor.dark.150",
              }}
              lineHeight={1.5}
              borderTopWidth="1px"
              borderBottomWidth="0px"
              borderTopColor={"borders.tableBorder"}
            >
              {content}
            </Td>
          );
        })}
    </Tr>
  );
}
function renderGroupedData(
  data: any,
  selectedColumns: Column[],
  groupBy?: string,
  includeInTotals?: string[]
) {
  if (typeof data === "object" && !Array.isArray(data)) {
    return Object.entries(data).map(([key, value]) => {
      const headerLength = selectedColumns.filter(
        (column) => column.groupingKey !== groupBy
      ).length;
      return (
        <Fragment key={key}>
          <Tr
            borderTopWidth="1px"
            borderBottomWidth="1px"
            borderTopColor={"borders.tableBorder"}
            borderBottomColor={"borders.tableBorder"}
          >
            <Td
              colSpan={headerLength}
              w={"100%"}
              padding="15px"
              fontWeight={500}
              fontSize="13.44px"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.dark.150",
              }}
              lineHeight={1.5}
              borderTopWidth="1px"
              borderBottomWidth="0px"
              borderTopColor={"borders.tableBorder"}
            >
              <Text fontWeight="bold">{key}</Text>
            </Td>
          </Tr>
          {Array.isArray(value)
            ? value.map((item) =>
                renderDataRows(item, selectedColumns, groupBy, includeInTotals)
              )
            : renderGroupedData(
                value,
                selectedColumns,
                groupBy,
                includeInTotals
              )}
          {/* {totalRequired && totalsColumns && Array.isArray(value) && (
            <Tr
              borderTopWidth="1px"
              borderBottomWidth="1px"
              borderTopColor={"borders.tableBorder"}
              borderBottomColor={"borders.tableBorder"}
            >
              {selectedColumns.map((column, index) => {
                if (index === 0) {
                  return (
                    <Td
                      key={index}
                      colSpan={headerLength - totalsColumns.length}
                      // padding="7px 12px"
                      // color="#373857"
                      // fontSize="13px"
                      // fontWeight="bold"
                      padding="15px"
                      fontWeight={700}
                      fontSize="13.44px"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      Total
                    </Td>
                  );
                } else if (totalsColumns.includes(column.dataKey as string)) {
                  return (
                    <Td
                      key={index}
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                      colSpan={
                        totalsColumns.includes(column.dataKey as string)
                          ? 1
                          : undefined
                      }
                    >
                      {totals[column.dataKey as string] ?? ""}
                    </Td>
                  );
                } else {
                  return null;
                }
              })}
            </Tr>
            // <Tr>
            //   {selectedColumns.map((column, index) => (
            //     <Td
            //       key={index}
            //       padding="7px 12px"
            //       color="#373857"
            //       fontSize="13px"
            //       fontWeight="bold"
            //     >
            //       {index === 0
            //         ? "Total"
            //         : totalsColumns.includes(column.dataKey as string)
            //         ? totals[column.dataKey as string]
            //         : ""}
            //     </Td>
            //   ))}
            // </Tr>
            // <Tr>
            //   <Td
            //     colSpan={headerLength - totalsColumns.length + 1}
            //     padding="7px 12px"
            //     fontWeight="bold"
            //     color="#373857"
            //     fontSize="13px"
            //   >
            //     Total
            //   </Td>
            //   {selectedColumns
            //     .filter((column) =>
            //       totalsColumns?.includes(column.dataKey as string)
            //     )
            //     .map((column, index) => (
            //       <Td
            //         key={index}
            //         padding="7px 12px"
            //         color="#373857"
            //         fontSize="13px"
            //         fontWeight="bold"
            //       >
            //         {totals[column.dataKey as string] ?? ""}
            //       </Td>
            //     ))}
            // </Tr>
          )} */}
        </Fragment>
      );
    });
  } else {
    return (
      <Tr>
        <Td
          _light={{
            color: "customColor.black7",
          }}
        >
          Data structure is incorrect.
        </Td>
      </Tr>
    );
  }
}

function GroupedData({
  data,
  selectedColumns,
  groupBy,
  includeInTotals,
  totalReqired,
}: GroupedDataProps) {
  return (
    <Tbody>
      {data ? (
        renderGroupedData(data, selectedColumns, groupBy, includeInTotals)
      ) : (
        <Tr>
          <Td
            _light={{
              color: "customColor.black7",
            }}
          >
            Data is not available.
          </Td>
        </Tr>
      )}
    </Tbody>
  );
}
