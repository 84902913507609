import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { Fragment } from "react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { FiMoreVertical } from "react-icons/fi";
import { IoMdArchive } from "react-icons/io";
import { MdHistory, MdModeEdit, MdRestore } from "react-icons/md";
import { RiBookReadFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useBusinessContext } from "../../../../../../Hooks/BusinessContext";
import { Inspection } from "../../../../../modules/Audit";
import Content from "../../../../template/pages/Content";
import SubHeading from "../../../../template/pages/Sub-Heading";
import InspectionActionDrawer from "./ActionDrawer";

interface inspectionprops {
  isOpen: boolean;
  onClose: () => void;
  inspection: Inspection.InpsectionMetaPayload | undefined;
}
export default function InspectionDrawer({
  isOpen,
  onClose,
  inspection,
}: inspectionprops) {
  const { allUsers } = useBusinessContext();
  const actiondrawer = useDisclosure();
  const navigate = useNavigate();
  const { setLastUpdatedLocations } = useBusinessContext();
  const menuitems: Array<{ label: string; icon: any }> = [
    {
      label: "Edit inspection",
      icon: MdModeEdit,
    },
    {
      label: "View report",
      icon: RiBookReadFill,
    },
    {
      label: "View history",
      icon: MdHistory,
    },
    {
      label: "Archive",
      icon: IoMdArchive,
    },
  ];
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size={{ xs: "full", md: "md" }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader p={0} backgroundColor="white">
          <Stack spacing="24px" px="24px" pt="16px" pb="24px" bgColor="white">
            <HStack>
              <IconButton
                aria-label="close"
                icon={<AiOutlineClose size={24} color="#545F70" />}
                backgroundColor="transparent"
                _hover={{ backgroundColor: "transparent" }}
                _active={{
                  backgroundColor: "transparent",
                }}
                onClick={onClose}
              />
              <Text
                align="center"
                fontSize="16px"
                fontWeight={500}
                color="textcolors.primary"
                flex={1}
              >
                Inspection
              </Text>
              <Menu>
                <MenuButton
                  as={IconButton}
                  display="none"
                  pl="12px"
                  aria-label="Options"
                  icon={<FiMoreVertical size={24} color="#545f70" />}
                  variant="outline"
                  borderRadius={"50%"}
                  border={"none"}
                  _hover={{ backgroundColor: "transparent" }}
                  _active={{
                    backgroundColor: "transparent",
                  }}
                />
                <MenuList minW="160px">
                  {menuitems.map((menu, i) => {
                    return (
                      <Fragment key={i}>
                        <MenuItem
                          px="14px"
                          py="9.6px"
                          color="textcolors.primary"
                        >
                          <HStack>
                            <Icon as={menu.icon} boxSize={18} color="#1f2533" />
                            <Text fontSize="14px" fontWeight={400}>
                              {menu.label}
                            </Text>
                          </HStack>
                        </MenuItem>
                      </Fragment>
                    );
                  })}
                </MenuList>
              </Menu>
            </HStack>
            <SubHeading lineHeight="2rem" fontSize="24px">
              <HStack
                divider={
                  <SubHeading
                    lineHeight="2rem"
                    fontSize="24px"
                    fontWeight={500}
                    color="textcolors.primary"
                    mx="1"
                  >
                    /
                  </SubHeading>
                }
              >
                <SubHeading lineHeight="2rem" fontSize="24px">
                  {moment(inspection?.inspectedOn).format("DD MMM YYYY")}
                </SubHeading>

                {inspection?.templateMeta?.site && (
                  <SubHeading lineHeight="2rem" fontSize="24px">
                    {inspection?.templateMeta?.site}
                  </SubHeading>
                )}
                {inspection?.templateMeta?.person && (
                  <SubHeading lineHeight="2rem" fontSize="24px">
                    {inspection?.templateMeta?.person}
                  </SubHeading>
                )}
              </HStack>
            </SubHeading>
            <Stack direction={{ xs: "column", md: "row" }} spacing="16px">
              <Button
                maxW="200px"
                px="20px"
                py="12px"
                h="41px"
                borderRadius="8px"
                flex={1}
                onClick={() => {
                  if (inspection?.inspectionCompleted) {
                    navigate(`/app/auditor/report/${inspection.id}`);
                  } else {
                    setLastUpdatedLocations(Date.now());
                    navigate(
                      `/app/auditor/inspection-conduct/CONTINUE_${inspection?.id}`
                    );
                  }
                }}
              >
                {inspection?.inspectionCompleted ? "View report" : "Continue"}
              </Button>
              <HStack spacing="16px">
                {/* <ButtonGroup isAttached variant="outline" w="100%">
                  <Button
                    w={{ xs: '75%', md: '70%' }}
                    h="41px"
                    px="20px"
                    py="12px"
                    borderLeftRadius="8px"
                    leftIcon={<BiDownload size="17px" />}
                    isDisabled={inspection?.inspectionCompleted ? false : true}>
                    PDF
                  </Button> */}
                {/* <Menu>
                    <MenuButton
                      as={IconButton}
                      w={{ xs: '25%', md: '30%' }}
                      h="41px"
                      px="9px"
                      py="12px"
                      aria-label="format"
                      borderRightRadius="8px"
                      icon={<FiChevronDown size="17px" />}></MenuButton>
                    <MenuList>
                      {['PDF', 'Word', 'CSV', 'CSV with media'].map(menu => {
                        return (
                          <MenuItem
                            fontSize="14px"
                            px="20px"
                            py="12px"
                            backgroundColor="green"
                            color="white"
                          // _selected={{
                          //   backgroundColor: 'white',
                          //   color: 'black',
                          // }}
                          >
                            {menu}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Menu> */}
                {/* </ButtonGroup> */}
                {/* <Button
                  w="100%"
                  h="41px"
                  px="20px"
                  py="12px"
                  variant="outline"
                  borderRadius="8px"
                  leftIcon={<MdOutlineAttachment size="17px" />}>
                  Share
                </Button> */}
              </HStack>
            </Stack>
          </Stack>
        </DrawerHeader>
        <DrawerBody p={0} backgroundColor="backgrounds.header">
          <Stack p="24px" spacing="16px">
            <HStack justifyContent="space-between">
              <SubHeading fontSize="21px">Details</SubHeading>
              <Button
                variant="link"
                leftIcon={<AiOutlinePlus />}
                iconSpacing="1px"
                fontWeight={400}
                onClick={actiondrawer.onOpen}
              >
                Create Action
              </Button>
              <InspectionActionDrawer
                isOpen={actiondrawer.isOpen}
                onClose={actiondrawer.onClose}
              />
            </HStack>
            <Stack
              backgroundColor="white"
              border="1px"
              borderColor="borders.accent"
              borderRadius="8px"
              p="16px"
              spacing="16px"
            >
              <Stack>
                <Content fontSize="13px" lineHeight="16px">
                  Template
                </Content>
                <SubHeading fontWeight={400} lineHeight="16px">
                  {inspection?.inspectionTemplate.title}
                </SubHeading>
              </Stack>
              <Stack>
                <Content fontSize="13px" lineHeight="16px">
                  Status
                </Content>
                <SubHeading fontWeight={400} lineHeight="16px">
                  {inspection?.inspectionCompleted ? "Complete" : "Incomplete"}
                </SubHeading>
              </Stack>
              {/* <Stack>
                <Content fontSize="13px" lineHeight="16px">
                  Score
                </Content>
                <SubHeading fontWeight={400} lineHeight="16px">
                  {inspection?.inspectionScore}
                </SubHeading>
              </Stack> */}
              {/* <Stack>
                <Content fontSize="13px" lineHeight="16px">
                  Items completed
                </Content>
                <SubHeading fontWeight={400} lineHeight="16px">
                  1/10
                </SubHeading>
              </Stack> */}
              {/* <Stack>
                <Content fontSize="13px" lineHeight="16px">
                  Location
                </Content>
                <SubHeading fontWeight={400} lineHeight="16px">
                  -
                </SubHeading>
              </Stack> */}
              {/* <Stack>
                <Content fontSize="13px" lineHeight="16px">
                  Owner
                </Content>
                <SubHeading fontWeight={400} lineHeight="16px">
                  { }
                </SubHeading>
              </Stack> */}
              <Stack>
                <Content fontSize="13px" lineHeight="16px">
                  Last edited by
                </Content>
                <SubHeading fontWeight={400} lineHeight="16px">
                  {allUsers?.find(
                    (user) => user?.user?.id === inspection?.inspectedBy
                  )?.user?.firstName +
                    " " +
                    allUsers?.find(
                      (user) => user?.user?.id === inspection?.inspectedBy
                    )?.user?.lastName}
                </SubHeading>
              </Stack>
              <Stack>
                <Content fontSize="13px" lineHeight="16px">
                  Started
                </Content>
                <SubHeading fontWeight={400} lineHeight="16px">
                  {moment(inspection?.createdAt).format("LLL")}
                </SubHeading>
              </Stack>
              <Stack>
                <Content fontSize="13px" lineHeight="16px">
                  Updated
                </Content>
                <HStack>
                  <Icon as={MdRestore} boxSize="21px" color="#1da5d4" />
                  <SubHeading
                    fontWeight={400}
                    lineHeight="16px"
                    color="primary.500"
                  >
                    {moment(inspection?.updatedAt).format("LLL")}
                  </SubHeading>
                </HStack>
              </Stack>
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
