import { ChevronRightIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  HStack,
  IconButton,
  Image,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useSteps,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";

export function ImportForItems() {
  const [file, setFile] = useState<File | null>(null);

  // Explicitly typing the ref to refer to an HTMLInputElement
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type.startsWith("image/")) {
      setFile(droppedFile);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      setFile(selectedFile);
    }
  };

  const handleDelete = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input value with an empty string
    }
  };

  const steps = [{ title: "Upload items" }, { title: "Complete import" }];
  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  });
  return (
    <Stack
      gap={0}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      h={"calc(100vh - 70px)"}
      // h="calc(100vh - 130px)"
      overflowY="auto"
    >
      <Stack
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        border="1px solid #e5e7eb"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        padding={"16px"}
        gap={0}
        lineHeight={1.2}
      >
        <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
          <BreadcrumbItem>
            <BreadcrumbLink
              href="ProductAndServices"
              fontSize={"13px"}
              color={"#3454d1"}
            >
              Products and services
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text
            _light={{ color: "customColor.black7" }}
            fontSize={"17px"}
            fontWeight={"700"}
          >
            Import items
          </Text>
        </Stack>
      </Stack>
      <Container maxWidth={"container.xl"} p={"16px"}>
        <Stack
          gap={0}
          _light={{
            bg: "#fff",
          }}
          marginBottom={"20px"}
          overflow={"hidden"}
          p={"8px"}
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          border="1px solid #e5e7eb"
        >
          <Container maxW={"450px"} p={0}>
            <HStack justifyContent={"space-between"}>
              {steps.map((step, index) => (
                <HStack
                  cursor={
                    (step.title === "Complete import" && activeStep === 1) ||
                    (step.title === "Upload items" && activeStep === 2)
                      ? "not-allowed"
                      : "pointer"
                  }
                  // _disabled={
                  //   step.title === "Complete import" && index === 1
                  //     ? { opacity: 0.4, cursor: "not-allowed" }
                  //     : undefined
                  // }
                  padding={"10px 5px"}
                  key={index}
                  _hover={{
                    bg:
                      (step.title === "Complete import" && activeStep === 1) ||
                      (step.title === "Upload items" && activeStep === 2)
                        ? "transparent"
                        : "#f3f4f5",
                  }}
                  onClick={() => {
                    if (step.title === "Upload items" && index === 1)
                      setActiveStep(index + 1);
                  }}
                >
                  <Button
                    cursor={
                      (step.title === "Complete import" && activeStep === 1) ||
                      (step.title === "Upload items" && activeStep === 2)
                        ? "not-allowed"
                        : "pointer"
                    }
                    borderRadius={"full"}
                    bg={
                      (step.title === "Complete import" && activeStep === 1) ||
                      (step.title === "Upload items" && activeStep === 2)
                        ? "#f3f4f5"
                        : "#3454d1"
                    }
                    color={
                      (step.title === "Complete import" && activeStep === 1) ||
                      (step.title === "Upload items" && activeStep === 2)
                        ? "customColor.black7"
                        : "#fff"
                    }
                    _hover={{
                      bg:
                        (step.title === "Complete import" &&
                          activeStep === 1) ||
                        (step.title === "Upload items" && activeStep === 2)
                          ? "#f3f4f5"
                          : "#2c48b5",
                    }}
                  >
                    {index + 1}
                  </Button>
                  <Text whiteSpace="nowrap">{step.title}</Text>
                </HStack>
              ))}
            </HStack>

            {/* <Stepper size="md" index={activeStep}>
              {steps.map((step, index) => (
                <Tooltip label={step.title} hasArrow>
                  <Step key={index} onClick={() => setActiveStep(index + 1)}>
                    <StepIndicator
                      cursor={"pointer"}
                      _hover={{
                        bg: "#ddd",
                        borderColor: "#ddd",
                      }}
                    >
                      <StepStatus
                        complete={<StepNumber />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box
                      flexShrink="0"
                      cursor={"pointer"}
                      _hover={{
                        bg: "#ddd",
                      }}
                    >
                      <StepTitle>{step.title} </StepTitle>
                    </Box>

                    <StepSeparator />
                  </Step>
                </Tooltip>
              ))}
            </Stepper> */}
          </Container>
          <Stack paddingTop={"11px"}>
            {activeStep === 1 && (
              <Container maxW={"560px"} padding={"0px 12px"}>
                <Text
                  margin={"21px 0px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize={"21px"}
                  fontWeight={"700"}
                >
                  Prepare file to import
                </Text>
                <Text
                  margin={"15px 0px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize={"15px"}
                  fontWeight={"400"}
                >
                  Download the template and add your items.
                </Text>
                <Text
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize={"15px"}
                  fontWeight={"400"}
                >
                  To update items already in Hris360, export your items to CSV
                  and edit them in the spreadsheet. Existing items will be
                  updated when the value in <b>ItemCode</b> matches the code of
                  an item that already exists.
                </Text>
                <Text
                  margin={"15px 0px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize={"15px"}
                  fontWeight={"400"}
                >
                  Up to 6,000 items can be imported each time.
                </Text>
                <Stack direction={"row"}>
                  <Button
                    backgroundColor={"#fff"}
                    borderRadius={"3px"}
                    border={"1px solid #dcdee4"}
                    fontSize={"15px"}
                    h={"40px"}
                    variant={"solid"}
                    textColor={"customColor.black7"}
                    padding={"12px 16px"}
                    fontWeight={600}
                    _hover={{
                      backgroundColor: "#fff",
                    }}
                  >
                    Download template
                  </Button>
                  <Button
                    variant={"ghost"}
                    h={"40px"}
                    color={"#3454d1"}
                    fontSize={"15px"}
                    _hover={{
                      bg: "rgba(0,10,30,.05)",
                    }}
                  >
                    Export items to CSV
                  </Button>
                </Stack>
                <Stack
                  marginBottom={"20px"}
                  marginTop={"20px"}
                  borderRadius={"3px"}
                  boxShadow={"0 0 0 1px rgba(0,10,30,.2)"}
                  paddingBottom={"12px"}
                  paddingLeft={"20px"}
                  paddingRight={"20px"}
                >
                  <Text
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontSize={"17px"}
                    fontWeight={"700"}
                  >
                    Tips for importing
                  </Text>
                  <UnorderedList>
                    <ListItem
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"15px"}
                      fontWeight={"400"}
                    >
                      Don't change or delete the column headings
                    </ListItem>
                    <ListItem
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"15px"}
                      fontWeight={"400"}
                    >
                      Enter the account code only (for example: 200)
                    </ListItem>
                    <ListItem
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"15px"}
                      fontWeight={"400"}
                    >
                      Enter the tax rate exactly as it appears in Hris360 (for
                      example: 15% GST on Income)
                    </ListItem>
                    <ListItem
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"15px"}
                      fontWeight={"400"}
                    >
                      If you're adding tracked inventory items, enter both the
                      <b> InventoryAssetAccount</b> and
                      <b> CostOfGoodsSoldAccount </b>
                      codes.
                    </ListItem>
                  </UnorderedList>
                  <Stack
                    alignItems={"center"}
                    direction={"row"}
                    margin={"8px 0px 16px"}
                  >
                    <Link fontSize={"15px"} color={"#3454d1"}>
                      Read our guide on importing items.
                    </Link>
                    <BsBoxArrowUpRight color={"#3454d1"} />
                  </Stack>
                </Stack>
                <Text
                  margin={"21px 0px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize={"21px"}
                  fontWeight={"700"}
                >
                  Upload items
                </Text>
                <Text
                  fontSize={"13px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  paddingBottom={"4px"}
                >
                  <b>File to upload</b> (required)
                </Text>
                <Stack
                  border={"1px dashed #a6a9b0"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  padding={"20px"}
                  textAlign={"center"}
                  borderRadius={"3px"}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  position="relative"
                >
                  {file ? (
                    <>
                      <Image
                        src={URL.createObjectURL(file)}
                        alt="Dropped file"
                        maxWidth="100%"
                        maxHeight="300px"
                        borderRadius="3px"
                      />
                      <IconButton
                        icon={<CloseIcon />}
                        size="sm"
                        colorScheme="red"
                        position="absolute"
                        top="10px"
                        right="10px"
                        onClick={handleDelete}
                        aria-label="Delete Image"
                      />
                    </>
                  ) : (
                    <>
                      <Text
                        fontSize={"15px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        marginBottom={"12px"}
                      >
                        Drag and drop file or select manually
                      </Text>
                      <Button
                        backgroundColor={"transparent"}
                        borderRadius={"3px"}
                        border={"1px solid #dcdee4"}
                        fontSize={"13px"}
                        variant={"solid"}
                        textColor={"customColor.black7"}
                        padding={"12px 16px"}
                        fontWeight={600}
                        _hover={{
                          backgroundColor: "#fff",
                          color: "#3454D1",
                        }}
                        onClick={() => {
                          if (fileInputRef.current) {
                            fileInputRef.current.click();
                          }
                        }}
                      >
                        Select File
                      </Button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileSelect}
                      />
                    </>
                  )}
                </Stack>
                <Text
                  fontSize={"11px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  marginBottom={"12px"}
                  paddingTop={"8px"}
                >
                  2 MB max size. File should end in .csv or .txt.
                </Text>
                <Stack
                  paddingTop={"20px"}
                  paddingBottom={"10px"}
                  alignItems={"flex-end"}
                >
                  <Button
                    colorScheme="green"
                    borderRadius={"3px"}
                    border={"1px solid green"}
                    fontSize={"13px"}
                    h={"40px"}
                    marginLeft={"12px"}
                    variant={"solid"}
                    textColor={"customColor.white"}
                    padding={"12px 16px"}
                    marginRight={"5px"}
                    fontWeight={600}
                    onClick={() => setActiveStep(activeStep + 1)}
                  >
                    Next
                  </Button>
                </Stack>
              </Container>
            )}
            {activeStep === 2 && (
              <Container maxW={"560px"} padding={"0px 12px"}>
                <Text
                  margin={"21px 0px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize={"21px"}
                  fontWeight={"700"}
                >
                  Import summary
                </Text>
                <Text
                  margin={"15px 0px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize={"15px"}
                  fontWeight={"700"}
                >
                  15 of 15 items will be imported:
                </Text>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  paddingLeft={"8px"}
                >
                  <FaCheck color="green" fontSize={"13px"} />
                  <Text
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontSize={"15px"}
                    fontWeight={"400"}
                  >
                    15 items will be added or updated
                  </Text>
                </Stack>

                <Stack
                  paddingTop={"20px"}
                  direction={"row"}
                  paddingBottom={"10px"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Button
                    backgroundColor={"#fff"}
                    borderRadius={"3px"}
                    color={"#ea4d4d"}
                    border={"1px solid #dcdee4"}
                    fontSize={"15px"}
                    h={"40px"}
                    variant={"solid"}
                    padding={"12px 16px"}
                    fontWeight={700}
                    _hover={{
                      backgroundColor: "#fff",
                    }}
                    onClick={() => setActiveStep(activeStep - 1)}
                  >
                    Back
                  </Button>
                  <Button
                    fontSize={"15px"}
                    bgColor="#3454D1"
                    h={"40px"}
                    borderRadius={"3px"}
                    borderWidth={"1px"}
                    borderColor={"#3454d1!important"}
                    variant={"solid"}
                    padding={"12px 16px"}
                    fontWeight={700}
                    _hover={{
                      bgColor: "#3454D1",
                    }}
                  >
                    Complete Import
                  </Button>
                </Stack>
              </Container>
            )}
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
}
