import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { MdOutlineReceiptLong } from "react-icons/md";
import { useDebouncedCallback } from "use-debounce";
import { ReactSelect } from "../../../Common/ReactSelect";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { ExpenseClaimApi } from "../../Api/ExpenseClaimApi";
import { ExpenseTag } from "../EmployeeDetails/Tabs/ExpenseClaim/ExpenseTag";
import { AddExpenseClaim } from "../EmployeeDetails/Tabs/ExpenseClaim/Modal/AddExpenseClaim";
import { AddMileageClaim } from "../EmployeeDetails/Tabs/ExpenseClaim/Modal/AddMileageClaim";
import { PaginationControl } from "../PayrollEmployee/PaginationControl/PaginationControl";
import { ClaimFilters } from "./ClaimFilters";

export function EmployeeExpenseClaim() {
  const [allEmployees, setAllEmployees] = useState<string[]>([]); // Updated to an array of strings

  const [claimData, setClaimData] = useState<
    Array<ExpenseClaimApi.FetchExpenseClaim>
  >([]);
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(25);
  const [count, setCount] = useState<number>(0);
  const pageCount = Math.ceil(count / limit);
  const [searchText, setSearchText] = useState("");
  const [personalClaim, setPersonalClaim] = useState<boolean>(false);
  const [companyClaim, setCompanyClaim] = useState<boolean>(false);
  const [mileageClaimCheck, setMileageClaimCheck] = useState<boolean>(false);
  useEffect(() => {
    ExpenseClaimApi.GetAllExpenseClaim(
      {
        expenseClaimPersonalMoney: personalClaim,
        expenseClaimCompanyMoney: companyClaim,
        milageClaim: mileageClaimCheck,
        status: "DRAFT",
        page: pageNumber,
        limit: limit,
        search: searchText,
      },
      (data) => {
        setClaimData(data.data);
        setCount(data?.count);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      }
    );
  }, [
    lastUpdated,
    pageNumber,
    limit,
    searchText,
    personalClaim,
    companyClaim,
    mileageClaimCheck,
  ]);
  const debounced = useDebouncedCallback((value) => {
    setSearchText(value);
    setPageNumber(1);
  }, 1000);
  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };
  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const renderPageNumbers = () => {
    const pageButtons = [];

    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            aria-label={`Page ${i}`}
            border={"1px solid #e8eaf2"}
            borderRadius={"full"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }
    } else {
      pageButtons.push(
        <IconButton
          key={1}
          aria-label={`Page 1`}
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          backgroundColor={pageNumber === 1 ? "#3454D1" : "#fff"}
          color={pageNumber === 1 ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === 1 ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>1</Text>}
          onClick={() => handlePageChange(1)}
        />
      );

      if (pageNumber > 3) {
        pageButtons.push(<Text key="left-dots">...</Text>);
      }

      const startPage = Math.max(2, pageNumber - 1);
      const endPage = Math.min(pageCount - 1, pageNumber + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            border={"1px solid #e8eaf2"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            aria-label={`Page ${i}`}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }

      if (pageNumber < pageCount - 2) {
        pageButtons.push(<Text key="right-dots">...</Text>);
      }

      pageButtons.push(
        <IconButton
          key={pageCount}
          border={"1px solid #e8eaf2"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          borderRadius={"full"}
          aria-label={`Page ${pageCount}`}
          backgroundColor={pageNumber === pageCount ? "#3454D1" : "#fff"}
          color={pageNumber === pageCount ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === pageCount ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>{pageCount}</Text>}
          onClick={() => handlePageChange(pageCount)}
        />
      );
    }

    return pageButtons;
  };
  const { employeesList } = useBusinessContext();
  const toast = useToast();
  const form = useForm<ExpenseClaimApi.AddExpenseClaim>({
    defaultValues: {
      claimType: undefined,

      amount: undefined,
      description: undefined,
      // payType: undefined,
      status: undefined,
      bankAccountType: undefined,
      spentAt: undefined,
      date: undefined,
      chartAccountId: undefined,
      region: undefined,
      assignTo: undefined,
      label: undefined,
      attachments: [],
      // empIds: allEmployees,
      taxType: undefined,
      taxRateId: undefined,
      taxableAmount: undefined,
      milageToClaim: undefined,
      milageRate: undefined,
      totalAmount: undefined,
    },
  });

  const expenseClaim = useDisclosure();
  const mileageClaim = useDisclosure();
  return (
    <Stack
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      paddingBottom={"20px"}
      h={{ xs: "calc(100vh - 150px)", md: "calc(100vh - 140px)" }}
      overflowY="auto"
    >
      <Container
        maxW={"container.xl"}
        padding={0}
        marginTop="16px"
        paddingRight={"20px"}
        paddingLeft={"20px"}
      >
        <Text
          _light={{
            color: "customColor.black7",
          }}
          fontSize="18px"
          fontWeight={"600"}
          marginBottom={"8px"}
        >
          Select Employees
        </Text>

        <ReactSelect
          withValidation
          isMulti
          closeMenuOnSelect={false}
          ErrorMessage="Please Select Employee"
          placeholder="Select Employee"
          options={
            allEmployees.length === employeesList?.length
              ? [{ id: "all", firstName: "All", lastName: "Employees" }] // Show only 'All Employees' when selected
              : [
                  { id: "all", firstName: "All", lastName: "Employees" }, // Include 'All Employees' option
                  ...(employeesList ?? []), // Spread other employees if not undefined
                ]
          }
          value={
            allEmployees.length === employeesList?.length
              ? [{ id: "all", firstName: "All", lastName: "Employees" }] // If all employees are selected, show its label
              : employeesList?.filter((emp: any) =>
                  allEmployees.includes(emp.id)
                ) ?? []
          }
          onChange={(selected: any) => {
            // Check if "All Employees" was selected
            if (selected.some((emp: any) => emp.id === "all")) {
              setAllEmployees(employeesList?.map((emp: any) => emp.id) || []); // Select all employees
            } else {
              setAllEmployees(selected.map((emp: any) => emp.id)); // Otherwise, store individual employee IDs
            }
          }}
          getOptionLabel={(option: any) => {
            if (option.id === "all") return "All Employees"; // Label for the 'All Employees' option
            return `${option?.firstName} ${option?.lastName}`;
          }}
          getOptionValue={(option: any) => option.id}
        />

        <Box border={"1px solid #e5e7eb"} bg={"#fff"} marginTop={"15px"}>
          <Box justifyContent="center" alignItems="center" p={0}>
            <HStack borderBottom={"1px solid #ddd"} paddingRight={"10px"}>
              <Heading
                textAlign="left"
                fontSize=".9975rem"
                _light={{
                  color: "customColor.black7",
                }}
                fontWeight="bold"
                padding="20px"
                as="h2"
                size="md"
                flex={1}
              >
                Expense Claim
              </Heading>
              <Menu>
                <MenuButton
                  color={"primary.950"}
                  bg={allEmployees.length <= 0 ? "#f2f3f4" : "#FFF"}
                  padding={"7.52px 12px"}
                  borderRadius="3px"
                  fontSize={"15px"}
                  border={"1px solid #a6a9b0"}
                  fontWeight={"700"}
                  textAlign={"center"}
                  minH={"35.6px"}
                  _hover={{
                    color: "primary.950",
                    bg: "#f2f3f4",
                  }}
                  disabled={allEmployees.length <= 0 ? true : false}
                  cursor={allEmployees.length <= 0 ? "not-allowed" : "pointer"}
                >
                  New Expense <ChevronDownIcon />
                </MenuButton>
                <MenuList width={"144px"} padding={"10px 0px"}>
                  {[
                    {
                      name: "Expense claim",
                      sideIcon: <MdOutlineReceiptLong />,
                      onClick: () => {
                        form.reset({
                          spentAt: undefined,
                          amount: undefined,
                          claimType: ExpenseClaimApi.ClaimType.EXPENSE_CLAIM,

                          description: undefined,
                          // payType: undefined,
                          // empIds: allEmployees,
                          bankAccountType: undefined,
                          date: undefined,
                          status: undefined,
                          attachments: [],
                          chartAccountId: undefined,
                          region: undefined,
                          assignTo: undefined,
                          label: undefined,
                          totalAmount: undefined,
                          taxRateId: undefined,
                          taxType: undefined,
                        });
                        // setSelectedLeaveId(undefined);
                        expenseClaim.onOpen();
                      },
                    },
                    {
                      name: "Mileage Claim",
                      sideIcon: <BsFillFuelPumpFill />,
                      onClick: () => {
                        form.reset({
                          claimType: ExpenseClaimApi.ClaimType.MILAGE_CLAIM,

                          milageToClaim: undefined,
                          // empIds: allEmployees,
                          // payType: "REIMBURSABLE",
                          milageRate: undefined,
                          attachments: [],
                          description: undefined,
                          date: undefined,
                          status: undefined,
                          chartAccountId: undefined,
                          region: undefined,
                          assignTo: undefined,
                          label: undefined,
                          totalAmount: undefined,
                        });
                        // setSelectedLeaveId(undefined);
                        mileageClaim.onOpen();
                      },
                    },
                  ].map((item) => (
                    <MenuItem
                      padding={"0px 12px"}
                      onClick={() => {
                        // item.onClick();
                      }}
                    >
                      <Button
                        padding={"8px"}
                        leftIcon={item.sideIcon}
                        variant={"ghost"}
                        fontSize={"0.9375rem"}
                        lineHeight={"1.5rem"}
                        fontWeight={"normal"}
                        _light={{
                          color: "black",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        onClick={item.onClick}
                        _hover={{
                          bg: "transparent",
                        }}
                      >
                        {item.name}
                      </Button>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </HStack>
          </Box>
          <Stack padding={"30px 20px"}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent={"space-between"}
              alignItems={"center"}
              margin={"5px 0px 0px 5px"}
            >
              <Box w={"100%"} flex={3}>
                <PaginationControl
                  limit={limit}
                  setLimit={setLimit}
                  setPageNumber={setPageNumber}
                />
              </Box>
              <HStack p="12px 0px 0px" flex={1}>
                <Stack display={"flex"} flex={1}>
                  <Stack
                    gap={0}
                    direction={"row"}
                    alignItems={"center"}
                    flex={1}
                  >
                    <Text
                      fontSize={"13.44px"}
                      _light={{
                        color: "customColor.gray.6b7885",
                      }}
                    >
                      Search:
                    </Text>
                    <InputGroup ml={[0, "7px"]}>
                      <Input
                        type="text"
                        placeholder="Search"
                        borderRadius={"3px"}
                        minW={"100px"}
                        h={["auto", "40px"]}
                        _focusVisible={{
                          border: "1px solid grey",
                        }}
                        onChange={(e) => {
                          debounced(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </Stack>
                </Stack>
                <HStack>
                  <ClaimFilters
                    setPersonalClaim={setPersonalClaim}
                    setCompanyClaim={setCompanyClaim}
                    setMileageClaimCheck={setMileageClaimCheck}
                  />
                </HStack>
              </HStack>
            </Stack>
            <Heading
              textAlign="left"
              fontSize=".9975rem"
              fontWeight="bold"
              padding="20px 0px 20px 0px"
              as="h2"
              size="md"
              display={"flex"}
              flex={1}
              _light={{
                color: "customColor.black7",
              }}
            >
              To Review
            </Heading>
            {claimData && claimData.length > 0 ? (
              claimData.map((expenseClaimData, i) => (
                <ExpenseTag
                  key={i}
                  expenseClaimData={expenseClaimData}
                  setLastUpdated={setLastUpdated}
                />
              ))
            ) : (
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                fontSize={".9975rem"}
              >
                No pending claim requests
              </Text>
            )}
          </Stack>
          {claimData && claimData.length > 0 && (
            <Stack
              p={"0px 20px 25px 20px"}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <HStack spacing="13px" justify={{ xs: "center", md: "end" }}>
                <IconButton
                  aria-label="back"
                  border={"1px solid #e8eaf2"}
                  borderRadius={"full"}
                  backgroundColor="transparent"
                  _hover={{
                    backgroundColor: "#3454D1",
                    color: "white",
                  }}
                  onMouseEnter={() => setIsHovered2(true)}
                  onMouseLeave={() => setIsHovered2(false)}
                  icon={
                    <FaArrowLeft
                      size={15}
                      color={
                        isHovered2
                          ? "#FFFFFF"
                          : pageNumber <= 1 || pageNumber > pageCount
                          ? "#828ea0"
                          : "#283c50"
                      }
                    />
                  }
                  isDisabled={pageNumber <= 1 || pageNumber > pageCount}
                  onClick={() => {
                    if (pageNumber > 1 && pageNumber <= pageCount) {
                      pageNumberInputRef.current?.value &&
                        (pageNumberInputRef.current.value = String(
                          pageNumber - 1
                        ));
                      setPageNumber(pageNumber - 1);
                    }
                  }}
                />
                <HStack spacing="13px">{renderPageNumbers()}</HStack>
                <IconButton
                  aria-label="right"
                  backgroundColor="transparent"
                  border={"1px solid #e8eaf2"}
                  borderRadius={"full"}
                  _hover={{
                    backgroundColor: "#3454D1",
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  icon={
                    <FaArrowRight
                      size={15}
                      color={
                        isHovered
                          ? "#FFFFFF"
                          : pageNumber >= pageCount || pageNumber < 1
                          ? "#828ea0"
                          : "#283c50"
                      }
                      style={{ transition: "color 0.2s" }} // Add smooth transition for color change
                    />
                  }
                  isDisabled={pageNumber >= pageCount || pageNumber < 1}
                  onClick={() => {
                    if (pageNumber < pageCount && pageNumber >= 1) {
                      pageNumberInputRef.current?.value &&
                        (pageNumberInputRef.current.value = String(
                          pageNumber + 1
                        ));
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                />
              </HStack>
            </Stack>
          )}
        </Box>
        <FormProvider {...form}>
          <AddExpenseClaim
            setLastUpdated={setLastUpdated}
            isOpen={expenseClaim.isOpen}
            allEmployees={allEmployees}
            onClose={expenseClaim.onClose}
          />

          <AddMileageClaim
            setLastUpdated={setLastUpdated}
            isOpen={mileageClaim.isOpen}
            allEmployees={allEmployees}
            onClose={mileageClaim.onClose}
          />
        </FormProvider>
      </Container>
    </Stack>
  );
}
