import {
  AbsoluteCenter,
  Avatar,
  Button,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useState } from "react";
import {
  AiFillEye,
  AiOutlineArrowDown,
  AiOutlineQrcode,
  AiOutlineUpload,
  AiTwotoneCalendar,
} from "react-icons/ai";
import { BsBookmarkFill } from "react-icons/bs";
import { CiMemoPad } from "react-icons/ci";
import { FiMoreVertical } from "react-icons/fi";
import { HiDuplicate } from "react-icons/hi";
import { IoIosSettings, IoMdArchive } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useBusinessContext } from "../../../../../../../Hooks/BusinessContext";
import { Inspection, Template } from "../../../../../../modules/Audit";
import { TemplateMeta } from "../../../../../../modules/Audit/Template-Meta";
import BuildBottomTemplates from "./BuildBottomTemplates";

interface TemplateTableLgProps {
  onRowClick: (temp: any) => void;
  data: Array<TemplateMeta.TemplateMeta>;
  isLoading: boolean;
  futureImplemetation: boolean;
  setLastUpdate: (timestamp: number) => void;
}
export default function LgTable({
  onRowClick,
  data,
  isLoading,
  futureImplemetation,
  setLastUpdate,
}: TemplateTableLgProps) {
  const navigate = useNavigate();
  const [selectedTemplateIds, setSelectedTemplateIds] = useState<number[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreatingInspection, setIsCreatingInspection] = useState(false);
  const { setLastUpdatedLocations } = useBusinessContext();
  const { allUsers } = useBusinessContext();
  const toast = useToast();
  type ShowColumns = {
    lastPublished: boolean;
    lastModified: boolean;
    scheduled: boolean;
    access: boolean;
  };
  const [showColumns, setShowColumns] = useState<ShowColumns>({
    lastPublished: true,
    lastModified: true,
    scheduled: true,
    access: true,
  });
  const handleColumnCheckbox = (column: keyof typeof showColumns) => {
    setShowColumns((prevState) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };
  const handleCheckboxClick = (templateId: number) => {
    if (selectedTemplateIds.includes(templateId)) {
      setSelectedTemplateIds((prevIds) =>
        prevIds.filter((id) => id !== templateId)
      );
    } else {
      setSelectedTemplateIds((prevIds) => [...prevIds, templateId]);
      onOpen();
    }
  };

  const menuitems: Array<{
    label: string;
    icon: any;
    onClick?: (...args: any) => void;
  }> = [
    {
      label: "Edit template",
      icon: MdModeEdit,
      onClick: (templateId: number) => {
        navigate(`/app/auditor/template-edit/${templateId}/?tab=Build`);
      },
    },
    {
      label: "Schedule",
      icon: AiTwotoneCalendar,
      onClick: (templateId: number) => {
        navigate(`/app/auditor/schedule_inspection?templateId=${templateId}`, {
          state: {
            triggerCreateModal: true,
          },
        });
      },
    },
    {
      label: "Manage access",
      icon: AiFillEye,
      onClick: (templateId: number) => {
        navigate(`/app/auditor/template-edit/${templateId}/?tab=Access`);
      },
    },
    {
      label: "View inspections",
      icon: CiMemoPad,
    },
    {
      label: "Duplicate template",
      icon: HiDuplicate,
      onClick: (templateId: number) => {
        const templateData: Template.duplicateTemplate = {
          templateId: templateId,
        };
        Template.CreateDuplicate(
          templateData,
          () => {
            toast({
              title: "Duplicate Template Created",
              status: "success",
            });
            setLastUpdate(Date.now());
          },
          (error) => {
            toast({
              title: "Error",
              description: error,
              status: "error",
            });
          }
        );
      },
    },
    {
      label: "Archive template",
      icon: IoMdArchive,
      onClick: (templateId: number) => {
        const archiveTemplate: Partial<TemplateMeta.TemplateMeta> = {
          archive: true,
        };

        TemplateMeta.Update(
          templateId,
          archiveTemplate,
          () => {
            toast({
              title: "Template Archived",
              status: "success",
            });
            setLastUpdate(Date.now());
          },
          (error) => {
            toast({
              title: "Error",
              description: error,
              status: "error",
            });
          }
        );
      },
    },
    {
      label: "Bookmark",
      icon: BsBookmarkFill,
    },
    {
      label: "Upload to Public Library",
      icon: AiOutlineUpload,
    },
    {
      label: "Get QR code",
      icon: AiOutlineQrcode,
    },
  ];
  if (futureImplemetation) {
    menuitems.splice(-3);
  }
  return (
    <TableContainer
      border="1px solid"
      borderRadius="5px"
      borderColor="borders.accent"
    >
      <Table variant="simple">
        <Thead bg="backgrounds.primary">
          <Tr>
            <Th maxW="40px" p="16px" borderColor="borders.accent">
              <Checkbox
                w="21px"
                h="21px"
                borderColor="grey"
                isChecked={data.length === selectedTemplateIds.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    const allTemplateIds = data.map((temp) => temp.id);
                    setSelectedTemplateIds(allTemplateIds);
                    onOpen();
                  } else {
                    setSelectedTemplateIds([]);
                  }
                }}
              />
            </Th>
            <Th
              maxW="200px"
              py="16px"
              pl="8px"
              pr="16px"
              fontSize="13px"
              fontWeight={400}
              lineHeight="1rem"
              color="textcolors.primary"
              textDecoration="underline"
              borderColor="borders.accent"
            >
              Template
            </Th>
            <Th
              maxW="200px"
              py="16px"
              pl="8px"
              pr="16px"
              fontSize="13px"
              fontWeight={400}
              lineHeight="1rem"
              color="textcolors.primary"
              textDecoration="underline"
              borderColor="borders.accent"
            >
              Template Type
            </Th>
            {showColumns.lastPublished && (
              <Th maxW="150px" p="16px" borderColor="borders.accent">
                <HStack>
                  <Text
                    fontSize="13px"
                    fontWeight={400}
                    lineHeight="1rem"
                    color="textcolors.primary"
                    textDecoration="underline"
                  >
                    last Published
                  </Text>
                  <AiOutlineArrowDown size={18} color="#3f495a" />
                </HStack>
              </Th>
            )}
            {showColumns.lastModified && (
              <Th
                maxW="150px"
                p="16px"
                fontSize="13px"
                fontWeight={400}
                lineHeight="1rem"
                color="textcolors.primary"
                textDecoration="underline"
                borderColor="borders.accent"
              >
                Last Modified
              </Th>
            )}
            {showColumns.scheduled && (
              <Th
                maxW="100px"
                p="16px"
                fontSize="13px"
                fontWeight={400}
                lineHeight="1rem"
                color="textcolors.primary"
                borderColor="borders.accent"
              >
                Scheduled
              </Th>
            )}
            {showColumns.access && (
              <Th
                maxW="200px"
                p="16px"
                fontSize="13px"
                fontWeight={400}
                lineHeight="1rem"
                color="textcolors.primary"
                borderColor="borders.accent"
              >
                Access
              </Th>
            )}
            <Th maxW="150px" p="16px" borderColor="borders.accent"></Th>
            <Th
              maxW="40px"
              py="16px"
              pr="0px"
              pl="8px"
              borderColor="borders.accent"
            >
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<IoIosSettings size={18} color="#545f70" />}
                  variant="outline"
                  borderRadius={"50%"}
                  border={"none"}
                  _hover={{ backgroundColor: "transparent" }}
                  _active={{
                    backgroundColor: "transparent",
                  }}
                />
                <MenuList minW="160px">
                  <MenuItem px="14px" py="9.5px">
                    <Text fontSize="13px" fontWeight={400} lineHeight="1rem">
                      Show / hide columns
                    </Text>
                  </MenuItem>
                  {(
                    [
                      { key: "lastPublished", label: "Last published" },
                      { key: "lastModified", label: "Last modified" },
                      { key: "scheduled", label: "Scheduled" },
                      { key: "access", label: "Access" },
                    ] as const
                  ).map((menu, i) => (
                    <MenuItem key={i} px="14px" py="9.5px">
                      <Checkbox
                        isChecked={showColumns[menu.key]}
                        onChange={(e) => {
                          handleColumnCheckbox(menu.key);
                          e.stopPropagation();
                        }}
                      >
                        {menu.label}
                      </Checkbox>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <AbsoluteCenter>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="primary.500"
                size="xl"
              />
            </AbsoluteCenter>
          ) : (
            data.map((temp, i) => {
              return (
                <Tr
                  onClick={() => {
                    onRowClick(temp);
                  }}
                  key={i}
                >
                  <Td
                    maxW="40px"
                    py="16px"
                    px="16px"
                    borderColor="borders.accent"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Checkbox
                      w="21px"
                      h="21px"
                      borderColor="grey"
                      isChecked={selectedTemplateIds.includes(temp.id)}
                      onChange={() => {
                        handleCheckboxClick(temp.id);
                      }}
                    />
                  </Td>
                  <Td py="16px" pr="8px" pl="0px" borderColor="borders.accent">
                    <HStack>
                      {temp.image ? (
                        <Image
                          h="40px"
                          w="40px"
                          ml="8px"
                          src={temp.image}
                          borderRadius="8px"
                        />
                      ) : (
                        <Avatar
                          w="40px"
                          h="40px"
                          ml="8px"
                          name={temp.title}
                          backgroundColor="primary.500"
                        />
                      )}
                      <Stack spacing={0}>
                        <Text fontWeight={500} color="textcolors.primary">
                          {temp.title}
                        </Text>
                        <Text
                          w="auto"
                          textColor="textcolors.primary"
                          fontWeight={400}
                        >
                          {allUsers.find(
                            (user) => user?.user?.id === temp.createdBy
                          )?.user?.firstName +
                            " " +
                            allUsers.find(
                              (user) => user?.user?.id === temp.createdBy
                            )?.user?.lastName}
                        </Text>
                      </Stack>
                    </HStack>
                  </Td>
                  <Td
                    py="16px"
                    pl="16px"
                    borderColor="borders.accent"
                    fontSize="14px"
                    color="textcolors.secondary"
                  >
                    {temp.inductionTemplate
                      ? "Induction"
                      : temp.warehouseTemplate
                      ? "Warehouse"
                      : "Audit"}
                  </Td>
                  {showColumns.lastPublished && (
                    <Td
                      py="16px"
                      pl="16px"
                      borderColor="borders.accent"
                      fontSize="14px"
                      color="textcolors.secondary"
                    >
                      {temp.lastPublished}
                    </Td>
                  )}
                  {showColumns.lastModified && (
                    <Td
                      py="16px"
                      pl="16px"
                      borderColor="borders.accent"
                      fontSize="14px"
                      color="textcolors.secondary"
                    >
                      {temp.lastModified}
                    </Td>
                  )}
                  {showColumns.scheduled && (
                    <Td
                      p="16px"
                      borderColor="borders.accent"
                      fontSize="14px"
                      color={
                        temp.scheduled
                          ? "textcolors.secondary"
                          : "textcolors.tertiary"
                      }
                    >
                      {temp.scheduled ? "Yes" : "No"}
                    </Td>
                  )}
                  {showColumns.access && (
                    <Td p="16px" borderColor="borders.accent">
                      <HStack>
                        {/* <AiTwotoneBank /> */}
                        <Text fontSize="14px" color="textcolors.secondary">
                          {temp.countOfPermissions}
                        </Text>
                      </HStack>
                    </Td>
                  )}
                  <Td
                    py="16px"
                    px="0px"
                    textAlign="right"
                    borderColor="borders.accent"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Button
                      isLoading={isCreatingInspection}
                      variant="outline"
                      h="41px"
                      px="20px"
                      py="12px"
                      fontWeight={500}
                      fontSize="14px"
                      borderRadius="8px"
                      onClick={(e) => {
                        setIsCreatingInspection(true);
                        Template.GetById(
                          +temp.id,
                          (temp) => {
                            Inspection.SubmitInspection(
                              {
                                inspectionCompleted: false,
                                inspectionInventory: [],
                                inspectionScore: 0,
                                mediaUniqueId: uuidv4(),
                                archive: false,
                                templateItems: temp.templateItems as any,
                                template: temp.template,
                                deletedInventoryItems: [],
                                templateMedia: temp?.templateMedia?.map(
                                  (doc) => {
                                    return {
                                      templateId: doc.tempId,
                                      attachmentUrl: doc.docUrl,
                                      attachmentName: doc.fileName,
                                      id: doc.id!,
                                    };
                                  }
                                ),
                              },
                              (inspection) => {
                                setIsCreatingInspection(false);
                                setLastUpdatedLocations(Date.now());
                                navigate(
                                  `/app/auditor/inspection-conduct/CONTINUE_${inspection.inspection?.id}`
                                );
                                // console.log(inspection);
                              },
                              (error) => {
                                toast({
                                  title: "Error",
                                  description:
                                    "Error Occured while Submitting Inspection",
                                  status: "error",
                                });
                              }
                            );
                          },
                          (error) => {
                            console.log(error);
                            toast({
                              title: "Error",
                              description:
                                "You do not have permission to submit this resource",
                              status: "error",
                              duration: 5000,
                              isClosable: true,
                            });
                            navigate(-1);
                          }
                        );
                        // navigate(`/app/auditor/inspection-conduct/NEW_${temp.id}`);

                        e.stopPropagation();
                      }}
                    >
                      Start inspection
                    </Button>
                  </Td>
                  <Td
                    py="16px"
                    px="0px"
                    borderColor="borders.accent"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        pl="12px"
                        aria-label="Options"
                        icon={<FiMoreVertical size={24} color="#545f70" />}
                        variant="outline"
                        borderRadius={"50%"}
                        border={"none"}
                        _hover={{ backgroundColor: "transparent" }}
                        _active={{
                          backgroundColor: "transparent",
                        }}
                      />
                      <MenuList minW="160px">
                        {menuitems.map((menu, i) => {
                          return (
                            <Fragment key={i}>
                              {(menu.label === "Edit template" ||
                                menu.label === "Manage access") &&
                              !temp.EDIT_PERMISSION ? null : (
                                <MenuItem
                                  px="14px"
                                  py="9.6px"
                                  color="textcolors.primary"
                                  onClick={() => {
                                    menu.onClick?.(temp.id);
                                  }}
                                >
                                  <HStack>
                                    <Icon
                                      as={menu.icon}
                                      boxSize={18}
                                      color="#1f2533"
                                    />
                                    <Text fontSize="14px" fontWeight={400}>
                                      {menu.label}
                                    </Text>
                                  </HStack>
                                </MenuItem>
                              )}
                            </Fragment>
                          );
                        })}
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              );
            })
          )}
        </Tbody>
        <BuildBottomTemplates
          isOpen={isOpen}
          onClose={onClose}
          templateIds={selectedTemplateIds}
          setLastUpdate={setLastUpdate}
        />
      </Table>
    </TableContainer>
  );
}
